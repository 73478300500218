import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useMemo } from "react";
import { useEnabledIntegrationTypes } from "./useEnabledIntegrationTypes";
import { useIntegrationTypeConfig } from "./useIntegrationConfig";

export const useIntegrationTypes = ({
  connected,
}: { connected?: boolean } = {}) => {
  const { enabledIntegrationTypes } = useEnabledIntegrationTypes();
  const { getIntegrationConfig: getConfig } = useIntegrationTypeConfig();

  const { settings } = useOrgSettings();
  const integrationTypes = useMemo(() => {
    if (connected) {
      return (
        settings?.integrations.accounting
          .filter((s) => enabledIntegrationTypes.includes(s.integration))
          .map((s) => {
            return {
              integrationType: s,
              config: getConfig(s.integration),
            };
          }) || []
      );
    }

    return enabledIntegrationTypes.map((s) => {
      return {
        integrationType: settings?.integrations.accounting.find(
          (sourceSystem) => sourceSystem.integration === s,
        ) || {
          integration: s,
          enabled: false,
        },
        config: getConfig(s),
      };
    });
  }, [
    connected,
    enabledIntegrationTypes,
    settings?.integrations.accounting,
    getConfig,
  ]);

  return { integrationTypes };
};
