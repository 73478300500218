import { Dialog } from "@/common/components/dialog/Dialog";
import { TextField } from "@/common/components/textfield/TextField";
import { FC, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const DialogContent = tw.div`
  grid gap-7 place-items-center
`;

const Details = tw.div`
  text-center text-sm
`;

type NewColumnDialogProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  handleConfirm: (column: string) => void;
};

export const NewColumnDialog: FC<NewColumnDialogProps> = ({
  visible = true,
  setVisible,
  handleConfirm,
}) => {
  const intl = useIntl();
  const [column, setColumn] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        inputRef.current?.focus();
      });
    }
  }, [visible]);

  const closeDialog = () => {
    setVisible?.(false);
    setColumn("");
  };

  return (
    <Dialog
      title={<FormattedMessage id="ADDITIONAL_DESCRIPTION_COLUMN" />}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({ id: "ADD_NEW_COLUMN" })}
      handleConfirm={() => {
        handleConfirm(column);
        closeDialog();
      }}
      handleCancel={closeDialog}
      maxWidth="sm"
      show={visible}
      content={
        <DialogContent>
          <FormattedMessage
            id="ADDITIONAL_DESCRIPTION_COLUMN_TEXT"
            tagName={Details}
            values={{
              b: (...chunks) => <strong>{chunks}</strong>,
            }}
          />
          <TextField
            size="small"
            value={column}
            InputProps={{ style: { width: "300px" } }}
            label={intl.$t({ id: "COLUMN_NAME" })}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setColumn(event.target.value);
            }}
            autoComplete="off"
            inputRef={inputRef}
            onKeyUpCapture={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter") {
                handleConfirm(column);
                closeDialog();
              }
            }}
          />
        </DialogContent>
      }
    />
  );
};
