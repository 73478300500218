import { NoFunction } from "@/types/NoFunction";
import { createContext, FC, useCallback, useContext, useState } from "react";

type ContextProps = {
  step: number;
  setStep: (step: number) => void;
  moveToPreviousStep: (previousPageInitialNestedStep?: number) => void;
  moveToNextStep: () => void;

  nestedStep: number;
  setNestedStep: (step: number) => void;
  moveToPreviousNestedStep: () => void;
  moveToNextNestedStep: () => void;
};

const ProviderContext = createContext<ContextProps>({
  step: 0,
  setStep: NoFunction,
  moveToPreviousStep: NoFunction,
  moveToNextStep: NoFunction,

  nestedStep: 0,
  setNestedStep: NoFunction,
  moveToPreviousNestedStep: NoFunction,
  moveToNextNestedStep: NoFunction,
});

export const NestedStepperProvider: FC<{
  children: React.ReactNode;
  initialStep?: number;
}> = ({ children, initialStep = 0 }) => {
  const [step, setStep] = useState(initialStep);
  const [nestedStep, setNestedStep] = useState(0);

  const moveToPreviousStep = useCallback(
    (previousPageInitialNestedStep?: number) => {
      setNestedStep(previousPageInitialNestedStep ?? 0);
      setStep((s) => s - 1);
    },
    [],
  );

  const moveToNextStep = useCallback(() => {
    setNestedStep(0);
    setStep((s) => s + 1);
  }, []);

  const moveToPreviousNestedStep = useCallback(() => {
    setNestedStep((s) => s - 1);
  }, []);

  const moveToNextNestedStep = useCallback(() => {
    setNestedStep((s) => s + 1);
  }, []);

  return (
    <ProviderContext.Provider
      value={{
        step,
        setStep,
        moveToPreviousStep,
        moveToNextStep,

        nestedStep,
        setNestedStep,
        moveToPreviousNestedStep,
        moveToNextNestedStep,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useNestedStepper = (): ContextProps => useContext(ProviderContext);
