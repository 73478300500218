import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { ZoneFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useProjectItemsZones } from "../../providers/ProjectItemsZonesProvider";

export const ZonesSelector: FC<{
  options: (ZoneFieldsFragment & { disabled?: boolean })[];
  disabled?: boolean;
  className?: string;
}> = ({ options, disabled, className = "" }) => {
  const intl = useIntl();
  const { selectedZones, setSelectedZones } = useProjectItemsZones();

  return (
    <Multiselect
      placeholder={intl.$t({
        id: "ZONES_PLURAL",
      })}
      className={`w-full ${className}`}
      options={options.sort((a, b) => a.name.localeCompare(b.name))}
      values={selectedZones}
      onMultipleChange={(zones) => setSelectedZones(zones || [])}
      getLabel={(option) => option.name}
      getValue={(option) => option.id}
      disableCloseOnSelect
      getOptionDisabled={(zone) => !!zone.disabled}
      disabled={disabled}
      includeCheckbox
      limitTags={1}
      chipSize="small"
      moreItems={options
        .filter((c) =>
          selectedZones?.slice(1, selectedZones.length).includes(c.id),
        )
        .map((c) => c.name)}
      sx={{
        ".MuiInputBase-formControl.MuiAutocomplete-inputRoot": {
          flexWrap: "nowrap",
        },
      }}
      required
    />
  );
};
