import { USERS_USER_FIELDS } from "@/common/fragments/usersUserFragment";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CreateUserInput,
  UpdateUserInput,
  useAdminUsersQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
} from "@/generated/graphql";
import { StoreObject } from "@apollo/client";
import { useMemo } from "react";

export const useAdminUsers = () => {
  const { data, error, loading: queryLoader } = useAdminUsersQuery();

  useErrorEffect(error);
  const { setError } = useGlobalError();

  const [createUserMutation, { loading: createUserLoader }] =
    useCreateUserMutation({
      update(cache, { data }) {
        cache.modify({
          fields: {
            users(existingUsers) {
              const newUserRef = cache.writeFragment({
                data: data?.createUser,
                fragment: USERS_USER_FIELDS,
              });
              return [...existingUsers, newUserRef];
            },
          },
        });
      },
    });
  const [updateUserMutation, { loading: updateUserLoader }] =
    useUpdateUserMutation();
  const [deleteUserMutation] = useDeleteUserMutation({
    update(cache, { data }) {
      cache.modify({
        fields: {
          users(existingUsers, { readField }) {
            return existingUsers.filter(
              (user: StoreObject) =>
                data?.deleteUser?.id !== readField("id", user),
            );
          },
        },
      });
    },
  });

  const loading = useMemo(
    () => queryLoader || createUserLoader || updateUserLoader,
    [queryLoader, createUserLoader, updateUserLoader],
  );

  const createUser = async (user: CreateUserInput) => {
    try {
      const { data, errors } = await createUserMutation({
        variables: { input: user },
      });
      setError(errors);
      return data?.createUser?.id;
    } catch (errors) {
      setError(errors);
    }
  };
  const updateUser = async (user: UpdateUserInput) => {
    try {
      const { data, errors } = await updateUserMutation({
        variables: { input: user },
      });
      setError(errors);
      return data?.updateUser?.id;
    } catch (errors) {
      setError(errors);
    }
  };

  const deleteUser = async (id: string) => {
    try {
      const { errors } = await deleteUserMutation({ variables: { id } });
      setError(errors);
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  return {
    users: (data?.users || []).toSorted((a, b) =>
      a.firstName.localeCompare(b.firstName),
    ),
    loading,
    error: !!error,
    createUser,
    deleteUser,
    updateUser,
  };
};
