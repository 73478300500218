import { GroupToggle } from "@/common/components/group-toggle/GroupToggle";
import { If } from "@/common/components/if/If";
import {
  Container,
  Content,
  Label,
  Row,
  Title,
} from "@/common/components/integration-batch-options/IntegrationBatchOptions.styles";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Switch } from "@/common/components/switch/Switch";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { BatchType, ReleaseSummaryFieldsFragment } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IntegrationBatchOptions } from "../../../../../../../../common/components/integration-batch-options/IntegrationBatchOptions";
import { ConnectionMode } from "../../../../../../admin/integrations/components/common/ConnectionMode";
import { useReleaseConnectionOptions } from "../../providers/ReleaseConnectionOptionsProvider";
import {
  ErrorContainer,
  ErrorIcon,
  ErrorMessage,
} from "./ConnectionOptions.styles";

type Props = {
  includeAutoSync?: boolean;
  defaultClosed?: boolean;
  release?: ReleaseSummaryFieldsFragment;
  mode?: ConnectionMode;
};

export const ReleaseConnectionOptions: FC<Props> = ({
  includeAutoSync,
  defaultClosed = false,
  release,
  mode,
}) => {
  const intl = useIntl();
  const { integrations } = useOrgSettingsExtended();
  const { autoSync, setAutoSync, hasBatchError } =
    useReleaseConnectionOptions();

  const [collapsed, setCollapsed] = useState(defaultClosed);

  if (!integrations) {
    return null;
  }

  return (
    <Container>
      <Title>
        <FormattedMessage id="OPTIONS" />
        <GroupToggle
          isOpened={!collapsed}
          onClick={() => setCollapsed((v) => !v)}
        />
      </Title>
      <Content $opened={!collapsed}>
        <If isTrue={includeAutoSync}>
          <Row>
            <Label>
              <FormattedMessage id="AUTO_SYNC" />
              <Tooltip
                id="default-auto-sync"
                element={
                  <LinkLike>
                    <InfoOutlined />
                  </LinkLike>
                }
              >
                <FormattedMessage
                  id="AUTO_SYNC_INFO"
                  values={{ sub: (...chunks) => <i>{chunks}</i> }}
                />
              </Tooltip>
            </Label>
            <Switch
              width={110}
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
              onChange={setAutoSync}
              value={autoSync}
            />
          </Row>
        </If>
        <IntegrationBatchOptions
          mode={mode}
          batch={release?.poLink?.batch}
          type={BatchType.PurchaseOrder}
        />
        <If isTrue={hasBatchError}>
          <ErrorContainer>
            <ErrorIcon />
            <ErrorMessage>
              <FormattedMessage id="ERROR_CREATING_BATCH" />
            </ErrorMessage>
          </ErrorContainer>
        </If>
      </Content>
    </Container>
  );
};
