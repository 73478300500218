import { InvoiceStatus, ReleaseStatus } from "@/generated/graphql";

export const SIGNATURE_HEADER_NAME = "X-Fm-Signature";
export const SIGNATURE_USER_ID = "X-Fm-Signed-User-Id";
export const SIGNATURE_PROJECT_ID = "X-Fm-Signed-Project-Id";
export const LOCAL_STORAGE_KEYS = {
  LOGIN_ORG_ID: "LOGIN_ORG_ID",
  PAGE_SIZE_PREFERENCE: "PAGE_SIZE_PREFERENCE",
  LIST_VIEW: "LIST_VIEW",
  INVOICE_SEQUENCES: {
    SEQUENCE_ACTIVE: "INVOICE_SEQUENCE_ACTIVE",
    SEQUENCE_STEP: "INVOICE_SEQUENCE_STEP",
    SELECTED_INVOICES: "INVOICE_SEQUENCE_SELECTED_INVOICES",
    REDIRECT_ROUTE: "INVOICE_SEQUENCE_REDIRECT_ROUTE",
  },
  RELEASE_SEQUENCES: {
    SEQUENCE_ACTIVE: "RELEASE_SEQUENCE_ACTIVE",
    SEQUENCE_STEP: "RELEASE_SEQUENCE_STEP",
    SELECTED_RELEASES: "RELEASE_SEQUENCE_SELECTED_RELEASES",
    REDIRECT_ROUTE: "RELEASE_SEQUENCE_REDIRECT_ROUTE",
  },
  RECEIPT_SEQUENCES: {
    SEQUENCE_ACTIVE: "RECEIPT_SEQUENCE_ACTIVE",
    SEQUENCE_STEP: "RECEIPT_SEQUENCE_STEP",
    SELECTED_INVOICES: "RECEIPT_SEQUENCE_SELECTED_INVOICES",
    REDIRECT_ROUTE: "RECEIPT_SEQUENCE_REDIRECT_ROUTE",
  },
  DELIVERY_SLIP_SEQUENCES: {
    SEQUENCE_ACTIVE: "DELIVERY_SLIP_SEQUENCE_ACTIVE",
    SEQUENCE_STEP: "DELIVERY_SLIP_SEQUENCE_STEP",
    SELECTED_DELIVERY_SLIPS: "DELIVERY_SLIP_SEQUENCE_SELECTED_DELIVERY_SLIPS",
    REDIRECT_ROUTE: "DELIVERY_SLIP_SEQUENCE_REDIRECT_ROUTE",
  },
  BUYOUT_SEQUENCES: {
    SEQUENCE_ACTIVE: "BUYOUT_SEQUENCE_ACTIVE",
    SEQUENCE_STEP: "BUYOUT_SEQUENCE_STEP",
    SELECTED_ENTITIES: "BUYOUT_SEQUENCE_SELECTED_BUYOUTS",
    REDIRECT_ROUTE: "BUYOUT_SEQUENCE_REDIRECT_ROUTE",
  },
  GROUPED_BY_COST_CODE: "GROUPED_BY_COST_CODE",
  INVOICES_LIST_FILTER: "INVOICES_LIST_FILTER",
  INVOICE_EMAILS_LIST_FILTER: "INVOICE_EMAILS_LIST_FILTER",
  RECEIPTS_LIST_FILTER: "RECEIPTS_LIST_FILTER",
  RFQS_LIST_FILTER: "RFQS_LIST_FILTER",
  DELIVERY_SLIPS_LIST_FILTER: "DELIVERY_SLIPS_LIST_FILTER",
  RELEASES_LIST_FILTER: "RELEASES_LIST_FILTER",
  BUYOUTS_LIST_FILTER: "BUYOUTS_LIST_FILTER",
  PROJECTS_LIST_FILTER: "PROJECTS_LIST_FILTER",
  GMV_REPORT_EXCLUDED_ORGS: "GMV_REPORT_EXCLUDED_ORGS",
};
export const SAVED_LIST_VIEW_ID = {
  ORDER: "ORDER",
  RFQ: "RFQ",
  BUYOUT: "BUYOUT",
};
export const QUERYSTRING = {
  STATUSES: "statuses",
  INVOICE_EMAIL_STATUSES: "invoice-email-statuses",
  SEARCH: "search",
  PROJECT_ID: "project-id",
  SELLER_ORG_IDS: "seller-org-ids",
  ORG_LOCATION_ID: "org-location-id",
  COST_CODE_IDS: "cost-code-ids",
  BUYOUT_FILTER_STATUSES: "buyout-filter-statuses",
  DELIVERY_STATUS: "delivery-status",
  PRICE_CONDITION: "price-condition",
  RFQ_FILTER_STATUSES: "rfqs-filter-statuses",
  WAREHOUSE_ID: "warehouse-id",
  ORDER_TYPE_ID: "order-type-id",
  MIN_ISSUE_DATE: "min-issue-date",
  MAX_ISSUE_DATE: "max-issue-date",
  MIN_CREATED_AT: "min-created-at",
  MAX_CREATED_AT: "max-created-at",
  MIN_FULFILLMENT_DATE: "min-fulfillment-date",
  MAX_FULFILLMENT_DATE: "max-fulfillment-date",
  MAPPED: "mapped",
  KICKBACK_TO_ME: "kickback-to-me",
  BACKORDERED: "backordered",
  ARCHIVED: "archived",
  EXPORTED: "exported",
  CREATED_BY_ID: "created-by-id",
  DELIVERY_SLIP_STATUS: "delivery-slip-status",
  CLOSED_PROJECTS: "closed-projects",
  SPREADSHEET_VIEW: "spreadsheet-view",
  REPLACE_DOCUMENT: "replace-document",
  FILTERS: "filters",
};
export const MAX_ITEMS_PER_PAGE = 1e6;
export const DEFAULT_ITEMS_PER_PAGE = 8;
export const DEFAULT_ADDRESS_STATE = "CA";
export const DEFAULT_ADDRESS_COUNTRY = "US";
export const DEFAULT_ADDRESS_COUNTRY_ALTERNATIVE = "USA";
export const SIGNATURE_PREFIX = "X-Fm";
export const MAX_IMAGE_SIZE = 5 * 1024 * 1024;
export const MIN_IMAGE_DIMENSION = 200;
export const MAX_IMAGE_DIMENSION = 5000;
export const NUMERIC_DECIMAL_POINTS = 3;
export const MAX_PRICE_DECIMALS = 6;
export const MAX_QUANTITY_DECIMALS = 3;
export const MIN_QUANTITY_DECIMALS = 2;
export const TOTAL_PRICE_DECIMAL_POINTS = 2;
export const MAX_NUMBER_OF_SNACKBAR_ALERTS = 10;
export const LUMP_SUM_UOM = "LS";
export const LUMP_SUM_UOM_PLURAL_DESCRIPTION = "lump sum";
export const ALLOWED_CONTINENTS = ["NA", "SA", "EU"];
export const DEFAULT_CURRENCY = "USD";
export const DEFAULT_CURRENCY_SYMBOL = "$";
export const PDF_FONT = "helvetica";
export const PDF_LEFT_SPACING = 16;
export const DEFAULT_AUXILIARY_ITEM_UOM_MNEMONIC = "UT";
export const UTC_OFFSET = new Date().getTimezoneOffset() * 60 * 1000;
export const RETRY_COUNT = 3;
export const ORG_ID_QUERY_PARAM = "orgID";
export const AUTO_HIDE_DURATIONS = {
  error: 10000,
  warning: 10000,
  success: 5000,
  system: 10000,
};
export const SCREEN_BREAKPOINTS = {
  xl: 1920,
  lg: 1280,
  md: 1024,
  sm: 768,
  xs: 640,
};
export enum GeneralFilter {
  All = "ALL",
}

export enum REASON {
  CLICKAWAY = "clickaway",
}

export const RELEASE_MESSAGE_DISABLE_STATUSES = [
  ReleaseStatus.Draft,
  ReleaseStatus.AwaitingApproval,
  ReleaseStatus.Rejected,
];

export const PAGE_OPTIONS = [8, 24, 50];
export const PAGINATION = {
  PAGE: "page",
  FIRST: "first",
  LAST: "last",
  BEFORE: "before",
  AFTER: "after",
};

export const DEFAULT_PAYMENT_OPTIONS = [30, 45, 60, 90, 120];

export const DEFAULT_DEBOUNCE_TIME = 500;

export const DIALOG_AUTO_CLOSE_TIMER = 2000;

export const APPROVALS_NUMBER = 3;

export const APPROVED_INVOICES_STATUS = [
  InvoiceStatus.Approved,
  InvoiceStatus.Paid,
];

export const ORDER_INVOICED_INVOICES_STATUS = [
  InvoiceStatus.Approved,
  InvoiceStatus.Paid,
  InvoiceStatus.AwaitingApproval,
  InvoiceStatus.InternalKickback,
  InvoiceStatus.IssuesReported,
];

export const DECIMAL_MAX_FRACTION_DIGITS = 6;
