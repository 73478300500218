import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import { HeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { ListDatesButton } from "@/common/components/list-dates-button/ListDatesButton";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { PopoverWithTabs } from "@/common/components/popover-with-tabs/PopoverWithTabs";
import { ProjectsFilterSelector } from "@/common/components/projects-filter-selector/ProjectsFilterSelector";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { StatusesFilter } from "@/common/components/status-filter/StatusesFilter";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { VendorsFilterSelector } from "@/common/components/vendors-filter-selector/VendorsFilterSelector";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { ReleaseListActions } from "@/contractor/pages/home/release/components/connections/components/buttons/ReleaseListActions";
import { ReleaseStatus } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDeliveries } from "../providers/DeliveriesProvider";
import { DeliveriesAdditionalFilters } from "./DeliveriesAdditionalFilters";

const HeaderContainerStyled = tw(HeaderContainer)`
  grid lg:grid-flow-col justify-between gap-3 flex-wrap bg-transparent lg:bg-gray-100 pt-4 pb-4 top-[77px] xl:top-[69px]
`;
const Filters = tw.div`grid grid-flow-col gap-2 items-center`;

enum ReleaseStatusType {
  Draft = "DRAFT",
  AwaitingApproval = "AWAITING_APPROVAL",
  Rejected = "REJECTED",
  Requested = "REQUESTED",
  Scheduled = "SCHEDULED",
  Received = "RECEIVED",
  PartiallyReceived = "PARTIALLY_RECEIVED",
  Canceled = "CANCELED",
}

export const DeliveriesHeader = () => {
  const intl = useIntl();
  const { filter, setFilter } = useDeliveries();
  const { projects } = useProjectListOptions({
    includeClosedProjects: filter?.closedProjects,
  });

  const options = useMemo(() => {
    return Object.entries(ReleaseStatusType).map(([, value]) => {
      return {
        value,
        name: intl.$t({ id: `RELEASE_STATUS_FILTER_${value}` }),
      };
    });
  }, [intl]);

  const setFilterHandler = useCallback(
    (values: ReleaseStatus[] | undefined) => {
      setFilter({
        ...filter,
        statuses: values,
      });
    },
    [filter, setFilter],
  );

  const isDefaultFilter = useMemo(() => {
    return (
      filter?.exported == null &&
      filter?.hasReceipt == null &&
      filter?.isBackorder == null &&
      filter?.deleted === false &&
      !filter?.typeIds
    );
  }, [
    filter?.deleted,
    filter?.exported,
    filter?.hasReceipt,
    filter?.isBackorder,
    filter?.typeIds,
  ]);

  return (
    <HeaderContainerStyled>
      <Filters>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={(value: string) => setFilter({ ...filter, search: value })}
          value={filter?.search}
          info={
            <Tooltip
              id="subtotal-price"
              element={
                <LinkLike onClick={() => null} forwardEvent={false}>
                  <InfoOutlined />
                </LinkLike>
              }
            >
              <FormattedMessage
                id="ORDERS_SEARCH_INFO"
                values={{ br: <br /> }}
              />
            </Tooltip>
          }
        />
        <ProjectsFilterSelector
          projects={projects}
          onChange={(value) =>
            setFilter({
              ...filter,
              projectIds: value ? ([value] as string[]) : undefined,
            })
          }
          value={filter?.projectIds?.[0]}
        />
        <VendorsFilterSelector
          value={filter?.sellerOrgLocationIds?.[0]}
          onChange={(value) => {
            setFilter({
              ...filter,
              sellerOrgLocationIds: value ? [value] : undefined,
            });
          }}
        />
        <StatusesFilter
          options={options}
          selectedStatuses={filter?.statuses}
          onChange={setFilterHandler}
        />
        <ListHeaderFilterButton
          isDefault={isDefaultFilter}
          filter={<DeliveriesAdditionalFilters />}
        />
        <ListDatesButton
          dates={[
            {
              startDate: filter?.minTime,
              endDate: filter?.maxTime,
            },
          ]}
          clearValues={() =>
            setFilter({
              ...filter,
              minTime: undefined,
              maxTime: undefined,
            })
          }
        >
          {(onClose) => (
            <PopoverWithTabs
              tabs={[
                {
                  label: intl.$t({ id: "DELIVERY_DATE" }),
                  content: (
                    <DateRangePicker
                      staticView
                      onClose={onClose}
                      selectedValue={
                        filter?.maxTime || filter?.minTime
                          ? {
                              startDate: filter?.minTime
                                ? new Date(filter.minTime)
                                : new Date(),
                              endDate: filter?.maxTime
                                ? new Date(filter.maxTime)
                                : new Date(),
                            }
                          : undefined
                      }
                      isUTC
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minTime: dateRange.startDate?.getTime(),
                          maxTime: dateRange.endDate?.getTime(),
                        })
                      }
                    />
                  ),
                  viewState: "deliveryDate",
                },
              ]}
              activeTab="deliveryDate"
            />
          )}
        </ListDatesButton>
      </Filters>
      <ReleaseListActions />
    </HeaderContainerStyled>
  );
};
