import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { If } from "@/common/components/if/If";
import { FieldPreview } from "@/generated/graphql";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { FC, KeyboardEvent, MouseEvent, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type FileColumnCarouselProps = {
  columns: FieldPreview[];
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  disabled?: boolean;
};

const ColumnCarousel = tw.div`
  grid w-full h-full place-items-center gap-2
`;

const Counter = tw.div`
  grid grid-flow-col gap-1 text-black text-sm font-normal my-4 place-items-center w-fit 
`;

const CounterNumber = tw.span`
  font-medium
`;

const CarouselContainer = tw.div`
  grid grid-cols-[auto_1fr_auto] gap-5 w-full place-items-center
`;

const CarouselWrapper = tw.div`
  grid w-full overflow-hidden
`;

const CarouselContent = tw.div`
  relative grid grid-flow-col col-span-1 w-full h-64 transition-all duration-500
  row-start-1 row-end-3 col-start-1 col-end-1
  ${({ $direction }: { $direction: number }) =>
    $direction > 0
      ? "animate-[enter-right_0.4s_ease-in-out_forwards]"
      : $direction < 0
        ? "animate-[enter-left_0.4s_ease-in-out_forwards]"
        : ""}
`;

const CarouselItem = tw.div`
  absolute inset-0 border rounded border-gray-400 select-none
  
`;
const CarouselItemHeader = tw.div`
  bg-gray-200 p-2 pl-2.5 text-base font-bold rounded-t
`;

const CarouselItemRow = tw.div`
  bg-white p-2 pl-2.5 text-sm border-b border-gray-200 h-8
`;

const BottomGradient = tw.div`
  z-30 bg-gradient-to-t from-white to-transparent w-full h-20
  row-start-2 row-end-3 col-start-1 col-end-1;
`;

export const FileColumnCarousel: FC<FileColumnCarouselProps> = ({
  columns,
  currentIndex,
  setCurrentIndex,
  disabled = false,
}) => {
  const [start, setStart] = useState<number | null>(null);
  const [mouseDown, setMouseDown] = useState(false);
  const [direction, setDirection] = useState(0);
  const [visibleIndexes, setVisibleIndexes] = useState([
    currentIndex > 0 ? currentIndex - 1 : columns.length - 1,
    currentIndex,
    currentIndex < columns.length - 1 ? currentIndex + 1 : 0,
  ]);
  const prev = () => {
    if (disabled) {
      return;
    }

    const newIndex = currentIndex > 0 ? currentIndex - 1 : columns.length - 1;
    setCurrentIndex(newIndex);
    setDirection(-1);
    updateVisibleIndexes(newIndex);
  };

  const next = () => {
    if (disabled) {
      return;
    }

    const newIndex = currentIndex < columns.length - 1 ? currentIndex + 1 : 0;
    setCurrentIndex(newIndex);
    setDirection(1);
    updateVisibleIndexes(newIndex);
  };

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    if (!mouseDown) {
      setStart(e.clientX);
      setMouseDown(true);
    }
  };

  const handleMouseUp = (e: MouseEvent<HTMLDivElement>) => {
    setMouseDown(false);
    if (mouseDown) {
      if (start && e.clientX > start) {
        prev();
      } else if (start && e.clientX < start) {
        next();
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowRight") {
      next();
    } else if (e.key === "ArrowLeft") {
      prev();
    }
  };

  const updateVisibleIndexes = (newIndex: number) => {
    setVisibleIndexes([
      newIndex > 0 ? newIndex - 1 : columns.length - 1,
      newIndex,
      newIndex < columns.length - 1 ? newIndex + 1 : 0,
    ]);
  };

  return (
    <ColumnCarousel onKeyDown={handleKeyDown}>
      <If isTrue={!disabled}>
        <Counter>
          <CounterNumber>{currentIndex + 1}</CounterNumber>
          <FormattedMessage id="OF" />
          <CounterNumber>{columns.length}</CounterNumber>
        </Counter>
      </If>
      <CarouselContainer>
        <IconButtonBorderless onClick={prev} disabled={disabled}>
          <If isTrue={!disabled}>
            <KeyboardArrowLeft />
          </If>
        </IconButtonBorderless>
        <CarouselWrapper
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          <CarouselContent key={currentIndex} $direction={direction}>
            {visibleIndexes.map((index, i) => (
              <CarouselItem
                key={index}
                style={{ transform: `translateX(${(i - 1) * 120}%)` }}
              >
                <CarouselItemHeader>{columns[index]?.name}</CarouselItemHeader>
                {columns[index]?.records.map((content, cIndx) => (
                  <CarouselItemRow key={cIndx}>{content}</CarouselItemRow>
                ))}
              </CarouselItem>
            ))}
          </CarouselContent>
          <BottomGradient />
        </CarouselWrapper>
        <IconButtonBorderless onClick={next} disabled={disabled}>
          <If isTrue={!disabled}>
            <KeyboardArrowRight />
          </If>
        </IconButtonBorderless>
      </CarouselContainer>
    </ColumnCarousel>
  );
};
