import { gql } from "@apollo/client";

export const ADDRESS_FIELDS = gql`
  fragment AddressFields on Address {
    id
    addressLine1
    addressLine2
    city
    state
    postalCode
    country
  }
`;
