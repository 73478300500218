import { If } from "@/common/components/if/If";
import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { ImportExternalResultTitle } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/components/import-external-codes/components/ImportExternalResultTitle";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useImportExternalItems } from "../../../providers/ImportExternalItemsProvider";
import { useImportExternalItemsTableConfiguration } from "./ImportExternalItemsTable.configuration";

const Container = tw.div`flex flex-1 self-stretch flex-col`;

export const ImportExternalItemsImportResults = () => {
  const intl = useIntl();
  const configuration = useImportExternalItemsTableConfiguration();
  const { importedItems } = useImportExternalItems();

  return (
    <Container>
      <If isTrue={(importedItems?.created ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedItems?.created ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "CREATED_MATERIALS_COUNT" },
                { count: (importedItems?.created ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16"
        />
      </If>
      <If isTrue={(importedItems?.mapped ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedItems?.mapped ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "MATCHED_MATERIALS_COUNT" },
                { count: (importedItems?.mapped ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16 mt-2"
        />
      </If>
      <If isTrue={(importedItems?.skipped ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedItems?.skipped ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "SKIPPED_MATERIALS_COUNT" },
                { count: (importedItems?.skipped ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16 mt-2"
        />
      </If>
    </Container>
  );
};
