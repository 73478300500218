import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { HeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { If } from "@/common/components/if/If";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { ListStatusesFilter } from "@/common/components/list-statuses-filter/ListStatusesFilter";
import { ProjectsFilterSelector } from "@/common/components/projects-filter-selector/ProjectsFilterSelector";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { SearchTooltip } from "@/common/components/search-input/SearchTooltip";
import { VendorsFilterSelector } from "@/common/components/vendors-filter-selector/VendorsFilterSelector";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useProjectListOptions } from "../../projects/hooks/useProjectListOptions";
import { useBuyoutSequence } from "../hooks/useBuyoutSequence";
import {
  BuyoutsFilterSelection,
  useBuyouts,
} from "../providers/BuyoutsProvider";
import { BuyoutsAdditionalFilters } from "./BuyoutsAdditionalFilters";
import { BuyoutSequence } from "./BuyoutSequence";
import { ToggleBuyoutForm } from "./new-buyout/ToggleBuyoutsForm";

const HeaderContainerStyled = tw(HeaderContainer)`
  justify-between
`;

const Filters = tw.div`
  grid grid-flow-col gap-2 items-center
`;

const Actions = tw.div`flex gap-3 items-center`;

export const BuyoutsHeader = () => {
  const intl = useIntl();
  const { filter, setFilter } = useBuyouts();
  const { projects } = useProjectListOptions({
    includeClosedProjects: filter?.closedProjects,
  });
  const { sequenceEnabled } = useBuyoutSequence();

  const options = useMemo(() => {
    return [
      {
        id: BuyoutsFilterSelection.Draft,
        name: intl.$t({ id: "BUYOUTS_DROPDOWN_DRAFT" }),
      },
      {
        id: BuyoutsFilterSelection.Active,
        name: intl.$t({ id: "BUYOUTS_DROPDOWN_ACTIVE" }),
      },
      {
        id: BuyoutsFilterSelection.Requested,
        name: intl.$t({ id: "BUYOUTS_DROPDOWN_REQUESTED" }),
      },
      {
        id: BuyoutsFilterSelection.Depleted,
        name: intl.$t({ id: "BUYOUTS_DROPDOWN_DEPLETED" }),
      },
      {
        id: BuyoutsFilterSelection.Cancelled,
        name: intl.$t({ id: "BUYOUTS_DROPDOWN_CANCELED" }),
      },
    ];
  }, [intl]);

  const isDefaultFilter = useMemo(() => {
    return filter?.deleted === false;
  }, [filter?.deleted]);

  return (
    <HeaderContainerStyled>
      <Filters>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={(value: string) => setFilter({ ...filter, search: value })}
          value={filter?.search}
          info={<SearchTooltip text="BUYOUTS_SEARCH_INFO" />}
        />
        <ProjectsFilterSelector
          projects={projects}
          onChange={(projectId) =>
            setFilter({
              ...filter,
              projectIDs: projectId ? [projectId] : undefined,
            })
          }
          value={filter?.projectIDs?.[0]}
        />
        <VendorsFilterSelector
          value={filter?.sellerOrgLocationIds?.[0]}
          onChange={(value) => {
            setFilter({
              ...filter,
              sellerOrgLocationIds: value ? [value] : undefined,
            });
          }}
        />
        <ListStatusesFilter
          options={options}
          filter={filter}
          setFilter={setFilter}
          values={filter?.statuses || null}
        />
        <ListHeaderFilterButton
          isDefault={isDefaultFilter}
          filter={<BuyoutsAdditionalFilters />}
        />
      </Filters>
      <Actions>
        <BuyoutSequence />
        <If isTrue={!sequenceEnabled}>
          <ToggleBuyoutForm label={intl.$t({ id: "NEW_BUYOUT" })}>
            <PrimaryButton testId="new-buyout-button">
              <FormattedMessage id="NEW_BUYOUT" />
            </PrimaryButton>
          </ToggleBuyoutForm>
        </If>
      </Actions>
    </HeaderContainerStyled>
  );
};
