import {
  WizardModal,
  WizardModalMode,
} from "@/common/components/wizard-modal/WizardModal";
import { useReceiptUploadModal } from "../../providers/UploadReceiptProvider";

export const ReceiptUploadModal = () => {
  const {
    uploadReceiptModalOpened,
    closeUploadReceiptModal,
    uploadReceiptModalPages,
  } = useReceiptUploadModal();

  return (
    <WizardModal
      opened={uploadReceiptModalOpened}
      onClose={closeUploadReceiptModal}
      mode={WizardModalMode.SINGLE_PAGE}
      pages={uploadReceiptModalPages}
    />
  );
};
