import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { useSourceSystemConfig } from "@/common/hooks/integrations/source-system-config/useSourceSystemConfig";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import Decimal from "decimal.js";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceImportExternalPO } from "../../../../providers/InvoiceImportExternalPoProvider";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";

const Container = tw.div`flex flex-col w-full my-4`;
const AggregatedItem = tw.div`grid grid-cols-[3fr_1fr_1fr] lg:grid-cols-[5fr_1fr_1fr] px-3 border border-gray-500 rounded-xl py-2 mt-2 justify-between border-dashed items-center`;
const Label = tw.div`text-2xs text-gray-500`;
const Item = tw.div`flex flex-col justify-start`;
const Title = tw.div`flex items-center gap-2 text-base font-medium`;

export const InvoiceExternalPOCostCodes = () => {
  const { isNonItemizedPO, importedPoItemized, aggregatedItems, externalPo } =
    useInvoiceImportExternalPO();
  const { invoice } = useInvoiceVerification();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();

  const { connectedSourceSystem } = useOrgSettings();
  const { sourceSystemConfig } = useSourceSystemConfig(connectedSourceSystem);
  const intl = useIntl();

  const aggregatedItemsWithAdditionalChargesAndSalesTax = useMemo(() => {
    return [
      ...(aggregatedItems || []),
      {
        externalCostCode: {
          name: intl.$t({ id: "ADDITIONAL_CHARGES" }),
        },
        amount: externalPo?.itemGroups.additionalCharges.reduce(
          (acc, item) => new Decimal(acc).add(item.amount).toNumber(),
          0,
        ),
        invoicedAmount: externalPo?.itemGroups.additionalCharges.reduce(
          (acc, item) =>
            new Decimal(acc).add(item.invoicedAmount || "0").toNumber(),
          0,
        ),
      },
      {
        externalCostCode: {
          name: intl.$t({ id: "SALES_TAX" }),
        },
        amount: externalPo?.itemGroups.salesTax.reduce(
          (acc, item) => new Decimal(acc).add(item.amount).toNumber(),
          0,
        ),
        invoicedAmount: externalPo?.itemGroups.salesTax.reduce(
          (acc, item) =>
            new Decimal(acc).add(item.invoicedAmount || "0").toNumber(),
          0,
        ),
      },
    ];
  }, [
    aggregatedItems,
    externalPo?.itemGroups.additionalCharges,
    externalPo?.itemGroups.salesTax,
    intl,
  ]);

  if (
    !isNonItemizedPO ||
    matchedOrderViewState !== MatchedOrderViewState.IMPORT_ORDER ||
    !invoice?.poExists ||
    !importedPoItemized
  ) {
    return null;
  }

  return (
    <Container>
      <Title>
        <img src={sourceSystemConfig?.logo.small} />
        <FormattedMessage id="ASSIGN_COST_CODES_AND_CATEGORIZE" />
      </Title>
      {aggregatedItemsWithAdditionalChargesAndSalesTax?.map((item, key) => (
        <AggregatedItem key={key}>
          <Item>
            <FormattedMessage id="COST_CODE" tagName={Label} />
            {item.externalCostCode?.name}
          </Item>
          <Item>
            <FormattedMessage id="TOTAL" tagName={Label} />
            <ValueCurrency
              classNames={{ container: "flex-row" }}
              value={item.amount || "0"}
            />
          </Item>
          <Item>
            <FormattedMessage id="ORDER_ITEM_INVOICED" tagName={Label} />
            <ValueCurrency
              classNames={{ container: "flex-row" }}
              value={item.invoicedAmount || "0"}
            />
          </Item>
        </AggregatedItem>
      ))}
    </Container>
  );
};
