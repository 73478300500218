import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ControlledProps } from "../textfield-controlled/TextFieldControlled";
import { Switch, SwitchProps } from "./Switch";

type Props = Omit<SwitchProps, "onChange" | "value"> & ControlledProps;

export const SwitchControlled: FC<Props> = ({ name, rules, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        return <Switch {...field} {...props} />;
      }}
    />
  );
};
