import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { ExportInvoicesPanel } from "../../../scanned-invoices/components/export/ExportInvoicesPanel";

const OutlinedButtonStyled = tw(
  OutlinedButton,
)`text-xs mb-2 my-0 mx-0 px-2 sm:min-h-2`;

type Props = {
  invoiceId: string;
};

export const ExportInvoicesButton: FC<Props> = ({ invoiceId }) => (
  <DrawerPanel
    anchor={(togglePanel) => (
      <OutlinedButtonStyled
        testId="export-multiple-invoices"
        onClick={() => togglePanel(true)}
      >
        <FormattedMessage id="EXPORT_INVOICE" />
      </OutlinedButtonStyled>
    )}
    content={(togglePanel) => (
      <ExportInvoicesPanel
        invoiceIds={[invoiceId]}
        onClose={() => togglePanel(false)}
      />
    )}
  />
);
