import { Price } from "@/common/components/price/Price";
import { MAX_COLUMN_WIDTH } from "@/contractor/pages/home/rfq-quotes/constants";
import {
  QuoteStatus,
  RfqQuotesQuoteFieldsFragment,
  RfqStatus,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useRfqQuotes } from "../../../../providers/RfqQuotesProvider";

type QuoteAuxTotalProps = {
  quote: RfqQuotesQuoteFieldsFragment;
  onClick?: () => void;
  topOffset: number;
};

const QuoteAuxTotalContainer = tw.div`
  sticky z-20 self-start
  grid justify-end items-center text-right font-bold w-full p-2 
  text-xs h-full
  ${({ $deactivated }: { $deactivated: boolean }) =>
    $deactivated && "text-gray-500"}
`;

const TotalItemCount = tw.div`
  text-gray-600 font-normal
`;

export const QuoteAuxTotal: FC<QuoteAuxTotalProps> = ({
  quote,
  onClick,
  topOffset,
}) => {
  const { rfq } = useRfqQuotes();

  const totalPrice = useMemo(() => {
    return quote.auxiliaryItems.reduce((acc, item) => {
      const itemPrice = new Decimal(item.unitPrice || 0).mul(
        item.quantityDecimal || 0,
      );
      return acc.add(itemPrice);
    }, new Decimal(0));
  }, [quote]);

  const isDeactivated = useMemo(() => {
    return (
      (rfq?.status === RfqStatus.Awarded ||
        rfq?.status === RfqStatus.Cancelled) &&
      quote.status !== QuoteStatus.Accepted
    );
  }, [rfq?.status, quote.status]);

  return (
    <QuoteAuxTotalContainer
      onClick={onClick}
      style={{
        top: `${topOffset}px`,
        maxWidth: `${MAX_COLUMN_WIDTH}px`,
      }}
      $deactivated={isDeactivated}
    >
      <Price price={totalPrice} />
      <TotalItemCount>
        <FormattedMessage
          id="PRODUCTS_N"
          values={{
            value: quote.auxiliaryItems.length,
          }}
        />
      </TotalItemCount>
    </QuoteAuxTotalContainer>
  );
};
