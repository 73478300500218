import { If } from "@/common/components/if/If";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import {
  TableViewProvider,
  VIEW_STATE,
  useTableView,
} from "@/common/providers/TableViewProvider";
import { ProjectExtendedFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { UNSPECIFIED_COST_CODE_ID } from "../../../../../common/hooks/useUnspecifiedCostCode";
import { useProjectMaps } from "../hooks/useProjectMaps";
import {
  ProjectItemsZonesProvider,
  useProjectItemsZones,
} from "../providers/ProjectItemsZonesProvider";
import { ProjectTagsProvider } from "../providers/ProjectTagsProvider";
import { ProjectZonesProvider } from "../providers/ProjectZonesProvider";
import {
  SyncEstimatedItemsProvider,
  useSyncEstimatedItems,
} from "../providers/SyncEstimatedItemsProvider";
import { ProjectContentFooter } from "./ProjectFooter";
import { ProjectHeader } from "./project-header/ProjectHeader";
import { ProjectList } from "./project-list/ProjectList";
import { useProjectListItemConfiguration } from "./project-list/ProjectListItem.configuration";

type Props = {
  project: ProjectExtendedFieldsFragment;
  loading?: boolean;
};

const ProjectContentWithProviders: FC<Props> = ({ project, loading }) => {
  const hasZones = useMemo(() => project.zones.length > 0, [project]);
  const { zones } = useProjectItemsZones();
  const readonly = useMemo(() => !!project?.deletedAt, [project]);
  const { estimatedItemsMap } = useProjectMaps(project);
  const { saving } = useSyncEstimatedItems();

  const { tableView } = useTableView();

  const items = useMemo(
    () =>
      zones
        .map((zone) =>
          zone.items.map((code) =>
            code.items.map((item) =>
              item.estimatedItems
                .filter((ei) => estimatedItemsMap.get(ei.id))
                .map((ei) => ({
                  ...item,
                  zone,
                  code:
                    code.id !== UNSPECIFIED_COST_CODE_ID
                      ? code
                      : {
                          ...code,
                          name: "",
                        },
                  ...estimatedItemsMap.get(ei.id),
                })),
            ),
          ),
        )
        .flat(3),
    [zones, estimatedItemsMap],
  );

  const { configuration, spreadsheetConfig } = useProjectListItemConfiguration(
    hasZones,
    readonly,
    items,
  );
  return (
    <>
      <ProjectHeader />
      <If isTrue={tableView === VIEW_STATE.normal}>
        <ProjectList
          project={project}
          loading={loading}
          columns={configuration}
          readonly={readonly}
        />
      </If>
      <If isTrue={tableView === VIEW_STATE.spreadsheet}>
        <SpreadSheetTable
          items={items}
          columns={spreadsheetConfig}
          saving={saving}
          height="calc(100vh - 350px)"
        />
      </If>
      <ProjectContentFooter />
    </>
  );
};

export const ProjectContent: FC<Props> = ({ project, loading }) => {
  const { spreadsheetConfig } = useProjectListItemConfiguration(true, true);

  return (
    <ProjectItemsZonesProvider project={project}>
      <ProjectZonesProvider>
        <ProjectTagsProvider id={project.id}>
          <ColumnMapperProvider config={spreadsheetConfig}>
            <TableViewProvider>
              <SyncEstimatedItemsProvider>
                <ProjectContentWithProviders
                  project={project}
                  loading={loading}
                />
              </SyncEstimatedItemsProvider>
            </TableViewProvider>
          </ColumnMapperProvider>
        </ProjectTagsProvider>
      </ProjectZonesProvider>
    </ProjectItemsZonesProvider>
  );
};
