import { useIntegrationTypeConfig } from "@/common/hooks/integrations/integration-type-config/useIntegrationConfig";
import { useSourceSystemConfig } from "@/common/hooks/integrations/source-system-config/useSourceSystemConfig";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { IntegrationType, SourceSystem } from "@/generated/graphql";
import { FC, PropsWithChildren, useMemo } from "react";
import { If } from "../if/If";

type Props = PropsWithChildren<{
  feature: IntegrationFeature;
  sourceSystem?: SourceSystem;
  integrationType?: IntegrationType;
}>;

export const IntegrationFeatureRequirement: FC<Props> = ({
  feature,
  sourceSystem,
  integrationType,
  children,
}) => {
  const { getSourceSystemConfig } = useSourceSystemConfig();
  const { getIntegrationConfig } = useIntegrationTypeConfig();
  const features = useMemo(() => {
    if (sourceSystem) {
      return getSourceSystemConfig(sourceSystem, true).features;
    }
    if (integrationType) {
      return getIntegrationConfig(integrationType).features;
    }
    return [];
  }, [
    sourceSystem,
    integrationType,
    getSourceSystemConfig,
    getIntegrationConfig,
  ]);

  return <If isTrue={features.includes(feature)}>{children}</If>;
};
