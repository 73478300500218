import { If } from "@/common/components/if/If";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { ReleasePartialFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-row gap-1 items-center`;
const TextContainer = tw.div`flex flex-col text-2xs`;
const Text = tw.div``;

type Props = {
  release: Pick<
    ReleasePartialFieldsFragment,
    "status" | "id" | "exportedAt" | "poLink"
  >;
};

export const ExportReleaseIcon: FC<Props> = ({ release }) => {
  const isIncluded = useMemo(
    () => release.exportedAt || release.poLink?.syncedAt,
    [release.exportedAt, release.poLink?.syncedAt],
  );

  return (
    <If isTrue={isIncluded}>
      <Container>
        <TextContainer>
          <Text>
            <FormattedMessage id="EXPORTED" />:
          </Text>
          <DateView
            date={release.exportedAt || release.poLink?.syncedAt}
            options={SHORT_DATE_OPTION}
          />
        </TextContainer>
      </Container>
    </If>
  );
};
