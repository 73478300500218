import { QuantityPicker } from "@/common/components/quantity-picker/QuantityPicker";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { RfqItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { useRfq } from "../../hooks/useRfq";
import { useRfqGroupedItems } from "../../hooks/useRfqGroupedItems";

type SheetsInputProps = {
  item: RfqItemFieldsFragment;
  readonly: boolean;
  error?: boolean;
};

export const RfqQuantityInput: FC<SheetsInputProps> = ({
  item,
  readonly,
  error = false,
}) => {
  const intl = useIntl();
  const { updateRfq, rfq } = useRfq();
  const { newRfqItem, setNewRfqItem } = useRfqGroupedItems();
  const { setWarningAlert } = useSnackbar();

  const updateValue = useCallback(
    async (value: string | null) => {
      if (newRfqItem?.isAddMode) {
        setNewRfqItem({
          ...newRfqItem,
          quantityDecimal: value,
        });
      } else if (value === "0") {
        setWarningAlert(
          intl.$t({
            id: "CONTRACTOR_RFQ_VALIDATION_ERROR_SHEETS_ZERO",
          }),
        );
      } else {
        await updateRfq({
          rfqId: rfq?.id || "",
          updates: [{ rfqItemId: item.id, quantityDecimal: value }],
        });
      }
    },
    [intl, item.id, newRfqItem, setNewRfqItem, setWarningAlert, updateRfq, rfq],
  );

  return (
    <QuantityPicker
      quantity={item.quantityDecimal}
      id={item.id}
      saveQuantity={updateValue}
      staticText={readonly}
      resetIfZero
      error={error && item.quantityDecimal === "0"}
    />
  );
};
