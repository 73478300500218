import { DeleteButton } from "@/common/components/button/DeleteButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { OrgLocationExtendedFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { useLocations } from "../providers/LocationsProvider";

type Props = {
  location: OrgLocationExtendedFieldsFragment;
};

export const DeleteLocation: FC<Props> = ({ location }) => {
  const { openDialog } = useDialog();
  const { removeLocation } = useLocations();
  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();
  const handleDelete = useCallback(async () => {
    if (await removeLocation(location.id)) {
      setSuccessAlert(
        intl.$t(
          { id: "LOCATION_REMOVE_SUCCESS" },
          {
            name: strongify(location.name),
          },
        ),
      );
    }
  }, [removeLocation, location.id, location.name, setSuccessAlert, intl]);

  return (
    <ButtonsContainer>
      <DeleteButton
        onClick={() =>
          openDialog({
            cancelButtonText: intl.$t({ id: "CANCEL" }),
            confirmButtonText: intl.$t({ id: "PROCEED" }),
            icon: <WarningIcon />,
            title: intl.$t({ id: "LOCATION_REMOVE_LOCATION_QUESTION" }),
            text: intl.$t({ id: "LOCATION_REMOVE_LOCATION_TEXT" }),
            handleConfirm: handleDelete,
          })
        }
      />
    </ButtonsContainer>
  );
};
