import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import {
  WizardModalMode,
  WizardModalPage,
} from "@/common/components/wizard-modal/WizardModal";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useAgaveHostedWizard } from "../../../AgaveHostedWizardProvider";
import { AgaveWizardPhaseCodes } from "../../../steps/phase-codes/AgaveWizardPhaseCodes";

export const useGlobalPhaseCodesPage = () => {
  const intl = useIntl();
  const { moveToPreviousStep } = useNestedStepper();
  const { wbsTags, wizardMode } = useAgaveHostedWizard();

  const globalPhaseCodesPage: WizardModalPage = useMemo(
    () => ({
      feature: IntegrationFeature.GlobalPhaseCodesStep,
      title: intl.$t({ id: "PHASE_CODES" }),
      completed: !!wbsTags,
      pages: [
        {
          component: <AgaveWizardPhaseCodes />,
          onPreviousClick: moveToPreviousStep,
          onPreviousLabel: intl.$t({ id: "CANCEL" }),
          onCloseClickDisabled:
            wizardMode === WizardModalMode.SINGLE_PAGE && !wbsTags,
          hideFooter: wizardMode === WizardModalMode.SINGLE_PAGE,
        },
      ],
    }),
    [intl, moveToPreviousStep, wbsTags, wizardMode],
  );

  return globalPhaseCodesPage;
};
