import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { BuyoutsBuyoutFieldsFragment } from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";

type DeliveredVsTotalProps = {
  buyout: BuyoutsBuyoutFieldsFragment;
};

const DeliveredVsTotalContainer = tw.div`flex text-sm`;

const Requested = tw.span`
  font-bold
`;

export const DeliveredVsTotal: FC<DeliveredVsTotalProps> = ({ buyout }) => {
  const ordered = useMemo(() => {
    return new Decimal(buyout.completedAmount)
      .plus(buyout.requestedAmount)
      .plus(buyout.scheduledAmount);
  }, [buyout]);

  return (
    <DeliveredVsTotalContainer>
      <Requested>
        <ValueCurrency value={ordered.toNumber()} fractionDigits={0} />
      </Requested>{" "}
      / <ValueCurrency value={buyout.amount} fractionDigits={0} />
    </DeliveredVsTotalContainer>
  );
};
