import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useNotifications } from "../providers/NotificationsProvider";
import { NotificationTabs } from "./common/NotificationTabs";
import { ExternalNotifications } from "./external/ExternalNotifications";
import { InternalNotifications } from "./internal/InternalNotifications";

type NotificationsListProps = {
  onClose: () => void;
};

const NotificationsListPropsWithProvider: FC<NotificationsListProps> = ({
  onClose,
}) => {
  const intl = useIntl();
  const { isInternalTabActive } = useNotifications();

  return (
    <OverlayPanel title={intl.$t({ id: "NOTIFICATIONS" })} onCancel={onClose}>
      <NotificationTabs />
      {isInternalTabActive ? (
        <InternalNotifications onClose={onClose} />
      ) : (
        <ExternalNotifications />
      )}
    </OverlayPanel>
  );
};

export const NotificationsList: FC<NotificationsListProps> = ({ onClose }) => (
  <NotificationsListPropsWithProvider onClose={onClose} />
);
