import { useCallback } from "react";
import { useStepperStore } from "./useStepperStore";

export const useStepper = () => {
  const { step, setStep } = useStepperStore();

  const moveToPreviousStep = useCallback(
    () => setStep(step - 1),
    [setStep, step],
  );
  const moveToNextStep = useCallback(() => setStep(step + 1), [setStep, step]);
  const moveToFirstStep = useCallback(() => setStep(0), [setStep]);
  const resetStep = useCallback(() => setStep(0), [setStep]);

  return {
    step,
    setStep,
    moveToPreviousStep,
    moveToNextStep,
    moveToFirstStep,
    resetStep,
  };
};
