import {
  ItemContainer,
  TextFieldContainer,
} from "@/common/components/additional-charges/AdditionalCharges.styles";
import { If } from "@/common/components/if/If";
import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { StyledNumericInput } from "@/common/components/styled-numeric-input/StyledNumericInput";
import { InnerLabeledSwitch } from "@/common/components/switch/InnerLabeledSwitch";
import { useUser } from "@/common/providers/UserProvider";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";

type DiscountProps = {
  readonly: boolean;
};

const Item = tw.div`flex flex-row gap-2 justify-evenly`;
const Label = tw.div`flex flex-col font-medium flex-1`;
const SmallLabel = tw.span`font-normal text-sm`;

export const DistributorQuoteDiscounts: FC<DiscountProps> = ({ readonly }) => {
  const { currencySymbol } = useUser();
  const { updateQuote, quote } = useDistributorQuote();
  const [toggleValue, setToggleValue] = useState<boolean>(
    !quote?.discount?.percentage,
  );
  const [amount, setAmount] = useState(
    quote?.discount?.amount ? Number(quote?.discount?.amount) : null,
  );
  const [percentage, setPercentage] = useState(
    quote?.discount?.percentage ? Number(quote?.discount?.percentage) : null,
  );
  const intl = useIntl();

  const setDiscount = useCallback((toggle: boolean, value: number) => {
    if (!toggle) {
      setAmount(null);
      setPercentage(value);
    } else {
      setAmount(value);
      setPercentage(null);
    }
  }, []);

  const handleBlur = useCallback(async () => {
    await updateQuote({
      discount: {
        amount: amount ? amount.toString() : null,
        percentage: percentage ? percentage.toString() : null,
        clear: !amount && !percentage,
      },
    });
  }, [updateQuote, amount, percentage]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(event.target.value);
      setDiscount(toggleValue, newValue);
    },
    [setDiscount, toggleValue],
  );

  const updateToggle = useCallback(
    (value: boolean) => {
      setToggleValue(value);
      setDiscount(value, 0);
    },
    [setDiscount],
  );

  return (
    <ItemContainer>
      <Item>
        <Label>
          <FormattedMessage id="DISTRIBUTOR_QUOTE_QUOTE_DISCOUNT" />{" "}
          <SmallLabel>
            <FormattedMessage id="OPTIONAL_WITH_BRACKETS" />
          </SmallLabel>
        </Label>
        <If isTrue={!readonly}>
          <InnerLabeledSwitch
            initialValue={toggleValue}
            offLabel={intl.$t({
              id: "DISTRIBUTOR_QUOTE_QUOTE_DISCOUNT_PERCENTAGE",
            })}
            toggle={updateToggle}
            onLabel={intl.$t({
              id: "AMOUNT",
            })}
            width={100}
          />
        </If>
      </Item>
      <TextFieldContainer>
        {!readonly ? (
          <StyledNumericInput
            id="discount-input"
            value={amount || percentage || 0}
            onChange={handleChange}
            onBlur={handleBlur}
            label={!readonly ? (!toggleValue ? "%" : currencySymbol) : ""}
            suffix={readonly ? (!toggleValue ? "%" : "") : ""}
            prefix={readonly ? (!toggleValue ? "" : currencySymbol) : ""}
            classes={{ root: "text-right" }}
            testId="discount-input"
          />
        ) : (
          <PricePicker
            readonly
            className="text-right text-sm"
            value={(amount || percentage || 50).toString()}
          />
        )}
      </TextFieldContainer>
    </ItemContainer>
  );
};
