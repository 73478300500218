import { MANUFACTURER_FIELDS } from "@/common/fragments/manufacturerFragment";
import { UOM_FIELDS } from "@/common/fragments/uomFragment";
import { gql } from "@apollo/client";

export const PROJECT_MATERIAL_ORG_CATALOG_SKU_FIELDS = gql`
  ${UOM_FIELDS}
  ${MANUFACTURER_FIELDS}
  fragment ProjectMaterialOrgCatalogSkuFields on OrgCatalogSku {
    id
    name
    customPartNumber
    sectionName
    defaultUom {
      ...UOMFields
    }
    defaultManufacturer {
      ...ManufacturerFields
    }
  }
`;
