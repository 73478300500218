import {
  FooterCell,
  FooterCellCol,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterItemInputContainer,
  FooterProps,
  FooterTotal,
} from "@/common/components/grid-table/styles/Footer.styles";
import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useBuyoutGrouped } from "../../non-quoted/providers/BuyoutGroupedProvider";
import { BuyoutInlineAllowance } from "./BuyoutInlineAllowance";
import { BuyoutInlineSalesTax } from "./BuyoutInlineSalesTax";

type Props = FooterProps & {
  includeTaxes?: boolean;
};

const marginRightClass = "pr-4";

export const BuyoutTotalFooter: FC<Props> = ({ Wrapper, includeTaxes }) => {
  const { salesTax, subtotal, total } = useBuyoutGrouped();

  return (
    <FooterCol>
      <Wrapper className="flex-col items-end justify-end">
        <FooterItemInputContainer>
          <If isTrue={includeTaxes}>
            <BuyoutInlineAllowance />
          </If>
        </FooterItemInputContainer>
        <FooterItemInputContainer>
          <If isTrue={includeTaxes}>
            <BuyoutInlineSalesTax />
          </If>
        </FooterItemInputContainer>
        <FooterHeader className={marginRightClass}>
          <FormattedMessage id="TOTAL" tagName={FooterHeaderText} />
        </FooterHeader>
        <FooterCell className={marginRightClass}>
          <Price
            price={subtotal}
            className="font-light"
            testId="totalSubtotal"
          />
        </FooterCell>
        <FooterCell className={marginRightClass}>
          <NotNullableRenderer value={salesTax}>
            <Price
              price={salesTax}
              className="font-light"
              testId="totalSalesTax"
            />
          </NotNullableRenderer>
        </FooterCell>
        <FooterDashedLine />
        <FooterTotal className="pr-0">
          <FooterCellCol className={marginRightClass}>
            <Price price={total} testId="totalTotal" />
          </FooterCellCol>
        </FooterTotal>
      </Wrapper>
    </FooterCol>
  );
};
