import { If } from "@/common/components/if/If";
import { List, ListItem, ListItemText } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  addedVendors: string[];
  removedVendors: string[];
  rfqNumber: string;
};

const Container = tw.div``;
const VendorsWrapper = tw.div`pt-2`;
const MessageWrapper = tw.div`text-start`;
const ListItemStyled = tw(ListItem)`list-item p-0`;

const Vendors: FC<{ data: string[] }> = ({ data }) => {
  return (
    <List sx={{ listStyleType: "disc", pl: 4 }}>
      {data.map((vendor, index) => (
        <ListItemStyled key={index}>
          <ListItemText>{vendor}</ListItemText>
        </ListItemStyled>
      ))}
    </List>
  );
};

export const RfqFormSuccessModal: FC<Props> = ({
  addedVendors,
  removedVendors,
  rfqNumber,
}) => {
  return (
    <Container>
      <MessageWrapper>
        <FormattedMessage
          id="RFQ_EDIT_SUCCESS"
          tagName="div"
          values={{ rfqNumber }}
        />
      </MessageWrapper>
      <VendorsWrapper>
        <If isTrue={addedVendors.length > 0}>
          <MessageWrapper>
            <FormattedMessage id="RFQ_VENDORS_ADDED" tagName="div" />
          </MessageWrapper>
          <Vendors data={addedVendors} />
        </If>
        <If isTrue={removedVendors.length > 0}>
          <MessageWrapper>
            <FormattedMessage id="RFQ_VENDORS_REMOVED" tagName="div" />
          </MessageWrapper>
          <Vendors data={removedVendors} />
        </If>
      </VendorsWrapper>
    </Container>
  );
};
