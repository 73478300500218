import { BaseRfqItemType } from "@/contractor/pages/home/rfq/BaseRfqItemType";
import { Sku } from "@/generated/graphql";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { isMasterSku, isOrgCatalogSku } from "../material/utils";
import { ProductBrand } from "../product/ProductBrand";
import { RfqProductInfo } from "./RfqProductInfo";

type Props = {
  item?: BaseRfqItemType | null;
  className?: string;
  includeInfo?: boolean;
};

const RfqProductBrandContainer = tw.div`grid grid-flow-col justify-start items-baseline gap-2 relative`;

export const RfqProductBrand: FC<Props> = ({
  item,
  className,
  includeInfo,
}) => {
  const sku = useMemo(
    () =>
      isMasterSku(item?.projectItem?.material.material)
        ? (item?.projectItem?.material.material as Sku)
        : null,
    [item],
  );

  return (
    <>
      {isOrgCatalogSku(item?.projectItem?.material.material) ? null : (
        <RfqProductBrandContainer>
          <ProductBrand
            className={className}
            brand={sku ? sku?.manufacturer?.name : null}
          />
          <If isTrue={includeInfo && sku && sku.description}>
            <RfqProductInfo item={item} />
          </If>
        </RfqProductBrandContainer>
      )}
    </>
  );
};
