import { useIntl } from "react-intl";
import { If } from "../if/If";
import { SelectControlled } from "../select/components/single/SelectControlled";
import { useOrderTypeOptions } from "./hooks/useOrderTypeOptions";

type Props = {
  name: string;
  required?: boolean;
  disabled?: boolean;
};

export const OrderTypePickerControlled = ({
  name,
  required = false,
  disabled,
}: Props) => {
  const intl = useIntl();
  const { orderTypes, loading, includeOrderTypes } = useOrderTypeOptions();

  return (
    <If isTrue={includeOrderTypes}>
      <SelectControlled
        name={name}
        className="w-full"
        testId="form-order-type-selector"
        label={intl.$t({ id: "ORDER_TYPE" })}
        options={orderTypes}
        loading={loading}
        getLabel={(option) => option.name}
        getValue={(option) => option.id}
        rules={{ required }}
        disabled={disabled}
      />
    </If>
  );
};
