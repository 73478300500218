import { DEFAULT_CURRENCY } from "@/common/const";
import { useUser } from "@/common/providers/UserProvider";
import Decimal from "decimal.js";
import { useCallback } from "react";
import { FormatNumberOptions, useIntl } from "react-intl";

export const useFormatNumberToCurrency = () => {
  const { currency } = useUser();
  const intl = useIntl();

  const formatCurrency = useCallback(
    (
      value: Decimal | string | number | undefined | null,
      options: FormatNumberOptions = { maximumFractionDigits: 2 },
    ) => {
      return intl.formatNumber(Number(value), {
        style: "currency",
        currencyDisplay: "narrowSymbol",
        currency: currency?.code || DEFAULT_CURRENCY,
        maximumFractionDigits: options.maximumFractionDigits,
        minimumFractionDigits: options.minimumFractionDigits,
      });
    },
    [currency?.code, intl],
  );

  return { formatCurrency };
};
