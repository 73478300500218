import {
  CostCodeFieldsFragment,
  DistributorQuoteItemGroupRfqItemFieldsFragment,
} from "@/generated/graphql";

export const getCostCodesByDistributorRfqItems = (
  items: DistributorQuoteItemGroupRfqItemFieldsFragment[],
  unassignedCostCode: Omit<CostCodeFieldsFragment, "mappings">,
) => {
  const costCodes: Omit<CostCodeFieldsFragment, "mappings" | "inUse">[] = [];
  items.forEach((item) => {
    if (item?.costCode && !costCodes.find((z) => z.id === item?.costCode?.id)) {
      costCodes.push(item.costCode);
    }
  });
  costCodes.sort((a, b) => a.code.localeCompare(b.code));

  if (items.some((projectItem) => !projectItem?.costCode)) {
    costCodes.push(unassignedCostCode);
  }
  return costCodes;
};
