import { ErrorOutline } from "@mui/icons-material";
import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";
import tw from "tailwind-styled-components";
import { TextField, TextFieldProps } from "../textfield/TextField";
import { Tooltip } from "../tooltip/Tooltip";

export type ControlledProps = {
  name: string;
  rules?: RegisterOptions<FieldValues>;
  disabled?: boolean;
};

export type TextFieldControlledProps = TextFieldProps & ControlledProps;

const ErrorContainer = tw.span`absolute top-2.5 right-2`;
const ErrorOutlineStyled = tw(ErrorOutline)`
  print:hidden text-base z-50
`;

export const TextFieldControlled = ({
  name,
  rules,
  ...props
}: TextFieldControlledProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          {...props}
          required={!!rules?.required}
          error={!!fieldState.invalid}
          className={`w-full rounded-md bg-white ${props.className || ""} ${
            props.staticText && "ml-3"
          }`}
          inputProps={{
            ...props.inputProps,
            className: fieldState.invalid
              ? `${props.inputProps?.className ? `${props.inputProps?.className} ` : ""}pr-6`
              : props.inputProps?.className,
          }}
          helperText={
            fieldState.invalid &&
            fieldState.error?.message && (
              <ErrorContainer>
                <Tooltip
                  element={<ErrorOutlineStyled color="error" />}
                  id="error-popover"
                >
                  {fieldState.error?.message || ""}
                </Tooltip>
              </ErrorContainer>
            )
          }
          FormHelperTextProps={{ className: "m-0" }}
          testId={name}
        />
      )}
    />
  );
};
