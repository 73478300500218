import { Popper, PopperProps } from "@mui/material";

export const CustomPopper = (props: PopperProps) => {
  return (
    <Popper
      {...props}
      style={{ maxWidth: "fit-content" }}
      placement="bottom-start"
    />
  );
};
