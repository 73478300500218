import { PDF_FONT, TOTAL_PRICE_DECIMAL_POINTS } from "@/common/const";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { IntlShape } from "react-intl";

type ReportType = {
  allowance?: string | null | undefined;
  estimated?: string | null | undefined;
  ordered?: string | null | undefined;
  invoiced?: string | null | undefined;
};

const EMPTY_STRING = "-";

const formatCurrency = (
  value: string | null | undefined,
  intl: IntlShape,
  currency: string,
) => {
  return (
    intl.formatNumber(Number(value), {
      minimumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
      maximumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
      currency,
      style: "currency",
    }) || EMPTY_STRING
  );
};

export const budgetReportHeader = (
  doc: jsPDF,
  intl: IntlShape,
  currency: string,
  report?: ReportType | null,
) => {
  autoTable(doc, {
    startY: 20,
    theme: "plain",
    showHead: false,
    styles: {
      font: PDF_FONT,
    },
    columnStyles: {
      0: { cellWidth: 50 },
      1: { cellWidth: 50 },
      2: { cellWidth: 50 },
    },
    body: [
      [
        {
          content: intl.$t({ id: "PROJECT_BUDGET_HEADER" }),
          styles: { fontStyle: "bold" },
        },
        {
          content: intl.$t({ id: "PROJECT_BUDGET_ORDERED_HEADER" }),
          styles: { fontStyle: "bold" },
        },
        {
          content: intl.$t({ id: "PROJECT_BUDGET_INVOICED_HEADER" }),
          styles: { fontStyle: "bold" },
        },
      ],
      [
        {
          content: formatCurrency(
            report?.allowance || report?.estimated,
            intl,
            currency,
          ),
          styles: { fontStyle: "bold" },
        },
        {
          content: formatCurrency(report?.ordered, intl, currency),
          styles: { fontStyle: "bold" },
        },
        {
          content: formatCurrency(report?.ordered, intl, currency),
          styles: { fontStyle: "bold" },
        },
      ],
    ],
  });
};
