import { If } from "@/common/components/if/If";
import { ProjectFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const ProjectWrapper = tw.div`col-span-2`;
const ProjectName = tw.div`text-sm font-medium`;
const JobNumber = tw.div`text-xs text-gray-600`;

type Props = {
  project: Pick<ProjectFieldsFragment, "name" | "jobNumber"> | null | undefined;
  classes?: {
    projectName?: string;
  };
};

export const ProjectNameListDetails: FC<Props> = ({ project, classes }) => {
  return (
    <ProjectWrapper>
      <ProjectName className={classes?.projectName}>
        {project?.name}
      </ProjectName>
      <If isTrue={project?.jobNumber}>
        <JobNumber>
          <FormattedMessage
            id="JOB_NUMBER_WITH_NO"
            values={{
              jobNumber: project?.jobNumber,
            }}
          />
        </JobNumber>
      </If>
    </ProjectWrapper>
  );
};
