import { gql } from "@apollo/client";
import { ASSET_FIELDS } from "./assetFragment";

export const RELEASE_DELIVERY_SLIP_FIELDS = gql`
  ${ASSET_FIELDS}
  fragment ReleaseDeliverySlipFields on DeliverySlip {
    id
    status
    createdBy {
      id
      firstName
      lastName
    }
    createdAt
    reviewedAt
    archivedAt
    asset {
      ...AssetFields
    }
  }
`;
