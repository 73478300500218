import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useUser } from "@/common/providers/UserProvider";
import { Circle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ViewerProfileForm } from "../profile/ViewerProfileForm";

const Container = tw.div`ml-auto`;
const ButtonStyled = tw(Button)`text-white border-white text-2xs font-normal`;
const Dot = tw(Circle)`text-xs flex items-center mr-1
    ${({ $enabled }: { $enabled?: boolean }) =>
      $enabled ? "text-green-600" : "text-red-500"}
`;

export const TextNotificationsButton = () => {
  const { viewer } = useUser();
  const { openDialog } = useDialog();
  const intl = useIntl();
  const onClick = useCallback(() => {
    openDialog({
      title: intl.$t({ id: "EDIT_YOUR_PROFILE" }),
      text: <ViewerProfileForm />,
    });
  }, [intl, openDialog]);

  return (
    <Container>
      <ButtonStyled variant="outlined" size="small" onClick={onClick}>
        <Dot $enabled={viewer?.getSmsNotifications} />
        <FormattedMessage
          id={
            viewer?.getSmsNotifications
              ? "TEXT_NOTIFICATIONS_ENABLED"
              : "TEXT_NOTIFICATIONS_DISABLED"
          }
        />
      </ButtonStyled>
    </Container>
  );
};
