import { GridTable } from "@/common/components/grid-table/GridTable";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { QUERYSTRING } from "@/common/const";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { routes } from "@/config/routes";
import {
  AuthorizationStatus,
  ReleasePartialFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import {
  createSearchParams,
  generatePath,
  useNavigate,
} from "react-router-dom";
import { useReleaseNavigation } from "../hooks/useReleaseNavigation";
import { useDeliveries } from "../providers/DeliveriesProvider";
import { useReleaseSequence } from "../providers/ReleaseSequenceProvider";
import { useDeliveriesConfiguration } from "./Deliveries.configuration";

type Props = {
  deliveries: ReleasePartialFieldsFragment[];
  loading: boolean;
  totalCount: number;
  isFiltered: boolean;
};

export const DeliveriesList: FC<Props> = ({
  deliveries,
  loading,
  totalCount,
  isFiltered,
}) => {
  const navigate = useNavigate();
  const { exportEnabled } = useDeliveries();
  const { selectedReleases, setSelectedReleases, sequenceIds } =
    useReleaseSequence();
  const { getReleaseEditPath } = useReleaseNavigation();

  const onClick = useCallback(
    (item: ReleasePartialFieldsFragment) => {
      if (exportEnabled) {
        const isIncluded = sequenceIds.includes(item.id);
        setSelectedReleases(
          !isIncluded
            ? [...selectedReleases, item]
            : selectedReleases.filter((d) => d.id !== item.id),
        );
      } else {
        if (item.status === ReleaseStatus.Draft && item.deliverySlips.length) {
          navigate({
            pathname: generatePath(routes.deliverySlipReceiveOrder, {
              deliverySlipId: item.deliverySlips[0].id,
            }),
            search: createSearchParams({
              [QUERYSTRING.SPREADSHEET_VIEW]:
                item?.poNumber && item?.status === ReleaseStatus.Draft
                  ? "true"
                  : "false",
            }).toString(),
          });
        } else {
          let path = routes.delivery;
          if (
            checkReleaseStatus(item, [
              ReleaseStatus.Draft,
              ReleaseStatus.AwaitingApproval,
              ReleaseStatus.Rejected,
            ]) &&
            item.permissions.submit === AuthorizationStatus.Authorized
          ) {
            path = getReleaseEditPath(item);
          }
          navigate(
            generatePath(path, {
              deliveryId: item.id,
              noteDocumentId: item.noteDocument?.id,
              quoteDocumentId: item.quoteDocument?.id,
            }),
          );
        }
      }
    },
    [
      exportEnabled,
      sequenceIds,
      setSelectedReleases,
      selectedReleases,
      navigate,
      getReleaseEditPath,
    ],
  );

  const itemFn = useCallback(
    (item: ReleasePartialFieldsFragment) => {
      if (exportEnabled && sequenceIds.includes(item.id)) {
        return { className: "bg-green-300 hover:bg-green-300" };
      }
      return { className: "" };
    },
    [exportEnabled, sequenceIds],
  );

  const configuration = useDeliveriesConfiguration({
    includeCheckbox: exportEnabled,
  });

  return (
    <ListRenderer totalCount={totalCount} count={deliveries.length} paginated>
      <GridTable
        configuration={{
          columns: configuration,
          classNames: {
            itemContent: "grid-cols-[50px_repeat(5,_auto)]",
            header: "top-[143px] bg-transparent",
            root: "pt-2",
            itemFn,
          },
          toggle: {
            item: onClick,
          },
          hasCardItems: true,
        }}
        items={deliveries}
        loading={loading}
        emptyList={
          <NoResults
            isFiltered={isFiltered}
            filteredTranslationKey="DELIVERIES_LIST_NO_DELIVERIES_FILTERED"
            translationKey="DELIVERIES_LIST_NO_DELIVERIES"
          />
        }
      />
    </ListRenderer>
  );
};
