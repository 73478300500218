import { useUploadAssets } from "@/common/components/upload-asset/UploadAssetProvider";
import {
  PDF_EXTENSIONS,
  PDF_MIME_TYPE,
} from "@/common/components/upload/FileUploadArea";
import { WizardModalFooterButton } from "@/common/components/wizard-modal/components/WizardModalFooterButton";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { AssetUploadStep } from "../../../../common/components/wizard-modal/components/AssetUploadStep";
import { useDistributorInvoice } from "./DistributorInvoiceProvider";

type ProviderContextType = {
  uploadInvoiceModalPages: WizardModalPage[];
  uploadInvoiceModalOpened: boolean;
  openUploadInvoiceModal: () => void;
  closeUploadInvoiceModal: () => void;
};

const ProviderContext = createContext<ProviderContextType>({
  uploadInvoiceModalPages: [],
  uploadInvoiceModalOpened: false,
  openUploadInvoiceModal: NoFunction,
  closeUploadInvoiceModal: NoFunction,
});

export const UploadInvoiceModalProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const { assets, removeAsset } = useUploadAssets();
  const { invoice, correctInvoiceDocument, correctingInvoice } =
    useDistributorInvoice();

  const [uploadInvoiceModalOpened, setUploadInvoiceModalOpened] =
    useState(false);

  const uploadInvoiceModalPages: WizardModalPage[] = useMemo(() => {
    return [
      {
        title: null,
        pages: [
          {
            component: (
              <AssetUploadStep
                accept={{
                  [PDF_MIME_TYPE]: PDF_EXTENSIONS,
                }}
                description={intl.$t({ id: "PROVIDE_CORRECTED_INVOICE" })}
                supportedFormats={intl.$t({ id: "INVOICE_SUPPORTED_FORMATS" })}
                limit={1}
                centered
              />
            ),
            footerButtonsConfig: [
              {
                text: intl.$t({ id: "CLOSE" }),
                onClick: async () => {
                  if (invoice && assets.length > 0) {
                    await correctInvoiceDocument({
                      invoiceId: invoice.id,
                      assetUrl: assets[0].url,
                    });
                  }
                  setUploadInvoiceModalOpened(false);
                },
                loading: correctingInvoice,
                type: "outlined",
              },
            ] as WizardModalFooterButton[],
          },
        ],
      },
    ];
  }, [intl, correctingInvoice, invoice, assets, correctInvoiceDocument]);

  const closeUploadInvoiceModal = useCallback(() => {
    setUploadInvoiceModalOpened(false);
    assets.forEach(removeAsset);
  }, [assets, removeAsset]);

  return (
    <ProviderContext.Provider
      value={{
        uploadInvoiceModalOpened,
        uploadInvoiceModalPages,
        openUploadInvoiceModal: () => setUploadInvoiceModalOpened(true),
        closeUploadInvoiceModal,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useUploadInvoiceModal = (): ProviderContextType =>
  useContext(ProviderContext);
