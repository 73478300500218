import { UploadAsset } from "@/common/components/upload-asset/UploadAsset";
import { useUploadAssets } from "@/common/components/upload-asset/UploadAssetProvider";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { ItemLabel } from "../panel/DrawerPanel.styles";
import { ATTACHMENTS_MIME_TYPES } from "../upload/FileUploadArea";

const Container = tw.div``;

type Props = { readonly?: boolean };

export const AdditionalFiles: FC<Props> = ({ readonly }) => {
  const { assets } = useUploadAssets();

  return (
    <If isTrue={!readonly || assets?.length > 0}>
      <Container>
        <FormattedMessage
          id={readonly ? "ADDITIONAL_FILES" : "ADD_ADDITIONAL_FILES"}
          tagName={ItemLabel}
        />
        <UploadAsset readonly={readonly} accept={ATTACHMENTS_MIME_TYPES} />
      </Container>
    </If>
  );
};
