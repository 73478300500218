import { If } from "@/common/components/if/If";
import { checkQuoteStatus } from "@/common/utils/status-checks/checkQuoteStatus";
import {
  DistributorQuoteCommonFieldsFragment,
  QuoteStatus,
} from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const StyledLink = tw.a`
  text-blue-500 font-normal hover:text-blue-300 ml-2 mr-2
`;

type DistributorQuoteHeaderDetailsProps = {
  quote: DistributorQuoteCommonFieldsFragment;
};

const StatusContainer = tw.div`
  ml-2 flex items-center justify-center h-6 min-w-32 p-2 
  text-sm font-normal rounded-md capitalize border
`;

export const DistributorQuoteHeaderDetails: FC<
  DistributorQuoteHeaderDetailsProps
> = ({ quote }) => {
  const intl = useIntl();
  const getText = useCallback(
    (styled = false) => {
      if (
        checkQuoteStatus(quote, [
          QuoteStatus.CancelledByContractor,
          QuoteStatus.Withdrawn,
        ])
      ) {
        if (quote.status === QuoteStatus.CancelledByContractor) {
          return intl.$t({ id: "CANCELED" });
        }
        return quote.status.toLowerCase();
      }
      if (quote.previousRevision) {
        const identifier = `#${quote.previousRevision?.clientIdentifier}`;
        return intl.$t(
          { id: "RFQ_BASED_ON" },
          {
            number: styled ? (
              <StyledLink href={quote.previousRevision?.url} target="_blank">
                {identifier}
              </StyledLink>
            ) : (
              identifier
            ),
          },
        );
      }
      if (quote.nextRevision) {
        const identifier = `#${quote.nextRevision?.clientIdentifier}`;
        return intl.$t(
          { id: "RFQ_SUPERSEDED_BY" },
          {
            number: styled ? (
              <StyledLink href={quote.nextRevision?.url} target="_blank">
                {identifier}
              </StyledLink>
            ) : (
              identifier
            ),
          },
        );
      }
    },
    [intl, quote],
  );
  const styledText = useMemo(() => {
    return getText(true);
  }, [getText]);

  return (
    <If
      isTrue={
        checkQuoteStatus(quote, [
          QuoteStatus.CancelledByContractor,
          QuoteStatus.Withdrawn,
        ]) ||
        !!quote.nextRevision ||
        !!quote.previousRevision
      }
    >
      <StatusContainer>{styledText}</StatusContainer>
    </If>
  );
};
