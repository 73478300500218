import {
  NoFunction,
  NoFunctionBoolean,
  NoFunctionUndefined,
} from "@/types/NoFunction";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

type Props = {
  isOpened: (id: string) => boolean;
  toggle: (id: string, value: boolean, objectId?: string) => void;
  getObjectId: (id: string) => string | undefined;
};

type ItemState = {
  id: string;
  value: boolean;
  objectId?: string;
};

const ProviderContext = createContext<Props>({
  isOpened: NoFunctionBoolean,
  toggle: NoFunction,
  getObjectId: NoFunctionUndefined,
});

export const DrawerGlobalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [itemsState, setItemsState] = useState<ItemState[]>([]);

  const isOpened = (id: string): boolean => {
    return !!itemsState.find((item) => item.id === id)?.value;
  };

  const getObjectId = (id: string): string | undefined => {
    return itemsState.find((item) => item.id === id)?.objectId;
  };

  const toggle = (id: string, value: boolean, objectId?: string) => {
    const existingItemIndex = itemsState.findIndex((item) => item.id === id);
    if (existingItemIndex !== -1) {
      itemsState[existingItemIndex] = { id, value, objectId };
      setItemsState([...itemsState]);
    } else {
      setItemsState([...itemsState, { id, value, objectId }]);
    }
  };

  return (
    <ProviderContext.Provider value={{ isOpened, toggle, getObjectId }}>
      {children}
    </ProviderContext.Provider>
  );
};

export const useGlobalDrawer = (): Props => useContext(ProviderContext);
