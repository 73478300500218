import { Checkbox } from "@/common/components/checkbox/Checkbox";
import { ReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";

const Container = tw.div`
  grid -ml-2 pr-8 w-5
`;

export const ReleaseAllItemsCheckbox: FC<{
  items: ReleaseItemFieldsFragment[] | undefined;
}> = ({ items = [] }) => {
  const { selectedReleaseItemIds, setSelectedReleaseItemIds } =
    useReleaseUpdate();

  const isSelected = useCallback(
    (item: ReleaseItemFieldsFragment) => {
      return selectedReleaseItemIds.find((id) => id === item.id);
    },
    [selectedReleaseItemIds],
  );

  const checked = useMemo(
    () =>
      items
        .filter((i) => i.isIncluded)
        .every((item) => {
          return isSelected(item);
        }),
    [isSelected, items],
  );
  const handleChange = useCallback(
    (newChecked: boolean) => {
      if (newChecked) {
        setSelectedReleaseItemIds(
          items.filter((i) => i.isIncluded).map((item) => item.id),
        );
      } else {
        setSelectedReleaseItemIds([]);
      }
    },
    [items, setSelectedReleaseItemIds],
  );

  return (
    <Container>
      <Checkbox
        checked={checked}
        data-testid="add-all-items-checkbox"
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
          handleChange(!checked);
        }}
        inputProps={{ "aria-label": "controlled" }}
      />
    </Container>
  );
};
