import { DeleteButton } from "@/common/components/button/DeleteButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { useVendors } from "../hooks/useVendors";
import { getVendorFullName } from "../utils/utils";

type Props = {
  vendor: OrgPreferredVendorsFieldsFragment;
};

export const DeleteVendor: FC<Props> = ({ vendor }) => {
  const { openDialog } = useDialog();
  const { removeVendor } = useVendors();
  const intl = useIntl();
  const { setSuccessAlert } = useSnackbar();

  const handleDelete = useCallback(async () => {
    if (await removeVendor(vendor.id)) {
      setSuccessAlert(
        intl.$t(
          { id: "VENDOR_REMOVE_SUCCESS" },
          {
            name: strongify(getVendorFullName(vendor)),
          },
        ),
      );
    }
  }, [removeVendor, vendor, setSuccessAlert, intl]);

  return (
    <DeleteButton
      onClick={() =>
        openDialog({
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "PROCEED" }),
          icon: <WarningIcon />,
          title: intl.$t({ id: "VENDOR_REMOVE_VENDOR_QUESTION" }),
          text: intl.$t({ id: "VENDOR_REMOVE_VENDOR_TEXT" }),
          handleConfirm: handleDelete,
        })
      }
    />
  );
};
