import { PROJECT_STATUSES } from "@/common/components/display-project-status/DisplayProjectStatus";
import { DateView } from "@/common/utils/dates/DateView";
import { ProjectFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-row gap-4`;
const Details = tw.div`flex flex-col gap-1`;
const Item = tw.div`flex`;
const Label = tw.div`flex mr-1 font-normal`;

type Props = {
  project: Pick<ProjectFieldsFragment, "status" | "startDate">;
};

export const ProjectStageHeader: FC<Props> = ({ project }) => {
  if (!project.status) {
    return <span />;
  }
  return (
    <Container>
      <Details>
        <Item>
          <Label>
            <FormattedMessage tagName="strong" id="STAGE" />
            {": "}
          </Label>
          <FormattedMessage id={PROJECT_STATUSES[project?.status].label} />
        </Item>
        <Item>
          <Label>
            <FormattedMessage tagName="strong" id="START_DATE" />
            {": "}
            <DateView date={project.startDate} />
          </Label>
        </Item>
      </Details>
    </Container>
  );
};
