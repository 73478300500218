import { Loader } from "@/common/components/loader/Loader";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { useTabActive } from "@/common/hooks/useTabActive";
import { AgaveHostedWizardModal } from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/AgaveHostedWizardModal";
import { FC, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { useOrgSettingsExtended } from "../org-settings/hooks/useOrgSettingsExtended";
import { IntegrationsHeader } from "./components/IntegrationsHeader";
import { IntegrationsList } from "./components/list/IntegrationsList";
import { AccountingOnPremiseWizardModal } from "./components/wizard/accounting-onpremise-wizard/AccountingOnPremiseWizardModal";
import { FoundationOnPremiseWizardProvider } from "./components/wizard/accounting-onpremise-wizard/AccountingOnPremiseWizardProvider";
import { AgaveHostedWizardProvider } from "./components/wizard/agave-wizard/AgaveHostedWizardProvider";
import { AgaveConnectionProvider } from "./providers/AgaveConnectionProvider";

const IntegrationsWithProvider: FC = () => {
  const intl = useIntl();
  const { integrations, loading, refetchSettings } = useOrgSettingsExtended();
  const { isTabActive } = useTabActive();

  useEffect(() => {
    if (isTabActive && !loading) {
      refetchSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabActive]);

  if (loading && !integrations) {
    return <Loader loading />;
  }

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "CONNECTIONS" })}</title>
      </Helmet>
      <IntegrationsHeader />
      <IntegrationsList integrations={integrations} />
      <AgaveHostedWizardModal />
      <AccountingOnPremiseWizardModal />
    </>
  );
};

export const Integrations: FC = () => {
  return (
    <NestedStepperProvider>
      <AgaveHostedWizardProvider>
        <FoundationOnPremiseWizardProvider>
          <AgaveConnectionProvider>
            <IntegrationsWithProvider />
          </AgaveConnectionProvider>
        </FoundationOnPremiseWizardProvider>
      </AgaveHostedWizardProvider>
    </NestedStepperProvider>
  );
};
