import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import {
  BuyoutFieldsFragment,
  MessageContextKind,
  ReleaseFieldsFragment,
  RfqQuotesFieldsFragment,
} from "@/generated/graphql";
import { FC, Fragment, useEffect, useMemo, useRef, useState } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import { Message } from "./components/Message";
import { MessageHeader } from "./components/MessagesHeader";
import {
  MessageSenderComponents,
  MessagesSender,
} from "./components/MessagesSender";
import { MessageType, useMessages } from "./providers/MessagesProvider";

export type MessageContextType = {
  quote?: Pick<RfqQuotesFieldsFragment, "id" | "clientIdentifier">;
  buyout?: Pick<BuyoutFieldsFragment, "id" | "clientIdentifier"> | null;
  release?: Pick<ReleaseFieldsFragment, "id" | "sequenceNumber">;
};

export type MessageOrgType = {
  name: string;
  logoImageUrl?: string | null;
};

export type MessagesProps = {
  id: string;
  kind: MessageContextKind;
  org?: MessageOrgType;
  isContractor?: boolean;
  context: MessageContextType;
  note?: React.ReactNode;
  messageTabName: React.ReactNode;
  noMessage?: string;
  components?: MessageSenderComponents;
  projectId?: string;
};

const NoMessagesContainer = tw.div`
  text-center font-thin absolute top-1/3 left-5 right-5
`;

const Container = tw.div<{ $isPrivate?: boolean; $isContractor?: boolean }>`
  grid w-full
  ${({ $isPrivate }) => (!$isPrivate ? "grid-rows-[auto,1fr,auto]" : "grid-rows-[1fr,auto]")}
  ${({ $isContractor }) => ($isContractor ? "h-[calc(100%-54px)]" : "h-full")}
`;

const DayDivider = tw.div`
  text-xs w-full text-center 
`;

const DividerLine = tw.div`
  w-full h-px border-b border-dashed
`;

const DayDividerContainer = tw.div`
  flex items-center justify-center relative mt-auto pt-6
`;

const MessageList = tw.div`
  grid grid-rows-[1fr_auto] w-full p-6 gap-1 h-auto overflow-scroll
`;

export const Messages: FC<MessagesProps> = ({
  note,
  noMessage,
  messageTabName,
  context,
  components,
  isContractor,
  org,
  projectId,
}) => {
  const { messages, loadMore, loading, isPrivate } = useMessages();
  const ref = useRef<HTMLDivElement>(null);
  const scrollPosition = useScrollPosition(ref);
  const [previousHeight, setPreviousHeight] = useState(0);
  const [oldMessages, setOldMessages] = useState([] as MessageType[]);

  useEffect(() => {
    if (ref?.current) {
      const scrollTop = scrollPosition.top;
      if (scrollTop === 0 && !loading && oldMessages.length) {
        loadMore();
      }
    }
  }, [ref, scrollPosition, loadMore, loading, messages, oldMessages]);

  useEffect(() => {
    const lastId = messages.length && messages[messages.length - 1].id;
    const oldLastId =
      oldMessages.length && oldMessages[oldMessages.length - 1]?.id;
    if (
      ref?.current &&
      (messages.length !== oldMessages.length || lastId !== oldLastId)
    ) {
      if (lastId !== oldLastId) {
        ref.current.scrollTop = ref.current.scrollHeight;
      } else {
        ref.current.scrollTop = ref.current.scrollHeight - previousHeight;
      }
      setPreviousHeight(ref.current.scrollHeight);
      setOldMessages(messages);
    }
  }, [ref, messages, previousHeight, oldMessages]);

  const groupedByDay = useMemo(
    () =>
      messages
        .sort((a, b) => a.timeSent - b.timeSent)
        .reduce(
          (acc, message) => {
            if (acc.length === 0) {
              acc.push([[message]]);
            } else {
              const lastGroup = acc[acc.length - 1];
              const lastUserGroup = lastGroup[lastGroup.length - 1];
              const lastMessage = lastUserGroup[lastUserGroup.length - 1];
              const sameUser = lastMessage.author.id === message.author.id;
              const sameDay =
                new Date(lastMessage.timeSent).toDateString() ===
                new Date(message.timeSent).toDateString();
              if (sameDay && sameUser && message.grouped) {
                lastUserGroup.push(message);
              } else if (sameDay) {
                lastGroup.push([message]);
              } else {
                acc.push([[message]]);
              }
            }
            return acc;
          },
          [] as MessageType[][][],
        ),
    [messages],
  );

  const messagePlaceholder = useMemo(() => {
    if (isContractor) {
      if (isPrivate) {
        return "MESSAGE_INTERNAL_SEND_PLACEHOLDER";
      }
      return "MESSAGE_VENDOR_SEND_PLACEHOLDER";
    }
    return "MESSAGE_CONTRACTOR_SEND_PLACEHOLDER";
  }, [isContractor, isPrivate]);

  return (
    <Container $isPrivate={isPrivate} $isContractor={isContractor}>
      <MessageHeader org={org} context={context} />
      <MessageList ref={ref}>
        <Loader loading={loading} />
        <If isTrue={messages.length === 0 && !loading}>
          <NoMessagesContainer>
            <FormattedMessage
              id={noMessage}
              values={{
                number:
                  context.quote?.clientIdentifier ||
                  context.release?.sequenceNumber ||
                  context.buyout?.clientIdentifier,
              }}
            />
          </NoMessagesContainer>
        </If>
        {groupedByDay.map((group, index) => (
          <Fragment key={index}>
            <DayDividerContainer>
              <DividerLine />
              <DayDivider>
                <FormattedDate
                  value={group[0] && group[0][0].timeSent}
                  year="numeric"
                  month="long"
                  day="2-digit"
                />
              </DayDivider>
              <DividerLine />
            </DayDividerContainer>
            {group.map((userGroup) =>
              userGroup.map((message) => (
                <Message
                  key={message.id}
                  message={message}
                  firstMessage={userGroup[0].id === message.id}
                  lastMessage={
                    userGroup[userGroup.length - 1].id === message.id
                  }
                />
              )),
            )}
          </Fragment>
        ))}
      </MessageList>
      <MessagesSender
        messageTabName={messageTabName}
        note={note}
        sendMessagePlaceholder={messagePlaceholder}
        components={components}
        projectId={projectId}
      />
    </Container>
  );
};
