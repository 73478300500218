import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useBuyoutSequence } from "../hooks/useBuyoutSequence";
import { useBuyouts } from "../providers/BuyoutsProvider";

const Container = tw.div`
  flex -ml-2
`;

export const BuyoutAllItemsCheckbox: FC = () => {
  const { sequenceIds, selectedEntities, setSelectedEntities } =
    useBuyoutSequence();
  const { buyouts } = useBuyouts();

  const checked = useMemo(
    () => buyouts.every((d) => selectedEntities.includes(d)),
    [buyouts, selectedEntities],
  );
  const handleChange = useCallback(() => {
    const filteredItems = buyouts;
    setSelectedEntities(
      checked
        ? selectedEntities.filter((i) => !filteredItems.includes(i))
        : [
            ...selectedEntities.filter((i) => filteredItems.includes(i)),
            ...filteredItems,
          ],
    );
  }, [buyouts, checked, selectedEntities, setSelectedEntities]);

  return (
    <Container>
      <SelectionCheckbox
        items={sequenceIds}
        setSelection={handleChange}
        checked={checked}
      />
    </Container>
  );
};
