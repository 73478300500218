import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { NumericalInputControlled } from "@/common/components/numerical-input/NumericalInputControlled";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { DashedDivider } from "@/contractor/pages/home/receipts/pages/receipt-record-order/components/order/itemized/DashedDivider";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { UpdateVendorReleaseInput } from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useOrderFromQuote } from "../../../../providers/OrderFromQuoteProvider";
import { CreateOrderFromQuoteFormValues } from "./CreateOrderFromQuoteForm";

const Container = tw.div`flex flex-col h-full pb-7`;
const InnerContainer = tw.div`flex flex-row items-end justify-end`;
const Item = tw.div`flex flex-col items-center justify-end gap-4 mt-10`;
const InputContainer = tw.div`w-full pr-5`;

type Props = {
  form: UseFormReturn<CreateOrderFromQuoteFormValues, unknown, undefined>;
};

export const CreateOrderFromQuoteNonItemizedView: FC<Props> = ({
  form: { watch, setValue },
}) => {
  const intl = useIntl();
  const { release } = useRelease();
  const { projects } = useProjectListOptions();
  const { hasPhaseCodes } = useOrgSettings();
  const { itemized } = useOrderFromQuote();

  const subtotal = watch("subtotal");
  const customTaxAmount = watch("customTaxAmount");
  const taxRate = watch("taxRate");
  const projectId = watch("projectId");

  const { costCodes, loading } = useProjectCostCodes(projectId);

  const taxAmount = useMemo(() => {
    const subtotalDecimal = new Decimal(subtotal || 0);
    const taxAmount = taxRate
      ? subtotalDecimal.mul(taxRate)
      : new Decimal(customTaxAmount || 0);

    return taxAmount.toNumber();
  }, [customTaxAmount, subtotal, taxRate]);

  const total = useMemo(() => {
    const subtotalDecimal = new Decimal(subtotal || 0);
    const taxAmount = taxRate
      ? subtotalDecimal.mul(taxRate)
      : new Decimal(customTaxAmount || 0);
    return subtotalDecimal.plus(taxAmount).toNumber();
  }, [customTaxAmount, subtotal, taxRate]);

  const project = useMemo(
    () => projects?.find((p) => p.id === projectId),
    [projectId, projects],
  );

  const phaseCodes = useMemo(
    () => project?.tags?.filter((t) => t.mapping) || [],
    [project],
  );

  const handleUpdateRelease = (values: UpdateVendorReleaseInput) => {
    if (
      values.customTaxAmount ||
      (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
    ) {
      setValue("customTaxAmount", values.customTaxAmount || "0");
      setValue("taxRate", undefined);
    }
    if (values.taxRate || values.clearCustomTaxAmount) {
      setValue("customTaxAmount", undefined);
      setValue("taxRate", values.taxRate || "0");
    }

    return true;
  };
  return (
    <Container>
      <InnerContainer>
        <Item>
          <InputContainer>
            <If isTrue={!hasPhaseCodes}>
              <SelectControlled
                name="costCodeId"
                options={costCodes}
                getValue={(o) => o.id}
                getLabel={(o) => o.formatted}
                placeholder={intl.$t({ id: "COST_CODE" })}
                loading={loading}
                className="w-full"
                label={intl.$t({ id: "ASSIGN_COST_CODE" })}
                required={!itemized && !hasPhaseCodes}
              />
            </If>
            <If isTrue={hasPhaseCodes}>
              <SelectControlled
                name="phaseCodeId"
                options={phaseCodes}
                getValue={(o) => o.id}
                getLabel={(o) => o.name}
                placeholder={intl.$t({ id: "PHASE_CODE" })}
                loading={loading}
                className="w-full"
                label={intl.$t({ id: "ASSIGN_PHASE_CODE" })}
                required={!itemized && !!hasPhaseCodes}
              />
            </If>
          </InputContainer>
          <InputContainer>
            <NumericalInputControlled
              name="subtotal"
              label={intl.$t({ id: "SUBTOTAL" })}
              includeCurrency
              inputProps={{ className: "bg-white text-center" }}
            />
          </InputContainer>
          <ReleaseAdditionalChargesAndTaxes
            classes={{
              tax: "-ml-2 gap-18 mr-5",
              total: "  -mr-5",
            }}
            total={`${total}`}
            subtotal={`${subtotal}`}
            customTaxAmount={customTaxAmount}
            customTaxRate={taxRate}
            taxAmount={`${taxAmount}`}
            editableByContractor
            editablePaymentTerms
            includeSubtotal={false}
            includeAdditionalCharges={false}
            editableAdditionalCharges={false}
            updateRelease={(values) =>
              handleUpdateRelease({ ...values, version: -1 })
            }
            hideTotalBorder
            includeSaleTaxLabel={false}
            totalDivider={<DashedDivider />}
          />
        </Item>
      </InnerContainer>
      <Instructions
        projectId={projectId}
        instruction={release?.instructions}
        saveInstruction={(instruction) => setValue("instructions", instruction)}
      />
    </Container>
  );
};
