import { ReleaseProjectItemFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { IncludedInBuyout } from "./IncludedInBuyout";

type Props = {
  item: ReleaseProjectItemFieldsFragment;
  buyoutId?: string;
};

export const ReleaseItemIncludedInBuyout: FC<Props> = ({ item, buyoutId }) => {
  const expirationDate = useMemo(
    () =>
      item.buyoutItems.find((b) => b.buyout.id === buyoutId)?.expirationDate,
    [buyoutId, item.buyoutItems],
  );

  if (!item.buyoutItems.find((i) => i.buyout?.id === buyoutId)) {
    return null;
  }

  return <IncludedInBuyout id={item.id} expirationDate={expirationDate} />;
};
