import { If } from "@/common/components/if/If";
import {
  FileUploadArea,
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPE,
  StylingClasses,
} from "@/common/components/upload/FileUploadArea";
import { AssetFieldsFragment } from "@/generated/graphql";
import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { Accept } from "react-dropzone";
import tw from "tailwind-styled-components";
import { AssetItem } from "../asset-item/AssetItem";
import { useUploadAssets } from "./UploadAssetProvider";

const Container = tw.div<{ $includeAssets: boolean }>`
  items-top gap-x-4 justify-start w-full flex-col
`;

const FilesContainer = tw.div`
  grid grid-flow-col gap-3 justify-start h-fit mt-2
`;

const FileUploadContainer = tw.div`w-full h-fit`;

export const SupportedFormats = tw.div`text-center text-xs text-gray-600 mt-2`;

const FileUploadAreaStyled = tw(FileUploadArea)`w-full h-full`;

type Props = PropsWithChildren<{
  limit?: number;
  readonly?: boolean;
  includeAssets?: boolean;
  accept?: Accept;
  onChange?: (assets: AssetFieldsFragment[]) => void;
  customRedirect?: () => void;
  mode?: "horizontal" | "vertical";
  multiple?: boolean;
  testId?: string;
  className?: string;
  xs?: boolean;
}> &
  StylingClasses;

export const hasImage = (asset: AssetFieldsFragment) => {
  return (
    asset.thumbnailUrl ||
    IMAGE_EXTENSIONS.some((ext) => asset.url?.toLowerCase().includes(ext))
  );
};

export const UploadAsset: FC<Props> = ({
  limit,
  readonly = false,
  includeAssets = true,
  accept,
  onChange,
  customRedirect,
  mode = "vertical",
  classes: defaultClasses,
  children,
  multiple = true,
  testId,
  className,
  xs,
}) => {
  const { uploadAssets, uploading, removeAsset, assets } = useUploadAssets();
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const files = await uploadAssets(acceptedFiles);
      onChange?.(files);
    },
    [uploadAssets, onChange],
  );

  const remove = useCallback(
    async (asset: AssetFieldsFragment) => {
      const files = await removeAsset(asset);
      onChange?.(files);
    },
    [removeAsset, onChange],
  );

  const classes = useMemo(() => {
    if (xs) {
      return {
        ...defaultClasses,
        uploadContainer: {
          ...defaultClasses?.uploadContainer,
          container: "px-2",
          image: "w-10 h-10",
          text: "w-24 text-xs",
          button: "text-xs whitespace-nowrap",
        },
        container: "bg-white rounded-lg",
        fileContainer: `${defaultClasses?.fileContainer || ""} mt-0`,
      };
    }

    return defaultClasses;
  }, [defaultClasses, xs]);

  return (
    <Container className={className} $includeAssets={includeAssets}>
      <FileUploadContainer>
        <If isTrue={(!limit || (assets && assets.length < limit)) && !readonly}>
          <FileUploadAreaStyled
            onChange={onDrop}
            accept={accept || { [IMAGE_MIME_TYPE]: IMAGE_EXTENSIONS }}
            error={false}
            includeFileDetails={false}
            multiple={multiple}
            disabled={multiple ? false : assets?.length > 0}
            mode={mode}
            classes={classes}
            loading={!!uploading}
            testId={testId}
          />
          {children}
        </If>
      </FileUploadContainer>
      <If isTrue={includeAssets}>
        <FilesContainer className={classes?.fileContainer}>
          {assets?.map((asset, index) => (
            <AssetItem
              key={index}
              asset={asset}
              includeNavigation
              customRedirect={customRedirect}
              includeTooltip
              readonly={readonly}
              remove={remove}
            />
          ))}
        </FilesContainer>
      </If>
    </Container>
  );
};
