import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { FormattedMessage, useIntl } from "react-intl";
import { OrderTypeForm } from "./OrderTypeForm";

export const NewOrderTypeButton = () => {
  const intl = useIntl();

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <PrimaryButton onClick={() => togglePanel(true)}>
          <FormattedMessage id="ORDER_TYPE_NEW_BUTTON" />
        </PrimaryButton>
      )}
      content={(togglePanel) => (
        <OrderTypeForm
          title={intl.$t({ id: "ORDER_TYPES_NEW_TITLE" })}
          onClose={() => {
            togglePanel(false);
          }}
        />
      )}
    />
  );
};
