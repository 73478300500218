import { isMasterSku, isProductSku } from "@/common/components/material/utils";
import {
  PDF_FONT,
  PDF_LEFT_SPACING,
  TOTAL_PRICE_DECIMAL_POINTS,
} from "@/common/const";
import {
  DistributorQuoteFieldsFragment,
  ProjectMaterialSkuFieldsFragment,
  QuoteStatus,
} from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable, { HAlignType, RowInput } from "jspdf-autotable";
import { IntlShape } from "react-intl";

const quoteItemsForPdf = (
  quote: DistributorQuoteFieldsFragment,
  intl: IntlShape,
  hasManufacturersSetting: boolean,
): RowInput[] => {
  const quoteItems = quote.itemGroups
    .flatMap((item) => item.quoteItems)
    .filter((i) => i.status === QuoteStatus.Withdrawn);
  return quoteItems.map((quoteItem) => {
    const group = quote.itemGroups.find((q) =>
      q.quoteItems.some((q2) => q2.id === quoteItem.id),
    );
    const rfqItem = group?.rfqItem;
    const index = quote.itemGroups.findIndex((q) =>
      q.quoteItems.includes(quoteItem),
    );

    const requestedItemName = `${
      isMasterSku(rfqItem?.projectItem.material.material) ||
      isProductSku(rfqItem?.projectItem.material.material)
        ? hasManufacturersSetting
          ? `${
              (
                rfqItem?.projectItem.material
                  .material as ProjectMaterialSkuFieldsFragment
              ).manufacturer?.name || intl.$t({ id: "ANY_MANUFACTURER" })
            }\n`
          : ""
        : ""
    }${rfqItem?.description}${
      rfqItem?.instructions?.text
        ? `\n\n${intl.$t({
            id: "DISTRIBUTOR_QUOTE_CONTRACTOR_NOTES",
          })}:\n${rfqItem?.instructions?.text}`
        : ""
    }`;

    const requestedQuantity = `${intl.formatNumber(
      Number(rfqItem?.quantityDecimal || 0),
      {
        minimumFractionDigits: 0,
        maximumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
      },
    )}\n${
      rfqItem?.projectItem.estimateUom.mnemonic ||
      rfqItem?.projectItem.estimateUom.pluralDescription
    }`;

    return [index + 1, requestedItemName, requestedQuantity];
  });
};

const HEADER = [
  { text: "EMPTY_DESCRIPTION" },
  {
    text: "DISTRIBUTOR_QUOTE_ITEM_DESCRIPTION",
    styles: { halign: "left" as HAlignType },
  },
  { text: "QUANTITY" },
];

export const excludedItems = (
  doc: jsPDF,
  quote: DistributorQuoteFieldsFragment,
  intl: IntlShape,
  hasManufacturersSetting: boolean,
) => {
  doc
    .setFont(PDF_FONT, "", "bold")
    .setFontSize(10)
    .text(intl.$t({ id: "EXCLUDED_ITEMS" }), PDF_LEFT_SPACING, 30);
  autoTable(doc, {
    theme: "grid",
    styles: {
      font: PDF_FONT,
      fontSize: 9,
    },
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: "black",
      halign: "center",
      valign: "middle",
    },
    margin: { top: 35 },
    columnStyles: {
      0: { halign: "center", valign: "middle", cellWidth: 10 },
      1: { valign: "middle" },
      2: { halign: "center", valign: "middle" },
    },
    head: [
      HEADER.map((conf) => ({
        content: intl.$t({ id: conf.text }),
        styles: { ...(conf.styles || {}) },
      })),
    ],
    body: [...quoteItemsForPdf(quote, intl, hasManufacturersSetting)],
  });
};
