import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { WizardModalMode } from "@/common/components/wizard-modal/WizardModal";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { useUpdateOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useUpdateOrgSettings";
import {
  SourceSystem,
  UpdateSourceSystemsSettingsInput,
} from "@/generated/graphql";
import { NoFunction, NoFunctionPromise } from "@/types/NoFunction";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { usePagesConfig } from "./agave-providers-pages/usePagesConfig";

type AgaveHostedWizardProviderContextType = {
  sourceSystem: SourceSystem | null;
  wizardOpened: boolean;
  wizardMode: WizardModalMode;
  openWizard: (args: {
    sourceSystem: SourceSystem;
    mode?: WizardModalMode;
    step?: IntegrationFeature;
  }) => void;
  closeWizard: () => void;
  handleSaveWizardData: (
    data: Omit<UpdateSourceSystemsSettingsInput, "system">,
  ) => Promise<void>;

  wbsTags: boolean | null;
  setWbsTags: (value: boolean | null) => void;

  selectedToJobLedgerAccount: string | null;
  setSelectedToJobLedgerAccount: (id: string | null) => void;

  selectedAccountsPayableLedgerAccount: string | null;
  setSelectedAccountsPayableLedgerAccount: (id: string | null) => void;

  selectedMaterialCostType: string | null;
  setSelectedMaterialCostType: (id: string | null) => void;

  automatedSynchronization: boolean;
  setAutomatedSynchronization: (value: boolean) => void;
  postInventoryReceipts: boolean;
  setPostInventoryReceipts: (value: boolean) => void;
  inventoryReceiptLedgerAccountId: string | null;
  setInventoryReceiptLedgerAccountId: (value: string | null) => void;
  autoPostPos: boolean;
  setAutoPostPos: (value: boolean) => void;
  autoPostInvoices: boolean;
  setAutoPostInvoices: (value: boolean) => void;

  selectedPoType: string | null;
  setSelectedPoType: (id: string | null) => void;
  autoExportApprovedInvoices: boolean;
  setAutoExportApprovedInvoices: (value: boolean) => void;
};

const ProviderContext = createContext<AgaveHostedWizardProviderContextType>({
  sourceSystem: null,
  wizardOpened: false,
  wizardMode: WizardModalMode.MULTIPLE_PAGES,
  openWizard: NoFunction,
  closeWizard: NoFunction,
  handleSaveWizardData: NoFunctionPromise,

  wbsTags: null,
  setWbsTags: NoFunction,

  selectedToJobLedgerAccount: null,
  setSelectedToJobLedgerAccount: NoFunction,

  selectedAccountsPayableLedgerAccount: null,
  setSelectedAccountsPayableLedgerAccount: NoFunction,

  selectedMaterialCostType: null,
  setSelectedMaterialCostType: NoFunction,

  automatedSynchronization: false,
  setAutomatedSynchronization: NoFunction,
  postInventoryReceipts: false,
  setPostInventoryReceipts: NoFunction,
  inventoryReceiptLedgerAccountId: null,
  setInventoryReceiptLedgerAccountId: NoFunction,
  autoPostPos: false,
  setAutoPostPos: NoFunction,
  autoPostInvoices: false,
  setAutoPostInvoices: NoFunction,

  selectedPoType: null,
  setSelectedPoType: NoFunction,

  autoExportApprovedInvoices: false,
  setAutoExportApprovedInvoices: NoFunction,
});

export const AgaveHostedWizardProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { integrations, orgId, refetchSettings, connectedSourceSystem } =
    useOrgSettingsExtended();
  const { updateOrgSettings } = useUpdateOrgSettings();
  const { setStep, setNestedStep } = useNestedStepper();

  const [sourceSystem, setSourceSystem] = useState<SourceSystem | null>(
    connectedSourceSystem?.system ?? null,
  );
  const [wizardOpened, setWizardOpened] = useState(false);
  const [wizardMode, setWizardMode] = useState(WizardModalMode.MULTIPLE_PAGES);
  const [wbsTags, setWbsTags] = useState<boolean | null>(null);
  const [selectedPoType, setSelectedPoType] = useState<string | null>(null);

  const [selectedToJobLedgerAccount, setSelectedToJobLedgerAccount] = useState<
    string | null
  >(null);

  const [
    selectedAccountsPayableLedgerAccount,
    setSelectedAccountsPayableLedgerAccount,
  ] = useState<string | null>(null);

  const [selectedMaterialCostType, setSelectedMaterialCostType] = useState<
    string | null
  >(null);

  const [automatedSynchronization, setAutomatedSynchronization] =
    useState(true);
  const [autoExportApprovedInvoices, setAutoExportApprovedInvoices] =
    useState(true);
  const [autoPostPos, setAutoPostPos] = useState(true);
  const [autoPostInvoices, setAutoPostInvoices] = useState(true);
  const [postInventoryReceipts, setPostInventoryReceipts] = useState(false);
  const [inventoryReceiptLedgerAccountId, setInventoryReceiptLedgerAccountId] =
    useState<string | null>(null);

  useEffect(() => {
    const settingsForCurrentSourceSystem = integrations?.sourceSystems.find(
      (s) => s.system === sourceSystem,
    );

    if (settingsForCurrentSourceSystem) {
      setSelectedToJobLedgerAccount(
        settingsForCurrentSourceSystem.toJobLedgerAccount?.externalId ?? null,
      );
      setSelectedAccountsPayableLedgerAccount(
        settingsForCurrentSourceSystem.accountsPayableLedgerAccount
          ?.externalId ?? null,
      );
      setSelectedMaterialCostType(
        settingsForCurrentSourceSystem.materialCostType?.externalId ?? null,
      );
      setAutomatedSynchronization(
        settingsForCurrentSourceSystem.defaultAutoSync ?? false,
      );
      setAutoExportApprovedInvoices(
        settingsForCurrentSourceSystem.autoExportApprovedInvoices ?? false,
      );
      setAutoPostPos(settingsForCurrentSourceSystem.autoPostPOs ?? false);
      setAutoPostInvoices(
        settingsForCurrentSourceSystem.autoPostInvoices ?? false,
      );
      setWbsTags(settingsForCurrentSourceSystem.wbsTags ?? null);
      setPostInventoryReceipts(
        !!settingsForCurrentSourceSystem.postInventoryReceipts,
      );
      setInventoryReceiptLedgerAccountId(
        settingsForCurrentSourceSystem.inventoryReceiptLedgerAccount
          ?.externalId ?? null,
      );
    }
  }, [integrations?.sourceSystems, sourceSystem]);

  useEffect(() => {
    setSourceSystem(connectedSourceSystem?.system ?? null);
  }, [connectedSourceSystem]);

  const handleSaveWizardData = useCallback(
    async (data: Omit<UpdateSourceSystemsSettingsInput, "system">) => {
      if (sourceSystem) {
        await updateOrgSettings({
          id: orgId,
          integrations: {
            sourceSystems: {
              system: sourceSystem,
              ...data,
            },
          },
        });
      }
    },
    [sourceSystem, orgId, updateOrgSettings],
  );

  const resetWizard = useCallback(() => {
    setSelectedToJobLedgerAccount(null);
    setSelectedMaterialCostType(null);
    setStep(0);
    setNestedStep(0);
  }, [setStep, setNestedStep]);

  const closeWizard = useCallback(() => {
    refetchSettings();
    setWizardOpened(false);
    resetWizard();
  }, [refetchSettings, resetWizard]);

  const { pages } = usePagesConfig();

  const openWizard = useCallback(
    ({
      sourceSystem,
      mode,
      step,
    }: {
      sourceSystem: SourceSystem;
      mode?: WizardModalMode;
      step?: IntegrationFeature;
    }) => {
      setSourceSystem(sourceSystem);
      setWizardMode(mode ?? WizardModalMode.MULTIPLE_PAGES);
      const existingPage = pages.find((p) => p.feature === step);
      if (step && !existingPage) {
        throw new Error(`Page with feature ${step} does not exist`);
      }
      setStep(existingPage ? pages.findIndex((p) => p.feature === step) : 0);
      setWizardOpened(true);
    },
    [pages, setStep],
  );

  return (
    <ProviderContext.Provider
      value={{
        sourceSystem,
        wizardOpened,
        wizardMode,
        openWizard,
        closeWizard,
        handleSaveWizardData,

        wbsTags,
        setWbsTags,

        selectedToJobLedgerAccount,
        setSelectedToJobLedgerAccount,

        selectedAccountsPayableLedgerAccount,
        setSelectedAccountsPayableLedgerAccount,

        selectedMaterialCostType,
        setSelectedMaterialCostType,

        automatedSynchronization,
        setAutomatedSynchronization,
        postInventoryReceipts,
        setPostInventoryReceipts,
        inventoryReceiptLedgerAccountId,
        setInventoryReceiptLedgerAccountId,
        autoPostPos,
        setAutoPostPos,
        autoPostInvoices,
        setAutoPostInvoices,

        selectedPoType,
        setSelectedPoType,
        autoExportApprovedInvoices,
        setAutoExportApprovedInvoices,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useAgaveHostedWizard = () => useContext(ProviderContext);
