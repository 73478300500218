import { EstimationSoftwareSelector } from "@/common/components/estimation-software-selector/EstimationSoftwareSelector";
import { If } from "@/common/components/if/If";
import { InfoText } from "@/common/components/info-text/InfoText";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { TextField } from "@/common/components/textfield/TextField";
import {
  IntegrationType,
  RfqQuotesQuoteFieldsFragment,
} from "@/generated/graphql";
import { Divider, FormControl } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { FormattedMessage, FormattedPlural, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useRfqQuotes } from "../../providers/RfqQuotesProvider";
import { ExportQuotesProvider, useExportQuotes } from "./ExportQuotesProvider";

type ExportQuotesProps = {
  onClose: () => void;
};

const FormControlStyled = tw(FormControl)`
  inline mb-8
`;

const SelectLabel = tw.div`
  text-md font-bold mb-2
`;

const QuoteContainer = tw.div`
  flex justify-between mt-2
`;
const QuoteContainerItem = tw.div`
  flex
`;
const QuoteItem = tw.div`
  last:ml-1
`;
const QuoteItemBold = tw(QuoteItem)`
  font-bold whitespace-nowrap
`;

const TextFieldStyled = tw(TextField)`
  w-full
`;

const ExportQuotesWithProvider: FC<ExportQuotesProps> = ({ onClose }) => {
  const intl = useIntl();
  const { selectedQuotes, rfq, allQuoteItems } = useRfqQuotes();
  const [exportError, setExportError] = useState<unknown>();
  const [targetURL, setTargetURL] = useState("");
  const { exportQuotes, loading } = useExportQuotes();
  const [integrationType, setIntegrationType] = useState<IntegrationType>(
    "" as IntegrationType,
  );

  const onSave = async () => {
    try {
      setExportError(undefined);
      await exportQuotes(
        integrationType,
        null,
        selectedQuotes?.map((item) => item.quoteItemId),
        targetURL,
      );
    } catch (error) {
      setExportError(error);
    }
  };

  const quotes = useMemo(() => {
    return rfq?.quotes.filter((q) =>
      selectedQuotes
        ?.map((item) => item.quoteItemId)
        .find((id) =>
          allQuoteItems
            .filter((qi) => qi.quoteId === q.id)
            .map((i) => i.id)
            .includes(id),
        ),
    );
  }, [selectedQuotes, rfq?.quotes, allQuoteItems]);

  const onSoftwareChanged = (integrationType: IntegrationType) => {
    setIntegrationType(integrationType);
  };

  const getQuoteItemsLength = (quote: RfqQuotesQuoteFieldsFragment) =>
    allQuoteItems
      .filter((qi) => qi.quoteId === quote.id)
      .filter((item) =>
        selectedQuotes?.map((item) => item.quoteItemId).includes(item.id),
      ).length;

  return (
    <OverlayPanel
      title={intl.$t({ id: "EXPORT_QUOTES" })}
      onSave={onSave}
      saveLabel={intl.$t({ id: "EXPORT" })}
      disableSave={!integrationType || !targetURL}
      onCancel={onClose}
      saving={loading}
    >
      <>
        <FormControlStyled>
          <EstimationSoftwareSelector
            value={integrationType}
            onChange={onSoftwareChanged}
            integrationTypes={[IntegrationType.Equotes]}
          />
        </FormControlStyled>
        <If isTrue={integrationType === IntegrationType.Equotes}>
          <FormControlStyled>
            <TextFieldStyled
              value={targetURL}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                setTargetURL(ev.target.value)
              }
              label={intl.$t({ id: "ONCENTER_URL" })}
              required
            />
          </FormControlStyled>
        </If>
        <SelectLabel>
          <FormattedMessage id="QUOTES_QUOTES_TO_EXPORT" />
        </SelectLabel>
        <Divider />
        <FormControlStyled>
          {quotes?.map((quote, index) => (
            <QuoteContainer key={index}>
              <QuoteContainerItem>
                <QuoteItemBold>
                  <FormattedMessage
                    id="QUOTE_NUMBER"
                    values={{ number: index + 1 }}
                  />
                  :
                </QuoteItemBold>
                <QuoteItem>{quote.sellerOrgLocation.org.name}</QuoteItem>
              </QuoteContainerItem>
              <QuoteContainerItem>
                <QuoteItemBold>
                  <FormattedPlural
                    value={getQuoteItemsLength(quote)}
                    one={
                      <FormattedMessage
                        id="QUOTES_ITEM"
                        values={{ number: getQuoteItemsLength(quote) }}
                      />
                    }
                    other={
                      <FormattedMessage
                        id="QUOTES_ITEMS"
                        values={{ number: getQuoteItemsLength(quote) }}
                      />
                    }
                  />
                </QuoteItemBold>
              </QuoteContainerItem>
            </QuoteContainer>
          ))}
        </FormControlStyled>
        {exportError && (
          <InfoText
            type="error"
            title="EXPORT_QUOTES_ERROR"
            body="EXPORT_QUOTES_ERROR_DESCRIPTION"
          />
        )}
      </>
    </OverlayPanel>
  );
};

export const ExportQuotes: FC<ExportQuotesProps> = ({ onClose }) => (
  <ExportQuotesProvider>
    <ExportQuotesWithProvider onClose={onClose} />
  </ExportQuotesProvider>
);
