import {
  QueryReleasesFilter,
  useReleasesSelectorQuery,
} from "@/generated/graphql";
import { useMemo, useState } from "react";
import { MAX_ITEMS_PER_PAGE } from "../const";

type Options = {
  skip?: boolean;
};

export const useReleasesSelectorData = (
  defaultFilter: QueryReleasesFilter,
  { skip }: Options = {},
) => {
  const [filter, setFilter] = useState<QueryReleasesFilter | undefined>(
    defaultFilter,
  );

  const { data, loading } = useReleasesSelectorQuery({
    variables: { filter, first: MAX_ITEMS_PER_PAGE },
    fetchPolicy: "cache-and-network",
    skip,
  });

  return useMemo(
    () => ({
      releases: data?.releases.edges.map((edge) => edge.node) || [],
      setFilter,
      loading,
      filter,
    }),
    [data?.releases.edges, filter, loading],
  );
};
