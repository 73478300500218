import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useProjectItemsZones } from "../../providers/ProjectItemsZonesProvider";

const FILTER_TYPE = {
  RFQ: "rfq",
  BUYOUT: "buyout",
};

export const ItemFilterSelector = () => {
  const intl = useIntl();
  const FILTERS = useMemo(
    () => [
      {
        id: FILTER_TYPE.RFQ,
        label: intl.$t({ id: "ITEMS_FILTER_HIDE_ITEMS_WITH_RFQ" }),
      },
      {
        id: FILTER_TYPE.BUYOUT,
        label: intl.$t({ id: "ITEMS_FILTER_HIDE_ITEMS_WITH_BUYOUT" }),
      },
    ],
    [intl],
  );
  const { filters, setFilters } = useProjectItemsZones();

  return (
    <Multiselect
      placeholder={intl.$t({
        id: "FILTERS",
      })}
      chipSize="small"
      className="w-full"
      options={FILTERS}
      values={filters}
      onMultipleChange={setFilters}
      getLabel={(filter) => filter.label}
      getValue={(option) => option.id}
      disableCloseOnSelect
      includeCheckbox
      limitTags={1}
      moreItems={FILTERS.filter((c) =>
        FILTERS?.slice(1, filters?.length).includes(c),
      )
        .map((f) => f.label)
        .join(", ")}
      required
    />
  );
};
