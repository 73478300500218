import { UomPicker } from "@/common/components/uom-picker/UomPicker";
import { LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "@/common/const";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useEstimatedItems } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  UomFieldsFragment,
  UpdateContractorReleaseInput,
  UpdateContractorReleaseItemInput,
} from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { usePriceCalculation } from "../../hooks/usePriceCalculation";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "../../providers/ReleaseActionsProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";

type Props = {
  item: ExpandedReleaseItem;
  readonly?: boolean;
  staticText?: boolean;
};

export const ReleaseDeliveryUnits: FC<Props> = ({ item, readonly }) => {
  const intl = useIntl();
  const { release } = useRelease();
  const { updateRelease } = useReleaseUpdate();
  const { inputErrors } = useReleaseActions();
  const { setNewProjectEstimatedItem } = useEstimatedItems();
  const { calcExtPrice } = usePriceCalculation();

  const saveQuantity = useCallback(
    async ({ uom, name }: { uom?: string | null; name?: string }) => {
      if (release) {
        let updates: UpdateContractorReleaseItemInput = {
          releaseItemId: item.id,
          uom,
        };
        if (name) {
          updates.name = name;
        }
        if (isLumpSumUomText(uom)) {
          updates.unitPrice = "1.0";
          updates.quantityDecimal = calcExtPrice(
            item.quantityDecimal,
            item.unitPrice,
          ).toString();
        }
        const input: UpdateContractorReleaseInput = {
          releaseId: release?.id,
          version: release?.version,
          updates: [updates],
        };
        await updateRelease(input);
      }
    },
    [
      release,
      item.id,
      item.quantityDecimal,
      item.unitPrice,
      updateRelease,
      calcExtPrice,
    ],
  );

  const error = useMemo(
    () =>
      !!(inputErrors.includes(ReleaseErrorType.REQUESTED_UOM) && !item.uom?.id),
    [inputErrors, item.uom?.id],
  );

  const saveUom = useCallback(
    async (newValue: string | null) => {
      let name;
      if (
        item.name.endsWith(` (Lump Sum)`) &&
        newValue !== LUMP_SUM_UOM_PLURAL_DESCRIPTION
      ) {
        name = item.name.replace(` (Lump Sum)`, "");
      } else if (
        !item.name.endsWith(` (Lump Sum)`) &&
        newValue === LUMP_SUM_UOM_PLURAL_DESCRIPTION
      ) {
        name = intl.$t({ id: "NAME_LUMP_SUM" }, { name: item.name });
      }

      return item.id
        ? await saveQuantity({ uom: newValue, name })
        : setNewProjectEstimatedItem([{ key: "uom", value: newValue }]);
    },
    [item.id, item.name, saveQuantity, setNewProjectEstimatedItem, intl],
  );

  const getLabel = useCallback((uom: UomFieldsFragment | string) => {
    if (!(uom as UomFieldsFragment).id) {
      return uom as string;
    }
    return (uom as UomFieldsFragment)?.pluralDescription;
  }, []);

  return (
    <UomPicker
      uom={item.uom}
      saveUom={saveUom}
      error={error}
      getLabel={getLabel}
      placeholder=""
      staticText={readonly}
      creatable
      centered
    />
  );
};
