import { BuyoutPriceInput } from "@/common/components/buyout-price-input/BuyoutPriceInput";
import { DistributorBuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { useDistributorBuyout } from "../../../providers/DistributorBuyoutProvider";

type SheetsInputProps = {
  item: DistributorBuyoutItemFieldsFragment;
  index?: number;
  error?: boolean;
};

export const DistributorBuyoutPriceInput: FC<SheetsInputProps> = ({
  item,
  index,
  error,
}) => {
  const { updateVendorBuyout } = useDistributorBuyout();
  const intl = useIntl();

  const updateValue = useCallback(
    async (value: string | null) => {
      await updateVendorBuyout({
        updates: [
          {
            buyoutItemId: item.id,
            unitPrice: value ? parseFloat(value).toString() : "0",
          },
        ],
      });
    },
    [updateVendorBuyout, item.id],
  );

  return (
    <BuyoutPriceInput
      index={index}
      price={item.unitPrice}
      uomDescription={item.projectItem?.estimateUom?.mnemonic}
      updateValue={updateValue}
      infoLabel={intl.$t({ id: "DISTRIBUTOR_YOUR_UNIT_PRICE_INFO" })}
      readonly={false}
      error={error}
    />
  );
};
