import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useNoteDocument } from "@/contractor/pages/home/releases/pages/deliveries/components/order-from-note/note-document/providers/NoteDocumentProvider";
import { NoteDocumentItemFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { useAddItemsModal } from "./useAddItemsModal";

export const useAddNoteItems = () => {
  const { handsonInstance } = useColumnMapper();
  const { noteDocument } = useNoteDocument();
  const { release } = useRelease();
  const { findMaterialByName, appendRows, rowIsEmpty } = useTableHelpers();
  const { showModal } = useAddItemsModal();
  const { formatCostCode } = useCostCodes();
  const { calcExtPrice } = usePriceCalculation();

  const getMissingItems = useCallback(
    () =>
      noteDocument?.items.filter(
        (item) =>
          !release?.items.some((releaseItem) => releaseItem?.id === item.id),
      ) || [],
    [noteDocument?.items, release?.items],
  );

  const addAllItems = useCallback(
    (items?: NoteDocumentItemFieldsFragment[]) => {
      const newItems =
        (items || noteDocument?.items || []).map((item) => {
          const material = findMaterialByName(item.description ?? "");
          return {
            [COLUMN_TYPE.Material]:
              material?.material.name ?? item.description ?? "",
            [COLUMN_TYPE.UOM]: String(item.uom) || "UT",
            [COLUMN_TYPE.Quantity]: item.quantity || "",
            [COLUMN_TYPE.UnitPrice]: item.unitPrice || "",
            [COLUMN_TYPE.ExtPrice]: String(
              calcExtPrice(item.quantity, item.unitPrice),
            ),
            [COLUMN_TYPE.CostCode]: formatCostCode(material?.costCode ?? ""),
          };
        }) || [];
      appendRows(newItems, handsonInstance);
    },
    [
      noteDocument?.items,
      appendRows,
      handsonInstance,
      findMaterialByName,
      calcExtPrice,
      formatCostCode,
    ],
  );

  const addMissingItems = useCallback(() => {
    addAllItems(getMissingItems());
  }, [addAllItems, getMissingItems]);

  const addNoteItems = useCallback(() => {
    if (handsonInstance?.isDestroyed) {
      return;
    }
    const allRowsEmpty = handsonInstance?.getData().every(rowIsEmpty);
    const noMatches = noteDocument?.items.length === getMissingItems().length;
    if (allRowsEmpty || noMatches) {
      addAllItems();
      return;
    }
    showModal(
      {
        handleCustomButtonAction: addAllItems,
        handleConfirm: addMissingItems,
      },
      noteDocument?.items.length || 0,
      getMissingItems().length,
    );
  }, [
    handsonInstance,
    rowIsEmpty,
    showModal,
    addAllItems,
    addMissingItems,
    noteDocument?.items.length,
    getMissingItems,
  ]);

  return {
    addNoteItems,
  };
};
