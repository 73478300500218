import { Pagination } from "@/common/components/pagination/Pagination";
import { PaginationContainer } from "@/common/components/pagination/Pagination.styles";
import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { FC, PropsWithChildren, ReactNode } from "react";
import { If } from "../if/If";
import { NotNullableRenderer } from "../not-nullable-renderer/NotNullableRenderer";

type Props = PropsWithChildren<{
  hasItemsCondition?: unknown;
  emptyList?: string | ReactNode;
  count?: number;
  totalCount?: number;
  paginated?: boolean;
}>;

export const ListRenderer: FC<Props> = ({
  hasItemsCondition = true,
  emptyList,
  children,
  totalCount = 0,
  count = 0,
  paginated = false,
}) => {
  const { page, nextPage, previousPage, pageInfo, pageSize } = usePagination();

  return (
    <NotNullableRenderer
      value={hasItemsCondition}
      defaultValue={emptyList ?? ""}
    >
      {children}
      <If isTrue={paginated}>
        <PaginationContainer>
          <Pagination
            count={count}
            total={totalCount}
            page={page}
            itemsPerPage={pageSize}
            nextPage={nextPage}
            previousPage={previousPage}
            hasNext={pageInfo?.hasNextPage || false}
            hasPrevious={pageInfo?.hasPreviousPage || false}
          />
        </PaginationContainer>
      </If>
    </NotNullableRenderer>
  );
};
