import { If } from "@/common/components/if/If";
import { Price } from "@/common/components/price/Price";
import { FC, useEffect, useRef } from "react";
import { InvoiceFooterEditInput } from "./InvoiceFooterEditInput";

type Props = {
  price: string;
  isEditing: boolean;
  toggleEditing: () => void;
  onSave: (t: string) => void;
};

export const InvoiceFooterPriceEdit: FC<Props> = ({
  price,
  isEditing,
  toggleEditing,
  onSave,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing) {
      ref.current?.focus();
    }
  }, [isEditing]);

  return (
    <>
      <If isTrue={!isEditing}>
        <Price price={price} maximumFractionDigits={2} />
      </If>
      <If isTrue={isEditing}>
        <InvoiceFooterEditInput
          isEditing={isEditing}
          onSave={onSave}
          toggleEditing={toggleEditing}
          inputValue={price}
          includeCurrency
        />
      </If>
    </>
  );
};
