import { Dialog } from "@/common/components/dialog/Dialog";
import { Switch } from "@/common/components/switch/Switch";
import { DefaultSettingsDetails } from "@/contractor/pages/admin/integrations/components/wizard/Wizard.styles";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const DialogContent = tw.div`w-[365px] flex items-center flex-row mt-6 px-3`;
const SwitchView = tw.div`flex h-10 items-center justify-center mr-5`;

type Props = {
  toJob: boolean;
  showDialog: boolean;
  setToJob: (toJob: boolean) => void;
  setShowDialog: (show: boolean) => void;
  togglePanel: (toggle: boolean) => void;
};

export const FoundationExportTypeDialog: FC<Props> = ({
  toJob,
  showDialog,
  setToJob,
  setShowDialog,
  togglePanel,
}) => {
  const intl = useIntl();
  return (
    <Dialog
      content={
        <DialogContent>
          <SwitchView>
            <Switch
              width={140}
              value={toJob}
              onChange={setToJob}
              onLabel={intl.$t({ id: "TO_JOB" })}
              offLabel={intl.$t({ id: "TO_WAREHOUSE" })}
            />
          </SwitchView>
          <DefaultSettingsDetails>
            {intl.$t({
              id: toJob ? "TO_JOB_DESCRIPTION" : "TO_WAREHOUSE_DESCRIPTION",
            })}
          </DefaultSettingsDetails>
        </DialogContent>
      }
      show={showDialog}
      title={intl.$t({ id: "SPECIFY_ITEM_DISTRIBUTION" })}
      cancelButtonText={intl.$t({ id: "CANCEL" })}
      handleCancel={() => setShowDialog(false)}
      confirmButtonText={intl.$t({ id: "CONFIRM" })}
      handleConfirm={() => {
        setShowDialog(false);
        togglePanel(true);
      }}
      buttonsConfiguration={{
        cancel: {
          wide: true,
        },
        confirm: {
          wide: true,
        },
      }}
    />
  );
};
