import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { ProjectItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";

const Container = tw.div`
  flex mr-1 w-5
`;

export const ProjectItemGroupCheckbox: FC<{
  group:
    | CategoryState<ProjectItemFieldsFragment>
    | CategoryState<CategoryState<ProjectItemFieldsFragment>>;
}> = ({ group }) => {
  const { setSelectedEstimatedItemIds, selectedEstimatedItemIds } =
    useEstimatedItems();

  const checked = useMemo(
    () =>
      group.items.every((item) => {
        if ((item as CategoryState<ProjectItemFieldsFragment>).items) {
          return (item as CategoryState<ProjectItemFieldsFragment>).items.every(
            (subitem) =>
              subitem.estimatedItems.length > 0 &&
              subitem.estimatedItems.every((i) =>
                selectedEstimatedItemIds.includes(i.id),
              ),
          );
        } else {
          return (item as ProjectItemFieldsFragment).estimatedItems.every((i) =>
            selectedEstimatedItemIds.includes(i.id),
          );
        }
      }),
    [group.items, selectedEstimatedItemIds],
  );
  const handleChange = useCallback(
    (newChecked: boolean) => {
      if (newChecked) {
        const idsToAdd = group.items
          .map((item) => {
            if ((item as CategoryState<ProjectItemFieldsFragment>).items) {
              return (
                item as CategoryState<ProjectItemFieldsFragment>
              ).items.flatMap((subitem) =>
                subitem.estimatedItems.map((i) => i.id),
              );
            } else {
              return (item as ProjectItemFieldsFragment).estimatedItems.map(
                (i) => i.id,
              );
            }
          })
          .flat();
        setSelectedEstimatedItemIds([...selectedEstimatedItemIds, ...idsToAdd]);
      } else {
        const idsToRemove = group.items
          .map((item) => {
            if ((item as CategoryState<ProjectItemFieldsFragment>).items) {
              return (
                item as CategoryState<ProjectItemFieldsFragment>
              ).items.flatMap((subitem) =>
                subitem.estimatedItems.map((i) => i.id),
              );
            } else {
              return (item as ProjectItemFieldsFragment).estimatedItems.map(
                (i) => i.id,
              );
            }
          })
          .flat();
        setSelectedEstimatedItemIds(
          selectedEstimatedItemIds.filter((r) => !idsToRemove.includes(r)),
        );
      }
    },
    [group.items, selectedEstimatedItemIds, setSelectedEstimatedItemIds],
  );

  return (
    <Container>
      <SelectionCheckbox
        testId={`group-checkbox-${group.name.toLowerCase().replaceAll(/\s/g, "-")}`}
        checked={checked}
        setSelection={handleChange}
      />
    </Container>
  );
};
