import { PaginationArgs } from "@/common/components/pagination/PaginationProvider";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { cleanQuery } from "@/common/utils/cacheUtils";
import {
  DeliverySlipStatsDocument,
  DeliverySlipsDocument,
  QueryDeliverySlipsFilter,
  namedOperations,
  useArchiveDeliverySlipMutation,
} from "@/generated/graphql";
import { useDeliverySlipStats } from "../providers/DeliverySlipStatsProvider";

type PaginationProps = {
  pagination: PaginationArgs;
  filter?: QueryDeliverySlipsFilter;
};

export const useArchiveDeliverySlip = (props?: PaginationProps) => {
  const { setError } = useGlobalError();
  const { refetch } = useDeliverySlipStats();

  const [archiveDeliverySlipMutation] = useArchiveDeliverySlipMutation();
  const archiveDeliverySlip = async (id: string) => {
    try {
      const { errors } = await archiveDeliverySlipMutation({
        variables: {
          id,
        },
        awaitRefetchQueries: true,
        refetchQueries: props
          ? [
              {
                query: DeliverySlipsDocument,
                variables: {
                  ...props.pagination,
                  filter: props.filter,
                },
                fetchPolicy: "network-only",
              },
              {
                query: DeliverySlipStatsDocument,
              },
            ]
          : [],
        update: (cache) => {
          cleanQuery(cache, namedOperations.Query.DeliverySlips);
        },
      });
      await refetch();
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return { archiveDeliverySlip };
};
