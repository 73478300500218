import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useEnterprisesQuery } from "@/generated/graphql";

export const useEnterprises = () => {
  const { data, loading, error, refetch } = useEnterprisesQuery();

  useErrorEffect(error);

  const refetchOrgs = async () => {
    await refetch();
  };

  return {
    orgs: data?.enterprises.flatMap((enterprise) => enterprise.orgs) || [],
    loading,
    error: !!error,
    refetchOrgs,
  };
};
