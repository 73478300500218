import { Dialog } from "@/common/components/dialog/Dialog";
import { Loader } from "@/common/components/loader/Loader";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutProjectItemsWithUpdates } from "../providers/BuyoutProjectItemsProvider";
import { useBuyoutProjectListItemConfiguration } from "./BuyoutProjectListItemConfiguration";
import { ProjectItems } from "./ProjectItems";

const Container = tw.div`w-[calc(80vw)] h-[calc(80vh)] flex-1 overflow-scroll flex flex-col`;

interface AddEstimatedItemsDialogProps {
  setShowEstimatedItemsDialog: (value: boolean) => void;
}

export const AddEstimatedItemsDialog = ({
  setShowEstimatedItemsDialog,
}: AddEstimatedItemsDialogProps) => {
  const intl = useIntl();
  const { addToBuyout, isAdding, updates } = useBuyoutProjectItemsWithUpdates();
  const { buyout, loading } = useContractorBuyout();
  const { setSuccessAlert, setWarningAlert } = useSnackbar();

  const handleOnCancel = useCallback(() => {
    setShowEstimatedItemsDialog(false);
  }, [setShowEstimatedItemsDialog]);

  const handleAddToBuyout = useCallback(async () => {
    if (updates.length === 0) {
      setWarningAlert(<FormattedMessage id={`NOTHING_TO_ADD`} />);
      return;
    }
    if (await addToBuyout()) {
      setSuccessAlert(intl.$t({ id: "ITEMS_ADDED_TO_BUYOUT" }));
      setShowEstimatedItemsDialog(false);
    }
  }, [
    addToBuyout,
    intl,
    setShowEstimatedItemsDialog,
    setSuccessAlert,
    setWarningAlert,
    updates,
  ]);

  const configuration = useBuyoutProjectListItemConfiguration({ buyout });

  if (!buyout || loading) {
    return <Loader loading />;
  }

  return (
    <Dialog
      title={<FormattedMessage id={"ADD_ITEMS_TO_BUYOUT_FROM_ESTIMATES"} />}
      confirmButtonText={intl.$t({
        id: "BUYOUT_ADD_ITEMS",
      })}
      handleConfirm={handleAddToBuyout}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      buttonsConfiguration={{
        cancel: {
          wide: true,
        },
      }}
      extraButtonMargin={false}
      loading={isAdding}
      handleCancel={handleOnCancel}
      maxWidth={false}
      show={true}
      content={
        <Container>
          <ProjectItems
            buyout={buyout}
            columns={configuration}
            loading={loading}
          />
        </Container>
      }
    />
  );
};
