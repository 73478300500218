import { If } from "@/common/components/if/If";
import { EventKeysConstants } from "@/config/constants/eventKeysContants";
import { ProjectStatus } from "@/generated/graphql";
import {
  CloseRounded,
  DoneAllRounded,
  DoneRounded,
  GavelRounded,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { FC, KeyboardEventHandler, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

type DisplayProjectStatusContainerProps = {
  $status: ProjectStatus;
  $isEditable: boolean;
  $isSelected: boolean;
};

export const PROJECT_STATUSES = {
  [ProjectStatus.Active]: {
    icon: GavelRounded,
    label: "PROJECT_STATUS_ACTIVE",
    tooltip: "PROJECT_STATUS_ACTIVE_BID_FOR_JOB",
    color: "bg-yellow-600",
    text: "",
  },
  [ProjectStatus.Awarded]: {
    icon: DoneRounded,
    label: "PROJECT_STATUS_AWARDED",
    tooltip: "PROJECT_STATUS_ACTIVE_AWARDED_JOB",
    color: "bg-green-300",
    text: "",
  },
  [ProjectStatus.Completed]: {
    icon: DoneAllRounded,
    label: "PROJECT_STATUS_COMPLETED",
    tooltip: "PROJECT_STATUS_COMPLETED_AWARDED_JOB",
    color: "bg-green-600",
    text: "text-white",
  },
  [ProjectStatus.Lost]: {
    icon: CloseRounded,
    label: "PROJECT_STATUS_LOST",
    tooltip: "PROJECT_STATUS_LOST_BID_FOR_JOB",
    color: "bg-red-200",
    text: "",
  },
};

const DisplayProjectStatusContainer = tw.div<DisplayProjectStatusContainerProps>`
    flex text-xs font-medium rounded-[3px] px-2 h-8 items-center justify-center min-w-28 whitespace-nowrap
    ${({ $isEditable }: DisplayProjectStatusContainerProps) =>
      !$isEditable ? "pointer-events-none" : "px-1"}
    ${({ $status, $isEditable }: DisplayProjectStatusContainerProps) =>
      $isEditable
        ? "h-10 bg-white cursor-pointer border-solid border border-gray-400 transition-colors"
        : `${PROJECT_STATUSES[$status].text} ${PROJECT_STATUSES[$status].color}`}
    ${({ $isSelected, $status }: DisplayProjectStatusContainerProps) =>
      $isSelected
        ? `${PROJECT_STATUSES[$status].color} ${PROJECT_STATUSES[$status].text} border-white`
        : "hover:bg-gray-200"}
`;
const StyleIconButton = tw(IconButton)`
  w-6 h-6 pointer-events-none 
`;

type DisplayProjectStatusProps = {
  status: ProjectStatus | undefined;
  onClick?: (status: ProjectStatus | null) => void;
  isEditable?: boolean;
  isSelected?: boolean;
};

export const DisplayProjectStatus: FC<DisplayProjectStatusProps> = ({
  status = ProjectStatus.Active,
  onClick,
  isEditable = false,
  isSelected = false,
}) => {
  const intl = useIntl();
  const label = useMemo(() => {
    const labelKey = PROJECT_STATUSES[status].label;
    return intl.$t({ id: labelKey });
  }, [status, intl]);

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (
      [EventKeysConstants.Enter, EventKeysConstants.Space].includes(
        event.key,
      ) &&
      onClick
    ) {
      onClick(status);
    }
  };
  const Icon = PROJECT_STATUSES[status].icon;

  return (
    <DisplayProjectStatusContainer
      onClick={() => onClick && onClick(status)}
      onKeyDown={handleKeyDown}
      $status={status}
      $isEditable={isEditable}
      $isSelected={isSelected}
    >
      <If isTrue={isEditable}>
        <StyleIconButton size="small">
          <Icon fontSize="small" />
        </StyleIconButton>
      </If>
      {label}
    </DisplayProjectStatusContainer>
  );
};
