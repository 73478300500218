import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { GridViewToggle } from "@/common/components/grid-view-toggle/GridViewToggle";
import { If } from "@/common/components/if/If";
import { SpreadsheetSaveType } from "@/common/providers/ColumnMapperProvider";
import { VIEW_STATE, useTableView } from "@/common/providers/TableViewProvider";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useProject } from "../providers/ProjectProvider";
import { useSyncEstimatedItems } from "../providers/SyncEstimatedItemsProvider";
import { ProjectAddItems } from "./project-add-items/ProjectAddItems";
import { UploadMaterialsDrawer } from "./upload-materials/UploadMaterialsDrawer";

const FooterContent = tw.div`grid grid-cols-[80px_1fr_auto] gap-4 w-full h-10 items-center`;
const SwitchContainer = tw.div`flex justify-start`;

export const ProjectContentFooter = () => {
  const { syncEstimatedItems, saving } = useSyncEstimatedItems();
  const { tableView, setTableView } = useTableView();
  const { project } = useProject();

  useEffect(() => {
    if (project?.estimatedItemGroups.length === 0) {
      setTableView(VIEW_STATE.spreadsheet);
    }
  }, [project, setTableView]);

  if (!project) {
    return null;
  }

  return (
    <FloatingFooter>
      <FooterContent>
        <ProjectAddItems project={project} />
        <span />
        <SwitchContainer>
          <GridViewToggle saveState={syncEstimatedItems} saving={saving} />
        </SwitchContainer>
        <If isTrue={tableView === VIEW_STATE.spreadsheet}>
          <PrimaryButton
            onClick={() => syncEstimatedItems(SpreadsheetSaveType.SaveButton)}
          >
            <FormattedMessage id="SAVE_CHANGES" />
          </PrimaryButton>
        </If>
      </FooterContent>
      <UploadMaterialsDrawer project={project} />
    </FloatingFooter>
  );
};
