import {
  TimePicker as MuiTimePicker,
  TimePickerProps,
} from "@mui/x-date-pickers/TimePicker";
import { FC } from "react";

type Props = TimePickerProps<Date>;

export const TimePicker: FC<Props> = (props) => {
  return (
    <MuiTimePicker
      {...props}
      slotProps={{
        textField: { size: "small" },
      }}
      format="hh:mm a"
    />
  );
};
