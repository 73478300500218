import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { ControlledProps } from "@/common/components/textfield-controlled/TextFieldControlled";
import { countries } from "@/common/utils/countries";
import { FC } from "react";
import { useIntl } from "react-intl";

export const ProjectCountrySelector: FC<ControlledProps> = (props) => {
  const intl = useIntl();
  return (
    <SelectControlled
      {...props}
      placeholder={intl.$t({ id: "COUNTRY" })}
      options={countries}
      rules={{ required: true }}
      getLabel={(option) => option.name}
      getValue={(option) => option.abbreviation}
    />
  );
};
