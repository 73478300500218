export const threeStateResult = (
  value: boolean | null | undefined,
  { yes, no, notDefined }: { yes: string; no: string; notDefined: string },
): string => {
  if (value) {
    return yes;
  } else if (value === false) {
    return no;
  }
  return notDefined;
};
