import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import tw from "tailwind-styled-components";

export const UserProfileHeader = tw.div`text-lg font-bold border-b border-gray-400`;
export const ButtonsContainerStyled = tw(
  ButtonsContainer,
)`grid-cols-auto justify-end`;
export const Form = tw.div`grid grid-cols-[auto] mt-10 w-1/3 min-w-96 gap-4`;
export const Item = tw.div`flex flex-row justify-between`;
export const Label = tw.div`whitespace-nowrap`;
export const Value = tw.div`flex justify-end`;
