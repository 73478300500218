import { ProjectStatus } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const useProjectStatusOptions = () => {
  const intl = useIntl();

  return useMemo(
    () =>
      Object.values(ProjectStatus).map((id) => ({
        id,
        name: intl.$t({ id: `PROJECT_STATUS_${id}` }),
      })),
    [intl],
  );
};
