import { Price } from "@/common/components/price/Price";
import {
  BuyoutStandaloneReleaseItemFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { OrderedContainer } from "./Buyout.styles";

const Container = tw(OrderedContainer)`text-xs`;

type Props = {
  items: BuyoutStandaloneReleaseItemFieldsFragment[];
  releaseStatuses: ReleaseStatus[];
};

export const BuyoutStandaloneReleaseItemAmountsCount: FC<Props> = ({
  items,
  releaseStatuses,
}) => {
  const costCodeReceivedTotal = useMemo(
    () =>
      items.reduce((acc, i) => {
        let itemTotal = 0;
        i.releaseItems.forEach((ri) => {
          if (releaseStatuses.includes(ri.release.status)) {
            itemTotal += new Decimal(ri.quantityDecimal)
              .mul(ri.unitPrice || 0)
              .toNumber();
          }
        });

        return acc.add(itemTotal);
      }, new Decimal(0)),
    [items, releaseStatuses],
  );

  return (
    <Container>
      <Price price={costCodeReceivedTotal.toNumber()} />
    </Container>
  );
};
