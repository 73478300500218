import { CheckOutlined } from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { BaseButton } from "./BaseButton";
import { IconButtonBorderless } from "./IconButton";

const CheckIcon = tw(CheckOutlined)`w-5 h-5`;
type CheckButtonProps = React.ComponentProps<typeof BaseButton>;

export const CheckButton: FC<CheckButtonProps> = ({ ...props }) => {
  return (
    <IconButtonBorderless autoScale {...props} testId="check-button">
      <CheckIcon />
    </IconButtonBorderless>
  );
};
