import { FC } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";

type Props = {
  count?: number | string;
  warning?: boolean;
  className?: string;
  innerClassName?: string;
};

type ContainerProps = {
  $warning?: boolean;
  $empty?: boolean;
};

const CircleCounterContainer = tw.span`flex`;

const Counter = tw.span`
  grid place-items-center text-center font-bold
  min-w-6 w-fit px-1.5 h-6 text-white bg-black rounded-2xl mx-auto text-2xs
  ${({ $warning }: ContainerProps) => ($warning ? "bg-red-500" : "")}
  ${({ $empty }: ContainerProps) => ($empty ? "hidden" : "")}
`;

export const CircleCounter: FC<Props> = ({
  count,
  className,
  innerClassName,
  warning = false,
}) => {
  return (
    <CircleCounterContainer
      className={className}
      data-testid={`circle-counter-${count}`}
    >
      <If isTrue={count}>
        <Counter
          $warning={warning}
          className={innerClassName}
          $empty={count === 0}
        >
          {count}
        </Counter>
      </If>
    </CircleCounterContainer>
  );
};
