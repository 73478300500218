import { If } from "@/common/components/if/If";
import { FC } from "react";
import { AssignableBuyoutWithBestMatch } from "../../../providers/AssignBuyoutProvider";
import { ExpandedRelease } from "../../../providers/ReleaseProvider";
import { BuyoutsItemsComparisonTable } from "./BuyoutsItemsComparisonTable";

export const ExpandedItemComponent: FC<{
  item: AssignableBuyoutWithBestMatch;
  isExpanded: boolean;
  release: ExpandedRelease | null | undefined;
}> = ({ item, isExpanded, release }) => {
  return (
    <If isTrue={isExpanded}>
      <BuyoutsItemsComparisonTable release={release} newBuyout={item.buyout} />
    </If>
  );
};
