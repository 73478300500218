import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import { ListDatesButton } from "@/common/components/list-dates-button/ListDatesButton";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { PopoverWithTabs } from "@/common/components/popover-with-tabs/PopoverWithTabs";
import { ProjectsFilterSelector } from "@/common/components/projects-filter-selector/ProjectsFilterSelector";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { SearchTooltip } from "@/common/components/search-input/SearchTooltip";
import { StatusesFilter } from "@/common/components/status-filter/StatusesFilter";
import { VendorsFilterSelector } from "@/common/components/vendors-filter-selector/VendorsFilterSelector";
import { InvoiceHeader } from "@/contractor/pages/home/invoices/common/components/InvoiceHeader";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { InvoiceStatus } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoices } from "../../providers/InvoicesProvider";
import { InvoiceListActions } from "../invoice-list-actions/InvoiceListActions";
import { InvoicesAdditionalFilters } from "./InvoicesAdditionalFilters";

const Filters = tw.div`grid grid-flow-col gap-2 items-center`;

export const ScannedInvoicesHeader = () => {
  const intl = useIntl();
  const { filter, setFilter } = useInvoices();
  const { projects } = useProjectListOptions({
    includeClosedProjects: filter?.closedProjects,
  });

  const options = useMemo(() => {
    return [
      {
        value: InvoiceStatus.Approved,
        name: intl.$t({ id: "INVOICE_STATUS_APPROVED" }),
      },
      {
        value: InvoiceStatus.AwaitingApproval,
        name: intl.$t({ id: "INVOICE_STATUS_AWAITING_APPROVAL" }),
      },
      {
        value: InvoiceStatus.InternalKickback,
        name: intl.$t({ id: "INVOICE_STATUS_INTERNAL_KICKBACK" }),
      },
      {
        value: InvoiceStatus.IssuesReported,
        name: intl.$t({ id: "INVOICE_STATUS_ISSUES_REPORTED" }),
      },
      {
        value: InvoiceStatus.Paid,
        name: intl.$t({ id: "INVOICE_STATUS_PAID" }),
      },
    ];
  }, [intl]);

  const setFilterHandler = useCallback(
    (values: InvoiceStatus[] | undefined) => {
      setFilter({
        ...filter,
        statuses: values,
      });
    },
    [filter, setFilter],
  );

  const isDefaultFilter = useMemo(() => {
    return (
      filter?.kickbackToMe == null &&
      filter?.poExported == null &&
      filter?.receiptPosted == null &&
      filter?.archived === false &&
      filter.exported === false
    );
  }, [
    filter?.archived,
    filter?.poExported,
    filter?.receiptPosted,
    filter?.exported,
    filter?.kickbackToMe,
  ]);

  return (
    <InvoiceHeader>
      <Filters>
        <SearchInput
          placeHolder={intl.$t({ id: "SEARCH" })}
          onChange={(value: string) => {
            if (filter?.search ?? value !== "") {
              setFilter({ ...filter, search: value });
            }
          }}
          value={filter?.search}
          info={<SearchTooltip text="INVOICES_SEARCH_INFO" />}
        />
        <ProjectsFilterSelector
          projects={projects}
          onChange={(projectId) =>
            setFilter({
              ...filter,
              projectIds: projectId ? [projectId] : undefined,
            })
          }
          value={filter?.projectIds?.[0]}
        />
        <VendorsFilterSelector
          value={filter?.sellerOrgLocationIds?.[0]}
          onChange={(value) => {
            setFilter({
              ...filter,
              sellerOrgLocationIds: value ? [value] : undefined,
            });
          }}
        />
        <StatusesFilter
          options={options}
          selectedStatuses={filter?.statuses}
          onChange={setFilterHandler}
        />
        <ListHeaderFilterButton
          isDefault={isDefaultFilter}
          filter={<InvoicesAdditionalFilters />}
        />
        <ListDatesButton
          dates={[
            {
              startDate: filter?.minIssueDate,
              endDate: filter?.maxIssueDate,
            },
          ]}
          clearValues={() =>
            setFilter({
              ...filter,
              minIssueDate: undefined,
              maxIssueDate: undefined,
            })
          }
        >
          {(onClose) => (
            <PopoverWithTabs
              tabs={[
                {
                  label: intl.$t({ id: "ISSUE_DATE" }),
                  content: (
                    <DateRangePicker
                      staticView
                      onClose={onClose}
                      selectedValue={
                        filter?.maxIssueDate || filter?.minIssueDate
                          ? {
                              startDate: filter?.minIssueDate
                                ? new Date(filter.minIssueDate)
                                : new Date(),
                              endDate: filter?.maxIssueDate
                                ? new Date(filter.maxIssueDate)
                                : new Date(),
                            }
                          : undefined
                      }
                      isUTC
                      onChange={(dateRange) =>
                        setFilter({
                          ...filter,
                          minIssueDate: dateRange.startDate?.getTime(),
                          maxIssueDate: dateRange.endDate?.getTime(),
                        })
                      }
                    />
                  ),
                  viewState: "issueDate",
                },
              ]}
              activeTab="issueDate"
            />
          )}
        </ListDatesButton>
      </Filters>
      <InvoiceListActions />
    </InvoiceHeader>
  );
};
