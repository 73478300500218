import { gql } from "@apollo/client";

export const INVOICED_RELEASE_ITEM_FIELDS = gql`
  fragment InvoicedReleaseItemFields on InvoicedReleaseItem {
    id
    quantity
    unitPrice
    quantitySoFar
  }
`;
