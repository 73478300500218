import { Price } from "@/common/components/price/Price";
import { sumQuoteGroupFullPrices } from "@/common/components/price/utils/sumQuoteGroupFullPrices";
import { FC, useMemo } from "react";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../providers/DistributorQuoteItemsProvider";

type DistributorQuoteItemToggleProps = {
  items: DistributorQuoteItemGroupExtendedFieldsFragment[];
};

export const DistributorQuoteCategoryTotal: FC<
  DistributorQuoteItemToggleProps
> = ({ items }) => {
  const price = useMemo(
    () => items.reduce(sumQuoteGroupFullPrices, 0),
    [items],
  );

  return <Price price={price} />;
};
