import { GridTable } from "@/common/components/grid-table/GridTable";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { FC } from "react";
import { useCostTypes } from "../hooks/useCostTypes";
import { CostType } from "../providers/CostTypesListProvider";
import { useCostTypesConfiguration } from "./CostTypes.config";

type Props = {
  items: CostType[];
};

export const CostTypeList: FC<Props> = ({ items }) => {
  const { configuration } = useCostTypesConfiguration();
  const { loading } = useCostTypes();

  return (
    <ListRenderer>
      <GridTable
        configuration={{
          columns: configuration,
          classNames: {
            header: "top-[134px] bg-transparent",
          },
        }}
        items={items}
        loading={loading}
        emptyList={
          <NoResults
            isFiltered={false}
            translationKey="COST_TYPE_LIST_NO_ITEMS"
          />
        }
      />
    </ListRenderer>
  );
};
