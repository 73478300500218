import { If } from "@/common/components/if/If";
import { Switch } from "@/common/components/switch/Switch";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  DistributorInvoiceFieldsFragment,
  InvoiceFieldsFragment,
  InvoiceStatus,
  InvoiceType,
  ReceiptFieldsFragment,
} from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  invoice:
    | DistributorInvoiceFieldsFragment
    | InvoiceFieldsFragment
    | ReceiptFieldsFragment
    | null;
  type: InvoiceType;
  onChange: (value: boolean) => void;
};

const Container = tw.div`flex gap-2 pr-1.5 py-1.5 pl-4 rounded-4xl items-center font-normal`;
const Text = tw.span`text-white font-normal`;
const InfoIcon = tw(InfoOutlined)`text-blue-500`;

export const InvoicePaidToggle: FC<Props> = ({ invoice, type, onChange }) => {
  const intl = useIntl();
  const { setWarningAlert } = useSnackbar();

  const [isPaid, setIsPaid] = useState(
    type === InvoiceType.Invoice
      ? invoice?.status === InvoiceStatus.Paid
      : !invoice?.prePaid,
  );

  const togglePaid = useCallback(
    (value: boolean) => {
      if (
        type === InvoiceType.Receipt &&
        !!invoice?.release &&
        value === false
      ) {
        setWarningAlert(intl.$t({ id: "RECEIPT_PAID_TOGGLE_WARNING" }));
        return;
      }
      setIsPaid(value);
      onChange(value);
    },
    [type, invoice?.release, intl, onChange, setWarningAlert],
  );

  return (
    <Container className={type === InvoiceType.Invoice ? "bg-blue-800" : ""}>
      <If isTrue={type === InvoiceType.Invoice}>
        <FormattedMessage id={"INVOICE_PAID"} tagName={Text} />
      </If>
      <If isTrue={type === InvoiceType.Receipt}>
        <FormattedMessage id="WILL_THIS_RECEIPT_BE_PAID_VIA_INVOICE" />
        <Tooltip id="receipt-info" element={<InfoIcon />}>
          <FormattedMessage id="WILL_THIS_RECEIPT_BE_PAID_VIA_INVOICE_INFO" />
        </Tooltip>
      </If>
      <Switch
        width={60}
        offLabel={intl.$t({ id: "NO" })}
        onLabel={intl.$t({ id: "YES" })}
        onChange={togglePaid}
        value={isPaid}
      />
    </Container>
  );
};
