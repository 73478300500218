import { CategoryState } from "@/common/hooks/useToggleCategory";
import { Card } from "@/common/layout/ResponsiveClasses";
import { useLocale } from "@/common/providers/LocaleProvider";
import { Identity } from "@/types/Identity";
import {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { If } from "../if/If";
import { SearchInput } from "../search-input/SearchInput";
import {
  Category,
  CategoryCardContent,
  CloseStyled,
  DefaultContainer,
  HeaderCard,
  HeaderCardContent,
  HeaderCardWrapper,
  HeaderSearch,
  LoaderStyled,
  TableContent,
} from "./GridTable.styles";
import { GridTableCategory } from "./GridTableCategory";
import { GridItem } from "./grid-item/GridItem";
import { GridCol } from "./types/GridCol";
import { RenderType } from "./types/RenderType";
import { CategoryType, getCategoryType } from "./utils/getCategoryType";

const GRID_MIN_ITEM_NUMBER_TO_VIRTUALIZE = 100;

export enum GridSortDirection {
  Ascending,
  Descending,
}

export type GridTableConfiguration<T extends Identity, TAdditional> = {
  container?: FC<PropsWithChildren>;
  columns: Array<GridCol<T, TAdditional>>;
  classNames?: {
    root?: string;
    container?: string;
    header?: string;
    category?: string;
    subCategory?: string;
    item?: string;
    itemContent?: string;
    detailsContent?: string;
    details?: string;
    subdetails?: string;
    subdetailsContent?: string;
    itemFn?: (
      item: T,
      category: CategoryState<T> | undefined,
      index?: number,
      editMode?: boolean,
    ) => { error?: boolean; className: string };
  };
  toggle?: {
    category?: (id: string) => void;
    subCategory?: (id: string, categoryId: string) => void;
    item?: (item: T) => void;
  };
  inEditModeFn?: (item: T, index?: number, editMode?: boolean) => boolean;
  hasCardItems?: boolean;
  hasRowError?: (item: T, index?: number) => boolean;
  onSort?: (column: GridCol<T, TAdditional>) => void;
  useLocalPagination?: boolean;
};

type Props<T extends Identity, TAdditional> = {
  items?: CategoryState<T>[] | CategoryState<CategoryState<T>>[] | Array<T>;
  configuration: GridTableConfiguration<T, TAdditional>;
  readonly?: boolean;
  readonlyFn?: (
    additionalItem: TAdditional | undefined,
    item?: T | undefined,
  ) => boolean;
  hideGroup?: boolean;
  hideFirstDetail?: boolean;
  expandedItems?: (item: T) => T[];
  expandedDetailItems?: (item: T) => T[];
  expandedItemComponent?: (item: T) => JSX.Element;
  preRowItemComponent?: (item: T) => JSX.Element;
  showSubdetailsIfNoDetailItems?: (item: T) => boolean;
  error?: boolean;
  virtualized?: boolean;
  loading?: boolean;
  emptyList?: string | ReactNode;
  hideHeader?: boolean;
  virtualizedItemsCount?: number;
  footer?: ReactNode;
  onSort?: (
    column: GridCol<T, TAdditional>,
    direction: GridSortDirection,
  ) => void;
  useLocalPagination?: boolean;
  disableSorting?: boolean;
};

export const GridTable = <T extends Identity, TAdditional = undefined>({
  items,
  configuration,
  readonly = false,
  readonlyFn,
  hideGroup = false,
  hideFirstDetail = false,
  expandedItems = () => [],
  expandedDetailItems = () => [],
  expandedItemComponent,
  preRowItemComponent,
  showSubdetailsIfNoDetailItems = () => false,
  error = false,
  virtualized = true,
  loading = false,
  emptyList,
  hideHeader = false,
  virtualizedItemsCount = GRID_MIN_ITEM_NUMBER_TO_VIRTUALIZE,
  footer,
  onSort,
  useLocalPagination,
  disableSorting,
}: Props<T, TAdditional>) => {
  const { selectedLocale } = useLocale();

  const intl = useIntl();
  const [searchText, setSearchText] = useState("");
  const [activeSearchColumn, setActiveSearchColumn] = useState<
    GridCol<T, TAdditional> | undefined
  >();
  const [activeSortColumn, setActiveSortColumn] = useState<
    GridCol<T, TAdditional> | undefined
  >();
  const [sortDirection, setSortDirection] =
    useState<GridSortDirection | null>();
  const [editing, setEditing] = useState(false);
  const countMap: Map<string, number> = new Map();

  const Container = useMemo(
    () => configuration.container || DefaultContainer,
    [configuration.container],
  );

  const totalItems = useMemo(() => {
    if (!items || items.length === 0) {
      return 0;
    }
    const categoryType = getCategoryType(items[0]);
    if (categoryType === CategoryType.TWO_LEVELS) {
      return (items as Array<CategoryState<T>>).reduce(
        (acc: number, item: CategoryState<T>) => {
          return acc + item.items.length;
        },
        0,
      );
    }
    if (categoryType === CategoryType.THREE_LEVELS) {
      return (items as Array<CategoryState<CategoryState<T>>>).reduce(
        (acc: number, item: CategoryState<CategoryState<T>>) => {
          return (
            acc +
            item.items.reduce((subAcc, subCategory) => {
              return subAcc + subCategory.items?.length || 0;
            }, 0)
          );
        },
        0,
      );
    }

    return items.length;
  }, [items]);

  const filteredItems = useMemo(() => {
    if (!activeSearchColumn?.searchItemFn && !activeSortColumn?.sortItemFn) {
      return items;
    }
    if (!items || items.length === 0) {
      return [];
    }
    const sortFunction =
      !disableSorting && !useLocalPagination && activeSortColumn?.sortItemFn
        ? sortDirection === GridSortDirection.Ascending
          ? activeSortColumn?.sortItemFn
          : (a: T, b: T) => -(activeSortColumn?.sortItemFn?.(a, b) || 0)
        : undefined;
    const categoryType = getCategoryType(items[0]);
    if (categoryType === CategoryType.ONE_LEVEL) {
      const result = (items as T[]).filter((item) =>
        activeSearchColumn?.searchItemFn
          ? activeSearchColumn?.searchItemFn(
              item as T,
              searchText,
              selectedLocale?.toString(),
            )
          : true,
      );
      if (sortFunction) {
        return result.toSorted(sortFunction);
      }

      return result;
    }
    if (categoryType === CategoryType.TWO_LEVELS) {
      return (items as CategoryState<T>[])
        .map((category) => {
          const categoryItems = category.items.filter((i) =>
            activeSearchColumn?.searchItemFn
              ? activeSearchColumn?.searchItemFn(
                  i as T,
                  searchText,
                  selectedLocale?.toString(),
                )
              : true,
          );
          return {
            ...category,
            items: sortFunction
              ? categoryItems.toSorted(sortFunction)
              : categoryItems,
          };
        })
        .filter((category) => (category as CategoryState<T>).items.length > 0);
    }
    if (categoryType === CategoryType.THREE_LEVELS) {
      return (items as CategoryState<CategoryState<T>>[])
        .map((category) => ({
          ...category,
          items: category.items
            .map((subCategory) => {
              const subCategoryItems = subCategory.items.filter((item) =>
                activeSearchColumn?.searchItemFn
                  ? activeSearchColumn?.searchItemFn(
                      item as T,
                      searchText,
                      selectedLocale?.toString(),
                    )
                  : true,
              );
              return {
                ...subCategory,
                items: sortFunction
                  ? subCategoryItems.toSorted(sortFunction)
                  : subCategoryItems,
              };
            })
            .filter((subCategory) => subCategory.items.length > 0),
        }))
        .filter((category) => category.items.length > 0);
    }
    return items;
  }, [
    activeSearchColumn,
    activeSortColumn,
    disableSorting,
    items,
    searchText,
    selectedLocale,
    sortDirection,
    useLocalPagination,
  ]);

  const setSortDirectionFn = useCallback(
    (column: GridCol<T, TAdditional>) => {
      let newDirection = GridSortDirection.Ascending;
      if (activeSortColumn === column) {
        newDirection =
          sortDirection === GridSortDirection.Ascending
            ? GridSortDirection.Descending
            : GridSortDirection.Ascending;
        setSortDirection(newDirection);
      }
      setSortDirection(newDirection);
      setActiveSortColumn(column);
      onSort?.(column, newDirection);
    },
    [activeSortColumn, onSort, sortDirection],
  );

  return (
    <Container className={configuration.classNames?.container}>
      <If isTrue={!hideHeader}>
        <HeaderCardWrapper className={configuration.classNames?.header}>
          <HeaderCard>
            <If isTrue={activeSearchColumn}>
              <HeaderSearch>
                <If isTrue={typeof activeSearchColumn?.header === "string"}>
                  <FormattedMessage id="SEARCH" /> {activeSearchColumn?.header}
                </If>
                <SearchInput
                  placeHolder={intl.$t({ id: "SEARCH" })}
                  value={searchText}
                  onChange={setSearchText}
                />
                <CloseStyled
                  onClick={() => {
                    setActiveSearchColumn(undefined);
                    setSearchText("");
                  }}
                />
              </HeaderSearch>
            </If>
            <HeaderCardContent>
              <GridItem
                className="text-center text-sm font-medium"
                columns={configuration.columns}
                index={0}
                renderType={RenderType.Header}
                setActiveSearchColumn={setActiveSearchColumn}
                setActiveSortColumn={setSortDirectionFn}
                sortedColumn={activeSortColumn}
                sortDirection={sortDirection}
                disableSorting={disableSorting || editing}
                category={{
                  items: filteredItems as Array<T>,
                  id: "header",
                  name: "header",
                  isOpened: true,
                }}
              />
            </HeaderCardContent>
          </HeaderCard>
        </HeaderCardWrapper>
      </If>
      <LoaderStyled loading={loading}>
        <TableContent className={configuration.classNames?.root}>
          {filteredItems?.map((category, index) => (
            <GridTableCategory
              key={index}
              index={index}
              category={category}
              configuration={configuration}
              readonly={readonly}
              readonlyFn={readonlyFn}
              hideGroup={hideGroup}
              expandedItems={expandedItems}
              expandedDetailItems={expandedDetailItems}
              expandedItemComponent={expandedItemComponent}
              preRowItemComponent={preRowItemComponent}
              showSubdetailsIfNoDetailItems={showSubdetailsIfNoDetailItems}
              hideFirstDetail={hideFirstDetail}
              error={error}
              virtualized={virtualized && totalItems > virtualizedItemsCount}
              countMap={countMap}
              setEditing={setEditing}
            />
          ))}
        </TableContent>
        <If isTrue={items?.length === 0}>{emptyList}</If>
        <If isTrue={configuration.columns.some((c) => c.footer)}>
          <Category>
            <Card className="rounded-b-2xl rounded-t-none bg-gray-100 pt-5">
              <CategoryCardContent className="gap-0 px-0">
                <GridItem
                  key="footer"
                  renderType={RenderType.Footer}
                  columns={configuration.columns}
                  className="gap-0"
                />
              </CategoryCardContent>
              {footer}
            </Card>
          </Category>
        </If>
        <If isTrue={!configuration.columns.some((c) => c.footer) && !!footer}>
          {footer}
        </If>
      </LoaderStyled>
    </Container>
  );
};
