import { gql } from "@apollo/client";

export const TAG_FIELDS = gql`
  fragment TagFields on ProjectTag {
    id
    name
    color
    deletedAt
    hasMapping
    mapping {
      externalCostCode {
        code
        name
      }
      externalCostType {
        code
      }
      sourceSystem
    }
  }
`;
