import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { If } from "@/common/components/if/If";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import { ReleaseItemPrice } from "@/common/components/release-item-price/ReleaseItemPrice";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  Base,
  MaterialContainer,
  MdFixedContainer,
  SmContainer,
} from "@/common/layout/ResponsiveClasses";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import {
  DistributorReleaseFieldsFragment,
  DistributorReleaseItemFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { DistributorReleaseManufacturerSelector } from "../../../components/DistributorReleaseManufacturerSelector";
import { DistributorGroupByCostCodeToggle } from "../../../components/distributor-group-by-costcode/DistributorGroupByCostCodeToggle";
import { DistributorReleaseItem } from "../../../components/list/DistributorReleaseItem";
import { DistributorReleaseItemIssues } from "../components/DistributorReleaseItemIssues";
import { DistributorReleaseItemIssuesButton } from "../components/DistributorReleaseItemIssuesButton";

const IssueCounter = tw.div`text-red-500`;
const ResolveIssueContainer = tw(Base)`basis-32 px-0`;
const ProductContainer = tw(MaterialContainer)`basis-full xl:basis-1/5 xl:w-1/5
  2xl:basis-2/5 2xl:w-2/5`;

export const useDistributorReleaseDeliveryRecordListConfiguration = (
  release: DistributorReleaseFieldsFragment | null | undefined,
) => {
  const { getVendorHasManufacturerSetting } = useManufacturersSettings();
  const hasManufacturersSetting = getVendorHasManufacturerSetting(
    release?.orgLocation?.org.settings?.display,
  );
  const configuration: Array<GridCol<DistributorReleaseItemFieldsFragment>> =
    useMemo(() => {
      return [
        {
          wrapper: ProductContainer,
          item: ({ item, count }) => (
            <DistributorReleaseItem item={item} count={count} />
          ),
          group: (category) => (
            <ProductCategory
              type={ProductCategoryType.Zone}
              category={category}
              items={category.items.length}
            />
          ),
          subgroup: (category) => (
            <ProductCategory
              type={ProductCategoryType.CostCode}
              category={category}
              items={category.items.length}
            />
          ),
          header: (
            <>
              <FormattedMessage id="REQUESTED_ITEMS" />
              <DistributorGroupByCostCodeToggle />
            </>
          ),
        },
        {
          wrapper: MdFixedContainer,
          item: ({ item, index }) => (
            <DistributorReleaseManufacturerSelector
              item={item}
              index={index}
              readonly
            />
          ),
          position: "center",
          header: <FormattedMessage id="PROVIDED_MANUFACTURER" />,
        },
        {
          wrapper: MdFixedContainer,
          item: ({ item }) => (
            <ValueUnit value={item.quantityDecimal} uom={item.uom} />
          ),
          group: (category) => (
            <ProductCategoryCount
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              itemsLength={category.items.flatMap((i: any) => i.items).length}
              $bold
            />
          ),
          subgroup: (category) => (
            <ProductCategoryCount itemsLength={category.items.length} />
          ),
          header: <FormattedMessage id="DELIVERED_QUANTITY" />,
          position: "center",
          hidden: !hasManufacturersSetting,
        },
        {
          wrapper: SmContainer,
          position: "center",
          header: <FormattedMessage id="ORDER_RECEIVED_SO_FAR" />,
          item: ({ item }) => (
            <ValueUnit value={item.receivedQuantityDecimal} uom={item.uom} />
          ),
        },
        {
          wrapper: SmContainer,
          item: ({ item }) => <ReleaseItemPrice item={item} />,
          header: <FormattedMessage id="UNIT_COST" />,
          position: "center",
        },
        {
          wrapper: SmContainer,
          item: ({ item }) => <DistributorReleaseItemIssues item={item} />,
          subgroup: (category) => {
            const issueCount = category.items.filter(
              (i) => i.issues && !i.issues?.[0]?.resolution,
            ).length;
            return (
              <If isTrue={issueCount > 0}>
                <IssueCounter>{issueCount}</IssueCounter>
              </If>
            );
          },
          header: <FormattedMessage id="ISSUE" />,
          position: "center",
        },
        {
          wrapper: ResolveIssueContainer,
          item: ({ item }) => (
            <If isTrue={item.issues.length}>
              <DistributorReleaseItemIssuesButton item={item} />
            </If>
          ),
          header: null,
          position: "center",
        },
      ];
    }, [hasManufacturersSetting]);

  return configuration;
};
