import { InfoOutlined } from "@mui/icons-material";
import { useIntl } from "react-intl";
import {
  SimpleRowDetails,
  SimpleStepCol,
  SimpleStepContainer,
  SimpleStepContent,
  SimpleStepRow,
  SimpleStepTitle,
  SimpleStepTitleContainer,
  StyledTextField,
} from "../../../Wizard.styles";
import { useAccountingOnPremiseWizard } from "../../AccountingOnPremiseWizardProvider";

export const AccountingOnPremiseGLAccountSettings = () => {
  const intl = useIntl();
  const { glAccountNumber, setGLAccountNumber } =
    useAccountingOnPremiseWizard();

  return (
    <SimpleStepContainer>
      <SimpleStepRow>
        <InfoOutlined className="mr-4 h-12 w-12 text-gray-400" />
        <SimpleStepTitleContainer>
          <SimpleStepTitle>
            {intl.$t({ id: "SPECIFY_GL_ACCOUNTS_TITLE" })}
          </SimpleStepTitle>
        </SimpleStepTitleContainer>
      </SimpleStepRow>
      <SimpleStepContent $narrow>
        <SimpleStepCol>
          <StyledTextField
            label={intl.$t({ id: "GL_ACCOUNT_NUMBER" })}
            value={glAccountNumber}
            onChange={(event) => setGLAccountNumber(event.target.value)}
            required
          />
          <SimpleRowDetails>
            {intl.$t({ id: "GL_ACCOUNT_NUMBER_DESCRIPTION" })}
          </SimpleRowDetails>
        </SimpleStepCol>
      </SimpleStepContent>
    </SimpleStepContainer>
  );
};
