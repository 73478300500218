import { DeleteButton } from "@/common/components/button/DeleteButton";
import { EditButton } from "@/common/components/button/EditButton";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { IntegrationTypeContainer } from "@/contractor/pages/admin/integrations/components/Integrations.styles";
import { IntegrationLogo } from "@/contractor/pages/admin/integrations/components/form/components/IntegrationLogo";
import { useExternalProjectMapping } from "@/contractor/pages/home/project/components/project-mapping/ExternalProjectMappingProvider";
import { useAgaveExternalProjectProvider } from "@/contractor/pages/home/project/providers/AgaveExternalProjectProvider";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { SourceSystem, useExternalProjectQuery } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div``;
const Connection = tw.div`bg-white rounded-xl flex flex-col justify-center items-center text-gray-600 text-xs cursor-pointer mr-4 h-24 px-6 pt-1 z-30`;
const ConnectionTopView = tw.div`flex flex-row items-center border-b border-dashed`;
const ConnectionBottomView = tw.div`flex self-stretch flex-1`;
const ConnectionActionButtons = tw.div`flex flex-row items-center`;
const Name = tw.div`flex flex-1 items-center justify-center`;
const ConnectButton = tw.div`relative bottom-7 h-7 text-blue-500 flex items-center justify-center mr-4 text-sm pb-2`;

export type ExternalProjectConnection = {
  logo: string;
  name: string;
  type: string | null | undefined;
  sourceSystem: SourceSystem;
  isMapped: boolean;
  isConnected: boolean;
  externalId: string | undefined;
};

type Props = {
  item: ExternalProjectConnection;
};

export const ProjectFormProjectConnection: FC<Props> = ({ item }) => {
  const intl = useIntl();
  const { project } = useProject();
  const { openModal } = useExternalProjectMapping();
  const { disconnectProject } = useAgaveExternalProjectProvider();

  const { data, loading } = useExternalProjectQuery({
    variables: {
      input: {
        externalId: item.externalId ?? "",
        sourceSystem: item.sourceSystem,
      },
    },
    skip: !item.externalId,
  });

  return (
    <Container>
      <Connection
        className={
          item.isMapped
            ? "cursor-default"
            : "cursor-pointer border-0.5 border-dashed border-gray-600 opacity-50 hover:shadow-md"
        }
        onClick={
          item.isMapped
            ? undefined
            : () =>
                openModal({
                  sourceSystem: item.sourceSystem,
                  selectedExternalProject: item.externalId,
                  isConnected: !!item.externalId,
                })
        }
      >
        <ConnectionTopView>
          <IntegrationLogo url={item.logo} className="mr-2 h-14 w-30" />
          <If isTrue={item.type}>
            <IntegrationTypeContainer $xs>{item.type}</IntegrationTypeContainer>
          </If>
        </ConnectionTopView>
        <ConnectionBottomView>
          <If isTrue={item.isMapped}>
            <If isTrue={!!item.externalId}>
              <If isTrue={loading}>
                <Loader loading={loading} small />
              </If>
              <If isTrue={!loading}>
                <Name>{data?.externalProject.name}</Name>
              </If>
            </If>
            <ConnectionActionButtons>
              <EditButton
                onClick={() =>
                  openModal({
                    sourceSystem: item.sourceSystem,
                    selectedExternalProject: item.externalId,
                    isConnected: !!item.externalId,
                  })
                }
                className="mr-1 h-5 min-h-5 w-5"
              />
              <DeleteButton
                onClick={() =>
                  disconnectProject({
                    nodeId: project?.id ?? "",
                    sourceSystem: item.sourceSystem,
                  })
                }
                className="h-5 min-h-5 w-5"
              />
            </ConnectionActionButtons>
          </If>
        </ConnectionBottomView>
      </Connection>
      <If isTrue={!item.isMapped}>
        <ConnectButton
          onClick={() =>
            openModal({
              sourceSystem: item.sourceSystem,
              selectedExternalProject: item.externalId,
              isConnected: !!item.externalId,
            })
          }
          className={item.isMapped ? "cursor-default" : "cursor-pointer"}
        >
          {intl.$t({ id: "CONNECT" })}
        </ConnectButton>
      </If>
    </Container>
  );
};
