import {
  WizardModal,
  WizardModalMode,
} from "@/common/components/wizard-modal/WizardModal";
import { useExternalVendorMapping } from "../../providers/ExternalVendorMappingProvider";

export const ExternalVendorMappingModal = () => {
  const { modalOpened, pages, closeModal } = useExternalVendorMapping();

  return (
    <WizardModal
      mode={WizardModalMode.SINGLE_PAGE}
      opened={modalOpened}
      pages={pages}
      onClose={closeModal}
    />
  );
};
