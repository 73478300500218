import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { SelectableItem } from "@/common/components/searchable-list/components/SelectableItem";
import { XlContainer } from "@/common/layout/ResponsiveClasses";
import { FormattedMessage } from "react-intl";
import { ExternalPoType } from "../../providers/ExternalPOTypesProvider";

export const useExternalPoTypeConfiguration = (
  selectedItems?: string[],
): Array<GridCol<ExternalPoType>> => {
  return [
    {
      header: <FormattedMessage id="DESCRIPTION" />,
      wrapper: XlContainer,
      item: ({ item, count }) => (
        <SelectableItem
          item={item}
          index={item.index}
          selectedItems={selectedItems ?? []}
          itemNameSelectorFn={(item) => item.name}
          count={count}
        />
      ),
    },
    {
      header: <FormattedMessage id="NAME" />,
      wrapper: XlContainer,
      item: ({ item }) => item.name,
    },
  ];
};
