import { CategoryState } from "@/common/hooks/useToggleCategory";
import { useWindowSize } from "@/common/hooks/useWindowResize";
import { useWindowScroll } from "@/common/providers/ScrollingProvider";
import { Identity } from "@/types/Identity";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { If } from "../if/If";
import { GridTableConfiguration } from "./GridTable";
import {
  Category,
  CategoryContainer,
  CategoryCard as DefaultCategoryCard,
  CategoryCardContent as DefaultCategoryCardContent,
  SubCategoryCard,
  SubCategoryCardContent,
} from "./GridTable.styles";
import { GridTableItem } from "./GridTableItem";
import { GridItem } from "./grid-item/GridItem";
import { RenderType } from "./types/RenderType";
import { generateIndex } from "./utils/generateIndex";
import { CategoryType, getCategoryType } from "./utils/getCategoryType";
import { useCountGeneration } from "./utils/useCountGeneration";

export const GRID_ERROR_ROW_KEY = "grid-error";
const GRID_VIRTUALIZE_ADDITIONAL_MARGIN = 500;
const INITIAL_RENDERED_CATEGORY_INDEX = {
  [CategoryType.ONE_LEVEL]: null,
  [CategoryType.TWO_LEVELS]: 5,
  [CategoryType.THREE_LEVELS]: 2,
};

type Props<T extends Identity, TAdditional> = {
  index: number;
  category: T | CategoryState<T> | CategoryState<CategoryState<T>>;
  readonly?: boolean;
  readonlyFn?: (
    additionalItem: TAdditional | undefined,
    item?: T | undefined,
  ) => boolean;
  hideGroup?: boolean;
  expandedItems?: (
    item: T,
    category: CategoryState<T>,
    subCategory?: CategoryState<T>,
  ) => T[];
  expandedDetailItems?: (
    detail: T,
    item: T,
    category: CategoryState<T>,
    subCategory?: CategoryState<T>,
  ) => T[];
  showSubdetailsIfNoDetailItems?: (item: T) => boolean;
  hideFirstDetail?: boolean;
  configuration: GridTableConfiguration<T, TAdditional>;
  error: boolean;
  virtualized: boolean;
  expandedItemComponent?: (item: T) => JSX.Element;
  preRowItemComponent?: (item: T) => JSX.Element;
  countMap: Map<string, number>;
  editing?: boolean;
  setEditing?: (editing: boolean) => void;
};

export const GridTableCategory = <T extends Identity, TAdditional>({
  index,
  category,
  configuration,
  readonly = false,
  readonlyFn,
  hideGroup = false,
  expandedItems = () => [],
  expandedDetailItems = () => [],
  showSubdetailsIfNoDetailItems,
  hideFirstDetail = false,
  error = false,
  virtualized,
  expandedItemComponent,
  preRowItemComponent,
  countMap,
  setEditing,
}: Props<T, TAdditional>) => {
  const categoryType = useMemo(() => getCategoryType(category), [category]);
  const initialInView = useMemo(
    () => index < (INITIAL_RENDERED_CATEGORY_INDEX[categoryType] || 0),
    [categoryType, index],
  );
  const [visible, setVisible] = useState(
    virtualized && categoryType !== CategoryType.ONE_LEVEL
      ? initialInView
      : true,
  );
  const { windowScroll } = useWindowScroll();
  const { height } = useWindowSize();
  const { ref, inView, entry } = useInView({
    threshold: 0,
    initialInView,
    trackVisibility: true,
    delay: 100,
  });

  const { generateCount } = useCountGeneration(countMap);
  useEffect(() => {
    const inRange =
      inView ||
      (!!entry?.boundingClientRect.top &&
        Math.abs(entry?.boundingClientRect.top - windowScroll.Top) <
          height + GRID_VIRTUALIZE_ADDITIONAL_MARGIN);
    if (
      virtualized &&
      categoryType !== CategoryType.ONE_LEVEL &&
      inRange !== visible
    ) {
      setVisible(inRange);
    }
  }, [
    categoryType,
    entry?.boundingClientRect.top,
    height,
    inView,
    virtualized,
    visible,
    windowScroll.Top,
    category,
  ]);

  useEffect(() => {
    if (!virtualized && !visible) {
      setVisible(true);
    }
  }, [virtualized, visible]);

  const { CategoryCard, CategoryCardContent } = useMemo(() => {
    return categoryType === CategoryType.THREE_LEVELS
      ? {
          CategoryCard: DefaultCategoryCard,
          CategoryCardContent: DefaultCategoryCardContent,
        }
      : {
          CategoryCard: SubCategoryCard,
          CategoryCardContent: SubCategoryCardContent,
        };
  }, [categoryType]);
  if (categoryType !== CategoryType.ONE_LEVEL) {
    return (
      <Category key={generateIndex([index])} ref={ref}>
        <If isTrue={!hideGroup}>
          <CategoryCard
            className={`${configuration.classNames?.category} print:top-0`}
            data-attr="zone"
            onClick={() =>
              configuration.toggle?.category &&
              configuration.toggle?.category((category as CategoryState<T>).id)
            }
          >
            <CategoryCardContent>
              <GridItem
                className="top-0 text-xs md:text-base"
                columns={configuration.columns}
                readonly={readonly}
                category={category as CategoryState<T>}
                index={index}
                renderType={RenderType.Group}
                error={error}
                visible={visible}
                setEditing={setEditing}
              />
            </CategoryCardContent>
          </CategoryCard>
        </If>
        <CategoryContainer $opened={(category as CategoryState<T>).isOpened}>
          {(category as CategoryState<T>).items.map(
            (subCategory, itemIndex) => {
              if (categoryType === CategoryType.THREE_LEVELS) {
                const mappedSubCategory =
                  subCategory as unknown as CategoryState<T>;
                return (
                  <Fragment key={itemIndex}>
                    <SubCategoryCard
                      className={configuration.classNames?.subCategory}
                      data-attr="zone"
                      onClick={() =>
                        configuration.toggle?.subCategory &&
                        configuration.toggle?.subCategory(
                          mappedSubCategory.id,
                          category.id,
                        )
                      }
                    >
                      <SubCategoryCardContent>
                        <GridItem
                          className="text-xs md:text-base"
                          columns={configuration.columns}
                          readonly={readonly}
                          category={mappedSubCategory}
                          index={itemIndex}
                          renderType={RenderType.Subgroup}
                          error={error}
                          visible={visible}
                          setEditing={setEditing}
                        />
                      </SubCategoryCardContent>
                    </SubCategoryCard>
                    <CategoryContainer $opened={mappedSubCategory.isOpened}>
                      {mappedSubCategory.items.map((i2, itemIndex2) => {
                        return (
                          <Fragment
                            key={generateIndex([index, itemIndex, itemIndex2])}
                          >
                            <GridTableItem<T, TAdditional>
                              key={itemIndex2}
                              item={i2}
                              index={itemIndex2}
                              configuration={configuration}
                              readonly={readonly}
                              readonlyFn={readonlyFn}
                              inEditModeFn={configuration.inEditModeFn}
                              itemFn={configuration.classNames?.itemFn}
                              onClick={configuration.toggle?.item}
                              hasCardItems={configuration.hasCardItems}
                              count={generateCount(
                                index,
                                itemIndex,
                                itemIndex2,
                              )}
                              className={`${configuration.classNames?.item}`}
                              contentClassName={
                                configuration.classNames?.itemContent || ""
                              }
                              category={mappedSubCategory}
                              error={error}
                              hasRowError={configuration.hasRowError}
                              visible={visible}
                              expandedItemComponent={expandedItemComponent}
                              preRowItemComponent={preRowItemComponent}
                              setEditing={setEditing}
                            />
                            {expandedItems(
                              i2,
                              category as CategoryState<T>,
                              mappedSubCategory,
                            ).map((detailItem, detailIndex) => {
                              if (detailIndex === 0 && hideFirstDetail) {
                                return null;
                              }
                              return (
                                <Fragment
                                  key={generateIndex([
                                    index,
                                    itemIndex,
                                    itemIndex2,
                                    detailIndex,
                                  ])}
                                >
                                  <GridTableItem<T, TAdditional>
                                    key={detailIndex}
                                    item={detailItem}
                                    index={detailIndex}
                                    configuration={configuration}
                                    readonly={readonly}
                                    readonlyFn={readonlyFn}
                                    inEditModeFn={configuration.inEditModeFn}
                                    itemFn={configuration.classNames?.itemFn}
                                    renderType={RenderType.Details}
                                    hasCardItems={configuration.hasCardItems}
                                    className={`${configuration.classNames?.item} ${configuration.classNames?.details}`}
                                    contentClassName={`${configuration.classNames?.itemContent} ${configuration.classNames?.detailsContent}`}
                                    error={error}
                                    hasRowError={configuration.hasRowError}
                                    category={category as CategoryState<T>}
                                    visible={visible}
                                    expandedItemComponent={
                                      expandedItemComponent
                                    }
                                    preRowItemComponent={preRowItemComponent}
                                    count={generateCount(
                                      index,
                                      itemIndex,
                                      itemIndex2,
                                      detailIndex,
                                    )}
                                    setEditing={setEditing}
                                  />
                                  {expandedDetailItems(
                                    detailItem,
                                    i2,
                                    category as CategoryState<T>,
                                    mappedSubCategory,
                                  ).map((subdetailItem, subdetailIndex) => {
                                    return (
                                      <GridTableItem<T, TAdditional>
                                        key={subdetailIndex}
                                        item={subdetailItem}
                                        index={subdetailIndex}
                                        configuration={configuration}
                                        readonly={readonly}
                                        readonlyFn={readonlyFn}
                                        inEditModeFn={
                                          configuration.inEditModeFn
                                        }
                                        itemFn={
                                          configuration.classNames?.itemFn
                                        }
                                        renderType={RenderType.Subdetails}
                                        hasCardItems={
                                          configuration.hasCardItems
                                        }
                                        className={`${configuration.classNames?.item} ${configuration.classNames?.subdetails}`}
                                        contentClassName={`${configuration.classNames?.itemContent} ${configuration.classNames?.subdetailsContent}`}
                                        error={error}
                                        hasRowError={configuration.hasRowError}
                                        category={category as CategoryState<T>}
                                        visible={visible}
                                        expandedItemComponent={
                                          expandedItemComponent
                                        }
                                        preRowItemComponent={
                                          preRowItemComponent
                                        }
                                        count={generateCount(
                                          index,
                                          itemIndex,
                                          itemIndex2,
                                          detailIndex,
                                          subdetailIndex,
                                        )}
                                        setEditing={setEditing}
                                      />
                                    );
                                  })}
                                </Fragment>
                              );
                            })}
                            <If isTrue={showSubdetailsIfNoDetailItems?.(i2)}>
                              {expandedDetailItems(
                                i2,
                                i2,
                                category as CategoryState<T>,
                                mappedSubCategory,
                              ).map((subdetailItem, subdetailIndex) => {
                                return (
                                  <GridTableItem<T, TAdditional>
                                    key={subdetailIndex}
                                    item={subdetailItem}
                                    index={subdetailIndex}
                                    configuration={configuration}
                                    readonly={readonly}
                                    readonlyFn={readonlyFn}
                                    inEditModeFn={configuration.inEditModeFn}
                                    itemFn={configuration.classNames?.itemFn}
                                    renderType={RenderType.Subdetails}
                                    hasCardItems={configuration.hasCardItems}
                                    className={`${configuration.classNames?.item} ${configuration.classNames?.subdetails}`}
                                    contentClassName={`${configuration.classNames?.itemContent} ${configuration.classNames?.subdetailsContent}`}
                                    error={error}
                                    hasRowError={configuration.hasRowError}
                                    category={category as CategoryState<T>}
                                    visible={visible}
                                    expandedItemComponent={
                                      expandedItemComponent
                                    }
                                    preRowItemComponent={preRowItemComponent}
                                    count={generateCount(
                                      index,
                                      itemIndex,
                                      itemIndex2,
                                      subdetailIndex,
                                    )}
                                    setEditing={setEditing}
                                  />
                                );
                              })}
                            </If>
                          </Fragment>
                        );
                      })}
                    </CategoryContainer>
                  </Fragment>
                );
              }
              return (
                <Fragment key={generateIndex([index, itemIndex])}>
                  <GridTableItem<T, TAdditional>
                    key={itemIndex}
                    item={subCategory}
                    index={itemIndex}
                    configuration={configuration}
                    readonly={readonly}
                    readonlyFn={readonlyFn}
                    inEditModeFn={configuration.inEditModeFn}
                    itemFn={configuration.classNames?.itemFn}
                    onClick={configuration.toggle?.item}
                    hasCardItems={configuration.hasCardItems}
                    count={generateCount(index, itemIndex)}
                    className={configuration.classNames?.item}
                    contentClassName={
                      configuration.classNames?.itemContent || ""
                    }
                    category={category as CategoryState<T>}
                    error={error}
                    hasRowError={configuration.hasRowError}
                    visible={visible}
                    expandedItemComponent={expandedItemComponent}
                    preRowItemComponent={preRowItemComponent}
                    setEditing={setEditing}
                  />
                  {expandedItems(subCategory, category as CategoryState<T>).map(
                    (detailItem, detailIndex) => {
                      if (detailIndex === 0 && hideFirstDetail) {
                        return null;
                      }
                      return (
                        <GridTableItem<T, TAdditional>
                          key={detailIndex}
                          item={detailItem}
                          index={detailIndex}
                          configuration={configuration}
                          readonly={readonly}
                          readonlyFn={readonlyFn}
                          inEditModeFn={configuration.inEditModeFn}
                          itemFn={configuration.classNames?.itemFn}
                          renderType={RenderType.Details}
                          hasCardItems={configuration.hasCardItems}
                          visible={visible}
                          category={category as CategoryState<T>}
                          className={`${configuration.classNames?.item} ${configuration.classNames?.details}`}
                          contentClassName={`${configuration.classNames?.itemContent} ${configuration.classNames?.detailsContent}`}
                          error={error}
                          hasRowError={configuration.hasRowError}
                          expandedItemComponent={expandedItemComponent}
                          preRowItemComponent={preRowItemComponent}
                          count={generateCount(index, itemIndex, detailIndex)}
                          setEditing={setEditing}
                        />
                      );
                    },
                  )}
                </Fragment>
              );
            },
          )}
        </CategoryContainer>
      </Category>
    );
  }
  return (
    <Fragment key={index}>
      <GridTableItem<T, TAdditional>
        key={index}
        item={category as T}
        index={index}
        configuration={configuration}
        readonly={readonly}
        readonlyFn={readonlyFn}
        itemFn={configuration.classNames?.itemFn}
        inEditModeFn={configuration.inEditModeFn}
        category={category as CategoryState<T>}
        className={configuration.classNames?.item}
        contentClassName={configuration.classNames?.itemContent || ""}
        count={generateCount(index)}
        onClick={configuration.toggle?.item}
        hasCardItems={configuration.hasCardItems}
        error={error}
        hasRowError={configuration.hasRowError}
        visible={visible}
        expandedItemComponent={expandedItemComponent}
        preRowItemComponent={preRowItemComponent}
        setEditing={setEditing}
      />
      {expandedItems(category as T, category as CategoryState<T>).map(
        (detailItem, detailIndex) => {
          if (detailIndex === 0 && hideFirstDetail) {
            return null;
          }
          return (
            <GridTableItem<T, TAdditional>
              key={detailIndex}
              item={detailItem}
              index={detailIndex}
              configuration={configuration}
              readonly={readonly}
              readonlyFn={readonlyFn}
              inEditModeFn={configuration.inEditModeFn}
              category={category as CategoryState<T>}
              itemFn={configuration.classNames?.itemFn}
              renderType={RenderType.Details}
              hasCardItems={configuration.hasCardItems}
              visible={visible}
              className={`${configuration.classNames?.item} ${configuration.classNames?.details}`}
              contentClassName={`${configuration.classNames?.itemContent} ${configuration.classNames?.detailsContent}`}
              error={error}
              hasRowError={configuration.hasRowError}
              onClick={configuration.toggle?.item}
              expandedItemComponent={expandedItemComponent}
              preRowItemComponent={preRowItemComponent}
              count={generateCount(index, detailIndex)}
            />
          );
        },
      )}
    </Fragment>
  );
};
