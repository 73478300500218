import { If } from "@/common/components/if/If";
import { ProjectItemFieldsFragment } from "@/generated/graphql";
import { FC, PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  item: ProjectItemFieldsFragment;
}>;

export const IncludeGroupedItems: FC<Props> = ({ item, children }) => {
  return <If isTrue={item.estimatedItems.length <= 1}>{children}</If>;
};
