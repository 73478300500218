import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useFiltersQueryParams } from "@/common/hooks/useFiltersQueryParams";
import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import {
  CreateReceiptInput,
  InvoiceType,
  QueryInvoicesFilter,
  ReceiptSummaryFieldsFragment,
  useReceiptsQuery,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionStringPromise,
} from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useArchiveReceipt } from "../hooks/useArchiveReceipt";
import { useCreateReceipt } from "../hooks/useCreateReceipt";

type ProviderContextType = {
  receipts: ReceiptSummaryFieldsFragment[];
  loading: boolean;
  error: boolean;
  totalCount: number;
  filter?: QueryInvoicesFilter;
  setFilter: (filter: QueryInvoicesFilter) => void;
  isFiltered: boolean;
  createReceipt: (input: CreateReceiptInput) => Promise<string | undefined>;
  archiveReceipt: (receiptId: string) => Promise<boolean>;
  exportEnabled: boolean;
  setExportEnabled: (enabled: boolean) => void;
  creating: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  receipts: [],
  totalCount: 0,
  loading: false,
  error: false,
  filter: undefined,
  setFilter: NoFunction,
  isFiltered: false,
  createReceipt: NoFunctionStringPromise,
  archiveReceipt: NoFunctionBooleanPromise,
  exportEnabled: false,
  setExportEnabled: NoFunction,
  creating: false,
});

export enum ReceiptType {
  Approved = "APPROVED",
  AwaitingApproval = "AWAITING_APPROVAL",
  Paid = "PAID",
}

export const ReceiptsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();
  const { paginationArgs, page, setPageInfo, previousPage } = usePagination();
  const { readValue, setValue } = useLocalStorage();

  const [filter, setFilter] = useState<QueryInvoicesFilter | undefined>(
    getFiltersQueryParam(),
  );
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);
  const { createReceipt: createReceiptHook, loading: creating } =
    useCreateReceipt();

  const setFilterWithType = useCallback((filter: QueryInvoicesFilter) => {
    setFilter({
      ...filter,
      type: InvoiceType.Receipt,
    });
  }, []);

  const setFilterAndUpdateQueryString = useCallback(
    (updatedFilter: QueryInvoicesFilter) => {
      setFiltersQueryParams(updatedFilter);
      setValue(LOCAL_STORAGE_KEYS.RECEIPTS_LIST_FILTER, updatedFilter);
      setFilterWithType(updatedFilter);
    },
    [setFilterWithType, setValue, setFiltersQueryParams],
  );

  useEffect(() => {
    const localStorageSettings = readValue<QueryInvoicesFilter>(
      LOCAL_STORAGE_KEYS.RECEIPTS_LIST_FILTER,
    ) as QueryInvoicesFilter;
    setFilterWithType({
      statuses: localStorageSettings?.statuses,
      archived: localStorageSettings?.archived,
      exported: localStorageSettings?.exported,
      prePaid: localStorageSettings?.prePaid,
      closedProjects: localStorageSettings?.closedProjects,
      ...getFiltersQueryParam(),
    });
  }, [readValue, setFilterWithType, getFiltersQueryParam]);

  useEffect(() => {
    if (page !== 0 && data?.receipts.edges.length === 0) {
      previousPage();
    }
  });

  const { data, loading, error, refetch } = useReceiptsQuery({
    variables: {
      filter,
      ...paginationArgs,
    },
    fetchPolicy: "cache-and-network",
  });

  const { archiveReceipt: archiveReceiptFn } = useArchiveReceipt({
    filter,
    page,
    paginationArgs,
    previousPage,
  });

  useEffect(() => {
    if (data?.receipts) {
      setPageInfo(data.receipts.pageInfo);
    }
  }, [data, setPageInfo]);

  const createReceipt = async (input: CreateReceiptInput) => {
    const receiptId = await createReceiptHook(input);
    if (receiptId) {
      await refetch();
      return receiptId;
    }
  };

  const archiveReceipt = (id: string) => {
    return archiveReceiptFn(id);
  };

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        receipts: data?.receipts?.edges?.map((edge) => edge.node) || [],
        loading,
        error: !!error,
        totalCount: data?.receipts?.totalCount || 0,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        isFiltered: !!filter?.statuses?.length || !!filter?.projectIds?.length,
        createReceipt,
        archiveReceipt,
        exportEnabled,
        setExportEnabled,
        creating,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReceipts = (): ProviderContextType =>
  useContext(ProviderContext);
