import { CategoryState } from "@/common/hooks/useToggleCategory";
import { Identity } from "@/types/Identity";
import { useCallback, useMemo, useState } from "react";
import { CountType } from "../circle-counter/CountType";
import { GridTableConfiguration } from "./GridTable";
import { ItemCard, ItemCardContent } from "./GridTable.styles";
import { GRID_ERROR_ROW_KEY } from "./GridTableCategory";
import { GridItem } from "./grid-item/GridItem";
import { RenderType } from "./types/RenderType";

type Props<T extends Identity, TAdditional> = {
  item: T;
  index: number;
  className?: string;
  contentClassName?: string;
  configuration: GridTableConfiguration<T, TAdditional>;
  readonly: boolean;
  readonlyFn?: (
    additionalItem: TAdditional | undefined,
    item?: T | undefined,
  ) => boolean;
  itemFn?: (
    item: T,
    category: CategoryState<T> | undefined,
    index?: number,
    editMode?: boolean,
  ) => { error?: boolean; className: string };
  renderType?: RenderType;
  onClick?: (item: T) => void;
  hasCardItems?: boolean;
  count: CountType;
  error?: boolean;
  visible?: boolean;
  category?: CategoryState<T>;
  inEditModeFn?: (item: T, index?: number, editMode?: boolean) => boolean;
  hasRowError?: (item: T, index?: number) => boolean;
  expandedItemComponent?: (item: T) => JSX.Element;
  preRowItemComponent?: (item: T) => JSX.Element;
  setEditing?: (editing: boolean) => void;
};

export const GridTableItem = <T extends Identity, TAdditional>({
  item,
  index,
  configuration,
  readonly,
  readonlyFn,
  itemFn,
  className,
  contentClassName,
  onClick,
  hasCardItems = false,
  renderType = RenderType.Item,
  count,
  error = false,
  visible,
  category,
  inEditModeFn,
  hasRowError,
  expandedItemComponent,
  preRowItemComponent,
  setEditing,
}: Props<T, TAdditional>) => {
  const [editMode, setEditMode] = useState(
    inEditModeFn?.(item, index) ?? false,
  );
  const itemStyle = useMemo(
    () => (itemFn ? itemFn(item, category, index, editMode) : undefined),
    [item, category, index, editMode, itemFn],
  );
  const setEditModeFn = useCallback(
    (editMode: boolean) => {
      setEditMode(editMode);
      setEditing?.(editMode);
    },
    [setEditing],
  );

  return (
    <ItemCard
      key={index}
      className={`${className} ${itemStyle?.className || ""}`}
      onClick={() => onClick && onClick(item)}
      data-attr={itemStyle?.error ? GRID_ERROR_ROW_KEY : ""}
      $hasCardItems={hasCardItems}
      $hasCursor={!!onClick}
      data-testid={`grid-table-item-${count}`}
    >
      {preRowItemComponent && preRowItemComponent(item)}
      <ItemCardContent
        className={contentClassName}
        data-testid="grid-table-item-card"
      >
        <GridItem
          className="text-xs md:text-base"
          columns={configuration.columns}
          item={item}
          readonly={readonly}
          editMode={editMode}
          setEditMode={setEditModeFn}
          readonlyFn={readonlyFn}
          index={index}
          renderType={renderType || RenderType.Item}
          count={count}
          error={error}
          rowError={hasRowError?.(item, index) ?? false}
          visible={visible}
        />
      </ItemCardContent>
      {expandedItemComponent && expandedItemComponent(item)}
    </ItemCard>
  );
};
