import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useUser } from "@/common/providers/UserProvider";
import {
  DeliverySlipStats,
  DeliverySlipStatsQuery,
  useDeliverySlipStatsQuery,
} from "@/generated/graphql";
import { ApolloQueryResult, NetworkStatus } from "@apollo/client/core";
import { FC, PropsWithChildren, createContext, useContext } from "react";

type ProviderContextType = {
  loading: boolean;
  deliverySlipsStats?: DeliverySlipStats;
  refetch: () => Promise<ApolloQueryResult<DeliverySlipStatsQuery>>;
};

const ProviderContext = createContext<ProviderContextType>({
  loading: false,
  deliverySlipsStats: undefined,
  refetch: () =>
    Promise.resolve({
      data: { deliverySlipStats: { pendingCount: 0 } },
      loading: false,
      networkStatus: NetworkStatus.ready,
    }),
});

export const DeliverySlipStatsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { isContractor } = useUser();
  const { data, error, loading, refetch } = useDeliverySlipStatsQuery({
    skip: !isContractor,
  });

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        loading,
        deliverySlipsStats: data?.deliverySlipStats,
        refetch,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDeliverySlipStats = (): ProviderContextType =>
  useContext(ProviderContext);
