import { gql } from "@apollo/client";

export const UOM_FIELDS = gql`
  fragment UOMFields on UOM {
    id
    mnemonic
    singularDescription
    pluralDescription
    alternativeMnemonics
  }
`;
