import { Breadcrumbs } from "@/common/components/breadcrumbs/Breadcrumbs";
import { If } from "@/common/components/if/If";
import {
  QuestionBox,
  QuestionText,
} from "@/common/components/messages/Messages.styles";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { routes } from "@/config/routes";
import { VendorReleaseMessagesButton } from "@/distributor/common/release-messages/VendorReleaseMessagesButton";
import { ReleaseInfoHeader } from "@/distributor/pages/release/components/ReleaseInfoHeader";
import {
  DistributorReleaseFieldsFragment,
  MessageContextKind,
} from "@/generated/graphql";
import { ListAlt } from "@mui/icons-material";
import { FC, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { DeliveryRecordPrintButton } from "../components/DeliveryRecordPrintButton";

const Headline = tw.div`
  pr-2 text-lg flex items-center font-medium
`;

type Props = {
  release: DistributorReleaseFieldsFragment;
  openedMessages?: boolean;
};

export const DistributorReleaseDeliveryRecordHeader: FC<Props> = ({
  release,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const items = useMemo(() => {
    if (release.buyout) {
      return [
        {
          text: intl.$t(
            { id: "BUYOUT" },
            { number: release.buyout?.clientIdentifier },
          ),
          link: generatePath(routes.distributorBuyout, {
            id: release.buyout?.id,
          }),
          keepSearchParams: true,
          id: "buyoutNo",
        },
        {
          text: intl.$t(
            { id: "DELIVERY_WITH_NUMBER" },
            { number: release.sequenceNumber },
          ),
          link: generatePath(routes.distributorDelivery, {
            deliveryId: release.id,
          }),
          keepSearchParams: true,
          id: "deliveryNo",
        },
        {
          id: "deliveryNo",
          text: <FormattedMessage id="DELIVERY_RECORD" tagName={Headline} />,
        },
      ];
    } else {
      return [
        {
          text: release.project?.name ?? "",
          id: "projectName",
        },
        {
          text: intl.$t(
            { id: "DELIVERY_WITH_NUMBER" },
            { number: release.sequenceNumber },
          ),
          id: "deliveryNo",
        },
      ];
    }
  }, [release, intl]);

  return (
    <>
      <Helmet>
        <title>
          {intl.$t(
            { id: "DELIVERY_WITH_NUMBER" },
            { number: release.sequenceNumber },
          )}
        </title>
      </Helmet>
      <Breadcrumbs
        items={items}
        actions={[
          <DeliveryRecordPrintButton release={release} key="print" />,
          <If key="release-history" isTrue={!!release.buyout}>
            <QuestionBox
              onClick={() =>
                release.buyout
                  ? navigate(
                      generatePath(routes.distributorBuyoutDeliveries, {
                        id: release.buyout?.id,
                      }) + location.search,
                    )
                  : null
              }
            >
              <QuestionText>
                <FormattedMessage id="DISTRIBUTOR_BUYOUT_ORDERS_HISTORY" />
              </QuestionText>
              <ListAlt />
            </QuestionBox>
          </If>,
          <VendorReleaseMessagesButton
            key="messages"
            release={release}
            org={release.project?.location.org}
            text={
              <QuestionBox $fixedWidth>
                <QuestionText>
                  <FormattedMessage id="MESSAGE_CONTRACTOR_ABOUT_DELIVERY" />
                </QuestionText>
                <MessageButton
                  id={release.id}
                  kind={MessageContextKind.Release}
                />
              </QuestionBox>
            }
          />,
        ]}
      />
      <ReleaseInfoHeader release={release} />
    </>
  );
};
