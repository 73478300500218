import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CreateNoteDocumentInput,
  NoteDocumentFieldsFragment,
  useCreateNoteDocumentMutation,
} from "@/generated/graphql";

export const useCreateNoteDocument = () => {
  const { setError } = useGlobalError();

  const [createNoteDocumentMutation] = useCreateNoteDocumentMutation();
  const createNoteDocument = async (input: CreateNoteDocumentInput) => {
    try {
      const { data, errors } = await createNoteDocumentMutation({
        variables: {
          input,
        },
      });

      setError(errors);
      return data?.createNoteDocument as NoteDocumentFieldsFragment | null;
    } catch (error) {
      setError(error);
      return null;
    }
  };

  return { createNoteDocument };
};
