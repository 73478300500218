import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import {
  InlineNotesContainer,
  MdContainer,
  SmFixedContainer,
  SmWideContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { DistributorQuotesAuxiliaryQuoteItemFieldsFragment } from "@/generated/graphql";
import tw from "tailwind-styled-components";
import { BidPriceTotalForItem } from "../../../../common/quote/BidPriceTotalForItem";
import { DistributorQuoteAuxiliaryCategoryTotal } from "../auxiliary-items/DistributorQuoteAuxiliaryCategoryTotal";
import { DistributorQuoteAuxiliaryCostCodeManufacturerSelector } from "../auxiliary-items/DistributorQuoteAuxiliaryCostCodeManufacturerSelector";
import { DistributorQuoteAuxiliaryDescription } from "../auxiliary-items/DistributorQuoteAuxiliaryDescription";
import { DistributorQuoteAuxiliaryLeadTime } from "../auxiliary-items/DistributorQuoteAuxiliaryLeadTime";
import { DistributorQuoteAuxiliaryManufacturerSelector } from "../auxiliary-items/DistributorQuoteAuxiliaryManufacturerSelector";
import { DistributorQuoteAuxiliaryNotes } from "../auxiliary-items/DistributorQuoteAuxiliaryNotes";
import { DistributorQuoteAuxiliaryOptionsMenu } from "../auxiliary-items/DistributorQuoteAuxiliaryOptionsMenu";
import { DistributorQuoteAuxiliaryUnitInput } from "../auxiliary-items/DistributorQuoteAuxiliaryUnitInput";
import { DistributorQuoteAuxiliaryUnitPriceInput } from "../auxiliary-items/DistributorQuoteAuxiliaryUnitPriceInput";
import { DistributorQuoteAuxiliaryUom } from "../auxiliary-items/DistributorQuoteAuxiliaryUom";
import { QuoteNotes } from "../notes-and-expiration/QuoteNotes";

const Container = tw.div`flex flex-1 justify-between`;
const ActionsContainer = tw(
  SmFixedContainer,
)`lg:w-30 lg:basis-30 md:basis-30 md:w-30`;

export const DistributorQuoteAuxiliaryItemsConfiguration: Array<
  GridCol<DistributorQuotesAuxiliaryQuoteItemFieldsFragment>
> = [
  {
    wrapper: Container,
    item: ({ item, readonly, error, count }) => (
      <DistributorQuoteAuxiliaryDescription
        item={item}
        readonly={readonly}
        error={error}
        count={count}
      />
    ),
    group: (category) => (
      <ProductCategory
        type={ProductCategoryType.AuxiliaryItem}
        category={category}
        items={category.items.length}
      />
    ),
    position: "none",
  },
  {
    wrapper: MdContainer,
    item: ({ item, index, readonly, error }) => (
      <DistributorQuoteAuxiliaryUom
        item={item}
        readonly={readonly}
        index={index}
        error={error}
      />
    ),
    group: (category) => (
      <ProductCategoryCount itemsLength={category.items.length} />
    ),
    position: "center",
  },
  {
    wrapper: MdContainer,
    item: ({ item, readonly, error, index }) => (
      <DistributorQuoteAuxiliaryManufacturerSelector
        item={item}
        error={error}
        readonly={readonly}
        index={index}
      />
    ),
    group: (category, readonly) => (
      <DistributorQuoteAuxiliaryCostCodeManufacturerSelector
        category={category}
        readonly={readonly}
      />
    ),
    position: "center",
  },
  {
    wrapper: SmWideContainer,
    item: ({ item, index, readonly, error }) => (
      <DistributorQuoteAuxiliaryUnitInput
        item={item}
        readonly={readonly}
        index={index}
        error={error}
      />
    ),
  },
  {
    wrapper: XxsFixedContainer,
    item: () => <>x</>,
    position: "center",
  },
  {
    wrapper: SmWideContainer,
    item: ({ item, index, readonly, error }) => (
      <DistributorQuoteAuxiliaryUnitPriceInput
        item={item}
        readonly={readonly}
        index={index}
        error={error}
      />
    ),
  },
  {
    wrapper: XxsFixedContainer,
    item: () => <>=</>,
    details: () => <>=</>,
    position: "center",
  },
  {
    wrapper: SmWideContainer,
    item: ({ item }) => <BidPriceTotalForItem item={item} />,
    position: "end",
    group: (category) => (
      <DistributorQuoteAuxiliaryCategoryTotal items={category.items} />
    ),
  },
  {
    wrapper: ActionsContainer,
    item: ({ item, readonly }) => (
      <>
        <QuoteNotes item={item} readonly={readonly} />
        <DistributorQuoteAuxiliaryLeadTime item={item} readonly={readonly} />
        <DistributorQuoteAuxiliaryOptionsMenu item={item} readonly={readonly} />
      </>
    ),
    position: "end",
  },
  {
    wrapper: InlineNotesContainer,
    item: ({ item, readonly }) => (
      <DistributorQuoteAuxiliaryNotes item={item} readonly={readonly} />
    ),
  },
];
