import { ProjectFormBudget } from "@/contractor/pages/home/projects/components/project-form/forms/ProjectFormBudget";
import tw from "tailwind-styled-components";
import { ProjectProvider } from "../../providers/ProjectProvider";
import { ProjectTagsProvider } from "../../providers/ProjectTagsProvider";
import { ProjectZonesProvider } from "../../providers/ProjectZonesProvider";

const Container = tw.div`my-6`;

const ProjectBudgetWithProvider = () => {
  return (
    <Container>
      <ProjectFormBudget />
    </Container>
  );
};

export const ProjectBudget = () => {
  return (
    <ProjectProvider>
      <ProjectTagsProvider>
        <ProjectZonesProvider>
          <ProjectBudgetWithProvider />
        </ProjectZonesProvider>
      </ProjectTagsProvider>
    </ProjectProvider>
  );
};
