import { InvoiceValidationFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";

const RECEIPT_VALIDATION_KEYS = [
  "errors",
  "costCodesNotConnected",
  "wbsMissingInPO",
  "itemsMissingCostCodes",
  "costCodesMissingInPO",
  "itemsMissingTags",
];

export const useValidateReceipt = () => {
  const isValidReceipt = useCallback(
    (invoiceValidation: InvoiceValidationFieldsFragment | undefined) => {
      return RECEIPT_VALIDATION_KEYS.every((key) => {
        const value =
          invoiceValidation?.[key as keyof InvoiceValidationFieldsFragment];
        return !value || (value as []).length === 0;
      });
    },
    [],
  );

  return { isValidReceipt };
};
