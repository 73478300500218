import { If } from "@/common/components/if/If";
import { Switch } from "@/common/components/switch/Switch";
import { DateView } from "@/common/utils/dates/DateView";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  IntegrationType,
  ReleaseStatus,
  SourceSystem,
} from "@/generated/graphql";
import { CheckCircleOutline } from "@mui/icons-material";
import { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ExpandedRelease } from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";
type Props = {
  release: Pick<
    ExpandedRelease,
    | "status"
    | "exportedAt"
    | "poLink"
    | "id"
    | "version"
    | "receiptPostedAt"
    | "receiptPostedBy"
    | "requiresInventoryReceipt"
  >;
  className?: string;
};

const Container = tw.div`flex gap-2 items-center text-base`;
const PostedContainer = tw.div``;
const ReceiptPostedBy = tw.div`flex gap-1 text-2xs`;
const UserName = tw.div``;
const Check = tw(CheckCircleOutline)`mr-2 text-green-600`;

export const ReceiptPosted: FC<Props> = ({ release, className }) => {
  const intl = useIntl();
  const { updateRelease } = useReleaseUpdate();
  const { settings } = useOrgSettings();

  const isFoundationConnected =
    !!settings?.integrations.sourceSystems.find(
      (system) => system.connected && system.system === SourceSystem.Foundation,
    ) ||
    !!settings?.integrations.accounting.find(
      (accounting) =>
        accounting.enabled &&
        accounting.integration === IntegrationType.Foundation,
    );

  const setReceiptPosted = useCallback(
    async (value: boolean) => {
      await updateRelease(
        {
          releaseId: release.id,
          receiptPosted: value,
          version: release.version,
          skipConfirmation: true,
        },
        { batch: false },
      );
    },
    [release.id, release.version, updateRelease],
  );

  if (
    (release.status !== ReleaseStatus.Received &&
      release.status !== ReleaseStatus.PartiallyReceived) ||
    !isFoundationConnected ||
    (!release.exportedAt && !release.poLink) ||
    !release.requiresInventoryReceipt
  ) {
    return null;
  }

  return (
    <Container className={className}>
      <If isTrue={release.receiptPostedAt}>
        <Check />
      </If>
      <PostedContainer>
        <FormattedMessage id="RECEIPT_POSTED" />
        <If isTrue={release.receiptPostedBy}>
          <ReceiptPostedBy>
            <DateView date={release.receiptPostedAt} />
            <UserName>
              ({release.receiptPostedBy?.firstName}{" "}
              {release.receiptPostedBy?.lastName})
            </UserName>
          </ReceiptPostedBy>
        </If>
      </PostedContainer>
      <Switch
        onLabel={intl.$t({ id: "YES" })}
        offLabel={intl.$t({ id: "NO" })}
        value={!!release?.receiptPostedAt}
        onChange={setReceiptPosted}
      />
    </Container>
  );
};
