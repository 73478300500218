import { OrgRole, UsersUserFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  user: UsersUserFieldsFragment;
  orgRoles: OrgRole | OrgRole[];
  negate?: boolean;
};

const Container = tw.div`
  font-semibold text-base uppercase
`;

export const HasOrgRole: FC<Props> = ({ user, orgRoles, negate }) => {
  const hasRole = useMemo(() => {
    const definedRoles = [orgRoles].flat();
    const includes = definedRoles.some((role) => user.orgRoles.includes(role));
    return negate ? !includes : includes;
  }, [negate, orgRoles, user.orgRoles]);

  return (
    <Container>
      <FormattedMessage id={hasRole ? "YES" : "NO"} />
    </Container>
  );
};
