import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { SideActionBar } from "@/common/components/side-action-bar/SideActionBar";
import { VIEW_STATE, useTableView } from "@/common/providers/TableViewProvider";
import { AddToBuyoutItemInput, BuyoutStatus } from "@/generated/graphql";
import { ControlPoint, DeleteOutline, DragHandle } from "@mui/icons-material";
import { useCallback, useMemo, useState } from "react";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { BuyoutBulkCostCodeSelector } from "./BuyoutBulkCostCodeSelector";

export const BuyoutItemsSideActions = () => {
  const { buyout, updateBuyout, selectedBuyoutItems, setSelectedBuyoutItems } =
    useContractorBuyout();
  const [loading, setLoading] = useState(false);
  const { tableView } = useTableView();

  const canAlterBuyoutItems = useMemo(
    () => buyout?.status !== BuyoutStatus.Active,
    [buyout],
  );

  const deleteItems = useCallback(async () => {
    if (buyout && selectedBuyoutItems) {
      setLoading(true);
      await updateBuyout({
        removedItems: selectedBuyoutItems,
      });
      setLoading(false);
      setSelectedBuyoutItems([]);
    }
  }, [buyout, selectedBuyoutItems, setSelectedBuyoutItems, updateBuyout]);

  const duplicate = useCallback(async () => {
    if (buyout && selectedBuyoutItems) {
      setLoading(true);
      await updateBuyout({
        addedItems: selectedBuyoutItems
          .map((id) => {
            const item = buyout.items.find((i) => i.id === id);
            if (item) {
              return {
                description: item.description,
                quantityDecimal: item.quantityDecimal,
                manufacturerId: item.manufacturer?.id,
                orgCatalogSku: item.orgCatalogSku?.id,
                costCodeId: item.costCode?.id,
                projectItem: {
                  estimateUom: item.projectItem.estimateUom.pluralDescription,
                  orgCatalogSkuId: isOrgCatalogSku(
                    item.projectItem?.material.material,
                  )
                    ? item.projectItem?.material.material.id
                    : undefined,
                  masterProductId: isProductSku(
                    item.projectItem?.material.material,
                  )
                    ? item.projectItem?.material.material.id
                    : undefined,
                  masterSkuId: isMasterSku(item.projectItem?.material.material)
                    ? item.projectItem?.material.material.id
                    : undefined,
                },
              } as AddToBuyoutItemInput;
            }
            return null;
          })
          .filter((i) => i !== null) as AddToBuyoutItemInput[],
        updates: [],
      });
      setLoading(false);
      setSelectedBuyoutItems([]);
    }
  }, [buyout, selectedBuyoutItems, updateBuyout, setSelectedBuyoutItems]);

  const actions = useMemo(() => {
    return [
      ...(canAlterBuyoutItems
        ? [
            {
              icon: <ControlPoint />,
              tooltipKey: "DUPLICATE_PROJECT_ITEMS",
              callback: duplicate,
            },
          ]
        : []),
      {
        icon: <DragHandle />,
        tooltipKey: "ASSIGN_COST_CODE",
        component: <BuyoutBulkCostCodeSelector setLoading={setLoading} />,
      },
      ...(canAlterBuyoutItems
        ? [
            {
              icon: <DeleteOutline />,
              tooltipKey: "DELETE_ITEMS",
              callback: deleteItems,
            },
          ]
        : []),
    ];
  }, [canAlterBuyoutItems, duplicate, deleteItems]);

  return (
    <SideActionBar
      loading={loading}
      visible={
        selectedBuyoutItems.length > 0 && tableView === VIEW_STATE.normal
      }
      actions={actions}
    ></SideActionBar>
  );
};
