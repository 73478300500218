import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import {
  EstimatedItemFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { usePriceCalculation } from "../../../release/hooks/usePriceCalculation";

type Props = {
  estimatedItem?: EstimatedItemFieldsFragment | null;
  item: ProjectItemFieldsFragment;
  index?: number;
};

export const ProjectItemExtendedUnitPrice: FC<Props> = ({
  estimatedItem,
  index,
  item,
}) => {
  const { calcExtPrice } = usePriceCalculation();

  const extendedPrice = useMemo(() => {
    if (index !== undefined) {
      if (estimatedItem?.quantityDecimal && estimatedItem?.unitPrice) {
        return calcExtPrice(
          estimatedItem?.quantityDecimal,
          estimatedItem?.unitPrice,
        );
      } else {
        return null;
      }
    }
    return item.estimatedItems
      .reduce(
        (acc, item) =>
          acc.add(calcExtPrice(item.quantityDecimal, item.unitPrice || 0)),
        new Decimal(0),
      )
      .toString();
  }, [estimatedItem, index, item, calcExtPrice]);

  return (
    <NotNullableRenderer value={extendedPrice}>
      <ValueCurrency
        value={extendedPrice ?? ""}
        classNames={{ value: "text-xs" }}
      />
    </NotNullableRenderer>
  );
};
