import { AdditionalFiles } from "@/common/components/additional-files/AdditionalFiles";
import { If } from "@/common/components/if/If";
import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import { AssetContext } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useRfqQuotes } from "../../../providers/RfqQuotesProvider";
import { NOTES_PANEL_ID } from "./NotesPanel";

type Props = {
  onClose: () => void;
};

const TitleWrapper = tw.div``;

const Wrapper = tw.div`px-10 py-6`;

const Description = tw.div`font-medium`;

const Details = tw.div`pb-2 whitespace-pre-line`;

export const NotesDetails: FC<Props> = ({ onClose }) => {
  const { getObjectId } = useGlobalDrawer();
  const { rfq, allQuoteItems } = useRfqQuotes();

  const quoteItem = useMemo(() => {
    return allQuoteItems.find(
      (item) => item.id === getObjectId(NOTES_PANEL_ID),
    );
  }, [allQuoteItems, getObjectId]);

  const quote = useMemo(() => {
    return rfq?.quotes?.find((q) => q.id === getObjectId(NOTES_PANEL_ID));
  }, [rfq, getObjectId]);

  const auxItem = useMemo(() => {
    const quote = rfq?.quotes?.find((q) =>
      q.auxiliaryItems?.find((item) => item.id === getObjectId(NOTES_PANEL_ID)),
    );
    return quote?.auxiliaryItems?.find(
      (item) => item.id === getObjectId(NOTES_PANEL_ID),
    );
  }, [rfq, getObjectId]);

  const sku = useMemo(() => {
    return (quoteItem?.masterSku || quoteItem?.orgCatalogSku)?.name;
  }, [quoteItem]);

  const notes = useMemo(() => {
    return quoteItem?.notes || auxItem?.notes || quote?.notes;
  }, [quoteItem, quote, auxItem]);

  const assets = useMemo(() => {
    return quoteItem?.assets || auxItem?.assets || quote?.assets;
  }, [quoteItem, quote, auxItem]);

  return (
    <UploadAssetProvider initialAssets={assets} context={AssetContext.Note}>
      <OverlayPanel
        title={
          <TitleWrapper>
            <FormattedMessage tagName="div" id="NOTES_AND_TERMS" />
          </TitleWrapper>
        }
        onCancel={onClose}
        noPadding
      >
        <Wrapper>
          <If isTrue={Boolean(sku)}>
            <Description>
              <FormattedMessage id="SKU" />
            </Description>
            <Details>{sku}</Details>
          </If>
          <If isTrue={notes}>
            <Description>
              <FormattedMessage id="NOTES_AND_TERMS" />
            </Description>

            <Details>{notes}</Details>
          </If>
          <AdditionalFiles readonly />
        </Wrapper>
      </OverlayPanel>
    </UploadAssetProvider>
  );
};
