import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { darken, lighten } from "@mui/material";
import { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { GraphDataType } from "../enums/GraphDataType";

const Container = tw.div`grid w-full h-full gap-y-1.5 bg-gray-100 bg-opacity-90 p-3 rounded-md`;
const Segment = tw.div`relative grid grid-flow-col justify-start items-center w-full text-2xs gap-2`;
const SegmentDetails = tw.div`grid text-xs font-light drop-shadow text-left`;
const PercentPie = tw.div`
  relative grid place-items-center w-7 h-7 rounded-full text-center
  font-bold text-blue-800
`;

const THRESHOLD_PERCENT = 0.03;

type DataWithSplits = {
  title?: string;
  format?: string;
  data: number[];
  labels?: string[];
};

type Props = {
  colors: string[];
  data: DataWithSplits[];
  index: number;
  item: number;
};

export const OrgSplitTooltip: FC<Props> = ({ colors, data, index, item }) => {
  const { formatCurrency } = useFormatNumberToCurrency();
  const getOtherSegmentValue = useCallback(
    (idx: number, itm: number) => {
      return (
        data
          ?.filter((split) => split.data[idx] / itm < THRESHOLD_PERCENT)
          .reduce((acc, split) => acc + split.data[idx], 0) || 0
      );
    },
    [data],
  );

  return (
    <Container>
      {data
        ?.toSorted((a, b) => b.data[index] - a.data[index])
        .filter(
          (split) =>
            split.data[index] > 0 &&
            split.data[index] / item > THRESHOLD_PERCENT,
        )
        .map((split, splitIndex) => (
          <Segment
            key={`${split}${splitIndex}`}
            style={{
              color: darken(colors[index % colors.length], 0.7),
            }}
          >
            <PercentPie
              style={{
                textShadow: `1px 1px 2px ${lighten(colors[index % colors.length], 0.7)}`,
                color: darken(colors[index % colors.length], 0.7),
                backgroundImage: `conic-gradient(
                  ${lighten(colors[index % colors.length], 0.1)} ${Math.round((split.data[index] / item) * 100)}%,
                  ${lighten(colors[index % colors.length], 0.5)} ${Math.round((split.data[index] / item) * 100) + 3}%
                )`,
              }}
            >
              {Math.round((split.data[index] / item) * 100)}%
            </PercentPie>
            <SegmentDetails>
              {split.title}{" "}
              <strong>
                {split.format === GraphDataType.Currency
                  ? formatCurrency(split.data[index])
                  : split.data[index]}
              </strong>
            </SegmentDetails>
          </Segment>
        ))}
      {data && getOtherSegmentValue(index, item) ? (
        <Segment
          key={`other`}
          style={{
            color: darken(colors[index % colors.length], 0.7),
          }}
        >
          <PercentPie
            style={{
              textShadow: `1px 1px 2px ${lighten(colors[index % colors.length], 0.7)}`,
              color: darken(colors[index % colors.length], 0.7),
              backgroundImage: `conic-gradient(
                  ${lighten(colors[index % colors.length], 0.1)} ${Math.round((getOtherSegmentValue(index, item) / item) * 100)}%,
                  ${lighten(colors[index % colors.length], 0.5)} ${Math.round((getOtherSegmentValue(index, item) / item) * 100)}%
                )`,
            }}
          >
            {Math.round((getOtherSegmentValue(index, item) / item) * 100)}%
          </PercentPie>
          <SegmentDetails>
            <FormattedMessage id="OTHER" />
            <strong>
              {data[0].format === GraphDataType.Currency
                ? formatCurrency(getOtherSegmentValue(index, item))
                : getOtherSegmentValue(index, item)}
            </strong>
          </SegmentDetails>
        </Segment>
      ) : null}
    </Container>
  );
};
