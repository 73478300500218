import { ManufacturerModalPicker } from "@/common/components/manufacturer-modal-picker/ManufacturerModalPicker";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { QuoteStatus } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../providers/DistributorQuoteItemsProvider";
import { useDistributorQuote } from "../providers/DistributorQuoteProvider";

type Props = {
  category: CategoryState<DistributorQuoteItemGroupExtendedFieldsFragment>;
  readonly?: boolean;
};

export const DistributorQuoteCostCodeManufacturerSelector: FC<Props> = ({
  category,
  readonly,
}) => {
  const { updateQuote } = useDistributorQuote();

  const saveManufacturer = useCallback(
    async (manufacturerId: string | null) => {
      const updates = category.items
        .filter((i) => i.quoteItems[0]?.status !== QuoteStatus.Withdrawn)
        .flatMap((i) => i.quoteItems)
        .map((item) => {
          return {
            quoteItemId: item.id,
            manufacturerId,
          };
        });
      await updateQuote({
        updates,
      });
    },
    [category.items, updateQuote],
  );

  return (
    <ManufacturerModalPicker
      onChange={saveManufacturer}
      readonly={
        readonly ||
        category.items.filter(
          (i) => i.quoteItems[0]?.status !== QuoteStatus.Withdrawn,
        ).length === 0
      }
    />
  );
};
