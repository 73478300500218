import { DiscardButton } from "@/common/components/button/DiscardButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import { FC, useCallback, useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useDeliverySlipSequence } from "../../../../providers/DeliverySlipSequenceProvider";
import { useDeliverySlips } from "../../../../providers/DeliverySlipsProvider";

const Container = tw.div`
  grid grid-cols-[75px_repeat(5,_auto)] gap-2 relative w-fit place-items-center
`;
const NumberContainer = tw.div`
  text-sm font-medium
  ${({ $hasItems }: { $hasItems: boolean }) =>
    $hasItems ? "text-green-800" : ""}
`;
const InfoText = tw.span`grid text-2xs max-w-20 font-normal`;

export const ExportDeliverySlipsButton: FC = () => {
  const {
    startSequence,
    sequenceActive,
    sequenceIds,
    stopSequence,
    selectedDeliverySlips,
  } = useDeliverySlipSequence();
  const { exportEnabled, setExportEnabled } = useDeliverySlips();
  const { readValue } = useLocalStorage();

  const isNewSequence = useMemo(() => {
    const localStorageSequence = readValue<string[]>(
      LOCAL_STORAGE_KEYS.DELIVERY_SLIP_SEQUENCES.SEQUENCE_ACTIVE,
    );
    return !localStorageSequence;
  }, [readValue]);

  useEffect(() => {
    if (sequenceActive) {
      setExportEnabled(sequenceActive);
    }
  }, [exportEnabled, sequenceActive, sequenceIds, setExportEnabled]);

  const startExportFlow = useCallback(() => {
    setExportEnabled(true);
  }, [setExportEnabled]);

  const onStartSequenceClicked = useCallback(() => {
    startSequence();
  }, [startSequence]);

  if (!exportEnabled) {
    return (
      <OutlinedButton testId="create-sequence" onClick={startExportFlow}>
        <FormattedMessage id="CREATE_SEQUENCE" />
      </OutlinedButton>
    );
  }
  return (
    <Container>
      <FormattedMessage
        id="USE_CHECKBOXES_TO_SELECT_ITEMS"
        tagName={InfoText}
      />
      <NumberContainer $hasItems={selectedDeliverySlips.length > 0}>
        <FormattedMessage
          id="NUMBER_OF_SELECTED"
          values={{ count: sequenceIds.length }}
        />
      </NumberContainer>
      <PrimaryButton
        testId="start-sequence"
        onClick={onStartSequenceClicked}
        disabled={sequenceIds.length === 0}
      >
        <FormattedMessage
          id={isNewSequence ? "START_SEQUENCE" : "CONTINUE_SEQUENCE"}
        />
      </PrimaryButton>
      <Tooltip
        element={
          <DiscardButton
            onClick={() => {
              stopSequence();
              setExportEnabled(false);
            }}
          />
        }
        id="discard-button"
      >
        <FormattedMessage id="CLEAR_SEQUENCE" />
      </Tooltip>
    </Container>
  );
};
