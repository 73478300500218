import { NoFunction } from "@/types/NoFunction";
import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, FC, useContext } from "react";
import {
  LOCAL_STORAGE_KEYS,
  SIGNATURE_HEADER_NAME,
  SIGNATURE_PREFIX,
} from "../const";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useQueryParams } from "../hooks/useQueryParams";

type ProviderContextType = {
  getAuthenticationHeader: (args?: {
    force: boolean;
  }) => Promise<Record<string, string> | null>;
  logout: (returnUrl?: string) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  getAuthenticationHeader: () => Promise.resolve(null),
  logout: NoFunction,
});

export const AuthenticationProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { getAccessTokenSilently, logout: auth0Logout } = useAuth0();
  const { queryParams } = useQueryParams();
  const { setValue } = useLocalStorage();

  const getAuthenticationHeader = async ({ force } = { force: false }) => {
    if (queryParams.get(SIGNATURE_HEADER_NAME)) {
      const result = {} as Record<string, string>;
      [...queryParams.entries()]
        .filter((entry) => entry[0].startsWith(SIGNATURE_PREFIX))
        .forEach((entry) => (result[entry[0]] = entry[1]));
      return result;
    }
    let token = null;
    try {
      token = await getAccessTokenSilently({ cacheMode: force ? "off" : "on" });
    } catch (e) {
      console.error(e);
      await logout();
    }
    if (token) {
      return { Authorization: `Bearer ${token}` };
    }
    return {};
  };

  const logout = async (returnUrl: string | undefined = undefined) => {
    setValue(LOCAL_STORAGE_KEYS.LOGIN_ORG_ID, null);
    await auth0Logout({
      logoutParams: { returnTo: returnUrl || window.location.origin },
    });
  };

  return (
    <ProviderContext.Provider value={{ getAuthenticationHeader, logout }}>
      {children}
    </ProviderContext.Provider>
  );
};

export const useAuthentication = () => useContext(ProviderContext);
