import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { routes } from "@/config/routes";
import { QuoteDocumentImport } from "@/contractor/pages/home/common/quote-document/components/QuoteDocumentImport";
import {
  QuoteDocumentProvider,
  useQuoteDocument,
} from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AssetFieldsFragment,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";

const AddQuoteDocument = tw.div`
  grid grid-flow-col justify-start gap-2 items-center text-sm
`;
const Container = tw.div`
  grid p-4 gap-4
`;

const ReplaceQuoteFileProviders: FC = () => {
  const intl = useIntl();
  const [updateContractorRelease, { loading: updating }] =
    useUpdateContractorReleaseMutation();
  const { release } = useRelease();
  const { setError } = useGlobalError();
  const { createQuoteDocumentWithAsset, setAsset, quoteDocument } =
    useQuoteDocument();
  const navigate = useNavigate();
  const { openDialog } = useDialog();

  const handleQuoteDocumentChange = (asset: AssetFieldsFragment) => {
    setAsset(asset);
  };

  const onSave = async () => {
    if (release && release.project) {
      const document = await createQuoteDocumentWithAsset(
        release.project.location.id,
      );

      if (!document) {
        return;
      }

      try {
        const { errors, data } = await updateContractorRelease({
          variables: {
            input: {
              releaseId: release.id,
              version: release.version,
              quoteDocumentId: document.id,
            },
          },
        });
        setError(errors);
        if (data) {
          navigate(
            generatePath(routes.editDeliveryFromQuote, {
              quoteDocumentId: document.id,
              deliveryId: release.id,
            }),
          );
        }
      } catch (error) {
        setError(error);
      }
    } else if (quoteDocument) {
      const document = await createQuoteDocumentWithAsset(
        quoteDocument?.orgLocation?.id,
      );
      if (document) {
        openDialog({
          content: (
            <SuccessModal
              message={intl.$t({ id: "QUOTE_DOCUMENT_REPLACED" })}
            />
          ),
          closingTimer: DIALOG_AUTO_CLOSE_TIMER,
        });
        setTimeout(() => {
          navigate({
            pathname: generatePath(routes.deliveryFromQuote, {
              quoteDocumentId: document?.id,
            }),
          });
        }, DIALOG_AUTO_CLOSE_TIMER);
      }
    }
  };

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <AddQuoteDocument onClick={() => togglePanel(true)}>
          <FormattedMessage id="REPLACE_THIS_QUOTE" />
          <PrimaryButton $small>
            <FormattedMessage id="REPLACE" />
          </PrimaryButton>
        </AddQuoteDocument>
      )}
      content={(togglePanel) => (
        <OverlayPanel
          onSave={onSave}
          saveLabel={intl.$t({ id: "REPLACE" })}
          title={intl.$t({ id: "REPLACE" })}
          onCancel={() => togglePanel(false)}
          className="px-0"
          isLoading={updating}
        >
          <Container>
            <QuoteDocumentImport onChange={handleQuoteDocumentChange} />
          </Container>
        </OverlayPanel>
      )}
    />
  );
};

export const ReplaceQuoteFile: FC = () => {
  return (
    <QuoteDocumentProvider>
      <ReplaceQuoteFileProviders />
    </QuoteDocumentProvider>
  );
};
