import { gql } from "@apollo/client";

export const RELEASE_APPROVAL_FIELDS = gql`
  fragment ReleaseApprovalFields on ReleaseApproval {
    id
    approver {
      id
      firstName
      lastName
    }
    roles
    createdAt
  }
`;
