import { If } from "@/common/components/if/If";
import { InlineNotesAndAttachments } from "@/common/components/inline-notes-and-attachments/InlineNotesAndAttachments";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import {
  AssetContext,
  DistributorQuotesAuxiliaryQuoteItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";

type Props = {
  item: DistributorQuotesAuxiliaryQuoteItemFieldsFragment;
  readonly: boolean;
};

const DistributorQuoteAuxiliaryNotesWithProvider: FC<Props> = ({
  item,
  readonly,
}) => {
  const { updateQuote, setExpandedItems, expandedItems } =
    useDistributorQuote();
  const { assets } = useUploadAssets();

  const closeItem = useCallback(
    () => setExpandedItems(expandedItems.filter((id) => id !== item.id)),
    [expandedItems, item.id, setExpandedItems],
  );

  const handleOnSave = useCallback(
    async (notes: string) => {
      const updatedAuxiliaryItems = [
        {
          auxiliaryItemId: item.id,
          notes,
          assets,
        },
      ];
      await updateQuote({
        updatedAuxiliaryItems,
      });

      closeItem();
    },
    [assets, closeItem, item.id, updateQuote],
  );

  return (
    <If isTrue={expandedItems.includes(item.id)}>
      <InlineNotesAndAttachments
        closeItem={closeItem}
        item={item}
        readonly={readonly}
        saveItem={handleOnSave}
      />
    </If>
  );
};

export const DistributorQuoteAuxiliaryNotes: FC<Props> = (props) => {
  const { item } = props;
  const { quote } = useDistributorQuote();

  return (
    <UploadAssetProvider
      initialAssets={item.assets}
      context={AssetContext.Note}
      projectId={quote?.rfq.project?.id ?? ""}
    >
      <DistributorQuoteAuxiliaryNotesWithProvider {...props} />
    </UploadAssetProvider>
  );
};
