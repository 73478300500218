import {
  AssignableBuyout,
  useAssignableBuyoutsQuery,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useRelease } from "./ReleaseProvider";

export type AssignableBuyoutWithBestMatch = AssignableBuyout & {
  id: string;
  bestMatch?: boolean;
};

type ProviderContextType = {
  expandedItem: string;
  selectedItem: string;
  setExpandedItem: (item: string) => void;
  setSelectedItem: (item: string) => void;
  items: AssignableBuyoutWithBestMatch[];
  allVendors: boolean;
  setAllVendors: (value: boolean) => void;
  search: string | undefined;
  setSearch: (value: string | undefined) => void;
};

type Props = {
  children: React.ReactNode;
};

const ProviderContext = createContext<ProviderContextType>({
  expandedItem: "",
  selectedItem: "",
  setExpandedItem: NoFunction,
  setSelectedItem: NoFunction,
  items: [],
  allVendors: false,
  setAllVendors: NoFunction,
  search: "",
  setSearch: NoFunction,
});

export const AssignBuyoutProvider: FC<Props> = ({ children }) => {
  const [allVendors, setAllVendors] = useState<boolean>(false);
  const [search, setSearch] = useState<string | undefined>("");
  const { release } = useRelease();
  const [items, setItems] = useState<AssignableBuyoutWithBestMatch[]>([]);
  const { data } = useAssignableBuyoutsQuery({
    variables: {
      input: {
        releaseId: release?.id ?? "",
        sellerOrgLocationId: !allVendors
          ? release?.sellerOrgLocation?.id
          : null,
      },
    },
    skip: !release,
  });

  const [expandedItem, setExpandedItem] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<string>(
    release?.buyout?.id ?? "",
  );

  useEffect(() => {
    if (data) {
      const itemsData =
        data.assignableBuyouts as AssignableBuyoutWithBestMatch[];
      const searchStr = search?.replaceAll("#", "");
      setItems(
        searchStr
          ? itemsData.filter((item) =>
              item.buyout.clientIdentifier.includes(searchStr),
            )
          : itemsData,
      );
    }
  }, [data, search]);

  return (
    <ProviderContext.Provider
      value={{
        expandedItem,
        selectedItem,
        setExpandedItem,
        setSelectedItem,
        items,
        allVendors,
        setAllVendors,
        search,
        setSearch,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useAssignBuyout = (): ProviderContextType =>
  useContext(ProviderContext);
