import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div` 
  grid grid-flow-col items-center justify-center gap-2 mb-4
  text-base font-medium rounded-[32px] bg-blue-450 pl-6 pr-3 py-2
`;
const Arrow = tw.button`text-blue-800 text-xs disabled:opacity-50`;
const Text = tw.div`text-blue-800 text-xs`;

type Props = {
  titleId: string;
  step: number;
  setStep: (step: number) => void;
  prevStepCallback?: () => void;
  nextStepCallback?: () => void;
  sequenceIds: string[];
  exitSequence: () => void;
};

export const SequenceStepper: FC<Props> = ({
  titleId,
  step,
  setStep,
  prevStepCallback,
  nextStepCallback,
  sequenceIds,
  exitSequence,
}) => {
  const nextStep = useCallback(() => {
    setStep(step + 1);
    nextStepCallback?.();
  }, [nextStepCallback, setStep, step]);

  const prevStep = useCallback(() => {
    setStep(step - 1);
    prevStepCallback?.();
  }, [prevStepCallback, setStep, step]);

  return (
    <Container>
      <FormattedMessage id={titleId} tagName={Text} />
      <Arrow onClick={prevStep} disabled={step === 0}>
        <ChevronLeft />
      </Arrow>
      <FormattedMessage
        id="PAGE_OF_TOTAL"
        tagName={Text}
        values={{
          page: step + 1,
          total: sequenceIds.length,
        }}
      />
      <Arrow onClick={nextStep} disabled={sequenceIds.length === step + 1}>
        <ChevronRight />
      </Arrow>
      <PrimaryButton onClick={exitSequence}>
        <FormattedMessage id="EXIT_SEQUENCE" />
      </PrimaryButton>
    </Container>
  );
};
