import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useUser } from "@/common/providers/UserProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  CostCode,
  SourceSystem,
  useStartupDataQuery,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { UNSPECIFIED_COST_CODE_ID } from "./../../../../../../../common/hooks/useUnspecifiedCostCode";

export type CostCodeType =
  | Pick<CostCode, "code" | "description">
  | string
  | null
  | undefined;

const NonFormattingSourceSystems = [
  SourceSystem.QbOnline,
  SourceSystem.QbDesktop,
];

export const useCostCodes = () => {
  const intl = useIntl();
  const { connectedSourceSystem } = useOrgSettings();
  const { isContractor } = useUser();
  const { data, loading, error } = useStartupDataQuery({
    skip: !isContractor,
  });
  useErrorEffect(error);

  const showCodeWithNumber = useMemo(() => {
    return (
      (!connectedSourceSystem ||
        !NonFormattingSourceSystems.includes(connectedSourceSystem)) &&
      isContractor
    );
  }, [connectedSourceSystem, isContractor]);

  const trimDescription = useCallback((description: string) => {
    return description.replace(/\s+/g, " ").trim();
  }, []);

  const formatCostCode = useCallback(
    (costCode: CostCodeType) => {
      if (!costCode) {
        return "";
      }
      if (typeof costCode === "string") {
        const code = data?.viewer?.org.costCodes.find(
          (cc) =>
            cc.code.toLowerCase() === costCode.toLowerCase() ||
            trimDescription(cc.description).toLowerCase() ===
              costCode.toLowerCase() ||
            costCode === cc.id,
        );

        return code
          ? showCodeWithNumber
            ? `${trimDescription(code?.description)} (${code?.code})`
            : trimDescription(code?.description)
          : costCode === UNSPECIFIED_COST_CODE_ID
            ? intl.$t({ id: "UNSPECIFIED" })
            : costCode;
      }

      return showCodeWithNumber
        ? `${trimDescription(costCode.description)} (${costCode.code})`
        : trimDescription(costCode.description);
    },
    [data?.viewer?.org.costCodes, intl, showCodeWithNumber, trimDescription],
  );

  const formattedCostCodes = useMemo(() => {
    return (
      data?.viewer?.org.costCodes.map((cc) => ({
        ...cc,
        description: trimDescription(cc.description),
        formatted: showCodeWithNumber
          ? formatCostCode(cc)
          : trimDescription(cc.description),
      })) || []
    );
  }, [
    data?.viewer?.org.costCodes,
    formatCostCode,
    showCodeWithNumber,
    trimDescription,
  ]);

  return {
    costCodes: formattedCostCodes,
    formatCostCode,
    loading,
    showCodeWithNumber,
  };
};
