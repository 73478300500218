import { If } from "@/common/components/if/If";
import { ReleaseItemIssueDescription } from "@/common/components/issue-details/ReleaseItemIssueDescription";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import { NoFunctionPromise } from "@/types/NoFunction";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { DistributorReleaseDatePicker } from "../../../components/DistributorReleaseDatePicker";
import { useDistributorRelease } from "../../../providers/DistributorReleaseProvider";
import { useDistributorReleaseDeliveryRecord } from "../providers/DistributorReleaseDeliveryRecordProvider";
import { DistributorReleaseItemIssueSolution } from "./DistributorReleaseItemIssueSolution";

const Container = tw.div`my-5`;
const Headline = tw.div`font-medium px-6`;
const Details = tw.div`my-2 py-4 px-6 bg-gray-100`;

type Props = {
  item: DistributorReleaseItemFieldsFragment;
  onClose: () => void;
};

export const DistributorReleaseItemIssuesPanel: FC<Props> = ({
  item,
  onClose,
}) => {
  const intl = useIntl();

  const { release } = useDistributorRelease();
  const {
    calendarOpened,
    issueResolutions,
    setIssueRedelivery,
    toggleCalendarOpened,
    handleSaveIssueSolutions,
    handleCancelIssueSolutions,
  } = useDistributorReleaseDeliveryRecord();

  const issue = useMemo(() => item?.issues?.[0], [item]);

  const selectedIssueSolutions = useMemo(
    () => issueResolutions.find((r) => r.issueId === issue?.id),
    [issueResolutions, issue?.id],
  );

  const selectedRedelivery = useMemo(
    () =>
      selectedIssueSolutions?.edit?.redelivery ||
      selectedIssueSolutions?.redelivery,
    [selectedIssueSolutions],
  );

  const title = useMemo(
    () =>
      calendarOpened
        ? intl.$t({ id: "SET_DELIVERY_DATETIME" })
        : intl.$t(
            { id: "DELIVERY_ISSUE_NUMBER" },
            { number: release?.sequenceNumber },
          ),
    [calendarOpened, release?.sequenceNumber, intl],
  );

  const onSave = useCallback(() => {
    if (calendarOpened) {
      toggleCalendarOpened();
    } else {
      onClose();
      handleSaveIssueSolutions(issue?.id);
    }
  }, [
    calendarOpened,
    issue?.id,
    toggleCalendarOpened,
    handleSaveIssueSolutions,
    onClose,
  ]);

  const onCancel = useCallback(() => {
    if (issue?.resolution) {
      onClose();
    } else if (calendarOpened) {
      toggleCalendarOpened();
      setIssueRedelivery(issue?.id, null);
    } else {
      onClose();
      handleCancelIssueSolutions(issue?.id);
    }
  }, [
    calendarOpened,
    issue,
    toggleCalendarOpened,
    handleCancelIssueSolutions,
    setIssueRedelivery,
    onClose,
  ]);

  if (!issue) {
    return null;
  }

  return (
    <OverlayPanel
      title={title}
      hasBackArrow={calendarOpened}
      onSave={issue?.resolution ? undefined : onSave}
      saveLabel={intl.$t({
        id: calendarOpened ? "CONFIRM" : "SAVE",
      })}
      onCancel={onCancel}
      cancelLabel={intl.$t({ id: calendarOpened ? "CANCEL" : "CLOSE" })}
      hideCloseButton={calendarOpened}
      noPadding
    >
      <If isTrue={calendarOpened}>
        <DistributorReleaseDatePicker
          item={item}
          release={release}
          onChange={(value) => setIssueRedelivery(issue?.id, value)}
          onSave={NoFunctionPromise}
          className="mt-10 w-full px-10"
          withoutBottomButtons
          selectedFulfillmentAlternative={selectedRedelivery}
          useReleaseTimeAsDefault={false}
          useCurrentDayAsDefault={false}
          includeBackorderQuantity={false}
          labels={{
            newInput: "SET_DELIVERY_TIME",
            timeHeader: "SET_DELIVERY_DATETIME",
          }}
        />
      </If>
      <If isTrue={!calendarOpened}>
        <Container>
          <Headline>
            <FormattedMessage id="ITEM_DELIVERED" />
          </Headline>
          <Details>
            <ReleaseItemIssueDescription item={item} />
          </Details>
        </Container>
        <DistributorReleaseItemIssueSolution item={item} />
      </If>
    </OverlayPanel>
  );
};
