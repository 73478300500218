import { If } from "@/common/components/if/If";
import { Switch } from "@/common/components/switch/Switch";
import { InfoOutlined } from "@mui/icons-material";
import { useIntl } from "react-intl";
import {
  DefaultSettingsToggleText,
  SimpleStepCol,
  SimpleStepContainer,
  SimpleStepContent,
  SimpleStepItemRow,
  SimpleStepRow,
  SimpleStepTitle,
  SimpleStepTitleContainer,
  StyledTextField,
} from "../../../Wizard.styles";
import { useAccountingOnPremiseWizard } from "../../AccountingOnPremiseWizardProvider";

export const FoundationOnPremisePostInventoryReceipts = () => {
  const intl = useIntl();
  const {
    postInventoryReceipts,
    setPostInventoryReceipts,
    inventoryReceiptLedgerAccount,
    setInventoryReceiptLedgerAccount,
  } = useAccountingOnPremiseWizard();

  return (
    <SimpleStepContainer>
      <SimpleStepRow>
        <InfoOutlined className="mr-4 h-12 w-12 text-gray-400" />
        <SimpleStepTitleContainer>
          <SimpleStepTitle>
            {intl.$t({ id: "INVENTORY_RECEIPT_SETTINGS" })}
          </SimpleStepTitle>
        </SimpleStepTitleContainer>
      </SimpleStepRow>
      <SimpleStepContent className="bg-gray-100 px-6">
        <SimpleStepItemRow
          className={`grid-cols-[1fr_110px] ${
            postInventoryReceipts ? "" : "border-0"
          }`}
        >
          <DefaultSettingsToggleText className="text-start">
            {intl.$t({
              id: "DO_YOU_PLAN_TO_POST_INVENTORY_RECEIPTS_FOUNDATION",
            })}
          </DefaultSettingsToggleText>
          <Switch
            width={110}
            offLabel={intl.$t({ id: "NO" })}
            onLabel={intl.$t({ id: "YES" })}
            onChange={setPostInventoryReceipts}
            value={postInventoryReceipts}
          />
        </SimpleStepItemRow>
        <If isTrue={postInventoryReceipts === true}>
          <SimpleStepCol className="items-start pt-5">
            <DefaultSettingsToggleText className="text-start">
              {intl.$t({
                id: "SPECIFY_GENERAL_LEDGER_ACCOUNT_FOR_UNINVOICED_RECEIPTS",
              })}
            </DefaultSettingsToggleText>
            <StyledTextField
              label={intl.$t({ id: "GL_ACCOUNT_NUMBER" })}
              value={inventoryReceiptLedgerAccount}
              onChange={(event) =>
                setInventoryReceiptLedgerAccount(event.target.value)
              }
              required
            />
          </SimpleStepCol>
        </If>
      </SimpleStepContent>
    </SimpleStepContainer>
  );
};
