import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { PDF_FONT } from "@/common/const";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import { useItemsByCategory } from "@/distributor/common/hooks/useItemsByCategory";
import { useServiceCategories } from "@/distributor/common/hooks/useServiceCategories";
import { useTotalPrices } from "@/distributor/common/hooks/useTotalPrices";
import {
  DistributorQuoteFieldsFragment,
  QuoteStatus,
  QuoteStockingPriceFieldsFragment,
} from "@/generated/graphql";
import jsPDF from "jspdf";
import { useIntl } from "react-intl";
import { excludedItems } from "./excludedItems";
import { materials } from "./materials";
import { quoteHeader } from "./quoteHeader";
import { stocking } from "./stocking";
import { termsAndConditions } from "./termsAndConditions";

export const usePrintView = (
  quote:
    | (DistributorQuoteFieldsFragment & QuoteStockingPriceFieldsFragment)
    | null
    | undefined,
) => {
  const intl = useIntl();
  const { categories } = useServiceCategories(
    quote?.servicePriceSheet?.prices || [],
  );
  const { totalPriceWithDiscount } = useTotalPrices(quote);
  const { itemsByCategory } = useItemsByCategory();
  const { formatCurrency } = useFormatNumberToCurrency();

  const { getVendorHasManufacturerSetting } = useManufacturersSettings();
  const hasManufacturersSetting = getVendorHasManufacturerSetting(
    quote?.rfq.project?.location?.org.settings?.display,
  );

  const savePDF = () => {
    if (quote) {
      const doc = new jsPDF("landscape").setFont(PDF_FONT);
      doc
        .setFont(PDF_FONT, "", "bold")
        .text(
          intl.$t({ id: "QUOTE_HEADLINE" }, { number: quote.clientIdentifier }),
          10,
          20,
        );
      quoteHeader(doc, quote, intl, totalPriceWithDiscount, formatCurrency);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const topSpacing = (doc as any).lastAutoTable.finalY + 5;
      doc
        .setFont(PDF_FONT, "", "bold")
        .text(intl.$t({ id: "NOTES_AND_TERMS" }), 10, topSpacing);
      termsAndConditions(doc, quote, intl, formatCurrency);
      doc.addPage();
      materials(doc, quote, intl, hasManufacturersSetting, formatCurrency);
      if (
        quote.itemGroups.some((i) =>
          i.quoteItems.some((qi) => qi.status === QuoteStatus.Withdrawn),
        )
      ) {
        doc.addPage();
        excludedItems(doc, quote, intl, hasManufacturersSetting);
      }
      if (quote.servicePriceSheet) {
        doc.addPage();
        doc
          .setFont(PDF_FONT, "bold")
          .text(intl.$t({ id: "DELIVERY_AND_STOCKING_FEES" }), 10, 10);
        stocking(doc, quote, intl, categories, itemsByCategory, formatCurrency);
      }
      doc.save(`Quote #${quote.clientIdentifier}.pdf`);
    }
  };

  return {
    savePDF,
  };
};
