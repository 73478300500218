import { routes } from "@/config/routes";
import { WithMainTabNavigation } from "@/contractor/pages/home/common/navigation/WithMainTabNavigation";
import { WithReleaseTabNavigation } from "./common/release-tab-navigation/WithReleaseTabNavigation";
import { Deliveries } from "./pages/deliveries/Deliveries";
import { DeliverySlips } from "./pages/delivery-slips/DeliverySlips";
import { DeliverySlipVerification } from "./pages/delivery-slips/components/delivery-slip-verification/DeliverySlipVerification";
import { DeliverySlipMatchOrder } from "./pages/delivery-slips/components/delivery-slip-verification/delivery-slip-match-order/DeliverySlipMatchOrder";
import { DeliverySlipReceiveOrder } from "./pages/delivery-slips/components/delivery-slip-verification/delivery-slip-receive-order/DeliverySlipReceiveOrder";

export const deliveriesRoutes = [
  {
    path: routes.deliveries,
    element: (
      <WithMainTabNavigation>
        <WithReleaseTabNavigation />
      </WithMainTabNavigation>
    ),
    children: [
      {
        path: routes.deliveries,
        element: <Deliveries />,
      },
      {
        path: routes.deliverySlips,
        element: <DeliverySlips />,
      },
    ],
  },
  {
    path: routes.deliverySlipVerification,
    element: <DeliverySlipVerification />,
    children: [
      {
        path: routes.deliverySlipMatchOrder,
        element: <DeliverySlipMatchOrder />,
      },
      {
        path: routes.deliverySlipReceiveOrder,
        element: <DeliverySlipReceiveOrder />,
      },
    ],
  },
];
