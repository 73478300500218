import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { GridViewToggle } from "@/common/components/grid-view-toggle/GridViewToggle";
import { If } from "@/common/components/if/If";
import { SpreadsheetSaveType } from "@/common/providers/ColumnMapperProvider";
import { VIEW_STATE, useTableView } from "@/common/providers/TableViewProvider";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useCostCodes } from "../hooks/useCostCodes";
import { useSyncCostCodes } from "../providers/SyncCostCodesProvider";

const FooterContent = tw.div`grid grid-cols-[80px_1fr_auto] gap-4 w-full h-10 items-center`;
const SwitchContainer = tw.div`flex justify-start`;

export const CostCodesFooter = () => {
  const { syncCostCodes } = useSyncCostCodes();
  const { loading } = useCostCodes();
  const { tableView } = useTableView();

  return (
    <FloatingFooter>
      <FooterContent>
        <span />
        <SwitchContainer>
          <GridViewToggle saveState={syncCostCodes} saving={loading} />
        </SwitchContainer>
        <If isTrue={tableView === VIEW_STATE.spreadsheet}>
          <PrimaryButton
            onClick={() => syncCostCodes(SpreadsheetSaveType.SaveButton)}
          >
            <FormattedMessage id="SAVE_CHANGES" />
          </PrimaryButton>
        </If>
      </FooterContent>
    </FloatingFooter>
  );
};
