import { GroupBySegment } from "@/common/components/group-by-segment/GroupBySegment";
import { FC } from "react";
import { useDistributorReleaseItemsZoneProvider } from "../../providers/DistributorReleaseItemsZoneProvider";

export const DistributorGroupByCostCodeToggle: FC = () => {
  const { groupedByCostCode, setGroupedByCostCode } =
    useDistributorReleaseItemsZoneProvider();
  return (
    <GroupBySegment
      groupedByCostCode={groupedByCostCode}
      setGroupedByCostCode={setGroupedByCostCode}
    />
  );
};
