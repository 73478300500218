import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";

import { DECIMAL_MAX_FRACTION_DIGITS } from "@/common/const";
import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useRenderHelpers } from "./useRenderHelpers";

export const useQuantityRenderer = () => {
  const intl = useIntl();
  const { checkReadOnly } = useRenderHelpers();

  const isNotEmpty = useCallback((value: string) => {
    return value !== "" && value !== null && value !== undefined;
  }, []);

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      const formattedValue = isNotEmpty(value)
        ? intl.formatNumber(Number(value), {
            maximumFractionDigits: DECIMAL_MAX_FRACTION_DIGITS,
          })
        : "";
      textRenderer(
        instance,
        td,
        row,
        col,
        prop,
        formattedValue,
        cellProperties,
      );

      checkReadOnly(td, row, instance, cellProperties);
    },
    [intl, isNotEmpty, checkReadOnly],
  );

  return renderer;
};
