import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { DrawerView } from "@/common/components/panel/DrawerView";
import { FC, PropsWithChildren, useCallback, useState } from "react";
import { ProjectFormWithStepper } from "./project-form-with-stepper/ProjectFormWithStepper";

type ToggleRfqFormProps = PropsWithChildren;

export const ToggleProjectForm: FC<ToggleRfqFormProps> = ({ children }) => {
  const { isOpened, toggle } = useGlobalDrawer();
  const [calendarOpened, setCalendarOpened] = useState(false);

  const onAnchorClick = useCallback(
    (
      ev: React.MouseEvent<HTMLElement>,
      togglePanel: (opened: boolean) => void,
    ) => {
      ev.stopPropagation();
      togglePanel(true);
    },
    [],
  );

  const onDrawerClick = useCallback(() => {
    if (calendarOpened) {
      setCalendarOpened(false);
    }
  }, [calendarOpened, setCalendarOpened]);

  const setIsOpened = useCallback(
    (opened: boolean) => {
      setCalendarOpened(false);
      toggle("new", opened);
    },
    [setCalendarOpened, toggle],
  );

  return (
    <DrawerView
      isOpened={isOpened("new")}
      setIsOpened={setIsOpened}
      onClick={onDrawerClick}
      anchor={(togglePanel) => (
        <div
          onClick={(ev: React.MouseEvent<HTMLElement>) =>
            onAnchorClick(ev, togglePanel)
          }
        >
          {children}
        </div>
      )}
      content={(togglePanel) => (
        <ProjectFormWithStepper
          onClose={() => togglePanel(false)}
          calendarOpened={calendarOpened}
          setCalendarOpened={setCalendarOpened}
        />
      )}
    />
  );
};
