import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { Price } from "@/common/components/price/Price";
import {
  BuyoutStandaloneReleaseItemFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC } from "react";
import { OrderedContainer } from "./Buyout.styles";
import { BuyoutReleaseAmountsPanel } from "./BuyoutReleaseAmountsPanel";

type Props = {
  title: string;
  item: BuyoutStandaloneReleaseItemFieldsFragment;
  releaseStatuses: ReleaseStatus[];
};

export const BuyoutStandaloneReleaseItemOrderedAmounts: FC<Props> = ({
  title,
  item,
  releaseStatuses,
}) => {
  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <OrderedContainer>
          <LinkLike onClick={() => togglePanel(true)}>
            <Price price={item.orderedSoFar} />
          </LinkLike>
        </OrderedContainer>
      )}
      content={(togglePanel) => (
        <BuyoutReleaseAmountsPanel
          item={item}
          title={title}
          amount={item.orderedSoFar}
          material={item.orgMaterial}
          onClose={() => togglePanel(false)}
          releaseStatuses={releaseStatuses}
        />
      )}
    />
  );
};
