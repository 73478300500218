import { Price } from "@/common/components/price/Price";
import { sumIncludedFullPrices } from "@/common/components/price/utils/sumIncludedFullPrices";
import { DistributorBuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";

type BuyoutCategoryTotalProps = {
  items: DistributorBuyoutItemFieldsFragment[];
};

export const BuyoutCategoryTotal: FC<BuyoutCategoryTotalProps> = ({
  items,
}) => {
  const price = useMemo(() => items.reduce(sumIncludedFullPrices, 0), [items]);

  return <Price price={price} />;
};
