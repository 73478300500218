import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import {
  COLUMN_TYPE,
  SpreadSheetConfig,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import {
  AddToReleaseItemInput,
  AssetFieldsFragment,
  DeliverySlipDocument,
  DeliverySlipsDocument,
  UomsDocument,
  UpdateChargeInput,
  UpdateContractorReleaseItemInput,
  useCreateStandaloneReleaseMutation,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionStringPromise,
} from "@/types/NoFunction";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "@/common/const";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { RELEASE } from "@/common/queries/release";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import Decimal from "decimal.js";
import { useForm, UseFormReturn } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useDeliverySlipReleaseSpreadsheetConfig } from "../components/delivery-slip-verification/components/delivery-slip-edit-release/DeliverySlipRelease.config";
import { DeliverySlipReleaseFormValues } from "../components/delivery-slip-verification/components/delivery-slip-form/DeliverySlipVerificationForm";
import { useDeliverySlipImportExternalPO } from "./DeliverySlipImportExternalPOProvider";
import { useDeliverySlipVerification } from "./DeliverySlipVerificationProvider";

type ReceivedQuantity = {
  releaseItemId: string;
  receivedQuantityDecimal: string | undefined | null;
};

export type UpdateReleaseFormValues = {
  businessLocationId: string;
  projectId: string;
  vendorId: string;
  poNumber: string;
  orderDate: Date;
  orderTypeId: string;
  subtotal: number;
  paymentTerm: string | undefined;
  additionalCharges: UpdateChargeInput[];
  taxRate: string | undefined;
  customTaxAmount: string | undefined;
  total: string;
};

type UpdateReleaseForm = UseFormReturn<UpdateReleaseFormValues, unknown>;

type ProviderContextType = {
  syncCreateReleaseFromDeliverySlip: (
    values: DeliverySlipReleaseFormValues,
  ) => Promise<string | boolean>;
  syncUpdateReleaseFromDeliverySlip: (
    values: UpdateReleaseFormValues,
  ) => Promise<boolean>;
  updateReleaseDeliverySlips: (deliverySlipUrls: string[]) => Promise<boolean>;
  loading: boolean;
  updating: boolean;
  spreadsheetViewColumns: SpreadSheetConfig[];
  setDeliveryPhotos: (assets: AssetFieldsFragment[]) => void;
  receivedQuantities: ReceivedQuantity[];
  updateReceivedItemQuantity: (id: string, quantityDecimal: string) => void;
  updateReleaseForm: UpdateReleaseForm;
  requestedTime: number | null;
  setRequestedTime: (time: number | null) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  syncCreateReleaseFromDeliverySlip: NoFunctionStringPromise,
  syncUpdateReleaseFromDeliverySlip: NoFunctionBooleanPromise,
  updateReleaseDeliverySlips: NoFunctionBooleanPromise,
  loading: false,
  updating: false,
  spreadsheetViewColumns: [],
  setDeliveryPhotos: NoFunction,
  receivedQuantities: [],
  updateReceivedItemQuantity: NoFunction,
  updateReleaseForm: {} as UpdateReleaseForm,
  requestedTime: null,
  setRequestedTime: NoFunction,
});

export const DeliverySlipReleaseProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { setError } = useGlobalError();
  const { materials } = useMaterials();
  const { projects } = useProjectListOptions({
    includeTags: true,
    includeClosedProjects: true,
  });
  const { release } = useRelease();
  const { manufacturers } = useManufacturers();
  const { deliverySlip, refetch } = useDeliverySlipVerification();
  const { importedPoExternalId, importedPoItemized, importedItems } =
    useDeliverySlipImportExternalPO();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const [receivedQuantities, setReceivedQuantities] = useState<
    ReceivedQuantity[]
  >([]);
  const [requestedTime, setRequestedTime] = useState<number | null>(null);

  const [deliveryPhotos, setDeliveryPhotos] = useState<AssetFieldsFragment[]>(
    [],
  );

  const updateReleaseForm: UpdateReleaseForm = useForm<UpdateReleaseFormValues>(
    {
      defaultValues: {
        businessLocationId: release?.project?.location?.id || "",
        projectId: release?.project?.id,
        vendorId: release?.sellerOrgLocation?.id ?? "",
        poNumber: release?.poNumber ?? "",
        orderDate: release?.time
          ? new Date(release?.time)
          : deliverySlip?.fulfillmentDate
            ? new Date(deliverySlip?.fulfillmentDate)
            : undefined,
        orderTypeId: release?.type.id,
        customTaxAmount: release?.taxAmount || undefined,
        taxRate: release?.taxRate || undefined,
        additionalCharges: release?.additionalCharges ?? [],
        subtotal: Number(release?.subtotal ?? 0),
        total: release?.total || "",
      },
      mode: "onChange",
      reValidateMode: "onChange",
    },
  );

  useEffect(() => {
    if (release) {
      updateReleaseForm.reset({
        ...updateReleaseForm.getValues(),
        projectId: release?.project?.id,
        businessLocationId: release?.project?.location?.id,
        vendorId: release?.sellerOrgLocation?.id,
        taxRate: release?.taxRate || "",
        customTaxAmount: release?.taxAmount || "",
        subtotal: Number(release?.subtotal ?? 0),
        total: release?.total || "",
      });
    }
  }, [release, updateReleaseForm]);

  const {
    spreadsheetData,
    resetPreviousData,
    getRemovedRowIds,
    gotoInvalidRow,
  } = useColumnMapper();
  const {
    getCellValue,
    getRowUomCreatableValue,
    getCostCodeId,
    rowIsEmpty,
    addMissingMaterials,
    addMissingManufacturers,
    getCellWithAdditionalData,
    findMaterialByName,
    isBuyoutItem,
  } = useTableHelpers();
  const { setWarningAlert } = useSnackbar();

  const { connectedSourceSystem, hasPhaseCodes: hasOrgSettingsPhaseCodes } =
    useOrgSettings();
  const { phaseCodeOptions } = useColumnMapper();

  const hasPhaseCodes = useMemo(
    () => hasOrgSettingsPhaseCodes && phaseCodeOptions?.length > 0,
    [hasOrgSettingsPhaseCodes, phaseCodeOptions?.length],
  );

  const [saving, setSaving] = useState(false);

  const [createStandaloneRelease, { loading: creatingRelease }] =
    useCreateStandaloneReleaseMutation();
  const syncCreateReleaseFromDeliverySlip = async (
    values: DeliverySlipReleaseFormValues,
  ) => {
    if (spreadsheetData.every((row) => rowIsEmpty(row))) {
      setWarningAlert(
        <FormattedMessage id={`VALIDATION_ERROR_SHEETS_EMPTY_LIST`} />,
      );
      return false;
    }

    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ]) ||
      !validateRowValues([
        COLUMN_TYPE.Quantity,
        COLUMN_TYPE.UOM,
        ...(hasPhaseCodes ? [COLUMN_TYPE.PhaseCode] : [COLUMN_TYPE.CostCode]),
      ])
    ) {
      gotoInvalidRow();
      return false;
    }

    const newItems: AddToReleaseItemInput[] = [];

    setSaving(true);
    const newMaterials = (await addMissingMaterials()) || [];
    const newManufacturers = (await addMissingManufacturers()) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getRowUomCreatableValue(row);
      const isLumpSum = isLumpSumUomText(uom);
      if (isLumpSum) {
        material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
          ...materials,
          ...newMaterials,
        ]);
      }

      if (!material || rowIsEmpty(row)) {
        return false;
      }

      const quantityDecimal = getCellValue(row, COLUMN_TYPE.Quantity);
      const receivedQuantityDecimal =
        Number(getCellValue(row, COLUMN_TYPE.ReceivedQuantity)) || 0;
      const unitPrice = getCellValue(row, COLUMN_TYPE.PrefilledPrice);
      const manufacturer = [...manufacturers, ...(newManufacturers ?? [])].find(
        (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
      );
      const phaseCode = projects
        ?.find((p) => p.id === deliverySlip?.project?.id)
        ?.tags?.find(
          (t) => t?.name === getCellValue(row, COLUMN_TYPE.PhaseCode),
        );

      newItems.push({
        projectItem: {
          estimateUom: uom,
          ...(isOrgCatalogSku(material.material) && {
            orgCatalogSkuId: material.material.id,
          }),
          ...(isProductSku(material.material) && {
            masterProductId: material.material.id,
          }),
          ...(isMasterSku(material.material) && {
            masterSkuId: material.material.id,
          }),
        },
        manufacturerId: manufacturer?.id,
        receivedQuantityDecimal: String(receivedQuantityDecimal),
        quantityDecimal,
        unitPrice: unitPrice ? String(unitPrice) : undefined,
        ...(isLumpSum && { name: rowMaterialText }),
        tags: hasPhaseCodes && phaseCode ? [phaseCode.id] : undefined,
        position: index,
        costCodeId: getCostCodeId(row),
        deliverySlipId: deliverySlip?.id,
      });
    });

    if (newItems.length > 0) {
      try {
        const { data, errors } = await createStandaloneRelease({
          variables: {
            input: {
              deliverySlipId: deliverySlip?.id ?? "",
              projectId: values.projectId ?? "",
              sellerOrgLocationId: values.vendorId,
              poNumber: values.poNumber,
              time: values.orderDate?.getTime() ?? undefined,
              items: newItems,
              retroactive: true,
              deliveryPhotoUrls: deliveryPhotos.map((p) => p.url),
              mapping:
                importedPoExternalId && connectedSourceSystem
                  ? {
                      externalId: importedPoExternalId,
                      sourceSystem: connectedSourceSystem,
                    }
                  : undefined,
              typeId: values.orderTypeId || undefined,
              paymentTerm: values.paymentTerm,
              additionalCharges: values.additionalCharges,
              customTaxAmount: values.customTaxAmount || undefined,
              taxRate: values.taxRate || undefined,
              assignPOItemZones:
                !!importedPoExternalId && importedPoItemized === true
                  ? true
                  : undefined,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: UomsDocument,
            },
            {
              query: DeliverySlipsDocument,
            },
          ],
        });
        setError(errors);
        if (!errors) {
          resetPreviousData();
          refetch();
        }
        return data?.createStandaloneRelease?.id ?? "";
      } catch (error) {
        setError(error);
        return false;
      }
    }

    return true;
  };

  const [updateRelease, { loading: updating }] =
    useUpdateContractorReleaseMutation();
  const syncUpdateReleaseFromDeliverySlip = async (
    values: UpdateReleaseFormValues,
  ) => {
    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ]) ||
      !validateRowValues(
        [
          COLUMN_TYPE.Quantity,
          COLUMN_TYPE.UOM,
          ...(hasPhaseCodes ? [COLUMN_TYPE.PhaseCode] : [COLUMN_TYPE.CostCode]),
        ],
        undefined,
        { minPrice: undefined },
      )
    ) {
      gotoInvalidRow();
      return false;
    }

    const newItems: AddToReleaseItemInput[] = [];
    const itemsToUpdate: UpdateContractorReleaseItemInput[] = [];
    const itemsToRemove = getRemovedRowIds(release?.items ?? []);

    setSaving(true);
    const newMaterials = (await addMissingMaterials()) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getRowUomCreatableValue(row);
      const isLumpSum = isLumpSumUomText(uom);
      if (isLumpSum) {
        material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
          ...materials,
          ...newMaterials,
        ]);
      }

      if (!material || rowIsEmpty(row)) {
        return false;
      }

      const quantityDecimal =
        Number(getCellValue(row, COLUMN_TYPE.Quantity)) || 0;
      const receivedQuantityDecimal =
        Number(getCellValue(row, COLUMN_TYPE.ReceivedQuantity)) || 0;
      const unitPrice = getCellValue(row, COLUMN_TYPE.PrefilledPrice);
      const phaseCode = projects
        ?.find((p) => p.id === release?.project?.id)
        ?.tags?.find(
          (t) => t?.name === getCellValue(row, COLUMN_TYPE.PhaseCode),
        );

      const hasUnitPrice =
        unitPrice !== "" && unitPrice !== null && unitPrice !== undefined;

      const existingItem = release?.items.find((item) => item.id === row.id);
      const matchingMaterials =
        existingItem && existingItem.projectItem?.material.id === material.id;

      const buyoutItem = isBuyoutItem(
        rowMaterialText,
        "",
        "",
        unitPrice,
        getCellValue(row, COLUMN_TYPE.CostCode),
      );

      if (matchingMaterials) {
        itemsToUpdate.push({
          releaseItemId: row.id,
          uom,
          receivedQuantityDecimal: String(receivedQuantityDecimal),
          quantityDecimal: String(quantityDecimal),
          unitPrice: hasUnitPrice ? String(unitPrice) : undefined,
          ...(isLumpSum && { name: rowMaterialText }),
          clearUnitPrice: !hasUnitPrice,
          position: index,
          buyoutItemId: buyoutItem ? buyoutItem?.id : undefined,
          tags: hasPhaseCodes && phaseCode ? [phaseCode.id] : undefined,
          costCodeId: getCostCodeId(row),
        });
      } else {
        const importedItem = importedItems?.find((_, i) => i === index);

        newItems.push({
          ...(!buyoutItem && {
            projectItem: {
              estimateUom: uom ?? "",
              ...(isOrgCatalogSku(material.material) && {
                orgCatalogSkuId: material.material.id,
              }),
              ...(isProductSku(material.material) && {
                masterProductId: material.material.id,
              }),
              ...(isMasterSku(material.material) && {
                masterSkuId: material.material.id,
              }),
            },
          }),
          receivedQuantityDecimal: String(receivedQuantityDecimal),
          quantityDecimal: String(quantityDecimal),
          unitPrice: hasUnitPrice ? String(unitPrice) : undefined,
          ...(isLumpSum && { name: rowMaterialText }),
          position: index,
          buyoutItemId: buyoutItem ? buyoutItem?.id : undefined,
          tags: hasPhaseCodes && phaseCode ? [phaseCode.id] : undefined,
          costCodeId: getCostCodeId(row),
          poItemExternalId: importedItem?.id,
          deliverySlipId: deliverySlip?.id,
        });
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    if (release) {
      try {
        const { errors } = await updateRelease({
          variables: {
            input: {
              poNumber: values.poNumber,
              requestedTime: values.orderDate?.getTime() ?? undefined,
              releaseId: release.id,
              version: release.version,
              addedItems: newItems,
              updates: itemsToUpdate,
              removedItems: itemsToRemove,
              deliveryPhotoUrls: deliveryPhotos.map((p) => p.url),
              taxRate: values.taxRate === "" ? undefined : values.taxRate,
              customTaxAmount: values.customTaxAmount || undefined,
              clearCustomTaxAmount: !values.customTaxAmount,
              assignDefaultCostCodes: false,
              prefillPrices: false,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: RELEASE,
              variables: { id: release?.id ?? "" },
            },
            {
              query: UomsDocument,
            },
            {
              query: DeliverySlipDocument,
              variables: { id: deliverySlip?.id ?? "" },
            },
          ],
        });
        setError(errors);
        if (!errors) {
          resetPreviousData();
          refetch();
        }
        return !errors;
      } catch (error) {
        setError(error);
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (deliverySlip && release) {
      if (
        release.items.every((item) =>
          receivedQuantities.find(
            (receivedQuantity) => receivedQuantity.releaseItemId === item.id,
          ),
        )
      ) {
        return;
      }
      setReceivedQuantities(
        release?.items.map((item) => {
          const slipItem = deliverySlip?.deliveredReleaseItems.find(
            (drItem) =>
              drItem.releaseItem.id === item.id && Number(drItem.quantity) > 0,
          );
          const hintQuantity = deliverySlip.releaseItemHints.find(
            (h) => h.releaseItem.id === item.id,
          )?.deliverySlipItem?.quantity;
          return {
            releaseItemId: item.id,
            receivedQuantityDecimal:
              slipItem?.quantity ??
              hintQuantity?.toString() ??
              new Decimal(item.quantityDecimal)
                .sub(item?.receivedQuantityDecimal || 0)
                .toString(),
          };
        }),
      );
    }
  }, [deliverySlip, release, receivedQuantities]);

  const updateReceivedItemQuantity = useCallback(
    (id: string, quantityDecimal: string) => {
      setReceivedQuantities((prev) => {
        const index = prev.findIndex((item) => item.releaseItemId === id);
        if (index !== -1) {
          return prev.map((item) =>
            item.releaseItemId === id
              ? { releaseItemId: id, receivedQuantityDecimal: quantityDecimal }
              : item,
          );
        } else {
          return [
            ...prev,
            { releaseItemId: id, receivedQuantityDecimal: quantityDecimal },
          ];
        }
      });
    },
    [],
  );

  const updateReleaseDeliverySlips = useCallback(
    async (deliverySlipUrls: string[]) => {
      try {
        const { errors } = await updateRelease({
          variables: {
            input: {
              releaseId: release?.id ?? "",
              version: release?.version ?? 0,
              deliverySlipUrls,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: RELEASE,
              variables: { id: release?.id },
            },
          ],
        });
        setError(errors);
        return !errors;
      } catch (error) {
        setError(error);
        return false;
      }
    },
    [release?.id, release?.version, setError, updateRelease],
  );

  return (
    <ProviderContext.Provider
      value={{
        syncCreateReleaseFromDeliverySlip,
        syncUpdateReleaseFromDeliverySlip,
        updateReleaseDeliverySlips,
        loading: saving || creatingRelease,
        updating,
        spreadsheetViewColumns: useDeliverySlipReleaseSpreadsheetConfig(),
        setDeliveryPhotos,
        receivedQuantities,
        updateReceivedItemQuantity,
        updateReleaseForm,
        setRequestedTime,
        requestedTime,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDeliverySlipRelease = (): ProviderContextType =>
  useContext(ProviderContext);
