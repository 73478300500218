import { Breadcrumbs } from "@/common/components/breadcrumbs/Breadcrumbs";
import { Loader } from "@/common/components/loader/Loader";
import { routes } from "@/config/routes";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { Outlet } from "react-router-dom";
import tw from "tailwind-styled-components";
import { ProjectNavigation } from "./components/ProjectNavigation";
import { ProjectProvider, useProject } from "./providers/ProjectProvider";

const ProjectContainer = tw.div``;

const ProjectWithProvider = () => {
  const { project, loading } = useProject();
  const intl = useIntl();

  if (!project || loading) {
    return <Loader loading />;
  }

  return (
    <ProjectContainer>
      <Helmet>
        <title>
          {intl.$t({ id: "PROJECT_WITH_NAME" }, { projectName: project?.name })}
        </title>
      </Helmet>
      <Breadcrumbs
        classes={{
          text: "flex items-center",
          root: "border-0 pb-0",
        }}
        floating
        items={[
          {
            text: intl.$t({ id: "PROJECTS" }),
            link: routes.projects,
            id: "projects",
          },
          {
            id: "projectName",
            text: project.name,
          },
        ]}
      />
      <ProjectNavigation />
      <Outlet />
    </ProjectContainer>
  );
};

export const Project = () => {
  return (
    <ProjectProvider>
      <ProjectWithProvider />
    </ProjectProvider>
  );
};
