import { If } from "@/common/components/if/If";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { RfqItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import tw from "tailwind-styled-components";
import { useRfqGroupedItems } from "../../hooks/useRfqGroupedItems";

const Container = tw.div`
  flex mr-1 w-5
`;

export const RfqItemCheckbox: FC<{
  item: RfqItemFieldsFragment;
}> = ({ item }) => {
  const { selectedRfqItemIds, setSelectedRfqItemIds } = useRfqGroupedItems();

  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedRfqItemIds(
        newChecked
          ? [...selectedRfqItemIds, item.id]
          : selectedRfqItemIds.filter((r) => r !== item.id),
      );
    },
    [item.id, selectedRfqItemIds, setSelectedRfqItemIds],
  );

  return (
    <Container>
      <If isTrue={item.id}>
        <SelectionCheckbox
          itemId={item.id}
          items={selectedRfqItemIds}
          setSelection={handleChange}
        />
      </If>
    </Container>
  );
};
