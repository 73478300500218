import { ReceiptToExportFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { ReceiptExportInformation } from "./ReceiptExportInformation";

const Container = tw.div`flex flex-col gap-3`;

type Props = {
  receipts: ReceiptToExportFieldsFragment[];
};

export const ReceiptsExport: FC<Props> = ({ receipts }) => {
  return (
    <Container>
      {receipts.map((receipt, key) => (
        <ReceiptExportInformation receipt={receipt} key={key} />
      ))}
    </Container>
  );
};
