import { CircleCounter } from "@/common/components/circle-counter/CircleCounter";
import { isOrgCatalogSku } from "@/common/components/material/utils";
import { ProductName } from "@/common/components/product/ProductName";
import { RfqProductBrand } from "@/common/components/rfq-product-view/RfqProductBrand";
import { RfqProductLabel } from "@/common/components/rfq-product-view/RfqProductLabel";
import { UpdateRfqItemInput } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { BaseRfqItemType } from "../../../contractor/pages/home/rfq/BaseRfqItemType";
import { CountType } from "../circle-counter/CountType";

type RfqProductViewProps = {
  item: BaseRfqItemType;
  count: CountType;
  index?: number;
  updateItem?: (updates: UpdateRfqItemInput[]) => void;
  label?: string;
  labelClassName?: string;
  className?: string;
  labelTooltip?: React.ReactNode;
  nowrap?: boolean;
  customDescription?: string | null;
};

const OrderProductViewContainer = tw.div`
  flex items-center justify-between w-full gap-0 lg:gap-2
`;

const NameAndBrandContainer = tw.div`
  ${({ $includeMargin }: { $includeMargin?: boolean }) =>
    $includeMargin ? "w-[calc(100%-6rem)]" : "w-full"}
    self-center text-sm flex-1 flex items-center w-3/4
`;

const BrandContainer = tw.div`
  flex flex-col items-left text-left relative px-2 w-11/12
`;

const BrandAndLabelContainer = tw.div`flex flex-row items-center `;

const RfqProductNameStyled = tw(ProductName)`
  ${({ $nowrap }: { $nowrap: boolean }) =>
    $nowrap ? "whitespace-normal lg:whitespace-nowrap" : ""}
  line-clamp-5
`;

const CircleCounterStyled = tw(CircleCounter)`
  print:hidden mx-0 float-left justify-center
`;

const RfqProductNameContainer = tw.div`flex flex-row w-full items-center`;

export const RfqProductView: FC<RfqProductViewProps> = ({
  item,
  count,
  label,
  labelClassName,
  labelTooltip,
  className,
  nowrap = false,
  customDescription,
}) => {
  return (
    <OrderProductViewContainer className={className}>
      <NameAndBrandContainer>
        <CircleCounterStyled count={count} />
        <BrandContainer>
          <BrandAndLabelContainer>
            <RfqProductBrand item={item} includeInfo />
            <RfqProductLabel
              label={label}
              className={labelClassName}
              tooltip={labelTooltip}
              containerClassName={
                isOrgCatalogSku(item.projectItem?.material.material)
                  ? "ml-0"
                  : ""
              }
            />
          </BrandAndLabelContainer>
          <RfqProductNameContainer>
            <RfqProductNameStyled
              name={
                customDescription ||
                item.description ||
                item.projectItem?.material.material?.name ||
                ""
              }
              $nowrap={nowrap}
              readonlyClassName={`w-full text-sm ${
                isOrgCatalogSku(item.projectItem?.material.material)
                  ? "py-0"
                  : ""
              }`}
            />
          </RfqProductNameContainer>
        </BrandContainer>
      </NameAndBrandContainer>
    </OrderProductViewContainer>
  );
};
