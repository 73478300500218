import { BulkCostCodeSelector } from "@/common/components/cost-code-selector/BulkCostCodeSelector";
import { FC, useCallback } from "react";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";
import { useProject } from "../../providers/ProjectProvider";

type Props = {
  setLoading: (loading: boolean) => void;
};

export const ProjectBulkCostCodeSelector: FC<Props> = ({ setLoading }) => {
  const {
    selectedEstimatedItemIds,
    setSelectedEstimatedItemIds,
    updateEstimatedItems,
  } = useEstimatedItems();
  const { project } = useProject();

  const updateCostCode = useCallback(
    async (code: string | null) => {
      if (!project) {
        return;
      }
      await updateEstimatedItems({
        projectId: project.id,
        updates: selectedEstimatedItemIds.map((id) => ({
          id,
          costCodeId: code,
        })),
      });
      setSelectedEstimatedItemIds([]);
    },
    [
      project,
      selectedEstimatedItemIds,
      setSelectedEstimatedItemIds,
      updateEstimatedItems,
    ],
  );

  return (
    <BulkCostCodeSelector
      count={selectedEstimatedItemIds.length}
      setLoading={setLoading}
      update={updateCostCode}
      projectId={project?.id}
    />
  );
};
