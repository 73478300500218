import { FC, ReactNode, useEffect } from "react";
import { useStepper } from "../stepper/Stepper";
import { OverlayPanel, OverlayPanelProps } from "./OverlayPanel";

type Props = {
  steps: OverlayPanelProps[];
  footer?: ReactNode;
};

export const OverlayPanelWithStepper: FC<Props> = ({ steps }) => {
  const { step, resetStep } = useStepper();

  useEffect(() => {
    resetStep();
  }, [resetStep]);

  return <OverlayPanel {...steps[step]} />;
};
