import {
  BuyoutItemFieldsFragment,
  BuyoutStandaloneReleaseItemFieldsFragment,
  CostCodeFieldsFragment,
  DistributorBuyoutItemFieldsFragment,
} from "@/generated/graphql";

export const getCostCodesByBuyoutItems = (
  items: (
    | DistributorBuyoutItemFieldsFragment
    | BuyoutItemFieldsFragment
    | BuyoutStandaloneReleaseItemFieldsFragment
  )[],
  unassignedCostCode: Omit<CostCodeFieldsFragment, "mappings">,
  isAddMode?: boolean,
) => {
  const costCodes: Omit<CostCodeFieldsFragment, "mappings">[] = [];
  items.forEach((item) => {
    if (
      item &&
      item.costCode &&
      !costCodes.map((c) => c.id).includes(item.costCode.id)
    ) {
      costCodes.push(item.costCode);
    }
  });
  costCodes.sort((a, b) => a.code.localeCompare(b.code));
  if (items.some((i) => i?.costCode === null) || isAddMode) {
    costCodes.push(unassignedCostCode);
  }
  return costCodes;
};
