import { GridTable } from "@/common/components/grid-table/GridTable";
import { If } from "@/common/components/if/If";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { scrollIntoViewByDataAttr } from "@/common/utils/scrollUtils";
import { checkRfqStatus } from "@/common/utils/status-checks/checkProjectStatus";
import {
  checkQuoteStatus,
  genericError,
} from "@/common/utils/status-checks/checkQuoteStatus";
import { DistributorQuotesAuxiliaryQuoteItemFieldsFragment } from "@/generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import {
  DISTRIBUTOR_QUOTE_READ_ONLY_STATUSES,
  DISTRIBUTOR_RFQ_READ_ONLY_STATUSES,
} from "../../../common/constants";
import {
  DistributorQuoteItemGroupExtendedFieldsFragment,
  useDistributorQuoteItemsProvider,
} from "../providers/DistributorQuoteItemsProvider";
import { useDistributorQuote } from "../providers/DistributorQuoteProvider";
import { isItemIncluded } from "../utils/isItemIncluded";
import { DistributorQuoteAuxiliaryItemsAddItems } from "./auxiliary-items/DistributorQuoteAuxiliaryItemsAddItems";
import { useDistributorQuoteAlternativesConfiguration } from "./list/DistributorQuoteAlternatives.configuration";
import { DistributorQuoteAuxiliaryItemsConfiguration } from "./list/DistributorQuoteAuxiliaryItems.configuration";

export const DistributorQuoteList = () => {
  const { quote, validationError, inEditQuoteItemGroups } =
    useDistributorQuote();
  const { costCodes, toggleCostCode, auxiliaryItems } =
    useDistributorQuoteItemsProvider();
  const configuration = useDistributorQuoteAlternativesConfiguration(quote);

  const readonly = useMemo(
    () =>
      !!quote?.nextRevision ||
      checkRfqStatus(quote?.rfq, DISTRIBUTOR_RFQ_READ_ONLY_STATUSES) ||
      checkQuoteStatus(quote, DISTRIBUTOR_QUOTE_READ_ONLY_STATUSES),
    [quote],
  );

  useEffect(() => {
    if (validationError) {
      setTimeout(() => scrollIntoViewByDataAttr(genericError), 100);
    }
  }, [validationError]);

  const getExpandedItems = useCallback(
    (item: DistributorQuoteItemGroupExtendedFieldsFragment) =>
      item.quoteItems.length > 1 || inEditQuoteItemGroups.includes(item.id)
        ? item.quoteItems.map((qi) => ({
            ...item,
            quoteItems: [qi],
            quoteItemCount: item.quoteItems.length,
            quoteItemIds: item.quoteItems.map((qi) => qi.id),
          }))
        : [],
    [inEditQuoteItemGroups],
  );

  const itemFn = useCallback(
    (item: DistributorQuoteItemGroupExtendedFieldsFragment) => {
      if (!isItemIncluded(item)) {
        return { className: "bg-gray-100" };
      }
      if (
        item.quoteItems.length > 1 ||
        inEditQuoteItemGroups.includes(item.id)
      ) {
        return { className: "bg-green-300" };
      }
      return { className: "" };
    },
    [inEditQuoteItemGroups],
  );

  if (!quote) {
    return null;
  }

  return (
    <>
      <ListRenderer>
        <GridTable
          configuration={{
            columns: configuration,
            classNames: {
              header: "top-[32px]",
              category: "lg:top-[82px] top-9",
              itemContent: "px-4 lg:px-3 lg:py-2",
              detailsContent: "bg-green-100 lg:py-2",
              itemFn,
            },
            toggle: {
              category: toggleCostCode,
            },
          }}
          items={costCodes}
          readonly={readonly}
          error={validationError}
          expandedItems={getExpandedItems}
        />
        <If
          isTrue={auxiliaryItems.length && auxiliaryItems[0].items.length > 0}
        >
          <GridTable<DistributorQuotesAuxiliaryQuoteItemFieldsFragment>
            configuration={{
              columns: DistributorQuoteAuxiliaryItemsConfiguration,
              classNames: {
                root: "mt-0",
                category: "lg:top-[48px] top-9",
                itemContent: "px-4 lg:px-3",
              },
              toggle: {
                category: toggleCostCode,
              },
            }}
            hideHeader
            items={auxiliaryItems}
            readonly={readonly}
            error={validationError}
          />
        </If>
      </ListRenderer>
      <DistributorQuoteAuxiliaryItemsAddItems readonly={readonly} />
    </>
  );
};
