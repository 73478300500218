import {
  DistributorProjectItemFieldsFragment,
  ProjectMaterialOrgCatalogSkuFieldsFragment,
  ProjectMaterialProductFieldsFragment,
  ProjectMaterialSkuFieldsFragment,
  UomFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { UomPicker } from "../uom-picker/UomPicker";

type Props = {
  item?: DistributorProjectItemFieldsFragment;
  index?: number;
  readonly?: boolean;
  staticText?: boolean;
  mnemonic?: boolean;
  saveUom: (uom: string | null) => void;
  error?: boolean;
  updating?: boolean;
  testId?: string;
  placeholder?: string;
  creatable?: boolean;
  getLabel?: (uom: UomFieldsFragment | string) => string;
};

export const ProjectItemUomPicker: FC<Props> = ({ item, ...props }) => {
  const uom = useMemo(
    () =>
      item?.estimateUom ||
      (item?.material?.material as ProjectMaterialOrgCatalogSkuFieldsFragment)
        ?.defaultUom ||
      (item?.material?.material as ProjectMaterialProductFieldsFragment)?.uom ||
      (item?.material?.material as ProjectMaterialSkuFieldsFragment)?.product
        .uom,
    [item],
  );

  return <UomPicker centered uom={uom} {...props} />;
};
