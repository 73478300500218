import { CountType } from "@/common/components/circle-counter/CountType";
import { MaterialView } from "@/common/components/material/MaterialView";
import { ProjectMaterialFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`w-full`;

type Props = {
  material: ProjectMaterialFieldsFragment | undefined;
  count: CountType;
};

export const ReportMaterialItem: FC<Props> = ({ material, count }) => {
  if (!material?.material) {
    return null;
  }

  return (
    <Container>
      <MaterialView
        material={material.material}
        description={material.material.name}
        count={count}
      />
    </Container>
  );
};
