import { DiscardButton } from "@/common/components/button/DiscardButton";
import { If } from "@/common/components/if/If";
import { ExternalProjectFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const GrayContainer = tw.div`bg-gray-100 p-8 w-96 h-full`;
const Title = tw.div`text-sm font-medium pt-3`;
const Projects = tw.div`flex flex-col gap-2 mt-5`;
const Item = tw.div`flex justify-between text-sm bg-white items-center p-3 h-9`;
const Name = tw.div`text-xs`;
const EmptyList = tw.div`text-gray-500`;
const StyledDiscardButton = tw(DiscardButton)`m-0`;

type Props = {
  externalProjects: ExternalProjectFieldsFragment[];
  onRemove: (id: string) => void;
};

export const SelectedExternalProjects: FC<Props> = ({
  externalProjects,
  onRemove,
}) => (
  <GrayContainer>
    <Title>
      <FormattedMessage id="SELECTED" />
    </Title>
    <Projects>
      {externalProjects.map((externalProject) => (
        <Item key={externalProject.externalId}>
          <Name>
            {externalProject.name}{" "}
            <If isTrue={externalProject.number}>({externalProject.number})</If>
          </Name>
          <StyledDiscardButton
            onClick={() => onRemove(externalProject.externalId)}
          />
        </Item>
      ))}
      <If isTrue={!externalProjects.length}>
        <FormattedMessage id="NO_PROJECTS_SELECTED" tagName={EmptyList} />
      </If>
    </Projects>
  </GrayContainer>
);
