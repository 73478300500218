import { CategoryState } from "@/common/hooks/useToggleCategory";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { Identity } from "@/types/Identity";

export const removeItemFromUnspecifiedCostCode = <T extends Identity>(
  items: CategoryState<T>[],
  item: T,
) => {
  return items.find((c) => c.id === UNSPECIFIED_COST_CODE_ID)?.items.length ===
    1
    ? items.filter((c) => c.id !== UNSPECIFIED_COST_CODE_ID)
    : items.map((c) =>
        c.id === UNSPECIFIED_COST_CODE_ID
          ? {
              ...c,
              items: c.items.filter((i) => i.id !== item.id),
            }
          : c,
      );
};
