import { If } from "@/common/components/if/If";
import { NewDeliveryButton } from "@/contractor/pages/home/releases/pages/deliveries/components/new-delivery/NewDeliveryButton";
import { useDeliveries } from "@/contractor/pages/home/releases/pages/deliveries/providers/DeliveriesProvider";
import { useReleaseSequence } from "@/contractor/pages/home/releases/pages/deliveries/providers/ReleaseSequenceProvider";
import { useMemo } from "react";
import tw from "tailwind-styled-components";
import { ExportMultipleReleasePOButton } from "./ExportMultipleReleasePOButton";

const Container = tw.div` 
  grid grid-flow-col items-center justify-center gap-2 
  text-base font-medium rounded-[32px] py-1 pl-6 pr-1
  ${({ $exportEnabled }: { $exportEnabled: boolean }) =>
    $exportEnabled ? "bg-blue-450" : ""}
`;

export const ReleaseListActions = () => {
  const { exportEnabled } = useDeliveries();
  const { sequenceActive } = useReleaseSequence();

  const inSequenceFlow = useMemo(
    () => exportEnabled || sequenceActive,
    [exportEnabled, sequenceActive],
  );

  return (
    <Container $exportEnabled={inSequenceFlow}>
      <ExportMultipleReleasePOButton />
      <If isTrue={!inSequenceFlow}>
        <NewDeliveryButton />
      </If>
    </Container>
  );
};
