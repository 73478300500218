import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { TuneRounded } from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { PrimaryButton } from "../../../../../../common/components/button/PrimaryButton";
import { RfqQuoteOptions } from "./RfqQuoteOptions";

const Button = tw(PrimaryButton)`mb-2`;

export const RfqQuoteOptionsButton: FC = () => {
  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <Button narrow onClick={() => togglePanel(true)}>
          <TuneRounded />
        </Button>
      )}
      content={(togglePanel) => (
        <RfqQuoteOptions onClose={() => togglePanel(false)} />
      )}
    />
  );
};
