import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { DateView } from "@/common/utils/dates/DateView";
import { RfqStatus } from "@/generated/graphql";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { getUserName } from "../../../../../common/utils/users/getUserName";
import { ButtonContainer } from "../../buyout/Buyout.styles";
import RfqVendorsInfo from "../../rfq-master-catalog-import/components/RfqVendorsInfo";
import { ButtonsContainerGroup } from "../Rfq.styles";
import { useRfq } from "../hooks/useRfq";
import { CancelRfqButton } from "./buttons/CancelRfqButton";
import { RequestChangesButton } from "./buttons/RequestChangesButton";

const OrderNote = tw.div`
 flex flex-col mr-5 text-gray-500 text-sm items-center 
`;

const OrderDetails = tw.div`
  text-xs text-blue-800
`;

const NoteItem = tw.div`
  self-start uppercase font-bold
`;

const DateContainer = tw.span`
  ml-1
`;

const StyledButtonContainer = tw(ButtonContainer)`gap-5`;

const CustomInnerFloatingFooter = tw.div` flex content-end justify-end gap-7`;

const RfqFooter = () => {
  const { rfq, cancelRfq, createRfqDraft, loading } = useRfq();
  const intl = useIntl();

  if (loading || !rfq) {
    return null;
  }

  return (
    <FloatingFooter>
      <CustomInnerFloatingFooter>
        <StyledButtonContainer>
          <OrderNote>
            <NoteItem>
              <FormattedMessage id="NOTE" />
            </NoteItem>
            <OrderDetails>
              <FormattedMessage
                id="USER_CREATED_RFQ_ON"
                values={{
                  username: getUserName(rfq.createdBy),
                }}
              />
              <DateContainer>
                <DateView date={rfq.createdAt} includeTime />
              </DateContainer>
            </OrderDetails>
          </OrderNote>

          <If isTrue={rfq.status === RfqStatus.Active}>
            <RfqVendorsInfo
              drawerTitle={intl.$t({
                id: "SEND_RFQ_TO_ANOTHER_VENDOR_S",
              })}
              drawerFilterExistingVendors
              drawerSaveLabel={intl.$t({
                id: "SEND_TO_VENDOR_S",
              })}
            />
          </If>

          <ButtonsContainerGroup>
            <If
              isTrue={
                (rfq.status === RfqStatus.Active ||
                  rfq.status === RfqStatus.Awarded) &&
                !rfq.deletedAt
              }
            >
              <CancelRfqButton cancelRfq={cancelRfq} rfq={rfq} />
            </If>
            <If isTrue={rfq.status === RfqStatus.Active && !rfq.deletedAt}>
              <RequestChangesButton rfq={rfq} createRfqDraft={createRfqDraft} />
            </If>
          </ButtonsContainerGroup>
        </StyledButtonContainer>
      </CustomInnerFloatingFooter>
    </FloatingFooter>
  );
};

export default RfqFooter;
