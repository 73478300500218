import { routes } from "@/config/routes";
import { WithAdminNavigation } from "../Admin";
import { WithAdminCodeTabNavigation } from "./common/WithAdminCodeTabNavigation";
import { CostCodes } from "./pages/cost-codes/CostCodes";
import { CostTypes } from "./pages/cost-types/CostTypes";

export const costStructureRoutes = [
  {
    path: routes.costStructure,
    element: (
      <WithAdminNavigation>
        <WithAdminCodeTabNavigation />
      </WithAdminNavigation>
    ),
    children: [
      {
        path: "",
        element: <CostCodes />,
      },
      {
        path: routes.costTypes,
        element: <CostTypes />,
      },
    ],
  },
];
