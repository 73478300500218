import { useLocalPagination } from "../pagination-local/LocalPaginationProvider";
import { usePagination } from "../pagination/PaginationProvider";
import { CountType } from "./CountType";

export const useCounter = (countMap?: Map<CountType, number>) => {
  // TO DO
  // drop pagination / local pagination logic after removing the providers
  const { page, pageSize } = usePagination();
  const { page: localPage, pageSize: localPageSize } = useLocalPagination();
  const setCountMap = (id: CountType) => {
    if (!countMap?.has(id)) {
      const counter =
        countMap && countMap?.size > 0
          ? Math.max(...Array.from(countMap?.values() || []))
          : 0;
      countMap?.set(id, counter + 1);
      const sortedMap = new Map(
        [...(countMap?.entries() || [])].sort((a, b) =>
          a[0].toString().localeCompare(b[0].toString()),
        ),
      );

      countMap?.clear();
      let index = pageSize ? page * pageSize : localPage * localPageSize;
      sortedMap.forEach((_, key) => {
        countMap?.set(key, index + 1);
        index++;
      });
      return counter + 1;
    }

    return countMap?.get(id) as number;
  };
  const getCount = (id: CountType) => {
    const mappedCount = countMap?.get(id);
    if (mappedCount) {
      return mappedCount;
    } else {
      return setCountMap(id);
    }
  };

  const setCount = (id: CountType) => {
    if (!countMap?.has(id)) {
      return setCountMap(id);
    }
  };

  return {
    getCount,
    setCount,
  };
};
