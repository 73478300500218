import { AssetsCard } from "@/common/components/assets-card/AssetsCard";
import {
  AssetContext,
  AssetType,
  ReleaseFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";

type Props = {
  release: Pick<ReleaseFieldsFragment, "deliveryPhotos"> & {
    deliverySlips: Array<
      Pick<
        ReleaseFieldsFragment["deliverySlips"][number],
        "id" | "asset" | "archivedAt"
      >
    >;
  };
};

export const ReleaseDeliverySlipAndPhotos: FC<Props> = ({ release }) => {
  const intl = useIntl();

  return (
    <AssetsCard
      title={intl.$t({ id: "DELIVERY_SLIP_AND_DELIVERY_PHOTOS" })}
      assets={[
        ...(release.deliverySlips
          ? release.deliverySlips
              .filter((deliverySlip) => !deliverySlip.archivedAt)
              .map((slip) => ({
                url: "",
                type: AssetType.Image,
                context: AssetContext.DeliverySlip,
                ...slip.asset,
              }))
          : []),
        ...(release.deliveryPhotos || []),
      ]}
    />
  );
};
