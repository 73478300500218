import { UsersUserFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { LocationsAndRoles } from "./LocationsAndRoles";
import { SingleOrMultipleContainer } from "./SingleOrMultipleContainer";

type Props = {
  user: UsersUserFieldsFragment;
};

export const UserRoles: FC<Props> = ({ user }) => {
  const intl = useIntl();

  const userLocationRoles = useMemo(
    () =>
      user.locationRoles.reduce<Array<{ orgLocID: string; role: string }>>(
        (roles, nextRole) => {
          if (
            !roles.some(
              (r) =>
                r.orgLocID === nextRole.orgLocID && r.role === nextRole.role,
            )
          ) {
            roles.push(nextRole);
          }
          return roles;
        },
        [],
      ),
    [user.locationRoles],
  );

  const roleNames = userLocationRoles.map((role) =>
    intl.$t({ id: `USER_ROLE_${role.role}` }),
  );

  return (
    <SingleOrMultipleContainer items={roleNames} user={user}>
      <LocationsAndRoles
        userLocationRoles={userLocationRoles}
        className="rounded bg-blue-500"
      />
    </SingleOrMultipleContainer>
  );
};
