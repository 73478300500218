import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { CodesFileUploadSelection } from "./CodesFileUploadSelection";

const LinkLikeStyled = tw(LinkLike)`
  py-2
`;

export const CodesFileUploadButton: FC = () => {
  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <LinkLikeStyled onClick={() => togglePanel(true)} forwardEvent>
          + <FormattedMessage id="UPLOAD_COST_CODE_LIST" />
        </LinkLikeStyled>
      )}
      content={(togglePanel) => (
        <CodesFileUploadSelection onClose={() => togglePanel(false)} />
      )}
    />
  );
};
