import { InputBaseComponentProps, InputLabelProps } from "@mui/material";
import {
  DateTimePicker as DateTimePickerMaterial,
  PickersActionBarProps,
} from "@mui/x-date-pickers";
import { FC, ReactNode } from "react";
import { TextField, TextFieldProps } from "../../textfield/TextField";

export type DateTimePickerProps = {
  date: Date | null;
  onChange: (date: Date | null) => void;
  onAccept?: (date: Date | null) => void;
  label?: string | ReactNode;
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  required?: boolean;
  staticText?: boolean;
  open?: boolean;
  error?: boolean;
  InputLabelProps?: InputLabelProps;
  inputProps?: InputBaseComponentProps | undefined;
  minTime?: Date;
  testId?: string;
  actionBar?: React.ElementType<PickersActionBarProps> | undefined;
  ref?: React.Ref<HTMLDivElement>;
};

const DateTimePickerTextField: FC<TextFieldProps> = (props) => {
  return (
    <TextField
      {...props}
      size="small"
      testId="date-time-picker-text-field"
      sx={{
        " .MuiInputBase-root": {
          backgroundColor: "white",
        },
        " .MuiInputBase-input": {
          paddingTop: "8.5px",
          paddingBottom: "8.5px",
          lineHeight: "1em",
        },
        " .MuiIconButton-root": {
          color: "#0070C0",
        },
        " .MuiIconButton-root:hover": {
          color: "#23B1ED",
          border: 0,
          backgroundColor: "transparent",
        },
      }}
    />
  );
};

export const DateTimePicker: FC<DateTimePickerProps> = ({
  date,
  onChange,
  onAccept,
  label,
  minDate,
  maxDate,
  className,
  required,
  staticText,
  open,
  error,
  InputLabelProps,
  inputProps,
  minTime,
  actionBar,
  ref,
}) => (
  <DateTimePickerMaterial
    className={className}
    open={open}
    ref={ref}
    value={date}
    label={label}
    minDate={minDate}
    maxDate={maxDate}
    onChange={(newDate) => !staticText && onChange(newDate as Date)}
    onAccept={(value) => !staticText && onAccept?.(value)}
    disableOpenPicker={staticText}
    minTime={minTime}
    format="MM/‎dd‎/yyyy - hh:mm a"
    slots={{
      textField: DateTimePickerTextField,
      actionBar,
    }}
    slotProps={{
      textField: {
        required,
        onBlur: () => onAccept && onAccept(date),
        error,
        InputLabelProps,
        inputProps,
      },
      popper: {
        placement: "bottom",
      },
    }}
  />
);
