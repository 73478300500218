import { WizardModal } from "../../../../../../../common/components/wizard-modal/WizardModal";
import { useAgaveHostedWizard } from "./AgaveHostedWizardProvider";
import { usePagesConfig } from "./agave-providers-pages/usePagesConfig";

export const AgaveHostedWizardModal = () => {
  const { pages } = usePagesConfig();
  const { wizardOpened, wizardMode, closeWizard } = useAgaveHostedWizard();

  return (
    <WizardModal
      opened={wizardOpened}
      pages={pages}
      mode={wizardMode}
      onClose={closeWizard}
    />
  );
};
