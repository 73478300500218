import { GridTable } from "@/common/components/grid-table/GridTable";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { routes } from "@/config/routes";
import { ProjectProjectsFieldsFragment } from "@/generated/graphql";
import { FC, ReactNode, useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";

type Props = {
  items: ProjectProjectsFieldsFragment[];
  columns: Array<GridCol<ProjectProjectsFieldsFragment>>;
  loading: boolean;
  emptyList?: string | ReactNode;
};

export const ProjectsListItems: FC<Props> = ({ columns, ...props }) => {
  const navigate = useNavigate();
  const { hasPermissions: canViewPrices } = usePermissions([
    Permission.canViewPrices,
  ]);

  const openProjectPanel = useCallback(
    (project: ProjectProjectsFieldsFragment) => {
      navigate(
        generatePath(
          canViewPrices ? routes.projectBudgetReport : routes.projectDetails,
          { id: project.id },
        ),
      );
    },
    [canViewPrices, navigate],
  );

  return (
    <GridTable
      configuration={{
        columns,
        classNames: {
          header: "top-[90px] lg:top-[72px] xl:top-[90px]",
          root: "pt-2",
          item: "cursor-pointer",
        },
        toggle: {
          item: openProjectPanel,
        },
        hasCardItems: true,
      }}
      readonly
      {...props}
    />
  );
};
