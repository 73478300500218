import { If } from "@/common/components/if/If";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { AssignableBuyoutWithBestMatch } from "../../../providers/AssignBuyoutProvider";
const BestMatchLabel = tw.div`text-xs text-green-600 font-semibold p-4 pb-0`;

export const PreRowComponent: FC<{
  item: AssignableBuyoutWithBestMatch;
  items: AssignableBuyoutWithBestMatch[];
}> = ({ item, items }) => {
  return (
    <If isTrue={!!items?.length && items[0].buyout.id === item.buyout.id}>
      <BestMatchLabel>
        <FormattedMessage id="BEST_MATCH" />
      </BestMatchLabel>
    </If>
  );
};
