import { Price } from "@/common/components/price/Price";
import { ReleasePartialFieldsFragment } from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";

const PriceStyled = tw(Price)`text-xs`;
const Container = tw.div`truncate`;

export const ReleaseAmount: FC<{ release: ReleasePartialFieldsFragment }> = ({
  release,
}) => {
  const total = useMemo(() => {
    return release.total ? new Decimal(release.total) : null;
  }, [release]);
  return (
    <Container>
      <PriceStyled price={total} zeroValuePlaceholder={<>--</>} />
    </Container>
  );
};
