import { GridTable } from "@/common/components/grid-table/GridTable";
import { If } from "@/common/components/if/If";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { NoResults } from "@/common/components/no-results/NoResults";
import { Pagination } from "@/common/components/pagination/Pagination";
import {
  PaginationProvider,
  usePagination,
} from "@/common/components/pagination/PaginationProvider";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { FC, useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceImportExternalPO } from "../../../../../providers/InvoiceImportExternalPoProvider";
import { useInvoiceExternalPOsTableConfiguration } from "./InvoiceExternalPOsTable.configuration";
import { InvoiceExternalPOsTableHeader } from "./InvoiceExternalPOsTableHeader";

const Container = tw.div``;
const PaginationContainer = tw.div`
  flex justify-end mt-5
`;
const InfoContainer = tw.div`flex self-stretch border border-gray-400 border-dashed py-4 items-center justify-center rounded-3xl mx-8 mt-8 text-gray-500`;

const InvoiceExternalPOsWithPagination: FC = () => {
  const {
    externalPOsQueryForm,
    externalPOsPaginatedOutput: {
      externalPOs,
      loadingExternalPOs,
      ...paginationProps
    },
  } = useInvoiceImportExternalPO();
  const { setPage, setPageSize } = usePagination();
  const externalPOsTableConfiguration =
    useInvoiceExternalPOsTableConfiguration();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  useEffect(() => {
    if (paginationProps) {
      setPage({ page: paginationProps.page });
      setPageSize(paginationProps.itemsPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationProps.page, paginationProps.count]);

  const projectId = externalPOsQueryForm?.watch("projectId");
  const shouldDisplaySelectProjectHint = useMemo(() => {
    return (
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.ExternalPoReadsProjectSpecific,
      ) && !projectId
    );
  }, [hasFeatureInConnectedSourceSystem, projectId]);

  return (
    <Container>
      <InvoiceExternalPOsTableHeader />
      <If isTrue={!shouldDisplaySelectProjectHint}>
        <GridTable
          items={externalPOs}
          loading={loadingExternalPOs}
          configuration={externalPOsTableConfiguration}
          emptyList={<NoResults translationKey="NO_POS_FOUND" />}
        />
        <If
          isTrue={
            !hasFeatureInConnectedSourceSystem(
              IntegrationFeature.DisableExternalPoPagination,
            )
          }
        >
          <PaginationContainer>
            <Pagination {...paginationProps} />
          </PaginationContainer>
        </If>
      </If>
      <If isTrue={shouldDisplaySelectProjectHint}>
        <InfoContainer>
          <FormattedMessage id="SELECT_THE_PROJECT_TO_VIEW_POS" />
        </InfoContainer>
      </If>
    </Container>
  );
};

export const InvoiceExternalPOs: FC = () => (
  <PaginationProvider>
    <InvoiceExternalPOsWithPagination />
  </PaginationProvider>
);
