import { UploadAsset } from "@/common/components/upload-asset/UploadAsset";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import {
  PDF_EXTENSIONS,
  PDF_MIME_TYPE,
} from "@/common/components/upload/FileUploadArea";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { AssetContext, AssetFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`w-full flex flex-col gap-4`;

type Props = {
  onChange: (asset: AssetFieldsFragment) => void;
};

const QuoteDocumentImportWithProviders: FC<Props> = ({ onChange }) => {
  const { setAsset } = useQuoteDocument();

  return (
    <Container>
      <UploadAsset
        accept={{
          [PDF_MIME_TYPE]: PDF_EXTENSIONS,
        }}
        multiple={false}
        onChange={(file) => {
          setAsset(file[0]);
          onChange(file[0]);
        }}
      />
    </Container>
  );
};

export const QuoteDocumentImport: FC<Props> = (props) => {
  return (
    <UploadAssetProvider context={AssetContext.QuoteDocument}>
      <QuoteDocumentImportWithProviders {...props} />
    </UploadAssetProvider>
  );
};
