import { InvoiceToExportFieldsFragment } from "@/generated/graphql";
import { UpdatedInvoice } from "../providers/ExportInvoiceProvider";

export const useIsValidInvoiceFoundationExport = () => {
  const isValidInvoiceFoundationExport = ({
    invoice,
    updatedInvoices,
  }: {
    invoice: InvoiceToExportFieldsFragment;
    updatedInvoices: UpdatedInvoice[];
  }) =>
    !invoice.release?.hasMissingCostCodes &&
    (!!invoice.release?.preferredVendor?.externalCode ||
      !!updatedInvoices?.find((i) => i.id === invoice.id)?.externalVendorCode);

  return { isValidInvoiceFoundationExport };
};
