import { useApolloClientStore } from "@/common/stores/useApolloClientStore";
import { SystemReportDocument, SystemReportQuery } from "@/generated/graphql";
import { ApolloQueryResult } from "@apollo/client";
import {
  addDays,
  addMonths,
  addQuarters,
  addYears,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
} from "date-fns";
import { useCallback } from "react";
import { ProcessedData, useDataProcessor } from "./useDataProcessor";

const FORMAT = "yyyy-MM-dd";

export enum Granularity {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
  Quarterly = "quarterly",
  Yearly = "yearly",
}

type DataRequest = {
  startDate: Date;
  endDate: Date;
  granularity: Granularity;
};

export type DataResponse = {
  date: string;
  data: ProcessedData[];
}[];

export const useGMVYearlyData = ({
  excludedOrgs,
}: {
  excludedOrgs: string[];
}) => {
  const { client } = useApolloClientStore();
  const { process } = useDataProcessor();

  const fetchData = useCallback(
    async ({ startDate, endDate, granularity }: DataRequest) => {
      if (!client) {
        return;
      }

      const promises = [];
      let currentStartDate = startDate;

      while (currentStartDate <= endDate) {
        let currentEndDate;

        switch (granularity) {
          case Granularity.Daily:
            currentEndDate = endOfDay(addDays(currentStartDate, 1));
            break;
          case Granularity.Weekly:
            currentEndDate = endOfDay(addDays(currentStartDate, 7));
            break;
          case Granularity.Monthly:
            currentEndDate = endOfMonth(currentStartDate);
            break;
          case Granularity.Quarterly:
            currentEndDate = endOfQuarter(currentStartDate);
            break;
          case Granularity.Yearly:
            currentEndDate = endOfYear(currentStartDate);
            break;
        }

        if (currentEndDate > endDate) {
          currentEndDate = endDate;
        }

        promises.push(
          (function (capturedStartDate) {
            return client
              .query({
                query: SystemReportDocument,
                variables: {
                  excludedOrgIDs: excludedOrgs,
                  from: format(capturedStartDate, FORMAT),
                  to: format(currentEndDate, FORMAT),
                },
              })
              .then(({ data }: ApolloQueryResult<SystemReportQuery>) => ({
                date: format(capturedStartDate, FORMAT),
                data: process(data.systemReport),
              }));
          })(currentStartDate),
        );

        switch (granularity) {
          case Granularity.Daily:
            currentStartDate = addDays(currentStartDate, 1);
            break;
          case Granularity.Weekly:
            currentStartDate = addDays(currentStartDate, 7);
            break;
          case Granularity.Monthly:
            currentStartDate = addMonths(currentStartDate, 1);
            break;
          case Granularity.Quarterly:
            currentStartDate = addQuarters(currentStartDate, 1);
            break;
          case Granularity.Yearly:
            currentStartDate = addYears(currentStartDate, 1);
            break;
        }
      }

      return (await Promise.all(promises)) as DataResponse;
    },
    [client, excludedOrgs, process],
  );

  const estimateValue = (xData: number[], yData: number[], index: number) => {
    let sumX = 0,
      sumY = 0,
      sumXY = 0,
      sumX2 = 0;

    for (let i = 0; i < xData.length; i++) {
      const x = xData[i];
      const y = Math.log(yData[i] === 0 ? 1 : yData[i]);

      sumX += x;
      sumY += y;
      sumXY += x * y;
      sumX2 += x * x;
    }

    const b =
      (xData.length * sumXY - sumX * sumY) /
      (xData.length * sumX2 - sumX * sumX);
    const a = Math.exp((sumY - b * sumX) / xData.length);

    return a * Math.exp(b * index);
  };

  return {
    fetchData,
    estimateValue,
  };
};
