import { If } from "@/common/components/if/If";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, memo, PropsWithChildren } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const MessageContainer = tw.div`absolute right-0 whitespace-nowrap text-sm font-thin`;

type Props = PropsWithChildren<{
  item: BuyoutItemFieldsFragment;
  includeMessage?: boolean;
}>;

export const BuyoutInclusionWrapper: FC<Props> = memo(
  ({ item, includeMessage, children }) => (
    <>
      <If isTrue={item.isIncluded}>{children}</If>
      <If isTrue={!item.isIncluded && includeMessage}>
        <MessageContainer>
          <FormattedMessage id="BUYOUT_VENDOR_EXCLUDED_THE_ITEM" />
        </MessageContainer>
      </If>
    </>
  ),
);
