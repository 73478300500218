import { Select } from "@/common/components/select/components/single/Select";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  grid grid-flow-col items-center gap-2 p-0.5 px-1
`;

type Props = {
  setLoading: (loading: boolean) => void;
  update: (code: string) => Promise<void>;
  count: number;
  projectId: string | null | undefined;
};

export const BulkCostCodeSelector: FC<Props> = ({
  setLoading,
  update,
  count,
  projectId,
}) => {
  const { costCodes } = useProjectCostCodes(projectId);
  const intl = useIntl();
  const [costCodeId, setCostCodeId] = useState<string | null>(null);

  const updateCostCode = useCallback(
    async (code: string | null) => {
      if (!code) {
        return;
      }
      setCostCodeId(code);
      setLoading(true);
      await update(code);
      setLoading(false);
    },
    [setLoading, update],
  );

  return (
    <Container>
      <FormattedMessage id="ASSIGN_COST_CODE_WITH_NUM" values={{ count }} />
      <Select
        testId="cost-code-bulk-selector"
        options={costCodes || []}
        className="w-72"
        value={costCodeId}
        label={intl.$t({ id: "SELECT_COST_CODE" })}
        InputLabelProps={{
          classes: {
            focused: "text-transparent",
          },
        }}
        onChange={(value) => updateCostCode(value as string)}
        getLabel={(option) => option.formatted}
        getValue={(option) => option.id}
        noOptionsText={intl.$t({ id: "NO_COST_CODES_FOUND" })}
      />
    </Container>
  );
};
