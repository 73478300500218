import { OrgRole, useViewerLocationsQuery } from "@/generated/graphql";
import { useMemo } from "react";
import { useUser } from "../providers/UserProvider";
import { useErrorEffect } from "./useErrorEffect";

export const useUserLocations = () => {
  const { data, error, loading } = useViewerLocationsQuery();
  const { viewer } = useUser();

  const locations = useMemo(
    () =>
      data?.viewer?.org.locations.filter((loc) => {
        return (
          viewer?.locationRoles.some((role) => role.orgLocID === loc.id) ||
          viewer?.orgRoles.some((role) => role === OrgRole.OrgAdmin)
        );
      }),
    [viewer, data],
  );

  useErrorEffect(error);

  return useMemo(() => {
    return { locations: locations || [], loading };
  }, [loading, locations]);
};
