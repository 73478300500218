import { ManufacturerModalPicker } from "@/common/components/manufacturer-modal-picker/ManufacturerModalPicker";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { DistributorQuotesAuxiliaryQuoteItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";

type Props = {
  category: CategoryState<DistributorQuotesAuxiliaryQuoteItemFieldsFragment>;
  readonly?: boolean;
};

export const DistributorQuoteAuxiliaryCostCodeManufacturerSelector: FC<
  Props
> = ({ category, readonly }) => {
  const { updateQuote } = useDistributorQuote();

  const saveManufacturer = useCallback(
    async (manufacturerId: string | null) => {
      const updates = category.items.map((item) => {
        return {
          auxiliaryItemId: item.id,
          manufacturerId,
        };
      });
      await updateQuote({
        updatedAuxiliaryItems: updates,
      });
    },
    [category.items, updateQuote],
  );

  return (
    <ManufacturerModalPicker
      onChange={saveManufacturer}
      readonly={readonly || category.items.length === 0}
    />
  );
};
