import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { WizardModalMode } from "@/common/components/wizard-modal/WizardModal";
import { WizardDecisionStep } from "@/common/components/wizard-modal/components/WizardDecisionStep";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useAgaveHostedWizard } from "../../AgaveHostedWizardProvider";

const Description = tw.div`flex flex-col`;
const DescriptionTitle = tw.div`font-medium`;

export const AgaveWizardPhaseCodes = () => {
  const intl = useIntl();
  const { moveToNextStep } = useNestedStepper();
  const { wizardMode, setWbsTags, handleSaveWizardData, closeWizard } =
    useAgaveHostedWizard();

  return (
    <WizardDecisionStep
      key="phaseCodesDecision"
      title={intl.$t({
        id: "DO_YOU_WANT_TO_USE_GLOBAL_PHASE_CODES",
      })}
      description={
        <Description>
          <FormattedMessage id="BY_SELECTING_NO" tagName={DescriptionTitle} />
          <li>
            <FormattedMessage id="PHASE_CODES_BY_SELECTING_NO_LINE_1" />
          </li>
          <li>
            <FormattedMessage id="PHASE_CODES_BY_SELECTING_NO_LINE_2" />
          </li>
          <li>
            <FormattedMessage id="PHASE_CODES_BY_SELECTING_NO_LINE_3" />
          </li>
        </Description>
      }
      onNoClick={async () => {
        setWbsTags(true);
        await handleSaveWizardData({
          wbsTags: true,
        });
        if (wizardMode === WizardModalMode.SINGLE_PAGE) {
          closeWizard();
        } else {
          moveToNextStep();
        }
      }}
      onYesClick={async () => {
        setWbsTags(false);
        await handleSaveWizardData({
          wbsTags: false,
        });
        if (wizardMode === WizardModalMode.SINGLE_PAGE) {
          closeWizard();
        } else {
          moveToNextStep();
        }
      }}
    />
  );
};
