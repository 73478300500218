import { BaseRfqItemType } from "@/contractor/pages/home/rfq/BaseRfqItemType";
import { Sku } from "@/generated/graphql";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { isMasterSku } from "../material/utils";
import { ProductInfo } from "../product/ProductInfo";

type Props = {
  item?: BaseRfqItemType | null;
};

const RfqProductInfoContainer = tw.div``;

export const RfqProductInfo: FC<Props> = ({ item }) => {
  const masterSku = useMemo(
    () =>
      isMasterSku(item?.projectItem?.material.material)
        ? (item?.projectItem?.material.material as Sku)
        : null,
    [item],
  );

  if (!item) {
    return null;
  }

  return (
    <RfqProductInfoContainer>
      {masterSku && masterSku?.description && (
        <ProductInfo sku={masterSku} brand={masterSku?.manufacturer.name} />
      )}
    </RfqProductInfoContainer>
  );
};
