import { Address } from "@/common/components/address/Address";
import { PROJECT_STATUSES } from "@/common/components/display-project-status/DisplayProjectStatus";
import { InfoHeader } from "@/common/components/info-header/InfoHeader";
import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { DateView } from "@/common/utils/dates/DateView";
import { BuyoutFieldsFragment, ProjectStatus } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

type BuyoutInfoProps = {
  buyout: BuyoutFieldsFragment;
};

export const BuyoutInfo: FC<BuyoutInfoProps> = ({ buyout }) => {
  return (
    <InfoHeader>
      <InfoHeaderItem
        title={
          buyout.project.status === ProjectStatus.Active ||
          buyout.project.status === ProjectStatus.Awarded ? (
            <FormattedMessage
              id={PROJECT_STATUSES[buyout.project.status].label}
            />
          ) : (
            <FormattedMessage id="RFQ_INFO_PROJECT" />
          )
        }
      >
        {buyout.project.name}
      </InfoHeaderItem>
      <InfoHeaderItem
        title={<FormattedMessage id="RFQ_INFO_DELIVERY_ADDRESS" />}
      >
        <Address address={buyout.project.address} />
      </InfoHeaderItem>
      <InfoHeaderItem
        title={<FormattedMessage id="RFQ_INFO_BUSINESS_LOCATION" />}
      >
        <Address address={buyout.sellerOrgLocation.address} />
      </InfoHeaderItem>
      <InfoHeaderItem title={<FormattedMessage id="RFQ_INFO_JOB_START_DATE" />}>
        <DateView date={buyout.project.startDate} />
      </InfoHeaderItem>
    </InfoHeader>
  );
};
