import { ProjectTagsProvider } from "@/contractor/pages/home/project/providers/ProjectTagsProvider";
import { QuoteDocumentProvider } from "../../../common/quote-document/providers/QuoteDocumentProvider";
import { CreateBuyoutFromQuote } from "./create-buyout-from-quote/CreateBuyoutFromQuote";
import { CreateBuyoutFromQuoteForm } from "./create-buyout-from-quote/components/CreateBuyoutFromQuoteForm";

export const BuyoutFromQuote = () => {
  return (
    <CreateBuyoutFromQuoteForm>
      <ProjectTagsProvider>
        <QuoteDocumentProvider>
          <CreateBuyoutFromQuote />
        </QuoteDocumentProvider>
      </ProjectTagsProvider>
    </CreateBuyoutFromQuoteForm>
  );
};
