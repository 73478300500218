import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { TextField } from "@/common/components/textfield/TextField";
import { useReleaseActions } from "@/contractor/pages/home/release/providers/ReleaseActionsProvider";
import { useReleaseSequence } from "@/contractor/pages/home/releases/pages/deliveries/providers/ReleaseSequenceProvider";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const OverlayPanelContainer = tw.div`
  grid
`;
const Label = tw.div`
  font-medium text-base mb-2
`;

type RejectReasonProps = {
  onClose: () => void;
  release: ReleaseFieldsFragment;
};

export const RejectReason: FC<RejectReasonProps> = ({ onClose, release }) => {
  const intl = useIntl();
  const { rejectRelease, rejecting } = useReleaseActions();
  const { navigateToNextSequence } = useReleaseSequence();

  const [reason, setReason] = useState(release.rejectionReason || "");

  const save = async () => {
    const result = await rejectRelease({
      releaseId: release.id,
      version: release.version,
      reason,
    });

    if (result) {
      navigateToNextSequence({ navigateToReleases: true });
      onClose();
    }
  };

  return (
    <OverlayPanel
      title={intl.$t({ id: "REJECT_ORDER_REQUEST" })}
      onSave={save}
      saving={rejecting}
      onCancel={onClose}
    >
      <OverlayPanelContainer>
        <Label>
          <FormattedMessage id="REJECT_ORDER_REQUEST_REASON" />
        </Label>
        <TextField
          className="w-full"
          multiline
          placeholder={intl.$t({
            id: "REJECT_ORDER_REQUEST_REASON_PLACEHOLDER",
          })}
          rows={5}
          value={reason}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
            setReason(ev.target.value)
          }
        />
      </OverlayPanelContainer>
    </OverlayPanel>
  );
};
