import { QuantityPicker } from "@/common/components/quantity-picker/QuantityPicker";
import { CreateMaterialConversionInput } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { useMissingMaterialConversions } from "../../../../../providers/MissingMaterialConversionsProvider";
import { PoMaterialConversionFieldsFragmentWithId } from "./MissingMaterialConversionsList";
import { getConversionId } from "./utils/getConversionId";
import { getCreateMaterialId } from "./utils/getCreateMaterialId";

type Props = {
  item: PoMaterialConversionFieldsFragmentWithId;
  setConversion: (conversion: CreateMaterialConversionInput) => void;
  readonly?: boolean;
};

export const MaterialConversionMultiplier: FC<Props> = ({
  item,
  setConversion,
  readonly,
}) => {
  const intl = useIntl();
  const { conversions } = useMissingMaterialConversions();
  const quantity = useMemo(() => {
    const q =
      conversions.find((c) => getCreateMaterialId(c) === getConversionId(item))
        ?.quantityMultiplier || item.quantityMultiplier;
    return q || null;
  }, [conversions, item]);
  return (
    <QuantityPicker
      id="material-conversion-multiplier"
      quantity={quantity}
      changeQuantity={(value) => {
        setConversion({
          from: item.fulfillmentUom.pluralDescription,
          to: item.purchasingUom,
          orgMaterialId: item.material.id,
          quantityMultiplier: !isNaN(Number(value)) ? value.toString() : "0",
        });
      }}
      testId="material-conversion-multiplier"
      label={intl.$t({ id: "MULTIPLIER" })}
      staticText={readonly}
      allowNull
      shrink
      error={!quantity}
    />
  );
};
