import { CheckButton } from "@/common/components/button/CheckButton";
import { DiscardButton } from "@/common/components/button/DiscardButton";
import { EditButton } from "@/common/components/button/EditButton";
import { If } from "@/common/components/if/If";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { PoMaterialConversionFieldsFragmentWithId } from "./MissingMaterialConversionsList";
import { getConversionId } from "./utils/getConversionId";

const Actions = tw.div`flex gap-2`;

type Props = {
  readonly?: boolean;
  setEditMode: (value: boolean) => void;
  setUpdatedConversion: (value: string) => void;
  item: PoMaterialConversionFieldsFragmentWithId;
  clearConversion: (id: string) => void;
};

export const MaterialConversionActions: FC<Props> = ({
  readonly = false,
  setEditMode,
  setUpdatedConversion,
  item,
  clearConversion,
}: Props) => {
  return (
    <Actions>
      <If isTrue={readonly}>
        <EditButton onClick={() => setEditMode(true)} />
      </If>
      <If isTrue={!readonly}>
        <CheckButton
          onClick={() => {
            setUpdatedConversion(getConversionId(item));
            setEditMode(false);
          }}
        />
        <DiscardButton
          onClick={() => {
            clearConversion(getConversionId(item));
            setEditMode(false);
          }}
        />
      </If>
    </Actions>
  );
};
