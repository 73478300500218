import { routes } from "@/config/routes";
import { DistributorBuyout } from "./pages/buyout/DistributorBuyout";
import { DistributorInvoice } from "./pages/invoices/DistributorInvoice";
import { DistributorQuote } from "./pages/quote/DistributorQuote";
import { DistributorRelease } from "./pages/release/DistributorRelease";
import { DistributorReleaseDeliveryRecord } from "./pages/release/pages/release-delivery/DistributorReleaseDeliveryRecord";
import { DistributorReleases } from "./pages/releases/DistributorReleases";

export const distributorRoutes = [
  {
    children: [
      {
        path: routes.distributorBuyout,
        element: <DistributorBuyout />,
      },
      {
        path: routes.distributorBuyoutDeliveries,
        element: <DistributorReleases />,
      },
      {
        path: routes.distributorBuyoutMessages,
        element: <DistributorBuyout />,
      },
      {
        path: routes.distributorBid,
        element: <DistributorQuote />,
      },
      {
        path: routes.distributorBidMessages,
        element: <DistributorQuote />,
      },
      {
        path: routes.distributorDelivery,
        element: <DistributorRelease />,
      },
      {
        path: routes.distributorDeliveryMessages,
        element: <DistributorRelease />,
      },
      {
        path: routes.distributorDeliveryRecord,
        element: <DistributorReleaseDeliveryRecord />,
      },
      {
        path: routes.distributorInvoice,
        element: <DistributorInvoice />,
      },
    ],
  },
];
