import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { PoNumberingMode } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Item = tw.div`flex flex-col py-2`;
const Title = tw.div`text-sm`;
const Subtitle = tw.div`text-xs max-w-56`;

export const PoNumberingSelector = () => {
  const intl = useIntl();
  const options = useMemo(
    () => [
      {
        value: PoNumberingMode.Never,
        label: intl.$t({ id: "PO_MANAGEMENT_NEVER_USE_POS" }),
      },
      {
        value: PoNumberingMode.Optional,
        label: intl.$t({ id: "PO_MANAGEMENT_OPTIONAL_USE_POS" }),
      },
      {
        value: PoNumberingMode.Always,
        label: intl.$t({ id: "PO_MANAGEMENT_ALWAYS_USE_POS" }),
      },
    ],
    [intl],
  );

  const customRender = useCallback(
    (option: { value: PoNumberingMode; label: string }) => (
      <Item>
        <Title>{option.label}</Title>
        <Subtitle>
          {intl.$t({ id: `PO_MANAGEMENT_${option.value}_DETAILS` })}
        </Subtitle>
      </Item>
    ),
    [intl],
  );

  return (
    <SelectControlled
      name="settings.releases.poNumbering"
      className="w-72"
      options={options}
      getLabel={(option) => option.label}
      getValue={(option) => option.value.toString()}
      customRender={customRender}
    />
  );
};
