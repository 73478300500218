import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { HeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { FormattedMessage } from "react-intl";
import { IntegrationForm } from "../IntegrationForm";

export const ToggleIntegrationFormButton = () => (
  <DrawerPanel
    anchor={(togglePanel) => {
      return (
        <HeaderContainer>
          <PrimaryButton onClick={() => togglePanel(true)}>
            <FormattedMessage id="INTEGRATION_ADD" />
          </PrimaryButton>
        </HeaderContainer>
      );
    }}
    content={(togglePanel) => (
      <IntegrationForm onClose={() => togglePanel(false)} />
    )}
  />
);
