import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { ProjectFieldsFragment } from "@/generated/graphql";
import { FC, PropsWithChildren } from "react";
import { NewBuyoutForm } from "./NewBuyoutForm";

type Prop = PropsWithChildren<{
  label: string;
  project?: ProjectFieldsFragment | null;
  disabled?: boolean;
}>;

export const ToggleBuyoutForm: FC<Prop> = ({
  children,
  label,
  project,
  disabled = false,
}) => {
  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <div onClick={() => !disabled && togglePanel(true)}>{children}</div>
      )}
      content={(togglePanel) => (
        <NewBuyoutForm
          onClose={() => togglePanel(false)}
          label={label}
          project={project}
        />
      )}
    />
  );
};
