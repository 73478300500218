import { IMAGE_EXTENSIONS } from "@/common/components/upload/FileUploadArea";
import { AssetType } from "@/generated/graphql";
import { getFileExtension } from "./getFileExtension";

export const getAssetType = (filename: string): AssetType => {
  const extension = getFileExtension(filename);
  if (IMAGE_EXTENSIONS.includes(extension.toLowerCase())) {
    return AssetType.Image;
  }

  return AssetType.Document;
};
