import { useBreadcrumbs } from "@/common/components/breadcrumbs/BreadcrumbsProvider";
import { Card, HiddenMobile } from "@/common/layout/ResponsiveClasses";
import { FC, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import tw from "tailwind-styled-components";
import { InfoHeaderButton } from "./InfoHeaderButton";

export enum InfoHeaderType {
  Default,
  Grayed,
}

const Container = tw(Card)<{
  $type: InfoHeaderType;
}>`
  grid-flow-row
  z-30 mt-4 mb:4 md:mb-6 border-blue-200 border
  w-full py-3 px-4 rounded-md 
  items-start
  grid-flow-row

  ${({ $type }) => {
    switch ($type) {
      case InfoHeaderType.Default:
        return "bg-blue-100";
      case InfoHeaderType.Grayed:
        return "bg-gray-100";
    }
  }}
`;
const Items = tw.div<{
  $hideInMobile: boolean;
}>`grid gap-x-4  xs:${({ $hideInMobile }) => ($hideInMobile ? "hidden" : "grid")} 
md:hidden lg:grid
items-start

grid-flow-row
xs:grid-flow-col

justify-stretch
md:justify-between`;

const Header = tw.div``;
const Footer = tw.div``;

type Props = {
  children: React.ReactNode;
  className?: string;
  hideActionInMobile?: boolean;
  hideInMobile?: boolean;
  headerType?: InfoHeaderType;
  header?: string | React.ReactNode;
  footer?: string | React.ReactNode;
};

export const InfoHeader: FC<Props> = ({
  children,
  className,
  hideActionInMobile = false,
  hideInMobile = true,
  headerType = InfoHeaderType.Default,
  header,
  footer,
}) => {
  const { ref, inView } = useInView({
    // Since we have fixed header on top which has higher z-index, we need more relaxed threshold
    threshold: 0.7,
    trackVisibility: true,
    delay: 100,
  });

  const { setActions } = useBreadcrumbs();

  useEffect(() => {
    if (inView === undefined) {
      return;
    }
    if (inView) {
      setActions([]);
    } else {
      setActions([
        hideActionInMobile ? (
          <HiddenMobile>
            <InfoHeaderButton key="info-button">{children}</InfoHeaderButton>
          </HiddenMobile>
        ) : (
          <InfoHeaderButton key="info-button">{children}</InfoHeaderButton>
        ),
      ]);
    }
  }, [inView, setActions, children, hideActionInMobile]);

  return (
    <Container ref={ref} className={className} $type={headerType}>
      {header ? <Header>{header}</Header> : null}
      <Items $hideInMobile={hideInMobile}>{children}</Items>
      {footer ? <Footer>{footer}</Footer> : null}
    </Container>
  );
};
