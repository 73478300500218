import { If } from "@/common/components/if/If";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useReleasesSelectorData } from "@/common/hooks/userReleasesSelectorQuery";
import { OrderOptionCustomRender } from "@/contractor/pages/home/common/order-selector-filters/OrderOptionCustomRender";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import {
  AuthorizationStatus,
  ReleaseSelectorFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { LET_OFFICE_ASSIGN } from "./NewDeliverySlipFormWithStepper";

const Container = tw.div`w-full flex flex-col gap-4`;
const DropdownOption = tw.div`text-base py-2`;

export const NewDeliverySlipFormFields: FC = () => {
  const { locations } = useUserLocations();
  const { formatCurrency } = useFormatNumberToCurrency();

  const intl = useIntl();
  const { releases: deliveries, loading: loadingDeliveries } =
    useReleasesSelectorData({
      statuses: [
        ReleaseStatus.Draft,
        ReleaseStatus.Scheduled,
        ReleaseStatus.Received,
        ReleaseStatus.PartiallyReceived,
        ReleaseStatus.Requested,
      ],
      closedProjects: false,
    });
  const { projects, loading } = useProjectListOptions();
  const projectOptions = useMemo(() => {
    if (projects?.length) {
      return [
        {
          id: LET_OFFICE_ASSIGN,
          name: intl.$t({ id: "LET_OFFICE_ASSIGN_JOB" }),
        },
      ].concat(
        projects.map((project) => ({
          id: project.id,
          name: getProjectSelectorLabel(project),
        })),
      );
    }
    return [];
  }, [intl, projects]);

  const releaseOptions = useMemo(() => {
    return [
      {
        id: LET_OFFICE_ASSIGN,
        name: intl.$t({ id: "LET_OFFICE_ASSIGN_RELEASE" }),
        relItem: undefined as ReleaseSelectorFieldsFragment | undefined,
      },
    ].concat(
      deliveries
        .filter((d) => !!d.poNumber)
        .map((delivery) => ({
          id: delivery.id,
          name: `#${delivery.sequenceNumber}`,
          relItem: delivery,
        })),
    );
  }, [deliveries, intl]);

  return (
    <Container
      onClick={(ev: { stopPropagation: () => void }) => {
        ev.stopPropagation();
      }}
    >
      <If isTrue={locations && locations.length !== 1}>
        <SelectControlled
          name="locationId"
          testId="form-locations-selector"
          placeholder={intl.$t({ id: "BUSINESS_LOCATION" })}
          options={locations || []}
          rules={{ required: true }}
          getOptionDisabled={(location) =>
            location.permissions?.createDeliverySlip !==
            AuthorizationStatus.Authorized
          }
          getLabel={(option) => option.name}
          getValue={(option) => option.id}
          noOptionsText={intl.$t({ id: "NO_LOCATIONS_FOUND" })}
        />
      </If>
      <SelectControlled
        name="projectId"
        placeholder={intl.$t({
          id: "SELECT_PROJECT",
        })}
        testId="projects-selector"
        options={projectOptions}
        getLabel={(option) => option.name}
        getValue={(option) => option.id}
        disabled={loading}
        noOptionsText={intl.$t({
          id: "NO_PROJECTS_IN_LOCATION",
        })}
      />
      <SelectControlled
        name="releaseId"
        options={releaseOptions}
        label={intl.$t({ id: "ORDER" })}
        customRender={(item) =>
          item.relItem ? (
            OrderOptionCustomRender(item.relItem)
          ) : (
            <DropdownOption>{item.name}</DropdownOption>
          )
        }
        getLabel={(option) =>
          option.relItem
            ? `${option.relItem.sequenceNumber ? `#${option.relItem.sequenceNumber}` : ""} ${
                option.relItem.poNumber ? `(${option.relItem.poNumber}) ` : ""
              }${
                option.relItem.time
                  ? intl.formatDate(option.relItem.time as number)
                  : ""
              } - (${formatCurrency(Number(option.relItem.total))})`
            : option.name
        }
        classes={{
          option: "text-sm",
        }}
        getValue={(option) => option.id}
        loading={loadingDeliveries}
        className="flex min-w-[260px] flex-1"
      />
      <TextFieldControlled
        name="notes"
        multiline
        label={intl.$t({ id: "NOTES" })}
        minRows={4}
        maxRows={10}
      />
    </Container>
  );
};
