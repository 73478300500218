import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import {
  FooterCell,
  FooterCellCol,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterProps,
  FooterTotal,
} from "../../../../../../../common/components/grid-table/styles/Footer.styles";
import { useReleaseItemsZoneProvider } from "../../../providers/ReleaseItemsZonesProvider";

const marginRightClass = "mr-8";

export const ReleaseReceivedFooter: FC<FooterProps> = ({ Wrapper }) => {
  const {
    receivedSoFarSubtotal,
    receivedSoFarAdditionalCharges,
    receivedSoFarSalesTax,
    noPrices,
  } = useReleaseItemsZoneProvider();

  return (
    <FooterCol>
      <Wrapper className="flex-col">
        <FooterHeader className={marginRightClass}>
          <FormattedMessage id="RECEIVED" tagName={FooterHeaderText} />
        </FooterHeader>
        <FooterCell className={marginRightClass}>
          <NotNullableRenderer value={!noPrices}>
            <Price price={receivedSoFarSubtotal} className="font-light" />
          </NotNullableRenderer>
        </FooterCell>
        <FooterCell className={marginRightClass}>
          <NotNullableRenderer value={!noPrices}>
            <Price
              price={receivedSoFarAdditionalCharges}
              className="font-light"
            />
          </NotNullableRenderer>
        </FooterCell>
        <FooterCell className={marginRightClass}>
          <NotNullableRenderer value={!noPrices}>
            <Price price={receivedSoFarSalesTax} className="font-light" />
          </NotNullableRenderer>
        </FooterCell>
        <FooterDashedLine />
        <FooterTotal>
          <FooterCellCol className={marginRightClass}>
            <NotNullableRenderer value={!noPrices}>
              <Price
                price={receivedSoFarSubtotal
                  .plus(receivedSoFarAdditionalCharges)
                  .plus(receivedSoFarSalesTax)}
              />
            </NotNullableRenderer>
          </FooterCellCol>
        </FooterTotal>
      </Wrapper>
    </FooterCol>
  );
};
