import CheckboxCheckedIcon from "@/assets/icons/checkbox-checked.svg?react";
import CheckboxIcon from "@/assets/icons/checkbox.svg?react";
import { CheckboxProps, Checkbox as MuiCheckbox } from "@mui/material";
import tw from "tailwind-styled-components";

const CheckboxIconStyled = tw(CheckboxIcon)`
  text-xl
`;

const CheckboxCheckedIconStyled = tw(CheckboxCheckedIcon)`
  text-xl
`;

const MuiCheckboxStyled = tw(MuiCheckbox)`
 -m-2
`;

export const Checkbox = (props: CheckboxProps) => (
  <MuiCheckboxStyled
    icon={<CheckboxIconStyled />}
    checkedIcon={<CheckboxCheckedIconStyled />}
    {...props}
  />
);
