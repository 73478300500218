import { useReleaseItemInvoiceItemsLazyQuery } from "@/generated/graphql";
import { useCallback } from "react";

type Props = {
  releaseId: string | undefined;
  itemId: string;
};

export const useReleaseItemInvoiceItems = ({ releaseId, itemId }: Props) => {
  const [call, { data, loading }] = useReleaseItemInvoiceItemsLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const fetchInvoiceItems = useCallback(() => {
    if (releaseId) {
      call({
        variables: {
          id: releaseId,
          itemId,
        },
      });
    }
  }, [releaseId, itemId, call]);

  return {
    fetchInvoiceItems,
    invoiceItems: data?.release?.item.invoiceItems ?? [],
    loadingInvoiceItems: loading,
  };
};
