import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { VendorDetailsList, VendorDetailsListItem } from "../Vendors.styles";

const VendorDetailsListStyled = tw(VendorDetailsList)`text-right items-end`;

type Props = {
  contacts: OrgPreferredVendorsFieldsFragment["contacts"];
};

export const VendorContactEmails: FC<Props> = ({ contacts }) => {
  return (
    <VendorDetailsListStyled>
      {contacts.map((contact, key) => (
        <VendorDetailsListItem key={key}>{contact.email}</VendorDetailsListItem>
      ))}
    </VendorDetailsListStyled>
  );
};
