import {
  SimpleStepCol,
  SimpleStepContainer,
  SimpleStepContent,
  SimpleStepRow,
  SimpleStepTitle,
  SimpleStepTitleContainer,
} from "@/contractor/pages/admin/integrations/components/wizard/Wizard.styles";
import {
  AddOrgMaterial,
  MaterialSummaryFields,
} from "@/contractor/pages/home/rfq/components/add-org-sku/AddOrgMaterial";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { useIntl } from "react-intl";

type Props = {
  selectedOrgMaterial?: MaterialSummaryFields | null;
  setSelectedOrgMaterial: (
    material: MaterialSummaryFields | null | undefined,
  ) => void;
  title?: string;
};

export const SelectOrgMaterial: FC<Props> = ({
  selectedOrgMaterial,
  setSelectedOrgMaterial,
  title,
}) => {
  const intl = useIntl();
  return (
    <SimpleStepContainer>
      <SimpleStepRow>
        <InfoOutlined className="mr-4 h-12 w-12 text-gray-400" />
        <SimpleStepTitleContainer>
          <SimpleStepTitle>
            {title || intl.$t({ id: "SELECT_ITEM" })}
          </SimpleStepTitle>
        </SimpleStepTitleContainer>
      </SimpleStepRow>
      <SimpleStepContent>
        <SimpleStepCol>
          <AddOrgMaterial
            selectedOrgMaterial={selectedOrgMaterial}
            setSelectedOrgMaterial={setSelectedOrgMaterial}
            filter={{ connected: true }}
          />
        </SimpleStepCol>
      </SimpleStepContent>
    </SimpleStepContainer>
  );
};
