import { useGlobalError } from "@/common/hooks/useGlobalError";
import { ApolloError } from "@apollo/client";
import { useEffect } from "react";

export const useErrorEffect = (error: boolean | ApolloError | undefined) => {
  const { setError } = useGlobalError();
  useEffect(() => {
    setError(error, false);
  }, [error, setError]);
};
