import { CircularProgress } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const LoaderContainer = tw.div`
  grid grid-flow-row place-items-center w-full h-full content-center
`;

const LoadingText = tw.div`
  text-center text-sm font-medium text-blue-800 mt-2 animate-[fade-in_2s]
`;

const Size = {
  Small: 16,
  Large: 40,
};

type LoaderProps = PropsWithChildren<{
  loading: boolean;
  small?: boolean;
  className?: string;
  circleClassName?: string;
  loadingTextKey?: string;
}>;

export const Loader: FC<LoaderProps> = ({
  children,
  loading,
  small,
  className,
  circleClassName,
  loadingTextKey,
}) => {
  if (loading) {
    return (
      <LoaderContainer className={className} data-testid="loader">
        <CircularProgress
          className={circleClassName}
          size={small ? Size.Small : Size.Large}
        />
        {loadingTextKey && (
          <LoadingText>
            <FormattedMessage id={loadingTextKey} />
          </LoadingText>
        )}
      </LoaderContainer>
    );
  }

  return <>{children}</>;
};
