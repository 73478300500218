import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { ProjectFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { NewDeliverySlipFormWithStepper } from "./NewDeliverySlipFormWithStepper";

type Props = {
  disabled?: boolean;
  project?: ProjectFieldsFragment | null;
  small?: boolean;
};

export const NewDeliverySlipButton: FC<Props> = ({
  disabled,
  small = false,
}) => {
  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <PrimaryButton
          $small={small}
          testId="new-release-button"
          onClick={() => togglePanel(true)}
          disabled={disabled}
        >
          <FormattedMessage id="ADD_PACKING_SLIP" />
        </PrimaryButton>
      )}
      content={(togglePanel) => (
        <NewDeliverySlipFormWithStepper onClose={() => togglePanel(false)} />
      )}
    />
  );
};
