import { useTabActive } from "@/common/hooks/useTabActive";
import { ReceiptToExportFieldsFragment } from "@/generated/graphql";
import { FC, useEffect } from "react";
import { useReceiptReleaseValidation } from "../../hooks/useReceiptValidation";
import {
  ReceiptValidationProvider,
  useReceiptValidation,
} from "../../providers/ReceiptValidationProvider";
import { ReceiptReleaseDetails } from "./ReceiptReleaseDetails";

type Props = {
  receipt: ReceiptToExportFieldsFragment;
  linking?: boolean;
  error?: string;
};

const HostedIntegrationReceiptInformationWithProvider: FC<Props> = ({
  receipt,
  linking,
  error,
}) => {
  const { refetchReceiptValidation, loading, poNumber, setPoNumber } =
    useReceiptValidation();
  const { exportValidations } = useReceiptReleaseValidation(
    receipt,
    poNumber || receipt.poNumber || receipt.release?.poNumber?.toString() || "",
  );

  const { isTabActive } = useTabActive();

  useEffect(() => {
    if (isTabActive && !loading) {
      refetchReceiptValidation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabActive]);

  return (
    <ReceiptReleaseDetails
      receipt={receipt}
      includedValidations={exportValidations}
      linking={linking}
      loading={loading}
      poNumber={poNumber}
      setPoNumber={setPoNumber}
      error={error}
    />
  );
};

export const HostedIntegrationReceiptInformation: FC<Props> = (props) => (
  <ReceiptValidationProvider receipt={props.receipt}>
    <HostedIntegrationReceiptInformationWithProvider {...props} />
  </ReceiptValidationProvider>
);
