import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Popover } from "@/common/components/popover/Popover";
import { UsersUserFieldsFragment } from "@/generated/graphql";
import { FC, PropsWithChildren } from "react";
import tw from "tailwind-styled-components";

type Props = {
  items: string[];
  user: UsersUserFieldsFragment;
};

const Container = tw.div``;
const LinkLikeStyled = tw(LinkLike)`font-normal pl-0`;
const TextContainer = tw.div`text-base`;

export const SingleOrMultipleContainer: FC<Props & PropsWithChildren> = ({
  items,
  user,
  children,
}) => {
  return (
    <Container>
      {items.length === 1 ? (
        <TextContainer>{items[0]}</TextContainer>
      ) : (
        <Popover
          id={user.id}
          $arrow
          position="top"
          element={
            <LinkLikeStyled>
              {items[0]} + {items.length - 1}
            </LinkLikeStyled>
          }
        >
          {children}
        </Popover>
      )}
    </Container>
  );
};
