import { OrgLocationRole } from "@/generated/graphql";
import { useIntl } from "react-intl";

export const useUserRoles = () => {
  const intl = useIntl();

  const userRoles = Object.entries(OrgLocationRole)
    .filter(([, role]) => role !== OrgLocationRole.LocationWarehouseManager)
    .map(([, value]) => ({
      label: intl.$t({ id: `USER_ROLE_${value}` }),
      value: value || "",
    }));

  return { userRoles };
};
