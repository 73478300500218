import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  BuyoutsDocument,
  useCancelRfqBuyoutsMutation,
} from "@/generated/graphql";

export const useCancelBuyoutByRfqId = () => {
  const [cancelRfqBuyoutsMutation] = useCancelRfqBuyoutsMutation();
  const { setError } = useGlobalError();
  const { paginationArgs } = usePagination();

  const cancelRfqBuyouts = async (rfqID: string) => {
    try {
      const { data, errors } = await cancelRfqBuyoutsMutation({
        variables: { rfqID },
        refetchQueries: [{ query: BuyoutsDocument, variables: paginationArgs }],
      });
      setError(errors);
      return !!data?.cancelRFQBuyouts;
    } catch (error) {
      setError(error);
    }
    return false;
  };

  return { cancelRfqBuyouts };
};
