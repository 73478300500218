import { useRescanDeliverySlipMutation } from "@/generated/graphql";
import { useCallback } from "react";

export const useRescanDeliverySlip = () => {
  const [rescanDeliverySlipMutation] = useRescanDeliverySlipMutation();

  const rescanDeliverySlip = useCallback(
    async (deliverySlipId: string) => {
      return await rescanDeliverySlipMutation({
        variables: { deliverySlipId },
      });
    },
    [rescanDeliverySlipMutation],
  );

  return {
    rescanDeliverySlip,
  };
};
