import {
  ReleaseFieldsFragment,
  UpdateContractorReleaseInput,
  UpdateVendorReleaseInput,
} from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  applyInputChanges,
  getReleaseSubtotal,
  getReleaseTotal,
} from "./releaseStoreUtils";

type ReleaseState = {
  hasChanges: boolean;
  setHasChanges: (value: boolean) => void;
  release: ReleaseFieldsFragment | null | undefined;
  setStoreRelease: (release: ReleaseFieldsFragment | null | undefined) => void;
  updateStoreItems: (items: ReleaseFieldsFragment["items"]) => void;
  updateStoreRelease: (
    release: UpdateContractorReleaseInput | UpdateVendorReleaseInput,
  ) => void;
  updateStoreReleaseVersion: (
    release: Pick<ReleaseFieldsFragment, "version">,
  ) => void;
};

export const useReleaseStore = create<ReleaseState>()(
  devtools((set, get) => ({
    release: null,
    setStoreRelease: (release) => {
      if (!release || get().release?.id === release.id) {
        return;
      }
      set({
        release: {
          ...release,
          subtotal: getReleaseSubtotal(release?.items).toString(),
          total: getReleaseTotal(release).toString(),
        },
      });
    },
    updateStoreItems: (items) => {
      const release = get().release;
      if (!release) {
        return;
      }
      set((state) => ({
        release: {
          ...state.release!,
          items,
          subtotal: getReleaseSubtotal(items).toString(),
          total: getReleaseTotal({
            ...state.release!,
            items,
          }).toString(),
        },
      }));
    },
    updateStoreReleaseVersion: ({ version }) => {
      const release = get().release;
      if (!release) {
        return;
      }
      set({
        release: {
          ...release,
          version,
        },
      });
    },
    updateStoreRelease: (updateInput) => {
      const release = get().release;
      if (!release) {
        return;
      }
      const updatedRelease = applyInputChanges(release, updateInput);
      set({
        release: {
          ...updatedRelease,
          subtotal: getReleaseSubtotal(updatedRelease.items).toString(),
          total: getReleaseTotal(updatedRelease).toString(),
        },
      });
    },
    hasChanges: false,
    setHasChanges: (value: boolean) => {
      set(() => ({
        hasChanges: value,
      }));
    },
  })),
);
