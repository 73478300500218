import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import {
  SelectableItem,
  useAddItemsToRelease,
} from "../../AddItemsToReleaseProvider";

const Container = tw.div`
  grid -ml-2 pr-8 w-5
`;

export const AddBuyoutAllItemsCheckbox: FC<{
  category:
    | CategoryState<BuyoutItemFieldsFragment>
    | CategoryState<CategoryState<BuyoutItemFieldsFragment>>;
}> = ({ category }) => {
  const { selectedItems, setSelectedItems } = useAddItemsToRelease();

  const isSelected = useCallback(
    (item: BuyoutItemFieldsFragment) => {
      return selectedItems.find((i) => i.itemId === item.id);
    },
    [selectedItems],
  );

  const checked = useMemo(
    () =>
      category.items.every((item) => {
        if ((item as CategoryState<BuyoutItemFieldsFragment>).items) {
          return (item as CategoryState<BuyoutItemFieldsFragment>).items.every(
            (subitem) => isSelected(subitem),
          );
        } else {
          return isSelected(item as BuyoutItemFieldsFragment);
        }
      }),
    [category.items, isSelected],
  );
  const handleChange = useCallback(
    (newChecked: boolean) => {
      if (newChecked) {
        const itemsToAdd = category.items
          .map((item) => {
            if ((item as CategoryState<BuyoutItemFieldsFragment>).items) {
              return (
                item as CategoryState<BuyoutItemFieldsFragment>
              ).items.map(
                (subitem) =>
                  ({
                    itemId: subitem.id,
                    quantityDecimal: subitem.quantityDecimal,
                  }) as SelectableItem,
              );
            } else {
              return {
                itemId: item.id,
                quantityDecimal: (item as BuyoutItemFieldsFragment)
                  .quantityDecimal,
              } as SelectableItem;
            }
          })
          .flat();
        setSelectedItems([...selectedItems, ...itemsToAdd]);
      } else {
        const idsToRemove = category.items
          .map((item) => {
            if ((item as CategoryState<BuyoutItemFieldsFragment>).items) {
              return (
                item as CategoryState<BuyoutItemFieldsFragment>
              ).items.map((subitem) => subitem.id);
            } else {
              return item.id;
            }
          })
          .flat();
        setSelectedItems(
          selectedItems.filter((r) => !idsToRemove.includes(r.itemId)),
        );
      }
    },
    [category.items, selectedItems, setSelectedItems],
  );

  return (
    <Container>
      <SelectionCheckbox
        testId="add-all-items-checkbox"
        checked={checked}
        setSelection={handleChange}
      />
    </Container>
  );
};
