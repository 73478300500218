import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import { NoFunction } from "@/types/NoFunction";

type Props = PropsWithChildren<{ defaultOpened?: boolean }>;

type DrawerProviderProps = {
  closeDrawer: () => void;
  isOpened: boolean;
  setIsOpened: (value: boolean) => void;
};

const DrawerProviderContext = createContext<DrawerProviderProps>({
  closeDrawer: NoFunction,
  isOpened: false,
  setIsOpened: NoFunction,
});

export const DrawerProvider: FC<Props> = ({
  children,
  defaultOpened = false,
}) => {
  const [isOpened, setIsOpened] = useState(defaultOpened);

  return (
    <DrawerProviderContext.Provider
      value={{ closeDrawer: () => setIsOpened(false), isOpened, setIsOpened }}
    >
      {children}
    </DrawerProviderContext.Provider>
  );
};

export const useDrawer = (): DrawerProviderProps =>
  useContext(DrawerProviderContext);
