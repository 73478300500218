import tw from "tailwind-styled-components";

export const ItemWithIconContainer = tw.div`w-full grid grid-cols-[auto_38px] items-center gap-2`;
export const Integration = tw.div`bg-white rounded-3xl border border-dashed overflow-hidden`;
export const IntegrationInner = tw.div`p-3 grid grid-cols-[250px_2fr_38px] gap-10`;
export const Logo = tw.div`flex flex-col justify-between`;
export const BaseContent = tw.div`grid grid-flow-col grid-cols-2 py-8 gap-6`;
export const Label = tw.div`text-2xs text-gray-600`;
export const Group = tw.div`flex flex-col gap-4`;
export const Item = tw.div`grid grid-cols-[40px_auto_40px] text-sm`;
export const Fulfilled = tw.div`flex items-center`;
export const Content = tw.div`grid gap-1`;
export const Header = tw.div`font-medium`;
export const Actions = tw.div`py-2`;
export const IntegrationTypeContainer = tw.div<{
  $xs?: boolean;
}>`bg-gray-100 text-gray-600 rounded-4xl px-4 text-2xs text-center
    ${(props) => (props.$xs ? "py-0.5" : "py-2")}
`;
export const BottomBar = tw.div`bg-blue-100 flex self-stretch h-[50px] items-center justify-end font-medium text-sm px-4`;
