import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`w-full`;

type Props = {
  item: ExpandedReleaseItem;
};

export const InvoiceItemReceivedQuantity: FC<Props> = ({ item }) => {
  const receivedQtyIsGreaterThanOrdered = useMemo(() => {
    return (
      (item.invoiceItems || [])?.length > 0 &&
      new Decimal(item.receivedQuantityDecimal).greaterThan(
        item.quantityDecimal,
      )
    );
  }, [item.invoiceItems, item.receivedQuantityDecimal, item.quantityDecimal]);

  const invoicedQtyIsGreaterThenReceivedQty = useMemo(() => {
    return (
      (item.invoiceItems || [])?.length > 0 &&
      new Decimal(item.invoiceItems[0].quantity).greaterThan(
        item.receivedQuantityDecimal,
      )
    );
  }, [item.invoiceItems, item.receivedQuantityDecimal]);

  return (
    <Container>
      <If
        isTrue={
          !receivedQtyIsGreaterThanOrdered &&
          !invoicedQtyIsGreaterThenReceivedQty
        }
      >
        <ValueUnit
          value={item.receivedQuantityDecimal}
          uom={item.uom}
          renderAsPrice={isLumpSumItem(item)}
        />
      </If>
      <If
        isTrue={
          receivedQtyIsGreaterThanOrdered || invoicedQtyIsGreaterThenReceivedQty
        }
      >
        <Tooltip
          id="unit-price"
          element={
            <ValueUnit
              classNames={{ container: "text-red-500" }}
              value={item.receivedQuantityDecimal}
              uom={item.uom}
            />
          }
        >
          <FormattedMessage
            id={
              receivedQtyIsGreaterThanOrdered
                ? "RECEIVED_QTY_GREATER_THAN_ORDERED"
                : "INVOICED_QTY_GREATER_THAN_RECEIVED"
            }
          />
        </Tooltip>
      </If>
    </Container>
  );
};
