import { ItemContainer } from "@/common/components/additional-charges/AdditionalCharges.styles";
import { DatePicker } from "@/common/components/picker/components/DatePicker";
import { FC, useCallback } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const ReadonlyValue = tw.div`font-normal text-sm`;

type Props = {
  expirationDate?: number | undefined | null;
  updateItem: (expirationDate: number) => void;
  readonly: boolean;
};

export const DistributorQuoteExpiration: FC<Props> = ({
  expirationDate,
  updateItem,
  readonly,
}) => {
  const intl = useIntl();

  const updateExpiration = useCallback(
    (date: Date | null) => {
      const newValue = date?.valueOf() || 0;
      updateItem(newValue);
    },
    [updateItem],
  );

  return (
    <ItemContainer className="mt-2 h-10">
      <FormattedMessage id="PRICE_EXPIRATION_DATE" />
      {!readonly ? (
        <DatePicker
          label={intl.$t({
            id: "SET_DATE",
          })}
          date={expirationDate ? new Date(expirationDate) : null}
          onChange={updateExpiration}
          staticText={readonly}
          className="w-full"
        />
      ) : expirationDate ? (
        <ReadonlyValue>
          <FormattedDate value={expirationDate} />
        </ReadonlyValue>
      ) : null}
    </ItemContainer>
  );
};
