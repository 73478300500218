import { stopPropagation } from "@/common/utils/stopPropagation";
import { Popover } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { LinkLike } from "../link-like/LinkLike";
import { ManufacturerPicker } from "../manufacturer-picker/ManufacturerPicker";

const Container = tw.div`flex items-center justify-center w-full`;
const ManufacturerPickerContainer = tw.div`p-2`;
const LinkLikeStyled = tw(LinkLike)`w-full flex items-center justify-center`;

type Props = {
  onChange: (value: string | null) => void;
  readonly?: boolean;
};

export const ManufacturerModalPicker: FC<Props> = ({
  onChange,
  readonly = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <If isTrue={!readonly}>
      <Container onClick={stopPropagation}>
        <LinkLikeStyled forwardEvent={false} onClick={handleClick}>
          <FormattedMessage id="EDIT_ALL" />
        </LinkLikeStyled>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <ManufacturerPickerContainer>
            <ManufacturerPicker item={null} saveManufacturer={onChange} />
          </ManufacturerPickerContainer>
        </Popover>
      </Container>
    </If>
  );
};
