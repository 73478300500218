import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";

import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useRenderHelpers } from "./useRenderHelpers";

export const useCostCodeRenderer = () => {
  const { isCostCodeSupportedByFormattedName, setProjectId } =
    useProjectCostCodes();
  const intl = useIntl();
  const { checkReadOnly, applyTooltip } = useRenderHelpers();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      const metadata = instance.getCellMeta(row, col).metadata;
      if (metadata?.projectId) {
        setProjectId(metadata?.projectId as string);
        const isSupported = isCostCodeSupportedByFormattedName(value);

        if (!isSupported) {
          applyTooltip(td, intl.$t({ id: "MISSING_COST_CODE_ERROR" }));
          td.className =
            "htCenter htMiddle text-red-500 bg-red-100 border-gray-200";
        }
      }
      textRenderer(instance, td, row, col, prop, value, cellProperties);
      checkReadOnly(td, row, instance, cellProperties);
    },
    [
      checkReadOnly,
      setProjectId,
      isCostCodeSupportedByFormattedName,
      applyTooltip,
      intl,
    ],
  );

  return renderer;
};
