import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { CloseRounded } from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Button = tw(
  IconButtonBorderless,
)`min-h-6 h-6 w-6 justify-self-end bg-white rounded-md`;

type Props = {
  onClose?: () => void;
};

export const InvoiceCloseButton: FC<Props> = ({ onClose }) => (
  <Button onClick={onClose}>
    <CloseRounded className="text-xs" />
  </Button>
);
