import { Breadcrumbs } from "@/common/components/breadcrumbs/Breadcrumbs";
import { routes } from "@/config/routes";
import { DistributorBuyoutReleasesFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";
import { DistributorReleasesHeaderInfo } from "./DistributorReleasesHeaderInfo";

type Props = {
  buyout: DistributorBuyoutReleasesFieldsFragment;
};

const BuyoutNoWrapper = tw.div`pr-2`;

const Headline = tw.div`
  text-lg flex items-center
`;

export const DistributorReleasesHeader: FC<Props> = ({ buyout }) => {
  return (
    <>
      <Breadcrumbs
        items={[
          {
            id: "buyoutNo",
            link:
              generatePath(routes.distributorBuyout, {
                id: buyout.id,
              }) + location.search,
            text: (
              <Headline>
                <BuyoutNoWrapper>
                  <FormattedMessage
                    id="BUYOUT"
                    values={{ number: buyout.clientIdentifier }}
                  />
                </BuyoutNoWrapper>
              </Headline>
            ),
          },
          {
            id: "ordersHistory",
            text: <FormattedMessage id="DISTRIBUTOR_BUYOUT_ORDERS_HISTORY" />,
          },
        ]}
        classes={{
          text: "flex items-center print:font-bold print:text-3xl print:flex-1 print:flex",
        }}
      />
      <DistributorReleasesHeaderInfo buyout={buyout} />
    </>
  );
};
