import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { InnerLabeledSwitch } from "@/common/components/switch/InnerLabeledSwitch";
import { Divider } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  QuoteOption,
  useRfqQuoteOptions,
} from "../../providers/RfqQuoteOptionsProvider";

type RfqQuoteOptionsProps = {
  onClose: () => void;
};

const DividerStyled = tw(Divider)`
  my-2
`;

const SectionLabel = tw.div`
 grid grid-flow-col justify-between text-base font-bold my-2
`;

const QuoteLabel = tw.span`
  text-sm
`;

const QuoteItem = tw.div`
  grid grid-flow-col justify-between py-1
`;

export const RfqQuoteOptions: FC<RfqQuoteOptionsProps> = ({ onClose }) => {
  const intl = useIntl();
  const [quotes, setQuotes] = useState<QuoteOption[]>([]);
  const { quoteOptions, setQuoteOptions, mismatchesOnly, setMismatchesOnly } =
    useRfqQuoteOptions();
  const [mismatchesOnlyState, setMismatchesOnlyState] =
    useState(mismatchesOnly);

  useEffect(() => {
    setQuotes([...quoteOptions]);
  }, [quoteOptions]);

  const onSave = () => {
    setQuoteOptions(quotes);
    setMismatchesOnly(mismatchesOnlyState);
    onClose();
  };

  const toggleMismatchOnly = () => {
    setMismatchesOnlyState(!mismatchesOnlyState);
  };

  return (
    <OverlayPanel
      title={intl.$t({ id: "VIEW_SETTINGS" })}
      onSave={onSave}
      saveLabel={intl.$t({ id: "SAVE" })}
      onCancel={onClose}
    >
      <>
        <SectionLabel>
          <FormattedMessage id="QUOTES_TO_DISPLAY" />
        </SectionLabel>
        <DividerStyled />
        {quoteOptions.map((quote, index) => (
          <QuoteItem key={quote.id}>
            <QuoteLabel>
              <strong>
                <FormattedMessage
                  id="QUOTE_NUMBER"
                  values={{ number: index + 1 }}
                />
              </strong>
              {": "}
              {quote.orgName}
            </QuoteLabel>
            <InnerLabeledSwitch
              toggle={() => {
                setQuotes(
                  quotes.map((q) => {
                    if (q.id === quote.id) {
                      return { ...q, show: !q.show };
                    }
                    return q;
                  }),
                );
              }}
              width={60}
              initialValue={quote.show}
              onLabel={intl.$t({ id: "YES" })}
              offLabel={intl.$t({ id: "NO" })}
            />
          </QuoteItem>
        ))}
        <DividerStyled />
        <SectionLabel>
          <FormattedMessage id="SHOW_QUOTES_WITH_MISMATCHES" />
          <InnerLabeledSwitch
            toggle={toggleMismatchOnly}
            width={60}
            initialValue={mismatchesOnly}
            onLabel={intl.$t({ id: "YES" })}
            offLabel={intl.$t({ id: "NO" })}
          />
        </SectionLabel>
      </>
    </OverlayPanel>
  );
};
