import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { IntegrationType } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { IntegrationFeature } from "../types/IntegrationFeature";
import { useEnabledSourceSystems } from "./useEnabledSourceSystems";
import { useSourceSystemConfig } from "./useSourceSystemConfig";

export const useSourceSystems = ({
  connected,
}: { connected?: boolean } = {}) => {
  const { enabledSourceSystems } = useEnabledSourceSystems();
  const { getSourceSystemConfig } = useSourceSystemConfig();
  const { connectedSourceSystem, connectedIntegrationType } = useOrgSettings();

  const { settings } = useOrgSettings();
  const sourceSystems = useMemo(() => {
    if (connected) {
      return (
        settings?.integrations.sourceSystems
          .filter((s) => enabledSourceSystems.includes(s.system) && s.connected)
          .map((s) => {
            return {
              sourceSystem: s,
              config: getSourceSystemConfig(s.system, true),
            };
          }) || []
      );
    }

    return enabledSourceSystems.map((s) => {
      return {
        sourceSystem: settings?.integrations.sourceSystems.find(
          (sourceSystem) => sourceSystem.system === s,
        ) || {
          system: s,
          connected: false,
          companyName: "",
          companyId: "",
        },
        config: getSourceSystemConfig(s, !!connectedSourceSystem),
      };
    });
  }, [
    connected,
    enabledSourceSystems,
    settings?.integrations.sourceSystems,
    getSourceSystemConfig,
    connectedSourceSystem,
  ]);

  const getSourceSystemsWithFeatures = useCallback(
    (features: IntegrationFeature[]) => {
      return sourceSystems.filter((s) =>
        features.some((feature) => s.config.features.includes(feature)),
      );
    },
    [sourceSystems],
  );

  const isSourceSystem = useCallback(
    ({
      allowedFeatures,
      allowedIntegrationTypes,
      anySourceSystem,
      anyIntegrationType,
    }: {
      allowedFeatures?: IntegrationFeature[];
      anySourceSystem?: boolean;
      allowedIntegrationTypes?: IntegrationType[];
      anyIntegrationType?: boolean;
    }) => {
      return (
        (connectedIntegrationType &&
          (anyIntegrationType ||
            allowedIntegrationTypes?.includes(connectedIntegrationType))) ||
        (connectedSourceSystem &&
          (anySourceSystem ||
            (allowedFeatures &&
              getSourceSystemsWithFeatures(allowedFeatures)
                .map((s) => s.sourceSystem.system)
                .includes(connectedSourceSystem))))
      );
    },
    [
      connectedIntegrationType,
      connectedSourceSystem,
      getSourceSystemsWithFeatures,
    ],
  );

  return {
    sourceSystems,
    getSourceSystemsWithFeatures,
    isSourceSystem,
  };
};
