import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-row text-sm items-center`;

type Props = {
  activePage: number;
  setActivePage: (p: number) => void;
  pageCount: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pageStart: number;
};

export const AssetPageCounter: FC<Props> = ({
  activePage,
  setActivePage,
  hasNextPage,
  hasPreviousPage,
  pageCount,
  pageStart,
}) => {
  const intl = useIntl();
  return (
    <Container>
      {intl.$t(
        { id: "PAGE_OF_NUMBER" },
        { pageNumber: pageStart || 1, numPages: pageCount },
      )}
      <Container className="ml-2">
        <ChevronLeft
          onClick={
            hasPreviousPage ? () => setActivePage(activePage - 1) : undefined
          }
          className={
            hasPreviousPage ? "cursor-pointer" : "cursor-pointer opacity-50"
          }
        />
        <ChevronRight
          onClick={
            hasNextPage ? () => setActivePage(activePage + 1) : undefined
          }
          className={
            hasNextPage ? "cursor-pointer" : "cursor-pointer opacity-50"
          }
        />
      </Container>
    </Container>
  );
};
