import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { FC, PropsWithChildren } from "react";
import { IntegrationFeatureRequirement } from "./IntegrationFeatureRequirement";

type Props = PropsWithChildren<{
  feature: IntegrationFeature;
}>;

export const SourceSystemFeatureRequirement: FC<Props> = ({
  children,
  feature,
}) => {
  const { connectedSourceSystem } = useOrgSettings();

  return (
    <IntegrationFeatureRequirement
      sourceSystem={connectedSourceSystem}
      feature={feature}
    >
      {children}
    </IntegrationFeatureRequirement>
  );
};
