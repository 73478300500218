import { Address } from "@/common/components/address/Address";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Loader } from "@/common/components/loader/Loader";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { routes } from "@/config/routes";
import { StyledTextField } from "@/contractor/pages/admin/integrations/components/wizard/Wizard.styles";
import {
  ConnectionError,
  ExportErrorList,
  ValidationType,
} from "@/contractor/pages/home/release/components/connections/components/common/ExportErrorList";
import { InvoiceToExportFieldsFragment } from "@/generated/graphql";
import { ArrowForward, ErrorOutlineOutlined } from "@mui/icons-material";
import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useInvoiceReleaseValidation } from "../../../invoice-verification/hooks/useInvoiceValidation";
const ProjectAndVendorContainer = tw.div`grid grid-cols-[1.5fr_2fr] flex-row gap-5`;
const ProjectContainer = tw.div`flex flex-col justify-start border-r border-gray-400 border-dashed pr-2`;
const Item = tw.div`flex flex-row gap-1 items-center whitespace-nowrap`;
const ProjectName = tw.div``;
const VendorContainer = tw.div`flex flex-row gap-2`;
const Details = tw.div`flex flex-col`;
const Container = tw.div`flex flex-col gap-3 text-sm`;
const CardStyled = tw.div<{
  $hasError: boolean;
}>`rounded-3xl p-4 shadow-md ${({ $hasError }) =>
  $hasError && "border border-red-500 bg-gray-100"}`;
const IconContainer = tw.div`border border-dashed border-gray-500 rounded-full`;
const Arrow = tw(ArrowForward)`transition-all duration-500 animate-slide`;
const Error = tw.div`flex items-center bg-red-200 text-red-500 rounded p-2 mb-2 gap-2`;
const ErrorText = tw.div`overflow-hidden break-words`;

type Props = PropsWithChildren<{
  invoice: InvoiceToExportFieldsFragment;
  includedValidations: ConnectionError[];
  editablePoNumber?: boolean;
  linking?: boolean;
  loading?: boolean;
  poNumber?: string | null;
  setPoNumber?: (value: string) => void;
  error?: string;
}>;

export const InvoiceReleaseDetails: FC<Props> = ({
  invoice,
  includedValidations,
  linking,
  loading,
  children,
  poNumber,
  setPoNumber,
  error,
}) => {
  const intl = useIntl();
  const { validations } = useInvoiceReleaseValidation(
    invoice,
    invoice?.poNumber || invoice?.release?.poNumber?.toString() || "",
  );

  const generateReleasePath = useCallback(() => {
    if (invoice.release) {
      return generatePath(routes.delivery, { deliveryId: invoice.release?.id });
    }

    return "";
  }, [invoice.release]);

  const generateInvoicePath = useCallback(
    () =>
      generatePath(routes.invoiceVerification, {
        invoiceId: invoice?.id,
      }),
    [invoice?.id],
  );

  const errorsAndWarnings = useMemo(
    () => includedValidations.filter((v) => v.condition),
    [includedValidations],
  );

  const errors = useMemo(
    () =>
      includedValidations.filter(
        (v) => v.condition && v.validationType !== ValidationType.Warning,
      ),
    [includedValidations],
  );

  const uniqueValidations = useMemo(
    () =>
      [...validations, ...errorsAndWarnings].filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i,
      ),
    [validations, errorsAndWarnings],
  );

  if (linking && errors.length > 0) {
    return null;
  }

  return (
    <CardStyled
      $hasError={
        errorsAndWarnings.filter(
          (e) => !e.validationType || e.validationType === ValidationType.Error,
        ).length > 0
      }
    >
      <Container>
        <ProjectAndVendorContainer>
          <ProjectContainer>
            <Item>
              <If isTrue={loading}>
                <Loader loading small className="w-5" />
              </If>
              <If isTrue={linking}>
                <IconContainer>
                  <Arrow />
                </IconContainer>
              </If>
              <FormattedMessage id="INVOICE" />:
              <LinkLike to={generateInvoicePath()} target="_blank">
                #{invoice.number}
              </LinkLike>
            </Item>
            <Item>
              <FormattedMessage id="ORDER" />:
              <LinkLike to={generateReleasePath()} target="_blank">
                #{invoice.release?.sequenceNumber}
              </LinkLike>
            </Item>
            <ProjectName>{invoice.release?.project?.name}</ProjectName>
          </ProjectContainer>
          <VendorContainer>
            <OrgLogo
              logoImageUrl={
                invoice.release?.sellerOrgLocation?.org.logoImageUrl
              }
              name={invoice.release?.sellerOrgLocation?.org.name || ""}
              width={40}
            />
            <Details>
              {invoice.release?.sellerOrgLocation?.org.name || ""}
              <Address address={invoice.release?.sellerOrgLocation?.address} />
            </Details>
          </VendorContainer>
        </ProjectAndVendorContainer>
        <If isTrue={error}>
          <Error>
            <ErrorOutlineOutlined />
            <ErrorText>{error}</ErrorText>
          </Error>
        </If>
        <PoNumberingSettingsCheck>
          <If
            isTrue={
              !errorsAndWarnings.some(
                (error) => error.id === "EXPORT_PO_NOT_LINKED",
              )
            }
          >
            <StyledTextField
              label={intl.$t({ id: "PO_NUMBER" })}
              className="mb-auto"
              value={poNumber || ""}
              placeholder={invoice.poNumber || invoice.release?.poNumber || ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setPoNumber?.(event.target.value)
              }
              disabled={!setPoNumber}
              size="small"
              shrink
            />
          </If>
        </PoNumberingSettingsCheck>
        {children}
        <ExportErrorList includedValidations={uniqueValidations} />
      </Container>
    </CardStyled>
  );
};
