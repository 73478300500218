import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { ExternalProjectFieldsFragment } from "@/generated/graphql";
import { FC, PropsWithChildren, useCallback } from "react";
import { FormattedMessage } from "react-intl";

type Props = PropsWithChildren & {
  item: ExternalProjectFieldsFragment;
  projectId?: string | null;
};

export const ExternalProjectConnectionCheck: FC<Props> = ({
  item,
  projectId,
  children,
}) => {
  const itemDisabled = useCallback(
    (item: ExternalProjectFieldsFragment) =>
      !!item.project?.id && item.project.id !== projectId,
    [projectId],
  );

  return (
    <Tooltip id={item.externalId} element={children}>
      {itemDisabled(item) ? (
        <FormattedMessage
          id="PROJECT_ALREADY_CONNECTED"
          values={{ projectName: item.project?.name }}
        />
      ) : null}
    </Tooltip>
  );
};
