import { useCurrencies } from "@/common/components/currency-picker/hooks/useCurrencies";
import { UpdateOrgInput } from "@/generated/graphql";
import { FC, PropsWithChildren, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useOrgProfile } from "../../org-profile/hooks/useOrgProfile";

type Props = PropsWithChildren;

export const OrgSettingsFormHookProvider: FC<Props> = ({ children }) => {
  const { viewerOrg } = useOrgProfile();
  const { currencies } = useCurrencies();
  const methods = useForm<UpdateOrgInput>({
    defaultValues: {
      id: viewerOrg?.viewer?.org.id,
      settings: {
        display: {
          enableManufacturers:
            viewerOrg?.viewer?.org.settings?.display?.enableManufacturers ??
            false,
        },
        releases: {
          poNumbering: viewerOrg?.viewer?.org.settings?.releases?.poNumbering,
          notifications:
            viewerOrg?.viewer?.org.settings?.releases?.notifications,
        },
      },
    },
  });

  useEffect(() => {
    if (viewerOrg) {
      methods.reset({
        id: viewerOrg.viewer?.org.id,
        settings: {
          display: {
            enableManufacturers:
              viewerOrg?.viewer?.org.settings?.display?.enableManufacturers ??
              false,
          },
          releases: {
            poNumbering: viewerOrg?.viewer?.org.settings?.releases?.poNumbering,
            notifications:
              viewerOrg?.viewer?.org.settings?.releases?.notifications,
          },
        },
      });
    }
  }, [methods, currencies, viewerOrg]);

  return <FormProvider {...methods}>{children}</FormProvider>;
};
