import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  OrgSettingsDocument,
  OrgSettingsQuery,
  UpdateOrgSettingsInput,
  useUpdateInvoiceApprovalMutation,
} from "@/generated/graphql";

export const useInvoiceApprovalActions = () => {
  const { setError } = useGlobalError();

  const [updateInvoiceApprovalMutation, { loading: updating }] =
    useUpdateInvoiceApprovalMutation();

  const updateInvoiceApproval = async (input: UpdateOrgSettingsInput) => {
    try {
      const { errors } = await updateInvoiceApprovalMutation({
        variables: { input },
        update: (cache, { data }) => {
          const orgSettings = cache.readQuery<OrgSettingsQuery>({
            query: OrgSettingsDocument,
          });
          if (orgSettings && data?.updateOrgSettings) {
            cache.writeQuery({
              query: OrgSettingsDocument,
              data: {
                viewer: {
                  ...orgSettings.viewer,
                  org: {
                    ...orgSettings.viewer?.org,
                    settings: {
                      ...orgSettings.viewer?.org.settings,
                      invoices: {
                        ...orgSettings.viewer?.org.settings?.invoices,
                        approval: {
                          ...orgSettings.viewer?.org.settings?.invoices
                            .approval,
                          ...data.updateOrgSettings.invoices.approval,
                        },
                      },
                    },
                  },
                },
              },
            });
          }
        },
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return {
    updateInvoiceApproval,
    updating,
  };
};
