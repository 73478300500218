import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";

type GroupToggleProps = {
  isOpened: boolean;
  className?: string;
  onClick?: () => void;
};

const KeyboardArrowDownStyled = tw(KeyboardArrowDown)`
  mr-1
`;

const KeyboardArrowUpStyled = tw(KeyboardArrowUp)`
  mr-1
`;

export const GroupToggle: FC<GroupToggleProps> = ({
  isOpened,
  className,
  onClick,
}) => (
  <>
    {isOpened ? (
      <KeyboardArrowUpStyled onClick={onClick} className={className} />
    ) : (
      <KeyboardArrowDownStyled onClick={onClick} className={className} />
    )}
  </>
);
