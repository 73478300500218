import { If } from "@/common/components/if/If";
import { Price } from "@/common/components/price/Price";
import { MAX_PRICE_DECIMALS, MAX_QUANTITY_DECIMALS } from "@/common/const";
import { FormattedMessage, FormattedNumber } from "react-intl";
import tw from "tailwind-styled-components";
import { WarningIconWithDetails } from "./WarningIconWithDetails";

const Container = tw.div`
  relative px-4 grid grid-cols-auto-1fr gap-2  font w-full items-center h-6
`;

const LeftSide = tw.div`
  grid grid-flow-col gap-2
`;

const RightSide = tw.div`
  grid grid-flow-col gap-2 justify-end items-center h-6
`;

const NotEnoughUnits = tw.span`
  pl-1 text-red-500 text-xs
`;

const UnitText = tw.span`
  grid grid-flow-col gap-2 text-xs text-right w-full font-light
`;

const Times = tw.span``;

const PriceText = tw(Price)`
  text-right font-normal
`;

type Props = {
  quantityDiff: number;
  unit?: string | null;
  quantityDecimal?: string | null;
  unitPrice: number;
};

export const UnitPriceRow = ({
  quantityDiff,
  unit,
  quantityDecimal,
  unitPrice,
}: Props) => {
  return (
    <Container>
      <LeftSide>
        <If isTrue={quantityDiff > 0}>
          <WarningIconWithDetails
            warning={
              <FormattedMessage
                id="MISMATCH_QUANTITY"
                values={{
                  items: quantityDiff,
                }}
              />
            }
          />
        </If>
      </LeftSide>
      <RightSide>
        <If isTrue={quantityDiff > 0}>
          <NotEnoughUnits>
            (-
            <FormattedNumber
              value={quantityDiff}
              minimumFractionDigits={0}
              maximumFractionDigits={2}
            />
            {unit})
          </NotEnoughUnits>
        </If>
        <UnitText>
          <FormattedNumber
            value={Number(quantityDecimal || 0)}
            minimumFractionDigits={0}
            maximumFractionDigits={MAX_QUANTITY_DECIMALS}
          />{" "}
          {unit}
          <Times>x</Times>
          <PriceText
            price={unitPrice}
            maximumFractionDigits={MAX_PRICE_DECIMALS}
          />
        </UnitText>
      </RightSide>
    </Container>
  );
};
