import { LinkLike } from "@/common/components/link-like/LinkLike";
import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import { EnterpriseOrgFieldsFragment } from "@/generated/graphql";
import { useAuth0 } from "@auth0/auth0-react";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  item: EnterpriseOrgFieldsFragment;
};

const LinkLikeStyled = tw(LinkLike)`
  p-0
`;

export const LoginLink: FC<Props> = ({ item }) => {
  const { loginWithRedirect } = useAuth0();
  const { setValue } = useLocalStorage();

  const loginToOrg = async () => {
    setValue(LOCAL_STORAGE_KEYS.LOGIN_ORG_ID, item.id);
    await loginWithRedirect({ authorizationParams: { org_id: item.id } });
  };

  return (
    <LinkLikeStyled onClick={loginToOrg}>
      <FormattedMessage id="ORGS_LOGIN_LINK" />
    </LinkLikeStyled>
  );
};
