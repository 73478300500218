import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useRfqQuotes } from "../../providers/RfqQuotesProvider";
import { ExportQuotes } from "./ExportQuotes";

const Button = tw(PrimaryButton)`mb-2 text-base`;

export const ExportQuotesButton: FC = () => {
  const intl = useIntl();

  const { selectedQuotes, rfq } = useRfqQuotes();

  const isDisabled = useMemo(() => {
    return !selectedQuotes.length || rfq?.quotes.length === 0;
  }, [rfq, selectedQuotes]);

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <Button onClick={() => togglePanel(true)} disabled={isDisabled}>
          {intl.$t({ id: "EXPORT_QUOTES" })}
        </Button>
      )}
      content={(togglePanel) => (
        <ExportQuotes onClose={() => togglePanel(false)} />
      )}
    />
  );
};
