import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { CompletedStep } from "@/common/components/wizard-modal/components/CompletedStep";
import { WizardModalFooterButton } from "@/common/components/wizard-modal/components/WizardModalFooterButton";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ExternalItemFieldsFragment,
  ImportOrgMaterialsMutation,
  OrgMaterialFieldsFragment,
  OrgMaterialsDocument,
  SourceSystem,
  useConnectOrgMaterialMutation,
  useDisconnectOrgMaterialMutation,
  useImportOrgMaterialsMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionPromise } from "@/types/NoFunction";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { AnimatedLoading } from "../../../../../home/invoices/pages/scanned-invoices/components/splitting-invoices/steps/AnimatedLoading";
import { ImportExternalItemsImportResults } from "../components/import-external-items/steps/ImportExternalItemsImportResults";
import { ImportExternalItemsTable } from "../components/import-external-items/steps/ImportExternalItemsTable";

export enum ExternalItemsMode {
  IMPORT = "IMPORT",
  CONNECT = "CONNECT",
}

type ProviderContextType = {
  pages: WizardModalPage[];
  sourceSystem: SourceSystem | null;
  modalOpened: boolean;
  openModal: ({
    sourceSystem,
    externalItem,
    item,
  }: {
    sourceSystem: SourceSystem;
    externalItem?: ExternalItemFieldsFragment;
    item?: OrgMaterialFieldsFragment;
  }) => void;
  closeModal: () => void;

  mode: ExternalItemsMode;
  setMode: (mode: ExternalItemsMode) => void;
  importAllExternalItems: boolean;
  setImportAllExternalItems: (value: boolean) => void;
  selectedExternalItems: string[];
  setSelectedExternalItems: (id: string[]) => void;
  connectedItem: OrgMaterialFieldsFragment | undefined;
  disconnectExternalItem: (externalItemId: string) => void;

  totalExternalItemsCount: number;
  setTotalExternalItemsCount: (count: number) => void;

  importedItems: ImportOrgMaterialsMutation["importOrgMaterials"] | null;
};

const ProviderContext = createContext<ProviderContextType>({
  pages: [],
  sourceSystem: null,
  modalOpened: false,
  openModal: NoFunction,
  closeModal: NoFunction,

  mode: ExternalItemsMode.IMPORT,
  setMode: NoFunction,
  importAllExternalItems: false,
  setImportAllExternalItems: NoFunction,
  selectedExternalItems: [],
  setSelectedExternalItems: NoFunction,
  connectedItem: undefined,
  disconnectExternalItem: NoFunctionPromise,

  totalExternalItemsCount: 0,
  setTotalExternalItemsCount: NoFunction,

  importedItems: null,
});

export const ImportExternalItemsProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { moveToNextNestedStep, moveToPreviousNestedStep, setNestedStep } =
    useNestedStepper();

  const [sourceSystem, setSourceSystem] = useState<SourceSystem | null>(null);
  const [modalOpened, setModalOpened] = useState(false);

  const [totalExternalItemsCount, setTotalExternalItemsCount] = useState(0);
  const [importAllExternalItems, setImportAllExternalItems] = useState(false);
  const [selectedExternalItems, setSelectedExternalItems] = useState<string[]>(
    [],
  );
  const [connectedExternalItemId, setConnectedExternalItemId] = useState<
    string | undefined
  >(undefined);
  const [connectedItem, setConnectedItem] = useState<
    OrgMaterialFieldsFragment | undefined
  >(undefined);
  const [mode, setMode] = useState<ExternalItemsMode>(ExternalItemsMode.IMPORT);
  const [importedItems, setImportedItems] = useState<
    ImportOrgMaterialsMutation["importOrgMaterials"] | null
  >(null);

  const openModal = useCallback(
    ({
      sourceSystem,
      externalItem,
      item,
    }: {
      sourceSystem: SourceSystem;
      externalItem?: ExternalItemFieldsFragment;
      item?: OrgMaterialFieldsFragment;
    }) => {
      setSourceSystem(sourceSystem);
      if (externalItem || item) {
        setSelectedExternalItems(externalItem ? [externalItem.id] : []);
        setConnectedExternalItemId(externalItem ? externalItem.id : undefined);
        setConnectedItem(item);
        setMode(ExternalItemsMode.CONNECT);
      } else {
        setMode(ExternalItemsMode.IMPORT);
      }
      setModalOpened(true);
    },
    [],
  );

  const resetModal = useCallback(() => {
    setSelectedExternalItems([]);
    setConnectedItem(undefined);
    setConnectedExternalItemId(undefined);
    setMode(ExternalItemsMode.IMPORT);
    setSourceSystem(null);
    setNestedStep(0);
  }, [setNestedStep]);

  const closeModal = useCallback(() => {
    setModalOpened(false);
    resetModal();
  }, [resetModal]);

  const [importOrgMaterialsMutation] = useImportOrgMaterialsMutation();
  const importSelectedOrgMaterials = useCallback(async () => {
    try {
      if (sourceSystem) {
        moveToNextNestedStep();
        const { data, errors } = await importOrgMaterialsMutation({
          variables: {
            input: {
              externalItemIds: importAllExternalItems
                ? undefined
                : selectedExternalItems,
              sourceSystem,
            },
          },
        });
        if (errors) {
          moveToPreviousNestedStep();
          setError(errors);
        }
        if (data?.importOrgMaterials) {
          setImportedItems(data.importOrgMaterials);
          moveToNextNestedStep();
        }
      }
    } catch (error) {
      moveToPreviousNestedStep();
      setError(error);
    }
  }, [
    sourceSystem,
    importAllExternalItems,
    selectedExternalItems,
    importOrgMaterialsMutation,
    moveToPreviousNestedStep,
    moveToNextNestedStep,
    setError,
  ]);

  const [disconnectOrgMaterialMutation] = useDisconnectOrgMaterialMutation();
  const disconnectExternalItem = useCallback(async () => {
    try {
      if (sourceSystem && connectedItem) {
        await disconnectOrgMaterialMutation({
          variables: {
            input: { nodeId: connectedItem.id, sourceSystem },
          },
          refetchQueries: [{ query: OrgMaterialsDocument }],
        });
        closeModal();
      }
    } catch (error) {
      setError(error);
    }
  }, [
    sourceSystem,
    connectedItem,
    disconnectOrgMaterialMutation,
    closeModal,
    setError,
  ]);

  const [connectOrgMaterialMutation] = useConnectOrgMaterialMutation();
  const connectExternalItem = useCallback(async () => {
    try {
      if (sourceSystem && selectedExternalItems.length === 1 && connectedItem) {
        await connectOrgMaterialMutation({
          variables: {
            input: {
              externalId: selectedExternalItems[0],
              sourceSystem,
              nodeId: connectedItem.id,
            },
          },
          refetchQueries: [{ query: OrgMaterialsDocument }],
        });
        closeModal();
      }
    } catch (error) {
      setError(error);
    }
  }, [
    sourceSystem,
    selectedExternalItems,
    connectedItem,
    connectOrgMaterialMutation,
    closeModal,
    setError,
  ]);

  const pages: WizardModalPage[] = useMemo(() => {
    const externalItemsTableFooterButtonsConfig: WizardModalFooterButton[] = [];
    if (mode === ExternalItemsMode.IMPORT) {
      externalItemsTableFooterButtonsConfig.push({
        type: "text",
        text: intl.$t(
          { id: "OUT_OF_SELECTED" },
          {
            count: importAllExternalItems
              ? totalExternalItemsCount
              : selectedExternalItems.length,
            total: totalExternalItemsCount,
          },
        ),
        className: "flex mr-4 items-center",
        hidden:
          totalExternalItemsCount === 0 &&
          (importAllExternalItems
            ? totalExternalItemsCount === 0
            : selectedExternalItems.length === 0),
      });
    } else if (connectedExternalItemId) {
      externalItemsTableFooterButtonsConfig.push({
        type: "link",
        onClick: disconnectExternalItem,
        text: intl.$t({ id: "DISCONNECT_ITEM" }),
        className: "mr-4 justify-center",
      });
    }
    externalItemsTableFooterButtonsConfig.push(
      ...([
        {
          type: "outlined",
          onClick: closeModal,
          text: intl.$t({ id: "CLOSE" }),
          className: "flex-1 mr-2",
        },
        {
          type: "primary",
          onClick:
            mode === ExternalItemsMode.IMPORT
              ? importSelectedOrgMaterials
              : connectExternalItem,
          text: intl.$t({
            id: mode === ExternalItemsMode.IMPORT ? "IMPORT" : "CONNECT",
          }),
          className: "flex-1",
          disabled:
            mode === ExternalItemsMode.IMPORT
              ? importAllExternalItems
                ? false
                : selectedExternalItems.length === 0
              : selectedExternalItems.length === 0 ||
                (selectedExternalItems.length === 1 &&
                  selectedExternalItems[0] === connectedExternalItemId),
        },
      ] as WizardModalFooterButton[]),
    );

    return [
      {
        title: null,
        pages: [
          {
            component: <ImportExternalItemsTable />,
            footerButtonsConfig: externalItemsTableFooterButtonsConfig,
          },
          {
            component: (
              <AnimatedLoading loading text={intl.$t({ id: "IMPORTING" })} />
            ),
            hideFooter: true,
            classNames: {
              wrapper: "mb-0",
            },
          },
          {
            component: (
              <CompletedStep text={intl.$t({ id: "IMPORT_COMPLETED" })} />
            ),
            onModalClose: moveToNextNestedStep,
            hideFooter: true,
            classNames: {
              wrapper: "mb-0",
            },
          },
          {
            component: <ImportExternalItemsImportResults />,
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "w-60",
              },
            ],
          },
        ],
      },
    ];
  }, [
    mode,
    selectedExternalItems,
    importAllExternalItems,
    totalExternalItemsCount,
    connectedExternalItemId,
    importSelectedOrgMaterials,
    closeModal,
    moveToNextNestedStep,
    disconnectExternalItem,
    connectExternalItem,
    intl,
  ]);

  return (
    <ProviderContext.Provider
      value={{
        modalOpened,
        sourceSystem,
        pages,
        openModal,
        closeModal,

        mode,
        setMode,
        importAllExternalItems,
        setImportAllExternalItems,
        selectedExternalItems,
        setSelectedExternalItems,
        connectedItem,
        disconnectExternalItem,

        totalExternalItemsCount,
        setTotalExternalItemsCount,

        importedItems,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useImportExternalItems = () => useContext(ProviderContext);
