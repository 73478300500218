import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  MdFixedContainer,
  SmFixedContainer,
  XsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { AdminUsersUserFieldsFragment, OrgRole } from "@/generated/graphql";
import { FormattedMessage } from "react-intl";
import { HasOrgRole } from "./HasOrgRole";
import { UserActions } from "./UserActions";
import { UserLocations } from "./UserLocations";
import { UserName } from "./UserName";
import { UserRoles } from "./UserRoles";
import { UserStatus } from "./UserStatus";

export const tableConfiguration: Array<GridCol<AdminUsersUserFieldsFragment>> =
  [
    {
      wrapper: MdFixedContainer,
      header: <FormattedMessage id="USERS_FULL_NAME" />,
      item: ({ item }) => <UserName user={item} />,
    },
    {
      wrapper: SmFixedContainer,
      header: <FormattedMessage id="USERS_ORG_ADMINISTRATOR" />,
      position: "center",
      item: ({ item }) => (
        <HasOrgRole user={item} orgRoles={OrgRole.OrgAdmin} />
      ),
    },
    {
      wrapper: MdFixedContainer,
      header: <FormattedMessage id="USER_FORM_ORGANIZATION_ACCOUNTANT" />,
      position: "center",
      item: ({ item }) => (
        <HasOrgRole
          user={item}
          orgRoles={[OrgRole.OrgAccountant, OrgRole.OrgAdmin]}
        />
      ),
    },
    {
      wrapper: MdFixedContainer,
      header: (
        <FormattedMessage id="USER_FORM_CAN_MANAGE_VENDORS_AND_ITEM_DATABASE" />
      ),
      position: "center",
      item: ({ item }) => (
        <HasOrgRole
          user={item}
          orgRoles={[OrgRole.OrgAdmin, OrgRole.OrgRestrictedAdmin]}
        />
      ),
    },
    {
      wrapper: SmFixedContainer,
      header: <FormattedMessage id="USER_CAN_SEE_PRICES" />,
      position: "center",
      item: ({ item }) => (
        <HasOrgRole user={item} orgRoles={OrgRole.OrgRestrictedMember} negate />
      ),
    },
    {
      wrapper: MdFixedContainer,
      header: <FormattedMessage id="USERS_ASSIGNED_LOCATIONS" />,
      item: ({ item }) => <UserLocations user={item} />,
    },
    {
      wrapper: MdFixedContainer,
      header: <FormattedMessage id="USERS_USER_ROLES" />,
      item: ({ item }) => <UserRoles user={item} />,
    },
    {
      wrapper: SmFixedContainer,
      header: <FormattedMessage id="STATUS" />,
      position: "center",
      item: ({ item }) => <UserStatus user={item} />,
    },
    {
      wrapper: XsFixedContainer,
      header: "",
      position: "center",
      item: ({ item }) => <UserActions user={item} />,
    },
  ];
