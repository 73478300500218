import { useDownloadInvoiceDocumentMutation } from "@/generated/graphql";
import { useCallback } from "react";

export const useInvoiceDownload = () => {
  const [downloadInvoiceDocument, { loading }] =
    useDownloadInvoiceDocumentMutation();

  const downloadInvoice = useCallback(
    async (invoiceId?: string, version?: number) => {
      if (!invoiceId) {
        return;
      }
      const { data } = await downloadInvoiceDocument({
        variables: {
          input: {
            invoiceId: invoiceId ?? "",
            version,
          },
        },
      });

      return data?.downloadInvoiceDocument;
    },
    [downloadInvoiceDocument],
  );

  return {
    downloadInvoice,
    loading,
  };
};
