import { EditButton } from "@/common/components/button/EditButton";
import { If } from "@/common/components/if/If";
import { CheckCircleOutlineOutlined, ErrorOutline } from "@mui/icons-material";
import { FC, ReactNode } from "react";
import { Content, Fulfilled, Item, Label } from "../Integrations.styles";
import { ItemValue } from "./ItemValue";

export type ItemGroupValue = {
  value: string | null | ReactNode;
  details?: string;
  emptyCondition?: () => boolean;
  label?: string;
  hidden?: boolean;
};

type Props = {
  containerClassName?: string;
  iconColorClassName?: string;
  values?: Array<ItemGroupValue>;
  label?: string;
  fulfilled?: boolean;
  onEdit?: () => void;
};

export const ItemGroup: FC<Props> = ({
  values,
  label,
  fulfilled,
  containerClassName,
  iconColorClassName,
  onEdit,
}) => (
  <Item className={containerClassName}>
    <Fulfilled>
      {fulfilled ? (
        <CheckCircleOutlineOutlined
          className={iconColorClassName ?? "text-green-800"}
        />
      ) : (
        <ErrorOutline className={iconColorClassName ?? "text-red-500"} />
      )}
    </Fulfilled>
    <Content>
      <Label>{label}</Label>
      {values?.map((item, index) => <ItemValue key={index} {...item} />)}
    </Content>
    <If isTrue={!!onEdit}>
      <EditButton onClick={onEdit} />
    </If>
  </Item>
);
