import { If } from "@/common/components/if/If";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseFieldsFragment, ReleaseStatus } from "@/generated/graphql";
import { format } from "date-fns";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  StatusMessageType,
  isTimeUOM,
  itemDeadline,
  statusMessage,
} from "../../utils/releaseItemRental";

type Props = {
  item?: ExpandedReleaseItem;
  release?: Pick<ReleaseFieldsFragment, "time" | "status"> | null;
};

const Container = tw.div`text-right`;
const Deadline = tw.div`text-sm`;
const Status = tw.div`text-xs text-gray-400`;
const SuccessFormattedMessage = tw(FormattedMessage)`text-green-400`;
const NeutralFormattedMessage = tw(FormattedMessage)`text-gray-400`;
const ErrorFormattedMessage = tw(FormattedMessage)`text-red-400`;

const statusText = ({
  type,
  days,
}: {
  type: StatusMessageType;
  days: number;
}): React.ReactNode => {
  const map: Record<StatusMessageType, React.ReactNode> = {
    [StatusMessageType.EXCEEDED]: (
      <ErrorFormattedMessage id="RENTAL_DAYS_EXCEEDED" values={{ days }} />
    ),
    [StatusMessageType.DAYS_UNTIL_START]: (
      <NeutralFormattedMessage id="RENTAL_DAYS_UNTIL_START" values={{ days }} />
    ),
    [StatusMessageType.DAYS_LEFT]: (
      <SuccessFormattedMessage id="RENTAL_DAYS_LEFT" values={{ days }} />
    ),
  };

  return map[type];
};

export const ReleaseItemRentalEndDate: FC<Props> = ({ item, release }) => {
  const itemUOM = item?.uom;

  if (!isTimeUOM(itemUOM) || !release?.time) {
    return null;
  }

  const deadline = itemDeadline({
    releaseTime: release.time,
    item: {
      uom: item?.uom,
      quantityDecimal: item?.quantityDecimal,
    },
  });

  if (!deadline) {
    return null;
  }

  const formattedDeadline = format(deadline, "MMM d, yyyy");

  const status = statusMessage({
    now: new Date(),
    deadline,
    releaseTime: release.time,
  });

  return (
    <Container>
      <Deadline>{formattedDeadline}</Deadline>
      <If isTrue={release.status !== ReleaseStatus.Received}>
        <Status>{status ? statusText(status) : null}</Status>
      </If>
    </Container>
  );
};
