import { MAX_ITEMS_PER_PAGE } from "@/common/const";
import {
  AddTagMutation,
  BuyoutDocument,
  BuyoutQuery,
  ProjectDocument,
  ProjectListOptionsDocument,
  ProjectListOptionsQuery,
  ProjectQuery,
  ProjectTagsDocument,
  ReleaseDocument,
  ReleaseQuery,
} from "@/generated/graphql";
import { ApolloCache } from "@apollo/client";

export const updateTagsCache = ({
  cache,
  tags,
  projectId,
  deliveryId,
  buyoutId,
}: {
  cache: ApolloCache<unknown>;
  tags: AddTagMutation["addTag"] | undefined;
  projectId: string;
  deliveryId: string | undefined;
  buyoutId: string | undefined;
}) => {
  const queryProject = cache.readQuery<ProjectQuery>({
    query: ProjectDocument,
    variables: { id: projectId, excludePhantoms: true },
  });

  if (queryProject && tags) {
    cache.writeQuery({
      query: ProjectDocument,
      variables: { id: projectId, excludePhantoms: true },
      data: {
        project: {
          ...queryProject?.project,
          tags: [...tags],
        },
      },
    });
  }

  const queryProjectTags = cache.readQuery<ProjectQuery>({
    query: ProjectTagsDocument,
    variables: { id: projectId, excludePhantoms: true },
  });

  if (queryProjectTags && tags) {
    cache.writeQuery({
      query: ProjectTagsDocument,
      variables: { id: projectId, excludePhantoms: true },
      data: {
        project: {
          ...queryProjectTags?.project,
          tags: [...tags],
        },
      },
    });
  }

  const queryProjectProjects = cache.readQuery<ProjectListOptionsQuery>({
    query: ProjectListOptionsDocument,
    variables: {
      first: MAX_ITEMS_PER_PAGE,
      deleted: false,
    },
  });
  if (queryProjectProjects && tags) {
    cache.writeQuery({
      query: ProjectListOptionsDocument,
      variables: { first: MAX_ITEMS_PER_PAGE, deleted: false },
      data: {
        projects: queryProjectProjects.projects.edges.map((p) => {
          if (p.node.id === projectId) {
            return {
              ...p.node,
              tags: [...tags],
            };
          }
          return p.node;
        }),
      },
    });
  }
  if (deliveryId) {
    const releaseQuery = cache.readQuery<ReleaseQuery>({
      query: ReleaseDocument,
      variables: {
        id: deliveryId,
      },
    });
    if (releaseQuery && tags) {
      cache.writeQuery({
        query: ReleaseDocument,
        variables: {
          id: deliveryId,
        },
        data: {
          release: {
            ...releaseQuery?.release,
            project: {
              ...releaseQuery?.release?.project,
              tags: [...tags],
            },
          },
        },
      });
    }
  }
  if (buyoutId) {
    const buyoutQuery = cache.readQuery<BuyoutQuery>({
      query: BuyoutDocument,
      variables: {
        id: buyoutId,
      },
    });
    if (buyoutQuery && tags) {
      cache.writeQuery({
        query: BuyoutDocument,
        variables: {
          id: buyoutId,
        },
        data: {
          buyout: {
            ...buyoutQuery?.buyout,
            project: {
              ...buyoutQuery?.buyout?.project,
              tags: [...tags],
            },
          },
        },
      });
    }
  }
};
