import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { Segment } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

type Props = {
  groupedByCostCode: boolean;
  setGroupedByCostCode: (value: boolean) => void;
};

export const GroupBySegment: FC<Props> = ({
  groupedByCostCode,
  setGroupedByCostCode,
}) => {
  return (
    <Tooltip
      id="group-by-cost-code"
      element={
        <IconButtonBorderless
          className="h-6"
          onClick={() => {
            setGroupedByCostCode(!groupedByCostCode);
          }}
        >
          <Segment color={groupedByCostCode ? "info" : "disabled"} />
        </IconButtonBorderless>
      }
    >
      <FormattedMessage
        id={
          !groupedByCostCode
            ? "GROUP_BY_COST_CODE_ON"
            : "GROUP_BY_COST_CODE_OFF"
        }
      />
    </Tooltip>
  );
};
