import { NoFunction } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";

export enum MatchedOrderViewState {
  DEFAULT = "DEFAULT",
  MATCH_ORDER = "MATCH_ORDER",
  CREATE_ORDER = "CREATE_ORDER",
  EDIT_INVOICE_COVERAGES = "EDIT_INVOICE_COVERAGES",
  EDIT_ORDER = "EDIT_ORDER",
  FULFILLED = "FULFILLED",
  IMPORT_ORDER = "IMPORT_ORDER",
}

type ProviderContextType = {
  matchedOrderViewState: MatchedOrderViewState | null;
  setMatchedOrderViewState: (status: MatchedOrderViewState | null) => void;
  selectedReleaseId: string | null;
  setSelectedReleaseId: (id: string | null) => void;
  preselectedPoNumber: string;
  setPreselectedPoNumber: (poNumber: string) => void;
  addMissingInvoiceItems: boolean;
  setAddMissingInvoiceItems: (add: boolean) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  matchedOrderViewState: null,
  setMatchedOrderViewState: NoFunction,
  selectedReleaseId: null,
  setSelectedReleaseId: NoFunction,
  preselectedPoNumber: "",
  setPreselectedPoNumber: NoFunction,
  addMissingInvoiceItems: false,
  setAddMissingInvoiceItems: NoFunction,
});

export const InvoiceMatchedOrderProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [matchedOrderViewState, setMatchedOrderViewState] =
    useState<MatchedOrderViewState | null>(null);
  const [selectedReleaseId, setSelectedReleaseId] = useState<string | null>(
    null,
  );
  const [preselectedPoNumber, setPreselectedPoNumber] = useState("");
  const [addMissingInvoiceItems, setAddMissingInvoiceItems] = useState(false);

  return (
    <ProviderContext.Provider
      value={{
        matchedOrderViewState,
        setMatchedOrderViewState,
        selectedReleaseId,
        setSelectedReleaseId,
        preselectedPoNumber,
        setPreselectedPoNumber,
        addMissingInvoiceItems,
        setAddMissingInvoiceItems,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceMatchedOrder = (): ProviderContextType =>
  useContext(ProviderContext);
