import {
  ReleaseProjectFieldsFragment,
  useReleaseProjectQuery,
} from "@/generated/graphql";
import { FC, createContext, useContext } from "react";
import { useParams } from "react-router-dom";

type ProviderContextType = {
  project: ReleaseProjectFieldsFragment | null;
  loading: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  project: null,
  loading: false,
});

export const ReleaseProjectProvider: FC<{
  children: React.ReactNode;
  id?: string;
}> = ({ children, id }) => {
  const { projectId } = useParams();
  const { data, loading } = useReleaseProjectQuery({
    variables: { id: id || projectId || "", excludePhantoms: true },
    skip: !id && !projectId,
  });

  return (
    <ProviderContext.Provider
      value={{
        project: data?.project || null,
        loading,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReleaseProject = (): ProviderContextType =>
  useContext(ProviderContext);
