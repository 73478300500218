import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import {
  ExternalCostCodeFieldsFragment,
  SourceSystem,
  useExternalCostCodesQuery,
} from "@/generated/graphql";
import React, { FC, createContext, useContext, useMemo } from "react";

export type ExternalCostCodeType = Omit<
  ExternalCostCodeFieldsFragment,
  "__typename"
> & {
  id: string;
  index: number;
};

type ProviderContextType = {
  externalCostCodes: ExternalCostCodeType[];
  loadingExternalCostCodes: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  externalCostCodes: [],
  loadingExternalCostCodes: false,
});

export const ExternalMaterialCostCodesProvider: FC<{
  children: React.ReactNode;
  sourceSystem: SourceSystem | null;
}> = ({ children, sourceSystem }) => {
  const {
    data: externalCostCodesData,
    loading: loadingExternalCostCodes,
    error: externalCostCodesError,
  } = useExternalCostCodesQuery({
    variables: {
      sourceSystem: sourceSystem ?? SourceSystem.Foundation,
    },
    skip: !sourceSystem,
  });
  useErrorEffect(externalCostCodesError);

  const externalCostCodes = useMemo(
    () =>
      externalCostCodesData?.externalCostCodes
        .map((i, index) => ({
          ...i,
          id: i.externalId,
          index,
        }))
        .toSorted((a, b) => a.name.localeCompare(b.name)) || [],
    [externalCostCodesData],
  );

  return (
    <ProviderContext.Provider
      value={{
        externalCostCodes,
        loadingExternalCostCodes,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useExternalMaterialCostCodes = () => useContext(ProviderContext);
