import { ManufacturerModalPicker } from "@/common/components/manufacturer-modal-picker/ManufacturerModalPicker";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useDistributorRelease } from "../providers/DistributorReleaseProvider";

type Props = {
  category: CategoryState<DistributorReleaseItemFieldsFragment>;
  readonly?: boolean;
};

export const DistributorReleaseCostCodeManufacturerSelector: FC<Props> = ({
  category,
  readonly,
}) => {
  const { updateVendorRelease } = useDistributorRelease();

  const saveManufacturer = useCallback(
    async (manufacturerId: string | null) => {
      const updates = category.items.map((item) => ({
        manufacturerId,
        releaseItemId: item.id,
      }));
      await updateVendorRelease({ updates });
    },
    [category.items, updateVendorRelease],
  );

  return (
    <ManufacturerModalPicker onChange={saveManufacturer} readonly={readonly} />
  );
};
