import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import { Check, Report } from "@mui/icons-material";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`text-sm`;
const Issue = tw.div`flex text-red-500 items-center gap-2`;
const IssueSolved = tw.div`flex text-green-600 items-center gap-2`;
const LgContainer = tw.div`hidden lg:block`;

type Props = {
  item: DistributorReleaseItemFieldsFragment;
};

export const DistributorReleaseItemIssues: FC<Props> = ({ item }) => {
  const issue = useMemo(() => item?.issues?.[0], [item]);
  return (
    <Container>
      <If isTrue={issue && !issue.resolution}>
        <Issue>
          <Report />
          <FormattedMessage id="ISSUE" />
        </Issue>
      </If>
      <If isTrue={issue && issue.resolution}>
        <IssueSolved>
          <Check />
          <FormattedMessage id="ISSUE_SOLVED" />
        </IssueSolved>
      </If>
      <If isTrue={!issue}>
        <LgContainer>
          <NotNullableRenderer />
        </LgContainer>
      </If>
    </Container>
  );
};
