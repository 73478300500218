import { If } from "@/common/components/if/If";
import { FC, ReactNode, useMemo } from "react";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../providers/DistributorQuoteItemsProvider";
import { useDistributorQuote } from "../providers/DistributorQuoteProvider";

type WithdrawalWrapperProps = {
  item: DistributorQuoteItemGroupExtendedFieldsFragment;
  children: ReactNode;
};

export const IncludeIfNoAlternatives: FC<WithdrawalWrapperProps> = ({
  item,
  children,
}) => {
  const { inEditQuoteItemGroups } = useDistributorQuote();
  const condition = useMemo(
    () => item.quoteItemCount === 1 && !inEditQuoteItemGroups.includes(item.id),
    [inEditQuoteItemGroups, item.id, item.quoteItemCount],
  );

  return <If isTrue={condition}>{children}</If>;
};
