import { addressToString } from "@/common/components/adress-location/AddressLocation";
import { FormatCurrencyType } from "@/common/components/value-currency/ValueCurrency";
import { PDF_FONT } from "@/common/const";
import { getVendorContacts } from "@/common/utils/getVendorContacts";
import {
  DistributorQuoteFieldsFragment,
  ProjectStatus,
} from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { IntlShape } from "react-intl";

export const quoteHeader = (
  doc: jsPDF,
  quote: DistributorQuoteFieldsFragment,
  intl: IntlShape,
  totalPriceWithDiscount: number,
  formatCurrency: FormatCurrencyType,
) => {
  autoTable(doc, {
    startY: 30,
    theme: "plain",
    showHead: false,
    styles: {
      font: PDF_FONT,
    },
    columnStyles: {
      0: { cellWidth: 50 },
    },
    body: [
      [
        {
          content: intl.$t({ id: "QUOTE_PRINT_RFQ_NUMBER" }),
          styles: { fontStyle: "bold" },
        },
        { content: quote.rfq.clientIdentifier },
      ],
      [
        {
          content: intl.$t({ id: "QUOTE_PRINT_RFQ_REQUESTED_FROM" }),
          styles: { fontStyle: "bold" },
        },
        {
          content: `${getVendorContacts(
            quote.preferredVendor?.contacts.filter(
              (f) => f.receivesQuoteNotifications,
            ),
          )}, ${quote.sellerOrgLocation.org.name}`,
        },
      ],
      [
        {
          content: intl.$t({ id: "QUOTE_PRINT_RFQ_REQUESTED_BY" }),
          styles: { fontStyle: "bold" },
        },
        {
          content: `${quote.rfq.createdBy.firstName} ${quote.rfq.createdBy.lastName}, ${quote.rfq.project.location.org.name}`,
        },
      ],
      [
        {
          content: intl.$t({ id: "QUOTE_PRINT_BRANCH_ADDRESS" }),
          styles: { fontStyle: "bold" },
        },
        {
          content: addressToString(quote.rfq.project.location.org.hqAddress),
        },
      ],
      [
        { content: intl.$t({ id: "PROJECT" }), styles: { fontStyle: "bold" } },
        { content: quote.rfq.project.name },
      ],
      [
        {
          content: intl.$t({ id: "PROJECT_STAGE" }),
          styles: { fontStyle: "bold" },
        },
        {
          content:
            quote.rfq.project.status === ProjectStatus.Awarded
              ? intl.$t({ id: "PROJECT_STATUS_AWARDED" })
              : intl.$t({ id: "PROJECT_STATUS_ACTIVE" }),
        },
      ],
      [
        {
          content: intl.$t({ id: "QUOTE_PRINT_PROJECT_ADDRESS" }),
          styles: { fontStyle: "bold" },
        },
        { content: addressToString(quote.rfq.project.address) },
      ],
      [
        {
          content: intl.$t({ id: "YOUR_QUOTE" }),
          styles: { fontStyle: "bold" },
        },
        {
          content: formatCurrency(totalPriceWithDiscount),
        },
      ],
      quote.submittedDate
        ? [
            {
              content: intl.$t({ id: "QUOTE_PRINT_QUOTE_DATE" }),
              styles: { fontStyle: "bold" },
            },
            {
              content: new Date(quote.submittedDate).toLocaleString("en", {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              }),
            },
          ]
        : [],
    ],
  });
};
