import { CountType } from "@/common/components/circle-counter/CountType";
import { ProjectItemMaterialView } from "@/common/components/project-item-material-view/ProjectItemMaterialView";
import { ReleaseItemPadding } from "@/common/components/release-item-padding/ReleaseItemPadding";
import {
  DistributorReleaseItemFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC } from "react";
import { useDistributorRelease } from "../../providers/DistributorReleaseProvider";

export const DistributorReleaseItem: FC<{
  item: DistributorReleaseItemFieldsFragment;
  count: CountType;
}> = ({ item, count }) => {
  const { release } = useDistributorRelease();
  if (!item.projectItem) {
    return null;
  }
  return (
    <>
      <ReleaseItemPadding item={item} count={count} />
      <ProjectItemMaterialView
        item={{
          ...item.projectItem,
          material: {
            ...item.projectItem.material,
            material: {
              ...item.projectItem.material.material,
              name: item.name,
            },
            costCode: item.costCode
              ? {
                  mappings: [],
                  inUse: true,
                  ...item.costCode,
                }
              : item.costCode,
          },
        }}
        includeCounter={!item.redelivery?.id && !item.backorderFromRelease}
        count={count}
        manufacturer={item.manufacturer}
        contractorNotes={{
          notes: item.instructions?.text,
          assets: item.instructions?.assets,
        }}
        vendorNotes={
          release &&
          [
            ReleaseStatus.Canceled,
            ReleaseStatus.PartiallyReceived,
            ReleaseStatus.Received,
          ].includes(release.status)
            ? {
                notes: item.notes,
                assets: item.assets,
              }
            : undefined
        }
      />
    </>
  );
};
