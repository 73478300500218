import { MAX_ITEMS_PER_PAGE } from "@/common/const";
import {
  ReleaseStatus,
  useReleasesSelectorLazyQuery,
} from "@/generated/graphql";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";

export const useInvoiceSuggestedOrders = () => {
  const { invoice } = useInvoiceVerification();

  const [loadSuggestedOrders, { data, loading, called }] =
    useReleasesSelectorLazyQuery({
      variables: {
        filter: {
          poNumber: invoice?.poNumber,
          statuses: [
            ReleaseStatus.Scheduled,
            ReleaseStatus.Received,
            ReleaseStatus.PartiallyReceived,
            ReleaseStatus.Requested,
            ReleaseStatus.Draft,
          ],
          closedProjects: false,
          deleted: false,
        },
        first: MAX_ITEMS_PER_PAGE,
      },
      fetchPolicy: "no-cache",
    });

  return {
    releases: data?.releases.edges.map((edge) => edge.node) || [],
    loading,
    called,
    loadSuggestedOrders,
  };
};
