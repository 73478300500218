import { ReleaseStatus } from "@/generated/graphql";

export const checkReleaseStatus = (
  release: { status: ReleaseStatus } | null | undefined,
  statuses: ReleaseStatus[],
) => {
  if (!release) {
    return false;
  }

  return statuses.includes(release.status);
};
