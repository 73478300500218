import { If } from "@/common/components/if/If";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { RfqMessagesButton } from "@/contractor/pages/home/rfqs/components/vendors/RfqMessagesButton";
import {
  MessageContextKind,
  QuoteStatus,
  RfqQuotesFieldsFragment,
  RfqStatus,
} from "@/generated/graphql";
import { IconButton } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { RfqQuotesQuoteFieldsFragment } from "../../../../../../../generated/graphql";
import {
  LEFT_COLUMN_WIDTH,
  MAX_COLUMN_WIDTH,
  MIN_COLUMN_WIDTH,
} from "../../../constants";
import { useRfqQuoteOptions } from "../../../providers/RfqQuoteOptionsProvider";
import { VendorInfo } from "./vendor-info/VendorInfo";

type Props = {
  rfq: RfqQuotesFieldsFragment;
};

const TABLE_QUOTE_HEADER_HEIGHT = 60;

const Container = tw.div`  
  sticky top-8 self-start z-40
  grid bg-white w-full mx-1
`;

const WhiteCorner = tw.div`
  grid sticky top-12 left-0 self-start justify-self-start bg-white z-50 w-full -ml-2
`;

const QuoteHeader = tw.div`
  w-full truncate rounded-tl-lg rounded-tr-lg
  grid grid-flow-col justify-between items-center bg-blue-100 pl-3 pr-4
`;

const Headers = tw.div`
  grid grid-flow-col gap-2 items-center 
  text-sm font-bold px-3 bg-white
`;

const QuoteText = tw.div`
  font-medium text-left w-full
`;

const Seller = tw.span`
  grid grid-flow-col ml-1.25 gap-1
  ${({ $deactivated }: { $deactivated: boolean }) =>
    $deactivated ? "text-gray-600" : ""}
`;

const OrgName = tw.div`
  truncate font-normal
`;

const IconButtonStyled = tw(IconButton)`
  z-20 cursor-pointer text-blue-500
  ${({ $deactivated }: { $deactivated: boolean }) =>
    $deactivated ? "opacity-50" : ""}
`;

export const QuotesHeader: FC<Props> = ({ rfq }) => {
  const { visibleQuotes } = useRfqQuoteOptions();
  const isDeactivated = (quote: RfqQuotesQuoteFieldsFragment) => {
    return (
      quote &&
      (rfq?.status === RfqStatus.Awarded ||
        rfq?.status === RfqStatus.Cancelled) &&
      quote.status !== QuoteStatus.Accepted
    );
  };

  return (
    <Container
      style={{
        gridTemplateColumns: `${LEFT_COLUMN_WIDTH}px auto`,
      }}
    >
      <WhiteCorner style={{ height: `${TABLE_QUOTE_HEADER_HEIGHT}px` }} />
      <Headers
        style={{
          gridTemplateColumns: `repeat(${visibleQuotes.length}, minmax(${MIN_COLUMN_WIDTH}px, 1fr))`,
        }}
      >
        {rfq.quotes.map((quote, index) => (
          <If isTrue={visibleQuotes.includes(quote)} key={quote.id}>
            <QuoteHeader
              style={{
                height: `${TABLE_QUOTE_HEADER_HEIGHT}px`,
                maxWidth: `${MAX_COLUMN_WIDTH}px`,
              }}
            >
              <Tooltip
                element={
                  <Seller $deactivated={isDeactivated(quote)}>
                    <QuoteText>
                      <FormattedMessage
                        id="QUOTE_NUMBER"
                        values={{ number: index + 1 }}
                      />
                      :
                    </QuoteText>
                    <OrgName>{quote.sellerOrgLocation.org?.name}</OrgName>
                  </Seller>
                }
                id="changed-status-popover"
                position="top"
              >
                {quote.preferredVendor ? (
                  <VendorInfo vendor={quote.preferredVendor} />
                ) : null}
              </Tooltip>
              <RfqMessagesButton
                quote={{
                  id: quote.id,
                  clientIdentifier: rfq.clientIdentifier,
                  rfqId: rfq.id,
                  projectId: rfq.project.id,
                }}
                org={quote.sellerOrgLocation.org}
                text={
                  <IconButtonStyled $deactivated={isDeactivated(quote)}>
                    <MessageButton
                      id={quote.id}
                      kind={MessageContextKind.Quote}
                    />
                  </IconButtonStyled>
                }
              />
            </QuoteHeader>
          </If>
        ))}
      </Headers>
    </Container>
  );
};
