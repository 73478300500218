import { TagPicker } from "@/common/components/tag-picker/TagPicker";
import { useEstimatedItems } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  TagFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FC, useCallback, useState } from "react";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";

type Props = {
  item?: ExpandedReleaseItem;
  readonly?: boolean;
  visibleTags?: number;
  batchUpdates?: boolean;
};

export const ReleaseTagsPicker: FC<Props> = ({
  item,
  visibleTags = 3,
  batchUpdates = false,
  readonly,
}) => {
  const { release, project, updateRelease } = useReleaseUpdate();
  const [selectedTags, setSelectedTags] = useState<TagFieldsFragment[]>([]);
  const { newProjectEstimatedItem } = useEstimatedItems();

  const handleChange = useCallback(
    async (tags: string[]) => {
      if (release) {
        if (item?.id) {
          const input: UpdateContractorReleaseInput = {
            releaseId: release?.id,
            version: release?.version,
            skipConfirmation: true,
            updates: [
              {
                releaseItemId: item.id,
                tags: tags.slice(-1),
              },
            ],
          };
          await updateRelease(input, { batch: batchUpdates });
        } else {
          newProjectEstimatedItem.tags = tags;
          setSelectedTags(
            tags
              .slice(-1)
              .map(
                (tag) =>
                  project?.tags?.find((t) => t.id === tag) as TagFieldsFragment,
              ),
          );
        }
      }
    },
    [
      release,
      item?.id,
      updateRelease,
      batchUpdates,
      newProjectEstimatedItem,
      project?.tags,
    ],
  );

  if (!release) {
    return null;
  }

  return (
    <TagPicker
      options={project?.tags || []}
      values={item?.id ? (item?.tags ?? selectedTags) : selectedTags}
      handleChange={handleChange}
      visibleTags={visibleTags}
      project={project}
      readonly={readonly}
    />
  );
};
