import { GridTable } from "@/common/components/grid-table/GridTable";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { Identity } from "@/types/Identity";
import { NoFunction } from "@/types/NoFunction";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ListRenderer } from "../list-renderer/ListRenderer";
import { NoResults } from "../no-results/NoResults";
import { TitledDebouncedRowSearchBar } from "./components/TitledDebouncedRowSearchBar";
import { itemFn, onItemClick } from "./utils";

const Container = tw.div``;

export type SingleSelectionProps<T = Identity> = {
  type: "single";
  selectedItemId: string | null;
  setSelectedItemId: (id: string | null) => void;
  setSelectedItem?: (item: T | null) => void;
  disable?: boolean;
};

export type MultipleSelectionProps = {
  type: "multiple";
  selectedItemIds: string[];
  setSelectedItemIds: (ids: string[]) => void;
  disable?: boolean;
};

export type ItemSelectionType<T = Identity> =
  | SingleSelectionProps<T>
  | MultipleSelectionProps;

type Props<T extends Identity> = {
  tableConfiguration: GridCol<T, undefined>[];
  items: T[];
  totalCount: number;
  loadingItems: boolean;
  error?: boolean;
  searchBarTitle: string | React.ReactNode;
  searchBarClassName?: string;
  itemSelection?: ItemSelectionType<T>;
  search?: {
    searchText: string;
    setSearchText: (text: string) => void;
    searchInputPlaceholder?: string;
  };
  additionalFilters?: React.ReactNode;
  headerAdditionalFilters?: React.ReactNode;
};

export const SearchableList = <T extends Identity>({
  totalCount,
  loadingItems,
  items,
  error,
  tableConfiguration,
  itemSelection,
  searchBarTitle,
  searchBarClassName,
  search,
  additionalFilters,
  headerAdditionalFilters,
}: Props<T>) => {
  const intl = useIntl();

  return (
    <Container>
      <TitledDebouncedRowSearchBar
        title={searchBarTitle}
        placeholder={
          search?.searchInputPlaceholder ?? intl.$t({ id: "SEARCH" })
        }
        onChange={search?.setSearchText ?? NoFunction}
        value={search?.searchText ?? ""}
        hideSearchInput={!search}
        containerClassName={searchBarClassName}
        additionalFilters={headerAdditionalFilters}
      />
      {additionalFilters}
      <ListRenderer totalCount={totalCount} count={items.length} paginated>
        <GridTable<T>
          configuration={{
            container: Container,
            columns: tableConfiguration,
            classNames: {
              itemFn: (item) => itemFn(item, itemSelection),
            },
            toggle: {
              item: itemSelection?.disable
                ? undefined
                : (item) => onItemClick(item, itemSelection),
            },
          }}
          items={items}
          loading={loadingItems}
          error={!!error}
          emptyList={
            <NoResults
              filteredTranslationKey="NO_PROJECT_FOUND"
              translationKey="NO_PROJECT_FOUND"
            />
          }
        />
      </ListRenderer>
    </Container>
  );
};
