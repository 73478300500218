import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  AssetContext,
  AssetFieldsFragment,
  AssetType,
  useCreateAssetMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionArrayPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { getAssetType } from "./getAssetType";

type ProviderContextType = {
  uploadAssets: (
    files: File[],
    options?: { assetType?: AssetType; replaceExistingAssets?: boolean },
  ) => Promise<AssetFieldsFragment[]>;
  removeAsset: (asset: AssetFieldsFragment) => AssetFieldsFragment[];
  uploading?: boolean;
  assets: AssetFieldsFragment[];
  setAssets: (assets: AssetFieldsFragment[]) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  uploadAssets: NoFunctionArrayPromise,
  removeAsset: () => [],
  uploading: false,
  assets: [],
  setAssets: NoFunction,
});

type UploadAssetProviderProps = {
  children: React.ReactNode;
  initialAssets?: AssetFieldsFragment[] | undefined | null;
  type?: AssetType;
  context: AssetContext;
  projectId?: string;
};

export const UploadAssetProvider: FC<UploadAssetProviderProps> = ({
  children,
  initialAssets,
  type,
  context,
  projectId,
}) => {
  const [uploadAssetMutation, { loading: uploading }] =
    useCreateAssetMutation();
  const { setError } = useGlobalError();
  const [assets, setAssets] = useState<AssetFieldsFragment[]>(
    initialAssets ?? [],
  );

  useEffect(() => {
    if (!assets) {
      setAssets(initialAssets ?? []);
    }
  }, [assets, initialAssets]);

  const uploadAssets = async (
    files: File[],
    options?: { assetType?: AssetType; replaceExistingAssets?: boolean },
  ) => {
    const assetList = options?.replaceExistingAssets ? [] : [...assets];
    try {
      for (const file of files) {
        const { data, errors } = await uploadAssetMutation({
          variables: {
            input: {
              file,
              type: options?.assetType ?? type ?? getAssetType(file.name),
              context,
              projectId,
            },
          },
        });
        if (data?.createAsset) {
          assetList.push(data.createAsset);
        }
        setError(errors);
      }
      setAssets(assetList);
      return assetList;
    } catch (error) {
      setError(error);
    }
    return assetList;
  };

  const removeAsset = (asset: AssetFieldsFragment) => {
    const result = [...assets.filter((a) => a.url !== asset.url)];
    setAssets(result);
    return result;
  };

  return (
    <ProviderContext.Provider
      value={{
        uploadAssets,
        removeAsset,
        uploading,
        assets,
        setAssets,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useUploadAssets = (): ProviderContextType =>
  useContext(ProviderContext);
