import {
  BreadcrumbItem,
  Breadcrumbs,
} from "@/common/components/breadcrumbs/Breadcrumbs";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { routes } from "@/config/routes";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";

export const CreateOrderFromQuoteBreadcrumbs: FC = () => {
  const intl = useIntl();
  const { release } = useRelease();

  const breadcrumbs = useMemo(() => {
    return [
      {
        text: intl.$t({ id: "ORDERS" }),
        link: routes.deliveries,
      },
      {
        text: release
          ? intl.$t({ id: "ADD_ITEMS_AND_COPY_PRICES" })
          : intl.$t({ id: "CREATE_ORDER_FROM_QUOTE" }),
      },
    ] as BreadcrumbItem[];
  }, [intl, release]);

  return (
    <Breadcrumbs
      classes={{ text: "flex items-center" }}
      items={breadcrumbs}
      appendItems={
        release
          ? [
              <ReleaseStatusChip
                key="status"
                status={release.status}
                type="breadcrumb"
                releaseType={release.type}
              />,
            ]
          : []
      }
    />
  );
};
