import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { OrgLocationFieldsFragment } from "@/generated/graphql";
import { FC, PropsWithChildren } from "react";
import { NewLocationForm } from "./NewLocationForm";

type ToggleNewLocationFormProps = PropsWithChildren<{
  location?: OrgLocationFieldsFragment;
  label: string;
}>;

export const ToggleNewLocationForm: FC<ToggleNewLocationFormProps> = ({
  location,
  children,
  label,
}) => {
  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <div onClick={() => togglePanel(true)}>{children}</div>
      )}
      content={(togglePanel) => (
        <NewLocationForm
          location={location}
          onClose={() => togglePanel(false)}
          label={label}
        />
      )}
    />
  );
};
