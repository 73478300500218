import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useExternalPoLazyQuery } from "@/generated/graphql";
import { useCallback } from "react";
import { useInvoiceImportExternalPO } from "../providers/InvoiceImportExternalPoProvider";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";

export const useLazyLoadExternalPo = () => {
  const { invoice } = useInvoiceVerification();
  const { importExternalPO } = useInvoiceImportExternalPO();
  const { connectedSourceSystem } = useOrgSettings();
  const [loadExternalPo, { loading }] = useExternalPoLazyQuery();

  const fetchExternalPo = useCallback(async () => {
    if (!connectedSourceSystem) {
      return;
    }
    const { data } = await loadExternalPo({
      variables: {
        input: {
          number: invoice?.poNumber,
          sourceSystem: connectedSourceSystem,
        },
      },
    });
    importExternalPO(data?.externalPO);
  }, [
    connectedSourceSystem,
    loadExternalPo,
    invoice?.poNumber,
    importExternalPO,
  ]);

  return {
    fetchExternalPo,
    loading,
  };
};
