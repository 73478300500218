import { LinkLike } from "@/common/components/link-like/LinkLike";
import { AddCircleOutlineOutlined, EditOutlined } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { FaEquals } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../../providers/DistributorQuoteItemsProvider";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";
import { IncludeIfNoAlternatives } from "../IncludeIfNoAlternatives";

const Container = tw.div`flex items-center`;
const ListItemIconStyled = tw(ListItemIcon)`text-blue-500`;

type Props = {
  item: DistributorQuoteItemGroupExtendedFieldsFragment;
};

export const DistributorQuoteItemAddMenu: FC<Props> = ({ item }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { updateQuote, updateInEditQuoteItemGroups } = useDistributorQuote();

  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((ev?: { stopPropagation?: () => void }) => {
    ev?.stopPropagation?.();
    setAnchorEl(null);
  }, []);

  const addAlternative = useCallback(async () => {
    await updateQuote({
      addedItems: [
        {
          rfqItemId: item.rfqItem.id,
          manufacturerId: item.quoteItems[0]?.manufacturer?.id,
          quantityDecimal: item.quoteItems[0]?.quantityDecimal,
        },
      ],
    });
    handleClose();
  }, [handleClose, item, updateQuote]);

  const specifySku = useCallback(() => {
    updateInEditQuoteItemGroups(item.id);
    handleClose();
  }, [handleClose, item.id, updateInEditQuoteItemGroups]);

  return (
    <Container>
      <LinkLike onClick={handleClick}>
        <AddCircleOutlineOutlined />
      </LinkLike>
      <Menu
        id={`quote-item-add-menu-${item.id}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={addAlternative}>
          <ListItemIconStyled>
            <FaEquals />
          </ListItemIconStyled>
          <ListItemText>
            <FormattedMessage id="ADD_ALTERNATIVE" />
          </ListItemText>
        </MenuItem>
        <IncludeIfNoAlternatives item={item}>
          <MenuItem onClick={specifySku}>
            <ListItemIconStyled>
              <EditOutlined />
            </ListItemIconStyled>
            <ListItemText>
              <FormattedMessage id="SPECIFY_SKU" />
            </ListItemText>
          </MenuItem>
        </IncludeIfNoAlternatives>
      </Menu>
    </Container>
  );
};
