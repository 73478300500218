import { ControlledProps } from "@/common/components/textfield-controlled/TextFieldControlled";
import { UploadAsset } from "@/common/components/upload-asset/UploadAsset";
import { ATTACHMENTS_MIME_TYPES } from "@/common/components/upload/FileUploadArea";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

export const ProjectReleaseAssets: FC<ControlledProps> = ({ disabled }) => {
  const { setValue } = useFormContext();

  return (
    <UploadAsset
      accept={ATTACHMENTS_MIME_TYPES}
      readonly={disabled}
      onChange={(assets) =>
        setValue(
          "releaseInstructions.assetUrls",
          assets.map((asset) => asset.url),
          { shouldDirty: true },
        )
      }
    />
  );
};
