import tw from "tailwind-styled-components";

type LinkProps = {
  $disabled?: boolean;
};

export const LinkLikeText = tw.div<LinkProps>`
    text-sm text-blue-500 font-bold cursor-pointer border-0 border-blue-500 hover:border-blue-300
     ${({ $disabled }: LinkProps) =>
       $disabled ? "text-gray-500 hover:text-gray-500 cursor-default" : ""}
`;
