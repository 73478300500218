import { useDialog } from "@/common/components/dialog/DialogProvider";
import {
  ExportCompleted,
  ExportType,
} from "@/common/components/export-completed/ExportCompleted";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useTabActive } from "@/common/hooks/useTabActive";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { IntegrationType } from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useIsValidReceiptFoundationExport } from "../../hooks/useIsValidReceiptFoundationExport";
import {
  ExportReceiptProvider,
  useExportReceipt,
} from "../../providers/ExportReceiptProvider";
import {
  ReceiptsByIdsProvider,
  useReceiptsByIds,
} from "../../providers/ReceiptsByIdsProvider";
import { ReceiptConnectionOptions } from "./ReceiptConnectionOptions";
import { ReceiptsExport } from "./ReceiptsExport";

const CardList = tw.div`flex flex-col gap-3`;

type Props = {
  onClose: () => void;
  receiptIds: string[];
};

const ExportReceiptsPanelWithProvider: FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const {
    receiptIds: receiptsByIds,
    loading,
    refetchReceiptsByIds,
  } = useReceiptsByIds();
  const { exportReceipts, updatedReceipts, postingDate } = useExportReceipt();
  const { openDialog } = useDialog();
  const { connectedIntegrationType, refetchSettings } = useOrgSettings();
  const { isValidReceiptFoundationExport } =
    useIsValidReceiptFoundationExport();

  const { isTabActive } = useTabActive();
  useEffect(() => {
    if (isTabActive && !loading) {
      refetchReceiptsByIds();

      refetchSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabActive]);

  const receiptsToExportCount = useMemo(
    () =>
      receiptsByIds.filter((receipt) =>
        isValidReceiptFoundationExport({
          receipt,
          updatedReceipts,
        }),
      ).length,
    [receiptsByIds, isValidReceiptFoundationExport, updatedReceipts],
  );

  const onSave = useCallback(async () => {
    if (
      await exportReceipts({
        integration: connectedIntegrationType ?? IntegrationType.Foundation,
        postingDate: postingDate ? postingDate.getTime() : undefined,
        invoices: receiptsByIds
          .filter((receipt) =>
            isValidReceiptFoundationExport({
              receipt,
              updatedReceipts,
            }),
          )
          .map((receipt) => ({
            invoiceId: receipt.id,
            includeCostCodes: false,
            vendorExternalCode: updatedReceipts.find((r) => r.id === receipt.id)
              ?.externalVendorCode,
          })),
      })
    ) {
      openDialog({
        content: (
          <ExportCompleted
            count={receiptsToExportCount}
            type={ExportType.INVOICE}
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
        hideTitle: true,
      });
      onClose();
    }
  }, [
    connectedIntegrationType,
    exportReceipts,
    postingDate,
    receiptsByIds,
    isValidReceiptFoundationExport,
    updatedReceipts,
    openDialog,
    receiptsToExportCount,
    onClose,
  ]);

  return (
    <OverlayPanel
      title={intl.$t({ id: "EXPORT_RECEIPTS" })}
      isLoading={loading && receiptsByIds.length === 0}
      onCancel={onClose}
      onSave={onSave}
      saveLabel={intl.$t(
        {
          id:
            !receiptsToExportCount || receiptsByIds.length > 1
              ? "EXPORT_WITH_NUMBER"
              : "EXPORT",
        },
        { number: receiptsToExportCount },
      )}
      disableSave={
        receiptsToExportCount === 0 ||
        (connectedIntegrationType === IntegrationType.Foundation &&
          !postingDate)
      }
    >
      <CardList>
        <ReceiptConnectionOptions />
        <ReceiptsExport receipts={receiptsByIds} />
      </CardList>
    </OverlayPanel>
  );
};

export const ExportReceiptsPanel: FC<Props> = (props) => (
  <ReceiptsByIdsProvider receiptIds={props.receiptIds}>
    <ExportReceiptProvider>
      <ExportReceiptsPanelWithProvider {...props} />
    </ExportReceiptProvider>
  </ReceiptsByIdsProvider>
);
