/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { GraphQLError } from "graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { wait } from "../utils/wait";
import { QueueItem } from "./types/QueueTypes";
import { useGlobalErrorStore } from "./useGlobalErrorStore";

const AWAIT_TIME = 400;

interface ApolloClientState<TInput, TOutput> {
  client: ApolloClient<NormalizedCacheObject> | null;
  setClient: (client: ApolloClient<NormalizedCacheObject>) => void;
  queue: QueueItem<TInput, TOutput>[];
  executionQueue: QueueItem<TInput, TOutput>[];
  getExecutionQueue: (id: string) => QueueItem<TInput, TOutput>[];
  runQueue: (id: string) => Promise<boolean>;
  chain: <TInput, TOutput>(input: QueueItem<TInput, TOutput>) => void;
}

export const useApolloClientStore = create<ApolloClientState<any, any>>()(
  devtools((set, get) => ({
    client: null,
    setClient: (client) => set({ client }),
    queue: [],
    chain: async <TInput, TOutput>(queueItem: QueueItem<TInput, TOutput>) => {
      set((state) => ({ queue: [...state.queue, queueItem] }));
      let runningQueue = await get().runQueue(queueItem.id);

      while (runningQueue) {
        runningQueue = await get().runQueue(queueItem.id);
        await wait(AWAIT_TIME);
      }
    },
    executionQueue: [],
    getExecutionQueue: (id: string) =>
      get().executionQueue.filter((queueItem) => queueItem.id === id),
    runQueue: async (id: string) => {
      const { setError } = useGlobalErrorStore.getState();
      if (get().executionQueue.some((item) => item.id === id)) {
        return true;
      }
      let executionQueue = get().queue.filter(
        (queueItem) => queueItem.id === id,
      );
      const remainingQueue = [
        ...get().queue.filter((queueItem) => queueItem.id !== id),
      ];
      set((state) => ({
        executionQueue: state.executionQueue.concat(executionQueue),
        queue: remainingQueue,
      }));
      for await (const item of executionQueue) {
        const index = executionQueue.indexOf(item);
        try {
          const result = await item.fn?.(executionQueue[index]?.params);
          if (item.callback) {
            const currentQueue = get().queue.filter(
              (queueItem) => queueItem.id === id,
            );
            const newQueueParams = item.callback(
              currentQueue.map((queueItem) => queueItem.params),
              result,
            );
            const executionQueueParams = item.callback(
              executionQueue.map((queueItem) => queueItem.params),
              result,
              { isExecutionQueueCallback: true },
            );
            executionQueue.forEach((queueItem, index) => {
              queueItem.params = {
                ...queueItem.params,
                ...executionQueueParams[index],
              };
            });
            set((state) => ({
              queue: state.queue
                .filter((queueItem) => queueItem.id !== id)
                .concat(
                  state.queue
                    .filter((queueItem) => queueItem.id === id)
                    .map((queueItem, index) => {
                      queueItem.params = newQueueParams[index];
                      return queueItem;
                    }),
                ),
            }));
          }
        } catch (e) {
          const currentQueue = get().queue.filter(
            (queueItem) => queueItem.id === id,
          );
          set((state) => ({
            executionQueue: state.executionQueue.filter(
              (queueItem) => queueItem.id !== id,
            ),
          }));
          setError(e);
          item.errorCallback?.(e);
          item.callback?.(
            currentQueue.map((queueItem) => queueItem.params),
            { errors: [e as GraphQLError], data: undefined },
            { isExecutionQueueCallback: true },
          );
        }
      }
      set((state) => ({
        executionQueue: state.executionQueue.filter(
          (queueItem) => queueItem.id !== id,
        ),
      }));
      return false;
    },
  })),
);

export const useGenericApolloClientStore = <TInput, TOutput>(
  selector: (
    state: ApolloClientState<TInput, TOutput>,
  ) => ApolloClientState<TInput, TOutput> = (state) => state,
) => useApolloClientStore(selector);
