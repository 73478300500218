import { CountType } from "@/common/components/circle-counter/CountType";
import { InvoiceReleaseItemDetails } from "@/common/components/invoices/invoice-release-item-details/InvoiceReleaseItemDetails";
import { ProjectItemMaterialView } from "@/common/components/project-item-material-view/ProjectItemMaterialView";
import { useMaterialCostCodeAndZone } from "@/contractor/pages/home/common/hooks/useMaterialCostCodeAndZone";
import { useReleaseItemsZoneProvider } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`grid w-full gap-0.5`;

type Props = {
  item: ExpandedReleaseItem;
  count: CountType;
  extraDetails?: React.ReactNode;
  projectId?: string;
};

export const InvoiceReleaseItemMaterial: FC<Props> = ({
  item,
  count,
  extraDetails,
  projectId,
}) => {
  const { groupedByCostCode } = useReleaseItemsZoneProvider();
  const { itemDetails } = useMaterialCostCodeAndZone(
    item,
    groupedByCostCode,
    projectId,
  );

  return (
    <Container>
      <ProjectItemMaterialView
        item={item}
        count={count}
        includeCounter={!item.redelivery?.id && !item.backorderFromRelease}
        manufacturer={item.manufacturer}
        expandable={false}
        extraDetails={extraDetails}
        extraDetailsPosition="right"
        bottomExtraDetails={itemDetails}
      />
      <InvoiceReleaseItemDetails
        notes={item.notes}
        assets={item.assets}
        issue={item.issues?.[0]}
        instructions={item.instructions}
      />
    </Container>
  );
};
