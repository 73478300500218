import {
  ReleaseFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { extPriceCalculation } from "../hooks/usePriceCalculation";

export const getReleaseSubtotal = (items: ReleaseFieldsFragment["items"]) =>
  items.reduce(
    (acc, item) =>
      acc.add(extPriceCalculation(item.quantityDecimal, item.unitPrice)),
    new Decimal(0),
  );

const getReleaseSubtotalWithCharges = (release: ReleaseFieldsFragment) =>
  getReleaseSubtotal(release.items).plus(
    release.additionalCharges.reduce(
      (acc, charge) => acc.add(new Decimal(charge.amount)),
      new Decimal(0),
    ),
  );

export const getReleaseTotal = (release: ReleaseFieldsFragment) => {
  const subtotalWithCharges = getReleaseSubtotalWithCharges(release);
  const taxAmount = release.taxRate
    ? subtotalWithCharges.mul(release.taxRate)
    : new Decimal(release.customTaxAmount || 0);
  return subtotalWithCharges.plus(taxAmount);
};

export const applyInputChanges = (
  release: ReleaseFieldsFragment,
  input: UpdateContractorReleaseInput,
): ReleaseFieldsFragment => {
  return {
    ...release,
    taxRate: input.customTaxAmount
      ? Number(getReleaseSubtotalWithCharges(release))
        ? new Decimal(input.customTaxAmount)
            .div(getReleaseSubtotalWithCharges(release))
            .toString()
        : "0"
      : input.taxRate || release.taxRate,
    customTaxAmount: Object.hasOwn(input, "customTaxAmount")
      ? input.customTaxAmount
      : input.clearCustomTaxAmount
        ? null
        : release.customTaxAmount,
    taxAmount: input.taxRate
      ? new Decimal(input.taxRate)
          .mul(getReleaseSubtotalWithCharges(release))
          .toString()
      : input.customTaxAmount || release.taxAmount,
    additionalCharges:
      input.additionalCharges?.map((charge, index) => ({
        ...charge,
        id: charge.id || index.toString(),
        amount: charge.amount.toString(),
      })) || release.additionalCharges,
    items: release.items
      .filter((item) => !input.removedItems?.includes(item.id))
      .map((item) => {
        const updatedItem = input.updates?.find(
          (update) => update.releaseItemId === item.id,
        );
        if (!updatedItem) {
          return item;
        }
        return {
          ...item,
          unitPrice: updatedItem.unitPrice || item.unitPrice,
          quantityDecimal: updatedItem.quantityDecimal || item.quantityDecimal,
        };
      }),
  };
};
