import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { useSourceSystems } from "@/common/hooks/integrations/source-system-config/useSourceSystems";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import {
  Base,
  LgContainer,
  MdContainer,
  MdFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { OrderTypeFieldsFragment, PoFormat } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { OrderTypeActions } from "./OrderTypeActions";
import { OrderTypeName } from "./OrderTypeName";

const ActionsWrapper = tw(Base)`
  right-4 w-17
`;
const Label = tw.div`grid text-center`;
const Item = tw.div`text-base`;
const SmallItem = tw.div`text-sm`;

export const useOrderTypesConfiguration = () => {
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { isSourceSystem } = useSourceSystems();

  const configuration: Array<GridCol<OrderTypeFieldsFragment>> = useMemo(
    () => [
      {
        wrapper: LgContainer,
        header: <FormattedMessage id="ORDER_TYPE" />,
        item: ({ item }) => <OrderTypeName orderType={item} />,
      },
      {
        wrapper: MdContainer,
        header: <FormattedMessage id="GL_ACCOUNT_NUMBER" />,
        item: ({ item }) => (
          <NotNullableRenderer value={item.ledgerAccount}>
            <Item>
              {item.ledgerAccount?.name} - {item.ledgerAccount?.number}
            </Item>
          </NotNullableRenderer>
        ),
        hidden: !hasFeatureInConnectedSourceSystem(
          IntegrationFeature.GeneralLedgerAccount,
        ),
      },
      {
        wrapper: MdContainer,
        header: <FormattedMessage id="COST_TYPE" />,
        item: ({ item }) => (
          <Label>
            <NotNullableRenderer value={item.costType?.description}>
              <Item>{item.costType?.description}</Item>
            </NotNullableRenderer>
          </Label>
        ),
        position: "center",
        hidden: !isSourceSystem({
          anySourceSystem: true,
          anyIntegrationType: true,
        }),
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="PO_TYPE" />,
        item: ({ item }) => (
          <Label>
            <NotNullableRenderer value={item.poType?.name}>
              {item.poType?.name}
            </NotNullableRenderer>
          </Label>
        ),
        position: "center",
        hidden: !hasFeatureInConnectedSourceSystem(IntegrationFeature.PoType),
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="PO_FORMAT" />,
        item: ({ item }) => (
          <Label>
            {item.poFormat === PoFormat.Basic ? (
              <FormattedMessage id="BASIC" />
            ) : (
              <FormattedMessage id="DETAILED" />
            )}
          </Label>
        ),
        position: "center",
      },
      {
        wrapper: LgContainer,
        header: <FormattedMessage id="ADDITIONAL_CHARGES" />,
        item: ({ item }) => (
          <NotNullableRenderer value={item.chargesMode}>
            <FormattedMessage
              id={`CHARGES_${item.chargesMode}`}
              tagName={SmallItem}
            />
          </NotNullableRenderer>
        ),
        position: "center",
      },
      {
        wrapper: LgContainer,
        header: <FormattedMessage id="SALES_TAX" />,
        item: ({ item }) => (
          <NotNullableRenderer value={item.taxMode}>
            <FormattedMessage
              id={`SALES_TAX_${item.taxMode}`}
              tagName={SmallItem}
            />
          </NotNullableRenderer>
        ),
        position: "center",
      },
      {
        wrapper: ActionsWrapper,
        item: ({ item }) => <OrderTypeActions orderType={item} />,
        position: "center",
      },
    ],
    [hasFeatureInConnectedSourceSystem, isSourceSystem],
  );

  return { configuration };
};
