import { UomPicker } from "@/common/components/uom-picker/UomPicker";
import { DistributorQuotesAuxiliaryQuoteItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";

type Props = {
  item: DistributorQuotesAuxiliaryQuoteItemFieldsFragment;
  readonly: boolean;
  index?: number;
  error?: boolean;
};

export const DistributorQuoteAuxiliaryUom: FC<Props> = ({
  item,
  error,
  readonly,
  ...props
}) => {
  const { updateQuote } = useDistributorQuote();
  const intl = useIntl();
  const saveUom = useCallback(
    async (newValue: string | null) => {
      await updateQuote({
        updatedAuxiliaryItems: [
          {
            auxiliaryItemId: item.id,
            uom: newValue || "",
          },
        ],
      });
    },
    [item.id, updateQuote],
  );
  return (
    <UomPicker
      uom={item.uom}
      saveUom={saveUom}
      error={error && !item.uom}
      placeholder={intl.$t({ id: "UNIT_OF_MEASURE" })}
      staticText={readonly}
      creatable={false}
      {...props}
    />
  );
};
