export const positionSuffix = (index: number): string => {
  const reminder = index % 10;
  if (reminder === 1 && index !== 11) {
    return "st";
  } else if (reminder === 2 && index !== 12) {
    return "nd";
  } else if (reminder === 3 && index !== 13) {
    return "rd";
  }
  return "th";
};
