import { IntegrationType } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntegrationTypeConfig } from "./useIntegrationConfig";

export const useIntegrationLogo = (
  integrationType?: IntegrationType | null,
  smallLogo?: boolean,
) => {
  const { getIntegrationConfig: getConfig } = useIntegrationTypeConfig();

  const integrationLogo = useMemo(() => {
    if (!integrationType) {
      return "";
    }

    const logos = getConfig(integrationType).logo;
    if (!logos) {
      return "";
    }
    return smallLogo ? logos.small : logos.normal;
  }, [getConfig, integrationType, smallLogo]);

  return { integrationLogo };
};
