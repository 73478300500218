import { If } from "@/common/components/if/If";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import {
  TableViewProvider,
  VIEW_STATE,
  useTableView,
} from "@/common/providers/TableViewProvider";

import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { useMaterials } from "../materials/hooks/useMaterials";
import { useVendorPrices } from "./hooks/useVendorPrices";
import { MaterialPriceList } from "./material-price-list/MaterialPriceList";
import { useMaterialPricesConfiguration } from "./material-price-list/MaterialPriceList.configuration";
import { MaterialPricesFooter } from "./material-price-list/MaterialPricesFooter";
import { MaterialPricesHeader } from "./material-price-list/MaterialPricesHeader";
import {
  MaterialPricesProvider,
  useMaterialPrices,
} from "./providers/MaterialPricesProvider";
import {
  SyncPricesProvider,
  useSyncPrices,
} from "./providers/SyncPricesProvider";
import { ExpandedMaterialPrice } from "./stores/useVendorPricesStore";

const MaterialPricesWithProvider = () => {
  const intl = useIntl();
  const { materials: allMaterials } = useMaterials();
  const { materials } = useMaterialPrices();
  const { loading, totalCount, isFiltered } = useMaterialPrices();
  const { tableView } = useTableView();
  const { spreadsheetConfig } = useMaterialPricesConfiguration();
  const { saving } = useSyncPrices();
  const { prices } = useVendorPrices();
  const [allPrices, setAllPrices] = useState([] as ExpandedMaterialPrice[]);
  useEffect(() => {
    const newPrices = [] as ExpandedMaterialPrice[];
    allMaterials.forEach((material) => {
      const matPrices = prices.filter((p) => p.orgMaterialId === material.id);

      if (matPrices.length > 0) {
        newPrices.push(...matPrices);
      } else {
        newPrices.push({
          orgMaterialId: material.id,
          id: "",
          material,
          uom: {
            id: material.defaultEstimateUom?.id,
          },
          manufacturer: { ...material.manufacturer },
          vendorName: "",
        } as ExpandedMaterialPrice);
      }
    });
    if (!isEqual(allPrices, newPrices)) {
      setAllPrices(newPrices);
    }
  }, [allPrices, allMaterials, prices]);

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "MATERIAL_PRICES" })}</title>
      </Helmet>
      <MaterialPricesHeader />
      <If isTrue={tableView === VIEW_STATE.normal}>
        <MaterialPriceList
          materials={materials}
          loading={loading}
          totalCount={totalCount}
          isFiltered={isFiltered}
        />
      </If>
      <If isTrue={tableView === VIEW_STATE.spreadsheet}>
        <SpreadSheetTable
          items={allPrices}
          columns={spreadsheetConfig}
          saving={saving}
          height="calc(100vh - 320px)"
        />
      </If>
      <MaterialPricesFooter />
    </>
  );
};
export const MaterialPrices = () => {
  const { spreadsheetConfig } = useMaterialPricesConfiguration();

  return (
    <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
      <MaterialPricesProvider>
        <ColumnMapperProvider config={spreadsheetConfig}>
          <TableViewProvider>
            <SyncPricesProvider>
              <MaterialPricesWithProvider />
            </SyncPricesProvider>
          </TableViewProvider>
        </ColumnMapperProvider>
      </MaterialPricesProvider>
    </PaginationProvider>
  );
};
