import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import {
  ReleaseFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { usePriceCalculation } from "../../hooks/usePriceCalculation";
import { ExpandedReleaseItem } from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";

type Props = {
  item: ExpandedReleaseItem;
  index: number | undefined;
  release:
    | Pick<ReleaseFieldsFragment, "id" | "status" | "version">
    | null
    | undefined;
};

export const ReleaseItemExtPrice: FC<Props> = ({ item, release, index }) => {
  const { calcExtPrice } = usePriceCalculation();
  const { updateRelease } = useReleaseUpdate();

  const saveExtPrice = useCallback(
    async (extPrice: string | null) => {
      if (!release || !isLumpSumItem(item)) {
        return;
      }
      const input: UpdateContractorReleaseInput = {
        releaseId: release?.id,
        version: release?.version,
        updates: [
          {
            releaseItemId: item.id,
            quantityDecimal: extPrice,
            unitPrice: "1.0",
          },
        ],
      };
      await updateRelease(input);
    },
    [release, item, updateRelease],
  );

  return (
    <PricePicker
      value={calcExtPrice(item.quantityDecimal, item.unitPrice).toString()}
      index={index}
      onBlur={async (extPrice) => {
        const mapValue = isNaN(Number(extPrice)) ? null : extPrice;
        if (mapValue === item.quantityDecimal) {
          return;
        }
        await saveExtPrice(extPrice);
      }}
    />
  );
};
