import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { TagColorPicker } from "@/common/components/tag-picker/TagColorPicker";
import { getRandomTagColor } from "@/common/components/tag-picker/TagColors";
import { TagItem } from "@/common/components/tag-picker/TagItem";
import { TextfieldWithActions } from "@/common/components/textfield-with-actions/TextfieldWithActions";
import { EventKeysConstants } from "@/config/constants/eventKeysContants";
import { useProjectTags } from "@/contractor/pages/home/project/providers/ProjectTagsProvider";
import { ProjectFieldsFragment, TagFieldsFragment } from "@/generated/graphql";
import {
  CheckOutlined,
  CloseOutlined,
  ColorLensOutlined,
  DeleteOutline,
  EditOutlined,
} from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FormControl, FormGroup } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  Actions,
  Container,
  InfoIconContainer,
  ItemContainer,
  ListItem,
  ListItemContainer,
} from "../../project-form-with-stepper/ProjectFormWithStepper.styles";

type Props = {
  project: ProjectFieldsFragment;
  readonly?: boolean;
};

const enum Mode {
  EditName,
  EditColor,
}

const Info = tw.div`bg-gray-100 rounded-3xl p-4 text-gray-500 flex max-w-[450px] items-center`;
const InfoText = tw.div`text-sm`;
const StyledInfoOutlinedIcon = tw(InfoOutlinedIcon)`text-4xl mr-2`;
const StyledFormGroup = tw(FormGroup)`max-w-[450px]`;
const StyledListItemContainer = tw(ListItemContainer)`max-w-[450px]`;
const Divider = tw.div`border-b border-gray-200 my-2`;
const Title = tw.div`text-sm font-medium p-3`;

export const ProjectFormTags: FC<Props> = ({ project, readonly }) => {
  const intl = useIntl();
  const [newTag, setNewTag] = useState("");
  const [updatedTagName, setUpdatedTagName] = useState("");
  const [updatedColor, setUpdatedColor] = useState("");
  const [tagId, setTagId] = useState<string | null>();
  const [mode, setMode] = useState<Mode | undefined>();
  const { updateTag, addTag, deleteTag } = useProjectTags();
  const { openDialog } = useDialog();

  const saveNewTag = useCallback(
    async (value: string) => {
      const color = getRandomTagColor(project.tags.map((t) => t.color));

      await addTag({
        projectId: project.id,
        name: value,
        color,
      });

      setNewTag("");
    },
    [addTag, project.id, project.tags],
  );

  const reset = useCallback(() => {
    setTagId(null);
    setUpdatedTagName("");
    setUpdatedColor("");
    setMode(undefined);
  }, [setTagId, setUpdatedTagName, setUpdatedColor, setMode]);

  const updateTagColor = useCallback(
    (tagId: string) => {
      updateTag({
        id: tagId,
        color: updatedColor,
      });
      reset();
    },
    [reset, updateTag, updatedColor],
  );

  const setUpdateColor = useCallback((tag: TagFieldsFragment) => {
    setTagId(tag.id);
    setUpdatedColor(tag.color || "");
    setMode(Mode.EditColor);
  }, []);

  const setDeleteTag = useCallback(
    (tag: TagFieldsFragment) => {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "DELETE" }),
        icon: <WarningIcon />,
        title: intl.$t({ id: "DELETE_TAG" }),
        text: intl.$t(
          { id: "DELETE_TAG_INFO" },
          { name: <strong>{tag.name}</strong> },
        ),
        handleConfirm: () => deleteTag(tag.id),
      });
    },
    [openDialog, intl, deleteTag],
  );

  const setUpdateName = useCallback((tag: TagFieldsFragment) => {
    setTagId(tag.id);
    setMode(Mode.EditName);
    setUpdatedTagName(tag.name || "");
  }, []);

  const saveUpdatedTagName = useCallback(
    (tagId: string) => {
      updateTag({
        id: tagId,
        name: updatedTagName,
      });
      setMode(undefined);
      reset();
    },
    [updateTag, updatedTagName, reset],
  );

  const disabled = useMemo(
    () =>
      project.tags.some(
        (t) =>
          t.name === newTag || (t.name === updatedTagName && t.id !== tagId),
      ),
    [project.tags, newTag, updatedTagName, tagId],
  );

  const projectTags = useMemo(
    () => project.tags.filter((t) => !t.mapping),
    [project.tags],
  );

  const mappedProjectTags = useMemo(
    () => project.tags.filter((t) => t.mapping),
    [project.tags],
  );

  return (
    <Container>
      <Info>
        <StyledInfoOutlinedIcon />
        <FormattedMessage id="TAGS_INFO" tagName={InfoText} />
      </Info>
      <If isTrue={!readonly}>
        <StyledFormGroup>
          <TextfieldWithActions
            label={intl.$t({ id: "TAGS" })}
            placeholder={intl.$t({ id: "CREATE_NEW_TAG" })}
            value={newTag}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setNewTag(event.target.value)
            }
            onKeyDown={async (event: React.KeyboardEvent<HTMLInputElement>) => {
              if (
                event.key === EventKeysConstants.Enter &&
                !disabled &&
                newTag
              ) {
                await saveNewTag(newTag);
              }
            }}
            error={project.tags.some((t) => t.name === newTag)}
            disableSave={disabled}
            onClickSave={() => saveNewTag(newTag)}
            onClickClose={() => setNewTag("")}
            showActions={newTag !== ""}
            additionalActions={
              <InfoIconContainer>
                <InfoTooltip
                  message={intl.$t({ id: "ADD_TAGS_INFO" })}
                  id="tag-info"
                />
              </InfoIconContainer>
            }
            shrink
          />
        </StyledFormGroup>
      </If>
      <StyledListItemContainer>
        {projectTags.map((tag, key) => (
          <ListItem key={key}>
            <ItemContainer>
              <If isTrue={mode !== Mode.EditName || tag.id !== tagId}>
                <TagItem
                  tag={
                    mode === Mode.EditColor && tag.id === tagId
                      ? { ...tag, color: updatedColor }
                      : tag
                  }
                />
                <If isTrue={!readonly && tag.id !== tagId}>
                  <Actions>
                    <LinkLike onClick={() => setUpdateName(tag)}>
                      <EditOutlined />
                    </LinkLike>
                    <LinkLike onClick={() => setUpdateColor(tag)}>
                      <ColorLensOutlined />
                    </LinkLike>
                    <LinkLike onClick={() => setDeleteTag(tag)}>
                      <DeleteOutline />
                    </LinkLike>
                  </Actions>
                </If>
                <If isTrue={!readonly && tagId === tag.id}>
                  <Actions>
                    <LinkLike onClick={() => updateTagColor(tag.id)}>
                      <CheckOutlined />
                    </LinkLike>
                    <LinkLike onClick={reset}>
                      <CloseOutlined />
                    </LinkLike>
                  </Actions>
                </If>
              </If>
              <If isTrue={tag.id === tagId && mode === Mode.EditName}>
                <FormControl fullWidth>
                  <TextfieldWithActions
                    label={intl.$t({ id: "UPDATE_TAG_NAME" })}
                    value={updatedTagName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setUpdatedTagName(event.target.value)
                    }
                    onKeyDown={(
                      event: React.KeyboardEvent<HTMLInputElement>,
                    ) => {
                      if (
                        event.key === EventKeysConstants.Enter &&
                        !disabled &&
                        updatedTagName
                      ) {
                        saveUpdatedTagName(tag.id);
                      }
                    }}
                    error={project.tags.some(
                      (t, index) => t.name === updatedTagName && key !== index,
                    )}
                    disableSave={disabled || updatedTagName === ""}
                    onClickSave={() => saveUpdatedTagName(tag.id)}
                    onClickClose={reset}
                    showActions={true}
                  />
                </FormControl>
              </If>
            </ItemContainer>
            <If isTrue={tag.id === tagId && mode === Mode.EditColor}>
              <TagColorPicker
                selectedColor={updatedColor}
                setSelectedColor={(color: string) => setUpdatedColor(color)}
              />
            </If>
          </ListItem>
        ))}
        <If isTrue={mappedProjectTags.length > 0}>
          <Divider />
          <FormattedMessage id="IMPORTED_PHASE_TAGS" tagName={Title} />
          {mappedProjectTags.map((tag, key) => (
            <ListItem key={key}>
              <ItemContainer>
                <TagItem
                  tag={
                    mode === Mode.EditColor && tag.id === tagId
                      ? { ...tag, color: updatedColor }
                      : tag
                  }
                />
                <If isTrue={!readonly && tag.id !== tagId}>
                  <Actions>
                    <LinkLike onClick={() => setUpdateColor(tag)}>
                      <ColorLensOutlined />
                    </LinkLike>
                    <LinkLike onClick={() => setDeleteTag(tag)}>
                      <DeleteOutline />
                    </LinkLike>
                  </Actions>
                </If>
                <If isTrue={!readonly && tagId === tag.id}>
                  <Actions>
                    <LinkLike onClick={() => updateTagColor(tag.id)}>
                      <CheckOutlined />
                    </LinkLike>
                    <LinkLike onClick={reset}>
                      <CloseOutlined />
                    </LinkLike>
                  </Actions>
                </If>
              </ItemContainer>
              <If isTrue={tag.id === tagId && mode === Mode.EditColor}>
                <TagColorPicker
                  selectedColor={updatedColor}
                  setSelectedColor={(color: string) => setUpdatedColor(color)}
                />
              </If>
            </ListItem>
          ))}
        </If>
      </StyledListItemContainer>
    </Container>
  );
};
