import { FC, PropsWithChildren } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`fixed bottom-0 pr-2 pl-2 md:pl-16 md:pr-16 left-0 right-0 z-[190] bg-blue-450 overflow-hidden min-h-15 py-2.5`;

export const FloatingFooter: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return <Container className={className}>{children}</Container>;
};
