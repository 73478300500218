import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  RfqVendorFieldsFragment,
  useResendQuoteMutation,
  useRfqVendorsQuery,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, PropsWithChildren, createContext, useContext } from "react";

type VendorLocationsType = Pick<RfqVendorFieldsFragment, "status"> & {
  preferredVendor: RfqVendorFieldsFragment["preferredVendor"];
  quote: {
    id: RfqVendorFieldsFragment["id"];
    clientIdentifier: RfqVendorFieldsFragment["clientIdentifier"];
  };
} & RfqVendorFieldsFragment["sellerOrgLocation"];

type RfqVendorsProviderProps = PropsWithChildren<{
  rfqId: string;
}>;

type ProviderContextType = {
  vendorLocations: VendorLocationsType[];
  resendQuote: (quoteId: string) => Promise<boolean>;
};

const ProviderContext = createContext<ProviderContextType>({
  vendorLocations: [],
  resendQuote: NoFunctionBooleanPromise,
});

export const RfqVendorsProvider: FC<RfqVendorsProviderProps> = ({
  children,
  rfqId,
}) => {
  const [resendQuoteMutation] = useResendQuoteMutation();
  const { setError } = useGlobalError();
  const { data, error } = useRfqVendorsQuery({
    variables: { id: rfqId },
  });
  useErrorEffect(error);

  const resendQuote = async (quoteId: string) => {
    try {
      const { errors } = await resendQuoteMutation({
        variables: {
          input: {
            quoteId,
          },
        },
      });
      setError(errors);
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        vendorLocations:
          data?.rfq?.quotes.map((quote) => ({
            ...quote.sellerOrgLocation,
            status: quote.status,
            quote,
            preferredVendor: quote.preferredVendor,
          })) || [],
        resendQuote,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useRfqVendors = (): ProviderContextType =>
  useContext(ProviderContext);
