import { If } from "@/common/components/if/If";
import { Select } from "@/common/components/select/components/single/Select";
import {
  VendorPickerCustomRender,
  vendorLabelFormatter,
} from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { UpdateContractorReleaseItemInput } from "@/generated/graphql";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReleaseUpdate } from "../../../../providers/ReleaseUpdateProvider";
import { useSyncReleaseItems } from "../../hooks/useSyncReleaseItems";

const Container = tw.div`
  grid grid-flow-col items-center gap-2 p-0.5 px-1
`;

const Label = tw.div`
  pr-1
`;

type Props = {
  setLoading: (loading: boolean) => void;
};

export const BulkVendorSelector: FC<Props> = ({ setLoading }) => {
  const { selectedReleaseItemIds, setSelectedReleaseItemIds } =
    useReleaseUpdate();
  const { updateRelease } = useSyncReleaseItems();
  const { release } = useRelease();
  const intl = useIntl();
  const { vendors } = useVendors();
  const [vendorId, setVendorId] = useState<string | null>(null);

  const invalidSelection = useMemo(() => {
    return release?.items.find((item) => {
      return item.buyoutItem && selectedReleaseItemIds.includes(item.id);
    });
  }, [release?.items, selectedReleaseItemIds]);

  const updateVendor = useCallback(
    async (vendor: string | null) => {
      setVendorId(vendor);
      const itemsToUpdate: UpdateContractorReleaseItemInput[] =
        selectedReleaseItemIds.map((item) => ({
          releaseItemId: item,
          sellerOrgLocationId: vendor,
          clearSellerOrgLocation: !vendor,
        }));

      setLoading(true);
      await updateRelease({
        releaseId: release?.id ?? "",
        version: release?.version ?? 0,
        updates: itemsToUpdate,
      });
      setLoading(false);

      setSelectedReleaseItemIds([]);
    },
    [
      release?.id,
      release?.version,
      selectedReleaseItemIds,
      setLoading,
      setSelectedReleaseItemIds,
      updateRelease,
    ],
  );

  return (
    <Container>
      <FormattedMessage
        id={
          invalidSelection
            ? "INVALID_BUYOUT_ITEMS_SPLIT"
            : "SPLIT_ORDER_WITH_NUM"
        }
        values={{ count: selectedReleaseItemIds.length }}
        tagName={Label}
      />
      <If isTrue={!invalidSelection}>
        <Select
          testId="vendor-selector"
          options={vendors || []}
          className="w-72"
          value={vendorId}
          onChange={updateVendor}
          customRender={(item) =>
            VendorPickerCustomRender(item, (c) => c.receivesOrderNotifications)
          }
          label={intl.$t({ id: "SELECT_VENDOR" })}
          InputLabelProps={{
            classes: {
              focused: "text-transparent",
            },
          }}
          getLabel={(o) =>
            vendorLabelFormatter(
              o.sellerOrgLocation,
              o.contacts.filter((c) => c.receivesOrderNotifications),
            )
          }
          getValue={(option) => option.sellerOrgLocation.id}
          noOptionsText={intl.$t({ id: "NO_VENDORS_FOUND" })}
        />
      </If>
    </Container>
  );
};
