import {
  AdditionalFiltersOverlay,
  Option,
} from "@/common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useDeliverySlipVerification } from "../../providers/DeliverySlipVerificationProvider";
import {
  DeliverySlipFilter,
  DeliverySlipFilters,
} from "../../types/DeliverySlipFilters";

export const DeliverySlipListFilters = () => {
  const intl = useIntl();
  const { filter, setFilter } = useDeliverySlipVerification();

  const options = useMemo<Option<DeliverySlipFilters>[]>(() => {
    return [
      {
        id: DeliverySlipFilter.FULLY_RECEIVED,
        title: intl.$t({ id: DeliverySlipFilter.FULLY_RECEIVED }),
        filter: (value) => ({
          [DeliverySlipFilter.FULLY_RECEIVED]: value,
        }),
        value: (currentFilter) =>
          currentFilter?.[DeliverySlipFilter.FULLY_RECEIVED],
      },
    ];
  }, [intl]);

  return (
    <AdditionalFiltersOverlay
      filter={filter}
      defaultFilter={{
        [DeliverySlipFilter.FULLY_RECEIVED]: undefined,
      }}
      options={options}
      setFilter={setFilter}
    />
  );
};
