import { Price } from "@/common/components/price/Price";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { DistributorQuoteItemFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";

type BidPriceTotalForItemProps = {
  item: Pick<
    DistributorQuoteItemFieldsFragment,
    "unitPrice" | "quantityDecimal" | "id"
  >;
};

const BidPriceTotalForItemContainer = tw.div`
  w-full flex font-medium text-sm md:text-base overflow-hidden print:text-base print:font-normal text-right justify-end
`;

const PriceContainer = tw.div`
  w-full text-ellipsis overflow-hidden whitespace-nowrap text-sm
`;

export const BidPriceTotalForItem: FC<BidPriceTotalForItemProps> = ({
  item,
}) => {
  const { calcExtPrice } = usePriceCalculation();

  const price = useMemo(() => {
    return calcExtPrice(item.quantityDecimal || 0, item.unitPrice || 0);
  }, [item.unitPrice, item.quantityDecimal, calcExtPrice]);
  return (
    <BidPriceTotalForItemContainer>
      <Tooltip
        element={
          <PriceContainer>
            <Price price={price} />
          </PriceContainer>
        }
        id={`price-info-${item.id}`}
      >
        <Price price={price} />
      </Tooltip>
    </BidPriceTotalForItemContainer>
  );
};
