import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import { routes } from "@/config/routes";
import {
  InvoiceStatus,
  ReceiptSummaryFieldsFragment,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";

type Params = {
  navigateToReceipts?: boolean;
  markSequenceIdAsApproved?: string;
};

type ProviderContextType = {
  step: number;
  setStep: (step: number) => void;
  sequenceIds: string[];
  selectedReceipts: ReceiptSummaryFieldsFragment[];
  setSelectedReceipts: (receipts: ReceiptSummaryFieldsFragment[]) => void;
  sequenceActive: boolean;
  startSequence: () => void;
  stopSequence: () => void;
  navigateToNextSequence: (params?: Params) => void;
  redirectToReceipts: () => void;
};

const ProviderContext = createContext<ProviderContextType>({
  step: 0,
  setStep: NoFunction,
  sequenceIds: [],
  selectedReceipts: [],
  setSelectedReceipts: NoFunction,
  sequenceActive: false,
  startSequence: NoFunction,
  stopSequence: NoFunction,
  navigateToNextSequence: NoFunction,
  redirectToReceipts: NoFunction,
});

export const ReceiptsSequenceProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { readValue, setValue, removeValue } = useLocalStorage();
  const [selectedReceipts, setSelectedReceipts] = useState<
    ReceiptSummaryFieldsFragment[]
  >(
    readValue(
      LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SELECTED_INVOICES,
      [],
    ) as ReceiptSummaryFieldsFragment[],
  );
  const [step, setStep] = useState(
    readValue(LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SEQUENCE_STEP, 0) as number,
  );
  const [sequenceActive, setSequenceActive] = useState(
    readValue(
      LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SEQUENCE_ACTIVE,
      false,
    ) as boolean,
  );

  const setSelectedReceiptsAndUpdateLocalStorage = useCallback(
    (receipts: ReceiptSummaryFieldsFragment[]) => {
      setSelectedReceipts(receipts);
      setValue(
        LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SELECTED_INVOICES,
        receipts,
      );
    },
    [setValue],
  );

  const setSequenceActiveAndUpdateLocalStorage = useCallback(
    (active: boolean) => {
      setSequenceActive(active);
      setValue(LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SEQUENCE_ACTIVE, active);
    },
    [setValue],
  );

  const setStepAndUpdateLocalStorage = useCallback(
    (step: number) => {
      setStep(step);
      setValue(LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SEQUENCE_STEP, step);
    },
    [setValue],
  );

  const startSequence = useCallback(() => {
    setSelectedReceiptsAndUpdateLocalStorage(selectedReceipts);
    setSequenceActiveAndUpdateLocalStorage(true);
    setValue(LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SEQUENCE_ACTIVE, true);
    const sequenceStep = readValue(
      LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SEQUENCE_STEP,
      0,
    ) as number;
    const firstReceipt = selectedReceipts[sequenceStep];
    if (firstReceipt) {
      setValue(
        LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.REDIRECT_ROUTE,
        location.search,
      );
      navigate(
        generatePath(routes.processReceipt, {
          receiptId: firstReceipt.id,
        }),
      );
    }
  }, [
    location.search,
    navigate,
    readValue,
    selectedReceipts,
    setSelectedReceiptsAndUpdateLocalStorage,
    setSequenceActiveAndUpdateLocalStorage,
    setValue,
  ]);

  const stopSequence = useCallback(() => {
    setSelectedReceiptsAndUpdateLocalStorage([]);
    setSequenceActiveAndUpdateLocalStorage(false);
    setStepAndUpdateLocalStorage(0);
    removeValue(LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.REDIRECT_ROUTE);
    removeValue(LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.SEQUENCE_STEP);
  }, [
    removeValue,
    setSelectedReceiptsAndUpdateLocalStorage,
    setSequenceActiveAndUpdateLocalStorage,
    setStepAndUpdateLocalStorage,
  ]);

  const sequenceIds = useMemo(
    () => selectedReceipts.map((i) => i.id),
    [selectedReceipts],
  );

  const redirectToReceipts = useCallback(() => {
    const redirectParams = readValue(
      LOCAL_STORAGE_KEYS.RECEIPT_SEQUENCES.REDIRECT_ROUTE,
      "",
    ) as string | undefined;
    navigate(generatePath(`${routes.receipts}${redirectParams}`));
  }, [readValue, navigate]);

  const navigateToNextSequence = useCallback(
    (
      { markSequenceIdAsApproved, navigateToReceipts }: Params = {
        navigateToReceipts: false,
        markSequenceIdAsApproved: undefined,
      },
    ) => {
      if (sequenceActive) {
        if (markSequenceIdAsApproved) {
          setSelectedReceiptsAndUpdateLocalStorage(
            selectedReceipts.map((i) => {
              if (i.id === markSequenceIdAsApproved) {
                return {
                  ...i,
                  status: InvoiceStatus.Approved,
                };
              }
              return i;
            }),
          );
        }
        if (sequenceIds.length === step + 1) {
          redirectToReceipts();
        } else {
          setStepAndUpdateLocalStorage(step + 1);
          navigate(
            generatePath(routes.processReceipt, {
              receiptId: sequenceIds[step + 1],
            }),
          );
        }
      } else if (navigateToReceipts) {
        redirectToReceipts();
      }
    },
    [
      sequenceActive,
      sequenceIds,
      step,
      setSelectedReceiptsAndUpdateLocalStorage,
      selectedReceipts,
      redirectToReceipts,
      setStepAndUpdateLocalStorage,
      navigate,
    ],
  );

  return (
    <ProviderContext.Provider
      value={{
        step,
        setStep: setStepAndUpdateLocalStorage,
        sequenceIds,
        selectedReceipts,
        setSelectedReceipts,
        sequenceActive,
        startSequence,
        stopSequence,
        navigateToNextSequence,
        redirectToReceipts,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReceiptSequence = (): ProviderContextType =>
  useContext(ProviderContext);
