import { GridTable } from "@/common/components/grid-table/GridTable";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { ZoneItemsContainer } from "@/contractor/pages/home/project/Project.styles";
import { useSpendingReportItems } from "@/contractor/pages/home/project/providers/ProjectSpendingReportItemsProvider";
import {
  ProjectReportType,
  useSpendingReport,
} from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { FC, useMemo } from "react";
type Props = {
  columns: Array<GridCol<ProjectReportType>>;
};

export const SpendingReportListVendors: FC<Props> = ({ columns }) => {
  const { zones, toggleCostCode } = useSpendingReportItems();
  const { vendorsLoading } = useSpendingReport();

  const hasItems = useMemo(() => {
    return zones.some((zone) => zone.items.length > 0);
  }, [zones]);

  return (
    <ListRenderer
      hasItemsCondition={hasItems}
      emptyList={<NoResults translationKey="NO_ITEMS" />}
    >
      <GridTable
        configuration={{
          container: ZoneItemsContainer,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          columns: columns as any,
          classNames: {
            root: "pb-20",
            header: "top-[146px]",
            subCategory: "top-[175px]",
          },
          toggle: {
            subCategory: toggleCostCode,
          },
        }}
        items={zones}
        hideGroup={true}
        readonly={true}
        loading={vendorsLoading}
      />
    </ListRenderer>
  );
};
