import {
  WizardModal,
  WizardModalMode,
} from "@/common/components/wizard-modal/WizardModal";
import { useImportExternalProjectsWizard } from "./ImportExternalProjectsWizardProvider";

export const ImportExternalProjectsModal = () => {
  const { modalOpened, pages, closeModal } = useImportExternalProjectsWizard();

  return (
    <WizardModal
      mode={WizardModalMode.MULTIPLE_PAGES}
      opened={modalOpened}
      pages={pages}
      onClose={closeModal}
    />
  );
};
