import Logo from "@/assets/images/Logo.svg?react";
import { ArrowForwardIos } from "@mui/icons-material";
import { Fragment } from "react/jsx-runtime";
import tw from "tailwind-styled-components";

const Container = tw.div`mb-2`;
const VariationChip = tw.div`bg-gray-300 px-2 py-1 font-bold text-2xs inline-block rounded-lg`;
const Info = tw.div`pt-6`;
const TopRow = tw.div`grid grid-cols-[1fr_1fr_1fr] pb-2 border-b border-dotted items-center justify-center`;
const SystemLogo = tw.div`text-center flex justify-center`;
const FMLogo = tw.div`text-center grid justify-center`;
const Explanation = tw.div`grid grid-cols-[1fr_1fr_1fr] items-center justify-center text-xs mt-2 gap-y-2`;
const Source = tw.div`text-center`;
const Arrow = tw.div`text-center`;
const Target = tw.div`text-center`;
const ArrowForwardIosStyled = tw(ArrowForwardIos)`h-3 w-3`;
const Image = tw.img``;
const StyledLogo = tw(Logo)`h-6 w-24`;

export const VariationView = ({
  variationText,
  explanations,
  logo,
}: {
  variationText: string;
  logo: string;
  explanations: { source: string; target: string }[];
}) => {
  return (
    <Container>
      <VariationChip>{variationText}</VariationChip>
      <Info>
        <TopRow>
          <SystemLogo>
            <Image src={logo} />
          </SystemLogo>
          <div />
          <FMLogo>
            <StyledLogo />
          </FMLogo>
        </TopRow>
        <Explanation>
          {explanations.map((explanation) => (
            <Fragment key={explanation.source}>
              <Source>{explanation.source}</Source>
              <Arrow>
                <ArrowForwardIosStyled />
              </Arrow>
              <Target>{explanation.target}</Target>
            </Fragment>
          ))}
        </Explanation>
      </Info>
    </Container>
  );
};
