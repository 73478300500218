import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import {
  AssetContext,
  AssetFieldsFragment,
  QuoteDocumentSummaryFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ExpandablePanel } from "../expandable-panel/ExpandablePanel";
import { If } from "../if/If";
import { SupportedFormats, UploadAsset } from "../upload-asset/UploadAsset";
import { UploadAssetProvider } from "../upload-asset/UploadAssetProvider";
import { PDF_EXTENSIONS, PDF_MIME_TYPE } from "../upload/FileUploadArea";

type Props = {
  quoteDocument?: QuoteDocumentSummaryFieldsFragment | null;
  readonly?: boolean;
  customRedirect?: () => void;
  children?: React.ReactNode;
};

const QuoteDocumentPanelWithProvider: FC<Props> = ({
  readonly,
  quoteDocument,
  customRedirect,
  children,
}) => {
  const intl = useIntl();

  const { updateBuyout, buyout } = useContractorBuyout();

  const updateUrls = useCallback(
    async (assets: AssetFieldsFragment[]) => {
      if (!buyout) {
        return;
      }

      await updateBuyout({
        quoteDocumentAssetUrl: assets.length
          ? assets.map((a) => a.url)[0]
          : null,
        buyoutId: buyout?.id,
        clearQuoteDocument: !assets.length,
      });
    },
    [buyout, updateBuyout],
  );

  if (readonly && !quoteDocument?.asset && !children) {
    return null;
  }

  return (
    <ExpandablePanel header={intl.$t({ id: "QUOTE_DOCUMENT" })} isOpened={true}>
      <If isTrue={children}>{children}</If>
      <If isTrue={!children}>
        <UploadAsset
          onChange={updateUrls}
          customRedirect={customRedirect}
          accept={{
            [PDF_MIME_TYPE]: PDF_EXTENSIONS,
          }}
          limit={1}
          xs
          mode="horizontal"
          className="flex items-start"
          readonly={readonly}
        >
          <If isTrue={!quoteDocument?.asset}>
            <SupportedFormats>
              <FormattedMessage id="QUOTE_DOCUMENT_SUPPORTED_FORMATS" />
            </SupportedFormats>
          </If>
        </UploadAsset>
      </If>
    </ExpandablePanel>
  );
};

export const QuoteDocumentPanel: FC<Props> = (props) => {
  return (
    <UploadAssetProvider
      initialAssets={props.quoteDocument ? [props.quoteDocument.asset] : []}
      context={AssetContext.QuoteDocument}
    >
      <QuoteDocumentPanelWithProvider {...props} />
    </UploadAssetProvider>
  );
};
