import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  SiteContactInput,
  SiteContactsDocument,
  useArchiveSiteContactMutation,
  useCreateSiteContactMutation,
  useSiteContactsQuery,
} from "@/generated/graphql";

export const useSiteContacts = () => {
  const [archiveSiteContactMutation] = useArchiveSiteContactMutation();
  const [createSiteContactMutation] = useCreateSiteContactMutation();
  const { setError } = useGlobalError();
  const { data, loading, error, refetch } = useSiteContactsQuery({
    variables: {
      archived: false,
    },
  });

  const archiveSiteContact = async (id: string) => {
    try {
      const { errors } = await archiveSiteContactMutation({
        variables: { id },
        refetchQueries: [
          {
            query: SiteContactsDocument,
            variables: { archived: false },
          },
        ],
      });

      setError(errors);
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const createSiteContact = async (input: SiteContactInput) => {
    try {
      const { data: createdData, errors } = await createSiteContactMutation({
        variables: { input },
        awaitRefetchQueries: true,
      });

      setError(errors);
      if (createdData) {
        refetch();
        return createdData?.createSiteContact.id;
      }
    } catch (errors) {
      setError(errors);
      return undefined;
    }
  };

  return {
    siteContacts: data?.siteContacts || [],
    loading,
    error,
    archiveSiteContact,
    createSiteContact,
  };
};
