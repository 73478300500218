import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useUser } from "@/common/providers/UserProvider";
import {
  AddWarehouseInput,
  UpdateWarehouseInput,
  WarehouseFieldsFragment,
  WarehousesDocument,
  useAddWarehouseMutation,
  useArchiveWarehouseMutation,
  useStartupDataQuery,
  useUpdateWarehouseMutation,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, PropsWithChildren, createContext, useContext } from "react";

type ContextProps = {
  warehouses: WarehouseFieldsFragment[];
  loading: boolean;
  error: boolean;
  createWarehouse: (warehouse: AddWarehouseInput) => Promise<boolean>;
  updateWarehouse: (warehouse: UpdateWarehouseInput) => Promise<boolean>;
  archiveWarehouse: (id: string) => Promise<boolean | undefined>;
};

const ProviderContext = createContext<ContextProps>({
  warehouses: [],
  loading: false,
  error: false,
  createWarehouse: NoFunctionBooleanPromise,
  updateWarehouse: NoFunctionBooleanPromise,
  archiveWarehouse: NoFunctionBooleanPromise,
});

export const WarehousesProvider: FC<PropsWithChildren> = ({ children }) => {
  const { viewer } = useUser();
  const { data, loading, error } = useStartupDataQuery();
  const { setError } = useGlobalError();
  const [archiveWarehouseMutation] = useArchiveWarehouseMutation();
  const [updateWarehouseMutation] = useUpdateWarehouseMutation();
  const [addWarehouseMutation] = useAddWarehouseMutation();

  const createWarehouse = async (warehouse: AddWarehouseInput) => {
    try {
      const { data, errors } = await addWarehouseMutation({
        variables: { input: warehouse },
        refetchQueries: [
          {
            query: WarehousesDocument,
            variables: { id: viewer?.org.id || "" },
          },
        ],
      });
      setError(errors);
      return !!data?.addWarehouse;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const updateWarehouse = async (warehouse: UpdateWarehouseInput) => {
    try {
      const { data, errors } = await updateWarehouseMutation({
        variables: { input: warehouse },
        refetchQueries: [
          {
            query: WarehousesDocument,
            variables: { id: viewer?.org.id || "" },
          },
        ],
      });
      setError(errors);
      return !!data?.updateWarehouse;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const archiveWarehouse = async (id: string) => {
    try {
      const { data, errors } = await archiveWarehouseMutation({
        variables: { id },
        refetchQueries: [
          {
            query: WarehousesDocument,
            variables: { id: viewer?.org.id || "" },
          },
        ],
      });
      setError(errors);
      return !!data?.archiveWarehouse;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        warehouses: data?.viewer?.org?.warehouses || [],
        loading,
        error: !!error,
        createWarehouse,
        updateWarehouse,
        archiveWarehouse,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useWarehouses = (): ContextProps => useContext(ProviderContext);
