import { CircleCounter } from "@/common/components/circle-counter/CircleCounter";
import { CountType } from "@/common/components/circle-counter/CountType";
import { TextfieldWithActions } from "@/common/components/textfield-with-actions/TextfieldWithActions";
import { DistributorQuotesAuxiliaryQuoteItemFieldsFragment } from "@/generated/graphql";
import { FC, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";

const Container = tw.div`w-full flex flex-row items-center gap-2`;
const TextfieldWithActionsStyled = tw(
  TextfieldWithActions,
)`w-full my-2 bg-transparent`;

type Props = {
  item: DistributorQuotesAuxiliaryQuoteItemFieldsFragment;
  readonly?: boolean;
  error?: boolean;
  count: CountType;
};

export const DistributorQuoteAuxiliaryDescription: FC<Props> = ({
  item,
  readonly,
  error,
  count,
}) => {
  const { updateQuote } = useDistributorQuote();
  const intl = useIntl();
  const initialDescription = useMemo(
    () => item.description,
    [item.description],
  );
  const [description, setDescription] = useState<string>(
    item.description || "",
  );

  useEffect(() => {
    setDescription(initialDescription || "");
  }, [initialDescription]);

  return (
    <Container>
      <CircleCounter count={count} />
      <TextfieldWithActionsStyled
        label={!readonly ? intl.$t({ id: "ITEM_DESCRIPTION" }) : ""}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        onBlur={() =>
          updateQuote({
            updatedAuxiliaryItems: [{ auxiliaryItemId: item.id, description }],
          })
        }
        shrink
        inputProps={{
          style: { marginTop: 0 },
          className: `${
            readonly && "font-light"
          } text-xs md:text-sm py-1 md:py-1.5`,
        }}
        error={error && !description}
        staticText={readonly}
      />
    </Container>
  );
};
