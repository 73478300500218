import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { NewBuyoutForm } from "@/contractor/pages/home/buyouts/components/new-buyout/NewBuyoutForm";
import {
  BuyoutFieldsFragment,
  ProjectFieldsFragment,
} from "@/generated/graphql";
import { FC, PropsWithChildren } from "react";
import { BsPencil } from "react-icons/bs";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

type Prop = PropsWithChildren<{
  project?: ProjectFieldsFragment | null;
  buyout?: BuyoutFieldsFragment | null;
  staticText?: boolean;
}>;

const Container = tw.div`flex gap-1 hover:cursor-pointer items-center`;

export const ToggleBuyoutForm: FC<Prop> = ({
  children,
  buyout,
  staticText = false,
}) => {
  const intl = useIntl();

  if (staticText) {
    return <>{children}</>;
  }

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <Container onClick={() => togglePanel(true)}>
          <>{children}</>
          <BsPencil />
        </Container>
      )}
      content={(togglePanel) => (
        <NewBuyoutForm
          onClose={() => togglePanel(false)}
          label={intl.$t({ id: "EDIT_BUYOUT" })}
          project={buyout?.project}
          buyout={buyout}
        />
      )}
    />
  );
};
