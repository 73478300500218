import { If } from "@/common/components/if/If";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { ReceiptSummaryFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import tw from "tailwind-styled-components";
import { useReceiptSequence } from "../../providers/ReceiptsSequenceProvider";

const Container = tw.div`flex`;

type Props = {
  receipt: ReceiptSummaryFieldsFragment;
};

export const ReceiptCheckbox: FC<Props> = ({ receipt }) => {
  const { sequenceIds, setSelectedReceipts, selectedReceipts } =
    useReceiptSequence();

  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedReceipts(
        newChecked
          ? [...selectedReceipts, receipt]
          : selectedReceipts.filter((i) => i.id !== receipt.id),
      );
    },
    [receipt, selectedReceipts, setSelectedReceipts],
  );

  return (
    <Container>
      <If isTrue={receipt.permissions.export && receipt.prePaid === true}>
        <SelectionCheckbox
          itemId={receipt.id}
          items={sequenceIds}
          setSelection={handleChange}
        />
      </If>
    </Container>
  );
};
