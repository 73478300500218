import { If } from "@/common/components/if/If";
import {
  LEFT_COLUMN_WIDTH,
  MIN_COLUMN_WIDTH,
} from "@/contractor/pages/home/rfq-quotes/constants";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useRfqQuoteOptions } from "../../../../providers/RfqQuoteOptionsProvider";
import { useRfqQuotes } from "../../../../providers/RfqQuotesProvider";
import { QuoteRowLabels } from "../../rfq-items-leveling/rfq-item/QuoteRowLabels";
import { AuxItemCard } from "./AuxItemCard";

const Container = tw.div`  
  grid bg-white  w-full rounded-3xl shadow
`;

const NameAndDescription = tw.div`
  grid justify-items-start content-start bg-blue-100 rounded-3xl 
  p-3 max-w-96 font-light text-2xs gap-1 text-gray-600 
`;

const LeftGroup = tw.div`
  sticky left-0 grid grid-cols-1fr-auto z-20
`;

const Quotes = tw.div`
  grid bg-blue-100 grid-flow-col gap-2 px-4 py-2 rounded-r-3xl h-full 
`;

const QuoteItemFields = tw.div`
  grid grid-flow-row gap-1 py-2 w-33 px-4 bg-white
`;

const QuotesAndAlternatives = tw.div`
  grid grid-flow-row gap-1 h-full content-start
`;

export const AuxVendorItems: FC = () => {
  const { visibleQuotes } = useRfqQuoteOptions();
  const { rfq } = useRfqQuotes();

  const itemFields = useMemo(() => {
    if (rfq?.quotes.length === 0) {
      return [0];
    }
    const maxRows = Math.max(
      ...(rfq?.quotes.map((quote) => quote.auxiliaryItems?.length ?? 0) ?? []),
    );
    return Array.from(Array(maxRows).keys());
  }, [rfq]);

  const showPriceExpiration = useCallback(
    (index: number) => {
      return rfq?.quotes.some((q) => q.auxiliaryItems[index]?.expirationDate);
    },
    [rfq?.quotes],
  );

  if (!rfq) {
    return null;
  }

  return (
    <Container
      style={{
        gridTemplateColumns: `${LEFT_COLUMN_WIDTH}px auto`,
      }}
    >
      <LeftGroup>
        <NameAndDescription>
          <FormattedMessage id="AUXILIARY_ITEMS_DESCRIPTION" />
        </NameAndDescription>
        <QuoteItemFields>
          {itemFields.map((row, index) => (
            <QuoteRowLabels
              key={row}
              showItemDescription
              hasExpiration={showPriceExpiration(index)}
            />
          ))}
        </QuoteItemFields>
      </LeftGroup>
      <Quotes
        style={{
          gridTemplateColumns: `repeat(${visibleQuotes.length}, minmax(${MIN_COLUMN_WIDTH}px, 1fr))`,
        }}
      >
        {rfq.quotes.map((quote) => (
          <If isTrue={visibleQuotes.includes(quote)} key={quote.id}>
            <QuotesAndAlternatives
              style={{
                gridTemplateRows: `repeat(${itemFields.length}, minmax(0, auto))`,
              }}
            >
              {quote.auxiliaryItems.map((item) => (
                <AuxItemCard key={item.id} quote={quote} item={item} />
              ))}
            </QuotesAndAlternatives>
          </If>
        ))}
      </Quotes>
    </Container>
  );
};
