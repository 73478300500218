import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import {
  EstimatedItemFieldsFragment,
  ProjectItemExtendedFieldsFragment,
} from "@/generated/graphql";
import { Check, Close, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useProjectMaps } from "../../hooks/useProjectMaps";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";
import { useProject } from "../../providers/ProjectProvider";

type Props = {
  item: ProjectItemExtendedFieldsFragment;
  readonly?: boolean;
  estimatedItem?: EstimatedItemFieldsFragment | null;
  setEditMode: (value: boolean) => void;
  canRemove: boolean;
};
const IconButtonContainer = tw.div`flex items-center pl-3 gap-3 w-full`;

export const ProjectItemActions: FC<Props> = ({
  item,
  readonly = false,
  estimatedItem,
  setEditMode,
  canRemove,
}: Props) => {
  const {
    cancelEstimatedItem,
    removeEstimatedItem,
    saveEstimatedItem,
    editEstimatedItem,
    updating,
  } = useEstimatedItems();
  const { openDialog } = useDialog();
  const intl = useIntl();
  const { project } = useProject();
  const { projectItemsMap } = useProjectMaps(project);

  const onEdit = useCallback(() => {
    if (!updating && editEstimatedItem(item, estimatedItem)) {
      setEditMode(true);
    }
  }, [editEstimatedItem, estimatedItem, item, setEditMode, updating]);

  const onCancel = useCallback(() => {
    setEditMode(false);
    cancelEstimatedItem();
  }, [cancelEstimatedItem, setEditMode]);

  const onSave = useCallback(async () => {
    const projectItem = item.id ? projectItemsMap.get(item.id) : item;
    if (projectItem && (await saveEstimatedItem(projectItem))) {
      setEditMode(false);
    }
  }, [item, projectItemsMap, saveEstimatedItem, setEditMode]);

  const onRemove = useCallback(() => {
    openDialog({
      confirmButtonText: intl.$t({ id: "REMOVE" }),
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      icon: <WarningIcon />,
      title: intl.$t({ id: "REMOVE_ESTIMATED_ITEM" }),
      text: intl.$t({ id: "REMOVE_ESTIMATED_ITEM_TEXT" }),
      handleConfirm: async () => {
        if (estimatedItem) {
          await removeEstimatedItem(estimatedItem?.id);
        }
      },
    });
  }, [openDialog, intl, estimatedItem, removeEstimatedItem]);

  const icons = useMemo(() => {
    if (!readonly) {
      return [
        <LinkLike key={0} onClick={onSave}>
          <Check />
        </LinkLike>,
        <LinkLike key={1} onClick={onCancel}>
          <Close />
        </LinkLike>,
      ];
    } else {
      return [
        <LinkLike key={0} onClick={onEdit}>
          <EditOutlined />
        </LinkLike>,
        <If isTrue={canRemove} key={1}>
          <LinkLike onClick={onRemove}>
            <DeleteOutline />
          </LinkLike>
        </If>,
      ];
    }
  }, [canRemove, onCancel, onEdit, onRemove, onSave, readonly]);

  return <IconButtonContainer>{icons.map((icon) => icon)}</IconButtonContainer>;
};
