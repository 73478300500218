import { Notes } from "@/common/components/notes/Notes";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { DISTRIBUTOR_RELEASE_READ_ONLY_STATUSES } from "@/distributor/common/constants";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useDistributorRelease } from "../providers/DistributorReleaseProvider";

type Props = {
  item: DistributorReleaseItemFieldsFragment;
};

export const DistributorReleaseNotes: FC<Props> = ({ item }) => {
  const { expandedItems, setExpandedItems, release } = useDistributorRelease();

  return (
    <Notes
      expandedItems={expandedItems}
      setExpandedItems={setExpandedItems}
      item={item}
      readonly={checkReleaseStatus(
        release,
        DISTRIBUTOR_RELEASE_READ_ONLY_STATUSES,
      )}
      hasNotesOrAttachments={!!item.notes || !!item.assets?.length}
    />
  );
};
