import { RfqFieldsFragment, RfqStatus } from "@/generated/graphql";

export const checkRfqStatus = (
  rfq: RfqFieldsFragment | { status: RfqStatus } | null | undefined,
  statuses: RfqStatus[],
) => {
  if (!rfq) {
    return false;
  }

  return statuses.includes(rfq.status);
};
