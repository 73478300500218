import { Loader } from "@/common/components/loader/Loader";
import { MessagesPanelProvider } from "@/common/components/messages/providers/MessagesPanelProvider";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { EstimatedItemsProvider } from "../project/providers/EstimatedItemsProvider";
import { ReleaseActionsProvider } from "../release/providers/ReleaseActionsProvider";
import { ReceiveReleaseDialog } from "../releases/pages/deliveries/components/receive-release-dialog/ReceiveReleaseDialog";
import { BuyoutReleasesFooter } from "./components/footer/BuyoutReleasesFooter";
import { ReleasesHeader } from "./components/header/ReleasesHeader";
import { ReleasesList } from "./components/releases-list/ReleasesList";
import {
  BuyoutReleasesProvider,
  useBuyoutReleases,
} from "./providers/BuyoutReleasesProvider";

const Container = tw.div`relative`;

const BuyoutReleasesWithProvider: FC = () => {
  const { buyout, releases, loading } = useBuyoutReleases();

  if (!buyout || loading) {
    return <Loader loading />;
  }

  return (
    <MessagesPanelProvider projectId={buyout.project.id}>
      <Container>
        <ReleasesHeader buyout={buyout} />
        <ReleasesList releases={releases} />
        <BuyoutReleasesFooter buyout={buyout} />
        <ReceiveReleaseDialog />
      </Container>
    </MessagesPanelProvider>
  );
};

export const BuyoutReleases = () => (
  <EstimatedItemsProvider>
    <BuyoutReleasesProvider>
      <ReleaseActionsProvider>
        <BuyoutReleasesWithProvider />
      </ReleaseActionsProvider>
    </BuyoutReleasesProvider>
  </EstimatedItemsProvider>
);
