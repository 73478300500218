import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useOrderTypesQuery } from "@/generated/graphql";
import { useMemo, useState } from "react";

export const useOrderTypes = () => {
  const [includeArchived, setIncludeArchived] = useState(false);
  const { data, error, loading } = useOrderTypesQuery();
  useErrorEffect(error);

  const filteredOrderTypes = useMemo(() => {
    return [
      ...(data?.viewer?.org.releaseTypes.filter((type) => {
        return includeArchived || !type.archivedAt;
      }) ?? []),
    ];
  }, [data?.viewer?.org.releaseTypes, includeArchived]);

  const hasArchived = useMemo(() => {
    return data?.viewer?.org.releaseTypes.some((type) => type.archivedAt);
  }, [data?.viewer?.org.releaseTypes]);

  return {
    orderTypes: filteredOrderTypes,
    loading,
    includeArchived,
    setIncludeArchived,
    hasArchived,
  };
};
