import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import {
  DistributorBuyoutReleasesFieldsFragment,
  useDistributorBuyoutReleasesQuery,
} from "@/generated/graphql";
import { FC, createContext, useContext } from "react";
import { useParams } from "react-router-dom";

type ProviderContextType = {
  buyout: DistributorBuyoutReleasesFieldsFragment | null;
  loading: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  buyout: null,
  loading: false,
});

export const DistributorReleasesProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { id } = useParams();

  const { data, loading, error } = useDistributorBuyoutReleasesQuery({
    variables: { id: id || "" },
  });

  useErrorEffect(error);

  return (
    <ProviderContext.Provider value={{ buyout: data?.buyout || null, loading }}>
      {children}
    </ProviderContext.Provider>
  );
};

export const useDistributorReleases = (): ProviderContextType =>
  useContext(ProviderContext);
