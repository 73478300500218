import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CreateOrgCatalogSkuInput,
  useCreateOrgCatalogSkuMutation,
} from "@/generated/graphql";

export const useCreateNewOrgCatalogSku = () => {
  const [createOrgCatalogSkuMutation, { loading }] =
    useCreateOrgCatalogSkuMutation();

  const { setError } = useGlobalError();

  const createNewOrgCatalogSku = async (
    input: CreateOrgCatalogSkuInput,
  ): Promise<string> => {
    try {
      const { errors, data } = await createOrgCatalogSkuMutation({
        variables: {
          input,
        },
      });
      setError(errors);
      return data?.createOrgCatalogSku.id || "";
    } catch (errors) {
      setError(errors);
      return "";
    }
  };

  return {
    createNewOrgCatalogSku,
    loading,
  };
};
