import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { ProjectProjectsFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { BudgetContainer } from "../Projects.styles";

type DisplayProjectBudgetProps = {
  project: ProjectProjectsFieldsFragment;
};

export const DisplayProjectBudget: FC<DisplayProjectBudgetProps> = ({
  project,
}) => {
  return (
    <NotNullableRenderer value={project.budget}>
      <BudgetContainer>
        <ValueCurrency value={Number(project.budget)} />
      </BudgetContainer>
    </NotNullableRenderer>
  );
};
