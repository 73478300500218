import { ImageUploaderWithCropping } from "@/common/components/upload/ImageUploaderWithCropping";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useOrgProfile } from "../../hooks/useOrgProfile";
import { LogoImage } from "./LogoImage";

export const LogoUploader = () => {
  const { uploadImage, logoImageUrl } = useOrgProfile();
  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();

  const saveImage = useCallback(
    async (image: File | null) => {
      if (await uploadImage(image)) {
        setSuccessAlert(
          intl.$t({
            id: "UPLOAD_SUCCESS_ORG_LOGO",
          }),
        );
      }
    },
    [intl, setSuccessAlert, uploadImage],
  );

  return (
    <ImageUploaderWithCropping
      save={saveImage}
      uploadArea={
        <LogoImage
          logoImageUrl={logoImageUrl}
          removeImage={() => saveImage(null)}
        />
      }
    />
  );
};
