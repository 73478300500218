import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { FC } from "react";
import { useIntl } from "react-intl";
import { ImportMaterialsSelection } from "./ImportMaterialsSelection";

export const ImportMaterialsButton: FC = () => {
  const intl = useIntl();

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <PrimaryButton onClick={() => togglePanel(true)}>
          {intl.$t({ id: "IMPORT_LIST" })}
        </PrimaryButton>
      )}
      content={(togglePanel) => (
        <ImportMaterialsSelection onClose={() => togglePanel(false)} />
      )}
    />
  );
};
