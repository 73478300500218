import { SourceSystem } from "@/generated/graphql";
import { useMemo } from "react";

export const useEnabledSourceSystems = () => {
  const enabledSourceSystems = useMemo(() => {
    return [
      SourceSystem.Foundation,
      SourceSystem.Cmic,
      SourceSystem.Sage100,
      SourceSystem.Sage300,
      SourceSystem.Procore,
      SourceSystem.Vista,
      SourceSystem.QbOnline,
      SourceSystem.QbDesktop,
      SourceSystem.QbDesktopAlt,
    ];
  }, []);
  return { enabledSourceSystems };
};
