import { goToExternalUrl } from "@/common/utils/browserUtils";
import { routes } from "@/config/routes";
import { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useDownloadInvoice } from "./hooks/useDownloadInvoice";

export const InvoiceDownload = () => {
  const { downloadInvoiceMutation } = useDownloadInvoice();
  const { invoiceId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (invoiceId) {
      downloadInvoiceMutation({ variables: { input: { invoiceId } } }).then(
        (response) => {
          const url = response.data?.downloadInvoiceDocument;
          if (url) {
            goToExternalUrl(url);
            navigate(generatePath(routes.invoiceVerification, { invoiceId }));
          }
        },
      );
    }
  }, [downloadInvoiceMutation, invoiceId, navigate]);

  return null;
};
