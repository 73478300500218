import {
  isMasterSku,
  isOrgCatalogSku,
} from "@/common/components/material/utils";
import {
  ManufacturerFieldsFragment,
  OrgCatalogSku,
  OrgMaterialFieldsFragment,
  Sku,
} from "@/generated/graphql";
import { useMemo } from "react";

export const useManufacturerFromMaterial = (
  item: OrgMaterialFieldsFragment,
) => {
  const manufacturer = useMemo(() => {
    const isMaster = isMasterSku(item.material);
    const isOrgSku = isOrgCatalogSku(item.material);

    return isMaster
      ? (item.material as Sku).manufacturer
      : isOrgSku
        ? (item.material as OrgCatalogSku).defaultManufacturer
        : null;
  }, [item.material]);

  return manufacturer as ManufacturerFieldsFragment | null;
};
