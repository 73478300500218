import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  BuyoutDocument,
  BuyoutQuery,
  ReleaseDocument,
  ReleaseQuery,
  useUnlinkPoMutation,
} from "@/generated/graphql";

export const useUnlinkPo = () => {
  const [unlinkPoMutation] = useUnlinkPoMutation();
  const { setError } = useGlobalError();

  const unlinkPo = async (
    poLinkId?: string,
    releaseId?: string,
    buyoutId?: string,
  ) => {
    if (poLinkId) {
      try {
        const { data } = await unlinkPoMutation({
          variables: {
            poLinkId,
          },
          update: (cache, { data }) => {
            if (releaseId) {
              const rls = cache.readQuery<ReleaseQuery>({
                query: ReleaseDocument,
                variables: {
                  id: releaseId,
                },
              });
              if (rls?.release && data?.unlinkPO) {
                cache.writeQuery({
                  query: ReleaseDocument,
                  variables: {
                    id: releaseId,
                  },
                  data: {
                    release: {
                      ...rls.release,
                      poLink: null,
                    },
                  },
                });
              }
            }
            if (buyoutId) {
              const buyout = cache.readQuery<BuyoutQuery>({
                query: BuyoutDocument,
                variables: {
                  id: buyoutId,
                },
              });
              if (buyout?.buyout && data?.unlinkPO) {
                cache.writeQuery({
                  query: BuyoutDocument,
                  variables: {
                    id: buyoutId,
                  },
                  data: {
                    buyout: {
                      ...buyout.buyout,
                      poLink: null,
                    },
                  },
                });
              }
            }
          },
        });
        return !!data?.unlinkPO;
      } catch (error) {
        setError(error);
        return false;
      }
    }
  };

  return { unlinkPo };
};
