import {
  DistributorQuoteCommonFieldsFragment,
  DistributorQuoteFieldsFragment,
  DistributorQuoteItemFieldsFragment,
  QuoteStatus,
} from "@/generated/graphql";

export const genericError = "generic-error";

export const checkQuoteStatus = (
  quote:
    | DistributorQuoteItemFieldsFragment
    | DistributorQuoteFieldsFragment
    | DistributorQuoteCommonFieldsFragment
    | { status: QuoteStatus }
    | null
    | undefined,
  statuses: QuoteStatus[],
) => {
  if (!quote) {
    return false;
  }

  return statuses.includes(quote.status);
};
