import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Timeout } from "react-number-format/types/types";

const CHECK_INTERVAL = 60000;

const getVersion = (src: string) => {
  return src.replace(/.*\/assets\/index-(.+).js.*/, "$1");
};

export const useVersionInformation = () => {
  const version = useRef<string | null>();
  const [isOutdateVersion, setOutdatedVersion] = useState(false);
  const timer = useRef<Timeout | undefined>();

  const checkVersion = useCallback(async () => {
    try {
      const data = await fetch(window.location.origin);
      const text = await data.text();
      const newVersion = getVersion(text.replaceAll(/\s+/g, ""));
      if (newVersion && newVersion !== version.current && !isOutdateVersion) {
        setOutdatedVersion((old) => !old);
      }
    } catch {
      // Ignore it and we will check on the next cycle
    }
  }, [isOutdateVersion, version]);

  const initiateCheckVersion = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setInterval(async () => {
      await checkVersion();
    }, CHECK_INTERVAL);
  }, [checkVersion]);

  useEffect(() => {
    const src = Array.from(document.head.querySelectorAll("script"))?.find(
      (script) => script.src.includes("index-"),
    )?.src;
    if (src) {
      const versionFromHead = getVersion(src) || null;
      version.current = versionFromHead;
      if (versionFromHead) {
        Sentry.setExtras({ version: versionFromHead });
      }
      initiateCheckVersion();
    }
  }, [initiateCheckVersion]);

  useEffect(() => {
    return () => timer.current && clearInterval(timer.current);
  }, [initiateCheckVersion]);

  return { isOutdateVersion };
};
