import {
  BreadcrumbItem,
  Breadcrumbs,
} from "@/common/components/breadcrumbs/Breadcrumbs";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { ListHeaderFilterButton } from "@/common/components/list-header-filter-button/ListHeaderFilterButton";
import { QuestionBox } from "@/common/components/messages/Messages.styles";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { VIEW_STATE, useTableView } from "@/common/providers/TableViewProvider";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { routes } from "@/config/routes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  IntegrationType,
  MessageContextKind,
  ReleaseStatus,
  SourceSystem,
} from "@/generated/graphql";
import { MenuItem, Select } from "@mui/material";
import { FC, ReactElement, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";
import { ReleaseSequenceStepper } from "../../releases/pages/deliveries/components/ReleaseSequenceStepper";
import { useReleaseSequence } from "../../releases/pages/deliveries/providers/ReleaseSequenceProvider";
import { useAddDeliverySlipStore } from "../../releases/pages/delivery-slips/store/useAddDeliverySlipStore";
import { ReleasePrintButton } from "../pages/release-details/ReleasePrintButton";
import { ReleaseUserDetails } from "../pages/specify-details/footer-actions/ReleaseUserDetails";
import { useSyncReleaseItems } from "../pages/specify-details/hooks/useSyncReleaseItems";
import { useReleaseActions } from "../providers/ReleaseActionsProvider";
import { useRelease } from "../providers/ReleaseProvider";
import { ReleaseItemListFilters } from "../release-items-list/components/ReleaseItemListFilters";
import { useReleaseStore } from "../store/useReleaseStore";
import { ReleaseMessagesButton } from "./ReleaseMessagesButton";
import { ConnectFoundationReleasePOButton } from "./connections/components/buttons/ConnectFoundationReleasePoButton";
import { ConnectReleasePOButton } from "./connections/components/buttons/ConnectReleasePoButton";
import { EditReleasePOButton } from "./connections/components/buttons/EditReleasePoButton";
import { ExportReleaseButton } from "./connections/components/buttons/ExportReleaseButton";
const StyledSelect = tw(
  Select,
)`[&>div]:p-0 [&>div]:pr-7 [&_fieldset]:border-none [&_svg]:right-0
`;

const CustomBreadcrumbItem = tw.div`
  grid grid-flow-col gap-2 text-lg truncate
`;

const LinkLikeStyled = tw(LinkLike)`
  text-base
`;

const Text = tw.div`text-base flex gap-1 items-center`;

type Props = {
  sub?: string;
  shoppingIcon?: ReactElement;
};

export const ReleaseBreadcrumbs: FC<Props> = ({ sub, shoppingIcon }) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { release } = useRelease();
  const { connectedIntegrationType, connectedSourceSystem } = useOrgSettings();
  const { scheduleRelease } = useReleaseActions();
  const { sequenceActive } = useReleaseSequence();
  const { tableView } = useTableView();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { setAddSlipVisible, setSlipRelease } = useAddDeliverySlipStore();
  const { hasChanges } = useReleaseStore();
  const { syncReleaseItems } = useSyncReleaseItems();

  const breadcrumbs = useMemo(() => {
    const items = [
      {
        text: intl.$t({ id: "DELIVERIES" }),
        link: routes.deliveries,
        id: "deliveries",
      },
    ] as BreadcrumbItem[];

    if (release) {
      const releaseNumberTextId = !release.sequenceNumber
        ? release.buyout
          ? "DRAFT_FROM_BUYOUT"
          : "RELEASE_STATUS_DRAFT"
        : release.buyout
          ? "ORDER_NUMBER_WITH_BUYOUT"
          : "ORDER_NUMBER_WITHOUT_BUYOUT";
      items.push({
        text: "",
        id: "buyout",
        custom: (
          <CustomBreadcrumbItem data-testid="release-with-number">
            <FormattedMessage
              id={releaseNumberTextId}
              values={{
                sequenceNumber: release.sequenceNumber,
                link: (...chunks) => {
                  if (!sub) {
                    return chunks;
                  }
                  return (
                    <LinkLikeStyled
                      to={generatePath(routes.delivery, {
                        deliveryId: release.id,
                      })}
                    >
                      {chunks}
                    </LinkLikeStyled>
                  );
                },
              }}
              tagName={Text}
            />
            {release.buyout ? (
              <LinkLikeStyled
                to={generatePath(routes.buyout, {
                  id: release?.buyout?.id,
                })}
              >
                #{release.buyout?.clientIdentifier}
              </LinkLikeStyled>
            ) : null}
          </CustomBreadcrumbItem>
        ),
      });
    }

    if (sub) {
      items.push({
        text: intl.$t({ id: sub }),
        id: `sub-${sub}`,
      });
    }
    return items;
  }, [release, intl, sub]);

  const releaseExportButtons = useMemo(() => {
    const buttons = [];

    const isIncluded = !checkReleaseStatus(release, [
      ReleaseStatus.Canceled,
      ReleaseStatus.AwaitingApproval,
      ReleaseStatus.Rejected,
    ]);

    if (
      release &&
      release?.sellerOrgLocation &&
      isIncluded &&
      (includePoNumbering || release.poLink)
    ) {
      if (release.buyout?.poLink) {
        return [
          <Tooltip
            key="buyout-po-already-exported"
            id="buyout-po-already-exported"
            element={
              <OutlinedButton $small disabled>
                <FormattedMessage id="CONNECT_PO" />
              </OutlinedButton>
            }
          >
            <FormattedMessage id="BUYOUT_PO_ALREADY_EXPORTED" />
          </Tooltip>,
        ];
      }

      if (
        connectedIntegrationType === IntegrationType.Foundation &&
        connectedSourceSystem === SourceSystem.Foundation &&
        !release.poLink?.syncedAt
      ) {
        buttons.push(
          <ConnectFoundationReleasePOButton
            key="connect-foundation-po-button"
            release={release}
          />,
        );
      }

      if (
        connectedIntegrationType &&
        !connectedSourceSystem &&
        !(
          connectedIntegrationType === IntegrationType.Foundation &&
          connectedSourceSystem === SourceSystem.Foundation &&
          !release.poLink?.syncedAt
        )
      ) {
        buttons.push(<ConnectFoundationReleasePOButton release={release} />);
      }

      if (
        connectedIntegrationType &&
        !connectedSourceSystem &&
        !(
          connectedIntegrationType === IntegrationType.Foundation &&
          connectedSourceSystem === SourceSystem.Foundation &&
          !release.poLink?.syncedAt
        )
      ) {
        buttons.push(
          <ExportReleaseButton release={release} key="export-release" />,
        );
      }

      if (
        connectedSourceSystem &&
        !(
          connectedIntegrationType === IntegrationType.Foundation &&
          connectedSourceSystem === SourceSystem.Foundation &&
          !release.poLink?.syncedAt
        )
      ) {
        if (!release.poLink) {
          buttons.push(
            <ConnectReleasePOButton
              release={release}
              key="connect-po"
              sourceSystem={connectedSourceSystem}
            />,
          );
        } else {
          buttons.push(
            <EditReleasePOButton
              release={release}
              key="edit-po"
              sourceSystem={connectedSourceSystem}
            />,
          );
        }
      }
    }

    return buttons;
  }, [
    release,
    includePoNumbering,
    connectedIntegrationType,
    connectedSourceSystem,
  ]);

  const actions = useMemo(() => {
    const bActions = [];

    if (release) {
      if (!sequenceActive) {
        bActions.push(
          <ReleaseUserDetails release={release} key="release-user-details" />,
        );
      }

      if (
        release.status !== ReleaseStatus.Draft &&
        tableView === VIEW_STATE.normal
      ) {
        bActions.push(
          <ListHeaderFilterButton
            key="filters"
            filter={<ReleaseItemListFilters />}
            classes={{
              button: "hover:border-white flex-row-reverse px-0 py-0",
              buttonText: "text-xs",
            }}
            hideLabel={sequenceActive}
          />,
        );
      }

      bActions.push(
        <ReleasePrintButton release={release} key="print-release" />,
      );

      bActions.push(
        <ReleaseMessagesButton
          key="messages"
          release={release}
          text={
            <QuestionBox>
              <Tooltip
                id="release-messages"
                element={
                  <MessageButton
                    id={release.id}
                    kind={MessageContextKind.Release}
                  />
                }
              >
                <FormattedMessage id="MESSENGER" />
              </Tooltip>
            </QuestionBox>
          }
        />,
      );

      if (release?.sellerOrgLocation) {
        bActions.push(...releaseExportButtons);
        if (sequenceActive) {
          bActions.push(<ReleaseSequenceStepper />);
        }
      }
    }
    if (shoppingIcon) {
      bActions.push(shoppingIcon);
    }
    return bActions;
  }, [release, shoppingIcon, tableView, sequenceActive, releaseExportButtons]);

  const appendItems = useMemo(() => {
    if (
      release?.status === ReleaseStatus.Requested ||
      release?.status === ReleaseStatus.Scheduled
    ) {
      return [
        <StyledSelect
          id="release-status-select"
          key="status-select"
          value={release?.status || ReleaseStatus.Draft}
          onChange={async (e) => {
            if (hasChanges) {
              openDialog({
                cancelButtonText: intl.$t({ id: "CONTINUE_EDITING" }),
                confirmButtonText: intl.$t({ id: "SAVE_CHANGES" }),
                icon: <WarningIcon />,
                title: intl.$t({ id: "UNSAVED_CHANGES_PRINT_TITLE" }),
                text: intl.$t(
                  { id: "RELEASE_STATE_CHANGE_UNSAVED_CHANGES_DESCRIPTION" },
                  {
                    nextReleaseState: intl.$t({
                      id: `RELEASE_STATUS_${e.target.value}`,
                    }),
                  },
                ),
                closeOnConfirm: true,
                handleConfirm: async () => {
                  await syncReleaseItems();
                  if (
                    e.target.value === ReleaseStatus.Received ||
                    e.target.value === ReleaseStatus.PartiallyReceived
                  ) {
                    setSlipRelease(release);
                    setAddSlipVisible(true);
                  } else if (
                    release?.status === ReleaseStatus.Requested &&
                    e.target.value === ReleaseStatus.Scheduled
                  ) {
                    await scheduleRelease({
                      releaseId: release.id,
                      version: release.version,
                    });
                  }
                },
              });
            } else {
              if (
                e.target.value === ReleaseStatus.Received ||
                e.target.value === ReleaseStatus.PartiallyReceived
              ) {
                setSlipRelease(release);
                setAddSlipVisible(true);
              } else if (
                release?.status === ReleaseStatus.Requested &&
                e.target.value === ReleaseStatus.Scheduled
              ) {
                await scheduleRelease({
                  releaseId: release.id,
                  version: release.version,
                });
              }
            }
          }}
        >
          {Object.entries(ReleaseStatus)
            .filter(
              ([, opt]) =>
                (release.status === ReleaseStatus.Requested
                  ? opt === ReleaseStatus.Requested
                  : false) ||
                opt === ReleaseStatus.Scheduled ||
                opt === ReleaseStatus.Received ||
                opt === ReleaseStatus.PartiallyReceived,
            )
            .map(([, opt]) => {
              return (
                <MenuItem key={opt} value={opt}>
                  <ReleaseStatusChip
                    status={opt}
                    type="breadcrumb"
                    releaseType={release.type}
                  />
                </MenuItem>
              );
            })}
        </StyledSelect>,
      ];
    } else {
      return release
        ? [
            <ReleaseStatusChip
              key="status"
              status={release.status}
              type="breadcrumb"
              releaseType={release.type}
            />,
          ]
        : [];
    }
  }, [
    release,
    hasChanges,
    intl,
    syncReleaseItems,
    setSlipRelease,
    setAddSlipVisible,
    scheduleRelease,
    openDialog,
  ]);

  return (
    <Breadcrumbs
      classes={{ text: "flex items-center" }}
      items={breadcrumbs}
      appendItems={appendItems}
      actions={actions}
    />
  );
};
