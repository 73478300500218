import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { SearchableList } from "@/common/components/searchable-list/SearchableList";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { FC } from "react";
import { useIntl } from "react-intl";
import {
  AgaveExternalVendorsProvider,
  useAgaveExternalVendorsProvider,
} from "../../../providers/AgaveExternalVendorsProvider";
import { useExternalVendorMapping } from "../../../providers/ExternalVendorMappingProvider";
import { useExternalVendorsTableConfiguration } from "./ExternalVendorsTable.configuration";

const ExternalVendorsTableWithProviders = () => {
  const intl = useIntl();
  const { selectedExternalVendor, setSelectedExternalVendor } =
    useExternalVendorMapping();
  const { externalVendors, loading, totalCount, filter, setFilter } =
    useAgaveExternalVendorsProvider();
  const { connectedSourceSystem } = useOrgSettings();
  const configuration = useExternalVendorsTableConfiguration(
    selectedExternalVendor ? [selectedExternalVendor] : [],
  );

  return (
    <SearchableList
      tableConfiguration={configuration}
      items={externalVendors}
      loadingItems={loading}
      totalCount={totalCount}
      searchBarTitle={intl.$t(
        { id: "SELECT_VENDOR_TO_CONNECT" },
        {
          sourceSystem: connectedSourceSystem
            ? intl.$t({
                id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
              })
            : "",
        },
      )}
      itemSelection={{
        type: "single",
        selectedItemId: selectedExternalVendor,
        setSelectedItemId: setSelectedExternalVendor,
      }}
      search={{
        searchText: filter?.search ?? "",
        setSearchText: (search) => setFilter({ search }),
      }}
    />
  );
};

export const ExternalVendorsTable: FC = () => {
  const { sourceSystem } = useExternalVendorMapping();
  return (
    <PaginationProvider>
      <AgaveExternalVendorsProvider sourceSystem={sourceSystem}>
        <ExternalVendorsTableWithProviders />
      </AgaveExternalVendorsProvider>
    </PaginationProvider>
  );
};
