import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { ChargesMode } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const AdditionalChargesPicker = () => {
  const intl = useIntl();
  const options = useMemo(
    () =>
      [ChargesMode.Blended, ChargesMode.Majority, ChargesMode.Separate].map(
        (mode) => ({
          value: mode,
          label: intl.$t({ id: `CHARGES_${mode}` }),
        }),
      ),
    [intl],
  );

  return (
    <SelectControlled
      name="chargesMode"
      getLabel={(option) => option.label}
      getValue={(option) => option.value}
      options={options}
      placeholder={intl.$t({ id: "ADDITIONAL_CHARGES" })}
      rules={{ required: true }}
    />
  );
};
