import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { routes } from "@/config/routes";
import { BuyoutsBuyoutFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";

type DeliveredVsTotalProps = {
  buyout: BuyoutsBuyoutFieldsFragment;
};

export const DeliveriesButton: FC<DeliveredVsTotalProps> = ({ buyout }) => {
  const navigate = useNavigate();

  const gotoDeliveries = useCallback(() => {
    navigate(generatePath(routes.buyoutDeliveries, { id: buyout.id }));
  }, [buyout.id, navigate]);

  const releaseCount = useMemo(
    () => buyout.releases.filter((r) => !r.deletedAt).length,
    [buyout.releases],
  );

  return (
    <NotNullableRenderer value={releaseCount}>
      <OutlinedButton onClick={gotoDeliveries}>{releaseCount}</OutlinedButton>
    </NotNullableRenderer>
  );
};
