import { FC, createContext, useContext, useEffect, useState } from "react";
import { LOCAL_STORAGE_KEYS } from "../const";
import { useLocalStorage } from "../hooks/useLocalStorage";

export enum VIEW_STATE {
  "normal" = "normal",
  "spreadsheet" = "spreadsheet",
  "materials" = "materials",
  "vendors" = "vendors",
}

type ProviderContextType = {
  tableView: VIEW_STATE | undefined;
  setTableView: (view: VIEW_STATE) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  tableView: undefined,
  setTableView: () => {},
});

export const TableViewProvider: FC<{
  children: React.ReactNode;
  defaultView?: VIEW_STATE;
  saveSelection?: {
    id: string;
  };
}> = ({ children, defaultView = VIEW_STATE.normal, saveSelection }) => {
  const { readValue, setValue } = useLocalStorage();
  const [tableView, setTableView] = useState<VIEW_STATE>(() => {
    if (saveSelection) {
      const value = readValue(
        `${LOCAL_STORAGE_KEYS.LIST_VIEW}_${saveSelection.id}`,
        defaultView,
      );
      if (value) {
        const key = value as keyof typeof VIEW_STATE;
        if (VIEW_STATE[key]) {
          return VIEW_STATE[key];
        }
      }
    }
    return defaultView;
  });

  useEffect(() => {
    if (saveSelection) {
      setValue(
        `${LOCAL_STORAGE_KEYS.LIST_VIEW}_${saveSelection.id}`,
        tableView,
      );
    }
  }, [saveSelection, setValue, tableView]);

  return (
    <ProviderContext.Provider
      value={{
        tableView,
        setTableView,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useTableView = (): ProviderContextType =>
  useContext(ProviderContext);
