import { create } from "zustand";
import { devtools } from "zustand/middleware";

type ReceiptCreateReleaseHeaderState = {
  projectId?: string;
  vendorId?: string;
  setProjectId: (projectId?: string) => void;
  setVendorId: (vendorId?: string) => void;
};

export const useReceiptCreateReleaseHeader =
  create<ReceiptCreateReleaseHeaderState>()(
    devtools((set) => ({
      setProjectId: (projectId?: string) =>
        set(() => ({
          projectId,
        })),
      setVendorId: (vendorId?: string) =>
        set(() => ({
          vendorId,
        })),
    })),
  );
