import { useState } from "react";

export const useLoadingAction = () => {
  const [loading, setLoading] = useState(false);

  const asyncAction = async (action?: () => Promise<void> | void) => {
    setLoading(true);
    if (action) {
      await action();
    }
    setLoading(false);
  };

  return {
    loading,
    asyncAction,
  };
};
