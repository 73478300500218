import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import {
  WizardModal,
  WizardModalMode,
  WizardModalPage,
} from "@/common/components/wizard-modal/WizardModal";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { MaterialSummaryFields } from "@/contractor/pages/home/rfq/components/add-org-sku/AddOrgMaterial";
import { TaxMode } from "@/generated/graphql";
import { FC, useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { SelectOrgMaterial } from "../list/SelectOrgMaterial";
import { SelectableCostCodeList } from "../list/SelectableCostCodeList";
import { SelectableCostTypeList } from "../list/SelectableCostTypeList";

type Props = {
  setOpened: (opened: boolean) => void;
  opened: boolean;
  costCodeId: string | null;
  costTypeId: string | null;
  orgMaterialId: string | null;
  taxMode: TaxMode | null;
};

export const SalesTaxModal: FC<Props> = ({
  opened,
  setOpened,
  taxMode,
  costCodeId: defaultCostCodeId,
  costTypeId: defaultCostTypeId,
  orgMaterialId: defaultOrgMaterialId,
}) => {
  const [costCodeId, setCostCodeId] = useState<string | null>(
    defaultCostCodeId,
  );
  const [costTypeId, setCostTypeId] = useState<string | null>(
    defaultCostTypeId,
  );
  const [orgMaterialId, setOrgMaterialId] = useState<string | null | undefined>(
    defaultOrgMaterialId,
  );
  const { materialsMap } = useMaterials();
  const intl = useIntl();
  const { moveToNextStep, moveToPreviousStep, setStep } = useNestedStepper();
  const { setValue } = useFormContext();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const onClose = useCallback(() => {
    setStep(0);
    setOpened(false);
  }, [setOpened, setStep]);

  const saveCostCode = useCallback(() => {
    if (
      !hasFeatureInConnectedSourceSystem(IntegrationFeature.IncludeCostTypes) &&
      !hasFeatureInConnectedSourceSystem(
        IntegrationFeature.IncludeOrderTypeSalesTaxOrgMaterial,
      )
    ) {
      setValue("salesTaxCostCodeId", costCodeId);
      onClose();
      return;
    }

    moveToNextStep();
  }, [
    costCodeId,
    hasFeatureInConnectedSourceSystem,
    moveToNextStep,
    onClose,
    setValue,
  ]);

  const save = useCallback(() => {
    setValue("salesTaxCostCodeId", costCodeId);
    setValue("salesTaxCostTypeId", costTypeId);
    setValue("salesTaxOrgMaterialId", orgMaterialId);
    onClose();
  }, [costCodeId, costTypeId, onClose, orgMaterialId, setValue]);

  const pages: WizardModalPage[] = useMemo(() => {
    const pagesList: WizardModalPage[] = [];
    if (taxMode === TaxMode.Separate) {
      pagesList.push({
        title: intl.$t({ id: "SELECT_COST_CODE_HEADER" }),
        pages: [
          {
            component: (
              <SelectableCostCodeList
                setSelectedCostCodeId={setCostCodeId}
                selectedCostCodeId={costCodeId}
              />
            ),
            hideHeader: true,
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: onClose,
                text: intl.$t({ id: "CLOSE" }),
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                onClick: saveCostCode,
                text: intl.$t({
                  id:
                    hasFeatureInConnectedSourceSystem(
                      IntegrationFeature.IncludeCostTypes,
                    ) ||
                    hasFeatureInConnectedSourceSystem(
                      IntegrationFeature.IncludeOrderTypeSalesTaxOrgMaterial,
                    )
                      ? "NEXT"
                      : "SAVE",
                }),
                className: "flex-1",
              },
            ],
          },
        ],
      });
    }

    if (
      hasFeatureInConnectedSourceSystem(IntegrationFeature.IncludeCostTypes)
    ) {
      pagesList.push({
        title: intl.$t({ id: "SELECT_COST_TYPE_HEADER" }),
        pages: [
          {
            component: (
              <SelectableCostTypeList
                setSelectedCostTypeId={setCostTypeId}
                selectedCostTypeId={costTypeId}
              />
            ),
            hideHeader: true,
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: moveToPreviousStep,
                text: intl.$t({ id: "BACK" }),
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                onClick: save,
                text: intl.$t({ id: "SAVE" }),
                className: "flex-1",
              },
            ],
          },
        ],
      });
    }

    if (
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.IncludeOrderTypeSalesTaxOrgMaterial,
      )
    ) {
      pagesList.push({
        title: intl.$t({ id: "SELECT_ITEM" }),
        pages: [
          {
            component: (
              <SelectOrgMaterial
                setSelectedOrgMaterial={(
                  orgMaterial: MaterialSummaryFields | null | undefined,
                ) => setOrgMaterialId(orgMaterial?.id)}
                selectedOrgMaterial={
                  orgMaterialId ? materialsMap.get(orgMaterialId) : null
                }
              />
            ),
            hideHeader: true,
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: moveToPreviousStep,
                text: intl.$t({ id: "BACK" }),
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                onClick: save,
                text: intl.$t({ id: "SAVE" }),
                className: "flex-1",
                disabled: !orgMaterialId,
              },
            ],
          },
        ],
      });
    }
    return pagesList;
  }, [
    costCodeId,
    costTypeId,
    hasFeatureInConnectedSourceSystem,
    intl,
    materialsMap,
    moveToPreviousStep,
    onClose,
    orgMaterialId,
    save,
    saveCostCode,
    taxMode,
  ]);

  return (
    <WizardModal
      mode={WizardModalMode.MULTIPLE_PAGES}
      opened={opened}
      pages={pages}
      onClose={onClose}
    />
  );
};
