import { gql } from "@apollo/client";
import { ASSET_FIELDS } from "./assetFragment";
import { RELEASE_ISSUE_RESOLUTION_FIELDS } from "./releaseIssueResolutionFragment";

export const RELEASE_ISSUES_FIELDS = gql`
  ${RELEASE_ISSUE_RESOLUTION_FIELDS}
  ${ASSET_FIELDS}
  fragment ReleaseIssuesFields on ReleaseIssue {
    id
    releaseItem {
      id
    }
    issueType
    description
    photos {
      ...AssetFields
    }
    quantityDecimal
    resolution {
      ...ReleaseIssueResolutionFields
    }
  }
`;
