import { useUser } from "@/common/providers/UserProvider";
import { EnterpriseRole, OrgRole } from "@/generated/graphql";
import { useMemo } from "react";

type Roles = {
  roles?: OrgRole[];
  enterpriseRoles?: EnterpriseRole[];
};

export const useRoles = ({ roles, enterpriseRoles }: Roles) => {
  const { viewer } = useUser();
  const hasRoles = useMemo(
    () => viewer?.orgRoles?.some((o) => roles?.some((p) => o === p)),
    [roles, viewer?.orgRoles],
  );

  const hasEnterpriseRoles = useMemo(
    () =>
      viewer?.enterpriseRoles?.some((o) =>
        enterpriseRoles?.some((p) => o === p),
      ),
    [enterpriseRoles, viewer?.enterpriseRoles],
  );

  return { hasRoles, hasEnterpriseRoles };
};
