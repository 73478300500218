import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { Loader } from "@/common/components/loader/Loader";
import { NoResultsWithOptions } from "@/common/components/no-results-with-options/NoResultsWithOptions";
import {
  ProjectProvider,
  useProject,
} from "@/contractor/pages/home/project/providers/ProjectProvider";
import {
  BuyoutFieldsFragment,
  EstimatedItemFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { EstimatedItemsProvider } from "../../../../project/providers/EstimatedItemsProvider";
import { ProjectItemsZonesProvider } from "../../../../project/providers/ProjectItemsZonesProvider";
import { AddEstimatedItemListItems } from "./AddEstimatedItemListItems";
import { BuyoutInfoWithActions } from "./BuyoutInfoWithActions";

type Props = {
  buyout: BuyoutFieldsFragment;
  loading?: boolean;
  columns: Array<
    GridCol<ProjectItemFieldsFragment, EstimatedItemFieldsFragment>
  >;
};

const ProjectItemsWithProvider: FC<Props> = ({ loading, columns }) => {
  const { project } = useProject();
  const condition = useMemo(
    () => (project?.items.length || 0) > 0,
    [project?.items.length],
  );
  if (!project) {
    return <Loader loading />;
  }

  return (
    <ProjectItemsZonesProvider project={project} defaultGroupedByZones={false}>
      <BuyoutInfoWithActions project={project} />
      <ListRenderer
        hasItemsCondition={condition}
        emptyList={
          <NoResultsWithOptions options={["PROJECT_EMPTY_PROJECT_LIST"]} />
        }
      >
        <AddEstimatedItemListItems columns={columns} loading={loading} />
      </ListRenderer>
    </ProjectItemsZonesProvider>
  );
};

export const ProjectItems: FC<
  Pick<Props, "columns"> & { buyout: BuyoutFieldsFragment; loading?: boolean }
> = ({ buyout, columns, loading }) => {
  return (
    <EstimatedItemsProvider>
      <ProjectProvider id={buyout.project.id}>
        <ProjectItemsWithProvider
          buyout={buyout}
          columns={columns}
          loading={loading}
        />
      </ProjectProvider>
    </EstimatedItemsProvider>
  );
};
