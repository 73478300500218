import { ManufacturerPicker } from "@/common/components/manufacturer-picker/ManufacturerPicker";
import { getManufacturerIdFromPicker } from "@/common/components/manufacturer-picker/getManufacturerIdFromPicker";
import { isMasterSku } from "@/common/components/material/utils";
import { useEstimatedItems } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import { MasterSkusByKeywordSkuFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import {
  ExpandedReleaseItem,
  useRelease,
} from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";

type Props = {
  item: ExpandedReleaseItem;
  error?: boolean;
  index?: number;
};

export const ReleaseItemManufacturerSelector: FC<Props> = ({
  item,
  index,
  error,
}) => {
  const { updateRelease } = useReleaseUpdate();
  const { release } = useRelease();
  const { setNewProjectEstimatedItem } = useEstimatedItems();

  const saveManufacturer = useCallback(
    async (value: string | null) => {
      const manufacturerId = getManufacturerIdFromPicker(value);
      if (item.id) {
        if (release) {
          await updateRelease({
            releaseId: release?.id,
            version: release?.version,
            updates: [
              {
                releaseItemId: item.id,
                manufacturerId,
                clearManufacturer: manufacturerId ? undefined : true,
              },
            ],
          });
        }
      } else {
        setNewProjectEstimatedItem([
          {
            key: "manufacturerId",
            value: manufacturerId,
          },
        ]);
      }
    },
    [item.id, updateRelease, release, setNewProjectEstimatedItem],
  );

  const manufacturer = useMemo(() => {
    const isMaster = isMasterSku(item.projectItem?.material.material);
    return (
      item.manufacturer ||
      (isMaster
        ? (
            item.projectItem?.material
              .material as MasterSkusByKeywordSkuFieldsFragment
          ).manufacturer
        : null)
    );
  }, [item]);

  return (
    <ManufacturerPicker
      item={manufacturer}
      saveManufacturer={saveManufacturer}
      error={error}
      readonly={
        isMasterSku(item.projectItem?.material.material) || !!item.buyoutItem
      }
      index={index}
      canClearManufacturer
    />
  );
};
