import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { ConnectionStatusAdornment } from "@/common/components/connection-status-adornment/ConnectionStatusAdornment";
import { If } from "@/common/components/if/If";
import { OrderTypePickerControlled } from "@/common/components/order-type-picker/OrderTypePickerControlled";
import { DatePickerControlled } from "@/common/components/picker/components/DatePickerControlled";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import {
  vendorLabelFormatter,
  VendorPickerCustomRender,
} from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useAddSlipItems } from "@/common/hooks/add-missing-items-to-order/useAddSlipItems";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { AddItemsToReleaseFromBuyoutDialog } from "@/contractor/pages/home/release/pages/add-items-to-release/AddItemsToReleaseFromBuyoutDialog";
import { SelectableItem } from "@/contractor/pages/home/release/pages/add-items-to-release/AddItemsToReleaseProvider";
import { AssignBuyoutButton } from "@/contractor/pages/home/release/pages/specify-details/assign-buyout/AssignBuyoutButton";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { OrgMaterialFieldsFragment, ReleaseStatus } from "@/generated/graphql";
import { SearchOutlined } from "@mui/icons-material";
import { FC, useCallback, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDeliverySlipImportExternalPO } from "../../../../providers/DeliverySlipImportExternalPOProvider";
import { useDeliverySlipRelease } from "../../../../providers/DeliverySlipReleaseProvider";
import {
  PackingSlipReceiveViewState,
  useDeliverySlipVerification,
} from "../../../../providers/DeliverySlipVerificationProvider";

const Header = tw.div`grid items-center mb-5 w-full`;
const HeaderTop = tw.div`grid grid-cols-2 gap-2 w-full justify-between items-center mb-4`;
const Container = tw.div`w-full grid grid-cols-3 gap-2 items-center z-20 p-4 -top-5 bg-blue-100 rounded-2xl drop-shadow-md`;
const SearchIcon = tw(
  SearchOutlined,
)`text-blue-500 cursor-pointer focus:text-black hover:text-black -mr-1`;
const MenuButton = tw(
  PrimaryButton,
)`w-6 h-6 min-w-6 max-h-6 rounded-xl cursor-pointer h-full items-center justify-center mr-2`;
const Row = tw.div`grid grid-flow-col justify-start items-center gap-1 w-full`;
const ChevronLeftStyled = tw(FaChevronLeft)`text-base mt-0.5`;
const Title = tw.div`text-base font-medium ml-1 mt-0.5`;
const ButtonGroup = tw.div`grid grid-flow-col w-fit gap-2 justify-self-end pr-2`;

export const DeliverySlipEditReleaseHeader: FC = () => {
  const { release } = useRelease();
  const { buyout } = useContractorBuyout();
  const { openModal } = useDeliverySlipImportExternalPO();
  const intl = useIntl();
  const { deliverySlip, setPackingSlipReceiveViewState } =
    useDeliverySlipVerification();
  const { connectedSourceSystem } = useOrgSettings();
  const { projects, loading: loadingProjects } = useProjectListOptions();
  const { vendors, loading: loadingVendors } = useVendors();
  const { updateReleaseForm: form } = useDeliverySlipRelease();
  const { loadingExternalPo, externalPo, importedItems, externalPOsQueryForm } =
    useDeliverySlipImportExternalPO();
  const { handsonInstance } = useColumnMapper();
  const { appendRows, getFormattedMaterialName } = useTableHelpers();
  const [addFromBuyoutDialog, setAddFromBuyoutDialog] =
    useState<boolean>(false);
  const { materials, loading: loadingMaterials } = useMaterials();
  const { addSlipItems } = useAddSlipItems();
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (
      deliverySlip?.release?.itemCount === 0 &&
      deliverySlip.release.status === ReleaseStatus.Draft &&
      !initialized &&
      handsonInstance &&
      !loadingMaterials
    ) {
      addSlipItems();
      setInitialized(true);
    }
  }, [
    deliverySlip?.release?.itemCount,
    deliverySlip?.release?.status,
    deliverySlip,
    initialized,
    handsonInstance,
    addSlipItems,
    loadingMaterials,
  ]);

  const poNumber = form?.watch("poNumber");

  const onPoNumberSearchIconClick = useCallback(() => {
    if (!externalPo && poNumber) {
      externalPOsQueryForm?.setValue("search", poNumber);
    }
    openModal({ skipPoList: !!externalPo });
  }, [externalPOsQueryForm, externalPo, openModal, poNumber]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setPackingSlipReceiveViewState(PackingSlipReceiveViewState.DEFAULT);
    },
    [setPackingSlipReceiveViewState],
  );

  const addBuyoutItems = useCallback(
    (items: SelectableItem[]) => {
      const newItems =
        buyout?.items
          .filter((item) => items.some((i) => i.itemId === item.id))
          .map((item) => {
            const material = materials.find(
              (m: OrgMaterialFieldsFragment) =>
                m.id === item.projectItem.material.id,
            );
            return {
              [COLUMN_TYPE.Material]: material
                ? getFormattedMaterialName(material)
                : (item.description ?? ""),
              [COLUMN_TYPE.UOM]:
                item.projectItem.estimateUom.pluralDescription || "UT",
              [COLUMN_TYPE.Quantity]: item.quantityDecimal || "",
              [COLUMN_TYPE.ReceivedQuantity]: item.quantityDecimal || "",
              [COLUMN_TYPE.PrefilledPrice]: item.unitPrice || "",
              [COLUMN_TYPE.CostCode]: item.costCode?.description || "",
            };
          }) || [];
      appendRows(newItems, handsonInstance);
    },
    [
      buyout?.items,
      appendRows,
      handsonInstance,
      materials,
      getFormattedMaterialName,
    ],
  );

  if (!release) {
    return null;
  }

  return (
    <Header>
      <HeaderTop>
        <Row>
          <MenuButton onClick={handleClick}>
            <ChevronLeftStyled />
          </MenuButton>
          <Title>
            <FormattedMessage
              id="EDIT_ORDER_WITH_NUMBER"
              values={{
                orderNumber: release?.sequenceNumber,
              }}
            />
          </Title>
          <ReleaseStatusChip
            className="ml-2 bg-gray-100"
            status={release?.status}
            type="breadcrumb"
            releaseType={release?.type}
          />
        </Row>
        <ButtonGroup>
          <If isTrue={buyout}>
            <OutlinedButton $small onClick={() => setAddFromBuyoutDialog(true)}>
              <FormattedMessage id="ADD_ITEMS_FROM_BUYOUT" />
            </OutlinedButton>
            <AddItemsToReleaseFromBuyoutDialog
              visible={addFromBuyoutDialog}
              setVisible={setAddFromBuyoutDialog}
              callback={addBuyoutItems}
            />
          </If>
          <OutlinedButton $small onClick={() => addSlipItems()}>
            <FormattedMessage id="ADD_SCANNED_ITEMS_FROM_PACKING_SLIP" />
          </OutlinedButton>
        </ButtonGroup>
      </HeaderTop>
      <If isTrue={release}>
        <Container>
          <FormProvider {...form}>
            <SelectControlled
              name="projectId"
              options={projects}
              getValue={(o) => o.id}
              getLabel={(o) => getProjectSelectorLabel(o)}
              placeholder={intl.$t({ id: "PROJECT_NAME" })}
              loading={loadingProjects}
              rules={{
                required: true,
              }}
            />
            <SelectControlled
              name="vendorId"
              options={vendors}
              getValue={(o) => o.sellerOrgLocation.id}
              getLabel={(o) =>
                vendorLabelFormatter(
                  o.sellerOrgLocation,
                  o.contacts.filter((c) => c.receivesOrderNotifications),
                )
              }
              placeholder={intl.$t({ id: "VENDOR" })}
              loading={loadingVendors}
              rules={{
                required: true,
              }}
              customRender={(item) =>
                VendorPickerCustomRender(
                  item,
                  (c) => c.receivesOrderNotifications,
                )
              }
            />
            <OrderTypePickerControlled name="orderTypeId" />
            <PoNumberingSettingsCheck>
              <TextFieldControlled
                name="poNumber"
                label={intl.$t({ id: "PO_NUMBER" })}
                className="bg-white"
                InputProps={{
                  startAdornment: (
                    <ConnectionStatusAdornment
                      connectedSourceSystem={connectedSourceSystem}
                      loading={loadingExternalPo}
                      isActive={!!externalPo}
                      onClick={() => openModal({ skipPoList: !!externalPo })}
                      enabledTooltip="PO_FOUND_IN_SOURCE_SYSTEM"
                      disabledTooltip="PO_NOT_FOUND_IN_SOURCE_SYSTEM"
                    />
                  ),
                  endAdornment: connectedSourceSystem ? (
                    <SearchIcon onClick={onPoNumberSearchIconClick} />
                  ) : null,
                }}
                disabled={!!importedItems}
              />
            </PoNumberingSettingsCheck>
            <DatePickerControlled
              name="orderDate"
              label={intl.$t({ id: "FULFILLMENT_DATE" })}
              className="bg-white"
              maxDate={new Date()}
              rules={{
                required: true,
              }}
            />
            <AssignBuyoutButton release={release} className="mt-2" />
          </FormProvider>
        </Container>
      </If>
    </Header>
  );
};
