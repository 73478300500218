import {
  BuyoutItemFieldsFragment,
  DistributorBuyoutItemFieldsFragment,
} from "@/generated/graphql";

export const getPhaseCodesByBuyoutItem = (
  buyoutItem: BuyoutItemFieldsFragment | DistributorBuyoutItemFieldsFragment,
) => {
  const phaseCode = buyoutItem.tags.find((t) => t.hasMapping);
  if (phaseCode?.id) {
    return {
      id: phaseCode.id,
      code: phaseCode.id,
      description: phaseCode.name,
      inUse: false,
    };
  }
};
