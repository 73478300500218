import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { useValidateRequiredParams } from "@/common/hooks/useValidateRequiredParams";
import {
  COLUMN_TYPE,
  SpreadsheetSaveType,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { VIEW_STATE, useTableView } from "@/common/providers/TableViewProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { useProjectTags } from "@/contractor/pages/home/project/providers/ProjectTagsProvider";
import { useProjectZones } from "@/contractor/pages/home/project/providers/ProjectZonesProvider";
import {
  AddToReleaseItemInput,
  ReleaseFieldsFragment,
  UpdateContractorReleaseInput,
  UpdateContractorReleaseItemInput,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "../../../providers/ReleaseActionsProvider";
import { useRelease } from "../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../../../store/useReleaseStore";
import { useSyncReleaseItemsStore } from "../store/useSyncReleaseItemsStore";

export type SyncedRelease = {
  releaseId: string;
  version: number;
  updates: UpdateContractorReleaseItemInput[];
  addedItems: AddToReleaseItemInput[];
  removedItems: string[];
  buyoutId?: string;
};

export const useSyncReleaseItems = () => {
  const { manufacturers } = useManufacturers();
  const {
    spreadsheetData,
    getRemovedRowIds,
    rowHasChanges,
    resetPreviousData,
    gotoInvalidRow,
  } = useColumnMapper();
  const {
    getCellValue,
    getRowUomCreatableValue,
    getCostCodeId,
    rowIsEmpty,
    addMissingZones,
    addMissingTags,
    addMissingMaterials,
    addMissingManufacturers,
    getCellWithAdditionalData,
    findMaterialByName,
    getPrefilledValue,
    isBuyoutItem,
    tableHasColumn,
  } = useTableHelpers();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { release, refetch } = useRelease();
  const { zones } = useProjectZones();
  const { tags } = useProjectTags();
  const { vendors } = useVendors();
  const { setError } = useGlobalError();
  const [updateReleaseItems, { loading: updating }] =
    useUpdateContractorReleaseMutation();
  const { materials } = useMaterials();
  const { tableView } = useTableView();
  const { saving, setSaving } = useSyncReleaseItemsStore();

  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();
  const { setInputError } = useReleaseActions();
  const {
    hasChanges,
    setHasChanges,
    updateStoreReleaseVersion,
    release: releaseStore,
  } = useReleaseStore();
  const { changes } = useReleaseUpdate();
  const { validateParam } = useValidateRequiredParams(
    "SyncReleaseItemsProvider",
  );

  useEffect(() => {
    setSaving(updating);
  }, [setSaving, updating]);

  const updateRelease = async (
    input: UpdateContractorReleaseInput,
  ): Promise<ReleaseFieldsFragment | undefined> => {
    try {
      const { data, errors } = await updateReleaseItems({
        variables: {
          input: {
            ...input,
            description: changes?.description,
            instructions: changes?.instructions,
            version: releaseStore?.version,
          },
        },
      });

      setError(errors);
      if (!errors && data) {
        updateStoreReleaseVersion(data.updateContractorRelease);
        resetPreviousData();
        setHasChanges(false);
        setSuccessAlert(intl.$t({ id: "RELEASE_ITEMS_SAVED_SUCCESS" }));
      }
      return errors ? undefined : data?.updateContractorRelease;
    } catch (error) {
      setError(error);
      return undefined;
    }
  };

  const getSyncedRelease = async () => {
    const newItems: AddToReleaseItemInput[] = [];
    const itemsToUpdate: UpdateContractorReleaseItemInput[] = [];
    const itemsToRemove = getRemovedRowIds(release?.items ?? []);

    setSaving(true);
    const newMaterials = (await addMissingMaterials()) || [];
    const newManufacturers = (await addMissingManufacturers()) || [];
    const newTags = (await addMissingTags(release?.project?.id || "")) || [];
    const newZones = (await addMissingZones(release?.project?.id || "")) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getRowUomCreatableValue(row);
      const isLumpSum = isLumpSumUomText(uom);
      if (isLumpSum) {
        material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
          ...materials,
          ...newMaterials,
        ]);
      }

      if (!material || rowIsEmpty(row)) {
        if (row.id) {
          itemsToRemove.push(row.id);
        }
        return false;
      }

      const vendorText = getCellValue(row, COLUMN_TYPE.Vendor);
      const vendor = vendors.find(
        (v) => vendorLabelFormatter(v.sellerOrgLocation) === vendorText,
      );

      const quantityDecimal = getCellValue(row, COLUMN_TYPE.Quantity);
      const receivedQuantityDecimal = getCellValue(
        row,
        COLUMN_TYPE.ReceivedQuantity,
      );
      const unitPrice = tableHasColumn(COLUMN_TYPE.PrefilledPrice)
        ? getCellValue(row, COLUMN_TYPE.PrefilledPrice)
        : getCellValue(row, COLUMN_TYPE.UnitPrice);

      const hasUnitPrice =
        unitPrice !== "" && unitPrice !== null && unitPrice !== undefined;
      const zone = [...(zones || []), ...newZones].find(
        (z) => z?.name === getCellValue(row, COLUMN_TYPE.Zone),
      );
      const tag = [...(tags || []), ...newTags].find(
        (t) => t?.name === getCellValue(row, COLUMN_TYPE.Tag),
      );
      const manufacturer = [...manufacturers, ...(newManufacturers ?? [])].find(
        (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
      );
      const notes = getCellValue(row, COLUMN_TYPE.Notes) || "";

      const existingItem = release?.items.find((item) => item.id === row.id);
      const matchingMaterials =
        existingItem && existingItem.projectItem?.material.id === material.id;

      const prefilledPrice = getPrefilledValue(
        rowMaterialText,
        vendorText,
        manufacturer?.name ?? "",
        uom,
      );

      const buyoutItem = isBuyoutItem(
        rowMaterialText,
        vendorText,
        manufacturer?.name ?? "",
        unitPrice,
        getCellValue(row, COLUMN_TYPE.CostCode),
      );

      if (matchingMaterials) {
        if (rowHasChanges(row) || existingItem.position !== index) {
          itemsToUpdate.push({
            releaseItemId: row.id,
            instructions: {
              text: notes,
            },
            ...(!existingItem?.buyoutItem && {
              manufacturerId: manufacturer?.id,
            }),
            uom,
            receivedQuantityDecimal:
              receivedQuantityDecimal === ""
                ? undefined
                : receivedQuantityDecimal,
            tags: tag ? [tag.id] : [],
            pricePrenegotiated:
              prefilledPrice.isVendorPrice &&
              prefilledPrice.value === String(unitPrice),
            quantityDecimal,
            unitPrice: hasUnitPrice ? String(unitPrice) : undefined,
            ...(isLumpSum && { name: rowMaterialText }),
            clearUnitPrice: !hasUnitPrice,
            zoneId: zone?.id,
            sellerOrgLocationId: vendor?.sellerOrgLocation.id ?? null,
            clearSellerOrgLocation: !vendor,
            position: index,
            buyoutItemId: buyoutItem ? buyoutItem?.id : undefined,
            clearManufacturer: !manufacturer,
            costCodeId: getCostCodeId(row),
            clearCostCode: !getCostCodeId(row),
          });
        }
      } else {
        newItems.push({
          ...(!buyoutItem && {
            projectItem: {
              estimateUom: uom ?? "",
              ...(isOrgCatalogSku(material.material) && {
                orgCatalogSkuId: material.material.id,
              }),
              ...(isProductSku(material.material) && {
                masterProductId: material.material.id,
              }),
              ...(isMasterSku(material.material) && {
                masterSkuId: material.material.id,
              }),
            },
          }),
          instructions: {
            text: notes,
          },
          manufacturerId: manufacturer?.id,
          receivedQuantityDecimal:
            receivedQuantityDecimal === ""
              ? undefined
              : receivedQuantityDecimal,
          tags: tag ? [tag.id] : [],
          pricePrenegotiated:
            prefilledPrice.isVendorPrice &&
            prefilledPrice.value === String(unitPrice),
          quantityDecimal: quantityDecimal,
          unitPrice: hasUnitPrice ? String(unitPrice) : undefined,
          ...(isLumpSum && { name: rowMaterialText }),
          zoneId: zone?.id,
          buyoutItemId: buyoutItem ? buyoutItem?.id : undefined,
          sellerOrgLocationId: vendor?.sellerOrgLocation.id ?? null,
          position: index,
          costCodeId: getCostCodeId(row),
        });
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    validateParam({
      value: release?.id,
      methodName: "getSyncedRelease",
      paramName: "release->id",
    });
    validateParam({
      value: releaseStore?.version,
      methodName: "getSyncedRelease",
      paramName: "releaseStore->version",
    });

    return {
      releaseId: release?.id ?? "",
      version: releaseStore?.version ?? 0,
      updates: itemsToUpdate,
      addedItems: newItems,
      removedItems: itemsToRemove,
      assignDefaultCostCodes: false,
      prefillPrices: false,
    };
  };

  const validateItems = (type?: SpreadsheetSaveType) => {
    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        ...(type !== SpreadsheetSaveType.Toggle ? [COLUMN_TYPE.Quantity] : []),
        COLUMN_TYPE.UOM,
      ]) ||
      !validateRowValues(
        [
          ...(type !== SpreadsheetSaveType.Toggle
            ? [COLUMN_TYPE.Quantity]
            : []),
          COLUMN_TYPE.Vendor,
          COLUMN_TYPE.PrefilledPrice,
          COLUMN_TYPE.UOM,
          COLUMN_TYPE.CostCode,
        ],
        undefined,
        { minPrice: undefined },
      )
    ) {
      setInputError(ReleaseErrorType.SPREADSHEET_ERROR);
      gotoInvalidRow();
      return false;
    }
    return true;
  };

  const syncReleaseItems = async (type?: SpreadsheetSaveType) => {
    if (tableView === VIEW_STATE.normal) {
      return release;
    }

    if (!validateItems(type)) {
      return undefined;
    }

    const syncedRelease = await getSyncedRelease();
    if (
      syncedRelease &&
      (syncedRelease.updates.length > 0 ||
        syncedRelease.addedItems.length > 0 ||
        syncedRelease.removedItems.length > 0 ||
        hasChanges)
    ) {
      const result = await updateRelease(syncedRelease);
      refetch();
      return result;
    } else if (type === SpreadsheetSaveType.SaveButton) {
      setSuccessAlert(intl.$t({ id: "NOTHING_TO_SAVE" }));
    }

    return release;
  };

  const getReleaseUpdateInput = async (type?: SpreadsheetSaveType) => {
    if (!validateItems(type)) {
      return undefined;
    }

    return (await getSyncedRelease()) as UpdateContractorReleaseInput;
  };

  return {
    getSyncedRelease,
    validateItems,
    syncReleaseItems,
    getReleaseUpdateInput,
    updateRelease,
    saving,
  };
};
