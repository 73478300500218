import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type ProductImageProps = {
  brand: string | null | undefined;
  className?: string;
};

const BrandName = tw.div`
  font-medium
`;

export const ProductBrand: FC<ProductImageProps> = ({ brand, className }) => {
  return (
    <BrandName title={brand || ""} className={className}>
      {brand || <FormattedMessage id="ANY_MANUFACTURER" />}
    </BrandName>
  );
};
