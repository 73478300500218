import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  title?: string;
  body?: string;
  type?: "info" | "error" | "primary";
  className?: string;
};

const InfoContainer = tw.div`
  self-end w-full pl-1 mb-1
`;

const INFO_TEXT_TITLE_COLOR = {
  info: "text-black",
  error: "text-red-500",
  primary: "text-black",
};
const Title = tw.div<Props>`
  font-medium text-sm
  ${({ type }: Props) => INFO_TEXT_TITLE_COLOR[type || "info"]}
`;

const INFO_TEXT_BODY_COLOR = {
  info: "text-gray-600",
  error: "text-red-500",
  primary: "text-black",
};
const Body = tw.div<Props>`
  font-normal text-xs w-full
  ${({ type }: Props) => INFO_TEXT_BODY_COLOR[type || "info"]}
`;

export const InfoText: FC<Props> = ({
  title,
  body,
  type = "info",
  className,
}) => {
  const intl = useIntl();

  const transTitle = useMemo(() => {
    if (title && intl.messages[title]) {
      return intl.$t({ id: title });
    } else {
      return title;
    }
  }, [title, intl]);

  const transBody = useMemo(() => {
    if (body && intl.messages[body]) {
      return intl.$t({ id: body });
    } else {
      return body;
    }
  }, [body, intl]);

  return (
    <InfoContainer className={className}>
      <Title type={type}>{transTitle}</Title>
      <Body type={type}>{transBody}</Body>
    </InfoContainer>
  );
};
