import { OverlayPanelProps } from "@/common/components/panel/OverlayPanel";
import { OverlayPanelWithStepper } from "@/common/components/panel/OverlayPanelWithStepper";
import { useStepper } from "@/common/components/stepper/Stepper";
import { IntegrationConfig } from "@/common/hooks/integrations/types/IntegrationConfigType";
import { FC, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { SelectIntegrationForm } from "./SelectIntegrationForm";
import { Variations } from "./Variations";

type Props = {
  onClose: () => void;
};

export const IntegrationForm: FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const [selectedConfig, setSelectedConfig] = useState<
    IntegrationConfig | undefined
  >();
  const { moveToPreviousStep, moveToFirstStep } = useStepper();

  const closePanel = useCallback(() => {
    moveToFirstStep();
    onClose();
  }, [moveToFirstStep, onClose]);

  const steps = useMemo((): OverlayPanelProps[] => {
    return [
      {
        title: intl.$t({
          id: "INTEGRATION_ADD",
        }),
        children: (
          <SelectIntegrationForm
            onClose={closePanel}
            setSelectedConfig={setSelectedConfig}
          />
        ),
        onCancel: closePanel,
      },
      {
        title: intl.$t({ id: "CHOOSE_SETUP" }),
        hasBackArrow: true,
        onBack: () => moveToPreviousStep(),
        children: <Variations config={selectedConfig} onClose={closePanel} />,
      },
    ];
  }, [closePanel, intl, moveToPreviousStep, selectedConfig]);

  return <OverlayPanelWithStepper steps={steps} />;
};
