import {
  DistributorQuoteItemFieldsFragment,
  QuoteStatus,
} from "@/generated/graphql";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../providers/DistributorQuoteItemsProvider";

export const isDistributorQuoteItemIncluded = (
  item:
    | DistributorQuoteItemGroupExtendedFieldsFragment
    | DistributorQuoteItemFieldsFragment,
) => {
  if ((item as DistributorQuoteItemGroupExtendedFieldsFragment).quoteItems) {
    return (
      (item as DistributorQuoteItemGroupExtendedFieldsFragment).quoteItems[0]
        ?.status !== QuoteStatus.Withdrawn
    );
  } else {
    return (
      (item as DistributorQuoteItemFieldsFragment).status !==
      QuoteStatus.Withdrawn
    );
  }
};
