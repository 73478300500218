import { Notes } from "@/common/components/notes/Notes";
import { RfqItemFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useRfqGroupedItems } from "../../hooks/useRfqGroupedItems";

type Props = {
  item: RfqItemFieldsFragment;
  readonly?: boolean;
};

export const RfqItemInstructions: FC<Props> = ({ item, readonly = false }) => {
  const { expandedItems, setExpandedItems, newRfqItem } = useRfqGroupedItems();

  return (
    <Notes
      expandedItems={expandedItems}
      setExpandedItems={setExpandedItems}
      item={{
        ...item,
        notes: item.instructions?.text ?? "",
        assets: item.instructions?.assets ?? [],
      }}
      readonly={readonly}
      hasNotesOrAttachments={
        !!item.instructions?.text ||
        !!item.instructions?.assets?.length ||
        (!!newRfqItem?.isAddMode &&
          !item.id &&
          (!!newRfqItem.instructions?.text ||
            !!newRfqItem.instructions?.assetUrls?.length))
      }
    />
  );
};
