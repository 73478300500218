import { ManufacturerPicker } from "@/common/components/manufacturer-picker/ManufacturerPicker";
import { isMasterSku } from "@/common/components/material/utils";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { OrgMaterialFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useManufacturerFromMaterial } from "../../../hooks/useManufacturerFromMaterial";
import { useMaterialList } from "../../../providers/MaterialListProvider";

type Props = {
  item: OrgMaterialFieldsFragment;
  error?: boolean;
  index?: number;
  readonly?: boolean;
};

export const MaterialManufacturerSelector: FC<Props> = ({
  item,
  index,
  error,
  readonly,
}) => {
  const manufacturer = useManufacturerFromMaterial(item);
  const { updateMaterial } = useMaterialList();

  const handleChange = useCallback(
    (newValue: string | null) => {
      updateMaterial(item.id, {
        updates: [
          {
            orgMaterialId: item.id,
            orgCatalogSku: {
              id: item.material.id,
              defaultManufacturerId: newValue,
            },
          },
        ],
      });
    },
    [updateMaterial, item],
  );

  return (
    <NotNullableRenderer value={!readonly || manufacturer}>
      <ManufacturerPicker
        item={manufacturer}
        saveManufacturer={handleChange}
        error={error}
        readonly={readonly || isMasterSku(item.material)}
        index={index}
        hasAnyManufacturer={false}
        canClearManufacturer
      />
    </NotNullableRenderer>
  );
};
