import { getVendorContacts } from "@/common/utils/getVendorContacts";
import {
  OrgPreferredVendorsFieldsFragment,
  QuoteStatus,
} from "@/generated/graphql";
import { useMemo } from "react";

type VendorsType = {
  addedVendors: string[];
  removedVendors: string[];
};

export const useAddedAndRemovedVendors = (
  initialVendors: string[],
  preferredVendors: string[],
  vendors: OrgPreferredVendorsFieldsFragment[],
  quotes: Array<{
    id: string;
    status: QuoteStatus;
    preferredVendor?: {
      id: string;
    } | null;
  }>,
): VendorsType => {
  return useMemo(() => {
    const added = preferredVendors.filter(
      (vendor) => !initialVendors.includes(vendor),
    );
    const removed = initialVendors.filter(
      (vendor) =>
        !preferredVendors.includes(vendor) &&
        !quotes.find(
          (quote) =>
            quote.preferredVendor?.id === vendor &&
            quote.status === QuoteStatus.Withdrawn,
        ),
    );

    return {
      addedVendors: vendors
        .filter((vendor) => added.includes(vendor.sellerOrgLocation.id))
        .map(
          (vendor) =>
            `${vendor.sellerOrgLocation.org.name} - ${getVendorContacts(
              vendor.contacts,
            )}`,
        ),
      removedVendors: vendors
        .filter((vendor) => removed.includes(vendor.sellerOrgLocation.id))
        .map(
          (vendor) =>
            `${vendor.sellerOrgLocation.org.name} - ${getVendorContacts(
              vendor.contacts,
            )}`,
        ),
    };
  }, [preferredVendors, initialVendors, vendors, quotes]);
};
