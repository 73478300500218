import {
  TaxesGroup,
  TotalItemContainer,
  TotalItemOuter,
} from "@/common/components/additional-charges/AdditionalCharges.styles";
import { AssetsCard } from "@/common/components/assets-card/AssetsCard";
import { InvoiceTotals } from "@/common/components/invoice-totals/InvoiceTotals";
import { Loader } from "@/common/components/loader/Loader";
import { NoteDocumentPanel } from "@/common/components/note-document-panel/NoteDocumentPanel";
import { Price } from "@/common/components/price/Price";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { ReleaseDeliverySlips } from "@/common/components/release-images/ReleaseDeliverySlips";
import {
  defaultTableItemSelectionClassNames,
  greenTableItemSelectionClassNames,
} from "@/common/components/searchable-list/utils";
import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { TOTAL_PRICE_DECIMAL_POINTS } from "@/common/const";
import { ReleaseReceipts } from "@/contractor/pages/home/release/pages/release-details/ReleaseReceipts";
import { ReleaseItemsZoneProvider } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseItemList } from "@/contractor/pages/home/release/release-items-list/ReleaseItemList";
import Decimal from "decimal.js";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";
import { InvoiceReleaseEditInfo } from "../invoice-edit-info/InvoiceReleaseEditInfo";
import { useInvoiceReleaseConfiguration } from "./InvoiceReleaseConfiguration";
import { InvoiceReleaseItemsFilterHeader } from "./InvoiceReleaseItemsFilterHeader";
import { InvoicesCard } from "./InvoicesCard";

const NotesCard = tw(AssetsCard)`my-[1px] lg:border-0 mt-3 overflow-visible`;
const ReceiptsCard = tw(ReleaseReceipts)`my-[1px] lg:border-0 overflow-visible`;
const Separator = tw.div`flex flex-1 h-full`;
const Footer = tw.div`mr-7 mt-10 pb-5`;
const Label = tw.div`font-medium text-lg`;

type Props = {
  readonly?: boolean;
};

export const InvoiceReleaseItems: FC<Props> = () => {
  const { release } = useRelease();
  const { invoice, showOnlyInvoicedItems } = useInvoiceVerification();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const intl = useIntl();

  const items = useMemo(
    () =>
      release?.items
        .filter((i) => !showOnlyInvoicedItems || !!i.invoiceItems?.[0])
        .map((item) => {
          const estimatedItems = item.projectItem?.estimatedItems.filter(
            (ei) => ei.zone?.id === item.zone?.id,
          );
          return {
            ...{
              ...item.projectItem,
              material: {
                ...item.projectItem?.material,
                material: {
                  ...item.projectItem?.material.material,
                  name: item.name,
                },
              },
              estimatedItems,
            },
            ...item,
          } as ExpandedReleaseItem;
        }) ?? [],
    [release?.items, showOnlyInvoicedItems],
  );
  const configuration = useInvoiceReleaseConfiguration(
    release,
    matchedOrderViewState !== MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
    items,
  );
  const itemFn = useCallback(
    (item: ExpandedReleaseItem) => ({
      className: item.invoiceItems?.[0]?.id
        ? matchedOrderViewState === MatchedOrderViewState.EDIT_INVOICE_COVERAGES
          ? defaultTableItemSelectionClassNames
          : greenTableItemSelectionClassNames
        : "bg-gray-100 first:mt-0",
    }),
    [matchedOrderViewState],
  );

  const hasAdditionalInvoices = useMemo(
    () => (invoice?.release?.invoices || []).length > 1,
    [invoice?.release?.invoices],
  );

  const totalInvoices = useMemo(
    () =>
      (invoice?.release?.invoices || [])
        .filter((i) => i.id !== invoice?.id)
        .reduce((acc, i) => new Decimal(acc).add(i.total || 0).toNumber(), 0),
    [invoice?.id, invoice?.release?.invoices],
  );

  const uninvoicedBalance = useMemo(
    () => new Decimal(release?.total || 0).sub(totalInvoices).toNumber(),
    [release?.total, totalInvoices],
  );

  const itemsCount = useMemo(
    () => ({
      partial: release?.items.filter((i) => i.unitPrice !== null).length || 0,
      total: release?.items.length || 0,
    }),
    [release?.items],
  );

  if (!release) {
    return <Loader loading />;
  }

  return (
    <ReleaseItemsZoneProvider items={items} usePhaseCodes>
      <InvoiceReleaseItemsFilterHeader />
      <ReleaseItemList
        columns={configuration}
        classNames={{
          header: "-top-6 bg-gray-100 -ml-1",
          category: "top-3",
          subCategory: (groupedByZones: boolean) =>
            groupedByZones ? "top-11" : "top-3",
        }}
        itemFn={itemFn}
      />
      <InvoiceReleaseEditInfo />
      <NotesCard
        headerClassName="bg-blue-100"
        title={intl.$t({ id: "NOTES_AND_ATTACHMENTS" })}
        assets={release.instructions?.assets || []}
        expanded={false}
      />
      <QuoteDocumentPanel readonly quoteDocument={release.quoteDocument} />
      <NoteDocumentPanel readonly noteDocument={release.noteDocument} />
      <ReceiptsCard release={release} prePaid={false} />
      <ReleaseDeliverySlips
        release={release}
        deliverySlips={release.deliverySlips}
      />
      <InvoicesCard expanded />
      <Separator />
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          release={release}
          total={release.total}
          subtotal={release.subtotal}
          customTaxAmount={release.customTaxAmount}
          taxAmount={release.taxAmount}
          customTaxRate={release.taxRate}
          includeNotesPanel={false}
          includePaymentTerms={true}
          itemsCount={itemsCount}
          taxExempt={{
            isProjectTaxExempt: release.project?.taxExempt,
            isVendorTaxExempt: release.preferredVendor?.taxExempt,
            vendorName: vendorLabelFormatter(release.sellerOrgLocation),
          }}
          additionalTaxes={
            hasAdditionalInvoices ? (
              <>
                <TaxesGroup>
                  <Label className="text-sm">
                    <FormattedMessage id="INVOICED_SO_FAR" />
                  </Label>
                  <Price price={totalInvoices} className="font-normal" />
                </TaxesGroup>
                <TotalItemOuter>
                  <TotalItemContainer $highlightTotal={true}>
                    <Label className="text-2xl">
                      <FormattedMessage id="UNINVOICED_BALANCE" />
                    </Label>
                    <Price
                      price={uninvoicedBalance}
                      className="text-xl font-medium"
                      maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
                    />
                  </TotalItemContainer>
                </TotalItemOuter>
              </>
            ) : undefined
          }
        />
        <InvoiceTotals release={release} invoice={invoice} />
      </Footer>
    </ReleaseItemsZoneProvider>
  );
};
