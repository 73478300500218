import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { getUTCDate } from "@/common/utils/dates/getUTCDate";
import { mergeChanges } from "@/common/utils/mergeChanges";
import { useReleaseCacheUpdate } from "@/contractor/pages/home/release/providers/useReleaseCacheUpdate";
import {
  ApproveInvoiceInput,
  CreateInvoicedReleaseItemInput,
  InvoiceDocument,
  InvoiceFieldsFragment,
  InvoiceStatus,
  ReleaseDocument,
  UpdateInvoiceInput,
  UpdateInvoicedReleaseItemInput,
  useInvoiceQuery,
  useRescanInvoiceMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionPromise,
} from "@/types/NoFunction";
import Decimal from "decimal.js";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useInvoiceCreation } from "../../scanned-invoices/providers/InvoiceCreationProvider";
import {
  InvoiceCreateReleaseFormValues,
  invoiceCreateReleaseFormDefaultValues,
} from "../components/matched-order/components/InvoiceVerificationForm";
import { useInvoiceApprove } from "../hooks/useInvoiceApprove";
import { useInvoiceUpdate } from "../hooks/useInvoiceUpdate";

type CreateInvoicedReleaseItemInputWithId = CreateInvoicedReleaseItemInput & {
  id: string;
};

type UpdateInvoiceInputWithReleaseItemId = Omit<
  UpdateInvoiceInput,
  "updatedInvoicedReleaseItems" | "addedInvoicedReleaseItems"
> & {
  updatedInvoicedReleaseItems?: (UpdateInvoicedReleaseItemInput & {
    releaseItemId: string;
    quantitySoFar?: string | null;
  })[];
  addedInvoicedReleaseItems?: CreateInvoicedReleaseItemInputWithId[];
};

type ProviderContextType = {
  invoice: InvoiceFieldsFragment | null;
  updateInvoice: (
    input: UpdateInvoiceInputWithReleaseItemId | UpdateInvoiceInput,
    args?: { includeDocuments?: boolean; bulkUpdate?: boolean },
  ) => Promise<boolean>;
  approveInvoice: (input: ApproveInvoiceInput) => Promise<boolean>;
  approving: boolean;
  footerState: InvoiceFooterState;
  setFooterState: (state: InvoiceFooterState) => void;
  loading: boolean;
  refetch: () => Promise<void>;
  setForceFetchExternalPO: (shouldFetch: boolean) => void;
  shouldFetchExternalPO: boolean;
  submitUpdates: (args: {
    includeDocuments?: boolean;
    refetchQueries?: boolean;
  }) => Promise<boolean>;
  hasChanges: boolean;
  showOnlyInvoicedItems: boolean;
  setShowOnlyInvoicedItems: (show: boolean) => void;
  hasError: boolean;
  setHasError: (hasError: boolean) => void;
  rescanInvoice: () => void;
  resetUpdates: () => void;
};

const ProviderContext = createContext<ProviderContextType>({
  invoice: null,
  updateInvoice: NoFunctionBooleanPromise,
  approveInvoice: NoFunctionBooleanPromise,
  approving: false,
  footerState: InvoiceFooterState.DEFAULT,
  setFooterState: NoFunction,
  loading: false,
  refetch: NoFunctionPromise,
  setForceFetchExternalPO: NoFunction,
  shouldFetchExternalPO: false,
  submitUpdates: NoFunctionBooleanPromise,
  hasChanges: false,
  showOnlyInvoicedItems: false,
  setShowOnlyInvoicedItems: NoFunction,
  hasError: false,
  setHasError: NoFunction,
  rescanInvoice: NoFunction,
  resetUpdates: NoFunction,
});

export const InvoiceVerificationProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { invoiceId } = useParams();
  const updateReleaseCache = useReleaseCacheUpdate();
  const { setError } = useGlobalError();
  const [hasChanges, setHasChanges] = useState(false);
  const [showOnlyInvoicedItems, setShowOnlyInvoicedItems] = useState(false);
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { locationId } = useInvoiceCreation();
  const [footerState, setFooterState] = useState<InvoiceFooterState>(
    InvoiceFooterState.DEFAULT,
  );
  const [forceFetchExternalPO, setForceFetchExternalPO] = useState(false);
  const changes = useRef<UpdateInvoiceInputWithReleaseItemId>();
  const [hasError, setHasError] = useState(false);

  const { data, loading, error, refetch } = useInvoiceQuery({
    variables: { id: invoiceId || "" },
    skip: !invoiceId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });
  const [isReset, setIsReset] = useState(false);

  const { reset, getValues, trigger } =
    useFormContext<InvoiceCreateReleaseFormValues>();

  useEffect(() => {
    reset({
      ...invoiceCreateReleaseFormDefaultValues,
      businessLocationId: locationId ?? "",
    });
  }, [invoiceId, locationId, reset]);

  const { updateInvoice: updateInvoiceMutation } = useInvoiceUpdate();

  const accumulateChanges = (input: UpdateInvoiceInputWithReleaseItemId) => {
    const removedInvoicedReleaseItems =
      input.removedInvoicedReleaseItems?.filter(
        (item) =>
          !changes.current?.addedInvoicedReleaseItems?.some(
            (i) => i.id === item,
          ),
      ) || [];
    const added = mergeChanges(
      changes.current?.addedInvoicedReleaseItems?.filter(
        (i) => !input.removedInvoicedReleaseItems?.includes(i.id),
      ),
      input.addedInvoicedReleaseItems,
      "releaseItemId",
    );
    const removals = [
      ...[
        ...(changes.current?.removedInvoicedReleaseItems || []),
        ...removedInvoicedReleaseItems,
      ].filter(
        (item) => !added.some((i) => i.id === item || i.releaseItemId === item),
      ),
    ];
    let updates = mergeChanges(
      changes.current?.updatedInvoicedReleaseItems?.filter(
        (i) => !input.removedInvoicedReleaseItems?.includes(i.id),
      ),
      input.updatedInvoicedReleaseItems,
      "releaseItemId",
    );

    const allAddedItems = added.filter((i) => !removals?.includes(i.id));
    if (allAddedItems.length > 0) {
      updates = [
        ...updates,
        ...(added.filter((i) => removals.includes(i.id)) || []),
      ];
    }
    changes.current = {
      id: invoiceId || "",
      addedInvoicedReleaseItems:
        allAddedItems.length > 0 ? allAddedItems : undefined,
      updatedInvoicedReleaseItems: updates.length > 0 ? updates : undefined,
      removedInvoicedReleaseItems:
        removals.length > 0 ? Array.from(new Set(removals)) : undefined,
    };

    setHasChanges(
      !!changes.current &&
        (!!changes.current.addedInvoicedReleaseItems?.length ||
          !!changes.current.updatedInvoicedReleaseItems?.length ||
          !!changes.current.removedInvoicedReleaseItems?.length),
    );
  };

  const updateInvoice = async (
    input: UpdateInvoiceInputWithReleaseItemId | UpdateInvoiceInput,
    {
      includeDocuments,
      bulkUpdate = false,
    }: { includeDocuments?: boolean; bulkUpdate?: boolean } = {},
  ): Promise<boolean> => {
    if (bulkUpdate) {
      const addedInvoicedReleaseItems: CreateInvoicedReleaseItemInputWithId[] =
        (
          input.addedInvoicedReleaseItems?.map((i) => ({
            ...i,
            id: i.releaseItemId,
          })) || []
        ).concat(
          (
            input as UpdateInvoiceInputWithReleaseItemId
          ).updatedInvoicedReleaseItems
            ?.filter((i) =>
              changes.current?.addedInvoicedReleaseItems?.some(
                (j) => j.releaseItemId === i.releaseItemId,
              ),
            )
            .map((i) => ({
              quantity: i.quantity || "0",
              unitPrice: i.unitPrice || "0",
              releaseItemId: i.releaseItemId,
              id: i.id,
              invoicedStatus: InvoiceStatus.AwaitingApproval,
            })) || [],
        );
      const newInput = {
        ...input,
        updatedInvoicedReleaseItems: (
          input as UpdateInvoiceInputWithReleaseItemId
        ).updatedInvoicedReleaseItems?.filter(
          (i) =>
            !changes.current?.addedInvoicedReleaseItems?.some(
              (j) => j.releaseItemId === i.releaseItemId,
            ),
        ),
        addedInvoicedReleaseItems:
          addedInvoicedReleaseItems.length > 0
            ? addedInvoicedReleaseItems
            : undefined,
      };
      accumulateChanges(newInput);
      const invoiceItems = [
        ...(changes.current?.addedInvoicedReleaseItems || []).map((i) => ({
          ...i,
          quantitySoFar: "0",
        })),

        ...(changes.current?.updatedInvoicedReleaseItems?.map((i) => ({
          id: i.id,
          quantity: i.quantity || "0",
          unitPrice: i.unitPrice || "0",
          releaseItemId: i.releaseItemId,
          quantitySoFar: i.quantitySoFar,
        })) || []),
      ].map((i) => ({
        ...i,
        __typename: "InvoicedReleaseItem" as const,
        invoiceStatus: InvoiceStatus.AwaitingApproval,
      }));

      updateReleaseCache(
        { releaseId: data?.invoice.release?.id || "" },
        undefined,
        {
          invoiceId: input.id,
          invoiceItems,
          removedInvoiceItems:
            changes.current?.removedInvoicedReleaseItems || [],
        },
      );

      return true;
    }
    const result = await updateInvoiceMutation(input, { includeDocuments });
    if (result && invoiceId) {
      if (input.clearRelease) {
        setHasChanges(false);
      }
      await refetch();
    }
    return result;
  };

  const submitUpdates = async ({
    includeDocuments,
    refetchQueries,
  }: {
    includeDocuments?: boolean;
    refetchQueries?: boolean;
  }) => {
    if (changes.current && hasChanges) {
      const result = await updateInvoiceMutation(
        {
          ...changes.current,
          releaseId: data?.invoice.release?.id || "",
          updatedInvoicedReleaseItems:
            changes.current?.updatedInvoicedReleaseItems?.map((i) => ({
              quantity: i.quantity,
              id: i.id,
              unitPrice: i.unitPrice,
            })),
          addedInvoicedReleaseItems:
            changes.current?.addedInvoicedReleaseItems?.map((i) => ({
              quantity: i.quantity,
              unitPrice: i.unitPrice,
              releaseItemId: i.releaseItemId,
            })) || [],
        },
        {
          includeDocuments,
          refetchQueries,
        },
      );
      if (result) {
        changes.current = undefined;
        setHasChanges(false);
        return true;
      }

      return false;
    }
    return false;
  };

  const { approveInvoice: approveInvoiceMutation, loading: approving } =
    useInvoiceApprove();
  const approveInvoice = async (
    input: ApproveInvoiceInput,
  ): Promise<boolean> => {
    if (invoiceId) {
      if (changes.current) {
        await submitUpdates({});
      }
      const result = await approveInvoiceMutation(
        input,
        data?.invoice.release?.id,
      );
      if (result) {
        await refetch();
      }
      return result;
    }
    return false;
  };

  useErrorEffect(error);

  useEffect(() => {
    if (data) {
      reset({
        ...getValues(),
        businessLocationId:
          getValues("businessLocationId") ||
          data.invoice?.predictedProject?.location?.id,
        projectId: getValues("projectId") || data.invoice?.predictedProject?.id,
        vendorId:
          getValues("vendorId") ||
          data?.invoice?.predictedSellerOrgLocation?.id ||
          "",
        poNumber: includePoNumbering ? (data?.invoice?.poNumber ?? "") : "",
        orderDate: getUTCDate(data?.invoice.issueDate),
        customTaxAmount: data?.invoice?.taxAmount || undefined,
        taxRate: undefined,
        additionalCharges: data?.invoice?.charges ?? [],
        subtotal:
          Number(data?.invoice?.subtotal) ||
          (data?.invoice?.items ?? []).reduce<number>(
            (acc, i) =>
              acc +
              Number(
                new Decimal(i.quantityDecimal)
                  .mul(new Decimal(i.unitPrice))
                  .toFixed(2),
              ),
            0,
          ),
        total: data?.invoice?.total ?? "0",
        orderTypeId: "",
      });
    }
    setIsReset(true);
  }, [data, getValues, includePoNumbering, reset]);

  useEffect(() => {
    if (isReset) {
      trigger();
      setIsReset(false);
    }
  }, [isReset, trigger]);

  const shouldFetchExternalPO = useMemo(
    () => (data?.invoice.release ? forceFetchExternalPO : true),
    [data?.invoice, forceFetchExternalPO],
  );

  useEffect(() => {
    setForceFetchExternalPO(false);
  }, [data?.invoice.id]);

  const [rescanInvoiceMutation] = useRescanInvoiceMutation();

  const rescanInvoice = async () => {
    if (invoiceId) {
      const releaseId = data?.invoice?.release?.id;

      try {
        const { data: rescanData, errors: rescanErrors } =
          await rescanInvoiceMutation({
            variables: {
              invoiceId,
            },
            refetchQueries: [
              ...(releaseId
                ? [
                    {
                      query: ReleaseDocument,
                      variables: {
                        id: releaseId,
                        invoiceId,
                      },
                    },
                  ]
                : []),
              {
                query: InvoiceDocument,
                variables: {
                  id: invoiceId,
                },
              },
            ],
            awaitRefetchQueries: true,
          });

        if (rescanData?.rescanInvoice) {
          changes.current = undefined;

          setHasChanges(false);
        }

        if (rescanErrors) {
          setError(rescanErrors);
        }
      } catch (errors) {
        setError(errors);
      }
    }

    return false;
  };

  const resetUpdates = useCallback(() => {
    changes.current = undefined;
    setHasChanges(false);
  }, []);

  const refetchInvoice = useCallback(async () => {
    await refetch();
  }, [refetch]);

  return (
    <ProviderContext.Provider
      value={{
        invoice: data?.invoice || null,
        updateInvoice,
        approveInvoice,
        approving,
        footerState,
        setFooterState,
        loading,
        refetch: refetchInvoice,
        setForceFetchExternalPO,
        shouldFetchExternalPO,
        submitUpdates,
        hasChanges,
        showOnlyInvoicedItems,
        setShowOnlyInvoicedItems,
        hasError,
        setHasError,
        rescanInvoice,
        resetUpdates,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceVerification = (): ProviderContextType =>
  useContext(ProviderContext);
