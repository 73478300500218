import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { InvoiceVerificationForm } from "@/contractor/pages/home/invoices/pages/invoice-verification/components/matched-order/components/InvoiceVerificationForm";
import { InvoiceVerificationProvider } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import {
  InvoiceForm,
  InvoiceFormType,
} from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/invoice-list-actions/add-invoice/InvoiceForm";
import { InvoiceCreationProvider } from "@/contractor/pages/home/invoices/pages/scanned-invoices/providers/InvoiceCreationProvider";
import { InvoicesProvider } from "@/contractor/pages/home/invoices/pages/scanned-invoices/providers/InvoicesProvider";
import { AuthorizationStatus } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { BsPlus } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useRelease } from "../../../../providers/ReleaseProvider";

const AddInvoiceDocument = tw.div`
  grid grid-flow-col justify-start gap-2 items-center text-blue-500 hover:text-blue-300 cursor-pointer text-sm
`;
const AddInvoiceDocumentButton = tw.button`
  grid place-items-center border-gray-300 w-17 h-17 border rounded-md  text-3xl
`;

export const AddInvoiceBtn: FC = () => {
  const { locations } = useUserLocations();
  const { release } = useRelease();
  const hasPermissionInOrgLocation = useMemo(
    () =>
      locations.some(
        (l) => l.permissions.createInvoice === AuthorizationStatus.Authorized,
      ),
    [locations],
  );

  if (!hasPermissionInOrgLocation) {
    return null;
  }

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <AddInvoiceDocument onClick={() => togglePanel(true)}>
          <AddInvoiceDocumentButton>
            <BsPlus />
          </AddInvoiceDocumentButton>
          <FormattedMessage id="ADD_INVOICE" />
        </AddInvoiceDocument>
      )}
      content={(togglePanel) => (
        <InvoiceCreationProvider>
          <InvoicesProvider>
            <InvoiceVerificationForm>
              <InvoiceVerificationProvider>
                <InvoiceForm
                  onClose={() => togglePanel(false)}
                  type={InvoiceFormType.ADD}
                  releaseId={release?.id}
                />
              </InvoiceVerificationProvider>
            </InvoiceVerificationForm>
          </InvoicesProvider>
        </InvoiceCreationProvider>
      )}
    />
  );
};
