import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { routes } from "@/config/routes";
import {
  InvoiceSummaryFieldsFragment,
  ReceiptSummaryFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-col items-center justify-center`;
const BuyoutNumber = tw.div`text-2xs`;
const OrderNumber = tw.div`text-sm`;
const LinkLikeStyled = tw(LinkLike)`
${({ disabled }) => (disabled ? "text-black font-normal" : "")}
`;
const PoNumber = tw.div`text-2xs`;

type Props = {
  item: InvoiceSummaryFieldsFragment | ReceiptSummaryFieldsFragment;
  disabled?: boolean;
};

export const InvoiceOrderAndBuyout: FC<Props> = ({ item, disabled }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <If isTrue={item.release?.buyout?.id}>
        <LinkLikeStyled
          onClick={() =>
            navigate(
              generatePath(routes.buyout, {
                id: item.release?.buyout?.id || "",
              }),
            )
          }
          disabled={disabled}
        >
          <FormattedMessage
            id="BUYOUT"
            values={{ number: item.release?.buyout?.clientIdentifier }}
            tagName={BuyoutNumber}
          />
        </LinkLikeStyled>
      </If>
      <If isTrue={item.release?.id && item.release?.sequenceNumber}>
        <LinkLikeStyled
          onClick={() =>
            navigate(
              generatePath(routes.delivery, {
                deliveryId: item.release?.id || "",
              }),
            )
          }
          disabled={disabled}
        >
          <FormattedMessage
            id="DELIVERY_WITH_NUMBER"
            values={{ number: item.release?.sequenceNumber }}
            tagName={OrderNumber}
          />
        </LinkLikeStyled>
      </If>
      <If isTrue={item.release?.poNumber}>
        <FormattedMessage
          id="ORDER_PO_SHORT_WITH_NUMBER"
          values={{ number: item.release?.poNumber }}
          tagName={PoNumber}
        />
      </If>
    </Container>
  );
};
