import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { FC } from "react";
import tw from "tailwind-styled-components";

const OutlinedButtonStyled = tw(OutlinedButton)`px-0 min-w-10`;

export const DeliveryIssues: FC<{ count: number }> = ({ count }) => {
  return count > 0 ? (
    <OutlinedButtonStyled $small>{count}</OutlinedButtonStyled>
  ) : (
    <>--</>
  );
};
