import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { routes } from "@/config/routes";
import { BuyoutFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import { useBuyoutQuoted } from "../providers/BuyoutQuotedProvider";

type Props = {
  buyout: BuyoutFieldsFragment;
};

export const BuyoutQuotedSubmitButton: FC<Props> = ({ buyout }) => {
  const intl = useIntl();
  const {
    hasMultipleBuyouts,
    nextNonActivatedBuyout,
    activatedBuyoutClientIdentifiers,
    activateBuyout,
  } = useBuyoutQuoted();

  const { openDialog } = useDialog();
  const { setSuccessAlert, setWarningAlert } = useSnackbar();
  const navigate = useNavigate();

  const handleBuyoutSuccessActivation = useCallback(() => {
    if (nextNonActivatedBuyout) {
      navigate(
        generatePath(routes.buyout, {
          id: nextNonActivatedBuyout.id,
        }),
      );
    } else {
      navigate(generatePath(routes.buyouts));
      if (hasMultipleBuyouts) {
        setSuccessAlert(
          intl.$t(
            { id: "MULTIPLE_BUYOUTS_SUBMITTED_SUCCESS" },
            {
              buyoutCount: activatedBuyoutClientIdentifiers.length,
              buyoutIdentifiers: activatedBuyoutClientIdentifiers.join(", "),
            },
          ),
        );
      }
    }
  }, [
    nextNonActivatedBuyout,
    hasMultipleBuyouts,
    activatedBuyoutClientIdentifiers,
    setSuccessAlert,
    navigate,
    intl,
  ]);

  const openSubmitBuyoutDialog = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PROCEED" }),
      icon: <WarningIcon />,
      title: intl.$t(
        { id: "CREATE_BUYOUT_NUMBER" },
        { buyoutIdentifier: buyout.clientIdentifier },
      ),
      text: intl.$t({ id: "CONFIRM_BUYOUT_SUBMIT" }),
      handleConfirm: async () => {
        const success = await activateBuyout({ buyoutId: buyout.id });
        const message = intl.$t(
          {
            id: success
              ? "BUYOUT_SUBMIT_SUCCESS_NUMBER"
              : "BUYOUT_SUBMIT_FAILURE_NUMBER",
          },
          { buyoutIdentifier: buyout.clientIdentifier },
        );
        if (success) {
          setSuccessAlert(message);
          handleBuyoutSuccessActivation();
        } else {
          setWarningAlert(message);
        }
      },
    });
  }, [
    buyout,
    openDialog,
    activateBuyout,
    setSuccessAlert,
    handleBuyoutSuccessActivation,
    setWarningAlert,
    intl,
  ]);

  return (
    <PrimaryButton onClick={openSubmitBuyoutDialog} testId="create-buyout">
      <FormattedMessage
        id={
          hasMultipleBuyouts ? "CREATE_BUYOUT_NUMBER" : "CREATE_SINGLE_BUYOUT"
        }
        values={{ buyoutIdentifier: buyout.clientIdentifier }}
      />
    </PrimaryButton>
  );
};
