import { useSourceSystems } from "@/common/hooks/integrations/source-system-config/useSourceSystems";
import { SourceSystemMappingFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { LineItemLabel } from "../../../Vendors.styles";
import {
  ExternalVendorConnection,
  VendorFormVendorConnection,
} from "./VendorFormVendorConnection";
const Container = tw.div`bg-gray-100 mt-0 pt-10 pb-5 px-6`;

type Props = {
  mappings: SourceSystemMappingFieldsFragment[];
  vendorId?: string;
};

export const VendorFormVendorConnections: FC<Props> = ({
  mappings,
  vendorId,
}) => {
  const { sourceSystems } = useSourceSystems({ connected: true });

  const integrations: ExternalVendorConnection[] = useMemo(
    () =>
      sourceSystems.map((ss) => {
        const mapping = mappings.find(
          (m) => m.sourceSystem === ss.sourceSystem.system,
        );
        return {
          logo: ss.config.logo.normal,
          name: ss.config.name,
          type: ss.config.description,
          sourceSystem: ss.sourceSystem.system,
          isConnected: !!ss?.sourceSystem.connected,
          isMapped: !!mapping,
          externalId: mapping?.externalId,
        };
      }),
    [sourceSystems, mappings],
  );

  return (
    <Container>
      <FormattedMessage id="CONNECTIONS" tagName={LineItemLabel} />
      {integrations.map((item, key) => (
        <VendorFormVendorConnection item={item} key={key} vendorId={vendorId} />
      ))}
    </Container>
  );
};
