import { UomFieldsFragment } from "@/generated/graphql";
import { InputLabelProps } from "@mui/material";
import React, { FC } from "react";
import { FormattedNumber } from "react-intl";
import tw from "tailwind-styled-components";
import {
  NumericInputProps,
  NumericalInput,
  ReadonlySx,
} from "../numerical-input/NumericalInput";
import { StyledReadonly } from "../styled-readonly/StyledReadonly";
import { StyledStaticText } from "../styled-static-text/StyledStaticText";
import { Tooltip } from "../tooltip/Tooltip";
import { ValueUnit } from "../value-unit/ValueUnit";

const InputStyled = tw(NumericalInput)`w-full print:bg-transparent`;
const UnitsInputContainer = tw.div`relative`;

type Props = NumericInputProps & {
  value?: string | number | null | undefined;
  label?: string | React.JSX.Element | null;
  errorMessage?: string;
  hoverLabel?: string;
  id: string;
  testId?: string;
  inputClassName?: string;
  staticText?: boolean;
  uom?: UomFieldsFragment | null;
  customError?: React.JSX.Element;
  hideErrorIcon?: boolean;
  includeUomMnemonic?: boolean;
  InputLabelProps?: InputLabelProps;
  className?: string;
  decimals?: number;
};

export const StyledNumericInput: FC<Props> = ({
  readonly,
  onBlur,
  onChange,
  error,
  value,
  testId,
  label,
  errorMessage,
  hoverLabel,
  id,
  inputClassName = "",
  staticText,
  uom,
  customError,
  hideErrorIcon,
  includeUomMnemonic,
  InputLabelProps,
  className,
  decimals = 2,
  ...props
}) => {
  if (staticText) {
    return (
      <StyledStaticText>
        <ValueUnit
          uom={uom}
          value={value || 0}
          includeMnemonic={includeUomMnemonic}
        />
      </StyledStaticText>
    );
  }
  if (readonly && value) {
    return (
      <StyledReadonly title={String(value)}>
        <FormattedNumber
          value={Number(value)}
          maximumFractionDigits={decimals}
        />
      </StyledReadonly>
    );
  }
  if (hoverLabel) {
    return (
      <Tooltip
        element={
          <UnitsInputContainer>
            <InputStyled
              size={readonly ? "medium" : "small"}
              value={value}
              label={label}
              decimals={decimals}
              onBlur={onBlur}
              onChange={onChange}
              inputProps={{
                disabled: readonly,
                style: { marginTop: 0 },
                className: `text-sm px-2 py-1.5 text-center pr-0 ${inputClassName}`,
              }}
              sx={readonly ? ReadonlySx : null}
              classes={{
                root: `${readonly ? "bg-gray-100 " : ""} border-0`,
              }}
              error={error}
              customError={customError}
              testId={testId}
              InputLabelProps={InputLabelProps}
              className={className}
              {...props}
            />
          </UnitsInputContainer>
        }
        id={id}
      >
        {hoverLabel}
      </Tooltip>
    );
  }
  return (
    <UnitsInputContainer>
      <InputStyled
        size={readonly ? "medium" : "small"}
        value={value}
        label={label}
        decimals={decimals}
        onBlur={onBlur}
        onChange={onChange}
        inputProps={{
          disabled: readonly,
          style: { marginTop: 0 },
          className: `text-sm px-2 py-1.5 text-center ${inputClassName}`,
        }}
        sx={readonly ? ReadonlySx : null}
        classes={{
          root: `${readonly ? "bg-gray-100 " : ""} border-0`,
        }}
        error={error}
        errorMessage={errorMessage}
        customError={customError}
        hideErrorIcon={hideErrorIcon}
        InputLabelProps={InputLabelProps}
        testId={testId}
        {...props}
      />
    </UnitsInputContainer>
  );
};
