import { UploadAsset } from "@/common/components/upload-asset/UploadAsset";
import { AssetFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { Accept } from "react-dropzone";
import { BsInfoCircle } from "react-icons/bs";
import tw from "tailwind-styled-components";

type Props = {
  header?: string;
  description?: string;
  accept?: Accept;
  supportedFormats?: string;
  classes?: {
    container?: string;
    uploadContainer?: string;
  };
  includeAssets?: boolean;
  limit?: number;
  onChange?: (assets: AssetFieldsFragment[]) => void;
  centered?: boolean;
};

const Container = tw.div`flex flex-1 flex-col bg-gray-100 rounded-3xl items-center justify-center `;
const Row = tw.div`flex flex-row mb-8 items-center font-medium text-md`;
const Info = tw(BsInfoCircle)`text-gray-500 mr-4 text-4xl`;
const SupportedFormats = tw.div`text-center text-xs text-gray-600 mt-2`;
const View = tw.div``;
const Title = tw.div`text-xl font-bold my-4`;
const UploadContainer = tw.div`w-[50%]`;

export const AssetUploadStep: FC<Props> = ({
  header,
  description,
  accept,
  supportedFormats,
  classes,
  includeAssets,
  limit,
  onChange,
  centered = false,
}) => {
  return (
    <>
      {header && <Title>{header}</Title>}
      <Container className={classes?.container}>
        {description && (
          <Row>
            <Info />
            <View>{description}</View>
          </Row>
        )}
        <UploadContainer className={classes?.uploadContainer}>
          <UploadAsset
            classes={{
              container: "bg-white w-full h-full",
              fileContainer: centered ? "justify-center" : undefined,
            }}
            accept={accept}
            limit={limit}
            includeAssets={includeAssets}
            mode="horizontal"
            onChange={onChange}
            testId="asset-upload-step"
          >
            <SupportedFormats>{supportedFormats}</SupportedFormats>
          </UploadAsset>
        </UploadContainer>
      </Container>
    </>
  );
};
