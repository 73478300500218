import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useUser } from "@/common/providers/UserProvider";
import { getUserName } from "@/common/utils/users/getUserName";
import { ViewerProfileForm } from "@/distributor/common/profile/ViewerProfileForm";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import {
  ButtonStyled,
  Container,
  UserAvatarStyled,
  UserFullName,
} from "./UserMenu.styles";

export const DistributorUserMenu = () => {
  const { viewer } = useUser();
  const { openDialog } = useDialog();
  const intl = useIntl();
  const onClick = useCallback(() => {
    openDialog({
      title: intl.$t({ id: "EDIT_YOUR_PROFILE" }),
      text: <ViewerProfileForm />,
    });
  }, [intl, openDialog]);
  return (
    <Container>
      <ButtonStyled id="user-menu-button" color="inherit" onClick={onClick}>
        <UserAvatarStyled
          photoUrl={viewer?.photoUrl}
          firstName={viewer?.firstName}
          lastName={viewer?.lastName}
          email={viewer?.email}
        />
      </ButtonStyled>
      <ButtonStyled id="user-menu-button" color="inherit" onClick={onClick}>
        <UserFullName>{getUserName(viewer)}</UserFullName>
      </ButtonStyled>
    </Container>
  );
};
