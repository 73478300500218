import { Notes } from "@/common/components/notes/Notes";
import { ReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useReleaseActions } from "../../providers/ReleaseActionsProvider";

type Props = {
  item: ReleaseItemFieldsFragment;
  readonly?: boolean;
};

export const ReleaseItemInstructions: FC<Props> = ({
  item,
  readonly = false,
}) => {
  const { expandedItems, setExpandedItems } = useReleaseActions();

  return (
    <Notes
      expandedItems={expandedItems}
      setExpandedItems={setExpandedItems}
      item={item}
      readonly={readonly}
      hasNotesOrAttachments={
        !!item.instructions?.text || !!item.instructions?.assets?.length
      }
    />
  );
};
