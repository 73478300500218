import { FC } from "react";
import {
  FooterCell,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterProps,
  FooterTotal,
} from "../../../../../../../common/components/grid-table/styles/Footer.styles";

export const ReleaseEmptyFooter: FC<FooterProps> = ({ Wrapper }) => (
  <FooterCol>
    <Wrapper className="flex-col">
      <FooterHeader />
      <FooterCell />
      <FooterCell />
      <FooterCell />
      <FooterDashedLine />
      <FooterTotal />
    </Wrapper>
  </FooterCol>
);
