import {
  ControlledProps,
  TextFieldControlled,
} from "@/common/components/textfield-controlled/TextFieldControlled";
import { FC } from "react";
import { useIntl } from "react-intl";

export const ProjectAddressTextField: FC<ControlledProps> = (props) => {
  const intl = useIntl();

  return (
    <TextFieldControlled
      size="small"
      label={intl.$t({ id: "PROJECT_ADDRESS" })}
      rules={{ required: true }}
      {...props}
    />
  );
};
