import { GridTable } from "@/common/components/grid-table/GridTable";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { InvoiceEmailFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { SplittingInvoicesWizardModal } from "../../../scanned-invoices/components/splitting-invoices/SplittingInvoicesWizardModal";
import { useSplittingInvoicesWizard } from "../../../scanned-invoices/components/splitting-invoices/SplittingInvoicesWizardProvider";
import { useInvoiceEmailsConfiguration } from "./InvoiceEmail.configuration";

type Props = {
  invoiceEmails: InvoiceEmailFieldsFragment[];
  loading: boolean;
  totalCount: number;
  isFiltered: boolean;
};

export const InvoiceEmailsList: FC<Props> = ({
  invoiceEmails,
  loading,
  totalCount,
  isFiltered,
}) => {
  const configuration = useInvoiceEmailsConfiguration();
  const { openWizard } = useSplittingInvoicesWizard();

  const openInvoiceEmail = useCallback(
    (invoiceEmail: InvoiceEmailFieldsFragment) => {
      openWizard({
        initialPage: 0,
        urls: invoiceEmail.attachments.map(
          (attachment) => attachment.asset.url,
        ),
        invoiceEmail,
      });
    },
    [openWizard],
  );

  return (
    <ListRenderer
      totalCount={totalCount}
      count={invoiceEmails.length}
      paginated
    >
      <GridTable
        configuration={{
          columns: configuration,
          classNames: {
            itemContent: "grid-cols-[50px_repeat(5,_auto)]",
            header: "top-[128px] xl:top-[140px] bg-transparent",
            container: "pt-6 xl:pt-2",
            root: "pt-2",
          },
          hasCardItems: true,
          toggle: {
            item: openInvoiceEmail,
          },
        }}
        items={invoiceEmails}
        loading={loading}
        emptyList={
          <NoResults
            isFiltered={isFiltered}
            filteredTranslationKey="NO_RESULTS_INVOICES_FILTERED"
            translationKey="NO_RESULTS_INVOICE_EMAILS"
          />
        }
      />
      <SplittingInvoicesWizardModal />
    </ListRenderer>
  );
};
