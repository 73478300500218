import { HeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { If } from "@/common/components/if/If";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import {
  TableViewProvider,
  VIEW_STATE,
  useTableView,
} from "@/common/providers/TableViewProvider";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { AddCodeMenu } from "./components/AddCodeMenu";
import { CostCodeList } from "./components/CostCodeList";
import { useCostCodesConfiguration } from "./components/CostCodes.tableConfiguration";
import { CostCodesFooter } from "./components/CostCodesFooter";
import { ImportExternalCostCodesButton } from "./components/ImportExternalCostCodesButton";
import { ImportExternalCostCodesModal } from "./components/import-external-codes/ImportExternalCostCodesModal";
import {
  CostCodesListProvider,
  useCostCodesList,
} from "./providers/CostCodesListProvider";
import { ImportExternalCostCodesProvider } from "./providers/ImportExternalCostCodesProvider";
import { SyncCostCodesProvider } from "./providers/SyncCostCodesProvider";

const HeaderContainerStyled = tw(HeaderContainer)`
  grid grid-flow-col gap-5 items-center justify-between lg:bg-gray-100 top-[57px] xl:top-[57px]
`;
const HeaderRightPanel = tw.div`flex flex-row`;
const Filters = tw.div`
  grid grid-flow-col gap-5 items-start
`;
const SpreadSheetContainer = tw.div`pt-12`;

const CostCodesWithProvider = () => {
  const intl = useIntl();
  const { costCodes } = useCostCodesList();
  const { spreadsheetConfig } = useCostCodesConfiguration();
  const { tableView } = useTableView();

  const [searchQuery, setSearchQuery] = useState("");

  const filteredCostCodes = useMemo(() => {
    if (!searchQuery) {
      return costCodes;
    }
    return costCodes.filter((costCode) => {
      return (
        (costCode.description
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) &&
          costCode.description.length > 0) ||
        costCode.code.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [costCodes, searchQuery]);

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "COST_CODES" })}</title>
      </Helmet>
      <If isTrue={tableView === VIEW_STATE.normal}>
        <HeaderContainerStyled>
          <Filters>
            <SearchInput
              placeHolder={intl.$t({ id: "SEARCH_COST_CODE" })}
              onChange={setSearchQuery}
            />
          </Filters>
          <HeaderRightPanel>
            <ImportExternalCostCodesButton />
            <AddCodeMenu />
          </HeaderRightPanel>
        </HeaderContainerStyled>
        <CostCodeList items={filteredCostCodes} />
      </If>
      <If isTrue={tableView === VIEW_STATE.spreadsheet}>
        <SpreadSheetContainer>
          <SpreadSheetTable
            items={costCodes}
            columns={spreadsheetConfig}
            height="calc(100vh - 320px)"
          />
        </SpreadSheetContainer>
      </If>
      <CostCodesFooter />
      <ImportExternalCostCodesModal />
    </>
  );
};

export const CostCodes = () => {
  const { spreadsheetConfig } = useCostCodesConfiguration();

  return (
    <NestedStepperProvider>
      <CostCodesListProvider>
        <ImportExternalCostCodesProvider>
          <ColumnMapperProvider config={spreadsheetConfig}>
            <TableViewProvider defaultView={VIEW_STATE.normal}>
              <SyncCostCodesProvider>
                <CostCodesWithProvider />
              </SyncCostCodesProvider>
            </TableViewProvider>
          </ColumnMapperProvider>
        </ImportExternalCostCodesProvider>
      </CostCodesListProvider>
    </NestedStepperProvider>
  );
};
