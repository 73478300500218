import { If } from "@/common/components/if/If";
import { PhoneView } from "@/common/components/phone-input/PhoneView";
import { SiteContact } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

type Props = {
  siteContact?: SiteContact | null;
};

const Container = tw.div`flex gap-1 items-center`;
const Name = tw.div``;
const Phone = tw.div`font-light`;

export const DistributorSiteContact: FC<Props> = ({ siteContact }) => {
  if (!siteContact) {
    return null;
  }
  return (
    <Container>
      <Name>{siteContact.name}</Name>
      <If isTrue={siteContact.phone}>
        <Phone>
          <PhoneView value={siteContact.phone ?? ""} />
        </Phone>
      </If>
    </Container>
  );
};
