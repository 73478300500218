import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  ConnectNodeInput,
  DisconnectNodeInput,
  useConnectOrgPreferredVendorMutation,
  useDisconnectOrgPreferredVendorMutation,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import React, { FC, createContext, useCallback, useContext } from "react";
import { useIntl } from "react-intl";

type ProviderContextType = {
  connectVendor: (input: ConnectNodeInput) => Promise<boolean>;
  disconnectVendor: (input: DisconnectNodeInput) => Promise<boolean>;
  connecting: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  connectVendor: NoFunctionBooleanPromise,
  disconnectVendor: NoFunctionBooleanPromise,
  connecting: false,
});

export const AgaveExternalVendorProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { setSuccessAlert } = useSnackbar();

  const [connectOrgPreferredVendorMutation, { loading: connecting }] =
    useConnectOrgPreferredVendorMutation();
  const connectVendor = useCallback(
    async (input: ConnectNodeInput) => {
      try {
        const { data } = await connectOrgPreferredVendorMutation({
          variables: {
            input,
          },
        });

        setSuccessAlert(
          intl.$t({
            id: "VENDOR_CONNECTED_SUCCESSFULLY",
          }),
        );
        return !!data?.connectOrgPreferredVendor;
      } catch (error) {
        setError(error);
        return false;
      }
    },
    [connectOrgPreferredVendorMutation, setError, setSuccessAlert, intl],
  );

  const [disconnectOrgPreferredVendorMutation] =
    useDisconnectOrgPreferredVendorMutation();
  const disconnectVendor = useCallback(
    async (input: DisconnectNodeInput) => {
      try {
        const { data } = await disconnectOrgPreferredVendorMutation({
          variables: {
            input,
          },
        });

        setSuccessAlert(
          intl.$t({
            id: "VENDOR_DISCONNECTED_SUCCESSFULLY",
          }),
        );
        return !!data?.disconnectOrgPreferredVendor;
      } catch (error) {
        setError(error);
        return false;
      }
    },
    [disconnectOrgPreferredVendorMutation, setError, setSuccessAlert, intl],
  );

  return (
    <ProviderContext.Provider
      value={{
        connectVendor,
        disconnectVendor,
        connecting,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useAgaveExternalVendorProvider = () => useContext(ProviderContext);
