import { FC, memo } from "react";
import { Link, useLocation } from "react-router-dom";
import tw from "tailwind-styled-components";

const LinkStyled = tw(Link)`
  grid grid-flow-col items-center gap-1 justify-start
  text-sm text-blue-500 font-medium cursor-pointer hover:text-blue-300 print:text-black
  ${($clickable: { $clickable?: boolean }) =>
    $clickable ? "pointer-events-auto" : "pointer-events-none"}
`;

const DisabledLink = tw.span`
  text-sm text-gray-500 font-bold gap-1 cursor-default 
`;

type LinkLikeProps = {
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children: React.ReactNode;
  className?: string;
  to?: string;
  disabled?: boolean;
  forwardEvent?: boolean;
  keepSearchParams?: boolean;
  testId?: string;
  target?: string;
};

export const LinkLike: FC<LinkLikeProps> = memo(
  ({
    onClick,
    children,
    className,
    to = "",
    disabled,
    forwardEvent,
    keepSearchParams = false,
    testId,
    target,
  }) => {
    const { search } = useLocation();
    const handleClick = (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
      if (onClick && !forwardEvent) {
        e.stopPropagation();
        e.preventDefault();
      }
      onClick?.(e as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>);
    };

    return disabled ? (
      <DisabledLink className={className}>{children}</DisabledLink>
    ) : (
      <LinkStyled
        onClick={handleClick}
        className={className}
        to={keepSearchParams ? `${to}${search}` : to}
        $clickable={!!(onClick || to)}
        data-testid={testId}
        target={target}
      >
        {children}
      </LinkStyled>
    );
  },
);
