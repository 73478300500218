import { ManufacturerPicker } from "@/common/components/manufacturer-picker/ManufacturerPicker";
import { DistributorBuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useDistributorBuyout } from "../../../providers/DistributorBuyoutProvider";

type Props = {
  item: DistributorBuyoutItemFieldsFragment;
  error?: boolean;
  index?: number;
};

export const DistributorBuyoutManufacturerSelector: FC<Props> = ({
  item,
  index,
  error,
}) => {
  const { updateVendorBuyout } = useDistributorBuyout();

  const saveManufacturer = useCallback(
    async (manufacturerId: string | null) => {
      await updateVendorBuyout({
        updates: [
          {
            buyoutItemId: item.id,
            manufacturerId,
          },
        ],
      });
    },
    [item.id, updateVendorBuyout],
  );

  if (!item) {
    return null;
  }

  return (
    <ManufacturerPicker
      item={item.manufacturer}
      saveManufacturer={saveManufacturer}
      error={error}
      index={index}
    />
  );
};
