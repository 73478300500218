import { If } from "@/common/components/if/If";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";

const Container = tw.div`
  flex mr-1 w-5
`;

export const BuyoutItemGroupCheckbox: FC<{
  group:
    | CategoryState<BuyoutItemFieldsFragment>
    | CategoryState<CategoryState<BuyoutItemFieldsFragment>>;
}> = ({ group }) => {
  const { selectedBuyoutItems, setSelectedBuyoutItems } = useContractorBuyout();

  const checked = useMemo(
    () =>
      group.items.every((item) => {
        if ((item as CategoryState<BuyoutItemFieldsFragment>).items) {
          return (item as CategoryState<BuyoutItemFieldsFragment>).items.every(
            (subitem) => selectedBuyoutItems.includes(subitem.id),
          );
        } else {
          return selectedBuyoutItems.includes(item.id);
        }
      }),
    [group.items, selectedBuyoutItems],
  );
  const handleChange = useCallback(
    (newChecked: boolean) => {
      if (newChecked) {
        const idsToAdd = group.items
          .map((item) => {
            if ((item as CategoryState<BuyoutItemFieldsFragment>).items) {
              return (
                item as CategoryState<BuyoutItemFieldsFragment>
              ).items.map((i) => i.id);
            } else {
              return (item as BuyoutItemFieldsFragment).id;
            }
          })
          .flat();
        setSelectedBuyoutItems([...selectedBuyoutItems, ...idsToAdd]);
      } else {
        const idsToRemove = group.items
          .map((item) => {
            if ((item as CategoryState<BuyoutItemFieldsFragment>).items) {
              return (
                item as CategoryState<BuyoutItemFieldsFragment>
              ).items.map((i) => i.id);
            } else {
              return (item as BuyoutItemFieldsFragment).id;
            }
          })
          .flat();
        setSelectedBuyoutItems(
          selectedBuyoutItems.filter((r) => !idsToRemove.includes(r)),
        );
      }
    },
    [group.items, selectedBuyoutItems, setSelectedBuyoutItems],
  );

  const hasItems = useMemo(() => {
    if (
      group.items.some(
        (item) => (item as CategoryState<BuyoutItemFieldsFragment>).items,
      )
    ) {
      return group.items.some((item) =>
        (item as CategoryState<BuyoutItemFieldsFragment>).items.some(
          (subitem) => subitem.id,
        ),
      );
    }
    return group.items.some((item) => item.id);
  }, [group.items]);

  return (
    <Container>
      <If isTrue={hasItems}>
        <SelectionCheckbox
          testId={`group-checkbox-${group.name.toLowerCase().replaceAll(/\s/g, "-")}`}
          checked={checked}
          setSelection={handleChange}
        />
      </If>
    </Container>
  );
};
