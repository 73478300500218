import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import {
  FooterCell,
  FooterCellCol,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterProps,
  FooterTotal,
} from "../../../../../../../common/components/grid-table/styles/Footer.styles";
import { useReleaseItemsZoneProvider } from "../../../providers/ReleaseItemsZonesProvider";

const marginRightClass = "mr-8";

export const ReleaseInvoicedFooter: FC<FooterProps> = ({ Wrapper }) => {
  const {
    invoicedSubtotal,
    invoicedAdditionalCharges,
    invoicedSalesTax,
    noPrices,
  } = useReleaseItemsZoneProvider();

  return (
    <FooterCol>
      <Wrapper className="flex-col">
        <FooterHeader className={marginRightClass}>
          <FormattedMessage
            id="ORDER_ITEM_INVOICED"
            tagName={FooterHeaderText}
          />
        </FooterHeader>
        <FooterCell className={marginRightClass}>
          <NotNullableRenderer value={!noPrices}>
            <Price price={invoicedSubtotal} className="font-light" />
          </NotNullableRenderer>
        </FooterCell>
        <FooterCell className={marginRightClass}>
          <NotNullableRenderer value={!noPrices}>
            <Price price={invoicedAdditionalCharges} className="font-light" />
          </NotNullableRenderer>
        </FooterCell>
        <FooterCell className={marginRightClass}>
          <NotNullableRenderer value={!noPrices}>
            <Price price={invoicedSalesTax} className="font-light" />
          </NotNullableRenderer>
        </FooterCell>
        <FooterDashedLine />
        <FooterTotal>
          <FooterCellCol className={marginRightClass}>
            <NotNullableRenderer value={!noPrices}>
              <Price
                price={invoicedSubtotal
                  .plus(invoicedAdditionalCharges)
                  .plus(invoicedSalesTax)}
              />
            </NotNullableRenderer>
          </FooterCellCol>
        </FooterTotal>
      </Wrapper>
    </FooterCol>
  );
};
