import { If } from "@/common/components/if/If";
import { StyledSelect } from "@/common/components/select/components/single/StyledSelect";
import { TextField } from "@/common/components/textfield/TextField";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import {
  DistributorReleaseItemFieldsFragment,
  ReleaseIssueResolutionType,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDistributorReleaseDeliveryRecord } from "../providers/DistributorReleaseDeliveryRecordProvider";
import { DistributorReleaseItemIssueSolutionDateSelector } from "./DistributorReleaseItemIssueSolutionDateSelector";

type Props = {
  item: DistributorReleaseItemFieldsFragment;
};

const Container = tw.div``;
const Headline = tw.div`font-medium px-6`;
const Details = tw.div`my-2 py-4 px-6 bg-gray-100`;
const Row = tw.div`grid grid-flow-col w-full gap-4 items-center`;
const Title = tw.div`font-medium text-base mt-3`;
const TextFieldStyled = tw(TextField)`
  w-full mt-2
`;

export const DistributorReleaseItemIssueSolution: FC<Props> = ({ item }) => {
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { issueResolutions, setIssueResolution, setIssueComments } =
    useDistributorReleaseDeliveryRecord();

  const issue = useMemo(() => item?.issues?.[0], [item]);

  const selectedIssueSolutions = useMemo(
    () => issueResolutions.find((r) => r.issueId === issue?.id),
    [issueResolutions, issue?.id],
  );

  const selectedResolutionType = useMemo(() => {
    return (
      issue?.resolution?.resolutionType ||
      selectedIssueSolutions?.edit?.resolutionType ||
      selectedIssueSolutions?.resolutionType
    );
  }, [issue?.resolution?.resolutionType, selectedIssueSolutions]);

  const selectedComments = useMemo(
    () =>
      issue?.resolution?.comments ||
      selectedIssueSolutions?.edit?.comments ||
      selectedIssueSolutions?.comments,
    [issue?.resolution?.comments, selectedIssueSolutions],
  );

  const solutions = useMemo(
    () => [
      {
        value: ReleaseIssueResolutionType.AdjustInvoice,
        label: `${intl.$t({ id: "ADJUST_INVOICE" })} (${intl.$t(
          {
            id: "SUBTRACT",
          },
          {
            amount: formatCurrency(
              Number(
                new Decimal(item.unitPrice || 0).mul(
                  issue?.quantityDecimal || 0,
                ),
              ),
            ),
          },
        )})`,
      },
      {
        value: ReleaseIssueResolutionType.Redeliver,
        label: `${intl.$t({ id: "REDELIVER" })} (${issue?.quantityDecimal} ${
          item.uom?.mnemonic || item.uom?.pluralDescription
        })`,
      },
      {
        value: ReleaseIssueResolutionType.Other,
        label: `${intl.$t({ id: "OTHER" })} (${intl.$t({
          id: "PLEASE_DESCRIBE_IN_COMMENTS",
        })})`,
      },
    ],
    [
      intl,
      formatCurrency,
      item.unitPrice,
      issue?.quantityDecimal,
      item.uom?.mnemonic,
      item.uom?.pluralDescription,
    ],
  );

  return (
    <Container>
      <Headline>
        <FormattedMessage id="YOUR_SOLUTION" />
      </Headline>
      <Details>
        <Row>
          <StyledSelect
            options={solutions}
            getValue={(opt) => opt.value}
            getLabel={(opt) => opt.label}
            className="flex flex-1"
            onChange={(solutionValue) =>
              setIssueResolution(
                issue?.id,
                solutionValue as ReleaseIssueResolutionType,
              )
            }
            value={selectedResolutionType}
            placeholder={intl.$t({ id: "SELECT_PROPOSED_SOLUTION" })}
            staticText={!!issue?.resolution}
            testId="distributor-solution-selector"
          />
          <If
            isTrue={
              selectedResolutionType === ReleaseIssueResolutionType.Redeliver &&
              !issue?.resolution
            }
          >
            <DistributorReleaseItemIssueSolutionDateSelector item={item} />
          </If>
        </Row>
        <FormattedMessage id="COMMENTS_TO_CONTRACTOR" tagName={Title} />
        <TextFieldStyled
          placeholder={intl.$t({ id: "TYPE_OPTIONAL_COMMENTS" })}
          InputProps={{
            className: `min-h-20 ${
              issue?.resolution ? "bg-gray-100" : "bg-white"
            }`,
          }}
          rows={4}
          staticTextRows={4}
          multiline
          staticText={!!issue?.resolution}
          value={selectedComments}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setIssueComments(issue?.id, event.target.value)
          }
          testId="distributor-solution-comments"
        />
      </Details>
    </Container>
  );
};
