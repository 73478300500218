import { addressToString } from "@/common/components/adress-location/AddressLocation";
import { formatPhone } from "@/common/components/phone-input/formatPhone";
import { PDF_FONT } from "@/common/const";
import { formattedDate } from "@/common/utils/dates/DateView";
import { getVendorContacts } from "@/common/utils/getVendorContacts";
import { drawLogo } from "@/common/utils/pdf-print/drawLogo";
import { getUserName } from "@/common/utils/users/getUserName";
import {
  DistributorReleaseFieldsFragment,
  ServiceType,
} from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable, { FontStyle } from "jspdf-autotable";
import { IntlShape } from "react-intl";

const addBuyoutCreatedBy = (
  doc: jsPDF,
  release: DistributorReleaseFieldsFragment,
  intl: IntlShape,
  x: number,
  y: number,
  width: number,
  logo: string,
  logoProportion: number,
) => {
  let yShift = y;
  let deltaX = 0;
  if (logo) {
    deltaX = drawLogo(doc, logo, logoProportion, x, yShift);
    x += deltaX;
  }
  doc.setFontSize(10).setFont(PDF_FONT, "", "bold");
  doc.text(`${intl.$t({ id: "ORDER_BY" })}:`, x, yShift);
  yShift += 5;
  doc.setFontSize(8).setFont(PDF_FONT, "", "normal");
  doc.text(getUserName(release.createdBy), x, yShift);
  yShift += 5;
  doc.text(release.project?.location.org.name || "", x, yShift);
  yShift += 5;
  const addressLines = doc.splitTextToSize(
    addressToString(release.project?.location.address, {
      excludeCity: true,
      excludeCountry: true,
      excludePostalCode: true,
      excludeState: true,
    }),
    width - deltaX,
  );
  const addressCityAndState = doc.splitTextToSize(
    addressToString(release.project?.location.address, {
      excludeAddressLines: true,
      excludeCountry: true,
    }),
    width - deltaX,
  );
  const address = [...addressLines, ...addressCityAndState];
  doc.text(address, x, yShift);
  yShift += 5 * address.length;
  return yShift;
};

const addProjectAndDeliveryInfo = (
  doc: jsPDF,
  release: DistributorReleaseFieldsFragment,
  intl: IntlShape,
  x: number,
  y: number,
  width: number,
) => {
  doc.setFontSize(8);
  autoTable(doc, {
    startY: y - 4,
    theme: "plain",
    margin: { left: x },
    tableWidth: width,
    showHead: false,
    styles: {
      fontSize: 8,
      cellPadding: 1,
    },
    columns: [{ dataKey: "label" }, { dataKey: "value" }],
    body: [
      [
        {
          content: `${intl.$t({ id: "PROJECT" })}:`,
          styles: { fontStyle: "bold" },
        },
        release.project?.name || "",
      ],
      ...(release.buyout
        ? [
            [
              {
                content: `${intl.$t({ id: "BUYOUT_NUMBER_SYMBOL" })}:`,
                styles: { fontStyle: "bold" as FontStyle },
              },
              release.buyout.clientIdentifier,
            ],
          ]
        : []),
      [
        {
          content: `${intl.$t({ id: "DELIVERY_ADDRESS" })}:`,
          styles: { fontStyle: "bold" },
        },
        `${addressToString(
          release.warehouse?.address ?? release.project?.address,
          {
            excludeCity: true,
            excludeCountry: true,
            excludePostalCode: true,
            excludeState: true,
          },
        )}\n${addressToString(
          release.warehouse?.address ?? release.project?.address,
          { excludeAddressLines: true, excludeCountry: true },
        )}`,
      ],
      [
        {
          content: `${intl.$t({ id: "DELIVERY_DATE" })}:`,
          styles: { fontStyle: "bold" },
        },
        formattedDate({
          date: new Date(release.time || 0),
          includeTime: true,
        }),
      ],
      ...(release.siteContact
        ? [
            [
              {
                content: `${intl.$t({ id: "PROJECT_SITE_CONTACT" })}:`,
                styles: { fontStyle: "bold" as FontStyle },
              },
              `${release.siteContact.name} ${formatPhone(release.siteContact.phone || "")}`,
            ],
          ]
        : []),
    ],
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (doc as any).lastAutoTable.finalY;
};

const addFulfillmentInformation = (
  doc: jsPDF,
  release: DistributorReleaseFieldsFragment,
  intl: IntlShape,
  x: number,
  y: number,
) => {
  let yShift = y;
  doc.setFontSize(10).setFont(PDF_FONT, "", "bold");
  doc.text(intl.$t({ id: "FULFILLMENT" }), x, yShift);
  yShift += 7;
  doc.setFontSize(8).setFont(PDF_FONT, "", "normal");
  doc.text(
    `${intl.$t({ id: "DELIVERY" })}: ${serviceTypeName(
      release.includeServices.includes(ServiceType.Delivery),
      intl,
      "CLIENT_DELIVERY",
    )}`,
    x,
    yShift,
  );
  yShift += 5;
  doc.text(
    `${intl.$t({ id: "STOCKING" })}: ${serviceTypeName(
      release.includeServices.includes(ServiceType.Stocking),
      intl,
      "CLIENT_STOCKING",
    )}`,
    x,
    yShift,
  );
  yShift += 5;
  return yShift;
};

const addReceiverInfo = (
  doc: jsPDF,
  release: DistributorReleaseFieldsFragment,
  intl: IntlShape,
  x: number,
  y: number,
  width: number,
  logo: string,
  logoProportion: number,
) => {
  let yShift = y;
  let deltaX = 0;
  if (logo) {
    deltaX = drawLogo(doc, logo, logoProportion, x, yShift);
    x += deltaX;
  }
  doc.setFontSize(10).setFont(PDF_FONT, "", "bold");
  doc.text(`${intl.$t({ id: "ORDER_FOR" })}:`, x, yShift);
  yShift += 5;
  doc.setFontSize(8).setFont(PDF_FONT, "", "normal");
  const vendorContacts =
    getVendorContacts(
      release.preferredVendor?.contacts.filter(
        (c) => c.receivesOrderNotifications,
      ),
    ) || "";
  const splitVendorContacts = doc.splitTextToSize(
    vendorContacts,
    width - deltaX,
  );
  doc.text(splitVendorContacts, x, yShift);
  yShift += 5 * splitVendorContacts.length;
  doc.text(release.sellerOrgLocation?.org.name as string, x, yShift);
  yShift += 5;
  const addressLines = doc.splitTextToSize(
    addressToString(release.sellerOrgLocation?.address, {
      excludeCity: true,
      excludeCountry: true,
      excludePostalCode: true,
      excludeState: true,
    }),
    width - deltaX,
  );
  const addressCityAndState = doc.splitTextToSize(
    addressToString(release.sellerOrgLocation?.address, {
      excludeAddressLines: true,
      excludeCountry: true,
    }),
    width - deltaX,
  );
  const address = [...addressLines, ...addressCityAndState];
  doc.text(address, x, yShift);
  yShift += 5 * address.length;
  return yShift;
};

const serviceTypeName = (
  includeService: boolean,
  intl: IntlShape,
  notIncludeMessage: string,
) => {
  if (includeService) {
    return intl.$t({ id: "VENDOR" });
  }
  return intl.$t({ id: notIncludeMessage });
};

export const releaseHeader = (
  doc: jsPDF,
  release: DistributorReleaseFieldsFragment,
  intl: IntlShape,
  topSpacing: number,
  contractorLogo: string,
  contractorLogoProportion: number,
  vendorLogo: string,
  vendorLogoProportion: number,
) => {
  autoTable(doc, {
    startY: topSpacing,
    theme: "plain",
    showHead: false,
    styles: {
      font: PDF_FONT,
    },
    body: [["", "", "", ""]],
    didDrawCell: function (data) {
      if (data.row.index === 0 && data.row.section === "body") {
        if (data.column.index === 0) {
          const yShift = addBuyoutCreatedBy(
            doc,
            release,
            intl,
            data.cell.x + 2,
            data.cell.y,
            data.cell.width - 2,
            contractorLogo,
            contractorLogoProportion,
          );
          topSpacing = Math.max(topSpacing, yShift);
        } else if (data.column.index === 1) {
          const yShift = addProjectAndDeliveryInfo(
            doc,
            release,
            intl,
            data.cell.x,
            data.cell.y,
            data.cell.width,
          );
          topSpacing = Math.max(topSpacing, yShift);
        } else if (data.column.index === 2) {
          const yShift = addReceiverInfo(
            doc,
            release,
            intl,
            data.cell.x + 10,
            data.cell.y,
            data.cell.width - 10,
            vendorLogo,
            vendorLogoProportion,
          );
          topSpacing = Math.max(topSpacing, yShift);
        } else if (data.column.index === 3) {
          const yShift = addFulfillmentInformation(
            doc,
            release,
            intl,
            data.cell.x,
            data.cell.y,
          );
          topSpacing = Math.max(topSpacing, yShift);
        }
      }
    },
  });
  return topSpacing;
};
