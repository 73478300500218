import { TextFieldProps } from "@mui/material";
import { FC } from "react";
import { TextField } from "../textfield/TextField";
import { PhoneMask } from "./PhoneMask";

type PhoneInputProps = TextFieldProps & { countryCode?: string | null };

export const PhoneInput: FC<PhoneInputProps> = ({
  className,
  inputProps,
  countryCode,
  ...props
}) => (
  <TextField
    {...props}
    className={className}
    inputProps={{ ...inputProps }}
    InputProps={{
      inputComponent: PhoneMask,
      inputProps: { countryCode },
    }}
  />
);
