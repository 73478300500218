import { If } from "@/common/components/if/If";
import { useMemo } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceSequence } from "../../providers/InvoiceSequenceProvider";
import { useInvoices } from "../../providers/InvoicesProvider";
import { ExportInvoicesButton } from "./ExportInvoicesButton";
import { ToggleAddInvoiceForm } from "./add-invoice/ToggleAddInvoiceForm";

const Container = tw.div` 
  grid grid-flow-col items-center justify-center gap-2 
  text-base font-medium rounded-[32px] py-1 pl-6 pr-1
  ${({ $exportEnabled }: { $exportEnabled: boolean }) =>
    $exportEnabled ? "bg-blue-450" : ""}
`;

export const InvoiceListActions = () => {
  const { exportEnabled } = useInvoices();
  const { sequenceActive } = useInvoiceSequence();

  const inSequenceFlow = useMemo(
    () => exportEnabled || sequenceActive,
    [exportEnabled, sequenceActive],
  );

  return (
    <Container $exportEnabled={inSequenceFlow}>
      <ExportInvoicesButton />
      <If isTrue={!inSequenceFlow}>
        <ToggleAddInvoiceForm />
      </If>
    </Container>
  );
};
