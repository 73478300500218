import { UpdatedReceipt } from "@/contractor/pages/home/receipts/pages/receipts/providers/ExportReceiptProvider";
import { ReceiptToExportFieldsFragment } from "@/generated/graphql";

export const useIsValidReceiptFoundationExport = () => {
  const isValidReceiptFoundationExport = ({
    receipt,
    updatedReceipts,
  }: {
    receipt: ReceiptToExportFieldsFragment;
    updatedReceipts: UpdatedReceipt[];
  }) =>
    !receipt.release?.hasMissingCostCodes &&
    (receipt.release?.preferredVendor?.externalCode ||
      updatedReceipts?.find((i) => i.id === receipt.id)?.externalVendorCode);

  return { isValidReceiptFoundationExport };
};
