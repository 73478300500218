import { DeleteIcon } from "@/common/components/icons/DeleteIcon";
import { Select } from "@/common/components/select/components/single/Select";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { useUserRoles } from "@/common/utils/hooks/useUserRoles";
import { LocationRoleInput, OrgLocationRole } from "@/generated/graphql";
import { AddBox } from "@mui/icons-material";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  locationRoles: Partial<LocationRoleInput>[];
  setLocationRoles: (locationRoles: Partial<LocationRoleInput>[]) => void;
};

const Container = tw.div``;
const Location = tw.div`
  flex-1 mr-3
`;
const Role = tw.div`
  flex-1 ml-3
`;
const LocationRole = tw.div`
  flex items-center mb-3
`;
const AddBoxStyled = tw(AddBox)`
  ml-2 hover:cursor-pointer hover:text-blue-500
`;
const Action = tw.div`
  w-8 flex justify-end
`;

export const LocationRoles: FC<Props> = ({
  locationRoles,
  setLocationRoles,
}) => {
  const intl = useIntl();
  const { locations } = useUserLocations();
  const { userRoles } = useUserRoles();

  const updateLocation = (
    locationRole: Partial<LocationRoleInput>,
    index: number,
    value: string | null,
  ) => {
    locationRoles[index] = {
      ...locationRole,
      orgLocationId: value || undefined,
    };
    setLocationRoles([...locationRoles]);
  };

  const updateRole = (
    locationRole: Partial<LocationRoleInput>,
    index: number,
    value: string | null,
  ) => {
    const role = value ? (value as OrgLocationRole) : undefined;
    locationRoles[index] = { ...locationRole, role };
    setLocationRoles([...locationRoles]);
  };

  const addRow = () => {
    setLocationRoles([...locationRoles, {}]);
  };

  const removeRow = (index: number) => {
    locationRoles.splice(index, 1);
    setLocationRoles([...locationRoles]);
  };

  return (
    <Container>
      {locationRoles.map((locationRole, index) => (
        <LocationRole key={index}>
          <Location>
            <Select
              placeholder={intl.$t({ id: "USER_FORM_SELECT_LOCATION" })}
              options={locations || []}
              value={locationRole.orgLocationId || null}
              onChange={(value) => updateLocation(locationRole, index, value)}
              getLabel={(option) => option.name}
              getValue={(option) => option.id}
              required
            />
          </Location>
          <Role>
            <Select
              placeholder={intl.$t({ id: "USER_FORM_SELECT_ROLE" })}
              options={userRoles}
              value={locationRole.role || null}
              onChange={(value) => updateRole(locationRole, index, value)}
              getLabel={(option) => option.label}
              getValue={(option) => option.value}
              required
            />
          </Role>
          <Action>
            {index === 0 ? (
              <AddBoxStyled onClick={addRow} />
            ) : (
              <DeleteIcon onClick={() => removeRow(index)} />
            )}
          </Action>
        </LocationRole>
      ))}
    </Container>
  );
};
