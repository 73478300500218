import { SequenceStepper } from "@/common/components/sequence-stepper/SequenceStepper";
import { routes } from "@/config/routes";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useReleaseSequence } from "../providers/ReleaseSequenceProvider";

export const ReleaseSequenceStepper = () => {
  const navigate = useNavigate();
  const { step, setStep, sequenceIds, redirectToReleases } =
    useReleaseSequence();

  const prevStepCallback = useCallback(() => {
    navigate(
      generatePath(routes.delivery, {
        deliveryId: sequenceIds[step - 1],
      }),
    );
  }, [navigate, sequenceIds, step]);

  const nextStepCallback = useCallback(() => {
    navigate(
      generatePath(routes.delivery, {
        deliveryId: sequenceIds[step + 1],
      }),
    );
  }, [navigate, sequenceIds, step]);

  return (
    <SequenceStepper
      titleId="ORDERS"
      step={step}
      setStep={setStep}
      sequenceIds={sequenceIds}
      exitSequence={redirectToReleases}
      prevStepCallback={prevStepCallback}
      nextStepCallback={nextStepCallback}
    />
  );
};
