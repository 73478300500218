import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { InvoiceFooterEditInput } from "./InvoiceFooterEditInput";

type Props = {
  paymentTerm: number;
  isEditing: boolean;
  toggleEditing: () => void;
  onSave: (t: string) => void;
};

export const InvoiceFooterDueDateEdit: FC<Props> = ({
  paymentTerm,
  isEditing,
  toggleEditing,
  onSave,
}) => {
  return (
    <>
      <If isTrue={!isEditing}>
        <NotNullableRenderer value={paymentTerm}>
          <FormattedMessage
            id="PAYMENT_TERM_NET_WITH_DAYS"
            values={{ days: paymentTerm }}
          />
        </NotNullableRenderer>
      </If>
      <If isTrue={isEditing}>
        <InvoiceFooterEditInput
          isEditing={isEditing}
          onSave={onSave}
          toggleEditing={toggleEditing}
          inputValue={paymentTerm.toString()}
          decimals={0}
        />
      </If>
    </>
  );
};
