import { IntlShape } from "react-intl";
import { formatCSVCurrency } from "./csvReportUtils";

type ReportType = {
  allowance?: string | null | undefined;
  estimated?: string | null | undefined;
  ordered?: string | null | undefined;
  invoiced?: string | null | undefined;
  overUnder?: string | null | undefined;
  reserved?: string | null | undefined;
};

export const budgetTotalCSV = (
  csv: string[][],
  intl: IntlShape,
  report?: ReportType | null,
) => {
  csv.push([
    intl.$t({ id: "TOTAL" }),
    formatCSVCurrency(report?.allowance || report?.estimated, intl),
    formatCSVCurrency(report?.reserved, intl),
    formatCSVCurrency(report?.ordered, intl),
    formatCSVCurrency(report?.invoiced, intl),
    formatCSVCurrency(report?.overUnder, intl),
  ]);
};
