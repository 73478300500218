import { useCurrencies } from "@/common/components/currency-picker/hooks/useCurrencies";
import { DEFAULT_ADDRESS_COUNTRY, DEFAULT_CURRENCY } from "@/common/const";
import { UpdateOrgInput, ViewerOrgFieldsFragment } from "@/generated/graphql";
import { FC, PropsWithChildren, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

type Props = PropsWithChildren<{ org?: ViewerOrgFieldsFragment }>;

export const OrgProfileFormHookProvider: FC<Props> = ({ children, org }) => {
  const { currencies } = useCurrencies();
  const methods = useForm<UpdateOrgInput>({
    defaultValues: {
      name: org?.name || "",
      hqAddress: {
        addressLine1: org?.hqAddress?.addressLine1 || "",
        addressLine2: org?.hqAddress?.addressLine2 || "",
        city: org?.hqAddress?.city || "",
        postalCode: org?.hqAddress?.postalCode || "",
        state: org?.hqAddress?.state || "",
        country: org?.hqAddress?.country || DEFAULT_ADDRESS_COUNTRY,
      },
      phone: org?.phone || "",
      ein: org?.ein || "",
      id: org?.id,
      settings: {
        invoices: {
          localPart: org?.settings?.invoices?.localPart || "",
        },
        display: {
          currencyId:
            org?.settings?.display?.currency?.id ??
            currencies.find((c) => c.code === DEFAULT_CURRENCY)?.id ??
            "",
        },
      },
    },
  });

  useEffect(() => {
    if (org) {
      methods.reset({
        name: org.name || "",
        hqAddress: {
          addressLine1: org.hqAddress?.addressLine1 || "",
          addressLine2: org.hqAddress?.addressLine2 || "",
          city: org.hqAddress?.city || "",
          postalCode: org.hqAddress?.postalCode || "",
          state: org.hqAddress?.state || "",
          country: org.hqAddress?.country || DEFAULT_ADDRESS_COUNTRY,
        },
        phone: org.phone || "",
        ein: org.ein || "",
        id: org.id,
        settings: {
          invoices: {
            localPart: org.settings?.invoices?.localPart || "",
          },
          display: {
            currencyId:
              org?.settings?.display?.currency?.id ??
              currencies.find((c) => c.code === DEFAULT_CURRENCY)?.id ??
              "",
          },
        },
      });
    }
  }, [org, methods, currencies]);

  return <FormProvider {...methods}>{children}</FormProvider>;
};
