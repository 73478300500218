import { OrderTypePickerControlled } from "@/common/components/order-type-picker/OrderTypePickerControlled";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { DateTimePickerControlled } from "@/common/components/picker/components/DateTimePickerControlled";
import { getProjectSelectorLabel } from "@/common/components/projects-filter-selector/getProjectSelectorLabel";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import {
  VendorPickerCustomRender,
  vendorLabelFormatter,
} from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useReceipt } from "../../providers/ReceiptProvider";
import { useReceiptCreateReleaseHeader } from "../../stores/useReceiptCreateReleaseHeader";
import { ReceiptCreateReleaseFormValues } from "../ReceiptVerificationForm";

const Container = tw.div`w-full grid grid-cols-4 grid-row-2 gap-2 items-center z-20 p-4 -top-5 bg-blue-100 rounded-2xl drop-shadow-md`;

export const ReceiptCreateReleaseHeader: FC = () => {
  const { setValue, watch } = useFormContext<ReceiptCreateReleaseFormValues>();
  const intl = useIntl();
  const { hasReleaseRecorded } = useReceipt();
  const { projects, loading: loadingProjects } = useProjectListOptions();
  const {
    vendors,
    loading: loadingVendors,
    findOrderTypeByLocationId,
  } = useVendors();
  const { defaultOrderType } = useOrderTypeOptions();
  const { setProjectId, setVendorId } = useReceiptCreateReleaseHeader(
    useShallow((state) => ({
      setProjectId: state.setProjectId,
      setVendorId: state.setVendorId,
    })),
  );

  const projectId = watch("projectId");
  const vendorId = watch("vendorId");

  useEffect(() => {
    setProjectId(projectId);
    return () => {
      setProjectId(undefined);
    };
  }, [projectId, setProjectId]);

  useEffect(() => {
    setVendorId(vendorId);
    return () => {
      setVendorId(undefined);
    };
  }, [vendorId, setVendorId]);

  useEffect(() => {
    if (defaultOrderType) {
      setValue("orderTypeId", defaultOrderType.id);
    }
  }, [defaultOrderType, setValue]);

  useEffect(() => {
    if (projects.length === 1) {
      setValue("projectId", projects[0].id);
    }
  }, [projects, setValue]);

  useEffect(() => {
    if (vendors.length === 1) {
      setValue("vendorId", vendors[0].sellerOrgLocation.id);
    }
  }, [setValue, vendors]);

  useEffect(() => {
    if (vendorId) {
      const orderTypeId = findOrderTypeByLocationId(vendorId);
      if (orderTypeId) {
        setValue("orderTypeId", orderTypeId);
      }
    }
  }, [findOrderTypeByLocationId, setValue, vendorId]);

  return (
    <Container>
      <SelectControlled
        name="projectId"
        options={projects}
        getValue={(o) => o.id}
        getLabel={(o) => getProjectSelectorLabel(o)}
        placeholder={intl.$t({ id: "PROJECT_NAME" })}
        loading={loadingProjects}
        rules={{
          required: true,
        }}
        className={hasReleaseRecorded ? "!bg-transparent" : ""}
        staticText={hasReleaseRecorded}
      />
      <SelectControlled
        name="vendorId"
        options={vendors}
        getValue={(o) => o.sellerOrgLocation.id}
        getLabel={(o) =>
          vendorLabelFormatter(
            o.sellerOrgLocation,
            o.contacts.filter((c) => c.receivesInvoiceNotifications),
          )
        }
        placeholder={intl.$t({ id: "VENDOR" })}
        loading={loadingVendors}
        rules={{
          required: true,
        }}
        customRender={(item) =>
          VendorPickerCustomRender(item, (c) => c.receivesInvoiceNotifications)
        }
        className={hasReleaseRecorded ? "!bg-transparent" : ""}
        staticText={hasReleaseRecorded}
      />
      <OrderTypePickerControlled name="orderTypeId" />
      <DateTimePickerControlled
        name="issueDate"
        label={intl.$t({ id: "TRANSACTION_DATE" })}
        className="bg-white"
        maxDate={new Date()}
        rules={{
          required: true,
        }}
      />
    </Container>
  );
};
