import { If } from "@/common/components/if/If";
import { ManufacturerModalPicker } from "@/common/components/manufacturer-modal-picker/ManufacturerModalPicker";
import { isMasterSku } from "@/common/components/material/utils";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";

type Props = {
  category: CategoryState<BuyoutItemFieldsFragment>;
  readonly?: boolean;
};

export const BuyoutCostCodeManufacturerSelector: FC<Props> = ({
  category,
  readonly,
}) => {
  const { updateBuyout } = useContractorBuyout();

  const saveManufacturer = useCallback(
    async (manufacturerId: string | null) => {
      const updates = category.items
        .filter((i) => !isMasterSku(i.projectItem.material.material))
        .map((item) => {
          return {
            buyoutItemId: item.id,
            manufacturerId,
          };
        });
      await updateBuyout({ updates });
    },
    [category.items, updateBuyout],
  );

  return (
    <If
      isTrue={category.items.some(
        (item) => !isMasterSku(item.projectItem.material.material),
      )}
    >
      <ManufacturerModalPicker
        onChange={saveManufacturer}
        readonly={
          readonly || category.items.filter((i) => i.isIncluded).length === 0
        }
      />
    </If>
  );
};
