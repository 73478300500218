import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useUser } from "@/common/providers/UserProvider";
import { useStartupDataQuery } from "@/generated/graphql";
import { useCallback, useMemo } from "react";

export const useOrgSettings = () => {
  const { isContractor } = useUser();
  const { data, loading, error, refetch } = useStartupDataQuery({
    skip: !isContractor,
  });
  const { setError } = useGlobalError();
  useErrorEffect(error);

  const connectedIntegrationType = useMemo(
    () =>
      data?.viewer?.org.settings?.integrations.accounting.find((a) => a.enabled)
        ?.integration,
    [data?.viewer?.org.settings?.integrations.accounting],
  );

  const connectedSourceSystem = useMemo(
    () =>
      data?.viewer?.org.settings?.integrations.sourceSystems.find(
        (s) => s.connected,
      )?.system,
    [data?.viewer?.org.settings?.integrations.sourceSystems],
  );

  const hasPhaseCodes = useMemo(
    () =>
      !!(
        connectedSourceSystem &&
        data?.viewer?.org.settings?.integrations.sourceSystems.find(
          (i) => i.connected,
        )?.wbsTags
      ),
    [
      connectedSourceSystem,
      data?.viewer?.org.settings?.integrations.sourceSystems,
    ],
  );

  const refetchSettings = useCallback(async () => {
    const { errors } = await refetch();
    setError(errors);
  }, [refetch, setError]);

  return {
    orgId: data?.viewer?.org.id || "",
    settings: data?.viewer?.org.settings || null,
    loading,
    connectedIntegrationType,
    connectedSourceSystem,
    refetchSettings,
    hasPhaseCodes,
  };
};
