import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { Tune } from "@mui/icons-material";
import { Popover } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { Tooltip } from "../tooltip/Tooltip";

const Container = tw.div``;
const TuneStyled = tw(Tune)<{
  $isDefault: boolean;
}>`${({ $isDefault }) => ($isDefault ? "text-blue-500" : "text-white bg-blue-500 rounded")}`;
const FiltersButton = tw.span`text-sm text-blue-500`;
const ButtonContent = tw.div`flex gap-2 items-center bg-white px-1 py-1 rounded-xl border border-white hover:border-gray-400`;

export const ListHeaderFilterButton = ({
  filter,
  isDefault = true,
  classes,
  hideLabel,
}: {
  filter: React.ReactNode;
  isDefault?: boolean;
  hideLabel?: boolean;
  classes?: {
    button?: string;
    buttonText?: string;
  };
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <IconButtonBorderless
        autoScale
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <ButtonContent className={classes?.button}>
          <Tooltip
            id="filter-button"
            element={<TuneStyled $isDefault={isDefault} />}
          >
            <FormattedMessage id="FILTERS" />
          </Tooltip>
          <FiltersButton className={classes?.buttonText}>
            <If isTrue={!hideLabel}>
              <FormattedMessage id="FILTERS" />
            </If>
          </FiltersButton>
        </ButtonContent>
      </IconButtonBorderless>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {filter}
      </Popover>
    </Container>
  );
};
