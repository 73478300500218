import { GraphQLError } from "graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { generateUUID } from "../utils/uuidUtils";

export type GlobalErrorType = GraphQLError & {
  timestamp?: number;
  id?: string;
};

type GlobalErrorState = {
  errors: GlobalErrorType[];
  addError: (error: GlobalErrorType) => void;
  addUniqueError: (error: GlobalErrorType) => void;
  addErrors: (error: GlobalErrorType[]) => void;
  removeError: (error: GlobalErrorType) => void;
  reset: () => void;
  setError: (
    error: readonly GraphQLError[] | undefined | unknown,
    cleanup?: boolean,
  ) => void;
};

const setMetadata = (error: GraphQLError) => {
  return {
    ...error,
    timestamp: Date.now(),
    id: generateUUID(),
  } as GlobalErrorType;
};

export const useGlobalErrorStore = create<GlobalErrorState>()(
  devtools((set, get) => ({
    errors: [],
    addError: (error: GlobalErrorType) =>
      set((state) => ({
        errors: [...state.errors, error],
      })),
    addUniqueError: (error: GlobalErrorType) =>
      set((state) => ({
        errors: [...state.errors].concat(
          !state.errors.find((e) => e.message === error.message)
            ? [error]
            : ([] as GlobalErrorType[]),
        ),
      })),
    addErrors: (errors: GlobalErrorType[]) =>
      set((state) => ({
        errors: [...state.errors, ...errors],
      })),
    removeError: (error: GlobalErrorType) =>
      set((state) => ({
        errors: [...(state.errors.filter((err) => err.id !== error.id) || [])],
      })),
    setErrors: (errors: GlobalErrorType[]) => set(() => ({ errors })),
    reset: () => set(() => ({ errors: [] })),
    setError: (
      error: readonly GraphQLError[] | undefined | unknown,
      cleanup = true,
    ) => {
      if (!error && cleanup) {
        return get().reset();
      }

      if (!error) {
        return;
      }

      if (Array.isArray(error)) {
        return get().addErrors(error.map((e) => setMetadata(e)));
      }

      return get().addUniqueError(setMetadata(error as GraphQLError));
    },
  })),
);
