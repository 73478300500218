import DateRangePicker from "@/common/components/date-range-picker/DateRangePicker";
import { If } from "@/common/components/if/If";
import { LinkLikeText } from "@/common/components/link-like-text/LinkLikeText";
import { ProjectPicker } from "@/common/components/project-picker/ProjectPicker";
import { VendorPicker } from "@/common/components/vendor-picker/VendorPicker";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { CancelRounded, Close } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useExternalNotifications } from "../../providers/ExternalNotificationsProvider";

const FilterContainer = tw.div`sticky top-[37px] flex flex-col gap-2 w-full z-70`;
const Filters = tw.div`flex flex-col gap-2 -mx-7 px-7 py-3 bg-gray-100`;
const FilterItem = tw.div`grid grid-cols-[1fr_40px] gap-2`;
const ReleaseContainer = tw.div``;
const ReleaseClearContainer = tw.div`flex items-center justify-between`;
const ReleaseNumber = tw.div`flex gap-1 items-center py-1 px-2 rounded-3xl bg-gray-200 text-sm justify-center`;
const DiscardIcon = tw(CancelRounded)`text-base text-gray-400 cursor-pointer`;
const LinkLikeTextStyled = tw(LinkLikeText)`font-medium text-sm`;

export const ExternalNotificationFilter: FC = () => {
  const intl = useIntl();
  const { setExternalFilter, externalFilter } = useExternalNotifications();
  const { release } = useRelease();

  return (
    <FilterContainer>
      <Filters>
        <FilterItem>
          <ProjectPicker
            projectId={externalFilter?.projectIds?.[0] || null}
            setProjectId={(projectId) =>
              setExternalFilter({
                ...externalFilter,
                projectIds: projectId ? [projectId] : [],
              })
            }
            placeholder={intl.$t({ id: "SELECT_PROJECT" })}
          />
          <DateRangePicker
            selectedValue={
              externalFilter?.maxDate || externalFilter?.minDate
                ? {
                    startDate: externalFilter?.minDate
                      ? new Date(externalFilter.minDate)
                      : new Date(),
                    endDate: externalFilter?.maxDate
                      ? new Date(externalFilter.maxDate)
                      : new Date(),
                  }
                : undefined
            }
            isUTC
            onChange={(dateRange) =>
              setExternalFilter({
                ...externalFilter,
                minDate: dateRange.startDate?.getTime(),
                maxDate: dateRange.endDate?.getTime(),
              })
            }
            titleId="SELECT_EXTERNAL_NOTIFICATIONS_DATE_RANGE"
            includeLabel={false}
          />
        </FilterItem>
        <FilterItem>
          <VendorPicker
            vendorId={externalFilter?.sellerOrgLocationIds?.[0] || null}
            setVendorId={(sellerOrgLocationIds) =>
              setExternalFilter({
                ...externalFilter,
                sellerOrgLocationIds: sellerOrgLocationIds
                  ? [sellerOrgLocationIds]
                  : [],
              })
            }
            placeholder={intl.$t({ id: "SELECT_VENDOR" })}
            includeAllOption
          />
        </FilterItem>
        <FilterItem>
          <ReleaseClearContainer>
            <ReleaseContainer>
              <If isTrue={externalFilter.releaseId}>
                <ReleaseNumber>
                  <FormattedMessage
                    id={
                      release?.sequenceNumber ? "DELIVERY_WITH_NUMBER" : "ORDER"
                    }
                    values={{ number: release?.sequenceNumber }}
                    tagName="div"
                  />
                  <DiscardIcon
                    onClick={() =>
                      setExternalFilter({
                        ...externalFilter,
                        releaseId: undefined,
                      })
                    }
                  />
                </ReleaseNumber>
              </If>
            </ReleaseContainer>
            <If
              isTrue={
                externalFilter?.projectIds?.length ||
                externalFilter.sellerOrgLocationIds?.length ||
                externalFilter.releaseId
              }
            >
              <LinkLikeTextStyled
                onClick={() => {
                  setExternalFilter({
                    projectIds: [],
                    sellerOrgLocationIds: [],
                  });
                }}
              >
                <FormattedMessage id="CLEAR_FILTERS" />
                <Close className="text-lg" />
              </LinkLikeTextStyled>
            </If>
          </ReleaseClearContainer>
        </FilterItem>
      </Filters>
    </FilterContainer>
  );
};
