import { If } from "@/common/components/if/If";
import { InfoText } from "@/common/components/info-text/InfoText";
import { Loader } from "@/common/components/loader/Loader";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { TextField } from "@/common/components/textfield/TextField";
import { FileTableCopyPaste } from "@/common/components/upload-file-mapper/FileTableCopyPaste";
import {
  FileUploadArea,
  getFileType,
} from "@/common/components/upload/FileUploadArea";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { routes } from "@/config/routes";
import { IntegrationType } from "@/generated/graphql";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { FC, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import { CodesFileMapper, FM_COLUMNS } from "./CodesFileMapper";
import {
  CodesFileSourceSelector,
  FileSourceType,
  PASTE_TABLE,
} from "./CodesFileSourceSelector";
import {
  CodesFileUploadProvider,
  useCodesFileUpload,
} from "./CodesFileUploadProvider";

type CodesFileUploadSelectionProps = {
  onClose: () => void;
};

const FormControlStyled = tw(FormControl)`
  inline mb-8
`;

const UploadAreaContainer = tw.div`
  grid h-full content-between gap-2
`;

const OverlayPanelContainer = tw.div`
  grid gap-2 content-start h-full w-full
`;

const FileFormatContainer = tw.div`
  mt-8
`;

const StyledCheckbox = tw(Checkbox)`
  ml-1 text-blue-800 mb-2 pb-1
`;

const CodesFileUploadSelectionWithProvider: FC<
  CodesFileUploadSelectionProps
> = ({ onClose }) => {
  const [sourceType, setSourceType] = useState<FileSourceType>("");
  const [file, setFile] = useState<File>();
  const [loadingError, setLoadingError] = useState<unknown>();
  const { id } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    uploadFile,
    isUploading,
    setFilePreview,
    setFieldPreviewType,
    fieldPreviews,
    loadingPreview,
    previewError,
    assignedColumns,
  } = useCodesFileUpload();
  const [formatName, setFormatName] = useState("");
  const [saveFormat, setSaveFormat] = useState(false);
  const [mappingMode, setMappingMode] = useState(false);
  const { setSuccessAlert } = useSnackbar();

  const uploadCodes = async () => {
    if (file) {
      const mappings = assignedColumns
        .filter((col) => col.assigned)
        .map((col) => ({
          index: fieldPreviews.findIndex((f) => col.assigned === f.name),
          name: col.assigned,
          property: col.value,
        }));

      try {
        setLoadingError(undefined);
        const result = await uploadFile(getFileType(file), file, {
          fileFormat: {
            fileType: getFileType(file),
            mappings,
          },
        });

        if (result) {
          setSuccessAlert(intl.$t({ id: "COST_CODE_FILE_UPLOAD_SUCCESS" }));
        } else {
          return;
        }

        navigate(generatePath(routes.rfqCheckout, { id }));
        onClose();
      } catch (error) {
        setLoadingError(error);
      }
    }
  };

  const onSourceChanged = (source: FileSourceType) => {
    setSourceType(source);
  };

  const updateFile = (files: File[]) => {
    const file = files[0];
    setFile(file);
    if (customFileTypeSelected || copyPasteTable) {
      setFilePreview(file);
      setFieldPreviewType(getFileType(file));
      setSourceType(file.name);
    }
  };

  const uploadedFileSelected = useMemo(() => {
    return sourceType === file?.name;
  }, [sourceType, file]);

  const customFileTypeSelected = useMemo(() => {
    return sourceType === IntegrationType.Custom;
  }, [sourceType]);

  const copyPasteTable = useMemo(() => {
    return sourceType === PASTE_TABLE;
  }, [sourceType]);

  const pastedDataSelected = useMemo(() => {
    return sourceType === intl.$t({ id: "PASTED_TABLE_DATA" });
  }, [sourceType, intl]);

  const disableSave = useMemo(() => {
    if (!file) {
      return true;
    }

    if (saveFormat && !formatName) {
      return true;
    }

    const assignedColumnNames = assignedColumns
      .filter((col) => col.assigned)
      .map((col) => col.assigned);
    const duplicateColumnNames = assignedColumnNames.filter(
      (name, index) => assignedColumnNames.indexOf(name) !== index,
    );
    if (duplicateColumnNames.length > 0) {
      return true;
    }

    if (uploadedFileSelected) {
      return !assignedColumns.every(
        (c) =>
          c.assigned !== "" ||
          FM_COLUMNS.find((col) => col.value === c.value)?.required === false,
      );
    }
  }, [file, uploadedFileSelected, assignedColumns, formatName, saveFormat]);

  return (
    <OverlayPanel
      title={intl.$t({ id: "UPLOAD_COST_CODE_LIST" })}
      onSave={uploadCodes}
      saving={isUploading}
      disableSave={disableSave}
      onCancel={onClose}
    >
      <OverlayPanelContainer>
        <InfoText
          type="primary"
          title="YOUR_COST_CODES_LIST"
          body="YOUR_COST_CODES_LIST_DESCRIPTION"
        />
        <FormControlStyled>
          <CodesFileSourceSelector
            value={sourceType}
            onChange={onSourceChanged}
            selectedFiles={file ? [file.name] : []}
            disabled={mappingMode}
          />
        </FormControlStyled>

        <If isTrue={customFileTypeSelected}>
          <UploadAreaContainer>
            <FileUploadArea onChange={updateFile} error={loadingError} />
          </UploadAreaContainer>
        </If>

        <If isTrue={uploadedFileSelected}>
          <Loader loading={loadingPreview}>
            <If isTrue={!previewError}>
              <InfoText type="primary" title="MAP_UPLOADED_FILES" />
              <CodesFileMapper
                mappingMode={mappingMode}
                setMappingMode={setMappingMode}
                fieldPreviews={fieldPreviews}
              />
              <If isTrue={!pastedDataSelected}>
                <FileFormatContainer>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          value={saveFormat}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            setSaveFormat(e.target.checked);
                          }}
                        />
                      }
                      label={intl.$t({ id: "SAVE_FORMAT" })}
                    />
                    <If isTrue={saveFormat}>
                      <FormControl fullWidth>
                        <TextField
                          size="small"
                          label={intl.$t({ id: "FILE_FORMAT_NAME" })}
                          value={formatName}
                          disabled={!saveFormat}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => setFormatName(event.target.value)}
                        />
                      </FormControl>
                    </If>
                  </FormGroup>
                </FileFormatContainer>
              </If>
            </If>
          </Loader>
          <If isTrue={previewError}>
            <InfoText
              type="error"
              title="UPLOAD_MATERIAL_LIST_ERROR"
              body="CUSTOM_FILE_UPLOAD_ERROR"
            />
          </If>
        </If>
        <If isTrue={copyPasteTable}>
          <FileTableCopyPaste
            handleChange={updateFile}
            instructions="CODES_COPY_PASTE_TABLE_DESCRIPTION"
          />
        </If>
      </OverlayPanelContainer>
    </OverlayPanel>
  );
};

export const CodesFileUploadSelection: FC<CodesFileUploadSelectionProps> = ({
  onClose,
}) => (
  <CodesFileUploadProvider>
    <CodesFileUploadSelectionWithProvider onClose={onClose} />
  </CodesFileUploadProvider>
);
