import { If } from "@/common/components/if/If";
import { IntegrationTypeContainer } from "@/contractor/pages/admin/integrations/components/Integrations.styles";
import { IntegrationLogo } from "@/contractor/pages/admin/integrations/components/form/components/IntegrationLogo";
import { SourceSystem } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Connection = tw.div`flex flex-row bg-white rounded-xl justify-center items-center text-gray-600 text-xs cursor-pointer mr-4 h-24 px-6 pt-1 z-30 border border-dashed border-gray-600`;

export type SourceSystemItem = {
  logo: string;
  name: string;
  type: string | null | undefined;
  sourceSystem: SourceSystem;
  isConnected: boolean;
};

type Props = {
  item: SourceSystemItem;
  onClick: (system: SourceSystem) => void;
};

export const SourceSystemItem: FC<Props> = ({ item, onClick }) => {
  return (
    <Connection onClick={() => onClick(item.sourceSystem)}>
      <IntegrationLogo url={item.logo} className="mr-2 w-30" />
      <If isTrue={item.type}>
        <IntegrationTypeContainer $xs>{item.type}</IntegrationTypeContainer>
      </If>
    </Connection>
  );
};
