import { TagsSelector } from "@/common/components/tags-selector/TagsSelector";
import { useSpendingReport } from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { TagFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";

type Props = {
  options: TagFieldsFragment[];
  disabled?: boolean;
  limitTags?: number;
};

export const SpendingReportTagsSelector: FC<Props> = ({
  options,
  disabled,
  limitTags = 1,
}) => {
  const intl = useIntl();
  const { tags, setTags } = useSpendingReport();

  const tagOptions = useMemo(() => {
    return options.map((tag) => {
      return {
        ...tag,
        disabled: false,
      };
    });
  }, [options]);

  return (
    <TagsSelector
      options={tagOptions}
      selectedTags={tags}
      setSelectedTags={setTags}
      disabled={disabled}
      limitTags={limitTags}
      placeholder={intl.$t({
        id: "TAGS",
      })}
      multiple={false}
      creatable={false}
    />
  );
};
