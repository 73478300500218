import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  step: number;
  setStep: (step: number) => void;
};

export const useStepperStore = create<State>()(
  devtools((set) => ({
    step: 0,
    setStep: (step: number) =>
      set(() => ({
        step,
      })),
  })),
);
