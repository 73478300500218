import { FC } from "react";
import tw from "tailwind-styled-components";

const PasteTableContainer = tw.div`
  relative grid max-h-screen-3/4 overflow-auto border rounded-md
`;

const PasteTable = tw.table`
  relative 
`;

const PasteTableHead = tw.thead``;

const PasteTableHeader = tw.th`
  sticky top-[-1px] self-start text-black bg-gray-100 border-r last:border-none px-2 py-2 text-xs text-center
`;

const PasteTableBody = tw.tbody`
  max-h-96 overflow-auto
`;

const PasteTableCell = tw.td`
  text-black border-r border-t last:border-r-0 px-2 py-1 text-xs text-center
`;

const PasteTableRow = tw.tr`
  even:bg-white
`;

type PasteTableProps = {
  csvString: string;
};

export const PreviewTable: FC<PasteTableProps> = ({ csvString }) => {
  const header = csvString.split("\n")[0].split(",");
  const rows = csvString.split("\n").slice(1);

  return (
    <PasteTableContainer>
      <PasteTable>
        <PasteTableHead>
          <PasteTableRow>
            {header.map((h, ind) => (
              <PasteTableHeader key={ind}>
                {h.replace(/"/gm, "")}
              </PasteTableHeader>
            ))}
          </PasteTableRow>
        </PasteTableHead>
        <PasteTableBody>
          {rows.map((r, idx) => (
            <PasteTableRow key={idx}>
              {r.split('","').map((c, i) => (
                <PasteTableCell key={i}>
                  {c.replace(/^"/gm, "").replace(/"$/gm, "")}
                </PasteTableCell>
              ))}
            </PasteTableRow>
          ))}
        </PasteTableBody>
      </PasteTable>
    </PasteTableContainer>
  );
};
