import { wait } from "./wait";

export const goToExternalUrl = (url: string, newTab: boolean = false): void => {
  if (newTab) {
    window.open(url, "_blank", "noreferrer");
  } else {
    window.location.href = url;
  }
};

export const goToExternalUrls = async (urls: string[]) => {
  for (const url of urls) {
    goToExternalUrl(url);
    await wait(1000);
  }
};
