export const getUserName = (
  user:
    | { firstName?: string; lastName?: string; email?: string }
    | null
    | undefined,
) => {
  if (!user) {
    return "";
  }
  return user.firstName
    ? `${user.firstName}${user.lastName ? ` ${user.lastName}` : ""}`
    : user.email
      ? user.email
      : "";
};
