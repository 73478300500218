import { If } from "@/common/components/if/If";
import { Check, ChevronRight } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Steps = tw.div`grid grid-flow-col gap-2 justify-between items-center w-full pr-5`;

type StepProps = {
  $active: boolean | undefined;
};
const StepContainer = tw.div<StepProps>`
  grid text-sm grid-flow-col gap-2 items-center justify-center text-blue-800 font-medium
  ${({ $active }) => (!$active ? "opacity-50" : "")}
`;
const StepCounter = tw.div`
  text-xs rounded-full border-2 w-5 h-5 flex items-center justify-center border-blue-800
`;

type Props = {
  steps: {
    name: string;
    active?: boolean;
    done?: boolean;
  }[];
};

export const DeliverySlipsFooterStepper: FC<Props> = ({ steps }) => {
  return (
    <Steps>
      {steps.map((step, index) => (
        <StepContainer key={index} $active={step.active}>
          <If isTrue={step.done}>
            <StepCounter>
              <Check className="text-sm" />
            </StepCounter>
          </If>
          <If isTrue={!step.done}>
            <StepCounter>{index + 1}</StepCounter>
          </If>
          <FormattedMessage id={step.name} />
          <If isTrue={index < steps.length - 1}>
            <ChevronRight />
          </If>
        </StepContainer>
      ))}
    </Steps>
  );
};
