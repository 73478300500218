import { GridTable } from "@/common/components/grid-table/GridTable";
import { If } from "@/common/components/if/If";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { Pagination } from "@/common/components/pagination/Pagination";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useDeliverySlipImportExternalPO } from "../../../../providers/DeliverySlipImportExternalPOProvider";
import { useDeliverySlipExternalPOsTableConfiguration } from "./DeliverySlipExternalPOsTable.configuration";
import { DeliverySlipExternalPOsTableHeader } from "./DeliverySlipExternalPOsTableHeader";

const Container = tw.div``;
const PaginationContainer = tw.div`
  flex justify-end mt-5
`;
const InfoContainer = tw.div`flex self-stretch border border-gray-400 border-dashed py-4 items-center justify-center rounded-3xl mx-8 mt-8 text-gray-500`;

const DeliverySlipExternalPOsWithPagination: FC = () => {
  const {
    externalPOsPaginatedOutput: {
      externalPOs,
      loadingExternalPOs,
      ...paginationProps
    },
    externalPOsQueryForm,
  } = useDeliverySlipImportExternalPO();
  const externalPOsTableConfiguration =
    useDeliverySlipExternalPOsTableConfiguration();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const projectId = externalPOsQueryForm?.watch("projectId");
  const shouldDisplaySelectProjectHint = useMemo(() => {
    return (
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.ExternalPoReadsProjectSpecific,
      ) && !projectId
    );
  }, [hasFeatureInConnectedSourceSystem, projectId]);

  return (
    <Container>
      <DeliverySlipExternalPOsTableHeader />
      <If isTrue={!shouldDisplaySelectProjectHint}>
        <GridTable
          items={externalPOs}
          loading={loadingExternalPOs}
          configuration={externalPOsTableConfiguration}
        />
        <PaginationContainer>
          <Pagination {...paginationProps} />
        </PaginationContainer>
      </If>
      <If isTrue={shouldDisplaySelectProjectHint}>
        <InfoContainer>
          <FormattedMessage id="SELECT_THE_PROJECT_TO_VIEW_POS" />
        </InfoContainer>
      </If>
    </Container>
  );
};

export const DeliverySlipExternalPOs: FC = () => (
  <PaginationProvider>
    <DeliverySlipExternalPOsWithPagination />
  </PaginationProvider>
);
