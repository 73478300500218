import { If } from "@/common/components/if/If";
import { ShowMoreCounter } from "@/common/components/show-more-counter/ShowMoreCounter";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import { ReleaseProjectItemFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-row gap-2`;

type Props = {
  item: ReleaseProjectItemFieldsFragment;
};

export const ViewRelatedBuyouts: FC<Props> = ({ item }) => {
  const buyoutItems = useMemo(
    () =>
      item?.buyoutItems.filter(
        (b) => b.buyout?.sellerOrgLocation.org.logoImageUrl,
      ),
    [item],
  );
  return (
    <Container>
      {buyoutItems.slice(0, 2).map((buyoutItem, key) => {
        return (
          <VendorLogo
            key={key}
            logoImageUrl={buyoutItem.buyout?.sellerOrgLocation.org.logoImageUrl}
            index={key}
            tooltip={
              <FormattedMessage
                id="ITEM_IS_PART_OF_BUYOUT"
                values={{
                  buyoutNo: buyoutItem.buyout.clientIdentifier,
                }}
              />
            }
          />
        );
      })}

      <If isTrue={buyoutItems.length > 2}>
        <ShowMoreCounter
          count={buyoutItems.length - 2}
          classes={{ innerElement: "space-x-1 text-sm" }}
          color="secondary"
        >
          <Container>
            {buyoutItems.slice(2, buyoutItems.length).map((buyoutItem, key) => (
              <VendorLogo
                key={key}
                logoImageUrl={
                  buyoutItem.buyout?.sellerOrgLocation.org.logoImageUrl
                }
                index={key}
              />
            ))}
          </Container>
        </ShowMoreCounter>
      </If>
    </Container>
  );
};
