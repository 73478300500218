import { CostCodesSelector } from "@/common/components/cost-codes-selector/CostCodesSelector";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { useSpendingReport } from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { CostCode } from "@/generated/graphql";
import { FC, useMemo } from "react";

export const SpendingReportCostCodesSelector: FC<{
  options: Pick<
    CostCode & { disabled?: boolean },
    "id" | "description" | "disabled" | "inUse"
  >[];
  disabled?: boolean;
}> = ({ options, disabled }) => {
  const { costCodes, setCostCodes } = useSpendingReport();

  const costCodesOptions = useMemo(() => {
    return options
      .filter((option) => option.id !== UNSPECIFIED_COST_CODE_ID)
      .map((code) => {
        return {
          ...code,
          disabled: false,
        };
      });
  }, [options]);

  return (
    <CostCodesSelector
      className="max-w-64"
      options={costCodesOptions}
      selectedCostCodes={costCodes}
      setSelectedCostCodes={setCostCodes}
      disabled={disabled}
      limitTags={2}
    />
  );
};
