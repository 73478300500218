import { MAX_ITEMS_PER_PAGE } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { cleanQuery } from "@/common/utils/cacheUtils";
import {
  CreateProjectsInput,
  ProjectListOptionsDocument,
  UpdateProjectInput,
  namedOperations,
  useCreateProjectsMutation,
  useUpdateProjectMutation,
} from "@/generated/graphql";

export const useProjectsHooks = () => {
  const { setError } = useGlobalError();
  const [createNewProjects, { loading: creating }] =
    useCreateProjectsMutation();

  const createProjects = async (projectsInput: CreateProjectsInput) => {
    try {
      const { data: newProjects, errors } = await createNewProjects({
        variables: {
          input: projectsInput,
        },
        update: (cache) => {
          cleanQuery(cache, namedOperations.Query.Projects);
          cleanQuery(cache, namedOperations.Query.ProjectListOptions);
        },
      });
      setError(errors);
      return !errors?.length && newProjects?.createProjects.length;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const [updateExistingProject, { loading: updating }] =
    useUpdateProjectMutation();
  const updateProject = async (projectInput: UpdateProjectInput) => {
    try {
      const {
        locationId,
        id,
        name,
        address,
        jobNumber,
        status,
        startDate,
        budget,
        team,
      } = projectInput;
      const newAddress =
        address?.addressLine1 &&
        address.city &&
        address.postalCode &&
        address.state
          ? {
              addressLine1: address.addressLine1,
              city: address.city,
              country: address.country,
              postalCode: address.postalCode,
              state: address.state,
            }
          : null;
      const { data: updatedProject, errors } = await updateExistingProject({
        variables: {
          input: {
            id,
            name,
            locationId,
            address: newAddress,
            jobNumber,
            startDate,
            status,
            budget,
            team,
          },
        },
        refetchQueries: [
          {
            query: ProjectListOptionsDocument,
            variables: {
              first: MAX_ITEMS_PER_PAGE,
              deleted: false,
            },
          },
        ],
      });
      setError(errors);
      if (updatedProject?.updateProject) {
        return updatedProject?.updateProject;
      }
    } catch (errors) {
      setError(errors);
    }
  };

  return {
    createProjects,
    updateProject,
    creating,
    updating,
  };
};
