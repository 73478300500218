import { If } from "@/common/components/if/If";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { ExpandedReleaseItem } from "../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";
import { hasUnreleasedQuantity } from "../../../utils/hasUnreleasedQuantity";

const Container = tw.div`
  flex mr-1 w-5
`;

export const ReleaseGroupCheckbox: FC<{
  group:
    | CategoryState<ExpandedReleaseItem>
    | CategoryState<CategoryState<ExpandedReleaseItem>>;
  checkUnreleasedQuantity?: boolean;
}> = ({ group, checkUnreleasedQuantity }) => {
  const { selectedReleaseItemIds, setSelectedReleaseItemIds } =
    useReleaseUpdate();
  const checked = useMemo(
    () =>
      group.items.every((item) => {
        if ((item as CategoryState<ExpandedReleaseItem>).items) {
          return (item as CategoryState<ExpandedReleaseItem>).items
            .filter((i) => i.isIncluded)
            .filter((i) => !checkUnreleasedQuantity || hasUnreleasedQuantity(i))
            .every((subitem) => selectedReleaseItemIds.includes(subitem.id));
        } else {
          return (
            ((!checkUnreleasedQuantity ||
              hasUnreleasedQuantity(item as ExpandedReleaseItem)) &&
              selectedReleaseItemIds.includes(item.id)) ||
            !(item as ExpandedReleaseItem).isIncluded
          );
        }
      }),
    [checkUnreleasedQuantity, group.items, selectedReleaseItemIds],
  );
  const handleChange = useCallback(
    (newChecked: boolean) => {
      if (newChecked) {
        const idsToAdd = group.items
          .filter((item) => {
            if ((item as CategoryState<ExpandedReleaseItem>).items) {
              return (item as CategoryState<ExpandedReleaseItem>).items
                .filter((i) => i.isIncluded)
                .every(
                  (subitem) =>
                    !checkUnreleasedQuantity || hasUnreleasedQuantity(subitem),
                );
            }
            return (
              !checkUnreleasedQuantity ||
              hasUnreleasedQuantity(item as ExpandedReleaseItem)
            );
          })
          .map((item) => {
            if ((item as CategoryState<ExpandedReleaseItem>).items) {
              return (item as CategoryState<ExpandedReleaseItem>).items
                .filter((i) => i.isIncluded)
                .map((subitem) => subitem.id);
            } else {
              return item.id;
            }
          })
          .flat();
        setSelectedReleaseItemIds([...selectedReleaseItemIds, ...idsToAdd]);
      } else {
        const idsToRemove = group.items
          .map((item) => {
            if ((item as CategoryState<ExpandedReleaseItem>).items) {
              return (item as CategoryState<ExpandedReleaseItem>).items
                .filter((i) => i.isIncluded)
                .map((subitem) => subitem.id);
            } else {
              return item.id;
            }
          })
          .flat();
        setSelectedReleaseItemIds(
          selectedReleaseItemIds.filter((r) => !idsToRemove.includes(r)),
        );
      }
    },
    [
      checkUnreleasedQuantity,
      group.items,
      selectedReleaseItemIds,
      setSelectedReleaseItemIds,
    ],
  );

  const include = useMemo(() => {
    if (!checkUnreleasedQuantity) {
      return true;
    }
    return group.items.some((item) => {
      if ((item as CategoryState<ExpandedReleaseItem>).items) {
        return (item as CategoryState<ExpandedReleaseItem>).items.some(
          (subitem) => hasUnreleasedQuantity(subitem),
        );
      }
      return hasUnreleasedQuantity(item as ExpandedReleaseItem);
    });
  }, [checkUnreleasedQuantity, group.items]);

  return (
    <Container>
      <If isTrue={include}>
        <SelectionCheckbox
          testId={`group-checkbox-${group.name.toLowerCase().replaceAll(/\s/g, "-")}`}
          checked={checked}
          setSelection={handleChange}
        />
      </If>
    </Container>
  );
};
