import { Instructions } from "@/common/components/instructions/Instructions";
import { Loader } from "@/common/components/loader/Loader";
import { NoteDocumentPanel } from "@/common/components/note-document-panel/NoteDocumentPanel";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import { ReleaseDeliveryPhotos } from "@/common/components/release-images/ReleaseDeliveryPhotos";
import { ReleaseDeliverySlips } from "@/common/components/release-images/ReleaseDeliverySlips";
import { routes } from "@/config/routes";
import { ReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { ProjectTagsProvider } from "../../../project/providers/ProjectTagsProvider";
import { ReleaseBreadcrumbs } from "../../components/ReleaseBreadcrumbs";
import { ReleaseDescription } from "../../components/ReleaseDescription";
import { ReleaseActionsProvider } from "../../providers/ReleaseActionsProvider";
import { ReleaseItemsZoneProvider } from "../../providers/ReleaseItemsZonesProvider";
import {
  ExpandedReleaseItem,
  useRelease,
} from "../../providers/ReleaseProvider";
import { ReleaseUpdateProvider } from "../../providers/ReleaseUpdateProvider";
import { ReleaseItemList } from "../../release-items-list/ReleaseItemList";
import { AddNoteSidePanel } from "../specify-details/components/add-note-btn/AddNoteSidePanel";
import { AddQuoteSidePanel } from "../specify-details/components/add-quote-btn/AddQuoteSidePanel";
import { ImportMaterialsDrawer } from "../specify-details/components/import-from-file/ImportReleaseItemsDrawer";
import { ReleaseRejectReasonView } from "../specify-details/footer-actions/reject-reason/ReleaseRejectReasonView";
import { useReleaseDetailsConfiguration } from "./ReleaseDetails.configuration";
import { ReleaseDetailsFooter } from "./ReleaseDetailsFooter";
import { ReleaseDetailsHeader } from "./ReleaseDetailsHeader";
import { ReleaseInvoices } from "./ReleaseInvoices";
import { ReleaseReceipts } from "./ReleaseReceipts";

const Container = tw.div`pb-15`;

export const ReleaseDetails: FC = () => {
  const { release } = useRelease();
  const releaseDetailsConfiguration = useReleaseDetailsConfiguration(release);
  const navigate = useNavigate();

  const itemFn = useCallback((item: ReleaseItemFieldsFragment) => {
    return {
      className: `${item.isIncluded ? "" : "bg-gray-100"}`,
    };
  }, []);

  const items = useMemo(
    () =>
      release?.items.map((item) => {
        const estimatedItems = item.projectItem?.estimatedItems.filter(
          (ei) => ei.zone?.id === item.zone?.id,
        );
        return {
          ...{
            ...item.projectItem,
            material: {
              ...item.projectItem?.material,
              material: {
                ...item.projectItem?.material.material,
                name: item.name,
              },
            },
            estimatedItems,
          },
          ...item,
        } as ExpandedReleaseItem;
      }) ?? [],
    [release],
  );

  if (!release) {
    return <Loader loading />;
  }

  return (
    <ReleaseUpdateProvider
      release={release}
      project={release?.project}
      items={items}
    >
      <ProjectTagsProvider id={release?.project?.id}>
        <ReleaseItemsZoneProvider items={items} usePhaseCodes>
          <ReleaseActionsProvider>
            <Container>
              <ReleaseBreadcrumbs />
              <ReleaseDetailsHeader />
              <ReleaseItemList
                columns={releaseDetailsConfiguration}
                classNames={{
                  header: "top-[32px]",
                  category: "top-[36px] lg:top-[77px]",
                  subCategory: (groupedByZones: boolean) =>
                    groupedByZones
                      ? "top-[76px] lg:top-[110px]"
                      : "top-[36px] lg:top-[80px]",
                }}
                itemFn={itemFn}
              />
              <Instructions
                instruction={release.instructions}
                projectId={release.project?.id}
              >
                <ReleaseDescription release={release} />
              </Instructions>
              <ReleaseDeliverySlips
                release={release}
                deliverySlips={release.deliverySlips}
              />
              <ReleaseDeliveryPhotos release={release} />
              <ReleaseInvoices release={release} />
              <ReleaseReceipts release={release} />
              <ReleaseRejectReasonView
                rejectionReason={release?.rejectionReason}
              />
              <QuoteDocumentPanel
                quoteDocument={release.quoteDocument}
                readonly
                customRedirect={() => {
                  navigate(
                    generatePath(routes.editDeliveryFromQuote, {
                      quoteDocumentId: release.quoteDocument?.id,
                      deliveryId: release.id,
                    }),
                  );
                }}
              >
                {release.quoteDocument ? null : <AddQuoteSidePanel />}
              </QuoteDocumentPanel>
              <NoteDocumentPanel
                noteDocument={release.noteDocument}
                readonly
                customRedirect={() => {
                  navigate(
                    generatePath(routes.editDeliveryFromNote, {
                      noteDocumentId: release.noteDocument?.id,
                      deliveryId: release.id,
                    }),
                  );
                }}
              >
                {release.noteDocument ? null : <AddNoteSidePanel />}
              </NoteDocumentPanel>
              <ImportMaterialsDrawer />
              <ReleaseDetailsFooter />
            </Container>
          </ReleaseActionsProvider>
        </ReleaseItemsZoneProvider>
      </ProjectTagsProvider>
    </ReleaseUpdateProvider>
  );
};
