import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Popover } from "@/common/components/popover/Popover";
import { FieldMappingTargetProperty, FieldPreview } from "@/generated/graphql";
import {
  ArrowForward,
  Close,
  TipsAndUpdatesTwoTone,
} from "@mui/icons-material";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { FileColumnCarousel } from "./FileColumnCarousel";

const Overlay = tw.div`
  absolute inset-0 border-0.5 border-blue-800 z-50 mx-4 mt-30 mb-4 rounded-md bg-white 
  grid grid-rows-[1fr_auto] items-start pt-5
`;

const Label = tw.div`
  grid grid-flow-col text-black text-sm first:pl-4 pl-2 justify-start gap-1 items-center
`;

const ColumnList = tw.div`
  grid grid-cols-2 justify-between
`;

const ColumnListItem = tw.div`
  grid grid-cols-[1fr_auto_1fr_20px_20px] gap-2 items-center col-span-2 border rounded-lg px-4 py-2 mt-2  
  ${({ $assigned }: { $assigned?: string }) => $assigned && "bg-green-100"}
`;

const ColumnName = tw.div`
  grid grid-flow-col font-medium justify-start gap-2 items-center 
`;

const OverlayBody = tw.div`
  grid grid-rows-[1fr_auto] gap-6 px-4 py-4 w-full place-items-center 
`;

const ColumnMapHeader = tw.div`
  text-center max-w-80
`;

const ColumnMapInfo = tw.div`
  text-sm max-w-52 text-center
`;

const OverlayActions = tw.div`
  grid grid-flow-col gap-2 p-5 w-full
`;

const TextGroup = tw.div`
  grid grid-flow-row gap-1
`;

const ErrorOutlineStyled = tw(ErrorOutline)`
  text-red-500
`;

const InfoIcon = tw(TipsAndUpdatesTwoTone)`
  text-blue-500 text-base hover:text-yellow-600 cursor-pointer ml-2 mb-1
`;

const Required = tw.sup`
  text-red-500 text-base -ml-1.5 cursor-pointer
`;

export type AssignedColumn = {
  value: FieldMappingTargetProperty;
  assigned: string;
};

type FMColumn = {
  name: string;
  details?: string;
  value: FieldMappingTargetProperty;
  required: boolean;
};

type FileMapperProps = {
  mappingMode: boolean;
  setMappingMode: (value: boolean) => void;
  fieldPreviews: FieldPreview[];
  columns: FMColumn[];
  assignedColumns: AssignedColumn[];
  setAssignedColumns: (value: AssignedColumn[]) => void;
};

export const FileMapper: FC<FileMapperProps> = ({
  mappingMode,
  setMappingMode,
  fieldPreviews,
  columns,
  assignedColumns,
  setAssignedColumns,
}) => {
  const intl = useIntl();
  const [selectedFMColumn, setSelectedFMColumn] = useState<FMColumn>(
    columns[0],
  );
  const [currentIndex, setCurrentIndex] = useState(0);

  const selectColumn = (column: FMColumn) => {
    setSelectedFMColumn(column);
    setMappingMode(true);

    const assignedCol = assignedColumns.find(
      (ac) => ac.value === column.value && ac.assigned,
    );
    if (assignedCol) {
      setCurrentIndex(
        fieldPreviews.findIndex((f) => f.name === assignedCol.assigned),
      );
    }
  };

  const unAssign = (column: FMColumn) => {
    setAssignedColumns(
      assignedColumns.map((col) => {
        if (col.value === column.value) {
          return {
            ...col,
            assigned: "",
          };
        }
        return col;
      }),
    );
  };

  const assignColumn = () => {
    const newAssignedColumns: AssignedColumn[] = assignedColumns.map((ac) => {
      if (ac.value === selectedFMColumn.value) {
        return {
          ...ac,
          assigned: fieldPreviews[currentIndex].name || "",
        };
      } else {
        return ac;
      }
    });
    setAssignedColumns(newAssignedColumns);
    setMappingMode(false);
  };

  const cancelAssignment = () => {
    setMappingMode(false);
  };

  const alreadyAssigned = (fmColumn: FMColumn) => {
    const assigned = assignedColumns.find(
      (ac) => ac.value === fmColumn.value,
    )?.assigned;
    if (assigned) {
      return (
        assignedColumns.filter((ac) => ac.assigned === assigned).length > 1
      );
    }
  };

  const getAssignedColumn = (fmColumn: FMColumn) => {
    return (
      assignedColumns.find((ac) => ac.value === fmColumn.value)?.assigned || ""
    );
  };

  return (
    <>
      <If isTrue={!mappingMode}>
        <ColumnList>
          <Label>
            <FormattedMessage id="FIELD_MATERIALS_COLUMN" />
          </Label>
          <Label>
            <FormattedMessage id="IMPORTED_COLUMN" />
          </Label>
          {columns.map((col) => (
            <ColumnListItem key={col.value} $assigned={getAssignedColumn(col)}>
              <ColumnName>
                {getAssignedColumn(col) ? (
                  <LinkLike onClick={() => selectColumn(col)}>
                    <FormattedMessage id={col.name} />
                  </LinkLike>
                ) : (
                  <FormattedMessage id={col.name} />
                )}
                <If isTrue={col.required}>
                  <Popover
                    element={<Required>*</Required>}
                    color="warning"
                    id="required"
                    $arrow
                    horizontal="center"
                  >
                    <FormattedMessage id="REQUIRED" />
                  </Popover>
                </If>
              </ColumnName>
              <ArrowForward />
              <ColumnName>
                {getAssignedColumn(col) ? (
                  <>{getAssignedColumn(col)}</>
                ) : (
                  <LinkLike onClick={() => selectColumn(col)}>
                    <FormattedMessage id="SELECT" />
                  </LinkLike>
                )}
              </ColumnName>
              <ColumnName>
                <If isTrue={alreadyAssigned(col)}>
                  <Popover
                    element={<ErrorOutlineStyled />}
                    color="warning"
                    id="hint"
                    classes={{ element: "inline" }}
                    horizontal="right"
                  >
                    <FormattedMessage id="COLUMN_ALREADY_ASSIGNED" />
                  </Popover>
                </If>
              </ColumnName>
              <ColumnName>
                <If isTrue={getAssignedColumn(col)}>
                  <LinkLike onClick={() => unAssign(col)}>
                    <Close />
                  </LinkLike>
                </If>
              </ColumnName>
            </ColumnListItem>
          ))}
        </ColumnList>
      </If>
      <If isTrue={mappingMode}>
        <Overlay>
          <OverlayBody>
            <If isTrue={!getAssignedColumn(selectedFMColumn)}>
              <ColumnMapHeader>
                <FormattedMessage
                  id="MAP_COLUMN_HEADER"
                  values={{
                    column: intl.$t({ id: selectedFMColumn.name }),
                    p: (text) => (
                      <div>
                        <strong className="justify-center">
                          {text}
                          <Popover
                            $arrow
                            element={<InfoIcon />}
                            id="hint"
                            classes={{ element: "inline" }}
                          >
                            <FormattedMessage id={selectedFMColumn.details} />
                          </Popover>
                        </strong>
                      </div>
                    ),
                  }}
                />
              </ColumnMapHeader>
            </If>
            <If isTrue={getAssignedColumn(selectedFMColumn)}>
              <TextGroup>
                {" "}
                <ColumnMapHeader>
                  <FormattedMessage id="FIELD_MATERIALS" />{" "}
                  <strong>
                    <FormattedMessage id={selectedFMColumn.name} />
                  </strong>
                </ColumnMapHeader>
                <ColumnMapInfo>
                  <FormattedMessage id="IS_MAPPED_TO" />
                </ColumnMapInfo>
                <ColumnMapHeader>
                  <FormattedMessage id="IMPORTED" />
                  <strong>{` "${getAssignedColumn(selectedFMColumn)}"`}</strong>
                </ColumnMapHeader>
              </TextGroup>
            </If>
            <FileColumnCarousel
              columns={fieldPreviews}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          </OverlayBody>
          <OverlayActions>
            <OutlinedButton onClick={cancelAssignment}>
              <FormattedMessage id="CANCEL" />
            </OutlinedButton>
            <PrimaryButton onClick={assignColumn}>
              <FormattedMessage id="ASSIGN" />
            </PrimaryButton>
          </OverlayActions>
        </Overlay>
      </If>
    </>
  );
};
