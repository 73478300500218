import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ReleaseDocument,
  ReleaseQuery,
  useReverseSyncPoMutation,
} from "@/generated/graphql";

export const useReverseSyncPO = () => {
  const [reverseSyncPOMutation] = useReverseSyncPoMutation();
  const { setError } = useGlobalError();

  const reverseSyncPO = async (poLinkId?: string, releaseId?: string) => {
    if (poLinkId) {
      try {
        const { data } = await reverseSyncPOMutation({
          variables: {
            poLinkId,
          },
          update: (cache, { data }) => {
            const rls = cache.readQuery<ReleaseQuery>({
              query: ReleaseDocument,
              variables: {
                id: releaseId,
              },
            });
            if (rls?.release && data?.reverseSyncPO) {
              cache.writeQuery({
                query: ReleaseDocument,
                variables: {
                  id: releaseId,
                },
                data: {
                  release: {
                    ...rls,
                  },
                },
              });
            }
          },
        });
        return !!data?.reverseSyncPO;
      } catch (error) {
        setError(error);
        return false;
      }
    }
  };

  return { reverseSyncPO };
};
