import { ImportTagsButton } from "@/contractor/pages/home/project/components/import-tags/ImportTagsButton";
import { ProjectExtendedFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  ProjectGroup,
  ProjectSection,
  ProjectSectionContainer,
  ProjectSectionLabel,
} from "../ProjectForm.styles";
import { ProjectFormTags } from "./ProjectFormTags";

const ProjectGroupStyled = tw(ProjectGroup)`md:grid-cols-1 md:pb-0 md:border-0`;

type Props = {
  project: ProjectExtendedFieldsFragment;
  readonly?: boolean;
};

export const ProjectFormProjectTags: FC<Props> = (props) => {
  return (
    <ProjectSectionContainer>
      <ProjectSection>
        <ProjectSectionLabel>
          <FormattedMessage id="PROJECT_TAGS" />
          <ImportTagsButton />
        </ProjectSectionLabel>
        <ProjectGroupStyled>
          <ProjectFormTags {...props} />
        </ProjectGroupStyled>
      </ProjectSection>
    </ProjectSectionContainer>
  );
};
