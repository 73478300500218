import { DateView } from "@/common/utils/dates/DateView";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";

type Props = {
  date: Date | number | null | undefined;
};

const FlexContainer = tw.div`
  flex flex-col h-fit
`;

const DateViewContainer = tw(FlexContainer)`text-sm`;

const DateViewItemContainer = tw.div``;

const DateViewYearContainer = tw.div`
  font-thin
`;

export const DateListView: FC<Props> = ({ date }) => (
  <>
    <If isTrue={date}>
      <DateViewContainer className="text-center">
        <DateViewItemContainer>
          <DateView date={date} options={{ month: "long", day: "numeric" }} />
        </DateViewItemContainer>
        <DateViewYearContainer>
          <DateView date={date} options={{ year: "numeric" }} />
        </DateViewYearContainer>
      </DateViewContainer>
    </If>
    <If isTrue={!date}>
      <DateViewContainer className="text-center">
        <FormattedMessage id="NO_DATE" />
      </DateViewContainer>
    </If>
  </>
);
