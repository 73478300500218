import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  SplitInvoiceDocumentInput,
  useSplitInvoiceDocumentMutation,
} from "@/generated/graphql";

export const useSplitInvoiceDocument = () => {
  const [splitInvoiceDocumentMutation, { loading }] =
    useSplitInvoiceDocumentMutation();
  const { setError } = useGlobalError();

  const splitInvoiceDocument = async (input: SplitInvoiceDocumentInput) => {
    try {
      const { data } = await splitInvoiceDocumentMutation({
        variables: {
          input,
        },
      });
      return data?.splitInvoiceDocument;
    } catch (error) {
      setError(error);
      return null;
    }
  };

  return { splitInvoiceDocument, loading };
};
