import { If } from "@/common/components/if/If";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import {
  IntegrationSettingsExtendedFieldsFragment,
  IntegrationType,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { FoundationOnPremiseIntegration } from "./integration-types/FoundationOnPremiseIntegration";
import { Sage300OnPremiseIntegration } from "./integration-types/Sage300OnPremiseIntegration";
import { SourceSystemIntegration } from "./integration-types/SourceSystemIntegration";

const ListContainer = tw.div`flex flex-col gap-4`;

type Props = {
  integrations: IntegrationSettingsExtendedFieldsFragment | null;
};

export const IntegrationsList: FC<Props> = ({ integrations }) => {
  const hasFoundationIntegration = useMemo(
    () =>
      !!integrations?.accounting.find(
        (a) => a.integration === IntegrationType.Foundation && a.enabled,
      ),
    [integrations?.accounting],
  );
  const hasSage300Integration = useMemo(
    () =>
      !!integrations?.accounting.find(
        (a) => a.integration === IntegrationType.Sage300 && a.enabled,
      ),
    [integrations?.accounting],
  );
  const hasSourceSystemsConnected = useMemo(
    () => integrations?.sourceSystems?.some((item) => item?.connected),
    [integrations],
  );
  const enabledAccountingSettings = useMemo(
    () => integrations?.accounting.find((a) => a.enabled),
    [integrations?.accounting],
  );

  return (
    <ListRenderer
      hasItemsCondition={
        !!(
          hasFoundationIntegration ||
          hasSage300Integration ||
          !!hasSourceSystemsConnected
        )
      }
      emptyList={<NoResults translationKey="NO_INTEGRATIONS" />}
    >
      <ListContainer>
        <If isTrue={hasFoundationIntegration}>
          <FoundationOnPremiseIntegration
            accountingSettings={enabledAccountingSettings}
          />
        </If>
        <If isTrue={hasSage300Integration}>
          <Sage300OnPremiseIntegration
            accountingSettings={enabledAccountingSettings}
          />
        </If>
        {integrations?.sourceSystems
          .filter((s) => s.connected)
          .map((s) => (
            <SourceSystemIntegration key={s.system} sourceSystem={s} />
          ))}
      </ListContainer>
    </ListRenderer>
  );
};
