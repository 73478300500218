import { DeleteOutline } from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { BaseButton } from "./BaseButton";
import { IconButtonBorderless } from "./IconButton";

const DeleteIcon = tw(DeleteOutline)`w-5 h-5`;
type DeleteButtonProps = React.ComponentProps<typeof BaseButton> & {
  iconClassName?: string;
};

export const DeleteButton: FC<DeleteButtonProps> = ({
  iconClassName,
  ...props
}) => {
  return (
    <IconButtonBorderless autoScale {...props} testId="delete-button">
      <DeleteIcon className={iconClassName} />
    </IconButtonBorderless>
  );
};
