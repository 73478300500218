import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { Price } from "@/common/components/price/Price";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { BuyoutItemFieldsFragment, ReleaseStatus } from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { OrderedContainer, OrderedItem } from "./Buyout.styles";
import { BuyoutReleaseAmountsPanel } from "./BuyoutReleaseAmountsPanel";

type Props = {
  title: string;
  amount: string | null | undefined;
  item: BuyoutItemFieldsFragment;
  releaseStatuses: ReleaseStatus[];
};

export const BuyoutReleaseAmounts: FC<Props> = ({
  title,
  amount,
  item,
  releaseStatuses,
}) => {
  const { formatCurrency } = useFormatNumberToCurrency();
  const percentage = useMemo(
    () =>
      new Decimal(amount || 0)
        .div(Number(item.unitPrice) || 1)
        .div(item.quantityDecimal || "1")
        .mul(100)
        .toNumber(),
    [item.quantityDecimal, item.unitPrice, amount],
  );
  const diff = useMemo(
    () =>
      new Decimal(amount || 0)
        .minus(new Decimal(item.unitPrice).mul(item.quantityDecimal || "1"))
        .toNumber(),
    [item.quantityDecimal, item.unitPrice, amount],
  );
  const { hasPermissions: canViewPrices } = usePermissions([
    Permission.canViewPrices,
  ]);

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <OrderedContainer>
          <LinkLike onClick={() => togglePanel(true)}>
            <If isTrue={canViewPrices}>
              <Price price={amount} />
            </If>
          </LinkLike>
          <OrderedItem $error={percentage > 100}>
            <Tooltip
              id="MORE_THAN_TOTAL"
              element={
                <>
                  (
                  <FormattedNumber
                    value={percentage}
                    maximumFractionDigits={2}
                  />
                  %)
                </>
              }
            >
              {percentage > 100 ? (
                <FormattedMessage
                  id="MORE_THAN_TOTAL"
                  values={{ amount: formatCurrency(diff) }}
                />
              ) : null}
            </Tooltip>
          </OrderedItem>
        </OrderedContainer>
      )}
      content={(togglePanel) => (
        <BuyoutReleaseAmountsPanel
          item={item}
          title={title}
          amount={amount}
          material={item.projectItem.material}
          onClose={() => togglePanel(false)}
          releaseStatuses={releaseStatuses}
        />
      )}
    />
  );
};
