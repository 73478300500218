import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useIntegrationTypes } from "@/common/hooks/integrations/integration-type-config/useIntegrationTypes";
import { isEmptyString } from "@/common/utils/validationUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { LineItemLabel, PopoverText } from "../../../Vendors.styles";
const VendorAccountingDetailsContainer = tw.div`flex flex-col gap-3`;
const Link = tw.a`underline`;
const Integration = tw.span``;

export const VendorAccountingDetails: FC = () => {
  const intl = useIntl();
  const { connectedIntegrationType } = useOrgSettings();

  const { watch } = useFormContext();
  const location = watch("location");
  const state = watch("state");
  const newVendorName = watch("newVendor.name");

  const isVisible = useMemo(() => {
    return (
      ((!isEmptyString(location) && !isEmptyString(state)) ||
        !isEmptyString(newVendorName)) &&
      !!connectedIntegrationType
    );
  }, [location, state, newVendorName, connectedIntegrationType]);

  const { integrationTypes } = useIntegrationTypes();

  const integrations = useMemo(() => {
    return integrationTypes.map((ss, key) => {
      if (ss) {
        return (
          <Integration key={key}>
            <Link
              key={key}
              href={ss.config.links?.accountSoftwareLink}
              target="_blank"
            >
              {ss.config.name}
            </Link>
            ,{" "}
          </Integration>
        );
      }
      return <Integration key={key}>{key}, </Integration>;
    });
  }, [integrationTypes]);

  return (
    <If isTrue={isVisible}>
      <VendorAccountingDetailsContainer>
        <FormattedMessage
          id="VENDOR_ACCOUNTING_SOFTWARE"
          tagName={LineItemLabel}
        />
        <TextFieldControlled
          name="externalCode"
          label={intl.$t({ id: "ACCOUNTING_SOFTWARE_ID_OPTIONAL" })}
          InputProps={{
            endAdornment: (
              <Tooltip
                id="external-code"
                element={
                  <LinkLike>
                    <InfoOutlined />
                  </LinkLike>
                }
              >
                <FormattedMessage
                  id="EXTERNAL_CODE_INFO"
                  tagName={PopoverText}
                />
                <br />
                <FormattedMessage
                  id="EXTERNAL_CODE_INFO_EXAMPLES"
                  tagName={PopoverText}
                />{" "}
                {integrations}
              </Tooltip>
            ),
          }}
        />
      </VendorAccountingDetailsContainer>
    </If>
  );
};
