import { useCounter } from "../../circle-counter/useCounter";

const GRID_COUNT_KEY = "count";

export const useCountGeneration = (countMap: Map<string, number>) => {
  const { getCount } = useCounter(countMap);

  const generateCountIndex = (index?: number) =>
    Number(index) < 10
      ? `00${index}`
      : Number(index) < 100
        ? `0${index}`
        : index;
  const generateCount = (
    index1: number,
    index2?: number,
    index3?: number,
    index4?: number,
    index5?: number,
  ) =>
    getCount(
      `${GRID_COUNT_KEY}_${generateCountIndex(index1)}${
        !isNaN(Number(index2)) ? `_${generateCountIndex(index2)}` : ""
      }${!isNaN(Number(index3)) ? `_${generateCountIndex(index3)}` : ""}${
        !isNaN(Number(index4)) ? `_${generateCountIndex(index4)}` : ""
      }${!isNaN(Number(index5)) ? `_${generateCountIndex(index5)}` : ""}`,
    );
  return {
    generateCount,
  };
};
