import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import { OrgRole, SystemRole } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { AdminNavigation } from "./AdminNavigation";

export const WithAdminNavigation: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { viewer, loading } = useUser();

  const isAdmin = useMemo(() => {
    return (
      viewer?.systemRoles.includes(SystemRole.FmAdmin) ||
      viewer?.orgRoles.includes(OrgRole.OrgAdmin) ||
      viewer?.orgRoles.includes(OrgRole.OrgRestrictedAdmin)
    );
  }, [viewer]);

  return loading ? null : isAdmin ? (
    <>
      <AdminNavigation />
      {children}
    </>
  ) : (
    <Navigate to={routes.home} replace />
  );
};
