import { useUser } from "@/common/providers/UserProvider";
import { PoNumberingMode } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { Loader } from "../loader/Loader";
import { TextFieldControlled } from "../textfield-controlled/TextFieldControlled";
import { MagicWand } from "./PoNumberInput.styles";

type PoNumberInputControlledProps = {
  predictedPoNumber: string | null | undefined;
  loading?: boolean;
  trigger: () => void;
  setValue: (poNumber: string) => void;
  includeMagicWand?: boolean;
  required?: boolean;
};

export const PoNumberInputControlled: FC<PoNumberInputControlledProps> = ({
  predictedPoNumber,
  loading,
  setValue,
  trigger,
  includeMagicWand,
  required,
}) => {
  const { poNumbering } = useUser();
  const intl = useIntl();

  const endAdornment = useMemo(() => {
    if (loading) {
      return <Loader loading small className="mr-2 justify-end" />;
    }

    if (
      predictedPoNumber &&
      (poNumbering === PoNumberingMode.Optional || includeMagicWand)
    ) {
      return (
        <MagicWand
          onClick={() => {
            setValue(predictedPoNumber);
            trigger();
          }}
        />
      );
    }
  }, [
    loading,
    predictedPoNumber,
    poNumbering,
    includeMagicWand,
    setValue,
    trigger,
  ]);

  return (
    <TextFieldControlled
      name="poNumber"
      label={intl.$t({ id: "PO_NUMBER" })}
      placeholder={
        poNumbering !== PoNumberingMode.Optional && !includeMagicWand
          ? predictedPoNumber || ""
          : ""
      }
      className="bg-white"
      shrink={!!predictedPoNumber}
      rules={{
        required,
      }}
      InputProps={{
        endAdornment,
      }}
    />
  );
};
