import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ExternalNotificationFieldsFragment,
  QueryNotificationsFilter,
  useResendExternalNotificationMutation,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";
import { useErrorEffect } from "../../../hooks/useErrorEffect";
import { useExternalNotificationsWithPagination } from "../hooks/useExternalNotificationsWithPagination";
import { useGetReleaseFromPathParams } from "../utils/getReleaseFromPathParams";

type ProviderContextType = {
  externalNotifications: ExternalNotificationFieldsFragment[];
  loading: boolean;
  error: boolean;
  externalFilter: QueryNotificationsFilter;
  setExternalFilter: (filter: QueryNotificationsFilter) => void;
  resendExternalNotification: (id: string) => void;
  hasNextPage: boolean;
  loadMore: () => void;
};

const ProviderContext = createContext<ProviderContextType>({
  externalNotifications: [],
  loading: false,
  error: false,
  externalFilter: {},
  setExternalFilter: NoFunction,
  resendExternalNotification: NoFunction,
  hasNextPage: false,
  loadMore: NoFunction,
});

export const ExternalNotificationsProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [resendExternalNotificationMutation] =
    useResendExternalNotificationMutation();
  const { setError } = useGlobalError();
  const { releaseId } = useGetReleaseFromPathParams();

  const [externalFilter, setExternalFilter] =
    useState<QueryNotificationsFilter>({
      projectIds: [],
      sellerOrgLocationIds: [],
      releaseId,
    });
  const {
    externalNotifications,
    loading,
    error,
    hasNextPage,
    loadMore,
    firstPage,
  } = useExternalNotificationsWithPagination(externalFilter);
  useErrorEffect(externalNotifications ? undefined : error);

  const resendExternalNotification = async (id: string) => {
    try {
      const { errors } = await resendExternalNotificationMutation({
        variables: {
          input: {
            id,
          },
        },
      });
      setError(errors);
    } catch (errors) {
      setError(errors);
    }
  };

  const updateFilterAndLoadFirstPage = (filter: QueryNotificationsFilter) => {
    setExternalFilter(filter);
    firstPage();
  };

  return (
    <ProviderContext.Provider
      value={{
        externalNotifications,
        hasNextPage,
        loading,
        error: !!error,
        externalFilter,
        setExternalFilter: updateFilterAndLoadFirstPage,
        resendExternalNotification,
        loadMore,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useExternalNotifications = (): ProviderContextType =>
  useContext(ProviderContext);
