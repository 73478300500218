import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import { QuoteDocumentDetails } from "@/contractor/pages/home/common/quote-document/components/quote-document-details/QuoteDocumentDetails";
import { useReleaseItemsToSubmit } from "@/contractor/pages/home/release/pages/specify-details/hooks/useReleaseItemsToSubmit";
import { ReleaseActionsProvider } from "@/contractor/pages/home/release/providers/ReleaseActionsProvider";
import {
  ReleaseProvider,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseUpdateProvider } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { OrderFromQuoteProvider } from "../../../providers/OrderFromQuoteProvider";
import { useCreateOrderFromQuoteConfig } from "./components/CreateOrderFromQuote.config";
import { CreateOrderFromQuoteBreadcrumbs } from "./components/CreateOrderFromQuoteBreadcrumbs";
import { CreateOrderFromQuoteDetails } from "./components/CreateOrderFromQuoteDetails";
import { CreateOrderFromQuoteFooter } from "./components/CreateOrderFromQuoteFooter";

const Container = tw.div`
  mt-5 xl:-mx-10 sticky 
`;

const GroupContainer = tw.div`
  grid grid-cols-[2.5fr_2fr] gap-4 top-0
`;

const CreateOrderFromQuoteWithProvider: FC = () => {
  const { release } = useRelease();
  const items = useReleaseItemsToSubmit();

  if (!release) {
    return (
      <Container>
        <CreateOrderFromQuoteBreadcrumbs />
        <GroupContainer>
          <CreateOrderFromQuoteDetails />
          <QuoteDocumentDetails />
        </GroupContainer>
        <CreateOrderFromQuoteFooter />
      </Container>
    );
  }

  return (
    <ReleaseActionsProvider>
      <ReleaseUpdateProvider release={release} items={items}>
        <Container>
          <CreateOrderFromQuoteBreadcrumbs />
          <GroupContainer>
            <CreateOrderFromQuoteDetails />
            <QuoteDocumentDetails />
          </GroupContainer>
          <CreateOrderFromQuoteFooter />
        </Container>
      </ReleaseUpdateProvider>
    </ReleaseActionsProvider>
  );
};

export const CreateOrderFromQuote: FC = () => {
  const spreadsheetConfig = useCreateOrderFromQuoteConfig();
  return (
    <ColumnMapperProvider config={spreadsheetConfig}>
      <ReleaseProvider>
        <OrderFromQuoteProvider>
          <CreateOrderFromQuoteWithProvider />
        </OrderFromQuoteProvider>
      </ReleaseProvider>
    </ColumnMapperProvider>
  );
};
