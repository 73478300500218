import { DateTimeView } from "@/common/components/date/DateTimeView";
import { useDrawer } from "@/common/components/panel/DrawerProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { Notification } from "@/generated/graphql";
import { Delete } from "@mui/icons-material";
import { FC, MouseEvent } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { NotificationContext } from "../../../../../generated/graphql";
import { useNotifications } from "../../providers/NotificationsProvider";
import {
  DetailsContainer,
  IconButtonStyled,
  MessageBody,
} from "../common/Notification.styles";

export const Sender = tw.div`
  font-semibold text-sm
`;

const UnreadBubble = tw.div`
  h-3 w-3 bg-blue-500 rounded-full
`;

const Empty = tw.div`
  h-3 w-3
`;

type BaseNotificationProps = {
  notification: Notification;
  onCallback?: (context: NotificationContext) => void;
  children?: React.ReactNode;
};

export const BaseNotification: FC<BaseNotificationProps> = ({
  notification,
  onCallback,
  children,
}) => {
  const { markNotificationRead, removeNotification } = useNotifications();
  const { closeDrawer } = useDrawer();
  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();
  const handleClick = () => {
    if (!notification.readAt) {
      markNotificationRead(notification.id);
    }
    onCallback?.(notification.context);
    closeDrawer();
  };

  const remove = async (event: MouseEvent) => {
    event.stopPropagation();
    if (await removeNotification(notification.id)) {
      setSuccessAlert(intl.$t({ id: "NOTIFICATION_REMOVE_SUCCESS" }));
    }
  };

  return (
    <>
      {notification.readAt ? <Empty /> : <UnreadBubble />}
      <DetailsContainer onClick={handleClick}>
        {children || <MessageBody>{notification.context?.type}</MessageBody>}
      </DetailsContainer>
      <DateTimeView date={notification.createdAt} />
      <IconButtonStyled onClick={remove}>
        <Delete />
      </IconButtonStyled>
    </>
  );
};
