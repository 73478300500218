import { Identity } from "@/types/Identity";
import { NoFunction } from "@/types/NoFunction";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { GridSortDirection } from "../grid-table/GridTable";

export type SortableColumn = {
  direction: GridSortDirection;
  sortFunction: ((item1: Identity, item2: Identity) => number) | undefined;
};

type LocalPaginationContextProps = {
  nextPage: () => void;
  previousPage: () => void;
  page: number;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  setPage: (page: number) => void;
  getPaginatedItems: <T extends { id: string }>(items: T[]) => T[];
  sortableColumn: SortableColumn | null;
  setSortableColumn: (column: SortableColumn | null) => void;
};

const LocalPaginationProviderContext =
  createContext<LocalPaginationContextProps>({
    nextPage: NoFunction,
    previousPage: NoFunction,
    page: 0,
    setPage: NoFunction,
    pageSize: 0,
    setPageSize: NoFunction,
    getPaginatedItems: () => [],
    sortableColumn: null,
    setSortableColumn: NoFunction,
  });

const ITEMS_PER_PAGE = 20;

type LocalPaginationProviderProps = {
  children: React.ReactNode;
  itemsPerPage?: number;
};

export const LocalPaginationProvider: FC<LocalPaginationProviderProps> = ({
  children,
  itemsPerPage = ITEMS_PER_PAGE,
}) => {
  const [pageSize, setPageSize] = useState(itemsPerPage);
  const [page, setPage] = useState(0);
  const [sortableColumn, setSortableColumn] = useState<SortableColumn | null>(
    null,
  );

  const nextPage = () => {
    setPage((oldPage) => oldPage + 1);
  };

  const previousPage = () => {
    if (page > 0) {
      setPage((oldPage) => oldPage - 1);
    }
  };

  const getPaginatedItems = useCallback(
    <T extends { id: string }>(items: T[]) => {
      const start = page * pageSize;
      const end = start + pageSize;
      let newItems = [...items];

      if (sortableColumn) {
        const { direction, sortFunction } = sortableColumn;
        if (sortFunction) {
          const sortFunctionWithDirection =
            direction === GridSortDirection.Ascending
              ? sortFunction
              : (item1: T, item2: T) => sortFunction(item2, item1);

          newItems = items.toSorted(sortFunctionWithDirection);
        }
      }
      return newItems.slice(start, end);
    },
    [page, pageSize, sortableColumn],
  );

  return (
    <LocalPaginationProviderContext.Provider
      value={{
        nextPage,
        previousPage,
        page,
        setPage,
        pageSize,
        setPageSize,
        getPaginatedItems,
        sortableColumn,
        setSortableColumn,
      }}
    >
      {children}
    </LocalPaginationProviderContext.Provider>
  );
};

export const useLocalPagination = (): LocalPaginationContextProps =>
  useContext(LocalPaginationProviderContext);
