import { AssetFieldsFragment } from "@/generated/graphql";
import React, { FC, ReactNode } from "react";
import tw from "tailwind-styled-components";
import { AssetItem } from "../asset-item/AssetItem";
import { ExpandablePanel } from "../expandable-panel/ExpandablePanel";
import { If } from "../if/If";

const Instructions = tw.div`
  text-sm font-light whitespace-pre-line
`;

const Assets = tw.div`
  flex flex-wrap gap-4
`;

type Props = {
  assets?: AssetFieldsFragment[];
  title: string;
  titleIcon?: React.JSX.Element;
  description?: string;
  showOnlyText?: boolean;
  children?: React.JSX.Element;
  className?: string;
  headerClassName?: string;
  expanded?: boolean;
  includeTooltip?: boolean;
  includeNavigation?: boolean;
  imageDetails?: (url: string) => React.JSX.Element | null;
  getImageWrapper?: (url: string, children: ReactNode) => React.JSX.Element;
};

export const AssetsCard: FC<Props> = ({
  assets,
  title,
  titleIcon,
  description,
  showOnlyText = false,
  children,
  className,
  headerClassName,
  expanded = true,
  includeTooltip = true,
  includeNavigation = true,
  imageDetails,
  getImageWrapper = (url, children) => <>{children}</>,
}) => {
  return (
    <If isTrue={assets?.length || (showOnlyText && title)}>
      <ExpandablePanel
        classes={{ container: className, header: headerClassName }}
        isOpened={expanded}
        header={
          <>
            {titleIcon}
            {title}
          </>
        }
      >
        <Instructions>{description}</Instructions>
        <If isTrue={assets?.length}>
          <Assets>
            {assets?.map((asset, index) => (
              <AssetItem
                key={index}
                asset={asset}
                getImageWrapper={getImageWrapper}
                imageDetails={imageDetails}
                includeNavigation={includeNavigation}
                includeTooltip={includeTooltip}
                readonly
              />
            ))}
            {children}
          </Assets>
        </If>
      </ExpandablePanel>
    </If>
  );
};
