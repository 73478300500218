import { Loader } from "@/common/components/loader/Loader";
import { isNonQuotedBuyout } from "@/common/utils/isNonQuotedBuyout";
import { FC } from "react";
import { BuyoutNonQuoted } from "./components/non-quoted/BuyoutNonQuoted";
import { BuyoutQuoted } from "./components/quoted/BuyoutQuoted";
import {
  DistributorBuyoutProvider,
  useDistributorBuyout,
} from "./providers/DistributorBuyoutProvider";

const DistributorBuyoutWithProvider: FC = () => {
  const { buyout } = useDistributorBuyout();

  if (!buyout) {
    return <Loader loading />;
  }

  if (isNonQuotedBuyout(buyout)) {
    return <BuyoutNonQuoted buyout={buyout} />;
  }

  return <BuyoutQuoted buyout={buyout} />;
};

export const DistributorBuyout: FC = () => {
  return (
    <DistributorBuyoutProvider>
      <DistributorBuyoutWithProvider />
    </DistributorBuyoutProvider>
  );
};
