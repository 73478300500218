import { If } from "@/common/components/if/If";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { FC, useCallback } from "react";
import tw from "tailwind-styled-components";
import { ExpandedReleaseItem } from "../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";
import { hasUnreleasedQuantity } from "../../../utils/hasUnreleasedQuantity";

const Container = tw.div`
  flex mr-1 w-5
`;

export const ReleaseItemCheckbox: FC<{
  item: ExpandedReleaseItem;
  checkUnreleasedQuantity?: boolean;
}> = ({ item, checkUnreleasedQuantity }) => {
  const { selectedReleaseItemIds, setSelectedReleaseItemIds } =
    useReleaseUpdate();
  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedReleaseItemIds(
        newChecked
          ? [...selectedReleaseItemIds, item.id]
          : selectedReleaseItemIds.filter((r) => r !== item.id),
      );
    },
    [item.id, selectedReleaseItemIds, setSelectedReleaseItemIds],
  );

  if (!item.id) {
    return null;
  }

  return (
    <Container>
      <If
        isTrue={
          (hasUnreleasedQuantity(item) || !checkUnreleasedQuantity) &&
          item.isIncluded
        }
      >
        <SelectionCheckbox
          itemId={item.id}
          items={selectedReleaseItemIds}
          setSelection={handleChange}
        />
      </If>
    </Container>
  );
};
