import { ReceiptToExportFieldsFragment } from "@/generated/graphql";
import { LinkingProgress } from "@/types/LinkingProgress";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { HostedIntegrationReceiptInformation } from "./HostedIntegrationReceiptInformation";

const Container = tw.div`flex flex-col gap-3`;

type Props = {
  receipts: ReceiptToExportFieldsFragment[];
  linking: LinkingProgress;
};

export const ReceiptAgaveConnections: FC<Props> = ({ receipts, linking }) => {
  const filteredReleases = useMemo(() => {
    if (linking.linking) {
      return receipts;
    } else if (linking.completed.length) {
      return receipts.filter(
        (receipt) => !linking.completed.includes(receipt.id),
      );
    } else {
      return receipts;
    }
  }, [linking.completed, linking.linking, receipts]);

  return (
    <Container>
      {filteredReleases.map((receipt, key) => {
        return (
          <HostedIntegrationReceiptInformation
            receipt={receipt}
            key={key}
            linking={linking.linking && !linking.completed.includes(receipt.id)}
            error={linking.errors.find((e) => e.id === receipt.id)?.message}
          />
        );
      })}
    </Container>
  );
};
