import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import tw from "tailwind-styled-components";
import { useAddItemsToRelease } from "../../AddItemsToReleaseProvider";

const Container = tw.div`
  flex mr-1 w-5
`;

export const AddBuyoutItemCheckbox: FC<{
  item: BuyoutItemFieldsFragment;
}> = ({ item }) => {
  const { selectedItems, setSelectedItems } = useAddItemsToRelease();
  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedItems(
        newChecked
          ? [
              ...selectedItems,
              {
                itemId: item.id,
                quantityDecimal: item.quantityDecimal,
                zoneId: UNSPECIFIED_ZONE_ID,
              },
            ]
          : selectedItems.filter((r) => r.itemId !== item.id),
      );
    },
    [item.id, item.quantityDecimal, selectedItems, setSelectedItems],
  );

  return (
    <Container>
      <SelectionCheckbox
        items={selectedItems}
        itemId={item.id}
        setSelection={handleChange}
        getId={(item) => item.itemId}
      />
    </Container>
  );
};
