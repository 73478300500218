import { If } from "@/common/components/if/If";
import { LineItemNameNotes } from "@/common/components/line-item-name-notes/LineItemNameNotes";
import { useUser } from "@/common/providers/UserProvider";
import {
  AssetFieldsFragment,
  InstructionFieldsFragment,
  ReleaseIssuesFieldsFragment,
  UomFieldsFragment,
} from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Detail = tw.div`grid grid-flow-col justify-start text-2xs ml-2 gap-1 items-start`;
const InfoOutlinedStyled = tw(InfoOutlined)`text-red-500 w-[12px] h-[12px]`;
const IssueText = tw.span`text-red-500 grid grid-flow-col gap-1`;

type Props = {
  instructions: InstructionFieldsFragment | null | undefined;
  notes: string | null | undefined;
  assets: AssetFieldsFragment[];
  issue: ReleaseIssuesFieldsFragment | null | undefined;
  uom?: UomFieldsFragment;
};

export const InvoiceReleaseItemDetails: FC<Props> = ({
  instructions,
  notes,
  assets,
  issue,
  uom,
}) => {
  const { isContractor } = useUser();

  return (
    <>
      <If isTrue={instructions?.text || instructions?.assets?.length}>
        <Detail>
          <FormattedMessage
            id={isContractor ? "YOUR_NOTE_LABEL" : "CONTRACTOR_NOTE_LABEL"}
          />
          <LineItemNameNotes
            className="pl-0"
            notes={instructions?.text}
            assets={instructions?.assets}
          />
        </Detail>
      </If>
      <If isTrue={notes || assets.length}>
        <Detail>
          <FormattedMessage
            id={isContractor ? "VENDOR_NOTE_LABEL" : "YOUR_NOTE_LABEL"}
          />
          <LineItemNameNotes className="pl-0" notes={notes} assets={assets} />
        </Detail>
      </If>
      <If isTrue={issue}>
        <Detail>
          <FormattedMessage id="ORDER_ISSUE" />
          <InfoOutlinedStyled />
          <IssueText>
            {issue?.quantityDecimal}
            {uom?.mnemonic ?? uom?.pluralDescription}{" "}
            <FormattedMessage id={`RELEASE_ISSUE_${issue?.issueType}`} />
          </IssueText>
          <If isTrue={issue?.resolution}>
            <FormattedMessage id="RESOLUTION" />
            <FormattedMessage
              id={issue?.resolution?.resolutionType}
              tagName={IssueText}
            />
          </If>
        </Detail>
      </If>
    </>
  );
};
