import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import {
  COLUMN_TYPE,
  SpreadSheetConfig,
} from "@/common/providers/ColumnMapperProvider";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { CreateBuyoutFromQuoteFormValues } from "./CreateBuyoutFromQuoteForm";

export const useCreateBuyoutFromQuoteConfig = (): SpreadSheetConfig[] => {
  const intl = useIntl();
  const { requiredValidator } = useTableValidators();
  const { buyout } = useContractorBuyout();
  const { watch } = useFormContext<CreateBuyoutFromQuoteFormValues>();
  const projectId = watch("projectId");
  const { costCodes } = useProjectCostCodes(projectId);

  return [
    {
      header: intl.$t({ id: "ITEM_NAME" }),
      columnId: "description",
      width: 100,
      columnType: COLUMN_TYPE.Material,
    },
    {
      header: intl.$t({ id: "TAG" }),
      columnId: "tags.0.name",
      width: 100,
      columnType: COLUMN_TYPE.Tag,
    },
    {
      header: intl.$t({ id: "UOM" }),
      columnId: "UOM",
      columnType: COLUMN_TYPE.UOM,
    },
    {
      header: intl.$t({ id: "QUANTITY" }),
      columnId: "quantityDecimal",
      columnType: COLUMN_TYPE.PositiveQuantity,
    },
    {
      header: intl.$t({ id: "UNIT_PRICE" }),
      columnId: buyout ? "requestedUnitPrice" : "unitPrice",
      columnType: COLUMN_TYPE.UnitPrice,
      validator: requiredValidator,
      disabledForLumpSum: true,
    },
    {
      header: intl.$t({ id: "EXT_PRICE" }),
      columnId: "extPrice",
      columnType: COLUMN_TYPE.ExtPrice,
    },
    {
      header: intl.$t({ id: "COST_CODE" }),
      columnId: "costCode",
      columnType: COLUMN_TYPE.CostCode,
      options: costCodes.map((c) => c.formatted),
      metadata: { projectId },
    },
    {
      header: intl.$t({ id: "NOTES" }),
      columnId: "notes",
      width: 100,
      columnType: COLUMN_TYPE.Notes,
    },
  ];
};
