import {
  TextFieldControlled,
  TextFieldControlledProps,
} from "@/common/components/textfield-controlled/TextFieldControlled";
import { isDefaultCountry } from "@/common/utils/isDefaultCountry";
import { isValidPostalCode } from "@/common/utils/validationUtils";
import { FC, useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

type Props = TextFieldControlledProps & {
  countryInputName: string;
};

export const ProjectAddressPostalCodeTextField: FC<Props> = ({
  countryInputName,
  ...props
}) => {
  const intl = useIntl();
  const { watch } = useFormContext();

  const country = watch(countryInputName);

  const required = useMemo(() => isDefaultCountry(country), [country]);

  const isValid = useCallback(
    (value: string | null) => {
      return !isValidPostalCode(value) && required
        ? intl.$t({ id: "POSTAL_CODE_VALIDATION" })
        : true;
    },
    [intl, required],
  );

  return (
    <TextFieldControlled
      {...props}
      size="small"
      label={intl.$t({ id: "ADDRESS_ZIP_CODE" })}
      rules={{ required, validate: isValid }}
    />
  );
};
