import Decimal from "decimal.js";
import { useCallback } from "react";

const ALLOWED_SYMBOLS = [",", ".", "-"];

export const extPriceCalculation = (
  quantity: number | string | undefined | null,
  unitPrice: number | string | undefined | null,
) => {
  if (
    quantity === undefined ||
    quantity === null ||
    quantity === "" ||
    unitPrice === undefined ||
    unitPrice === null ||
    unitPrice === "" ||
    ALLOWED_SYMBOLS.includes(quantity.toString()) ||
    ALLOWED_SYMBOLS.includes(unitPrice.toString())
  ) {
    return 0;
  }
  const quant = new Decimal(quantity);
  const price = new Decimal(unitPrice);
  return quant.toDP(6).mul(price.toDP(6)).toDP(2).toNumber();
};

const unitPriceCalculation = (
  quantity: number | string | undefined | null,
  extPrice: number | string | undefined | null,
) => {
  if (
    quantity === undefined ||
    quantity === null ||
    quantity === "" ||
    ALLOWED_SYMBOLS.includes(quantity.toString()) ||
    extPrice === undefined ||
    extPrice === null ||
    extPrice === "" ||
    ALLOWED_SYMBOLS.includes(extPrice.toString())
  ) {
    return 0;
  }

  const decimalQuantity = new Decimal(quantity);
  const decimalExtPrice = new Decimal(extPrice);
  const unitPrice = decimalQuantity.equals(0)
    ? new Decimal("0")
    : decimalExtPrice.div(decimalQuantity);
  let decimalPlaces = unitPrice.toString().split(".")[1]?.length;

  if (!decimalPlaces) {
    return unitPrice.toNumber();
  }

  while (
    decimalPlaces > 0 &&
    extPriceCalculation(
      decimalQuantity.toNumber(),
      unitPrice.toDP(decimalPlaces - 1).toNumber(),
    ) === decimalExtPrice.toNumber()
  ) {
    decimalPlaces--;
  }

  return unitPrice.toDP(decimalPlaces).toNumber();
};

export const usePriceCalculation = () => {
  return {
    calcExtPrice: useCallback(extPriceCalculation, []),
    calcUnitPrice: useCallback(unitPriceCalculation, []),
  };
};
