import { useLoadingAction } from "@/common/hooks/useLoadingAction";
import { FC } from "react";
import { Loader } from "../loader/Loader";
import { BaseButton } from "./BaseButton";

type ButtonProps = React.ComponentProps<typeof BaseButton>;

type Props = ButtonProps & {
  button: React.FC<ButtonProps>;
  onClick?: () => Promise<void> | void;
  $small?: boolean;
};

export const LoadingButton: FC<Props> = ({
  children,
  button,
  onClick,
  ...props
}) => {
  const Button = button;
  const { loading, asyncAction } = useLoadingAction();
  return (
    <Button {...props} onClick={() => !loading && asyncAction(onClick)}>
      {loading ? <Loader small loading /> : children}
    </Button>
  );
};
