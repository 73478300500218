import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { useMemo } from "react";
import { AgaveIframe } from "../../../steps/agave-iframe/AgaveIframe";
import { AgaveIframeSuccess } from "../../../steps/agave-iframe/AgaveIframeSuccess";

export const useAgaveConnectionPage = () => {
  const { moveToNextStep } = useNestedStepper();

  const agaveConnectionPage: WizardModalPage = useMemo(
    () => ({
      title: null,
      pages: [
        {
          component: <AgaveIframe />,
          hideModal: true,
        },
        {
          component: <AgaveIframeSuccess />,
          hideModal: true,
          onModalClose: moveToNextStep,
        },
      ],
    }),
    [moveToNextStep],
  );

  return agaveConnectionPage;
};
