import { If } from "@/common/components/if/If";
import { InnerLabeledSwitch } from "@/common/components/switch/InnerLabeledSwitch";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDistributorRelease } from "../providers/DistributorReleaseProvider";
const Container = tw.div`
  flex items-center z-100
`;

type Props = {
  items: DistributorReleaseItemFieldsFragment[];
  readonly?: boolean;
};

export const DistributorReleaseCategoryToggle: FC<Props> = ({
  items,
  readonly,
}) => {
  const intl = useIntl();

  const [includeItem, toggleItem] = useState<boolean>(
    items.every((item) => item.isIncluded),
  );
  const [isNeutral, setIsNeutral] = useState<boolean>(false);
  const { updateVendorRelease, changes } = useDistributorRelease();

  useEffect(() => {
    const activeCount = items.filter((item) => {
      if (changes && changes.updates) {
        return (
          changes.updates.find((u) => u.releaseItemId === item.id)
            ?.isIncluded ?? item.isIncluded
        );
      } else {
        return item.isIncluded;
      }
    }).length;
    setIsNeutral(activeCount > 0 && activeCount !== items.length);
    toggleItem(activeCount === items.length);
  }, [items, changes]);

  const onToggle = useCallback(
    async (checked: boolean) => {
      setIsNeutral(false);
      const updates = items.map((item) => ({
        releaseItemId: item.id,
        isIncluded: checked,
      }));
      await updateVendorRelease({
        updates,
      });

      toggleItem(checked);
    },
    [items, updateVendorRelease],
  );

  return (
    <Container onClick={(e: React.MouseEvent) => e.stopPropagation()}>
      <If isTrue={!readonly}>
        <InnerLabeledSwitch
          toggle={onToggle}
          className="print:hidden"
          width={80}
          initialValue={includeItem}
          onLabel={
            isNeutral ? intl.$t({ id: "YES" }) : intl.$t({ id: "YES_ALL" })
          }
          offLabel={
            isNeutral ? intl.$t({ id: "NO" }) : intl.$t({ id: "NO_ALL" })
          }
          neutral={isNeutral}
        />
      </If>
    </Container>
  );
};
