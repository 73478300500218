import { CircleCounter } from "@/common/components/circle-counter/CircleCounter";
import { If } from "@/common/components/if/If";
import { Popover } from "@/common/components/popover/Popover";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { CountType } from "../circle-counter/CountType";

const CircleCounterStyled = tw(
  CircleCounter,
)`print:hidden mr-2 float-left justify-center`;

type Props = {
  item: Pick<ExpandedReleaseItem, "redelivery" | "backorderFromRelease">;
  count: CountType;
};

export const ReleaseItemPadding: FC<Props> = ({ item, count }) => {
  return (
    <If isTrue={item.redelivery?.id || item.backorderFromRelease}>
      <CircleCounterStyled count={count} />
      <Popover
        element={<MoveUpIcon sx={{ fontSize: 32 }} color="primary" />}
        id="order-issue"
        $arrow
        position="top"
      >
        <If isTrue={item.redelivery}>
          <FormattedMessage
            id="RELEASE_MOVED"
            values={{
              oldOrder:
                item.redelivery?.issue?.releaseItem?.release?.sequenceNumber,
            }}
          />
        </If>
        <If isTrue={item.backorderFromRelease}>
          <FormattedMessage
            id="RELEASE_BACKORDERED"
            values={{
              oldOrder: item.backorderFromRelease?.sequenceNumber,
            }}
          />
        </If>
      </Popover>
    </If>
  );
};
