import { DateView } from "@/common/utils/dates/DateView";
import { UsersUserFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  user: UsersUserFieldsFragment;
};

const Container = tw.div<{ $active: boolean }>`
  px-2 py-2 rounded w-full
  ${({ $active }: { $active: boolean }) =>
    $active ? "bg-green-200" : "bg-yellow-600"}
`;
const Status = tw.div`
  font-medium
`;
const CreationDate = tw.div`
  font-light text-xs
`;

export const UserStatus: FC<Props> = ({ user }) => {
  return (
    <Container $active={user.isEmailValidated}>
      <Status>
        <FormattedMessage
          id={
            user.isEmailValidated
              ? "USERS_STATUS_ACTIVE"
              : "USERS_STATUS_PENDING"
          }
        />
      </Status>
      <CreationDate>
        <DateView date={user.createdAt} />
      </CreationDate>
    </Container>
  );
};
