import { Select } from "@/common/components/select/components/single/Select";
import {
  ToggleFilterItems,
  ToggleFilterType,
} from "@/common/components/toggle-filter-items/ToggleFilterItems";
import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import { useReleaseItemsZoneProvider } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";

const Container = tw.div`
  grid grid-flow-col gap-4 justify-end text-xs font-normal items-center my-1
`;

const SortContainer = tw.div`
  grid grid-flow-col gap-2 justify-end text-xs font-normal items-center my-1
`;

const INVOICE_SORT_ORDER = "invoiceSortOrder";

enum SortOptions {
  ZonesAndCodes = "ZONES_AND_COST_CODES",
  OriginalOrder = "ORIGINAL_ORDER",
  InvoiceOrder = "INVOICE_ORDER",
  ItemDescription = "ITEM_DESCRIPTION_A_Z",
}

export const InvoiceReleaseItemsFilterHeader: FC = () => {
  const { invoice, showOnlyInvoicedItems, setShowOnlyInvoicedItems } =
    useInvoiceVerification();
  const { groupedByCostCode, setGroupedByCostCode, setSortFunction } =
    useReleaseItemsZoneProvider();
  const { release } = useRelease();
  const { readValue, setValue } = useLocalStorage();
  const [sortingOrder, setSortingOrder] = useState(SortOptions.OriginalOrder);

  const intl = useIntl();

  const sortOptions = useMemo(() => {
    return Object.values(SortOptions).map((value) => ({
      value,
      label: intl.formatMessage({ id: value }),
    }));
  }, [intl]);

  const hintMap = useMemo(() => {
    const map = new Map<string, number>();
    release?.items.forEach((item) => {
      const invoiceItem = invoice?.releaseItemHints.find(
        (i) => i?.releaseItem?.id === item?.id,
      )?.invoiceItem;
      const index =
        invoice?.items.findIndex((i) => i?.id === invoiceItem?.id) ?? -1;
      map.set(item?.id ?? "", index !== -1 ? index + 1 : 0);
    });
    return map;
  }, [invoice?.items, invoice?.releaseItemHints, release?.items]);

  const sortByInvoicePosition = useCallback(
    (a: ReleaseItemFieldsFragment, b: ReleaseItemFieldsFragment) => {
      const hintA = hintMap.get(a.id);
      const hintB = hintMap.get(b.id);
      return hintA ? (hintB ? hintA - hintB : -1) : 1;
    },
    [hintMap],
  );

  const sortByItemName = useCallback(
    (a: ReleaseItemFieldsFragment, b: ReleaseItemFieldsFragment) =>
      a?.name.localeCompare(b?.name),
    [],
  );

  const onSortChange = useCallback(
    (value: string | null) => {
      setValue(INVOICE_SORT_ORDER, value);
      switch (value) {
        case SortOptions.ZonesAndCodes:
          setGroupedByCostCode(true);
          break;
        case SortOptions.OriginalOrder:
          setGroupedByCostCode(false);
          setSortFunction(undefined);
          break;
        case SortOptions.InvoiceOrder:
          setGroupedByCostCode(false);
          setSortFunction(() => sortByInvoicePosition);
          break;
        case SortOptions.ItemDescription:
          setGroupedByCostCode(false);
          setSortFunction(() => sortByItemName);
          break;
      }
      setSortingOrder(value as SortOptions);
    },
    [
      setGroupedByCostCode,
      setSortFunction,
      setValue,
      sortByInvoicePosition,
      sortByItemName,
    ],
  );

  useEffect(() => {
    const sort = readValue(INVOICE_SORT_ORDER) as SortOptions;
    if (sort) {
      onSortChange(sort);
    }
  }, [onSortChange, readValue]);

  useEffect(() => {
    if (groupedByCostCode && sortingOrder !== SortOptions.ZonesAndCodes) {
      setSortingOrder(SortOptions.ZonesAndCodes);
      setSortFunction(undefined);
    } else if (
      !groupedByCostCode &&
      sortingOrder === SortOptions.ZonesAndCodes
    ) {
      setSortingOrder(SortOptions.InvoiceOrder);
      setGroupedByCostCode(false);
      setSortFunction(() => sortByInvoicePosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedByCostCode]);

  return (
    <Container>
      <ToggleFilterItems
        onToggle={setShowOnlyInvoicedItems}
        show={showOnlyInvoicedItems}
        type={ToggleFilterType.Invoice}
      />
      <SortContainer>
        <FormattedMessage id="SORT_ITEMS_BY" />
        <Select
          options={sortOptions}
          getLabel={(option) => option.label}
          getValue={(option) => option.value}
          value={sortingOrder}
          onChange={onSortChange}
        />
      </SortContainer>
    </Container>
  );
};
