import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { ProductImage } from "./ProductImage";

type Props = {
  sku?: {
    description: string;
    imageUrl: string;
    name: string;
  };
  brand?: string;
  onClose: () => void;
};

const ProductInfoPanelContainer = tw.div``;
const ProductName = tw.div`text-base`;
const ProductImageStyled = tw(ProductImage)`my-2`;
const ProductDescription = tw.div`text-xs`;

export const ProductInfoPanel: FC<Props> = ({ sku, brand, onClose }) => {
  if (!sku) {
    return null;
  }

  return (
    <OverlayPanel title={brand || ""} onCancel={onClose}>
      <ProductInfoPanelContainer>
        <ProductName>{sku.name}</ProductName>
        <ProductImageStyled imageUrl={sku.imageUrl} name={sku.name} />
        <ProductDescription>{sku.description}</ProductDescription>
      </ProductInfoPanelContainer>
    </OverlayPanel>
  );
};
