import { ReleaseSummaryFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { OnPremiseReleaseInformation } from "./OnPremiseReleaseInformation";

const Container = tw.div`flex flex-col gap-3`;

type Props = {
  releases: ReleaseSummaryFieldsFragment[];
};

export const FoundationReleaseExport: FC<Props> = ({ releases }) => {
  return (
    <Container>
      {releases.map((release, key) => {
        return <OnPremiseReleaseInformation release={release} key={key} />;
      })}
    </Container>
  );
};
