import { useEffect, useState } from "react";

type Props = {
  ref: React.RefObject<HTMLDivElement>;
  top: number;
};

function useSticky({ ref, top }: Props) {
  const [isStuck, setIsStuck] = useState<boolean>(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsStuck(!entry.isIntersecting),
      { rootMargin: `-${top}px`, threshold: 0 },
    );

    const currentElement = ref.current;

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [ref, top]);

  return isStuck;
}

export default useSticky;
