import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useToggleCategory } from "@/common/hooks/useToggleCategory";
import { useEffect } from "react";
import { useRfqGroupedItemsStore } from "../stores/useRfqGroupedItemsStore";
import { useRfq } from "./useRfq";
import { useRfqMutations } from "./useRfqMutations";

export const useRfqGroupedItems = () => {
  const {
    rfqGroupedItems,
    setRfqGroupedItems,
    newRfqItem,
    setNewRfqItem,
    fetchRfq,
    selectedRfqItemIds,
    setSelectedRfqItemIds,
    expandedItems,
    setExpandedItems,
    setIsRfqWithErrors,
    isRfqWithErrors,
  } = useRfqGroupedItemsStore();
  const { rfq } = useRfq();
  const { toggleCategory: toggleCostCode } = useToggleCategory(
    rfqGroupedItems || [],
    setRfqGroupedItems,
  );
  const { setError } = useGlobalError();
  const { updateRfq } = useRfqMutations();
  useEffect(() => {
    if (rfq?.id) {
      fetchRfq(rfq.id);
    }
  }, [fetchRfq, rfq?.id]);

  const addToRfq = async () => {
    const rfqId = rfq?.id;
    if (!newRfqItem || !rfqId) {
      return false;
    }
    try {
      const result = await updateRfq({
        addedItems: [
          {
            orgCatalogSku:
              newRfqItem.orgCatalogSkuName &&
              !newRfqItem.projectItem?.orgCatalogSkuId
                ? {
                    name: newRfqItem.orgCatalogSkuName,
                    defaultUom: newRfqItem.projectItem?.estimateUom || "",
                  }
                : undefined,

            projectItem: newRfqItem.orgCatalogSkuName
              ? undefined
              : {
                  orgCatalogSkuId: newRfqItem.projectItem?.orgCatalogSkuId,
                  masterProductId: newRfqItem.projectItem?.masterProductId,
                  masterSkuId: newRfqItem.projectItem?.masterSkuId,
                  estimateUom: newRfqItem.projectItem?.estimateUom || "",
                },
            description: newRfqItem.description || "",
            quantityDecimal: newRfqItem.quantityDecimal || "0",
            manufacturerId: newRfqItem.manufacturerId,
            instructions: newRfqItem.instructions,
          },
        ],
        rfqId,
        assignDefaultCostCodes: false,
      });

      if (!result) {
        setNewRfqItem(null);
        fetchRfq(rfqId, { force: true });
      }
      return result;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  return {
    rfqGroupedItems,
    newRfqItem,
    setNewRfqItem,
    toggleCostCode,
    selectedRfqItemIds,
    setSelectedRfqItemIds,
    isRfqWithErrors,
    setIsRfqWithErrors,
    expandedItems,
    setExpandedItems,
    addToRfq,
  };
};
