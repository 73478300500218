import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CreateInvoiceKickbackInput,
  ResolveInvoiceKickbackInput,
  UpdateInvoiceKickbackInput,
  useCreateInvoiceKickbackMutation,
  useDeleteInvoiceKickbackMutation,
  useResolveInvoiceKickbackMutation,
  useUpdateInvoiceKickbackMutation,
} from "@/generated/graphql";

export const useInvoiceKickbacks = () => {
  const [createInvoiceKickbackMutation, { loading: creating }] =
    useCreateInvoiceKickbackMutation();
  const [updateInvoiceKickbackMutation, { loading: updating }] =
    useUpdateInvoiceKickbackMutation();
  const [resolveInvoiceKickbackMutation, { loading: resolving }] =
    useResolveInvoiceKickbackMutation();
  const [deleteInvoiceKickbackMutation, { loading: deleting }] =
    useDeleteInvoiceKickbackMutation();
  const { setError } = useGlobalError();

  const createInvoiceKickback = async (input: CreateInvoiceKickbackInput) => {
    try {
      const { data } = await createInvoiceKickbackMutation({
        variables: {
          input,
        },
      });
      return data?.createInvoiceKickback;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const updateInvoiceKickback = async (input: UpdateInvoiceKickbackInput) => {
    try {
      const { data } = await updateInvoiceKickbackMutation({
        variables: {
          input,
        },
      });
      return !!data?.updateInvoiceKickback;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const resolveInvoiceKickback = async (input: ResolveInvoiceKickbackInput) => {
    try {
      const { data } = await resolveInvoiceKickbackMutation({
        variables: {
          input,
        },
      });
      return !!data?.resolveInvoiceKickback;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const deleteInvoiceKickback = async (id: string) => {
    try {
      const { data } = await deleteInvoiceKickbackMutation({
        variables: {
          id,
        },
      });
      return !!data?.deleteInvoiceKickback;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return {
    createInvoiceKickback,
    updateInvoiceKickback,
    resolveInvoiceKickback,
    deleteInvoiceKickback,
    loading: creating || updating || resolving || deleting,
  };
};
