import { IconButtonBorderless } from "@/common/components/button/IconButton";
import tw from "tailwind-styled-components";

export const NoNotifications = tw.div`
  text-center text-gray-600 mt-8
`;

export const NotificationContainer = tw.div<{
  $read?: boolean;
  $disabled?: boolean;
}>`
  flex gap-2 items-center relative border-b justify-between 
  ${({ $read }: { $read?: boolean }) => $read && "opacity-70"}
  ${({ $disabled }: { $disabled?: boolean }) =>
    !$disabled &&
    "hover:opacity-100 hover:bg-gray-100 cursor-pointer transition-all ease-out duration-200"}
`;

export const DetailsContainer = tw.div`
  flex flex-col w-64 py-2 px-1
`;

export const MessageBody = tw.div`
  text-black text-sm line-clamp-4
`;

export const IconButtonStyled = tw(
  IconButtonBorderless,
)`max-w-14 justify-end flex`;
