import { WarehousesProvider } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import { QuoteDocumentProvider } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { ProjectTagsProvider } from "@/contractor/pages/home/project/providers/ProjectTagsProvider";
import { CreateOrderFromQuote } from "./create-order-from-quote/CreateOrderFromQuote";
import { CreateOrderFromQuoteForm } from "./create-order-from-quote/components/CreateOrderFromQuoteForm";

export const OrderFromQuote = () => {
  return (
    <CreateOrderFromQuoteForm>
      <WarehousesProvider>
        <ProjectTagsProvider>
          <QuoteDocumentProvider>
            <CreateOrderFromQuote />
          </QuoteDocumentProvider>
        </ProjectTagsProvider>
      </WarehousesProvider>
    </CreateOrderFromQuoteForm>
  );
};
