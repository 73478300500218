import { InvoiceToExportFieldsFragment } from "@/generated/graphql";
import { LinkingProgress } from "@/types/LinkingProgress";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { HostedIntegrationInvoiceInformation } from "./HostedIntegrationInvoiceInformation";

const Container = tw.div`flex flex-col gap-3`;

type Props = {
  invoices: InvoiceToExportFieldsFragment[];
  linking: LinkingProgress;
};

export const InvoiceAgaveConnections: FC<Props> = ({ invoices, linking }) => {
  const filteredReleases = useMemo(() => {
    if (linking.linking) {
      return invoices;
    } else if (linking.completed.length) {
      return invoices.filter(
        (invoice) => !linking.completed.includes(invoice.id),
      );
    } else {
      return invoices;
    }
  }, [linking.completed, linking.linking, invoices]);

  return (
    <Container>
      {filteredReleases.map((invoice, key) => {
        return (
          <HostedIntegrationInvoiceInformation
            invoice={invoice}
            key={key}
            linking={linking.linking && !linking.completed.includes(invoice.id)}
            error={linking.errors.find((e) => e.id === invoice.id)?.message}
          />
        );
      })}
    </Container>
  );
};
