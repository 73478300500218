import Handsontable from "handsontable";
import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { useRenderHelpers } from "./useRenderHelpers";

type VendorRenderer = (
  instance: Handsontable,
  td: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: string,
  cellProperties: Handsontable.CellProperties,
) => void;

export const useVendorRenderer = () => {
  const { checkReadOnly } = useRenderHelpers();

  const renderer: VendorRenderer = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      textRenderer(instance, td, row, col, prop, value, cellProperties);
      checkReadOnly(td, row, instance, cellProperties);
    },
    [checkReadOnly],
  );

  return renderer;
};
