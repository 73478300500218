import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  SetVendorPricesInput,
  useSetVendorPricesMutation,
} from "@/generated/graphql";
import { useCallback, useEffect } from "react";
import { useVendorPricesStore } from "../stores/useVendorPricesStore";

export const useVendorPrices = () => {
  const { setPage } = usePagination();
  const { prices, fetchVendorPrices, globalVendorId, setGlobalVendorId } =
    useVendorPricesStore();
  const { setError } = useGlobalError();

  useEffect(() => {
    fetchVendorPrices();
  }, [fetchVendorPrices]);

  const [setVendorPriceMutation, { loading: updating }] =
    useSetVendorPricesMutation();

  const updatePrices = useCallback(
    async (input: SetVendorPricesInput) => {
      try {
        const { errors } = await setVendorPriceMutation({
          variables: {
            input,
          },
        });
        setError(errors);
        setPage({ page: 0 });
        return !errors;
      } catch (e) {
        setError(e);
        return false;
      }
    },
    [setVendorPriceMutation, setError, setPage],
  );

  return {
    prices: prices ?? [],
    updating,
    updatePrices,
    globalVendorId,
    setGlobalVendorId,
  };
};
