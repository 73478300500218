import { RefObject, useCallback, useEffect, useState } from "react";

export const useIsTextOverflow = (ref: RefObject<HTMLElement>) => {
  const [isTextOverflow, setIsTextOverflow] = useState(false);

  const compareSize = useCallback(() => {
    if (ref.current) {
      const { scrollWidth, clientWidth, scrollHeight, clientHeight } =
        ref.current;
      setIsTextOverflow(
        scrollWidth > clientWidth || scrollHeight > clientHeight,
      );
    }
  }, [ref]);

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);

    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, [compareSize]);

  return { isTextOverflow };
};
