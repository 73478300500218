import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { IntegrationType, ReleaseFieldsFragment } from "@/generated/graphql";
import { UpdatedRelease } from "../providers/ReleaseConnectionOptionsProvider";
type Release = Pick<
  ReleaseFieldsFragment,
  | "status"
  | "exportedAt"
  | "exportedVersion"
  | "version"
  | "poNumber"
  | "taxRate"
  | "total"
  | "id"
  | "hasMissingExternalCodes"
  | "hasMissingCostCodes"
  | "customTaxAmount"
> & {
  project?: { jobNumber?: string | null } | null;
  preferredVendor?: { externalCode?: string | null } | null;
};

export const useIsValidFoundationReleaseExport = () => {
  const { settings } = useOrgSettings();

  const isValidFoundationReleaseExport = ({
    release,
    updatedReleases,
    poFormatBasic,
  }: {
    release: Release;
    updatedReleases?: UpdatedRelease[];
    poFormatBasic: boolean;
  }) => {
    return (
      !release.hasMissingCostCodes &&
      release.project?.jobNumber &&
      (release.preferredVendor?.externalCode ||
        updatedReleases?.find((po) => po.releaseId === release.id)
          ?.externalVendorCode) &&
      release.total !== null &&
      (release.customTaxAmount !== null ||
        settings?.integrations.accounting
          .find(
            (a) => a.enabled && a.integration === IntegrationType.Foundation,
          )
          ?.taxAuthorities.some((t) => t.rate === release?.taxRate)) &&
      !(!poFormatBasic && release.hasMissingExternalCodes)
    );
  };

  return { isValidFoundationReleaseExport };
};
