import { InfoOutlined } from "@mui/icons-material";
import { useIntl } from "react-intl";
import {
  SimpleStepCol,
  SimpleStepContainer,
  SimpleStepContent,
  SimpleStepRow,
  SimpleStepTitle,
  SimpleStepTitleContainer,
  StyledTextField,
} from "../../../Wizard.styles";
import { useAccountingOnPremiseWizard } from "../../AccountingOnPremiseWizardProvider";

export const AccountingOnPremiseMaterialCostType = () => {
  const intl = useIntl();
  const { materialCostType, setMaterialCostType } =
    useAccountingOnPremiseWizard();

  return (
    <SimpleStepContainer>
      <SimpleStepRow>
        <InfoOutlined className="mr-4 h-12 w-12 text-gray-400" />
        <SimpleStepTitleContainer>
          <SimpleStepTitle>
            {intl.$t({ id: "SPECIFY_MATERIAL_COST_TYPE" })}
          </SimpleStepTitle>
        </SimpleStepTitleContainer>
      </SimpleStepRow>
      <SimpleStepContent $narrow>
        <SimpleStepCol>
          <StyledTextField
            label={intl.$t({ id: "ACCOUNTING_MATERIAL_COST_TYPE" })}
            value={materialCostType}
            onChange={(event) => setMaterialCostType(event.target.value)}
            required
          />
        </SimpleStepCol>
      </SimpleStepContent>
    </SimpleStepContainer>
  );
};
