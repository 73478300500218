import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import { routes } from "@/config/routes";
import { InvoiceStatus } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";

export type InvoiceSequenceData = {
  id: string;
  status: InvoiceStatus;
  link?: {
    failedAt?: number | null;
  };
};

type ProviderContextType = {
  step: number;
  setStep: (step: number) => void;
  sequenceIds: string[];
  selectedInvoices: InvoiceSequenceData[];
  setSelectedInvoices: (
    invoices: InvoiceSequenceData[],
    save?: boolean,
  ) => void;
  sequenceActive: boolean;
  startSequence: () => void;
  stopSequence: () => void;
  navigateToNextSequence: (params?: { navigateToInvoices: boolean }) => void;
  redirectToInvoices: () => void;
};

const ProviderContext = createContext<ProviderContextType>({
  step: 0,
  setStep: NoFunction,
  sequenceIds: [],
  selectedInvoices: [],
  setSelectedInvoices: NoFunction,
  sequenceActive: false,
  startSequence: NoFunction,
  stopSequence: NoFunction,
  navigateToNextSequence: NoFunction,
  redirectToInvoices: NoFunction,
});

const invoiceToSequence = (invoice: InvoiceSequenceData) => ({
  id: invoice.id,
  status: invoice.status,
  link: invoice.link ? { failedAt: invoice.link?.failedAt } : undefined,
});

export const InvoiceSequenceProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { readValue, setValue, removeValue } = useLocalStorage();
  const [selectedInvoices, setSelectedInvoices] = useState<
    InvoiceSequenceData[]
  >(
    readValue(
      LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SELECTED_INVOICES,
      [],
    ) as InvoiceSequenceData[],
  );
  const [step, setStep] = useState(
    readValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_STEP, 0) as number,
  );
  const [sequenceActive, setSequenceActive] = useState(
    readValue(
      LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_ACTIVE,
      false,
    ) as boolean,
  );

  const setSelectedInvoicesAndUpdateLocalStorage = useCallback(
    (invoices: InvoiceSequenceData[]) => {
      setSelectedInvoices(invoices.map(invoiceToSequence));
      setValue(
        LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SELECTED_INVOICES,
        invoices,
      );
    },
    [setValue],
  );

  const setSequenceActiveAndUpdateLocalStorage = useCallback(
    (active: boolean) => {
      setSequenceActive(active);
      setValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_ACTIVE, active);
    },
    [setValue],
  );

  const setStepAndUpdateLocalStorage = useCallback(
    (step: number) => {
      setStep(step);
      setValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_STEP, step);
    },
    [setValue],
  );

  const startSequence = useCallback(() => {
    setSelectedInvoicesAndUpdateLocalStorage(selectedInvoices);
    setSequenceActiveAndUpdateLocalStorage(true);
    setValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_ACTIVE, true);
    const sequenceStep = readValue(
      LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_STEP,
      0,
    ) as number;
    const firstInvoice = selectedInvoices[sequenceStep];
    if (firstInvoice) {
      setValue(
        LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.REDIRECT_ROUTE,
        location.search,
      );
      navigate(
        generatePath(routes.invoiceVerification, {
          invoiceId: firstInvoice.id,
        }),
      );
    }
  }, [
    location.search,
    navigate,
    readValue,
    selectedInvoices,
    setSelectedInvoicesAndUpdateLocalStorage,
    setSequenceActiveAndUpdateLocalStorage,
    setValue,
  ]);

  const stopSequence = useCallback(() => {
    setSelectedInvoicesAndUpdateLocalStorage([]);
    setSequenceActiveAndUpdateLocalStorage(false);
    setStepAndUpdateLocalStorage(0);
    removeValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.REDIRECT_ROUTE);
    removeValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_STEP);
  }, [
    removeValue,
    setSelectedInvoicesAndUpdateLocalStorage,
    setSequenceActiveAndUpdateLocalStorage,
    setStepAndUpdateLocalStorage,
  ]);

  const sequenceIds = useMemo(
    () => selectedInvoices.map((i) => i.id),
    [selectedInvoices],
  );

  const redirectToInvoices = useCallback(() => {
    const redirectParams = readValue(
      LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.REDIRECT_ROUTE,
      "",
    ) as string | undefined;
    stopSequence();
    navigate(generatePath(`${routes.invoices}${redirectParams}`));
  }, [readValue, navigate, stopSequence]);

  const navigateToNextSequence = useCallback(
    ({ navigateToInvoices } = { navigateToInvoices: false }) => {
      if (sequenceActive) {
        if (sequenceIds.length <= step + 1) {
          redirectToInvoices();
        } else {
          setStepAndUpdateLocalStorage(step + 1);
          navigate(
            generatePath(routes.invoiceVerification, {
              invoiceId: sequenceIds[step + 1],
            }),
          );
        }
      } else if (navigateToInvoices) {
        redirectToInvoices();
      }
    },
    [
      sequenceActive,
      sequenceIds,
      step,
      redirectToInvoices,
      setStepAndUpdateLocalStorage,
      navigate,
    ],
  );

  const setSelectedInvoicesAndSaveToLocalStorage = useCallback(
    (invoices: InvoiceSequenceData[], saveToLocalStorage = false) => {
      setSelectedInvoices(invoices.map(invoiceToSequence));
      if (saveToLocalStorage) {
        setValue(
          LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SELECTED_INVOICES,
          invoices,
        );
        if (invoices.length > 1) {
          setValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_ACTIVE, true);
          setValue(LOCAL_STORAGE_KEYS.INVOICE_SEQUENCES.SEQUENCE_STEP, 0);
        }
      }
    },
    [setValue],
  );

  return (
    <ProviderContext.Provider
      value={{
        step,
        setStep: setStepAndUpdateLocalStorage,
        sequenceIds,
        selectedInvoices,
        setSelectedInvoices: setSelectedInvoicesAndSaveToLocalStorage,
        sequenceActive,
        startSequence,
        stopSequence,
        navigateToNextSequence,
        redirectToInvoices,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceSequence = (): ProviderContextType =>
  useContext(ProviderContext);
