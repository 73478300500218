import { CheckCircleOutline, InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`grid grid-rows-[auto_1fr] w-full h-full gap-4`;
const Notification = tw.div`
  grid grid-cols-[auto,_150px] place-items-center 
  bg-gray-100 rounded-3xl p-8 justify-center gap-4
`;
const NotificationText = tw.div`text-base font-medium`;

const Info = tw.div`
  grid place-items-center gap-4 content-center p-30
`;
const InfoText = tw.div`text-base font-medium text-center`;

export const NewDeliverySlipCreatedInfo: FC = () => {
  return (
    <Container>
      <Notification>
        <CheckCircleOutline className="text-5xl text-blue-500" />
        <FormattedMessage
          id="DELIVERY_SLIP_CREATED"
          tagName={NotificationText}
        />
      </Notification>
      <Info>
        <InfoOutlined className="text-5xl text-gray-300" />
        <FormattedMessage id="DELIVERY_SLIP_CREATED_INFO" tagName={InfoText} />
      </Info>
    </Container>
  );
};
