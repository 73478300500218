import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { generateUUID } from "@/common/utils/uuidUtils";
import { AdditionalChargesFieldsFragment } from "@/generated/graphql";
import React, { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { TextField } from "../textfield/TextField";
import { TextFieldContainer } from "./AdditionalCharges.styles";

const Container = tw.div<{
  $readonly: boolean;
}>`
  grid gap-4 text-right items-center text-sm md:text-base font-medium justify-end
  ${({ $readonly }: { $readonly: boolean }) =>
    $readonly ? "grid-cols-[auto_164px]" : "grid-cols-[auto_auto_24px]"} 
`;
const Item = tw.div`flex items-center justify-end`;

type Props = {
  charge: AdditionalChargesFieldsFragment;
  readonly: boolean;
  index: number;
  showError?: boolean;
  children?: React.JSX.Element;
  onChange: (additionalCharge: AdditionalChargesFieldsFragment) => void;
};

export const AdditionalCharge: FC<Props> = ({
  charge,
  readonly,
  index,
  showError = false,
  children,
  onChange,
}) => {
  const intl = useIntl();
  const [additionalCharge, setAdditionalCharge] =
    useState<AdditionalChargesFieldsFragment>(charge);

  useEffect(() => {
    setAdditionalCharge(charge);
  }, [charge]);

  const update = useCallback(
    (updatedCharge: AdditionalChargesFieldsFragment, triggerChange = true) => {
      setAdditionalCharge(updatedCharge);
      if (
        triggerChange &&
        Number(updatedCharge.amount) >= 0 &&
        updatedCharge.description !== undefined
      ) {
        onChange({
          ...updatedCharge,
          id: updatedCharge.id || generateUUID(),
        });
      }
    },
    [onChange],
  );

  const updateDescription = useCallback(
    (event: React.FocusEvent<HTMLInputElement>, triggerChange?: boolean) => {
      update(
        {
          ...additionalCharge,
          description: event.target.value,
        },
        triggerChange,
      );
    },
    [additionalCharge, update],
  );

  const updateAmount = useCallback(
    (value: string | null) => {
      update({
        ...additionalCharge,
        amount: value || "0",
      });
    },
    [additionalCharge, update],
  );

  return (
    <Container key={index} $readonly={readonly}>
      <Item data-testid="order-additionalChargeDescription">
        <Tooltip
          id={`${additionalCharge.id}-key`}
          element={
            <TextField
              value={additionalCharge.description}
              staticText={readonly}
              onChange={(event: React.FocusEvent<HTMLInputElement>) =>
                updateDescription(event, false)
              }
              onBlur={updateDescription}
              inputProps={{
                className: `text-sm text-right bg-white min-w-40 ${
                  readonly && "!bg-transparent"
                }`,
              }}
              placeholder={intl.$t({ id: "ADDITIONAL_CHARGE" })}
              error={!additionalCharge.description && showError}
              xs
            />
          }
        >
          {!readonly && <FormattedMessage id="ADDITIONAL_CHARGE_INFO" />}
        </Tooltip>
      </Item>
      <TextFieldContainer data-testid="order-additionalChargeValue">
        <PricePicker
          value={additionalCharge.amount}
          readonly={readonly}
          onBlur={updateAmount}
          className={`bg-white ${
            readonly && "bg-transparent text-sm font-normal"
          } && text-right`}
          error={!Number(additionalCharge.amount) && showError}
          hideErrorIcon
          index={-1}
        />
      </TextFieldContainer>
      {children}
    </Container>
  );
};
