import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useCallback } from "react";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";

export const useCopyInvoicePrices = () => {
  const { handsonInstance, spreadsheetData, setMetadata } = useColumnMapper();
  const { invoice } = useInvoiceVerification();
  const { getPhysicalColumnIndex, getPhysicalRowIndex, setCells } =
    useTableHelpers();
  const { calcExtPrice } = usePriceCalculation();

  const copyPriceFromInvoice = useCallback(() => {
    if (!handsonInstance || !invoice) {
      return;
    }

    const updatedCells = [] as [number, number, string | number][];
    spreadsheetData?.forEach((row, index) => {
      if (row.id) {
        const match = invoice.releaseItemHints.find(
          (hint) => hint?.releaseItem.id === row.id,
        );

        const matchingInvoiceItem = match?.invoiceItem;
        const matchingReleaseItem = match?.releaseItem;

        if (matchingInvoiceItem) {
          const copiedPrice = matchingInvoiceItem.unitPrice;
          const extPrice = calcExtPrice(
            matchingInvoiceItem.quantityDecimal,
            matchingInvoiceItem.unitPrice,
          );
          const previousPrice = matchingReleaseItem?.unitPrice;
          setMetadata(index, { extPrice, copiedPrice, previousPrice });

          updatedCells.push([
            getPhysicalRowIndex(handsonInstance, index),
            getPhysicalColumnIndex(handsonInstance, COLUMN_TYPE.UnitPrice),
            matchingInvoiceItem.unitPrice,
          ]);

          updatedCells.push([
            getPhysicalRowIndex(handsonInstance, index),
            getPhysicalColumnIndex(handsonInstance, COLUMN_TYPE.ExtPrice),
            extPrice,
          ]);
        }
      }
    });
    setCells(updatedCells, handsonInstance, "none");
  }, [
    calcExtPrice,
    getPhysicalColumnIndex,
    getPhysicalRowIndex,
    handsonInstance,
    invoice,
    setCells,
    setMetadata,
    spreadsheetData,
  ]);

  return { copyPriceFromInvoice };
};
