import { Loader } from "@/common/components/loader/Loader";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useDistributorInvoice } from "../../providers/DistributorInvoiceProvider";
import { InvoiceIssueHeader } from "./components/invoice-issue-header/InvoiceIssueHeader";
import { InvoiceReleaseItems } from "./components/invoice-release-items/InvoiceReleaseItems";

const Container = tw.div`bg-gray-100 rounded-3xl py-6 h-full overflow-scroll`;
const InnerContainer = tw.div``;

export const InvoiceMatchedOrder: FC = () => {
  const { invoice } = useDistributorInvoice();

  return (
    <Container>
      {invoice ? (
        <InnerContainer>
          <InvoiceIssueHeader />
          {invoice?.release ? <InvoiceReleaseItems /> : null}
        </InnerContainer>
      ) : (
        <Loader loading />
      )}
    </Container>
  );
};
