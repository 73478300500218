import { TagPicker } from "@/common/components/tag-picker/TagPicker";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useEstimatedItems } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import {
  EstimatedItemFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { useReleaseProject } from "../ReleaseProjectProvider";

type Props = {
  item?: ProjectItemFieldsFragment;
  estimatedItem?: EstimatedItemFieldsFragment | null;
  readonly?: boolean;
};

export const AddToReleaseTagsPicker: FC<Props> = ({ estimatedItem }) => {
  const { buyout } = useContractorBuyout();
  const { project } = useReleaseProject();
  const releaseProject = useMemo(
    () => buyout?.project || project,
    [buyout?.project, project],
  );
  const { updateEstimatedItemTags } = useEstimatedItems();

  const handleChange = useCallback(
    async (tags: string[]) => {
      await updateEstimatedItemTags(estimatedItem?.id || "", tags);
    },
    [estimatedItem?.id, updateEstimatedItemTags],
  );

  if (!releaseProject || !estimatedItem || !estimatedItem.id) {
    return null;
  }

  return (
    <TagPicker
      options={releaseProject.tags || []}
      values={estimatedItem.tags || []}
      handleChange={handleChange}
      project={releaseProject}
    />
  );
};
