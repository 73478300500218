import { useUser } from "@/common/providers/UserProvider";
import Decimal from "decimal.js";
import React, { FC } from "react";
import { FormattedNumber } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";

type PriceProps = {
  price: Decimal | number | undefined | null | string;
  className?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number | undefined;
  negative?: boolean;
  zeroValuePlaceholder?: React.JSX.Element;
  testId?: string;
  currency?: string;
};

const PriceContainer = tw.span``;

export const Price: FC<PriceProps> = ({
  price,
  className,
  minimumFractionDigits = 2,
  maximumFractionDigits = 6,
  negative,
  zeroValuePlaceholder,
  testId,
}) => {
  const { currency } = useUser();
  return (
    <PriceContainer className={className} data-testid={testId}>
      <If isTrue={zeroValuePlaceholder && (price === 0 || !price)}>
        {zeroValuePlaceholder}
      </If>
      <If isTrue={!zeroValuePlaceholder || (price && price !== 0)}>
        <If isTrue={negative}>-</If>
        <FormattedNumber
          value={Number(price) || 0}
          style="currency"
          currencyDisplay="narrowSymbol"
          currency={currency?.code}
          minimumFractionDigits={minimumFractionDigits}
          maximumFractionDigits={maximumFractionDigits}
        />
      </If>
    </PriceContainer>
  );
};
