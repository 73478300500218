import { routes } from "@/config/routes";
import { WithAdminNavigation } from "../Admin";
import { WithAdminItemsTabNavigation } from "./common/common/WithAdminItemsTabNavigation";
import { MaterialPrices } from "./pages/materials-prices/MaterialPrices";
import { MaterialsList } from "./pages/materials/MaterialsList";

export const adminItemsRoutes = [
  {
    path: routes.itemDatabase,
    element: (
      <WithAdminNavigation>
        <WithAdminItemsTabNavigation />
      </WithAdminNavigation>
    ),
    children: [
      {
        path: "",
        element: <MaterialsList />,
      },
      {
        path: routes.itemPrices,
        element: <MaterialPrices />,
      },
    ],
  },
];
