import { If } from "@/common/components/if/If";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const DisplayProjectBudgetBuyoutDiffContainer = tw.div`
  pt-1 text-sm text-center text-red-500 font-bold
`;

type DisplayProjectBudgetBuyoutDiffProps = {
  percentage?: string | null | Decimal;
};

export const DisplayProjectBudgetBuyoutDiff: FC<
  DisplayProjectBudgetBuyoutDiffProps
> = ({ percentage }) => {
  const intl = useIntl();
  const diff = useMemo(() => {
    const decimalPercentage = new Decimal(percentage || 0);
    if (decimalPercentage.greaterThan(1)) {
      const percentageDiff = decimalPercentage.minus(1).mul(100);
      if (!decimalPercentage) {
        return null;
      }
      return intl.$t(
        { id: "PROJECT_DIFF_OVER" },
        { diff: Number(percentageDiff).toFixed(0) },
      );
    }
    return null;
  }, [percentage, intl]);

  return (
    <If isTrue={diff}>
      <DisplayProjectBudgetBuyoutDiffContainer>
        {diff}
      </DisplayProjectBudgetBuyoutDiffContainer>
    </If>
  );
};
