import { LinkLikeText } from "@/common/components/link-like-text/LinkLikeText";
import { RfqFieldsFragment, RfqStatus } from "@/generated/graphql";
import { ModeEditOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { getUserName } from "../../../../../common/utils/users/getUserName";
import { isRfqDraft } from "../../rfq/utils/utils";
import { Item, ItemText } from "../Rfq.styles";
import { ToggleRfqForm } from "./ToggleRfqForm";

type Props = {
  rfq: RfqFieldsFragment;
  hideVendors?: boolean;
};

const PencilIcon = tw(
  ModeEditOutlined,
)` ml-2 -mt-1 text-xl hover:text-blue-300`;
const StyledLinkLikeText = tw(LinkLikeText)`inline-block`;

export const RfqNumberWithToggle: FC<Props> = ({ rfq, hideVendors }) => {
  const intl = useIntl();

  return (
    <Item>
      <ToggleRfqForm
        hideVendors={hideVendors}
        rfq={rfq}
        label={intl.$t({
          id:
            (rfq.status === RfqStatus.Active && !rfq.nextRevision) ||
            isRfqDraft(rfq)
              ? "EDIT_RFQ_DETAILS"
              : "VIEW_RFQ_DETAILS",
        })}
      >
        <FormattedMessage
          id="RFQ_NUMBER_WITH_SYMBOL"
          values={{ number: rfq.clientIdentifier }}
        />
        <StyledLinkLikeText>
          <PencilIcon />
        </StyledLinkLikeText>
      </ToggleRfqForm>
      <ItemText>
        <FormattedMessage
          id="BY_USERNAME"
          values={{
            username: getUserName(rfq.createdBy),
          }}
        />
      </ItemText>
    </Item>
  );
};
