import { QUERYSTRING } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import {
  ExternalProjectFieldsFragment,
  QueryExternalProjectsFilter,
  SourceSystem,
} from "@/generated/graphql";
import { Identity } from "@/types/Identity";
import { NoFunction } from "@/types/NoFunction";
import React, { FC, createContext, useContext, useState } from "react";
import { useAgaveExternalProjectsWithPagination } from "../hooks/useAgaveExternalProjectsWithPagination";

type ProviderContextType = {
  externalProjects: (ExternalProjectFieldsFragment & Identity)[];
  loading: boolean;
  totalCount: number;
  filter?: QueryExternalProjectsFilter;
  setFilter: (filter: QueryExternalProjectsFilter) => void;
  isFiltered: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  externalProjects: [],
  loading: false,
  totalCount: 0,
  filter: undefined,
  setFilter: NoFunction,
  isFiltered: false,
});

export const AgaveExternalProjectsProvider: FC<{
  children: React.ReactNode;
  sourceSystem: SourceSystem | null;
}> = ({ children, sourceSystem }) => {
  const { queryParams } = useQueryParams();
  const [filter, setFilter] = useState<QueryExternalProjectsFilter>({
    search: queryParams.get(QUERYSTRING.SEARCH) || "",
    mapped:
      queryParams.get(QUERYSTRING.MAPPED) !== null
        ? Boolean(queryParams.get(QUERYSTRING.MAPPED))
        : undefined,
  });
  const { externalProjects, loading, error, totalCount, setPage } =
    useAgaveExternalProjectsWithPagination(sourceSystem, filter);

  useErrorEffect(error);

  const setFilterUpdate = (filter: QueryExternalProjectsFilter) => {
    setPage({
      page: 0,
      queryParams: {
        ...filter,
      },
    });
    setFilter(filter);
  };

  return (
    <ProviderContext.Provider
      value={{
        externalProjects:
          externalProjects.map((i) => ({ ...i, id: i.externalId })) ?? [],
        loading,
        totalCount,
        filter,
        isFiltered: !!(filter?.search || filter?.mapped !== undefined),
        setFilter: setFilterUpdate,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useAgaveExternalProjectsProvider = () =>
  useContext(ProviderContext);
