import { If } from "@/common/components/if/If";
import { InnerLabeledSwitch } from "@/common/components/switch/InnerLabeledSwitch";
import { QuoteStatus } from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../providers/DistributorQuoteItemsProvider";
import { useDistributorQuote } from "../providers/DistributorQuoteProvider";

const Container = tw.div`
  flex items-center 
`;

const Include = tw.div`
  mr-2 text-sm lg:hidden
`;

const PrintContainer = tw.div<{ $readonly?: boolean }>`
  print:block text-sm
  ${({ $readonly }: { $readonly?: boolean }) => ($readonly ? "" : "hidden")}
`;

type Props = {
  item: DistributorQuoteItemGroupExtendedFieldsFragment;
  readonly: boolean;
};

export const DistributorQuoteItemToggle: FC<Props> = ({ item, readonly }) => {
  const intl = useIntl();
  const [includeItem, toggleItem] = useState<boolean>(
    item.quoteItems[0]?.status !== QuoteStatus.Withdrawn,
  );
  const { updateQuote } = useDistributorQuote();

  useEffect(() => {
    const toggle = item.quoteItems[0]?.status !== QuoteStatus.Withdrawn;
    toggleItem(toggle);
  }, [item.quoteItems]);

  const onToggle = useCallback(
    async (checked: boolean) => {
      const payload = {
        quoteItemId: item.quoteItems[0]?.id,
        isIncluded: checked,
        quantityDecimal: checked ? item.rfqItem.quantityDecimal : undefined,
      };
      await updateQuote({
        updates: [payload],
      });

      toggleItem(checked);
    },
    [item.quoteItems, item.rfqItem.quantityDecimal, updateQuote],
  );

  return (
    <Container>
      <Include>
        <FormattedMessage id="INCLUDE" />?
      </Include>
      <If isTrue={!readonly}>
        <InnerLabeledSwitch
          toggle={onToggle}
          className="print:hidden"
          width={55}
          initialValue={includeItem}
          onLabel={intl.$t({ id: "YES" })}
          offLabel={intl.$t({ id: "NO" })}
        />
      </If>
      <PrintContainer $readonly={readonly}>
        {includeItem ? intl.$t({ id: "YES" }) : intl.$t({ id: "NO" })}
      </PrintContainer>
    </Container>
  );
};
