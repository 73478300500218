import { Dialog } from "@/common/components/dialog/Dialog";
import { If } from "@/common/components/if/If";
import { InfoOutlined } from "@mui/icons-material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  grid gap-4 mt-5 px-30 items-start justify-center
`;

const Card = tw.div`
  group hover:bg-blue-500 hover:text-white rounded-lg hover:shadow-[0_35px_60px_-15px_rgba(0,0,0,.4)]
  transition-all cursor-pointer hover:scale-105 ease-out duration-200
`;

const CardContent = tw.div`
  relative grid items-center justify-center rounded-lg border
  border-gray-600 border-dashed content-center h-72 group-hover:border-blue-500
`;

const CardIcon = tw.div`
  grid justify-center text-blue-500 group-hover:text-white transition-all ease-out duration-200
`;

const CardLabel = tw.div`
  px-12 text-base font-medium text-center mt-4 select-none
`;

const CardDetails = tw.div`
  px-4 text-xs text-center mt-5 text-gray-600 group-hover:text-white pb-10 select-none transition-all ease-out duration-200
`;

const PreferredChip = tw.div`
  absolute w-fit left-[calc(50%-41px)] top-0 h-5 px-2 py-1 text-2xs font-normal rounded-b-lg bg-blue-500 text-white 
  border border-blue-500 group-hover:border-white border-t-0 transition-all ease-out duration-200
`;

const InfoText = tw.div`
  text-xs w-fit grid grid-flow-col gap-x-1 items-center font-light place-self-center
  text-gray-600 bg-gray-100 py-1.5 px-4 col-span-full rounded-3xl mt-6
`;

export type AddItemsOptionType = {
  label: string;
  details: string;
  callback: () => void;
  preferred?: boolean;
  icon?: React.JSX.Element;
};

type AddItemsFromDialogProps = {
  title?: string;
  options: AddItemsOptionType[];
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
};

export const AddItemsFromDialog: FC<AddItemsFromDialogProps> = ({
  title,
  options,
  visible = true,
  setVisible,
}) => {
  const intl = useIntl();

  const handleCancel = () => {
    setVisible?.(false);
  };

  return (
    <Dialog
      title={<FormattedMessage id={title ?? "ADD_ITEMS"} />}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({ id: "SELECT" })}
      buttonsConfiguration={{
        cancel: {
          wide: true,
        },
      }}
      handleCancel={handleCancel}
      maxWidth="xl"
      show={visible}
      content={
        <Container
          style={{
            gridTemplateColumns: `repeat(${options.length}, 276px)`,
          }}
        >
          {options.map((option, index) => (
            <Card
              key={index}
              onClick={option.callback}
              data-testid={`option-${index}`}
            >
              <CardContent>
                <If isTrue={option.preferred}>
                  <PreferredChip>
                    <FormattedMessage id="MOST_COMMON" />
                  </PreferredChip>
                </If>
                <CardIcon>{option.icon}</CardIcon>
                <CardLabel>
                  <FormattedMessage id={option.label} />
                </CardLabel>
              </CardContent>
              <CardDetails>
                <FormattedMessage id={option.details} />
              </CardDetails>
            </Card>
          ))}
          <InfoText>
            <InfoOutlined className="h-5 w-5" />
            <FormattedMessage id="ADD_ITEMS_FROM_DIALOG_INFO_TEXT" />
          </InfoText>
        </Container>
      }
    />
  );
};
