import { MAX_COLUMN_WIDTH } from "@/contractor/pages/home/rfq-quotes/constants";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  grid w-full transition z-10 h-full
  rounded-3xl overflow-hidden border border-gray-400 p-3
  bg-gray-100 font-normal text-2xs place-items-center
`;

const Label = tw.div`
  text-center text-gray-600 place-self-center
`;

export const EmptyQuote: FC = () => {
  return (
    <Container
      style={{
        maxWidth: `${MAX_COLUMN_WIDTH}px`,
      }}
    >
      <FormattedMessage id="NOT_INCLUDED" tagName={Label} />
    </Container>
  );
};
