import { CountType } from "@/common/components/circle-counter/CountType";
import { ProjectItemMaterialView } from "@/common/components/project-item-material-view/ProjectItemMaterialView";
import { ReleaseItemPadding } from "@/common/components/release-item-padding/ReleaseItemPadding";
import { useMaterialCostCodeAndZone } from "@/contractor/pages/home/common/hooks/useMaterialCostCodeAndZone";
import { useReleaseItemsZoneProvider } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`flex items-center`;

type Props = {
  item: ExpandedReleaseItem;
  count: CountType;
  projectId?: string;
};

export const ReleaseItemMaterial: FC<Props> = ({ item, count, projectId }) => {
  const { groupedByCostCode } = useReleaseItemsZoneProvider();
  const { itemDetails } = useMaterialCostCodeAndZone(
    item,
    groupedByCostCode,
    projectId,
  );

  return (
    <Container>
      <ReleaseItemPadding item={item} count={count} />
      <ProjectItemMaterialView
        item={item}
        count={count}
        includeCounter={!item.redelivery?.id && !item.backorderFromRelease}
        manufacturer={item.manufacturer}
        expandable={false}
        contractorNotes={{
          notes: item.instructions?.text,
          assets: item.instructions?.assets,
        }}
        vendorNotes={{
          notes: item.notes,
          assets: item.assets,
        }}
        showOutsideOfEstimatesInfo={item.estimatedItems.length === 0}
        extraDetails={itemDetails}
      />
    </Container>
  );
};
