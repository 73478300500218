import { GroupToggle } from "@/common/components/group-toggle/GroupToggle";
import useSticky from "@/common/hooks/useSticky";
import {
  LEFT_COLUMN_WIDTH,
  MIN_COLUMN_WIDTH,
  TOP_OFFSET,
} from "@/contractor/pages/home/rfq-quotes/constants";
import { FC, useRef } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useRfqQuoteOptions } from "../../../../providers/RfqQuoteOptionsProvider";
import { QuoteAuxTotal } from "./QuoteAuxTotal";

type Props = {
  topOffset: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const Container = tw.div`  
  sticky self-start z-40 group/item min-h-11
  grid bg-yellow-200 hover:bg-yellow-300 w-full rounded-3xl
`;

const Name = tw.div`
  grid z-30 grid-flow-col w-full sticky left-0 justify-self-start px-2
  text-sm font-medium items-center justify-start cursor-pointer
  rounded-l-3xl bg-yellow-200 group-hover/item:bg-yellow-300
`;

const Headers = tw.div`
  grid grid-flow-col gap-2 items-center px-4 py-1
  text-sm font-bold rounded-r-3xl
`;

export const AuxItemsHeader: FC<Props> = ({ topOffset, isOpen, setIsOpen }) => {
  const { visibleQuotes } = useRfqQuoteOptions();
  const ref = useRef<HTMLDivElement>(null);
  const isStuck = useSticky({ ref, top: 300 });

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container
      ref={ref}
      style={{
        top: `${TOP_OFFSET}px`,
        gridTemplateColumns: `${LEFT_COLUMN_WIDTH}px auto`,
      }}
      className={`${isStuck ? "border-b" : "shadow"}`}
    >
      <Name onClick={handleClick}>
        <GroupToggle isOpened={isOpen} />
        <FormattedMessage id="AUXILIARY_ITEMS_HEADER" />
      </Name>
      <Headers
        style={{
          gridTemplateColumns: `repeat(${visibleQuotes.length}, minmax(${MIN_COLUMN_WIDTH}px, 1fr))`,
        }}
      >
        {visibleQuotes.map((quote) => (
          <QuoteAuxTotal topOffset={topOffset} quote={quote} key={quote.id} />
        ))}
      </Headers>
    </Container>
  );
};
