import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useUser } from "@/common/providers/UserProvider";
import { UsersUserFieldsFragment, useUsersQuery } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";

type ContextProps = {
  users: UsersUserFieldsFragment[];
  loading: boolean;
  error: boolean;
  setLocation: (id?: string) => void;
};

const ProviderContext = createContext<ContextProps>({
  users: [],
  loading: false,
  error: false,
  setLocation: NoFunction,
});

export const UsersProvider: FC<{
  children: React.ReactNode;
  requireLocation?: boolean;
  projectId?: string;
}> = ({ children, projectId, requireLocation = false }) => {
  const [locationId, setLocationId] = useState<string | undefined>();
  const { isContractor } = useUser();
  const { data, error, loading } = useUsersQuery({
    variables: {
      orgLocationID: locationId,
      projectID: projectId,
    },
    skip: (requireLocation && !locationId) || !isContractor,
  });
  useErrorEffect(error);

  const setLocation = (id?: string) => {
    setLocationId(id);
  };

  return (
    <ProviderContext.Provider
      value={{
        users: (data?.users ?? []).toSorted((a, b) =>
          a.firstName.localeCompare(b.firstName),
        ),
        loading,
        error: !!error,
        setLocation,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useUsers = () => useContext(ProviderContext);
