import { EditButton } from "@/common/components/button/EditButton";
import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { TextfieldWithActions } from "@/common/components/textfield-with-actions/TextfieldWithActions";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { ReleaseStatus } from "@/generated/graphql";
import { FormControl } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { InfoIconContainer } from "../../projects/components/project-form-with-stepper/ProjectFormWithStepper.styles";
import { ExpandedRelease } from "../providers/ReleaseProvider";
import { useReleaseUpdate } from "../providers/ReleaseUpdateProvider";

const EMPTY_VALUE = "--";

const Container = tw.div`flex flex-col`;

const Content = tw.div`flex items-center mt-2 mb-4`;

const DescriptionContent = tw.div`flex items-center mb-4`;

const LabelText = tw.div`text-gray-600 text-[9px] font-normal`;

const Text = tw.div`text-sm font-light`;

const EditButtonStyled = tw(EditButton)`mx-1`;

const TextfieldWithActionsStyled = tw(TextfieldWithActions)`mr-3 w-96`;

type Props = {
  release: ExpandedRelease | null | undefined;
  directlyEditable?: boolean;
};

export const ReleaseDescription: FC<Props> = ({
  release,
  directlyEditable = false,
}) => {
  const intl = useIntl();
  const { updateRelease } = useReleaseUpdate();

  const isDirectlyEditable =
    directlyEditable || checkReleaseStatus(release, [ReleaseStatus.Draft]);

  const [editedDescription, setEditedDescription] = useState<string>(
    release?.description || "",
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const isEditable = !checkReleaseStatus(release, [ReleaseStatus.Canceled]);

  const labelOptional = useMemo(() => {
    return `${intl.$t({ id: "RELEASE_DESCRIPTION" })} ${intl.$t({
      id: "OPTIONAL_WITH_BRACKETS",
    })}`;
  }, [intl]);

  const tooltipLabel = useMemo(() => {
    return intl.$t({
      id: "RELEASE_DESCRIPTION_TOOLTIP",
    });
  }, [intl]);

  const saveDescription = useCallback(
    (description: string) => {
      if (release) {
        updateRelease({
          releaseId: release.id,
          description: description || "",
          version: release.version,
        });
      }
    },
    [release, updateRelease],
  );

  const onSave = useCallback(() => {
    saveDescription(editedDescription);

    setIsEditing(false);
  }, [editedDescription, saveDescription]);

  const onClose = useCallback(() => {
    if (release?.description) {
      setEditedDescription(release.description);
    }

    setIsEditing(false);
  }, [release?.description, setEditedDescription, setIsEditing]);

  return (
    <Container>
      <If isTrue={isEditing || isDirectlyEditable}>
        <Content>
          <FormControl>
            <TextfieldWithActionsStyled
              label={labelOptional}
              value={editedDescription}
              showActions={!isDirectlyEditable}
              onChange={(e) => setEditedDescription(e.target.value)}
              onBlur={(e) =>
                isDirectlyEditable && saveDescription(e.target.value)
              }
              onClickSave={onSave}
              onClickClose={onClose}
            />
          </FormControl>

          <InfoIconContainer>
            <InfoTooltip message={tooltipLabel} id="description-info" />
          </InfoIconContainer>
        </Content>
      </If>

      <If isTrue={!isEditing && !isDirectlyEditable}>
        <LabelText>{labelOptional}</LabelText>

        <DescriptionContent>
          <Text>{release?.description || EMPTY_VALUE}</Text>

          <If isTrue={isEditable}>
            <EditButtonStyled onClick={() => setIsEditing(true)} />
          </If>
          <InfoIconContainer>
            <InfoTooltip message={tooltipLabel} id="description-info" />
          </InfoIconContainer>
        </DescriptionContent>
      </If>
    </Container>
  );
};
