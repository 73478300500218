import { gql } from "@apollo/client";
import { SOURCE_SYSTEM_MAPPING_FIELDS } from "../../../../org-settings/fragments/sourceSystemMappingFragment";

export const COST_CODE_FIELDS = gql`
  ${SOURCE_SYSTEM_MAPPING_FIELDS}
  fragment CostCodeFields on CostCode {
    id
    code
    description
    deletedAt
    inUse
    mappings {
      ...SourceSystemMappingFields
    }
  }
`;
