import { UserAvatar } from "@/common/components/user-avatar/UserAvatar";
import { ExpandMore } from "@mui/icons-material";
import tw from "tailwind-styled-components";

export const ButtonStyled = tw.button`
  text-sm font-normal capitalize p-0
  grid grid-flow-col gap-1 h-full items-center last-of-type:xl:pr-3
`;

export const ExpandMoreStyled = tw(ExpandMore)`
  text-xl cursor-pointer
`;

export const UserAvatarStyled = tw(UserAvatar)`
  lg:mr-3
`;

export const Container = tw.div`
  hover:bg-blue-800 grid grid-flow-col items-center text-white cursor-pointer h-full p-3 text-sm
`;

export const UserFullName = tw.span`
  hidden lg:inline-block text-sm
`;
