import { DateListView } from "@/common/components/date/DateListView";
import { ProjectProjectsFieldsFragment } from "@/generated/graphql";
import { FC } from "react";

type DisplayProjectStartDateProps = {
  project: ProjectProjectsFieldsFragment;
};

export const DisplayProjectStartDate: FC<DisplayProjectStartDateProps> = ({
  project,
}) => {
  return <DateListView date={project.startDate} />;
};
