import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import {
  AddToReleaseItemInput,
  DeliverySlipsDocument,
  OrgMaterialFieldsFragment,
  OrgPreferredVendorsFieldsFragment,
  QuoteDocumentFieldsFragment,
  ReleaseFieldsFragment,
  ReleaseStatus,
  ServiceType,
  UomsDocument,
  UpdateContractorReleaseItemInput,
  useCreateStandaloneReleaseMutation,
  useProjectPredictedPoNumberQuery,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { FC, createContext, useContext, useEffect, useState } from "react";

import { LUMP_SUM_UOM, LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "@/common/const";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { RELEASE } from "@/common/queries/release";
import {
  isLumpSumItem,
  isLumpSumUomText,
} from "@/common/utils/lumpSumItemUtils";
import { routes } from "@/config/routes";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { NoFunction, NoFunctionUndefined } from "@/types/NoFunction";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import { CreateOrderFromQuoteFormValues } from "../components/order-from-quote/create-order-from-quote/components/CreateOrderFromQuoteForm";

type ProviderContextType = {
  syncCreateReleaseFromQuote: (
    skipConfirmation?: boolean,
    retroactive?: boolean,
    status?: ReleaseStatus,
  ) => Promise<ReleaseFieldsFragment | undefined | null>;
  syncUpdateReleaseFromQuote: () => Promise<
    ReleaseFieldsFragment | undefined | null
  >;
  validateSpreadsheet: () => boolean;
  loading: boolean;
  vendors: OrgPreferredVendorsFieldsFragment[];
  loadingVendors: boolean;
  findOrderTypeByLocationId: (locationId: string) => string | undefined;
  loadingPredictedPoNumber: boolean;
  itemized: boolean;
  setItemized: (isItemized: boolean) => void;
  createRelease: (
    skipConfirmation?: boolean,
    retroactive?: boolean,
    status?: ReleaseStatus,
  ) => Promise<ReleaseFieldsFragment | undefined | null>;
};

const ProviderContext = createContext<ProviderContextType>({
  syncCreateReleaseFromQuote: () => Promise.resolve(null),
  syncUpdateReleaseFromQuote: () => Promise.resolve(null),
  validateSpreadsheet: () => false,
  loading: false,
  vendors: [],
  loadingPredictedPoNumber: false,
  loadingVendors: false,
  findOrderTypeByLocationId: NoFunctionUndefined,
  itemized: true,
  setItemized: NoFunction,
  createRelease: () => Promise.resolve(null),
});

export const OrderFromQuoteProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { hasPhaseCodes } = useOrgSettings();
  const { quoteDocument } = useQuoteDocument();
  const { setError } = useGlobalError();
  const { materials, updateMaterials } = useMaterials();
  const { costCodes } = useCostCodes();
  const { release } = useRelease();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const {
    spreadsheetData,
    resetPreviousData,
    gotoInvalidRow,
    getRemovedRowIds,
  } = useColumnMapper();
  const {
    getCellValue,
    getRowUomCreatableValue,
    getCostCodeId,
    rowIsEmpty,
    addMissingMaterials,
    getCellWithAdditionalData,
    findMaterialByName,
  } = useTableHelpers();
  const {
    vendors,
    loading: loadingVendors,
    findOrderTypeByLocationId,
  } = useVendors();
  const { setWarningAlert } = useSnackbar();

  const isItemized = (
    quote: QuoteDocumentFieldsFragment | null | undefined,
  ) => {
    if (!quote) {
      return false;
    }

    const items = quote?.releaseItemHints?.map((hint) => hint.releaseItem);

    if (!items) {
      return false;
    }

    const firstItem = items[0];

    const isNonItemized =
      items?.length === 1 &&
      firstItem.quantityDecimal === "1" &&
      firstItem?.uom?.mnemonic === LUMP_SUM_UOM;

    return !isNonItemized;
  };

  const [itemized, setItemized] = useState(
    !!quoteDocument ? isItemized(quoteDocument) : true,
  );
  const [saving, setSaving] = useState(false);

  const { setValue, watch, getValues } =
    useFormContext<CreateOrderFromQuoteFormValues>();

  const projectId = watch("projectId");
  const poNumber = watch("poNumber");
  const orderDate = watch("orderDate");

  const {
    data: projectData,
    error: predictedPoNumberError,
    loading: loadingPredictedPoNumber,
  } = useProjectPredictedPoNumberQuery({
    variables: {
      id: projectId,
    },
    skip: !projectId || !!poNumber,
  });

  useEffect(() => {
    if (release?.items?.length === 1 && isLumpSumItem(release.items[0])) {
      setItemized(false);
      setValue(
        hasPhaseCodes ? "phaseCodeId" : "costCodeId",
        release.items[0].costCode?.id,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [release]);

  useEffect(() => {
    const retroactiveRelease =
      orderDate && orderDate?.getTime() < new Date().getTime();
    const predictedPoNumber = projectData?.project?.predictedPoNumber;

    if (!retroactiveRelease && !predictedPoNumberError && predictedPoNumber) {
      return setValue("predictedPoNumber", predictedPoNumber);
    }

    return setValue("predictedPoNumber", "");
  }, [
    orderDate,
    projectData?.project?.predictedPoNumber,
    predictedPoNumberError,
    setValue,
  ]);

  const [createStandaloneRelease, { loading: creating }] =
    useCreateStandaloneReleaseMutation();
  const [updateRelease, { loading: updating }] =
    useUpdateContractorReleaseMutation();

  const validateSpreadsheet = () => {
    if (spreadsheetData.every((row) => rowIsEmpty(row))) {
      setWarningAlert(
        <FormattedMessage id={`VALIDATION_ERROR_SHEETS_EMPTY_LIST`} />,
      );
      return false;
    }

    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ]) ||
      !validateRowValues([
        COLUMN_TYPE.Quantity,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.CostCode,
      ])
    ) {
      gotoInvalidRow();
      return false;
    }

    return true;
  };
  const syncCreateReleaseFromQuote = async (
    skipConfirmation?: boolean,
    retroactive?: boolean,
    status?: ReleaseStatus,
  ) => {
    const isValid = validateSpreadsheet();

    if (!isValid) {
      return null;
    }

    const newItems: AddToReleaseItemInput[] = [];
    setSaving(true);
    const newMaterials = (await addMissingMaterials()) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getRowUomCreatableValue(row);
      const isLumpSum = isLumpSumUomText(uom);
      if (isLumpSum) {
        material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
          ...materials,
          ...newMaterials,
        ]);
      }

      if (!material || rowIsEmpty(row)) {
        return null;
      }

      const quantityDecimal = getCellValue(row, COLUMN_TYPE.Quantity);
      const unitPrice = getCellValue(row, COLUMN_TYPE.UnitPrice);
      const notes = getCellValue(row, COLUMN_TYPE.Notes);

      newItems.push({
        projectItem: {
          estimateUom: uom,
          ...(isOrgCatalogSku(material.material) && {
            orgCatalogSkuId: material.material.id,
          }),
          ...(isProductSku(material.material) && {
            masterProductId: material.material.id,
          }),
          ...(isMasterSku(material.material) && {
            masterSkuId: material.material.id,
          }),
        },
        quantityDecimal,
        unitPrice: unitPrice ? String(unitPrice) : undefined,
        ...(isLumpSum && { name: rowMaterialText }),
        position: index,
        costCodeId: getCostCodeId(row),
        instructions: { text: notes },
      } as AddToReleaseItemInput);
    });

    if (newItems.length > 0) {
      try {
        const values = getValues();
        const includeServices = [];
        if (!values.willCall) {
          includeServices.push({ type: ServiceType.Delivery });
        }
        if (!values.willCall && values.vendorStocking) {
          includeServices.push({ type: ServiceType.Stocking });
        }

        const relStatus =
          values.orderDate?.getTime() < Date.now()
            ? retroactive
              ? ReleaseStatus.Received
              : ReleaseStatus.Scheduled
            : skipConfirmation
              ? ReleaseStatus.Scheduled
              : skipConfirmation === false
                ? ReleaseStatus.Requested
                : ReleaseStatus.Draft;

        const { data, errors } = await createStandaloneRelease({
          variables: {
            input: {
              projectId: values.projectId ?? "",
              sellerOrgLocationId: values.vendorId,
              poNumber: values.poNumber || undefined,
              time: values.orderDate?.getTime() ?? undefined,
              items: newItems,
              requiresInventoryReceipt: values.requiresInventoryReceipt,
              quoteDocumentId: quoteDocument?.id ?? "",
              includeServices,
              taxRate:
                values.taxRate ||
                (values.clearCustomTaxAmount ||
                values.customTaxAmount?.length === 0
                  ? "0"
                  : undefined),
              customTaxAmount:
                values.clearCustomTaxAmount ||
                values.customTaxAmount?.length === 0
                  ? undefined
                  : values.customTaxAmount,
              additionalCharges: values.additionalCharges,
              instructions: values.instructions,
              paymentTerm: values.paymentTerm,
              status: status || relStatus,
              typeId: values.orderTypeId || undefined,
              vendorContactIds: values.vendorContactIds,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: UomsDocument,
            },
            {
              query: DeliverySlipsDocument,
            },
          ],
        });
        setError(errors);
        if (!errors) {
          resetPreviousData();
        }
        return data?.createStandaloneRelease ?? null;
      } catch (error) {
        setError(error);
        return null;
      }
    }

    return release;
  };

  const syncUpdateReleaseFromQuote = async () => {
    const values = getValues();
    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ]) ||
      !validateRowValues(
        [COLUMN_TYPE.Quantity, COLUMN_TYPE.UOM, COLUMN_TYPE.CostCode],
        undefined,
        { minPrice: undefined },
      )
    ) {
      gotoInvalidRow();
      return null;
    }

    const newItems: AddToReleaseItemInput[] = [];
    const itemsToUpdate: UpdateContractorReleaseItemInput[] = [];
    const itemsToRemove = getRemovedRowIds(release?.items ?? []);

    setSaving(true);
    const newMaterials = (await addMissingMaterials()) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getRowUomCreatableValue(row);
      const isLumpSum = isLumpSumUomText(uom);
      if (isLumpSum) {
        material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
          ...materials,
          ...newMaterials,
        ]);
      }

      if (!material || rowIsEmpty(row)) {
        return null;
      }

      const quantityDecimal = getCellValue(row, COLUMN_TYPE.Quantity);
      const unitPrice = getCellValue(row, COLUMN_TYPE.UnitPrice);

      const hasUnitPrice =
        unitPrice !== "" && unitPrice !== null && unitPrice !== undefined;
      const notes = getCellValue(row, COLUMN_TYPE.Notes);

      const existingItem = release?.items.find((item) => item.id === row.id);
      const matchingMaterials =
        existingItem && existingItem.projectItem?.material.id === material.id;

      if (matchingMaterials) {
        itemsToUpdate.push({
          releaseItemId: row.id,
          uom,
          quantityDecimal,
          unitPrice: hasUnitPrice ? String(unitPrice) : undefined,
          ...(isLumpSum && { name: rowMaterialText }),
          clearUnitPrice: !hasUnitPrice,
          position: index,
          costCodeId: getCostCodeId(row),
          instructions: { text: notes },
        });
      } else {
        newItems.push({
          projectItem: {
            estimateUom: uom ?? "",
            ...(isOrgCatalogSku(material.material) && {
              orgCatalogSkuId: material.material.id,
            }),
            ...(isProductSku(material.material) && {
              masterProductId: material.material.id,
            }),
            ...(isMasterSku(material.material) && {
              masterSkuId: material.material.id,
            }),
          },
          quantityDecimal,
          unitPrice: unitPrice ? String(unitPrice) : undefined,
          ...(isLumpSum && { name: rowMaterialText }),
          position: index,
          costCodeId: getCostCodeId(row),
          instructions: { text: notes },
        });
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    const includeServices = [];
    if (!values.willCall) {
      includeServices.push({ type: ServiceType.Delivery });
    }
    if (!values.willCall && values.vendorStocking) {
      includeServices.push({ type: ServiceType.Stocking });
    }

    if (release) {
      try {
        const { data, errors } = await updateRelease({
          variables: {
            input: {
              releaseId: release.id,
              version: release.version,
              addedItems: newItems,
              updates: itemsToUpdate,
              removedItems: itemsToRemove,
              taxRate: values.taxRate || undefined,
              customTaxAmount: values.customTaxAmount || undefined,
              clearCustomTaxAmount: !values.customTaxAmount,
              additionalCharges: values.additionalCharges.filter(
                (charge) => charge.description && Number(charge.amount) > 0,
              ),
              poNumber: values.poNumber,
              requestedTime: values.orderDate?.getTime() ?? undefined,
              requiresInventoryReceipt: values.requiresInventoryReceipt,
              includeServices,
              instructions: values.instructions,
              typeId: values.orderTypeId || undefined,
              assignDefaultCostCodes: false,
              prefillPrices: false,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: RELEASE,
              variables: { id: release?.id ?? "" },
            },
            {
              query: UomsDocument,
            },
          ],
        });
        setError(errors);
        if (!errors) {
          resetPreviousData();
        }
        return data?.updateContractorRelease ?? null;
      } catch (error) {
        setError(error);
        return null;
      }
    }

    return release;
  };

  const createRelease = async (
    skipConfirmation?: boolean,
    retroactive?: boolean,
    status?: ReleaseStatus,
  ) => {
    try {
      setSaving(true);
      const values = getValues();
      const costCode = costCodes.find(
        (c) => c.id === values.costCodeId,
      )?.description;

      const lumpSumMaterial = materials.find(
        (m) => m.material.name === LUMP_SUM_UOM_PLURAL_DESCRIPTION,
      );

      let newMaterials;
      if (!lumpSumMaterial) {
        newMaterials = await updateMaterials({
          addedMaterials: [
            {
              costCodeId: values.costCodeId,
              newOrgCatalogSKU: {
                defaultUom: LUMP_SUM_UOM,
                name: LUMP_SUM_UOM_PLURAL_DESCRIPTION,
              },
            },
          ],
        });
      }
      const items: AddToReleaseItemInput[] = [
        {
          projectItem: {
            estimateUom: LUMP_SUM_UOM,
            orgCatalogSkuId: (
              lumpSumMaterial ||
              (newMaterials as OrgMaterialFieldsFragment[])[0]
            )?.material.id,
          },
          quantityDecimal: String(values.subtotal),
          position: 0,
          unitPrice: "1",
          tags: values.phaseCodeId ? [values.phaseCodeId] : undefined,
          costCodeId: values.costCodeId,
          name: intl.$t({ id: "NAME_LUMP_SUM" }, { name: costCode }),
        },
      ];

      const relStatus =
        values.orderDate?.getTime() < Date.now()
          ? retroactive
            ? ReleaseStatus.Received
            : ReleaseStatus.Scheduled
          : skipConfirmation
            ? ReleaseStatus.Scheduled
            : skipConfirmation === false
              ? ReleaseStatus.Requested
              : ReleaseStatus.Draft;

      const { data, errors } = await createStandaloneRelease({
        variables: {
          input: {
            projectId: values.projectId ?? "",
            sellerOrgLocationId: values.vendorId,
            poNumber: values.poNumber || undefined,
            time: values.orderDate?.getTime() ?? undefined,
            items,
            requiresInventoryReceipt: values.requiresInventoryReceipt,
            quoteDocumentId: quoteDocument?.id ?? "",
            taxRate:
              values.taxRate ||
              (values.clearCustomTaxAmount ||
              values.customTaxAmount?.length === 0
                ? "0"
                : undefined),
            customTaxAmount:
              values.clearCustomTaxAmount ||
              values.customTaxAmount?.length === 0
                ? undefined
                : values.customTaxAmount,
            additionalCharges: values.additionalCharges,
            instructions: values.instructions,
            paymentTerm: values.paymentTerm,
            status: status || relStatus,
            typeId: values.orderTypeId || undefined,
            vendorContactIds: values.vendorContactIds,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: UomsDocument,
          },
        ],
      });
      setError(errors);

      if (data?.createStandaloneRelease?.id) {
        navigate(
          generatePath(routes.delivery, {
            deliveryId: data.createStandaloneRelease.id,
          }),
        );
      }

      setSaving(false);
      return data?.createStandaloneRelease;
    } catch (error) {
      setError(error);
      setSaving(false);
      return null;
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        syncCreateReleaseFromQuote,
        syncUpdateReleaseFromQuote,
        validateSpreadsheet,
        loading: saving || creating || updating,
        vendors,
        loadingVendors,
        findOrderTypeByLocationId,
        loadingPredictedPoNumber,
        itemized,
        setItemized,
        createRelease,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useOrderFromQuote = (): ProviderContextType =>
  useContext(ProviderContext);
