import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { AgaveHostedWizardProvider } from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/AgaveHostedWizardProvider";
import { ExternalGeneralLedgerAccountsProvider } from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/steps/general-ledger-account/ExternalGeneralLedgerAccountsProvider";
import { OrderTypeFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { ExternalLedgerAccountProvider } from "../../providers/ExternalLedgerAccountProvider";
import { ExternalPoTypeProvider } from "../../providers/ExternalPOTypeProvider";
import { ExternalPOTypesProvider } from "../../providers/ExternalPOTypesProvider";
import { OrderTypeFormWithProviders } from "./OrderTypeFormWithProviders";

export type OrderTypeFormProps = {
  onClose: () => void;
  title: string;
  orderType?: OrderTypeFieldsFragment;
};

export const OrderTypeForm: FC<OrderTypeFormProps> = (props) => (
  <ExternalPOTypesProvider>
    <ExternalGeneralLedgerAccountsProvider>
      <AgaveHostedWizardProvider>
        <ExternalLedgerAccountProvider>
          <ExternalPoTypeProvider>
            <NestedStepperProvider>
              <OrderTypeFormWithProviders {...props} />
            </NestedStepperProvider>
          </ExternalPoTypeProvider>
        </ExternalLedgerAccountProvider>
      </AgaveHostedWizardProvider>
    </ExternalGeneralLedgerAccountsProvider>
  </ExternalPOTypesProvider>
);
