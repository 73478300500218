import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { useMemo } from "react";
import tw from "tailwind-styled-components";
import { RfqItemsGroupedProvider } from "../../../rfq/providers/RfqItemsGroupedProvider";
import { useRfqQuotes } from "../../providers/RfqQuotesProvider";
import { AuxItemsLeveling } from "./aux-items-leveling/AuxItemsLeveling";
import { NotesPanel } from "./notes-panel/NotesPanel";
import { QuotesHeader } from "./quotes-header/QuotesHeader";
import { RfqItemsLeveling } from "./rfq-items-leveling/RfqItemsLeveling";
import { TableSummary } from "./summary/TableSummary";
import { TotalAmount } from "./total-amount/TotalAmount";

const TableContainer = tw.div`
  grid content-start relative gap-y-3 grid-flow-row
`;

const TableBidsViewWithProvider = () => {
  const { rfq } = useRfqQuotes();

  const includesAuxItems = useMemo(() => {
    return rfq?.quotes.some((quote) => quote.auxiliaryItems.length > 0);
  }, [rfq]);

  if (!rfq) {
    return <Loader loading />;
  }

  return (
    <>
      <QuotesHeader rfq={rfq} />
      <TableContainer>
        <RfqItemsLeveling />
        <If isTrue={includesAuxItems}>
          <AuxItemsLeveling />
        </If>
        <TableSummary />
      </TableContainer>
      <TotalAmount />
    </>
  );
};

export const TableView = () => {
  const { rfq } = useRfqQuotes();

  return (
    <RfqItemsGroupedProvider items={rfq?.items || []}>
      <TableBidsViewWithProvider />
      <NotesPanel />
    </RfqItemsGroupedProvider>
  );
};
