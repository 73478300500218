import {
  EstimatedItemFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { AddEstimatedItemCheckbox } from "../../../pages/add-item-to-rfq/rfq-items-list/AddEstimatedItemCheckbox";
import {
  AddToRfqItem,
  useRfqProjectItemsWithUpdates,
} from "../RfqProjectItemsProvider";

export const RfqProjectItemCheckbox: FC<{
  item: ProjectItemFieldsFragment;
  estimatedItem?: EstimatedItemFieldsFragment | null;
}> = ({ item, estimatedItem }) => {
  const { updates, setUpdates } = useRfqProjectItemsWithUpdates();

  const onToggle = useCallback(
    (itemsToAdd: AddToRfqItem[]) => {
      setUpdates(itemsToAdd);
    },
    [setUpdates],
  );

  return (
    <AddEstimatedItemCheckbox
      item={item}
      estimatedItem={estimatedItem}
      updates={updates}
      setUpdates={onToggle}
    />
  );
};
