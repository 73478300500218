import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { LinkLike } from "../link-like/LinkLike";

export type Option<T> = {
  id: string;
  title: string;
  filter: (value: boolean | null) => T;
  value: (filter: T | undefined) => boolean | undefined | null;
};

type Props<T> = {
  customFilters?: React.ReactNode;
  customBottomFilters?: React.ReactNode;
  options: Option<T>[];
  filter: T | undefined;
  setFilter: (value: T) => void;
  defaultFilter: T;
};

const Container = tw.div`px-5 py-3`;
const FilterHeader = tw.div`flex mt-2 text-xs pb-2 border-b border-dashed border-gray-400 mb-4`;
const FilterHeaderText = tw.div`flex-1`;
const ResetLink = tw(LinkLike)`text-xs`;
const FilterContainer = tw.div`grid grid-cols-2 mt-2`;
const Name = tw.div``;
const Configurations = tw.div`flex gap-2 justify-end`;
const Configuration = tw.div`text-right
${({ $active }: { $active: boolean }) =>
  $active
    ? "text-black cursor-default underline font-bold"
    : "text-blue-500 cursor-pointer"}`;
const DividerLine = tw.div`
  w-full h-px border-b border-dashed mt-2 mb-2
`;

const isYes = (value: boolean | undefined | null) => {
  return !!value;
};

const isAll = (value: boolean | undefined | null) => {
  return value === undefined || value === null;
};

const isNo = (value: boolean | undefined | null) => {
  return !isAll(value) && !value;
};

export const AdditionalFiltersOverlay = <T,>({
  customFilters,
  customBottomFilters,
  options,
  filter,
  setFilter,
  defaultFilter,
}: Props<T>) => {
  const updateFilter = useCallback(
    (option: Option<T>, value: boolean | null) => {
      setFilter({
        ...filter,
        ...option.filter(value),
      });
    },
    [filter, setFilter],
  );

  const resetFilter = useCallback(() => {
    setFilter(defaultFilter);
  }, [defaultFilter, setFilter]);

  return (
    <Container>
      <FilterHeader>
        <FormattedMessage id="FILTERS" tagName={FilterHeaderText} />
        <If isTrue={options.length}>
          <ResetLink onClick={resetFilter}>
            <FormattedMessage id="RESET" />
          </ResetLink>
        </If>
      </FilterHeader>
      <FilterContainer>{customFilters}</FilterContainer>
      {options.map((option) => (
        <FilterContainer key={option.id}>
          <Name>{option.title}</Name>
          <Configurations>
            <Configuration
              $active={isYes(option.value(filter))}
              onClick={() => updateFilter(option, true)}
            >
              <FormattedMessage id="YES" />
            </Configuration>
            <Configuration
              $active={isNo(option.value(filter))}
              onClick={() => updateFilter(option, false)}
            >
              <FormattedMessage id="NO" />
            </Configuration>
            <Configuration
              $active={isAll(option.value(filter))}
              onClick={() => updateFilter(option, null)}
            >
              <FormattedMessage id="ALL" />
            </Configuration>
          </Configurations>
        </FilterContainer>
      ))}
      {customBottomFilters && (
        <>
          <If isTrue={options.length}>
            <DividerLine />
          </If>
          {customBottomFilters}
        </>
      )}
    </Container>
  );
};

AdditionalFiltersOverlay.Container = Container;
AdditionalFiltersOverlay.FilterHeader = FilterHeader;
AdditionalFiltersOverlay.FilterContainer = FilterContainer;
AdditionalFiltersOverlay.Name = Name;
AdditionalFiltersOverlay.Configurations = Configurations;
AdditionalFiltersOverlay.Configuration = Configuration;
AdditionalFiltersOverlay.isYes = isYes;
AdditionalFiltersOverlay.isNo = isNo;
AdditionalFiltersOverlay.isAll = isAll;
