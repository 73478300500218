import {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Cropper, { Area } from "react-easy-crop";
import { getCroppedImg } from "./utils";

type Props = {
  imageSrc: string;
  forceSquare?: boolean;
  showCircleCrop?: boolean;
  croppedImageRef: MutableRefObject<Blob | null>;
};

export const ImageCropper: FC<Props> = ({
  imageSrc,
  croppedImageRef,
  forceSquare,
  showCircleCrop,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      setImageDimensions({ width: image.width, height: image.height });
    };
  }, [imageSrc]);

  const saveCroppedImage = useCallback(
    async (areaPixels: Area) => {
      if (areaPixels) {
        try {
          const croppedImage = await getCroppedImg(imageSrc, areaPixels);
          croppedImageRef.current = croppedImage;
        } catch (e) {
          console.error(e);
        }
      }
    },
    [imageSrc, croppedImageRef],
  );

  const onCropComplete = useCallback(
    async (_area: Area, areaPixels: Area) => {
      await saveCroppedImage(areaPixels);
    },
    [saveCroppedImage],
  );

  const aspect = useMemo(() => {
    if (forceSquare) {
      return 1;
    }

    if (imageDimensions.width && imageDimensions.height) {
      const { width, height } = imageDimensions;
      return zoom > 1 ? 1 : width / height;
    }
  }, [imageDimensions, zoom, forceSquare]);

  const maxZoom = useMemo(() => {
    if (imageDimensions.width && imageDimensions.height) {
      const { width, height } = imageDimensions;
      return Math.max(2, (width + height) / 700);
    }
  }, [imageDimensions]);

  return (
    <Cropper
      image={imageSrc}
      crop={crop}
      zoom={zoom}
      aspect={aspect}
      minZoom={1}
      maxZoom={maxZoom}
      cropShape={showCircleCrop ? "round" : "rect"}
      onCropChange={setCrop}
      onCropComplete={onCropComplete}
      onZoomChange={setZoom}
    />
  );
};
