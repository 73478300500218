import { MANUFACTURER_FIELDS } from "@/common/fragments/manufacturerFragment";
import { UOM_FIELDS } from "@/common/fragments/uomFragment";
import { gql } from "@apollo/client";
export const PROJECT_MATERIAL_SKU_FIELDS = gql`
  ${MANUFACTURER_FIELDS}
  ${UOM_FIELDS}
  fragment ProjectMaterialSkuFields on Sku {
    id
    name
    description
    imageUrl
    brand
    manufacturer {
      ...ManufacturerFields
    }
    product {
      id
      uom {
        ...UOMFields
      }
    }
  }
`;
