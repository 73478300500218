import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { ProjectOrderedQuantityProvider } from "@/contractor/pages/home/project/providers/ProjectOrderedQuantityProvider";
import { ReportItemType } from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import {
  ProjectReportCostCodeFieldsFragment,
  ProjectReportCostCodeVendorFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { CurrencyDisplay } from "../CurrencyDisplay";
import {
  ReportItemOrderedPanel,
  ReportType,
} from "../report-item-orders-panel/ReportItemOrderedPanel";
import { CostCodeTotalContainer, ValueWithTax } from "./CostCodeTotal.Styles";

const LinkLikeStyled = tw(LinkLike)`font-medium text-xs`;

type Props = {
  item:
    | ProjectReportCostCodeVendorFieldsFragment
    | ProjectReportCostCodeFieldsFragment
    | null
    | undefined;
  zoneId?: string | undefined;
  type: ReportType;
};

export const CostCodeOrdered: FC<Props> = ({ item, zoneId, type }) => {
  const { formatCurrency } = useFormatNumberToCurrency();
  if (!item) {
    return null;
  }
  return (
    <DrawerPanel
      anchor={(togglePanel) =>
        !item.ordered ? (
          "--"
        ) : (
          <LinkLikeStyled
            onClick={() => {
              togglePanel(true);
            }}
          >
            <CostCodeTotalContainer>
              <CurrencyDisplay value={item?.ordered} />
              <If isTrue={item?.orderedTotal}>
                <FormattedMessage
                  id="VALUE_WITH_TAX_AND_FEES"
                  tagName={ValueWithTax}
                  values={{ value: formatCurrency(item?.orderedTotal) }}
                />
              </If>
            </CostCodeTotalContainer>{" "}
          </LinkLikeStyled>
        )
      }
      content={(togglePanel) => (
        <ProjectOrderedQuantityProvider
          item={{
            costCodeId: item.costCode?.id,
            ordered: item.ordered,
            orderedTotal: item.orderedTotal,
            zoneId,
          }}
          type={ReportItemType.CostCode}
        >
          <ReportItemOrderedPanel
            onClose={() => togglePanel(false)}
            type={type}
            ordered={item.ordered}
            includeVendorHeader={type !== ReportType.Vendor}
          />
        </ProjectOrderedQuantityProvider>
      )}
    />
  );
};
