import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { ErrorOutline } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { ErrorContainer } from "../../Vendors.styles";

type Props = {
  hasError: boolean;
};

export const VendorNotificationError: FC<Props> = ({ hasError }) => {
  return (
    <Tooltip
      id="vendor-error"
      element={
        <If isTrue={hasError}>
          <ErrorContainer>
            <ErrorOutline color="error" fontSize="small" />
          </ErrorContainer>
        </If>
      }
    >
      {hasError && <FormattedMessage id="CONTACT_NOTIFICATION_VALIDATION" />}
    </Tooltip>
  );
};
