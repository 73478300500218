import { BaseSyntheticEvent, FC, forwardRef, useEffect, useState } from "react";
import tw from "tailwind-styled-components";

export type SwitchProps = {
  onChange: (checked: boolean) => void;
  value: boolean;
  onLabel?: string;
  offLabel?: string;
  width?: number;
  className?: string;
  neutral?: boolean;
  disabled?: boolean;
  testId?: string;
  ref?: React.ForwardedRef<HTMLInputElement>;
};

type SwitchStyleProps = {
  $checked?: boolean;
  width?: number;
  $neutral?: boolean;
  $disabled?: boolean;
};

const Label = tw.label`  
  flex items-center
  ${({ $disabled }: SwitchStyleProps) =>
    $disabled ? "cursor-default" : "cursor-pointer"}  
`;

const SwitchControl = tw.div`relative group`;
const SwitchBackground = tw.div`
  block border border-blue-800 h-6 rounded-4xl bg-white
  ${({ $disabled }: SwitchStyleProps) => ($disabled ? "opacity-50" : "")}  
`;
const SwitchInput = tw.input`sr-only`;
const SwitchDot = tw.div<SwitchStyleProps>`
  dot absolute right-1 top-1 w-4 h-4 rounded-full transition ease-in-out duration-200
  ${({ $disabled }: SwitchStyleProps) =>
    $disabled
      ? "bg-gray-500 cursor-auto"
      : "bg-blue-800 group-hover:bg-blue-800"}
`;
const SwitchLabel = tw.div`absolute text-sm top-[3px] pointer-events-none`;
const SwitchOnLabel = tw(SwitchLabel)<SwitchStyleProps>`
  switch-off-label left-2 text-blue-800
    ${({ $checked }: SwitchStyleProps) =>
      $checked ? "inline-block" : "hidden"}
    ${({ $disabled }: SwitchStyleProps) => ($disabled ? "opacity-50" : "")}     
`;
const SwitchOffLabel = tw(SwitchLabel)<SwitchStyleProps>`
  switch-on-label hidden right-2.5 text-blue-800
  ${({ $checked }: SwitchStyleProps) => ($checked ? "hidden" : "inline-block")}
  ${({ $disabled }: SwitchStyleProps) => ($disabled ? "opacity-50" : "")}  
`;

const getSwitchTranslateValue = (
  checked?: boolean,
  width = 60,
  neutral?: boolean,
) => {
  const offset = 24;
  if (neutral) {
    return `translateX(${-width / 2 + offset / 2}px)`;
  } else if (checked) {
    return `translateX(0px)`;
  } else {
    return `translateX(${-width + offset}px)`;
  }
};

export const Switch: FC<SwitchProps> = forwardRef<
  HTMLInputElement,
  SwitchProps
>(
  (
    {
      value,
      onChange,
      className,
      onLabel = "",
      offLabel = "",
      width = 60,
      neutral,
      disabled = false,
      testId,
    },
    ref,
  ) => {
    const [checked, setChecked] = useState<boolean>(value);
    useEffect(() => setChecked(value), [value]);

    return (
      <Label className={className} data-testid={testId} $disabled={disabled}>
        <SwitchControl style={{ width }}>
          <SwitchInput
            data-testid="switch-input"
            type="checkbox"
            checked={checked}
            onChange={(e: BaseSyntheticEvent) => {
              onChange(e.target.checked);
            }}
            disabled={disabled}
            ref={ref}
          />
          <SwitchBackground $disabled={disabled} />
          <SwitchDot
            $checked={checked}
            width={width}
            style={{
              transform: getSwitchTranslateValue(checked, width, neutral),
            }}
            $neutral={neutral}
            $disabled={disabled}
          />
          <SwitchOnLabel $checked={checked || neutral} $disabled={disabled}>
            {onLabel}
          </SwitchOnLabel>
          <SwitchOffLabel $checked={checked && !neutral} $disabled={disabled}>
            {offLabel}
          </SwitchOffLabel>
        </SwitchControl>
      </Label>
    );
  },
);
