import { DistributorQuotesAuxiliaryQuoteItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";
import { LeadTime } from "../common/LeadTime";

type Props = {
  item: DistributorQuotesAuxiliaryQuoteItemFieldsFragment;
  readonly: boolean;
};

export const DistributorQuoteAuxiliaryLeadTime: FC<Props> = ({
  item,
  readonly,
}) => {
  const { updateQuote } = useDistributorQuote();
  const handleOnSave = useCallback(
    async (leadTimeDays: number | null) => {
      const updatedAuxiliaryItems = [
        {
          auxiliaryItemId: item.id,
          leadTimeDays,
        },
      ];
      await updateQuote({
        updatedAuxiliaryItems,
      });
    },
    [item.id, updateQuote],
  );

  return (
    <LeadTime
      leadTimeDays={item.leadTimeDays}
      readonly={readonly}
      updateLeadTime={handleOnSave}
    />
  );
};
