import { TagPicker } from "@/common/components/tag-picker/TagPicker";
import { DistributorBuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC } from "react";

type Props = {
  item?: DistributorBuyoutItemFieldsFragment;
  readonly?: boolean;
};

export const DistributorBuyoutTagsPicker: FC<Props> = ({ item, readonly }) => {
  return (
    <TagPicker
      options={item?.tags || []}
      values={item?.tags || []}
      readonly={readonly}
    />
  );
};
