import { FC } from "react";
import tw from "tailwind-styled-components";

const NativePdfContainer = tw.div`w-full col-span-full`;
const NativePdfObject = tw.object`w-full h-[65vh] rounded-3xl object-contain`;

type Props = {
  url: string | undefined;
};

export const NativePdfViewer: FC<Props> = ({ url }) => {
  return (
    <NativePdfContainer>
      <NativePdfObject data={url} />
    </NativePdfContainer>
  );
};
