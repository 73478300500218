import { LinkLikeText } from "@/common/components/link-like-text/LinkLikeText";
import { ReleaseIssuesFieldsFragment } from "@/generated/graphql";
import { Report } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Wrapper = tw.div`flex text-red-500 items-center gap-1`;

export const DeliveryIssues: FC<{
  issues: Pick<ReleaseIssuesFieldsFragment, "id" | "resolution">[];
}> = ({ issues }) => {
  const count = issues.length;

  if (issues.length === 0) {
    return <>--</>;
  }

  if (issues.every((issue) => issue.resolution)) {
    return (
      <Wrapper>
        <FormattedMessage
          tagName={LinkLikeText}
          id="ORDER_RESOLVED_ISSUES"
          values={{ count }}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Report />
      <FormattedMessage id="ORDER_ISSUES" values={{ count }} />
    </Wrapper>
  );
};
