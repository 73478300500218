import { routes } from "@/config/routes";
import { OrderFromQuote } from "../releases/pages/deliveries/components/order-from-quote/OrderFromQuote";
import { Release } from "./Release";

import { OrderFromNote } from "../releases/pages/deliveries/components/order-from-note/OrderFromNote";
import { ReleaseDetails } from "./pages/release-details/ReleaseDetails";
import { SpecifyReleaseDetails } from "./pages/specify-details/SpecifyReleaseDetails";
import { SelectReleaseProducts } from "./pages/specify-details/select-release-products/SelectReleaseProducts";

export const releaseRoutes = {
  children: [
    {
      path: routes.deliveries,
      element: <Release />,
      children: [
        {
          path: routes.addProductsToRelease,
          element: <SelectReleaseProducts />,
        },
        {
          path: routes.specifyDeliveryDetails,
          element: <SpecifyReleaseDetails />,
        },
        {
          path: routes.delivery,
          element: <ReleaseDetails />,
        },
        {
          path: routes.deliveryMessages,
          element: <ReleaseDetails />,
        },
        {
          path: routes.deliveryFromQuote,
          element: <OrderFromQuote />,
        },
        {
          path: routes.editDeliveryFromQuote,
          element: <OrderFromQuote />,
        },
        {
          path: routes.deliveryFromNote,
          element: <OrderFromNote />,
        },
        {
          path: routes.editDeliveryFromNote,
          element: <OrderFromNote />,
        },
      ],
    },
  ],
};
