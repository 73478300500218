import { LinkInvoiceInput, useLinkInvoiceMutation } from "@/generated/graphql";
import { GraphQLError } from "graphql";
import { useInvoiceVerification } from "../../invoice-verification/providers/InvoiceVerificationProvider";

export const useLinkInvoice = () => {
  const { refetch } = useInvoiceVerification();
  const [linkInvoiceMutation] = useLinkInvoiceMutation();

  const linkInvoice = async (linkInvoiceInput: LinkInvoiceInput) => {
    try {
      const { data, errors } = await linkInvoiceMutation({
        variables: {
          input: linkInvoiceInput,
        },
      });
      if (!errors) {
        refetch();
      }
      return !!data?.linkInvoice;
    } catch (error) {
      if ((error as GraphQLError).message) {
        return (error as GraphQLError).message;
      }
      return false;
    }
  };

  return { linkInvoice };
};
