import { RfqStatus } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { BaseStatusChip, BaseStatusChipProps } from "./BaseStatusChip";

type Props = BaseStatusChipProps & {
  status: RfqStatus;
};

const RFQ_STATUSES = {
  [RfqStatus.Draft]: "bg-gray-200",
  [RfqStatus.Active]: "bg-yellow-600",
  [RfqStatus.Awarded]: "bg-green-300",
  [RfqStatus.Cancelled]: "bg-red-200",
};

export const RfqStatusChip: FC<Props> = ({ status, type = "normal" }) => {
  return (
    <BaseStatusChip
      type={type}
      classes={{ text: RFQ_STATUSES[status] }}
      data-testid={`rfq-status-${type}`}
    >
      <FormattedMessage id={`RFQ_STATUS_${status}`} />
    </BaseStatusChip>
  );
};
