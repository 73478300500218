import { FC, forwardRef } from "react";
import tw from "tailwind-styled-components";
import { BaseButton } from "./BaseButton";

const IconButtonBorderlessStyled = tw(BaseButton)`
  text-blue-500 border-none w-10 h-10 px-1 py-1
  hover:text-blue-300  disabled:text-gray-400
  ${({ $autoScale }: { $autoScale?: boolean }) => $autoScale && "w-auto"}
`;

type IconButtonProps = { autoScale?: boolean } & React.ComponentProps<
  typeof BaseButton
>;

const Icon = tw.div``;

export const IconButtonBorderless: FC<IconButtonProps> = forwardRef(
  ({ children, autoScale, ...props }, ref) => {
    return (
      <IconButtonBorderlessStyled ref={ref} $autoScale={autoScale} {...props}>
        <Icon>{children}</Icon>
      </IconButtonBorderlessStyled>
    );
  },
);
