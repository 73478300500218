import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import {
  WizardModal,
  WizardModalMode,
  WizardModalPage,
} from "@/common/components/wizard-modal/WizardModal";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { MaterialSummaryFields } from "@/contractor/pages/home/rfq/components/add-org-sku/AddOrgMaterial";
import { FC, useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { SelectOrgMaterial } from "../list/SelectOrgMaterial";

type Props = {
  setOpened: (opened: boolean) => void;
  opened: boolean;
  defaultItemId: string | null;
};

export const DefaultItemModal: FC<Props> = ({
  opened,
  setOpened,
  defaultItemId: defaultOrgMaterialId,
}) => {
  const [defaultItemId, setDefaultItemId] = useState<string | null | undefined>(
    defaultOrgMaterialId,
  );
  const { materialsMap } = useMaterials();
  const intl = useIntl();
  const { setValue } = useFormContext();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const onClose = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  const save = useCallback(() => {
    setValue("defaultOrgMaterialId", defaultItemId);
    onClose();
  }, [onClose, defaultItemId, setValue]);

  const pages: WizardModalPage[] = useMemo(() => {
    const pagesList: WizardModalPage[] = [];

    if (
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.DefaultItemOrderTypeMapping,
      )
    ) {
      pagesList.push({
        pages: [
          {
            component: (
              <SelectOrgMaterial
                title={intl.$t({ id: "SELECT_DEFAULT_ITEM" })}
                setSelectedOrgMaterial={(
                  orgMaterial: MaterialSummaryFields | null | undefined,
                ) => setDefaultItemId(orgMaterial?.id)}
                selectedOrgMaterial={
                  defaultItemId ? materialsMap.get(defaultItemId) : null
                }
              />
            ),
            hideHeader: true,
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: onClose,
                text: intl.$t({ id: "CLOSE" }),
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                onClick: save,
                text: intl.$t({ id: "SAVE" }),
                className: "flex-1",
                disabled: !defaultItemId,
              },
            ],
          },
        ],
      });
    }
    return pagesList;
  }, [
    hasFeatureInConnectedSourceSystem,
    intl,
    defaultItemId,
    materialsMap,
    onClose,
    save,
  ]);

  return (
    <WizardModal
      mode={WizardModalMode.MULTIPLE_PAGES}
      opened={opened}
      pages={pages}
      onClose={onClose}
    />
  );
};
