import {
  BuyoutEvent,
  InvoiceEvent,
  MessageContextKind,
  QuoteEvent,
  ReleaseEvent,
} from "@/generated/graphql";

export const mapExcludedEventsToArray = <
  T extends
    | QuoteEvent
    | ReleaseEvent
    | BuyoutEvent
    | InvoiceEvent
    | MessageContextKind,
>(
  events: Array<T> | undefined,
  types: T[],
  orgEvents: Array<T> | undefined = undefined,
) => {
  return types.reduce(
    (acc, value) => {
      acc[value] =
        events?.includes(value) || orgEvents?.includes(value)
          ? !events?.includes(value) && !orgEvents?.includes(value)
          : true;
      return acc;
    },
    {} as Record<T, boolean>,
  );
};
