import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import {
  AlternativeFulfillmentInput,
  DistributorReleaseItemFieldsFragment,
} from "@/generated/graphql";
import { CalendarTodayOutlined, Clear } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDistributorRelease } from "../providers/DistributorReleaseProvider";
import { getReleaseTime } from "../utils/getReleaseTime";
import { DistributorReleaseDatePicker } from "./DistributorReleaseDatePicker";

const Container = tw.div`flex flex-col items-center`;
const MovedToContainer = tw.div`text-2xs mt-1`;
const ButtonContent = tw.div`flex flex-row gap-1 text-sm items-center justify-center`;
const CalendarTodayOutlinedStyled = tw(CalendarTodayOutlined)`text-lg`;
const ClearIcon = tw(Clear)`text-sm hover:text-red-300`;
const DateViewContainer = tw.div``;
const DeleteContainer = tw.span`grid w-full h-full place-items-center`;

type Props = {
  item: DistributorReleaseItemFieldsFragment;
  readonly?: boolean;
};

export const DistributorReleaseDateSelector: FC<Props> = ({
  item,
  readonly,
}) => {
  const { openDialog } = useDialog();
  const { release, updateVendorReleaseItem, updatedItem } =
    useDistributorRelease();
  const intl = useIntl();

  const Button = useMemo(
    () =>
      item.alternativeFulfillmentTime || item.alternativeFulfillmentRelease
        ? PrimaryButton
        : OutlinedButton,
    [item.alternativeFulfillmentTime, item.alternativeFulfillmentRelease],
  );

  const onSave = useCallback(
    async (time: AlternativeFulfillmentInput) => {
      await updateVendorReleaseItem({
        releaseItemId: item.id,
        time,
      });
    },
    [item.id, updateVendorReleaseItem],
  );

  const openReleaseDialog = useCallback(() => {
    openDialog({
      title: intl.$t({ id: "SET_FULFILLMENT_DATE_TIME" }),
      titleClassName: "text-base text-left font-medium",
      content: (
        <DistributorReleaseDatePicker
          item={item}
          release={release}
          onSave={onSave}
        />
      ),
    });
  }, [openDialog, intl, item, release, onSave]);

  const onClear = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      await onSave({ time: null, quantity: null });
      e.stopPropagation();
    },
    [onSave],
  );

  if (!(updatedItem(item)?.isIncluded ?? item.isIncluded)) {
    return null;
  }

  return (
    <Container>
      <If isTrue={!readonly}>
        <Button className="h-7 min-h-0 w-28 p-0" onClick={openReleaseDialog}>
          <ButtonContent>
            {item.alternativeFulfillmentTime ||
            item.alternativeFulfillmentRelease ? (
              <>
                <DateViewContainer>
                  <DateView
                    date={
                      item.alternativeFulfillmentTime ||
                      getReleaseTime(item.alternativeFulfillmentRelease)
                    }
                    options={SHORT_DATE_OPTION}
                  />
                </DateViewContainer>
                <DeleteContainer onClick={onClear}>
                  <ClearIcon />
                </DeleteContainer>
              </>
            ) : (
              <>
                <CalendarTodayOutlinedStyled />
                <FormattedMessage id="PICK_DATE" />
              </>
            )}
          </ButtonContent>
        </Button>
        <If isTrue={item.alternativeFulfillmentRelease}>
          <FormattedMessage
            id="MOVED_TO_DELIVERY"
            values={{
              number: item.alternativeFulfillmentRelease?.sequenceNumber,
            }}
            tagName={MovedToContainer}
          />
        </If>
      </If>
      <If isTrue={readonly}>
        {item.alternativeFulfillmentTime ? (
          <DateView
            date={item.alternativeFulfillmentTime}
            options={SHORT_DATE_OPTION}
          />
        ) : (
          <NotNullableRenderer />
        )}
      </If>
    </Container>
  );
};
