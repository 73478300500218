import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { Buyout, BuyoutFieldsFragment } from "@/generated/graphql";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Chip } from "@mui/material";
import { FC, Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ExpandedRelease } from "../../../providers/ReleaseProvider";

export const SHOW_ITEMS_COMPARISON_TABLE = false;

const Container = tw.div`grid grid-cols-[1fr_auto_auto_auto_auto] gap-4 w-fit items-center max-h-screen-1/2 px-5 overflow-auto`;
const Header = tw.div`text-sm font-medium`;
const ItemNameCell = tw.div`grid grid-flow-col place-content-start items-center w-full h-full grow`;
const ItemCell = tw.div`grid grid-flow-col gap-1 place-content-center items-center w-full h-full grow`;
const ItemHeaderCell = tw(ItemCell)`sticky top-0 z-10`;
const ItemHeaderNameCell = tw(ItemNameCell)`sticky top-0 z-10`;
const BackgroundWhite = tw.div`col-span-5 w-full h-7 sticky top-0 bg-white z-10`;
const Index = tw.span`text-sm font-semibold text-white bg-black rounded-full w-6 h-6 flex items-center justify-center`;
const ItemDetailsWrapper = tw.div`grid items-start justify-between w-full h-full p-3`;
const Manufacturer = tw.div`text-xs font-semibold`;
const ItemName = tw.div`text-sm`;
const StyledChip = tw(Chip)`text-2xs h-4 ml-2`;
const PriceWrapper = tw.div`flex flex-col items-center justify-between`;
const UnitLabel = tw.div`text-xs text-gray-600`;
const GreenCircle = tw.div<{ $isVisible: boolean }>`
  ${({ $isVisible }) =>
    $isVisible ? "bg-green-600" : "invisible"} rounded-full w-4 h-4
`;
const Separator = tw.div`col-span-5 w-full border-b border-gray-300 border-dashed`;
const Arrow = tw(ArrowForwardIcon)`h-5 w-6 mx-1`;

type Props = {
  release?: ExpandedRelease | null | undefined;
  newBuyout?: BuyoutFieldsFragment;
};

type ComparisonItem = {
  id: string;
  name: string;
  manufacturer: string;
  details: string;
  unitPrice: string;
  buyout: Buyout;
  newUnitPrice: string;
  uom: string;
  newBuyout: Buyout;
  isItemIncluded: boolean;
  isNewItemIncluded: boolean;
};

export const BuyoutsItemsComparisonTable: FC<Props> = ({
  release,
  newBuyout,
}) => {
  const intl = useIntl();
  const [items, setItems] = useState<ComparisonItem[]>([]);

  useEffect(() => {
    if (release?.items && newBuyout?.items) {
      const items = release.items.map((item) => {
        const newItem = newBuyout.items.find(
          (i) => i.projectItem.id === item.projectItem?.id,
        );
        return {
          id: item.id,
          name: item?.name ?? intl.$t({ id: "NAME_NOT_FOUND" }),
          manufacturer: item?.manufacturer?.name ?? "",
          details: item.notes ?? "",
          unitPrice: item.unitPrice ?? "",
          isItemIncluded: !!item?.buyoutItem?.isIncluded,
          isNewItemIncluded: !!newItem?.isIncluded,
          buyout: release.buyout,
          newUnitPrice: newItem?.unitPrice ?? "",
          newBuyout,
          uom:
            item?.uom?.singularDescription ??
            item?.uom?.pluralDescription ??
            intl.$t({ id: "UOM_NOT_FOUND" }),
        } as ComparisonItem;
      });
      setItems(items);
    }
  }, [intl, newBuyout, release]);

  return (
    <If isTrue={release && newBuyout}>
      <Container>
        <BackgroundWhite />
        <ItemHeaderNameCell>
          <FormattedMessage id="ITEMS_AFFECTED" tagName={Header} />
        </ItemHeaderNameCell>
        <ItemHeaderCell />
        <ItemHeaderCell>
          <StyledChip label={intl.$t({ id: "UNIT_PRICE" })} />
        </ItemHeaderCell>
        <ItemHeaderCell />
        <ItemHeaderCell>
          <StyledChip label={intl.$t({ id: "NEW_UNIT_PRICE" })} />
        </ItemHeaderCell>
        <Separator />
        {items.map((item, index) => (
          <Fragment key={index}>
            <ItemNameCell>
              <Index>{index + 1}</Index>
              <ItemDetailsWrapper>
                <Manufacturer>{item.manufacturer}</Manufacturer>
                <ItemName>
                  {item.name} {item.details}
                </ItemName>
              </ItemDetailsWrapper>
            </ItemNameCell>
            <Tooltip
              id="unit-price-tooltip"
              element={<GreenCircle $isVisible={item.isItemIncluded} />}
            >
              <FormattedMessage
                id="ITEM_IS_PART_OF_BUYOUT"
                values={{
                  buyoutNo: release?.buyout?.clientIdentifier,
                }}
              />
            </Tooltip>
            <ItemCell>
              <PriceWrapper>
                <ValueCurrency value={item.unitPrice} />
                <UnitLabel>
                  <FormattedMessage
                    id="PER_UOM"
                    values={{
                      uom: item?.uom,
                    }}
                  />
                </UnitLabel>
              </PriceWrapper>
            </ItemCell>
            <ItemCell>
              <Arrow />
              <Tooltip
                id="unit-price-tooltip"
                element={<GreenCircle $isVisible={item.isNewItemIncluded} />}
              >
                <FormattedMessage
                  id="ITEM_IS_PART_OF_BUYOUT"
                  values={{
                    buyoutNo: newBuyout?.clientIdentifier,
                  }}
                />
              </Tooltip>
            </ItemCell>
            <ItemCell>
              <If isTrue={item.isNewItemIncluded}>
                <PriceWrapper>
                  <ValueCurrency value={item.newUnitPrice} />
                  <UnitLabel>
                    <FormattedMessage
                      id="PER_UOM"
                      values={{
                        uom: item?.uom,
                      }}
                    />
                  </UnitLabel>
                </PriceWrapper>
              </If>
              <If isTrue={!item.isNewItemIncluded}>
                <FormattedMessage id="NOT_INCLUDED" />
              </If>
            </ItemCell>
            <Separator />
          </Fragment>
        ))}
      </Container>
    </If>
  );
};
