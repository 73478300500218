import { If } from "@/common/components/if/If";
import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useImportExternalCostCodes } from "../../../providers/ImportExternalCostCodesProvider";
import { ImportExternalResultTitle } from "../components/ImportExternalResultTitle";
import { useImportExternalCostCodesTableConfiguration } from "./ImportExternalCostCodesTable.configuration";

const Container = tw.div`flex flex-1 self-stretch flex-col`;

export const ImportExternalCostCodesImportResults = () => {
  const intl = useIntl();
  const configuration = useImportExternalCostCodesTableConfiguration();
  const { importedCostCodes } = useImportExternalCostCodes();

  return (
    <Container>
      <If isTrue={(importedCostCodes?.created ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedCostCodes?.created ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "CREATED_COST_CODES_COUNT" },
                { count: (importedCostCodes?.created ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16"
        />
      </If>
      <If isTrue={(importedCostCodes?.mapped ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedCostCodes?.mapped ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "MATCHED_COST_CODES_COUNT" },
                { count: (importedCostCodes?.mapped ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16 mt-2"
        />
      </If>
      <If isTrue={(importedCostCodes?.skipped ?? []).length > 0}>
        <LocalSearchableList
          tableConfiguration={configuration}
          items={importedCostCodes?.skipped ?? []}
          loadingItems={false}
          searchBarTitle={
            <ImportExternalResultTitle
              title={intl.$t(
                { id: "SKIPPED_COST_CODES_COUNT" },
                { count: (importedCostCodes?.skipped ?? []).length },
              )}
            />
          }
          searchBarClassName="h-16 mt-2"
        />
      </If>
    </Container>
  );
};
