import tw from "tailwind-styled-components";

export const ProductsContainer = tw.div`
  flex mt-5 h-full
`;

export const Item = tw.div`
  flex flex-col
`;

export const ItemText = tw.div`
  text-xs
`;
