import { FC, PropsWithChildren } from "react";
import tw from "tailwind-styled-components";

const StatusContainer = tw.div`
  grid items-center justify-center min-h-8 py-1 w-26 px-2 
  text-sm rounded-[3px] capitalize text-center pointer-events-none
`;
const BreadcrumbStatusContainer = tw.div`
  print:hidden text-2xs md:text-sm px-2 py-1 rounded border border-dashed border-gray-400 bg-white
`;
const SmallStatusContainer = tw.div`
  print:hidden flex items-center w-fit gap-1 text-xs border border-dashed rounded 
  px-1 mx-0.5 py-0.5 bg-transparent text-nowrap 
`;

export type BaseStatusChipProps = {
  classes?: {
    root?: string;
    text?: string;
  };
  type?: "normal" | "breadcrumb" | "small";
  "data-testid"?: string;
};

type Props = PropsWithChildren & BaseStatusChipProps;

export const BaseStatusChip: FC<Props> = ({
  children,
  classes,
  type = "normal",
  "data-testid": testId,
}) => {
  switch (type) {
    case "breadcrumb":
      return (
        <BreadcrumbStatusContainer
          style={{ letterSpacing: "0.02rem" }}
          className={`${classes?.root}`}
          data-testid={testId}
        >
          {children}
        </BreadcrumbStatusContainer>
      );
    case "small":
      return (
        <SmallStatusContainer
          style={{ lineHeight: "0.8rem", letterSpacing: "0.01rem" }}
          className={`${classes?.root}`}
          data-testid={testId}
        >
          {children}
        </SmallStatusContainer>
      );
    default:
      return (
        <StatusContainer
          style={{ lineHeight: "0.8rem", letterSpacing: "0.02rem" }}
          className={`${classes?.root} ${classes?.text}`}
          data-testid={testId}
        >
          {children}
        </StatusContainer>
      );
  }
};
