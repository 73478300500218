import { FC, PropsWithChildren } from "react";

type IfProps = PropsWithChildren<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isTrue: any;
}>;

export const If: FC<IfProps> = ({ children, isTrue }) => {
  if (isTrue) {
    return <>{children}</>;
  }

  return null;
};
