import { create } from "zustand";
import { devtools } from "zustand/middleware";

export enum BuyoutItemFilter {
  FULLY_RECEIVED = "FULLY_RECEIVED",
}

export type BuyoutItemsFilters = {
  [BuyoutItemFilter.FULLY_RECEIVED]?: boolean | null | undefined;
};

type State = {
  filter: BuyoutItemsFilters;
  setFilter: (filter: BuyoutItemsFilters) => void;
  selectedCostCodes: string[];
  setSelectedCostCodes: (costCodes: string[]) => void;
  selectedTags: string[];
  setSelectedTags: (tags: string[]) => void;
  excludeFullyOrdered: boolean;
  setExcludeFullyOrdered: (exclude: boolean) => void;
  resetFilters: () => void;
};

export const useBuyoutFiltersStore = create<State>()(
  devtools((set) => ({
    filter: { [BuyoutItemFilter.FULLY_RECEIVED]: undefined },
    setFilter: (filter) => set({ filter }),
    selectedCostCodes: [],
    setSelectedCostCodes: (costCodes) => set({ selectedCostCodes: costCodes }),
    selectedTags: [],
    setSelectedTags: (tags) => set({ selectedTags: tags }),
    excludeFullyOrdered: false,
    setExcludeFullyOrdered: (exclude) => set({ excludeFullyOrdered: exclude }),
    resetFilters: () =>
      set({
        filter: { [BuyoutItemFilter.FULLY_RECEIVED]: undefined },
        selectedCostCodes: [],
        selectedTags: [],
        excludeFullyOrdered: false,
      }),
  })),
);
