import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { SourceSystem } from "@/generated/graphql";
import { useMemo } from "react";
import { useAgaveConnectionPage } from "../../default-page-config/pages/useAgaveConnectionPage";
import { useAgaveFinalStep } from "../../default-page-config/pages/useAgaveFinalStep";
import { useGlobalPhaseCodesPage } from "../../default-page-config/pages/useGlobalPhaseCodesPage";
import { useInvoiceExportSettingsPage } from "../../default-page-config/pages/useInvoiceExportSettingsPage";
import { usePoExportSettingsPage } from "../../default-page-config/pages/usePoExportSettingsPage";

type CustomPage = {
  sourceSystem: SourceSystem;
  pages: WizardModalPage[];
};

export const useVistaPages = (): CustomPage => {
  const finalStep = useAgaveFinalStep();
  const poExportSettings = usePoExportSettingsPage();
  const invoiceExportSettings = useInvoiceExportSettingsPage();

  const agaveConnectionPage = useAgaveConnectionPage();
  const globalPhaseCodesPage = useGlobalPhaseCodesPage();
  return useMemo(
    () => ({
      sourceSystem: SourceSystem.Vista,
      pages: [
        agaveConnectionPage,
        globalPhaseCodesPage,
        poExportSettings,
        invoiceExportSettings,
        finalStep,
      ],
    }),
    [
      agaveConnectionPage,
      finalStep,
      globalPhaseCodesPage,
      poExportSettings,
      invoiceExportSettings,
    ],
  );
};
