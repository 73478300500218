import { If } from "@/common/components/if/If";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { BuyoutReleaseFieldsFragment } from "@/generated/graphql";
import { FC, useState } from "react";
import tw from "tailwind-styled-components";
import { ReleaseStatusChip } from "../../../../../../common/components/statuses/ReleaseStatusChip";
import { useBuyoutReleases } from "../../providers/BuyoutReleasesProvider";
import { ReleaseInfoViewDetails } from "./ReleaseInfoViewDetails";
import { ReleaseActions } from "./release-view/ReleaseActions";
import { ReleaseDelivery } from "./release-view/ReleaseDelivery";
import { ReleaseDetails } from "./release-view/ReleaseDetails";
import { ReleasePONumber } from "./release-view/ReleasePONumber";
import { ReleaseStocking } from "./release-view/ReleaseStocking";
import { ReleaseTotal } from "./release-view/ReleaseTotal";
import { ReleaseValidations } from "./release-view/ReleaseValidations";

const OuterContainer = tw.div<{ $isOpened: boolean }>`
  ${({ $isOpened }: { $isOpened: boolean }) => $isOpened && "mb-4"}
`;

const Container = tw.div<{ $archived: boolean }>`
  sticky top-9 lg:top-12 z-30 grid grid-flow-col bg-blue-100 border border-gray-200 
  rounded-md items-center py-2 px-5 2xl:grid-cols-[0.5fr_120px_130px_3fr_176px_176px_176px_20px]
  lg:grid-cols-[0.5fr_120px_130px_3fr_144px_144px_144px_20px] gap-2 mb-2
  hover:cursor-pointer hover:bg-blue-100
  ${({ $archived }: { $archived: boolean }) => $archived && "bg-gray-100"}
`;

const DeliveryStatusStyled = tw(ReleaseStatusChip)`
  hidden lg:inline-grid
`;

type Props = {
  release: BuyoutReleaseFieldsFragment;
};

export const ReleaseInfoView: FC<Props> = ({ release }) => {
  const [isOpened, setIsOpened] = useState(false);
  const { updatePONumber } = useBuyoutReleases();

  return (
    <OuterContainer $isOpened={isOpened}>
      <Container
        $archived={!!release.deletedAt}
        onClick={() => setIsOpened((oldValue) => !oldValue)}
      >
        <ReleaseDetails isOpened={isOpened} release={release} />
        <PoNumberingSettingsCheck>
          <ReleasePONumber
            release={release}
            readonly={!!release.deletedAt}
            update={updatePONumber}
            sourceSystem={release.poLink?.sourceSystem}
          />
        </PoNumberingSettingsCheck>
        <DeliveryStatusStyled
          status={release.status}
          releaseType={release.type}
        />
        <ReleaseValidations release={release} />
        <ReleaseDelivery release={release} />
        <ReleaseStocking release={release} />
        <ReleaseTotal release={release} />
        <ReleaseActions release={release} />
      </Container>
      <If isTrue={isOpened}>
        <ReleaseInfoViewDetails releaseId={release.id} />
      </If>
    </OuterContainer>
  );
};
