import FileAdd from "@/assets/icons/file-add.svg?react";
import File from "@/assets/icons/file.svg?react";
import { AddItemsButton } from "@/common/components/add-items-button/AddItemsButton";
import {
  AddItemsFromDialog,
  AddItemsOptionType,
} from "@/common/components/add-items-from-dialog/AddItemsFromDialog";
import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { SpreadsheetSaveType } from "@/common/providers/ColumnMapperProvider";
import { VIEW_STATE, useTableView } from "@/common/providers/TableViewProvider";
import { ProjectFieldsFragment } from "@/generated/graphql";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import tw from "tailwind-styled-components";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";
import { useSyncEstimatedItems } from "../../providers/SyncEstimatedItemsProvider";
import { UPLOAD_MATERIALS_DRAWER } from "../upload-materials/UploadMaterialsDrawer";

const ElementContainer = tw.div``;

type ProjectProductMenuProps = {
  project?: ProjectFieldsFragment | null;
  element?: ReactNode;
};

export const ProjectAddItems: FC<ProjectProductMenuProps> = ({
  project,
  element,
}) => {
  const [showAddItemsDialog, setShowAddItemsDialog] = useState(false);
  const { setNewProjectEstimatedItem } = useEstimatedItems();
  const { toggle } = useGlobalDrawer();
  const { tableView } = useTableView();
  const { syncEstimatedItems } = useSyncEstimatedItems();

  useEffect(() => {
    setTimeout(() => {
      setShowAddItemsDialog(
        project?.estimatedItemGroups.length === 0 &&
          tableView === VIEW_STATE.normal,
      );
    });
  }, [project, tableView]);

  const dialogOptions = useMemo<AddItemsOptionType[]>(
    () => [
      {
        label: "UPLOAD_ESTIMATE",
        details: "UPLOAD_ESTIMATE_INFO",
        callback: () => {
          setShowAddItemsDialog(false);
          toggle(UPLOAD_MATERIALS_DRAWER, true);
        },
        icon: <File className="h-28 w-28" />,
        preferred: true,
      },
      ...(tableView === VIEW_STATE.normal
        ? [
            {
              label: "ADD_CUSTOM_MATERIAL",
              details: "ADD_CUSTOM_MATERIAL_INFO",
              callback: () => {
                setNewProjectEstimatedItem([
                  { key: "id", value: "" },
                  { key: "isAddMode", value: true },
                ]);
                setShowAddItemsDialog(false);
              },
              icon: <FileAdd className="h-28 w-28" />,
            },
          ]
        : []),
    ],
    [setNewProjectEstimatedItem, tableView, toggle],
  );

  const openDialog = () => {
    setShowAddItemsDialog(true);
  };

  const gotoItemSelection = async () => {
    const result = await syncEstimatedItems(SpreadsheetSaveType.Toggle);
    if (result) {
      setShowAddItemsDialog(true);
    }
  };

  return (
    <>
      {element ? (
        <ElementContainer onClick={openDialog}>{element}</ElementContainer>
      ) : (
        <AddItemsButton onClick={gotoItemSelection} />
      )}
      <AddItemsFromDialog
        title="ADD_ITEMS_TO_PROJECT"
        options={dialogOptions}
        visible={showAddItemsDialog}
        setVisible={setShowAddItemsDialog}
      />
    </>
  );
};
