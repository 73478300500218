import { useUser } from "@/common/providers/UserProvider";
import { PoNumberingMode } from "@/generated/graphql";
import { useMemo } from "react";

export const usePoNumberingSettingsCheck = () => {
  const { poNumbering } = useUser();

  const includePoNumbering = useMemo(
    () => PoNumberingMode.Never !== poNumbering,
    [poNumbering],
  );

  return {
    includePoNumbering,
  };
};
