import { Loader } from "@/common/components/loader/Loader";
import { DistributorReleasesHeader } from "./components/DistributorReleasesHeader";
import { DistributorReleasesList } from "./components/DistributorReleasesList";
import {
  DistributorReleasesProvider,
  useDistributorReleases,
} from "./providers/DistributorReleasesProvider";

const DistributorReleasesWithProvider = () => {
  const { buyout } = useDistributorReleases();

  if (!buyout) {
    return <Loader loading />;
  }

  return (
    <>
      <DistributorReleasesHeader buyout={buyout} />
      <DistributorReleasesList releases={buyout.releases} />
    </>
  );
};

export const DistributorReleases = () => {
  return (
    <DistributorReleasesProvider>
      <DistributorReleasesWithProvider />
    </DistributorReleasesProvider>
  );
};
