import { Checkbox, FormControlLabel } from "@mui/material";
import { FC, memo } from "react";
import tw from "tailwind-styled-components";

type ProductFacetValueProps = {
  parentId: string;
  name: string;
  count?: number | null;
  value: string;
  selected: boolean;
  handleChange: (id: string, value: string) => void;
};

const LabelContainer = tw.div`
  flex w-full font-light text-xs
`;

const LabelName = tw.div`
  flex-1
`;

const LabelCount = tw.div``;

const FormControlLabelStyled = tw(FormControlLabel)`
  w-full
`;

export const ProductFacetValue: FC<ProductFacetValueProps> = memo(
  ({ parentId, name, value, count, selected, handleChange }) => (
    <FormControlLabelStyled
      key={value}
      control={
        <Checkbox
          size="small"
          checked={selected}
          onChange={() => handleChange(parentId, value)}
          name={name}
        />
      }
      label={
        <LabelContainer>
          <LabelName>{name}</LabelName>
          <LabelCount>{count}</LabelCount>
        </LabelContainer>
      }
      sx={{
        " .MuiFormControlLabel-label": {
          width: "100%",
        },
      }}
    />
  ),
);
