import { useScrollOnDrag } from "@/common/hooks/useScrollOnDrag";
import {
  CropOriginal,
  RotateLeft,
  RotateRight,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { LinkLike } from "../link-like/LinkLike";

type Props = {
  src?: string | null;
  width?: number | "auto";
  height?: number | "auto";
  withControls?: boolean;
};

const ImageStyled = tw.img`object-contain absolute`;
const CropOriginalStyled = tw(CropOriginal)<Props>`
  h-full text-gray-500
`;
const Container = tw.div`flex flex-col gap-2 relative items-center`;
const Options = tw.div`
  rounded-t-3xl
  flex flex-row items-center justify-center z-20 bg-gray-100 
  border border-gray-300 py-2 w-full sticky top-0 gap-3 right-0
`;
const ImageContainer = tw.div`overflow-scroll relative h-[65vh] min-w-[40vw] w-full p-2`;

export const Image: FC<Props> = ({
  src,
  withControls,
  width = 50,
  height = "auto",
}) => {
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [imageHeight, setImageHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const { handleMouseDown, handleMouseMove, onDragEnd } =
    useScrollOnDrag(containerRef);
  const translate = useMemo(() => {
    switch (rotation) {
      case 0:
        return "";
      case -90:
      case 270:
        return `translate(-100%)`;
      case 180:
      case -180:
        return `translate(-100%, -100%)`;
      case 90:
      case -270:
        return `translate(0, -100%)`;
      default:
        return "";
    }
  }, [rotation]);

  const onImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      const { naturalHeight, naturalWidth } = e.currentTarget;
      setImageHeight(naturalHeight);
      setImageWidth(naturalWidth);
    },
    [],
  );

  return src ? (
    <Container>
      <If isTrue={withControls}>
        <Options>
          <LinkLike onClick={() => setRotation((rotation - 90) % 360)}>
            <RotateLeft className="text-2xl" />
          </LinkLike>
          <LinkLike onClick={() => setRotation((rotation + 90) % 360)}>
            <RotateRight className="text-2xl" />
          </LinkLike>
          <LinkLike onClick={() => setZoom(zoom + 0.1)}>
            <ZoomIn className="text-2xl" />
          </LinkLike>
          <LinkLike onClick={() => setZoom(zoom - 0.1)}>
            <ZoomOut className="text-2xl" />
          </LinkLike>
        </Options>
      </If>
      <ImageContainer
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={onDragEnd}
        onMouseLeave={onDragEnd}
        style={{
          maxWidth: imageWidth,
          maxHeight: imageHeight,
        }}
      >
        <ImageStyled
          src={src}
          onLoad={onImageLoad}
          style={{
            width,
            height,
            transformOrigin: "top left",
            transform: `scale(${zoom}) rotate(${rotation}deg) ${translate}`,
          }}
        />
      </ImageContainer>
    </Container>
  ) : (
    <CropOriginalStyled style={{ width, height }} />
  );
};
