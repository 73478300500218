import { IconButtonBorderless } from "@/common/components/button/IconButton";
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from "@mui/x-date-pickers";

type ButtonFieldProps = BaseSingleInputFieldProps<
  Date | null,
  Date,
  FieldSection,
  boolean,
  DateValidationError
> & {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DateButtonField = ({
  setOpen,
  label,
  id,
  disabled,
  InputProps: { ref } = {},
  inputProps: { "aria-label": ariaLabel } = {},
}: ButtonFieldProps) => {
  return (
    <IconButtonBorderless
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      {label}
    </IconButtonBorderless>
  );
};
