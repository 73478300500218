import { Loader } from "@/common/components/loader/Loader";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import tw from "tailwind-styled-components";
import { ProjectTagsProvider } from "../../../project/providers/ProjectTagsProvider";
import { useContractorBuyout } from "../../providers/ContractorBuyoutProvider";
import { BuyoutStandaloneReleaseItemsList } from "../common/buyout-standalone-release-items-list/BuyoutStandaloneReleaseItemsList";
import { VendorBuyoutInlineNotesAndTermsPanel } from "../common/notes-and-attachments/VendorBuyoutInlineNotesAndTermsPanel";
import { useBuyoutItemsListConfiguration } from "../common/table-configurations/BuyoutListItemsConfiguration";
import { BuyoutItemsSideActions } from "../non-quoted/components/BuyoutItemsSideActions";
import { BuyoutGroupedProvider } from "../non-quoted/providers/BuyoutGroupedProvider";
import { BuyoutQuotedFooter } from "./components/BuyoutQuotedFooter";
import { BuyoutQuotedHeader } from "./components/BuyoutQuotedHeader";
import { BuyoutQuotedList } from "./components/BuyoutQuotedList";
import { BuyoutQuotedGroupedProvider } from "./providers/BuyoutQuotedGroupedProvider";
import { BuyoutQuotedProvider } from "./providers/BuyoutQuotedProvider";

const Container = tw.div`pb-15`;

export const BuyoutQuoted = () => {
  const { buyout, loading } = useContractorBuyout();
  const buyoutItemsListConfiguration = useBuyoutItemsListConfiguration();

  if (!buyout) {
    return <Loader loading />;
  }

  return (
    <ProjectTagsProvider>
      <BuyoutQuotedProvider buyout={buyout}>
        <BuyoutQuotedGroupedProvider buyout={buyout}>
          <ColumnMapperProvider
            config={buyoutItemsListConfiguration.spreadsheetConfig}
          >
            <BuyoutGroupedProvider buyout={buyout}>
              <Container>
                <BuyoutQuotedHeader buyout={buyout} />
                <BuyoutQuotedList buyout={buyout} loading={loading} />
                <BuyoutItemsSideActions />
                <BuyoutStandaloneReleaseItemsList />
                <VendorBuyoutInlineNotesAndTermsPanel />
                <BuyoutQuotedFooter buyout={buyout} />
              </Container>
            </BuyoutGroupedProvider>
          </ColumnMapperProvider>
        </BuyoutQuotedGroupedProvider>
      </BuyoutQuotedProvider>
    </ProjectTagsProvider>
  );
};
