import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useSourceSystems } from "@/common/hooks/integrations/source-system-config/useSourceSystems";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { InfoOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useImportExternalItems } from "../../../providers/ImportExternalItemsProvider";
import { SourceSystemItem } from "./SourceSystemItem";
const SourceSystemSelectorContainer = tw.div`grid grid-cols-2 gap-y-4 mt-8`;
const TooltipElement = tw.div`flex self-stretch justify-center`;

export const ImportExternalMaterialsButton = () => {
  const intl = useIntl();
  const { openDialog, cancelDialog } = useDialog();
  const { settings } = useOrgSettings();
  const { openModal } = useImportExternalItems();
  const { sourceSystems } = useSourceSystems({ connected: true });
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const connectedSourceSystems = useMemo(
    () =>
      (settings?.integrations.sourceSystems ?? []).filter((s) => s.connected),
    [settings],
  );

  const integrations = useMemo(
    () =>
      sourceSystems.map((ss) => {
        return {
          logo: ss.config.logo.normal,
          name: ss.config.name,
          type: ss.config.description,
          sourceSystem: ss.sourceSystem.system,
          isConnected: !!ss?.sourceSystem.connected,
        };
      }),
    [sourceSystems],
  );

  if (
    connectedSourceSystems.length === 0 ||
    !hasFeatureInConnectedSourceSystem(IntegrationFeature.OrgMaterialsMapping)
  ) {
    return null;
  }

  if (connectedSourceSystems.length === 1) {
    return (
      <OutlinedButton
        onClick={() =>
          openModal({ sourceSystem: connectedSourceSystems[0].system })
        }
      >
        <FormattedMessage
          id="IMPORT_FROM_SYSTEM"
          values={{
            system: intl.$t({
              id: `INTEGRATION_${connectedSourceSystems[0].system}`,
            }),
          }}
        />
      </OutlinedButton>
    );
  }

  return (
    <OutlinedButton
      onClick={() =>
        openDialog({
          title: (
            <Tooltip
              id="import-materials"
              element={
                <TooltipElement>
                  <FormattedMessage id="IMPORT_MATERIALS" />
                  <InfoOutlined className="ml-2 h-8 w-8 text-blue-500" />
                </TooltipElement>
              }
            >
              <FormattedMessage id="IMPORT_MATERIALS_DESCRIPTION" />
            </Tooltip>
          ),
          content: (
            <SourceSystemSelectorContainer>
              {integrations.map((item, key) => (
                <SourceSystemItem
                  item={item}
                  key={key}
                  onClick={(system) => openModal({ sourceSystem: system })}
                />
              ))}
            </SourceSystemSelectorContainer>
          ),
          handleCancel: cancelDialog,
          cancelButtonText: intl.$t({ id: "CLOSE" }),
        })
      }
    >
      <FormattedMessage id="IMPORT" />
    </OutlinedButton>
  );
};
