import { ReleaseSummaryFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { useReleasesByIds } from "../providers/ReleasesByIdsProvider";

type Release = Pick<
  ReleaseSummaryFieldsFragment,
  "project" | "sequenceNumber" | "predictedPoNumber" | "id"
>;

export const useDefaultReleasePo = () => {
  const { releasesByIds } = useReleasesByIds();
  const getDefaultReleasePo = useCallback(
    (release: Release) => {
      const predictedPoNumber = release.predictedPoNumber;
      const index = releasesByIds
        .filter((r) => r.predictedPoNumber === predictedPoNumber)
        .findIndex((r) => r.id === release.id);
      if (index === 0 && predictedPoNumber) {
        return predictedPoNumber;
      }
      const incrementPredictedNumberAfterLashDash =
        predictedPoNumber?.split("-")[1];
      const increment = incrementPredictedNumberAfterLashDash
        ? parseInt(incrementPredictedNumberAfterLashDash) + index
        : 1;
      return predictedPoNumber?.split("-")[0] + "-" + increment.toString();
    },
    [releasesByIds],
  );

  return { getDefaultReleasePo };
};
