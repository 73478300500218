import { LinkLikeText } from "@/common/components/link-like-text/LinkLikeText";
import tw from "tailwind-styled-components";

export const ListItemContainer = tw.div``;

export const ListItem = tw.div`
    relative px-3 py-2 flex flex-col gap-2 w-full justify-content-center
    
`;

export const InfoIconContainer = tw(LinkLikeText)`text-left pl-1`;

export const Container = tw.div`flex flex-col gap-4`;

export const ItemContainer = tw.div`
    flex flex-row justify-between
`;

export const Actions = tw.div`
    absolute flex gap-1 right-4 top-3
`;
