import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useRfqMutations } from "@/contractor/pages/home/rfq/hooks/useRfqMutations";
import {
  AcceptQuoteMutationVariables,
  BuyoutsDocument,
  QuoteStatus,
  RfqQuotesFieldsFragment,
  RfqQuotesQuoteItemFieldsFragment,
  RfqStatus,
  useAcceptQuoteMutation,
  useRfqQuotesQuery,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionStringPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";

export type QuoteItem = {
  rfqItemId: string;
  quoteId: string;
  quoteItemId: string;
};

export type AuxQuoteItem = {
  quoteId: string;
  itemId: string;
};

type ExpandedQuoteItem = RfqQuotesQuoteItemFieldsFragment & {
  quoteId: string;
  rfqItemId: string;
};

type RfqQuotesProviderContextType = {
  rfq?: RfqQuotesFieldsFragment | null;
  isOpen: boolean;
  toggleOpen: () => void;
  acceptQuote: () => Promise<string | undefined>;
  hoverQuoteId: string | null;
  setHoverQuoteId: (quoteId: string | null) => void;
  selectedQuotes: QuoteItem[];
  setSelectedQuotes: (quotes: QuoteItem[]) => void;
  selectedAuxiliaryQuoteItems: AuxQuoteItem[];
  setSelectedAuxiliaryQuoteItems: (quotes: AuxQuoteItem[]) => void;
  multiQuote: boolean;
  setMultiQuote: (multiQuote: boolean) => void;
  cancelQuote: (rfqId: string) => Promise<boolean>;
  createDraftQuote: (
    rfqId: string,
  ) => Promise<undefined | { id: string; clientIdentifier: string }>;
  hasMultipleQuotes: boolean;
  loading: boolean;
  allQuoteItems: ExpandedQuoteItem[];
};

const RfqQuotesProviderContext = createContext<RfqQuotesProviderContextType>({
  isOpen: false,
  toggleOpen: NoFunction,
  acceptQuote: NoFunctionStringPromise,
  hoverQuoteId: null,
  setHoverQuoteId: NoFunction,
  selectedQuotes: [],
  setSelectedQuotes: NoFunction,
  selectedAuxiliaryQuoteItems: [],
  setSelectedAuxiliaryQuoteItems: NoFunction,
  multiQuote: false,
  setMultiQuote: NoFunction,
  cancelQuote: NoFunctionBooleanPromise,
  createDraftQuote: NoFunctionUndefinedPromise,
  hasMultipleQuotes: false,
  loading: false,
  allQuoteItems: [],
});

const VISIBLE_QUOTE_STATUSES = [
  QuoteStatus.Submitted,
  QuoteStatus.Rejected,
  QuoteStatus.Accepted,
];

export const OrderQuotesProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { id } = useParams();
  const { paginationArgs } = usePagination();
  const { data, loading, error } = useRfqQuotesQuery({
    variables: {
      id: id || "",
      quoteStatus: VISIBLE_QUOTE_STATUSES,
    },
    fetchPolicy: "cache-and-network",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [acceptQuoteMutation] = useAcceptQuoteMutation({
    refetchQueries: [
      {
        query: BuyoutsDocument,
        variables: {
          ...paginationArgs,
        },
      },
    ],
    awaitRefetchQueries: true,
  });
  const [selectedQuotes, setSelectedQuotesFn] = useState<QuoteItem[]>([]);
  const [selectedAuxiliaryQuoteItems, setSelectedAuxiliaryQuoteItems] =
    useState<AuxQuoteItem[]>([]);
  const [hoverQuoteId, setHoverQuoteId] = useState<string | null>(null);
  const [multiQuote, setMultiQuote] = useState<boolean>(false);
  const { setError } = useGlobalError();
  const { cancelRfqMutation, createRfqDraftMutation } = useRfqMutations();

  const toggleOpen = () => {
    setIsOpen((oldValue: boolean) => !oldValue);
  };

  const allQuoteItems = useMemo(() => {
    return (
      data?.rfq?.quotes
        .map((quote) =>
          quote.itemGroups.map((group) =>
            group.quoteItems.map((item) => ({
              ...item,
              quoteId: quote.id,
              rfqItemId: group.rfqItem.id,
            })),
          ),
        )
        .flat(3) || []
    );
  }, [data?.rfq?.quotes]);

  const acceptQuote = async () => {
    try {
      if (data && data.rfq) {
        const variables = {
          input: {
            rfqId: data.rfq.id,
          },
          quoteStatus: VISIBLE_QUOTE_STATUSES,
        } as AcceptQuoteMutationVariables;
        if (selectedQuotes.length > 0) {
          variables.input.quoteItemIDs = selectedQuotes.map(
            (quote) => quote.quoteItemId,
          );
          variables.input.auxiliaryQuoteItemIDs =
            selectedAuxiliaryQuoteItems.map((quote) => quote.itemId);
        }

        const { errors, data: acceptQuoteResult } = await acceptQuoteMutation({
          variables,
        });
        setError(errors);
        return acceptQuoteResult?.acceptQuote?.quotes
          .filter((quote) => quote.buyout)
          .sort((a, b) =>
            a.buyout && b.buyout
              ? a.buyout.clientIdentifier.localeCompare(
                  b.buyout.clientIdentifier,
                )
              : 0,
          )[0]?.buyout?.id;
      }
    } catch (errors) {
      setError(errors);
      return undefined;
    }
  };

  useErrorEffect(error);

  const setSelectedQuotes = (quotes: QuoteItem[]) => {
    if (data?.rfq?.status !== RfqStatus.Awarded) {
      setSelectedQuotesFn(quotes.filter((quote) => quote.quoteItemId));
    }
  };

  useEffect(() => {
    if (data?.rfq?.status === RfqStatus.Awarded) {
      const acceptedQuotes = data.rfq.quotes.filter(
        (quote) => quote.status === QuoteStatus.Accepted,
      );
      const acceptedItems = acceptedQuotes
        .map((quote) => {
          return quote?.itemGroups.map((group) =>
            group.quoteItems
              .filter((item) => item.status === QuoteStatus.Accepted)
              .map((item) => ({
                rfqItemId: group.rfqItem.id,
                quoteId: quote.id,
                quoteItemId: item.id,
              })),
          );
        })
        .flat(3);
      setSelectedQuotesFn(acceptedItems);
      setMultiQuote(true);
    }
  }, [data]);

  const hasMultipleQuotes = useMemo(() => {
    return !!data?.rfq?.quotes && data?.rfq?.quotes.length > 1;
  }, [data]);

  return (
    <RfqQuotesProviderContext.Provider
      value={{
        rfq: data?.rfq ?? null,
        isOpen,
        toggleOpen,
        acceptQuote,
        cancelQuote: cancelRfqMutation,
        createDraftQuote: createRfqDraftMutation,
        hoverQuoteId,
        setHoverQuoteId,
        selectedQuotes,
        setSelectedQuotes,
        selectedAuxiliaryQuoteItems,
        setSelectedAuxiliaryQuoteItems,
        multiQuote,
        setMultiQuote,
        hasMultipleQuotes,
        allQuoteItems,
        loading,
      }}
    >
      {children}
    </RfqQuotesProviderContext.Provider>
  );
};

export const useRfqQuotes = (): RfqQuotesProviderContextType =>
  useContext(RfqQuotesProviderContext);
