import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ControlledProps } from "../textfield-controlled/TextFieldControlled";

type Props = Omit<CheckboxProps, "onChange" | "value"> &
  ControlledProps & { label: string };

export const CheckboxControlled: FC<Props> = ({ name, rules, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormControlLabel
          checked={field.value}
          onChange={field.onChange}
          control={<Checkbox checked={field.value} />}
          label={props.label}
          componentsProps={{ typography: { sx: { fontSize: "inherit" } } }}
        />
      )}
    />
  );
};
