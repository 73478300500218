import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CreateQuoteDocumentInput,
  QuoteDocumentFieldsFragment,
  useCreateQuoteDocumentMutation,
} from "@/generated/graphql";

export const useCreateQuoteDocument = () => {
  const { setError } = useGlobalError();

  const [createQuoteDocumentMutation] = useCreateQuoteDocumentMutation();
  const createQuoteDocument = async (input: CreateQuoteDocumentInput) => {
    try {
      const { data, errors } = await createQuoteDocumentMutation({
        variables: {
          input,
        },
      });

      setError(errors);
      return data?.createQuoteDocument as QuoteDocumentFieldsFragment | null;
    } catch (error) {
      setError(error);
      return null;
    }
  };

  return { createQuoteDocument };
};
