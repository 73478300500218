import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import Decimal from "decimal.js";
import { useIntl } from "react-intl";
import { AccountingOnPremiseProviderContextType } from "../AccountingOnPremiseWizardProvider";
import { FoundationOnPremisePostInventoryReceipts } from "../steps/default-settings/FoundationOnPremisePostInventoryReceipts";
import { AccountingOnPremiseFinalStep } from "../steps/final/AccountingOnPremiseFinalStep";
import { AccountingOnPremiseGLAccountSettings } from "../steps/general-ledger-account/AccountingOnPremiseGLAccountSettings";
import { AccountingOnPremiseTaxAuthorities } from "../steps/tax-authorities/AccountingOnPremiseTaxAuthorities";

type FoundationPagesArgs = Pick<
  AccountingOnPremiseProviderContextType,
  | "postInventoryReceipts"
  | "closeWizard"
  | "handleSaveWizardData"
  | "glAccountNumber"
  | "taxAuthorities"
  | "inventoryReceiptLedgerAccount"
>;

export const useOnPremiseFoundationPages = ({
  closeWizard,
  handleSaveWizardData,
  glAccountNumber,
  taxAuthorities,
  postInventoryReceipts,
  inventoryReceiptLedgerAccount,
}: FoundationPagesArgs): WizardModalPage[] => {
  const intl = useIntl();
  const { moveToPreviousStep, moveToNextStep, moveToPreviousNestedStep } =
    useNestedStepper();

  return [
    {
      title: intl.$t({ id: "DEFAULT_SETTINGS_FOR_PO_EXPORT" }),
      completed: true,
      pages: [
        {
          component: <FoundationOnPremisePostInventoryReceipts />,
          onPreviousClick: moveToPreviousNestedStep,
          onNextClick: async () => {
            await handleSaveWizardData({
              postInventoryReceipts,
              inventoryReceiptLedgerAccount,
              enabled: true,
            });
            moveToNextStep();
          },
        },
      ],
    },
    {
      title: intl.$t({ id: "GL_ACCOUNT_SETTINGS" }),
      completed: !!glAccountNumber,
      pages: [
        {
          component: <AccountingOnPremiseGLAccountSettings />,
          onNextClickDisabled: !glAccountNumber,
          onPreviousClick: moveToPreviousStep,
          onNextClick: async () => {
            await handleSaveWizardData({
              toJobLedgerAccount: glAccountNumber,
            });
            moveToNextStep();
          },
        },
      ],
    },
    {
      title: intl.$t({ id: "TAX_AUTHORITIES" }),
      completed: !!taxAuthorities.length,
      pages: [
        {
          component: <AccountingOnPremiseTaxAuthorities />,
          onPreviousClick: moveToPreviousStep,
          onNextClick: async () => {
            await handleSaveWizardData({
              taxAuthorities: taxAuthorities
                .filter((t) => t.code && t.rate)
                .map((t) => ({
                  code: t.code,
                  rate: new Decimal(t.rate).div(100).toString(),
                })),
            });

            moveToNextStep();
          },
          onNextClickDisabled: taxAuthorities.some((t) => !t.code || !t.rate),
        },
      ],
    },
    {
      title: null,
      pages: [
        {
          component: <AccountingOnPremiseFinalStep />,
          onCloseClick: closeWizard,
        },
      ],
    },
  ];
};
