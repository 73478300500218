import { LinkLike } from "@/common/components/link-like/LinkLike";
import { useProjectPredictedPoNumber } from "@/common/components/po-number/hooks/useProjectPredictedPoNumber";
import { MagicWand } from "@/common/components/po-number/PoNumberInput.styles";
import { useUser } from "@/common/providers/UserProvider";
import { PoNumberingMode } from "@/generated/graphql";

export const InvoiceOrderMagicWand = ({
  poNumber,
  projectId,
  setPoNUmber,
}: {
  poNumber: string;
  projectId: string | null | undefined;
  setPoNUmber: (value: string) => void;
}) => {
  const { poNumbering } = useUser();
  const { predictedPoNumber } = useProjectPredictedPoNumber(projectId || "");

  if (poNumber || poNumbering === PoNumberingMode.Never || !predictedPoNumber) {
    return null;
  }

  return (
    <LinkLike>
      <MagicWand
        onClick={() => {
          if (predictedPoNumber) {
            setPoNUmber(predictedPoNumber);
          }
        }}
      />
    </LinkLike>
  );
};
