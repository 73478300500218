import { MIN_QUANTITY_DECIMALS } from "@/common/const";
import { EventKeysConstants } from "@/config/constants/eventKeysContants";
import { BaseSyntheticEvent, forwardRef } from "react";
import {
  NumberFormatBase,
  NumberFormatValues,
  NumericFormatProps as NumericFormatBaseProps,
  useNumericFormat,
} from "react-number-format";
import tw from "tailwind-styled-components";
import { TextField, TextFieldProps } from "../textfield/TextField";

type CustomTextFieldProps = TextFieldProps & {
  size?: "small" | "medium";
};

const TextFieldStyled = tw(TextField)<CustomTextFieldProps>`
  "child-first:text-black child-first:bg-transparent child-hover:border-white"}
`;

type NumericFormatProps = Omit<NumericFormatBaseProps, "customInput"> & {
  customInput?: React.ComponentType<CustomTextFieldProps>;
} & TextFieldProps & {
    name: string;
    prefix?: string;
    suffix?: string;
    isAllowed?: (values: NumberFormatValues) => boolean;
    decimals?: number;
    classes?: {
      root: string;
    };
    testId?: string;
    autoSelect?: boolean;
    onChange: (value: number | undefined | string) => void;
    emptyValueFiller?: string;
  };

export const NumericFormat = forwardRef(
  (
    {
      onChange,
      autoSelect,
      emptyValueFiller,
      decimals,
      ...props
    }: NumericFormatProps,
    ref,
  ) => {
    const { format, ...numberFormatBaseProps } = useNumericFormat({
      ...props,
      thousandSeparator: true,
      decimalScale: decimals || MIN_QUANTITY_DECIMALS,
    });

    return (
      <NumberFormatBase
        {...numberFormatBaseProps}
        customInput={
          TextFieldStyled as React.ComponentType<CustomTextFieldProps>
        }
        format={(str: string) => {
          if (!str) {
            return emptyValueFiller ?? "";
          }
          return format?.(str) || "";
        }}
        onValueChange={(v) => {
          onChange(v.floatValue);
        }}
        getInputRef={ref}
        onFocusCapture={
          autoSelect
            ? (event: BaseSyntheticEvent) => {
                event.target.select();
                event.preventDefault();
                event.stopPropagation();
              }
            : undefined
        }
        onKeyDown={(event: BaseSyntheticEvent & { key: string }) => {
          if (event.key === EventKeysConstants.Enter) {
            event.preventDefault();
            event.target.blur();
          }
        }}
        {...props}
      />
    );
  },
);
