import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { OrgFieldsFragment, OrgType, useOrgsQuery } from "@/generated/graphql";
import { NoFunctionPromise } from "@/types/NoFunction";
import { PropsWithChildren, createContext, useContext } from "react";

type ContextProps = {
  orgs: OrgFieldsFragment[];
  loading: boolean;
  error: boolean;
  refetchOrgs: () => Promise<void>;
};

const ProviderContext = createContext<ContextProps>({
  orgs: [],
  loading: false,
  error: false,
  refetchOrgs: NoFunctionPromise,
});

type Props = PropsWithChildren<{
  type?: OrgType;
}>;

export const OrgsProvider = ({
  children,
  type = OrgType.Contractor,
}: Props) => {
  const { data, loading, error, refetch } = useOrgsQuery({
    variables: { filter: { type } },
  });

  useErrorEffect(error);

  const refetchOrgs = async () => {
    await refetch({
      filter: { type },
    });
  };

  return (
    <ProviderContext.Provider
      value={{
        orgs: data?.orgs || [],
        loading,
        error: !!error,
        refetchOrgs,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useOrgs = (): ContextProps => useContext(ProviderContext);
