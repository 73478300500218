import { Loader } from "@/common/components/loader/Loader";
import {
  BuyoutReleaseDetailsFieldsFragment,
  Scalars,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { ReleaseActionsProvider } from "../../../release/providers/ReleaseActionsProvider";
import { ReleaseItemsZoneProvider } from "../../../release/providers/ReleaseItemsZonesProvider";
import { ExpandedReleaseItem } from "../../../release/providers/ReleaseProvider";
import { ReleaseUpdateProvider } from "../../../release/providers/ReleaseUpdateProvider";
import { ReleaseItemList } from "../../../release/release-items-list/ReleaseItemList";
import {
  BuyoutReleaseDetailsProvider,
  useBuyoutReleaseDetails,
} from "../../providers/BuyoutReleaseDetailsProvider";
import { useBuyoutReleases } from "../../providers/BuyoutReleasesProvider";
import { useReleaseInfoViewConfiguration } from "./release-view/ReleaseInfoViewConfiguration";

type Props = {
  releaseId: Scalars["ID"]["output"];
};

const ListContainer = tw.div`pl-5`;
const LoaderStyled = tw(Loader)`
  pb-8
`;

export const mapReleaseItems = (
  release: BuyoutReleaseDetailsFieldsFragment | null,
) =>
  release?.items.map((item) => {
    const estimatedItems =
      item.projectItem?.estimatedItems.filter(
        (ei) => ei.zone?.id === item.zone?.id,
      ) ?? [];
    return {
      ...{
        ...item.projectItem,
        estimatedItems: estimatedItems.map((ei) => ({
          ...ei,
          releasedQuantity: "",
        })),
      },
      receivedQuantityDecimal: "0",
      buyoutItems: [],
      ...item,
      buyoutItem: {
        ...item.buyoutItem,
        releaseItems: [],
        isIncluded: true,
      },
      isIncluded: false,
      priceEstimated: false,
      pricePrenegotiated: false,
      invoicedQuantity: "",
      invoiceItems: [],
      quantityDecimal: item.quantityDecimal,
      issues: [],
    } as ExpandedReleaseItem;
  }) ?? [];

const ReleaseInfoViewDetailsWithProvider = () => {
  const { buyout } = useBuyoutReleases();
  const { release, loading } = useBuyoutReleaseDetails();
  const configuration = useReleaseInfoViewConfiguration(
    release,
    buyout?.project.id,
  );

  const items = useMemo(() => mapReleaseItems(release), [release]);

  if (loading) {
    return <LoaderStyled loading />;
  }

  if (!buyout || !release) {
    return null;
  }

  return (
    <ReleaseItemsZoneProvider items={items} usePhaseCodes>
      <ReleaseActionsProvider>
        <ReleaseUpdateProvider
          release={release}
          project={buyout.project}
          items={items}
        >
          <ListContainer>
            <ReleaseItemList
              columns={configuration}
              classNames={{
                header: "!hidden",
                category: "top-[88px] lg:top-[102px]",
                subCategory: (groupedByZones: boolean) =>
                  groupedByZones
                    ? "top-[130px] lg:top-[144px]"
                    : "top-[88px] lg:top-[102px]",
              }}
            />
          </ListContainer>
        </ReleaseUpdateProvider>
      </ReleaseActionsProvider>
    </ReleaseItemsZoneProvider>
  );
};

export const ReleaseInfoViewDetails: FC<Props> = ({ releaseId }) => {
  return (
    <BuyoutReleaseDetailsProvider id={releaseId}>
      <ReleaseInfoViewDetailsWithProvider />
    </BuyoutReleaseDetailsProvider>
  );
};
