import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { FormattedMessage, useIntl } from "react-intl";

import { NewOrgPanel } from "./NewOrgPanel";

export const NewOrgButton = () => {
  const intl = useIntl();

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <PrimaryButton wide onClick={() => togglePanel(true)}>
          <FormattedMessage id="ORG_NEW" />
        </PrimaryButton>
      )}
      content={(togglePanel) => (
        <NewOrgPanel
          label={intl.$t({ id: "ORG_NEW_TITLE" })}
          onClose={() => togglePanel(false)}
          useViewerEnterprise={false}
        />
      )}
    />
  );
};
