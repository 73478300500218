import {
  AdditionalFiltersOverlay,
  Option,
} from "@/common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { OrderTypesFilter } from "@/common/components/order-types-filter/OrderTypesFilter";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { QueryInvoicesFilter } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useInvoices } from "../../providers/InvoicesProvider";

enum InvoicesAdditionalFilter {
  PoExported = "PO_EXPORTED",
  ReceiptPosted = "RECEIPT_POSTED",
  InvoiceExported = "INVOICE_EXPORTED",
  ClosedProjects = "CLOSED_PROJECTS",
  Deleted = "DELETED",
}

export const InvoicesAdditionalFilters = () => {
  const { filter, setFilter } = useInvoices();
  const intl = useIntl();
  const { includePoNumbering } = usePoNumberingSettingsCheck();

  const options = useMemo<Option<QueryInvoicesFilter>[]>(() => {
    return [
      ...(includePoNumbering
        ? [
            {
              id: InvoicesAdditionalFilter.PoExported,
              title: intl.$t({ id: "PO_EXPORTED" }),
              filter: (value: boolean | null) => ({
                poExported: value,
              }),
              value: (currentFilter: QueryInvoicesFilter | undefined) =>
                currentFilter?.poExported,
            },
          ]
        : []),
      {
        id: InvoicesAdditionalFilter.ReceiptPosted,
        title: intl.$t({ id: "RECEIPT_POSTED" }),
        filter: (value) => ({
          receiptPosted: value,
        }),
        value: (currentFilter) => currentFilter?.receiptPosted,
      },
      {
        id: InvoicesAdditionalFilter.InvoiceExported,
        title: intl.$t({ id: "INVOICE_EXPORTED_FILTER" }),
        filter: (value) => ({
          exported: value,
        }),
        value: (currentFilter) => currentFilter?.exported,
      },
      {
        id: InvoicesAdditionalFilter.ClosedProjects,
        title: intl.$t({ id: "INVOICES_FROM_CLOSED_PROJECTS" }),
        filter: (value) => ({
          closedProjects: value,
        }),
        value: (currentFilter) => currentFilter?.closedProjects,
      },
      {
        id: InvoicesAdditionalFilter.Deleted,
        title: intl.$t({ id: "DELETED" }),
        filter: (value) => ({
          archived: value,
        }),
        value: (currentFilter) => currentFilter?.archived,
      },
    ];
  }, [includePoNumbering, intl]);

  const setFilterHandler = useCallback(
    (values: string[] | undefined) => {
      setFilter({
        ...filter,
        releaseTypeIds: values,
      });
    },
    [filter, setFilter],
  );

  return (
    <AdditionalFiltersOverlay
      filter={filter}
      options={options}
      defaultFilter={{
        archived: false,
        releaseTypeIds: undefined,
        poExported: null,
        receiptPosted: null,
        exported: false,
      }}
      setFilter={setFilter}
      customFilters={
        <>
          <AdditionalFiltersOverlay.Name>
            <FormattedMessage id="INVOICE_KICKBACK" />
          </AdditionalFiltersOverlay.Name>
          <AdditionalFiltersOverlay.Configurations>
            <AdditionalFiltersOverlay.Configuration
              $active={AdditionalFiltersOverlay.isYes(filter?.kickbackToMe)}
              onClick={() => setFilter({ ...filter, kickbackToMe: true })}
            >
              <FormattedMessage id="ASSIGNED_TO_ME" />
            </AdditionalFiltersOverlay.Configuration>
            <AdditionalFiltersOverlay.Configuration
              $active={
                AdditionalFiltersOverlay.isNo(filter?.kickbackToMe) ||
                AdditionalFiltersOverlay.isAll(filter?.kickbackToMe)
              }
              onClick={() => setFilter({ ...filter, kickbackToMe: undefined })}
            >
              <FormattedMessage id="ALL" />
            </AdditionalFiltersOverlay.Configuration>
          </AdditionalFiltersOverlay.Configurations>
        </>
      }
      customBottomFilters={
        <OrderTypesFilter
          selectedOrderTypes={filter?.releaseTypeIds}
          onChange={setFilterHandler}
        />
      }
    />
  );
};
