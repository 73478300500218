import { ArrowDropDown } from "@mui/icons-material";
import { ReactNode } from "react";
import { Loader } from "../../../loader/Loader";

type Props = {
  popup?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
};

export const PopupIcon = ({ popup, loading, disabled }: Props) => {
  return loading ? (
    <Loader loading small />
  ) : disabled ? (
    ""
  ) : (
    popup || <ArrowDropDown className="print:hidden" />
  );
};
