import { AdminHeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { AddWarehouseButton } from "../add-panel/AddWarehouseButton";

export const WarehouseHeader = () => {
  return (
    <AdminHeaderContainer>
      <AddWarehouseButton />
    </AdminHeaderContainer>
  );
};
