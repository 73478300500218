import { HeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import tw from "tailwind-styled-components";

const FlexContainer = tw.div`
  flex flex-col h-fit
`;

export const BudgetContainer = tw(FlexContainer)`
  justify-between items-center text-sm
`;

export const Filters = tw.div`
  grid grid-flow-col gap-2 items-center
`;

export const HeaderContainerStyled = tw(HeaderContainer)`justify-between`;

export const LinkLikeStyled = tw(LinkLike)`font-medium text-base`;
