import {
  ProjectListOptionFragmentFragment,
  ProjectStatus,
  QueryProjectsFilter,
  TagFieldsFragment,
  useProjectListOptionsQuery,
} from "@/generated/graphql";
import { useCallback, useMemo, useState } from "react";
import { MAX_ITEMS_PER_PAGE } from "../../../../../common/const";

type ProjectListProps = {
  includeTags?: boolean;
  includeClosedProjects?: boolean | null;
};

export type ProjectListOptionWithTags = ProjectListOptionFragmentFragment & {
  tags: TagFieldsFragment[];
};

export const useProjectListOptions = (props?: ProjectListProps) => {
  const [filter, setFilter] = useState<QueryProjectsFilter>({ deleted: false });
  const { data, loading } = useProjectListOptionsQuery({
    variables: {
      first: MAX_ITEMS_PER_PAGE,
      filter,
      includeTags: props?.includeTags ?? false,
    },
  });

  const getActiveProjects = useCallback(
    (orgLocationID: string) => {
      if (orgLocationID !== filter?.orgLocationID) {
        setFilter({
          orgLocationID,
          statuses: [ProjectStatus.Awarded, ProjectStatus.Active],
          deleted: false,
        });
      }
    },
    [filter?.orgLocationID],
  );

  const projects = useMemo(
    () =>
      data?.projects.edges
        .flatMap((e) => e.node)
        .filter((project) =>
          props === undefined ||
          !("includeClosedProjects" in props) ||
          props?.includeClosedProjects === false
            ? ![ProjectStatus.Completed, ProjectStatus.Lost].includes(
                project.status,
              )
            : props.includeClosedProjects === true
              ? ![ProjectStatus.Active, ProjectStatus.Awarded].includes(
                  project.status,
                )
              : true,
        )
        .sort((projectA, projectB) =>
          projectA.name.localeCompare(projectB.name),
        ) ?? [],
    [data?.projects.edges, props],
  );

  return useMemo(
    () => ({
      projects,
      getActiveProjects,
      loading,
    }),
    [projects, getActiveProjects, loading],
  );
};
