import {
  RfqFieldsFragment,
  RfqsRfqFieldsFragment,
  RfqStatus,
} from "@/generated/graphql";

export const isRfqDraft = (
  rfq: RfqFieldsFragment | RfqsRfqFieldsFragment | null | undefined,
) => {
  if (!rfq) {
    return false;
  }

  return rfq.status === RfqStatus.Draft && !rfq.deletedAt;
};
