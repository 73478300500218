export enum InvoiceFooterState {
  DEFAULT = "DEFAULT",
  KICKBACK = "KICKBACK",
  REPORT_ISSUE = "REPORT_ISSUE",
  MATCH_ORDER = "MATCH_ORDER",
  CREATE_ORDER = "CREATE_ORDER",
  EDIT_INVOICE_COVERAGES = "EDIT_INVOICE_COVERAGES",
  EDIT_ORDER = "EDIT_ORDER",
  IMPORT_ORDER = "IMPORT_ORDER",
}
