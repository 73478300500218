import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { CheckCircleOutline, Close } from "@mui/icons-material";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useAgaveHostedWizard } from "../../AgaveHostedWizardProvider";
import { AGAVE_IFRAME_HEIGHT, AGAVE_IFRAME_WIDTH } from "./AgaveIframe";

const View = tw.div`bg-white rounded-xl`;
const TopRow = tw.div`flex flex-row justify-end`;
const CloseButton = tw.button`flex w-10 h-10 items-center justify-center `;
const Content = tw.div`flex h-full flex-col items-center justify-center pb-10 font-bold text-lg`;

export const AgaveIframeSuccess = () => {
  const intl = useIntl();
  const { moveToNextStep } = useNestedStepper();
  const { sourceSystem } = useAgaveHostedWizard();
  return (
    <View style={{ width: AGAVE_IFRAME_WIDTH, height: AGAVE_IFRAME_HEIGHT }}>
      <TopRow>
        <CloseButton onClick={moveToNextStep}>
          <Close style={{ width: 18, height: 18 }} className="text-gray-600" />
        </CloseButton>
      </TopRow>
      <Content>
        <CheckCircleOutline
          style={{ width: 120, height: 120 }}
          className="mb-6 text-blue-500"
        />
        {intl.$t(
          { id: "LOGGED_IN_TO" },
          {
            sourceSystem: intl.$t({
              id: `INTEGRATION_${sourceSystem}`,
            }),
          },
        )}
      </Content>
    </View>
  );
};
