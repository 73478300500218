import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { EstimatedItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import tw from "tailwind-styled-components";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";

const Container = tw.div`
  flex mr-1 w-5
`;

export const ProjectItemCheckbox: FC<{
  items: EstimatedItemFieldsFragment[];
}> = ({ items }) => {
  const { setSelectedEstimatedItemIds, selectedEstimatedItemIds } =
    useEstimatedItems();

  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedEstimatedItemIds(
        newChecked
          ? [...selectedEstimatedItemIds, ...items.map((i) => i.id)]
          : selectedEstimatedItemIds.filter(
              (r) => !items.map((i) => i.id).includes(r),
            ),
      );
    },
    [items, selectedEstimatedItemIds, setSelectedEstimatedItemIds],
  );

  return (
    <Container>
      <SelectionCheckbox
        items={selectedEstimatedItemIds}
        setSelection={handleChange}
        checked={items.every((i) => selectedEstimatedItemIds.includes(i.id))}
      />
    </Container>
  );
};
