import { Alerts } from "@/common/components/alerts/Alerts";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { generateUUID } from "@/common/utils/uuidUtils";
import { NoFunction } from "@/types/NoFunction";
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";

export enum Severity {
  Error = "error",
  Warning = "warning",
  Success = "success",
  SystemAlert = "system",
}

export type Message = {
  id: string;
  text: string | React.ReactNode;
  type: Severity;
  timestamp?: number;
};

type ProviderContextType = {
  setSuccessAlert: (text: string | React.ReactNode) => void;
  setWarningAlert: (text: string | React.ReactNode) => void;
  setSystemAlert: (text: string | React.ReactNode) => void;
  removeMessage: (id: string) => void;
  removeMessagesByType: (type: Severity) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  setSuccessAlert: NoFunction,
  setWarningAlert: NoFunction,
  setSystemAlert: NoFunction,
  removeMessage: NoFunction,
  removeMessagesByType: NoFunction,
});

export const SnackbarProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const { errors, removeError } = useGlobalError();
  const intl = useIntl();

  const mapErrorMessage = useCallback(
    (message: string) => {
      if (message === "Failed to fetch") {
        return intl.$t({ id: "NETWORK_ERROR" });
      }
      return message;
    },
    [intl],
  );

  const setMessage = useCallback(
    (message: Message) => {
      setMessages((oldMessages) => [
        ...oldMessages,
        { ...message, timestamp: Date.now() },
      ]);
    },
    [setMessages],
  );
  const setSuccessAlert = useCallback(
    (text: string | React.ReactNode) =>
      setMessage({
        text,
        id: generateUUID(),
        type: Severity.Success,
      }),
    [setMessage],
  );
  const setWarningAlert = useCallback(
    (text: string | React.ReactNode) =>
      setMessage({ text, id: generateUUID(), type: Severity.Warning }),
    [setMessage],
  );

  const setSystemAlert = useCallback(
    (text: string | React.ReactNode) =>
      setMessage({ text, id: generateUUID(), type: Severity.SystemAlert }),
    [setMessage],
  );

  const removeMessage = (id: string) => {
    if (messages.find((message) => message.id === id)) {
      setMessages((oldMessages) => [
        ...oldMessages.filter((item) => item.id !== id),
      ]);
    } else {
      const error = errors.find((error) => error.id === id);
      if (error) {
        removeError(error);
      }
    }
  };

  const removeMessagesByType = (type: Severity) => {
    setMessages((oldMessages) => [
      ...oldMessages.filter((item) => item.type !== type),
    ]);
  };

  const mergedMessages = useMemo(() => {
    const mappedErrors = errors.map((err) => {
      return {
        text: mapErrorMessage(err.message),
        timestamp: err.timestamp,
        type: Severity.Error,
        id: err.id?.toString() || generateUUID(),
      };
    });
    return messages
      .concat(mappedErrors)
      .sort((a, b) => (a.timestamp || 0) - (b.timestamp || 0));
  }, [errors, mapErrorMessage, messages]);

  return (
    <ProviderContext.Provider
      value={{
        setWarningAlert,
        setSuccessAlert,
        removeMessage,
        removeMessagesByType,
        setSystemAlert,
      }}
    >
      <Alerts messages={mergedMessages} />
      {children}
    </ProviderContext.Provider>
  );
};

export const useSnackbar = () => useContext(ProviderContext);
