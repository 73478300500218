import { FC } from "react";
import tw from "tailwind-styled-components";
import { BaseButton } from "./BaseButton";

type OutlinedButtonProps = React.ComponentProps<typeof BaseButton> & {
  $small?: boolean;
};

const OutlinedButtonStyled = tw(BaseButton)`
  min-w-24 border bg-white border-solid border-blue-800 py-1.5 text-blue-800 hover:border-blue-800 hover:text-blue-800 hover:bg-blue-100 hover:cursor-pointer
  group-[.is-row]:min-h-8 group-[.is-row]:text-sm disabled:bg-white disabled:opacity-50 disabled:text-blue-800
  ${({ disabled }: OutlinedButtonProps) =>
    disabled && "text-white border-inherit"}
  ${({ $small }: OutlinedButtonProps) =>
    $small && "text-xs mb-2 my-0 mx-0 px-2 min-h-6 2xl:h-6 2xl:min-w-16"}
`;

export const OutlinedButton: FC<OutlinedButtonProps> = ({
  children,
  ...props
}) => {
  return <OutlinedButtonStyled {...props}>{children}</OutlinedButtonStyled>;
};
