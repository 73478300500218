import { If } from "@/common/components/if/If";
import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Item = tw.div``;
const ErrorIcon = tw(ErrorOutlineOutlined)`text-red-500`;
const SuccessIcon = tw(CheckCircleOutlineOutlined)`text-green-600`;
const OptionalIcon = tw(InfoOutlined)`text-gray-500`;

type Props = {
  success: boolean;
  optional?: boolean;
};

export const ProjectImportInputStatus: FC<Props> = ({ success, optional }) => {
  return (
    <Item>
      <If isTrue={success && !optional}>
        <SuccessIcon />
      </If>
      <If isTrue={!success && !optional}>
        <ErrorIcon />
      </If>
      <If isTrue={optional}>
        <OptionalIcon />
      </If>
    </Item>
  );
};
