import { ORDER_INVOICED_INVOICES_STATUS } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { goToExternalUrls } from "@/common/utils/browserUtils";
import {
  ExportReleasesInput,
  ReleaseDocument,
  ReleasesDocument,
  UpdateProjectInput,
  ViewerDocument,
  useExportReleasesMutation,
  useUpdateProjectMutation,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, PropsWithChildren, createContext, useContext } from "react";

type ProviderContextType = {
  exportReleases: (input: ExportReleasesInput) => Promise<boolean>;
  updateProject: (projectInput: UpdateProjectInput) => Promise<boolean>;
};

const ProviderContext = createContext<ProviderContextType>({
  exportReleases: NoFunctionBooleanPromise,
  updateProject: NoFunctionBooleanPromise,
});

export const ExportReleaseProvider: FC<PropsWithChildren> = ({ children }) => {
  const { setError } = useGlobalError();
  const [exportReleasesMutation] = useExportReleasesMutation();
  const [updateProjectMutation] = useUpdateProjectMutation();

  const exportReleases = async (input: ExportReleasesInput) => {
    try {
      const { data, errors } = await exportReleasesMutation({
        variables: {
          input,
        },
        refetchQueries: [
          ...(input.releases.length === 1
            ? [
                {
                  query: ReleaseDocument,
                  variables: {
                    id: input.releases[0].releaseID,
                    invoiceStatuses: ORDER_INVOICED_INVOICES_STATUS,
                  },
                },
              ]
            : []),
          { query: ReleasesDocument },
          { query: ViewerDocument },
        ],
      });
      setError(errors);

      const urls = data?.exportReleases.fileURLs;
      if (urls) {
        await goToExternalUrls(urls);
      }
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const updateProject = async (projectInput: UpdateProjectInput) => {
    try {
      const { errors } = await updateProjectMutation({
        variables: {
          input: projectInput,
        },
      });
      setError(errors);
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        exportReleases,
        updateProject,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useExportRelease = (): ProviderContextType =>
  useContext(ProviderContext);
