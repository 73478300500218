import { PredictedTaxInput, usePredictedTaxQuery } from "@/generated/graphql";
import { useState } from "react";

type ReturnType = {
  predictedTaxRate: string | null | undefined;
  setTaxInput: (input: PredictedTaxInput) => void;
};

export const usePredictedTax = (): ReturnType => {
  const [input, setInput] = useState<PredictedTaxInput | null>(null);
  const { data } = usePredictedTaxQuery({
    variables: {
      input: input ?? {
        projectId: "",
        sellerOrgLocationId: "",
        willCall: false,
        warehouseId: "",
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !input || !input.sellerOrgLocationId || !input.projectId,
  });

  return {
    predictedTaxRate: data?.predictedTax.taxRate,
    setTaxInput: setInput,
  };
};
