import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { DrawerView } from "@/common/components/panel/DrawerView";
import { useWarehouses } from "../../providers/WarehousesProvider";
import { WarehouseForm } from "../form/WarehouseForm";

export const EditWarehousePanelId = "edit-warehouse-panel";

export const ToggleEditWarehouse = () => {
  const { isOpened, toggle, getObjectId } = useGlobalDrawer();
  const { warehouses } = useWarehouses();

  return (
    <DrawerView
      isOpened={isOpened(EditWarehousePanelId)}
      content={(togglePanel) => (
        <WarehouseForm
          onClose={() => togglePanel(false)}
          warehouse={warehouses.find(
            (v) => v.id === getObjectId(EditWarehousePanelId),
          )}
        />
      )}
      setIsOpened={(value) => {
        toggle(EditWarehousePanelId, value);
      }}
    />
  );
};
