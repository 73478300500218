import { GridTable } from "@/common/components/grid-table/GridTable";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { LocalListRenderer } from "@/common/components/list-renderer-local/LocalListRenderer";
import {
  LocalPaginationProvider,
  SortableColumn,
  useLocalPagination,
} from "@/common/components/pagination-local/LocalPaginationProvider";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { Identity } from "@/types/Identity";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { TitledDebouncedRowSearchBar } from "./components/TitledDebouncedRowSearchBar";
import { itemFn, onItemClick } from "./utils";

const Container = tw.div``;

type SingleSelectionProps<T> = {
  type: "single";
  selectedItemId: string | null;
  setSelectedItemId: (id: string | null) => void;
  setSelectedItem?: (item: T | null) => void;
};

type MultipleSelectionProps = {
  type: "multiple";
  selectedItemIds: string[];
  setSelectedItemIds: (ids: string[]) => void;
};

type Props<T extends Identity> = {
  tableConfiguration: GridCol<T, undefined>[];
  searchBarTitle: string | React.ReactNode;
  searchBarClassName?: string;
  searchInputPlaceholder?: string;
  items: T[];
  loadingItems: boolean;
  itemSelection?: SingleSelectionProps<T> | MultipleSelectionProps;
  searchFilterFn?: (item: T, searchText: string) => boolean;
  className?: string;
};

const LocalSearchableListWithProviders = <T extends Identity>({
  tableConfiguration,
  searchBarTitle,
  searchBarClassName,
  searchInputPlaceholder,
  items,
  loadingItems,
  itemSelection,
  searchFilterFn,
  className,
}: Props<T>) => {
  const intl = useIntl();
  const { getPaginatedItems, setSortableColumn } = useLocalPagination();

  const [searchText, setSearchText] = useState("");
  const filteredItems = useMemo(
    () =>
      searchText && !!searchFilterFn
        ? items.filter((i) => searchFilterFn(i, searchText.toLowerCase()))
        : items,
    [items, searchText, searchFilterFn],
  );

  const filteredAndPaginatedItems = useMemo(
    () => getPaginatedItems(filteredItems),
    [filteredItems, getPaginatedItems],
  );

  const onChange = useCallback(
    (text: string) => {
      setSearchText(text);
    },
    [setSearchText],
  );

  return (
    <Container className={className}>
      <LocalListRenderer
        totalCount={filteredItems.length}
        count={filteredAndPaginatedItems.length}
        paginated
      >
        <TitledDebouncedRowSearchBar
          title={searchBarTitle}
          placeholder={searchInputPlaceholder ?? intl.$t({ id: "SEARCH" })}
          onChange={onChange}
          value={searchText}
          hideSearchInput={!searchFilterFn}
          containerClassName={searchBarClassName}
        />
        <GridTable<T>
          configuration={{
            container: Container,
            columns: tableConfiguration,
            classNames: {
              itemFn: (item) => itemFn(item, itemSelection),
            },
            toggle: {
              item: (item) => onItemClick(item, itemSelection),
            },
          }}
          items={filteredAndPaginatedItems}
          loading={loadingItems}
          error={false}
          onSort={(column, direction) =>
            setSortableColumn({
              direction,
              sortFunction: column.sortItemFn as SortableColumn["sortFunction"],
            })
          }
          useLocalPagination
        />
      </LocalListRenderer>
    </Container>
  );
};

export const LocalSearchableList = <T extends Identity>(props: Props<T>) => (
  <LocalPaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
    <LocalSearchableListWithProviders {...props} />
  </LocalPaginationProvider>
);
