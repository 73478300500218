import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { DateView } from "@/common/utils/dates/DateView";
import { routes } from "@/config/routes";
import { useInvoiceValidation } from "@/contractor/pages/home/invoices/pages/scanned-invoices/providers/InvoiceValidationProvider";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { PoFormat } from "@/generated/graphql";
import { Refresh } from "@mui/icons-material";
import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useConnectMissingPOItems } from "../../../../../hooks/useConnectMissingPOItems";
import { useInvoiceImportExternalPO } from "../../../../../providers/InvoiceImportExternalPoProvider";
import { useInvoiceVerification } from "../../../../../providers/InvoiceVerificationProvider";

const LinkLikeStyled = tw(LinkLike)`flex items-center gap-1 text-blue-500`;
const Container = tw.div`flex gap-1 mb-5`;
const PoNumberContainer = tw.div`grid grid-flow-col items-center gap-1`;
const InnerContainer = tw.div`flex flex-col gap-0.5`;
const ClearButtonText = tw.div`text-xs font-normal text-blue-800`;
const DetailsGroup = tw.div`flex flex-row gap-1 text-xs`;
const Label = tw.div`font-medium`;
const Name = tw.div``;
const PoContainer = tw.div`flex flex-row items-center gap-2`;
const IconButtonBorderlessStyled = tw(
  IconButtonBorderless,
)`h-4 min-h-4 w-4 py-0`;

export const InvoiceOrderInputSelector: FC<PropsWithChildren> = ({
  children,
}) => {
  const intl = useIntl();
  const { invoice, setForceFetchExternalPO, updateInvoice } =
    useInvoiceVerification();
  const { refetchInvoiceValidation } = useInvoiceValidation();
  const { openModal } = useInvoiceImportExternalPO();
  const { connectMissingPOItems } = useConnectMissingPOItems();
  const { release } = useRelease();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const releasePath = useMemo(() => {
    if (invoice?.release?.id) {
      return generatePath(routes.delivery, {
        deliveryId: invoice?.release?.id,
      });
    }
  }, [invoice?.release?.id]);

  const onPOClick = useCallback(() => {
    setForceFetchExternalPO(true);
    openModal({
      skipPoList: true,
      hasMissingPoLink: !invoice?.release?.poLink,
    });
  }, [setForceFetchExternalPO, openModal, invoice?.release?.poLink]);

  const clearInvoiceRelease = useCallback(async () => {
    if (invoice) {
      await updateInvoice({ clearRelease: true, id: invoice.id });
    }
  }, [invoice, updateInvoice]);

  const value = useMemo(() => {
    if (invoice?.release) {
      return (
        <>
          {intl.$t(
            { id: "ORDER_WITH_NUMBER" },
            { orderNumber: invoice?.release?.sequenceNumber },
          )}
        </>
      );
    }
    return null;
  }, [invoice, intl]);

  const includeRefreshButton = useMemo(
    () =>
      release?.type.poFormat === PoFormat.Detail &&
      release.items.some((i) => !i.poItemLink),
    [release],
  );

  const refresh = useCallback(async () => {
    await connectMissingPOItems(invoice?.release?.poLink?.id, invoice?.id);
    refetchInvoiceValidation();
  }, [
    connectMissingPOItems,
    invoice?.release?.poLink?.id,
    invoice?.id,
    refetchInvoiceValidation,
  ]);

  return (
    <Container>
      <If isTrue={value}>
        <InnerContainer>
          <PoNumberContainer>
            <LinkLikeStyled to={releasePath} disabled={!releasePath}>
              {value}
            </LinkLikeStyled>
            <ReleaseStatusChip
              status={invoice?.release?.status}
              type="small"
              releaseType={invoice?.release?.type}
            />
            {children}
            <If isTrue={!!invoice?.release}>
              <OutlinedButton
                $small
                className="h-6 min-w-16"
                onClick={clearInvoiceRelease}
              >
                <FormattedMessage id="CLEAR" tagName={ClearButtonText} />
              </OutlinedButton>
            </If>
          </PoNumberContainer>
          <DetailsGroup>
            <Label>
              <FormattedMessage id="DELIVERY" />:
            </Label>
            <Name>
              <DateView date={invoice?.release?.time} />
            </Name>
          </DetailsGroup>
          <DetailsGroup>
            <Label>
              <FormattedMessage id="ORDER_TYPE" />:
            </Label>
            <Name>{invoice?.release?.type.name}</Name>
          </DetailsGroup>
          <If isTrue={invoice?.release?.poNumber}>
            <PoContainer>
              <LinkLikeStyled onClick={onPOClick}>
                <FormattedMessage id="PO_HASH" />
                {invoice?.release?.poNumber}
              </LinkLikeStyled>
              <If
                isTrue={
                  includeRefreshButton &&
                  !hasFeatureInConnectedSourceSystem(
                    IntegrationFeature.POAlwaysMutable,
                  )
                }
              >
                <IconButtonBorderlessStyled onClick={refresh}>
                  <Refresh />
                </IconButtonBorderlessStyled>
              </If>
            </PoContainer>
          </If>
        </InnerContainer>
      </If>
    </Container>
  );
};
