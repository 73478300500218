import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`font-thin text-xs`;

export const ReleaseType: FC<{ isDelivery: boolean }> = ({ isDelivery }) => {
  return (
    <Container>
      <FormattedMessage id={isDelivery ? "BY_VENDOR" : "CLIENT_DELIVERY"} />
    </Container>
  );
};
