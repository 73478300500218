import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  NoteDocumentFieldsFragment,
  useNoteDocumentQuery,
} from "@/generated/graphql";
import { ApolloError } from "@apollo/client";

type ReturnType = {
  noteDocument?: NoteDocumentFieldsFragment;
  loading: boolean;
  error?: ApolloError;
};

export const useGetNoteDocument = (id?: string): ReturnType => {
  const { setError } = useGlobalError();

  const { data, loading, error } = useNoteDocumentQuery({
    variables: {
      id: id || "",
    },
    skip: !id,
    fetchPolicy: "cache-and-network",
    onError(error) {
      setError(error);
    },
  });

  return {
    noteDocument: data?.noteDocument,
    loading,
    error,
  };
};
