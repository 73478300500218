import { If } from "@/common/components/if/If";
import { FC, ReactNode, useMemo } from "react";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../providers/DistributorQuoteItemsProvider";
import { isItemIncluded } from "../utils/isItemIncluded";

type WithdrawalWrapperProps = {
  item: DistributorQuoteItemGroupExtendedFieldsFragment;
  children: ReactNode;
};

export const WithdrawalWrapper: FC<WithdrawalWrapperProps> = ({
  item,
  children,
}) => {
  const condition = useMemo(() => isItemIncluded(item), [item]);

  return <If isTrue={condition}>{children}</If>;
};
