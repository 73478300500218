import { ExpandMore } from "@mui/icons-material";
import { Menu } from "@szhsin/react-menu";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { Category } from "./CategoriesProvider";
import { CategoryItem } from "./CategoryItem";
import { Button } from "@mui/material";
import { ProductCategory } from "@/generated/graphql";
import { FormattedMessage, useIntl } from "react-intl";
import { getCategoryFullName } from "./utils";

const CategoryListFilterContainer = tw.div`
  flex flex-1
`;

const ButtonStyled = tw(Button)`
  capitalize text-black text-left
`;

const CategoryName = tw.div`
  w-full
`;

type CategoryFilterProps = {
  categoryId: string | null;
  categories: ProductCategory[];
  onCategorySelect: (id: string) => void;
  categoryLevels: Category[];
  includeAllCategories?: boolean;
};

export const CategoryFilter: FC<CategoryFilterProps> = ({
  categoryId,
  categories,
  onCategorySelect,
  categoryLevels,
  includeAllCategories = false,
}) => {
  const intl = useIntl();
  const memoizedCategories = useMemo(() => {
    return categoryId && includeAllCategories
      ? [
          { label: intl.$t({ id: "ALL_CATEGORIES" }), children: [] },
          ...categoryLevels,
        ]
      : categoryLevels;
  }, [categoryLevels, categoryId, intl, includeAllCategories]);
  const selectedCategory = categories.find(
    (category) => category.id === categoryId,
  );

  const categoryName = useMemo(
    () => getCategoryFullName(selectedCategory),
    [selectedCategory],
  );

  return (
    <CategoryListFilterContainer>
      <Menu
        portal
        menuButton={
          <ButtonStyled aria-controls="simple-menu" aria-haspopup="true">
            <CategoryName>
              {selectedCategory ? (
                <>{categoryName}</>
              ) : (
                <FormattedMessage id={"SELECT_CATEGORY"} />
              )}
            </CategoryName>
            <ExpandMore />
          </ButtonStyled>
        }
      >
        {memoizedCategories.map((category) => (
          <CategoryItem
            category={category}
            onCategorySelect={onCategorySelect}
            key={category.label}
            selectedId={selectedCategory?.id}
          />
        ))}
      </Menu>
    </CategoryListFilterContainer>
  );
};
