import {
  AdditionalFiltersOverlay,
  Option,
} from "@/common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { QueryInvoiceEmailsFilter } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useInvoiceEmails } from "../../hooks/useInvoiceEmails";

enum AdditionalFilter {
  Deleted = "DELETED",
}

export const InvoiceEmailsAdditionalFilters = () => {
  const { filter, setFilter } = useInvoiceEmails();
  const intl = useIntl();

  const options = useMemo<Option<QueryInvoiceEmailsFilter>[]>(() => {
    return [
      {
        id: AdditionalFilter.Deleted,
        title: intl.$t({ id: "DELETED" }),
        filter: (value) => ({
          archived: value,
        }),
        value: (currentFilter) => currentFilter?.archived,
      },
    ];
  }, [intl]);

  return (
    <AdditionalFiltersOverlay
      filter={filter}
      defaultFilter={{
        archived: false,
      }}
      options={options}
      setFilter={setFilter}
    />
  );
};
