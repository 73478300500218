import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { ProjectProjectsFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Value = tw.span`text-sm`;

type Props = {
  project: ProjectProjectsFieldsFragment;
};

export const DisplayProjectMaterialCount: FC<Props> = ({ project }) => {
  return (
    <NotNullableRenderer value={project.materialCount}>
      <Value>{project.materialCount}</Value>
    </NotNullableRenderer>
  );
};
