import { If } from "@/common/components/if/If";
import { FC } from "react";
import tw from "tailwind-styled-components";
import {
  PackingSlipMatchViewState,
  useDeliverySlipVerification,
} from "../../../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipRelease } from "../../components/delivery-slip-edit-release/DeliverySlipRelease";
import { DeliverySlipOrderSelectorView } from "../../components/delivery-slip-order-selector/DeliverySlipOrderSelectorView";
import { DeliverySlipSelectModeHeader } from "../../components/delivery-slip-select-mode-header/DeliverySlipSelectModeHeader";
import { NoReleaseForDeliverySlipInfo } from "./NoReleaseForDeliverySlipInfo";

const Container = tw.div`bg-gray-100 rounded-3xl pt-6 overflow-y-scroll h-full`;
const InnerContainer = tw.div`flex flex-col h-full`;

export const DeliverySlipMatchOrderDetails: FC = () => {
  const { packingSlipMatchViewState } = useDeliverySlipVerification();
  return (
    <Container>
      <If
        isTrue={packingSlipMatchViewState === PackingSlipMatchViewState.DEFAULT}
      >
        <NoReleaseForDeliverySlipInfo />
      </If>
      <If
        isTrue={packingSlipMatchViewState !== PackingSlipMatchViewState.DEFAULT}
      >
        <DeliverySlipSelectModeHeader />
        <If
          isTrue={
            packingSlipMatchViewState === PackingSlipMatchViewState.MATCH_ORDER
          }
        >
          <InnerContainer>
            <DeliverySlipOrderSelectorView />
          </InnerContainer>
        </If>
        <If
          isTrue={
            packingSlipMatchViewState === PackingSlipMatchViewState.CREATE_ORDER
          }
        >
          <InnerContainer>
            <DeliverySlipRelease />
          </InnerContainer>
        </If>
      </If>
    </Container>
  );
};
