import { If } from "@/common/components/if/If";
import { ProjectHeaderContent } from "@/contractor/pages/home/project/Project.styles";
import { GroupByZonesSwitch } from "@/contractor/pages/home/project/components/project-items-filters/GroupByZonesSwitch";
import { ItemFilterSelector } from "@/contractor/pages/home/project/components/project-items-filters/ItemFilterSelector";
import { ProjectCostCodesSelector } from "@/contractor/pages/home/project/components/project-items-filters/ProjectCostCodesSelector";
import {
  ActionItemWrapper,
  ActionsContainer,
  ActionsWrapper,
} from "@/contractor/pages/home/project/components/project-items-filters/ProjectItemsFilters.styles";
import { ProjectTagsSelector } from "@/contractor/pages/home/project/components/project-items-filters/ProjectTagsSelector";
import { ZonesSelector } from "@/contractor/pages/home/project/components/project-items-filters/ZonesSelector";
import { useProjectZoneUtils } from "@/contractor/pages/home/project/hooks/useProjectZoneUtils";
import { useProjectItemsZones } from "@/contractor/pages/home/project/providers/ProjectItemsZonesProvider";
import { ProjectExtendedFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

const HeaderContainerStyled = tw.div`mb-1`;

type Props = {
  project: ProjectExtendedFieldsFragment;
};

export const BuyoutInfoWithActions: FC<Props> = ({ project }) => {
  const { groupedByZones } = useProjectItemsZones();
  const { zoneOptions, costCodeOptions, tagOptions, includeGrouping } =
    useProjectZoneUtils(project);

  return (
    <HeaderContainerStyled>
      <ProjectHeaderContent>
        <ActionsContainer>
          <ActionsWrapper className="justify-end">
            <If isTrue={includeGrouping}>
              <ActionItemWrapper>
                <GroupByZonesSwitch />
              </ActionItemWrapper>
            </If>
            <If isTrue={groupedByZones}>
              <ActionItemWrapper>
                <ZonesSelector options={zoneOptions} />
              </ActionItemWrapper>
            </If>
            <ActionItemWrapper>
              <ProjectCostCodesSelector options={costCodeOptions} />
            </ActionItemWrapper>
            <ActionItemWrapper>
              <ProjectTagsSelector options={tagOptions} />
            </ActionItemWrapper>
            <ActionItemWrapper>
              <ItemFilterSelector />
            </ActionItemWrapper>
          </ActionsWrapper>
        </ActionsContainer>
      </ProjectHeaderContent>
    </HeaderContainerStyled>
  );
};
