import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useTabActive } from "@/common/hooks/useTabActive";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { usePoValidationMappings } from "@/contractor/pages/home/common/hooks/usePoValidationMappings";
import { LinkLikeStyled } from "@/contractor/pages/home/projects/Projects.styles";
import { MissingMaterialConversionsModal } from "@/contractor/pages/home/release/components/connections/components/connection-types/hosted/steps/missing-material-conversion/MissingMaterialConversionsModal";
import {
  PoValidationError,
  ReleaseSummaryFieldsFragment,
} from "@/generated/graphql";
import { Close, Download } from "@mui/icons-material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReverseSyncPO } from "../../../hooks/useReverseSyncPO";
import { useUnlinkPo } from "../../../hooks/useUnlinkPo";
import {
  MissingItemsFromInventoryProvider,
  useMissingItemsFromInventory,
} from "../../../providers/MissingItemsFromInventoryProvider";
import {
  MissingMaterialConversionsProvider,
  useMissingMaterialConversions,
} from "../../../providers/MissingMaterialConversionsProvider";
import {
  PoValidationProvider,
  usePoValidation,
} from "../../../providers/PoValidationProvider";
import { useReleaseConnectionOptions } from "../../../providers/ReleaseConnectionOptionsProvider";
import { PoReleaseDetails } from "../../common/PoReleaseDetails";
import { SourceSystemWrapper } from "../../common/SourceSystemWrapper";
import { MissingItemsFromInventory } from "./MissingItemsFromInventory";

const LinkLike = tw(LinkLikeStyled)`font-medium text-xs items-center flex`;
const DisconnectPoLink = tw(LinkLike)`text-red-500`;
const Divider = tw.div`border-b border-gray-300`;

type Props = {
  release: ReleaseSummaryFieldsFragment;
  editablePoNumber?: boolean;
  linking?: boolean;
  displayDisconnect: boolean;
  onClose?: () => void;
  error?: string;
};

const HostedIntegrationReleaseInformationWithProvider: FC<Props> = ({
  release,
  editablePoNumber,
  linking,
  displayDisconnect,
  onClose,
  error,
}) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { unlinkPo } = useUnlinkPo();
  const { reverseSyncPO } = useReverseSyncPO();

  const { poValidation, refetchPoValidation, loading } = usePoValidation();
  const { sourceSystem, updateRelease, updatedReleases } =
    useReleaseConnectionOptions();
  const [poNumber, setPoNumber] = useState(release?.poNumber ?? "");
  const { connectedSourceSystem } = useOrgSettings();

  const updatedRelease = useMemo(
    () => updatedReleases.find((r) => r.releaseId === release.id),
    [release.id, updatedReleases],
  );

  const { openModal: openMissingConversionsModal } =
    useMissingMaterialConversions();
  const { openModal: openMissingItemsModal } = useMissingItemsFromInventory();
  const { isTabActive } = useTabActive();

  useEffect(() => {
    if (isTabActive && !loading && connectedSourceSystem) {
      refetchPoValidation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabActive]);

  const includedValidations = usePoValidationMappings({
    type: "Release",
    item: release,
    poValidation,
    sourceSystem,
    openMissingItemsModal,
    openMissingConversionsModal,
  });

  const updatePoNumber = useCallback(
    (value: string) => {
      setPoNumber(value);
      updateRelease({
        poNumber: value,
        releaseId: release.id,
        externalVendorCode: updatedRelease?.externalVendorCode,
      });
    },
    [release.id, updateRelease, updatedRelease?.externalVendorCode],
  );

  const disconnectPo = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "DISCONNECT_PO" }),
      icon: <WarningIcon />,
      closeOnConfirm: true,
      title: intl.$t({ id: "DISCONNECT_PO" }),
      text: intl.$t({ id: "DISCONNECT_PO_DESCRIPTION" }),
      handleConfirm: async () => {
        await unlinkPo(release.poLink?.id || "", release.id);
        updatePoNumber("");
      },
    });
  }, [release.poLink, release.id, unlinkPo, updatePoNumber, openDialog, intl]);

  const reverseSyncPoConfirmation = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "REVERSE_PO" }),
      icon: <WarningIcon />,
      closeOnConfirm: true,
      title: intl.$t(
        { id: "REVERSE_PO_TITLE" },
        { integration: intl.$t({ id: `INTEGRATION_${sourceSystem}` }) },
      ),
      text: intl.$t({ id: "REVERSE_PO_DESCRIPTION" }),
      handleConfirm: async () => {
        if (await reverseSyncPO(release.poLink?.id || "")) {
          onClose?.();
          setTimeout(
            () =>
              openDialog({
                content: (
                  <SuccessModal
                    message={intl.$t({ id: "REVERSE_PO_COMPLETED" })}
                  />
                ),
                closingTimer: DIALOG_AUTO_CLOSE_TIMER,
              }),
            200,
          );
        }
      },
    });
  }, [
    openDialog,
    intl,
    sourceSystem,
    reverseSyncPO,
    release.poLink?.id,
    onClose,
  ]);

  return (
    <PoReleaseDetails
      release={release}
      poNumber={poNumber}
      updatePoNumber={updatePoNumber}
      includedValidations={includedValidations}
      editablePoNumber={editablePoNumber}
      linking={linking}
      loading={loading}
      error={error}
    >
      <If isTrue={displayDisconnect}>
        <DisconnectPoLink onClick={disconnectPo}>
          <Close fontSize="small" />
          <FormattedMessage id="DISCONNECT_PO" />
        </DisconnectPoLink>
        <SourceSystemWrapper
          allowedFeatures={[IntegrationFeature.ReversePoSync]}
        >
          <If
            isTrue={poValidation?.errors.includes(
              PoValidationError.PoImmutable,
            )}
          >
            <LinkLike onClick={reverseSyncPoConfirmation}>
              <Download fontSize="small" />
              <FormattedMessage id="REVERSE_SYNC_PO" />
            </LinkLike>
            <Divider />
          </If>
        </SourceSystemWrapper>
      </If>
      <MissingMaterialConversionsModal />
      <MissingItemsFromInventory />
    </PoReleaseDetails>
  );
};

export const HostedIntegrationReleaseInformation: FC<Props> = (props) => (
  <PoValidationProvider release={props.release}>
    <MissingMaterialConversionsProvider>
      <MissingItemsFromInventoryProvider>
        <HostedIntegrationReleaseInformationWithProvider {...props} />
      </MissingItemsFromInventoryProvider>
    </MissingMaterialConversionsProvider>
  </PoValidationProvider>
);
