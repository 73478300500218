import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CostTypesDocument,
  UpdateCostTypesInput,
  useUpdateCostTypesMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useUpdateCostTypes = () => {
  const { setError } = useGlobalError();
  const [updateCostTypesMutation] = useUpdateCostTypesMutation();

  const updateCostTypes = useCallback(
    async (input: UpdateCostTypesInput) => {
      try {
        const { errors } = await updateCostTypesMutation({
          variables: {
            input,
          },
          refetchQueries: [{ query: CostTypesDocument }],
        });
        setError(errors);
        return !errors;
      } catch (e) {
        setError(e);
        return false;
      }
    },
    [updateCostTypesMutation, setError],
  );

  return {
    updateCostTypes,
  };
};
