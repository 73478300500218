import { If } from "@/common/components/if/If";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { BuyoutsBuyoutFieldsFragment } from "@/generated/graphql";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { SourceSystemWrapper } from "../../release/components/connections/components/common/SourceSystemWrapper";

const IconContainer = tw.div`h-fit flex flex-row gap-1 flex-1 justify-end`;
const Rounded = tw.div<{
  $disabled: boolean;
  $notRequired?: boolean;
}>`
${({ $disabled }) =>
  $disabled ? "bg-white text-gray-500" : "bg-green-300 text-black"}
${({ $notRequired }) => $notRequired && "bg-gray-200 text-black"}
rounded-full p-3 text-xs border border-gray-300 w-5 h-5 flex items-center justify-center`;

export const BuyoutExportStatus = ({
  buyout,
}: {
  buyout: BuyoutsBuyoutFieldsFragment | undefined;
}) => {
  const { includePoNumbering } = usePoNumberingSettingsCheck();

  return (
    <SourceSystemWrapper anySourceSystem anyIntegrationType>
      <IconContainer>
        <If
          isTrue={
            includePoNumbering || buyout?.exportedAt || buyout?.poLink?.syncedAt
          }
        >
          <Tooltip
            id={`po-export-${buyout?.id}`}
            element={
              <Rounded
                $disabled={!buyout?.exportedAt && !buyout?.poLink?.syncedAt}
              >
                P
              </Rounded>
            }
          >
            <FormattedMessage
              id={
                buyout?.exportedAt || buyout?.poLink?.syncedAt
                  ? buyout?.poLink?.createdBy
                    ? "PO_EXPORTED_AT_BY"
                    : "PO_EXPORTED_AT"
                  : "PO_NOT_EXPORTED"
              }
              values={{
                date: (
                  <DateView
                    date={buyout?.exportedAt || buyout?.poLink?.syncedAt}
                    options={SHORT_DATE_OPTION}
                  />
                ),
                username: getUserName(buyout?.poLink?.createdBy),
              }}
            />
          </Tooltip>
        </If>
      </IconContainer>
    </SourceSystemWrapper>
  );
};
