import { CheckCircleOutline } from "@mui/icons-material";
import { FC, ReactNode } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-1 flex-col items-center justify-center font-medium text-lg px-20 py-10 text-center`;
const Check = tw(CheckCircleOutline)`mb-10 text-9xl text-blue-500`;

type Props = {
  message: string | ReactNode;
};

export const SuccessModal: FC<Props> = ({ message }) => {
  return (
    <Container>
      <Check />
      {message}
    </Container>
  );
};
