import { ExpirationPicker } from "@/distributor/common/components/expiration-picker/ExporationPicker";
import { DistributorBuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useDistributorBuyout } from "../../../providers/DistributorBuyoutProvider";

type Props = {
  item: DistributorBuyoutItemFieldsFragment;
  readonly: boolean;
};

export const BuyoutExpirationDate: FC<Props> = ({ item, readonly }) => {
  const { setExpandedItems, expandedItems, updateVendorBuyout } =
    useDistributorBuyout();

  const closeItem = useCallback(
    () => setExpandedItems(expandedItems.filter((id) => id !== item.id)),
    [expandedItems, item.id, setExpandedItems],
  );

  const handleOnSave = useCallback(
    async (expirationDate: Date | null) => {
      await updateVendorBuyout({
        updates: [
          {
            buyoutItemId: item.id,
            expirationDate: expirationDate?.getTime(),
          },
        ],
      });

      closeItem();
    },
    [closeItem, item.id, updateVendorBuyout],
  );

  return (
    <ExpirationPicker
      date={item.expirationDate}
      onChange={handleOnSave}
      readonly={readonly}
    />
  );
};
