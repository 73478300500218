import { If } from "@/common/components/if/If";
import { useSourceSystems } from "@/common/hooks/integrations/source-system-config/useSourceSystems";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { IntegrationType } from "@/generated/graphql";
import { FC, PropsWithChildren, useMemo } from "react";

type Props = PropsWithChildren<{
  allowedFeatures?: IntegrationFeature[];
  anySourceSystem?: boolean;
  allowedIntegrationTypes?: IntegrationType[];
  anyIntegrationType?: boolean;
}>;

export const SourceSystemWrapper: FC<Props> = ({
  allowedFeatures,
  allowedIntegrationTypes,
  anySourceSystem,
  anyIntegrationType,
  children,
}) => {
  const { isSourceSystem } = useSourceSystems();

  const shouldShowSetting = useMemo(() => {
    return isSourceSystem({
      allowedFeatures,
      allowedIntegrationTypes,
      anyIntegrationType,
      anySourceSystem,
    });
  }, [
    allowedFeatures,
    allowedIntegrationTypes,
    anyIntegrationType,
    anySourceSystem,
    isSourceSystem,
  ]);

  return <If isTrue={shouldShowSetting}>{children}</If>;
};
