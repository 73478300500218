import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";

type BidPriceTotalForItemProps = {
  item: BuyoutItemFieldsFragment;
};

export const BidPriceTotalForItem: FC<BidPriceTotalForItemProps> = ({
  item,
}) => {
  const price = useMemo(() => {
    return Number(
      new Decimal(item.unitPrice || 0).mul(item.quantityDecimal || 0),
    );
  }, [item.unitPrice, item.quantityDecimal]);

  return (
    <ValueCurrency
      value={price}
      classNames={{ value: "font-medium text-right", container: "items-end" }}
    />
  );
};
