import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { InfoPanel } from "./InfoPanel";

const TopButtonContainer = tw.span`
  grid grid-flow-col gap-1 place-items-center text-sm print:hidden opacity-0
  animate-[fade-in_0.1s_ease-in-out_forwards] animation-delay-[500ms]
`;

const Label = tw.span`
  text-sm hidden md:inline-grid
`;

type Props = {
  children: React.ReactNode;
};

export const InfoHeaderButton: FC<Props> = ({ children }) => {
  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <TopButtonContainer onClick={() => togglePanel(true)}>
          <Label>
            <FormattedMessage id="INFO" />
          </Label>
          <IconButtonBorderless>
            <InfoOutlined />
          </IconButtonBorderless>
        </TopButtonContainer>
      )}
      content={(togglePanel) => (
        <InfoPanel onClose={() => togglePanel(false)}>{children}</InfoPanel>
      )}
    />
  );
};
