import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { VendorListItem } from "@/common/components/vendor-list/VendorListItem";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "@/contractor/pages/home/release/providers/ReleaseActionsProvider";
import { UpdateContractorBuyoutInput } from "@/generated/graphql";
import { FC, useState } from "react";
import { useIntl } from "react-intl";

export const BuyoutVendorContactSelector: FC = () => {
  const intl = useIntl();
  const { buyout, updateBuyout, updating } = useContractorBuyout();
  const { inputErrors } = useReleaseActions();
  const { setWarningAlert } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const [contacts, setContacts] = useState(
    buyout?.vendorContacts.map((vc) => vc.id) || [],
  );

  const update = async (vendorContacts: string[]) => {
    if (vendorContacts.length === 0) {
      setWarningAlert(intl.$t({ id: "INVALID_VENDOR_CONTACTS" }));
      return;
    }

    if (buyout) {
      setSaving(true);
      const input: UpdateContractorBuyoutInput = {
        buyoutId: buyout?.id,
        version: buyout?.version,
        vendorContactIds: vendorContacts,
      };
      await updateBuyout(input, true);
      setSaving(false);
    }
  };

  return (
    <Multiselect
      testId="vendor-contacts-selector"
      options={buyout?.preferredVendor?.contacts || []}
      className="min-w-32 xl:min-w-48"
      inputProps={{
        className: "text-xs px-0.5 py-0.5",
      }}
      loading={saving && updating}
      values={buyout?.vendorContacts.map((vc) => vc.id) || []}
      onMultipleChange={(values: string[] | null) => setContacts(values || [])}
      onBlur={() => update(contacts)}
      required={(buyout?.preferredVendor?.contacts ?? []).length > 0}
      error={inputErrors.includes(ReleaseErrorType.VENDOR_CONTACT)}
      getLabel={(o) => o.name}
      getValue={(option) => option.id}
      noOptionsText={intl.$t({ id: "NO_VENDORS_CONTACTS_FOUND" })}
      customRender={(contact) => <VendorListItem contact={contact} />}
      limitTags={1}
      chipSize="small"
      includeCheckbox
      noWrap
    />
  );
};
