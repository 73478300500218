import { ProjectItemUomPicker } from "@/common/components/project-item-uom-picker/ProjectItemUomPicker";
import { isEmptyString } from "@/common/utils/validationUtils";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useBuyoutGrouped } from "../providers/BuyoutGroupedProvider";

type Props = {
  item: BuyoutItemFieldsFragment;
  readonly?: boolean;
  staticText?: boolean;
  mnemonic?: boolean;
  error?: boolean;
};

export const BuyoutUomSelector: FC<Props> = ({
  item,
  staticText = false,
  readonly = false,
  mnemonic = true,
  error = false,
}) => {
  const { newBuyoutItem, setNewBuyoutItem } = useBuyoutGrouped();

  const saveUom = useCallback(
    (newValue: string | null) => {
      setNewBuyoutItem({
        ...newBuyoutItem,
        projectItem: {
          ...newBuyoutItem.projectItem,
          estimateUom: newValue || "",
        },
      });
    },
    [newBuyoutItem, setNewBuyoutItem],
  );

  return (
    <ProjectItemUomPicker
      item={item.projectItem}
      saveUom={saveUom}
      mnemonic={mnemonic}
      readonly={readonly}
      staticText={staticText}
      error={
        error && isEmptyString(newBuyoutItem.projectItem?.estimateUom || "")
      }
      testId="buyout-item-uom-selector"
    />
  );
};
