import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import {
  WizardModalMode,
  WizardModalPage,
} from "@/common/components/wizard-modal/WizardModal";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useAgaveHostedWizard } from "../../../AgaveHostedWizardProvider";
import { ToJobGeneralLedgerAccount } from "../../../steps/general-ledger-account/ToJobGeneralLedgerAccount";

export const useApLedgerAccountPages = (): WizardModalPage => {
  const intl = useIntl();
  const { moveToNextStep } = useNestedStepper();
  const {
    closeWizard,
    wizardMode,
    handleSaveWizardData,
    selectedToJobLedgerAccount,
  } = useAgaveHostedWizard();

  return useMemo(() => {
    return {
      feature: IntegrationFeature.ApLedgerAccountStep,
      title: intl.$t({ id: "ACCOUNTS_PAYABLE_LEDGER_ACCOUNT" }),
      completed: !!selectedToJobLedgerAccount,
      pages: [
        {
          component: (
            <ToJobGeneralLedgerAccount
              title={intl.$t({ id: "ACCOUNTS_PAYABLE_LEDGER_ACCOUNT" })}
            />
          ),
          onCloseClick: closeWizard,
          onNextClick: async () => {
            await handleSaveWizardData({
              accountsPayableLedgerAccountId: selectedToJobLedgerAccount,
            });
            moveToNextStep();
          },
          onNextClickDisabled: !selectedToJobLedgerAccount,
          onCloseClickDisabled:
            wizardMode === WizardModalMode.SINGLE_PAGE &&
            !selectedToJobLedgerAccount,
        },
      ],
    };
  }, [
    intl,
    selectedToJobLedgerAccount,
    closeWizard,
    wizardMode,
    handleSaveWizardData,
    moveToNextStep,
  ]);
};
