import { If } from "@/common/components/if/If";
import { Checkbox, FormControlLabel } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useQuoteMessage } from "../../providers/QuoteMessagesProvider";

type Props = {
  isPrivate?: boolean;
};

const Container = tw.div`
  flex justify-end
`;

export const RfqWideCheckbox: FC<Props> = ({ isPrivate }) => {
  const { isRfqWideMessage, setIsRfqWideMessage } = useQuoteMessage();
  const intl = useIntl();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRfqWideMessage(event.target.checked);
  };

  return (
    <If isTrue={!isPrivate}>
      <Container>
        <FormControlLabel
          classes={{
            label: "text-xs",
          }}
          control={
            <Checkbox
              checked={isRfqWideMessage}
              onChange={handleChange}
              size="small"
            />
          }
          label={intl.$t({ id: "QUOTE_MESSAGES_RFQ_WIDE_LABEL" })}
        />
      </Container>
    </If>
  );
};
