import { Price } from "@/common/components/price/Price";
import { sumAuxiliaryQuoteFullPrices } from "@/common/components/price/utils/sumAuxiliaryQuoteFullPrices";
import { DistributorQuotesAuxiliaryQuoteItemFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";

type Props = {
  items: DistributorQuotesAuxiliaryQuoteItemFieldsFragment[];
};

export const DistributorQuoteAuxiliaryCategoryTotal: FC<Props> = ({
  items,
}) => {
  const price = useMemo(
    () => items.reduce(sumAuxiliaryQuoteFullPrices, 0),
    [items],
  );

  return <Price price={price} />;
};
