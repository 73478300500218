import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useFiltersQueryParams } from "@/common/hooks/useFiltersQueryParams";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import { useScheduleRelease } from "@/contractor/pages/home/release/hooks/useScheduleRelease";
import {
  QueryReleasesFilter,
  ReleasePartialFieldsFragment,
  ReleasesDocument,
  ScheduleReleaseInput,
  useArchiveReleaseMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useDeliveriesWithPagination } from "../hooks/useDeliveriesWithPagination";

type ProviderContextType = {
  deliveries: ReleasePartialFieldsFragment[];
  filter?: QueryReleasesFilter | undefined;
  setFilter: (filter: QueryReleasesFilter | undefined) => void;
  loading: boolean;
  error: boolean;
  totalCount: number;
  archiveRelease: (id: string) => Promise<boolean>;
  isFiltered: boolean;
  exportEnabled: boolean;
  setExportEnabled: (enabled: boolean) => void;
  scheduleRelease: (input: ScheduleReleaseInput) => Promise<boolean>;
  refetch: () => void;
};

const ProviderContext = createContext<ProviderContextType>({
  deliveries: [],
  filter: undefined,
  setFilter: NoFunction,
  loading: false,
  error: false,
  totalCount: 0,
  archiveRelease: NoFunctionBooleanPromise,
  isFiltered: false,
  exportEnabled: false,
  setExportEnabled: NoFunction,
  scheduleRelease: NoFunctionBooleanPromise,
  refetch: NoFunction,
});

export const DeliveriesProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();
  const { setError } = useGlobalError();
  const { readValue, setValue } = useLocalStorage();

  const [filter, setFilter] = useState<QueryReleasesFilter | undefined>(
    getFiltersQueryParam(),
  );

  const { deliveries, loading, error, totalCount, pagination, refetch } =
    useDeliveriesWithPagination(filter);
  const [exportEnabled, setExportEnabled] = useState<boolean>(false);

  useEffect(() => {
    const localStorageSettings = readValue<QueryReleasesFilter>(
      LOCAL_STORAGE_KEYS.RELEASES_LIST_FILTER,
    ) as QueryReleasesFilter;
    setFilter({
      statuses: localStorageSettings?.statuses,
      deleted: localStorageSettings?.deleted,
      exported: localStorageSettings?.exported,
      retroactive: localStorageSettings?.retroactive,
      isBackorder: localStorageSettings?.isBackorder,
      hasReceipt: localStorageSettings?.hasReceipt,
      closedProjects: localStorageSettings?.closedProjects,
      ...getFiltersQueryParam(),
    });
  }, [getFiltersQueryParam, readValue]);

  const { scheduleReleaseMutation } = useScheduleRelease();
  const scheduleRelease = async (input: ScheduleReleaseInput) => {
    const result = await scheduleReleaseMutation(input);
    if (result) {
      refetch();
    }
    return result;
  };

  const [archiveDeliveryMutation] = useArchiveReleaseMutation();
  const archiveRelease = async (id: string) => {
    try {
      const { errors } = await archiveDeliveryMutation({
        variables: {
          id,
        },
        refetchQueries: [
          { query: ReleasesDocument, variables: { ...pagination, filter } },
        ],
      });

      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const setFilterAndUpdateQueryString = (
    updatedFilter: QueryReleasesFilter | undefined,
  ) => {
    setFiltersQueryParams(updatedFilter);
    setValue(LOCAL_STORAGE_KEYS.RELEASES_LIST_FILTER, updatedFilter);
    setFilter(updatedFilter);
  };

  return (
    <ProviderContext.Provider
      value={{
        deliveries,
        isFiltered: !!filter?.projectIds || !!filter?.statuses,
        loading,
        error: !!error,
        totalCount,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        archiveRelease,
        exportEnabled,
        setExportEnabled,
        scheduleRelease,
        refetch,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDeliveries = (): ProviderContextType =>
  useContext(ProviderContext);
