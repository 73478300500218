import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ImportProjectTagsInput,
  ImportProjectTagsMutation,
  useImportProjectTagsMutation,
} from "@/generated/graphql";

type Output = {
  importProjectTags: (
    input: ImportProjectTagsInput,
  ) => Promise<ImportProjectTagsMutation["importProjectTags"] | undefined>;
  importingProjectTags: boolean;
};

export const useImportProjectTags = (): Output => {
  const { setError } = useGlobalError();
  const [importProjectTagsMutation, { loading: importingProjectTags }] =
    useImportProjectTagsMutation();

  const importProjectTags = async (input: ImportProjectTagsInput) => {
    try {
      const { data } = await importProjectTagsMutation({
        variables: {
          input,
        },
      });
      return data?.importProjectTags;
    } catch (error) {
      setError(error);
    }
  };
  return {
    importProjectTags,
    importingProjectTags,
  };
};
