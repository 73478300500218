import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ImportZonesInput,
  ImportZonesMutation,
  useImportZonesMutation,
} from "@/generated/graphql";

type Output = {
  importZones: (
    input: ImportZonesInput,
  ) => Promise<ImportZonesMutation["importZones"] | undefined>;
  importingZones: boolean;
};

export const useImportZones = (): Output => {
  const { setError } = useGlobalError();
  const [importProjectZonesMutation, { loading: importingZones }] =
    useImportZonesMutation();

  const importZones = async (input: ImportZonesInput) => {
    try {
      const { data } = await importProjectZonesMutation({
        variables: {
          input,
        },
      });
      return data?.importZones;
    } catch (error) {
      setError(error);
    }
  };
  return {
    importZones,
    importingZones,
  };
};
