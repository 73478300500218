import { routes } from "@/config/routes";
import { BuyoutFieldsFragment, BuyoutStatus } from "@/generated/graphql";
import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";

export const useEnsureBuyoutStatus = (
  buyout: Pick<BuyoutFieldsFragment, "id" | "status" | "quote"> | null,
  statuses: BuyoutStatus[],
) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (buyout && !statuses.includes(buyout.status) && !buyout.quote) {
      navigate(generatePath(routes.buyout, { id: buyout.id }));
    }
  }, [buyout, navigate, statuses]);
};
