import { useCallback, useMemo, useState } from "react";
import tw from "tailwind-styled-components";
import { OverlayPanelTabs } from "../panel/OverlayPanelTabs";

type Props = {
  tabs: Array<{
    label: string | JSX.Element;
    viewState: string;
    content: React.ReactNode;
  }>;
  activeTab: string;
};

const Container = tw.div``;
const ContentContainer = tw.div``;

export const PopoverWithTabs = ({ tabs, activeTab }: Props) => {
  const [currentTab, setCurrentTab] = useState(activeTab);

  const activeTabInstance = useMemo(() => {
    return tabs.find((tab) => tab.viewState === currentTab);
  }, [currentTab, tabs]);

  const onTabChange = useCallback((value: string) => {
    setCurrentTab(value);
  }, []);

  return (
    <Container>
      <OverlayPanelTabs
        tabs={tabs}
        activeTab={currentTab}
        onTabChange={onTabChange}
      />
      <ContentContainer>{activeTabInstance?.content}</ContentContainer>
    </Container>
  );
};
