import {
  AdditionalFiltersOverlay,
  Option,
} from "@/common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import {
  BuyoutItemFilter,
  BuyoutItemsFilters,
  useBuyoutFiltersStore,
} from "../../non-quoted/providers/store/useBuyoutFiltersStore";

export const BuyoutItemListFilters = () => {
  const intl = useIntl();
  const { filter, setFilter } = useBuyoutFiltersStore();

  const options = useMemo<Option<BuyoutItemsFilters>[]>(() => {
    return [
      {
        id: BuyoutItemFilter.FULLY_RECEIVED,
        title: intl.$t({ id: BuyoutItemFilter.FULLY_RECEIVED }),
        filter: (value) => ({
          [BuyoutItemFilter.FULLY_RECEIVED]: value,
        }),
        value: (currentFilter) =>
          currentFilter?.[BuyoutItemFilter.FULLY_RECEIVED],
      },
    ];
  }, [intl]);

  return (
    <AdditionalFiltersOverlay
      filter={filter}
      defaultFilter={{
        [BuyoutItemFilter.FULLY_RECEIVED]: undefined,
      }}
      options={options}
      setFilter={setFilter}
    />
  );
};
