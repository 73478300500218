import { ProjectItemUomPicker } from "@/common/components/project-item-uom-picker/ProjectItemUomPicker";
import { ProjectItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";

type Props = {
  item: ProjectItemFieldsFragment;
  readonly?: boolean;
  staticText?: boolean;
  mnemonic?: boolean;
  index?: number;
};

export const ProjectItemItemUomPicker: FC<Props> = ({
  item,
  index,
  staticText = false,
  readonly = false,
  mnemonic = true,
}) => {
  const { setNewProjectEstimatedItem, updating } = useEstimatedItems();

  const saveUom = useCallback(
    (newValue: string | null) => {
      setNewProjectEstimatedItem([{ key: "uom", value: newValue }]);
    },
    [setNewProjectEstimatedItem],
  );

  return (
    <ProjectItemUomPicker
      index={index}
      item={item}
      saveUom={saveUom}
      mnemonic={mnemonic}
      readonly={readonly}
      staticText={staticText}
      updating={updating}
      testId="editable-project-item-uom"
    />
  );
};
