import {
  MasterSkusSkuFieldsFragment,
  ProjectItemFieldsFragment,
  ReleaseItemFieldsFragment,
  RfqItemFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import {
  CatalogListItem,
  CatalogListItemCustomLabels,
} from "./CatalogListItem";

type ProductListItemProps = {
  sku: MasterSkusSkuFieldsFragment;
  addToCart: (id: string, description: string) => Promise<boolean>;
  findItem: (
    id: string,
  ) =>
    | { id: string }
    | RfqItemFieldsFragment
    | undefined
    | ProjectItemFieldsFragment
    | ReleaseItemFieldsFragment;
  removeFromCart: (itemId: string) => Promise<boolean>;
  customLabels?: CatalogListItemCustomLabels;
};

export const SkuListItem: FC<ProductListItemProps> = ({
  sku,
  addToCart,
  findItem,
  removeFromCart,
  customLabels,
}) => (
  <CatalogListItem
    addToCart={addToCart}
    findItem={findItem}
    removeFromCart={removeFromCart}
    item={{
      id: sku.id,
      imageUrl: sku.imageUrl,
      name: sku.name,
      brand: sku.manufacturer?.name,
    }}
    customLabels={customLabels}
  />
);
