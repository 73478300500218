import { RfqItemFieldsFragment, useRfqQuery } from "@/generated/graphql";
import { useParams } from "react-router-dom";
import { useRfqMutations } from "../../rfq/hooks/useRfqMutations";

export const useRfqShoppingCart = () => {
  const { id } = useParams();
  const { data } = useRfqQuery({
    variables: { id: id || "" },
  });
  const { updateRfq } = useRfqMutations();

  const addToRfq = async (input: {
    masterSkuId?: string;
    masterProductId?: string;
    description?: string;
    estimateUom: string;
  }) => {
    const rfqId = id || "";
    return await updateRfq({
      addedItems: [
        {
          projectItem: {
            masterProductId: input.masterProductId,
            masterSkuId: input.masterSkuId,
            estimateUom: input.estimateUom,
          },
          description: input.description || "",
          quantityDecimal: "1",
        },
      ],
      rfqId,
    });
  };

  const removeFromRfq = async (itemId: string) => {
    return await updateRfq({ rfqId: id || "", removedItems: [itemId] });
  };

  const findItem = (materialId: string): RfqItemFieldsFragment | undefined => {
    if (data?.rfq) {
      return data.rfq.items.find(
        (item) => item.projectItem.material.material?.id === materialId,
      );
    }
  };

  return {
    addToRfq,
    removeFromRfq,
    findItem,
  };
};
