import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { CheckCircleOutline } from "@mui/icons-material";
import { FC, useEffect } from "react";
import tw from "tailwind-styled-components";

type Props = {
  text: string;
  onTimeoutCompleted?: () => void;
};

const Container = tw.div`flex flex-1 flex-col items-center justify-center self-stretch`;

export const CompletedStep: FC<Props> = ({ text, onTimeoutCompleted }) => {
  const { moveToNextNestedStep } = useNestedStepper();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (onTimeoutCompleted) {
        onTimeoutCompleted();
      } else {
        moveToNextNestedStep();
      }
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <CheckCircleOutline className="mb-4 h-30 w-30 text-blue-500" />
      {text}
    </Container>
  );
};
