import {
  StaticDatePicker as MuiStaticDatePicker,
  StaticDatePickerProps,
} from "@mui/x-date-pickers/StaticDatePicker";
import { FC } from "react";

type Props = StaticDatePickerProps<Date>;

export const StaticDatePicker: FC<Props> = (props) => {
  return <MuiStaticDatePicker displayStaticWrapperAs="desktop" {...props} />;
};
