import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";

import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";

import { SalesTaxType } from "@/common/components/amount-percentage-switch/AmountPercentageSwitch";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import {
  ItemizedNonItemizedToggle,
  ViewType,
} from "@/common/components/itemized-non-itemized-toggle/ItemizedNonItemizedToggle";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { useAddQuoteItems } from "@/common/hooks/add-missing-items-to-order/useAddQuoteItems";
import { usePredictedTax } from "@/common/hooks/usePredictedTax";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { getUTCDate } from "@/common/utils/dates/getUTCDate";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { routes } from "@/config/routes";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useReleaseStore } from "@/contractor/pages/home/release/store/useReleaseStore";
import { ChevronLeft } from "@mui/icons-material";
import Decimal from "decimal.js";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useOrderFromQuote } from "../../../../providers/OrderFromQuoteProvider";
import { useCopyQuotePrices } from "../../hooks/useCopyQuotePrices";
import { CreateOrderFromQuoteFormValues } from "./CreateOrderFromQuoteForm";
import { CreateOrderFromQuoteHeader } from "./CreateOrderFromQuoteHeader";
import {
  CreateOrderFromQuoteItemizedView,
  ItemizedQuoteTableItem,
} from "./CreateOrderFromQuoteItemizedView";
import { CreateOrderFromQuoteNonItemizedView } from "./CreateOrderFromQuoteNonItemizedView";

const Container = tw.div`bg-gray-100 rounded-3xl pt-4 px-4 h-fit overflow-y-scroll`;
const HeaderRow = tw.div`flex flex-row justify-between mb-1`;
const Header = tw.div`grid pl-1 text-base font-medium items-center mb-1`;

const HeaderGroup = tw.div`grid grid-cols-[auto_auto_1fr] w-full place-content-center place-items-center gap-1 mb-4`;
const ButtonGroup = tw.div`grid grid-flow-col w-fit gap-2 justify-self-end pr-2`;
const BackButton = tw.button`grid place-items-center bg-blue-800 rounded-md text-white`;
const ViewContainer = tw.div<{ $show: boolean }>`
  ${({ $show }) => !$show && "opacity-0 h-0 fixed -bottom-full"}
`;

export const CreateOrderFromQuoteDetails: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { openDialog, cancelDialog } = useDialog();
  const { findOrderTypeByLocationId } = useVendors();
  const { release, loading: loadingRelease } = useRelease();
  const { quoteDocument, loading } = useQuoteDocument();
  const { setHasChanges } = useReleaseStore();
  const { defaultOrderType, orderTypes } = useOrderTypeOptions();
  const { setTaxInput, predictedTaxRate } = usePredictedTax();
  const { spreadsheetData } = useColumnMapper();
  const { addQuoteItems } = useAddQuoteItems();
  const { calcTableTotal, findMaterialByName } = useTableHelpers();
  const { copyPriceFromQuote } = useCopyQuotePrices();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { itemized, setItemized } = useOrderFromQuote();
  const { calcExtPrice } = usePriceCalculation();

  const form = useFormContext<CreateOrderFromQuoteFormValues>();
  const { watch, setValue, getValues } = form;

  const [itemizedQuoteTableItems, setItemizedQuoteTableItems] = useState<
    ItemizedQuoteTableItem[]
  >([]);
  const [salesTaxType, setSalesTaxType] = useState(SalesTaxType.Amount);
  const [customTaxRate, setCustomTaxRate] = useState("");

  const projectId = watch("projectId");
  const sellerOrgLocationId = watch("vendorId");
  const willCall = watch("willCall");
  const warehouseId = watch("fulfillmentLocationId");
  const additionalCharges = watch("additionalCharges");
  const customTaxAmount = watch("customTaxAmount");
  const subtotal = watch("subtotal");
  const taxRate = watch("taxRate");

  const getItemizedQuoteTableItems = useCallback(() => {
    if (release?.items) {
      return release.items
        .toSorted((a, b) => (a.position || 0) - (b.position || 0))
        .map((item) => {
          const matchingOrgMaterial = findMaterialByName(
            item.projectItem?.material.material.name ?? item.name ?? "",
          );

          return {
            id: item.id,
            name: item.name ?? "",
            material: matchingOrgMaterial,
            costCode:
              item?.costCode?.description ??
              matchingOrgMaterial?.costCode?.description ??
              undefined,
            notes: item.instructions?.text ?? "",
            UOM:
              item?.uom?.pluralDescription ??
              item?.uom?.mnemonic ??
              item.projectItem?.estimateUom.mnemonic,
            unitPrice: item.unitPrice ?? undefined,
            quantityDecimal: item.quantityDecimal ?? "",
            extPrice: calcExtPrice(item.quantityDecimal, item.unitPrice),
          };
        });
    }

    if (!quoteDocument?.items) {
      return [];
    }

    return quoteDocument.items.map((item) => {
      const matchingOrgMaterial = findMaterialByName(item.description ?? "");
      const isLumpSum = isLumpSumUomText(item.uom);

      return {
        id: item.id,
        quantityDecimal: (isLumpSum ? item.unitPrice : item.quantity) ?? "",
        name: item.description ?? "",
        material: matchingOrgMaterial,
        costCode: matchingOrgMaterial?.costCode?.description ?? undefined,
        UOM:
          item.uom ??
          matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
          matchingOrgMaterial?.defaultEstimateUom?.mnemonic,
        unitPrice: isLumpSum ? "1" : (item.unitPrice ?? undefined),
        extPrice: calcExtPrice(item.quantity, item.unitPrice ?? undefined),
      };
    });
  }, [release, quoteDocument, calcExtPrice, findMaterialByName]);

  useEffect(() => {
    setItemizedQuoteTableItems(getItemizedQuoteTableItems());
  }, [getItemizedQuoteTableItems]);

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      setHasChanges(true);
      const newTotal = `${calcTableTotal(data)}`;
      if (newTotal !== subtotal) {
        setValue("subtotal", newTotal);
      }
    },
    [calcTableTotal, setHasChanges, setValue, subtotal],
  );

  useEffect(() => {
    setValue("businessLocationId", quoteDocument?.orgLocation?.id ?? "");
    setValue(
      "projectId",
      projectId ||
        release?.project?.id ||
        quoteDocument?.predictedProject?.id ||
        searchParams.get("projectId") ||
        "",
    );
    setValue(
      "vendorId",
      sellerOrgLocationId ||
        release?.sellerOrgLocation?.id ||
        quoteDocument?.predictedSellerOrgLocation?.id ||
        "",
    );
    setValue(
      "paymentTerm",
      release?.paymentTerm || quoteDocument?.paymentTerm || undefined,
    );
    setValue(
      "customTaxAmount",
      getValues("customTaxAmount") ||
        release?.taxAmount ||
        quoteDocument?.taxAmount ||
        "",
    );
    setValue("poNumber", (getValues("poNumber") || release?.poNumber) ?? "");
    setValue(
      "vendorContactIds",
      getValues("vendorContactIds") ||
        (release?.vendorContacts.length === 1
          ? release?.vendorContacts?.map((vc) => vc.id)
          : []) ||
        [],
    );
    if (release?.time) {
      setValue("orderDate", defaultReleaseDate(getUTCDate(release.time)));
    }
    updateSubtotal(spreadsheetData);
  }, [
    updateSubtotal,
    quoteDocument,
    setValue,
    searchParams,
    release,
    spreadsheetData,
    projectId,
    sellerOrgLocationId,
    loadingRelease,
    loading,
    getValues,
  ]);

  useEffect(() => {
    if (defaultOrderType) {
      setValue("orderTypeId", defaultOrderType.id);
    }
  }, [defaultOrderType, setValue]);

  useEffect(() => {
    if (sellerOrgLocationId) {
      const orderTypeId = findOrderTypeByLocationId(sellerOrgLocationId);
      if (orderTypeId) {
        setValue("orderTypeId", orderTypeId);
      }
    }
  }, [findOrderTypeByLocationId, sellerOrgLocationId, setValue]);

  useEffect(() => {
    setTaxInput({
      projectId,
      sellerOrgLocationId,
      willCall,
      warehouseId: willCall
        ? undefined
        : projectId !== warehouseId
          ? warehouseId
          : undefined,
    });
  }, [projectId, sellerOrgLocationId, willCall, warehouseId, setTaxInput]);

  const total = useMemo(() => {
    const subTotal = new Decimal(subtotal || 0);
    const taxAmount =
      salesTaxType === SalesTaxType.Percent
        ? subTotal.mul(customTaxRate || "0")
        : new Decimal(customTaxAmount || 0);
    const charges = additionalCharges.reduce((acc, charge) => {
      return acc.plus(charge.amount);
    }, new Decimal(0));
    return subTotal.plus(taxAmount).plus(charges).toString();
  }, [
    customTaxAmount,
    subtotal,
    customTaxRate,
    additionalCharges,
    salesTaxType,
  ]);

  useEffect(() => {
    if (
      predictedTaxRate &&
      taxRate === "" &&
      !quoteDocument?.taxAmount &&
      !release
    ) {
      openDialog({
        cancelButtonText: intl.$t({ id: "NO" }),
        confirmButtonText: intl.$t({ id: "YES" }),
        icon: <WarningIcon />,
        title: intl.$t({ id: "OVERWRITE_EXISTING_SALES_TAX_QUESTION" }),
        text: intl.$t(
          {
            id: "OVERWRITE_EXISTING_SALES_TAX",
          },
          {
            value: formatCurrency(new Decimal(predictedTaxRate).mul(subtotal), {
              minimumFractionDigits: 2,
            }),
          },
        ),
        handleConfirm: () => {
          setValue("taxRate", predictedTaxRate);
          setCustomTaxRate(predictedTaxRate);
          setSalesTaxType(SalesTaxType.Percent);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatCurrency, intl, predictedTaxRate, setValue, release]);

  const handleClick = useCallback(() => {
    if (release) {
      navigate(
        generatePath(routes.specifyDeliveryDetails, { deliveryId: release.id }),
      );
    }
  }, [release, navigate]);

  const handleToggleItemizedView = useCallback(
    (viewItemized: ViewType) => {
      if (viewItemized === ViewType.Itemized) {
        openDialog({
          icon: <WarningIcon />,
          title: intl.$t({ id: "RESCAN_QUOTE" }),
          text: intl.$t({ id: "RESCAN_QUOTE_TEXT" }),
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          customButtonText: intl.$t({ id: "CLEAR_ORDER" }),
          handleCustomButtonAction: () => {
            setItemized(true);
            setItemizedQuoteTableItems([]);
            cancelDialog?.();
          },
          confirmButtonText: intl.$t({ id: "RESCAN" }),
          handleConfirm: () => {
            setItemized(true);
            setItemizedQuoteTableItems([]);
            setTimeout(() => {
              setItemizedQuoteTableItems(getItemizedQuoteTableItems());
            });
            cancelDialog?.();
          },
        });
      } else {
        setItemized(false);
      }
    },
    [
      setItemized,
      setItemizedQuoteTableItems,
      getItemizedQuoteTableItems,
      openDialog,
      cancelDialog,
      intl,
    ],
  );

  return (
    <Container>
      <If isTrue={release}>
        <HeaderGroup>
          <BackButton onClick={handleClick}>
            <ChevronLeft />
          </BackButton>
          <FormattedMessage id="ADD_ITEMS_AND_COPY_PRICES" tagName={Header} />
          <ButtonGroup>
            <OutlinedButton
              $small
              className="ml-2"
              onClick={() => copyPriceFromQuote()}
            >
              <FormattedMessage id="COPY_PRICES_FROM_QUOTE" />
            </OutlinedButton>
            <OutlinedButton $small onClick={() => addQuoteItems()}>
              <FormattedMessage id="ADD_SCANNED_ITEMS_FROM_QUOTE" />
            </OutlinedButton>
          </ButtonGroup>
        </HeaderGroup>
      </If>
      <If isTrue={!release}>
        <HeaderRow>
          <FormattedMessage id="NEW_RELEASE" tagName={Header} />
          <ItemizedNonItemizedToggle
            handleChange={handleToggleItemizedView}
            isItemized={itemized}
          />
        </HeaderRow>
      </If>
      <CreateOrderFromQuoteHeader />
      <ViewContainer $show={itemized}>
        <CreateOrderFromQuoteItemizedView
          form={form}
          tableItems={itemizedQuoteTableItems}
          orderTypes={orderTypes}
          salesTaxType={salesTaxType}
          setSalesTaxType={setSalesTaxType}
          customTaxRate={customTaxRate}
          setCustomTaxRate={setCustomTaxRate}
          total={total}
          updateSubtotal={updateSubtotal}
        />
      </ViewContainer>
      <ViewContainer $show={!itemized}>
        <CreateOrderFromQuoteNonItemizedView form={form} />
      </ViewContainer>
    </Container>
  );
};
