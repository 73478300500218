import { If } from "@/common/components/if/If";
import { InnerLabeledSwitch } from "@/common/components/switch/InnerLabeledSwitch";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDistributorRelease } from "../providers/DistributorReleaseProvider";

const Container = tw.div`
  flex items-center 
`;

const Include = tw.div`
  mr-2 text-sm lg:hidden
`;

const PrintContainer = tw.div<{ $readonly?: boolean }>`
  print:block text-sm
  ${({ $readonly }: { $readonly?: boolean }) => ($readonly ? "" : "hidden")}
`;

type Props = {
  item: DistributorReleaseItemFieldsFragment;
  readonly: boolean;
};

export const DistributorReleaseItemToggle: FC<Props> = ({ item, readonly }) => {
  const intl = useIntl();
  const [includeItem, toggleItem] = useState<boolean>(item.isIncluded);
  const { release, updateVendorReleaseItem, updatedItem } =
    useDistributorRelease();

  useEffect(() => {
    toggleItem(item.isIncluded);
  }, [item.isIncluded]);

  const onToggle = useCallback(
    async (checked: boolean) => {
      if (!release) {
        return;
      }
      await updateVendorReleaseItem({
        releaseItemId: item.id,
        isIncluded: checked,
      });
      toggleItem(checked);
    },
    [release, item, updateVendorReleaseItem],
  );

  return (
    <Container>
      <Include>
        <FormattedMessage id="INCLUDE" />?
      </Include>
      <If isTrue={!readonly}>
        <InnerLabeledSwitch
          toggle={onToggle}
          className="print:hidden"
          width={55}
          initialValue={updatedItem(item)?.isIncluded ?? includeItem}
          onLabel={intl.$t({ id: "YES" })}
          offLabel={intl.$t({ id: "NO" })}
        />
      </If>
      <PrintContainer $readonly={readonly}>
        {includeItem ? intl.$t({ id: "YES" }) : intl.$t({ id: "NO" })}
      </PrintContainer>
    </Container>
  );
};
