import { InfoOutlined } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { LinkLike } from "../link-like/LinkLike";
import { Tooltip } from "../tooltip/Tooltip";

const SmallTooltip = tw.div`text-sm text-center`;

export const SearchTooltip = ({ text }: { text: string }) => {
  return (
    <Tooltip
      id="subtotal-price"
      element={
        <LinkLike onClick={() => null} forwardEvent={false}>
          <InfoOutlined />
        </LinkLike>
      }
    >
      <FormattedMessage
        id={text}
        values={{ br: <br /> }}
        tagName={SmallTooltip}
      />
    </Tooltip>
  );
};
