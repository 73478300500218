import { generateUUID } from "@/common/utils/uuidUtils";
import { InfoOutlined } from "@mui/icons-material";
import { FC, ReactNode } from "react";
import { LinkLike } from "../link-like/LinkLike";
import { Tooltip } from "../tooltip/Tooltip";

type Props = {
  message: string | ReactNode;
  id?: string;
  iconClassName?: string;
};

export const InfoTooltip: FC<Props> = ({ message, id, iconClassName }) => {
  return (
    <Tooltip
      id={id || `info-tooltip-${generateUUID()}`}
      element={
        <LinkLike>
          <InfoOutlined className={iconClassName} />
        </LinkLike>
      }
    >
      {message}
    </Tooltip>
  );
};
