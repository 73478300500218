import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { CompletedStep } from "@/common/components/wizard-modal/components/CompletedStep";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CostTypesDocument,
  ImportCostTypesMutation,
  SourceSystem,
  useImportCostTypesMutation,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { AnimatedLoading } from "../../../../../home/invoices/pages/scanned-invoices/components/splitting-invoices/steps/AnimatedLoading";
import { ImportExternalCostTypesImportResults } from "../components/import-external-codes/steps/ImportExternalCostTypesImportResults";
import { ImportExternalCostTypesTable } from "../components/import-external-codes/steps/ImportExternalCostTypesTable";

type ProviderContextType = {
  pages: WizardModalPage[];
  sourceSystem: SourceSystem | null;
  modalOpened: boolean;
  openModal: ({ sourceSystem }: { sourceSystem: SourceSystem }) => void;
  closeModal: () => void;

  selectedExternalCostTypes: string[];
  setSelectedExternalCostTypes: (id: string[]) => void;

  importAllExternalItems: boolean;
  setImportAllExternalItems: (value: boolean) => void;

  totalExternalCostTypesCount: number;
  setTotalExternalCostTypesCount: (count: number) => void;

  importedCostTypes: ImportCostTypesMutation["importCostTypes"] | null;
};

const ProviderContext = createContext<ProviderContextType>({
  pages: [],
  sourceSystem: null,
  modalOpened: false,
  openModal: NoFunction,
  closeModal: NoFunction,

  selectedExternalCostTypes: [],
  setSelectedExternalCostTypes: NoFunction,

  importAllExternalItems: false,
  setImportAllExternalItems: NoFunction,

  totalExternalCostTypesCount: 0,
  setTotalExternalCostTypesCount: NoFunction,

  importedCostTypes: null,
});

export const ImportExternalCostTypesProvider: FC<{
  children: React.ReactNode;
  defaultSourceSystem?: SourceSystem;
}> = ({ children, defaultSourceSystem }) => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { moveToNextNestedStep, moveToPreviousNestedStep, setNestedStep } =
    useNestedStepper();

  const [sourceSystem, setSourceSystem] = useState<SourceSystem | null>(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [importAllExternalItems, setImportAllExternalItems] = useState(false);

  const [totalExternalCostTypesCount, setTotalExternalCostTypesCount] =
    useState(0);
  const [selectedExternalCostTypes, setSelectedExternalCostTypes] = useState<
    string[]
  >([]);
  const [importedCostTypes, setImportedCostTypes] = useState<
    ImportCostTypesMutation["importCostTypes"] | null
  >(null);

  const openModal = useCallback(
    ({ sourceSystem }: { sourceSystem: SourceSystem }) => {
      setSourceSystem(sourceSystem);
      setModalOpened(true);
    },
    [],
  );

  const resetModal = useCallback(() => {
    setSourceSystem(null);
    setNestedStep(0);
  }, [setNestedStep]);

  const closeModal = useCallback(() => {
    setModalOpened(false);
    resetModal();
  }, [resetModal]);

  const [importCostTypesMutation] = useImportCostTypesMutation();
  const importSelectedCostTypes = useCallback(async () => {
    try {
      if (sourceSystem) {
        moveToNextNestedStep();
        const { data, errors } = await importCostTypesMutation({
          variables: {
            input: {
              externalCostTypeIds: selectedExternalCostTypes,
              sourceSystem,
            },
          },
          refetchQueries: [
            {
              query: CostTypesDocument,
            },
          ],
        });
        if (errors) {
          moveToPreviousNestedStep();
          setError(errors);
        }
        if (data?.importCostTypes) {
          setImportedCostTypes(data.importCostTypes);
          moveToNextNestedStep();
        }
      }
    } catch (error) {
      moveToPreviousNestedStep();
      setError(error);
    }
  }, [
    sourceSystem,
    selectedExternalCostTypes,
    importCostTypesMutation,
    moveToPreviousNestedStep,
    moveToNextNestedStep,
    setError,
  ]);

  const pages: WizardModalPage[] = useMemo(
    () => [
      {
        title: null,
        pages: [
          {
            component: (
              <ImportExternalCostTypesTable
                defaultSourceSystem={defaultSourceSystem}
              />
            ),
            footerButtonsConfig: [
              {
                type: "text",
                text: intl.$t(
                  { id: "OUT_OF_SELECTED" },
                  {
                    count: selectedExternalCostTypes.length,
                    total: totalExternalCostTypesCount,
                  },
                ),
                className: "flex mr-4 items-center",
                hidden:
                  selectedExternalCostTypes.length === 0 &&
                  totalExternalCostTypesCount === 0,
              },
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                onClick: importSelectedCostTypes,
                text: intl.$t({ id: "IMPORT" }),
                className: "flex-1",
                disabled: selectedExternalCostTypes.length === 0,
              },
            ],
          },
          {
            component: (
              <AnimatedLoading loading text={intl.$t({ id: "IMPORTING" })} />
            ),
            hideFooter: true,
            classNames: {
              wrapper: "mb-0",
            },
          },
          {
            component: (
              <CompletedStep text={intl.$t({ id: "IMPORT_COMPLETED" })} />
            ),
            onModalClose: moveToNextNestedStep,
            hideFooter: true,
            classNames: {
              wrapper: "mb-0",
            },
          },
          {
            component: <ImportExternalCostTypesImportResults />,
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "w-60",
              },
            ],
          },
        ],
      },
    ],
    [
      defaultSourceSystem,
      intl,
      selectedExternalCostTypes.length,
      totalExternalCostTypesCount,
      closeModal,
      importSelectedCostTypes,
      moveToNextNestedStep,
    ],
  );

  return (
    <ProviderContext.Provider
      value={{
        modalOpened,
        sourceSystem,
        pages,
        openModal,
        closeModal,

        selectedExternalCostTypes,
        setSelectedExternalCostTypes,

        importAllExternalItems,
        setImportAllExternalItems,

        totalExternalCostTypesCount,
        setTotalExternalCostTypesCount,

        importedCostTypes,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useImportExternalCostTypes = () => useContext(ProviderContext);
