import { FC, useEffect } from "react";
import tw from "tailwind-styled-components";

type Props = {
  loading: boolean;
  text: string;
  onCompleteLoading?: () => void;
  className?: string;
};

const Container = tw.div`flex flex-1 flex-col items-center justify-center self-stretch`;
const AnimationContainer = tw.div`relative flex flex-row w-full bottom-20`;

const Centered = tw.div`w-[400px] h-[400px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`;
const Blob1 = tw.div`w-[70px] h-[70px] absolute bg-gray-200 rounded-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-oscillateLeft`;
const Blob2 = tw(Blob1)`left-[80%] bg-blue-500 animate-oscillateRight`;

export const AnimatedLoading: FC<Props> = ({
  loading,
  text,
  onCompleteLoading,
  className,
}) => {
  useEffect(() => {
    if (!loading) {
      onCompleteLoading?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <Container className={className}>
      <AnimationContainer>
        <Centered>
          <Blob1 />
          <Blob2 />
        </Centered>
      </AnimationContainer>
      {text}
    </Container>
  );
};
