import { Dialog } from "@/common/components/dialog/Dialog";
import { Select } from "@/common/components/select/components/single/Select";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { ReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useReleaseActions } from "../../providers/ReleaseActionsProvider";
import { useRelease } from "../../providers/ReleaseProvider";

const Container = tw.div`
  grid gap-y-4 mt-5
`;

type AssignCostCodeDialogProps = {
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
  item: ReleaseItemFieldsFragment;
};

export const AssignCostCodeDialog: FC<AssignCostCodeDialogProps> = ({
  visible = true,
  setVisible,
  item,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { release } = useRelease();
  const [selectedCostCode, setSelectedCostCode] = useState(
    item?.zone?.id || "",
  );
  const { costCodes } = useCostCodes();
  const { updateCostCode, setNewCostCodes } = useReleaseActions();

  useEffect(() => {
    setSelectedCostCode(item?.costCode?.id || "");
  }, [item]);

  const assignZone = useCallback(async () => {
    if (release && item && selectedCostCode) {
      await updateCostCode();
    }
  }, [release, item, selectedCostCode, updateCostCode]);

  const handleConfirm = async () => {
    setVisible?.(false);
    await assignZone();
  };

  const handleCancel = () => {
    if (!release?.id) {
      navigate(-1);
    } else {
      setVisible?.(false);
    }
  };

  const updateCode = (code: string) => {
    setSelectedCostCode(code);
    setNewCostCodes([
      {
        costCodeId: code ? code : undefined,
        clearCostCode: !code ? true : undefined,
        releaseItemId: item.id,
      },
    ]);
  };

  return (
    <Dialog
      title={<FormattedMessage id="ASSIGN_COST_CODE" />}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({ id: "SELECT" })}
      handleConfirm={handleConfirm}
      classes={{
        paper: "mx-2 w-full max-w-96",
      }}
      handleCancel={handleCancel}
      show={visible}
      content={
        <Container>
          <Select
            placeholder={intl.$t({ id: "COST_CODE" })}
            className="w-full"
            options={costCodes}
            value={selectedCostCode}
            onChange={(code) => updateCode(code as string)}
            getLabel={(option) => option.formatted}
            getValue={(option) => option.id}
            required
          />
        </Container>
      }
    />
  );
};
