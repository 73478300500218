import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { DISTRIBUTOR_QUOTE_READ_ONLY_STATUSES } from "@/distributor/common/constants";
import { FC, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { checkQuoteStatus } from "../../../../../common/utils/status-checks/checkQuoteStatus";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";
import { DistributorQuoteExpiration } from "./DistributorQuoteExpiration";
import { DISTRIBUTOR_QUOTE_ORDER_REQUIREMENTS_PANEL } from "./DistributorQuoteOrderRequirements";

const Container = tw.div`flex flex-col gap-2`;

type Props = {
  onClose: () => void;
};

export const DistributorQuoteOrderRequirementsPanel: FC<Props> = ({
  onClose,
}) => {
  const { quote, paymentTermOptions, updateQuote, updating } =
    useDistributorQuote();
  const intl = useIntl();
  const { getObjectId } = useGlobalDrawer();
  const itemId = getObjectId(DISTRIBUTOR_QUOTE_ORDER_REQUIREMENTS_PANEL);
  const [isQuoteItem, setIsQuoteItem] = useState(false);
  const item = useMemo(() => {
    const quoteItem = quote?.itemGroups
      .flatMap((q) => q.quoteItems)
      .find((q) => q.id === itemId);
    if (quoteItem) {
      setIsQuoteItem(true);
      return quoteItem;
    }
    return quote?.auxiliaryItems.find((q) => q.id === itemId);
  }, [quote?.itemGroups, quote?.auxiliaryItems, itemId]);
  const [expirationDate, setExpirationDate] = useState(item?.expirationDate);
  const readonly = useMemo(
    () =>
      !!quote?.nextRevision ||
      checkQuoteStatus(quote, DISTRIBUTOR_QUOTE_READ_ONLY_STATUSES),
    [quote],
  );

  if (!quote || !paymentTermOptions) {
    return null;
  }

  const save = async () => {
    if (!itemId) {
      return;
    }
    const result = await updateQuote({
      ...(isQuoteItem && {
        updates: [
          {
            quoteItemId: itemId,
            expirationDate,
          },
        ],
      }),
      ...(!isQuoteItem && {
        updatedAuxiliaryItems: [
          {
            auxiliaryItemId: itemId,
            expirationDate,
          },
        ],
      }),
    });
    if (!result) {
      return;
    }
    onClose();
  };

  return (
    <OverlayPanel
      title={intl.$t({ id: "ORDER_REQUIREMENTS" })}
      onSave={!readonly ? save : undefined}
      onCancel={onClose}
      saving={updating}
    >
      <Container>
        <DistributorQuoteExpiration
          expirationDate={expirationDate}
          updateItem={setExpirationDate}
          readonly={readonly}
        />
      </Container>
    </OverlayPanel>
  );
};
