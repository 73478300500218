import { If } from "@/common/components/if/If";
import {
  SupportedFormats,
  UploadAsset,
} from "@/common/components/upload-asset/UploadAsset";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import {
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPE,
  PDF_EXTENSIONS,
  PDF_MIME_TYPE,
} from "@/common/components/upload/FileUploadArea";
import { AssetContext, AssetFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useNoteDocument } from "../providers/NoteDocumentProvider";

const Container = tw.div`w-full flex flex-col gap-4`;

type Props = {
  onChange: (asset: AssetFieldsFragment) => void;
};

const NoteDocumentImportWithProviders: FC<Props> = ({ onChange }) => {
  const { setAsset, asset } = useNoteDocument();

  return (
    <Container>
      <UploadAsset
        accept={{
          [PDF_MIME_TYPE]: PDF_EXTENSIONS,
          [IMAGE_MIME_TYPE]: IMAGE_EXTENSIONS,
        }}
        multiple={false}
        onChange={(file) => {
          setAsset(file[0]);
          onChange(file[0]);
        }}
      />
      <If isTrue={!asset}>
        <SupportedFormats>
          <FormattedMessage id="NOTE_DOCUMENT_SUPPORTED_FORMATS" />
        </SupportedFormats>
      </If>
    </Container>
  );
};

export const NoteDocumentImport: FC<Props> = (props) => {
  return (
    <UploadAssetProvider context={AssetContext.NoteDocument}>
      <NoteDocumentImportWithProviders {...props} />
    </UploadAssetProvider>
  );
};
