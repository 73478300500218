import { LinkLike } from "@/common/components/link-like/LinkLike";
import { NumericalInput } from "@/common/components/numerical-input/NumericalInput";
import { TextField } from "@/common/components/textfield/TextField";
import { AddCircleOutlineRounded, ClearOutlined } from "@mui/icons-material";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ItemWithIconContainer } from "../../Integrations.styles";
import { useAccountingOnPremiseWizard } from "../../wizard/accounting-onpremise-wizard/AccountingOnPremiseWizardProvider";

const Container = tw.div`w-full flex flex-col gap-4 mb-4`;
const TaxAuthority = tw.div`grid grid-cols-[2fr_1fr] gap-4`;

export const TaxAuthorities = () => {
  const { taxAuthorities, setTaxAuthorities } = useAccountingOnPremiseWizard();
  const intl = useIntl();

  const addTaxAuthority = useCallback(() => {
    setTaxAuthorities([
      ...taxAuthorities,
      {
        code: "",
        rate: "",
      },
    ]);
  }, [setTaxAuthorities, taxAuthorities]);

  const onRemove = useCallback(
    (key: number) => {
      const newTaxAuthorities = [...taxAuthorities];
      newTaxAuthorities.splice(key, 1);
      setTaxAuthorities(newTaxAuthorities);
    },
    [setTaxAuthorities, taxAuthorities],
  );

  return (
    <Container>
      {taxAuthorities.map((item, index) => (
        <ItemWithIconContainer key={index}>
          <TaxAuthority>
            <TextField
              label={intl.$t({ id: "TAX_AUTHORITY_NO" })}
              onChange={(event) => {
                const newTaxAuthorities = [...taxAuthorities];
                newTaxAuthorities[index].code = event.target.value;
                setTaxAuthorities(newTaxAuthorities);
              }}
              value={taxAuthorities[index].code}
            />
            <NumericalInput
              className="bg-white"
              label={intl.$t({ id: "TAX_AUTHORITY_TAX_RATE" })}
              name={`taxAuthorities.${index}.rate`}
              InputProps={{
                endAdornment: <>%</>,
              }}
              onChange={(event) => {
                const newTaxAuthorities = [...taxAuthorities];
                newTaxAuthorities[index].rate = event.target.value;
                setTaxAuthorities(newTaxAuthorities);
              }}
              value={taxAuthorities[index].rate}
              inputProps={{
                className: "placeholder:text-center",
              }}
              shrink
              placeholder="0.00"
            />
          </TaxAuthority>

          <LinkLike
            onClick={index === 0 ? addTaxAuthority : () => onRemove(index)}
          >
            {index === 0 ? <AddCircleOutlineRounded /> : <ClearOutlined />}
          </LinkLike>
        </ItemWithIconContainer>
      ))}
    </Container>
  );
};
