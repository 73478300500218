import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { getAssetType } from "@/common/components/upload-asset/getAssetType";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { hasProperty } from "@/common/utils/objectUtils";
import { generateUUID } from "@/common/utils/uuidUtils";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import {
  AssetContext,
  DeliverySlipStatus,
  InvoiceStatus,
  InvoicedReleaseItemFieldsFragment,
  ReleaseDocument,
  ReleaseQuery,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { useApolloClient } from "@apollo/client";
import Decimal from "decimal.js";
import { useVendorPrice } from "../hooks/useVendorPrice";
import { useSiteContacts } from "../pages/specify-details/components/site-contact-picker/hooks/useSiteContacts";

export const useReleaseCacheUpdate = () => {
  const client = useApolloClient();
  const { warehouses } = useWarehouses();
  const { getUomByName } = useUomOptions();
  const { getPrice } = useVendorPrice();
  const { manufacturers } = useManufacturers();
  const { getOrderType } = useOrderTypeOptions();
  const { vendors } = useVendors();
  const { siteContacts } = useSiteContacts();

  return (
    input?: UpdateContractorReleaseInput,
    changes?: React.MutableRefObject<UpdateContractorReleaseInput | undefined>,
    {
      invoiceId,
      invoiceItems,
      invoiceStatuses,
      removedInvoiceItems,
    }: {
      invoiceId?: string;
      invoiceItems?: (InvoicedReleaseItemFieldsFragment & {
        releaseItemId: string;
      })[];
      invoiceStatuses?: InvoiceStatus[];
      removedInvoiceItems?: string[];
    } = {},
  ) => {
    client.cache.updateQuery(
      {
        query: ReleaseDocument,
        variables: {
          id: input?.releaseId,
          input,
          invoiceId,
          invoiceStatuses,
        },
      },
      (data: ReleaseQuery | null) => {
        if (data?.release) {
          const issues = data.release.issues
            .filter(
              (issue) =>
                !input?.issues?.find(
                  (inputIssue) =>
                    inputIssue.releaseItemId === issue.releaseItem.id,
                ),
            )
            .concat(
              input?.issues?.map((issue) => ({
                description: issue.description || "",
                issueType: issue.issueType || "",
                photos: [],
                quantityDecimal: issue.quantityDecimal || "0",
                id: generateUUID(),
                releaseItem: {
                  __typename: "ReleaseItem" as const,
                  id: issue.releaseItemId,
                },
                __typename: "ReleaseIssue" as const,
                resolution: null,
              })) || [],
            );
          const newVendor = vendors.find(
            (v) => v.sellerOrgLocation.id === input?.sellerOrgLocationId,
          );
          const result = {
            ...data,
            release: {
              ...data?.release,
              description: input?.description
                ? input?.description
                : data.release.description,
              poNumber: input?.poNumber
                ? input?.poNumber
                : data.release.poNumber,
              requiresInventoryReceipt: hasProperty(
                input,
                "requiresInventoryReceipt",
              )
                ? input?.requiresInventoryReceipt
                : data?.release?.requiresInventoryReceipt,
              vendorContacts: input?.vendorContactIds
                ? vendors
                    .find(
                      (v) =>
                        v.sellerOrgLocation.id ===
                        data.release?.sellerOrgLocation?.id,
                    )
                    ?.contacts.filter((c) =>
                      input?.vendorContactIds?.includes(c.id),
                    ) || []
                : input?.sellerOrgLocationId && newVendor
                  ? newVendor.contacts.filter(
                      (c) => c.receivesOrderNotifications,
                    ) || []
                  : data.release.vendorContacts,
              includeServices: input?.includeServices
                ? input?.includeServices
                    ?.filter((i) => !i.remove)
                    .map((i) => i.type)
                    .concat(
                      data.release.includeServices.filter(
                        (i) =>
                          !input?.includeServices
                            ?.filter((is) => is.remove)
                            .map((i) => i.type)
                            .includes(i),
                      ),
                    )
                : data?.release?.includeServices,
              deliverySlips: [
                ...data?.release?.deliverySlips,
                ...(input?.deliverySlipUrls || [])
                  .filter((url) =>
                    data?.release?.deliverySlips.every(
                      (ds) => ds.asset?.url !== url,
                    ),
                  )
                  .map((url) => ({
                    __typename: "DeliverySlip" as const,
                    id: generateUUID(),
                    status: DeliverySlipStatus.Pending,
                    createdAt: new Date().getTime(),
                    createdBy: {
                      id: "",
                      firstName: "",
                      lastName: "",
                    },
                    reviewedAt: null,
                    asset: {
                      url,
                      __typename: "Asset" as const,
                      type: getAssetType(url),
                      context: AssetContext.DeliverySlip,
                      pages: null,
                      thumbnailUrl: url,
                    },
                  })),
              ],
              type: input?.typeId
                ? getOrderType(input?.typeId) || data.release.type
                : data.release.type,
              time: input?.requestedTime || data.release.time,
              warehouse: input?.warehouseId
                ? warehouses.find((w) => w.id === input?.warehouseId)
                : input?.clearWarehouse
                  ? null
                  : data.release.warehouse,
              taxRate: hasProperty(input, "taxRate")
                ? input?.taxRate
                : data?.release?.taxRate,
              customTaxAmount: input?.clearCustomTaxAmount
                ? null
                : hasProperty(input, "customTaxAmount")
                  ? input?.customTaxAmount
                  : data?.release?.customTaxAmount,
              preferredVendor:
                input?.sellerOrgLocationId && newVendor
                  ? {
                      id: newVendor.id,
                      contacts: newVendor.contacts,
                      externalCode: newVendor.externalCode,
                      taxExempt: newVendor.taxExempt,
                    }
                  : data.release.preferredVendor,
              sellerOrgLocation:
                input?.sellerOrgLocationId && newVendor?.sellerOrgLocation
                  ? newVendor.sellerOrgLocation
                  : data.release.sellerOrgLocation,
              siteContact: hasProperty(input, "siteContactId")
                ? input?.siteContactId
                  ? siteContacts.find(
                      (sc) => sc.id === input?.siteContactId,
                    ) || {
                      id: input?.siteContactId,
                      name: "",
                      email: "",
                      phone: "",
                      __typename: "SiteContact",
                    }
                  : null
                : data.release.siteContact,
              items: data?.release?.items.map((item) => {
                const updatedItem = input?.updates?.find(
                  (i) => i.releaseItemId === item.id,
                );
                const itemIssues = issues.filter(
                  (issue) => issue.releaseItem.id === item.id,
                );

                if (updatedItem) {
                  let requestedUom = item.uom;
                  if (updatedItem.uom) {
                    const uom = getUomByName(updatedItem.uom);
                    if (uom) {
                      requestedUom = uom;
                    } else {
                      requestedUom = {
                        id: generateUUID(),
                        pluralDescription: updatedItem.uom,
                        alternativeMnemonics: [],
                      };
                    }
                  }
                  const quantityDecimal =
                    updatedItem.quantityDecimal ?? item.quantityDecimal;
                  const prefilledPrice = getPrice(
                    item.projectItem?.material.id || "",
                    updatedItem.uom || requestedUom?.id || "",
                    updatedItem.sellerOrgLocationId ||
                      data?.release?.sellerOrgLocation?.id,
                    updatedItem.manufacturerId || item.manufacturer?.id || "",
                  );
                  const previousChanges = changes?.current?.updates?.find(
                    (u) => u.releaseItemId === item.id,
                  );
                  const unitPrice = Object.hasOwn(updatedItem, "unitPrice")
                    ? updatedItem.unitPrice
                    : previousChanges &&
                        Object.hasOwn(previousChanges, "unitPrice")
                      ? previousChanges?.unitPrice
                      : item.unitPrice;
                  const hasUnitPrice =
                    unitPrice !== null &&
                    unitPrice !== undefined &&
                    unitPrice !== "" &&
                    unitPrice !== "0";
                  const pricePrenegotiated =
                    previousChanges?.pricePrenegotiated ||
                    item.pricePrenegotiated;
                  if (!updatedItem.unitPrice) {
                    if (prefilledPrice) {
                      if (!hasUnitPrice || pricePrenegotiated) {
                        updatedItem.unitPrice = prefilledPrice;
                        updatedItem.pricePrenegotiated = true;
                      }
                    } else {
                      if (hasUnitPrice && pricePrenegotiated) {
                        updatedItem.unitPrice = null;
                        updatedItem.clearUnitPrice = true;
                        updatedItem.pricePrenegotiated = false;
                      }
                    }
                  } else {
                    updatedItem.pricePrenegotiated = false;
                  }
                  return {
                    ...item,
                    issues: itemIssues,
                    buyoutItem: item.buyoutItem ?? null,
                    manufacturer: updatedItem.manufacturerId
                      ? manufacturers.find(
                          (m) => m.id === updatedItem.manufacturerId,
                        )
                      : item.manufacturer,
                    requestedQuantity: quantityDecimal,
                    quantityDecimal,
                    receivedQuantityDecimal:
                      updatedItem.receivedQuantityDecimal ??
                      item.receivedQuantityDecimal,
                    requestedUom,
                    instructions: {
                      ...item.instructions,
                      __typename: "Instruction" as const,
                      text:
                        updatedItem.instructions?.text ??
                        item.instructions?.text ??
                        "",
                      assets:
                        updatedItem.instructions?.assetUrls?.map((url) => ({
                          url,
                          __typename: "Asset" as const,
                          type: getAssetType(url),
                          context: AssetContext.Instruction,
                          pages: null,
                          thumbnailUrl: url,
                        })) ??
                        item.instructions?.assets ??
                        [],
                    },
                    uom: requestedUom,
                    tags: updatedItem.tags
                      ? (data.release?.project?.tags || []).filter((t) =>
                          updatedItem.tags?.includes(t.id),
                        )
                      : item.tags,
                    zone:
                      data.release?.project?.zones.find(
                        (z) => z.id === updatedItem.zoneId,
                      ) || item.zone,
                    pricePrenegotiated: !!updatedItem.pricePrenegotiated,
                    priceEstimated:
                      item.priceEstimated &&
                      item.unitPrice === updatedItem.unitPrice,
                    unitPrice:
                      updatedItem.unitPrice === undefined
                        ? item.unitPrice
                        : updatedItem.unitPrice,
                    clearUnitPrice: updatedItem.clearUnitPrice,
                  };
                } else {
                  return {
                    ...item,
                    issues: itemIssues,
                    invoiceItems: (
                      item.invoiceItems?.filter(
                        (i) =>
                          !removedInvoiceItems?.includes(i.id) &&
                          !invoiceItems?.some((it) => it.id === i.id) &&
                          item.invoiceItems?.some((i) => i.id !== item.id),
                      ) || []
                    ).concat(
                      invoiceItems?.filter(
                        (i) => i.releaseItemId === item.id,
                      ) || [],
                    ),
                  };
                }
              }),
            },
          };

          const subtotal = result.release.items.reduce((acc: number, item) => {
            return Number(
              new Decimal(acc).add(
                new Decimal(item.unitPrice || 0).mul(item.quantityDecimal),
              ),
            );
          }, 0);
          const charges = (
            input?.additionalCharges || result.release.additionalCharges
          ).reduce(
            (acc, charge) =>
              Number(new Decimal(acc).add(new Decimal(charge.amount || 0))),
            0,
          );
          const taxRateAmount = new Decimal(subtotal)
            .add(charges)
            .mul(result?.release?.taxRate ?? 0);

          return {
            ...result,
            release: {
              ...result.release,
              additionalCharges:
                (
                  input?.additionalCharges ?? result.release.additionalCharges
                )?.map((charge) => ({
                  ...charge,
                  id: charge.id ?? generateUUID(),
                  __typename: "Charge" as const,
                })) || [],
              taxAmount:
                result.release.customTaxAmount || taxRateAmount?.toString(),
              subtotal: subtotal.toString(),
              total: new Decimal(subtotal)
                .add(charges)
                .add(
                  input?.customTaxAmount ||
                    result.release.customTaxAmount ||
                    taxRateAmount ||
                    0,
                )
                .toString(),
            },
          };
        }
        return data;
      },
    );
  };
};
