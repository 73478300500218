import { Check } from "@mui/icons-material";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC } from "react";
import tw from "tailwind-styled-components";

const IconContainer = tw.div`flex items-center justify-center w-28 h-28 text-green-600 border-8 border-green-600 rounded-full`;

type SuccessIconProps = {
  customIcon?: OverridableComponent<SvgIconTypeMap<object, "svg">>;
};

export const SuccessIcon: FC<SuccessIconProps> = ({ customIcon }) => {
  const CustomIcon = customIcon || Check;

  return (
    <IconContainer>
      <CustomIcon className="text-7xl" />
    </IconContainer>
  );
};
