import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { useDistributorInvoice } from "@/distributor/pages/invoices/providers/DistributorInvoiceProvider";
import { useUploadInvoiceModal } from "@/distributor/pages/invoices/providers/UploadInvoiceProvider";
import { InvoiceStatus } from "@/generated/graphql";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const ButtonContainer = tw.div`grid grid-flow-col gap-2 items-center justify-end`;

export const InvoiceFooter = () => {
  const { invoice, resolvingInvoiceIssue: resolvingInvoiceIssues } =
    useDistributorInvoice();
  const { openUploadInvoiceModal } = useUploadInvoiceModal();

  return (
    <FloatingFooter>
      <ButtonContainer>
        <If
          isTrue={
            invoice?.status === InvoiceStatus.IssuesReported ||
            invoice?.status === InvoiceStatus.AwaitingApproval
          }
        >
          <OutlinedButton
            className="py-0"
            onClick={openUploadInvoiceModal}
            disabled={resolvingInvoiceIssues}
          >
            <FormattedMessage id="INVOICE_CORRECTION_DESCRIPTION" />
          </OutlinedButton>
        </If>
      </ButtonContainer>
    </FloatingFooter>
  );
};
