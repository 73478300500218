import { SeededRandom } from "@/common/utils/seedRadom";
import { darken, getLuminance, lighten } from "@mui/material";
import { FC, memo, useMemo, useState } from "react";
import tw from "tailwind-styled-components";
import { TAG_COLORS } from "../tag-picker/TagColors";

const VendorLogo = tw.img``;

const VendorInitials = tw.div`rounded-sm flex items-center justify-center shadow-inner`;
const InitialsText = tw.span`font-semibold drop-shadow-sm tracking-wide select-none`;

type Props = {
  logoImageUrl?: string | null;
  name: string;
  width: number;
  height?: number;
  className?: string;
};

export const OrgLogo: FC<Props> = memo(
  ({ logoImageUrl, name = "", width, height, className }) => {
    const initials = useMemo(() => {
      if (!name) {
        return "NA";
      }
      const nameParts = name.split(" ");
      if (
        nameParts[0].toUpperCase() === nameParts[0] ||
        nameParts[0].length <= 3
      ) {
        return nameParts[0].slice(0, 3);
      } else {
        return nameParts.length > 1
          ? nameParts
              .reduce((acc, part) => acc + part.charAt(0), "")
              .slice(0, 3)
          : nameParts[0].slice(0, 2);
      }
    }, [name]);

    const [brokenImage, setBrokenImage] = useState(false);

    const random = useMemo(() => new SeededRandom(name || "NA"), [name]);

    const selectedColor = useMemo(() => {
      return TAG_COLORS[random.nextInt(TAG_COLORS.length)];
    }, [random]);

    const backgroundColor = useMemo(() => {
      if (selectedColor) {
        return lighten(selectedColor, 0.8 - getLuminance(selectedColor));
      } else {
        return "gray";
      }
    }, [selectedColor]);

    const color = useMemo(() => {
      if (selectedColor) {
        return darken(selectedColor, 0.65);
      } else {
        return "darkgray";
      }
    }, [selectedColor]);

    const borderColor = useMemo(() => {
      if (selectedColor) {
        return darken(selectedColor, 0.3);
      } else {
        return "darkgray";
      }
    }, [selectedColor]);

    return !logoImageUrl || brokenImage ? (
      <VendorInitials
        style={{
          backgroundColor,
          color,
          borderColor,
          width,
          minWidth: width,
          minHeight: height || width,
          height: height || width,
          fontSize: width * 0.35 + "px",
        }}
        className={`${className} ${!initials ? "opacity-0" : ""}`}
      >
        <InitialsText>{initials}</InitialsText>
      </VendorInitials>
    ) : (
      <VendorLogo
        loading="lazy"
        src={logoImageUrl}
        alt={name}
        width={width}
        height={height || width}
        className={className}
        style={{
          width,
          height: height || width,
          objectFit: "contain",
        }}
        onError={() => {
          setBrokenImage(true);
        }}
      />
    );
  },
);
