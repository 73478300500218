import { BarLineChart } from "@/common/components/charting/bar-chart/BarLineChart";
import { GraphDataType } from "@/common/components/charting/enums/GraphDataType";
import { If } from "@/common/components/if/If";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type ContainerProps = {
  $clickable: boolean;
};

const Container = tw.div<ContainerProps>`
  grid gap-1 w-full p-2 bg-gray-100 mb-3 rounded-2xl 
  transition-all duration-300 ease-in-out min-w-68 px-20
  ${(props) =>
    props.$clickable ? "hover:bg-yellow-300 hover:shadow cursor-pointer" : ""}
`;
const Title = tw.div`grid grid-flow-col justify-center gap-1 text-sm font-medium text-center`;
const Type = tw.div`text-xs text-center text-gray-600 font-light`;
const Bars = tw.div`relative grid grid-flow-col gap-1 xl:gap-2 w-fit pt-7 items-end mx-auto`;

type Props = {
  title?: string;
  type?: string;
  data: number[];
  onClick?: () => void;
  showMax?: boolean;
  colors?: string[];
  labels?: string[];
  format?: GraphDataType;
  loadData?: (id: string) => Promise<{
    labels: string[];
    values: number[];
  }>;
};

const DEFAULT_COLORS = ["#009BD9", "#F40018", "#00CB76", "#FF7628"];

export const ReportChart: FC<Props> = ({
  title,
  type,
  data,
  onClick,
  showMax = true,
  labels,
  colors = DEFAULT_COLORS,
  format = GraphDataType.Currency,
  loadData,
}) => {
  return (
    <Container onClick={onClick} $clickable={!!onClick}>
      <Title>
        <If isTrue={title}>
          {title}
          <If isTrue={type}>
            <Type>
              (<FormattedMessage id={type} />)
            </Type>
          </If>
        </If>
      </Title>
      <Bars>
        {data.map((item, index) => (
          <BarLineChart
            key={index}
            item={item}
            index={index}
            data={data}
            colors={colors}
            showMax={showMax}
            format={format}
            labels={labels}
            loadData={loadData}
          />
        ))}
      </Bars>
    </Container>
  );
};
