import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  OrgSettingsExtendedDocument,
  OrgSettingsExtendedQuery,
  UpdateOrgSettingsInput,
  useUpdateOrgSettingsMutation,
  ViewerDocument,
} from "@/generated/graphql";

export const useUpdateOrgSettings = () => {
  const { setError } = useGlobalError();

  const [updateOrgSettingsMutation, { loading: updating }] =
    useUpdateOrgSettingsMutation();
  const updateOrgSettings = async (input: UpdateOrgSettingsInput) => {
    try {
      const { errors } = await updateOrgSettingsMutation({
        variables: { input },
        update: (cache, { data: newData }) => {
          const data = cache.readQuery<OrgSettingsExtendedQuery>({
            query: OrgSettingsExtendedDocument,
          });
          if (data) {
            cache.writeQuery({
              query: OrgSettingsExtendedDocument,
              data: {
                viewer: {
                  ...data?.viewer,
                  org: {
                    ...data?.viewer?.org,
                    settings: newData?.updateOrgSettings,
                  },
                },
              },
            });
          }
        },
        refetchQueries: [{ query: ViewerDocument }],
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return {
    updateOrgSettings,
    updating,
  };
};
