import { hasProperty } from "./objectUtils";

const deepMerge = <T>(a: T, b: T): T => {
  const result: T = { ...a };

  for (const key in b) {
    if (hasProperty(b, key)) {
      if (
        typeof b[key] === "object" &&
        b[key] !== null &&
        hasProperty(a, key) &&
        typeof a[key] === "object" &&
        !Array.isArray(b[key])
      ) {
        result[key] = deepMerge(a[key], b[key]);
      } else {
        result[key] = b[key];
      }
    }
  }

  return result;
};

export const mergeChanges = <T>(
  currentValues: T[] | undefined | null,
  newValues: T[] | undefined | null,
  idKey: string,
) => {
  if (!currentValues || !newValues) {
    return [...(currentValues || []), ...(newValues || [])];
  }

  newValues.forEach((obj) => {
    const index = currentValues.findIndex((o) => {
      return (
        hasProperty(o, idKey) &&
        hasProperty(obj, idKey) &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (o as any)[idKey] === (obj as any)[idKey]
      );
    });

    if (index === -1) {
      currentValues.push(obj);
    } else {
      currentValues[index] = deepMerge(currentValues[index], obj);
    }
  });

  return currentValues as T[];
};
