import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";

import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { useRenderHelpers } from "./useRenderHelpers";
export const useUomRenderer = () => {
  const { checkReadOnly } = useRenderHelpers();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      textRenderer(instance, td, row, col, prop, value, cellProperties);

      checkReadOnly(td, row, instance, cellProperties);
    },
    [checkReadOnly],
  );

  return renderer;
};
