import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { ZoneCategory } from "@/distributor/pages/release/providers/DistributorReleaseItemsZoneProvider";
import { useMemo } from "react";

export const useHideZones = (zones: Pick<ZoneCategory, "id">[]) => {
  const hideZones = useMemo(() => {
    return zones.length === 1 && zones[0].id === UNSPECIFIED_ZONE_ID;
  }, [zones]);

  return { hideZones };
};
