import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { ConnectionMode } from "@/contractor/pages/admin/integrations/components/common/ConnectionMode";
import { ConnectReleasePOsPanel } from "@/contractor/pages/home/release/components/connections/components/panels/ConnectReleasePOsPanel";
import { ReleaseFieldsFragment, SourceSystem } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";

type Props = {
  release: Pick<ReleaseFieldsFragment, "status" | "id">;
  sourceSystem: SourceSystem;
};

export const ConnectReleasePOButton: FC<Props> = ({
  release,
  sourceSystem,
}) => {
  const intl = useIntl();

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <OutlinedButton $small onClick={() => togglePanel(true)}>
          {intl.$t({ id: "CONNECT_PO" })}
        </OutlinedButton>
      )}
      content={(togglePanel) => (
        <ConnectReleasePOsPanel
          releaseIds={[release.id]}
          sourceSystem={sourceSystem}
          onClose={() => togglePanel(false)}
          mode={ConnectionMode.Connect}
        />
      )}
    />
  );
};
