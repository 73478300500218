import { UOM_FIELDS } from "@/common/fragments/uomFragment";
import { gql } from "@apollo/client";

export const PROJECT_MATERIAL_PRODUCT_FIELDS = gql`
  ${UOM_FIELDS}
  fragment ProjectMaterialProductFields on Product {
    id
    name
    description
    imageUrl
    uom {
      ...UOMFields
    }
  }
`;
