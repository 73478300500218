import { useBuyoutItemsGrouping } from "@/common/utils/hooks/useBuyoutItemsGrouping";
import {
  BuyoutFieldsFragment,
  BuyoutItemFieldsFragment,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  CategoryState,
  useToggleCategory,
} from "../../../../../../../common/hooks/useToggleCategory";
import { useUnspecifiedCostCode } from "../../../../../../../common/hooks/useUnspecifiedCostCode";

type CostCodeCategory = CategoryState<BuyoutItemFieldsFragment>;

type ContextType = {
  costCodes: CostCodeCategory[];
  toggleCategory: (name: string) => void;
  groupedByCostCode: boolean;
  setGroupedByCostCode: (grouped: boolean) => void;
};

const Context = createContext<ContextType>({
  costCodes: [],
  toggleCategory: NoFunction,
  groupedByCostCode: true,
  setGroupedByCostCode: NoFunction,
});

export const BuyoutQuotedGroupedProvider: FC<
  PropsWithChildren<{ buyout: BuyoutFieldsFragment }>
> = ({ children, buyout }) => {
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const [costCodes, setCostCodes] = useState<CostCodeCategory[]>([]);
  const { toggleCategory } = useToggleCategory(costCodes, setCostCodes);
  const [groupedByCostCode, setGroupedByCostCode] = useState(true);
  const { getProjectCodes, filterBasedOnCostCodeAndPhaseCode } =
    useBuyoutItemsGrouping({ items: buyout.items });

  useEffect(() => {
    const costCodes = getProjectCodes();

    const mappedCost: CostCodeCategory[] = costCodes.map((costCode) => {
      const newCostCode = {
        id: costCode.id,
        name: costCode.description,
        isOpened: true,
      };
      return {
        ...newCostCode,
        items: [
          ...buyout.items.filter((i) =>
            filterBasedOnCostCodeAndPhaseCode(i, costCode),
          ),
        ],
      };
    });

    setCostCodes(mappedCost);
  }, [
    buyout.items,
    unassignedCostCode.id,
    unassignedCostCode,
    filterBasedOnCostCodeAndPhaseCode,
    getProjectCodes,
  ]);

  return (
    <Context.Provider
      value={{
        costCodes,
        toggleCategory,
        groupedByCostCode,
        setGroupedByCostCode,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useBuyoutQuotedGrouped = () => useContext(Context);
