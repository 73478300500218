import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { stopPropagation } from "@/common/utils/stopPropagation";
import { FC, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex items-center justify-center w-48`;
const ButtonText = tw.span`text-sm`;
const PrimaryButtonStyled = tw(PrimaryButton)`py-0 min-h-6`;
const OutlinedButtonStyled = tw(OutlinedButton)`py-0 min-h-6`;
const ActionsContainer = tw.div`absolute w-40 flex gap-2 -top-3 -left-1`;

type Props = {
  readonly?: boolean;
  onSave: () => void;
  onCancel: () => void;
  onEdit: (value: boolean) => void;
};

export const AssignCostCodesButton: FC<Props> = ({
  readonly = false,
  onEdit,
  onSave,
  onCancel,
}) => {
  const [editMode, setEditMode] = useState(false);

  const handleClick = useCallback(() => {
    onEdit(true);
    setEditMode(true);
  }, [onEdit]);

  const handleOnSave = useCallback(() => {
    onSave();
    onEdit(false);
    setEditMode(false);
  }, [onEdit, onSave]);

  const handleOnCancel = useCallback(() => {
    onCancel();
    onEdit(false);
    setEditMode(false);
  }, [onCancel, onEdit]);

  return (
    <If isTrue={!readonly}>
      <Container onClick={stopPropagation}>
        <ButtonsContainer>
          <If isTrue={!editMode}>
            <ActionsContainer>
              <OutlinedButtonStyled onClick={handleClick} $small>
                <FormattedMessage tagName={ButtonText} id="ASSIGN_COST_CODES" />
              </OutlinedButtonStyled>
            </ActionsContainer>
          </If>
          <If isTrue={editMode}>
            <ActionsContainer>
              <OutlinedButtonStyled onClick={handleOnCancel}>
                <FormattedMessage tagName={ButtonText} id="CANCEL" />
              </OutlinedButtonStyled>
              <PrimaryButtonStyled onClick={handleOnSave}>
                <FormattedMessage tagName={ButtonText} id="SAVE" />
              </PrimaryButtonStyled>
            </ActionsContainer>
          </If>
        </ButtonsContainer>
      </Container>
    </If>
  );
};
