import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { routes } from "@/config/routes";
import { DeliverySlipPartialFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-col items-center justify-center`;
const BuyoutNumber = tw.div`text-2xs`;
const OrderNumber = tw.div`text-sm`;

type Props = {
  deliverySlip: DeliverySlipPartialFieldsFragment;
};

export const DeliverySlipOrderAndBuyout: FC<Props> = ({ deliverySlip }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <If isTrue={deliverySlip.release?.buyout?.id}>
        <LinkLike
          onClick={() =>
            navigate(
              generatePath(routes.buyout, {
                id: deliverySlip.release?.buyout?.id || "",
              }),
            )
          }
        >
          <FormattedMessage
            id="BUYOUT"
            values={{
              number: deliverySlip.release?.buyout?.clientIdentifier,
            }}
            tagName={BuyoutNumber}
          />
        </LinkLike>
      </If>
      <If
        isTrue={
          deliverySlip.release?.id && deliverySlip.release?.sequenceNumber
        }
      >
        <LinkLike
          onClick={() =>
            navigate(
              generatePath(routes.delivery, {
                deliveryId: deliverySlip.release?.id || "",
              }),
            )
          }
        >
          <FormattedMessage
            id="DELIVERY_WITH_NUMBER"
            values={{ number: deliverySlip.release?.sequenceNumber }}
            tagName={OrderNumber}
          />
        </LinkLike>
      </If>
    </Container>
  );
};
