import { Locale, useLocale } from "@/common/providers/LocaleProvider";
import { useUser } from "@/common/providers/UserProvider";
import { SystemRole } from "@/generated/graphql";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`+
  relative grid grid-flow-col h-7 w-fit px-2 bg-blue-800 border border-blue-400 
  rounded-4xl place-items-center self-center justify-self-center cursor-pointer mx-5
`;

const KeyboardArrowDownStyled = tw(KeyboardArrowDown)`
  text-gray-400 w-4
`;

const Dropdown = tw.div`
  flex items-center gap-1
`;

const Item = tw.div`
  grid place-items-center grid-flow-col gap-2 ml-1
`;

const Icon = tw.div`
  text-lg
`;

const Label = tw.div`
  text-sm
`;

export const LanguageSelector = () => {
  const { selectedLocale, setSelectedLocale } = useLocale();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { viewer } = useUser();
  const intl = useIntl();
  const options = [
    {
      label: intl.$t({ id: "EN" }),
      value: Locale.en,
      icon: "🇺🇸",
    },
    {
      label: intl.$t({ id: "ES" }),
      value: Locale.es,
      icon: "🇪🇸",
    },
  ];

  const onChange = (locale: string | null) => {
    if (!locale) {
      return;
    }
    setSelectedLocale(locale as Locale);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isFMAdmin = useMemo(() => {
    return viewer?.systemRoles.includes(SystemRole.FmAdmin);
  }, [viewer]);

  if (!isFMAdmin) {
    return null;
  }

  return (
    <Container>
      <Dropdown onClick={handleClick}>
        <Item>
          <span>
            {options.find((item) => item.value === selectedLocale)?.icon}
          </span>
        </Item>
        <KeyboardArrowDownStyled />
      </Dropdown>
      <Menu
        anchorEl={anchorEl}
        open={open}
        variant="selectedMenu"
        onClose={handleClose}
        keepMounted
      >
        {options.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              onChange(item.value);
              handleClose();
            }}
            selected={item.value === selectedLocale}
          >
            <Item>
              <Icon>{item.icon}</Icon>
              <Label>{item.label}</Label>
            </Item>
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};
