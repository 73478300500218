import { QuoteStatus, ReleaseStatus, RfqStatus } from "@/generated/graphql";

export const DISTRIBUTOR_QUOTE_READ_ONLY_STATUSES = [
  QuoteStatus.Accepted,
  QuoteStatus.Rejected,
  QuoteStatus.Withdrawn,
  QuoteStatus.CancelledByContractor,
];

export const DISTRIBUTOR_BULK_UPDATE_STATUSES = [QuoteStatus.Submitted];

export const DISTRIBUTOR_RELEASE_READ_ONLY_STATUSES = [
  ReleaseStatus.Received,
  ReleaseStatus.PartiallyReceived,
  ReleaseStatus.Rejected,
  ReleaseStatus.Canceled,
];

export const DISTRIBUTOR_RFQ_READ_ONLY_STATUSES = [
  RfqStatus.Awarded,
  RfqStatus.Cancelled,
];
