import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { SelectableItem } from "@/common/components/searchable-list/components/SelectableItem";
import {
  SmdFixedContainer,
  XlContainer,
} from "@/common/layout/ResponsiveClasses";
import { hasProperty } from "@/common/utils/objectUtils";
import {
  ExternalItemFieldsFragment,
  OrgMaterialFieldsFragment,
} from "@/generated/graphql";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Row = tw.div`flex flex-row`;

export const useImportExternalItemsTableConfiguration = (
  selectedItems?: string[],
): Array<GridCol<ExternalItemFieldsFragment | OrgMaterialFieldsFragment>> => {
  const { page, pageSize } = usePagination();

  return [
    {
      header: <FormattedMessage id="ITEM_DESCRIPTION" />,
      wrapper: XlContainer,
      item: ({ item, index, count }) => (
        <SelectableItem
          item={item}
          index={page * pageSize + (index ?? 0)}
          selectedItems={selectedItems ?? []}
          itemNameSelectorFn={(item) =>
            "name" in item ? item.name : item.material.name
          }
          count={count}
        />
      ),
    },
    {
      header: <FormattedMessage id="ITEM_CODE" />,
      wrapper: SmdFixedContainer,
      item: ({ item }) => (
        <Row>
          {hasProperty(item, "sourceId")
            ? (item as ExternalItemFieldsFragment).sourceId
            : (item as OrgMaterialFieldsFragment).externalCode}
        </Row>
      ),
    },
    {
      header: <FormattedMessage id="PURCHASING_UOM" />,
      wrapper: SmdFixedContainer,
      item: ({ item }) => (
        <Row>
          {hasProperty(item, "purchasingUom")
            ? (item as ExternalItemFieldsFragment).purchasingUom
            : (item as OrgMaterialFieldsFragment).defaultEstimateUom
                .pluralDescription}
        </Row>
      ),
    },
    {
      header: <FormattedMessage id="DEFAULT_COST_CODE" />,
      wrapper: SmdFixedContainer,
      item: ({ item }) => (
        <Row>
          {hasProperty(item, "defaultCostCode")
            ? (item as ExternalItemFieldsFragment).defaultCostCode
            : (item as OrgMaterialFieldsFragment).costCode?.description}
        </Row>
      ),
    },
  ];
};
