import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormGroup,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { FC, useCallback } from "react";
import { ProductFacetValue } from "./ProductFacetValue";
import tw from "tailwind-styled-components";
import { ProductFacet, SelectedFacet } from "./SkusAndProductsProvider";
import { MasterSkusFacetFieldsFragment } from "@/generated/graphql";

const ProductFacetsFilterContainer = tw.div`
  pl-0.5
`;

const FormControlStyled = tw(FormControl)`
  w-full
`;

const ProductFacetName = tw.div`
  text-sm
`;

const FormGroupStyled = tw(FormGroup)`
  ml-4
`;

type ProductFacetFilterProps = {
  productFacets: ProductFacet[];
  productFacetsValues: MasterSkusFacetFieldsFragment[];
  selectedFacets: SelectedFacet[];
  toggleFacetValue: (facetId: string, value: string) => void;
};

export const ProductFacetsFilter: FC<ProductFacetFilterProps> = ({
  productFacets,
  productFacetsValues,
  selectedFacets,
  toggleFacetValue,
}) => {
  const onFacetClick = useCallback(
    (productFacetId: string, productFacetValue: string) => {
      toggleFacetValue(productFacetId, productFacetValue);
    },
    [toggleFacetValue],
  );

  return (
    <ProductFacetsFilterContainer>
      {productFacets.map((productFacet) => (
        <Accordion key={productFacet.id}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={productFacet.id}
            id={`${productFacet.id}-header`}
          >
            <ProductFacetName>{productFacet.name}</ProductFacetName>
          </AccordionSummary>
          <AccordionDetails>
            <FormControlStyled>
              <FormGroupStyled>
                {(
                  productFacetsValues.find(
                    (facet) => facet.id === productFacet.id,
                  )?.values || []
                ).map((facetValue) => (
                  <ProductFacetValue
                    key={facetValue.value}
                    count={facetValue.count}
                    name={facetValue.name}
                    parentId={productFacet.id}
                    value={facetValue.value}
                    selected={Boolean(
                      selectedFacets.find(
                        (facet) =>
                          facet.value === facetValue.value &&
                          facet.id === productFacet.id,
                      ),
                    )}
                    handleChange={onFacetClick}
                  />
                ))}
              </FormGroupStyled>
            </FormControlStyled>
          </AccordionDetails>
        </Accordion>
      ))}
    </ProductFacetsFilterContainer>
  );
};
