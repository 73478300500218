import { LinkLikeText } from "@/common/components/link-like-text/LinkLikeText";
import { VendorList } from "@/common/components/vendor-list/VendorList";
import { getUserName } from "@/common/utils/users/getUserName";
import {
  UsersProvider,
  useUsers,
} from "@/contractor/pages/admin/users/components/providers/UsersProvider";
import { FC } from "react";
import tw from "tailwind-styled-components";

type Props = {
  className?: string;
};

const LinkLikeTextStyled = tw(LinkLikeText)`inline`;
const VendorListStyled = tw(VendorList)`inline`;

const AdminUsersInfoWithProvider: FC<Props> = ({ className }) => {
  const { users } = useUsers();

  return (
    <LinkLikeTextStyled>
      <VendorListStyled
        contacts={users
          .filter((u) => u.isOrgAdmin)
          .map((u) => ({
            id: u.id,
            name: getUserName(u),
            email: u.email,
            cellPhone: u.cellPhone,
          }))}
        limit={users.filter((u) => u.isOrgAdmin).length}
        classes={{
          popover: `inline ${className}`,
        }}
        separator=","
      />
    </LinkLikeTextStyled>
  );
};

export const AdminUsersInfo: FC<Props> = (props) => (
  <UsersProvider>
    <AdminUsersInfoWithProvider {...props} />
  </UsersProvider>
);
