import { Tabs } from "@/common/components/tabs/Tabs";
import { routes } from "@/config/routes";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const AdminOrgNavigation = () => {
  const intl = useIntl();

  const tabs = useMemo(
    () => [
      {
        label: intl.$t({ id: "PROFILE" }),
        path: routes.orgProfile,
      },
      {
        label: intl.$t({ id: "SETTINGS" }),
        path: routes.orgSettings,
      },
      {
        label: intl.$t({ id: "NOTIFICATIONS" }),
        path: routes.orgNotifications,
      },
    ],
    [intl],
  );

  return <Tabs tabs={tabs} type="secondary" className="pt-4" />;
};
