import { CircleCounter } from "@/common/components/circle-counter/CircleCounter";
import { If } from "@/common/components/if/If";
import { ChevronRight } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useNestedStepper } from "../../stepper/NestedStepper";
import { WizardModalPage } from "../WizardModal";

const HeaderItem = tw.button`flex flex-row font-medium items-center`;

type Props = {
  title?: string | null;
  index: number;
  pages: WizardModalPage[];
};

export const WizardModalHeaderBreadcrumb: FC<Props> = ({
  title,
  index,
  pages,
}) => {
  const { step, setStep } = useNestedStepper();

  const handleClick = useCallback(() => {
    if (step !== pages.length - 1 && step > index) {
      setStep(index);
    }
  }, [step, pages.length, index, setStep]);

  const pagesWithTitles = useMemo(
    () => pages.filter((p) => !!p.title),
    [pages],
  );

  const displayIndex = useMemo(
    () => pagesWithTitles.findIndex((p) => p.title === title),
    [title, pagesWithTitles],
  );

  if (!title) {
    return null;
  }

  const isActive = index === step;
  const isCompleted = pages[index].completed;

  return (
    <>
      <HeaderItem
        onClick={handleClick}
        className={
          isCompleted
            ? "text-green-800"
            : isActive
              ? "text-blue-800"
              : "text-blue-800 opacity-50"
        }
      >
        <CircleCounter
          count={displayIndex + 1}
          className="mr-2"
          innerClassName={
            isCompleted
              ? "bg-green-800"
              : isActive
                ? "bg-blue-800"
                : "bg-blue-800 opacity-50"
          }
        />
        {title}
      </HeaderItem>
      <If isTrue={displayIndex + 1 !== pagesWithTitles.length}>
        <ChevronRight className="mx-2 text-black" />
      </If>
    </>
  );
};
