import { CategoryState } from "@/common/hooks/useToggleCategory";
import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { ZoneCategory } from "@/contractor/pages/home/project/providers/ProjectSpendingReportItemsProvider";
import { ProjectReportType } from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import {
  ProjectReportCostCodeVendorFieldsFragment,
  ProjectReportVendorFieldsFragment,
} from "@/generated/graphql";
import { IntlShape } from "react-intl";
import { formatCSVCurrency } from "./csvReportUtils";

const EMPTY_STRING = "-";

const getVendorCostCode = (category: CategoryState<ProjectReportType>) => {
  return category as unknown as ProjectReportCostCodeVendorFieldsFragment & {
    id: string;
  };
};

const escapeCSV = (value: string) => {
  return value.replace(/,/g, " ").replace(/"/g, '""');
};

const HEADER = [
  "PROJECT_BUDGET_VENDOR_HEADER",
  "PROJECT_BUDGET_HEADER",
  "PROJECT_BUDGET_QUOTED_HEADER",
  "PROJECT_BUDGET_RECEIVED_HEADER",
  "PROJECT_BUDGET_ORDERED_HEADER",
  "PROJECT_BUDGET_INVOICED_HEADER",
  "PROJECT_BUDGET_PAID_HEADER",
  "PROJECT_BUDGET_ORDERED_VS_BUDGET_HEADER",
];

export const vendorsCSVBudgetReport = (
  csv: string[][],
  intl: IntlShape,
  zones: ZoneCategory[],
) => {
  const header = HEADER;
  csv.push(header.map((header) => intl.$t({ id: header })));
  zones.forEach((zone) => {
    if (!(zones.length === 1 && zones[0].id === UNSPECIFIED_ZONE_ID)) {
      csv.push([
        escapeCSV(`${zone.name} (${intl.$t({ id: "ZONE" })})`),
        EMPTY_STRING,
        EMPTY_STRING,
        EMPTY_STRING,
        EMPTY_STRING,
        EMPTY_STRING,
        EMPTY_STRING,
        EMPTY_STRING,
      ]);
    }
    zone.items.forEach((costCode) => {
      const code = getVendorCostCode(costCode);
      const budget = formatCSVCurrency(
        code?.allowance || code?.estimated,
        intl,
      );
      const reserved = formatCSVCurrency(code?.reserved, intl);
      const ordered = formatCSVCurrency(code?.ordered, intl);
      const invoiced = formatCSVCurrency(code?.invoiced, intl);
      const paid = formatCSVCurrency(code?.paid, intl);
      const overUnder = formatCSVCurrency(code?.overUnder, intl);
      csv.push([
        escapeCSV(`${costCode.name} (${intl.$t({ id: "COST_CODE" })})`),
        budget,
        EMPTY_STRING,
        reserved,
        ordered,
        invoiced,
        paid,
        overUnder,
      ]);

      const items = costCode.items;
      items.forEach((item) => {
        const vendor = item as ProjectReportVendorFieldsFragment;
        const vendorName = vendor.vendor.name;
        const reserved = formatCSVCurrency(vendor.reserved, intl);
        const ordered = formatCSVCurrency(vendor.ordered, intl);
        const invoiced = formatCSVCurrency(vendor.invoiced, intl);
        const paid = formatCSVCurrency(vendor.paid, intl);

        csv.push([
          escapeCSV(vendorName),
          EMPTY_STRING,
          EMPTY_STRING,
          reserved,
          ordered,
          invoiced,
          paid,
          EMPTY_STRING,
        ]);
      });
    });
  });
};
