import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { SelectableItem } from "@/common/components/searchable-list/components/SelectableItem";
import { XlContainer } from "@/common/layout/ResponsiveClasses";
import { ExternalLedgerAccountFieldsFragment } from "@/generated/graphql";
import { FormattedMessage } from "react-intl";

export const useGeneralLedgerTableConfiguration = (
  selectedItems?: string[],
): Array<
  GridCol<ExternalLedgerAccountFieldsFragment & { id: string; index: number }>
> => {
  return [
    {
      header: <FormattedMessage id="DESCRIPTION" />,
      wrapper: XlContainer,
      item: ({ item, count }) => (
        <SelectableItem
          item={item}
          index={item.index}
          selectedItems={selectedItems ?? []}
          itemNameSelectorFn={(item) => item.name}
          count={count}
        />
      ),
    },
    {
      header: <FormattedMessage id="NUMBER" />,
      wrapper: XlContainer,
      item: ({ item }) => item.number,
    },
  ];
};
