import { ProjectItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import {
  AddToBuyoutItem,
  useBuyoutProjectItemsWithUpdates,
} from "../providers/BuyoutProjectItemsProvider";
import { AddEstimatedItemGroupCheckbox } from "./AddEstimatedItemGroupCheckbox";

export const BuyoutProjectItemGroupCheckbox: FC<{
  items: ProjectItemFieldsFragment[];
}> = ({ items }) => {
  const { updates, setUpdates } = useBuyoutProjectItemsWithUpdates();

  const onToggle = useCallback(
    (itemsToAdd: AddToBuyoutItem[]) => {
      setUpdates(itemsToAdd);
    },
    [setUpdates],
  );

  return (
    <AddEstimatedItemGroupCheckbox
      items={items}
      setUpdates={onToggle}
      updates={updates}
    />
  );
};
