import { ReleaseItemFieldsFragment } from "@/generated/graphql";
import Decimal from "decimal.js";
import { ExpandedReleaseItem } from "../providers/ReleaseProvider";

export const hasUnreleasedQuantity = (
  item: ExpandedReleaseItem | ReleaseItemFieldsFragment,
) =>
  !new Decimal(item.quantityDecimal || 0).equals(
    item.receivedQuantityDecimal || 0,
  );
