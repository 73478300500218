import { useGlobalError } from "@/common/hooks/useGlobalError";
import { cleanQuery } from "@/common/utils/cacheUtils";
import {
  CreateReceiptInput,
  InvoiceStatsDocument,
  namedOperations,
  useCreateReceiptMutation,
} from "@/generated/graphql";

export const useCreateReceipt = () => {
  const [createReceiptsMutation, { loading }] = useCreateReceiptMutation();
  const { setError } = useGlobalError();

  const createReceipt = async (input: CreateReceiptInput) => {
    try {
      const { data, errors } = await createReceiptsMutation({
        variables: {
          input,
        },
        update: (cache) => cleanQuery(cache, namedOperations.Query.Receipts),
        refetchQueries: [
          {
            query: InvoiceStatsDocument,
          },
        ],
      });

      const createdReceipt = data?.createReceipt;
      setError(errors);

      return createdReceipt?.id;
    } catch (error) {
      setError(error);
    }
  };

  return { createReceipt, loading };
};
