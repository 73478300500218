import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ControlledProps } from "../textfield-controlled/TextFieldControlled";
import {
  InnerLabeledSwitch,
  InnerLabeledSwitchProps,
} from "./InnerLabeledSwitch";

type Props = Omit<
  InnerLabeledSwitchProps,
  "toggle" | "value" | "initialValue"
> &
  ControlledProps;

export const InnerLabeledSwitchControlled: FC<Props> = ({
  name,
  rules,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <InnerLabeledSwitch
          {...props}
          {...field}
          initialValue={field.value}
          toggle={field.onChange}
        />
      )}
    />
  );
};
