import { OrderTypePicker } from "@/common/components/order-type-picker/OrderTypePicker";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { VendorFormType } from "./VendorForm";

const Container = tw.div``;
const Label = tw.div`font-medium`;
const InputContainer = tw.div``;

export const VendorOrderType = () => {
  const { includeOrderTypes } = useOrderTypeOptions();
  const { watch, setValue } = useFormContext<VendorFormType>();
  const defaultOrderTypeId = watch("defaultOrderTypeId");

  const setOrderType = useCallback(
    (value: string | null) => {
      if (value) {
        setValue("defaultOrderTypeId", value);
      }
    },
    [setValue],
  );

  if (!includeOrderTypes) {
    return null;
  }

  return (
    <Container>
      <Label>
        <FormattedMessage id="DEFAULT_ORDER_TYPE" />
      </Label>
      <InputContainer>
        <OrderTypePicker
          orderTypeId={defaultOrderTypeId}
          setOrderTypeId={setOrderType}
        />
      </InputContainer>
    </Container>
  );
};
