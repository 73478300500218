import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { RfqItemFieldsFragment } from "@/generated/graphql";
import { Check, Close } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useRfqGroupedItems } from "../../hooks/useRfqGroupedItems";
import { DeleteItem } from "../DeleteItem";
import { RfqItemInstructions } from "./RfqItemInstructions";

type Props = {
  item: RfqItemFieldsFragment;
  readonly?: boolean;
};
const IconButtonContainer = tw.div`flex items-center`;

export const RfqActions: FC<Props> = ({ item, readonly = false }: Props) => {
  const { addToRfq, setNewRfqItem, newRfqItem, setIsRfqWithErrors } =
    useRfqGroupedItems();
  const { setWarningAlert } = useSnackbar();
  const intl = useIntl();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { setSuccessAlert } = useSnackbar();

  const cancel = useCallback(() => {
    setIsRfqWithErrors(false);
    setNewRfqItem(null);
  }, [setIsRfqWithErrors, setNewRfqItem]);

  const handleAddToRfq = useCallback(async () => {
    if (
      !newRfqItem?.orgCatalogSkuName &&
      !newRfqItem?.projectItem?.orgCatalogSkuId &&
      !newRfqItem?.projectItem?.masterProductId &&
      !newRfqItem?.projectItem?.masterSkuId
    ) {
      setIsRfqWithErrors(true);
      setWarningAlert(intl.$t({ id: "ERROR_SKU_DESCRIPTION" }));
      return;
    }
    if (!newRfqItem.projectItem?.estimateUom) {
      setIsRfqWithErrors(true);
      setWarningAlert(intl.$t({ id: "ERROR_UOM" }));
      return;
    }
    if (!newRfqItem.quantityDecimal) {
      setIsRfqWithErrors(true);
      setWarningAlert(intl.$t({ id: "ERROR_QUANTITY" }));
      return;
    }
    setIsRfqWithErrors(false);
    const result = await addToRfq();
    if (result) {
      setSuccessAlert(
        intl.$t(
          { id: "SUCCESSFULLY_CREATED_RFQ_CUSTOM_MATERIAL" },
          {
            costCode: <strong>{unassignedCostCode.description}</strong>,
          },
        ),
      );
    }
  }, [
    newRfqItem?.orgCatalogSkuName,
    newRfqItem?.projectItem?.orgCatalogSkuId,
    newRfqItem?.projectItem?.masterProductId,
    newRfqItem?.projectItem?.masterSkuId,
    newRfqItem?.projectItem?.estimateUom,
    newRfqItem?.quantityDecimal,
    setIsRfqWithErrors,
    addToRfq,
    setWarningAlert,
    intl,
    setSuccessAlert,
    unassignedCostCode.description,
  ]);
  const icons = useMemo(() => {
    if (!item.id) {
      return [
        <LinkLike key={0} onClick={handleAddToRfq}>
          <Check />
        </LinkLike>,
        <LinkLike key={1} onClick={cancel}>
          <Close />
        </LinkLike>,
      ];
    } else {
      return [<DeleteItem id={item.id} readonly={readonly} key={0} />];
    }
  }, [cancel, handleAddToRfq, item.id, readonly]);

  return (
    <If isTrue={!readonly}>
      <IconButtonContainer>
        <RfqItemInstructions item={item} readonly={readonly} />
        {icons.map((icon) => icon)}
      </IconButtonContainer>
    </If>
  );
};
