import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { RfqsRfqFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { RfqsListItemConfiguration } from "./RfqsListItem.configuration";
import { RfqsListItems } from "./RfqsListItems";

type Props = {
  rfqs: RfqsRfqFieldsFragment[];
  loading: boolean;
  totalCount: number;
  isFiltered?: boolean;
};

export const RfqsList: FC<Props> = ({
  rfqs,
  loading,
  totalCount,
  isFiltered,
}) => {
  return (
    <ListRenderer totalCount={totalCount} count={rfqs.length} paginated>
      <RfqsListItems
        columns={RfqsListItemConfiguration}
        items={rfqs}
        loading={loading}
        isFiltered={isFiltered}
      />
    </ListRenderer>
  );
};
