import { UomPicker } from "@/common/components/uom-picker/UomPicker";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import {
  DistributorReleaseErrorType,
  useDistributorRelease,
} from "../providers/DistributorReleaseProvider";

type Props = {
  item: DistributorReleaseItemFieldsFragment;
  readonly?: boolean;
  staticText?: boolean;
};

export const DistributorReleaseDeliveryUnits: FC<Props> = ({
  item,
  ...props
}) => {
  const { updateVendorReleaseItem, inputErrors, updatedItem } =
    useDistributorRelease();

  const saveUom = useCallback(
    async (uom?: string | null) => {
      await updateVendorReleaseItem({
        uom,
        releaseItemId: item.id,
      });
    },
    [updateVendorReleaseItem, item.id],
  );

  const error = useMemo(
    () =>
      !!(
        inputErrors.includes(DistributorReleaseErrorType.UOM) && !item.uom?.id
      ),
    [inputErrors, item.uom?.id],
  );

  if (!(updatedItem(item)?.isIncluded ?? item.isIncluded)) {
    return null;
  }

  return (
    <UomPicker
      creatable={false}
      uom={item.uom}
      saveUom={saveUom}
      error={error}
      {...props}
    />
  );
};
