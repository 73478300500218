import { Identity } from "@/types/Identity";

export const getExpandedItems = <T extends Identity>(
  item: T,
  expandedItems: string[],
) => {
  if (expandedItems.some((id) => id === item.id)) {
    return [item];
  }

  return [];
};
