import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { MoreVert } from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";

type Props = {
  active?: boolean;
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const MoreVertIconStyled = tw(MoreVert)`h-5 w-5
${({ $active }: { $active?: boolean }) =>
  $active && "rounded-sm bg-blue-500 text-white"}`;

export const ThreeDotMenu: FC<Props> = ({ active, handleClick }) => (
  <IconButtonBorderless onClick={handleClick} autoScale>
    <MoreVertIconStyled $active={active} />
  </IconButtonBorderless>
);
