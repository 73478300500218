import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";

import { SeededRandom } from "@/common/utils/seedRadom";
import { useProjectTags } from "@/contractor/pages/home/project/providers/ProjectTagsProvider";
import { darken, getLuminance, lighten } from "@mui/material";
import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { TAG_COLORS } from "../../tag-picker/TagColors";
import { useRenderHelpers } from "./useRenderHelpers";

type Tag = {
  name: string;
  color: string;
};

export const useTagRenderer = () => {
  const intl = useIntl();
  const { tags, loading } = useProjectTags();
  const { applyTooltip, checkReadOnly } = useRenderHelpers();

  const backgroundColor = (tag: Tag | string, isImported?: boolean) => {
    if (isImported) {
      return "white";
    }
    if (tag && typeof tag !== "string" && tag.color) {
      return lighten(tag.color, 0.8 - getLuminance(tag.color));
    } else {
      return "gray";
    }
  };

  const getColor = (tag: Tag) => {
    if (tag && tag.color) {
      return darken(tag.color, 0.65);
    } else {
      return "darkgray";
    }
  };

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      textRenderer(instance, td, row, col, prop, value, cellProperties);
      if (value) {
        const tag = tags.find((tag) => tag.name === value);
        const isImported = !!tag?.mapping?.sourceSystem;
        const tagContainer = document.createElement("div");
        const tagName = document.createElement("div");
        tagContainer.className = `relative inline-flex py-1 pl-2 pr-3
          rounded-l rounded-r-4xl text-white font-normal items-center 
          justify-between whitespace-nowrap w-fit max-w-[80%] 
          ${isImported ? "border border-l-4" : ""}
        `;
        tagName.className = "truncate text-xs font-medium";

        const random = new SeededRandom(value);
        const color =
          tag?.color ?? TAG_COLORS[random.nextInt(TAG_COLORS.length)];
        tagContainer.style.borderColor = backgroundColor(tag ?? value);
        tagContainer.style.backgroundColor = backgroundColor(
          { name: tag?.name ?? value, color },
          isImported,
        );
        tagContainer.style.color = getColor({
          name: tag?.name ?? value,
          color,
        });
        tagName.innerText = value;
        td.innerText = "";
        tagContainer.appendChild(tagName);
        td.appendChild(tagContainer);

        if (!tag && !loading) {
          td.className = "htCenter htMiddle text-blue-800 bg-blue-100";
          const sup = document.createElement("sup");
          sup.className = "text-blue-500 font-semibold text-[8px] ml-1";
          sup.innerText = intl.formatMessage({ id: "NEW" });
          tagContainer.appendChild(sup);
        }

        applyTooltip(tagContainer, value);
      }
      checkReadOnly(td, row, instance, cellProperties);
    },
    [applyTooltip, checkReadOnly, intl, loading, tags],
  );

  return renderer;
};
