import { Identity } from "@/types/Identity";
import { Check } from "@mui/icons-material";
import tw from "tailwind-styled-components";
import { CircleCounter } from "../../circle-counter/CircleCounter";
import { CountType } from "../../circle-counter/CountType";
import { If } from "../../if/If";

const Container = tw.div`flex flex-row items-center
  ${({ $disabled }: { $disabled?: boolean }) => ($disabled ? "opacity-50" : "")}
`;
const StyledCheck = tw(Check)`mr-2 h-6 w-6 text-green-600`;
const CircleCounterStyled = tw(CircleCounter)`mr-2
  ${({ $visible }: { $visible: boolean }) => (!$visible ? "hidden" : "")}
`;

type Props<T extends Identity> = {
  selectedItems: string[];
  index: number | undefined;
  item: T;
  itemNameSelectorFn: (item: T) => string;
  disabled?: boolean;
  count: CountType;
};

export const SelectableItem = <T extends Identity>({
  selectedItems,
  index,
  item,
  itemNameSelectorFn,
  disabled,
  count,
}: Props<T>) => {
  return (
    <Container $disabled={disabled}>
      <If isTrue={(selectedItems ?? []).includes(item.id)}>
        <StyledCheck />
      </If>
      <CircleCounterStyled
        count={count}
        key={index}
        $visible={
          index !== undefined && !(selectedItems ?? []).includes(item.id)
        }
      />
      {itemNameSelectorFn(item)}
    </Container>
  );
};
