import { DeleteButton } from "@/common/components/button/DeleteButton";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useEstimatedItems } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import { ReleaseItemFieldsFragment } from "@/generated/graphql";
import { Check, Close } from "@mui/icons-material";
import { FC, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useCreateNewOrgCatalogSku } from "../../../rfq/components/add-org-sku/hooks/useCreateNewOrgCatalogSku";
import { useReleaseMutations } from "../../pages/specify-details/hooks/useReleaseMutations";
import { useRelease } from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";
import { AssignCostCodeDialog } from "./AssignCostCodeDialog";
import { AssignZoneDialog } from "./AssignZoneDialog";
import { ReleaseItemInstructions } from "./ReleaseItemInstructions";

const DeleteContainer = tw.div`w-6`;

type Props = {
  item: ReleaseItemFieldsFragment;
  readonly?: boolean;
};

export const ReleaseItemActions: FC<Props> = ({ item, readonly }: Props) => {
  const { removeReleaseItem, addItems } = useReleaseUpdate();
  const { removingReleaseItemIds } = useReleaseMutations();
  const { newProjectEstimatedItem, cancelEstimatedItem } = useEstimatedItems();
  const { setWarningAlert } = useSnackbar();
  const intl = useIntl();
  const { release } = useRelease();
  const [assignZoneDialogOpen, setAssignZoneDialogOpen] = useState(false);
  const [assignCostCodeOpen, setAssignCostCodeOpen] = useState(false);
  const { createNewOrgCatalogSku } = useCreateNewOrgCatalogSku();

  const cancel = useCallback(() => {
    cancelEstimatedItem();
  }, [cancelEstimatedItem]);

  const handleAddToRelease = useCallback(async () => {
    if (
      !newProjectEstimatedItem?.orgCatalogSkuName &&
      !newProjectEstimatedItem?.orgCatalogSkuId &&
      !newProjectEstimatedItem?.masterProductId &&
      !newProjectEstimatedItem?.masterSkuId
    ) {
      setWarningAlert(intl.$t({ id: "ERROR_SKU_DESCRIPTION" }));
      return;
    }
    if (!newProjectEstimatedItem?.uom) {
      setWarningAlert(intl.$t({ id: "ERROR_UOM" }));
      return;
    }
    if (!newProjectEstimatedItem?.quantityDecimal) {
      setWarningAlert(intl.$t({ id: "ERROR_QUANTITY" }));
      return;
    }

    let skuId = newProjectEstimatedItem.orgCatalogSkuId;
    if (
      !skuId &&
      newProjectEstimatedItem.orgCatalogSkuName &&
      newProjectEstimatedItem.uom
    ) {
      skuId = await createNewOrgCatalogSku({
        name: newProjectEstimatedItem.orgCatalogSkuName,
        defaultUom: newProjectEstimatedItem.uom,
      });
    }
    await addItems([
      {
        projectItem: {
          orgCatalogSkuId: skuId,
          estimateUom: newProjectEstimatedItem.uom,
          masterProductId: newProjectEstimatedItem.masterProductId,
          masterSkuId: newProjectEstimatedItem.masterSkuId,
        },
        manufacturerId: newProjectEstimatedItem.manufacturerId,
        quantityDecimal: newProjectEstimatedItem.quantityDecimal,
        tags: newProjectEstimatedItem.tags,
        unitPrice: newProjectEstimatedItem.unitPrice,
        position: release?.items.length || 0,
      },
    ]);
    cancelEstimatedItem();
  }, [
    newProjectEstimatedItem.orgCatalogSkuName,
    newProjectEstimatedItem.orgCatalogSkuId,
    newProjectEstimatedItem.masterProductId,
    newProjectEstimatedItem.masterSkuId,
    newProjectEstimatedItem.uom,
    newProjectEstimatedItem.quantityDecimal,
    newProjectEstimatedItem.manufacturerId,
    newProjectEstimatedItem.tags,
    newProjectEstimatedItem.unitPrice,
    addItems,
    release?.items.length,
    cancelEstimatedItem,
    setWarningAlert,
    intl,
    createNewOrgCatalogSku,
  ]);

  const actions = useMemo(() => {
    if (!item?.id) {
      return [
        <LinkLike key={0} onClick={handleAddToRelease}>
          <Check />
        </LinkLike>,
        <LinkLike key={1} onClick={cancel}>
          <Close />
        </LinkLike>,
      ];
    } else {
      return [
        <ReleaseItemInstructions key={0} item={item} readonly={readonly} />,
        <DeleteContainer key={1}>
          <If isTrue={item.invoiceItems.length === 0}>
            <DeleteButton
              onClick={() => {
                if (!item) {
                  return;
                }
                removeReleaseItem(item?.id);
              }}
              disabled={readonly}
              loading={removingReleaseItemIds?.includes(item?.id)}
            />
          </If>
        </DeleteContainer>,
      ];
    }
  }, [
    item,
    handleAddToRelease,
    cancel,
    readonly,
    removingReleaseItemIds,
    removeReleaseItem,
  ]);

  return (
    <>
      {actions}
      {assignZoneDialogOpen ? (
        <AssignZoneDialog
          visible={assignZoneDialogOpen}
          setVisible={setAssignZoneDialogOpen}
          item={item}
        />
      ) : null}
      {assignCostCodeOpen ? (
        <AssignCostCodeDialog
          visible={assignCostCodeOpen}
          setVisible={setAssignCostCodeOpen}
          item={item}
        />
      ) : null}
    </>
  );
};
