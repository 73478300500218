import { Instructions } from "@/common/components/instructions/Instructions";
import { ReleaseDeliverySlipAndPhotos } from "@/common/components/release-images/ReleaseDeliverySlipAndPhotos";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { DistributorReleaseItemsZoneProvider } from "../../providers/DistributorReleaseItemsZoneProvider";
import {
  DistributorReleaseProvider,
  useDistributorRelease,
} from "../../providers/DistributorReleaseProvider";
import { DistributorReleaseDeliveryRecordFooter } from "./footer/DistributorReleaseDeliveryRecordFooter";
import { DistributorReleaseDeliveryRecordHeader } from "./header/DistributorReleaseDeliveryRecordHeader";
import { DistributorReleaseDeliveryRecordList } from "./list/DistributorReleaseDeliveryRecordList";
import { DistributorReleaseDeliveryRecordProvider } from "./providers/DistributorReleaseDeliveryRecordProvider";

const Container = tw.div``;

const DistributorReleaseDeliveryRecordWithProvider: FC = () => {
  const { release } = useDistributorRelease();

  if (!release) {
    return null;
  }

  return (
    <DistributorReleaseItemsZoneProvider items={release?.items || []}>
      <Container>
        <DistributorReleaseDeliveryRecordHeader release={release} />
        <DistributorReleaseDeliveryRecordList />
        <Instructions
          instruction={release.instructions}
          projectId={release.project?.id}
        />
        <ReleaseDeliverySlipAndPhotos release={release} />
        <DistributorReleaseDeliveryRecordFooter />
      </Container>
    </DistributorReleaseItemsZoneProvider>
  );
};

export const DistributorReleaseDeliveryRecord: FC = () => {
  return (
    <DistributorReleaseProvider>
      <DistributorReleaseDeliveryRecordProvider>
        <DistributorReleaseDeliveryRecordWithProvider />
      </DistributorReleaseDeliveryRecordProvider>
    </DistributorReleaseProvider>
  );
};
