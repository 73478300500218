import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  itemsLength?: number;
  $bold?: boolean;
};
const ProductCategoryCountContainer = tw.div`
  text-xs text-center
  ${({ $bold }: Props) => ($bold ? "font-medium" : "")}
$`;

export const ProductCategoryCount: FC<Props> = ({
  itemsLength = 0,
  $bold = false,
}) => {
  return (
    <ProductCategoryCountContainer $bold={$bold}>
      <FormattedMessage id="COUNT_PRODUCTS" values={{ count: itemsLength }} />
    </ProductCategoryCountContainer>
  );
};
