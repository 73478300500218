import { CategoriesProvider } from "@/common/components/category-filter/CategoriesProvider";
import { Loader } from "@/common/components/loader/Loader";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { DistributorQuoteBase } from "./DistributorQuoteBase";
import { DistributorQuoteList } from "./components/DistributorQuoteList";
import { DistributorQuoteFooter } from "./components/footer/DistributorQuoteFooter";
import { DistributorQuoteItemsProvider } from "./providers/DistributorQuoteItemsProvider";
import {
  DistributorQuoteProvider,
  useDistributorQuote,
} from "./providers/DistributorQuoteProvider";
import { QuoteActionsProvider } from "./providers/QuoteActionsProvider";

const Container = tw.div`pb-15`;

const DistributorQuoteWithProvider: FC = () => {
  const { quote, loading, paymentTermOptions } = useDistributorQuote();
  const intl = useIntl();

  if (!quote || !paymentTermOptions) {
    return <Loader loading={loading} />;
  }

  return (
    <Container>
      <DistributorQuoteBase quote={quote}>
        <Helmet>
          <title>
            {intl.$t(
              { id: "QUOTE_NUMBER" },
              { number: quote.clientIdentifier },
            )}
          </title>
        </Helmet>
        <DistributorQuoteItemsProvider quote={quote}>
          <DistributorQuoteList />
          <DistributorQuoteFooter />
        </DistributorQuoteItemsProvider>
      </DistributorQuoteBase>
    </Container>
  );
};

export const DistributorQuote: FC = () => (
  <DistributorQuoteProvider>
    <QuoteActionsProvider>
      <CategoriesProvider>
        <DistributorQuoteWithProvider />
      </CategoriesProvider>
    </QuoteActionsProvider>
  </DistributorQuoteProvider>
);
