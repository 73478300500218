import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { joinRfqVendors } from "@/contractor/pages/admin/vendors/utils/utils";
import { useSyncRfqItems } from "@/contractor/pages/home/rfq/providers/SyncRfqItemsProvider";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRfq } from "../../../rfq/hooks/useRfq";

type SendToVendorsRfqButtonProps = {
  spreadsheetData: Record<string, string>[];
};

export const SendToVendorsRfqButton: FC<SendToVendorsRfqButtonProps> = ({
  spreadsheetData,
}) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { setSuccessAlert } = useSnackbar();
  const { rfq, submit, isSubmitting } = useRfq();
  const { vendors } = useVendors();
  const { syncRfqItems } = useSyncRfqItems();
  const { rowIsEmpty } = useTableHelpers();

  const submitRfq = useCallback(async () => {
    if (await submit()) {
      setSuccessAlert(
        intl.$t(
          {
            id: "RFQ_SENT_TO_VENDORS_SUCCESS",
          },
          {
            rfqNumber: strongify(rfq?.clientIdentifier),
            vendors: strongify(joinRfqVendors(vendors, rfq)),
          },
        ),
      );
    }
  }, [submit, setSuccessAlert, intl, rfq, vendors]);

  const isSendToVendorEnabled = useMemo(() => {
    return (
      (rfq && rfq.items.length > 0) ||
      (spreadsheetData.length > 0 &&
        spreadsheetData.some((row) => !rowIsEmpty(row)))
    );
  }, [rfq, spreadsheetData, rowIsEmpty]);

  return (
    <PrimaryButton
      disabled={!isSendToVendorEnabled}
      onClick={async () => {
        const result = await syncRfqItems();
        if (!result) {
          return;
        }
        openDialog({
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "SEND" }),
          icon: <WarningIcon />,
          title: intl.$t(
            { id: "RFQ_SEND_TO_VENDORS_TITLE" },
            { number: rfq?.clientIdentifier },
          ),
          text: intl.$t({ id: "RFQ_SEND_TO_VENDORS_TEXT" }),
          handleConfirm: submitRfq,
        });
      }}
      testId="rfq-send-to-vendors-button"
      loading={isSubmitting}
    >
      <FormattedMessage id="RFQ_SEND_TO_VENDORS" />
    </PrimaryButton>
  );
};
