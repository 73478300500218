export const tableToCsvString = (tableId: string) => {
  const separator = ",";
  const rows = document.querySelectorAll(`#${tableId} tr`);
  const cleanups = [
    [/("[ ]+")/gm, '""'],
    [/(\d),(\d)/gm, "$1.$2"],
    [/(\d+)\.(\d+)\.(\d)/gm, "$1$2.$3"],
    [/(\d+)\.(\d+)\.(\d)/gm, "$1$2.$3"],
    [/"[\s+]([\w])/gm, '"$1'],
    [/([\w])[\s+]"/gm, '$1"'],
    [/(\n[",]+\n)/gm, "\n"],
    [/(\n[",]+$)/gm, ""],
  ];
  const csv = [];
  for (let i = 0; i < rows.length; i++) {
    const row = [];
    const cols = rows[i].children;

    for (let j = 0; j < cols.length; j++) {
      const data = (cols[j] as HTMLElement).innerText
        .replace(/(\r\n|\n|\r)/gm, "")
        .replace(/(\s\s)/gm, " ")
        .replace(/"/g, '""');
      row.push('"' + data + '"');
    }
    csv.push(row.join(separator));
  }

  let csvString = csv.join("\n");
  cleanups.forEach((c) => {
    csvString = csvString.replace(c[0], c[1] as string);
  });

  return csvString;
};
