import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { FC, useEffect, useState } from "react";
import { PiBellFill } from "react-icons/pi";
import tw from "tailwind-styled-components";
import { useNotifications } from "../providers/NotificationsProvider";
import { NotificationCount } from "./NotificationCount";
import { NotificationsList } from "./NotificationsList";

const NotificationsIconContainer = tw.div`
  relative cursor-pointer
`;

const NotificationsIcon = tw(PiBellFill)`
  text-2xl mr-2.5 text-blue-200 cursor-pointer hover:text-gray-100
`;

export const NotificationsButton: FC = () => {
  const { notifications } = useNotifications();

  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    setUnreadCount(notifications.filter((n) => !n.readAt).length);
  }, [notifications]);

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <NotificationsIconContainer onClick={() => togglePanel(true)}>
          <NotificationsIcon />
          <NotificationCount
            unreadCount={unreadCount}
            className="absolute -top-2 right-1 h-5 w-5"
          />
        </NotificationsIconContainer>
      )}
      content={(togglePanel) => (
        <NotificationsList onClose={() => togglePanel(false)} />
      )}
    />
  );
};
