import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { routes } from "@/config/routes";
import {
  DistributorBuyoutReleaseFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";

export const DeliveryActions: FC<{
  release: Pick<
    DistributorBuyoutReleaseFieldsFragment,
    "status" | "id" | "issues"
  >;
}> = ({ release }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleConfirm = useCallback(() => {
    navigate(
      generatePath(routes.distributorDelivery, { deliveryId: release.id }) +
        location.search,
    );
  }, [navigate, release.id]);

  const handleResolve = useCallback(() => {
    navigate(
      generatePath(routes.distributorDeliveryRecord, {
        deliveryId: release.id,
      }) + location.search,
    );
  }, [navigate, release.id]);

  if (
    release.issues.length > 0 &&
    release.issues.some((issue) => !issue.resolution)
  ) {
    return (
      <ButtonsContainer>
        <PrimaryButton onClick={handleResolve}>
          {intl.$t({ id: "RESOLVE" })}
        </PrimaryButton>
      </ButtonsContainer>
    );
  }

  if (release.status === ReleaseStatus.Scheduled) {
    return (
      <PrimaryButton onClick={handleConfirm}>
        {intl.$t({ id: "UPDATE_RELEASE" })}
      </PrimaryButton>
    );
  }

  if (
    release.status === ReleaseStatus.Draft ||
    release.status === ReleaseStatus.Canceled ||
    release.status === ReleaseStatus.Received ||
    release.issues.every((issue) => !!issue.resolution)
  ) {
    return null;
  }

  return (
    <ButtonsContainer>
      <PrimaryButton onClick={handleConfirm}>
        {intl.$t({ id: "CONFIRM_VENDOR_DELIVERY" })}
      </PrimaryButton>
    </ButtonsContainer>
  );
};
