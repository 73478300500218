import { Tabs } from "@/common/components/tabs/Tabs";
import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import { OrgRole, SystemRole } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const AdminNavigation = () => {
  const intl = useIntl();
  const { viewer } = useUser();

  const tabs = useMemo(() => {
    if (
      viewer?.orgRoles.includes(OrgRole.OrgRestrictedAdmin) &&
      !viewer.orgRoles.includes(OrgRole.OrgAdmin) &&
      !viewer.systemRoles.includes(SystemRole.FmAdmin)
    ) {
      return [
        {
          label: intl.$t({ id: "VENDORS" }),
          path: routes.vendors,
        },
        {
          label: intl.$t({ id: "ITEM_DATABASE" }),
          path: routes.itemDatabase,
        },
      ];
    }

    const adminTabs = [
      {
        label: intl.$t({ id: "ORGANIZATION" }),
        path: routes.orgProfile,
        skipCase: true,
      },
      {
        label: intl.$t({ id: "LOCATIONS" }),
        path: routes.locations,
      },
      {
        label: intl.$t({ id: "WAREHOUSES" }),
        path: routes.warehouses,
      },
      {
        label: intl.$t({ id: "USERS" }),
        path: routes.users,
      },
      {
        label: intl.$t({ id: "VENDORS" }),
        path: routes.vendors,
      },
      {
        label: intl.$t({ id: "ORDERS" }),
        path: routes.adminOrders,
        skipCase: true,
      },
      {
        label: intl.$t({ id: "ITEM_DATABASE" }),
        path: routes.itemDatabase,
        skipCase: true,
      },
      {
        label: intl.$t({ id: "COST_STRUCTURE" }),
        path: routes.costStructure,
        skipCase: true,
      },
      {
        label: intl.$t({ id: "CONNECTIONS" }),
        path: routes.connections,
      },
    ];

    if (viewer?.systemRoles.includes(SystemRole.FmAdmin)) {
      adminTabs.push({
        label: intl.$t({ id: "ORGS" }),
        path: routes.orgs,
      });
      adminTabs.push({
        label: intl.$t({ id: "GMV_REPORT" }),
        path: routes.gmvReport,
      });
    }

    return adminTabs;
  }, [intl, viewer]);

  return <Tabs tabs={tabs} narrow className="-top-14 -mt-16 pt-8" />;
};
