import { COLUMN_TYPE } from "@/common/providers/ColumnMapperProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { Identity } from "@/types/Identity";
import Handsontable from "handsontable";
import { CellChange, ChangeSource } from "handsontable/common";
import { ColumnSettings } from "handsontable/settings";
import { useCallback } from "react";
import { CUSTOM_SOURCE, useTableHelpers } from "./useTableHelpers";

export const useCellEditHelpers = () => {
  const { getPhysicalColumnIndex } = useTableHelpers();
  const capDecimalCount = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: (CellChange | null)[],
      source: ChangeSource,
    ) => {
      if (!hotInstance) {
        return;
      }

      if ([CUSTOM_SOURCE, "edit", "CopyPaste.paste"].includes(source)) {
        changes
          ?.filter((change) => change !== null)
          .forEach((change) => {
            if (!change) {
              return;
            }
            const [, , , newVal] = change;
            if (
              newVal !== null &&
              newVal !== undefined &&
              newVal !== "" &&
              (typeof newVal === "number" || !isNaN(newVal))
            ) {
              const roundedValue = Math.round(Number(newVal) * 1e6) / 1e6;
              change[3] = roundedValue;
            }
          });
      }
    },
    [],
  );

  const preventReadOnlyChanges = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: (CellChange | null)[],
      source: ChangeSource,
    ) => {
      if (!hotInstance) {
        return;
      }

      if (["edit", "CopyPaste.paste"].includes(source)) {
        changes
          ?.filter((change) => change !== null)
          .forEach((change) => {
            if (!change) {
              return;
            }
            const [row, column, oldValue] = change;
            const cellMeta = hotInstance.getCellMeta(
              row,
              getPhysicalColumnIndex(hotInstance, column as COLUMN_TYPE),
            );
            const physicalRow = hotInstance?.toPhysicalRow(row) || row;
            const sourceRow = hotInstance.getSourceDataAtRow(
              physicalRow,
            ) as Identity;
            if (
              !cellMeta.ignoreReadOnly &&
              cellMeta.readOnlyFn &&
              cellMeta.readOnlyFn(sourceRow?.id)
            ) {
              change[3] = oldValue;
            }
          });
      }
    },
    [getPhysicalColumnIndex],
  );

  const removePriceSymbols = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: (CellChange | null)[],
      source: ChangeSource,
      columnsConfig: ColumnSettings[],
    ) => {
      if (!hotInstance) {
        return;
      }

      if ([CUSTOM_SOURCE, "edit", "CopyPaste.paste"].includes(source)) {
        changes
          ?.filter((change) => change !== null)
          .forEach((change) => {
            if (!change) {
              return;
            }
            const [, column, , newVal] = change;
            const type = columnsConfig.find(
              (c) => c.columnType === column,
            )?.type;
            if (
              newVal !== null &&
              newVal !== undefined &&
              newVal !== "" &&
              (typeof newVal === "string" || isNaN(newVal)) &&
              (type === "numeric" || type === "date")
            ) {
              const roundedValue = newVal.replace(/[,$£]/g, "");
              change[3] = roundedValue;
            }
          });
      }
    },
    [],
  );

  const sanitizedInvalidNumbers = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: (CellChange | null)[],
      source: ChangeSource,
      columnsConfig: ColumnSettings[],
    ) => {
      if (!hotInstance) {
        return;
      }

      if ([CUSTOM_SOURCE, "edit", "CopyPaste.paste"].includes(source)) {
        changes
          ?.filter((change) => change !== null)
          .forEach((change) => {
            if (!change) {
              return;
            }

            const [, column, , newVal] = change;

            const type = columnsConfig.find(
              (c) => c.columnType === column,
            )?.type;
            if (
              newVal !== null &&
              newVal !== undefined &&
              newVal !== "" &&
              (typeof newVal === "string" || isNaN(newVal)) &&
              type === "numeric"
            ) {
              const sanitizedValue = newVal.replace(/[^0-9.]/g, "");
              change[3] = sanitizedValue;
            }
          });
      }
    },
    [],
  );

  const preventLumpSumChanges = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: (CellChange | null)[],
      source: ChangeSource,
    ) => {
      if (!hotInstance) {
        return;
      }

      if (["edit", "CopyPaste.paste"].includes(source)) {
        changes
          ?.filter((change) => change !== null)
          .forEach((change) => {
            if (!change) {
              return;
            }
            const [row, column, oldValue] = change;
            const cellMeta = hotInstance.getCellMeta(
              row,
              getPhysicalColumnIndex(hotInstance, column as COLUMN_TYPE),
            );
            const physicalRow = hotInstance?.toPhysicalRow(row) || row;
            const rows = hotInstance?.getData() as Record<string, string>[];
            const uom =
              rows[physicalRow][
                getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.UOM)
              ];
            const isLumpSum = isLumpSumUomText(uom);

            if (
              !cellMeta.ignoreReadOnly &&
              isLumpSum &&
              cellMeta.disabledForLumpSum
            ) {
              change[3] = oldValue;
            }
          });
      }
    },
    [getPhysicalColumnIndex],
  );

  const updateActivePriceEditorForLumpSum = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      column: number,
      row: number,
    ) => {
      if (!hotInstance) {
        return;
      }

      const physicalRow = hotInstance?.toPhysicalRow(row) || row;
      const rows = hotInstance?.getData() as Record<string, string>[];
      const uom =
        rows[physicalRow][getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.UOM)];
      const isLumpSum = isLumpSumUomText(uom);

      if (
        isLumpSum &&
        (column ===
          getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.PrefilledPrice) ||
          column === getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.UnitPrice))
      ) {
        const quantity =
          rows[row][getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Quantity)];
        hotInstance?.getActiveEditor()?.setValue(quantity);
      }
    },
    [getPhysicalColumnIndex],
  );

  return {
    capDecimalCount,
    preventReadOnlyChanges,
    removePriceSymbols,
    sanitizedInvalidNumbers,
    preventLumpSumChanges,
    updateActivePriceEditorForLumpSum,
  };
};
