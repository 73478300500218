import { If } from "@/common/components/if/If";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { InvoiceSummaryFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import {
  InvoiceSequenceData,
  useInvoiceSequence,
} from "../../providers/InvoiceSequenceProvider";
import { useInvoices } from "../../providers/InvoicesProvider";

const Container = tw.div`
  flex -ml-2
`;

export const InvoiceAllItemsCheckbox: FC = () => {
  const { sequenceIds, setSelectedInvoices, selectedInvoices } =
    useInvoiceSequence();
  const { invoices } = useInvoices();
  const filterItems = useCallback(
    (invoice: InvoiceSummaryFieldsFragment) => invoice.permissions.export,
    [],
  );
  const checked = useMemo(
    () =>
      invoices
        .filter(filterItems)
        .every((invoice) =>
          selectedInvoices.find(
            (selectedInvoice) => selectedInvoice.id === invoice.id,
          ),
        ),
    [filterItems, invoices, selectedInvoices],
  );
  const handleChange = useCallback(() => {
    const filteredInvoices = invoices.filter(filterItems);
    setSelectedInvoices(
      checked
        ? selectedInvoices.filter(
            (invoice) =>
              !filteredInvoices.find(
                (filteredInvoice) => filteredInvoice.id === invoice.id,
              ),
          )
        : ([
            ...selectedInvoices.filter((invoice) =>
              filteredInvoices.find(
                (filteredInvoice) => filteredInvoice.id === invoice.id,
              ),
            ),
            ...filteredInvoices,
          ] as InvoiceSequenceData[]),
    );
  }, [checked, filterItems, invoices, selectedInvoices, setSelectedInvoices]);

  return (
    <Container>
      <If isTrue={invoices.some(filterItems)}>
        <SelectionCheckbox
          items={sequenceIds}
          setSelection={handleChange}
          checked={checked}
        />
      </If>
    </Container>
  );
};
