import { useMemo } from "react";
import { useIntl } from "react-intl";
import { DEFAULT_PAYMENT_OPTIONS } from "../const";

export type PaymentTerm = {
  id: number;
  name: string;
};

export const usePaymentTerms = () => {
  const intl = useIntl();

  const paymentTermsOptions: PaymentTerm[] = useMemo(() => {
    return DEFAULT_PAYMENT_OPTIONS.map((opt) => ({
      name: intl.$t({ id: "PAYMENT_TERM_NET_WITH_DAYS" }, { days: opt }),
      id: opt,
    }));
  }, [intl]);

  return { paymentTermsOptions };
};
