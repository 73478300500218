import { If } from "@/common/components/if/If";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { MatchGroup } from "@/common/components/invoices/invoice-details/Invoice.styles";
import { InvoiceDetails } from "@/common/components/invoices/invoice-details/InvoiceDetails";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { Loader } from "@/common/components/loader/Loader";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { getUTCDate } from "@/common/utils/dates/getUTCDate";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { WarehousesProvider } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import {
  ReleaseProvider,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { AuthorizationStatus } from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ContractorBuyoutProvider } from "../../../buyout/providers/ContractorBuyoutProvider";
import { useProjectListOptions } from "../../../projects/hooks/useProjectListOptions";
import { ReleaseUpdateProvider } from "../../../release/providers/ReleaseUpdateProvider";
import { SplittingInvoicesWizardProvider } from "../scanned-invoices/components/splitting-invoices/SplittingInvoicesWizardProvider";
import { InvoiceCreationProvider } from "../scanned-invoices/providers/InvoiceCreationProvider";
import { InvoiceSequenceProvider } from "../scanned-invoices/providers/InvoiceSequenceProvider";
import { InvoiceValidationProvider } from "../scanned-invoices/providers/InvoiceValidationProvider";
import { dateDiffInDays } from "../scanned-invoices/utils/dateDiff";
import { InvoiceBreadcrumbs } from "./components/breadcrumbs/InvoiceBreadcrumbs";
import { InvoiceFooter } from "./components/footer/InvoiceFooter";
import { InvoiceMatchedOrder } from "./components/matched-order/InvoiceMatchedOrder";
import {
  InvoiceCreateReleaseFormValues,
  InvoiceVerificationForm,
} from "./components/matched-order/components/InvoiceVerificationForm";
import { useInvoiceCreateReleaseSpreadsheetConfig } from "./components/matched-order/components/invoice-create-release/InvoiceCreateRelease.config";
import { InvoiceImportExternalPOWizard } from "./components/matched-order/components/invoice-import-external-po/InvoiceImportExternalPOWizard";
import { useInvoiceUpdateReleaseSpreadsheetConfig } from "./components/matched-order/components/invoice-update-release/InvoiceUpdateRelease.config";
import { useInvoiceSuggestedOrders } from "./hooks/useInvoiceSuggestedOrders";
import { useInvoiceViewAutoSelection } from "./hooks/useInvoiceViewAutoSelection";
import { InvoiceCreateReleaseProvider } from "./providers/InvoiceCreateReleaseProvider";
import {
  InvoiceImportExternalPOProvider,
  useInvoiceImportExternalPO,
} from "./providers/InvoiceImportExternalPoProvider";
import {
  InvoiceMatchedOrderProvider,
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "./providers/InvoiceMatchedOrderProvider";
import { InvoiceUpdateReleaseProvider } from "./providers/InvoiceUpdateReleaseProvider";
import {
  InvoiceVerificationProvider,
  useInvoiceVerification,
} from "./providers/InvoiceVerificationProvider";
import { InvoiceImportedExternalPOValues } from "./providers/enums/InvoiceImportedExternalPOValues";

const Container = tw.div`
  relative mt-5 xl:-mx-10
`;

const InvoiceVerificationProviderWithProvider: FC = () => {
  const intl = useIntl();
  const {
    invoice,
    updateInvoice,
    footerState,
    hasError,
    rescanInvoice,
    setFooterState,
  } = useInvoiceVerification();

  const { setMatchedOrderViewState, setPreselectedPoNumber } =
    useInvoiceMatchedOrder();
  const { connectedSourceSystem } = useOrgSettings();
  const { release } = useRelease();
  const { projects } = useProjectListOptions();
  const { importModalOpened, importedExternalPOValues, externalPOsQueryForm } =
    useInvoiceImportExternalPO();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const { watch, setValue } = useFormContext<InvoiceCreateReleaseFormValues>();

  const subtotal = watch("subtotal");
  const additionalCharges = watch("additionalCharges");
  const customTaxAmount = watch("customTaxAmount");
  const taxRate = watch("taxRate");
  const projectId = watch("projectId");

  useEffect(() => {
    const selectedProject = projects.some(
      (p) =>
        p.mappings.some((m) => m.sourceSystem === connectedSourceSystem) &&
        p.id === projectId,
    );
    if (selectedProject) {
      externalPOsQueryForm?.setValue("projectId", projectId ?? undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    const additionalChargesValue = (additionalCharges ?? [])
      .reduce((acc, charge) => {
        return acc.plus(charge.amount);
      }, new Decimal(0))
      .toNumber();

    const customTaxAmountValue = Number(customTaxAmount ?? 0);
    const taxRateValue = taxRate
      ? (Number(subtotal ?? 0) + additionalChargesValue) * Number(taxRate)
      : 0;

    setValue(
      "total",
      `${
        Number(subtotal ?? 0) +
        additionalChargesValue +
        customTaxAmountValue +
        taxRateValue
      }`,
    );
  }, [
    subtotal,
    additionalCharges,
    customTaxAmount,
    taxRate,
    invoice?.chargesAmount,
    setValue,
  ]);

  useEffect(() => {
    if (
      invoice?.poNumber &&
      !importedExternalPOValues.includes(
        InvoiceImportedExternalPOValues.PO_NUMBER,
      ) &&
      includePoNumbering
    ) {
      setValue("poNumber", invoice.poNumber);
    }
    if (invoice?.issueDate) {
      setValue("orderDate", defaultReleaseDate(getUTCDate(invoice.issueDate)));
    }

    if (
      (release?.additionalCharges ||
        invoice?.chargesAmount ||
        (invoice?.charges ?? []).length > 0) &&
      !importedExternalPOValues.includes(
        InvoiceImportedExternalPOValues.ADDITIONAL_CHARGES,
      )
    ) {
      setValue(
        "additionalCharges",
        release?.additionalCharges.length
          ? release?.additionalCharges
          : invoice?.chargesAmount
            ? [
                {
                  amount: invoice?.chargesAmount,
                  description: intl.$t({ id: "ADDITIONAL_CHARGES" }),
                },
              ]
            : (invoice?.charges ?? []),
      );
    }

    if (invoice?.dueDate && invoice.issueDate) {
      const paymentTerm = dateDiffInDays(invoice.dueDate, invoice.issueDate);
      setValue("paymentTerm", paymentTerm?.toString());
    }

    if (
      invoice?.taxAmount &&
      !importedExternalPOValues.includes(
        InvoiceImportedExternalPOValues.SALES_TAX,
      )
    ) {
      setValue(
        "customTaxAmount",
        release?.customTaxAmount && release?.customTaxAmount !== "0"
          ? release?.customTaxAmount
          : invoice.taxAmount,
      );
    }

    if (invoice?.subtotal) {
      setValue(
        "subtotal",
        release?.subtotal ? Number(release.subtotal) : Number(invoice.subtotal),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice, release]);

  const readonly = useMemo(() => {
    return (
      invoice?.permissions.edit !== AuthorizationStatus.Authorized ||
      !!invoice.archivedAt
    );
  }, [invoice]);

  const invoicePoNumberReadonly = useMemo(
    () =>
      invoice?.permissions.edit !== AuthorizationStatus.Authorized ||
      !!invoice.archivedAt,
    [invoice],
  );

  const hasFooter = useMemo(() => {
    if (!invoice) {
      return false;
    }

    if (invoice.permissions.archive) {
      return true;
    }

    return !!(
      footerState === InvoiceFooterState.CREATE_ORDER ||
      footerState === InvoiceFooterState.MATCH_ORDER ||
      footerState === InvoiceFooterState.EDIT_ORDER
    );
  }, [invoice, footerState]);

  const autoSelectFn = useCallback(
    (poNumber: string) => {
      if (poNumber && footerState === InvoiceFooterState.MATCH_ORDER) {
        setFooterState(InvoiceFooterState.MATCH_ORDER);
        setMatchedOrderViewState(MatchedOrderViewState.MATCH_ORDER);
        setPreselectedPoNumber(poNumber);
      }
    },
    [
      setFooterState,
      setMatchedOrderViewState,
      setPreselectedPoNumber,
      footerState,
    ],
  );

  return (
    <Container>
      <InvoiceBreadcrumbs />
      <MatchGroup $hasFooter={hasFooter} $editState={footerState}>
        <InvoiceMatchedOrder />
        <InvoiceDetails
          invoice={invoice}
          updateInvoice={updateInvoice}
          rescanInvoice={rescanInvoice}
          pricesReadonly={readonly}
          invoiceReadonly={readonly}
          invoicePoNumberReadonly={invoicePoNumberReadonly}
          releaseHasMultipleInvoices={
            (invoice?.release?.invoices || []).length > 1
          }
          hasError={hasError}
          autoselectFn={autoSelectFn}
        />
      </MatchGroup>
      <If isTrue={hasFooter}>
        <InvoiceFooter />
      </If>
      <If isTrue={importModalOpened}>
        <InvoiceImportExternalPOWizard />
      </If>
    </Container>
  );
};

const InvoiceVerificationWithReleaseProvider: FC = () => {
  const initialized = useRef<boolean>(false);

  const { invoice } = useInvoiceVerification();

  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { matchedOrderViewState, setMatchedOrderViewState } =
    useInvoiceMatchedOrder();
  const { connectedSourceSystem, loading: loadingOrgSettings } =
    useOrgSettings();
  const { autoSelectView } = useInvoiceViewAutoSelection({
    matchedOrderViewState,
    setMatchedOrderViewState,
  });
  const {
    loadSuggestedOrders,
    loading: loadingReleases,
    called,
    releases,
  } = useInvoiceSuggestedOrders();

  useEffect(() => {
    if (invoice && invoice.poNumber && !called) {
      loadSuggestedOrders();
    }
  }, [invoice, called, loadSuggestedOrders]);

  const enableValidation = useMemo(
    () =>
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.InvoiceFileExports,
      ) || hasFeatureInConnectedSourceSystem(IntegrationFeature.InvoiceWrites),
    [hasFeatureInConnectedSourceSystem],
  );
  useEffect(() => {
    if (invoice?.poNumber) {
      initialized.current = false;
    }
  }, [invoice?.poNumber]);

  useEffect(() => {
    if (
      !initialized.current &&
      invoice?.id &&
      (called || !invoice?.poNumber) &&
      !loadingReleases &&
      (connectedSourceSystem || !loadingOrgSettings)
    ) {
      initialized.current = true;
      autoSelectView(releases);
    }
  }, [
    setMatchedOrderViewState,
    invoice?.id,
    matchedOrderViewState,
    releases.length,
    invoice,
    releases,
    loadingReleases,
    called,
    connectedSourceSystem,
    autoSelectView,
    loadingOrgSettings,
  ]);

  if (!invoice) {
    return <Loader loading />;
  }

  return (
    <InvoiceValidationProvider invoice={invoice} skip={!enableValidation}>
      <ReleaseProvider id={invoice?.release?.id} invoiceId={invoice.id}>
        <ContractorBuyoutProvider id={invoice?.release?.buyout?.id}>
          <SpreadsheetWrapper />
        </ContractorBuyoutProvider>
      </ReleaseProvider>
    </InvoiceValidationProvider>
  );
};

const InvoiceCreateReleaseWithSpreadsheetWrapper = () => {
  const invoiceCreateReleaseSpreadsheetConfig =
    useInvoiceCreateReleaseSpreadsheetConfig();
  return (
    <ColumnMapperProvider config={invoiceCreateReleaseSpreadsheetConfig}>
      <InvoiceCreateReleaseProvider>
        <InvoiceVerificationProviderWithProvider />
      </InvoiceCreateReleaseProvider>
    </ColumnMapperProvider>
  );
};

const InvoiceUpdateReleaseWithSpreadsheetWrapper = () => {
  const { release } = useRelease();

  const invoiceUpdateReleaseSpreadsheetConfig =
    useInvoiceUpdateReleaseSpreadsheetConfig();

  if (!release) {
    return;
  }
  return (
    <ReleaseUpdateProvider
      release={release}
      project={release?.project}
      items={release.items}
    >
      <ColumnMapperProvider config={invoiceUpdateReleaseSpreadsheetConfig}>
        <InvoiceCreateReleaseProvider>
          <InvoiceUpdateReleaseProvider>
            <InvoiceVerificationProviderWithProvider />
          </InvoiceUpdateReleaseProvider>
        </InvoiceCreateReleaseProvider>
      </ColumnMapperProvider>
    </ReleaseUpdateProvider>
  );
};

const SpreadsheetWrapper = () => {
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  if (!matchedOrderViewState) {
    return <Loader loading />;
  }

  if (
    matchedOrderViewState === MatchedOrderViewState.CREATE_ORDER ||
    matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
  ) {
    return <InvoiceCreateReleaseWithSpreadsheetWrapper />;
  } else if (matchedOrderViewState === MatchedOrderViewState.EDIT_ORDER) {
    return <InvoiceUpdateReleaseWithSpreadsheetWrapper />;
  } else {
    return <InvoiceVerificationProviderWithProvider />;
  }
};

export const InvoiceVerification = () => {
  return (
    <NestedStepperProvider>
      <InvoiceSequenceProvider>
        <WarehousesProvider>
          <InvoiceCreationProvider>
            <InvoiceVerificationForm>
              <InvoiceVerificationProvider>
                <InvoiceMatchedOrderProvider>
                  <InvoiceImportExternalPOProvider>
                    <SplittingInvoicesWizardProvider>
                      <InvoiceVerificationWithReleaseProvider />
                    </SplittingInvoicesWizardProvider>
                  </InvoiceImportExternalPOProvider>
                </InvoiceMatchedOrderProvider>
              </InvoiceVerificationProvider>
            </InvoiceVerificationForm>
          </InvoiceCreationProvider>
        </WarehousesProvider>
      </InvoiceSequenceProvider>
    </NestedStepperProvider>
  );
};
