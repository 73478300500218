import { NUMERIC_DECIMAL_POINTS } from "@/common/const";
import { isEmptyString } from "@/common/utils/validationUtils";
import { EventKeysConstants } from "@/config/constants/eventKeysContants";
import { InputBaseComponentProps } from "@mui/material";
import { BaseSyntheticEvent, forwardRef } from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";

interface NumberFormatCustomProps
  extends Omit<InputBaseComponentProps, "onChange"> {
  onChange: (event: { target: { value: string } }) => void;
  onFocus: (event: { target: { value: string } }) => void;
  prefix?: string;
  suffix?: string;
  isAllowed?: (values: NumberFormatValues) => boolean;
  decimals?: number;
  fixedDecimalScale?: boolean;
}

export const NumberFormatCustom = forwardRef<
  HTMLInputElement,
  NumberFormatCustomProps
>(function NumberFormatCustom(props, ref) {
  const {
    onChange,
    onFocus,
    isAllowed,
    decimals,
    fixedDecimalScale,
    ...other
  } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: isEmptyString(values.value) ? "0" : values.value,
          },
        });
      }}
      onFocusCapture={(event: BaseSyntheticEvent) => {
        onFocus(event);
        event.target.select();
        event.preventDefault();
        event.stopPropagation();
      }}
      onKeyDown={(event: BaseSyntheticEvent & { key: string }) => {
        if (event.key === EventKeysConstants.Enter) {
          event.preventDefault();
          event.target.blur();
        }
      }}
      thousandSeparator
      decimalScale={decimals ?? NUMERIC_DECIMAL_POINTS}
      fixedDecimalScale={fixedDecimalScale}
      isAllowed={isAllowed}
    />
  );
});
