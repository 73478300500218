import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { useSourceSystemConfig } from "@/common/hooks/integrations/source-system-config/useSourceSystemConfig";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useUser } from "@/common/providers/UserProvider";
import {
  OrgSettingsDocument,
  OrgSettingsExtendedDocument,
  SourceSystem,
  SystemRole,
  useConnectSourceSystemMutation,
  useDisconnectSourceSystemMutation,
  useInitializeSourceSystemConnectionMutation,
} from "@/generated/graphql";
import { NoFunctionBoolean, NoFunctionPromise } from "@/types/NoFunction";
import { useAgaveLink } from "@agave-api/react-agave-link";
import { ApolloError } from "@apollo/client";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useSnackbar } from "../../../../../common/providers/SnackbarProvider";
import { useAgaveHostedWizard } from "../components/wizard/agave-wizard/AgaveHostedWizardProvider";
import { AGAVE_IFRAME_ID } from "../components/wizard/agave-wizard/steps/agave-iframe/AgaveIframe";

const BACKEND_ERROR_MESSAGE_EXISTING_DISABLED_CONNECTION_WITH_DIFFERENT_COMPANY =
  "the specified source system is already connected to a different company";

type ProviderContextType = {
  connect: ({ system }: { system: SourceSystem }) => Promise<void>;
  disconnect: ({ system }: { system: SourceSystem }) => Promise<void>;
  isLoading: (system: SourceSystem) => boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  connect: NoFunctionPromise,
  disconnect: NoFunctionPromise,
  isLoading: NoFunctionBoolean,
});

export const AgaveConnectionProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const intl = useIntl();
  const { viewer } = useUser();
  const { setError } = useGlobalError();
  const { setSuccessAlert } = useSnackbar();
  const { openDialog } = useDialog();
  const { moveToNextNestedStep } = useNestedStepper();
  const { openWizard, closeWizard } = useAgaveHostedWizard();
  const { getSourceSystemConfig } = useSourceSystemConfig();

  const [shouldOverwriteConnection, setShouldOverwriteConnection] = useState<
    boolean | undefined
  >();
  const [connectionInput, setConnectionInput] = useState<{
    system: SourceSystem;
    linkToken?: string;
  } | null>(null);

  const [initializeSourceSystemConnection, { loading: initializing }] =
    useInitializeSourceSystemConnectionMutation();
  const connect = useCallback(
    async ({ system }: { system: SourceSystem }) => {
      try {
        setConnectionInput({ system });
        const data = await initializeSourceSystemConnection({
          variables: {
            input: {
              orgId: viewer?.org.id || "",
              system,
            },
          },
        });
        if (data.data?.initializeSourceSystemConnection) {
          setConnectionInput({
            linkToken: data.data?.initializeSourceSystemConnection,
            system,
          });
        }
      } catch (error) {
        setError(intl.$t({ id: "CONNECTION_AGAVE_FAILED" }) + error);
      }
    },
    [initializeSourceSystemConnection, intl, setError, viewer?.org.id],
  );

  const [disconnectSourceSystemMutation] = useDisconnectSourceSystemMutation();
  const disconnect = useCallback(
    async ({ system }: { system: SourceSystem }) => {
      try {
        const { data } = await disconnectSourceSystemMutation({
          variables: {
            input: {
              orgId: viewer?.org.id || "",
              system,
            },
          },
          refetchQueries: [{ query: OrgSettingsExtendedDocument }],
        });
        if (data?.disconnectSourceSystem) {
          setSuccessAlert(
            intl.$t(
              { id: "DISCONNECT_AGAVE_SUCCESSFUL" },
              {
                integration: intl.$t({
                  id: `INTEGRATION_${system}`,
                }),
              },
            ),
          );
        }
      } catch (error) {
        setError(intl.$t({ id: "DISCONNECT_AGAVE_FAILED" }) + error);
      } finally {
        setConnectionInput(null);
      }
    },
    [
      disconnectSourceSystemMutation,
      intl,
      setError,
      setSuccessAlert,
      viewer?.org.id,
    ],
  );

  const [connectSourceSystemMutation, { loading: connecting }] =
    useConnectSourceSystemMutation();
  const onSuccess = useCallback(
    async (publicToken: string) => {
      try {
        if (connectionInput?.system) {
          const { data } = await connectSourceSystemMutation({
            variables: {
              input: {
                orgId: viewer?.org.id || "",
                system: connectionInput.system,
                publicToken,
                overwrite: shouldOverwriteConnection,
              },
            },
            refetchQueries: [{ query: OrgSettingsDocument }],
          });
          if (data?.connectSourceSystem) {
            setShouldOverwriteConnection(undefined);
            moveToNextNestedStep();
            setSuccessAlert(
              intl.$t(
                { id: "CONNECTION_AGAVE_SUCCESSFUL" },
                {
                  integration: intl.$t({
                    id: `INTEGRATION_${connectionInput.system}`,
                  }),
                },
              ),
            );
          }
        }
      } catch (error) {
        if (
          (error as ApolloError).message.includes(
            BACKEND_ERROR_MESSAGE_EXISTING_DISABLED_CONNECTION_WITH_DIFFERENT_COMPANY,
          )
        ) {
          openDialog({
            title: intl.$t({ id: "CONFIRM_COMPANY_CHANGE" }),
            text: intl.$t(
              { id: "CONFIRM_COMPANY_CHANGE_DESCRIPTION" },
              { source: connectionInput?.system },
            ),
            icon: <WarningIcon />,
            confirmButtonText: intl.$t({ id: "PROCEED" }),
            cancelButtonText: intl.$t({ id: "CANCEL" }),
            handleConfirm: async () => {
              setShouldOverwriteConnection(true);
              closeWizard();
              if (connectionInput?.system) {
                await connect({ system: connectionInput?.system });
              }
            },
            handleCancel: () => {
              setShouldOverwriteConnection(undefined);
              closeWizard();
            },
          });
          return false;
        } else {
          setError(
            intl.$t(
              { id: "CONNECTION_AGAVE_FAILED" },
              {
                integration: connectionInput?.system
                  ? intl.$t({
                      id: `INTEGRATION_${connectionInput?.system}`,
                    })
                  : "",
              },
            ) + ` ${(error as ApolloError).message ?? error}`,
          );
        }
        return false;
      }
    },
    [
      connectionInput?.system,
      viewer?.org.id,
      shouldOverwriteConnection,
      connect,
      connectSourceSystemMutation,
      moveToNextNestedStep,
      openDialog,
      closeWizard,
      setSuccessAlert,
      setError,
      intl,
    ],
  );

  const onExit = useCallback(
    (error: string) => {
      closeWizard();
      setError(error);
      setConnectionInput(null);
    },
    [setError, closeWizard],
  );

  const mappedSourceSystem = connectionInput?.system
    ? getSourceSystemConfig(connectionInput.system).agaveSourceSystemName
    : connectionInput?.system;
  const { openLink } = useAgaveLink({
    linkToken: connectionInput?.linkToken,
    showSandboxSourceSystems: true, // Only for local development
    showProductionSourceSystems: true,
    // use sandbox for Procore if user is FM Admin for testing purposes
    sourceSystemEnvironment:
      connectionInput?.system &&
      getSourceSystemConfig(connectionInput?.system).useSandbox &&
      viewer?.systemRoles.includes(SystemRole.FmAdmin)
        ? "sandbox"
        : "prod",
    sourceSystem: mappedSourceSystem,
    onSuccess,
    onExit,
    iframeId: AGAVE_IFRAME_ID,
  });
  useEffect(() => {
    if (connectionInput?.linkToken) {
      openWizard({
        sourceSystem: connectionInput.system,
      });
      setTimeout(() => {
        openLink();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionInput?.linkToken]);

  const isLoading = useCallback(
    (system: SourceSystem) => {
      if (system !== connectionInput?.system) {
        return false;
      }
      return initializing || connecting;
    },
    [initializing, connecting, connectionInput],
  );

  return (
    <ProviderContext.Provider value={{ connect, disconnect, isLoading }}>
      {children}
    </ProviderContext.Provider>
  );
};

export const useAgaveConnectionProvider = () => useContext(ProviderContext);
