import {
  createContext,
  forwardRef,
  PropsWithChildren,
  Ref,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

type ScrollPosition = {
  Top: number;
  Left: number;
};

type ProviderContextType = {
  windowScroll: ScrollPosition;
};

const ProviderContext = createContext<ProviderContextType>({
  windowScroll: {} as ScrollPosition,
});

export const ScrollingProvider = forwardRef<HTMLElement, PropsWithChildren>(
  ({ children }, ref: Ref<HTMLElement>) => {
    const [windowScroll, setWindowScroll] = useState<ScrollPosition>({
      Top: 0,
      Left: 0,
    });
    const innerRef = ref as RefObject<HTMLElement>;
    const handleScroll = useCallback(() => {
      setWindowScroll({
        Top: innerRef?.current?.scrollTop || 0,
        Left: innerRef?.current?.scrollLeft || 0,
      });
    }, [innerRef]);

    useEffect(() => {
      let observerRefValue: HTMLElement | null = null;

      const observer = new IntersectionObserver(handleScroll);
      if (innerRef.current) {
        observer.observe(innerRef.current);
        observerRefValue = innerRef.current;
        innerRef.current?.addEventListener("scroll", handleScroll);
      }

      return () => {
        if (observerRefValue) {
          observerRefValue.removeEventListener("scroll", handleScroll);
          observer.unobserve(observerRefValue);
        }
      };
    }, [handleScroll, innerRef, ref]);

    return (
      <ProviderContext.Provider value={{ windowScroll }}>
        {children}
      </ProviderContext.Provider>
    );
  },
);

export const useWindowScroll = () => useContext(ProviderContext);
