import { FC } from "react";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../providers/DistributorQuoteItemsProvider";
import { useDistributorQuote } from "../providers/DistributorQuoteProvider";
import { UnitPriceInput } from "./common/UnitPriceInput";

type Props = {
  item: DistributorQuoteItemGroupExtendedFieldsFragment;
  readonly: boolean;
  index?: number;
  error?: boolean;
  hideErrorIcon?: boolean;
};

export const DistributorQuoteUnitPriceInput: FC<Props> = ({
  item,
  ...props
}) => {
  const { updateQuote } = useDistributorQuote();

  return (
    <UnitPriceInput
      onChange={async (unitPrice: string | null) => {
        await updateQuote({
          updates: [
            {
              quoteItemId: item.quoteItems[0]?.id,
              unitPrice: unitPrice === null ? undefined : unitPrice,
              clearUnitPrice: unitPrice === null,
            },
          ],
        });
      }}
      unitPrice={item.quoteItems[0]?.unitPrice}
      quantity={item.quoteItems[0]?.quantityDecimal || "0"}
      uom={item.rfqItem.projectItem.estimateUom}
      {...props}
    />
  );
};
