import {
  PaginationArgs,
  usePagination,
} from "@/common/components/pagination/PaginationProvider";
import {
  ExternalItemFieldsFragment,
  QueryExternalItemsFilter,
  SourceSystem,
  useExternalItemsQuery,
} from "@/generated/graphql";
import { ApolloError } from "@apollo/client";
import { useEffect } from "react";

type ReturnType = {
  externalItems: ExternalItemFieldsFragment[];
  loading: boolean;
  error?: ApolloError;
  totalCount: number;
  pagination: PaginationArgs;
  setPage: ({
    page,
    pagination,
    queryParams,
  }: {
    page: number;
    pagination?: PaginationArgs;
    queryParams?: Record<
      string,
      string | null | boolean | Array<unknown> | undefined
    >;
  }) => void;
};

export const useExternalItemsWithPagination = (
  sourceSystem: SourceSystem | null,
  filter?: QueryExternalItemsFilter,
): ReturnType => {
  const { paginationArgs, setPageInfo, setPage } = usePagination();
  const { data, loading, error } = useExternalItemsQuery({
    variables: {
      ...paginationArgs,
      sourceSystem: sourceSystem ?? SourceSystem.Foundation,
      filter,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    skip: !sourceSystem,
  });

  useEffect(() => {
    if (data?.externalItems?.pageInfo) {
      setPageInfo({ ...data.externalItems.pageInfo });
    }
  }, [data, setPageInfo]);

  return {
    externalItems: data?.externalItems.edges.map((edge) => edge.node) || [],
    pagination: paginationArgs,
    loading,
    error,
    totalCount: data?.externalItems.totalCount || 0,
    setPage,
  };
};
