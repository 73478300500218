import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CreateMaterialConversionInput,
  useCreateMaterialConversionsMutation,
} from "@/generated/graphql";

export const useCreateMaterialConversions = () => {
  const [createMaterialConversionsMutation] =
    useCreateMaterialConversionsMutation();
  const { setError } = useGlobalError();

  const createMaterialConversions = async (
    input: CreateMaterialConversionInput[],
  ) => {
    try {
      const { data } = await createMaterialConversionsMutation({
        variables: {
          input,
        },
      });
      return !!data?.createMaterialConversions;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  return { createMaterialConversions };
};
