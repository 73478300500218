import { gql } from "@apollo/client";
import { PROJECT_EXTENDED_FIELDS } from "../../../../../common/fragments/projectExtendedFragment";

export const PROJECT = gql`
  ${PROJECT_EXTENDED_FIELDS}
  query Project($id: ID!, $excludePhantoms: Boolean = false) {
    project(id: $id) {
      ...ProjectExtendedFields
    }
  }
`;
