import { GroupBySegment } from "@/common/components/group-by-segment/GroupBySegment";
import { FC } from "react";
import { useDistributorBuyoutItemsProvider } from "../providers/DistributorBuyoutItemsProvider";

export const DistributorBuyoutGroupByCostCodeToggle: FC = () => {
  const { groupedByCostCode, setGroupedByCostCode } =
    useDistributorBuyoutItemsProvider();
  return (
    <GroupBySegment
      groupedByCostCode={groupedByCostCode}
      setGroupedByCostCode={setGroupedByCostCode}
    />
  );
};
