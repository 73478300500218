import { EditButton } from "@/common/components/button/EditButton";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { TextfieldWithActions } from "@/common/components/textfield-with-actions/TextfieldWithActions";
import { FC, useCallback, useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutGrouped } from "../../non-quoted/providers/BuyoutGroupedProvider";

const EditIconStyled = tw(EditButton)`h-4`;
const LoaderStyled = tw(Loader)`h-4 justify-end`;

export const BuyoutInlineAllowance: FC = () => {
  const { buyout, updateBuyout } = useContractorBuyout();
  const { setEditAllowance, editAllowance } = useBuyoutGrouped();
  const [allowance, setAllowance] = useState(
    buyout?.additionalChargesAllowance,
  );
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (!updating) {
      setAllowance(buyout?.additionalChargesAllowance);
    }
  }, [buyout, updating]);

  const updateBuyoutAllowance = useCallback(async () => {
    if (!buyout) {
      return;
    }
    setUpdating(true);
    await updateBuyout(
      {
        buyoutId: buyout?.id,
        additionalChargesAllowance: allowance,
        clearAdditionalChargesAllowance: !allowance,
      },
      true,
    );
    setUpdating(false);
    setEditAllowance(false);
  }, [allowance, buyout, setEditAllowance, updateBuyout]);

  return (
    <>
      <If isTrue={!editAllowance}>
        <NotNullableRenderer
          value={buyout?.additionalChargesAllowance}
          testId="allowance"
        >
          <Price
            price={buyout?.additionalChargesAllowance}
            testId="allowance"
          />
        </NotNullableRenderer>
        <EditIconStyled onClick={() => setEditAllowance(true)} />
      </If>
      <If isTrue={editAllowance}>
        <TextfieldWithActions
          value={allowance}
          onChange={(e) => setAllowance(e.target.value)}
          onClickSave={updateBuyoutAllowance}
          onClickClose={() => {
            setAllowance(buyout?.additionalChargesAllowance);
            setEditAllowance(false);
          }}
          showActions={!updating}
          additionalActions={
            updating ? <LoaderStyled loading small /> : undefined
          }
          className="max-w-32"
        />
      </If>
    </>
  );
};
