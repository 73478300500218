export const AGAVE_IFRAME_ID = "agave-iframe-id";
export const AGAVE_IFRAME_WIDTH = 400;
export const AGAVE_IFRAME_HEIGHT = 600;

export const AgaveIframe = () => {
  return (
    <iframe
      id={AGAVE_IFRAME_ID}
      width={AGAVE_IFRAME_WIDTH}
      height={AGAVE_IFRAME_HEIGHT}
      className="rounded-xl"
    />
  );
};
