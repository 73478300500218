import { create } from "zustand";
import { devtools } from "zustand/middleware";

type State = {
  saving: boolean;
  setSaving: (saving: boolean) => void;
};

export const useSyncReleaseItemsStore = create<State>()(
  devtools((set) => ({
    saving: false,
    setSaving: (saving) => set({ saving }),
  })),
);
