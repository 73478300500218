import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";

export const useVendorPrice = () => {
  const { prices } = useVendorPrices();

  const getPrice = (
    materialId: string,
    uomId: string,
    sellerOrgLocationId?: string,
    manufacturerId?: string,
  ) => {
    const price = prices.find(
      (p) =>
        p.orgMaterialId === materialId &&
        p.uom.id === uomId &&
        (!sellerOrgLocationId ||
          p.sellerOrgLocation?.id === sellerOrgLocationId) &&
        (!manufacturerId ||
          !p.manufacturer ||
          p.manufacturer?.id === manufacturerId),
    );
    return price ? price.price : "";
  };

  return {
    getPrice,
  };
};
