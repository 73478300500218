import { If } from "@/common/components/if/If";
import {
  ControlledProps,
  TextFieldControlled,
} from "@/common/components/textfield-controlled/TextFieldControlled";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Example = tw.div`text-gray-600 text-sm`;
const Container = tw.div`grid grid-cols-2 gap-3 items-center`;

type Props = ControlledProps & { jobNumberName: string };

export const ProjectPoNumberFormatTextField: FC<Props> = ({
  jobNumberName,
  ...props
}) => {
  const intl = useIntl();
  const { watch } = useFormContext();
  const poJobNumber = watch(props.name);
  const jobNumber = watch(jobNumberName);

  return (
    <Container>
      <TextFieldControlled
        size="small"
        label={intl.$t({ id: "SPECIFY_PO_NUMBER_FORMAT" })}
        placeholder={jobNumber}
        shrink
        InputProps={{
          classes: {
            input: "placeholder:opacity-70",
          },
        }}
        {...props}
      />
      <If isTrue={poJobNumber || jobNumber}>
        <Example>-1</Example>
      </If>
    </Container>
  );
};
