import Decimal from "decimal.js";

export const areDecimalsEqual = (
  value1?: string | null,
  value2?: string | null,
) => {
  if (!value1 || !value2) {
    return false;
  }
  return new Decimal(value1 || 0)
    .mul(100)
    .round()
    .div(100)
    .equals(new Decimal(value2 || 0).mul(100).round().div(100));
};
