import { Breadcrumbs } from "@/common/components/breadcrumbs/Breadcrumbs";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { GridViewToggle } from "@/common/components/grid-view-toggle/GridViewToggle";
import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { Loader } from "@/common/components/loader/Loader";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { RfqStatusChip } from "@/common/components/statuses/RfqStatusChip";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { VIEW_STATE, useTableView } from "@/common/providers/TableViewProvider";
import { routes } from "@/config/routes";
import {
  InstructionInput,
  RfqStatus,
  UpdateRfqInput,
} from "@/generated/graphql";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { ButtonsContainerGroup } from "../../rfq/Rfq.styles";
import { RfqItemsList } from "../../rfq/components/RfqItemsList";
import { useRfqItemsListConfiguration } from "../../rfq/components/RfqItemsList.configuration";
import { RfqProductMenu } from "../../rfq/components/rfq-product-menu/RfqProductMenu";
import { useRfq } from "../../rfq/hooks/useRfq";
import { useSyncRfqItems } from "../../rfq/providers/SyncRfqItemsProvider";
import { useEnsureRfqStatus } from "../hooks/useEnsureRfqStatus";
import { RFQInfo } from "./RFQInfo";
import RfqVendorsInfo from "./RfqVendorsInfo";
import { ArchiveRfqButton } from "./buttons/ArchiveRfqButton";
import { SendToVendorsRfqButton } from "./buttons/SendToVendorsRfqButton";

const RfqNoWrapper = tw.div`pr-2`;
const Container = tw.div`pb-15`;
const FooterContent = tw.div`grid grid-cols-[80px_auto_1fr_auto] gap-4`;

export const RfqItems = () => {
  const { rfq, loading, updateRfq, isUpdating } = useRfq();
  const intl = useIntl();
  const navigate = useNavigate();
  const { syncRfqItems, saving } = useSyncRfqItems();
  const { tableView } = useTableView();
  const { spreadsheetConfig } = useRfqItemsListConfiguration(true);
  const { spreadsheetData } = useColumnMapper();
  const [newSpreadsheetData, setNewSpreadsheetData] =
    useState<Record<string, string>[]>(spreadsheetData);

  useEnsureRfqStatus(rfq, RfqStatus.Draft);

  const saveInstructions = (instruction: InstructionInput) => {
    if (rfq) {
      const input: UpdateRfqInput = {
        rfqId: rfq.id,
        instructions: instruction,
      };
      return updateRfq(input);
    }
  };

  if (!rfq) {
    return <Loader loading />;
  }

  if (rfq.deletedAt) {
    navigate(generatePath(routes.rfqById, { id: rfq.id }));
  }

  return (
    <Container>
      <Helmet>
        <title>{intl.$t({ id: "RFQ_ORDER_CHECKOUT" })}</title>
      </Helmet>
      <Breadcrumbs
        classes={{
          text: "flex items-center",
        }}
        items={[
          {
            text: intl.$t({ id: "QUOTES" }),
            link: routes.quotes,
            id: "activeOrders",
          },
          {
            text: (
              <>
                <RfqNoWrapper>
                  <FormattedMessage
                    id="RFQ_NUMBER_WITH_SYMBOL"
                    values={{ number: rfq.clientIdentifier }}
                  />
                </RfqNoWrapper>
              </>
            ),
            id: "newOrder",
          },
        ]}
        appendItems={[
          <RfqStatusChip key="status" status={rfq?.status} type="breadcrumb" />,
        ]}
      />
      <RFQInfo rfq={rfq} hideVendors />
      <If isTrue={tableView === VIEW_STATE.normal}>
        <RfqItemsList loading={loading} />
      </If>
      <If isTrue={tableView === VIEW_STATE.spreadsheet}>
        <SpreadSheetTable
          items={rfq.items}
          columns={spreadsheetConfig}
          saving={saving}
          height="calc(100vh - 400px)"
          onChanges={setNewSpreadsheetData}
        />
        <RfqProductMenu />
      </If>
      <Instructions
        projectId={rfq?.project?.id}
        saveInstruction={saveInstructions}
        saving={isUpdating}
        instruction={rfq?.instructions}
        key={rfq?.id}
      />
      <FloatingFooter>
        <FooterContent>
          <span />
          {rfq?.status === RfqStatus.Draft ? (
            <GridViewToggle saveState={syncRfqItems} saving={saving} />
          ) : (
            <span />
          )}
          <RfqVendorsInfo
            drawerTitle={intl.$t({
              id: "ADD_ANOTHER_VENDOR_S_TO_RFQ",
            })}
          />
          <ButtonsContainerGroup>
            <ArchiveRfqButton />
            <If isTrue={rfq.status === RfqStatus.Draft}>
              <If isTrue={tableView === VIEW_STATE.spreadsheet}>
                <OutlinedButton onClick={syncRfqItems}>
                  <FormattedMessage id="SAVE" />
                </OutlinedButton>
              </If>
            </If>
            <SendToVendorsRfqButton spreadsheetData={newSpreadsheetData} />
          </ButtonsContainerGroup>
        </FooterContent>
      </FloatingFooter>
    </Container>
  );
};
