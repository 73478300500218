import { TagsSelector } from "@/common/components/tags-selector/TagsSelector";
import { TagFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useProjectItemsZones } from "../../providers/ProjectItemsZonesProvider";

type Props = {
  options: TagFieldsFragment[];
  disabled?: boolean;
  limitTags?: number;
  className?: string;
};

export const ProjectTagsSelector: FC<Props> = ({
  options,
  disabled,
  limitTags = 2,
  className,
}) => {
  const intl = useIntl();
  const { tags, setTags } = useProjectItemsZones();

  return (
    <TagsSelector
      options={options}
      selectedTags={tags}
      setSelectedTags={setTags}
      disabled={disabled}
      limitTags={limitTags}
      placeholder={intl.$t({
        id: "TAGS",
      })}
      creatable={false}
      className={className}
    />
  );
};
