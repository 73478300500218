import { ExternalPOTableCategoryId } from "@/contractor/pages/home/common/external-po/ExternalPOItemsTable.configuration";
import { ExternalPoFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";

export const useExternalPOUtils = () => {
  const intl = useIntl();
  const getUnsupportedCostTypesCategory = useCallback(
    (externalPO?: ExternalPoFieldsFragment | null) =>
      (externalPO?.itemGroups.unsupportedCostType ?? []).length > 0
        ? [
            {
              id: ExternalPOTableCategoryId.UNSUPPORTED_COST_TYPES,
              isOpened: true,
              name: intl.$t({ id: "UNSUPPORTED_COST_TYPES" }),
              description: intl.$t({ id: "UNSUPPORTED_COST_TYPES_TOOLTIP" }),
              items: (externalPO?.itemGroups.unsupportedCostType ?? []).map(
                (item) => ({ ...item, id: item.externalId }),
              ),
            },
          ]
        : [],
    [intl],
  );

  const getMissingTagsCategory = useCallback(
    (externalPO?: ExternalPoFieldsFragment | null) =>
      (externalPO?.itemGroups.missingTag ?? []).length > 0
        ? [
            {
              id: ExternalPOTableCategoryId.MISSING_TAG,
              isOpened: true,
              name: intl.$t({ id: "MISSING_PHASE_CODES" }),
              description: intl.$t({ id: "MISSING_PHASE_CODES_TOOLTIP" }),
              items: (externalPO?.itemGroups.missingTag ?? []).map((item) => ({
                ...item,
                id: item.externalId,
              })),
            },
          ]
        : [],
    [intl],
  );

  const getMissingCostCodesCategory = useCallback(
    (externalPO?: ExternalPoFieldsFragment | null) =>
      (externalPO?.itemGroups.missingCostCode ?? []).length > 0
        ? [
            {
              id: ExternalPOTableCategoryId.MISSING_COST_CODES,
              isOpened: true,
              name: intl.$t({ id: "MISSING_COST_CODES" }),
              description: intl.$t({ id: "MISSING_COST_CODES_TOOLTIP" }),
              items: (externalPO?.itemGroups.missingCostCode ?? []).map(
                (item) => ({ ...item, id: item.externalId }),
              ),
            },
          ]
        : [],
    [intl],
  );

  const checkIfPOIsItemized = useCallback(
    (externalPO?: ExternalPoFieldsFragment | null) =>
      (externalPO?.itemGroups.aggregatedItems ?? []).length === 0,
    [],
  );

  const getIncludedItemsCategory = useCallback(
    (externalPO?: ExternalPoFieldsFragment | null) => [
      {
        id: ExternalPOTableCategoryId.INCLUDED,
        isOpened: true,
        name: "",
        items: [
          ...(externalPO?.itemGroups.importable ?? []),
          ...(externalPO?.itemGroups.additionalCharges ?? []),
          ...(externalPO?.itemGroups.salesTax ?? []),
        ].map((item) => ({ ...item, id: item.externalId })),
      },
    ],
    [],
  );

  return {
    getUnsupportedCostTypesCategory,
    getMissingTagsCategory,
    getMissingCostCodesCategory,
    getIncludedItemsCategory,
    checkIfPOIsItemized,
  };
};
