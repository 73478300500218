import { gql } from "@apollo/client";
import { RELEASE_APPROVAL_SLOT_FIELDS } from "./releaseApprovalSlot";

export const RELEASE_APPROVAL_PROGRESS_FIELDS = gql`
  ${RELEASE_APPROVAL_SLOT_FIELDS}
  fragment ReleaseApprovalProgressFields on ReleaseApprovalProgress {
    slots {
      ...ReleaseApprovalSlotFields
    }
    canApprove
    canApproveImmediately
    canReject
  }
`;
