import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { DrawerView } from "@/common/components/panel/DrawerView";
import { FC } from "react";
import { NotesDetails } from "./NoteDetails";

export const NOTES_PANEL_ID = "notes-panel";

export const NotesPanel: FC = () => {
  const { isOpened, toggle } = useGlobalDrawer();
  return (
    <DrawerView
      isOpened={isOpened(NOTES_PANEL_ID)}
      setIsOpened={(value) => toggle(NOTES_PANEL_ID, value)}
      content={(togglePanel) => (
        <NotesDetails onClose={() => togglePanel(false)} />
      )}
    />
  );
};
