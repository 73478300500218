import { SuccessIcon } from "@/common/components/dialog-icons/SuccessIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  AlternativeFulfillmentInput,
  ReleaseIssueResolutionInput,
  ReleaseIssueResolutionType,
  useResolveReleaseIssuesMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionPromise } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useDistributorRelease } from "../../../providers/DistributorReleaseProvider";

type ProviderContextType = {
  calendarOpened: boolean;
  issueResolutions: Array<
    Partial<
      ReleaseIssueResolutionInput & {
        edit: Partial<ReleaseIssueResolutionInput>;
      }
    >
  >;
  hasUnsolvedIssues: boolean;
  toggleCalendarOpened: () => void;
  setIssueRedelivery: (
    issueId: string | undefined,
    redelivery: AlternativeFulfillmentInput | null,
  ) => void;
  setIssueResolution: (
    issueId: string | undefined,
    resolutionType: ReleaseIssueResolutionType | undefined,
  ) => void;
  setIssueComments: (issueId: string | undefined, comments: string) => void;
  handleSaveIssueSolutions: (issueId: string | undefined) => void;
  handleCancelIssueSolutions: (issueId: string | undefined) => void;
  cancelSolvingReleaseSolutions: () => void;
  solveReleaseSolutions: () => void;
};

const ProviderContext = createContext<ProviderContextType>({
  calendarOpened: false,
  issueResolutions: [],
  hasUnsolvedIssues: false,
  toggleCalendarOpened: NoFunction,
  setIssueRedelivery: NoFunction,
  setIssueResolution: NoFunction,
  setIssueComments: NoFunction,
  handleSaveIssueSolutions: NoFunction,
  handleCancelIssueSolutions: NoFunction,
  cancelSolvingReleaseSolutions: NoFunction,
  solveReleaseSolutions: NoFunction,
});

export const DistributorReleaseDeliveryRecordProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { openDialog } = useDialog();

  const { release } = useDistributorRelease();
  const [resolveReleaseIssuesMutation] = useResolveReleaseIssuesMutation();

  const [calendarOpened, setCalendarOpened] = useState(false);
  const [issueResolutions, setIssueResolutions] = useState<
    Array<
      Partial<
        ReleaseIssueResolutionInput & {
          edit: Partial<ReleaseIssueResolutionInput>;
        }
      >
    >
  >([]);

  const hasUnsolvedIssues = useMemo(
    () =>
      release?.items.some(
        (i) => !!i.issues.length && !i.issues[0]?.resolution,
      ) || false,
    [release],
  );

  const setIssueRedelivery = useCallback(
    (
      issueId: string | undefined,
      redelivery: AlternativeFulfillmentInput | null,
    ) => {
      if (issueId) {
        setIssueResolutions((resolutions) => {
          const existingResolution = resolutions.find(
            (r) => r.issueId === issueId,
          );
          return [
            ...resolutions.filter((r) => r.issueId !== issueId),
            {
              ...existingResolution,
              issueId,
              edit: {
                ...existingResolution?.edit,
                redelivery,
              },
            },
          ];
        });
      }
    },
    [],
  );

  const setIssueResolution = useCallback(
    (
      issueId: string | undefined,
      resolutionType: ReleaseIssueResolutionType | undefined,
    ) => {
      if (issueId) {
        setIssueResolutions((resolutions) => {
          const existingResolution = resolutions.find(
            (r) => r.issueId === issueId,
          );
          return [
            ...resolutions.filter((r) => r.issueId !== issueId),
            {
              ...existingResolution,
              issueId,
              edit: {
                ...existingResolution?.edit,
                resolutionType,
              },
            },
          ];
        });
      }
    },
    [],
  );

  const setIssueComments = useCallback(
    (issueId: string | undefined, comments: string) => {
      if (issueId) {
        setIssueResolutions((resolutions) => {
          const existingResolution = resolutions.find(
            (r) => r.issueId === issueId,
          );
          return [
            ...resolutions.filter((r) => r.issueId !== issueId),
            {
              ...existingResolution,
              issueId,
              edit: {
                ...existingResolution?.edit,
                comments,
              },
            },
          ];
        });
      }
    },
    [],
  );

  const toggleCalendarOpened = useCallback(
    () => setCalendarOpened((opened) => !opened),
    [],
  );

  const handleSaveIssueSolutions = useCallback(
    (issueId: string | undefined) => {
      if (issueId) {
        setIssueResolutions((resolutions) =>
          resolutions.map((r) =>
            r.issueId === issueId
              ? {
                  ...r.edit,
                  edit: r.edit,
                  issueId: r.issueId,
                }
              : r,
          ),
        );
      }
    },
    [],
  );

  const handleCancelIssueSolutions = useCallback(
    (issueId: string | undefined) => {
      if (issueId) {
        setIssueResolutions((resolutions) =>
          resolutions.map(({ edit, ...r }) =>
            r.issueId === issueId
              ? {
                  ...r,
                  edit: r,
                  issueId: r.issueId,
                }
              : {
                  ...r,
                  edit,
                },
          ),
        );
      }
    },
    [],
  );

  const cancelSolvingReleaseSolutions = useCallback(() => {
    setIssueResolutions([]);
  }, []);

  const solveReleaseSolutions = useCallback(async () => {
    try {
      if (release) {
        const { errors } = await resolveReleaseIssuesMutation({
          variables: {
            input: {
              releaseId: release.id,
              issueResolutions: issueResolutions
                .filter((r) => r.issueId)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .map(({ edit, ...r }) => r as ReleaseIssueResolutionInput),
            },
          },
        });
        if (errors) {
          setError(errors);
        } else {
          openDialog({
            title: intl.$t({ id: "SUCCESS" }),
            text: intl.$t({ id: "ISSUE_SOLUTION_SUCCESS" }),
            icon: <SuccessIcon />,
            confirmButtonText: intl.$t({ id: "CLOSE" }),
            handleConfirm: NoFunctionPromise,
          });
        }
      }
    } catch (error) {
      setError(error);
    }
  }, [
    release,
    issueResolutions,
    resolveReleaseIssuesMutation,
    setError,
    openDialog,
    intl,
  ]);

  return (
    <ProviderContext.Provider
      value={{
        calendarOpened,
        issueResolutions,
        hasUnsolvedIssues,
        toggleCalendarOpened,
        setIssueResolution,
        setIssueRedelivery,
        setIssueComments,
        handleSaveIssueSolutions,
        handleCancelIssueSolutions,
        cancelSolvingReleaseSolutions,
        solveReleaseSolutions,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDistributorReleaseDeliveryRecord = (): ProviderContextType =>
  useContext(ProviderContext);
