import {
  PaginationArgs,
  usePagination,
} from "@/common/components/pagination/PaginationProvider";
import {
  OrgMaterialFieldsFragment,
  QueryOrgMaterialsFilter,
  useOrgMaterialsQuery,
} from "@/generated/graphql";
import { ApolloError } from "@apollo/client";
import { useEffect } from "react";

type ReturnType = {
  materials: OrgMaterialFieldsFragment[];
  loading: boolean;
  error?: ApolloError;
  totalCount: number;
  pagination: PaginationArgs;
  refetch: () => void;
};

export const useMaterialsWithPagination = (
  filter: QueryOrgMaterialsFilter | undefined,
): ReturnType => {
  const { paginationArgs, setPageInfo } = usePagination();
  const { data, loading, error, refetch } = useOrgMaterialsQuery({
    variables: {
      filter,
      ...paginationArgs,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data?.orgMaterials.pageInfo) {
      setPageInfo({ ...data.orgMaterials.pageInfo });
    }
  }, [data, setPageInfo]);

  return {
    materials: data?.orgMaterials.edges.map((edge) => edge.node) || [],
    pagination: paginationArgs,
    loading,
    error,
    totalCount: data?.orgMaterials.totalCount || 0,
    refetch,
  };
};
