import { Switch } from "@/common/components/switch/Switch";
import { useSpendingReport } from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  disabled?: boolean;
  label?: string;
};

const Label = tw.div`mr-2`;

export const SpendingReportGroupByZonesSwitch: FC<Props> = ({
  disabled,
  label,
}) => {
  const { groupedByZones, setGroupedByZones } = useSpendingReport();
  const intl = useIntl();

  return (
    <>
      <Label>
        <FormattedMessage id={label ?? "RFQ_GROUP_BY_ZONE"} />
      </Label>
      <Switch
        value={groupedByZones}
        onChange={setGroupedByZones}
        onLabel={intl.$t({ id: "YES" })}
        offLabel={intl.$t({ id: "NO" })}
        disabled={disabled}
      />
    </>
  );
};
