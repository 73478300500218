import { CircleCounter } from "@/common/components/circle-counter/CircleCounter";
import { CountType } from "@/common/components/circle-counter/CountType";
import { useCounter } from "@/common/components/circle-counter/useCounter";
import { If } from "@/common/components/if/If";
import { LineItemNameNotes } from "@/common/components/line-item-name-notes/LineItemNameNotes";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import {
  LEFT_COLUMN_WIDTH,
  MIN_COLUMN_WIDTH,
} from "@/contractor/pages/home/rfq-quotes/constants";
import {
  QuoteStatus,
  RfqItemFieldsFragment,
  RfqQuotesQuoteFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useRfqQuoteOptions } from "../../../../providers/RfqQuoteOptionsProvider";
import { useRfqQuotes } from "../../../../providers/RfqQuotesProvider";
import { QuoteRowLabels } from "./QuoteRowLabels";
import { EmptyQuote } from "./quote-item-card/EmptyQuote";
import { QuoteItemCard } from "./quote-item-card/QuoteItemCard";

const Container = tw.div`  
  grid bg-white w-full rounded-3xl shadow
`;

const ManufacturerName = tw.div`
  text-sm font-bold 
`;

const NameAndDescription = tw.div`
  grid justify-items-start content-baseline bg-gray-100 rounded-3xl p-2 max-w-96 font-light text-sm gap-0.5
`;

const LeftGroup = tw.div`
  sticky left-0 grid grid-cols-1fr-auto z-20
`;

const Quotes = tw.div`
  grid grid-flow-col gap-2 px-4 py-0 rounded-r-3xl h-full justify-around
`;

const QuoteItemFields = tw.div`
  grid grid-flow-row gap-2 py-0 w-33 px-4 bg-white
`;

const QuotesAndAlternatives = tw.div`
  grid grid-flow-row gap-1 h-full items-start content-start
`;

type RfqItemContainerProps = {
  rfqItem: RfqItemFieldsFragment;
  quotes: RfqQuotesQuoteFieldsFragment[];
  countMap: Map<string, number>;
  count: CountType;
};

export const RfqItemContainer: FC<RfqItemContainerProps> = ({
  rfqItem,
  quotes,
  countMap,
  count,
}) => {
  const { getCount } = useCounter(countMap);
  const { visibleQuotes } = useRfqQuoteOptions();
  const filteredGroups = useMemo(() => {
    return visibleQuotes.map((quote) => {
      const currentGroup = quote.itemGroups.find(
        (group) => group.rfqItem.id === rfqItem.id,
      );
      return {
        quoteId: quote.id,
        quoteItems: currentGroup?.quoteItems ?? [],
      };
    });
  }, [visibleQuotes, rfqItem.id]);

  const quoteItemFields = useMemo(() => {
    if (quotes.length === 0) {
      return [0];
    }
    const maxRows = Math.max(
      1,
      ...filteredGroups.map(
        (group) =>
          group?.quoteItems.filter((qi) => qi.status !== QuoteStatus.Withdrawn)
            ?.length,
      ),
    );
    return Array.from(Array(maxRows).keys());
  }, [filteredGroups, quotes.length]);

  const hasComponents = useMemo(() => {
    // TODO: implement
    return false;
  }, []);

  const showItemDescription = useCallback(
    (index: number) => {
      return (
        filteredGroups.some((group) => group.quoteItems.length > 1) ||
        filteredGroups.some(
          (group) =>
            group.quoteItems[index]?.description !== rfqItem.description,
        )
      );
    },
    [filteredGroups, rfqItem],
  );

  const showPriceExpiration = useCallback(
    (index: number) => {
      return filteredGroups.some(
        (group) => group.quoteItems[index]?.expirationDate,
      );
    },
    [filteredGroups],
  );

  const { selectedQuotes } = useRfqQuotes();

  const isSelected = useMemo(
    () =>
      selectedQuotes.length === 0 ||
      selectedQuotes.find((quote) => quote.rfqItemId === rfqItem?.id),
    [rfqItem?.id, selectedQuotes],
  );

  const hasQuotedItemsForRfqItem = useCallback(
    (quoteId: string) => {
      return filteredGroups
        .filter((g) => g.quoteId === quoteId)
        .some(
          (group) =>
            group.quoteItems.length > 0 &&
            group.quoteItems[0].status !== QuoteStatus.Withdrawn,
        );
    },
    [filteredGroups],
  );

  return (
    <Container
      style={{
        gridTemplateColumns: `${LEFT_COLUMN_WIDTH}px auto`,
      }}
    >
      <LeftGroup>
        <NameAndDescription>
          <If isTrue={!isSelected}>
            <Tooltip
              id="rfq-item-counter"
              element={
                <CircleCounter count={getCount(count)} warning={!isSelected} />
              }
            >
              <FormattedMessage id="NO_ITEM_SELECTED_FOR_RFQ_ITEM" />
            </Tooltip>
          </If>
          <If isTrue={isSelected}>
            <CircleCounter count={getCount(count)} />
          </If>
          <ManufacturerName>{rfqItem.manufacturer?.name}</ManufacturerName>
          {rfqItem.description}
          <LineItemNameNotes
            notes={rfqItem.instructions?.text}
            assets={rfqItem.instructions?.assets}
            className="ml-0"
          />
        </NameAndDescription>
        <QuoteItemFields>
          {quoteItemFields.map((row, index) => (
            <QuoteRowLabels
              key={row}
              hasComponents={hasComponents}
              showItemDescription={showItemDescription(index)}
              hasExpiration={showPriceExpiration(index)}
            />
          ))}
        </QuoteItemFields>
      </LeftGroup>
      <Quotes
        style={{
          gridTemplateColumns: `repeat(${visibleQuotes.length}, minmax(${MIN_COLUMN_WIDTH}px, 1fr))`,
        }}
      >
        {quotes.map((quote) => (
          <If isTrue={visibleQuotes.includes(quote)} key={quote.id}>
            <If isTrue={hasQuotedItemsForRfqItem(quote.id)}>
              <QuotesAndAlternatives
                style={{
                  gridTemplateRows: `repeat(${
                    hasQuotedItemsForRfqItem(quote.id)
                      ? quoteItemFields.length
                      : 1
                  }, minmax(0, auto))`,
                }}
              >
                {filteredGroups
                  .find((group) => group.quoteId === quote.id)
                  ?.quoteItems.map((quoteItem, index) =>
                    quoteItem.status !== QuoteStatus.Withdrawn ? (
                      <QuoteItemCard
                        key={quoteItem.id}
                        quote={quote}
                        rfqItem={rfqItem}
                        quoteItem={quoteItem}
                        hasComponents={hasComponents}
                        showItemDescription={showItemDescription(index)}
                        hasExpiration={showPriceExpiration(index)}
                      />
                    ) : null,
                  )}
              </QuotesAndAlternatives>
            </If>
            <If isTrue={!hasQuotedItemsForRfqItem(quote.id)}>
              <EmptyQuote key={quote.id} />
            </If>
          </If>
        ))}
      </Quotes>
    </Container>
  );
};
