import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CreateInvoiceIssueInput,
  ResolveInvoiceIssueInput,
  UpdateInvoiceIssueInput,
  useCreateInvoiceIssueMutation,
  useDeleteInvoiceIssueMutation,
  useResolveInvoiceIssueMutation,
  useUpdateInvoiceIssueMutation,
} from "@/generated/graphql";

export const useInvoiceIssues = () => {
  const [createInvoiceIssueMutation, { loading: creating }] =
    useCreateInvoiceIssueMutation();
  const [updateInvoiceIssueMutation, { loading: updating }] =
    useUpdateInvoiceIssueMutation();
  const [deleteInvoiceIssueMutation, { loading: deleting }] =
    useDeleteInvoiceIssueMutation();
  const [resolveInvoiceIssueMutation, { loading: resolving }] =
    useResolveInvoiceIssueMutation();

  const { setError } = useGlobalError();

  const createInvoiceIssue = async (input: CreateInvoiceIssueInput) => {
    try {
      const { data } = await createInvoiceIssueMutation({
        variables: {
          input,
        },
      });
      return !!data?.createInvoiceIssue;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const updateInvoiceIssue = async (input: UpdateInvoiceIssueInput) => {
    try {
      const { data } = await updateInvoiceIssueMutation({
        variables: {
          input,
        },
      });
      return !!data?.updateInvoiceIssue;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const deleteInvoiceIssue = async (id: string) => {
    try {
      const { data } = await deleteInvoiceIssueMutation({
        variables: {
          id,
        },
      });
      return !!data?.deleteInvoiceIssue;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const resolveInvoiceIssue = async (input: ResolveInvoiceIssueInput) => {
    try {
      const { data } = await resolveInvoiceIssueMutation({
        variables: {
          input,
        },
      });
      return !!data?.resolveInvoiceIssue;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return {
    createInvoiceIssue,
    updateInvoiceIssue,
    deleteInvoiceIssue,
    resolveInvoiceIssue,
    loading: creating || updating || deleting || resolving,
  };
};
