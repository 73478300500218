import { Address } from "@/common/components/address/Address";
import { DistributorReleaseFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div``;

type Props = {
  release: DistributorReleaseFieldsFragment;
};

export const DistributorReleaseAddress: FC<Props> = ({ release }) => {
  if (release.warehouse) {
    return (
      <Container>
        <FormattedMessage
          id="FORMATTED_WAREHOUSE_NAME"
          values={{ name: release.warehouse?.name }}
        />{" "}
        - <Address address={release.warehouse.address} />
      </Container>
    );
  }

  return <Address address={release.project?.address} />;
};
