import { useCallback, useState } from "react";

export const useScrollOnDrag = (ref: React.RefObject<HTMLDivElement>) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      setIsDragging(true);
      setStartX(e.pageX - ref.current!.offsetLeft);
      setStartY(e.pageY - ref.current!.offsetTop);
      setScrollLeft(ref.current!.scrollLeft);
      setScrollTop(ref.current!.scrollTop);
    },
    [ref],
  );

  const handleMouseMove = useCallback(
    (e: React.MouseEvent) => {
      if (!isDragging) {
        return;
      }
      e.preventDefault();
      const x = e.pageX - ref.current!.offsetLeft;
      const walk = x - startX;
      ref.current!.scrollLeft = scrollLeft - walk;
      const y = e.pageY - ref.current!.offsetTop;
      const walkY = y - startY;
      ref.current!.scrollTop = scrollTop - walkY;
    },
    [ref, isDragging, startX, startY, scrollLeft, scrollTop],
  );

  const onDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  return {
    handleMouseDown,
    handleMouseMove,
    onDragEnd,
  };
};
