import { hasProperty } from "@/common/utils/objectUtils";
import { useSearchParams } from "react-router-dom";
import { PAGINATION } from "../const";

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setQueryParams = (
    params: Record<
      string,
      string | null | boolean | Array<unknown> | undefined | number
    >,
  ) => {
    const existingParams: Record<string, string> = {};
    const hasPagination = Object.keys(searchParams).some((p) =>
      Object.keys(PAGINATION).includes(p),
    );
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      if (
        (hasPagination && !Object.keys(PAGINATION).includes(param)) ||
        !hasProperty(params, param)
      ) {
        existingParams[param] = value;
      }
    }

    Object.keys(params).forEach(
      (prop) =>
        (params[prop] === null ||
          params[prop] === undefined ||
          params[prop] === "") &&
        delete params[prop],
    );
    const urlParams = new URLSearchParams({
      ...existingParams,
      ...(params as Record<string, string>),
    });
    setSearchParams(urlParams);
  };

  return { queryParams: searchParams, setQueryParams };
};
