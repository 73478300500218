import TypeEquipment from "@/assets/icons/type-equipment.svg?react";
import TypeMaterials from "@/assets/icons/type-materials.svg?react";
import TypeServices from "@/assets/icons/type-services.svg?react";
import { TransactionKind } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import { Tooltip } from "../tooltip/Tooltip";

type Props = {
  orderType?: {
    id: string;
    name: string;
    transactionKind: TransactionKind;
  };
};

const ICON_MAP: Record<TransactionKind, React.ReactNode> = {
  [TransactionKind.Purchase]: <TypeMaterials />,
  [TransactionKind.Rental]: <TypeEquipment />,
  [TransactionKind.Services]: <TypeServices />,
};

export const TypeIndicator: FC<Props> = ({ orderType }) => {
  const intl = useIntl();

  if (!orderType) {
    return null;
  }

  const label = `${intl.$t({ id: "ORDER_TYPE" })}: ${orderType?.name || ""}`;

  return (
    <Tooltip
      id={`type-indicator-${orderType.transactionKind}`}
      element={<>{ICON_MAP[orderType.transactionKind]}</>}
    >
      {label}
    </Tooltip>
  );
};
