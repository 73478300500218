import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { CheckCircle } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { IssueResolution } from "./IssueResolution";

type Props = {
  item?: ExpandedReleaseItem;
  release?: ReleaseFieldsFragment | null;
};

export const ViewResolution: FC<Props> = ({ item }) => {
  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <LinkLike className="text-xs" onClick={() => togglePanel(true)}>
          <CheckCircle />
          <FormattedMessage id={"ISSUE_RESOLUTION"} />
        </LinkLike>
      )}
      content={(togglePanel) => (
        <IssueResolution onClose={() => togglePanel(false)} item={item} />
      )}
    />
  );
};
