import { routes } from "@/config/routes";
import { UserNotificationsSettings } from "../user-notifications-settings/UserNotificationsSettings";
import { UserAccount } from "./UserAccount";

export const userAccountRoutes = [
  {
    path: routes.me,
    element: <UserAccount />,
  },
  {
    path: routes.userNotifications,
    element: <UserNotificationsSettings />,
  },
];
