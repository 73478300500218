import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { LinkLikeText } from "../link-like-text/LinkLikeText";
import { DrawerPanel } from "../panel/DrawerPanel";
import { ProductInfoPanel } from "./ProductInfoPanel";

type ProductInfoProps = {
  sku?: {
    description: string;
    imageUrl: string;
    name: string;
  } | null;
  brand?: string;
  className?: string;
  usePopover?: boolean;
};

const LinkLikeTextStyled = tw(LinkLikeText)`h-0 relative -top-1`;

const InfoOutlinedStyled = tw(InfoOutlined)`mt-1`;

export const ProductInfo: FC<ProductInfoProps> = ({
  sku,
  brand,
  className,
  usePopover,
}) => {
  const intl = useIntl();
  if (!sku?.description) {
    return null;
  }

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <LinkLikeTextStyled
          className={className}
          title={
            !usePopover ? intl.$t({ id: "PRODUCT_INFO" }) : sku.description
          }
        >
          <InfoOutlinedStyled
            fontSize="small"
            onClick={() => !usePopover && togglePanel(true)}
          />
        </LinkLikeTextStyled>
      )}
      content={(togglePanel) => (
        <ProductInfoPanel
          sku={sku}
          brand={brand}
          onClose={() => togglePanel(false)}
        />
      )}
    />
  );
};
