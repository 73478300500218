import { AssetsCard } from "@/common/components/assets-card/AssetsCard";
import { Loader } from "@/common/components/loader/Loader";
import { NoteDocumentPanel } from "@/common/components/note-document-panel/NoteDocumentPanel";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import {
  defaultTableItemSelectionClassNames,
  greenTableItemSelectionClassNames,
} from "@/common/components/searchable-list/utils";
import { ReleaseItemsZoneProvider } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useReleaseUpdate } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import { ReleaseItemList } from "@/contractor/pages/home/release/release-items-list/ReleaseItemList";
import {
  DeliverySlipStatus,
  ReleaseItemFieldsFragment,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  PackingSlipReceiveViewState,
  useDeliverySlipVerification,
} from "../../../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipFilter } from "../../../../types/DeliverySlipFilters";
import { useDeliverySlipReleaseConfiguration } from "./DeliverySlipReleaseConfiguration";
import { DeliverySlipReleaseEditInfo } from "./DeliverySlipReleaseEditInfo";
import { DeliverySlipReleaseItemsFilterHeader } from "./DeliverySlipReleaseItemsFilterHeader";
import { DeliverySlipPanel } from "./DeliverySlipsPanel";

const NotesCard = tw(AssetsCard)`my-[1px] lg:border-0 mt-3 overflow-visible`;
type Props = {
  readonly?: boolean;
};

export const DeliverySlipReleaseItems: FC<Props> = ({ readonly }) => {
  const { release } = useRelease();
  const intl = useIntl();
  const { selectedReleaseItemIds, setSelectedReleaseItemIds } =
    useReleaseUpdate();
  const {
    deliverySlip,
    showOnlySelectedItems,
    filter,
    packingSlipReceiveViewState,
  } = useDeliverySlipVerification();

  const hasSlipItem = useCallback(
    (item: { id: string }) => {
      return deliverySlip?.deliveredReleaseItems.some(
        (drItem) =>
          drItem.releaseItem.id === item.id && Number(drItem.quantity) > 0,
      );
    },
    [deliverySlip?.deliveredReleaseItems],
  );

  useEffect(() => {
    if (deliverySlip) {
      const releaseItemIds =
        release?.items
          .filter((i) => i.isIncluded)
          .filter((item) => hasSlipItem(item))
          .map((item) => item.id) || [];
      setSelectedReleaseItemIds(releaseItemIds);
    } else {
      setSelectedReleaseItemIds(
        release?.items
          .filter((i) => i.isIncluded)
          .filter((item) =>
            new Decimal(item.quantityDecimal).sub(
              item.receivedQuantityDecimal || 0,
            ),
          )
          .map((item) => item.id) || [],
      );
    }
  }, [deliverySlip, hasSlipItem, release?.items, setSelectedReleaseItemIds]);

  const filterFullyReceived = useCallback(
    (i: ReleaseItemFieldsFragment) => {
      switch (filter[DeliverySlipFilter.FULLY_RECEIVED]) {
        case false:
          return new Decimal(i.receivedQuantityDecimal || 0).lessThan(
            i.quantityDecimal,
          );
        case true:
          return new Decimal(
            i.receivedQuantityDecimal || 0,
          ).greaterThanOrEqualTo(i.quantityDecimal);
        default:
          return true;
      }
    },
    [filter],
  );

  const items = useMemo(
    () =>
      release?.items
        .filter(filterFullyReceived)
        .filter(
          (i) =>
            !showOnlySelectedItems || selectedReleaseItemIds.includes(i.id),
        )
        .map((item) => {
          const estimatedItems = item.projectItem?.estimatedItems.filter(
            (ei) => ei.zone?.id === item.zone?.id,
          );
          return {
            ...{
              ...item.projectItem,
              material: {
                ...item.projectItem?.material,
                material: {
                  ...item.projectItem?.material.material,
                  name: item.name,
                },
              },
              estimatedItems,
            },
            ...item,
          } as ExpandedReleaseItem;
        }) ?? [],
    [
      filterFullyReceived,
      release?.items,
      selectedReleaseItemIds,
      showOnlySelectedItems,
    ],
  );
  const configuration = useDeliverySlipReleaseConfiguration({
    items,
    projectId: release?.project?.id,
  });

  const itemFn = useCallback(
    (item: ExpandedReleaseItem) => {
      return deliverySlip?.status === DeliverySlipStatus.Processed
        ? packingSlipReceiveViewState ===
          PackingSlipReceiveViewState.EDIT_COVERAGE
          ? selectedReleaseItemIds.includes(item.id)
            ? defaultTableItemSelectionClassNames
            : "bg-gray-100"
          : hasSlipItem(item)
            ? greenTableItemSelectionClassNames
            : ""
        : selectedReleaseItemIds.includes(item.id)
          ? defaultTableItemSelectionClassNames
          : "bg-gray-100";
    },
    [
      deliverySlip?.status,
      hasSlipItem,
      packingSlipReceiveViewState,
      selectedReleaseItemIds,
    ],
  );

  if (!release) {
    return <Loader loading />;
  }

  return (
    <ReleaseItemsZoneProvider items={items}>
      <DeliverySlipReleaseItemsFilterHeader />
      <ReleaseItemList
        columns={configuration}
        classNames={{
          header: "-top-6 bg-gray-100",
          category: "top-5",
          subCategory: (groupedByZones: boolean) =>
            groupedByZones ? "top-16" : "top-5",
        }}
        itemFn={(item) => ({
          className: itemFn(item),
        })}
        readonly={readonly}
      />
      <DeliverySlipReleaseEditInfo />
      <NotesCard
        headerClassName="bg-blue-100"
        title={intl.$t({ id: "NOTES_AND_ATTACHMENTS" })}
        assets={release.instructions?.assets || []}
        expanded={false}
      />
      <QuoteDocumentPanel quoteDocument={release.quoteDocument} readonly />
      <NoteDocumentPanel noteDocument={release.noteDocument} readonly />
      <DeliverySlipPanel release={release} expanded />
    </ReleaseItemsZoneProvider>
  );
};
