import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { FormattedMessage, useIntl } from "react-intl";
import { useExternalVendorMapping } from "../../providers/ExternalVendorMappingProvider";
import { VendorForm } from "./VendorForm";

export const NewVendorButton = () => {
  const intl = useIntl();
  const { setSelectedExternalVendorData, setSourceSystem } =
    useExternalVendorMapping();

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <PrimaryButton wide onClick={() => togglePanel(true)}>
          <FormattedMessage id="VENDORS_NEW_BUTTON" />
        </PrimaryButton>
      )}
      content={(togglePanel) => (
        <VendorForm
          title={intl.$t({ id: "VENDORS_NEW_TITLE" })}
          onClose={() => {
            togglePanel(false);
            setSourceSystem(null);
            setSelectedExternalVendorData(null);
          }}
        />
      )}
    />
  );
};
