import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { Switch } from "../switch/Switch";

const Container = tw.div`flex flex-row items-center gap-1`;

export enum ToggleFilterType {
  Invoice,
  ReceiveRelease,
}

type Props = {
  onToggle: (show: boolean) => void;
  show: boolean;
  type: ToggleFilterType;
};

export const ToggleFilterItems: FC<Props> = ({ onToggle, show, type }) => {
  const intl = useIntl();
  const labelKey = useMemo(() => {
    switch (type) {
      case ToggleFilterType.Invoice:
        return "SHOW_ONLY_INVOICED_ITEMS";
      case ToggleFilterType.ReceiveRelease:
        return "SHOW_ONLY_RECEIVED_ITEMS";
    }
  }, [type]);

  return (
    <Container>
      <FormattedMessage id={labelKey} />
      <Switch
        value={show}
        onChange={onToggle}
        width={60}
        offLabel={intl.$t({ id: "NO" })}
        onLabel={intl.$t({ id: "YES" })}
      />
    </Container>
  );
};
