import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useViewerNotificationsQuery } from "@/generated/graphql";

export const useViewerNotifications = () => {
  const { data, error, loading } = useViewerNotificationsQuery();

  useErrorEffect(error);

  return {
    viewerNotifications: data?.viewer,
    loading,
  };
};
