import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { BuyoutsBuyoutFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

type Props = {
  item: BuyoutsBuyoutFieldsFragment;
};

const Container = tw.div`
  flex flex-col text-sm
`;

const NumberContainer = tw.div`
  font-bold
`;

const DateContainer = tw.div`
  font-thin
`;

export const BuyoutNo: FC<Props> = ({ item }) => {
  return (
    <Container>
      <NumberContainer>{item.clientIdentifier}</NumberContainer>
      <DateContainer>
        <DateView date={item.createdAt} options={SHORT_DATE_OPTION} />
      </DateContainer>
    </Container>
  );
};
