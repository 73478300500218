import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { RELEASE } from "@/common/queries/release";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AddToReleaseItemInput,
  UomsDocument,
  UpdateContractorReleaseInput,
  UpdateContractorReleaseItemInput,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { InvoiceCreateReleaseFormValues } from "../components/matched-order/components/InvoiceVerificationForm";
import { useInvoiceVerification } from "./InvoiceVerificationProvider";

type ProviderContextType = {
  syncUpdateReleaseFromInvoice: (
    values: InvoiceCreateReleaseFormValues,
  ) => Promise<boolean>;
  saving: boolean;
  updateRelease: (input: UpdateContractorReleaseInput) => Promise<boolean>;
};

const ProviderContext = createContext<ProviderContextType>({
  syncUpdateReleaseFromInvoice: NoFunctionBooleanPromise,
  saving: false,
  updateRelease: NoFunctionBooleanPromise,
});

export const InvoiceUpdateReleaseProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { invoice, refetch } = useInvoiceVerification();

  const {
    spreadsheetData,
    getRemovedRowIds,
    resetPreviousData,
    gotoInvalidRow,
  } = useColumnMapper();
  const {
    getCellValue,
    getRowUomCreatableValue,
    getCostCodeId,
    rowIsEmpty,
    addMissingMaterials,
    getCellWithAdditionalData,
    findMaterialByName,
  } = useTableHelpers();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { setError } = useGlobalError();
  const { release } = useRelease();
  const { materials } = useMaterials();
  const [saving, setSaving] = useState(false);
  const [updateReleaseMutation, { loading: updating }] =
    useUpdateContractorReleaseMutation();

  const { reset, getValues } = useFormContext<InvoiceCreateReleaseFormValues>();

  useEffect(() => {
    if (release) {
      reset({
        ...getValues(),
        projectId: getValues("projectId") || release?.project?.id,
        businessLocationId:
          getValues("businessLocationId") || release?.project?.location?.id,
        vendorId: getValues("vendorId") || release?.sellerOrgLocation?.id,
        fulfillmentLocationId:
          getValues("fulfillmentLocationId") || release?.warehouse?.id || "",
        poNumber: release?.poNumber ?? "",
        orderDate: new Date(release?.time ?? 0),
        orderTypeId: release?.type.id,
        customTaxAmount: release?.taxAmount || undefined,
        taxRate: release?.taxRate || undefined,
        additionalCharges: release?.additionalCharges ?? [],
        subtotal: Number(release?.subtotal ?? 0),
        total: release?.total || "",
      });
    }
  }, [getValues, release, reset]);

  const syncUpdateReleaseFromInvoice = async (
    values: InvoiceCreateReleaseFormValues,
  ) => {
    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
      ]) ||
      !validateRowValues(
        [COLUMN_TYPE.Quantity, COLUMN_TYPE.UOM, COLUMN_TYPE.CostCode],
        undefined,
        { minPrice: undefined },
      )
    ) {
      gotoInvalidRow();
      return false;
    }

    const newItems: AddToReleaseItemInput[] = [];
    const itemsToUpdate: UpdateContractorReleaseItemInput[] = [];
    const itemsToRemove = getRemovedRowIds(release?.items ?? []);

    setSaving(true);
    const newMaterials = (await addMissingMaterials()) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getRowUomCreatableValue(row);
      const isLumpSum = isLumpSumUomText(uom);
      if (isLumpSum) {
        material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
          ...materials,
          ...newMaterials,
        ]);
      }

      if (!material || rowIsEmpty(row)) {
        return false;
      }

      const quantityDecimal = getCellValue(row, COLUMN_TYPE.Quantity);
      const unitPrice = getCellValue(row, COLUMN_TYPE.UnitPrice);
      const notes = getCellValue(row, COLUMN_TYPE.Notes);

      const hasUnitPrice =
        unitPrice !== "" && unitPrice !== null && unitPrice !== undefined;

      const existingItem = release?.items.find((item) => item.id === row.id);
      const matchingMaterials =
        existingItem && existingItem.projectItem?.material.id === material.id;

      if (matchingMaterials) {
        itemsToUpdate.push({
          releaseItemId: row.id,
          uom,
          quantityDecimal: quantityDecimal,
          unitPrice: hasUnitPrice ? String(unitPrice) : undefined,
          ...(isLumpSum && { name: rowMaterialText }),
          clearUnitPrice: !hasUnitPrice,
          position: index,
          costCodeId: getCostCodeId(row),
          clearCostCode: !getCostCodeId(row),
          instructions: { text: notes },
        });
      } else {
        newItems.push({
          invoiceId: invoice?.id ?? "",
          projectItem: {
            estimateUom: uom ?? "",
            ...(isOrgCatalogSku(material.material) && {
              orgCatalogSkuId: material.material.id,
            }),
            ...(isProductSku(material.material) && {
              masterProductId: material.material.id,
            }),
            ...(isMasterSku(material.material) && {
              masterSkuId: material.material.id,
            }),
          },
          quantityDecimal: quantityDecimal,
          unitPrice: hasUnitPrice ? String(unitPrice) : undefined,
          ...(isLumpSum && { name: rowMaterialText }),
          position: index,
          costCodeId: getCostCodeId(row),
          instructions: { text: notes },
        });
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    if (release) {
      try {
        const { errors } = await updateReleaseMutation({
          variables: {
            input: {
              releaseId: release.id,
              version: release.version,
              addedItems: newItems,
              poNumber: values.poNumber,
              updates: itemsToUpdate,
              removedItems: itemsToRemove,
              taxRate: values.taxRate || undefined,
              customTaxAmount: values.customTaxAmount || undefined,
              clearCustomTaxAmount: !values.customTaxAmount,
              additionalCharges: values.additionalCharges.filter(
                (charge) => charge.description && Number(charge.amount) > 0,
              ),
              assignDefaultCostCodes: false,
              prefillPrices: false,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: RELEASE,
              variables: { id: release?.id ?? "" },
            },
            {
              query: UomsDocument,
            },
          ],
        });
        setError(errors);
        if (!errors) {
          resetPreviousData();
          refetch();
        }
        return !errors;
      } catch (error) {
        setError(error);
        return false;
      }
    }

    return true;
  };

  const updateRelease = async (input: UpdateContractorReleaseInput) => {
    setSaving(true);
    try {
      const { errors } = await updateReleaseMutation({
        variables: {
          input,
        },
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    } finally {
      setSaving(false);
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        syncUpdateReleaseFromInvoice,
        updateRelease,
        saving: saving || updating,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceUpdateRelease = (): ProviderContextType =>
  useContext(ProviderContext);
