import { EditOutlined } from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { BaseButton } from "./BaseButton";
import { IconButtonBorderless } from "./IconButton";

const EditIcon = tw(EditOutlined)`w-5 h-5`;
type EditButtonProps = React.ComponentProps<typeof BaseButton>;

export const EditButton: FC<EditButtonProps> = ({ ...props }) => {
  return (
    <IconButtonBorderless autoScale {...props} testId="edit-button">
      <EditIcon />
    </IconButtonBorderless>
  );
};
