import { BuyoutStatus } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { BaseStatusChip, BaseStatusChipProps } from "./BaseStatusChip";

type Props = BaseStatusChipProps & {
  status: BuyoutStatus;
};

const BUYOUT_STATUS = {
  [BuyoutStatus.Draft]: "bg-gray-200",
  [BuyoutStatus.Cancelled]: "bg-red-200",
  [BuyoutStatus.Declined]: "bg-red-200",
  [BuyoutStatus.Requested]: "bg-yellow-600",
  [BuyoutStatus.Active]: "bg-green-300",
  [BuyoutStatus.Depleted]: "bg-green-600 text-white",
};

export const BuyoutStatusChip: FC<Props> = ({ status, type = "normal" }) => {
  return (
    <BaseStatusChip
      type={type}
      classes={{ text: BUYOUT_STATUS[status] }}
      data-testid={`buyout-status-${type}`}
    >
      <FormattedMessage id={`BUYOUT_STATUS_${status}`} />
    </BaseStatusChip>
  );
};
