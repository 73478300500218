import { ProjectMaterialSkuFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { ProductBrand } from "../product/ProductBrand";
import { MaterialProductInfo } from "./MaterialProductInfo";
import { MaterialType } from "./MaterialType";
import { isOrgCatalogSku } from "./utils";

type Props = {
  material: MaterialType;
  className?: string;
  includeInfo?: boolean;
};

const Container = tw.div`grid grid-flow-col justify-start items-baseline gap-2 relative`;

export const MaterialProductBrand: FC<Props> = ({
  material,
  className,
  includeInfo,
}) => (
  <>
    {isOrgCatalogSku(material) ? null : (
      <Container>
        <ProductBrand
          className={className}
          brand={
            (material as ProjectMaterialSkuFieldsFragment)?.manufacturer
              ?.name || null
          }
        />
        <If
          isTrue={
            includeInfo &&
            (material as ProjectMaterialSkuFieldsFragment)?.manufacturer
          }
        >
          <MaterialProductInfo material={material} />
        </If>
      </Container>
    )}
  </>
);
