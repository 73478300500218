import { AssetsCard } from "@/common/components/assets-card/AssetsCard";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";

type Props = {
  release: Pick<ReleaseFieldsFragment, "deliveryPhotos"> & {
    deliverySlips: Array<
      Pick<
        ReleaseFieldsFragment["deliverySlips"][number],
        "id" | "asset" | "archivedAt"
      >
    >;
  };
};

export const ReleaseDeliveryPhotos: FC<Props> = ({ release }) => {
  const intl = useIntl();

  return (
    <AssetsCard
      title={intl.$t({ id: "DELIVERY_PHOTOS_FOR_THIS_ORDER" })}
      assets={[...(release.deliveryPhotos || [])]}
    />
  );
};
