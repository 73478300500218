import { QuantityPicker } from "@/common/components/quantity-picker/QuantityPicker";
import { UpdateContractorReleaseInput } from "@/generated/graphql";
import Decimal from "decimal.js";
import { useCallback } from "react";
import { ExpandedReleaseItem } from "../../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../../providers/ReleaseUpdateProvider";

type Props = {
  item: ExpandedReleaseItem;
  readonly?: boolean;
};

export const ReleaseReceivedQuantityInput = ({ item, readonly }: Props) => {
  const { updateRelease, release } = useReleaseUpdate();

  const saveQuantity = useCallback(
    async (receivedQuantityDecimal: string | null) => {
      if (!item.id || !release) {
        return;
      }

      const input: UpdateContractorReleaseInput = {
        releaseId: release?.id,
        version: release?.version,
        updates: [
          {
            releaseItemId: item.id,
            receivedQuantityDecimal,
          },
        ],
      };

      await updateRelease(input, { setLoading: true });
    },
    [item.id, release, updateRelease],
  );

  return (
    <QuantityPicker
      quantity={item.receivedQuantityDecimal}
      id={item.id}
      saveQuantity={saveQuantity}
      error={new Decimal(item.receivedQuantityDecimal || 0).lessThanOrEqualTo(
        0,
      )}
      hideErrorIcon
      className="bg-white"
      staticText={readonly}
    />
  );
};
