import { If } from "@/common/components/if/If";
import { InnerLabeledSwitch } from "@/common/components/switch/InnerLabeledSwitch";
import { checkQuoteStatus } from "@/common/utils/status-checks/checkQuoteStatus";
import { QuoteStatus } from "@/generated/graphql";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { DISTRIBUTOR_BULK_UPDATE_STATUSES } from "../../../common/constants";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../providers/DistributorQuoteItemsProvider";
import { useDistributorQuote } from "../providers/DistributorQuoteProvider";

const Container = tw.div`
  flex items-center z-100
`;

type DistributorQuoteItemToggleProps = {
  items: DistributorQuoteItemGroupExtendedFieldsFragment[];
  readonly?: boolean;
};

export const DistributorQuoteCategoryToggle: FC<
  DistributorQuoteItemToggleProps
> = ({ items, readonly }) => {
  const intl = useIntl();

  const [includeItem, toggleItem] = useState<boolean>(
    items.every((item) => item.quoteItems[0]?.status !== QuoteStatus.Withdrawn),
  );
  const [isNeutral, setIsNeutral] = useState<boolean>(false);
  const { updateQuote, quote } = useDistributorQuote();
  const bulkUpdate = useMemo(
    () => checkQuoteStatus(quote, DISTRIBUTOR_BULK_UPDATE_STATUSES),
    [quote],
  );

  const hasNoQuantity = useCallback(
    () => (item: DistributorQuoteItemGroupExtendedFieldsFragment) => {
      return item.quoteItems[0]?.status === QuoteStatus.Withdrawn;
    },
    [],
  );

  useEffect(() => {
    if (items.every(hasNoQuantity()) && includeItem) {
      toggleItem(false);
    }
  }, [items, includeItem, hasNoQuantity]);

  useEffect(() => {
    const neutral = bulkUpdate
      ? items.some(hasNoQuantity()) && !items.every(hasNoQuantity())
      : items.some(
          (item) => item.quoteItems[0]?.status === QuoteStatus.Withdrawn,
        ) &&
        !items.every(
          (item) => item.quoteItems[0]?.status === QuoteStatus.Withdrawn,
        );
    setIsNeutral(neutral);

    if (!items.every(hasNoQuantity())) {
      toggleItem(true);
    }
  }, [bulkUpdate, hasNoQuantity, items]);

  const onToggle = useCallback(
    async (checked: boolean) => {
      setIsNeutral(false);
      const itemsToUpdate = checked
        ? items
        : items.filter(
            (item) => item.quoteItems[0]?.status !== QuoteStatus.Withdrawn,
          );
      const updates = itemsToUpdate.map((item) => ({
        quoteItemId: item.quoteItems[0]?.id,
        isIncluded: checked,
        quantityDecimal: checked ? item.rfqItem.quantityDecimal : undefined,
      }));
      await updateQuote({
        updates,
      });

      toggleItem(checked);
    },
    [items, updateQuote],
  );

  return (
    <Container onClick={(e: React.MouseEvent) => e.stopPropagation()}>
      <If isTrue={!readonly}>
        <InnerLabeledSwitch
          toggle={onToggle}
          className="print:hidden"
          width={80}
          initialValue={includeItem}
          onLabel={
            isNeutral ? intl.$t({ id: "YES" }) : intl.$t({ id: "YES_ALL" })
          }
          offLabel={
            isNeutral ? intl.$t({ id: "NO" }) : intl.$t({ id: "NO_ALL" })
          }
          neutral={isNeutral}
        />
      </If>
    </Container>
  );
};
