import { BulkCostCodeSelector } from "@/common/components/cost-code-selector/BulkCostCodeSelector";
import { FC, useCallback } from "react";
import { useRfq } from "../../hooks/useRfq";
import { useRfqGroupedItems } from "../../hooks/useRfqGroupedItems";

type Props = {
  setLoading: (loading: boolean) => void;
};

export const RfqBulkCostCodeSelector: FC<Props> = ({ setLoading }) => {
  const { selectedRfqItemIds, setSelectedRfqItemIds } = useRfqGroupedItems();
  const { rfq, updateRfq } = useRfq();

  const updateCostCode = useCallback(
    async (code: string | null) => {
      if (!rfq) {
        return;
      }
      await updateRfq({
        rfqId: rfq.id,
        updates: selectedRfqItemIds.map((id) => ({
          rfqItemId: id,
          costCodeId: code,
        })),
      });
      setSelectedRfqItemIds([]);
    },
    [rfq, selectedRfqItemIds, setSelectedRfqItemIds, updateRfq],
  );

  return (
    <BulkCostCodeSelector
      count={selectedRfqItemIds.length}
      setLoading={setLoading}
      update={updateCostCode}
      projectId={rfq?.project?.id}
    />
  );
};
