import { useTabActive } from "@/common/hooks/useTabActive";
import { InvoiceToExportFieldsFragment } from "@/generated/graphql";
import { FC, useEffect } from "react";
import { useInvoiceReleaseValidation } from "../../../invoice-verification/hooks/useInvoiceValidation";
import {
  InvoiceValidationProvider,
  useInvoiceValidation,
} from "../../providers/InvoiceValidationProvider";
import { InvoiceReleaseDetails } from "./InvoiceReleaseDetails";

type Props = {
  invoice: InvoiceToExportFieldsFragment;
  linking?: boolean;
  error?: string;
};

const HostedIntegrationInvoiceInformationWithProvider: FC<Props> = ({
  invoice,
  linking,
  error,
}) => {
  const { refetchInvoiceValidation, loading, poNumber, setPoNumber } =
    useInvoiceValidation();
  const { exportValidations } = useInvoiceReleaseValidation(
    invoice,
    poNumber || invoice.poNumber || invoice.release?.poNumber?.toString() || "",
  );

  const { isTabActive } = useTabActive();

  useEffect(() => {
    if (isTabActive && !loading) {
      refetchInvoiceValidation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabActive]);

  return (
    <InvoiceReleaseDetails
      invoice={invoice}
      includedValidations={exportValidations}
      linking={linking}
      loading={loading}
      poNumber={poNumber}
      setPoNumber={setPoNumber}
      error={error}
    />
  );
};

export const HostedIntegrationInvoiceInformation: FC<Props> = (props) => (
  <InvoiceValidationProvider invoice={props.invoice}>
    <HostedIntegrationInvoiceInformationWithProvider {...props} />
  </InvoiceValidationProvider>
);
