import { If } from "@/common/components/if/If";
import {
  OrgRolesWrapper,
  Permission,
} from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { ExternalProjectFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { StateSelectorControlled } from "../../../../../../common/components/state-selector/StateSelectorControlled";
import { ProjectAddress2TextField } from "../project-form/input-components/ProjectAddress2TextField";
import { ProjectAddressCityTextField } from "../project-form/input-components/ProjectAddressCityTextField";
import { ProjectAddressPostalCodeTextField } from "../project-form/input-components/ProjectAddressPostalCodeTextField";
import { ProjectAddressTextField } from "../project-form/input-components/ProjectAddressTextField";
import { ProjectBudgetTextField } from "../project-form/input-components/ProjectBudgetTextField";
import { ProjectCountrySelector } from "../project-form/input-components/ProjectCountrySelector";
import { ProjectJobDatePicker } from "../project-form/input-components/ProjectJobDatePicker";
import { ProjectJobNumberTextField } from "../project-form/input-components/ProjectJobNumberTextField";
import { ProjectNameTextField } from "../project-form/input-components/ProjectNameTextField";
import { ProjectImportInputStatus } from "./ProjectImportInputStatus";

const Container = tw.div`flex basis-1/2 flex-col gap-3 py-4 px-8 mt-3`;
const Row = tw.div`flex flex-row items-center gap-1 w-full`;
const AddressRow = tw(Row)`grid grid-cols-[25px_2fr_1fr_100px_1.5fr]`;
const Label = tw.div`text-sm font-medium`;

type Props = {
  index: number;
  externalProject?: ExternalProjectFieldsFragment;
};

export const ProjectImportForm: FC<Props> = ({ index, externalProject }) => {
  const { getFieldState, watch } = useFormContext();

  return (
    <Container>
      <Label>
        <FormattedMessage id="SPECIFY" />:
      </Label>
      <If isTrue={!externalProject?.name}>
        <Row>
          <ProjectImportInputStatus
            success={!getFieldState(`projects.${index}.name`).error}
          />
          <ProjectNameTextField name={`projects.${index}.name`} />
        </Row>
      </If>
      <If isTrue={!externalProject?.number}>
        <Row>
          <ProjectImportInputStatus
            success={!getFieldState(`projects.${index}.jobNumber`).error}
            optional
          />
          <ProjectJobNumberTextField
            name={`projects.${index}.jobNumber`}
            required={false}
            tooltip="GENERATED_JOB_NUMBER"
          />
        </Row>
      </If>
      <If isTrue={!externalProject?.address?.addressLine1}>
        <Row>
          <ProjectImportInputStatus
            success={
              !getFieldState(`projects.${index}.address.addressLine1`).error
            }
          />
          <ProjectAddressTextField
            name={`projects.${index}.address.addressLine1`}
          />
        </Row>
      </If>
      <If isTrue={!externalProject?.address?.addressLine1}>
        <Row>
          <ProjectImportInputStatus
            success={
              !getFieldState(`projects.${index}.address.addressLine2`).error
            }
            optional={!watch(`projects.${index}.address.addressLine2`)}
          />
          <ProjectAddress2TextField
            name={`projects.${index}.address.addressLine2`}
          />
        </Row>
      </If>
      <If
        isTrue={
          !externalProject?.address?.addressLine1 ||
          !externalProject.address.city ||
          !externalProject.address.state ||
          !externalProject.address.postalCode
        }
      >
        <AddressRow>
          <ProjectImportInputStatus
            success={
              !getFieldState(`projects.${index}.address.city`).error &&
              !getFieldState(`projects.${index}.address.state`).error &&
              !getFieldState(`projects.${index}.address.postalCode`).error &&
              !getFieldState(`projects.${index}.address.country`).error
            }
          />
          <ProjectAddressCityTextField
            name={`projects.${index}.address.city`}
          />
          <StateSelectorControlled
            name={`projects.${index}.address.state`}
            countryInputName={`projects.${index}.address.country`}
            useDefaultCountryAndState={false}
          />
          <ProjectAddressPostalCodeTextField
            name={`projects.${index}.address.postalCode`}
            countryInputName={`projects.${index}.address.country`}
          />
          <ProjectCountrySelector name={`projects.${index}.address.country`} />
        </AddressRow>
      </If>
      <If isTrue={!externalProject?.startDate}>
        <Row>
          <ProjectImportInputStatus
            success={!getFieldState(`projects.${index}.startDate`).error}
          />
          <ProjectJobDatePicker name={`projects.${index}.startDate`} />
        </Row>
      </If>
      <If isTrue={!externalProject?.amount}>
        <OrgRolesWrapper permissions={[Permission.canViewPrices]}>
          <Row>
            <ProjectImportInputStatus
              success={!getFieldState(`projects.${index}.budget`).error}
              optional={!watch(`projects.${index}.budget`)}
            />
            <ProjectBudgetTextField name={`projects.${index}.budget`} />
          </Row>
        </OrgRolesWrapper>
      </If>
    </Container>
  );
};
