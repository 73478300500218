import { FormattedMessage } from "react-intl";
import { Size, ViewToggle } from "../view-toggle/ViewToggle";

export enum ViewType {
  Itemized = "itemized",
  NonItemized = "non-itemized",
}

const toggleOptions = [
  {
    label: <FormattedMessage id="NON_ITEMIZED" />,
    viewState: ViewType.NonItemized,
  },
  {
    label: <FormattedMessage id="ITEMIZED" />,
    viewState: ViewType.Itemized,
  },
];

export const ItemizedNonItemizedToggle = ({
  handleChange,
  isItemized,
  size = "small",
}: {
  handleChange: (viewType: ViewType) => void;
  isItemized: boolean;
  size?: Size;
}) => {
  return (
    <ViewToggle
      options={toggleOptions}
      setView={handleChange}
      tableView={isItemized ? ViewType.Itemized : ViewType.NonItemized}
      size={size}
    />
  );
};
