import { FC } from "react";
import { useDropzone } from "react-dropzone";
import tw from "tailwind-styled-components";

type Props = {
  onDrop: (acceptedFiles: File[]) => void;
  className?: string;
  components: {
    uploadArea: React.ReactNode;
  };
  disabled?: boolean;
};

const Container = tw.div``;

export const UploadArea: FC<Props> = ({
  onDrop,
  className,
  components,
  disabled = false,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    disabled,
  });
  const { uploadArea } = components;
  return (
    <Container className={className} {...getRootProps()}>
      <input {...getInputProps()} />
      {uploadArea}
    </Container>
  );
};
