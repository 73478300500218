import { Controller, useFormContext } from "react-hook-form";
import { ControlledProps } from "../../../textfield-controlled/TextFieldControlled";
import { Multiselect, MultiselectProps } from "./Multiselect";

type MultiselectControlledProps<T> = ControlledProps &
  MultiselectProps<T> & {
    onCustomMultipleChange?: (value: string[] | null) => void;
  };

export const MultiselectControlled = <T,>({
  name,
  rules,
  onCustomMultipleChange,
  ...props
}: MultiselectControlledProps<T>) => {
  const { control, trigger } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Multiselect<T>
          {...field}
          {...props}
          onMultipleChange={
            onCustomMultipleChange
              ? (values) => {
                  onCustomMultipleChange(values);
                  trigger(name);
                }
              : field.onChange
          }
          values={field.value}
          error={!!fieldState.invalid}
          className={`${props.className} bg-white ${
            props.staticText && "ml-3"
          }`}
          testId={name}
          required={!!rules?.required}
        />
      )}
    />
  );
};
