import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useCurrenciesQuery } from "@/generated/graphql";

export const useCurrencies = () => {
  const { data, error, loading } = useCurrenciesQuery();
  useErrorEffect(error);

  return {
    currencies: data?.currencies || [],
    loading,
  };
};
