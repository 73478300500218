import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { PDF_FONT, PDF_LEFT_SPACING } from "@/common/const";
import { useDownloadAsset } from "@/common/hooks/useDowloadAsset";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import { DistributorReleaseFieldsFragment } from "@/generated/graphql";
import jsPDF from "jspdf";
import { useIntl } from "react-intl";
import { useDistributorReleaseItemsZoneProvider } from "../providers/DistributorReleaseItemsZoneProvider";
import { total } from "../utils/print/total";
import { backorderMaterials } from "./print/backorderMaterials";
import { generalInstructions } from "./print/generalInstructions";
import { materials } from "./print/materials";
import { releaseHeader } from "./print/releaseHeader";

export const usePrintView = (
  release: DistributorReleaseFieldsFragment | null | undefined,
) => {
  const intl = useIntl();
  const { zones, groupedByCostCode } = useDistributorReleaseItemsZoneProvider();
  const { formatCurrency } = useFormatNumberToCurrency();

  const { getVendorHasManufacturerSetting } = useManufacturersSettings();
  const hasManufacturersSetting = getVendorHasManufacturerSetting(
    release?.orgLocation?.org.settings?.display,
  );
  const { downloadAsset } = useDownloadAsset();

  const savePDF = async () => {
    const contractorLogo = await downloadAsset(
      release?.orgLocation?.org.photoUrl,
    );

    const vendorLogo = await downloadAsset(
      release?.sellerOrgLocation?.org.photoUrl,
    );

    let topSpacing = 20;
    if (release) {
      const doc = new jsPDF("landscape")
        .setFont(PDF_FONT)
        .setLineHeightFactor(1.5);

      doc
        .setFont(PDF_FONT, "", "bold")
        .setFontSize(16)
        .text(
          `${intl.$t(
            {
              id: "RELEASE_WITH_NUM",
            },
            {
              num: release.sequenceNumber,
            },
          )}${
            release.poNumber
              ? ": " +
                intl.$t(
                  {
                    id: "PO_WITH_NUM",
                  },
                  {
                    num: release.poNumber,
                  },
                )
              : ""
          }`,
          PDF_LEFT_SPACING,
          topSpacing,
        );

      topSpacing = releaseHeader(
        doc,
        release,
        intl,
        topSpacing + 10,
        contractorLogo.base64Data,
        contractorLogo.proportion,
        vendorLogo.base64Data,
        vendorLogo.proportion,
      );

      topSpacing = generalInstructions(doc, release, intl, topSpacing + 10);
      materials(
        doc,
        release,
        intl,
        zones,
        topSpacing,
        hasManufacturersSetting,
        groupedByCostCode,
        formatCurrency,
      );
      total(doc, release, intl, formatCurrency);
      if (
        release.items.find(
          (i) =>
            i.alternativeFulfillmentRelease || i.alternativeFulfillmentTime,
        )
      ) {
        doc.addPage();
        backorderMaterials(doc, release, intl, zones, formatCurrency);
      }
      doc.save(`Order #${release.sequenceNumber}.pdf`);
    }
  };

  return {
    savePDF,
  };
};
