import { StyledSelect } from "@/common/components/select/components/single/StyledSelect";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import {
  EstimatedItemFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useProjectCostCodes } from "../../hooks/useProjectCostCodes";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";
import { useProject } from "../../providers/ProjectProvider";

type Props = {
  item?: ProjectItemFieldsFragment;
  estimatedItem?: EstimatedItemFieldsFragment | null;
  readonly?: boolean;
};

export const ProjectItemCostCodePicker: FC<Props> = ({
  item,
  estimatedItem,
  readonly = false,
}) => {
  const { project } = useProject();
  const [selectedCostCode, setSelectedCostCode] = useState<string | null>(
    estimatedItem?.costCode?.id ||
      item?.material?.costCode?.id ||
      UNSPECIFIED_COST_CODE_ID,
  );
  const { costCodes, isNotSupportedCostCode } = useProjectCostCodes(
    project?.id,
    {
      selectedCostCodeId: selectedCostCode,
      includeUnspecifiedCostCode: true,
    },
  );
  const { setNewProjectEstimatedItem, updating } = useEstimatedItems();

  useEffect(() => {
    if (!updating) {
      setSelectedCostCode(
        estimatedItem?.costCode?.id ||
          item?.material?.costCode?.id ||
          UNSPECIFIED_COST_CODE_ID,
      );
    }
  }, [estimatedItem?.costCode?.id, item?.material?.costCode?.id, updating]);

  const handleChange = useCallback(
    (newValue: string | null) => {
      setSelectedCostCode(newValue);
      setNewProjectEstimatedItem([{ key: "costCodeId", value: newValue }]);
    },
    [setNewProjectEstimatedItem],
  );

  return (
    <Tooltip
      id="cost-code-tooltip"
      className="w-full"
      element={
        <StyledSelect
          options={costCodes}
          value={selectedCostCode}
          getValue={(opt) => opt.id}
          getLabel={(opt) => opt.description}
          getOptionDisabled={(opt) => !!opt.isExcluded}
          onChange={handleChange}
          disabled={readonly}
          error={isNotSupportedCostCode}
          xs
          testId="editable-project-item-cost-code"
        />
      }
      key="cost-code-tooltip"
    >
      {isNotSupportedCostCode ? (
        <FormattedMessage id="MISSING_COST_CODE_ERROR" />
      ) : undefined}
    </Tooltip>
  );
};
