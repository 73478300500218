import { CircleCounter } from "@/common/components/circle-counter/CircleCounter";
import { CountType } from "@/common/components/circle-counter/CountType";
import {
  AddOrgMaterial,
  MaterialSummaryFields,
} from "@/contractor/pages/home/rfq/components/add-org-sku/AddOrgMaterial";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const CircleCounterStyled = tw(CircleCounter)`
  mx-0 float-left justify-center
`;

type Props = {
  setSelectedOrgMaterial: (
    material: MaterialSummaryFields | null | undefined,
  ) => void;
  error?: boolean;
  count: CountType;
};

export const AddOrgMaterialWithCounter: FC<Props> = ({
  count,
  setSelectedOrgMaterial,
  error = false,
}) => {
  const intl = useIntl();
  return (
    <>
      <CircleCounterStyled count={count} />
      <AddOrgMaterial
        setSelectedOrgMaterial={setSelectedOrgMaterial}
        label={intl.$t({
          id: "SKU_CATEGORY_DESCRIPTION_READONLY",
        })}
        error={error}
      />
    </>
  );
};
