import { FC, ReactNode } from "react";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";
import { LinkLike } from "../link-like/LinkLike";

type Props = {
  chunks: ReactNode;
  route: string;
  className?: string;
  routeParams?: {
    [key: string]: string | null;
  };
};

const Link = tw(LinkLike)`inline text-white text-base underline`;
export const LinkChunks: FC<Props> = ({
  chunks,
  route,
  routeParams,
  className,
}) => (
  <Link
    className={className}
    to={generatePath(route, routeParams)}
    target="_blank"
  >
    {chunks}
  </Link>
);
