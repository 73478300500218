import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { Select } from "@/common/components/select/components/single/Select";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";

const Container = tw.div`grid grid-flow-col gap-2 items-center`;

export const BuyoutInlineOrderType: FC = () => {
  const intl = useIntl();
  const { buyout, updateBuyout, updating } = useContractorBuyout();
  const { setSuccessAlert } = useSnackbar();
  const { openDialog } = useDialog();
  const { orderTypes, loading: loadingTypes } = useOrderTypeOptions();
  const [orderType, setOrderType] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (buyout?.releaseType) {
      setOrderType(buyout?.releaseType.id);
    }
  }, [buyout]);

  const updateBuyoutOrderType = useCallback(
    async (orderType: string | null) => {
      if (!buyout) {
        return;
      }
      setOrderType(orderType);
      setSaving(true);
      const result = await updateBuyout(
        {
          buyoutId: buyout?.id,
          releaseTypeId: orderType,
        },
        true,
      );
      setSaving(false);
      if (result) {
        setSuccessAlert(intl.$t({ id: "ORDER_TYPE_UPDATED_ON_BUYOUT" }));
      }
    },
    [buyout, intl, setSuccessAlert, updateBuyout],
  );

  const onOrderTypeChange = useCallback(
    (value: string | null) => {
      if (buyout?.releases && buyout?.releases?.length > 0) {
        openDialog({
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "SAVE" }),
          icon: <WarningIcon />,
          title: intl.$t({ id: "CHANGE_BUYOUT_ORDER_TYPE" }),
          text: intl.$t({
            id: "CHANGE_ORDER_TYPE_CONFIRMATION",
          }),
          handleConfirm: () => {
            updateBuyoutOrderType(value);
          },
        });
      } else {
        updateBuyoutOrderType(value);
      }
    },
    [buyout?.releases, intl, openDialog, updateBuyoutOrderType],
  );

  return (
    <Container>
      <Select
        options={orderTypes || []}
        value={orderType}
        onChange={onOrderTypeChange}
        getLabel={(option) => option.name}
        getValue={(option) => option.id}
        testId="buyout-form-order-type-selector"
        loading={loadingTypes || (saving && updating)}
        required
        inputProps={{
          style: { marginTop: 0 },
          className: `text-sm py-0 px-0.5`,
        }}
      />
    </Container>
  );
};
