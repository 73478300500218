import Logo from "@/assets/images/Logo.svg?react";
import { LanguageSelector } from "@/common/components/language-selector/LanguageSelector";
import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import { TextNotificationsButton } from "@/distributor/common/notifications/TextNotificationsButton";
import { AppBar, Toolbar } from "@mui/material";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { EnterpriseOrgSwitch } from "../enterprise-org-switch/EnterpriseOrgSwitch";
import { NotificationsButton } from "../notifications/components/Notifications";
import { ContractorUserMenu } from "./ContractorUserMenu";
import { DistributorUserMenu } from "./DistributorUserMenu";
import { ImpersonateBanner } from "./ImpersonateBanner";
import { MainMenu } from "./MainMenu";

const AppBarStyled = tw(AppBar)`
  bg-blue-800 print:hidden
`;

const ToolbarStyled = tw(Toolbar)`
  pl-2.5 pr-0 min-h-14 h-14
`;

const StyledLogo = tw(Logo)`
  h-6 w-10 text-white mr-1.5
`;

const LogoText = tw.span`
  font-light text-base uppercase leading-4 tracking-widest pt-0.5 mr-5 print:hidden hidden lg:inline-block
`;

const StyledLink = tw(Link)`
  grid grid-flow-col items-center text-white h-full 
`;

export const Header = () => {
  const { isContractor } = useUser();
  const navigate = useNavigate();

  const navigateToHome = useCallback(
    (ev: React.MouseEvent<HTMLAnchorElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      if (isContractor) {
        navigate(routes.home);
      }
      return false;
    },
    [isContractor, navigate],
  );

  return (
    <AppBarStyled position="fixed">
      <ToolbarStyled>
        <StyledLink to="" onClick={navigateToHome}>
          <StyledLogo />
          <LogoText>
            <FormattedMessage
              id="FIELD_MATERIALS_HEADER"
              values={{ br: <br /> }}
            />
          </LogoText>
        </StyledLink>

        {isContractor ? (
          <>
            <MainMenu />
            <ImpersonateBanner />
            <LanguageSelector />
            <EnterpriseOrgSwitch />
            <NotificationsButton />
            <ContractorUserMenu />
          </>
        ) : (
          <>
            <LanguageSelector />
            <TextNotificationsButton />
            <DistributorUserMenu />
          </>
        )}
      </ToolbarStyled>
    </AppBarStyled>
  );
};
