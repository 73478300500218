import { addressToString } from "@/common/components/adress-location/AddressLocation";
import { If } from "@/common/components/if/If";
import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { InvoiceIssues } from "@/common/components/invoices/invoice-issue/InvoiceIssues";
import { useDistributorInvoice } from "@/distributor/pages/invoices/providers/DistributorInvoiceProvider";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Header = tw.div`mb-4`;
const DetailsView = tw.div`grid grid-cols-2 px-5 mb-5 font-medium text-md`;
const Details = tw.div`text-sm font-normal`;
const ListContent = tw.div`grid grid-flow-row justify-start items-start`;
const View = tw.div``;

export const InvoiceIssueHeader = () => {
  const { invoice } = useDistributorInvoice();
  return (
    <Header>
      <DetailsView>
        <View>
          <If isTrue={invoice?.release?.project?.address}>
            <InfoHeaderItem title={""}>
              <ListContent>
                <Details>
                  {invoice?.release?.project?.location.name}
                  <br />
                  {addressToString(invoice?.release?.project?.location.address)}
                </Details>
              </ListContent>
            </InfoHeaderItem>
          </If>
        </View>
        <If isTrue={invoice?.release?.project?.name}>
          <InfoHeaderItem title={<FormattedMessage id="PROJECT" />}>
            <ListContent>
              <Details>{invoice?.release?.project?.name}</Details>
              <Details>
                {addressToString(invoice?.release?.project?.address)}
              </Details>
            </ListContent>
          </InfoHeaderItem>
        </If>
      </DetailsView>
      <InvoiceIssues invoice={invoice} />
    </Header>
  );
};
