import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import {
  Base,
  ExpandedNotesContainer,
  MdContainer,
  NotesContainer,
  RfqItemContainer,
} from "@/common/layout/ResponsiveClasses";
import {
  COLUMN_TYPE,
  SpreadSheetConfig,
} from "@/common/providers/ColumnMapperProvider";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import { RfqItemFieldsFragment, Sku } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useProjectCostCodes } from "../../project/hooks/useProjectCostCodes";
import { useRfq } from "../hooks/useRfq";
import { useRfqGroupedItems } from "../hooks/useRfqGroupedItems";
import { RfqItemCheckbox } from "./bulk-actions/RfqItemCheckbox";
import { RfqItemGroupCheckbox } from "./bulk-actions/RfqItemGroupCheckbox";
import { RfqActions } from "./rfq-details/RfqActions";
import { RfqCostCodeManufacturerSelector } from "./rfq-details/RfqCostCodeManufacturerSelector";
import { RfqItemInstructionsDetails } from "./rfq-details/RfqItemInstructionsDetails";
import { RfqItemMaterialEditableView } from "./rfq-details/RfqItemMaterialEditableView";
import { RfqManufacturerSelector } from "./rfq-details/RfqManufacturerSelector";
import { RfqQuantityInput } from "./rfq-details/RfqQuantityInput";
import { RfqUomSelector } from "./rfq-details/RfqUomSelector";

export const useRfqItemsListConfiguration = (readonly: boolean) => {
  const intl = useIntl();
  const { newRfqItem } = useRfqGroupedItems();
  const { hasManufacturersSetting } = useManufacturersSettings();
  const { rfq } = useRfq();
  const { costCodes } = useProjectCostCodes(rfq?.project?.id);

  const configuration: Array<GridCol<RfqItemFieldsFragment>> = useMemo(
    () => [
      {
        wrapper: Base,
        position: "center",
        item: ({ item }) => <RfqItemCheckbox item={item} />,
        group: (category) => <RfqItemGroupCheckbox group={category} />,
        subgroup: (category) => <RfqItemGroupCheckbox group={category} />,
        hidden: readonly,
      },
      {
        wrapper: RfqItemContainer,
        item: ({ item, index, readonly, count, error }) => (
          <RfqItemMaterialEditableView
            item={item}
            index={index}
            readonly={readonly}
            count={count}
            error={error}
          />
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
            projectId={rfq?.project.id}
          />
        ),
        header: <FormattedMessage id="ITEM_NAME" />,
        includesCounter: true,
      },
      {
        wrapper: MdContainer,
        item: ({ item, readonly, index }) => (
          <RfqManufacturerSelector
            item={item}
            readonly={
              readonly ||
              !!(item.projectItem.material.material as Sku).manufacturer
            }
            index={index}
          />
        ),
        group: (category, readonly) => (
          <RfqCostCodeManufacturerSelector
            category={category}
            readonly={readonly}
          />
        ),
        position: "center",
        header: <FormattedMessage id="MANUFACTURER" />,
        hidden: !hasManufacturersSetting,
      },
      {
        wrapper: MdContainer,
        item: ({ item, readonly, error }) => {
          return (
            <RfqQuantityInput item={item} readonly={readonly} error={error} />
          );
        },
        group: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} $bold />
        ),
        position: "center",
        header: <FormattedMessage id="RFQ_QUANTITY_TO_ORDER" />,
      },
      {
        wrapper: MdContainer,
        item: ({ item, index, error, readonly }) => {
          return (
            <RfqUomSelector
              index={index}
              item={item}
              mnemonic={false}
              error={error}
              staticText={readonly}
            />
          );
        },
        position: "center",
        header: <FormattedMessage id="UOM" />,
      },
      {
        wrapper: newRfqItem?.isAddMode
          ? ExpandedNotesContainer
          : NotesContainer,
        item: ({ item }) => <RfqActions item={item} readonly={readonly} />,
        hidden: readonly,
        details: ({ item }) => {
          return <RfqItemInstructionsDetails item={item} readonly={readonly} />;
        },
      },
    ],
    [hasManufacturersSetting, newRfqItem?.isAddMode, readonly, rfq?.project.id],
  );

  const spreadsheetConfig = useMemo<SpreadSheetConfig[]>(
    () => [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: "projectItem.material",
        width: 200,
        columnType: COLUMN_TYPE.Material,
      },
      {
        header: intl.$t({ id: "MANUFACTURER" }),
        columnId: "manufacturer.name",
        width: 100,
        columnType: COLUMN_TYPE.Manufacturer,
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "projectItem.estimateUom.pluralDescription",
        width: 100,
        columnType: COLUMN_TYPE.UOM,
      },
      {
        header: intl.$t({ id: "QUANTITY" }),
        columnId: "quantityDecimal",
        width: 100,
        columnType: COLUMN_TYPE.PositiveQuantity,
      },
      {
        header: intl.$t({ id: "COST_CODE" }),
        columnId: "costCode.code",
        width: 100,
        columnType: COLUMN_TYPE.CostCode,
        options: costCodes.map((c) => c.formatted),
        metadata: {
          projectId: rfq?.project?.id,
        },
      },
      {
        header: intl.$t({ id: "NOTES" }),
        columnId: "instructions.text",
        width: 150,
        columnType: COLUMN_TYPE.Notes,
      },
    ],
    [costCodes, intl, rfq?.project?.id],
  );

  return { configuration, spreadsheetConfig };
};
