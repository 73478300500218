import { If } from "@/common/components/if/If";
import { LineItemNameNotes } from "@/common/components/line-item-name-notes/LineItemNameNotes";
import { useUser } from "@/common/providers/UserProvider";
import {
  AssetFieldsFragment,
  InstructionFieldsFragment,
  UomFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Detail = tw.div`grid grid-flow-col justify-start text-2xs ml-2 gap-1 items-start`;

type Props = {
  instructions: InstructionFieldsFragment | null | undefined;
  notes: string | null | undefined;
  assets: AssetFieldsFragment[];
  uom?: UomFieldsFragment;
};

export const BuyoutItemDetails: FC<Props> = ({
  instructions,
  notes,
  assets,
}) => {
  const { isContractor } = useUser();

  return (
    <>
      <If isTrue={instructions?.text || instructions?.assets?.length}>
        <Detail>
          <FormattedMessage
            id={isContractor ? "YOUR_NOTE_LABEL" : "CONTRACTOR_NOTE_LABEL"}
          />
          <LineItemNameNotes
            className="pl-0"
            notes={instructions?.text}
            assets={instructions?.assets}
          />
        </Detail>
      </If>
      <If isTrue={notes || assets.length}>
        <Detail>
          <FormattedMessage
            id={isContractor ? "VENDOR_NOTE_LABEL" : "YOUR_NOTE_LABEL"}
          />
          <LineItemNameNotes className="pl-0" notes={notes} assets={assets} />
        </Detail>
      </If>
    </>
  );
};
