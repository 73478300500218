import { MessageContextKind } from "@/generated/graphql";
import { ForumOutlined } from "@mui/icons-material";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { If } from "../../if/If";
import {
  MessageStatsProvider,
  useMessageStats,
} from "../providers/MessageStatsProvider";

const Container = tw.div`relative`;
const Count = tw.div`absolute top-3 left-2 bg-red-500 rounded-3xl text-2xs text-white min-w-4 py-1 px-1.5 h-4 font-medium ml-2`;
const CountText = tw.div`flex items-center justify-center h-full`;

type Props = {
  kind: MessageContextKind;
  id: string;
};

const MessageButtonWithProvider: FC = () => {
  const { messageStats } = useMessageStats();
  const totalUnread = useMemo(
    () =>
      (messageStats?.unreadPrivate || 0) + (messageStats?.unreadPublic || 0),
    [messageStats],
  );
  return (
    <Container>
      <ForumOutlined />
      <If isTrue={totalUnread > 0}>
        <Count>
          <CountText>
            {(messageStats?.unreadPrivate || 0) +
              (messageStats?.unreadPublic || 0)}
          </CountText>
        </Count>
      </If>
    </Container>
  );
};

export const MessageButton: FC<Props> = ({ kind, id }) => (
  <MessageStatsProvider kind={kind} id={id}>
    <MessageButtonWithProvider />
  </MessageStatsProvider>
);
