import { useIsTextOverflow } from "@/common/utils/useIsTextOverflow";
import { Popover as MuiPopover } from "@mui/material";
import { FC, PropsWithChildren, ReactNode, useRef, useState } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { useArrowPosition } from "./useArrowPosition";

type Props = PropsWithChildren<{
  id: string;
  element: ReactNode;
  $arrow?: boolean;
  color?: "primary" | "secondary" | "warning";
  classes?: { popover?: string; element?: string; arrow?: string };
  position?: "top" | "bottom" | "center";
  horizontal?: "left" | "center" | "right" | number;
  $ellipsis?: boolean;
  $fullWidth?: boolean;
  style?: React.CSSProperties;
}>;

type ChildrenWrapperProps = {
  color: "primary" | "secondary" | "warning";
};

type ElementWrapperProps = {
  $ellipsis: boolean;
  $fullWidth: boolean;
};
const ElementWrapper = tw.div<ElementWrapperProps>`cursor:pointer ${({
  $ellipsis,
  $fullWidth,
}: ElementWrapperProps) =>
  `${$ellipsis && "truncate"} ${$fullWidth && "w-full"}`}`;
const ChildrenWrapper = tw.div<ChildrenWrapperProps>`p-3 rounded-lg shadow-md 
${({ color }: ChildrenWrapperProps) =>
  color === "primary"
    ? "bg-blue-500 text-white"
    : color === "secondary"
      ? "bg-white text-black"
      : color === "warning"
        ? "bg-yellow-600 text-red-500"
        : ""}`;

export const Popover: FC<Props> = ({
  id,
  element,
  children,
  $arrow = false,
  color = "primary",
  position = "top",
  horizontal = "center",
  classes,
  $ellipsis = false,
  $fullWidth = false,
  style,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const { isTextOverflow } = useIsTextOverflow(ref);
  const arrow = useArrowPosition(color, position, classes?.arrow);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    if ($ellipsis && !isTextOverflow) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ElementWrapper
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        $ellipsis={$ellipsis}
        $fullWidth={$fullWidth}
        className={classes?.element}
        style={style}
        ref={ref}
      >
        {element}
      </ElementWrapper>
      <If isTrue={children}>
        <MuiPopover
          id={id}
          sx={{
            pointerEvents: "none",
            marginTop: 1,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: position,
            horizontal,
          }}
          transitionDuration={300}
          transformOrigin={{
            vertical:
              position === "top"
                ? "bottom"
                : position === "bottom"
                  ? "top"
                  : position,
            horizontal:
              horizontal === "center" || typeof horizontal === "number"
                ? horizontal
                : horizontal === "right"
                  ? "left"
                  : "right",
          }}
          onClose={handlePopoverClose}
          classes={{
            root: `${
              position === "top" ? "top-[-20px]" : "left-5"
            } ${classes?.popover}
            `,
            ...($arrow
              ? { paper: arrow }
              : { paper: "bg-transparent shadow-none p-2" }),
          }}
          disableRestoreFocus
          disableAutoFocus={true}
          disableEnforceFocus={true}
        >
          <ChildrenWrapper color={color}>{children}</ChildrenWrapper>
        </MuiPopover>
      </If>
    </>
  );
};
