import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { ExternalItemFieldsFragment, SourceSystem } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { ApolloError } from "@apollo/client";
import React, { FC, createContext, useContext, useState } from "react";
import { useExternalItemsWithPagination } from "../hooks/useExternalItemsWithPagination";

type ProviderContextType = {
  externalItems: ExternalItemFieldsFragment[];
  loading: boolean;
  totalCount: number;
  error: ApolloError | undefined;
  searchText: string;
  setSearchText: (text: string) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  externalItems: [],
  totalCount: 0,
  loading: false,
  error: undefined,
  searchText: "",
  setSearchText: NoFunction,
});

export const AgaveExternalItemsProvider: FC<{
  children: React.ReactNode;
  sourceSystem: SourceSystem | null;
}> = ({ children, sourceSystem }) => {
  const [searchText, setSearchText] = useState("");

  const { externalItems, error, loading, totalCount } =
    useExternalItemsWithPagination(
      sourceSystem,
      searchText
        ? {
            search: searchText,
          }
        : {},
    );
  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        externalItems: externalItems ?? [],
        totalCount,
        loading,
        error,
        searchText,
        setSearchText,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useAgaveExternalItemsProvider = () => useContext(ProviderContext);
