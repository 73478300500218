import { Dialog } from "@/common/components/dialog/Dialog";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { ExternalGeneralLedgerAccountsProvider } from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/steps/general-ledger-account/ExternalGeneralLedgerAccountsProvider";
import { Link } from "@mui/icons-material";
import { useCallback, useState } from "react";
import tw from "tailwind-styled-components";
import { CostCode } from "../providers/CostCodesListProvider";
import { LinkLedgerAccountWithProvider } from "./LinkLedgerAccountWithProvider";

const LedgerName = tw.span`ml-1 overflow-hidden text-ellipsis text-nowrap`;

interface CostCodeLinkLedgerAccountProps {
  costCode: CostCode;
}

export const CostCodeLinkLedgerAccount = ({
  costCode,
}: CostCodeLinkLedgerAccountProps) => {
  const [showLinkDialog, setShowLinkDialog] = useState(false);

  const handleCloseDialog = useCallback(() => setShowLinkDialog(false), []);

  const handleOpenDialog = useCallback(() => setShowLinkDialog(true), []);

  if (!costCode.mappings.length) {
    return null;
  }

  return (
    <>
      <LinkLike onClick={handleOpenDialog}>
        <Link />
      </LinkLike>
      {costCode?.ledgerAccount ? (
        <LedgerName title={costCode.ledgerAccount.name}>
          {costCode.ledgerAccount.name}
        </LedgerName>
      ) : null}
      <Dialog
        hideTitle
        show={showLinkDialog}
        classes={{
          paper: "max-w-[90vw] w-[90vw] rounded-3xl max-h-[90vh] h-[90vh]",
        }}
        content={
          <ExternalGeneralLedgerAccountsProvider>
            <LinkLedgerAccountWithProvider
              costCode={costCode}
              handleClose={handleCloseDialog}
            />
          </ExternalGeneralLedgerAccountsProvider>
        }
      />
    </>
  );
};
