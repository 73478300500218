import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import {
  ExternalPoTypeFieldsFragment,
  SourceSystem,
  useExternalPoTypesQuery,
} from "@/generated/graphql";
import React, { FC, createContext, useContext } from "react";

export type ExternalPoType = ExternalPoTypeFieldsFragment & {
  id: string;
  index: number;
};

type ProviderContextType = {
  externalPoTypes: ExternalPoType[];
  loadingExternalPoTypes: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  externalPoTypes: [],
  loadingExternalPoTypes: false,
});

export const ExternalPOTypesProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { connectedSourceSystem } = useOrgSettingsExtended();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const {
    data: externalPoTypesData,
    loading: loadingExternalPoTypes,
    error: externalPoTypesError,
  } = useExternalPoTypesQuery({
    variables: {
      sourceSystem: connectedSourceSystem?.system ?? SourceSystem.Foundation,
    },
    skip: !hasFeatureInConnectedSourceSystem(IntegrationFeature.PoType),
  });
  useErrorEffect(externalPoTypesError);

  return (
    <ProviderContext.Provider
      value={{
        externalPoTypes:
          externalPoTypesData?.externalPOTypes?.map((i, index) => ({
            ...i,
            id: i.externalId,
            index,
          })) ?? [],
        loadingExternalPoTypes,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useExternalPOTypes = () => useContext(ProviderContext);
