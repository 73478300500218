import { IntegrationType } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { IntegrationConfig } from "../types/IntegrationConfigType";
import { IntegrationFeature } from "../types/IntegrationFeature";

export const useIntegrationTypeConfig = (integrationType?: IntegrationType) => {
  const intl = useIntl();
  const getIntegrationConfig = useCallback(
    (
      integrationType: IntegrationType | null | undefined,
    ): IntegrationConfig => {
      const name = intl.$t({
        id: `INTEGRATION_${integrationType}`,
      });
      switch (integrationType) {
        case IntegrationType.Foundation:
          return {
            name,
            logo: {
              normal:
                "https://cdn.fieldmaterials.com/integrations/logos/Foundation.png",
              small:
                "https://cdn.fieldmaterials.com/integrations/logos/foundation-symbol.png",
            },
            features: [IntegrationFeature.InvoiceFileExports],
          };
        case IntegrationType.Sage300:
          return {
            name,
            logo: {
              normal:
                "https://cdn.fieldmaterials.com/integrations/logos/Sage300.png",
              small:
                "https://cdn.fieldmaterials.com/integrations/logos/Sage-symbol.png",
            },
            links: {
              accountSoftwareLink:
                "https://help-sage50.na.sage.com/en-us/2023/Content/Transactions/Accounts_Payable/Maintain_Vendors/Change_a_Vendor_ID.htm",
            },
            features: [],
          };

        default:
          throw new Error(`Integration ${integrationType} is not configured`);
      }
    },
    [intl],
  );

  const integrationConfig = useMemo(
    () => (integrationType ? getIntegrationConfig(integrationType) : null),
    [integrationType, getIntegrationConfig],
  );

  return { getIntegrationConfig, integrationConfig };
};
