import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import {
  CostCodeFieldsFragment,
  RfqItemFieldsFragment,
} from "@/generated/graphql";

export const getCostCodesByRfqItems = (
  items: RfqItemFieldsFragment[],
  isAddMode?: boolean,
) => {
  const costCodes: Omit<CostCodeFieldsFragment, "mappings">[] = [];
  items.forEach((item) => {
    if (
      item &&
      item.costCode &&
      !costCodes.map((c) => c.id).includes(item.costCode.id)
    ) {
      costCodes.push(item.costCode);
    }
  });
  costCodes.sort((a, b) => a.code.localeCompare(b.code));
  if (items.some((i) => i?.costCode === null) || isAddMode) {
    costCodes.push({
      id: UNSPECIFIED_COST_CODE_ID,
      code: "",
      description: "",
      inUse: true,
    });
  }
  return costCodes;
};
