import { FC } from "react";
import { Outlet } from "react-router-dom";
import tw from "tailwind-styled-components";
import { AdminOrgNavigation } from "./AdminOrgNavigation";

const Container = tw.div`mt-5 px-16 -mx-16 bg-gray-100 flex flex-col -mb-8 pb-8 min-h-[calc(100vh-210px)]`;

export const WithAdminOrgTabNavigation: FC = () => (
  <>
    <AdminOrgNavigation />
    <Container>
      <Outlet />
    </Container>
  </>
);
