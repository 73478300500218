import {
  LinkInvoiceInput,
  ReceiptDocument,
  ReceiptQuery,
  useLinkReceiptMutation,
} from "@/generated/graphql";
import { GraphQLError } from "graphql";

export const useLinkReceipt = () => {
  const [linkReceiptMutation] = useLinkReceiptMutation();

  const linkReceipt = async (linkReceiptInput: LinkInvoiceInput) => {
    try {
      const { data } = await linkReceiptMutation({
        variables: {
          input: linkReceiptInput,
        },
        update: (cache, { data }) => {
          const receipt = cache.readQuery<ReceiptQuery>({
            query: ReceiptDocument,
            variables: { id: linkReceiptInput.invoiceId },
          });
          if (receipt?.receipt && data?.linkReceipt) {
            cache.writeQuery({
              query: ReceiptDocument,
              variables: { id: linkReceiptInput.invoiceId },
              data: {
                receipt: {
                  ...receipt.receipt,
                  link: data.linkReceipt.link,
                },
              },
            });
          }
        },
      });
      return !!data?.linkReceipt;
    } catch (error) {
      if ((error as GraphQLError).message) {
        return (error as GraphQLError).message;
      }
      return false;
    }
  };

  return { linkReceipt };
};
