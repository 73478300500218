import {
  AdditionalChargesFieldsFragment,
  ReleaseFieldsFragment,
  UpdateChargeInput,
  UpdateContractorReleaseInput,
  UpdateVendorReleaseFieldsFragment,
  UpdateVendorReleaseInput,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { AdditionalCharges } from "../additional-charges/AdditionalCharges";

type Props = {
  release?: Pick<
    ReleaseFieldsFragment,
    "additionalCharges" | "id" | "version"
  > | null;
  additionalCharges?: AdditionalChargesFieldsFragment[];
  readonly: boolean;
  showError?: boolean;
  updateRelease?: (
    input: UpdateVendorReleaseInput | UpdateContractorReleaseInput,
  ) =>
    | Promise<UpdateVendorReleaseFieldsFragment | boolean | undefined | null>
    | (UpdateVendorReleaseFieldsFragment | boolean | undefined | null);
};

export const ReleaseAdditionalCharges: FC<Props> = ({
  release,
  additionalCharges,
  updateRelease,
  ...props
}) => {
  const onChange = useCallback(
    async (additionalCharges: UpdateChargeInput[]) => {
      await updateRelease?.({
        releaseId: release?.id || "",
        version: release?.version,
        additionalCharges: additionalCharges.filter(
          (charge) =>
            charge.description.length > 0 && Number(charge.amount) > 0,
        ),
      });
    },
    [updateRelease, release],
  );

  return (
    <AdditionalCharges
      additionalCharges={release?.additionalCharges ?? additionalCharges}
      updateAdditionalCharges={onChange}
      {...props}
    />
  );
};
