import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { vendorLabelFormatter } from "./VendorPickerCustomRender";

export const ALL_VENDORS_LIST_ID = "-1";

export const useVendorOptions = (includeAllOption: boolean = true) => {
  const { vendors } = useVendors();
  const intl = useIntl();
  return useMemo(() => {
    const opts = [
      ...(includeAllOption
        ? [
            {
              value: ALL_VENDORS_LIST_ID,
              name: intl.$t({ id: "ALL_VENDORS" }),
            },
          ]
        : []),
    ] as {
      value: string | undefined;
      name: string;
    }[];

    [...vendors]
      .sort((vendorA, vendorB) =>
        vendorA.sellerOrgLocation.name.localeCompare(
          vendorB.sellerOrgLocation.name,
        ),
      )
      .forEach((vendor) => {
        opts.push({
          ...vendor,
          value: vendor.sellerOrgLocation.id,
          name: vendorLabelFormatter(vendor.sellerOrgLocation),
        });
      });
    return opts;
  }, [includeAllOption, intl, vendors]);
};
