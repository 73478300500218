import { FC, useMemo } from "react";
import { formatPhone } from "./formatPhone";

type Props = {
  value: string;
};

export const PhoneView: FC<Props> = ({ value }) => {
  const formattedPhone = useMemo(() => formatPhone(value), [value]);
  return <>{formattedPhone}</>;
};
