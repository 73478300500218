import {
  RfqItemFieldsFragment,
  RfqQuotesQuoteFieldsFragment,
  RfqQuotesQuoteItemGroupFieldsFragment,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRfqQuotes } from "./RfqQuotesProvider";

export type QuoteOption = {
  id: string;
  show: boolean;
  orgName: string;
};

type ProviderContextType = {
  quoteOptions: QuoteOption[];
  setQuoteOptions: (quoteOptions: QuoteOption[]) => void;
  mismatchesOnly: boolean;
  setMismatchesOnly: (mismatchesOnly: boolean) => void;
  visibleQuotes: RfqQuotesQuoteFieldsFragment[];
  getQuoteGroups: (
    rfqItem: RfqItemFieldsFragment,
  ) => RfqQuotesQuoteItemGroupFieldsFragment[];
};

const ProviderContext = createContext<ProviderContextType>({
  quoteOptions: [],
  setQuoteOptions: NoFunction,
  mismatchesOnly: false,
  setMismatchesOnly: NoFunction,
  visibleQuotes: [],
  getQuoteGroups: () => [],
});

export const RfqQuoteOptionsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { rfq } = useRfqQuotes();
  const [quoteOptions, setQuoteOptions] = useState<QuoteOption[]>([]);
  const [mismatchesOnly, setMismatchesOnly] = useState<boolean>(false);

  useEffect(() => {
    if (rfq) {
      const quotes = rfq?.quotes.map((quote) => ({
        id: quote.id,
        orgName: quote.sellerOrgLocation.org.name,
        show: true,
      }));
      setQuoteOptions(quotes ?? []);
    }
  }, [rfq]);

  const visibleQuotes = useMemo(() => {
    return (
      rfq?.quotes?.filter((quote) => {
        const quoteOption = quoteOptions.find(
          (option) => option.id === quote.id,
        );
        return quoteOption?.show ?? true;
      }) ?? []
    );
  }, [rfq, quoteOptions]);

  const getQuoteGroups = (rfqItem: RfqItemFieldsFragment) => {
    return (
      rfq?.quotes.reduce((acc, quote) => {
        const quoteOption = quoteOptions.find(
          (option) => option.id === quote.id,
        );
        if (quoteOption?.show ?? true) {
          const quoteItemGroup = quote.itemGroups.find(
            (group) => group.rfqItem.id === rfqItem.id,
          );
          if (quoteItemGroup) {
            acc.push(quoteItemGroup);
          }
        }
        return acc;
      }, [] as RfqQuotesQuoteItemGroupFieldsFragment[]) ?? []
    );
  };

  return (
    <ProviderContext.Provider
      value={{
        quoteOptions,
        setQuoteOptions,
        mismatchesOnly,
        setMismatchesOnly,
        visibleQuotes,
        getQuoteGroups,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useRfqQuoteOptions = (): ProviderContextType =>
  useContext(ProviderContext);
