import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import {
  ExternalLedgerAccountFieldsFragment,
  useExternalLedgerAccountsLazyQuery,
} from "@/generated/graphql";
import React, { FC, createContext, useContext, useEffect } from "react";
import { useAgaveHostedWizard } from "../../AgaveHostedWizardProvider";

export type ExternalLedgerAccountType = ExternalLedgerAccountFieldsFragment & {
  id: string;
  index: number;
};

type ProviderContextType = {
  externalLedgerAccounts: ExternalLedgerAccountType[];
  loadingExternalLedgerAccounts: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  externalLedgerAccounts: [],
  loadingExternalLedgerAccounts: false,
});

export const ExternalGeneralLedgerAccountsProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { sourceSystem } = useAgaveHostedWizard();
  const { connectedSourceSystem } = useOrgSettingsExtended();
  const { hasFeatureInConnectedSourceSystem, hasFeature } =
    useIntegrationFeatureRequirement();

  const [
    loadExternalLedgerAccount,
    {
      data: externalLedgerAccountsData,
      loading: loadingExternalLedgerAccounts,
      called,
      error: externalLedgerAccountsError,
    },
  ] = useExternalLedgerAccountsLazyQuery();

  useErrorEffect(externalLedgerAccountsError);

  useEffect(() => {
    const system = sourceSystem ?? connectedSourceSystem?.system;
    const hasFeatureConnected =
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.GeneralLedgerAccount,
      ) ||
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.ApLedgerAccountStep,
      ) ||
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.PostInventoryReceipts,
      );
    let hasSourceSystemConfiguration = true;
    if (sourceSystem) {
      hasSourceSystemConfiguration =
        hasFeature({
          feature: IntegrationFeature.GeneralLedgerAccount,
          sourceSystem,
        }) ||
        hasFeature({
          feature: IntegrationFeature.ApLedgerAccountStep,
          sourceSystem,
        });
    }

    if (system && hasFeatureConnected && hasSourceSystemConfiguration) {
      loadExternalLedgerAccount({
        variables: {
          sourceSystem: system,
        },
      });
    }
  }, [
    sourceSystem,
    connectedSourceSystem?.system,
    hasFeature,
    loadExternalLedgerAccount,
    hasFeatureInConnectedSourceSystem,
  ]);

  return (
    <ProviderContext.Provider
      value={{
        externalLedgerAccounts:
          externalLedgerAccountsData?.externalLedgerAccounts.map(
            (i, index) => ({
              ...i,
              id: i.externalId,
              index,
            }),
          ) ?? [],
        loadingExternalLedgerAccounts: loadingExternalLedgerAccounts || !called,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useExternalGeneralLedgerAccounts = () =>
  useContext(ProviderContext);
