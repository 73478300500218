import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useViewerOrgNotificationsQuery } from "@/generated/graphql";

export const useViewerOrgNotifications = () => {
  const { data, error, loading } = useViewerOrgNotificationsQuery();

  useErrorEffect(error);

  return {
    viewerOrgNotifications: data?.viewer?.org.settings?.notifications,
    orgId: data?.viewer?.org.id,
    loading,
  };
};
