import { PrintOutlined } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { Tooltip } from "../tooltip/Tooltip";

const ButtonText = tw.div`flex gap-1 items-center text-blue-500 hover:text-blue-300`;
const PrintOutlinedStyled = tw(PrintOutlined)`text-2xl`;

export const PrintButton = () => (
  <ButtonText>
    <Tooltip id="print-button" element={<PrintOutlinedStyled />}>
      <FormattedMessage id="PRINT" />
    </Tooltip>
  </ButtonText>
);
