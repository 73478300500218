/* eslint-disable @cspell/spellchecker */
import { ALLOWED_CONTINENTS } from "../const";

/* eslint-disable max-lines */
export const countries = [
  {
    name: "Afghanistan",
    abbreviation: "AF",
    continent: "AS",
  },
  {
    name: "Åland Islands",
    abbreviation: "AX",
    continent: "EU",
  },
  {
    name: "Albania",
    abbreviation: "AL",
    continent: "EU",
  },
  {
    name: "Algeria",
    abbreviation: "DZ",
    continent: "AF",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
    continent: "OC",
  },
  {
    name: "Andorra",
    abbreviation: "AD",
    continent: "EU",
  },
  {
    name: "Angola",
    abbreviation: "AO",
    continent: "AF",
  },
  {
    name: "Anguilla",
    abbreviation: "AI",
    continent: "NA",
  },
  {
    name: "Antarctica",
    abbreviation: "AQ",
    continent: "Antarctica",
  },
  {
    name: "Antigua and Barbuda",
    abbreviation: "AG",
    continent: "NA",
  },
  {
    name: "Argentina",
    abbreviation: "AR",
    continent: "SA",
  },
  {
    name: "Armenia",
    abbreviation: "AM",
    continent: "AS",
  },
  {
    name: "Aruba",
    abbreviation: "AW",
    continent: "NA",
  },
  {
    name: "Australia",
    abbreviation: "AU",
    continent: "OC",
  },
  {
    name: "Austria",
    abbreviation: "AT",
    continent: "EU",
  },
  {
    name: "Azerbaijan",
    abbreviation: "AZ",
    continent: "AS",
  },
  {
    name: "Bahamas",
    abbreviation: "BS",
    continent: "NA",
  },
  {
    name: "Bahrain",
    abbreviation: "BH",
    continent: "AS",
  },
  {
    name: "Bangladesh",
    abbreviation: "BD",
    continent: "AS",
  },
  {
    name: "Barbados",
    abbreviation: "BB",
    continent: "NA",
  },
  {
    name: "Belarus",
    abbreviation: "BY",
    continent: "EU",
  },
  {
    name: "Belgium",
    abbreviation: "BE",
    continent: "EU",
  },
  {
    name: "Belize",
    abbreviation: "BZ",
    continent: "NA",
  },
  {
    name: "Benin",
    abbreviation: "BJ",
    continent: "AF",
  },
  {
    name: "Bermuda",
    abbreviation: "BM",
    continent: "NA",
  },
  {
    name: "Bhutan",
    abbreviation: "BT",
    continent: "AS",
  },
  {
    name: "Bolivia",
    abbreviation: "BO",
    continent: "SA",
  },
  {
    name: "Bosnia and Herzegovina",
    abbreviation: "BA",
    continent: "EU",
  },
  {
    name: "Botswana",
    abbreviation: "BW",
    continent: "AF",
  },
  {
    name: "Bouvet Island",
    abbreviation: "BV",
    continent: "Antarctica",
  },
  {
    name: "Brazil",
    abbreviation: "BR",
    continent: "SA",
  },
  {
    name: "British Indian Ocean Territory",
    abbreviation: "IO",
    continent: "AS",
  },
  {
    name: "Brunei Darussalam",
    abbreviation: "BN",
    continent: "AS",
  },
  {
    name: "Bulgaria",
    abbreviation: "BG",
    continent: "EU",
  },
  {
    name: "Burkina Faso",
    abbreviation: "BF",
    continent: "AF",
  },
  {
    name: "Burundi",
    abbreviation: "BI",
    continent: "AF",
  },
  {
    name: "Cambodia",
    abbreviation: "KH",
    continent: "AS",
  },
  {
    name: "Cameroon",
    abbreviation: "CM",
    continent: "AF",
  },
  {
    name: "Canada",
    abbreviation: "CA",
    continent: "NA",
  },
  {
    name: "Cape Verde",
    abbreviation: "CV",
    continent: "AF",
  },
  {
    name: "Cayman Islands",
    abbreviation: "KY",
    continent: "NA",
  },
  {
    name: "Central African Republic",
    abbreviation: "CF",
    continent: "AF",
  },
  {
    name: "Chad",
    abbreviation: "TD",
    continent: "AF",
  },
  {
    name: "Chile",
    abbreviation: "CL",
    continent: "SA",
  },
  {
    name: "China",
    abbreviation: "CN",
    continent: "AS",
  },
  {
    name: "Christmas Island",
    abbreviation: "CX",
    continent: "AS",
  },
  {
    name: "Cocos (Keeling) Islands",
    abbreviation: "CC",
    continent: "AS",
  },
  {
    name: "Colombia",
    abbreviation: "CO",
    continent: "SA",
  },
  {
    name: "Comoros",
    abbreviation: "KM",
    continent: "AF",
  },
  {
    name: "Congo",
    abbreviation: "CG",
    continent: "AF",
  },
  {
    name: "Congo, The Democratic Republic of the",
    abbreviation: "CD",
    continent: "AF",
  },
  {
    name: "Cook Islands",
    abbreviation: "CK",
    continent: "OC",
  },
  {
    name: "Costa Rica",
    abbreviation: "CR",
    continent: "NA",
  },
  {
    name: "Cote D'Ivoire",
    abbreviation: "CI",
    continent: "AF",
  },
  {
    name: "Croatia",
    abbreviation: "HR",
    continent: "EU",
  },
  {
    name: "Cuba",
    abbreviation: "CU",
    continent: "NA",
  },
  {
    name: "Cyprus",
    abbreviation: "CY",
    continent: "EU",
  },
  {
    name: "Czech Republic",
    abbreviation: "CZ",
    continent: "EU",
  },
  {
    name: "Denmark",
    abbreviation: "DK",
    continent: "EU",
  },
  {
    name: "Djibouti",
    abbreviation: "DJ",
    continent: "AF",
  },
  {
    name: "Dominica",
    abbreviation: "DM",
    continent: "NA",
  },
  {
    name: "Dominican Republic",
    abbreviation: "DO",
    continent: "NA",
  },
  {
    name: "Ecuador",
    abbreviation: "EC",
    continent: "SA",
  },
  {
    name: "Egypt",
    abbreviation: "EG",
    continent: "AF",
  },
  {
    name: "El Salvador",
    abbreviation: "SV",
    continent: "NA",
  },
  {
    name: "Equatorial Guinea",
    abbreviation: "GQ",
    continent: "AF",
  },
  {
    name: "Eritrea",
    abbreviation: "ER",
    continent: "AF",
  },
  {
    name: "Estonia",
    abbreviation: "EE",
    continent: "EU",
  },
  {
    name: "Ethiopia",
    abbreviation: "ET",
    continent: "AF",
  },
  {
    name: "Falkland Islands (Malvinas)",
    abbreviation: "FK",
    continent: "SA",
  },
  {
    name: "Faroe Islands",
    abbreviation: "FO",
    continent: "EU",
  },
  {
    name: "Fiji",
    abbreviation: "FJ",
    continent: "OC",
  },
  {
    name: "Finland",
    abbreviation: "FI",
    continent: "EU",
  },
  {
    name: "France",
    abbreviation: "FR",
    continent: "EU",
  },
  {
    name: "French Guiana",
    abbreviation: "GF",
    continent: "SA",
  },
  {
    name: "French Polynesia",
    abbreviation: "PF",
    continent: "OC",
  },
  {
    name: "French Southern Territories",
    abbreviation: "TF",
    continent: "Antarctica",
  },
  {
    name: "Gabon",
    abbreviation: "GA",
    continent: "AF",
  },
  {
    name: "Gambia",
    abbreviation: "GM",
    continent: "AF",
  },
  {
    name: "Georgia",
    abbreviation: "GE",
    continent: "AS",
  },
  {
    name: "Germany",
    abbreviation: "DE",
    continent: "EU",
  },
  {
    name: "Ghana",
    abbreviation: "GH",
    continent: "AF",
  },
  {
    name: "Gibraltar",
    abbreviation: "GI",
    continent: "EU",
  },
  {
    name: "Greece",
    abbreviation: "GR",
    continent: "EU",
  },
  {
    name: "Greenland",
    abbreviation: "GL",
    continent: "NA",
  },
  {
    name: "Grenada",
    abbreviation: "GD",
    continent: "NA",
  },
  {
    name: "Guadeloupe",
    abbreviation: "GP",
    continent: "NA",
  },
  {
    name: "Guam",
    abbreviation: "GU",
    continent: "OC",
  },
  {
    name: "Guatemala",
    abbreviation: "GT",
    continent: "NA",
  },
  {
    name: "Guernsey",
    abbreviation: "GG",
    continent: "EU",
  },
  {
    name: "Guinea",
    abbreviation: "GN",
    continent: "AF",
  },
  {
    name: "Guinea-Bissau",
    abbreviation: "GW",
    continent: "AF",
  },
  {
    name: "Guyana",
    abbreviation: "GY",
    continent: "SA",
  },
  {
    name: "Haiti",
    abbreviation: "HT",
    continent: "NA",
  },
  {
    name: "Heard Island and Mcdonald Islands",
    abbreviation: "HM",
    continent: "Antarctica",
  },
  {
    name: "Holy See (Vatican City State)",
    abbreviation: "VA",
    continent: "EU",
  },
  {
    name: "Honduras",
    abbreviation: "HN",
    continent: "NA",
  },
  {
    name: "Hong Kong",
    abbreviation: "HK",
    continent: "AS",
  },
  {
    name: "Hungary",
    abbreviation: "HU",
    continent: "EU",
  },
  {
    name: "Iceland",
    abbreviation: "IS",
    continent: "EU",
  },
  {
    name: "India",
    abbreviation: "IN",
    continent: "AS",
  },
  {
    name: "Indonesia",
    abbreviation: "ID",
    continent: "AS",
  },
  {
    name: "Iran, Islamic Republic Of",
    abbreviation: "IR",
    continent: "AS",
  },
  {
    name: "Iraq",
    abbreviation: "IQ",
    continent: "AS",
  },
  {
    name: "Ireland",
    abbreviation: "IE",
    continent: "EU",
  },
  {
    name: "Isle of Man",
    abbreviation: "IM",
    continent: "EU",
  },
  {
    name: "Italy",
    abbreviation: "IT",
    continent: "EU",
  },
  {
    name: "Jamaica",
    abbreviation: "JM",
    continent: "NA",
  },
  {
    name: "Japan",
    abbreviation: "JP",
    continent: "AS",
  },
  {
    name: "Jersey",
    abbreviation: "JE",
    continent: "EU",
  },
  {
    name: "Jordan",
    abbreviation: "JO",
    continent: "AS",
  },
  {
    name: "Kazakhstan",
    abbreviation: "KZ",
    continent: "AS",
  },
  {
    name: "Kenya",
    abbreviation: "KE",
    continent: "AF",
  },
  {
    name: "Kiribati",
    abbreviation: "KI",
    continent: "OC",
  },
  {
    name: 'Korea, Democratic People"S Republic of',
    abbreviation: "KP",
    continent: "AS",
  },
  {
    name: "Korea, Republic of",
    abbreviation: "KR",
    continent: "AS",
  },
  {
    name: "Kuwait",
    abbreviation: "KW",
    continent: "AS",
  },
  {
    name: "Kyrgyzstan",
    abbreviation: "KG",
    continent: "AS",
  },
  {
    name: 'Lao People"S Democratic Republic',
    abbreviation: "LA",
    continent: "AS",
  },
  {
    name: "Latvia",
    abbreviation: "LV",
    continent: "EU",
  },
  {
    name: "Lebanon",
    abbreviation: "LB",
    continent: "AS",
  },
  {
    name: "Lesotho",
    abbreviation: "LS",
    continent: "AF",
  },
  {
    name: "Liberia",
    abbreviation: "LR",
    continent: "AF",
  },
  {
    name: "Libyan Arab Jamahiriya",
    abbreviation: "LY",
    continent: "AF",
  },
  {
    name: "Liechtenstein",
    abbreviation: "LI",
    continent: "EU",
  },
  {
    name: "Lithuania",
    abbreviation: "LT",
    continent: "EU",
  },
  {
    name: "Luxembourg",
    abbreviation: "LU",
    continent: "EU",
  },
  {
    name: "Macao",
    abbreviation: "MO",
    continent: "AS",
  },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    abbreviation: "MK",
    continent: "EU",
  },
  {
    name: "Madagascar",
    abbreviation: "MG",
    continent: "AF",
  },
  {
    name: "Malawi",
    abbreviation: "MW",
    continent: "AF",
  },
  {
    name: "Malaysia",
    abbreviation: "MY",
    continent: "AS",
  },
  {
    name: "Maldives",
    abbreviation: "MV",
    continent: "AS",
  },
  {
    name: "Mali",
    abbreviation: "ML",
    continent: "AF",
  },
  {
    name: "Malta",
    abbreviation: "MT",
    continent: "EU",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
    continent: "OC",
  },
  {
    name: "Martinique",
    abbreviation: "MQ",
    continent: "NA",
  },
  {
    name: "Mauritania",
    abbreviation: "MR",
    continent: "AF",
  },
  {
    name: "Mauritius",
    abbreviation: "MU",
    continent: "AF",
  },
  {
    name: "Mayotte",
    abbreviation: "YT",
    continent: "AF",
  },
  {
    name: "Mexico",
    abbreviation: "MX",
    continent: "NA",
  },
  {
    name: "Micronesia, Federated States of",
    abbreviation: "FM",
    continent: "OC",
  },
  {
    name: "Moldova, Republic of",
    abbreviation: "MD",
    continent: "EU",
  },
  {
    name: "Monaco",
    abbreviation: "MC",
    continent: "EU",
  },
  {
    name: "Mongolia",
    abbreviation: "MN",
    continent: "AS",
  },
  {
    name: "Montserrat",
    abbreviation: "MS",
    continent: "NA",
  },
  {
    name: "Morocco",
    abbreviation: "MA",
    continent: "AF",
  },
  {
    name: "Mozambique",
    abbreviation: "MZ",
    continent: "AF",
  },
  {
    name: "Myanmar",
    abbreviation: "MM",
    continent: "AS",
  },
  {
    name: "Namibia",
    abbreviation: "NA",
    continent: "AF",
  },
  {
    name: "Nauru",
    abbreviation: "NR",
    continent: "OC",
  },
  {
    name: "Nepal",
    abbreviation: "NP",
    continent: "AS",
  },
  {
    name: "Netherlands",
    abbreviation: "NL",
    continent: "EU",
  },
  {
    name: "Netherlands Antilles",
    abbreviation: "AN",
    continent: "NA",
  },
  {
    name: "New Caledonia",
    abbreviation: "NC",
    continent: "OC",
  },
  {
    name: "New Zealand",
    abbreviation: "NZ",
    continent: "OC",
  },
  {
    name: "Nicaragua",
    abbreviation: "NI",
    continent: "NA",
  },
  {
    name: "Niger",
    abbreviation: "NE",
    continent: "AF",
  },
  {
    name: "Nigeria",
    abbreviation: "NG",
    continent: "AF",
  },
  {
    name: "Niue",
    abbreviation: "NU",
    continent: "OC",
  },
  {
    name: "Norfolk Island",
    abbreviation: "NF",
    continent: "OC",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
    continent: "OC",
  },
  {
    name: "Norway",
    abbreviation: "NO",
    continent: "EU",
  },
  {
    name: "Oman",
    abbreviation: "OM",
    continent: "AS",
  },
  {
    name: "Pakistan",
    abbreviation: "PK",
    continent: "AS",
  },
  {
    name: "Palau",
    abbreviation: "PW",
    continent: "OC",
  },
  {
    name: "Palestinian Territory, Occupied",
    abbreviation: "PS",
    continent: "AS",
  },
  {
    name: "Panama",
    abbreviation: "PA",
    continent: "NA",
  },
  {
    name: "Papua New Guinea",
    abbreviation: "PG",
    continent: "OC",
  },
  {
    name: "Paraguay",
    abbreviation: "PY",
    continent: "SA",
  },
  {
    name: "Peru",
    abbreviation: "PE",
    continent: "SA",
  },
  {
    name: "Philippines",
    abbreviation: "PH",
    continent: "AS",
  },
  {
    name: "Pitcairn",
    abbreviation: "PN",
    continent: "OC",
  },
  {
    name: "Poland",
    abbreviation: "PL",
    continent: "EU",
  },
  {
    name: "Portugal",
    abbreviation: "PT",
    continent: "EU",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
    continent: "NA",
  },
  {
    name: "Qatar",
    abbreviation: "QA",
    continent: "AS",
  },
  {
    name: "Reunion",
    abbreviation: "RE",
    continent: "AF",
  },
  {
    name: "Romania",
    abbreviation: "RO",
    continent: "EU",
  },
  {
    name: "Russian Federation",
    abbreviation: "RU",
    continent: "EU",
  },
  {
    name: "RWANDA",
    abbreviation: "RW",
    continent: "AF",
  },
  {
    name: "Saint Helena",
    abbreviation: "SH",
    continent: "AF",
  },
  {
    name: "Saint Kitts and Nevis",
    abbreviation: "KN",
    continent: "NA",
  },
  {
    name: "Saint Lucia",
    abbreviation: "LC",
    continent: "NA",
  },
  {
    name: "Saint Pierre and Miquelon",
    abbreviation: "PM",
    continent: "NA",
  },
  {
    name: "Saint Vincent and the Grenadines",
    abbreviation: "VC",
    continent: "NA",
  },
  {
    name: "Samoa",
    abbreviation: "WS",
    continent: "OC",
  },
  {
    name: "San Marino",
    abbreviation: "SM",
    continent: "EU",
  },
  {
    name: "Sao Tome and Principe",
    abbreviation: "ST",
    continent: "AF",
  },
  {
    name: "Saudi Arabia",
    abbreviation: "SA",
    continent: "AS",
  },
  {
    name: "Senegal",
    abbreviation: "SN",
    continent: "AF",
  },
  {
    name: "Serbia and Montenegro",
    abbreviation: "CS",
    continent: "EU",
  },
  {
    name: "Seychelles",
    abbreviation: "SC",
    continent: "AF",
  },
  {
    name: "Sierra Leone",
    abbreviation: "SL",
    continent: "AF",
  },
  {
    name: "Singapore",
    abbreviation: "SG",
    continent: "AS",
  },
  {
    name: "Slovakia",
    abbreviation: "SK",
    continent: "EU",
  },
  {
    name: "Slovenia",
    abbreviation: "SI",
    continent: "EU",
  },
  {
    name: "Solomon Islands",
    abbreviation: "SB",
    continent: "OC",
  },
  {
    name: "Somalia",
    abbreviation: "SO",
    continent: "AF",
  },
  {
    name: "South Africa",
    abbreviation: "ZA",
    continent: "AF",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    abbreviation: "GS",
    continent: "Antarctica",
  },
  {
    name: "Spain",
    abbreviation: "ES",
    continent: "EU",
  },
  {
    name: "Sri Lanka",
    abbreviation: "LK",
    continent: "AS",
  },
  {
    name: "Sudan",
    abbreviation: "SD",
    continent: "AF",
  },
  {
    name: "Suriname",
    abbreviation: "SR",
    continent: "SA",
  },
  {
    name: "Svalbard and Jan Mayen",
    abbreviation: "SJ",
    continent: "EU",
  },
  {
    name: "Swaziland",
    abbreviation: "SZ",
    continent: "AF",
  },
  {
    name: "Sweden",
    abbreviation: "SE",
    continent: "EU",
  },
  {
    name: "Switzerland",
    abbreviation: "CH",
    continent: "EU",
  },
  {
    name: "Syrian Arab Republic",
    abbreviation: "SY",
    continent: "AS",
  },
  {
    name: "Taiwan, Province of China",
    abbreviation: "TW",
    continent: "AS",
  },
  {
    name: "Tajikistan",
    abbreviation: "TJ",
    continent: "AS",
  },
  {
    name: "Tanzania, United Republic of",
    abbreviation: "TZ",
    continent: "AF",
  },
  {
    name: "Thailand",
    abbreviation: "TH",
    continent: "AS",
  },
  {
    name: "Timor-Leste",
    abbreviation: "TL",
    continent: "AS",
  },
  {
    name: "Togo",
    abbreviation: "TG",
    continent: "AF",
  },
  {
    name: "Tokelau",
    abbreviation: "TK",
    continent: "OC",
  },
  {
    name: "Tonga",
    abbreviation: "TO",
    continent: "OC",
  },
  {
    name: "Trinidad and Tobago",
    abbreviation: "TT",
    continent: "NA",
  },
  {
    name: "Tunisia",
    abbreviation: "TN",
    continent: "AF",
  },
  {
    name: "Turkey",
    abbreviation: "TR",
    continent: "AS",
  },
  {
    name: "Turkmenistan",
    abbreviation: "TM",
    continent: "AS",
  },
  {
    name: "Turks and Caicos Islands",
    abbreviation: "TC",
    continent: "NA",
  },
  {
    name: "Tuvalu",
    abbreviation: "TV",
    continent: "OC",
  },
  {
    name: "Uganda",
    abbreviation: "UG",
    continent: "AF",
  },
  {
    name: "Ukraine",
    abbreviation: "UA",
    continent: "EU",
  },
  {
    name: "United Arab Emirates",
    abbreviation: "AE",
    continent: "AS",
  },
  {
    name: "United Kingdom",
    abbreviation: "GB",
    continent: "EU",
  },
  {
    name: "United States",
    abbreviation: "US",
    continent: "NA",
  },
  {
    name: "United States Minor Outlying Islands",
    abbreviation: "UM",
    continent: "OC",
  },
  {
    name: "Uruguay",
    abbreviation: "UY",
    continent: "SA",
  },
  {
    name: "Uzbekistan",
    abbreviation: "UZ",
    continent: "AS",
  },
  {
    name: "Vanuatu",
    abbreviation: "VU",
    continent: "OC",
  },
  {
    name: "Venezuela",
    abbreviation: "VE",
    continent: "SA",
  },
  {
    name: "Viet Nam",
    abbreviation: "VN",
    continent: "AS",
  },
  {
    name: "Virgin Islands, British",
    abbreviation: "VG",
    continent: "NA",
  },
  {
    name: "Virgin Islands, U.S.",
    abbreviation: "VI",
    continent: "NA",
  },
  {
    name: "Wallis and Futuna",
    abbreviation: "WF",
    continent: "OC",
  },
  {
    name: "Western Sahara",
    abbreviation: "EH",
    continent: "AF",
  },
  {
    name: "Yemen",
    abbreviation: "YE",
    continent: "AS",
  },
  {
    name: "Zambia",
    abbreviation: "ZM",
    continent: "AF",
  },
  {
    name: "Zimbabwe",
    abbreviation: "ZW",
    continent: "AF",
  },
].filter((country) => ALLOWED_CONTINENTS.includes(country.continent));
