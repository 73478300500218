import { gql } from "@apollo/client";
import { AGGREGATED_COST_CODE_FIELDS } from "./aggregatedCostCodeFragment";
import { ZONE_EXTENDED_FIELDS } from "./zoneExtendedFragment";

export const AGGREGATED_ZONE_FIELDS = gql`
  ${AGGREGATED_COST_CODE_FIELDS}
  ${ZONE_EXTENDED_FIELDS}
  fragment AggregatedZoneFields on AggregatedZone {
    zone {
      ...ZoneExtendedFields
    }
    costCodes {
      ...AggregatedCostCodeFields
    }
  }
`;
