import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  UpdateOrgSettingsInput,
  useUpdateOrgNotificationSettingsMutation,
  ViewerOrgNotificationsDocument,
  ViewerOrgNotificationsQuery,
} from "@/generated/graphql";

export const useUpdateOrgNotificationSettings = () => {
  const { setError } = useGlobalError();

  const [updateOrgNotificationSettingsMutation, { loading: updating }] =
    useUpdateOrgNotificationSettingsMutation();
  const updateOrgNotifications = async (input: UpdateOrgSettingsInput) => {
    try {
      const { errors } = await updateOrgNotificationSettingsMutation({
        variables: { input },
        update: (cache, { data: newData }) => {
          const data = cache.readQuery<ViewerOrgNotificationsQuery>({
            query: ViewerOrgNotificationsDocument,
          });
          if (data) {
            cache.writeQuery({
              query: ViewerOrgNotificationsDocument,
              data: {
                viewer: {
                  ...data?.viewer,
                  org: {
                    ...data?.viewer?.org,
                    settings: newData?.updateOrgSettings,
                  },
                },
              },
            });
          }
        },
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return {
    updateOrgNotifications,
    updating,
  };
};
