import { NumericalInputControlled } from "@/common/components/numerical-input/NumericalInputControlled";
import { ControlledProps } from "@/common/components/textfield-controlled/TextFieldControlled";
import { TextFieldProps } from "@/common/components/textfield/TextField";
import { useUser } from "@/common/providers/UserProvider";
import { FC } from "react";
import { NumberFormatValues } from "react-number-format";
import tw from "tailwind-styled-components";

type Props = TextFieldProps & ControlledProps;

const NumericalInputStyled = tw(NumericalInputControlled)`w-full bg-white`;

export const AmountWithCurrencyTextField: FC<Props> = (props) => {
  const { currencySymbol } = useUser();

  return (
    <NumericalInputStyled
      inputProps={{ className: "text-center" }}
      prefix={currencySymbol}
      isAllowed={(values: NumberFormatValues) => {
        const { floatValue } = values;
        return !floatValue || floatValue > 0;
      }}
      useWatchValue
      {...props}
    />
  );
};
