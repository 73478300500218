import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ScheduleReleaseInput,
  useScheduleReleaseMutation,
} from "@/generated/graphql";

export const useScheduleRelease = () => {
  const { setError } = useGlobalError();
  const [scheduleRelease] = useScheduleReleaseMutation();

  const scheduleReleaseMutation = async (input: ScheduleReleaseInput) => {
    try {
      const { errors } = await scheduleRelease({
        variables: {
          input,
        },
      });

      setError(errors);
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  return { scheduleReleaseMutation };
};
