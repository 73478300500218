import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { FC, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useReceiptCreateRelease } from "../../providers/ReceiptCreateReleaseProvider";
import { useReceipt } from "../../providers/ReceiptProvider";
import { useReceiptUpdateRelease } from "../../providers/ReceiptUpdateReleaseProvider";
import { ReceiptCreateReleaseFormValues } from "../ReceiptVerificationForm";

const ButtonContainer = tw.div`grid grid-flow-col gap-2 items-center justify-end`;
const FloatingFooterStyled = tw(FloatingFooter)`md:px-6`;

type Props = {
  readonly: boolean;
};

export const ReceiptFooter: FC<Props> = ({ readonly }) => {
  const { handleSubmit, reset, setError } =
    useFormContext<ReceiptCreateReleaseFormValues>();

  const intl = useIntl();
  const { receipt, loading, itemized, hasReleaseRecorded } = useReceipt();
  const navigate = useNavigate();
  const { syncCreateReleaseFromReceipt, createRelease } =
    useReceiptCreateRelease();
  const { syncUpdateReleaseFromReceipt, updateRelease } =
    useReceiptUpdateRelease();
  const { setWarningAlert } = useSnackbar();
  const { hasPhaseCodes } = useOrgSettings();

  const handleCreateReleaseFromReceipt = useCallback(async () => {
    await handleSubmit(async (values: ReceiptCreateReleaseFormValues) => {
      if (!receipt?.number) {
        setWarningAlert(intl.$t({ id: "RECEIPT_MISSING_NUMBER" }));
        return;
      }
      if (itemized) {
        const sync = await syncCreateReleaseFromReceipt(values);
        if (!sync) {
          return null;
        }
      } else {
        if (hasPhaseCodes ? !values.phaseCodeId : !values.costCodeId) {
          const errorMessage = intl.$t({
            id: hasPhaseCodes ? "ASSIGN_PHASE_CODE" : "ASSIGN_COST_CODE",
          });
          setError(hasPhaseCodes ? "phaseCodeId" : "costCodeId", {
            message: errorMessage,
          });
          setWarningAlert(errorMessage);
          return;
        }
        const release = await createRelease(values);
        if (!release) {
          return null;
        }
      }
      reset();
    })();
  }, [
    handleSubmit,
    receipt?.number,
    itemized,
    reset,
    setWarningAlert,
    intl,
    syncCreateReleaseFromReceipt,
    hasPhaseCodes,
    createRelease,
    setError,
  ]);

  const handleUpdateReleaseFromReceipt = useCallback(async () => {
    await handleSubmit(async (values: ReceiptCreateReleaseFormValues) => {
      if (itemized) {
        const sync = await syncUpdateReleaseFromReceipt(values);
        if (!sync) {
          return null;
        }
      } else {
        const release = await updateRelease(values);
        if (!release) {
          return null;
        }
      }
      reset();
    })();
  }, [
    handleSubmit,
    itemized,
    reset,
    syncUpdateReleaseFromReceipt,
    updateRelease,
  ]);

  // TODO remove this when we add unarchive feature - right now we hide the footer entirely if archived
  if (receipt?.archivedAt || readonly) {
    return null;
  }

  return (
    <FloatingFooterStyled>
      <ButtonContainer>
        <OutlinedButton
          onClick={() => {
            reset();
            navigate(-1);
          }}
        >
          <FormattedMessage id="CANCEL" />
        </OutlinedButton>
        <PrimaryButton
          className="py-0"
          onClick={
            !hasReleaseRecorded
              ? handleCreateReleaseFromReceipt
              : handleUpdateReleaseFromReceipt
          }
          loading={loading}
          testId="receipt-record-order-footer-submit"
        >
          <FormattedMessage
            id={hasReleaseRecorded ? "UPDATE_RELEASE" : "RECORD_ORDER"}
          />
        </PrimaryButton>
      </ButtonContainer>
    </FloatingFooterStyled>
  );
};
