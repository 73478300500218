import { FC } from "react";
import { OrderTypesHeader } from "./components/OrderTypesHeader";
import { OrderTypesList } from "./components/OrderTypesList";

export const OrderTypes: FC = () => {
  return (
    <>
      <OrderTypesHeader />
      <OrderTypesList />
    </>
  );
};
