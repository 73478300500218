import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useDeliverySlipSequence } from "../../providers/DeliverySlipSequenceProvider";
import { useDeliverySlips } from "../../providers/DeliverySlipsProvider";

const Container = tw.div`
  flex -ml-2
`;

export const DeliverySlipsAllItemsCheckbox: FC = () => {
  const { sequenceIds, setSelectedDeliverySlips, selectedDeliverySlips } =
    useDeliverySlipSequence();
  const { deliverySlips } = useDeliverySlips();

  const checked = useMemo(
    () => deliverySlips.every((i) => selectedDeliverySlips.includes(i)),
    [deliverySlips, selectedDeliverySlips],
  );
  const handleChange = useCallback(() => {
    setSelectedDeliverySlips(
      checked
        ? selectedDeliverySlips.filter((i) => !deliverySlips.includes(i))
        : [
            ...selectedDeliverySlips.filter((i) => deliverySlips.includes(i)),
            ...deliverySlips,
          ],
    );
  }, [checked, deliverySlips, selectedDeliverySlips, setSelectedDeliverySlips]);

  return (
    <Container>
      <SelectionCheckbox
        items={sequenceIds}
        setSelection={handleChange}
        checked={checked}
      />
    </Container>
  );
};
