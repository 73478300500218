import { CostCodeRenderer } from "@/common/components/cost-code-renderer/CostCodeRenderer";
import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { SourceSystem } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useProjectCostCodes } from "../../project/hooks/useProjectCostCodes";

type Item = {
  costCode?:
    | {
        id: string;
        description: string;
      }
    | undefined
    | null;
  zone?:
    | {
        id: string;
        name: string;
      }
    | undefined
    | null;
  tags?: {
    id: string;
    name: string;
    mapping?: { sourceSystem: SourceSystem } | undefined | null;
  }[];
};

const TooltipContainer = tw.div`
  flex text-gray-600 text-2xs gap-0.5 min-w-30 items-center whitespace-normal truncate
`;
const Item = tw.div``;

export const useMaterialCostCodeAndZone = (
  item: Item,
  groupedByCostCode: boolean,
  projectId?: string,
) => {
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { hasPhaseCodes } = useOrgSettings();
  const { isNotSupportedCostCode } = useProjectCostCodes(projectId, {
    selectedCostCodeId: item.costCode?.id,
  });

  const costCodeDescription = useMemo(() => {
    if (hasPhaseCodes && item.tags) {
      const phaseCode = item.tags.find((tag) => tag.mapping);
      return phaseCode?.name || unassignedCostCode.description;
    }
    return item.costCode?.id ? (
      <CostCodeRenderer
        costCode={{ id: item.costCode.id, name: item.costCode.description }}
        projectId={projectId}
      />
    ) : (
      unassignedCostCode.description
    );
  }, [
    hasPhaseCodes,
    item.costCode,
    item.tags,
    projectId,
    unassignedCostCode.description,
  ]);

  const itemDetails = useMemo(
    () =>
      !groupedByCostCode ? (
        <Tooltip
          id="material-cost-code-and-zone-tooltip"
          className="flex items-center"
          element={
            <TooltipContainer>
              {costCodeDescription}
              <If isTrue={item.zone?.id}>, {item.zone?.name}</If>
            </TooltipContainer>
          }
        >
          {!isNotSupportedCostCode ? (
            <>
              <Item>
                <FormattedMessage id="COST_CODE" />: {costCodeDescription}
              </Item>
              <If isTrue={item.zone?.id}>
                <Item></Item>
                <Item>
                  <FormattedMessage id="ZONE" />: {item.zone?.name}
                </Item>
              </If>
            </>
          ) : undefined}
        </Tooltip>
      ) : undefined,
    [
      costCodeDescription,
      groupedByCostCode,
      isNotSupportedCostCode,
      item.zone?.id,
      item.zone?.name,
    ],
  );

  return { itemDetails };
};
