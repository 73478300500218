import { Loader } from "@/common/components/loader/Loader";
import { isNonQuotedBuyout } from "@/common/utils/isNonQuotedBuyout";
import { FC } from "react";
import { BuyoutNonQuoted } from "./components/non-quoted/BuyoutNonQuoted";
import { BuyoutQuoted } from "./components/quoted/BuyoutQuoted";
import {
  ContractorBuyoutProvider,
  useContractorBuyout,
} from "./providers/ContractorBuyoutProvider";

type Props = { openedMessages?: boolean };

const BuyoutWithProvider: FC<Props> = () => {
  const { buyout } = useContractorBuyout();

  if (!buyout) {
    return <Loader loading />;
  }

  if (isNonQuotedBuyout(buyout)) {
    return <BuyoutNonQuoted />;
  }

  return <BuyoutQuoted />;
};

export const Buyout: FC<Props> = (props) => {
  return (
    <ContractorBuyoutProvider>
      <BuyoutWithProvider {...props} />
    </ContractorBuyoutProvider>
  );
};
