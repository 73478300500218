import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Bold = tw.span`
  font-medium
`;

const Label = tw.span`
  font-normal text-sm sticky left-0 bg-white h-full
`;

export const QuoteInfoFields: FC = () => (
  <>
    <Label>
      <Bold>
        <FormattedMessage id="DISCOUNTS" />{" "}
      </Bold>
      (<FormattedMessage id="PRODUCTS" />)
    </Label>
    <Label>
      <Bold>
        <FormattedMessage id="ADDITIONAL_CHARGES" />
      </Bold>
    </Label>
    <Label>
      <Bold>
        <FormattedMessage id="QUOTE_EXPIRES" />
      </Bold>
    </Label>
    <Label>
      <Bold>
        <FormattedMessage id="PAYMENT_TERMS" />
      </Bold>
    </Label>
    <Label>
      <Bold>
        <FormattedMessage id="NOTES_AND_TERMS" />
      </Bold>
    </Label>
  </>
);
