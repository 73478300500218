import { If } from "@/common/components/if/If";
import { Check } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { AssignableBuyoutWithBestMatch } from "../../../providers/AssignBuyoutProvider";
import { SHOW_ITEMS_COMPARISON_TABLE } from "./BuyoutsItemsComparisonTable";

const StyledCheck = tw(Check)`text-green-800`;

export const AssignableBuyoutNumber: FC<{
  item: AssignableBuyoutWithBestMatch;
  isSelected: boolean;
  isExpanded: boolean;
  onExpandClick: (item: AssignableBuyoutWithBestMatch) => void;
}> = ({ item, isSelected, isExpanded, onExpandClick }) => {
  return (
    <>
      <If isTrue={isSelected}>
        <StyledCheck />
      </If>
      <If isTrue={SHOW_ITEMS_COMPARISON_TABLE}>
        <button onClick={() => onExpandClick(item)}>
          <KeyboardArrowDownIcon
            sx={{
              transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </button>
      </If>
      <div>#{item.buyout.clientIdentifier}</div>
    </>
  );
};
