import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { routes } from "@/config/routes";
import { RfqQuotesFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import { useRfqQuotes } from "../../../providers/RfqQuotesProvider";

type Props = {
  rfq: RfqQuotesFieldsFragment | undefined | null;
  isDisabled: boolean;
};

export const AcceptQuotesButton: FC<Props> = ({ isDisabled, rfq }: Props) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { acceptQuote } = useRfqQuotes();
  const navigate = useNavigate();

  const acceptQuotes = useCallback(async () => {
    const firstBuyoutId = await acceptQuote();
    if (firstBuyoutId) {
      navigate(generatePath(routes.buyout, { id: firstBuyoutId }));
    }
  }, [acceptQuote, navigate]);

  return (
    <PrimaryButton
      onClick={() =>
        openDialog({
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "ACCEPT" }),
          icon: <WarningIcon />,
          title: intl.$t({ id: "ACCEPT_SELECTED_QUOTE" }),
          text: intl.$t({
            id: rfq?.nextRevision
              ? "ACCEPT_SELECTED_QUOTE_FOR_RFQ_WITH_DRAFT_DETAILS"
              : "ACCEPT_SELECTED_QUOTE_DETAILS",
          }),
          handleConfirm: acceptQuotes,
        })
      }
      disabled={isDisabled}
      testId="accept-quotes-button"
      autoFocus
    >
      <FormattedMessage id="ACCEPT_QUOTE_AND_CREATE_BUYOUT" />
    </PrimaryButton>
  );
};
