import { Tabs } from "@/common/components/tabs/Tabs";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useNotifications } from "../../providers/NotificationsProvider";

enum TabType {
  Internal = 1,
  External = 2,
}

export const NotificationTabs = () => {
  const { isInternalTabActive, setIsInternalTabActive } = useNotifications();
  const intl = useIntl();
  const tabs = useMemo(
    () => [
      {
        id: TabType.Internal,
        label: intl.$t({ id: "NOTIFICATIONS_INTERNAL" }),
        onClick: () => setIsInternalTabActive(true),
      },
      {
        id: TabType.External,
        label: intl.$t({ id: "NOTIFICATIONS_EXTERNAL" }),
        onClick: () => setIsInternalTabActive(false),
      },
    ],
    [intl, setIsInternalTabActive],
  );
  return (
    <Tabs
      className="overflow-visible xl:mx-0 xl:px-0"
      tabs={tabs}
      findIndex={(tab) =>
        isInternalTabActive
          ? tab.id === TabType.Internal
          : tab.id === TabType.External
      }
    />
  );
};
