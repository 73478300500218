import { darken, lighten } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import LineChart from "../../../../contractor/pages/admin/gmv-report/components/line-chart/LineChart";
import { useDialog } from "../../dialog/DialogProvider";
import { If } from "../../if/If";
import { ValueCurrency } from "../../value-currency/ValueCurrency";
import { GraphDataType } from "../enums/GraphDataType";

const Container = tw.div`relative grid grid-rows-[1fr_auto] gap-0.5 w-full h-56 self-end items-end justify-items-center`;
const Value = tw.div`text-xs relative -top-5 text-center font-medium text-blue-800`;
const Bar = tw.div`relative grid self-end w-full min-w-24 border border-b-0 rounded-t-3xl rounded-b-[3px] min-h-1 animate-[grow_0.3s_ease-out_forwards] shadow-md cursor-pointer`;
const Label = tw.div`text-xs mt-0.5 text-center text-gray-600 font-light whitespace-nowrap`;
const Line = tw.div`absolute top-0 left-0 w-full h-0 border-dashed border-t border-white `;
const LineLabel = tw.div`absolute w-full -top-5 text-center text-white pt-1 text-2xs`;

const DEFAULT_LABELS = [
  "PROJECT_BUDGET_HEADER",
  "PROJECT_BUDGET_ORDERED_HEADER",
  "PROJECT_BUDGET_INVOICED_HEADER",
  "PROJECT_BUDGET_PAID_HEADER",
];

type Props = {
  title?: string;
  data: number[];
  colors: string[];
  index: number;
  item: number;
  labels?: string[];
  format?: GraphDataType;
  showMax?: boolean;
  loadData?: (id: string) => Promise<{
    labels: string[];
    values: number[];
  }>;
};

export const BarLineChart: FC<Props> = ({
  title,
  data,
  colors,
  index,
  item,
  labels,
  format = GraphDataType.Currency,
  showMax = true,
  loadData,
}) => {
  const { openDialog } = useDialog();
  const intl = useIntl();
  const max = useMemo(() => Math.max(...data, 1), [data]);
  const getHeight = useCallback(
    (item: number) => {
      const value = Number.isNaN(item) ? 0 : item;
      return Math.max(item ? 3 : 0, (value / max) * 100);
    },
    [max],
  );

  const lineY = useMemo(() => {
    return 100 - Math.min(getHeight(data[0]), getHeight(data[1]));
  }, [data, getHeight]);

  const isMax = useCallback(
    (item: number) => {
      return item === Math.max(...data) && data[0] !== 0 && data[1] !== 0;
    },
    [data],
  );

  const showDetailsDialog = useCallback(async () => {
    if (!labels?.[index]) {
      return;
    }

    let result = await loadData?.(labels?.[index]);
    if (!result) {
      return;
    }

    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      title: `${title} - ${labels?.[index]}`,
      text: (
        <LineChart
          data={result.values}
          labels={result.labels}
          format={format}
        />
      ),
      maxWidth: "xl",
    });
  }, [format, index, intl, labels, loadData, openDialog, title]);

  return (
    <Container>
      <Bar
        onClick={showDetailsDialog}
        style={{
          height: `${getHeight(item)}%`,
          background: `linear-gradient(to bottom, ${darken(
            colors[index % colors.length],
            0.05,
          )} 30%, ${lighten(colors[index % colors.length], 0.4)} 100%)`,
          borderColor: lighten(colors[index % colors.length], 0.5),
        }}
      >
        <Value>
          {format === GraphDataType.Numeric && (
            <FormattedNumber value={item} style="decimal" />
          )}
          {format === GraphDataType.Currency && (
            <ValueCurrency value={item} fractionDigits={item > 1e5 ? 0 : 2} />
          )}
        </Value>
        <If isTrue={isMax(item) && showMax}>
          <Line style={{ top: `${lineY}%` }}>
            <LineLabel>
              +
              <FormattedNumber
                value={
                  Math.max(data[0], data[1]) / Math.min(data[0], data[1]) - 1
                }
                style="percent"
              />
            </LineLabel>
          </Line>
        </If>
      </Bar>
      <If isTrue={!labels?.[index]}>
        <FormattedMessage id={DEFAULT_LABELS[index]} tagName={Label} />
      </If>
      <If isTrue={labels?.[index]}>
        <Label>{labels?.[index]}</Label>
      </If>
    </Container>
  );
};
