import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { ExternalPoBaseFieldsFragment } from "@/generated/graphql";
import { Identity } from "@/types/Identity";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import {
  BlueCircle,
  BlueCircleText,
  InfoOutlineRed,
  ReadonlyField,
  StretchRow,
} from "./ExternalPO.styles";

type Props = {
  item: ExternalPoBaseFieldsFragment & Identity;
};

export const ExternalPOLinks: FC<Props> = ({ item }) => {
  const firstPoLink = useMemo(() => item.poLinks[0], [item.poLinks]);

  const hasMorePoLinks = useMemo(
    () => item.poLinks.length > 1,
    [item.poLinks.length],
  );

  const errorTextId = useMemo(() => {
    if (!item.project || (item.project && !item.project.project)) {
      return "PROJECT_NOT_CONNECTED_PLEASE_IMPORT_BEFORE_IMPORTING_PO";
    }
    if (
      item.vendor &&
      (!item.vendor.orgPreferredVendors ||
        item.vendor.orgPreferredVendors.length === 0)
    ) {
      return "VENDOR_NOT_CONNECTED_PLEASE_IMPORT_BEFORE_IMPORTING_PO";
    }
    if (item.poLinks.length > 0) {
      return "EXTERNAL_PO_ORDER_ALREADY_ASSOCIATED";
    }
    return undefined;
  }, [item]);

  return (
    <StretchRow>
      <ReadonlyField>
        <NotNullableRenderer value={!!firstPoLink}>
          <FormattedMessage
            id="DELIVERY_WITH_NUMBER"
            values={{ number: firstPoLink?.release?.sequenceNumber }}
          />
        </NotNullableRenderer>
        <If isTrue={hasMorePoLinks}>
          <BlueCircle>
            <BlueCircleText>+{item.poLinks.length - 1}</BlueCircleText>
          </BlueCircle>
        </If>
      </ReadonlyField>
      <If isTrue={errorTextId}>
        <Tooltip id="tooltip" element={<InfoOutlineRed />}>
          <FormattedMessage id={errorTextId} />
        </Tooltip>
      </If>
    </StretchRow>
  );
};
