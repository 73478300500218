import { useLocalPagination } from "@/common/components/pagination-local/LocalPaginationProvider";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { FC, useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { useDebounce } from "use-debounce";
import { If } from "../../if/If";

const SearchRowBackground = tw.div`sticky top-0 left-0 right-0 bg-white h-16 z-70 flex items-center `;
const SearchRow = tw.div`flex flex-1 flex-row justify-between mx-4 items-center font-medium text-lg`;
const SearchInputWrapper = tw.div`w-50`;
const SearchFilters = tw.div`flex gap-4 items-center`;

type Props = {
  title: string | React.ReactNode;
  placeholder: string;
  value: string;
  hideSearchInput?: boolean;
  onChange: (searchText: string) => void;
  containerClassName?: string;
  additionalFilters?: React.ReactNode;
};

export const TitledDebouncedRowSearchBar: FC<Props> = ({
  title,
  placeholder,
  value,
  hideSearchInput = false,
  onChange,
  containerClassName,
  additionalFilters,
}) => {
  const [searchText, setSearchText] = useState(value);
  const [debouncedSearchText] = useDebounce(searchText, 700);
  const { setPage } = useLocalPagination();

  useEffect(() => {
    onChange(debouncedSearchText);
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText, setPage]);

  return (
    <SearchRowBackground className={containerClassName}>
      <SearchRow>
        {title}
        <SearchFilters>
          <If isTrue={!hideSearchInput}>
            <SearchInputWrapper>
              <SearchInput
                placeHolder={placeholder}
                onChange={setSearchText}
                value={searchText}
                updateOnChange
              />
            </SearchInputWrapper>
          </If>
          {additionalFilters}
        </SearchFilters>
      </SearchRow>
    </SearchRowBackground>
  );
};
