import { GraphDataType } from "@/common/components/charting/enums/GraphDataType";
import { Select } from "@/common/components/select/components/single/Select";
import { GMVBarChart } from "@/contractor/pages/admin/gmv-report/components/GMVBarChart";
import {
  addMonths,
  addQuarters,
  addYears,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from "date-fns";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { camelToTitle } from "../GMVReport";
import {
  DataResponse,
  Granularity,
  useGMVYearlyData,
} from "../hooks/useGMVYearlyData";

const Container = tw.div`flex justify-center flex-col mt-5 w-full`;
const Selectors = tw.div`flex gap-2 justify-end`;
const GraphContainer = tw.div`grid w-full overflow-x-auto`;

const COLORS = [
  "#F40018",
  "#FF4382",
  "#C260E7",
  "#715EFA",
  "#0046E2",
  "#009BD9",
  "#0084B5",
  "#00BDD2",
  "#00BC9D",
  "#00CB76",
  "#FFD63D",
  "#FF7628",
  "#F44F1E",
  "#B5BDC2",
];

const COUNT_LABEL = "count";

type Graph = {
  title: string;
  labels: string[];
  data: number[];
  format: GraphDataType;
};

type HeaderOption = {
  name: string;
  value: string;
};

const ALL_CUSTOMERS = "all customers";
const DEFAULT_PERIOD = "12";

const DATE_FORMATS = {
  [Granularity.Daily]: "dd MMM yyyy",
  [Granularity.Weekly]: "dd MMM yyyy",
  [Granularity.Monthly]: "MMM yyyy",
  [Granularity.Quarterly]: "Qo 'Q' yyyy",
  [Granularity.Yearly]: "yyyy",
};

const getFirstDayOfQuarter = (quarterStr: string): Date => {
  const [quarter, year] = quarterStr.split("Q");
  const quarterNum = parseInt(quarter);
  return startOfQuarter(addQuarters(new Date(year), quarterNum - 1));
};

const periods = [
  {
    name: "Quarterly",
    value: "8",
    granularity: Granularity.Quarterly,
  },
  {
    name: "12 Months",
    value: "12",
    granularity: Granularity.Monthly,
  },
  {
    name: "24 Months",
    value: "24",
    granularity: Granularity.Monthly,
  },
  {
    name: "36 Months",
    value: "36",
    granularity: Granularity.Monthly,
  },
  {
    name: "Yearly",
    value: "4",
    granularity: Granularity.Yearly,
  },
];

type Props = {
  excludedOrgs: string[] | null;
};

export const GMVCharts: FC<Props> = ({ excludedOrgs }) => {
  const intl = useIntl();
  const { fetchData } = useGMVYearlyData({
    excludedOrgs: excludedOrgs ?? [],
  });
  const [graphs, setGraphs] = useState<Graph[]>([]);
  const [selectedHeader, setSelectedHeader] = useState("total");
  const [headers, setHeaders] = useState<HeaderOption[]>([]);
  const [customers, setCustomers] = useState<HeaderOption[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState(ALL_CUSTOMERS);
  const [selectedPeriod, setSelectedPeriod] = useState<number>(
    Number(DEFAULT_PERIOD),
  );

  const sortCustomers = useCallback((a: string, b: string) => {
    if (a.startsWith("ALL")) {
      return -1;
    }
    if (b.startsWith("ALL")) {
      return 1;
    }
    return a.localeCompare(b);
  }, []);

  const updateHeaders = useCallback(
    (periodsData: DataResponse) => {
      setHeaders(
        Object.keys(periodsData[0].data[0])
          .filter((header) => header !== "name")
          .map((header) => {
            return {
              name: camelToTitle(header),
              value: header,
            };
          }) ?? [],
      );
    },
    [setHeaders],
  );

  const updateCustomers = useCallback(
    (periodsData: DataResponse) => {
      const customerMap = new Map<string, string>();
      periodsData.forEach((d) => {
        d.data.forEach((row) => {
          const name = row.name as string;
          if (!customerMap.has(name)) {
            customerMap.set(name, name);
          }
        });
      });
      setCustomers(
        Array.from(customerMap.keys())
          .map((key) => ({
            name: key,
            value: key.toLocaleLowerCase(),
          }))
          .toSorted((a, b) => sortCustomers(a.name, b.name)),
      );
    },
    [sortCustomers],
  );

  const showGraph = useCallback(async () => {
    const granularity =
      periods.find((m) => m.value === String(selectedPeriod))?.granularity ??
      Granularity.Monthly;
    let startDate;
    let endDate;
    switch (granularity) {
      case Granularity.Quarterly:
        startDate = startOfQuarter(addQuarters(new Date(), -selectedPeriod));
        endDate = endOfQuarter(new Date());
        break;
      case Granularity.Yearly:
        startDate = startOfYear(addYears(new Date(), -selectedPeriod));
        endDate = endOfYear(new Date());
        break;
      default:
        startDate = startOfMonth(addMonths(new Date(), -selectedPeriod));
        endDate = endOfMonth(new Date());
    }

    const resultData = await fetchData({
      startDate,
      endDate,
      granularity,
    });

    if (!resultData) {
      return;
    }

    updateHeaders(resultData);
    updateCustomers(resultData);

    const allGraphs = [] as Graph[];
    resultData.forEach((d) => {
      d.data.forEach((row) => {
        const dates = resultData.map((r) => new Date(r.date));
        const title = `${row.name} ${selectedHeader ?? ""}`;

        if (!allGraphs.find((g) => g.title === title)) {
          allGraphs.push({
            title,
            format: selectedHeader.includes(COUNT_LABEL)
              ? GraphDataType.Numeric
              : GraphDataType.Currency,
            labels: dates.map((date) =>
              format(date, DATE_FORMATS[granularity]),
            ),
            data: dates.map((date) => {
              return Number(
                resultData
                  .find(
                    (d) =>
                      format(new Date(d.date), DATE_FORMATS[granularity]) ===
                      format(date, DATE_FORMATS[granularity]),
                  )
                  ?.data.find((r) => r.name === row.name)?.[selectedHeader] ??
                  0,
              );
            }),
          });
        }
      });
    });
    setGraphs(allGraphs.toSorted((a, b) => sortCustomers(a.title, b.title)));
  }, [
    fetchData,
    selectedHeader,
    selectedPeriod,
    sortCustomers,
    updateCustomers,
    updateHeaders,
  ]);

  useEffect(() => {
    if (excludedOrgs) {
      showGraph();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHeader, selectedPeriod, excludedOrgs]);

  const randomizedColors = useMemo(() => {
    return COLORS.sort(() => Math.random() - 0.5);
  }, []);

  const loadDataForOneMonth = useCallback(
    async (dateString: string, index: number) => {
      const granularity =
        periods.find((m) => m.value === String(selectedPeriod))?.granularity ??
        Granularity.Monthly;
      const date =
        granularity === Granularity.Quarterly
          ? getFirstDayOfQuarter(dateString)
          : new Date(dateString);
      let startDate;
      let endDate;
      let gran;
      switch (granularity) {
        case Granularity.Quarterly:
          startDate = startOfQuarter(date);
          endDate = endOfQuarter(date);
          gran = Granularity.Weekly;
          break;
        case Granularity.Yearly:
          startDate = startOfYear(date);
          endDate = endOfYear(date);
          gran = Granularity.Monthly;
          break;
        default:
          startDate = startOfMonth(date);
          endDate = endOfMonth(date);
          gran = Granularity.Daily;
      }

      const result = await fetchData({
        startDate,
        endDate,
        granularity: gran,
      });

      if (!result) {
        return {
          labels: [],
          values: [],
        };
      }

      const customerData = result.map((d) =>
        d.data.find((r) => r.name === customers[index]?.name),
      );
      let total = 0;

      return {
        labels:
          result.map((r) => {
            if (granularity === Granularity.Yearly) {
              return format(
                new Date(r.date),
                DATE_FORMATS[Granularity.Monthly],
              );
            } else {
              return format(new Date(r.date), DATE_FORMATS[Granularity.Daily]);
            }
          }) ?? [],
        values: customerData.map((d) => {
          total += Number(d?.[selectedHeader] ?? 0);
          return total;
        }),
      };
    },
    [customers, fetchData, selectedHeader, selectedPeriod],
  );

  return (
    <Container>
      <Selectors>
        <Select
          className="mb-1 w-64 self-end"
          testId="form-order-type-selector"
          label={intl.$t({ id: "CUSTOMERS" })}
          options={customers}
          value={selectedCustomer}
          onChange={(value) => setSelectedCustomer(value as string)}
          getLabel={(option) => option.name}
          getValue={(option) => option.value}
        />
        <Select
          className="mb-1 w-52 self-end"
          testId="form-order-type-selector"
          label={intl.$t({ id: "COLUMN" })}
          options={headers}
          value={selectedHeader}
          onChange={(value) => setSelectedHeader(value as string)}
          getLabel={(option) => option.name}
          getValue={(option) => option.value}
        />
        <Select
          className="mb-1 w-40 self-end"
          testId="form-order-type-selector"
          label={intl.$t({ id: "PERIOD" })}
          options={periods}
          value={String(selectedPeriod)}
          onChange={(value) => setSelectedPeriod(Number(value))}
          getLabel={(option) => option.name}
          getValue={(option) => option.value}
        />
      </Selectors>
      <GraphContainer>
        {graphs
          .filter(
            (graph) =>
              selectedCustomer === ALL_CUSTOMERS ||
              graph.title.toLocaleLowerCase().includes(selectedCustomer),
          )
          .map((graph, index) => {
            return (
              <GMVBarChart
                key={`${graph.title}${index}`}
                title={graph.title}
                data={graph.data}
                dataSplit={
                  index === 0 && selectedCustomer === ALL_CUSTOMERS
                    ? graphs.slice(1)
                    : undefined
                }
                format={graph.format}
                labels={graph.labels}
                colors={randomizedColors}
                showMax={false}
                loadData={(id: string) => loadDataForOneMonth(id, index)}
              />
            );
          })}
      </GraphContainer>
    </Container>
  );
};
