import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { SelectableItem } from "@/common/components/searchable-list/components/SelectableItem";
import { Base, MdFixedContainer } from "@/common/layout/ResponsiveClasses";
import { CostTypeFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const WideContainer = tw(Base)`
  lg:basis-auto lg:w-5/12 2xl:basis-auto 2xl:w-6/12
`;

export const useSelectableCostTypesConfiguration = ({
  selectedItem,
}: {
  selectedItem: string | null;
}) => {
  const configuration: Array<GridCol<CostTypeFieldsFragment>> = useMemo(
    () => [
      {
        wrapper: WideContainer,
        header: <FormattedMessage id="DESCRIPTION" />,
        item: ({ item, index, count }) => (
          <SelectableItem
            item={item}
            index={index}
            selectedItems={selectedItem ? [selectedItem] : []}
            itemNameSelectorFn={(item) => item.description}
            count={count}
          />
        ),
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="COST_CODE" />,
        item: ({ item }) => item.code,
      },
    ],
    [selectedItem],
  );
  return { configuration };
};
