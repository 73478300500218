import { useUser } from "@/common/providers/UserProvider";
import { useMemo } from "react";
import { Permission, PermissionRoleMapping } from "./OrgRolesWrapper";

export const usePermissions = (permissions: Permission[]) => {
  const { viewer } = useUser();
  const hasPermissions = useMemo(() => {
    return viewer?.orgRoles?.some((o) =>
      permissions.some((p) => PermissionRoleMapping[p].includes(o)),
    );
  }, [permissions, viewer?.orgRoles]);

  return { hasPermissions };
};
