import useDelayedQuery from "@/common/hooks/useDelayedQuery";
import { useQueryPollingWhileWindowFocused } from "@/common/hooks/useQueryPollingWhileWindowFocused";
import { useUser } from "@/common/providers/UserProvider";
import { useOrgNotificationCountQuery } from "@/generated/graphql";
import { useEffect, useState } from "react";

const POLL_INTERVAL = 60000;
const QUERY_DELAY = 4000;

type OrgNotificationCount = {
  id: string;
  notificationCount: number;
}[];

const useOrgNotificationCount = (): {
  orgNotificationCount: OrgNotificationCount | null;
} => {
  const { isContractor } = useUser();
  const { startQuery } = useDelayedQuery(QUERY_DELAY);
  const { data, error, refetch, startPolling, stopPolling } =
    useOrgNotificationCountQuery({
      skip: !startQuery || !isContractor,
    });

  const [orgNotificationCount, setOrgNotificationCount] =
    useState<OrgNotificationCount | null>(null);

  useQueryPollingWhileWindowFocused({
    pollInterval: POLL_INTERVAL,
    refetch,
    startPolling,
    stopPolling,
    skip: !isContractor || !startQuery,
  });

  useEffect(() => {
    if (error) {
      return;
    }

    const orgs = data?.viewer?.org?.enterprise?.orgs;

    if (orgs) {
      setOrgNotificationCount(orgs);
    }
  }, [data, error]);

  return {
    orgNotificationCount,
  };
};

export default useOrgNotificationCount;
