import { GroupBySegment } from "@/common/components/group-by-segment/GroupBySegment";
import { FC } from "react";
import { useBuyoutQuotedGrouped } from "../quoted/providers/BuyoutQuotedGroupedProvider";

export const GroupQuotedBuyoutByCostCodeToggle: FC = () => {
  const { groupedByCostCode, setGroupedByCostCode } = useBuyoutQuotedGrouped();
  return (
    <GroupBySegment
      groupedByCostCode={groupedByCostCode}
      setGroupedByCostCode={setGroupedByCostCode}
    />
  );
};
