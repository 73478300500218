import { CountType } from "@/common/components/circle-counter/CountType";
import { If } from "@/common/components/if/If";
import { LineItemNameNotes } from "@/common/components/line-item-name-notes/LineItemNameNotes";
import { RfqProductView } from "@/common/components/rfq-product-view/RfqProductView";
import { DateView } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { QuoteStatus, UpdateRfqItemInput } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../providers/DistributorQuoteItemsProvider";
import { useDistributorQuote } from "../providers/DistributorQuoteProvider";
import { WithdrawalWrapper } from "./WithdrawalWrapper";
import { DistributorQuoteItemAddMenu } from "./alternatives/DistributorQuoteItemAddMenu";

const RfqProductViewStyled = tw(RfqProductView)``;
const Container = tw.div`flex`;
const NameContainer = tw.div`flex w-full flex-col truncate`;

type DistributorQuoteRfqProductViewProps = {
  item: DistributorQuoteItemGroupExtendedFieldsFragment;
  index?: number;
  updateItem?: (updates: UpdateRfqItemInput[]) => void;
  isEditable?: boolean;
  readonly?: boolean;
  count: CountType;
};

enum QUOTE_ITEM_CHANGE_REQUEST_STATUS {
  NEW,
  CHANGED,
}

const QUOTE_ITEM = {
  [QUOTE_ITEM_CHANGE_REQUEST_STATUS.NEW]: {
    label: "QUOTE_ITEM_NEW",
    tooltipKey: "QUOTE_ITEM_NEW_TOOLTIP",
    labelClassName: "bg-green-300",
  },
  [QUOTE_ITEM_CHANGE_REQUEST_STATUS.CHANGED]: {
    label: "QUOTE_ITEM_UPDATED",
    tooltipKey: "QUOTE_ITEM_UPDATED_TOOLTIP",
    labelClassName: "bg-yellow-600",
  },
};

export const DistributorQuoteRfqProductView: FC<
  DistributorQuoteRfqProductViewProps
> = ({ item, readonly, ...props }: DistributorQuoteRfqProductViewProps) => {
  const intl = useIntl();
  const { quote } = useDistributorQuote();
  const changeRequestedProps = useMemo(() => {
    if (
      !quote?.nextRevision &&
      item.quoteItems[0]?.status === QuoteStatus.ChangeRequested
    ) {
      const baseClassName = "uppercase text-2xs";
      const { tooltipKey, label, labelClassName } =
        QUOTE_ITEM[
          item.quoteItems[0]?.quantityDecimal === null
            ? QUOTE_ITEM_CHANGE_REQUEST_STATUS.NEW
            : QUOTE_ITEM_CHANGE_REQUEST_STATUS.CHANGED
        ];
      return {
        labelTooltip: intl.$t(
          { id: tooltipKey },
          {
            date: <DateView date={quote?.submittedDate} />,
            contractor: getUserName(quote?.rfq.createdBy),
            quantity: item.rfqItem.quantityDecimal,
            uom: item.rfqItem.projectItem.estimateUom.mnemonic,
            initialQuantity: item.quoteItems[0]?.quantityDecimal,
          },
        ),
        label: intl.$t({ id: label }),
        labelClassName: labelClassName.concat(" ", baseClassName),
      };
    }
  }, [item, intl, quote]);

  return (
    <>
      <NameContainer>
        <RfqProductViewStyled
          item={item.rfqItem}
          {...props}
          {...changeRequestedProps}
          nowrap={false}
        />
        <LineItemNameNotes
          notes={item.rfqItem.instructions?.text}
          assets={item.rfqItem.instructions?.assets}
          label={intl.$t({ id: "CONTRACTOR_NOTE_LABEL" })}
        />
        <If isTrue={readonly}>
          <LineItemNameNotes
            notes={item.quoteItems[0]?.notes}
            assets={item.quoteItems[0]?.assets}
            label={intl.$t({ id: "YOUR_NOTE_LABEL" })}
          />
        </If>
      </NameContainer>
      <If isTrue={!readonly}>
        <WithdrawalWrapper item={item}>
          <Container>
            <DistributorQuoteItemAddMenu item={item} />
          </Container>
        </WithdrawalWrapper>
      </If>
    </>
  );
};
