import { MAX_ITEMS_PER_PAGE } from "@/common/const";
import {
  BuyoutsBuyoutFieldsFragment,
  BuyoutStatus,
  useBuyoutsQuery,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { createContext, FC, useContext, useMemo, useState } from "react";

type ProviderContextType = {
  buyouts: BuyoutsBuyoutFieldsFragment[];
  loading: boolean;
  setProjectIdFilter: (id: string) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  buyouts: [],
  loading: false,
  setProjectIdFilter: NoFunction,
});

export const BuyoutsByProjectProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [projectId, setProjectId] = useState<string>("");

  const filter = useMemo(
    () => ({
      projectIDs: projectId ? [projectId] : [],
      statuses: [BuyoutStatus.Active],
    }),
    [projectId],
  );

  const { data: buyoutsData, loading } = useBuyoutsQuery({
    variables: {
      first: MAX_ITEMS_PER_PAGE,
      filter,
    },
    skip: filter.projectIDs.length === 0,
  });

  return (
    <ProviderContext.Provider
      value={{
        buyouts: buyoutsData?.buyouts?.edges?.map((edge) => edge.node) || [],
        loading,
        setProjectIdFilter: setProjectId,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useBuyoutsByProject = (): ProviderContextType =>
  useContext(ProviderContext);
