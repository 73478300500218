import {
  TextFieldControlled,
  TextFieldControlledProps,
} from "@/common/components/textfield-controlled/TextFieldControlled";
import { FC } from "react";

export const ProjectReleaseInstructionsTextField: FC<
  TextFieldControlledProps
> = (props) => {
  return <TextFieldControlled {...props} multiline rows={2.3} size="small" />;
};
