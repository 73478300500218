import { hiddenDetails } from "@/common/components/grid-table/grid-item/utils";
import { WrapperProps } from "@/common/components/grid-table/types/WrapperProps";
import { Base } from "@/common/layout/ResponsiveClasses";
import { RiFileEditFill, RiFileEditLine } from "react-icons/ri";
import tw from "tailwind-styled-components";

export const QuoteInputContainerReadonly = tw(Base)`
  w-fit lg:w-24 lg:basis-fit lg:py-0 justify-start lg:justify-center print:w-[80px] print:justify-center
  ${hiddenDetails}
  ${({ $header, $group }: WrapperProps) =>
    $group || $header ? "lg:pl-0 print:pl-0" : "print:ml-0 lg:ml-0"}`;

export const QuoteInputContainerReadonlyWithoutGroup = tw(
  QuoteInputContainerReadonly,
)`${({ $header, $group }: WrapperProps) =>
  $group || $header ? "hidden lg:flex print:flex" : ""}`;

export const EditIcon = tw(RiFileEditLine)`
  w-5 h-5
`;

export const EditIconFilled = tw(RiFileEditFill)`
  w-5 h-5
`;
