import { FC } from "react";
import tw from "tailwind-styled-components";

const IconContainer = tw.div`
flex items-center justify-center w-28 h-28 text-7xl text-yellow-600 border-8 border-yellow-600 rounded-full mt-2
`;
const ExclamationContainer = tw.div``;

type Props = {
  className?: string;
};

export const WarningIcon: FC<Props> = ({ className }) => (
  <IconContainer className={className}>
    <ExclamationContainer>!</ExclamationContainer>
  </IconContainer>
);
