import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useCallback, useMemo, useState } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";

const Container = tw.div`relative`;

export const InvoiceExtPriceInput = ({
  item,
  readonly = false,
}: {
  item: ExpandedReleaseItem;
  readonly?: boolean;
}) => {
  const { updateInvoice, invoice } = useInvoiceVerification();
  const invoicedItem = useMemo(
    () => item.invoiceItems?.[0],
    [item.invoiceItems],
  );

  const [invoiced, setInvoiced] = useState<string>(
    item.invoiceItems?.[0]?.quantity,
  );

  const onSave = useCallback(
    async (quantity: string | null) => {
      if (quantity) {
        setInvoiced(quantity);
      }

      if (!invoicedItem) {
        return;
      }
      await updateInvoice(
        {
          id: invoice?.id || "",
          updatedInvoicedReleaseItems: [
            {
              id: invoicedItem.id,
              quantity: quantity ? quantity.toString() : undefined,
              quantitySoFar: invoicedItem.quantitySoFar || "0",
              releaseItemId: item.id,
            },
          ],
          releaseId: invoice?.release?.id || "",
        },
        { bulkUpdate: true },
      );
    },
    [invoicedItem, updateInvoice, invoice?.id, invoice?.release?.id, item.id],
  );

  return (
    <Container>
      <PricePicker
        value={invoiced}
        onBlur={async (extPrice) => {
          await onSave(extPrice);
        }}
        readonly={readonly}
      />
    </Container>
  );
};
