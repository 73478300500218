import { FC } from "react";
import { FormattedMessage } from "react-intl";

type DateProps = {
  date: Date | number | null | undefined;
  includeTime?: boolean;
  onlyTime?: boolean;
  twoLines?: boolean;
  options?: Intl.DateTimeFormatOptions;
  hideTimeZone?: boolean;
  utc?: boolean;
};

export const SHORT_DATE_OPTION: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};

const DATE_OPTION: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
  year: "numeric",
};

const TIME_OPTION: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "short",
};

const DATE_TIME_OPTION: Intl.DateTimeFormatOptions = {
  ...DATE_OPTION,
  ...TIME_OPTION,
};

export const formattedDate = ({
  date,
  options,
  onlyTime,
  includeTime,
  hideTimeZone,
  utc = false,
}: DateProps): string | null => {
  if (!date) {
    return null;
  }

  return new Date(date).toLocaleString("en", {
    ...options,
    ...(options && includeTime ? TIME_OPTION : {}),
    ...(!options &&
      (onlyTime ? TIME_OPTION : includeTime ? DATE_TIME_OPTION : DATE_OPTION)),
    ...(hideTimeZone ? { timeZoneName: undefined } : {}),
    ...(utc ? { timeZone: "UTC" } : {}),
  });
};

export const DateView: FC<DateProps> = ({
  date,
  includeTime = false,
  onlyTime = false,
  hideTimeZone = false,
  twoLines = false,
  options,
  utc = false,
}) => (
  <>
    {date ? (
      twoLines ? (
        formattedDate({
          date,
          includeTime,
          onlyTime,
          options,
          hideTimeZone,
          utc,
        })
          ?.split(",")
          .map((d, i) => <div key={i}>{d}</div>)
      ) : (
        formattedDate({
          date,
          includeTime,
          onlyTime,
          options,
          hideTimeZone,
          utc,
        })
      )
    ) : onlyTime ? null : (
      <FormattedMessage id="NO_DATE" />
    )}
  </>
);
