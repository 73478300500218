import { FC, useState } from "react";
import { useRfqQuotes } from "../../../providers/RfqQuotesProvider";
import { AuxItemsHeader } from "./aux-items-header/AuxItemsHeader";
import { AuxVendorItems } from "./aux-vendor-items/AuxVendorItems";

export const AuxItemsLeveling: FC = () => {
  const { rfq } = useRfqQuotes();
  const [isOpen, setIsOpen] = useState(true);

  if (!rfq) {
    return null;
  }

  return (
    <>
      <AuxItemsHeader topOffset={60} isOpen={isOpen} setIsOpen={setIsOpen} />
      {isOpen ? <AuxVendorItems /> : null}
    </>
  );
};
