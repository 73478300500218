import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { ConnectionMode } from "@/contractor/pages/admin/integrations/components/common/ConnectionMode";
import { ConnectReleasePOsPanel } from "@/contractor/pages/home/release/components/connections/components/panels/ConnectReleasePOsPanel";
import { ReleaseFieldsFragment, SourceSystem } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  ReleaseExportStatus,
  ReleaseExportStatusEnum,
} from "../common/ReleaseExportStatus";

type Props = {
  release: Pick<
    ReleaseFieldsFragment,
    | "status"
    | "id"
    | "poLink"
    | "version"
    | "poNumber"
    | "project"
    | "syncVersion"
  >;
  sourceSystem: SourceSystem;
};

export const EditReleasePOButton: FC<Props> = ({ release, sourceSystem }) => {
  const intl = useIntl();

  const status = useMemo(() => {
    if (release.poLink?.syncing) {
      return ReleaseExportStatusEnum.Syncing;
    }
    if (release.poLink?.syncedVersion !== release.syncVersion) {
      return ReleaseExportStatusEnum.Outdated;
    }
    if (release.poLink?.autoSyncError) {
      return ReleaseExportStatusEnum.SyncIssue;
    }
    return ReleaseExportStatusEnum.OK;
  }, [
    release.poLink?.autoSyncError,
    release.poLink?.syncedVersion,
    release.poLink?.syncing,
    release.syncVersion,
  ]);

  const connectionMode = useMemo(() => {
    if (release.poLink?.autoSync) {
      return ConnectionMode.Edit;
    }
    return ConnectionMode.Sync;
  }, [release.poLink?.autoSync]);

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <>
          <If isTrue={release.poLink}>
            <ReleaseExportStatus
              status={status}
              sourceSystem={sourceSystem}
              date={release.poLink?.syncedAt}
              poNumber={release.poNumber}
              projectId={release.project?.id}
            />
          </If>
          <OutlinedButton $small onClick={() => togglePanel(true)}>
            <FormattedMessage
              id={
                connectionMode === ConnectionMode.Edit
                  ? "EDIT"
                  : "SYNC_PO_TO_INTEGRATION_TYPE"
              }
              values={{
                integration: intl.$t({ id: `INTEGRATION_${sourceSystem}` }),
              }}
              tagName="span"
            />
          </OutlinedButton>
        </>
      )}
      content={(togglePanel) => (
        <ConnectReleasePOsPanel
          releaseIds={[release.id]}
          sourceSystem={sourceSystem}
          onClose={() => togglePanel(false)}
          mode={connectionMode}
        />
      )}
    />
  );
};
