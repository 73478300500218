import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { isEmptyString } from "@/common/utils/validationUtils";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { Check, Close } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useBuyoutGrouped } from "../providers/BuyoutGroupedProvider";
import { BuyoutItemInstructions } from "./BuyoutItemInstructions";
import { DeleteItem } from "./DeleteItem";

const IconButtonContainer = tw.div`flex items-center`;

export const BuyoutItemActions: FC<{
  item: BuyoutItemFieldsFragment;
  readonly: boolean;
}> = ({ item, readonly }) => {
  const {
    newBuyoutItem,
    addToBuyout,
    setNewBuyoutItem,
    setIsNewBuyoutWithErrors,
  } = useBuyoutGrouped();
  const { setWarningAlert } = useSnackbar();
  const intl = useIntl();

  const cancel = useCallback(() => {
    setIsNewBuyoutWithErrors(false);
    setNewBuyoutItem({
      description: "",
      projectItem: { estimateUom: "" },
      requestedUnitPrice: "0",
      instructions: { text: "", assetUrls: [] },
    });
  }, [setNewBuyoutItem, setIsNewBuyoutWithErrors]);

  const handleAddToBuyout = useCallback(async () => {
    if (
      (!newBuyoutItem.orgCatalogSkuName &&
        !newBuyoutItem.projectItem?.orgCatalogSkuId &&
        !newBuyoutItem.projectItem?.masterProductId &&
        !newBuyoutItem.projectItem?.masterSkuId) ||
      isEmptyString(newBuyoutItem.description)
    ) {
      setIsNewBuyoutWithErrors(true);
      setWarningAlert(intl.$t({ id: "ERROR_SKU_DESCRIPTION" }));
      return;
    }
    if (!newBuyoutItem.projectItem?.estimateUom) {
      setIsNewBuyoutWithErrors(true);
      setWarningAlert(intl.$t({ id: "ERROR_UOM" }));
      return;
    }
    if (!newBuyoutItem.quantityDecimal) {
      setIsNewBuyoutWithErrors(true);
      setWarningAlert(intl.$t({ id: "ERROR_QUANTITY" }));
      return;
    }

    setIsNewBuyoutWithErrors(false);
    await addToBuyout();
    setNewBuyoutItem({
      description: "",
      projectItem: { estimateUom: "" },
      requestedUnitPrice: "0",
      instructions: { text: "", assetUrls: [] },
    });
  }, [
    addToBuyout,
    intl,
    newBuyoutItem,
    setNewBuyoutItem,
    setWarningAlert,
    setIsNewBuyoutWithErrors,
  ]);

  const icons = useMemo(() => {
    if (!item.id) {
      return [
        <LinkLike key={0} onClick={handleAddToBuyout}>
          <Check />
        </LinkLike>,
        <LinkLike key={1} onClick={cancel}>
          <Close />
        </LinkLike>,
      ];
    } else {
      return [<DeleteItem id={item.id} readonly={readonly} key={0} />];
    }
  }, [item.id, handleAddToBuyout, cancel, readonly]);

  return (
    <If isTrue={!readonly}>
      <IconButtonContainer>
        <BuyoutItemInstructions item={item} />
        {icons.map((icon) => icon)}
      </IconButtonContainer>
    </If>
  );
};
