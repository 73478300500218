import { If } from "@/common/components/if/If";
import { OrderTypePicker } from "@/common/components/order-type-picker/OrderTypePicker";
import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { DateTimePicker } from "@/common/components/picker/components/DateTimePicker";
import { PoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/PoNumberingSettingsCheck";
import { WarehouseSelector } from "@/common/components/warehouse-selector/WarehouseSelector";
import { PROJECT_ADDRESS_ID } from "@/common/components/warehouse-selector/useWarehouseOptions";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { useWarehouses } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import { ReleasePONumber } from "@/contractor/pages/home/buyout-releases/components/releases-list/release-view/ReleasePONumber";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  ReleaseStatus,
  ServiceType,
  TransactionKind,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FormControl, InputLabelProps } from "@mui/material";
import { useMemo } from "react";
import tw from "tailwind-styled-components";
import { useOrderTypesConfig } from "../../../../../../common/hooks/order-types-config/useOrderTypesConfig";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "../../providers/ReleaseActionsProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";
import { PickerCustomActionBar } from "./PickerCustomActionBar";
import { READ_ONLY_RELEASE_STATUSES } from "./SpecifyReleaseDetails";
import { SiteContactPicker } from "./components/site-contact-picker/components/SiteContactPicker";
import { useReleasePredictedPoNumber } from "./hooks/useReleasePredictedPoNumber";

const FormControlStyled = tw(FormControl)`
  w-full bg-white
`;

export const DeliveryInformation = () => {
  const { release } = useRelease();
  const { predictedPoNumber, loading } = useReleasePredictedPoNumber(
    release?.id || "",
  );
  const { warehouses } = useWarehouses();
  const { updateRelease } = useReleaseUpdate();
  const { requestedDate, setRequestedDate, inputErrors } = useReleaseActions();
  const { includeOrderTypes } = useOrderTypeOptions();

  const { orderTypeConfig } = useOrderTypesConfig({ release });
  const readonly = useMemo(() => {
    return !!release && READ_ONLY_RELEASE_STATUSES.includes(release.status);
  }, [release]);

  const InputLabelPropsMemoized: InputLabelProps = useMemo(() => {
    return {
      classes: {
        shrink: "text-base",
        root: "text-xs",
      },
    };
  }, []);

  const update = async (poNumber?: string) => {
    if (release) {
      const input: UpdateContractorReleaseInput = {
        releaseId: release.id,
        version: release.version,
        poNumber,
      };

      await updateRelease(input);
    }
  };

  const onDateConfirm = async (date: Date | null) => {
    setRequestedDate(date);

    if (release) {
      const input: UpdateContractorReleaseInput = {
        releaseId: release?.id,
        version: release?.version,
      };

      if (date) {
        input.requestedTime = date.getTime();
      }

      await updateRelease(input);
    }
  };
  const onDateChange = (date: Date | null) => {
    setRequestedDate(!requestedDate ? defaultReleaseDate(date) : date);
  };

  const retroactiveRelease = useMemo(() => {
    return requestedDate && requestedDate?.getTime() < new Date().getTime();
  }, [requestedDate]);

  const predictedPoNumberPlaceholder = useMemo(() => {
    if (
      checkReleaseStatus(release, [
        ReleaseStatus.Draft,
        ReleaseStatus.AwaitingApproval,
        ReleaseStatus.Rejected,
      ])
    ) {
      return predictedPoNumber || undefined;
    }
  }, [predictedPoNumber, release]);
  return (
    <>
      <FormControlStyled fullWidth>
        <If
          isTrue={
            release?.includeServices.includes(ServiceType.Delivery) ||
            release?.type.transactionKind === TransactionKind.Purchase
          }
        >
          <WarehouseSelector
            value={release?.warehouse}
            options={warehouses}
            inputProps={{
              className: "text-sm p-0.5",
            }}
            projectAddress={release?.project?.address}
            onChange={(warehouseId) =>
              updateRelease({
                releaseId: release?.id || "",
                version: release?.version,
                warehouseId:
                  warehouseId === PROJECT_ADDRESS_ID ? undefined : warehouseId,
                clearWarehouse: warehouseId === PROJECT_ADDRESS_ID,
              })
            }
          />
        </If>
      </FormControlStyled>
      <If isTrue={includeOrderTypes}>
        <FormControlStyled fullWidth>
          <OrderTypePicker
            orderTypeId={release?.type.id}
            inputProps={{
              className: "text-sm p-0.5",
            }}
            setOrderTypeId={(id) =>
              updateRelease({
                releaseId: release?.id || "",
                version: release?.version,
                typeId: id,
              })
            }
            disabled={
              !!release?.buyout ||
              !checkReleaseStatus(
                release,
                [
                  ReleaseStatus.Draft,
                  ReleaseStatus.AwaitingApproval,
                  ReleaseStatus.Requested,
                  ReleaseStatus.Scheduled,
                ] || release?.poLink?.syncedAt,
              )
            }
          />
        </FormControlStyled>
      </If>
      <PoNumberingSettingsCheck alternativeCondition={!!release?.poNumber}>
        <FormControlStyled fullWidth>
          <ReleasePONumber
            update={(_, poNumber?: string) => update(poNumber)}
            release={release}
            sourceSystem={release?.poLink?.sourceSystem}
            autoSize
            placeholder={predictedPoNumberPlaceholder}
            loading={loading}
          />
        </FormControlStyled>
      </PoNumberingSettingsCheck>
      <If isTrue={!readonly}>
        <FormControlStyled fullWidth>
          <DateTimePicker
            label={orderTypeConfig?.labels.datePicker}
            date={requestedDate}
            onChange={onDateChange}
            onAccept={onDateConfirm}
            required
            inputProps={{
              className: "text-sm",
            }}
            error={inputErrors.includes(
              ReleaseErrorType.REQUESTED_FULFILLMENT_DATE,
            )}
            InputLabelProps={InputLabelPropsMemoized}
            actionBar={retroactiveRelease ? PickerCustomActionBar : undefined}
          />
        </FormControlStyled>
        <FormControlStyled fullWidth>
          <SiteContactPicker
            value={release?.siteContact?.id}
            inputProps={{
              className: "text-sm p-0.5",
            }}
            onChange={(contactId) =>
              updateRelease({
                releaseId: release?.id || "",
                version: release?.version,
                siteContactId: contactId || undefined,
                clearSiteContact: !contactId,
              })
            }
            countryCode={release?.sellerOrgLocation?.address.country}
          />
        </FormControlStyled>
      </If>
    </>
  );
};
