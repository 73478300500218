import { Dialog } from "@/common/components/dialog/Dialog";
import { UploadAsset } from "@/common/components/upload-asset/UploadAsset";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import {
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPE,
  PDF_EXTENSIONS,
  PDF_MIME_TYPE,
} from "@/common/components/upload/FileUploadArea";
import { AssetContext, AssetFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const DialogContent = tw.div`
  grid gap-7 place-items-center w-full h-fit
`;

const AssetUploadContainer = tw.div`
  grid w-full h-fit
`;

type Props = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  handleConfirm: (assets: AssetFieldsFragment[]) => void;
  initialAssets?: AssetFieldsFragment[];
};

const DeliverySlipEditWithProvider: FC<Props> = ({
  visible = true,
  setVisible,
  handleConfirm,
}) => {
  const intl = useIntl();
  const { assets, setAssets } = useUploadAssets();

  const closeDialog = () => {
    setAssets([]);
    setVisible?.(false);
  };

  return (
    <Dialog
      title={<FormattedMessage id="EDIT_PACKING_SLIP" />}
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({ id: "SAVE" })}
      handleConfirm={() => {
        handleConfirm(assets);
        closeDialog();
      }}
      maxWidth="xl"
      handleCancel={closeDialog}
      show={visible}
      content={
        <DialogContent>
          <AssetUploadContainer>
            <UploadAsset
              className="h-1/2 w-full"
              accept={{
                [PDF_MIME_TYPE]: PDF_EXTENSIONS,
                [IMAGE_MIME_TYPE]: IMAGE_EXTENSIONS,
              }}
            >
              <FormattedMessage id="DELIVERY_SLIP_SUPPORTED_FORMATS" />
            </UploadAsset>
          </AssetUploadContainer>
        </DialogContent>
      }
      buttonsConfiguration={{
        confirm: { wide: true },
        cancel: { wide: true },
      }}
    />
  );
};

export const DeliverySlipEditDialog: FC<Props> = (props) => {
  return (
    <UploadAssetProvider context={AssetContext.DeliverySlip}>
      <DeliverySlipEditWithProvider {...props} />
    </UploadAssetProvider>
  );
};
