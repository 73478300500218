import { GridTable } from "@/common/components/grid-table/GridTable";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { WarehouseFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import {
  EditWarehousePanelId,
  ToggleEditWarehouse,
} from "../add-panel/ToggleEditWarehouse";
import { useWarehousesConfiguration } from "./Warehouses.configuration";

type Props = {
  warehouses: WarehouseFieldsFragment[];
  loading: boolean;
};

export const WarehousesList: FC<Props> = ({ warehouses, loading }) => {
  const { toggle } = useGlobalDrawer();
  const configuration = useWarehousesConfiguration();
  return (
    <ListRenderer
      hasItemsCondition={warehouses && warehouses.length > 0}
      emptyList={<NoResults translationKey="NO_WAREHOUSE" />}
    >
      <GridTable
        configuration={{
          columns: configuration,
          toggle: {
            item: (vendor) => toggle(EditWarehousePanelId, true, vendor.id),
          },
          classNames: {
            header: "top-25",
          },
        }}
        loading={loading}
        items={warehouses}
      />
      <ToggleEditWarehouse />
    </ListRenderer>
  );
};
