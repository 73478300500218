import { Loader } from "@/common/components/loader/Loader";
import { useUser } from "@/common/providers/UserProvider";
import tw from "tailwind-styled-components";
import { OrgProfileForm } from "./components/OrgProfileForm";
import { OrgProfileFormHookProvider } from "./components/OrgProfileFormHookProvider";
import { useOrgProfile } from "./hooks/useOrgProfile";

const Container = tw.div`pt-12`;

export const OrgProfile = () => {
  const { isContractor } = useUser();
  const { viewerOrg } = useOrgProfile();

  if (!isContractor || !viewerOrg) {
    return <Loader loading />;
  }

  return (
    <Container>
      <OrgProfileFormHookProvider org={viewerOrg?.viewer?.org}>
        <OrgProfileForm withSave withLogoUpload />
      </OrgProfileFormHookProvider>
    </Container>
  );
};
