import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { Report, ReportOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { ReportIssue } from "./ReportIssue";

type Props = {
  item?: ExpandedReleaseItem;
  release?: ReleaseFieldsFragment | null;
  batch?: boolean;
};

export const AddItemIssue: FC<Props> = ({ item, batch = false }) => {
  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <LinkLike
          className="text-xs"
          onClick={() => togglePanel(true)}
          testId="report-issue"
        >
          <If isTrue={item?.issues.length}>
            <Report />
            <FormattedMessage id={"ISSUE_ADDED"} />
          </If>
          <If isTrue={!item?.issues.length}>
            <ReportOutlined />
            <FormattedMessage id={"REPORT_ISSUE"} />
          </If>
        </LinkLike>
      )}
      content={(togglePanel) => (
        <ReportIssue
          onClose={() => togglePanel(false)}
          item={item}
          batch={batch}
        />
      )}
    />
  );
};
