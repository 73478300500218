import { FC, forwardRef } from "react";
import tw from "tailwind-styled-components";
import { BaseButton } from "./BaseButton";

type PrimaryButtonProps = React.ComponentProps<typeof BaseButton> & {
  $small?: boolean;
};

const PrimaryButtonStyled = tw(BaseButton)`
  min-w-24 bg-blue-800 border text-white hover:bg-opacity-90
  group-[.is-row]:min-h-8 group-[.is-row]:text-sm disabled:text-gray-300
  ${({ $small }: PrimaryButtonProps) =>
    $small && "text-xs mb-2 my-0 mx-0 px-2 min-h-6 2xl:h-6 2xl:min-w-16"}
`;

export const PrimaryButton: FC<PrimaryButtonProps> = forwardRef(
  ({ children, ...props }, ref) => {
    return (
      <PrimaryButtonStyled {...props} ref={ref}>
        {children}
      </PrimaryButtonStyled>
    );
  },
);
