import {
  ArchivedContainer,
  BreadcrumbItem,
  Breadcrumbs,
} from "@/common/components/breadcrumbs/Breadcrumbs";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { QuestionBox } from "@/common/components/messages/Messages.styles";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { InvoiceStatusChip } from "@/common/components/statuses/InvoiceStatusChip";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import { ReleaseMessagesButton } from "@/contractor/pages/home/release/components/ReleaseMessagesButton";
import {
  InvoiceStatus,
  MessageContextKind,
  OrgLocationRole,
} from "@/generated/graphql";
import { FC, ReactElement, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useInvoiceSequence } from "../../../scanned-invoices/providers/InvoiceSequenceProvider";
import { useInvoiceVerification } from "../../providers/InvoiceVerificationProvider";
import { ConnectInvoiceButton } from "../export/ConnectInvoiceButton";
import { ExportInvoicesButton } from "../export/ExportInvoiceButton";
import { InvoiceSequenceStepper } from "../sequence-stepper/InvoiceSequenceStepper";

export const InvoiceBreadcrumbs: FC = () => {
  const intl = useIntl();
  const { invoice } = useInvoiceVerification();
  const { sequenceActive } = useInvoiceSequence();

  const {
    hasFeatureInConnectedIntegrationType,
    hasFeatureInConnectedSourceSystem,
  } = useIntegrationFeatureRequirement();
  const { hasPermissions: canViewInvoices } = usePermissions([
    Permission.canViewInvoices,
  ]);
  const { viewer } = useUser();
  const isForeman = useMemo(
    () =>
      viewer?.locationRoles.every(
        (s) => s.role === OrgLocationRole.LocationForeman,
      ),
    [viewer],
  );

  const breadcrumbs = useMemo(() => {
    return [
      {
        text: intl.$t({ id: "INVOICES" }),
        link: canViewInvoices && !isForeman ? routes.invoices : undefined,
        id: "invoices",
      },
      {
        text: intl.$t(
          { id: "VENDOR_WITH_INVOICE_NUMBER" },
          {
            vendor: invoice?.release?.sellerOrgLocation?.org.name,
            invoiceNumber: invoice?.number,
          },
        ),
        id: "invoices",
      },
    ] as BreadcrumbItem[];
  }, [
    canViewInvoices,
    intl,
    invoice?.number,
    invoice?.release?.sellerOrgLocation?.org.name,
    isForeman,
  ]);

  const actions = useMemo(() => {
    const bActions = [];

    if (invoice?.release && invoice?.release?.sellerOrgLocation?.org) {
      bActions.push(
        <ReleaseMessagesButton
          key="messages"
          release={invoice?.release}
          org={invoice?.release?.sellerOrgLocation?.org}
          text={
            <QuestionBox>
              <Tooltip
                id="invoice-messages"
                element={
                  <MessageButton
                    id={invoice.release.id}
                    kind={MessageContextKind.Release}
                  />
                }
              >
                <FormattedMessage id="MESSENGER" />
              </Tooltip>
            </QuestionBox>
          }
        />,
      );
    }
    if (
      (invoice?.status === InvoiceStatus.Approved ||
        invoice?.status === InvoiceStatus.Paid) &&
      invoice.permissions.export
    ) {
      if (
        hasFeatureInConnectedIntegrationType(
          IntegrationFeature.InvoiceFileExports,
        )
      ) {
        bActions.push(
          <ExportInvoicesButton key="export-invoices" invoiceId={invoice.id} />,
        );
      } else if (
        hasFeatureInConnectedSourceSystem(IntegrationFeature.InvoiceWrites) ||
        hasFeatureInConnectedSourceSystem(IntegrationFeature.InvoiceFileExports)
      ) {
        bActions.push(
          <ConnectInvoiceButton key="connect-invoice" invoice={invoice} />,
        );
      }
    }
    if (sequenceActive) {
      bActions.push(<InvoiceSequenceStepper key="invoice-sequence-stepper" />);
    }

    return bActions;
  }, [
    hasFeatureInConnectedIntegrationType,
    hasFeatureInConnectedSourceSystem,
    invoice,
    sequenceActive,
  ]);

  const appendedItems = useMemo(() => {
    const items = [] as ReactElement[];
    if (invoice) {
      items.push(
        <InvoiceStatusChip
          key="status"
          status={invoice?.status}
          type="breadcrumb"
        />,
      );
    }
    if (invoice?.archivedAt) {
      items.push(
        <FormattedMessage
          key="archived"
          id="ARCHIVED"
          tagName={ArchivedContainer}
        />,
      );
    }
    return items;
  }, [invoice]);

  return (
    <Breadcrumbs
      classes={{ text: "flex items-center" }}
      items={breadcrumbs}
      appendItems={appendedItems}
      actions={actions}
    />
  );
};
