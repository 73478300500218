import { useCategories } from "@/common/components/category-filter/CategoriesProvider";
import { ProductSkuSwitch } from "@/common/components/skus-products-switch/ProductSkuSwitch";
import tw from "tailwind-styled-components";

import { CategoryFilter } from "@/common/components/category-filter/CategoryFilter";
import { getCategoryNameById } from "@/common/components/category-filter/utils";
import { useSkusAndProducts } from "@/common/components/product-facets-filter/SkusAndProductsProvider";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import "@szhsin/react-menu/dist/index.css";
import { useIntl } from "react-intl";

const MainContainer = tw.div`
  grid bg-white sticky pb-5 w-full gap-5 top-12 z-50
`;

const CategoryListFilterContainer = tw.div`
  grid relative
`;

const WhiteBackground = tw.div`
  bg-white w-full h-full absolute inset-0
`;

const Container = tw.div`
  grid border rounded-xl px-5 py-2.5 w-full h-full border-gray-200 bg-gray-100 z-10
  grid-cols-[auto_1fr_auto] gap-5 place-items-center
`;

const SearchInputContainer = tw.div`
  w-full max-w-3xl
`;

export const CategoryListFilter = () => {
  const intl = useIntl();
  const { drywallCategories, categoryLevels, categoryId, changeCategory } =
    useCategories();
  const { setSearch } = useSkusAndProducts();

  const setSearchQuery = (searchQuery: string) => {
    if (searchQuery) {
      setSearch({
        query: searchQuery,
        refineToDominantCategory: false,
      });
    } else {
      setSearch(null);
    }
  };

  return (
    <MainContainer>
      <CategoryListFilterContainer>
        <WhiteBackground />
        <Container>
          <CategoryFilter
            categoryId={categoryId}
            categories={drywallCategories}
            categoryLevels={categoryLevels}
            onCategorySelect={changeCategory}
            includeAllCategories
          />
          <SearchInputContainer>
            <SearchInput
              placeHolder={intl.$t(
                {
                  id: categoryId
                    ? "SEARCH_MATERIAL_IN_CATEGORY"
                    : "SEARCH_MATERIAL_ALL_CATEGORIES",
                },
                {
                  category: getCategoryNameById(categoryId, drywallCategories),
                },
              )}
              onChange={setSearchQuery}
            />
          </SearchInputContainer>
          <ProductSkuSwitch />
        </Container>
      </CategoryListFilterContainer>
    </MainContainer>
  );
};
