import { If } from "@/common/components/if/If";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { WarningIconWithDetails } from "./WarningIconWithDetails";

const Container = tw.div`
  relative px-4 h-6 grid grid-cols-auto-1fr gap-2 justify-end font w-full items-center
`;

const LeftSide = tw.div`
  grid grid-flow-col gap-2 
`;

const Name = tw.div`
  text-xs text-right w-full
`;

type Props = {
  expirationDate: number | null | undefined;
};

export const ExpirationRow = ({ expirationDate }: Props) => {
  const expirationDateFormatted = useMemo(
    () =>
      expirationDate ? new Date(expirationDate).toLocaleDateString() : null,
    [expirationDate],
  );
  return (
    <Container>
      <If isTrue={expirationDateFormatted}>
        <LeftSide>
          <WarningIconWithDetails
            warning={
              <FormattedMessage
                id="EXPIRATION_DATE_WITH_VALUE"
                values={{ date: expirationDateFormatted }}
              />
            }
          />
        </LeftSide>
        <Name>{expirationDateFormatted}</Name>
      </If>
      <If isTrue={!expirationDateFormatted}>
        <LeftSide />
        <FormattedMessage id="N_A" tagName={Name} />
      </If>
    </Container>
  );
};
