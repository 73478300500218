import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { ExternalPoTypeModal } from "../components/external-po-type/ExternalPoTypeModal";
import { ExternalPoType, useExternalPOTypes } from "./ExternalPOTypesProvider";

type ProviderContextType = {
  openModal: () => void;
  closeModal: () => void;
  open: boolean;
  pages: WizardModalPage[];
  poTypeName: string | null;
  setPoTypeName: (value: string | null) => void;
  existingPoType: ExternalPoType | undefined;
};

const ProviderContext = createContext<ProviderContextType>({
  openModal: NoFunction,
  closeModal: NoFunction,
  pages: [],
  open: false,
  poTypeName: null,
  setPoTypeName: NoFunction,
  existingPoType: undefined,
});

export const ExternalPoTypeProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const { externalPoTypes } = useExternalPOTypes();
  const [poTypeName, setPoTypeName] = useState<string | null>(null);

  const openModal = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const cancelModal = useCallback(() => {
    setOpen(false);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  const pages: WizardModalPage[] = useMemo(
    () => [
      {
        title: intl.$t({ id: "PO_TYPE" }),
        pages: [
          {
            component: (
              <ExternalPoTypeModal
                onChange={(item) => setPoTypeName(item?.name || "")}
              />
            ),
            onCloseClick: cancelModal,
          },
        ],
      },
    ],
    [cancelModal, intl],
  );

  return (
    <ProviderContext.Provider
      value={{
        openModal,
        closeModal,
        pages,
        open,
        poTypeName,
        setPoTypeName,
        existingPoType: externalPoTypes.find(
          (e) => e.name.toLowerCase() === poTypeName?.toLowerCase(),
        ),
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useExternalPoType = (): ProviderContextType =>
  useContext(ProviderContext);
