import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { Price } from "@/common/components/price/Price";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import { LUMP_SUM_UOM } from "@/common/const";
import {
  Base,
  MdContainer,
  MdFixedContainer,
  SmContainer,
  SmWideContainer,
  SmdFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ReleaseAssignCostCodes } from "@/contractor/pages/home/buyout-releases/components/releases-list/release-view/ReleaseAssignCostCodes";
import { ReleaseCostCodeDropdown } from "@/contractor/pages/home/buyout-releases/components/releases-list/release-view/ReleaseCostCodeDropdown";
import { ReleaseItemMaterial } from "@/contractor/pages/home/buyout-releases/components/releases-list/release-view/ReleaseItemMaterial";
import {
  DeliverySlipStatus,
  EstimatedItemFieldsFragment,
  ReleaseFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { GroupByCostCodeToggle } from "../../components/group-by-costcode/GroupByCostCodeToggle";
import { usePriceCalculation } from "../../hooks/usePriceCalculation";
import { useReleaseItemsZoneProvider } from "../../providers/ReleaseItemsZonesProvider";
import { ExpandedReleaseItem } from "../../providers/ReleaseProvider";
import { ReleaseItemExtPriceGroup } from "../../release-items-list/components/ReleaseItemExtPriceGroup";
import { ReleaseItemRentalEndDate } from "../../release-items-list/components/ReleaseItemRentalEndDate";
import { ReleaseItemUnitPrice } from "../../release-items-list/components/ReleaseItemUnitPrice";
import { ReleaseTagsPicker } from "../../release-items-list/components/ReleaseTagsPicker";
import { filterByMaterialNamePriceAndQty } from "../../utils/filters/filterByMaterialNamePriceAndQty";
import { isTimeUOM } from "../../utils/releaseItemRental";
import { ViewResolution } from "../specify-details/components/issue-resolution/ViewResolution";
import { AddItemIssue } from "../specify-details/components/report-issue/AddItemIssue";
import { ReleaseEmptyFooter } from "./release-footer/ReleaseEmptyFooter";
import { ReleaseExtPriceFooter } from "./release-footer/ReleaseExtPriceFooter";
import { ReleaseInvoicedFooter } from "./release-footer/ReleaseInvoicedFooter";
import { ReleaseReceivedFooter } from "./release-footer/ReleaseReceivedFooter";
import { ReleaseTaxFooter } from "./release-footer/ReleaseTaxFooter";
import { ReleaseTitleColFooter } from "./release-footer/ReleaseTitleColFooter";
import { ReleaseItemInvoicedSoFarSidePanel } from "./ReleaseItemInvoicedSoFarSidePanel";
import { ReleaseItemReceivedSoFarSidePanel } from "./ReleaseItemReceivedSoFarSidePanel";

const RequestedItemsContainer = tw(Base)`col-span-full flex-1`;
const FloatingText = tw.span`absolute w-96 text-center text-xs text-gray-600`;

export const useReleaseDetailsConfiguration = (
  release?: ReleaseFieldsFragment | null,
) => {
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);
  const { calcExtPrice } = usePriceCalculation();
  const { hasPhaseCodes } = useOrgSettings();
  const { groupedByCostCode } = useReleaseItemsZoneProvider();

  const showRentalDate = !!release?.items.find((item) => isTimeUOM(item.uom));
  const showFooter =
    release?.status &&
    [
      ReleaseStatus.Received,
      ReleaseStatus.PartiallyReceived,
      ReleaseStatus.Requested,
      ReleaseStatus.Scheduled,
    ].includes(release.status);

  const configuration: Array<
    GridCol<ExpandedReleaseItem, EstimatedItemFieldsFragment>
  > = useMemo(() => {
    return [
      {
        wrapper: RequestedItemsContainer,
        item: ({ item, count }) => (
          <ReleaseItemMaterial
            count={count}
            item={item}
            projectId={release?.project?.id}
          />
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) => (
          <ProductCategory
            type={
              hasPhaseCodes
                ? ProductCategoryType.PhaseCode
                : ProductCategoryType.CostCode
            }
            category={category}
            items={category.items.length}
            projectId={release?.project?.id}
          />
        ),
        header: (
          <>
            <FormattedMessage id="ITEMS_IN_RELEASES" />
            <GroupByCostCodeToggle />
          </>
        ),
        searchItemFn: filterByMaterialNamePriceAndQty,
        footer: showFooter && <ReleaseTaxFooter />,
      },
      {
        subgroup: (category) => (
          <If isTrue={!hasPhaseCodes}>
            <ReleaseAssignCostCodes
              category={category}
              status={release?.status}
            />
          </If>
        ),
        item: ({ item }) => (
          <ReleaseCostCodeDropdown
            releaseItemId={item.id}
            readonly={!!item.costCode || !item.isIncluded}
          />
        ),
        hidden: !groupedByCostCode,
        wrapper: MdContainer,
        footer: showFooter && <ReleaseEmptyFooter Wrapper={MdContainer} />,
      },
      {
        wrapper: MdFixedContainer,
        header: <FormattedMessage id="TAGS" />,
        item: ({ item }) => {
          return <ReleaseTagsPicker item={item} visibleTags={4} readonly />;
        },
        footer: showFooter && <ReleaseEmptyFooter Wrapper={MdFixedContainer} />,
      },
      {
        wrapper: MdFixedContainer,
        item: ({ item }) => {
          if (isLumpSumItem(item)) {
            return <FloatingText>{LUMP_SUM_UOM}</FloatingText>;
          }
          return item.isIncluded ? (
            <ValueUnit
              value={item.quantityDecimal}
              uom={item.uom || item.projectItem?.estimateUom}
            />
          ) : (
            <FormattedMessage
              id="NOT_INCLUDED_BY_VENDOR"
              tagName={FloatingText}
            />
          );
        },
        position: "center",
        header: <FormattedMessage id="RELEASE_QUANTITY" />,
        footer:
          showFooter &&
          ([ReleaseStatus.Received, ReleaseStatus.PartiallyReceived].includes(
            release.status,
          ) ? (
            <ReleaseTitleColFooter />
          ) : (
            <ReleaseEmptyFooter Wrapper={MdFixedContainer} />
          )),
      },
      {
        wrapper: MdFixedContainer,
        position: "center",
        header: <FormattedMessage id="ORDER_RECEIVED_SO_FAR" />,
        item: ({ item }) => (
          <ReleaseItemReceivedSoFarSidePanel
            item={item}
            releaseId={release?.id}
          />
        ),
        hidden:
          !checkReleaseStatus(release, [
            ReleaseStatus.PartiallyReceived,
            ReleaseStatus.Received,
          ]) ||
          release?.deliverySlips.filter(
            (slip) =>
              !slip.archivedAt && slip.status === DeliverySlipStatus.Processed,
          ).length === 0,
        footer: showFooter && (
          <ReleaseReceivedFooter Wrapper={MdFixedContainer} />
        ),
      },
      {
        wrapper: MdFixedContainer,
        position: "center",
        header: <FormattedMessage id="ORDER_ITEM_INVOICED" />,
        item: ({ item }) => (
          <ReleaseItemInvoicedSoFarSidePanel
            item={item}
            releaseId={release?.id}
          />
        ),
        hidden: !checkReleaseStatus(release, [
          ReleaseStatus.PartiallyReceived,
          ReleaseStatus.Received,
        ]),
        footer: showFooter && (
          <ReleaseInvoicedFooter Wrapper={MdFixedContainer} />
        ),
      },
      {
        wrapper: SmContainer,
        item: ({ item, index }) => {
          if (isLumpSumItem(item)) {
            return <NotNullableRenderer />;
          }
          return item.isIncluded ? (
            <ReleaseItemUnitPrice
              item={item}
              release={release}
              index={index}
              readonly
            />
          ) : null;
        },
        header: <FormattedMessage id="UNIT_PRICE" />,
        position: "center",
        hidden: !hasPermissions,
        footer:
          showFooter &&
          ([ReleaseStatus.Received, ReleaseStatus.PartiallyReceived].includes(
            release.status,
          ) ? (
            <ReleaseEmptyFooter Wrapper={SmContainer} />
          ) : (
            <ReleaseTitleColFooter />
          )),
      },
      {
        header: <FormattedMessage id="EXT_PRICE" />,
        wrapper: SmWideContainer,
        position: "center",
        item: ({ item }) => (
          <If isTrue={item.isIncluded}>
            <NotNullableRenderer value={item.unitPrice !== null}>
              <Price
                className="text-xs"
                price={calcExtPrice(item.quantityDecimal, item.unitPrice)}
              />
            </NotNullableRenderer>
          </If>
        ),
        subgroup: (category) => (
          <ReleaseItemExtPriceGroup items={category.items} />
        ),
        footer: showFooter && (
          <ReleaseExtPriceFooter Wrapper={SmWideContainer} />
        ),
        hidden: !hasPermissions,
      },
      {
        wrapper: SmdFixedContainer,
        item: ({ item }) =>
          item.isIncluded ? (
            item.issues?.[0]?.resolution ? (
              <ViewResolution item={item} />
            ) : (
              <AddItemIssue item={item} />
            )
          ) : null,
        header: <FormattedMessage id="DELIVERY_ISSUES" />,
        hidden:
          showFooter &&
          ![ReleaseStatus.Received, ReleaseStatus.PartiallyReceived].includes(
            release.status,
          ),
        footer: showFooter && (
          <ReleaseEmptyFooter Wrapper={SmdFixedContainer} />
        ),
      },
      {
        header: <FormattedMessage id="RENTAL_END_DATE" />,
        position: "center",
        hidden: !showRentalDate,
        wrapper: MdContainer,
        item: ({ item }) => (
          <ReleaseItemRentalEndDate item={item} release={release} />
        ),
        footer: showFooter && <ReleaseEmptyFooter Wrapper={MdContainer} />,
      },
    ];
  }, [
    calcExtPrice,
    groupedByCostCode,
    hasPermissions,
    hasPhaseCodes,
    release,
    showRentalDate,
    showFooter,
  ]);

  return configuration;
};
