import { If } from "@/common/components/if/If";
import { Select } from "@/common/components/select/components/single/Select";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import {
  VendorPickerCustomRender,
  vendorLabelFormatter,
} from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useUser } from "@/common/providers/UserProvider";
import { getVendorContacts } from "@/common/utils/getVendorContacts";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import {
  AuthorizationStatus,
  ReleaseStatus,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  ReleaseErrorType,
  useReleaseActions,
} from "../../providers/ReleaseActionsProvider";
import { useRelease } from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";

const VendorItem = tw.div`
  w-full py-[6px] grid grid-flow-col gap-x-2 justify-start items-center
`;

export const ReleaseVendorSelector: FC = () => {
  const intl = useIntl();
  const { release } = useRelease();
  const { viewer } = useUser();
  const { vendors, findOrderTypeByLocationId } = useVendors();
  const { updateRelease } = useReleaseUpdate();
  const { vendorId, setVendorId, inputErrors } = useReleaseActions();
  const { setGlobalVendorId } = useVendorPrices();

  const update = async () => {
    if (release) {
      const input: UpdateContractorReleaseInput = {
        releaseId: release?.id,
        version: release?.version,
      };

      if (vendorId) {
        input.sellerOrgLocationId = vendorId;
      }

      const orderTypeId = findOrderTypeByLocationId(vendorId);
      if (orderTypeId) {
        input.typeId = orderTypeId;
      }

      if (setGlobalVendorId) {
        setGlobalVendorId(vendorId);
      }

      await updateRelease(input);
    }
  };

  const canEditVendor = useMemo(
    () =>
      !release?.buyout &&
      (release?.status === ReleaseStatus.Draft ||
        release?.status === ReleaseStatus.AwaitingApproval ||
        release?.status === ReleaseStatus.Rejected) &&
      (release?.permissions.approve || release?.permissions.submit),
    [release],
  );

  const requiresVendor = useMemo(() => {
    if (viewer?.org?.settings?.releaseApproval.enabled) {
      if (
        release?.permissions.submitDirectly === AuthorizationStatus.Authorized
      ) {
        return true;
      }
      return (
        release?.status &&
        ![ReleaseStatus.AwaitingApproval, ReleaseStatus.Draft].includes(
          release?.status,
        )
      );
    } else {
      return true;
    }
  }, [
    release?.permissions.submitDirectly,
    release?.status,
    viewer?.org?.settings?.releaseApproval.enabled,
  ]);

  return (
    <>
      <If isTrue={canEditVendor}>
        <Select
          testId="vendors-selector"
          placeholder={intl.$t({
            id:
              release?.status === ReleaseStatus.AwaitingApproval ||
              release?.permissions.submitDirectly ===
                AuthorizationStatus.Authorized
                ? "VENDOR"
                : "VENDOR_OPTIONAL",
          })}
          options={vendors || []}
          className="w-72"
          value={vendorId}
          onChange={(value) => setVendorId(value as string)}
          customRender={(item) =>
            VendorPickerCustomRender(item, (c) => c.receivesOrderNotifications)
          }
          required={requiresVendor}
          error={inputErrors.includes(ReleaseErrorType.VENDOR)}
          getLabel={(o) =>
            vendorLabelFormatter(
              o.sellerOrgLocation,
              o.contacts.filter((c) => c.receivesOrderNotifications),
            )
          }
          getValue={(option) => option.sellerOrgLocation.id}
          noOptionsText={intl.$t({ id: "NO_VENDORS_FOUND" })}
          onBlur={update}
        />
      </If>
      <If isTrue={!canEditVendor}>
        <VendorItem>
          <VendorLogo
            logoImageUrl={release?.sellerOrgLocation?.org?.logoImageUrl}
            address={release?.sellerOrgLocation?.address}
            contactName={getVendorContacts(
              release?.preferredVendor?.contacts.filter(
                (c) => c.receivesOrderNotifications,
              ),
            )}
            orgName={release?.sellerOrgLocation?.org.name}
          />
        </VendorItem>
      </If>
    </>
  );
};
