import { Multiselect } from "@/common/components/select/components/multiple/Multiselect";
import { useSpendingReport } from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { ZoneFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";

export const SpendingReportZonesSelector: FC<{
  options: (ZoneFieldsFragment & { disabled?: boolean })[];
  disabled?: boolean;
}> = ({ options, disabled }) => {
  const intl = useIntl();
  const { selectedZones, setSelectedZones } = useSpendingReport();

  return (
    <Multiselect
      placeholder={intl.$t({
        id: "ZONES_PLURAL",
      })}
      className="w-full"
      options={options.sort((a, b) => a.name.localeCompare(b.name))}
      values={selectedZones}
      onMultipleChange={(zones) => setSelectedZones(zones || [])}
      getLabel={(option) => option.name}
      getValue={(option) => option.id}
      disableCloseOnSelect
      getOptionDisabled={(zone) => !!zone.disabled}
      disabled={disabled}
      includeCheckbox
      limitTags={1}
      chipSize="small"
      moreItems={options
        .filter((c) =>
          selectedZones?.slice(1, selectedZones.length).includes(c.id),
        )
        .map((c) => c.name)}
      required
      noWrap
    />
  );
};
