import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import { DEFAULT_ADDRESS_COUNTRY, DEFAULT_ADDRESS_STATE } from "@/common/const";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { strongify } from "@/common/utils/htmlUtils";
import {
  AssetContext,
  MapNodeInput,
  OrgPreferredVendorsFieldsFragment,
  SourceSystem,
} from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { Container, PopoverText } from "../../Vendors.styles";
import { useVendors } from "../../hooks/useVendors";
import { useExternalVendorMapping } from "../../providers/ExternalVendorMappingProvider";
import { useOrgs } from "../../providers/OrgsProvider";
import { PreferredVendorDetailsSection } from "./PreferredVendorDetailsSection";
import { NEW_BRANCH_ID } from "./VendorBranchSection";

export const NEW_VENDOR_ID = "-1";

export type VendorFormType = {
  preferredVendor: string;
  externalCode: string;
  editVendorLocation: {
    name: string;
    addressLine1: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    phone: string;
  } | null;
  newVendor: {
    name: string;
    location: {
      name: string;
      addressLine1: string;
      city: string;
      state: string;
      postalCode: string;
      country: string;
      phone: string;
    };
  };
  location: string;
  defaultOrderTypeId: string;
  createNewBranch: boolean;
  state: string;
  country: string;
  taxExempt: boolean;
  addVendorContacts: boolean;
  contacts: {
    email: string;
    cellPhone: string;
    name: string;
    receivesInvoiceNotifications: boolean;
    receivesQuoteNotifications: boolean;
    receivesBuyoutNotifications: boolean;
    receivesOrderNotifications: boolean;
  }[];
  mappings: {
    externalId: string;
    sourceSystem: SourceSystem;
  }[];
};

type Props = {
  onClose: () => void;
  title: string;
  vendor?: OrgPreferredVendorsFieldsFragment;
};

const OverlayPanelStyled = tw(OverlayPanel)`px-0 pt-0`;
const Form = tw.form`h-full`;
const TitleContainer = tw.div`flex gap-2 items-center`;

export const VendorForm: FC<Props> = ({ onClose, title, vendor }) => {
  const intl = useIntl();
  const {
    createVendor,
    updateVendor,
    loading,
    createVendorOrg,
    addVendorLocation,
    updateVendorLocation,
  } = useVendors();
  const { setSuccessAlert } = useSnackbar();
  const { refetchOrgs } = useOrgs();
  const { sourceSystem, selectedExternalVendorData } =
    useExternalVendorMapping();

  const [editingPreferredVendorLocation, setEditingPreferredVendorLocation] =
    useState(false);

  const methods = useForm<VendorFormType>({
    defaultValues: {
      preferredVendor: vendor?.sellerOrgLocation.org.id || "",
      location: vendor?.sellerOrgLocation?.id || "",
      state: vendor?.sellerOrgLocation.address.state || DEFAULT_ADDRESS_STATE,
      country:
        vendor?.sellerOrgLocation.address.country || DEFAULT_ADDRESS_COUNTRY,
      externalCode: vendor?.externalCode || "",
      taxExempt: vendor?.taxExempt || false,
      addVendorContacts: vendor?.contacts
        ? (vendor?.contacts ?? []).length > 0
        : true,
      defaultOrderTypeId: vendor?.defaultReleaseType?.id,
      contacts: vendor?.contacts.map((c) => {
        return {
          email: c.email,
          cellPhone: c.cellPhone,
          name: c.name,
          receivesInvoiceNotifications: c.receivesInvoiceNotifications,
          receivesQuoteNotifications: c.receivesQuoteNotifications,
          receivesBuyoutNotifications: c.receivesBuyoutNotifications,
          receivesOrderNotifications: c.receivesOrderNotifications,
        };
      }) || [
        {
          email: "",
          cellPhone: "",
          name: "",
          receivesInvoiceNotifications: true,
          receivesQuoteNotifications: true,
          receivesBuyoutNotifications: true,
          receivesOrderNotifications: true,
        },
      ],
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    methods.setValue(
      "editVendorLocation",
      editingPreferredVendorLocation
        ? {
            name: vendor?.sellerOrgLocation.name || "",
            addressLine1: vendor?.sellerOrgLocation.address.addressLine1 || "",
            city: vendor?.sellerOrgLocation.address.city || "",
            state: vendor?.sellerOrgLocation.address.state || "",
            postalCode: vendor?.sellerOrgLocation.address.postalCode || "",
            country:
              vendor?.sellerOrgLocation.address.country ||
              DEFAULT_ADDRESS_COUNTRY,
            phone: vendor?.sellerOrgLocation.phone || "",
          }
        : null,
    );
  }, [editingPreferredVendorLocation, vendor, methods]);

  useEffect(() => {
    methods.setValue("newVendor", {
      name: "",
      location: {
        name: vendor?.sellerOrgLocation.name || "",
        addressLine1: vendor?.sellerOrgLocation.address.addressLine1 || "",
        city: vendor?.sellerOrgLocation.address.city || "",
        state: vendor?.sellerOrgLocation.address.state || "",
        postalCode: vendor?.sellerOrgLocation.address.postalCode || "",
        country:
          vendor?.sellerOrgLocation.address.country || DEFAULT_ADDRESS_COUNTRY,
        phone: vendor?.sellerOrgLocation.phone || "",
      },
    });
  }, [vendor, methods]);

  useEffect(() => {
    if (
      selectedExternalVendorData &&
      !vendor &&
      (selectedExternalVendorData.email || selectedExternalVendorData.phone)
    ) {
      methods.setValue("contacts", [
        {
          email: selectedExternalVendorData.email ?? "",
          cellPhone: selectedExternalVendorData.phone ?? "",
          name: "",
          receivesInvoiceNotifications: true,
          receivesBuyoutNotifications: true,
          receivesOrderNotifications: true,
          receivesQuoteNotifications: true,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExternalVendorData, methods]);

  const addVendorContacts = methods.watch("addVendorContacts");
  useEffect(() => {
    methods.setValue(
      "contacts",
      addVendorContacts
        ? vendor?.contacts.map((c) => {
            return {
              email: c.email,
              cellPhone: c.cellPhone,
              name: c.name,
              receivesInvoiceNotifications: c.receivesInvoiceNotifications,
              receivesQuoteNotifications: c.receivesQuoteNotifications,
              receivesBuyoutNotifications: c.receivesBuyoutNotifications,
              receivesOrderNotifications: c.receivesOrderNotifications,
            };
          }) || [
            {
              email: "",
              cellPhone: "",
              name: "",
              receivesInvoiceNotifications: true,
              receivesQuoteNotifications: true,
              receivesBuyoutNotifications: true,
              receivesOrderNotifications: true,
            },
          ]
        : [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addVendorContacts]);

  const location = methods.watch("location");

  const onSave = useCallback(
    async (values: VendorFormType) => {
      const contacts = values.contacts.map((c) => {
        return {
          ...c,
          receivesInvoiceNotifications: c.receivesInvoiceNotifications || false,
          receivesQuoteNotifications: c.receivesQuoteNotifications || false,
          receivesBuyoutNotifications: c.receivesBuyoutNotifications || false,
          receivesOrderNotifications: c.receivesOrderNotifications || false,
        };
      });
      let result = null;
      let orgLocationName = "";
      if (vendor) {
        if (values.editVendorLocation) {
          await updateVendorLocation({
            id: vendor.sellerOrgLocation.id,
            name: values.editVendorLocation.name,
            phone: values.editVendorLocation.phone,
            address: {
              addressLine1: values.editVendorLocation.addressLine1,
              city: values.editVendorLocation.city,
              country: values.editVendorLocation.country,
              state: values.editVendorLocation.state,
              postalCode: values.editVendorLocation.postalCode,
            },
          });
        }
        result = await updateVendor({
          vendorID: vendor.id,
          externalCode: values.externalCode,
          taxExempt: values.taxExempt,
          contacts,
          defaultReleaseType: values.defaultOrderTypeId,
        });
        orgLocationName = vendor.sellerOrgLocation.name;
      } else {
        let mappings: MapNodeInput[] | undefined;

        if (values.mappings) {
          mappings = values.mappings;
        }

        if (!!sourceSystem && selectedExternalVendorData) {
          mappings = [
            {
              sourceSystem,
              externalId: selectedExternalVendorData.externalId,
            },
          ];
        }

        if (values.preferredVendor === NEW_VENDOR_ID) {
          const distributor = await createVendorOrg({
            name: values.newVendor.name,
          });

          if (!distributor) {
            return;
          }

          const newLocation = await addVendorLocation({
            name: values.newVendor.location.name,
            orgId: distributor.id,
            address: {
              addressLine1: values.newVendor.location.addressLine1,
              city: values.newVendor.location.city,
              state: values.newVendor.location.state || values.state,
              postalCode: values.newVendor.location.postalCode,
              country: values.newVendor.location.country,
            },
            phone: values.newVendor.location.phone,
          });

          await refetchOrgs();

          if (!newLocation) {
            return;
          }

          result = await createVendor({
            contacts,
            externalCode: values.externalCode,
            taxExempt: values.taxExempt,
            orgLocationID: newLocation.id,
            mappings,
            defaultReleaseType: values.defaultOrderTypeId,
          });
          orgLocationName = result?.sellerOrgLocation.name || "";
        } else {
          let newLocation;
          if (values.location === NEW_BRANCH_ID) {
            newLocation = await addVendorLocation({
              name: values.newVendor.location.name,
              orgId: values.preferredVendor,
              address: {
                addressLine1: values.newVendor.location.addressLine1,
                city: values.newVendor.location.city,
                state: values.newVendor.location.state || values.state,
                postalCode: values.newVendor.location.postalCode,
                country: values.newVendor.location.country || values.country,
              },
              phone: values.newVendor.location.phone,
            });

            await refetchOrgs();
          }

          result = await createVendor({
            contacts,
            orgLocationID: newLocation?.id || location,
            taxExempt: values.taxExempt,
            mappings,
            defaultReleaseType: values.defaultOrderTypeId,
          });
          orgLocationName = result?.sellerOrgLocation.name || "";
        }
      }
      if (!result) {
        return;
      }
      setSuccessAlert(
        intl.$t(
          { id: vendor ? "VENDOR_UPDATE_SUCCESS" : "VENDOR_CREATE_SUCCESS" },
          {
            name: strongify(orgLocationName),
          },
        ),
      );
      onClose();
    },
    [
      vendor,
      setSuccessAlert,
      intl,
      onClose,
      updateVendor,
      updateVendorLocation,
      sourceSystem,
      selectedExternalVendorData,
      createVendorOrg,
      addVendorLocation,
      refetchOrgs,
      createVendor,
      location,
    ],
  );

  return (
    <UploadAssetProvider context={AssetContext.OrgImage}>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSave)}>
          <OverlayPanelStyled
            title={
              <TitleContainer>
                {title}
                <Tooltip
                  id="add-preferred-vendor-information"
                  position="bottom"
                  element={<InfoOutlined />}
                >
                  <FormattedMessage
                    id="ADD_PREFERRED_VENDOR_INFORMATION"
                    tagName={PopoverText}
                  />
                </Tooltip>
              </TitleContainer>
            }
            onCancel={onClose}
            saving={loading}
            onSave={methods.handleSubmit(onSave)}
          >
            <Container>
              <PreferredVendorDetailsSection
                vendor={vendor}
                editVendorLocation={{
                  editing: editingPreferredVendorLocation,
                  setEditing: setEditingPreferredVendorLocation,
                }}
              />
            </Container>
          </OverlayPanelStyled>
        </Form>
      </FormProvider>
    </UploadAssetProvider>
  );
};
