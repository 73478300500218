import { Info } from "@mui/icons-material";
import { Chip } from "@mui/material";
import tw from "tailwind-styled-components";

export const Container = tw.form`w-full flex flex-col rounded-lg bg-gray-100 p-6 md:p-12`;

export const Row = tw.div`grid gap-1 grid-cols-[_13rem_13.5rem_1fr]`;

export const ButtonContainer = tw.div`flex justify-end gap-4 h-8 duration-300 transition-opacity mt-2 mb-2`;

export const CellWrapper = tw.div`
  flex flex-col items-start
  max-w-[15.5rem] p-4
`;

export const LabelCellWrapper = tw(CellWrapper)`
  flex flex-col items-start
  max-w-[15.5rem] p-4
  border-t border-dashed border-gray-400 overflow-hidden
`;

export const InputsCellWrapper = tw(CellWrapper)`
  flex flex-col items-start
  max-w-[15.5rem] p-4
  border-t border-dashed border-gray-400 border-r
  bg-white
`;

export const TagInputsCellWrapper = tw(CellWrapper)`
  flex flex-col items-start
  max-w-[15.5rem] p-4
  border-t border-dashed border-gray-400 border-r
`;

export const Title = tw.h3`
  text-black
  font-roboto font-medium text-lg
  h-13 p-2
`;

export const SubTitle = tw.h4`
  text-black
  font-roboto font-medium text-lg
  flex items-center
  h-12 p-2
  border-t border-gray-400 border-dashed
`;

export const Cell = tw.div`
  flex flex-col items-start self-stretch
  p-2 h-12
`;

export const CellLabel = tw.div`
  flex flex-col items-end justify-center self-stretch
  p-2 h-12 pt-4
`;

export const CellLabelCostCode = tw.div`
  flex flex-row items-center self-stretch p-2 h-12 capitalize justify-between 
`;

export const CellTotal = tw.div`
  flex flex-col items-start self-stretch
  p-2 h-12
  text-gray-600
  justify-center
`;

export const RemoveCostCodeButton = tw.button`
  inline-block
  text-right
  text-blue-500
  font-roboto font-medium text-base
  items-center gap-2
`;

export const AddCostCodeButton = tw.button`
  text-right
  text-blue-500
  font-roboto font-medium text-base
  flex items-center gap-2
`;

export const ValueCurrencyWrapper = tw.div`flex flex-row items-center text-center py-2 px-4 w-52`;

export const SwitchWrapper = tw.div`flex flex-row items-center p-2 gap-3 text-sm`;

export const ZoneChip = tw(Chip)`self-center w-full max-w-[12.5rem]`;

export const CostCodesWrapper = tw.div`flex flex-row gap-0 min-w-0 bg-white`;

export const ZonesWrapper = tw.div`flex flex-row gap-0 overflow-x-auto`;

export const Spacer = tw.div`h-8`;

export const CostCodeLabel = tw.div`whitespace-normal text-sm `;

export const TagInputsWrapper = tw.div`rounded-b-lg bg-white`;

export const InfoIcon = tw(Info)`text-gray-600 text-2xl`;

export const EmptyInfoWrapper = tw.div`flex flex-row items-center justify-center gap-2 text-gray-600 text-xs`;

export const SubtitleContainer = tw.div``;

export const TitleContainer = tw.div<{
  $span: boolean;
}>`flex col-span-2
${({ $span }) => ($span ? `flex-col` : `items-center`)}`;
