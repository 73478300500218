import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { Progress } from "@/common/components/progress/Progress";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import {
  ExportBatchProvider,
  useExportBatch,
} from "@/common/providers/ExportBatchProvider";
import { ConnectionMode } from "@/contractor/pages/admin/integrations/components/common/ConnectionMode";
import { InvoiceConnectionOptions } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/export/InvoiceConnectionOptions";
import { BatchType } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { FormattedNumber, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  ExportCompleted,
  ExportType,
} from "../../../../../../../../common/components/export-completed/ExportCompleted";
import {
  ExportReceiptProvider,
  useExportReceipt,
} from "../../providers/ExportReceiptProvider";
import {
  ReceiptsByIdsProvider,
  useReceiptsByIds,
} from "../../providers/ReceiptsByIdsProvider";
import { ReceiptAgaveConnections } from "./ReceiptAgaveConnections";
const OverlayPanelStyled = tw(OverlayPanel)`justify-between gap-3`;
const CardList = tw.div`flex flex-col gap-3`;
const PanelTitle = tw.div`flex flex-row gap-1 items-center`;

type Props = {
  onClose: () => void;
  receiptIds: string[];
};

const ConnectReceiptsPanelWithProvider: FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const { receiptIds, loading, validatedReceipts } = useReceiptsByIds();
  const receiptsToExportCount = useMemo(
    () => validatedReceipts.filter((i) => i.validated).length,
    [validatedReceipts],
  );
  const { linking, linkReceipts } = useExportReceipt();
  const { openDialog } = useDialog();
  const { newBatch, externalBatch } = useExportBatch();

  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const onSave = useCallback(async () => {
    const linkingResult = await linkReceipts();
    if (!linkingResult?.success) {
      return;
    }
    openDialog({
      content: (
        <ExportCompleted
          count={receiptsToExportCount}
          type={ExportType.INVOICE}
          batch={linkingResult.batch}
        />
      ),
      closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      hideTitle: true,
    });
    onClose();
  }, [openDialog, receiptsToExportCount, onClose, linkReceipts]);

  const disableSave = useMemo(() => {
    if (
      hasFeatureInConnectedSourceSystem(IntegrationFeature.InvoiceBatching) &&
      !newBatch &&
      !externalBatch
    ) {
      return true;
    }
    return (
      receiptsToExportCount === 0 || validatedReceipts.some((v) => v.loading)
    );
  }, [
    hasFeatureInConnectedSourceSystem,
    newBatch,
    externalBatch,
    receiptsToExportCount,
    validatedReceipts,
  ]);

  return (
    <OverlayPanelStyled
      title={
        <PanelTitle>
          {intl.$t({ id: "CONNECT_RECEIPT" })}{" "}
          <InfoTooltip
            message={intl.$t({ id: "CONNECT_RECEIPT_TITLE_INFO" })}
            id="title"
            iconClassName="text-white"
          />
        </PanelTitle>
      }
      isLoading={loading}
      onCancel={onClose}
      onSave={onSave}
      saveLabel={intl.$t({ id: "EXPORT" })}
      disableSave={disableSave}
    >
      <CardList>
        <InvoiceConnectionOptions
          invoice={receiptIds[0]}
          mode={ConnectionMode.Connect}
        />
        <ReceiptAgaveConnections receipts={receiptIds} linking={linking} />
      </CardList>
      <If isTrue={linking.linking && receiptIds.length > 1}>
        <Progress
          progress={linking.percentage}
          label={intl.$t(
            { id: "EXPORTING_WITH_VALUE" },
            {
              value: (
                <FormattedNumber
                  value={linking.percentage * 100}
                  maximumFractionDigits={0}
                />
              ),
            },
          )}
        />
      </If>
    </OverlayPanelStyled>
  );
};

export const ConnectReceiptsPanel: FC<Props> = (props) => (
  <ReceiptsByIdsProvider receiptIds={props.receiptIds}>
    <ExportBatchProvider type={BatchType.Invoice}>
      <ExportReceiptProvider>
        <ConnectReceiptsPanelWithProvider {...props} />
      </ExportReceiptProvider>
    </ExportBatchProvider>
  </ReceiptsByIdsProvider>
);
