import { useUser } from "@/common/providers/UserProvider";
import { ErrorOutline } from "@mui/icons-material";
import { InputProps } from "@mui/material";
import React, { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { TextFieldProps } from "../textfield/TextField";
import { TextFieldWithHelperText } from "../textfield/TextFieldWithHelperText";
import { Tooltip } from "../tooltip/Tooltip";
import { ValueCurrency } from "../value-currency/ValueCurrency";
import { NumberFormatCustom } from "./NumberFormatCustom";

const Container = tw.div`
  relative
`;

const ErrorOutlineStyled = tw(ErrorOutline)`
  absolute top-1.5 -left-4 print:hidden text-base
`;

const TextFieldWithHelperTextStyled = tw(TextFieldWithHelperText)<{
  $readonly?: boolean;
}>`
  ${({ $readonly }: { $readonly?: boolean }) =>
    $readonly &&
    "child-first:text-black child-first:bg-transparent child-hover:border-white"}
`;

interface NumberFormatValues {
  floatValue: number | undefined;
  formattedValue: string;
  value: string;
}

export const ReadonlySx = {
  "fieldSet, *:hover fieldset.MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray",
  },
};

export type NumericInputProps = TextFieldProps & {
  prefix?: string;
  suffix?: string;
  isAllowed?: (values: NumberFormatValues) => boolean;
  decimals?: number;
  errorMessage?: string;
  classes?: {
    root?: string;
    container?: string;
  };
  testId?: string;
  customError?: React.JSX.Element;
  hideErrorIcon?: boolean;
  readonly?: boolean;
  fixedDecimalScale?: boolean;
  dynamicDigits?: boolean;
  InputProps?: InputProps;
  includeCurrency?: boolean;
};

export const NumericalInput: FC<NumericInputProps> = ({
  className,
  prefix = "",
  suffix = "",
  inputProps,
  isAllowed,
  error,
  decimals,
  fixedDecimalScale,
  errorMessage,
  classes,
  customError,
  hideErrorIcon,
  readonly,
  dynamicDigits,
  InputProps,
  includeCurrency,
  ...props
}) => {
  const { currencySymbol } = useUser();
  const currencyPrefix = useMemo(
    () => (includeCurrency ? currencySymbol : ""),
    [currencySymbol, includeCurrency],
  );
  return (
    <Container className={classes?.container}>
      {readonly ? (
        <ValueCurrency
          classNames={{ value: className }}
          value={props.value as string}
          fractionDigits={decimals}
          dynamicDigits={dynamicDigits}
        />
      ) : (
        <TextFieldWithHelperTextStyled
          {...props}
          $readonly={!!inputProps?.disabled || readonly}
          className={className}
          inputProps={{
            ...inputProps,
            suffix,
            prefix: currencyPrefix || prefix,
            isAllowed,
            decimals,
            fixedDecimalScale,
            style: {
              ...(inputProps?.disabled && { marginTop: "-10px" }),
              ...inputProps?.style,
            },
          }}
          error={error}
          InputProps={{
            ...InputProps,
            inputComponent: NumberFormatCustom,
          }}
          classes={classes}
        />
      )}
      <If isTrue={error && !hideErrorIcon && errorMessage}>
        <Tooltip
          element={<ErrorOutlineStyled color="error" />}
          id="error-popover"
          position="bottom"
        >
          {error ? errorMessage : null}
        </Tooltip>
      </If>
      <If isTrue={customError}>{customError}</If>
    </Container>
  );
};
