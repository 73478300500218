import { usePagination } from "@/common/components/pagination/PaginationProvider";
import {
  ExternalNotificationFieldsFragment,
  QueryNotificationsFilter,
  useExternalNotificationsQuery,
} from "@/generated/graphql";
import { useEffect, useState } from "react";

export const useExternalNotificationsWithPagination = (
  filter?: QueryNotificationsFilter,
) => {
  const { paginationArgs, setPageInfo, setPage, nextPage, page, firstPage } =
    usePagination();
  const { data, loading, error } = useExternalNotificationsQuery({
    variables: {
      ...paginationArgs,
      filter: {
        projectIds: filter?.projectIds?.length ? filter.projectIds : undefined,
        sellerOrgLocationIds: filter?.sellerOrgLocationIds?.length
          ? filter.sellerOrgLocationIds
          : undefined,
        maxDate: filter?.maxDate ? filter.maxDate : undefined,
        minDate: filter?.minDate ? filter.minDate : undefined,
        releaseId: filter?.releaseId ? filter.releaseId : undefined,
      },
    },
    fetchPolicy: "network-only", // Used for first execution
    nextFetchPolicy: "cache-first",
  });
  const [externalNotifications, setExternalNotifications] = useState<
    ExternalNotificationFieldsFragment[]
  >([]);

  useEffect(() => {
    if (data?.externalNotifications?.pageInfo) {
      setPageInfo({ ...data.externalNotifications.pageInfo });
    }
  }, [data, page, setPageInfo]);

  useEffect(() => {
    if (data?.externalNotifications?.edges) {
      const notifications = data.externalNotifications.edges.map(
        (edge) => edge.node,
      );
      if (page === 0) {
        setExternalNotifications(notifications);
      } else {
        setExternalNotifications([...externalNotifications, ...notifications]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    externalNotifications,
    pagination: paginationArgs,
    loading,
    error,
    totalCount: data?.externalNotifications.totalCount || 0,
    setPage,
    hasNextPage: data?.externalNotifications.pageInfo?.hasNextPage || false,
    loadMore: nextPage,
    firstPage,
  };
};
