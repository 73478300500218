import { useUser } from "@/common/providers/UserProvider";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`italic`;

export const ImpersonateBanner = () => {
  const { viewer, isSystemAdmin } = useUser();

  if (!isSystemAdmin || !viewer?.isExternal) {
    return null;
  }
  return (
    <Container>
      <FormattedMessage
        id="IMPERSONATE_ORG"
        values={{ org: viewer?.org?.name }}
      />
    </Container>
  );
};
