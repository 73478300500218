import { useStartupDataQuery, useUomsQuery } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useUser } from "../providers/UserProvider";
import { useErrorEffect } from "./useErrorEffect";

export const useUomOptions = () => {
  const { isContractor } = useUser();
  const { data, error } = useStartupDataQuery({
    skip: !isContractor,
  });
  const { data: uomData, error: uomError } = useUomsQuery({
    skip: isContractor,
  });

  useErrorEffect(error || uomError);

  const uoms = useMemo(() => {
    return (
      [...(data?.viewer?.org.uoms || uomData?.uoms || [])].sort((a, b) =>
        a.pluralDescription.localeCompare(b.pluralDescription),
      ) || []
    );
  }, [data, uomData]);

  const getUomByName = useCallback(
    (uomText: string) => {
      return uoms.find((option) => {
        const lcUomText =
          typeof uomText === "string" ? uomText?.toLowerCase() || "" : "";
        return (
          option.pluralDescription.toLowerCase() === lcUomText ||
          option.singularDescription?.toLowerCase() === lcUomText ||
          option.mnemonic?.toLowerCase() === lcUomText ||
          option.alternativeMnemonics
            ?.map((m) => m.toLowerCase())
            .includes(lcUomText)
        );
      });
    },
    [uoms],
  );

  return {
    uoms,
    getUomByName,
  };
};
