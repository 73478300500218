import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Loader } from "@/common/components/loader/Loader";
import { DatePicker } from "@/common/components/picker/components/DatePicker";
import { Switch } from "@/common/components/switch/Switch";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { StyledTextField } from "@/contractor/pages/admin/integrations/components/wizard/Wizard.styles";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { BatchType, ExternalBatchFieldsFragment } from "@/generated/graphql";
import { Error, InfoOutlined } from "@mui/icons-material";
import { FC, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ConnectionMode } from "../../../contractor/pages/admin/integrations/components/common/ConnectionMode";
import { useExportBatch } from "../../providers/ExportBatchProvider";
import { useIntegrationFeatureRequirement } from "../integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import {
  BatchNumberAdornment,
  Label,
  Row,
} from "./IntegrationBatchOptions.styles";

type Props = {
  batch?: ExternalBatchFieldsFragment | null;
  mode?: ConnectionMode;
  type: BatchType;
};

export const IntegrationBatchOptions: FC<Props> = ({ batch, mode, type }) => {
  const intl = useIntl();
  const {
    setNewBatch,
    newBatch,
    batchNumber,
    setBatchNumber,
    batchDate,
    setBatchDate,
    loadingExternalBatch,
    externalBatch,
    postAutomatically,
    setPostAutomatically,
  } = useExportBatch();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();
  const { connectedSourceSystem } = useOrgSettingsExtended();

  const disableBatching = useMemo(
    () => mode !== ConnectionMode.Connect,
    [mode],
  );

  const [batchNumberText, setBatchNumberText] = useState<string>(
    batchNumber ?? "",
  );

  const includePostAutomatically = useMemo(
    () => type !== BatchType.PurchaseOrder,
    [type],
  );

  const includeBatching = useMemo(
    () =>
      !includePostAutomatically ||
      !postAutomatically ||
      type === BatchType.PurchaseOrder,
    [postAutomatically, type, includePostAutomatically],
  );

  return (
    <If
      isTrue={
        (hasFeatureInConnectedSourceSystem(IntegrationFeature.POBatching) &&
          (!hasFeatureInConnectedSourceSystem(IntegrationFeature.AutoPostPOs) ||
            !connectedSourceSystem?.autoPostPOs) &&
          type === BatchType.PurchaseOrder) ||
        (hasFeatureInConnectedSourceSystem(
          IntegrationFeature.InvoiceBatching,
        ) &&
          type === BatchType.Invoice)
      }
    >
      <If isTrue={mode === ConnectionMode.Connect}>
        <If isTrue={includePostAutomatically}>
          <Row>
            <FormattedMessage id="POST_AUTOMATICALLY" />
            <Switch
              width={60}
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
              onChange={setPostAutomatically}
              value={postAutomatically}
            />
          </Row>
        </If>
        <If isTrue={includeBatching}>
          <Row>
            <Label>
              <FormattedMessage id="BATCH" />
              <Tooltip
                id="batch"
                element={
                  <LinkLike>
                    <InfoOutlined />
                  </LinkLike>
                }
              >
                <FormattedMessage
                  id={
                    type === BatchType.PurchaseOrder
                      ? "BATCH_INFO_PO"
                      : "BATCH_INFO_INVOICE"
                  }
                />
              </Tooltip>
            </Label>
            <Switch
              width={110}
              offLabel={intl.$t({ id: "EXISTING" })}
              onLabel={intl.$t({ id: "NEW_BATCH" })}
              onChange={setNewBatch}
              value={newBatch}
            />
          </Row>
        </If>
        <If isTrue={includeBatching && (!disableBatching || batch?.number)}>
          <Row>
            <FormattedMessage
              id={
                type === BatchType.Invoice &&
                connectedSourceSystem?.autoPostInvoices
                  ? "TRANSACTION_MONTH"
                  : "BATCH_MONTH"
              }
            />
            <DatePicker
              views={["month", "year"]}
              date={
                disableBatching && batch?.month
                  ? new Date(batch?.month)
                  : batchDate
              }
              onChange={(newValue) => {
                if (newValue) {
                  setBatchDate(newValue);
                }
              }}
              disabled={disableBatching}
              className="bg-white"
            />
          </Row>
        </If>
        <If
          isTrue={
            includeBatching && (!newBatch || (disableBatching && batch?.number))
          }
        >
          <Row>
            <FormattedMessage id="BATCH_NUMBER" />
            <StyledTextField
              value={disableBatching ? batch?.number : batchNumberText}
              size="small"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                setBatchNumberText(ev.target.value)
              }
              onBlur={() => setBatchNumber(batchNumberText)}
              className="w-[231px]"
              required
              label={intl.$t({ id: "BATCH_NUMBER" })}
              type="number"
              error={
                !disableBatching && !externalBatch && !loadingExternalBatch
              }
              disabled={disableBatching}
              InputProps={{
                endAdornment: (
                  <If isTrue={!disableBatching}>
                    <BatchNumberAdornment>
                      <If isTrue={!externalBatch && !loadingExternalBatch}>
                        <Tooltip
                          id="batch-number-error"
                          element={<Error color="error" />}
                        >
                          <FormattedMessage
                            id="BATCH_NUMBER_NOT_FOUND"
                            values={{ sub: (...chunks) => <i>{chunks}</i> }}
                          />
                        </Tooltip>
                      </If>
                      <If isTrue={loadingExternalBatch}>
                        <Loader loading small />
                      </If>
                    </BatchNumberAdornment>
                  </If>
                ),
              }}
            />
          </Row>
        </If>
      </If>
    </If>
  );
};
