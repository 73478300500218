import { Check, Close, EditOutlined, LockOutlined } from "@mui/icons-material";
import { FC, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { LinkLike } from "../link-like/LinkLike";
import { Tooltip } from "../tooltip/Tooltip";

const Actions = tw.div`flex flex-nowrap gap-1 pr-2`;

type Props = {
  extraDetails?: ReactNode;
  readonly: boolean;
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  onClose: () => void;
  updateItem: () => void;
  error?: boolean;
  isLocked?: boolean;
};

export const MaterialViewActions: FC<Props> = ({
  updateItem,
  onClose,
  readonly,
  isEditMode,
  setIsEditMode,
  extraDetails,
  error = false,
  isLocked,
}) => {
  const onCloseHandler = () => {
    setIsEditMode(false);
    onClose();
  };
  const onSave = () => {
    if (error) {
      return;
    }
    updateItem();
    setIsEditMode(false);
  };

  return (
    <Actions>
      <If isTrue={isLocked}>
        <Tooltip
          id="item-lock"
          element={
            <LinkLike>
              <LockOutlined className="text-lg" />
            </LinkLike>
          }
        >
          <FormattedMessage id="ITEM_CANNOT_BE_MODIFIED_EXISTING_INVOICE" />
        </Tooltip>
      </If>
      <If isTrue={!isLocked}>
        {extraDetails}
        <If isTrue={!readonly && !isEditMode}>
          <LinkLike onClick={() => setIsEditMode(true)}>
            <EditOutlined />
          </LinkLike>
        </If>
        <If isTrue={isEditMode}>
          <LinkLike onClick={onCloseHandler}>
            <Close />
          </LinkLike>
          <LinkLike onClick={onSave}>
            <Check />
          </LinkLike>
        </If>
      </If>
    </Actions>
  );
};
