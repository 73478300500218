import { Check, InfoOutlined } from "@mui/icons-material";
import tw from "tailwind-styled-components";

export const Header = tw.div`ml-9`;
export const Row = tw.div`flex flex-row items-center`;
export const StretchRow = tw(Row)`flex-1 justify-between`;
export const ReadonlyField = tw.div`text-xs text-start`;
export const Image = tw.img``;
export const IconView = tw.div`w-6`;
export const InfoOutlineRed = tw(InfoOutlined)`text-red-500`;
export const CheckBlue = tw(Check)`text-blue-500 w-5 h-5`;
export const BlueCircle = tw.div`w-5 h-5 rounded-full flex items-center justify-center bg-blue-500 ml-2`;
export const BlueCircleText = tw.div`text-white text-2xs`;
