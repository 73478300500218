import { If } from "@/common/components/if/If";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { QUERYSTRING } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { routes } from "@/config/routes";
import { QuoteDocumentImport } from "@/contractor/pages/home/common/quote-document/components/QuoteDocumentImport";
import {
  QuoteDocumentProvider,
  useQuoteDocument,
} from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { NoteDocumentImport } from "@/contractor/pages/home/releases/pages/deliveries/components/order-from-note/note-document/components/NoteDocumentImport";
import {
  NoteDocumentProvider,
  useNoteDocument,
} from "@/contractor/pages/home/releases/pages/deliveries/components/order-from-note/note-document/providers/NoteDocumentProvider";
import { useUpdateContractorReleaseMutation } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  createSearchParams,
  generatePath,
  useNavigate,
} from "react-router-dom";
import tw from "tailwind-styled-components";
import { useRelease } from "../../../../providers/ReleaseProvider";
import { ImportMaterialsDrawerTabs } from "./ImportReleaseItemsDrawer";

type ImportReleaseItemsPanelProps = {
  onClose: () => void;
  defaultTab?: string;
};

enum NewOrderType {
  ImportQuote = "quote",
  ImportNote = "note",
}

const GrayInfoIcon = tw(InfoOutlined)`text-gray-500 text-2xl`;
const InfoText = tw.div`
  grid grid-flow-col justify-start place-items-center text-gray-600 text-sm gap-2 items-center
`;

const Container = tw.div`
  grid p-4 gap-4
`;

const ImportReleaseItemsPanelProviders: FC<ImportReleaseItemsPanelProps> = ({
  onClose,
  defaultTab,
}) => {
  const intl = useIntl();
  const [updateContractorRelease, { loading: updating }] =
    useUpdateContractorReleaseMutation();
  const { release } = useRelease();
  const { setError } = useGlobalError();
  const { createQuoteDocumentWithAsset, setAsset: setQuoteAsset } =
    useQuoteDocument();
  const { createNoteDocumentWithAsset, setAsset: setNoteAsset } =
    useNoteDocument();
  const navigate = useNavigate();
  const [view, setView] = useState(
    defaultTab === ImportMaterialsDrawerTabs.Note
      ? NewOrderType.ImportNote
      : NewOrderType.ImportQuote,
  );
  const tabs = useMemo(
    () => [
      {
        label: (
          <FormattedMessage
            id="IMPORT_FROM_VENDOR_QUOTE"
            values={{ br: <br /> }}
          />
        ),
        viewState: NewOrderType.ImportQuote,
      },
      {
        label: (
          <FormattedMessage
            id={
              release?.noteDocument ? "REPLACE_FROM_NOTE" : "IMPORT_FROM_NOTE"
            }
            values={{ br: <br /> }}
          />
        ),
        viewState: NewOrderType.ImportNote,
      },
    ],
    [release?.noteDocument],
  );

  const onTabChange = useCallback(
    (viewState: string) => {
      setView(viewState as NewOrderType);
    },
    [setView],
  );

  const importQuoteDocument = useCallback(async () => {
    if (!release || !release.project) {
      return;
    }

    const document = await createQuoteDocumentWithAsset(
      release.project.location.id,
    );

    if (!document) {
      return;
    }

    try {
      const { errors, data } = await updateContractorRelease({
        variables: {
          input: {
            releaseId: release.id,
            version: release.version,
            quoteDocumentId: document.id,
          },
        },
      });
      setError(errors);
      if (data) {
        onClose();
        navigate(
          generatePath(routes.editDeliveryFromQuote, {
            quoteDocumentId: document.id,
            deliveryId: release.id,
          }),
        );
      }
    } catch (error) {
      setError(error);
      return;
    }
  }, [
    release,
    createQuoteDocumentWithAsset,
    updateContractorRelease,
    setError,
    onClose,
    navigate,
  ]);

  const importNoteDocument = useCallback(async () => {
    if (!release || !release.project) {
      return;
    }

    const document = await createNoteDocumentWithAsset(
      release.project.location.id,
    );

    if (!document) {
      return;
    }

    try {
      const { errors, data } = await updateContractorRelease({
        variables: {
          input: {
            releaseId: release.id,
            version: release.version,
            noteDocumentId: document.id,
          },
        },
      });
      setError(errors);
      if (data) {
        onClose();
        navigate({
          pathname: generatePath(routes.editDeliveryFromNote, {
            noteDocumentId: document.id,
            deliveryId: release.id,
          }),
          search: createSearchParams({
            [QUERYSTRING.REPLACE_DOCUMENT]: release.noteDocument
              ? "true"
              : "false",
          }).toString(),
        });
      }
    } catch (error) {
      setError(error);
    }
  }, [
    release,
    createNoteDocumentWithAsset,
    updateContractorRelease,
    setError,
    onClose,
    navigate,
  ]);

  const importDocument = useCallback(async () => {
    if (view === NewOrderType.ImportQuote) {
      await importQuoteDocument();
    } else {
      await importNoteDocument();
    }
  }, [view, importQuoteDocument, importNoteDocument]);

  return (
    <OverlayPanel
      onSave={importDocument}
      saveLabel={intl.$t({ id: "IMPORT" })}
      title={intl.$t({ id: "IMPORT_ITEMS_FROM_DOCUMENT" })}
      onCancel={onClose}
      className="px-0"
      isLoading={updating}
      tabs={tabs}
      activeTab={view}
      onTabChange={onTabChange}
    >
      <Container>
        <If isTrue={view === NewOrderType.ImportQuote}>
          <InfoText>
            <GrayInfoIcon />
            <FormattedMessage id="IMPORT_ITEMS_FROM_QUOTE_INFO" />
          </InfoText>
          <QuoteDocumentImport onChange={setQuoteAsset} />
        </If>
        <If isTrue={view === NewOrderType.ImportNote}>
          <NoteDocumentImport onChange={setNoteAsset} />
        </If>
      </Container>
    </OverlayPanel>
  );
};

export const ImportReleaseItemsPanel: FC<ImportReleaseItemsPanelProps> = (
  props,
) => (
  <QuoteDocumentProvider>
    <NoteDocumentProvider>
      <ImportReleaseItemsPanelProviders {...props} />
    </NoteDocumentProvider>
  </QuoteDocumentProvider>
);
