import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import Decimal from "decimal.js";
import { useMemo } from "react";
import { useProjectOrderedQuantity } from "../../../../providers/ProjectOrderedQuantityProvider";

export const useSpendingReportTotal = ({
  releaseId,
}: {
  releaseId: string;
}) => {
  const { item } = useProjectOrderedQuantity();
  const { calcExtPrice } = usePriceCalculation();

  const total = useMemo(
    () =>
      item?.elements
        .filter((e) => e.release.id === releaseId)
        .reduce(
          (acc, e) =>
            new Decimal(acc)
              .plus(
                e?.releaseItems.reduce(
                  (acc, i) =>
                    new Decimal(acc)
                      .plus(
                        calcExtPrice(i?.quantityDecimal || 0, i.unitPrice || 0),
                      )
                      .toNumber(),
                  0,
                ) || 0,
              )
              .toNumber(),
          0,
        ) || 0,
    [item?.elements, releaseId, calcExtPrice],
  );

  return { total };
};
