import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { LineItemLabel } from "../../../Vendors.styles";

const PreferredVendorDetailsContainer = tw.div`flex flex-col gap-3`;

export const NewVendorDetailsSection: FC = () => {
  const intl = useIntl();

  return (
    <PreferredVendorDetailsContainer>
      <FormattedMessage id="ADD_NEW_ORG" tagName={LineItemLabel} />
      <TextFieldControlled
        name="newVendor.name"
        size="small"
        label={intl.$t({ id: "VENDOR_NAME" })}
        rules={{ required: true }}
      />
    </PreferredVendorDetailsContainer>
  );
};
