import { Notes } from "@/common/components/notes/Notes";
import { DistributorBuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useDistributorBuyout } from "../providers/DistributorBuyoutProvider";

type Props = {
  item: DistributorBuyoutItemFieldsFragment;
  readonly: boolean;
};

export const BuyoutNotes: FC<Props> = ({ item, readonly }) => {
  const { expandedItems, setExpandedItems } = useDistributorBuyout();

  return (
    <Notes
      expandedItems={expandedItems}
      setExpandedItems={setExpandedItems}
      item={item}
      readonly={readonly}
      hasNotesOrAttachments={!!item.notes || !!item.assets?.length}
    />
  );
};
