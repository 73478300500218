import Decimal from "decimal.js";
import { useMemo } from "react";
import { ExpandedRelease } from "../providers/ReleaseProvider";
import { useReleaseUpdate } from "../providers/ReleaseUpdateProvider";
import { hasUnreleasedQuantity } from "../utils/hasUnreleasedQuantity";

export const useHasPartialSelection = (
  release: ExpandedRelease | null | undefined,
) => {
  const { updates, updatingChanges } = useReleaseUpdate();
  const hasPartialSelection = useMemo(() => {
    const unreleasedItems = (release?.items || []).filter((i) =>
      hasUnreleasedQuantity(i),
    );

    return !unreleasedItems.every(
      (i) =>
        (release?.items.find((ri) => ri.id === i.id) &&
          !updates?.find((u) => u.releaseItemId === i.id)) ||
        updates?.find((u) => u.releaseItemId === i.id)
          ?.receivedQuantityDecimal ===
          new Decimal(i.quantityDecimal || 0)
            .minus(i.receivedQuantityDecimal || 0)
            .toString(),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updates, release?.items, updatingChanges]);

  return {
    hasPartialSelection,
  };
};
