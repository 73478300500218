import { ProjectItemUomPicker } from "@/common/components/project-item-uom-picker/ProjectItemUomPicker";
import { isEmptyString } from "@/common/utils/validationUtils";
import { RfqItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useRfq } from "../../hooks/useRfq";
import { useRfqGroupedItems } from "../../hooks/useRfqGroupedItems";

type Props = {
  item: RfqItemFieldsFragment;
  index?: number;
  readonly?: boolean;
  staticText?: boolean;
  mnemonic?: boolean;
  error?: boolean;
};

export const RfqUomSelector: FC<Props> = ({
  item,
  index,
  staticText = false,
  readonly = false,
  mnemonic = true,
  error = false,
}) => {
  const { updateRfq, rfq } = useRfq();
  const { newRfqItem, setNewRfqItem } = useRfqGroupedItems();
  const saveUom = useCallback(
    async (newValue: string | null) => {
      if (newRfqItem?.isAddMode) {
        setNewRfqItem({
          ...newRfqItem,
          projectItem: {
            ...newRfqItem.projectItem,
            estimateUom: newValue || "",
          },
        });
      } else {
        await updateRfq({
          rfqId: rfq?.id || "",
          updates: [{ rfqItemId: item.id, uom: newValue }],
        });
      }
    },
    [item.id, newRfqItem, rfq?.id, setNewRfqItem, updateRfq],
  );

  return (
    <ProjectItemUomPicker
      index={index}
      item={item.projectItem}
      saveUom={saveUom}
      mnemonic={mnemonic}
      readonly={readonly}
      staticText={staticText}
      error={error && isEmptyString(newRfqItem?.projectItem?.estimateUom || "")}
    />
  );
};
