import { throttle } from "lodash";
import { useEffect, useState } from "react";

export const useScrollPosition = (ref: React.RefObject<HTMLDivElement>) => {
  const [scrollPosition, setScrollPosition] = useState({
    top: 0,
    left: 0,
  });

  useEffect(() => {
    const handleScroll = throttle(() => {
      setScrollPosition({
        top: ref.current?.scrollTop || 0,
        left: ref.current?.scrollLeft || 0,
      });
    }, 300);

    const currentRef = ref.current;
    currentRef?.addEventListener("scroll", handleScroll);
    return () => {
      currentRef?.removeEventListener("scroll", handleScroll);
    };
  }, [ref]);

  return scrollPosition;
};
