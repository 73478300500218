import FileDocumentPlusOutline from "@/assets/icons/file-document-plus-outline.svg?react";
import FileDocumentPlus from "@/assets/icons/file-document-plus.svg?react";
import { LinkLikeText } from "@/common/components/link-like-text/LinkLikeText";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { ReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  item: Pick<ReleaseItemFieldsFragment, "id" | "notes" | "assets">;
  readonly: boolean;
  expandedItems: string[];
  setExpandedItems: (items: string[]) => void;
  defaultValue?: string;
  hasNotesOrAttachments: boolean;
};

const NotesWrapper = tw.div`flex flex-col w-7 h-7 items-center justify-center`;

export const Notes: FC<Props> = ({
  item,
  readonly,
  expandedItems,
  setExpandedItems,
  defaultValue,
  hasNotesOrAttachments,
}) => {
  const { ref, inView } = useInView();

  const disabled = readonly && (item?.assets || []).length === 0 && !item.notes;

  useEffect(() => {
    if (!inView && expandedItems.some((i) => i === item.id)) {
      setExpandedItems([...expandedItems.filter((i) => i !== item.id)]);
    }
  }, [expandedItems, inView, item.id, setExpandedItems]);

  return (
    <>
      {disabled ? (
        <NotNullableRenderer defaultValue={defaultValue} />
      ) : (
        <NotesWrapper>
          <Tooltip
            id="note"
            element={
              <LinkLikeText
                ref={ref}
                onClick={() => {
                  setExpandedItems(
                    expandedItems.some((i) => i === item.id)
                      ? expandedItems.filter((i) => i !== item.id)
                      : [...expandedItems, item.id],
                  );
                }}
              >
                {hasNotesOrAttachments ? (
                  <FileDocumentPlus className="h-5 w-5" />
                ) : (
                  <FileDocumentPlusOutline className="h-5 w-5" />
                )}
              </LinkLikeText>
            }
          >
            <FormattedMessage id="NOTES_AND_ATTACHMENTS" />
          </Tooltip>
        </NotesWrapper>
      )}
    </>
  );
};
