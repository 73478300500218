import {
  BuyoutEvent,
  InvoiceEvent,
  MessageContextKind,
  QuoteEvent,
  ReleaseEvent,
} from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const useNotificationConfig = () => {
  const intl = useIntl();

  const config = useMemo(() => {
    return [
      {
        id: "QUOTES",
        name: intl.$t({ id: "QUOTES" }),
        children: [
          {
            value: QuoteEvent.VendorSubmit,
            name: intl.$t({ id: "NOTIFICATION_QUOTE_VENDOR_SUBMIT" }),
            key: `excludedQuoteEvents.${QuoteEvent.VendorSubmit}`,
          },
          {
            value: QuoteEvent.VendorWithdraw,
            name: intl.$t({ id: "NOTIFICATION_QUOTE_VENDOR_WITHDRAW" }),
            key: `excludedQuoteEvents.${QuoteEvent.VendorWithdraw}`,
          },
          {
            value: MessageContextKind.Quote,
            name: intl.$t({ id: "NOTIFICATION_QUOTE_VENDOR_MESSAGE" }),
            key: `excludedMessageContexts.${MessageContextKind.Quote}`,
          },
        ],
      },
      {
        id: "BUYOUTS",
        name: intl.$t({ id: "BUYOUTS" }),
        children: [
          {
            value: BuyoutEvent.VendorAccept,
            name: intl.$t({ id: "NOTIFICATION_BUYOUT_VENDOR_ACCEPTS" }),
            key: `excludedBuyoutEvents.${BuyoutEvent.VendorAccept}`,
          },
          {
            value: BuyoutEvent.VendorDecline,
            name: intl.$t({ id: "NOTIFICATION_BUYOUT_VENDOR_DECLINES" }),
            key: `excludedBuyoutEvents.${BuyoutEvent.VendorDecline}`,
          },
          {
            value: MessageContextKind.Buyout,
            name: intl.$t({ id: "NOTIFICATION_BUYOUT_VENDOR_MESSAGE" }),
            key: `excludedMessageContexts.${MessageContextKind.Buyout}`,
          },
        ],
      },
      {
        id: "ORDERS",
        name: intl.$t({ id: "ORDERS" }),
        children: [
          {
            value: ReleaseEvent.RequestedInternal,
            name: intl.$t({ id: "NOTIFICATION_RELEASE_RELEASE" }),
            key: `excludedReleaseEvents.${ReleaseEvent.RequestedInternal}`,
          },
          {
            value: ReleaseEvent.ApprovalRequested,
            name: intl.$t({ id: "NOTIFICATION_RELEASE_APPROVAL_REQUESTED" }),
            key: `excludedReleaseEvents.${ReleaseEvent.ApprovalRequested}`,
          },
          {
            value: ReleaseEvent.Approved,
            name: intl.$t({ id: "NOTIFICATION_RELEASE_APPROVED" }),
            key: `excludedReleaseEvents.${ReleaseEvent.Approved}`,
          },
          {
            value: ReleaseEvent.Rejected,
            name: intl.$t({ id: "NOTIFICATION_RELEASE_REJECTED" }),
            key: `excludedReleaseEvents.${ReleaseEvent.Rejected}`,
          },
          {
            value: ReleaseEvent.ChangedByContractorInternal,
            name: intl.$t({ id: "NOTIFICATION_RELEASE_CHANGED_BY_CONTRACTOR" }),
            key: `excludedReleaseEvents.${ReleaseEvent.ChangedByContractorInternal}`,
          },
          {
            value: ReleaseEvent.Confirmed,
            name: intl.$t({ id: "NOTIFICATION_RELEASE_VENDOR_CONFIRM" }),
            key: `excludedReleaseEvents.${ReleaseEvent.Confirmed}`,
          },
          {
            value: ReleaseEvent.ChangedByVendor,
            name: intl.$t({ id: "NOTIFICATION_RELEASE_VENDOR_CHANGE" }),
            key: `excludedReleaseEvents.${ReleaseEvent.ChangedByVendor}`,
          },
          {
            value: ReleaseEvent.NeedsToBeReceived,
            name: intl.$t({ id: "NOTIFICATION_RELEASE_NEEDS_TO_BE_RECEIVED" }),
            key: `excludedReleaseEvents.${ReleaseEvent.NeedsToBeReceived}`,
          },
          {
            value: ReleaseEvent.ReceivedInternal,
            name: intl.$t({ id: "NOTIFICATION_RELEASE_RECEIVED" }),
            key: `excludedReleaseEvents.${ReleaseEvent.ReceivedInternal}`,
          },
          {
            value: ReleaseEvent.PartiallyReceivedInternal,
            name: intl.$t({ id: "NOTIFICATION_RELEASE_PARTIALLY_RECEIVED" }),
            key: `excludedReleaseEvents.${ReleaseEvent.PartiallyReceivedInternal}`,
          },
          {
            value: ReleaseEvent.ItemDue,
            name: intl.$t({ id: "NOTIFICATION_RELEASE_ITEM_DUE" }),
            key: `excludedReleaseEvents.${ReleaseEvent.ItemDue}`,
          },
          {
            value: ReleaseEvent.IssuesResolved,
            name: intl.$t({ id: "NOTIFICATION_RELEASE_ISSUES_RESOLVED" }),
            key: `excludedReleaseEvents.${ReleaseEvent.IssuesResolved}`,
          },
          {
            value: MessageContextKind.Release,
            name: intl.$t({ id: "NOTIFICATION_RELEASE_VENDOR_MESSAGE" }),
            key: `excludedMessageContexts.${MessageContextKind.Release}`,
          },
        ],
      },
      {
        id: "INVOICES",
        name: intl.$t({ id: "INVOICES" }),
        children: [
          {
            value: InvoiceEvent.AwaitingApproval,
            name: intl.$t({ id: "NOTIFICATION_INVOICE_IS_AWAITING_APPROVAL" }),
            key: `excludedInvoiceEvents.${InvoiceEvent.AwaitingApproval}`,
          },
          {
            value: InvoiceEvent.KickbackRequested,
            name: intl.$t({ id: "NOTIFICATION_INVOICE_KICKBACK_IS_REQUESTED" }),
            key: `excludedInvoiceEvents.${InvoiceEvent.KickbackRequested}`,
          },
          {
            value: InvoiceEvent.KickbackUpdated,
            name: intl.$t({ id: "NOTIFICATION_INVOICE_KICKBACK_UPDATED" }),
            key: `excludedInvoiceEvents.${InvoiceEvent.KickbackUpdated}`,
          },
          {
            value: InvoiceEvent.KickbackResolved,
            name: intl.$t({ id: "NOTIFICATION_INVOICE_KICKBACK_IS_RESOLVED" }),
            key: `excludedInvoiceEvents.${InvoiceEvent.KickbackResolved}`,
          },
          {
            value: InvoiceEvent.IssuesCorrected,
            name: intl.$t({ id: "NOTIFICATION_INVOICE_ISSUES_IS_CORRECTED" }),
            key: `excludedInvoiceEvents.${InvoiceEvent.IssuesCorrected}`,
          },
          {
            value: InvoiceEvent.IssuesRejected,
            name: intl.$t({ id: "NOTIFICATION_INVOICE_ISSUES_IS_REJECTED" }),
            key: `excludedInvoiceEvents.${InvoiceEvent.IssuesRejected}`,
          },
        ],
      },
    ];
  }, [intl]);

  return { config };
};
