import { CircleCounter } from "@/common/components/circle-counter/CircleCounter";
import { CountType } from "@/common/components/circle-counter/CountType";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`grid grid-flow-col place-items-center gap-4`;
const Name = tw.div`font-light text-sm`;

type Props = {
  vendor: {
    id: string;
    name: string;
    photoUrl?: string | null;
  };
  count: CountType;
};

export const ReportVendorItem: FC<Props> = ({ vendor, count }) => {
  if (!vendor) {
    return null;
  }

  return (
    <Container>
      <CircleCounter count={count} />
      <OrgLogo
        logoImageUrl={vendor.photoUrl}
        className="ml-1"
        name={vendor.name}
        width={35}
      />
      <Name>{vendor.name}</Name>
    </Container>
  );
};
