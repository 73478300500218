import {
  TextFieldControlled,
  TextFieldControlledProps,
} from "@/common/components/textfield-controlled/TextFieldControlled";
import { FC } from "react";
import { useIntl } from "react-intl";

export const ProjectAddressCityTextField: FC<TextFieldControlledProps> = (
  props,
) => {
  const intl = useIntl();

  return (
    <TextFieldControlled
      {...props}
      size="small"
      label={intl.$t({ id: "ADDRESS_CITY" })}
      rules={{ required: true }}
    />
  );
};
