import { GridTable } from "@/common/components/grid-table/GridTable";
import { useHideZones } from "@/common/hooks/useHideZones";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useDistributorReleaseItemsZoneProvider } from "../../../providers/DistributorReleaseItemsZoneProvider";
import { useDistributorRelease } from "../../../providers/DistributorReleaseProvider";
import { useDistributorReleaseDeliveryRecordListConfiguration } from "./DistributorReleaseDeliveryRecordList.configuration";

const ZoneItemsContainer = tw.div`flex flex-col`;

export const DistributorReleaseDeliveryRecordList: FC = () => {
  const { release } = useDistributorRelease();
  const { zones, toggleZone, toggleCostCode, groupedByCostCode } =
    useDistributorReleaseItemsZoneProvider();

  const distributorReleaseDeliveryRecordListConfiguration =
    useDistributorReleaseDeliveryRecordListConfiguration(release);

  const { hideZones } = useHideZones(zones);

  if (!release) {
    return null;
  }

  return (
    <GridTable<DistributorReleaseItemFieldsFragment>
      configuration={{
        container: ZoneItemsContainer,
        columns: distributorReleaseDeliveryRecordListConfiguration,
        classNames: {
          header: "top-[32px]",
          category: "top-[36px] lg:top-[82px]",
          subCategory: !groupedByCostCode
            ? "hidden"
            : hideZones
              ? "top-[36px] lg:top-[82px]"
              : "top-[75px] lg:top-[116px]",
        },
        toggle: {
          category: toggleZone,
          subCategory: toggleCostCode,
        },
      }}
      hideGroup={hideZones}
      items={zones}
      readonly={true}
    />
  );
};
