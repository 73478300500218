import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { UsersProvider } from "@/contractor/pages/admin/users/components/providers/UsersProvider";
import { ProjectStatus } from "@/generated/graphql";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { PROJECT_STATUSES } from "../../../../../../common/components/display-project-status/DisplayProjectStatus";
import { ProjectLocationSelector } from "../project-form/input-components/ProjectLocationSelector";
import { ProjectUsersSelector } from "../project-form/input-components/ProjectUsersSelector";

const Container = tw.div<{
  $hasMultipleLocations: boolean;
}>`sticky top-16 bg-blue-100 px-2 py-3 grid grid-flow-col gap-2 items-center mb-3 z-50
${({ $hasMultipleLocations }) =>
  $hasMultipleLocations
    ? "grid-cols-[60px_1fr_1fr_2fr]"
    : "grid-cols-[60px_1fr_2fr]"}
`;
const Title = tw.div`text-lg`;

const ProjectsImportFormHeaderWithProvider = () => {
  const intl = useIntl();
  const { locations } = useUserLocations();
  const { trigger } = useFormContext();
  useEffect(() => {
    trigger();
  }, [trigger]);

  const stageOptions = useMemo(
    () =>
      Object.entries(ProjectStatus)
        .filter(
          ([, s]) => s === ProjectStatus.Active || s === ProjectStatus.Awarded,
        )
        .map(([, enumStatus]) => ({
          id: enumStatus,
          name: intl.$t({ id: PROJECT_STATUSES[enumStatus].label }),
        })),
    [intl],
  );

  return (
    <Container $hasMultipleLocations={locations.length > 1}>
      <FormattedMessage id="ASSIGN" tagName={Title} />
      <ProjectLocationSelector name="locationId" />
      <SelectControlled
        name="status"
        options={stageOptions}
        getLabel={(option) => option.name}
        getValue={(option) => option.id}
        rules={{ required: true }}
        label={intl.$t({ id: "STAGE" })}
      />
      <ProjectUsersSelector name="team" />
    </Container>
  );
};

export const ProjectsImportFormHeader = () => (
  <UsersProvider>
    <ProjectsImportFormHeaderWithProvider />
  </UsersProvider>
);
