import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import {
  UsersUserFieldsFragment,
  useInvoiceKickbackEligibleUsersQuery,
} from "@/generated/graphql";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";

type ProviderContextType = {
  users: UsersUserFieldsFragment[];
  loading: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  users: [],
  loading: false,
});

type Props = {
  invoiceId: string | null | undefined;
} & PropsWithChildren;

export const InvoiceInternalKickbackEligibleUsersProvider: FC<Props> = ({
  children,
  invoiceId,
}) => {
  const { data, error, loading } = useInvoiceKickbackEligibleUsersQuery({
    variables: { id: invoiceId || "" },
    skip: !invoiceId,
    fetchPolicy: "no-cache",
  });
  useErrorEffect(error);

  const users = useMemo(
    () =>
      [...(data?.invoice.kickbackEligibleUsers ?? [])].sort((a, b) =>
        a.firstName.localeCompare(b.firstName),
      ),
    [data],
  );

  return (
    <ProviderContext.Provider
      value={{
        users,
        loading,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceInternalKickbackEligibleUsers =
  (): ProviderContextType => useContext(ProviderContext);
