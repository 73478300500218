import { ExpandablePanel } from "@/common/components/expandable-panel/ExpandablePanel";
import {
  SupportedFormats,
  UploadAsset,
} from "@/common/components/upload-asset/UploadAsset";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import {
  AssetContext,
  AssetFieldsFragment,
  AssetType,
  ReleaseFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDeliverySlipVerification } from "../../../../providers/DeliverySlipVerificationProvider";

type Props = {
  release: ReleaseFieldsFragment;
  expanded?: boolean;
};

const DeliverySlipPhotosPanelWithProvider: FC<Props> = ({
  release,
  expanded = false,
}) => {
  const intl = useIntl();
  const { deliverySlip, updateDeliverySlip } = useDeliverySlipVerification();

  const updateUrls = useCallback(
    async (assets: AssetFieldsFragment[]) => {
      await updateDeliverySlip({
        id: deliverySlip?.id ?? "",
        releaseId: release?.id ?? "",
        deliveryPhotoUrls: assets.map((a) => a.url),
      });
    },

    [deliverySlip?.id, release?.id, updateDeliverySlip],
  );

  return (
    <ExpandablePanel
      header={intl.$t({ id: "DELIVERY_PHOTOS_HEADER" })}
      isOpened={expanded}
    >
      <UploadAsset
        onChange={updateUrls}
        xs
        mode="horizontal"
        className="flex items-start"
      >
        <SupportedFormats>
          <FormattedMessage id="DELIVERY_PHOTOS_SUPPORTED_FORMATS" />
        </SupportedFormats>
      </UploadAsset>
    </ExpandablePanel>
  );
};

export const DeliverySlipPhotosPanel: FC<Props> = ({ release, expanded }) => {
  const { deliverySlip } = useDeliverySlipVerification();

  return (
    <UploadAssetProvider
      initialAssets={deliverySlip?.deliveryPhotos}
      context={AssetContext.DeliveryImage}
      projectId={release?.project?.id ?? ""}
      type={AssetType.Image}
    >
      <DeliverySlipPhotosPanelWithProvider
        release={release}
        expanded={expanded}
      />
    </UploadAssetProvider>
  );
};
