import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Row = tw.div`flex flex-row`;
const Dot = tw.div`h-[18p] w-[18px] rounded-full mr-2`;

type Props = {
  isActive: boolean;
};

export const ConnectionStatus: FC<Props> = ({ isActive }) => {
  const intl = useIntl();
  return (
    <Row>
      <Dot className={isActive ? "bg-green-600" : "bg-orange-500"} />
      {intl.$t({
        id: isActive ? "CONNECTION_ACTIVE" : "CONNECTION_REQUIRE_SETTING",
      })}
    </Row>
  );
};
