import { MessageOrgType } from "@/common/components/messages/Messages";
import { MessagesPanel } from "@/common/components/messages/components/MessagesPanel";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { MESSAGES_SEGMENT } from "@/config/routes";
import {
  DistributorQuoteCommonFieldsFragment,
  MessageContextKind,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import tw from "tailwind-styled-components";

type Props = {
  quote: DistributorQuoteCommonFieldsFragment;
  text: React.ReactNode;
  org: MessageOrgType;
};

const Container = tw.div`flex items-center`;

export const VendorQuoteMessagesButton: FC<Props> = ({
  text,
  quote,
  org,
}: Props) => {
  const location = useLocation();
  const defaultOpened = useMemo(
    () => location.pathname.includes(MESSAGES_SEGMENT),
    [location.pathname],
  );

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <Container onClick={() => togglePanel(true)}>{text}</Container>
      )}
      content={(togglePanel) => (
        <MessagesPanel
          title="MESSENGER"
          id={quote.id}
          kind={MessageContextKind.Quote}
          context={{ quote }}
          org={org}
          messageTabName={<FormattedMessage id="MESSAGE_CONTRACTOR" />}
          noMessage="DISTRIBUTOR_QUOTE_NO_MESSAGES"
          onClose={() => togglePanel(false)}
        />
      )}
      defaultOpened={defaultOpened}
    />
  );
};
