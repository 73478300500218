import { RendererFunctionType } from "@/common/providers/ColumnMapperProvider";

import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { useIntl } from "react-intl";

export const useLeadTimeRenderer = () => {
  const intl = useIntl();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      textRenderer(instance, td, row, col, prop, value, cellProperties);
      if (value && !Number.isNaN(Number(value))) {
        const days = document.createElement("span");
        days.className = "text-gray-600 font-light text-xs";
        days.innerText = ` ${
          Number(value) > 1
            ? intl.formatMessage({ id: "DAYS" })
            : intl.formatMessage({ id: "DAY" })
        }`;
        td.appendChild(days);
      }
    },
    [intl],
  );

  return renderer;
};
