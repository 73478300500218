import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { ProjectItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";

const Container = tw.div`flex`;

export const ProjectItemWholeSelectionCheckbox: FC<{
  items: ProjectItemFieldsFragment[];
}> = ({ items }) => {
  const { setSelectedEstimatedItemIds, selectedEstimatedItemIds } =
    useEstimatedItems();

  const checked = useMemo(
    () => items.every((i) => selectedEstimatedItemIds.includes(i.id)),
    [items, selectedEstimatedItemIds],
  );

  const handleChange = useCallback(
    (newChecked: boolean) => {
      if (!newChecked) {
        setSelectedEstimatedItemIds([]);
        return;
      }
      const idsToAdd = newChecked ? items.map((i) => i.id) : [];
      setSelectedEstimatedItemIds(idsToAdd);
    },
    [items, setSelectedEstimatedItemIds],
  );

  return (
    <Container>
      <SelectionCheckbox checked={checked} setSelection={handleChange} />
    </Container>
  );
};
