import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { DrawerView } from "@/common/components/panel/DrawerView";
import { FC } from "react";
import { ImportReleaseItemsPanel } from "./ImportReleaseItemsPanel";

export const IMPORT_MATERIALS_DRAWER = "import-release-items";
export enum ImportMaterialsDrawerTabs {
  Quote = "quote",
  Note = "note",
}

export const ImportMaterialsDrawer: FC = () => {
  const { isOpened, toggle, getObjectId } = useGlobalDrawer();
  return (
    <DrawerView
      isOpened={isOpened(IMPORT_MATERIALS_DRAWER)}
      setIsOpened={(value) => toggle(IMPORT_MATERIALS_DRAWER, value)}
      content={(togglePanel) => (
        <ImportReleaseItemsPanel
          onClose={() => togglePanel(false)}
          defaultTab={getObjectId(IMPORT_MATERIALS_DRAWER)}
        />
      )}
    />
  );
};
