import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { ProductBrand } from "@/common/components/product/ProductBrand";
import { ProductImage } from "@/common/components/product/ProductImage";
import { ProductName } from "@/common/components/product/ProductName";
import {
  ProjectItemFieldsFragment,
  ReleaseItemFieldsFragment,
  RfqItemFieldsFragment,
} from "@/generated/graphql";
import { Add, Remove } from "@mui/icons-material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

export type CatalogListItemCustomLabels = {
  addLabel?: string;
  removeLabel?: string;
  removeIcon?: React.ReactNode;
  removeDisabled?: boolean;
};

type Props = {
  addToCart: (id: string, description: string) => Promise<boolean>;
  findItem: (
    id: string,
  ) =>
    | { id: string }
    | RfqItemFieldsFragment
    | undefined
    | ProjectItemFieldsFragment
    | ReleaseItemFieldsFragment;
  removeFromCart: (itemId: string) => Promise<boolean>;
  item: {
    id: string;
    name: string;
    imageUrl?: string | null;
    brand?: string;
  };
  customLabels?: CatalogListItemCustomLabels;
};

const ProductContainer = tw.div`
  flex relative border border-gray-400 rounded-md pt-5 px-2.5 pb-2.5 flex-col bg-white h-64
`;

const ProductListItemContainer = tw.div`
  w-full relative
`;

const OutlinedButtonStyled = tw(OutlinedButton)`
  w-full mt-2 max-h-8 p-0
`;

const Label = tw.div`flex items-center`;

const ProductImageStyled = tw(ProductImage)`
  w-full max-w-40
`;

const ProductImageContainer = tw.div`
  flex justify-center items-center mb-2 h-36 mt-2
`;

const ProductBrandStyled = tw(ProductBrand)`
  truncate text-sm
`;

const ProductNameStyled = tw(ProductName)`
  line-clamp-3 font-light
`;

export const CatalogListItem: FC<Props> = ({
  findItem,
  addToCart,
  removeFromCart,
  item,
  customLabels = {
    addLabel: "CATALOG_ADD_TO_ORDER",
    removeLabel: "CATALOG_REMOVE_FROM_ORDER",
    removeIcon: <Remove />,
    removeDisabled: false,
  },
}) => {
  const projectItem = findItem(item.id);
  const [isAdded, setIsAdded] = useState<string | undefined>(projectItem?.id);

  useEffect(() => {
    setIsAdded(projectItem?.id);
  }, [projectItem]);

  const [removingItem, setRemovingItem] = useState(false);
  const [addingItem, setAddingItem] = useState(false);

  const addItem = async () => {
    setAddingItem(true);
    if (await addToCart(item.id, item.name)) {
      setIsAdded(item.id);
      setAddingItem(false);
    }
  };

  const removeItem = async () => {
    if (isAdded) {
      setRemovingItem(true);
      await removeFromCart(isAdded);
      setIsAdded(undefined);
      setRemovingItem(false);
    }
  };
  return (
    <ProductListItemContainer data-testid="product">
      <ProductContainer>
        <ProductImageContainer>
          <ProductImageStyled imageUrl={item.imageUrl} name={item.name} />
        </ProductImageContainer>
        <ProductBrandStyled brand={item.brand || null} />
        <ProductNameStyled name={item.name} />
      </ProductContainer>
      {isAdded ? (
        <OutlinedButtonStyled
          onClick={removeItem}
          loading={removingItem}
          disabled={customLabels.removeDisabled}
        >
          <Label>
            {customLabels.removeIcon}
            <FormattedMessage id={customLabels.removeLabel} />
          </Label>
        </OutlinedButtonStyled>
      ) : (
        <OutlinedButtonStyled onClick={addItem} loading={addingItem}>
          <Label>
            <Add />
            <FormattedMessage id={customLabels.addLabel} />
          </Label>
        </OutlinedButtonStyled>
      )}
    </ProductListItemContainer>
  );
};
