import {
  CategoryState,
  useToggleCategory,
} from "@/common/hooks/useToggleCategory";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { getCostCodesByDistributorRfqItems } from "@/distributor/common/utils/getCostCodesByDistributorProjectItems";
import {
  DistributorQuoteFieldsFragment,
  DistributorQuoteItemGroupFieldsFragment,
  DistributorQuotesAuxiliaryQuoteItemFieldsFragment,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";

export type DistributorQuoteItemGroupExtendedFieldsFragment =
  DistributorQuoteItemGroupFieldsFragment & {
    id: string;
    quoteItemCount?: number;
    quoteItemIds?: string[];
  };
type CostCodeCategory =
  CategoryState<DistributorQuoteItemGroupExtendedFieldsFragment>;

type AuxiliaryCategory =
  CategoryState<DistributorQuotesAuxiliaryQuoteItemFieldsFragment>;

type ProviderContextType = {
  costCodes: CostCodeCategory[];
  toggleCostCode: (name: string) => void;
  auxiliaryItems: AuxiliaryCategory[];
};

type Props = {
  quote: DistributorQuoteFieldsFragment;
  children: React.ReactNode;
};

const ProviderContext = createContext<ProviderContextType>({
  costCodes: [],
  toggleCostCode: NoFunction,
  auxiliaryItems: [],
});

export const AUXILIARY_ITEMS_ID = "auxiliary-items";

export const DistributorQuoteItemsProvider: FC<Props> = ({
  children,
  quote,
}) => {
  const [costCodes, setCostCodes] = useState<CostCodeCategory[]>([]);
  const [auxiliaryItems, setAuxiliaryItems] = useState<AuxiliaryCategory[]>([]);
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { toggleCategory } = useToggleCategory(costCodes, setCostCodes);
  const intl = useIntl();

  useEffect(() => {
    const { itemGroups } = quote;
    const costCodeGroups = getCostCodesByDistributorRfqItems(
      itemGroups.map((i) => i.rfqItem),
      unassignedCostCode,
    );
    const costCodeCategories: CostCodeCategory[] = costCodeGroups.map(
      (costCode) => {
        return {
          id: costCode.id,
          name: costCode.description,
          isOpened: true,
          items: itemGroups
            .filter(
              (i) =>
                (!i.rfqItem?.costCode &&
                  costCode.id === unassignedCostCode.id) ||
                i.rfqItem?.costCode?.id === costCode.id,
            )
            .sort((a, b) =>
              a.rfqItem.projectItem.material.material.name.localeCompare(
                b.rfqItem.projectItem.material.material.name,
              ),
            )
            .map((i) => {
              return {
                ...i,
                id: i.quoteItems[0]?.id,
                quoteItemCount: i.quoteItems.length,
              };
            }),
        };
      },
    );
    setCostCodes(costCodeCategories);

    const auxiliaryItems = {
      id: AUXILIARY_ITEMS_ID,
      name: intl.$t({ id: "ADDITIONAL_ITEMS" }),
      isOpened: true,
      items: quote.auxiliaryItems || [],
    };
    setAuxiliaryItems([auxiliaryItems]);
  }, [intl, quote, unassignedCostCode]);

  return (
    <ProviderContext.Provider
      value={{
        costCodes,
        toggleCostCode: toggleCategory,
        auxiliaryItems,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDistributorQuoteItemsProvider = (): ProviderContextType =>
  useContext(ProviderContext);
