import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ProjectDocument,
  ProjectFieldsFragment,
  ProjectItemFieldsFragment,
  ProjectQuery,
  RfqDocument,
  useAddEstimatedItemMutation,
  useProjectQuery,
  useUpdateRfqMutation,
} from "@/generated/graphql";
import {
  NoFunctionBooleanPromise,
  NoFunctionUndefined,
} from "@/types/NoFunction";
import { FC, createContext, useContext } from "react";
import { useParams } from "react-router-dom";

type ProviderContextType = {
  addToCart: (input: {
    masterSkuId?: string;
    masterProductId?: string;
    orgCatalogSkuId?: string;
    estimateUom: string;
  }) => Promise<boolean>;
  removeFromProject: (itemId: string) => Promise<boolean>;
  findItem: (id: string) => Pick<ProjectItemFieldsFragment, "id"> | undefined;
  count: number;
  project: ProjectFieldsFragment | null;
  loading: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  addToCart: NoFunctionBooleanPromise,
  removeFromProject: NoFunctionBooleanPromise,
  findItem: NoFunctionUndefined,
  count: 0,
  project: null,
  loading: false,
});

export const ProjectShoppingCartProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { id } = useParams();
  const projectId = id || "";
  const { data, error, loading } = useProjectQuery({
    variables: { id: projectId },
  });
  useErrorEffect(error);
  const [addToCartMutation] = useAddEstimatedItemMutation({
    refetchQueries: [
      {
        query: ProjectDocument,
        variables: { id: projectId, excludePhantoms: true },
      },
    ],
  });
  const [removeFromRfqMutation] = useUpdateRfqMutation({
    update: (cache, { data: updateFromRfqResult }) => {
      const project = cache.readQuery<ProjectQuery>({
        query: RfqDocument,
        variables: { id: id || "" },
      });
      if (project?.project && updateFromRfqResult?.updateRfq) {
        cache.writeQuery({
          query: RfqDocument,
          variables: { id: id || "" },
          data: {
            project: {
              ...project.project,
              items: updateFromRfqResult.updateRfq.items,
            },
          },
        });
      }
    },
  });
  const { setError } = useGlobalError();

  const addToCart = async (input: {
    masterSkuId?: string;
    masterProductId?: string;
    estimateUom: string;
  }) => {
    try {
      const { errors } = await addToCartMutation({
        variables: {
          input: {
            projectId,
            item: {
              masterSkuId: input.masterSkuId,
              masterProductId: input.masterProductId,
              estimateUom: input.estimateUom,
            },
            quantity: 1,
          },
        },
      });
      setError(errors);
      return !!errors;
    } catch (errors) {
      setError(errors);
    }

    return true;
  };

  const removeFromProject = async (itemId: string) => {
    try {
      // to do: replace after discussion with backend
      const { errors } = await removeFromRfqMutation({
        variables: { input: { rfqId: id || "", removedItems: [itemId] } },
      });
      setError(errors);
    } catch (errors) {
      setError(errors);
    }

    return false;
  };

  const findItem = (
    materialId: string,
  ): Pick<ProjectItemFieldsFragment, "id"> | undefined => {
    if (data?.project) {
      return data.project.items.find(
        (item) => item.material.material?.id === materialId,
      );
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        project: data?.project || null,
        addToCart,
        findItem,
        removeFromProject,
        loading,
        count: data?.project?.items?.length || 0,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useProjectShoppingCart = (): ProviderContextType =>
  useContext(ProviderContext);
