import { OverlayPanel } from "@/common/components/panel/OverlayPanel";

import { If } from "@/common/components/if/If";
import { Price } from "@/common/components/price/Price";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { TextField } from "@/common/components/textfield/TextField";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import { useProjectMaps } from "@/contractor/pages/home/project/hooks/useProjectMaps";
import { useProjectOrderedQuantity } from "@/contractor/pages/home/project/providers/ProjectOrderedQuantityProvider";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ValueWithTax } from "../cost-codes-totals/CostCodeTotal.Styles";
import { ReportReleaseItem } from "./ReportReleaseItem";

enum HeaderType {
  Total,
  CostCode,
  Zone,
}

const Container = tw.div`flex flex-col`;
const DeliveriesContainer = tw.div`bg-white pb-2`;
const Header = tw.div`grid grid-cols-[2fr_1fr]
-mx-7 px-7 py-3
${({ $type }: { $type?: HeaderType }) =>
  $type === HeaderType.Total
    ? "bg-blue-100"
    : $type === HeaderType.CostCode
      ? "bg-yellow-200"
      : "bg-yellow-300"}`;
const HeaderName = tw.div`text-sm font-medium`;
const StyledPrice = tw(Price)`text-right text-sm font-medium`;
const PriceContainer = tw.div`flex flex-col gap-y-0.5`;
const VendorContainer = tw.div`flex flex-row items-center gap-x-2 my-4`;
const VendorName = tw.div`text-sm font-medium`;

export enum ReportType {
  Material,
  Vendor,
}

type Props = {
  onClose: () => void;
  orgMaterialId?: string;
  ordered?: string | null | undefined;
  type: ReportType;
  includeVendorHeader?: boolean;
};

export const ReportItemOrderedPanel: FC<Props> = ({
  onClose,
  orgMaterialId,
  ordered,
  type,
  includeVendorHeader = true,
}) => {
  const intl = useIntl();
  const {
    item,
    loading,
    zoneId,
    ordered: total,
    orderedTotal,
    costCodeId,
  } = useProjectOrderedQuantity();
  const { project } = useProject();
  const { zoneMap, costCodeMap } = useProjectMaps(project);
  const { formatCurrency } = useFormatNumberToCurrency();

  return (
    <OverlayPanel
      className="pt-[2px]"
      title={intl.$t({
        id: type === ReportType.Material ? "ORDERS" : "ORDERS_BY_VENDOR",
      })}
      onCancel={onClose}
      isLoading={loading}
    >
      <Container>
        <If isTrue={includeVendorHeader && type === ReportType.Vendor}>
          <VendorContainer>
            <VendorLogo
              logoImageUrl={
                item?.elements?.[0]?.release.sellerOrgLocation?.org.logoImageUrl
              }
              orgName={
                item?.elements?.[0]?.release.sellerOrgLocation?.org
                  .name as string
              }
              address={item?.elements?.[0]?.release.sellerOrgLocation?.address}
            />
            <VendorName>
              {
                item?.elements?.[0]?.release.sellerOrgLocation?.org
                  .name as string
              }
            </VendorName>
          </VendorContainer>
        </If>
        <If isTrue={orgMaterialId}>
          <TextField
            className="my-4"
            inputProps={{
              className: "text-lg",
            }}
            value={item?.orgMaterial?.material?.name}
            label={intl.$t({ id: "ITEM_NAME" })}
            multiline
            staticText
          />
        </If>
        <If isTrue={zoneId || !costCodeId}>
          <Header $type={zoneId ? HeaderType.Zone : HeaderType.Total}>
            <HeaderName>
              {zoneId ? (
                <ProductCategory
                  type={ProductCategoryType.Zone}
                  category={{
                    id: zoneId,
                    name: zoneMap.get(zoneId)?.name || "",
                    isOpened: false,
                  }}
                  includeToggle={false}
                />
              ) : (
                intl.$t({ id: "TOTAL" })
              )}
            </HeaderName>
            <StyledPrice price={ordered} />
          </Header>
        </If>
        <If isTrue={costCodeId}>
          <Header $type={HeaderType.CostCode}>
            <ProductCategory
              type={ProductCategoryType.CostCode}
              category={{
                id: costCodeId || "",
                name: costCodeMap.get(costCodeId as string)?.description || "",
                isOpened: false,
              }}
              includeToggle={false}
              projectId={project?.id}
            />
            <PriceContainer>
              <StyledPrice price={total} />
              <If isTrue={orderedTotal}>
                <ValueWithTax className="text-right">
                  <FormattedMessage
                    id="VALUE_WITH_TAX_AND_FEES"
                    values={{ value: formatCurrency(orderedTotal) }}
                  />
                </ValueWithTax>
              </If>
            </PriceContainer>
          </Header>
        </If>
        <DeliveriesContainer>
          {item?.elements?.map((item, key) => (
            <ReportReleaseItem
              key={key}
              release={item.release}
              includeVendorHeader={
                type === ReportType.Vendor && includeVendorHeader
              }
            />
          ))}
        </DeliveriesContainer>
      </Container>
    </OverlayPanel>
  );
};
