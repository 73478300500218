import DateRangeOutlineFilled from "@/assets/icons/lead-time-filled.svg?react";
import { CheckButton } from "@/common/components/button/CheckButton";
import { DiscardButton } from "@/common/components/button/DiscardButton";
import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { If } from "@/common/components/if/If";
import { StyledNumericInput } from "@/common/components/styled-numeric-input/StyledNumericInput";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { DistributorQuoteItemFieldsFragment } from "@/generated/graphql";
import { DateRangeOutlined } from "@mui/icons-material";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Popover from "@mui/material/Popover";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { getLeadTimeInterval } from "./getLeadTimeInterval";

const Container = tw.div`flex flex-row items-center gap-4 bg-white rounded-full p-4 px-6`;
const RadioGroupStyled = tw(RadioGroup)`flex-nowrap`;
const ButtonContainer = tw.div`flex flex-row items-center gap-2`;
const DateRangeOutlineFilledStyled = tw(DateRangeOutlineFilled)`text-2xl`;
const LeadTimeContainer = tw.div`absolute text-2xs text-gray-600 left-0 right-0 whitespace-nowrap`;

type Props = {
  leadTimeDays: DistributorQuoteItemFieldsFragment["leadTimeDays"];
  readonly: boolean;
  updateLeadTime: (leadTimeDays: number | null) => void;
};

export const LeadTime: FC<Props> = ({
  leadTimeDays: initialLeadTimeDays,
  readonly,
  updateLeadTime,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [mul, setMul] = useState(getLeadTimeInterval(initialLeadTimeDays));
  const [leadTimeDays, setLeadTimeDays] = useState(
    initialLeadTimeDays ? initialLeadTimeDays / mul : 1,
  );
  const intl = useIntl();

  useEffect(() => {
    const newMul = getLeadTimeInterval(initialLeadTimeDays);
    setMul(newMul);
    setLeadTimeDays(initialLeadTimeDays ? initialLeadTimeDays / newMul : 1);
  }, [initialLeadTimeDays]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOnSave = useCallback(() => {
    setMul(mul);
    updateLeadTime(!leadTimeDays ? 0 : leadTimeDays * mul);
    handleClose();
  }, [handleClose, leadTimeDays, mul, updateLeadTime]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const updateLeadTimeDays = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = parseFloat(event.target.value);
      setLeadTimeDays(val);
    },
    [],
  );

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const getIntervalKey = useCallback(() => {
    switch (getLeadTimeInterval(initialLeadTimeDays)) {
      case 1:
        return "DAYS";
      case 7:
        return "WEEKS";
      case 30:
        return "MONTHS";
      default:
        return "DAYS";
    }
  }, [initialLeadTimeDays]);

  return (
    <>
      <Tooltip
        id="lead-time-tooltip"
        className="relative"
        element={
          <IconButtonBorderless onClick={handleClick}>
            {!initialLeadTimeDays ? (
              <DateRangeOutlined />
            ) : (
              <>
                <DateRangeOutlineFilledStyled />
                <LeadTimeContainer>
                  {initialLeadTimeDays /
                    getLeadTimeInterval(initialLeadTimeDays)}{" "}
                  <FormattedMessage id={getIntervalKey()} />
                </LeadTimeContainer>
              </>
            )}
          </IconButtonBorderless>
        }
      >
        {intl.$t({ id: "LEAD_TIME" })}
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Container>
          <StyledNumericInput
            id="leadTime"
            decimals={0}
            value={leadTimeDays}
            onChange={updateLeadTimeDays}
            staticText={readonly}
            label={intl.$t({ id: "SPECIFY_VALUE" })}
            shrink
          />
          <RadioGroupStyled
            value={mul}
            row
            onChange={(e) => setMul(Number(e.target.value))}
          >
            <FormControlLabel
              value="1"
              control={<Radio />}
              label={intl.$t({ id: "DAYS" })}
              disabled={readonly}
            />
            <FormControlLabel
              value="7"
              control={<Radio />}
              label={intl.$t({ id: "WEEKS" })}
              disabled={readonly}
            />
            <FormControlLabel
              value="30"
              control={<Radio />}
              label={intl.$t({ id: "MONTHS" })}
              disabled={readonly}
            />
          </RadioGroupStyled>
          <If isTrue={!readonly}>
            <ButtonContainer>
              <CheckButton onClick={handleOnSave} />
              <DiscardButton onClick={handleClose} />
            </ButtonContainer>
          </If>
        </Container>
      </Popover>
    </>
  );
};
