import {
  AdditionalFiltersOverlay,
  Option,
} from "@/common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { TagsSelector } from "@/common/components/tags-selector/TagsSelector";
import { ReleaseStatus } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useReleaseItemsZoneProvider } from "../../providers/ReleaseItemsZonesProvider";
import { useRelease } from "../../providers/ReleaseProvider";
import {
  ReleaseItemFilter,
  ReleaseItemsFilters,
} from "../../types/ReleaseItemsFilters";

export const ReleaseItemListFilters = () => {
  const intl = useIntl();
  const { release } = useRelease();
  const { filter, setFilter, filteredTags, setFilteredTags } =
    useReleaseItemsZoneProvider();

  const options = useMemo<Option<ReleaseItemsFilters>[]>(() => {
    return [
      {
        id: ReleaseItemFilter.FULLY_RECEIVED,
        title: intl.$t({ id: ReleaseItemFilter.FULLY_RECEIVED }),
        filter: (value) => ({
          [ReleaseItemFilter.FULLY_RECEIVED]: value,
        }),
        value: (currentFilter) =>
          currentFilter?.[ReleaseItemFilter.FULLY_RECEIVED],
      },
      {
        id: ReleaseItemFilter.FULLY_INVOICED,
        title: intl.$t({ id: ReleaseItemFilter.FULLY_INVOICED }),
        filter: (value) => ({
          [ReleaseItemFilter.FULLY_INVOICED]: value,
        }),
        value: (currentFilter) =>
          currentFilter?.[ReleaseItemFilter.FULLY_INVOICED],
      },
    ];
  }, [intl]);

  return (
    <AdditionalFiltersOverlay
      filter={filter}
      defaultFilter={{
        [ReleaseItemFilter.FULLY_RECEIVED]: undefined,
        [ReleaseItemFilter.FULLY_INVOICED]: undefined,
      }}
      options={
        !!release &&
        [ReleaseStatus.PartiallyReceived, ReleaseStatus.Received].includes(
          release.status,
        )
          ? options
          : []
      }
      setFilter={setFilter}
      customBottomFilters={
        <TagsSelector
          options={release?.project?.tags ?? []}
          selectedTags={filteredTags}
          setSelectedTags={setFilteredTags}
          limitTags={1}
          placeholder={intl.$t({ id: "TAGS" })}
          creatable={false}
        />
      }
    />
  );
};
