import { ConnectionError } from "@/contractor/pages/home/release/components/connections/components/common/ExportErrorList";
import { MissingItemsFromInventory } from "@/contractor/pages/home/release/components/connections/components/connection-types/hosted/MissingItemsFromInventory";
import { MissingMaterialConversionsModal } from "@/contractor/pages/home/release/components/connections/components/connection-types/hosted/steps/missing-material-conversion/MissingMaterialConversionsModal";
import { MissingItemsFromInventoryProvider } from "@/contractor/pages/home/release/components/connections/providers/MissingItemsFromInventoryProvider";
import { MissingMaterialConversionsProvider } from "@/contractor/pages/home/release/components/connections/providers/MissingMaterialConversionsProvider";
import { BuyoutFieldsFragment } from "@/generated/graphql";
import { PoBuyoutDetails } from "../../common/PoBuyoutDetails";

type Props = {
  buyout: BuyoutFieldsFragment;
  poNumber: string;
  updatePoNumber: (poNumber: string) => void;
  poValidation: ConnectionError[];
  loadingPoValidation: boolean;
};

const HostedIntegrationBuyoutInformationWithProviders = ({
  buyout,
  poNumber,
  updatePoNumber,
  poValidation,
  loadingPoValidation,
}: Props) => {
  return (
    <PoBuyoutDetails
      buyout={buyout}
      poNumber={poNumber}
      updatePoNumber={updatePoNumber}
      includedValidations={poValidation}
      loading={loadingPoValidation}
    >
      <MissingMaterialConversionsModal />
      <MissingItemsFromInventory />
    </PoBuyoutDetails>
  );
};

export const HostedIntegrationBuyoutInformation = (props: Props) => (
  <MissingMaterialConversionsProvider>
    <MissingItemsFromInventoryProvider>
      <HostedIntegrationBuyoutInformationWithProviders {...props} />
    </MissingItemsFromInventoryProvider>
  </MissingMaterialConversionsProvider>
);
