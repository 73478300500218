import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";

const Container = tw.div<{
  $rounded: boolean;
}>`flex items-center justify-center border-dashed border border-gray-300 mt-1
  ${({ $rounded }) => ($rounded ? "rounded-lg" : "")}
`;
const Text = tw.div`flex items-center font-thin p-4 text-md gap-2`;
const GrayInfoIcon = tw(InfoOutlined)`text-gray-500 text-xl`;

type Props = {
  translationKey: string;
  filteredTranslationKey?: string;
  isFiltered?: boolean;
  includeWarningIcon?: boolean;
  rounded?: boolean;
};

export const NoResults: FC<Props> = ({
  isFiltered = false,
  translationKey: defaultTranslationKey,
  filteredTranslationKey,
  includeWarningIcon,
  rounded = false,
}) => {
  const intl = useIntl();
  return (
    <Container $rounded={rounded}>
      <Text>
        <If isTrue={includeWarningIcon}>
          <GrayInfoIcon />
        </If>
        {intl.$t({
          id: !isFiltered ? defaultTranslationKey : filteredTranslationKey,
        })}
      </Text>
    </Container>
  );
};
