import { getUserName } from "@/common/utils/users/getUserName";
import { routes } from "@/config/routes";
import {
  Notification,
  NotificationQuoteChangeContextFieldsFragment,
  QuoteStatus,
} from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import { DetailsContainer, MessageBody } from "../common/Notification.styles";
import { BaseNotification, Sender } from "./BaseNotification";

type QuoteChangeNotificationProps = {
  notification: Notification;
};

export const QuoteChangeNotification: FC<QuoteChangeNotificationProps> = ({
  notification,
}) => {
  const context =
    notification.context as NotificationQuoteChangeContextFieldsFragment;
  const intl = useIntl();
  const navigate = useNavigate();
  const onCallback = () => {
    navigate(
      generatePath(routes.bidsWithId, {
        id: context.quote.rfq?.id,
      }),
    );
  };

  const getNotificationMessage = () => {
    switch (context.quoteStatus) {
      case QuoteStatus.Requested:
        return intl.$t(
          { id: "NOTIFICATION_REQUESTED" },
          {
            rfqNumber: context.quote.rfq?.clientIdentifier,
            vendorOrgName: context.quote.sellerOrgLocation.org.name,
          },
        );
      case QuoteStatus.Submitted:
        return intl.$t(
          { id: "NOTIFICATION_SUBMITTED" },
          {
            projectName: context.quote.rfq.project.name,
            vendorOrgName: context.quote.sellerOrgLocation.org.name,
          },
        );
      case QuoteStatus.Withdrawn:
        return intl.$t(
          { id: "NOTIFICATION_WITHDRAWN" },
          {
            projectName: context.quote.rfq.project.name,
            vendorOrgName: context.quote.sellerOrgLocation.org.name,
          },
        );
      case QuoteStatus.Draft:
        return intl.$t(
          { id: "NOTIFICATION_DRAFT" },
          {
            rfqNumber: context.quote.rfq?.clientIdentifier,
          },
        );
      default:
        return "";
    }
  };

  return (
    <BaseNotification notification={notification} onCallback={onCallback}>
      <DetailsContainer>
        <Sender>{getUserName(context?.initiatedBy)}</Sender>
        <MessageBody>{getNotificationMessage()}</MessageBody>
      </DetailsContainer>
    </BaseNotification>
  );
};
