import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { ProjectNameListDetails } from "@/common/components/projects-filter-selector/ProjectNameListDetails";
import { RfqStatusChip } from "@/common/components/statuses/RfqStatusChip";
import {
  FullWidth,
  LgContainer,
  SmContainer,
  SmWideContainer,
  StatusContainer,
  XsContainer,
} from "@/common/layout/ResponsiveClasses";
import { RfqsRfqFieldsFragment } from "@/generated/graphql";
import { FormattedMessage } from "react-intl";
import { ItemsToDelivery } from "../../Rfqs.styles";
import { ArchiveRfqIconButton } from "../ArchiveRfqIconButton";
import { RfqNumber } from "../RfqNumber";
import { QuotesReceived } from "../vendors/QuotesReceived";
import { QuotesRequested } from "../vendors/QuotesRequested";

export const RfqsListItemConfiguration: Array<GridCol<RfqsRfqFieldsFragment>> =
  [
    {
      wrapper: SmWideContainer,
      item: ({ item }) => <RfqNumber rfq={item} />,
      header: <FormattedMessage id="RFQ_NUMBER" />,
    },
    {
      wrapper: FullWidth,
      position: "start",
      item: ({ item }) => <ProjectNameListDetails project={item.project} />,
      header: <FormattedMessage id="PROJECT" />,
    },
    {
      wrapper: SmContainer,
      position: "center",
      item: ({ item }) => (
        <ItemsToDelivery>{item.items.length}</ItemsToDelivery>
      ),
      header: <FormattedMessage id="RFQ_TOTAL_ITEMS" />,
    },
    {
      wrapper: LgContainer,
      position: "center",
      item: ({ item }) => <QuotesReceived rfq={item} />,
      header: <FormattedMessage id="QUOTES_RECEIVED" />,
    },
    {
      wrapper: LgContainer,
      position: "center",
      item: ({ item }) => <QuotesRequested rfq={item} />,
      header: <FormattedMessage id="QUOTES_REQUESTED" />,
    },
    {
      wrapper: StatusContainer,
      position: "center",
      item: ({ item }) => <RfqStatusChip status={item.status} />,
      header: <FormattedMessage id="STATUS" />,
    },
    {
      wrapper: XsContainer,
      item: ({ item }) => (
        <ButtonsContainer>
          <ArchiveRfqIconButton rfq={item} />
        </ButtonsContainer>
      ),
    },
  ];
