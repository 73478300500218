import tw from "tailwind-styled-components";
import { BaseButton } from "../button/BaseButton";
import { If } from "../if/If";

const Container = tw.div`
  relative grid grid-cols-2 w-full bg-gradient-to-r from-blue-400 to-blue-800 h-fit px-6
  place-items-center self-center justify-self-center  
`;

type OptionButtonProps = {
  $selected?: boolean;
  $loading?: boolean;
};

const TabButton = tw(BaseButton)<OptionButtonProps>`
  grid grid-flow-col gap-1 h-14 text-center rounded-none
  font-medium text-blue-500 z-10 hover:bg-blue-400
  cursor-pointer items-center select-none w-full
  first:rounded-tl-lg last:rounded-tr-lg  
  ${({ $selected }: OptionButtonProps) =>
    $selected
      ? "text-gray-800 bg-white hover:bg-white"
      : "text-white bg-blue-400"}
  ${({ $loading }: OptionButtonProps) =>
    $loading ? "opacity-70 animate-pulse cursor-default" : ""}
`;

const Divider = tw.div`
  absolute content-[''] inset-0 my-2
  border-r border-blue-200 border-dashed
`;

const TabButtonContent = tw.div`
  grid grid-flow-col gap-1 items-center justify-center
`;

type Props = {
  tabs?: Array<{
    label: string | JSX.Element;
    viewState: string;
    info?: string | JSX.Element;
  }>;
  activeTab?: string;
  onTabChange?: (viewState: string) => void;
};

export const OverlayPanelTabs = ({
  tabs = [],
  activeTab,
  onTabChange,
}: Props) => {
  return (
    <Container
      style={{ gridTemplateColumns: `repeat(${tabs.length}, minmax(0, 1fr))` }}
    >
      {tabs.map((option, index) => (
        <TabButton
          $selected={option.viewState === activeTab}
          key={option.viewState}
          onClick={() => onTabChange?.(option.viewState)}
          data-testid={`option-item-${option.viewState}`}
        >
          <TabButtonContent>
            {option.label}
            <If
              isTrue={
                index < tabs.length - 1 &&
                option.viewState !== activeTab &&
                tabs[index + 1].viewState !== activeTab
              }
            >
              <Divider />
            </If>
          </TabButtonContent>
        </TabButton>
      ))}
    </Container>
  );
};
