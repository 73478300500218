import { useUser } from "@/common/providers/UserProvider";
import { routes } from "@/config/routes";
import { EnterpriseRole, OrgRole } from "@/generated/graphql";
import { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

export enum Permission {
  canViewPrices = 0,
  canViewInvoices = 1,
}

type Props = PropsWithChildren<{
  permissions?: Permission[];
  roles?: OrgRole[];
  enterpriseRoles?: EnterpriseRole[];
  redirect?: boolean;
}>;

export const PermissionRoleMapping = {
  [Permission.canViewPrices]: [
    OrgRole.OrgAdmin,
    OrgRole.OrgMember,
    OrgRole.OrgAccountant,
  ],
  [Permission.canViewInvoices]: [
    OrgRole.OrgAdmin,
    OrgRole.OrgMember,
    OrgRole.OrgAccountant,
  ],
};

export const OrgRolesWrapper: FC<Props> = ({
  permissions = [],
  roles = [],
  enterpriseRoles = [],
  children,
  redirect,
}) => {
  const { viewer } = useUser();

  if (
    (permissions.length === 0 &&
      roles.length === 0 &&
      enterpriseRoles.length === 0) ||
    (permissions.length &&
      viewer?.orgRoles.some((o) =>
        permissions.some((p) => PermissionRoleMapping[p].includes(o)),
      )) ||
    (roles.length &&
      viewer?.orgRoles.some((orgRole) => roles.includes(orgRole))) ||
    (enterpriseRoles.length &&
      viewer?.enterpriseRoles.some((enterpriseRole) =>
        enterpriseRoles.includes(enterpriseRole),
      ))
  ) {
    return <>{children}</>;
  }

  if (redirect) {
    return <Navigate to={routes.home} replace />;
  }

  return null;
};
