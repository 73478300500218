import { DeleteButton } from "@/common/components/button/DeleteButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { useProjects } from "@/contractor/pages/home/projects/providers/ProjectsProvider";
import { ProjectProjectsFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const ButtonsContainerStyled = tw(ButtonsContainer)`gap-0`;

type Props = {
  project: ProjectProjectsFieldsFragment;
};

export const DisplayProjectActions: FC<Props> = ({ project }) => {
  const { openDialog } = useDialog();
  const { deleteProject } = useProjects();
  const intl = useIntl();

  const handleDelete = useCallback(() => {
    return deleteProject(project.id);
  }, [deleteProject, project.id]);

  if (project.deletedAt) {
    return null;
  }
  return (
    <ButtonsContainerStyled>
      <If isTrue={!project.deletedAt}>
        <DeleteButton
          onClick={() =>
            openDialog({
              cancelButtonText: intl.$t({ id: "CANCEL" }),
              confirmButtonText: intl.$t({ id: "PROCEED" }),
              icon: <WarningIcon />,
              title: intl.$t({ id: "PROJECT_REMOVE_PROJECT_QUESTION" }),
              text: intl.$t({ id: "PROJECT_REMOVE_PROJECT_TEXT" }),
              handleConfirm: handleDelete,
            })
          }
        />
      </If>
    </ButtonsContainerStyled>
  );
};
