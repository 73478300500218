import { ProjectMaterialSkuFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { If } from "../if/If";
import { ProductInfo } from "../product/ProductInfo";
import { MaterialType } from "./MaterialType";

type Props = {
  material: MaterialType;
};

export const MaterialProductInfo: FC<Props> = ({ material }) => (
  <If isTrue={(material as ProjectMaterialSkuFieldsFragment)?.description}>
    <ProductInfo
      sku={material as ProjectMaterialSkuFieldsFragment}
      brand={(material as ProjectMaterialSkuFieldsFragment)?.manufacturer.name}
    />
  </If>
);
