import { DistributorQuotesAuxiliaryQuoteItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";
import { UnitInput } from "../common/UnitInput";

type Props = {
  item: DistributorQuotesAuxiliaryQuoteItemFieldsFragment;
  readonly: boolean;
  index?: number;
  error?: boolean;
};

export const DistributorQuoteAuxiliaryUnitInput: FC<Props> = ({
  item,
  ...props
}) => {
  const { updateQuote } = useDistributorQuote();
  const intl = useIntl();

  const onChange = useCallback(
    async (quantityDecimal: string | null | undefined) => {
      await updateQuote({
        updatedAuxiliaryItems: [
          {
            auxiliaryItemId: item.id,
            quantityDecimal,
          },
        ],
      });
    },
    [item.id, updateQuote],
  );

  return (
    <UnitInput
      {...props}
      quantity={item.quantityDecimal}
      uom={item.uom}
      onChange={onChange}
      resetMessageIfEmpty={intl.$t({
        id: "DISTRIBUTOR_QUOTE_AUX_ITEM_VALIDATION_MISSING_QUANTITY",
      })}
      hideErrorIcon
    />
  );
};
