import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  AddOrgLocationInput,
  LocationsDocument,
  OrgLocationExtendedFieldsFragment,
  UpdateOrgLocationInput,
  ViewerDocument,
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useLocationsQuery,
  useUpdateLocationMutation,
} from "@/generated/graphql";
import {
  NoFunctionBooleanPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import { FC, createContext, useContext, useMemo } from "react";

type LocationsProviderContextType = {
  locations: OrgLocationExtendedFieldsFragment[];
  loading: boolean;
  createLocation: (
    locationInput: AddOrgLocationInput,
  ) => Promise<string | null | undefined>;
  updateLocation: (
    locationInput: UpdateOrgLocationInput,
  ) => Promise<string | null | undefined>;
  removeLocation: (locationId: string) => Promise<boolean | undefined>;
};

const LocationsProviderContext = createContext<LocationsProviderContextType>({
  locations: [],
  loading: false,
  createLocation: NoFunctionUndefinedPromise,
  updateLocation: NoFunctionUndefinedPromise,
  removeLocation: NoFunctionBooleanPromise,
});

export const LocationsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data, loading: queryLoading } = useLocationsQuery();
  const [createNewLocation, { loading: newLocationLoading }] =
    useCreateLocationMutation();
  const [updateExistingLocation, { loading: updateLocationLoading }] =
    useUpdateLocationMutation();
  const [deleteLocation] = useDeleteLocationMutation();
  const { setError } = useGlobalError();

  const loading = useMemo(() => {
    return queryLoading || newLocationLoading || updateLocationLoading;
  }, [queryLoading, newLocationLoading, updateLocationLoading]);

  const updateLocation = async (locationInput: UpdateOrgLocationInput) => {
    try {
      const { id, phone, name, address } = locationInput;
      const { data: updatedLocation, errors } = await updateExistingLocation({
        variables: {
          input: {
            name,
            id,
            address: {
              addressLine1: address.addressLine1,
              addressLine2: address.addressLine2,
              city: address.city,
              country: address.country,
              postalCode: address.postalCode,
              state: address.state,
            },
            phone,
          },
        },
        refetchQueries: [
          {
            query: LocationsDocument,
          },
          {
            query: ViewerDocument,
          },
        ],
      });
      setError(errors);
      if (updatedLocation?.updateLocation?.id) {
        return updatedLocation?.updateLocation?.id;
      }
    } catch (errors) {
      setError(errors);
    }
  };

  const createLocation = async (locationInput: AddOrgLocationInput) => {
    const { orgId, phone, name, address } = locationInput;
    if (orgId) {
      try {
        const { data: newLocation, errors } = await createNewLocation({
          variables: {
            input: {
              name: name ?? null,
              orgId,
              address: {
                addressLine1: address.addressLine1,
                addressLine2: address.addressLine2,
                city: address.city,
                country: address.country,
                postalCode: address.postalCode,
                state: address.state,
              },
              phone,
            },
          },
          refetchQueries: [
            {
              query: LocationsDocument,
            },
            {
              query: ViewerDocument,
            },
          ],
        });
        setError(errors);
        if (newLocation?.addNewLocation?.id) {
          return newLocation?.addNewLocation?.id;
        }
      } catch (errors) {
        setError(errors);
      }
    }
  };

  const removeLocation = async (id: string) => {
    try {
      if (id) {
        const { errors } = await deleteLocation({
          variables: {
            input: {
              id,
            },
          },
          refetchQueries: [
            {
              query: LocationsDocument,
            },
            {
              query: ViewerDocument,
            },
          ],
        });

        setError(errors);
        return !errors;
      }
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  return (
    <LocationsProviderContext.Provider
      value={{
        locations:
          data?.locations?.edges?.map((location) => location.node) || [],
        loading,
        createLocation,
        updateLocation,
        removeLocation,
      }}
    >
      {children}
    </LocationsProviderContext.Provider>
  );
};

export const useLocations = (): LocationsProviderContextType =>
  useContext(LocationsProviderContext);
