import { LinearProgress } from "@mui/material";
import { FC, ReactNode } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";

const Container = tw.div`flex flex-col px-2`;
const Label = tw.div`text-gray-600 text-xs`;

type Props = {
  progress: number;
  className?: string;
  label?: string | ReactNode;
};

export const Progress: FC<Props> = ({ progress, label, className }) => {
  return (
    <Container className={className}>
      <If isTrue={label}>
        <Label>{label}</Label>
      </If>
      <LinearProgress value={progress * 100} variant="determinate" />
    </Container>
  );
};
