import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  InvoiceValidationFieldsFragment,
  ReceiptFieldsFragment,
  ReceiptToExportFieldsFragment,
  SourceSystem,
  useInvoiceValidationQuery,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useLinkReceipt } from "../hooks/useLinkReceipt";
import { useValidateReceipt } from "../hooks/useValidateReceipt";
import { useExportReceipt } from "./ExportReceiptProvider";
import { useReceiptsByIds } from "./ReceiptsByIdsProvider";
type ProviderContextType = {
  linkReceipt: () => Promise<boolean | undefined | string>;
  receiptValidation?: InvoiceValidationFieldsFragment;
  refetchReceiptValidation: () => void;
  loading: boolean;
  poNumber: string | null;
  setPoNumber: (poNumber: string) => void;
};

type Props = {
  children: React.ReactNode;
  receipt: ReceiptToExportFieldsFragment | ReceiptFieldsFragment;
  skip?: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  linkReceipt: NoFunctionBooleanPromise,
  receiptValidation: undefined,
  refetchReceiptValidation: NoFunctionBooleanPromise,
  loading: false,
  poNumber: "",
  setPoNumber: NoFunction,
});

export const ReceiptValidationProvider: FC<Props> = ({
  children,
  receipt,
  skip,
}) => {
  const { linkReceipt: linkReceiptMutation } = useLinkReceipt();
  const { connectedSourceSystem } = useOrgSettings();
  const { setValidatedReceipt } = useReceiptsByIds();
  const [invoiceValidation, setReceiptValidation] =
    useState<InvoiceValidationFieldsFragment>();
  const [poNumber, setPoNumber] = useState<string | null>(null);
  const { clearErrors } = useExportReceipt();
  const { isValidReceipt } = useValidateReceipt();

  useEffect(() => {
    if (poNumber) {
      clearErrors();
    }
  }, [poNumber, clearErrors]);

  const { data, error, refetch, loading } = useInvoiceValidationQuery({
    variables: {
      input: {
        sourceSystem: connectedSourceSystem || SourceSystem.Foundation,
        invoiceId: receipt.id,
        poNumber:
          poNumber === null
            ? receipt.release?.poNumber || receipt.poNumber
            : poNumber,
      },
    },
    skip: !receipt.release || !connectedSourceSystem || skip,
    fetchPolicy: "cache-and-network",
  });
  useErrorEffect(error);

  useEffect(() => {
    if (data?.invoiceValidation || !loading) {
      const validated = isValidReceipt(data?.invoiceValidation);
      setValidatedReceipt({
        id: receipt.id,
        loading: false,
        validated,
      });
      setReceiptValidation(data?.invoiceValidation);
    }
  }, [
    data?.invoiceValidation,
    isValidReceipt,
    loading,
    receipt.id,
    setValidatedReceipt,
  ]);

  useEffect(() => {
    if (loading) {
      setValidatedReceipt({
        id: receipt.id,
        loading: true,
        validated: isValidReceipt(invoiceValidation),
      });
    }
  }, [
    loading,
    invoiceValidation,
    receipt.id,
    setValidatedReceipt,
    isValidReceipt,
  ]);

  const linkReceipt = async () => {
    if (receipt && connectedSourceSystem) {
      return await linkReceiptMutation({
        invoiceId: receipt?.id,
        sourceSystem: connectedSourceSystem,
      });
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        linkReceipt,
        receiptValidation: invoiceValidation,
        refetchReceiptValidation: refetch,
        loading,
        poNumber,
        setPoNumber,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReceiptValidation = (): ProviderContextType =>
  useContext(ProviderContext);
