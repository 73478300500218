import { ExtraOption } from "@/common/providers/ColumnMapperProvider";
import { AutocompleteEditor } from "handsontable/editors";

export class AutocompleteWithPlaceholder extends AutocompleteEditor {
  open() {
    super.open();
    setTimeout(() => {
      const input = this.TEXTAREA as HTMLInputElement;
      const placeholder = this.cellProperties.editorPlaceholder as string;
      if (placeholder) {
        input.setAttribute("placeholder", placeholder);
      }
    }, 0);
  }

  updateChoicesList(choicesList: string[]): void {
    const extraOptions = this.cellProperties.extraOptions?.map(
      (item: ExtraOption) =>
        `${item.name}${item.content ? ` ${item.content.outerHTML}` : ""}`,
    );

    if (!extraOptions) {
      super.updateChoicesList(choicesList);
      return;
    }
    super.updateChoicesList([
      ...extraOptions,
      ...choicesList.filter((choice) =>
        this.cellProperties.extraOptions?.every(
          (item: ExtraOption) => item.name !== choice,
        ),
      ),
    ]);
  }

  getValue(): string {
    const value = super.getValue();
    if (!value) {
      return value;
    }
    const valueInParentheses = value.match(/⟮(.*)⟯/)?.[0] || "";
    return value.replace(/<.*>/, valueInParentheses);
  }

  close() {
    super.close();
    const input = this.TEXTAREA as HTMLInputElement;
    input.removeAttribute("placeholder");
  }
}
