import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";

import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import Handsontable from "handsontable";
import { textRenderer } from "handsontable/renderers";
import { ColumnSettings } from "handsontable/settings";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useTableHelpers } from "../hooks/useTableHelpers";
import { useRenderHelpers } from "./useRenderHelpers";

type MaterialRenderer = (
  instance: Handsontable,
  td: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: string,
  cellProperties: Handsontable.CellProperties,
) => void;

export const useMaterialRenderer = () => {
  const intl = useIntl();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { getNameParts, isBuyoutItem, getPhysicalColumnIndex } =
    useTableHelpers();
  const { applyTooltip, checkReadOnly, applyClasses } = useRenderHelpers();
  const { getMetadata } = useColumnMapper();

  const renderer: MaterialRenderer = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      textRenderer(instance, td, row, col, prop, value, cellProperties);

      if (!value) {
        return;
      }
      applyClasses(td, "htMiddle relative pr-3");
      checkReadOnly(td, row, instance, cellProperties);

      const rows = instance?.getData();
      const columns = instance?.getSettings().columns;
      const autocompleteOptions: string[] = cellProperties.source as string[];
      if (!rows || !columns) {
        return;
      }

      const additionData: string[] = [];
      (columns as ColumnSettings[]).forEach((c, index) => {
        if (
          c.additional === (columns as ColumnSettings[])[col].columnType &&
          rows[row][index]
        ) {
          additionData.push(`${c.header} = ${rows[row][index]}`);
        }
      });

      if (additionData.length) {
        td.innerText = td.innerText.replace(/,.*(=.*)/, "");
        const container = document.createElement("span");
        container.className = "text-green-600 font-light";
        container.innerText = `, ${additionData.join(", ")}`;
        td.appendChild(container);
      }

      const { namePart, partNumber } = getNameParts(value);
      if (partNumber) {
        td.innerText = namePart;
        const partText = document.createElement("span");
        partText.className = "text-gray-600 font-light text-xs select-none";
        partText.innerText = `〈${partNumber}〉`;
        td.appendChild(partText);

        applyTooltip(
          partText,
          intl.formatMessage({ id: "CUSTOM_PART_NUMBER_NOTICE" }),
        );
      }

      const isLumpSumItem = isLumpSumUomText(
        rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.UOM)],
      );

      if (
        value &&
        autocompleteOptions?.length &&
        !autocompleteOptions.includes(value.toString()) &&
        !isLumpSumItem
      ) {
        td.className = "htMiddle text-blue-800 bg-blue-100";
        const newSup = document.createElement("sup");
        newSup.className = "text-blue-500 font-semibold text-[8px] ml-1";
        newSup.innerText = intl.formatMessage({ id: "NEW" });
        td.appendChild(newSup);
      }

      const buyoutItem = cellProperties.extraOptions
        ? isBuyoutItem(
            rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Material)],
            rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.Vendor)],
            rows[row][
              getPhysicalColumnIndex(instance, COLUMN_TYPE.Manufacturer)
            ],
            rows[row][
              getPhysicalColumnIndex(instance, COLUMN_TYPE.PrefilledPrice)
            ] ||
              rows[row][
                getPhysicalColumnIndex(instance, COLUMN_TYPE.UnitPrice)
              ],
            rows[row][getPhysicalColumnIndex(instance, COLUMN_TYPE.CostCode)],
          )
        : null;

      if (buyoutItem) {
        const buyoutText = document.createElement("span");
        buyoutText.className = "text-gray-500";
        buyoutText.innerText = ` ⟮${buyoutItem.costCode?.description || unassignedCostCode.description}, ${buyoutItem.quantityDecimal} ${buyoutItem.projectItem.estimateUom.pluralDescription}⟯`;
        const bubble = document.createElement("span");
        bubble.className = "absolute top-1/4 right-1 text-green-800";
        bubble.innerText = "●";
        applyTooltip(bubble, intl.formatMessage({ id: "BUYOUT_ITEM_NOTICE" }));
        td.appendChild(bubble);
        td.appendChild(buyoutText);
      }

      const meta = getMetadata(row);

      if (meta?.warningIconTooltipText) {
        const manuallyAddedItemCount = document.createElement("span");
        manuallyAddedItemCount.className = `
          absolute inline-grid top-0 right-2 place-items-center h-full
        `;
        const priceIcon = document.createElement("span");
        priceIcon.innerText = "i";
        priceIcon.className = `
          text-center border font-semibold border-red-500 rounded-full 
          text-2xs text-red-500 w-3.5 h-3.5
          bg-white hover:text-white hover:bg-red-500
        `;
        manuallyAddedItemCount.appendChild(priceIcon);
        td.appendChild(manuallyAddedItemCount);

        applyTooltip(priceIcon, meta?.warningIconTooltipText.toString());
      }
    },
    [
      applyClasses,
      applyTooltip,
      checkReadOnly,
      getNameParts,
      getPhysicalColumnIndex,
      intl,
      isBuyoutItem,
      unassignedCostCode.description,
      getMetadata,
    ],
  );

  return renderer;
};
