import { EstimationSoftwareSelector } from "@/common/components/estimation-software-selector/EstimationSoftwareSelector";
import { InfoText } from "@/common/components/info-text/InfoText";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { FileUploadArea } from "@/common/components/upload/FileUploadArea";
import { useUser } from "@/common/providers/UserProvider";
import { IntegrationType } from "@/generated/graphql";
import { FormControl } from "@mui/material";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  ImportMaterialsProvider,
  useImportMaterials,
} from "./ImportMaterialsProvider";

type ImportMaterialsSelectionProps = {
  onClose: () => void;
};

const FormControlStyled = tw(FormControl)`
  inline mb-8
`;

const UploadAreaContainer = tw.div`
  grid gap-2 h-full
`;

const ImportMaterialsSelectionWithProvider: FC<
  ImportMaterialsSelectionProps
> = ({ onClose }) => {
  const [integrationType, setIntegrationType] = useState<IntegrationType>(
    "" as IntegrationType,
  );
  const [file, setFile] = useState<File>();
  const [loadingError, setLoadingError] = useState<unknown>();
  const { viewer } = useUser();
  const intl = useIntl();
  const { importMaterials, loading } = useImportMaterials();

  const importMaterialsOrg = async () => {
    if (file && integrationType) {
      try {
        setLoadingError(undefined);
        const result = await importMaterials(
          viewer?.org.id || "",
          integrationType,
          file,
        );

        if (!result) {
          return;
        }

        onClose();
      } catch (error) {
        setLoadingError(error);
      }
    }
  };

  const onSoftwareChanged = (integrationType: IntegrationType) => {
    setIntegrationType(integrationType);
  };

  return (
    <OverlayPanel
      title={intl.$t({ id: "IMPORT_MATERIAL_LIST" })}
      onSave={importMaterialsOrg}
      disableSave={!integrationType || !file}
      onCancel={onClose}
      saving={loading}
    >
      <>
        <FormControlStyled>
          <EstimationSoftwareSelector
            value={integrationType}
            onChange={onSoftwareChanged}
            integrationTypes={[IntegrationType.Oncenter]}
          />
        </FormControlStyled>
        <UploadAreaContainer>
          {integrationType ? (
            <FileUploadArea
              onChange={(files) => setFile(files[0])}
              error={loadingError}
            />
          ) : null}
          <InfoText
            title="DID_YOU_KNOW"
            body="DID_YOU_KNOW_CUSTOM_MATERIALS_TEXT"
          />
        </UploadAreaContainer>
      </>
    </OverlayPanel>
  );
};

export const ImportMaterialsSelection: FC<ImportMaterialsSelectionProps> = ({
  onClose,
}) => (
  <ImportMaterialsProvider>
    <ImportMaterialsSelectionWithProvider onClose={onClose} />
  </ImportMaterialsProvider>
);
