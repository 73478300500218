import { QuantityPicker } from "@/common/components/quantity-picker/QuantityPicker";
import { useEstimatedItems } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import { UpdateContractorReleaseInput } from "@/generated/graphql";
import { FC, useCallback } from "react";
import tw from "tailwind-styled-components";
import { useReleaseActions } from "../../providers/ReleaseActionsProvider";
import {
  ExpandedReleaseItem,
  useRelease,
} from "../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";

const Container = tw.div`
  w-full
`;

type Props = {
  item: ExpandedReleaseItem;
  readonly?: boolean;
};

export const ReleaseItemQuantityInput: FC<Props> = ({
  item,
  readonly = false,
}) => {
  const { release } = useRelease();
  const { updateRelease } = useReleaseUpdate();
  const { buyoutItemIds, inputErrors } = useReleaseActions();
  const { setNewProjectEstimatedItem } = useEstimatedItems();

  const saveQuantity = useCallback(
    async (quantityDecimal: string | null) => {
      if (item.id) {
        if (release) {
          const input: UpdateContractorReleaseInput = {
            releaseId: release?.id,
            version: release?.version,
            updates: [
              {
                releaseItemId: item.id,
                quantityDecimal,
              },
            ],
          };
          await updateRelease(input);
        }
      } else {
        setNewProjectEstimatedItem([
          {
            key: "quantityDecimal",
            value: quantityDecimal,
          },
        ]);
      }
    },
    [release, item.id, updateRelease, setNewProjectEstimatedItem],
  );

  return (
    <Container>
      <QuantityPicker
        quantity={item.quantityDecimal}
        id={item.id}
        saveQuantity={saveQuantity}
        staticText={readonly}
        error={
          buyoutItemIds.includes(item.buyoutItem?.id ?? "") &&
          inputErrors.length > 0
        }
        className="bg-white"
        hideErrorIcon
      />
    </Container>
  );
};
