import {
  OrgRolesWrapper,
  Permission,
} from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { ProjectFieldsFragment, RfqFieldsFragment } from "@/generated/graphql";
import { FC, PropsWithChildren } from "react";
import tw from "tailwind-styled-components";
import { RfqForm } from "./RfqForm";

const Content = tw.div`flex items-center`;

type ToggleRfqFormProps = PropsWithChildren<{
  rfq?: RfqFieldsFragment | null | undefined;
  project?: ProjectFieldsFragment | null;
  label: string;
  disabled?: boolean;
  hideVendors?: boolean;
}>;

export const ToggleRfqForm: FC<ToggleRfqFormProps> = ({
  children,
  hideVendors,
  ...props
}) => {
  return (
    <OrgRolesWrapper permissions={[Permission.canViewPrices]}>
      <DrawerPanel
        anchor={(togglePanel) => (
          <Content
            onClick={() => !props.disabled && togglePanel(true)}
            data-testid="rfq-number"
          >
            {children}
          </Content>
        )}
        content={(togglePanel) => (
          <RfqForm
            onClose={() => togglePanel(false)}
            hideVendors={hideVendors}
            {...props}
          />
        )}
      />
    </OrgRolesWrapper>
  );
};
