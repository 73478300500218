import {
  AssetContext,
  NoteDocumentSummaryFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ExpandablePanel } from "../expandable-panel/ExpandablePanel";
import { If } from "../if/If";
import { SupportedFormats, UploadAsset } from "../upload-asset/UploadAsset";
import { UploadAssetProvider } from "../upload-asset/UploadAssetProvider";
import {
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPE,
  PDF_EXTENSIONS,
  PDF_MIME_TYPE,
} from "../upload/FileUploadArea";

type Props = {
  noteDocument?: NoteDocumentSummaryFieldsFragment | null;
  readonly?: boolean;
  customRedirect?: () => void;
  children?: React.ReactNode;
};

const NoteDocumentPanelWithProvider: FC<Props> = ({
  readonly,
  noteDocument,
  customRedirect,
  children,
}) => {
  const intl = useIntl();

  if (readonly && !noteDocument?.asset && !children) {
    return null;
  }

  return (
    <ExpandablePanel
      header={intl.$t({ id: "NOTE_DOCUMENT_PANEL_TITLE" })}
      isOpened={true}
    >
      <If isTrue={children}>{children}</If>
      <If isTrue={!children}>
        <UploadAsset
          customRedirect={customRedirect}
          accept={{
            [PDF_MIME_TYPE]: PDF_EXTENSIONS,
            [IMAGE_MIME_TYPE]: IMAGE_EXTENSIONS,
          }}
          limit={1}
          xs
          mode="horizontal"
          className="flex items-start"
          readonly={readonly}
        >
          <If isTrue={!noteDocument?.asset}>
            <SupportedFormats>
              <FormattedMessage id="NOTE_DOCUMENT_SUPPORTED_FORMATS" />
            </SupportedFormats>
          </If>
        </UploadAsset>
      </If>
    </ExpandablePanel>
  );
};

export const NoteDocumentPanel: FC<Props> = (props) => {
  return (
    <UploadAssetProvider
      initialAssets={props.noteDocument ? [props.noteDocument.asset] : []}
      context={AssetContext.NoteDocument}
    >
      <NoteDocumentPanelWithProvider {...props} />
    </UploadAssetProvider>
  );
};
