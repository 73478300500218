import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { useExternalGeneralLedgerAccounts } from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/steps/general-ledger-account/ExternalGeneralLedgerAccountsProvider";
import { ToJobGeneralLedgerAccount } from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/steps/general-ledger-account/ToJobGeneralLedgerAccount";
import { ExternalLedgerAccountFieldsFragment } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";

type ProviderContextType = {
  openModal: () => void;
  closeModal: () => void;
  open: boolean;
  pages: WizardModalPage[];
  selectedToJobLedgerAccountName: string | null;
  setSelectedToJobLedgerAccountName: (value: string | null) => void;
  existingLedgerAccount: ExternalLedgerAccountFieldsFragment | undefined;
};

const ProviderContext = createContext<ProviderContextType>({
  openModal: NoFunction,
  closeModal: NoFunction,
  pages: [],
  open: false,
  selectedToJobLedgerAccountName: null,
  setSelectedToJobLedgerAccountName: NoFunction,
  existingLedgerAccount: undefined,
});

export const ExternalLedgerAccountProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const { externalLedgerAccounts } = useExternalGeneralLedgerAccounts();
  const [selectedToJobLedgerAccountName, setSelectedToJobLedgerAccountName] =
    useState<string | null>(null);

  const openModal = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const cancelModal = useCallback(() => {
    setOpen(false);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  const pages: WizardModalPage[] = useMemo(
    () => [
      {
        title: intl.$t({ id: "GENERAL_LEDGER_ACCOUNT_NUMBER" }),
        pages: [
          {
            component: (
              <ToJobGeneralLedgerAccount
                onChange={(item) =>
                  setSelectedToJobLedgerAccountName(item?.name || "")
                }
              />
            ),
            onCloseClick: cancelModal,
          },
        ],
      },
    ],
    [cancelModal, intl],
  );

  return (
    <ProviderContext.Provider
      value={{
        openModal,
        closeModal,
        pages,
        open,
        selectedToJobLedgerAccountName,
        setSelectedToJobLedgerAccountName,
        existingLedgerAccount: externalLedgerAccounts.find(
          (e) =>
            e.name.toLowerCase() ===
            selectedToJobLedgerAccountName?.toLowerCase(),
        ),
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useExternalLedgerAccount = (): ProviderContextType =>
  useContext(ProviderContext);
