import { ExpandablePanel } from "@/common/components/expandable-panel/ExpandablePanel";
import {
  SupportedFormats,
  UploadAsset,
} from "@/common/components/upload-asset/UploadAsset";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import {
  AssetContext,
  AssetFieldsFragment,
  AssetType,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

type Props = {
  updateAssets: (assets: AssetFieldsFragment[]) => void;
};

const DeliverySlipPhotosPanelWithProvider: FC<Props> = ({ updateAssets }) => {
  const intl = useIntl();

  return (
    <ExpandablePanel
      header={intl.$t({ id: "DELIVERY_PHOTOS_HEADER" })}
      isOpened={true}
    >
      <UploadAsset
        onChange={updateAssets}
        xs
        mode="horizontal"
        className="flex items-start"
      >
        <SupportedFormats>
          <FormattedMessage id="DELIVERY_PHOTOS_SUPPORTED_FORMATS" />
        </SupportedFormats>
      </UploadAsset>
    </ExpandablePanel>
  );
};

export const DeliverySlipReleasePhotosPanel: FC<Props> = (props) => {
  return (
    <UploadAssetProvider
      context={AssetContext.DeliveryImage}
      type={AssetType.Image}
    >
      <DeliverySlipPhotosPanelWithProvider {...props} />
    </UploadAssetProvider>
  );
};
