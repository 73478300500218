import { CategoryState } from "@/common/hooks/useToggleCategory";
import { ProjectItemFieldsFragment } from "@/generated/graphql";

type ZoneCategory = CategoryState<CategoryState<ProjectItemFieldsFragment>>;

export const toggleZoneCostCode = (
  costCodeId: string,
  zoneId: string,
  zones: ZoneCategory[],
) => {
  const zoneIndex = zones.findIndex((z) => z.id === zoneId);
  const zone = zones[zoneIndex];
  const costCodeIndex = zone.items.findIndex((c) => c.id === costCodeId);
  const costCode = zone.items[costCodeIndex];
  return [
    ...zones.slice(0, zoneIndex),
    {
      ...zone,
      items: [
        ...zone.items.slice(0, costCodeIndex),
        { ...costCode, isOpened: !costCode.isOpened },
        ...zone.items.slice(costCodeIndex + 1),
      ],
    },
    ...zones.slice(zoneIndex + 1),
  ];
};
