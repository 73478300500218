import { Address } from "@/common/components/address/Address";
import { If } from "@/common/components/if/If";
import { ProjectFieldsFragment } from "@/generated/graphql";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-row gap-4`;
const Details = tw.div`flex flex-col gap-1`;
const Item = tw.div`flex`;
const Label = tw.div`flex mr-1 font-normal`;
const InlineLabel = tw(Label)`inline`;
const AddressStyled = tw(Address)`ml-1 inline`;

type Props = {
  project: Pick<ProjectFieldsFragment, "name" | "address">;
  includeName?: boolean;
  details?: { label: string; value: string | React.JSX.Element };
};

export const ProjectInfoHeader: FC<Props> = ({
  project,
  includeName = true,
  details,
}) => {
  return (
    <Container>
      <Details>
        <If isTrue={includeName}>
          <Item>
            <Label>
              <FormattedMessage tagName="strong" id="PROJECT_TITLE" />
            </Label>
            {project.name}
          </Item>
        </If>
        <Item>
          <InlineLabel>
            <FormattedMessage tagName="strong" id="LOCATION_TITLE" />
            <AddressStyled inline address={project?.address} />
          </InlineLabel>
        </Item>
        <If isTrue={details}>
          <Item>
            <Label>
              <FormattedMessage tagName="strong" id={details?.label} />:
            </Label>
            {details?.value}
          </Item>
        </If>
      </Details>
    </Container>
  );
};
