import { useUser } from "@/common/providers/UserProvider";
import tw from "tailwind-styled-components";
import { useOrgProfile } from "../org-profile/hooks/useOrgProfile";
import { OrgSettingsForm } from "./components/OrgSettingsForm";
import { OrgSettingsFormHookProvider } from "./components/OrgSettingsFormHookProvider";

const Container = tw.div`pt-12`;

export const OrgSettings = () => {
  const { isContractor } = useUser();
  const { viewerOrg } = useOrgProfile();

  if (!isContractor || !viewerOrg) {
    return null;
  }

  return (
    <Container>
      <OrgSettingsFormHookProvider>
        <OrgSettingsForm />
      </OrgSettingsFormHookProvider>
    </Container>
  );
};
