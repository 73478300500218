import { GroupToggle } from "@/common/components/group-toggle/GroupToggle";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DateView } from "@/common/utils/dates/DateView";
import { routes } from "@/config/routes";
import { BuyoutReleaseFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";

const Container = tw.div`flex items-center cursor-pointer`;
const DeliveryInfo = tw.div`flex flex-col`;
const DeliveryNumber = tw.div`flex gap-1`;
const DeliveryArchived = tw.div`flex text-xs font-light items-end`;
const DeliveryDate = tw.div`text-xs font-light truncate w-44`;
const LinkLikeStyled = tw(LinkLike)`text-base`;
const GroupToggleStyled = tw(GroupToggle)`mr-6`;

type Props = {
  release: BuyoutReleaseFieldsFragment;
  isOpened: boolean;
};

export const ReleaseDetails: FC<Props> = ({ release, isOpened }) => {
  return (
    <Container>
      <GroupToggleStyled isOpened={isOpened} />
      <DeliveryInfo>
        <DeliveryNumber>
          <LinkLikeStyled
            to={generatePath(routes.delivery, { deliveryId: release.id })}
          >
            <FormattedMessage
              id={
                !release.sequenceNumber
                  ? "RELEASE_STATUS_DRAFT"
                  : "DELIVERY_WITH_NUMBER"
              }
              values={{ number: release.sequenceNumber }}
            />
          </LinkLikeStyled>
          <If isTrue={release.deletedAt}>
            <DeliveryArchived>
              (
              <FormattedMessage id="RELEASE_STATUS_ARCHIVED" />)
            </DeliveryArchived>
          </If>
        </DeliveryNumber>
        <DeliveryDate>
          <DateView date={release.time} includeTime />
        </DeliveryDate>
      </DeliveryInfo>
    </Container>
  );
};
