import { Checkbox } from "@/common/components/checkbox/Checkbox";
import { If } from "@/common/components/if/If";
import { PageRange } from "@/generated/graphql";
import {
  ArrowDownward,
  CheckBox,
  CheckBoxOutlineBlank,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { AssetProvider, useAsset } from "../../providers/AssetProvider";
import {
  PageRangeWithExcludedPages,
  SplitType,
  useSplittingInvoicesWizard,
} from "./SplittingInvoicesWizardProvider";

const DATA_TRANSFER = "text/plain";

const Container = tw.div`h-full flex flex-col gap-1 bg-blue-400 overflow-scroll py-2 w-32 z-70`;
const Page = tw.div`mx-4`;
const Details = tw.div`text-center text-xs text-white px-2 pt-2 pb-0`;
const InvoiceTitle = tw.div`text-white text-xs bg-blue-500 rounded-xl text-center mb-3 border-`;
const Image = tw.img<{ $excluded?: boolean; $selected: boolean }>`
  border-transparent border
  ${({ $excluded }) => ($excluded ? "opacity-10" : "hover:opacity-90 p-2")}
  ${({ $selected }) =>
    $selected && "border border-blue-800 border-dashed rounded-md"})}
  h-[95px] w-[80px] text-center hover:cursor-pointer active:cursor-no-drop hover:shadow-md`;
const ImageContainer = tw.div`flex justify-center active:cursor-no-drop relative`;
const Line = tw.div`border-b border-white border-dashed cursor-grab active:cursor-grabbing w-full`;
const HeaderContainer = tw.div`bg-blue-800/40 mb-4 mt-3 hover:px-3`;
const LineContainer = tw.div<{
  draggable?: boolean;
  $dropping?: boolean;
  $header?: boolean;
}>`
${({ draggable, $dropping }) => (draggable || $dropping ? "my-4" : "")}
  ${({ $header }) => ($header ? "my-0" : "mt-2")}
  cursor-grab relative
  focus:my-4 py-3 flex items-center justify-center h-3 w-full flex-col`;
const KeyboardArrowUpStyled = tw(KeyboardArrowUp)`text-white text-sm`;
const KeyboardArrowDownStyled = tw(KeyboardArrowDown)`text-white text-sm`;
const CheckboxStyled = tw(
  Checkbox,
)`absolute top-1 -left-1 z-50 rounded-none p-0`;
const PageRangesContainer = tw.div`flex flex-col gap-2`;
const Excluded = tw.div`absolute bottom-1/2 text-2xs text-white`;
const Drop = tw.div`absolute top-0 z-70 text-white bg-blue-400 mx-4 cursor-move text-sm`;
const ArrowDownwardStyled = tw(ArrowDownward)`animate-bounce text-white`;

type Props = {
  page: number;
  type: SplitType;
};
const PageRangesWithProvider: FC<Props> = ({ page: selectedPage, type }) => {
  const { thumbnails } = useAsset();
  const {
    pageRanges,
    selectedPages,
    setSelectedPages,
    setSelectedPage,
    setPageRanges,
  } = useSplittingInvoicesWizard();
  const [dragging, setDragging] = useState(false);
  const [draggingSource, setDraggingSource] = useState<number | null>(null);
  const [draggingTarget, setDraggingTarget] = useState<number | null>(null);
  const [restructured, setRestructured] = useState(false);
  const hasInterval = useCallback(
    (pageRanges: PageRange[], start: number, end: number) =>
      pageRanges.some(
        (pageRange) =>
          (pageRange.start <= start && pageRange.end >= end) ||
          (pageRange.start >= start && pageRange.end <= end),
      ),
    [],
  );
  const onDragEnd = useCallback(() => {
    setDragging(false);
    setDraggingSource(null);
    setDraggingTarget(null);
  }, []);

  useEffect(() => {
    if (type === SplitType.Split && thumbnails.length && !restructured) {
      const minPage = pageRanges[0]?.start;
      const maxPage = pageRanges[pageRanges.length - 1]?.end
        ? pageRanges[pageRanges.length - 1]?.end + 1
        : 1;
      let newPageRanges: Array<PageRangeWithExcludedPages> = [];
      for (let i = 1; i < minPage; i++) {
        newPageRanges.push({
          start: i,
          end: i,
          excluded: true,
        });
      }
      newPageRanges = [...newPageRanges, ...pageRanges];
      for (let i = maxPage; i <= thumbnails.length; i++) {
        newPageRanges.push({
          start: i,
          end: i,
          excluded: true,
        });
      }
      setPageRanges(newPageRanges);
      setRestructured(true);
      setSelectedPage(pageRanges[0]?.start);
    }
  }, [
    pageRanges,
    restructured,
    setPageRanges,
    setSelectedPage,
    thumbnails,
    type,
  ]);

  const getInvoiceIndex = useCallback(
    (page: PageRange) =>
      pageRanges
        .filter((p) => !p.excluded)
        .findIndex((p) => p.start === page.start && p.end === page.end),
    [pageRanges],
  );
  const getPages = (pageRange: PageRange) => {
    const pages = [];
    for (let i = pageRange.start; i <= pageRange.end; i++) {
      pages.push(i);
    }
    return pages;
  };

  const onDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    position: number,
  ) => {
    e.dataTransfer.setData(DATA_TRANSFER, position.toString());
    setDragging(true);
    setDraggingSource(position);
  };

  const onDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>, targetPosition: number) => {
      if (!e.dataTransfer.getData(DATA_TRANSFER)) {
        return;
      }
      e.preventDefault();
      const initialPosition = Number(e.dataTransfer.getData(DATA_TRANSFER));
      if (targetPosition === initialPosition) {
        return;
      }

      let newPageRange: Array<PageRangeWithExcludedPages> = [];
      // split page range
      if (initialPosition === -1) {
        const pageRangeToSplit = pageRanges.find(
          (pageRange) =>
            pageRange.end >= targetPosition &&
            pageRange.start >= targetPosition,
        );
        if (
          !pageRangeToSplit ||
          pageRangeToSplit.start === pageRangeToSplit.end
        ) {
          return;
        }
        newPageRange = [
          ...pageRanges.slice(0, pageRanges.indexOf(pageRangeToSplit)),
          {
            start: pageRangeToSplit.start,
            end: targetPosition,
          },
          {
            start: targetPosition + 1,
            end: pageRangeToSplit.end,
          },
          ...pageRanges.slice(pageRanges.indexOf(pageRangeToSplit) + 1),
        ];
      } else {
        // drag and drop
        pageRanges.forEach((pageRange, index) => {
          if (
            targetPosition < initialPosition &&
            pageRange.end >= targetPosition
          ) {
            if (
              pageRanges.length !== index + 1 &&
              pageRanges[index].start > targetPosition &&
              !hasInterval(
                newPageRange,
                targetPosition + 1,
                pageRanges[index].end,
              )
            ) {
              newPageRange.push({
                start: targetPosition + 1,
                end: pageRanges[index + 1].end,
              });
            } else {
              if (
                pageRange.end >= targetPosition &&
                !hasInterval(newPageRange, pageRange.start, targetPosition)
              ) {
                const nextPageRangeToSplit =
                  index < pageRanges.length - 1 ? pageRanges[index + 1] : null;
                newPageRange.push({
                  start: pageRange.start,
                  end: targetPosition,
                });
                newPageRange.push({
                  start: targetPosition + 1,
                  end: nextPageRangeToSplit
                    ? nextPageRangeToSplit.end
                    : pageRange.end,
                });
              }
              if (!hasInterval(newPageRange, pageRange.start, pageRange.end)) {
                newPageRange.push(pageRange);
              }
            }
          } else if (
            pageRange.end < targetPosition &&
            initialPosition > pageRange.end
          ) {
            newPageRange.push(pageRange);
          } else if (pageRange.start < targetPosition) {
            if (
              !newPageRange.some(
                (p) => p.start === pageRange.start || p.end === targetPosition,
              )
            ) {
              newPageRange.push({
                start: pageRange.start,
                end: targetPosition,
              });
            }
            const currentIndex = pageRanges.indexOf(pageRange);
            if (currentIndex < pageRanges.length - 1) {
              const nextPageRangeEnd = pageRanges[currentIndex + 1].end;
              if (
                targetPosition + 1 <= nextPageRangeEnd &&
                targetPosition <= nextPageRangeEnd &&
                !newPageRange.some(
                  (p) =>
                    p.start === targetPosition + 1 ||
                    p.end === nextPageRangeEnd,
                )
              ) {
                newPageRange.push({
                  start: targetPosition + 1,
                  end: nextPageRangeEnd,
                });
              }
            }
          } else if (
            targetPosition !== pageRange.start &&
            targetPosition !== pageRange.end &&
            !newPageRange.some(
              (p) => p.start === pageRange.start || p.end === pageRange.end,
            )
          ) {
            newPageRange.push(pageRange);
          } else {
            const start = Math.min(targetPosition, pageRange.start);
            const end = Math.max(targetPosition, pageRange.start);
            if (!hasInterval(newPageRange, start, end)) {
              newPageRange.push({
                start,
                end,
              });
            }
          }
        });
      }
      setPageRanges(newPageRange);
      onDragEnd();
    },
    [hasInterval, onDragEnd, pageRanges, setPageRanges],
  );

  return (
    <Container>
      <If isTrue={type === SplitType.Split}>
        <FormattedMessage id="SPLIT_INVOICE_DESCRIPTION" tagName={Details} />
        <HeaderContainer>
          <LineContainer
            draggable
            onDragStart={(e: React.DragEvent<HTMLDivElement>) =>
              onDragStart(e, -1)
            }
            $header
          >
            <KeyboardArrowUpStyled />
            <Line />
            <KeyboardArrowDownStyled />
          </LineContainer>
        </HeaderContainer>
      </If>
      <PageRangesContainer>
        {pageRanges.map((pageRange, index) => (
          <Page key={index}>
            <If isTrue={!pageRange.excluded}>
              <FormattedMessage
                id="INVOICE_WITH_NUMBER"
                values={{ invoiceNumber: getInvoiceIndex(pageRange) + 1 }}
                tagName={InvoiceTitle}
              />
            </If>
            {getPages(pageRange).map((page, key) => (
              <Fragment key={key}>
                <ImageContainer key={key} onClick={() => setSelectedPage(page)}>
                  <CheckboxStyled
                    icon={
                      <CheckBoxOutlineBlank
                        className="fill-white"
                        fontSize="small"
                      />
                    }
                    checkedIcon={<CheckBox fontSize="small" />}
                    checked={selectedPages.includes(page)}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSelectedPages(
                        selectedPages.includes(page)
                          ? selectedPages.filter((p) => p !== page)
                          : [...selectedPages, page],
                      );
                    }}
                  />
                  <Image
                    $excluded={pageRange.excluded}
                    $selected={page === selectedPage}
                    src={thumbnails[page - 1]}
                    draggable={false}
                  />
                  <If isTrue={pageRange.excluded}>
                    <FormattedMessage id="EXCLUDED" tagName={Excluded} />
                  </If>
                </ImageContainer>
                <If isTrue={!pageRange.excluded && type === SplitType.Split}>
                  <LineContainer
                    draggable={key === getPages(pageRange).length - 1}
                    $dropping={dragging && draggingTarget === page}
                    onDragStart={(e: React.DragEvent<HTMLDivElement>) =>
                      onDragStart(e, page)
                    }
                    onDragEnd={onDragEnd}
                    onDragOverCapture={(e: React.DragEvent<HTMLDivElement>) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDraggingTarget(page);
                    }}
                    onDragLeave={() => setDraggingTarget(null)}
                    onDrop={(e: React.DragEvent<HTMLDivElement>) =>
                      onDrop(e, page)
                    }
                  >
                    <If isTrue={key === getPages(pageRange).length - 1}>
                      <KeyboardArrowUpStyled />
                      <Line />
                      <KeyboardArrowDownStyled />
                    </If>
                    <If
                      isTrue={
                        dragging &&
                        draggingTarget === page &&
                        draggingSource !== page
                      }
                    >
                      <Drop>
                        <ArrowDownwardStyled />
                        <FormattedMessage id="DROP" />
                      </Drop>
                    </If>
                  </LineContainer>
                </If>
              </Fragment>
            ))}
          </Page>
        ))}
      </PageRangesContainer>
    </Container>
  );
};

export const PageRanges: FC<{ url: string; page: number; type: SplitType }> = ({
  url,
  page,
  type,
}) => (
  <AssetProvider url={url}>
    <PageRangesWithProvider page={page} type={type} />
  </AssetProvider>
);
