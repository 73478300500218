import { SCREEN_BREAKPOINTS } from "@/common/const";
import { createTheme } from "@mui/material";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: SCREEN_BREAKPOINTS.sm,
      md: SCREEN_BREAKPOINTS.md,
      lg: SCREEN_BREAKPOINTS.lg,
      xl: SCREEN_BREAKPOINTS.xl,
    },
  },
});
