import {
  AggregatedCostCodeFieldsFragment,
  AggregatedZoneFieldsFragment,
  ProjectExtendedFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";
import { useUnspecifiedCostCode } from "../../../../../common/hooks/useUnspecifiedCostCode";
import { useUnspecifiedZone } from "../../../../../common/hooks/useUnspecifiedZone";

export const useProjectZoneUtils = (
  project?: ProjectExtendedFieldsFragment | null,
) => {
  const { unassignedZone } = useUnspecifiedZone();
  const { unassignedCostCode } = useUnspecifiedCostCode();

  const includeGrouping = useMemo(() => {
    const usedZones = project?.aggregatedZones?.flatMap((a) => a.zone);
    return (
      usedZones &&
      (usedZones.length > 1 ||
        (usedZones.length === 1 && usedZones[0] !== null))
    );
  }, [project]);

  const zoneOptions = useMemo(() => {
    return (
      project?.zones.concat(unassignedZone).map((zone) => ({
        ...zone,
        disabled: !project?.aggregatedZones?.some(
          (aggr) =>
            aggr.zone?.id === zone.id ||
            (zone.id === unassignedZone.id && aggr.zone === null),
        ),
      })) || []
    );
  }, [project, unassignedZone]);

  const costCodeOptions = useMemo(() => {
    return (
      project?.location.org.costCodes
        .concat(unassignedCostCode)
        .map((costCode) => ({
          ...costCode,
          disabled: !project?.aggregatedZones?.some((aggr) =>
            aggr.costCodes.some(
              (aggrCode) =>
                aggrCode.costCode?.id === costCode.id ||
                (costCode.id === unassignedCostCode.id &&
                  aggrCode.costCode === null),
            ),
          ),
        })) || []
    );
  }, [project, unassignedCostCode]);

  const tagOptions = useMemo(() => {
    return (
      project?.tags.map((tag) => ({
        ...tag,
        disabled: !project.aggregatedZones?.some((aggr) =>
          aggr.costCodes.some((aggrCode) =>
            aggrCode.items?.some((item) =>
              item.estimatedItems?.some((ei) =>
                ei.tags?.some((t) => t.id === tag.id),
              ),
            ),
          ),
        ),
      })) || []
    );
  }, [project]);

  const groupedAggregatedZones = useMemo(
    () =>
      [
        {
          zone: null,
          costCodes: project?.aggregatedZones
            .flatMap((a) => a.costCodes)
            .reduce((acc, costCode) => {
              const existing = acc.find(
                (c) => c.costCode?.id === costCode.costCode?.id,
              );
              if (existing) {
                return [
                  ...acc.filter(
                    (c) => c.costCode?.id !== costCode.costCode?.id,
                  ),
                  {
                    ...existing,
                    items: [
                      ...existing.items.filter(
                        (existingItem) =>
                          !costCode.items.some(
                            (i) =>
                              i.projectItem.id === existingItem.projectItem.id,
                          ),
                      ),
                      ...costCode.items.map((item) => {
                        return {
                          ...item,
                          estimatedItems: [
                            ...existing.items
                              .filter(
                                (existingItem) =>
                                  existingItem.projectItem.id ===
                                  item.projectItem.id,
                              )
                              .flatMap((it) => it.estimatedItems),
                            ...item.estimatedItems,
                          ],
                        };
                      }),
                    ],
                  },
                ];
              } else {
                return [...acc, costCode];
              }
            }, [] as AggregatedCostCodeFieldsFragment[]),
        },
      ] as AggregatedZoneFieldsFragment[],
    [project],
  );

  return {
    zoneOptions,
    costCodeOptions,
    tagOptions,
    includeGrouping,
    groupedAggregatedZones,
  };
};
