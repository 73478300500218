import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Price } from "@/common/components/price/Price";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  grid grid-flow-col gap-2 w-full h-6 px-4 justify-end items-center
`;

const PriceText = tw(Price)`
  text-right font-normal text-xs
`;

const LinkLikeStyled = tw(LinkLike)`
  text-xs font-medium
`;

type Props = {
  components?: {
    name: string;
    price: number;
  }[];
};

export const ComponentsRow = ({ components }: Props) => {
  return (
    <Container>
      <If isTrue={components}>
        <Tooltip
          id="item-components"
          element={
            <LinkLikeStyled>
              <FormattedMessage
                id="COMPONENTS_WITH_NUMBER"
                values={{
                  number: components?.length,
                }}
              />{" "}
              :
            </LinkLikeStyled>
          }
        >
          {components?.map((component, index) => (
            <div key={index}>{component.name}</div>
          ))}
        </Tooltip>
        <PriceText
          price={components?.reduce(
            (acc, component) => acc + component.price,
            0,
          )}
          maximumFractionDigits={2}
        />
      </If>
    </Container>
  );
};
