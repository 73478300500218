import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { PrintButton } from "@/common/components/print-button/PrintButton";
import { DistributorQuoteFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { usePrintView } from "../print-view/usePrintView";

const IconButtonStyled = tw(IconButtonBorderless)`
  text-blue-500 w-50 hover:cursor-pointer
`;

type PrintButtonProps = {
  quote: DistributorQuoteFieldsFragment;
};

export const DistributorQuotePrintButton: FC<PrintButtonProps> = ({
  quote,
}) => {
  const { savePDF } = usePrintView(quote);
  return (
    <IconButtonStyled onClick={savePDF}>
      <PrintButton />
    </IconButtonStyled>
  );
};
