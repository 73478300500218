import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { useRoles } from "@/common/components/org-roles-wrapper/hasRoles";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { OrgRole } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import {
  FC,
  Fragment,
  PropsWithChildren,
  ReactNode,
  useMemo,
  useState,
} from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ConnectionError, ValidationType } from "./ExportErrorList";

const Container = tw.div`border-dashed border border-gray-500 border-r-0 border-l-0`;
const ErrorList = tw.div`flex flex-col gap-2 my-2 mx-1
${({ $withMarginAndBorder }: { $withMarginAndBorder?: boolean }) => ($withMarginAndBorder ? "border-l border-dashed border-gray-500 pl-2 ml-7" : "ml-7")}
`;
const CollapsibleHeader = tw.div`flex flex-row pl-5 pr-3 py-1.5 font-medium items-center cursor-pointer justify-between`;
const Row = tw.div`flex flex-row items-center font-semibold text-2xs text-red-500 ml-1.5`;
const Title = tw.div`text-2xs text-gray-500 bg-gray-100`;
const LinkLikeStyled = tw(LinkLike)`px-3`;
const ErrorContainer = tw.div``;

type ErrorItemProps = {
  $isError?: boolean;
  $isFirstWarning: boolean;
};
const ErrorItem = tw.div<ErrorItemProps>`
  flex flex-row gap-2 items-center
  ${({ $isFirstWarning }) =>
    $isFirstWarning ? "border-t border-dashed border-gray-400 pt-2" : ""}
`;
const ErrorText = tw.div`font-medium text-xs`;
const Errors = tw.div`flex flex-row justify-start 
${({ $hasErrors }: { $hasErrors?: boolean }) => ($hasErrors ? "flex-row" : "flex-col")}`;

type Warning = {
  id: string;
  child?: ReactNode;
  clearable?: boolean;
};

type Props = PropsWithChildren<{
  includedValidations: ConnectionError[];
  classes?: {
    item?: string;
  };
  includeDivider?: boolean;
  warnings?: Warning[];
}>;

export const InvoiceExportErrorList: FC<Props> = ({
  includedValidations,
  classes,
  includeDivider = true,
  warnings,
}) => {
  const intl = useIntl();
  const { hasRoles: isAdmin } = useRoles({ roles: [OrgRole.OrgAdmin] });

  const [clearedWarningsIndexes, setClearedWarningsIndexes] = useState<
    string[]
  >([]);

  const sortedValidations = useMemo(
    () =>
      includedValidations.sort((i) => {
        if (!i.validationType) {
          return -1;
        }

        if (i.validationType === ValidationType.Error) {
          return -1;
        }

        return 1;
      }),
    [includedValidations],
  );

  const filteredWarnings = useMemo(
    () =>
      (warnings ?? []).filter(
        (warning) => !clearedWarningsIndexes.includes(warning.id),
      ),
    [warnings, clearedWarningsIndexes],
  );

  const [expanded, setExpanded] = useState(
    sortedValidations.length + filteredWarnings.length <= 2,
  );

  const headerText = useMemo(() => {
    let text =
      includedValidations.length > 0
        ? intl.$t({ id: "ERROR_COUNT" }, { count: includedValidations.length })
        : "";
    if (expanded && includedValidations.length > 0) {
      return text;
    }
    if (includedValidations.length > 0 && filteredWarnings.length > 0) {
      text += ` ${intl.$t({ id: "AND" })} `;
    }
    if (filteredWarnings.length > 0) {
      text += intl.$t(
        { id: "WARNING_COUNT" },
        { count: filteredWarnings.length },
      );
    }
    return text;
  }, [includedValidations.length, intl, expanded, filteredWarnings.length]);

  return (
    <If
      isTrue={
        includedValidations.length ||
        (warnings?.length && clearedWarningsIndexes.length < warnings.length)
      }
    >
      <Container>
        <Errors $hasErrors={includedValidations.length > 0}>
          <ErrorContainer>
            <CollapsibleHeader
              onClick={() => setExpanded((e) => !e)}
              className={!expanded ? "rounded-b-md" : ""}
            >
              <Row>
                {expanded ? (
                  <FaChevronUp className="mr-3" />
                ) : (
                  <FaChevronDown className="mr-3" />
                )}
                {headerText}
              </Row>
            </CollapsibleHeader>
            <If isTrue={expanded}>
              <If isTrue={sortedValidations.length > 0}>
                <ErrorList
                  className={
                    filteredWarnings.length === 0 ? "rounded-b-md" : ""
                  }
                >
                  {sortedValidations.map((e, key) => (
                    <Fragment key={key}>
                      <ErrorItem
                        $isError={e.validationType !== ValidationType.Warning}
                        $isFirstWarning={
                          includeDivider &&
                          e.validationType === ValidationType.Warning &&
                          key !== 0 &&
                          sortedValidations.findIndex(
                            (v) => v.validationType === ValidationType.Warning,
                          ) === key
                        }
                        key={key}
                        className={classes?.item}
                      >
                        <If isTrue={!e.hideInfoIcon}>
                          <Tooltip
                            id={e.toolTipKey || ""}
                            element={
                              <LinkLike
                                disabled={
                                  e.validationType === ValidationType.Warning
                                }
                              >
                                <InfoOutlined className="text-red-500" />
                              </LinkLike>
                            }
                          >
                            {(
                              isAdmin
                                ? e.adminToolTipKey || e.toolTipKey
                                : e.toolTipKey
                            ) ? (
                              <FormattedMessage
                                id={
                                  isAdmin
                                    ? e.adminToolTipKey || e.toolTipKey
                                    : e.toolTipKey
                                }
                                values={e.values}
                              />
                            ) : null}
                          </Tooltip>
                        </If>
                        <If isTrue={e.button}>{e.button}</If>
                        <FormattedMessage
                          id={e.key}
                          values={e.values}
                          tagName={ErrorText}
                        />
                      </ErrorItem>
                      {e.child}
                    </Fragment>
                  ))}
                </ErrorList>
              </If>
            </If>
          </ErrorContainer>
          <If isTrue={expanded}>
            <If isTrue={filteredWarnings.length > 0}>
              <ErrorList
                className="pl-2"
                $withMarginAndBorder={includedValidations.length > 0}
              >
                <If isTrue={sortedValidations.length > 0}>
                  <FormattedMessage
                    id="WARNING_COUNT"
                    tagName={Title}
                    values={{ count: filteredWarnings.length }}
                  />
                </If>
                {filteredWarnings.map((warning) => (
                  <Fragment key={warning.id}>
                    <ErrorItem
                      $isError={false}
                      $isFirstWarning={false}
                      key={warning.id}
                      className={classes?.item}
                    >
                      <InfoOutlined className="text-gray-500" />
                      {warning.child}
                      <If isTrue={warning.clearable}>
                        <LinkLikeStyled
                          onClick={() =>
                            setClearedWarningsIndexes([
                              ...clearedWarningsIndexes,
                              warning.id,
                            ])
                          }
                        >
                          {intl.$t({ id: "CLEAR" })}
                        </LinkLikeStyled>
                      </If>
                    </ErrorItem>
                  </Fragment>
                ))}
              </ErrorList>
            </If>
          </If>
        </Errors>
      </Container>
    </If>
  );
};
