import { useIntl } from "react-intl";
import { VariationView } from "./VariationView";

export const QuickBooksVariantGenericItems = ({ logo }: { logo: string }) => {
  const intl = useIntl();

  return (
    <VariationView
      explanations={[
        {
          source: intl.$t({ id: "CLASSES" }),
          target: intl.$t({ id: "COST_CODES" }),
        },
        {
          source: intl.$t({ id: "ITEMS" }),
          target: intl.$t({ id: "ITEM_DATABASE" }),
        },
      ]}
      variationText={intl.$t({ id: "GENERIC_ITEMS" })}
      logo={logo}
    />
  );
};
