import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CreateExternalBatchInput,
  useCreateExternalBatchMutation,
} from "@/generated/graphql";

export const useCreateExternalBatch = () => {
  const [createExternalBatchMutation] = useCreateExternalBatchMutation();
  const { setError } = useGlobalError();

  const createExternalBatch = async (input: CreateExternalBatchInput) => {
    try {
      const { data } = await createExternalBatchMutation({
        variables: {
          input,
        },
      });
      return data?.createExternalBatch;
    } catch (errors) {
      setError(errors);
    }
  };

  return { createExternalBatch };
};
