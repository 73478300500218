import { Skeleton } from "@mui/material";
import tw from "tailwind-styled-components";

export const SkeletonCard = tw(Skeleton)`
  w-full first:h-[242px] last:p-5
`;

export const SkeletonCardContainer = tw.div`
   w-full
`;
