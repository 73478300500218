import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  SmsVerificationFieldsFragment,
  useInitializeSmsVerificationMutation,
  useResendSmsVerificationMutation,
  useSubmitSmsVerificationMutation,
} from "@/generated/graphql";
import { NoFunctionUndefinedPromise } from "@/types/NoFunction";
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
} from "react";

export type SMSVerificationResult = {
  verification: SmsVerificationFieldsFragment | null | undefined;
  warnings: string[] | undefined;
};

type ContextProps = {
  initializeSMSVerification: () => Promise<
    SmsVerificationFieldsFragment | null | undefined
  >;
  resendSMSVerification: (
    verificationId: string,
  ) => Promise<SmsVerificationFieldsFragment | null | undefined>;
  submitSMSVerification: (
    verificationId: string,
    code: string,
  ) => Promise<SMSVerificationResult | null | undefined>;
};

const Context = createContext<ContextProps>({
  initializeSMSVerification: NoFunctionUndefinedPromise,
  resendSMSVerification: NoFunctionUndefinedPromise,
  submitSMSVerification: NoFunctionUndefinedPromise,
});

export const SMSValidationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [initializeSMSVerificationMutation] =
    useInitializeSmsVerificationMutation();
  const [resendSMSVerificationMutation] = useResendSmsVerificationMutation();
  const [submitSMSVerificationMutation] = useSubmitSmsVerificationMutation();

  const { setError } = useGlobalError();

  const initializeSMSVerification = useCallback(async () => {
    try {
      const { data, errors } = await initializeSMSVerificationMutation();
      setError(errors);
      return data?.initializeSMSVerification;
    } catch (errors) {
      setError(errors);
    }
    return null;
  }, [initializeSMSVerificationMutation, setError]);

  const resendSMSVerification = useCallback(
    async (verificationId: string) => {
      if (verificationId) {
        try {
          const { data, errors } = await resendSMSVerificationMutation({
            variables: { id: verificationId },
          });
          setError(errors);
          return data?.resendSMSVerification;
        } catch (errors) {
          setError(errors);
        }
      }
      return null;
    },
    [resendSMSVerificationMutation, setError],
  );

  const submitSMSVerification = useCallback(
    async (verificationId: string, code: string) => {
      if (verificationId && code) {
        try {
          const { data, errors, extensions } =
            await submitSMSVerificationMutation({
              variables: { input: { smsVerificationId: verificationId, code } },
            });
          setError(errors);
          return {
            verification: data?.submitSMSVerification,
            warnings: extensions?.warnings as string[],
          };
        } catch (errors) {
          setError(errors);
        }
      }
      return null;
    },
    [setError, submitSMSVerificationMutation],
  );

  return (
    <Context.Provider
      value={{
        initializeSMSVerification,
        resendSMSVerification,
        submitSMSVerification,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useSMSVerification = (): ContextProps => useContext(Context);
