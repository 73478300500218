import { isOrgCatalogSku } from "@/common/components/material/utils";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { TextField } from "@/common/components/textfield/TextField";
import {
  OrgMaterialFieldsFragment,
  OrgSkuFieldsFragment,
} from "@/generated/graphql";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMaterialList } from "../../../providers/MaterialListProvider";

type Props = {
  item: OrgMaterialFieldsFragment;
  readonly: boolean;
};

export const MaterialEstimationSoftwareID: FC<Props> = ({
  item,
  readonly,
}: Props) => {
  const [estimationSoftwareId, setEstimationSoftwareId] = useState(
    (item.material as OrgSkuFieldsFragment).customPartNumber,
  );
  useEffect(() => {
    if (readonly) {
      setEstimationSoftwareId(
        (item.material as OrgSkuFieldsFragment).customPartNumber,
      );
    }
  }, [item.material, readonly]);
  const { updateMaterial } = useMaterialList();
  const intl = useIntl();

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      setEstimationSoftwareId(newValue);
      updateMaterial(item.id, {
        updates: [
          {
            orgCatalogSku: {
              id: item.material.id,
              customPartNumber: newValue,
            },
            orgMaterialId: item.id,
          },
        ],
      });
    },
    [updateMaterial, item],
  );
  return (
    <NotNullableRenderer
      value={
        isOrgCatalogSku(item.material)
          ? estimationSoftwareId || !readonly
          : false
      }
    >
      <TextField
        label={!readonly ? intl.$t({ id: "SPECIFY_ITEM_ID" }) : ""}
        value={estimationSoftwareId}
        onChange={onChange}
        staticText={readonly}
        inputProps={{ className: readonly ? "text-center text-sm" : "" }}
        InputLabelProps={{ className: "text-sm" }}
        xs
      />
    </NotNullableRenderer>
  );
};
