import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { useReleasesSelectorData } from "@/common/hooks/userReleasesSelectorQuery";
import { OrderSelectorFilters } from "@/contractor/pages/home/common/order-selector-filters/OrderSelectorFilters";
import {
  ReleaseSelectorFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../../providers/InvoiceVerificationProvider";
import { InvoiceSelectModeHeader } from "../../invoice-select-mode-header/InvoiceSelectModeHeader";

const Container = tw.div`w-full`;

export const InvoiceOrderFilters: FC = () => {
  const {
    setMatchedOrderViewState,
    selectedReleaseId,
    setSelectedReleaseId,
    preselectedPoNumber,
    setPreselectedPoNumber,
  } = useInvoiceMatchedOrder();
  const { filter, setFilter, releases } = useReleasesSelectorData({
    search: preselectedPoNumber,
    statuses: [
      ReleaseStatus.Scheduled,
      ReleaseStatus.Received,
      ReleaseStatus.PartiallyReceived,
      ReleaseStatus.Requested,
    ],
    closedProjects: false,
  });
  const { invoice, setFooterState, updateInvoice } = useInvoiceVerification();
  useEffect(() => {
    if (preselectedPoNumber && releases.length > 0) {
      setPreselectedPoNumber("");
      const release = releases.find((r) => r.poNumber === preselectedPoNumber);
      if (release) {
        setSelectedReleaseId(release.id);
        setFilter({
          ...filter,
          projectIds: [release.project?.id ?? ""],
          sellerOrgLocationIds: release.sellerOrgLocation?.id
            ? [release.sellerOrgLocation.id]
            : [],
        });
      }
    }
  }, [
    filter,
    preselectedPoNumber,
    releases,
    setFilter,
    setPreselectedPoNumber,
    setSelectedReleaseId,
  ]);

  useEffect(() => {
    return () => setSelectedReleaseId(null);
  }, [setSelectedReleaseId]);

  const [selectedRelease] = useState<ReleaseSelectorFieldsFragment | null>(
    null,
  );
  useEffect(() => {
    if (selectedRelease) {
      setFilter({
        ...filter,
        projectIds: [selectedRelease.project?.id ?? ""],
        sellerOrgLocationIds: selectedRelease.sellerOrgLocation?.id
          ? [selectedRelease.sellerOrgLocation.id]
          : [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRelease]);

  const selectInvoiceRelease = useCallback(async () => {
    const success = await updateInvoice({
      id: invoice?.id ?? "",
      releaseId: selectedReleaseId ?? undefined,
      clearRelease: !selectedReleaseId ? true : undefined,
    });
    if (success) {
      setMatchedOrderViewState(MatchedOrderViewState.EDIT_INVOICE_COVERAGES);
      setFooterState(InvoiceFooterState.DEFAULT);
    }
    return success;
  }, [
    updateInvoice,
    invoice?.id,
    selectedReleaseId,
    setMatchedOrderViewState,
    setFooterState,
  ]);

  return (
    <Container>
      <InvoiceSelectModeHeader />
      <OrderSelectorFilters
        onOrderChange={(rel) => setSelectedReleaseId(rel?.id ?? null)}
        onOrderConfirmed={selectInvoiceRelease}
        initialReleaseId={selectedReleaseId}
        allowDraftOrders={false}
      />
    </Container>
  );
};
