import { AssetViewer } from "@/common/components/asset-viewer/AssetViewer";
import { Loader } from "@/common/components/loader/Loader";
import { QuoteDocumentSummaryFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useQuoteDocument } from "../../providers/QuoteDocumentProvider";
import { ReplaceQuoteFile } from "../replace-quote-file/ReplaceQuoteFile";

const Container = tw.div`flex flex-col bg-blue-100 rounded-3xl pt-6 pb-5 mb-10 text-black h-fit overflow-scroll`;
const Header = tw.div`grid grid-flow-col w-full place-items-center justify-between text-base font-medium px-6 pb-4 `;

type Props = {
  document?: QuoteDocumentSummaryFieldsFragment | null;
};

export const QuoteDocumentDetails: FC<Props> = ({ document }) => {
  const { quoteDocument } = useQuoteDocument();

  if (!quoteDocument && !document) {
    return <Loader loading />;
  }

  return (
    <Container>
      <Header>
        <FormattedMessage id="QUOTE_PDF" />
      </Header>
      <AssetViewer
        asset={document?.asset || quoteDocument?.asset}
        customFooter={<ReplaceQuoteFile />}
      />
    </Container>
  );
};
