import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { FC } from "react";
import { BsPlus } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  IMPORT_MATERIALS_DRAWER,
  ImportMaterialsDrawerTabs,
} from "../import-from-file/ImportReleaseItemsDrawer";

const AddQuoteDocument = tw.div`
  grid grid-flow-col justify-start gap-2 items-center text-blue-500 hover:text-blue-300 cursor-pointer text-sm
`;
const AddQuoteDocumentButton = tw.button`
  grid place-items-center border-blue-800 w-17 h-17 border rounded-md  text-3xl
`;

export const AddQuoteSidePanel: FC = () => {
  const { toggle } = useGlobalDrawer();

  return (
    <AddQuoteDocument
      onClick={() =>
        toggle(IMPORT_MATERIALS_DRAWER, true, ImportMaterialsDrawerTabs.Quote)
      }
    >
      <AddQuoteDocumentButton>
        <BsPlus />
      </AddQuoteDocumentButton>
      <FormattedMessage id="ADD_VENDOR_QUOTE" />
    </AddQuoteDocument>
  );
};
