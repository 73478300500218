import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import {
  ExternalCostTypeFieldsFragment,
  SourceSystem,
  useExternalCostTypesQuery,
} from "@/generated/graphql";
import React, { FC, createContext, useContext } from "react";
import { useAgaveHostedWizard } from "../../AgaveHostedWizardProvider";

export type ExternalCostTypeType = Omit<
  ExternalCostTypeFieldsFragment,
  "__typename"
> & {
  id: string;
  index: number;
};

type ProviderContextType = {
  externalCostTypes: ExternalCostTypeType[];
  loadingExternalCostTypes: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  externalCostTypes: [],
  loadingExternalCostTypes: false,
});

export const ExternalMaterialCostTypesProvider: FC<{
  children: React.ReactNode;
  defaultSourceSystem?: SourceSystem;
}> = ({ children, defaultSourceSystem }) => {
  const { sourceSystem } = useAgaveHostedWizard();

  const {
    data: externalCostTypesData,
    loading: loadingExternalCostTypes,
    error: externalCostTypesError,
  } = useExternalCostTypesQuery({
    variables: {
      sourceSystem:
        defaultSourceSystem ?? sourceSystem ?? SourceSystem.Foundation,
    },
    skip: !defaultSourceSystem && !sourceSystem,
  });
  useErrorEffect(externalCostTypesError);

  return (
    <ProviderContext.Provider
      value={{
        externalCostTypes:
          externalCostTypesData?.externalCostTypes.map((i, index) => ({
            ...i,
            id: i.externalId,
            index,
          })) ?? [],
        loadingExternalCostTypes,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useExternalMaterialCostTypes = () => useContext(ProviderContext);
