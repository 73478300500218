export class SeededRandom {
  private m = 0x80000000;
  private a = 1103515245;
  private c = 12345;

  private state: number;

  constructor(seed: number | string) {
    if (typeof seed === "string") {
      seed = seed.split("").reduce((a, b) => {
        return a + b.charCodeAt(0);
      }, 0);
    }
    this.state = seed || Math.floor(Math.random() * this.m);
  }

  nextFloat(): number {
    this.state = (this.a * this.state + this.c) % this.m;
    return this.state / (this.m - 1);
  }

  nextInt(max: number): number {
    return Math.floor(this.nextFloat() * max);
  }
}
