import { routes } from "@/config/routes";
import { WithMainTabNavigation } from "@/contractor/pages/home/common/navigation/WithMainTabNavigation";
import { WithInvoiceTabNavigation } from "./common/components/invoice-tab-navigation/WithInvoiceTabNavigation";
import { InvoiceEmails } from "./pages/email-invoices/InvoiceEmails";
import { InvoiceDownload } from "./pages/invoice-download/InvoiceDownload";
import { InvoiceVerification } from "./pages/invoice-verification/InvoiceVerification";
import { ScannedInvoices } from "./pages/scanned-invoices/ScannedInvoices";

export const invoicesRoutes = [
  {
    path: routes.invoices,
    element: (
      <WithMainTabNavigation>
        <WithInvoiceTabNavigation />
      </WithMainTabNavigation>
    ),
    children: [
      {
        path: "",
        element: <ScannedInvoices />,
      },
      {
        path: routes.emailInvoices,
        element: <InvoiceEmails />,
      },
    ],
  },
  {
    path: routes.invoiceVerification,
    element: <InvoiceVerification />,
  },
  {
    path: routes.invoiceDownload,
    element: <InvoiceDownload />,
  },
];
