import { useSourceSystemLogo } from "@/common/hooks/integrations/source-system-config/useSourceSystemLogo";
import { IntegrationLogo } from "@/contractor/pages/admin/integrations/components/form/components/IntegrationLogo";
import { ExternalItemsList } from "@/contractor/pages/admin/org-items/pages/materials/components/external-items-list/ExternalItemsList";
import { PoValidationFieldsFragment, SourceSystem } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useMissingItemsFromInventory } from "../../../../../providers/MissingItemsFromInventoryProvider";
import {
  StepCol,
  StepHeader,
  StepHeaderDescription,
  StepHeaderTitle,
  StepRow,
} from "../HostedAgaveConnection.Styles";

const IntegrationLogoStyled = tw(IntegrationLogo)`h-16`;

type Props = {
  material?: PoValidationFieldsFragment["materialsNotConnected"][0];
  sourceSystem: SourceSystem | null;
};

export const MissingItemFromInventory: FC<Props> = ({
  material,
  sourceSystem,
}) => {
  const { selectedExternalItem, setSelectedExternalItem } =
    useMissingItemsFromInventory();

  const { sourceSystemLogo } = useSourceSystemLogo(sourceSystem);

  return (
    <ExternalItemsList
      itemSelection={{
        type: "single",
        selectedItemId: selectedExternalItem,
        setSelectedItemId: setSelectedExternalItem,
      }}
      sourceSystem={sourceSystem}
      searchTitleBar={
        <StepHeader>
          <StepRow>
            <StepCol>
              <StepHeaderTitle>
                <FormattedMessage id="CONNECT_EQUIVALENT_ACCOUNTING_SYSTEM" />
              </StepHeaderTitle>
              <StepHeaderDescription>
                {material?.material.name}
              </StepHeaderDescription>
            </StepCol>
            <StepCol>
              <IntegrationLogoStyled url={sourceSystemLogo} />
            </StepCol>
          </StepRow>
        </StepHeader>
      }
    />
  );
};
