import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDeliverySlipImportExternalPO } from "../../../../providers/DeliverySlipImportExternalPOProvider";
import {
  PackingSlipMatchViewState,
  useDeliverySlipVerification,
} from "../../../../providers/DeliverySlipVerificationProvider";

const Container = tw.div`
  flex flex-col justify-center items-center py-16
  border-dashed border border-gray-600 rounded-3xl mt-5 mx-4
`;
const Row = tw.div`flex flex-row`;
const InfoIcon = tw(InfoOutlined)`text-gray-500 w-6 h-6 mr-2`;
const Title = tw.div`text-lg font-medium mb-3`;
const Description = tw.div`text-sm text-gray-600 font-normal text-center mb-10 mx-10`;

export const NoReleaseForDeliverySlipInfo: FC = () => {
  const { connectedSourceSystem } = useOrgSettings();
  const { setPackingSlipMatchViewState } = useDeliverySlipVerification();
  const { openModal } = useDeliverySlipImportExternalPO();
  const intl = useIntl();

  return (
    <Container>
      <Row>
        <InfoIcon />
        <FormattedMessage id="PACKING_SLIP_NOT_MATCHED_TITLE" tagName={Title} />
      </Row>
      <FormattedMessage
        id="PACKING_SLIP_NOT_MATCHED_DESCRIPTION"
        values={{ br: <br />, sourceSystem: connectedSourceSystem }}
        tagName={Description}
      />
      <Row>
        <PrimaryButton
          className="mr-2 w-[150px] rounded-[90px]"
          onClick={() => {
            setPackingSlipMatchViewState(PackingSlipMatchViewState.MATCH_ORDER);
          }}
        >
          <FormattedMessage id="SELECT_ORDER_MANUALLY" />
        </PrimaryButton>
        <PrimaryButton
          className="w-[180px] rounded-[90px]"
          onClick={() => {
            setPackingSlipMatchViewState(
              PackingSlipMatchViewState.CREATE_ORDER,
            );
          }}
          testId="create-new-order-from-invoice"
        >
          <FormattedMessage id="CREATE_NEW_ORDER_FROM_PACKING_SLIP" />
        </PrimaryButton>
        <If isTrue={connectedSourceSystem}>
          <PrimaryButton
            className="ml-2 w-[180px] rounded-[90px]"
            onClick={() => openModal({ skipPoList: false })}
            testId="import-external-po"
          >
            <FormattedMessage
              id="IMPORT_PO_FROM_SYSTEM_AS_NEW_ORDER"
              values={{
                system: connectedSourceSystem
                  ? intl.$t({
                      id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
                    })
                  : "",
              }}
            />
          </PrimaryButton>
        </If>
      </Row>
    </Container>
  );
};
