import { If } from "@/common/components/if/If";
import { getUserName } from "@/common/utils/users/getUserName";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  border-r border-dashed pr-4
`;

const UserContainer = tw.div`
  flex gap-1 items-end w-full
`;

const Label = tw.div`
  text-xs text-gray-600 text-right flex-1
`;

const UserName = tw.div`
  text-xs text-gray-600 text-right
`;

type Props = {
  release: ReleaseFieldsFragment;
};

const UserAvatarContainer: FC<{
  labelKey: string;
  user: {
    photoUrl?: string | null | undefined;
    firstName: string;
    lastName: string;
  };
}> = ({ labelKey, user }) => {
  return (
    <UserContainer>
      <Label>
        <FormattedMessage id={labelKey} />
      </Label>
      <UserName>{getUserName(user)}</UserName>
    </UserContainer>
  );
};

export const ReleaseUserDetails: FC<Props> = ({ release }) => {
  return (
    <If
      isTrue={
        release.submittedBy || release.approvals.length || release.rejectedBy
      }
    >
      <Container>
        {release.submittedBy && (
          <UserAvatarContainer
            labelKey="REQUESTED_BY"
            user={release.submittedBy}
          />
        )}
        {release.rejectedBy && (
          <UserAvatarContainer
            labelKey="REJECTED_BY"
            user={release.rejectedBy}
          />
        )}
      </Container>
    </If>
  );
};
