import tw from "tailwind-styled-components";
import { Popover } from "../popover/Popover";

type Props = {
  count: number;
  children: React.ReactNode;
  color?: "primary" | "secondary" | "warning";
  classes?: { popover?: string; element?: string; innerElement?: string };
};

const AvatarCountWrapper = tw.div`flex h-full items-center text-blue-500 pl-2`;
const AvatarCount = tw.div``;

export const ShowMoreCounter = ({ count, children, color, classes }: Props) => {
  return (
    <Popover
      id="show-more"
      color={color}
      classes={{ element: classes?.popover }}
      element={
        <AvatarCountWrapper className={classes?.element}>
          <AvatarCount className={classes?.innerElement}>+{count}</AvatarCount>
        </AvatarCountWrapper>
      }
    >
      {children}
    </Popover>
  );
};
