import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  DistributorReleaseItemFieldsFragment,
  ReleaseItemFieldsFragment,
} from "@/generated/graphql";
import { LUMP_SUM_UOM, LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "../const";

export const isLumpSumItem = (
  item:
    | ExpandedReleaseItem
    | ReleaseItemFieldsFragment
    | DistributorReleaseItemFieldsFragment
    | undefined,
) => {
  const uom = item?.uom ?? item?.projectItem?.estimateUom;
  return (
    uom?.pluralDescription?.toLocaleLowerCase() ===
      LUMP_SUM_UOM_PLURAL_DESCRIPTION.toLocaleLowerCase() ||
    uom?.pluralDescription?.toLocaleLowerCase() ===
      LUMP_SUM_UOM.toLocaleLowerCase()
  );
};

export const isLumpSumUomText = (uomText: string | undefined | null) =>
  uomText &&
  typeof uomText === "string" &&
  (uomText?.toLocaleLowerCase() === LUMP_SUM_UOM.toLocaleLowerCase() ||
    uomText?.toLocaleLowerCase() ===
      LUMP_SUM_UOM_PLURAL_DESCRIPTION.toLocaleLowerCase());
