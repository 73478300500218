import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { SideActionBar } from "@/common/components/side-action-bar/SideActionBar";
import { VIEW_STATE, useTableView } from "@/common/providers/TableViewProvider";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useReleaseUpdate } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import { AddToReleaseItemInput, ReleaseStatus } from "@/generated/graphql";
import {
  AltRoute,
  ControlPoint,
  DeleteOutline,
  DragHandle,
  Place,
} from "@mui/icons-material";
import { useCallback, useMemo, useState } from "react";
import { useReleaseActions } from "../../../../providers/ReleaseActionsProvider";
import { useSyncReleaseItems } from "../../hooks/useSyncReleaseItems";
import { BulkVendorSelector } from "./BulkVendorSelector";
import { ReleaseBulkCostCodeSelector } from "./ReleaseBulkCostCodeSelector";
import { ReleaseBulkZoneSelector } from "./ReleaseBulkZoneSelector";

export const ReleaseSideActions = () => {
  const { vendorReassignmentReleases } = useReleaseActions();
  const { addItems, selectedReleaseItemIds, setSelectedReleaseItemIds } =
    useReleaseUpdate();
  const { updateRelease } = useSyncReleaseItems();
  const { release } = useRelease();
  const [loading, setLoading] = useState(false);
  const { tableView } = useTableView();

  const canModifyItems = useMemo(
    () =>
      !release?.items.some(
        (i) =>
          selectedReleaseItemIds.includes(i.id) && i.invoiceItems.length > 0,
      ),
    [release, selectedReleaseItemIds],
  );

  const deleteItems = useCallback(async () => {
    if (release && selectedReleaseItemIds) {
      setLoading(true);
      await updateRelease({
        releaseId: release?.id ?? "",
        version: release?.version ?? 0,
        removedItems: selectedReleaseItemIds,
      });
      setLoading(false);
      setSelectedReleaseItemIds([]);
    }
  }, [
    release,
    selectedReleaseItemIds,
    setSelectedReleaseItemIds,
    updateRelease,
  ]);

  const duplicate = useCallback(async () => {
    if (release && selectedReleaseItemIds) {
      setLoading(true);
      await addItems(
        selectedReleaseItemIds
          .map((id) => {
            const item = release.items.find((i) => i.id === id);
            if (item) {
              return {
                buyoutItemId: item.buyoutItem?.id,
                projectItem: item.buyoutItem
                  ? undefined
                  : {
                      orgCatalogSkuId: isOrgCatalogSku(
                        item.projectItem?.material.material,
                      )
                        ? item.projectItem?.material.material.id
                        : undefined,
                      masterProductId: isProductSku(
                        item.projectItem?.material.material,
                      )
                        ? item.projectItem?.material.material.id
                        : undefined,
                      masterSkuId: isMasterSku(
                        item.projectItem?.material.material,
                      )
                        ? item.projectItem?.material.material.id
                        : undefined,
                      estimateUom:
                        item.projectItem?.estimateUom.pluralDescription || "",
                    },
                quantity: 0,
                manufacturerId: item.manufacturer?.id,
                tags: item.tags.map((t) => t.id),
                position: item.position || release.items.length,
                zoneId: item.zone?.id,
                costCodeId: item.costCode?.id,
              };
            }
            return null;
          })
          .filter((i) => i !== null) as AddToReleaseItemInput[],
      );
      setLoading(false);
      setSelectedReleaseItemIds([]);
    }
  }, [release, selectedReleaseItemIds, addItems, setSelectedReleaseItemIds]);

  const actions = useMemo(() => {
    return [
      ...((release?.status === ReleaseStatus.Draft ||
        release?.status === ReleaseStatus.AwaitingApproval ||
        release?.status === ReleaseStatus.Rejected) &&
      vendorReassignmentReleases.length === 0
        ? [
            {
              icon: <AltRoute />,
              tooltipKey: "SPLIT_ORDER",
              component: <BulkVendorSelector setLoading={setLoading} />,
            },
          ]
        : []),
      {
        icon: <ControlPoint />,
        tooltipKey: "DUPLICATE_ITEMS",
        callback: duplicate,
      },
      ...(canModifyItems
        ? [
            {
              icon: <Place />,
              tooltipKey: "ASSIGN_ZONE",
              component: <ReleaseBulkZoneSelector setLoading={setLoading} />,
            },
          ]
        : []),

      {
        icon: <DragHandle />,
        tooltipKey: "ASSIGN_COST_CODE",
        component: <ReleaseBulkCostCodeSelector setLoading={setLoading} />,
      },
      ...(canModifyItems
        ? [
            {
              icon: <DeleteOutline />,
              tooltipKey: "DELETE_ITEMS",
              callback: deleteItems,
            },
          ]
        : []),
    ];
  }, [
    release?.status,
    vendorReassignmentReleases.length,
    duplicate,
    canModifyItems,
    deleteItems,
  ]);

  return (
    <SideActionBar
      loading={loading}
      visible={
        selectedReleaseItemIds.length > 0 && tableView === VIEW_STATE.normal
      }
      actions={actions}
    ></SideActionBar>
  );
};
