import { TagPicker } from "@/common/components/tag-picker/TagPicker";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";

export const DistributorReleaseTags = ({
  item,
}: {
  item: DistributorReleaseItemFieldsFragment;
}) => {
  return (
    <TagPicker values={item?.id ? item?.tags : []} visibleTags={1} readonly />
  );
};
