import {
  COLUMN_TYPE,
  ExtraOption,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import Handsontable from "handsontable";
import { CellChange, ChangeSource } from "handsontable/common";
import { useCallback } from "react";
import { CUSTOM_SOURCE, useTableHelpers } from "./useTableHelpers";

export const usePrefillHelpers = () => {
  const {
    findMaterialByName,
    getFormattedMaterialName,
    getPrefilledValue,
    sanitizeMaterialName,
    getPhysicalColumnIndex,
    setCells,
    toggleReadOnly,
  } = useTableHelpers();
  const { config } = useColumnMapper();
  const { formatCostCode } = useCostCodes();
  const { calcUnitPrice, calcExtPrice } = usePriceCalculation();

  const prefillMaterialFields = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      if (!hotInstance) {
        return;
      }
      if (["edit", "CopyPaste.paste", "Autofill.fill"].includes(source)) {
        const prefilledCells = [] as [number, number, string | number][];
        changes?.forEach((change) => {
          const [row, column, , value] = change;
          const physicalRow = hotInstance?.toPhysicalRow(row) || row;

          if (column === COLUMN_TYPE.Material) {
            const material = findMaterialByName(value);

            if (material) {
              prefilledCells.push([
                physicalRow,
                getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Material),
                getFormattedMaterialName(material),
              ]);
              const uomPartOfPaste = changes.find(
                (c) => c[0] === row && c[1] === COLUMN_TYPE.UOM,
              );
              if (
                material.defaultEstimateUom?.pluralDescription &&
                !uomPartOfPaste
              ) {
                prefilledCells.push([
                  physicalRow,
                  getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.UOM),
                  material.defaultEstimateUom?.pluralDescription,
                ]);
              }

              const manufacturerPartOfPaste = changes.find(
                (c) => c[0] === row && c[1] === COLUMN_TYPE.Manufacturer,
              );
              if (material.manufacturer?.name && !manufacturerPartOfPaste) {
                prefilledCells.push([
                  physicalRow,
                  getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Manufacturer),
                  material.manufacturer?.name,
                ]);
              }

              const costCodePartOfPaste = changes.find(
                (c) => c[0] === row && c[1] === COLUMN_TYPE.CostCode,
              );
              if (material.costCode?.description && !costCodePartOfPaste) {
                prefilledCells.push([
                  physicalRow,
                  getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.CostCode),
                  formatCostCode(material.costCode),
                ]);
              }
            } else if (value) {
              prefilledCells.push([
                physicalRow,
                getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Material),
                sanitizeMaterialName(value),
              ]);
            }
          }
        });
        setCells(prefilledCells, hotInstance);
      }
    },
    [
      findMaterialByName,
      formatCostCode,
      getFormattedMaterialName,
      getPhysicalColumnIndex,
      sanitizeMaterialName,
      setCells,
    ],
  );

  const prefillPrices = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      if (!hotInstance) {
        return;
      }

      if (
        [CUSTOM_SOURCE, "edit", "CopyPaste.paste", "Autofill.fill"].includes(
          source,
        )
      ) {
        const prefilledCells = [] as [number, number, string | number][];
        changes?.forEach((change) => {
          const [row, column, oldValue] = change;

          const pricePartOfPaste = changes.find(
            (c) => c[0] === row && c[1] === COLUMN_TYPE.PrefilledPrice,
          );

          if (pricePartOfPaste) {
            return;
          }

          if (
            column === COLUMN_TYPE.Material ||
            column === COLUMN_TYPE.Vendor ||
            column === COLUMN_TYPE.Manufacturer ||
            column === COLUMN_TYPE.UOM
          ) {
            const physicalRow = hotInstance?.toPhysicalRow(row) || row;
            const rows = hotInstance?.getData() as Record<string, string>[];
            const price = getPrefilledValue(
              rows[physicalRow][
                getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Material)
              ],
              rows[physicalRow][
                getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Vendor)
              ],
              rows[physicalRow][
                getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Manufacturer)
              ],
              rows[physicalRow][
                getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.UOM)
              ],
            );
            const oldPrice = getPrefilledValue(
              column === COLUMN_TYPE.Material
                ? oldValue
                : rows[physicalRow][
                    getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Material)
                  ],
              column === COLUMN_TYPE.Vendor
                ? oldValue
                : rows[physicalRow][
                    getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Vendor)
                  ],
              column === COLUMN_TYPE.Manufacturer
                ? oldValue
                : rows[physicalRow][
                    getPhysicalColumnIndex(
                      hotInstance,
                      COLUMN_TYPE.Manufacturer,
                    )
                  ],
              column === COLUMN_TYPE.UOM
                ? oldValue
                : rows[physicalRow][
                    getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.UOM)
                  ],
            );
            const currentPrice =
              rows[physicalRow][
                getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.PrefilledPrice)
              ];
            const isEmpty = !currentPrice || currentPrice === "0";

            const prefilledValue =
              String(
                rows[physicalRow][
                  getPhysicalColumnIndex(
                    hotInstance,
                    COLUMN_TYPE.PrefilledPrice,
                  )
                ],
              ) === String(oldPrice.value);
            if (isEmpty || prefilledValue) {
              prefilledCells.push([
                physicalRow,
                getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.PrefilledPrice),
                price.value,
              ]);
            }
          }
        });
        setCells(prefilledCells, hotInstance);
      }
    },
    [getPhysicalColumnIndex, getPrefilledValue, setCells],
  );

  const prefillExtraOptions = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      if (!hotInstance) {
        return;
      }
      if (
        [CUSTOM_SOURCE, "edit", "CopyPaste.paste", "Autofill.fill"].includes(
          source,
        )
      ) {
        const prefilledCells = [] as [number, number, string | number][];
        changes?.forEach((change) => {
          const [row, column, , value] = change;
          const physicalRow = hotInstance?.toPhysicalRow(row) || row;

          if (typeof value === "string" && column === COLUMN_TYPE.Material) {
            const extraDetails = value?.match(/ ⟮(.*)⟯/);
            const stripedName = value?.replace(/ ⟮(.*)⟯/, "");
            const material = findMaterialByName(stripedName);
            if (material) {
              if (extraDetails) {
                prefilledCells.push([
                  physicalRow,
                  getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Material),
                  getFormattedMaterialName(material),
                ]);
              }

              const extraOptions = hotInstance.getCellMeta(
                physicalRow,
                getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Material),
              ).extraOptions as ExtraOption[];

              if (extraOptions && extraDetails) {
                const currentOption = extraOptions.find(
                  (option) =>
                    option.name === getFormattedMaterialName(material) &&
                    option.content.outerHTML.includes(extraDetails[1]),
                );
                currentOption?.prefilling?.forEach((option) => {
                  prefilledCells.push([
                    physicalRow,
                    getPhysicalColumnIndex(hotInstance, option[0]),
                    option[1] as string | number,
                  ]);
                });
              }
            } else if (stripedName?.length > 0) {
              setCells(
                [
                  [
                    physicalRow,
                    getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Material),
                    sanitizeMaterialName(stripedName),
                  ],
                ],
                hotInstance,
                "none",
              );
            }
          }
        });
        setCells(prefilledCells, hotInstance);
      }
    },
    [
      findMaterialByName,
      getFormattedMaterialName,
      getPhysicalColumnIndex,
      sanitizeMaterialName,
      setCells,
    ],
  );

  const updateExtPriceDependence = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      const prefilledCells = [] as [number, number, string | number][];
      if (
        !hotInstance ||
        config.every((c) => c.columnType !== COLUMN_TYPE.ExtPrice) ||
        config.every(
          (c) =>
            c.columnType !== COLUMN_TYPE.UnitPrice &&
            c.columnType !== COLUMN_TYPE.PrefilledPrice,
        ) ||
        config.every(
          (c) =>
            c.columnType !== COLUMN_TYPE.Quantity &&
            c.columnType !== COLUMN_TYPE.PositiveQuantity,
        )
      ) {
        return;
      }

      const isPrefilledPrice = config.some(
        (c) => c.columnType === COLUMN_TYPE.PrefilledPrice,
      );

      const hasPositiveQuantity = config.some(
        (c) => c.columnType === COLUMN_TYPE.PositiveQuantity,
      );

      const rows = hotInstance?.getData() as Record<string, string>[];

      if (["edit", "CopyPaste.paste", "Autofill.fill"].includes(source)) {
        changes?.forEach((change) => {
          const [row, column] = change;
          const physicalRow = hotInstance?.toPhysicalRow(row) || row;
          const quantity =
            rows[physicalRow][
              getPhysicalColumnIndex(
                hotInstance,
                hasPositiveQuantity
                  ? COLUMN_TYPE.PositiveQuantity
                  : COLUMN_TYPE.Quantity,
              )
            ];

          if (quantity === null || quantity === undefined) {
            return;
          }

          const unitPrice =
            rows[physicalRow][
              getPhysicalColumnIndex(
                hotInstance,
                isPrefilledPrice
                  ? COLUMN_TYPE.PrefilledPrice
                  : COLUMN_TYPE.UnitPrice,
              )
            ];

          const extPrice =
            rows[physicalRow][
              getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.ExtPrice)
            ];

          const uom =
            rows[physicalRow][
              getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.UOM)
            ];

          if (
            column ===
              (isPrefilledPrice
                ? COLUMN_TYPE.PrefilledPrice
                : COLUMN_TYPE.UnitPrice) ||
            column ===
              (hasPositiveQuantity
                ? COLUMN_TYPE.PositiveQuantity
                : COLUMN_TYPE.Quantity)
          ) {
            const value = calcExtPrice(unitPrice, quantity);
            prefilledCells.push([
              physicalRow,
              getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.ExtPrice),
              !Number.isNaN(value) ? value : "",
            ]);
          }

          if (column === COLUMN_TYPE.ExtPrice) {
            const value = calcUnitPrice(quantity, extPrice);
            if (isLumpSumUomText(uom)) {
              prefilledCells.push([
                physicalRow,
                getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Quantity),
                extPrice,
              ]);
            } else {
              prefilledCells.push([
                physicalRow,
                getPhysicalColumnIndex(
                  hotInstance,
                  isPrefilledPrice
                    ? COLUMN_TYPE.PrefilledPrice
                    : COLUMN_TYPE.UnitPrice,
                ),
                !Number.isNaN(value) ? value : "",
              ]);
            }
          }
        });
        toggleReadOnly(hotInstance, true);
        setCells(prefilledCells, hotInstance, CUSTOM_SOURCE);
        toggleReadOnly(hotInstance, false);
      }
    },
    [
      calcExtPrice,
      calcUnitPrice,
      config,
      getPhysicalColumnIndex,
      setCells,
      toggleReadOnly,
    ],
  );

  const prefillForLumpSum = useCallback(
    (
      hotInstance: Handsontable | undefined | null,
      changes: CellChange[] | null,
      source: ChangeSource,
    ) => {
      const isPrefilledPrice = config.some(
        (c) => c.columnType === COLUMN_TYPE.PrefilledPrice,
      );

      if (!hotInstance) {
        return;
      }
      if (["edit", "CopyPaste.paste", "Autofill.fill"].includes(source)) {
        const prefilledCells = [] as [number, number, string | number][];
        changes?.forEach((change) => {
          const [row, column, , value] = change;
          const physicalRow = hotInstance?.toPhysicalRow(row) || row;
          const rows = hotInstance?.getData() as Record<string, string>[];

          const uom =
            rows[physicalRow][
              getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.UOM)
            ];

          const quantity =
            rows[physicalRow][
              getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Quantity)
            ];

          const unitPrice =
            rows[physicalRow][
              getPhysicalColumnIndex(
                hotInstance,
                isPrefilledPrice
                  ? COLUMN_TYPE.PrefilledPrice
                  : COLUMN_TYPE.UnitPrice,
              )
            ];

          const extPrice = calcExtPrice(unitPrice, quantity);

          const cellMeta = hotInstance.getCellMeta(
            row,
            getPhysicalColumnIndex(hotInstance, column as COLUMN_TYPE),
          );

          if (isLumpSumUomText(uom) && !cellMeta.disabledForLumpSum) {
            if (
              column === COLUMN_TYPE.PrefilledPrice ||
              column === COLUMN_TYPE.UnitPrice
            ) {
              prefilledCells.push([
                physicalRow,
                getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Quantity),
                !Number.isNaN(value) ? value : "",
              ]);

              prefilledCells.push([
                physicalRow,
                getPhysicalColumnIndex(
                  hotInstance,
                  isPrefilledPrice
                    ? COLUMN_TYPE.PrefilledPrice
                    : COLUMN_TYPE.UnitPrice,
                ),
                "1",
              ]);
            }
            if (column === COLUMN_TYPE.UOM) {
              prefilledCells.push([
                physicalRow,
                getPhysicalColumnIndex(hotInstance, COLUMN_TYPE.Quantity),
                extPrice,
              ]);

              prefilledCells.push([
                physicalRow,
                getPhysicalColumnIndex(
                  hotInstance,
                  isPrefilledPrice
                    ? COLUMN_TYPE.PrefilledPrice
                    : COLUMN_TYPE.UnitPrice,
                ),
                "1",
              ]);
            }
          }
        });

        toggleReadOnly(hotInstance, true);
        setCells(prefilledCells, hotInstance, CUSTOM_SOURCE);
        toggleReadOnly(hotInstance, false);
      }
    },
    [calcExtPrice, config, getPhysicalColumnIndex, setCells, toggleReadOnly],
  );

  return {
    prefillMaterialFields,
    prefillPrices,
    prefillExtraOptions,
    updateExtPriceDependence,
    prefillForLumpSum,
  };
};
