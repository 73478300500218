import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useDistributorReleaseDeliveryRecord } from "../providers/DistributorReleaseDeliveryRecordProvider";
import { DistributorReleaseItemIssuesPanel } from "./DistributorReleaseItemIssuesPanel";

type Props = {
  item: DistributorReleaseItemFieldsFragment;
};

export const DistributorReleaseItemIssuesButton: FC<Props> = ({ item }) => {
  const { issueResolutions } = useDistributorReleaseDeliveryRecord();
  const issue = useMemo(() => item?.issues?.[0], [item]);

  const hasIssueResolutions = useMemo(
    () =>
      issue?.resolution ||
      issueResolutions.some((r) => r.issueId === issue?.id),
    [issueResolutions, issue],
  );

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <>
          <If isTrue={hasIssueResolutions}>
            <OutlinedButton
              onClick={() => togglePanel(true)}
              className="h-7 min-h-1 w-26 p-0"
            >
              <FormattedMessage id="SOLUTION" />
            </OutlinedButton>
          </If>
          <If isTrue={!hasIssueResolutions}>
            <PrimaryButton
              onClick={() => togglePanel(true)}
              className="h-7 min-h-1 w-26 p-0"
              testId="resolve-issue-button"
            >
              <FormattedMessage id="RESOLVE" />
            </PrimaryButton>
          </If>
        </>
      )}
      content={(togglePanel) => (
        <DistributorReleaseItemIssuesPanel
          item={item}
          onClose={() => togglePanel(false)}
        />
      )}
    />
  );
};
