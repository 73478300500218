import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import {
  AdditionalChargesFieldsFragment,
  UpdateVendorReleaseInput,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import tw from "tailwind-styled-components";
import {
  DeliverySlipMappedItem,
  useDeliverySlipImportExternalPO,
} from "../../../../providers/DeliverySlipImportExternalPOProvider";
import { useDeliverySlipRelease } from "../../../../providers/DeliverySlipReleaseProvider";
import { useDeliverySlipVerification } from "../../../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipReleaseFormValues } from "../delivery-slip-form/DeliverySlipVerificationForm";
import { useDeliverySlipReleaseSpreadsheetConfig } from "./DeliverySlipRelease.config";
import { DeliverySlipCreateReleaseHeader } from "./DeliverySlipReleaseHeader";
import { DeliverySlipReleasePhotosPanel } from "./DeliverySlipReleasePhotosPanel";

const Container = tw.div`w-full flex flex-col flex-1 items-start px-2.5`;
const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-fit`;
const Footer = tw.div`mr-7 mt-10 w-full pb-5`;

export const DeliverySlipRelease: FC = () => {
  const { deliverySlip } = useDeliverySlipVerification();
  const { loading, setDeliveryPhotos } = useDeliverySlipRelease();
  const { importedItems } = useDeliverySlipImportExternalPO();
  const { findMaterialByName } = useTableHelpers();
  const { getPrefilledValue, calcTableTotal } = useTableHelpers();
  const { spreadsheetData } = useColumnMapper();
  const spreadsheetViewColumns = useDeliverySlipReleaseSpreadsheetConfig();

  const { watch, setValue } = useFormContext<DeliverySlipReleaseFormValues>();

  const taxRate = watch("taxRate");
  const customTaxAmount = watch("customTaxAmount");
  const subtotal = watch("subtotal");
  const additionalCharges = watch("additionalCharges");

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      const newTotal = calcTableTotal(data);
      if (newTotal !== watch("subtotal")) {
        setValue("subtotal", newTotal);
      }
    },
    [calcTableTotal, setValue, watch],
  );

  useEffect(() => {
    updateSubtotal(spreadsheetData);
  }, [spreadsheetData, updateSubtotal]);

  const total = useMemo(() => {
    const subTotal = new Decimal(subtotal || 0);
    const taxAmount = taxRate
      ? subTotal.mul(taxRate)
      : new Decimal(customTaxAmount || 0);
    const charges = (additionalCharges ?? []).reduce((acc, charge) => {
      return acc.plus(charge.amount);
    }, new Decimal(0));
    return subTotal.plus(taxAmount).plus(charges).toString();
  }, [customTaxAmount, subtotal, taxRate, additionalCharges]);

  const [tableItems, setTableItems] = useState<DeliverySlipMappedItem[]>([]);

  useEffect(() => {
    if (tableItems.length === 0) {
      setTableItems(
        importedItems ??
          deliverySlip?.items.map((item) => {
            const matchingOrgMaterial = findMaterialByName(
              item.description ?? "",
            );
            const prefilledPrice = getPrefilledValue(
              item.description ?? "",
              "",
              matchingOrgMaterial?.manufacturer?.name ??
                item.manufacturer ??
                "",
              matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
                matchingOrgMaterial?.defaultEstimateUom?.mnemonic ??
                item.uom ??
                "",
            );

            return {
              ...item,
              receivedQuantityDecimal: item.quantityDecimal,
              material: matchingOrgMaterial,
              costCode: matchingOrgMaterial?.costCode?.description ?? undefined,
              manufacturer:
                item.manufacturer ?? matchingOrgMaterial?.manufacturer?.name,
              UOM:
                item.uom ??
                matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
                matchingOrgMaterial?.defaultEstimateUom?.mnemonic,
              unitPrice: prefilledPrice?.value ?? undefined,
            };
          }) ??
          [],
      );
    } else if (importedItems) {
      setTableItems(importedItems);
    }
  }, [
    importedItems,
    deliverySlip?.items,
    findMaterialByName,
    tableItems.length,
    getPrefilledValue,
  ]);

  const handleUpdateRelease = (values: UpdateVendorReleaseInput) => {
    if (values.additionalCharges) {
      setValue("additionalCharges", values.additionalCharges);
    }
    if (
      values.customTaxAmount ||
      (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
    ) {
      setValue("customTaxAmount", values.customTaxAmount || "0");
      setValue("taxRate", undefined);
    }
    if (values.taxRate || values.clearCustomTaxAmount) {
      setValue("customTaxAmount", undefined);
      setValue("taxRate", values.taxRate || "0");
    }
    if (values.paymentTerm) {
      setValue("paymentTerm", values.paymentTerm);
    }

    return true;
  };

  return (
    <Container>
      <DeliverySlipCreateReleaseHeader />
      <SpreadSheetView>
        <SpreadSheetTable
          items={tableItems}
          columns={spreadsheetViewColumns}
          saving={loading}
          height="350px"
          rowNumber={10}
          onChanges={updateSubtotal}
        />
      </SpreadSheetView>
      <DeliverySlipReleasePhotosPanel
        updateAssets={(assets) => setDeliveryPhotos(assets)}
      />
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          total={`${total}`}
          subtotal={`${subtotal}`}
          customTaxAmount={watch("customTaxAmount")}
          customTaxRate={watch("taxRate")}
          customPaymentTerm={`${watch("paymentTerm")}`}
          editableByContractor
          editablePaymentTerms
          updateRelease={(values) =>
            handleUpdateRelease({ ...values, version: -1 })
          }
          includePaymentTerms
          additionalCharges={
            watch("additionalCharges") as AdditionalChargesFieldsFragment[]
          }
        />
      </Footer>
    </Container>
  );
};
