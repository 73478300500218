import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";

type Props = {
  items: BuyoutItemFieldsFragment[];
};

export const BuyoutCategoryPrice: FC<Props> = ({ items }) => {
  const price = useMemo(() => {
    return items
      .reduce((total, item) => {
        return total.plus(
          new Decimal(
            new Decimal(item.unitPrice || 0).mul(item.quantityDecimal || 0) ??
              0,
          ),
        );
      }, new Decimal(0))
      .toString();
  }, [items]);

  return (
    <ValueCurrency
      value={price}
      classNames={{ value: "font-medium text-right", container: "items-end" }}
    />
  );
};
