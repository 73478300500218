import { StyledNumericInput } from "@/common/components/styled-numeric-input/StyledNumericInput";
import { MAX_PRICE_DECIMALS } from "@/common/const";
import {
  EstimatedItemFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useState } from "react";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";

type Props = {
  estimatedItem?: EstimatedItemFieldsFragment | null;
  item: ProjectItemFieldsFragment;
  readonly?: boolean;
  staticText?: boolean;
};

export const ProjectItemUnitPrice: FC<Props> = ({
  estimatedItem,
  item,
  readonly = false,
  staticText = false,
}) => {
  const { setNewProjectEstimatedItem } = useEstimatedItems();
  const [value, setValue] = useState<string | null>(
    estimatedItem?.unitPrice || "0",
  );

  const saveUnitPrice = useCallback(() => {
    setNewProjectEstimatedItem([{ key: "unitPrice", value }]);
  }, [setNewProjectEstimatedItem, value]);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const priceValue = event.target.value;

    setValue(priceValue);
  }, []);

  return (
    <StyledNumericInput
      value={Number(estimatedItem?.unitPrice)}
      uom={item.estimateUom}
      id={(estimatedItem || item).id}
      onBlur={saveUnitPrice}
      onChange={onChange}
      readonly={readonly}
      staticText={staticText}
      decimals={MAX_PRICE_DECIMALS}
      includeCurrency
      testId="editable-project-item-unit-price"
    />
  );
};
