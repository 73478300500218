import * as Sentry from "@sentry/react";
import { useCallback } from "react";

export const useValidateRequiredParams = (name: string) => {
  const validateParam = useCallback(
    ({
      value,
      methodName,
      paramName,
    }: {
      value: unknown;
      methodName: string;
      paramName: string;
    }) => {
      if (!value) {
        Sentry.captureMessage(
          `${name}: ${methodName}: ${paramName} is ${value}`,
        );
        return false;
      }
      return true;
    },
    [name],
  );

  return { validateParam };
};
