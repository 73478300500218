import { ServiceProductCategoryName, ServiceType } from "@/generated/graphql";

export type CategoryProps<T> = {
  id: T;
  isOpened: boolean;
};

export type DeliveryCategoryId = {
  id?: string;
  productCategory: ServiceProductCategoryName;
  type: ServiceType;
  isOpened?: boolean;
};

export const serviceCategories: DeliveryCategoryId[] = [
  {
    productCategory: ServiceProductCategoryName.Drywall,
    type: ServiceType.Stocking,
  },
  {
    productCategory: ServiceProductCategoryName.LightGaugeSteel,
    type: ServiceType.Stocking,
  },
  {
    productCategory: ServiceProductCategoryName.All,
    type: ServiceType.Stocking,
  },
  {
    productCategory: ServiceProductCategoryName.All,
    type: ServiceType.Delivery,
  },
];
