import { routes } from "@/config/routes";
import { WithMainTabNavigation } from "../common/navigation/WithMainTabNavigation";
import { ReceiptRecordOrder } from "./pages/receipt-record-order/ReceiptRecordOrder";
import { Receipts } from "./pages/receipts/Receipts";

export const receiptRoutes = [
  {
    path: routes.receipts,
    element: (
      <WithMainTabNavigation>
        <Receipts />
      </WithMainTabNavigation>
    ),
  },
  {
    path: routes.processReceipt,
    element: <ReceiptRecordOrder />,
  },
];
