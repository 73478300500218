import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { ReceiptSummaryFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Item = tw.div`text-sm`;

type Props = {
  receipt: ReceiptSummaryFieldsFragment;
};

export const ReceiptScanDate: FC<Props> = ({ receipt }) => {
  return (
    <NotNullableRenderer value={!!receipt.createdAt}>
      <Item>
        <DateView date={receipt.createdAt} options={SHORT_DATE_OPTION} />
      </Item>
    </NotNullableRenderer>
  );
};
