import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { NumericalInput } from "@/common/components/numerical-input/NumericalInput";
import { Price } from "@/common/components/price/Price";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useUser } from "@/common/providers/UserProvider";
import {
  AdditionalChargesFieldsFragment,
  OrderTypeFieldsFragment,
  ReleaseFieldsFragment,
  ReleaseItemFieldsFragment,
  ReleaseStatus,
  UpdateContractorReleaseInput,
  UpdateVendorReleaseFieldsFragment,
  UpdateVendorReleaseInput,
} from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import Decimal from "decimal.js";
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  AdditionalChargesContainer,
  AdditionalChargesItemContainer,
  ItemContainer,
  TotalItemContainer,
  TotalItemOuter,
} from "../additional-charges/AdditionalCharges.styles";
import {
  AmountPercentageSwitch,
  SalesTaxType,
} from "../amount-percentage-switch/AmountPercentageSwitch";
import { InfoTooltip } from "../info-tooltip/InfoTooltip";
import {
  OrgRolesWrapper,
  Permission,
} from "../org-roles-wrapper/OrgRolesWrapper";
import { ReleaseTermsAndNotes } from "../release-terms-and-notes/ReleaseTermsAndNotes";
import { useFormatNumberToCurrency } from "../value-currency/hooks/useFormatNumberToCurrency";
import { ReleaseAdditionalCharges } from "./ReleaseAdditionalCharges";

const SubTotalItemContainer = tw(
  AdditionalChargesItemContainer,
)`text-base pr-10`;
const Sub = tw.span`font-normal`;
const StyledNumericalInput = tw(NumericalInput)`w-full`;
const Item = tw.div`font-medium text-sm`;
const Value = tw.div`font-normal text-sm`;
const TotalItem = tw.div`-mr-1`;
const NoPriceContainer = tw.div`flex justify-end items-center`;
const LinkLikeStyled = tw(LinkLike)`pl-1`;
const Label = tw.div`flex gap-3 items-center justify-end`;
const SalesReadonlyTaxLabel = tw.div`font-medium text-sm`;
const PartialItems = tw.div`text-xs font-normal text-gray-600`;
const InfoTooltipContainer = tw.div``;

type Props = {
  release?:
    | ((Pick<
        ReleaseFieldsFragment,
        | "additionalCharges"
        | "taxRate"
        | "notes"
        | "paymentTerm"
        | "status"
        | "assets"
        | "id"
        | "version"
        | "customTaxAmount"
        | "taxAmount"
        | "type"
      > & { project?: { id: string } | null }) & {
        items:
          | (Pick<
              ReleaseItemFieldsFragment,
              "receivedQuantityDecimal" | "unitPrice"
            > & {
              invoicedQuantity?: string | undefined | null;
            })[]
          | null;
      } & { invoices?: { total?: string | null }[] })
    | null;
  showError?: boolean;
  total?: string | null;
  subtotal?: string | null;
  taxAmount?: string | null;
  customTaxAmount?: string | null;
  customTaxRate?: string | null;
  customPaymentTerm?: string | null;
  additionalItems?: React.ReactNode;
  editableByContractor?: boolean;
  updateRelease?: (
    input: UpdateVendorReleaseInput | UpdateContractorReleaseInput,
  ) =>
    | Promise<UpdateVendorReleaseFieldsFragment | boolean | undefined | null>
    | (UpdateVendorReleaseFieldsFragment | boolean | undefined | null);
  onTypeChange?: (type: SalesTaxType) => void;
  onTaxRateChange?: (value: string) => void;
  includeNotesPanel?: boolean;
  includePaymentTerms?: boolean;
  includeAdditionalCharges?: boolean;
  includeSubtotal?: boolean;
  includeInvoicedTotals?: boolean;
  editablePaymentTerms?: boolean;
  editableAdditionalCharges?: boolean;
  readonly?: boolean;
  additionalTaxes?: ReactNode;
  additionalCharges?: AdditionalChargesFieldsFragment[];
  classes?: {
    total?: string;
    tax?: string;
  };
  hideTotalBorder?: boolean;
  totalDivider?: ReactNode;
  includeSaleTaxLabel?: boolean;
  itemsCount?: {
    partial: number;
    total: number;
  };
  taxExempt?: {
    isProjectTaxExempt?: boolean;
    isVendorTaxExempt?: boolean;
    vendorName?: string;
  };
  orderType?: Pick<OrderTypeFieldsFragment, "includeAdditionalCharges"> | null;
  salesTaxType?: SalesTaxType;
};

export const ReleaseAdditionalChargesAndTaxes: FC<Props> = ({
  release,
  total,
  subtotal,
  showError,
  taxAmount,
  customTaxAmount,
  customTaxRate,
  customPaymentTerm,
  additionalItems = null,
  editableByContractor = false,
  includeNotesPanel = true,
  includePaymentTerms = false,
  editablePaymentTerms = false,
  editableAdditionalCharges = true,
  includeAdditionalCharges = true,
  includeSaleTaxLabel = true,
  includeSubtotal = true,
  includeInvoicedTotals = false,
  readonly = false,
  additionalTaxes,
  additionalCharges,
  updateRelease,
  onTypeChange,
  onTaxRateChange,
  classes,
  hideTotalBorder,
  totalDivider,
  itemsCount,
  taxExempt,
  orderType,
  salesTaxType,
}) => {
  const { isContractor } = useUser();
  const { formatCurrency } = useFormatNumberToCurrency();
  const [salesTax, setSalesTax] = useState(
    release?.taxRate
      ? Number(new Decimal(release?.taxRate ?? 0).mul(100))
      : null,
  );
  const [salesAmount, setSalesAmount] = useState(
    release?.customTaxAmount || customTaxAmount
      ? Number(release?.customTaxAmount || customTaxAmount)
      : 0,
  );
  const [paymentTerm, setPaymentTerm] = useState(
    (release?.paymentTerm || customPaymentTerm) ?? null,
  );
  const [type, setType] = useState<SalesTaxType>(
    salesTaxType ||
      (release?.customTaxAmount || (customTaxAmount && Number(customTaxAmount))
        ? SalesTaxType.Amount
        : SalesTaxType.Percent),
  );

  useEffect(() => {
    if (salesTaxType) {
      setType(salesTaxType);
    } else {
      if (
        release?.customTaxAmount ||
        (customTaxAmount && Number(customTaxAmount))
      ) {
        setType(SalesTaxType.Amount);
      } else {
        setType(SalesTaxType.Percent);
      }
    }
  }, [customTaxAmount, release?.customTaxAmount, salesTaxType]);

  useEffect(() => {
    if (release) {
      setSalesTax(
        release.taxRate ? Number(new Decimal(release.taxRate).mul(100)) : 0,
      );
      setSalesAmount(
        release.customTaxAmount ? Number(release.customTaxAmount) : 0,
      );
    }
  }, [release]);

  useEffect(() => {
    if (customTaxRate !== undefined) {
      setSalesTax(Number(customTaxRate) * 100);
    }

    if (!release?.customTaxAmount && !customTaxAmount && customTaxRate) {
      setType(SalesTaxType.Percent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customTaxRate]);

  useEffect(() => {
    if (customTaxAmount !== undefined) {
      setSalesAmount(Number(customTaxAmount));
    }
  }, [customTaxAmount]);

  const isPartialAmount = useMemo(
    () => itemsCount?.partial !== itemsCount?.total,
    [itemsCount?.partial, itemsCount?.total],
  );

  const isOrderScheduledOrInEditByVendor = useMemo(
    () =>
      release?.status !== ReleaseStatus.Draft &&
      release?.status !== ReleaseStatus.AwaitingApproval &&
      release?.status !== ReleaseStatus.Rejected &&
      (!isContractor || release?.status !== ReleaseStatus.Requested),
    [release?.status, isContractor],
  );
  const intl = useIntl();

  const calculatedTaxAmount = useMemo(() => {
    const currentSubtotal =
      !subtotal || subtotal === "undefined" ? 0 : subtotal;

    return new Decimal(currentSubtotal)
      .mul(salesTax || 0)
      .div(100)
      .toNumber();
  }, [subtotal, salesTax]);

  const formattedTaxAmount = useMemo(() => {
    return `${formatCurrency(taxAmount || customTaxAmount || calculatedTaxAmount, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`;
  }, [calculatedTaxAmount, formatCurrency, taxAmount, customTaxAmount]);

  const canEdit = useMemo(
    () => !readonly && (!isContractor || editableByContractor),
    [readonly, isContractor, editableByContractor],
  );

  const formattedSalesTax = useMemo(
    () =>
      canEdit
        ? salesTax
        : release?.customTaxAmount
          ? formattedTaxAmount
          : `${intl.formatNumber(Number(salesTax))}% (${formattedTaxAmount})`,
    [canEdit, salesTax, release?.customTaxAmount, formattedTaxAmount, intl],
  );

  const changeType = useCallback(
    async (type: SalesTaxType) => {
      if (type) {
        setType(type);
        onTypeChange?.(type);
        if (type === SalesTaxType.Amount) {
          await updateRelease?.({
            releaseId: release?.id || "",
            version: release?.version,
            customTaxAmount:
              release?.taxAmount ||
              (salesAmount
                ? String(salesAmount)
                : new Decimal(calculatedTaxAmount).toDP(2).toString()),
            clearCustomTaxAmount: false,
          });
        } else {
          await updateRelease?.({
            releaseId: release?.id || "",
            version: release?.version,
            clearCustomTaxAmount: true,
            customTaxAmount: null,
            taxRate: release?.taxRate,
          });
        }
      }
    },
    [
      onTypeChange,
      updateRelease,
      release?.id,
      release?.version,
      release?.taxAmount,
      release?.taxRate,
      salesAmount,
      calculatedTaxAmount,
    ],
  );

  const receivedSoFar = useMemo(() => {
    return release?.items?.reduce((acc, item) => {
      return new Decimal(acc)
        .add(
          new Decimal(item.receivedQuantityDecimal || 0).mul(
            item.unitPrice || 0,
          ),
        )
        .toNumber();
    }, 0);
  }, [release]);

  const invoicedQuantity = useMemo(() => {
    const invoiceSubtotal = release?.invoices?.reduce((acc, item) => {
      return acc.add(item.total || 0);
    }, new Decimal(0));

    return invoiceSubtotal?.toNumber() || 0;
  }, [release]);

  return (
    <OrgRolesWrapper
      permissions={isContractor ? [Permission.canViewPrices] : []}
    >
      <AdditionalChargesContainer>
        <If isTrue={includePaymentTerms}>
          <If isTrue={!editablePaymentTerms || !canEdit}>
            <ItemContainer className="mt-0 h-10">
              <FormattedMessage id="PAYMENT_TERMS" tagName={Item} />
              <FormattedMessage
                id="PAYMENT_TERM_NET_WITH_DAYS"
                tagName={Value}
                values={{ days: release?.paymentTerm }}
              />
            </ItemContainer>
          </If>
          <If isTrue={!!editablePaymentTerms && canEdit}>
            <ItemContainer className="h-10">
              <FormattedMessage id="PAYMENT_TERMS" tagName={Item} />
              <StyledNumericalInput
                value={paymentTerm}
                testId="order-paymentTerms"
                onChange={(e) => setPaymentTerm(Number(e.target.value))}
                onBlur={() =>
                  updateRelease?.({
                    releaseId: release?.id || "",
                    version: release?.version,
                    paymentTerm: Number(paymentTerm),
                  })
                }
                className="w-full"
                xs
                inputProps={{
                  className: "text-right bg-white",
                }}
              />
            </ItemContainer>
          </If>
        </If>
        <If isTrue={includeSubtotal}>
          <SubTotalItemContainer>
            <FormattedMessage id="SUBTOTAL" tagName={Item} />
            <Price
              testId="order-subtotal"
              price={subtotal}
              maximumFractionDigits={2}
              className="text-sm font-normal"
              zeroValuePlaceholder={
                <NoPriceContainer>
                  --{" "}
                  <Tooltip
                    id="subtotal-price"
                    element={
                      <LinkLikeStyled onClick={() => null} forwardEvent={false}>
                        <InfoOutlined />
                      </LinkLikeStyled>
                    }
                  >
                    <FormattedMessage id="NO_SUBTOTAL_TOOLTIP" />
                  </Tooltip>
                </NoPriceContainer>
              }
            />
          </SubTotalItemContainer>
        </If>
        <If
          isTrue={
            includeAdditionalCharges &&
            (release?.type.includeAdditionalCharges ||
              orderType?.includeAdditionalCharges)
          }
        >
          <ReleaseAdditionalCharges
            release={release}
            readonly={!canEdit || !editableAdditionalCharges}
            showError={showError}
            updateRelease={updateRelease}
            additionalCharges={additionalCharges}
          />
        </If>
        {additionalItems}
        <ItemContainer className={classes?.tax} data-testid="order-salesTax">
          <Label>
            <If isTrue={includeSaleTaxLabel}>
              <FormattedMessage
                id="SALES_TAX"
                tagName={!canEdit ? SalesReadonlyTaxLabel : undefined}
              />
            </If>
            <If isTrue={canEdit}>
              <AmountPercentageSwitch type={type} changeType={changeType} />
            </If>
          </Label>
          {!canEdit ? (
            <Value>{formattedSalesTax}</Value>
          ) : type === SalesTaxType.Percent ? (
            <StyledNumericalInput
              value={formattedSalesTax}
              onChange={(e) => setSalesTax(Number(e.target.value))}
              onBlur={async () => {
                onTaxRateChange?.(
                  new Decimal(salesTax || 0).dividedBy(100).toString(),
                );
                await updateRelease?.({
                  releaseId: release?.id || "",
                  version: release?.version,
                  taxRate: salesTax
                    ? new Decimal(salesTax).dividedBy(100).toString()
                    : "0",
                  clearCustomTaxAmount: true,
                  customTaxAmount: null,
                });
              }}
              label={intl.$t(
                { id: "TAX_WITH_AMOUNT" },
                {
                  taxAmount: formattedTaxAmount,
                },
              )}
              suffix="%"
              className="w-full"
              xs
              inputProps={{
                className: "text-right bg-white",
              }}
            />
          ) : (
            <StyledNumericalInput
              testId="tax"
              value={salesAmount}
              onChange={(e) => setSalesAmount(Number(e.target.value))}
              onBlur={() =>
                updateRelease?.({
                  releaseId: release?.id || "",
                  version: release?.version,
                  customTaxAmount: salesAmount?.toString() || "0",
                  clearCustomTaxAmount: false,
                })
              }
              label={intl.$t({ id: "TAX" })}
              includeCurrency
              className="w-full"
              xs
              inputProps={{
                className: "text-right bg-white",
              }}
            />
          )}
          <InfoTooltipContainer>
            <If
              isTrue={
                taxExempt?.isProjectTaxExempt || taxExempt?.isVendorTaxExempt
              }
            >
              <InfoTooltip
                message={intl.$t(
                  {
                    id: taxExempt?.isProjectTaxExempt
                      ? "TAX_EXEMPT_PROJECT_TOOLTIP"
                      : "TAX_EXEMPT_VENDOR_TOOLTIP",
                  },
                  {
                    vendor: taxExempt?.vendorName,
                  },
                )}
              />
            </If>
          </InfoTooltipContainer>
        </ItemContainer>
        {totalDivider}
        <TotalItemOuter className={classes?.total}>
          <TotalItemContainer
            $highlightTotal={!additionalTaxes && !hideTotalBorder}
            $hasAdditionalTaxes={!!additionalTaxes}
          >
            <TotalItem>
              <FormattedMessage
                id="TOTAL_ORDER"
                values={{ sub: (...chunks) => <Sub>{chunks}</Sub> }}
              />
              <If isTrue={isPartialAmount}>
                <FormattedMessage
                  id="ITEMS_OUT_OF"
                  values={{
                    partial: itemsCount?.partial,
                    total: itemsCount?.total,
                  }}
                  tagName={PartialItems}
                />
              </If>
            </TotalItem>
            <Price
              price={total}
              maximumFractionDigits={2}
              zeroValuePlaceholder={
                <NoPriceContainer>
                  --{" "}
                  <Tooltip
                    id="subtotal-price"
                    element={
                      <LinkLikeStyled forwardEvent={false} onClick={() => null}>
                        <InfoOutlined />
                      </LinkLikeStyled>
                    }
                  >
                    <FormattedMessage id="NO_TOTAL_TOOLTIP" />
                  </Tooltip>
                </NoPriceContainer>
              }
              className={!additionalTaxes ? "font-bold" : "font-normal"}
              testId="total-price"
            />
          </TotalItemContainer>
        </TotalItemOuter>
        <If isTrue={includeInvoicedTotals}>
          <ItemContainer>
            <FormattedMessage id="ORDER_RECEIVED_SO_FAR" tagName={Item} />
            <Price
              testId="order-receivedSoFar"
              price={receivedSoFar}
              maximumFractionDigits={2}
              className="font-normal"
            />
          </ItemContainer>
          <ItemContainer className="mb-2">
            <FormattedMessage id="ORDER_ITEM_INVOICED" tagName={Item} />
            <Price
              testId="order-itemInvoiced"
              price={invoicedQuantity}
              maximumFractionDigits={2}
              className="font-normal"
            />
          </ItemContainer>
        </If>
        <If
          isTrue={
            includeNotesPanel && (isOrderScheduledOrInEditByVendor || canEdit)
          }
        >
          <ReleaseTermsAndNotes release={release} readonly={isContractor} />
        </If>
        {additionalTaxes}
      </AdditionalChargesContainer>
    </OrgRolesWrapper>
  );
};
