import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { SelectableItem } from "@/common/components/searchable-list/components/SelectableItem";
import { SmContainer, XlContainer } from "@/common/layout/ResponsiveClasses";
import { DateView } from "@/common/utils/dates/DateView";
import { ExternalProjectFieldsFragment } from "@/generated/graphql";
import { Identity } from "@/types/Identity";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { ExternalProjectConnectionCheck } from "./ExternalProjectConnectionCheck";

const Container = tw.div`
  ${({ $disabled }: { $disabled?: boolean }) => ($disabled ? "opacity-50" : "")}
`;

export const useExternalProjectsTableConfiguration = (
  selectedItems?: string[],
  projectId?: string | null,
): Array<GridCol<ExternalProjectFieldsFragment & Identity>> => {
  return [
    {
      header: <FormattedMessage id="PROJECT_NAME" />,
      wrapper: XlContainer,
      item: ({ item, index, count }) => (
        <ExternalProjectConnectionCheck item={item} projectId={projectId}>
          <SelectableItem
            item={item}
            index={index}
            selectedItems={selectedItems ?? []}
            itemNameSelectorFn={(item) => item.name}
            disabled={!!item.project?.id && item.project.id !== projectId}
            count={count}
          />
        </ExternalProjectConnectionCheck>
      ),
    },
    {
      header: <FormattedMessage id="JOB_NUMBER" />,
      wrapper: SmContainer,
      item: ({ item }) => (
        <ExternalProjectConnectionCheck item={item} projectId={projectId}>
          <Container
            $disabled={!!item.project?.id && item.project.id !== projectId}
          >
            <NotNullableRenderer value={!!item.number}>
              {item.number}
            </NotNullableRenderer>
          </Container>
        </ExternalProjectConnectionCheck>
      ),
    },
    {
      header: <FormattedMessage id="ADDRESS" />,
      wrapper: XlContainer,
      item: ({ item }) => (
        <ExternalProjectConnectionCheck item={item} projectId={projectId}>
          <Container
            $disabled={!!item.project?.id && item.project.id !== projectId}
          >
            {item.address?.addressLine1
              ? `${item.address?.addressLine1.trim()} `
              : ""}
            {item.address?.addressLine2
              ? `${item.address?.addressLine2.trim()}, `
              : ""}
            {item.address?.city ? `${item.address.city.trim()}, ` : ""}
            {item.address?.state ? `${item.address.state} ` : ""}
            {item.address?.postalCode
              ? `${item.address.postalCode.trim()}`
              : ""}
          </Container>
        </ExternalProjectConnectionCheck>
      ),
    },
    {
      header: <FormattedMessage id="START_DATE" />,
      wrapper: SmContainer,
      item: ({ item }) => (
        <ExternalProjectConnectionCheck item={item} projectId={projectId}>
          <Container
            $disabled={!!item.project?.id && item.project.id !== projectId}
          >
            <NotNullableRenderer value={!!item.startDate}>
              <DateView date={item.startDate} />
            </NotNullableRenderer>
          </Container>
        </ExternalProjectConnectionCheck>
      ),
    },
  ];
};
