import { If } from "@/common/components/if/If";
import {
  TableViewProvider,
  VIEW_STATE,
  useTableView,
} from "@/common/providers/TableViewProvider";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useProject } from "../../providers/ProjectProvider";
import { ProjectSpendingReportItemsProvider } from "../../providers/ProjectSpendingReportItemsProvider";
import {
  ProjectSpendingReportProvider,
  useSpendingReport,
} from "../../providers/ProjectSpendingReportProvider";
import { SpendingReportChart } from "./components/spending-report-chart/SpendingReportChart";
import { SpendingReportFooter } from "./components/spending-report-footer/SpendingReportFooter";
import { SpendingReportHeader } from "./components/spending-report-header/SpendingReportHeader";
import { useSpendingReportListItemConfiguration } from "./spending-report-list/SpendingReportListItem.configuration";
import { SpendingReportListMaterials } from "./spending-report-list/SpendingReportListMaterials";
import { SpendingReportListVendors } from "./spending-report-list/SpendingReportListVendors";

const Container = tw.div`relative`;

const SpendingReportReportWithProvider = () => {
  const { materialsConfig, vendorsConfig } =
    useSpendingReportListItemConfiguration();
  const { tableView } = useTableView();
  const { showChart } = useSpendingReport();

  return (
    <ProjectSpendingReportItemsProvider>
      <Container>
        <SpendingReportHeader />
        <If isTrue={showChart}>
          <SpendingReportChart />
        </If>
        <If isTrue={tableView === VIEW_STATE.materials}>
          <SpendingReportListMaterials columns={materialsConfig} />
        </If>
        <If isTrue={tableView === VIEW_STATE.vendors}>
          <SpendingReportListVendors columns={vendorsConfig} />
        </If>
        <SpendingReportFooter />
      </Container>
    </ProjectSpendingReportItemsProvider>
  );
};

export const SpendingReportReport: FC = () => {
  const { project } = useProject();

  if (!project) {
    return null;
  }

  return (
    <TableViewProvider defaultView={VIEW_STATE.materials}>
      <ProjectSpendingReportProvider>
        <ProjectSpendingReportItemsProvider>
          <SpendingReportReportWithProvider />
        </ProjectSpendingReportItemsProvider>
      </ProjectSpendingReportProvider>
    </TableViewProvider>
  );
};
