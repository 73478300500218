import { If } from "@/common/components/if/If";
import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import useIsEllipsisVisibleHook from "./useIsEllipsisVisibleHook";

const NotesPreviewText = tw.p`line-clamp-3 text-start justify-end flex`;

export const NotesLink = tw.div`
  grid cursor-pointer justify-end grid-flow-col place-items-center gap-1 text-xs text-blue-500 hover:text-blue-300 rounded-2xl h-fit
`;

const NotesPreview = ({
  text = "",
  openNotes,
}: {
  text?: string | null;
  openNotes: () => void;
}) => {
  const ref = useRef<HTMLParagraphElement>(null);
  const { isEllipsisVisible } = useIsEllipsisVisibleHook(ref);

  return (
    <>
      <NotesPreviewText ref={ref}>{text}</NotesPreviewText>
      <If isTrue={isEllipsisVisible}>
        <NotesLink onClick={openNotes}>
          <FormattedMessage id="VIEW_ALL" />
        </NotesLink>
      </If>
    </>
  );
};

export default NotesPreview;
