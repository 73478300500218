import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { GridTable } from "@/common/components/grid-table/GridTable";
import { If } from "@/common/components/if/If";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { Switch } from "@/common/components/switch/Switch";
import {
  WizardModal,
  WizardModalMode,
  WizardModalPage,
} from "@/common/components/wizard-modal/WizardModal";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { getVendorContacts } from "@/common/utils/getVendorContacts";
import { ReleaseStatus } from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  AssignableBuyoutWithBestMatch,
  useAssignBuyout,
} from "../../../providers/AssignBuyoutProvider";
import { useRelease } from "../../../providers/ReleaseProvider";
import { useSyncReleaseItems } from "../hooks/useSyncReleaseItems";
import { useAssignBuyoutConfiguration } from "./AssignBuyout.configuration";
import { ExpandedItemComponent } from "./AssignBuyoutExpandedItemComponent";
import { PreRowComponent } from "./AssignBuyoutPreRowComponent";
import { AssignBuyoutVendorInfo } from "./AssignBuyoutVendorInfo";
import { BuyoutsItemsComparisonTable } from "./BuyoutsItemsComparisonTable";

const Container = tw.div`flex flex-col items-center justify-between w-full h-full`;
const Header = tw.div`grid grid-cols-[auto_auto_1fr_auto] gap-4 justify-between items-center w-full px-5`;
const Title = tw.div`grid grid-flow-col gap-1 text-xl font-semibold text-black pl-3`;
const BuyoutNumber = tw.div`text-xl font-normal text-black`;
const ValidationWrapper = tw.div`flex flex-col items-center gap-2 text-sm text-gray-600 h-full justify-center`;
const ItemsContainer = tw.div`flex flex-col w-full h-full`;
const MainContent = tw.div`p-4 w-full h-full overflow-y-auto`;
const SwitchWrapper = tw.div`flex justify-end items-center gap-1 flex-grow-1 ml-auto`;
const SearchFilterWrapper = tw.div`max-w-[_200px]`;

const LOCKED_VENDOR_STATUSES = [
  ReleaseStatus.Requested,
  ReleaseStatus.Scheduled,
  ReleaseStatus.Received,
  ReleaseStatus.PartiallyReceived,
];

const AssignBuyoutModalContent: FC = () => {
  const intl = useIntl();
  const { release } = useRelease();
  const configuration = useAssignBuyoutConfiguration();
  const {
    selectedItem,
    setSelectedItem,
    expandedItem,
    items,
    allVendors,
    setAllVendors,
    search,
    setSearch,
  } = useAssignBuyout();
  const getPreRowComponent = useCallback(
    (item: AssignableBuyoutWithBestMatch) => (
      <PreRowComponent item={item} items={items} />
    ),
    [items],
  );
  const getExpandedComponent = useCallback(
    (item: AssignableBuyoutWithBestMatch) => (
      <ExpandedItemComponent
        item={item}
        isExpanded={expandedItem === item?.buyout?.id}
        release={release}
      />
    ),
    [expandedItem, release],
  );

  const vendorLock = LOCKED_VENDOR_STATUSES.includes(
    release?.status ?? ReleaseStatus.Draft,
  );

  useEffect(() => {
    if (vendorLock) {
      setAllVendors(false);
    }
  }, [vendorLock, setAllVendors]);

  const condition = useMemo(() => (items.length || 0) > 0, [items.length]);

  return (
    <>
      <Header>
        <Title>
          <FormattedMessage
            id={
              release?.buyout?.clientIdentifier
                ? "CHANGE_BUYOUT"
                : "ASSIGN_BUYOUT"
            }
          />
          <BuyoutNumber>
            {release?.buyout?.clientIdentifier
              ? "#" + release?.buyout?.clientIdentifier
              : ""}
          </BuyoutNumber>
        </Title>
        <AssignBuyoutVendorInfo
          logoImageUrl={release?.sellerOrgLocation?.org.logoImageUrl}
          address={release?.sellerOrgLocation?.address}
          contactName={getVendorContacts(
            release?.preferredVendor?.contacts.filter(
              (c) => c.receivesOrderNotifications,
            ),
          )}
          orgName={release?.sellerOrgLocation?.org.name}
        />
        <SwitchWrapper>
          <If isTrue={!vendorLock}>
            <FormattedMessage id="VIEW_BUYOUTS_FROM_OTHER_VENDORS" />
            <Switch
              onLabel={intl.$t({ id: "YES" })}
              offLabel={intl.$t({ id: "NO" })}
              onChange={() => setAllVendors(!allVendors)}
              value={allVendors}
            />
          </If>
        </SwitchWrapper>
        <SearchFilterWrapper>
          <SearchInput
            updateOnChange
            value={search}
            onChange={(value) => setSearch(value)}
            placeHolder={intl.$t({ id: "SEARCH_BUYOUTS" })}
          />
        </SearchFilterWrapper>
      </Header>

      <Container>
        <MainContent>
          <ListRenderer
            hasItemsCondition={condition}
            emptyList={<NoResults translationKey="NO_MATCHING_BUYOUTS" />}
          >
            <GridTable
              configuration={{
                container: ItemsContainer,
                columns: configuration,
                classNames: {
                  itemFn: (
                    item: AssignableBuyoutWithBestMatch,
                    category,
                    index,
                  ) => ({
                    className: `
                      first:mt-0 
                        ${
                          index === 0
                            ? "border-green-600 lg:border-2 lg:rounded-md lg:mb-2"
                            : ""
                        } 
                        ${
                          selectedItem && item.buyout?.id === selectedItem
                            ? "bg-blue-100"
                            : ""
                        } 
                      `,
                  }),
                },
                toggle: {
                  item: (item: AssignableBuyoutWithBestMatch) => {
                    setSelectedItem(item.buyout.id);
                  },
                },
              }}
              items={items}
              readonly
              expandedItemComponent={(item: AssignableBuyoutWithBestMatch) =>
                getExpandedComponent(item)
              }
              preRowItemComponent={(item: AssignableBuyoutWithBestMatch) =>
                getPreRowComponent(item)
              }
              virtualizedItemsCount={20}
              virtualized
            />
          </ListRenderer>
        </MainContent>
      </Container>
    </>
  );
};

const UserValidation: FC = () => {
  const intl = useIntl();
  const { release } = useRelease();
  const { selectedItem, items } = useAssignBuyout();

  return (
    <>
      <Container>
        <MainContent>
          <ValidationWrapper>
            <WarningIcon />
            <Title>{intl.$t({ id: "APPLY_CHANGES" })}</Title>
            <FormattedMessage id="APPLY_BUYOUT_CHANGES_DESCRIPTION" />
            <BuyoutsItemsComparisonTable
              release={release}
              newBuyout={
                items.find((el) => el.buyout.id === selectedItem)?.buyout
              }
            />
          </ValidationWrapper>
        </MainContent>
      </Container>
    </>
  );
};

type Props = {
  visible: boolean;
  handleCancel: () => void;
};

export const AssignBuyoutModal: FC<Props> = (props) => {
  const intl = useIntl();
  const { moveToNextNestedStep, moveToPreviousNestedStep, setNestedStep } =
    useNestedStepper();
  const { setSuccessAlert } = useSnackbar();
  const { changeReleaseBuyout } = useRelease();
  const { getSyncedRelease } = useSyncReleaseItems();
  const { selectedItem } = useAssignBuyout();

  const resetModal = useCallback(() => {
    setNestedStep(0);
  }, [setNestedStep]);

  const closeModal = useCallback(() => {
    props.handleCancel();
    resetModal();
  }, [resetModal, props]);
  const { setWarningAlert } = useSnackbar();

  const handleSave = useCallback(async () => {
    const syncedRelease = await getSyncedRelease();
    if (!syncedRelease) {
      return;
    }
    const result = await changeReleaseBuyout({
      ...syncedRelease,
      buyoutId: selectedItem,
    });
    if (result) {
      setSuccessAlert(intl.$t({ id: "CHANGES_SAVED" }));
      props.handleCancel();
      resetModal();
    }
  }, [
    changeReleaseBuyout,
    getSyncedRelease,
    intl,
    props,
    resetModal,
    selectedItem,
    setSuccessAlert,
  ]);

  const pages: WizardModalPage[] = useMemo(
    () => [
      {
        title: null,
        pages: [
          {
            component: <AssignBuyoutModalContent />,
            footerButtonsConfig: [
              {
                type: "outlined",
                text: intl.$t({ id: "CLOSE" }),
                onClick: closeModal,
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                text: intl.$t({ id: "CHANGE" }),
                onClick: () => {
                  if (selectedItem) {
                    moveToNextNestedStep();
                  } else {
                    setWarningAlert(intl.$t({ id: "SELECT_BUYOUT_WARNING" }));
                  }
                },
                className: "flex-1",
              },
            ],
          },
          {
            component: <UserValidation />,
            footerButtonsConfig: [
              {
                type: "outlined",
                text: intl.$t({ id: "CANCEL" }),
                onClick: moveToPreviousNestedStep,
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                text: intl.$t({ id: "CHANGE" }),
                onClick: handleSave,
                className: "flex-1",
              },
            ],
          },
        ],
      },
    ],
    [
      closeModal,
      handleSave,
      intl,
      moveToNextNestedStep,
      moveToPreviousNestedStep,
      selectedItem,
      setWarningAlert,
    ],
  );

  return (
    <WizardModal
      mode={WizardModalMode.MULTIPLE_PAGES}
      opened={props.visible}
      pages={pages}
      onClose={props.handleCancel}
    />
  );
};
