import { If } from "@/common/components/if/If";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { CurrencyDisplay } from "./CurrencyDisplay";

const Container = tw.div`
  grid justify-items-end
`;

const Details = tw.div`
  text-2xs text-black
`;

type Props = {
  item:
    | {
        allowance?: string | null | undefined;
        estimated?: string | null | undefined;
        unallotted?: string | null | undefined;
      }
    | null
    | undefined;
  overall?: boolean;
  className?: string;
};

export const ReportItemDisplay: FC<Props> = ({ item, overall, className }) => {
  const { formatCurrency } = useFormatNumberToCurrency();
  if (!item) {
    return null;
  }
  return (
    <Container>
      <If isTrue={item.allowance}>
        <CurrencyDisplay
          value={item.allowance}
          info={overall ? "OVERALL_BUDGET_ALLOWANCE" : "ALLOWANCE"}
          className={className}
        />
      </If>
      <If isTrue={item.unallotted}>
        <FormattedMessage
          id="UNALLOTTED_WITH_VALUE"
          tagName={Details}
          values={{
            value: formatCurrency(item.unallotted, {
              signDisplay: "always",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          }}
        />
      </If>
      <If isTrue={!item.allowance}>
        <CurrencyDisplay value={item.estimated} info="SUM_OF_ESTIMATED_ITEMS" />
      </If>
    </Container>
  );
};
