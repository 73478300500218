import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import {
  ReleasePartialFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { Info } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const InfoIcon = tw(Info)`text-red-500`;

export const ReleaseMissingCostCode: FC<{
  release: ReleasePartialFieldsFragment;
}> = ({ release }) => {
  return (
    <If
      isTrue={
        release.status !== ReleaseStatus.Draft && release.hasMissingCostCodes
      }
    >
      <Tooltip id={`missing-costCodes-${release.id}`} element={<InfoIcon />}>
        <FormattedMessage id="DELIVERY_MISSING_COST_CODES" />
      </Tooltip>
    </If>
  );
};
