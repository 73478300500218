import { DeliverySlipStatus } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { BaseStatusChip, BaseStatusChipProps } from "./BaseStatusChip";

const BaseStatusChipStyled = tw(BaseStatusChip)`text-center text-xs flex-col`;

type Props = BaseStatusChipProps & {
  status: DeliverySlipStatus;
};

const DELIVERY_SLIP_STATUS_ = {
  [DeliverySlipStatus.Pending]: "bg-yellow-600",
  [DeliverySlipStatus.Processed]: "bg-green-600 text-white",
};

export const DeliverySlipStatusChip: FC<Props> = ({
  status,
  type = "normal",
  classes,
}) => {
  return (
    <BaseStatusChipStyled
      type={type}
      classes={{ text: DELIVERY_SLIP_STATUS_[status], ...classes }}
      data-testid={`packing-slip-status-${type}`}
    >
      <FormattedMessage id={`DELIVERY_SLIP_STATUS_${status}`} />
    </BaseStatusChipStyled>
  );
};
