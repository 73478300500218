import { Close, InfoOutlined } from "@mui/icons-material";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { IconButtonBorderless } from "../button/IconButton";
import { OutlinedButton } from "../button/OutlinedButton";
import { useVersionInformation } from "./useVersionInformation";

const Container = tw.div`fixed bottom-7 left-4 bg-blue-800 p-4 z-[210] w-96 rounded-3xl`;
const InfoStyled = tw(InfoOutlined)`text-yellow-600 w-10 h-10`;
const Text = tw.div`text-white`;
const Information = tw.div`flex gap-2 justify-start`;
const Buttons = tw.div`flex justify-end pt-2`;
const CloseStyled = tw(Close)`text-white`;

export const OutdatedVersionInfo = () => {
  const { isOutdateVersion } = useVersionInformation();
  const [closed, setClosed] = useState(false);

  const reload = useCallback(() => {
    window.location.reload();
  }, []);

  if (!isOutdateVersion || closed) {
    return null;
  }

  return (
    <Container>
      <Information>
        <InfoStyled />
        <FormattedMessage id="OUTDATE_INFO" tagName={Text} />
        <IconButtonBorderless
          onClick={() => setClosed(true)}
          className="h-6 min-h-6 w-6 min-w-6 p-0"
        >
          <CloseStyled />
        </IconButtonBorderless>
      </Information>
      <Buttons>
        <OutlinedButton onClick={reload}>
          <FormattedMessage id="RELOAD" />
        </OutlinedButton>
      </Buttons>
    </Container>
  );
};
