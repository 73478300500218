import { GridTable } from "@/common/components/grid-table/GridTable";
import { If } from "@/common/components/if/If";
import {
  PoMaterialConversionFieldsFragment,
  SourceSystem,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useMissingMaterialConversions } from "../../../../../providers/MissingMaterialConversionsProvider";
import { StepContainer, StepHeader } from "../HostedAgaveConnection.Styles";
import { useMissingMaterialConversionsConfiguration } from "./MissingMaterialConversions.configuration";

type Props = {
  sourceSystem?: SourceSystem | null;
};

export type PoMaterialConversionFieldsFragmentWithId =
  PoMaterialConversionFieldsFragment & {
    id: string;
  };

const MissingMaterialConversionsListWithProvider: FC<Props> = ({
  sourceSystem,
}) => {
  const { poConversions } = useMissingMaterialConversions();
  const editableConfiguration = useMissingMaterialConversionsConfiguration({
    sourceSystem,
    includeActions: true,
  });
  const configuration = useMissingMaterialConversionsConfiguration({
    sourceSystem,
    includeActions: false,
  });

  const conversionItemsWithIds = poConversions.map((e) => ({
    ...e,
    id: `${e.material.id}-${e.fulfillmentUom.id}`,
  }));

  return (
    <StepContainer>
      <If
        isTrue={
          conversionItemsWithIds.filter((c) => !c.quantityMultiplier).length
        }
      >
        <StepHeader>
          <FormattedMessage id="SPECIFY_UOM_CONVERSION" tagName={StepHeader} />
        </StepHeader>
        <GridTable
          configuration={{
            columns: configuration,
          }}
          items={conversionItemsWithIds.filter((c) => !c.quantityMultiplier)}
        />
      </If>
      <If
        isTrue={
          conversionItemsWithIds.filter((c) => c.quantityMultiplier).length
        }
      >
        <StepHeader>
          <FormattedMessage id="SAVED_CONVERSIONS" tagName={StepHeader} />
        </StepHeader>
        <GridTable
          configuration={{
            columns: editableConfiguration,
          }}
          items={conversionItemsWithIds.filter((c) => c.quantityMultiplier)}
          readonly
        />
      </If>
    </StepContainer>
  );
};

export const MissingMaterialConversionsList: FC<Props> = (props) => (
  <MissingMaterialConversionsListWithProvider {...props} />
);
