export const getMultipleValueMaxWidth = (
  limit: number | undefined = 0,
  values = 0,
) => {
  const items = limit < values ? limit : values;
  if (!items) {
    return "";
  }
  if (items === 1) {
    return "max-w-[80%]";
  }
  if (items === 2) {
    return "max-w-[40%]";
  }
  if (items === 3) {
    return "max-w-[30%]";
  }
};
