import tw from "tailwind-styled-components";
import { BaseButton } from "../button/BaseButton";

export type Size = "small" | "large";
type SizeProps = {
  $size: Size;
};

const Container = tw.div`
  relative grid grid-flow-col w-fit bg-white border border-gray-200 rounded-4xl place-items-center self-center justify-self-center
  ${({ $size }: SizeProps) => ($size === "small" ? "h-7" : "h-9")}
`;

type OptionButtonProps = {
  $selected?: boolean;
  $loading?: boolean;
  $size?: Size;
  disabled?: boolean;
};

const OptionButton = tw(BaseButton)<OptionButtonProps>`
  grid grid-flow-col gap-1 truncate rounded-4xl text-sm text-blue-500 py-0 px-4 z-10 
  -mt-[1px] -ml-[1px] -mr-[1px] cursor-pointer items-center select-none
  ${({ $selected }: OptionButtonProps) =>
    $selected ? "text-white bg-blue-800" : ""}
  ${({ $selected, disabled }: OptionButtonProps) =>
    disabled && !$selected ? "text-gray-400" : ""}
  ${({ $loading }: OptionButtonProps) =>
    $loading ? "opacity-70 animate-pulse cursor-default" : ""}
  ${({ $size }: OptionButtonProps) =>
    $size === "small" ? "h-7 min-h-7" : "h-9 min-h-9"}
`;

const ButtonContent = tw.div`
  grid grid-flow-col gap-1 items-center whitespace-nowrap
`;

const Icon = tw.div`
  grid place-items-center
  ${({ $selected }: { $selected: boolean }) =>
    $selected ? "text-white" : "text-blue-500"}
`;

type Props<T> = {
  options?: Array<{
    label: string | JSX.Element;
    viewState: T;
    icon?: JSX.Element;
  }>;
  tableView?: T;
  setView: (viewState: T) => void;
  loading?: boolean;
  size?: Size;
  readonly?: boolean;
};

export const ViewToggle = <T extends string | number | boolean>({
  options = [],
  tableView,
  setView,
  loading,
  readonly,
  size = "small",
}: Props<T>) => {
  return (
    <Container $size={size}>
      {options.map((option, index) => (
        <OptionButton
          $selected={option.viewState === tableView}
          key={option.viewState.toString()}
          onClick={() => {
            if (!loading) {
              setView(option.viewState);
            }
          }}
          $loading={loading}
          testId={`option-item-${option.viewState}`}
          $size={size}
          className={
            options.length > index + 1 ? `-mr-1.5` : index > 1 ? `-ml-1.5` : ""
          }
          disabled={readonly}
        >
          <ButtonContent>
            <Icon $selected={option.viewState === tableView}>
              {option.icon}
            </Icon>
            {option.label}
          </ButtonContent>
        </OptionButton>
      ))}
    </Container>
  );
};
