import { EventKeysConstants } from "@/config/constants/eventKeysContants";
import {
  Autocomplete,
  FilterOptionsState,
  createFilterOptions,
} from "@mui/material";
import React, { Ref, SyntheticEvent, useMemo } from "react";
import { useIntl } from "react-intl";
import { If } from "../../../if/If";
import { Loader } from "../../../loader/Loader";
import { Tooltip } from "../../../tooltip/Tooltip";
import { InputRenderer } from "../common/InputRenderer";
import { PopupIcon } from "../common/PopupIcon";
import { SelectCommonProps } from "../common/SelectCommonProps";
import { CustomPopper } from "./components/CustomPoper";

const UNDEFINED_DEFAULT_KEY = "0";

export type SelectProps<T> = SelectCommonProps<T> & {
  disabledTooltip?: string;
  handleInputChange?: (value: string) => void;
  onChange?: (value: string | null) => void;
  openOnFocus?: boolean;
  serverSideFiltering?: boolean;
  customFilter?: (options: T[], params: FilterOptionsState<T>) => T[];
  value?: string | null;
};

const SelectWithoutRef = <T,>(
  {
    options,
    getLabel,
    getOptionLabel,
    getOptionDisabled,
    getValue,
    customRender,
    value,
    onChange,
    className,
    handleInputChange = () => undefined,
    disableClearable = true,
    disabled,
    serverSideFiltering = false,
    staticText = false,
    creatable = false,
    creatableTextKey,
    testId,
    creatableFn,
    creatableFirstOption = false,
    creatableAlwaysVisible = false,
    popup,
    loading = false,
    disabledTooltip,
    error,
    inputProps,
    InputLabelProps,
    inputValue,
    customFilter,
    InputProps,
    ...props
  }: SelectProps<T>,
  ref: Ref<HTMLDivElement>,
) => {
  const intl = useIntl();

  const filter = useMemo(
    () => creatableFn && createFilterOptions<T>(),
    [creatableFn],
  );

  const selectedOption = useMemo(() => {
    const option = options.find((o) => getValue(o) === value);
    return option || null;
  }, [options, getValue, value]);

  const getValueWithDefaultValue = (option: T | null) =>
    option ? getValue(option) || "" : "";

  return (
    <Autocomplete
      ref={ref}
      {...props}
      options={options}
      PopperComponent={CustomPopper}
      className={className}
      data-testid={testId}
      onInputChange={(event: SyntheticEvent, newInputValue) => {
        if (event) {
          if (
            (event as unknown as KeyboardEvent).key ===
              EventKeysConstants.Enter ||
            event.type === "blur"
          ) {
            event.preventDefault();
            event.stopPropagation();
            return;
          }
          handleInputChange(newInputValue);
        }
      }}
      onChange={(event, value) => {
        onChange?.(getValueWithDefaultValue(value as T));
      }}
      isOptionEqualToValue={(option, value) =>
        getValue(option) === getValue(value)
      }
      value={selectedOption}
      getOptionLabel={(option) =>
        getOptionLabel
          ? getOptionLabel(option as T) || ""
          : getLabel(option as T) || ""
      }
      freeSolo={creatable}
      disableClearable={disableClearable}
      inputValue={inputValue}
      autoHighlight
      filterOptions={
        serverSideFiltering
          ? (option) => option
          : creatable && creatableFn
            ? (options, params) => {
                if (creatable) {
                  const filtered = customFilter
                    ? customFilter(options, params)
                    : filter
                      ? filter(options, params)
                      : options;
                  const { inputValue } = params;
                  if (
                    filtered &&
                    !filtered.find(
                      (o) =>
                        getLabel(o).toLocaleLowerCase() ===
                        inputValue.toLocaleLowerCase(),
                    ) &&
                    (inputValue !== "" || creatableAlwaysVisible) &&
                    !inputValue.includes("Add")
                  ) {
                    const creatableResults = creatableFn(
                      intl.$t(
                        { id: creatableTextKey || "CREATABLE_ADD_NEW" },
                        { inputValue },
                      ),
                      inputValue,
                    );
                    if (creatableResults) {
                      const mappedCreatableResults = (
                        (creatableResults as Array<T>)?.length > 0
                          ? creatableResults
                          : [creatableResults]
                      ) as Array<T>;
                      return creatableFirstOption
                        ? mappedCreatableResults.concat(filtered)
                        : filtered.concat(mappedCreatableResults);
                    }
                    return filtered;
                  }
                  return filtered;
                }

                return options;
              }
            : undefined
      }
      disabled={disabled}
      readOnly={staticText || disabled}
      popupIcon={
        <PopupIcon
          disabled={disabled || staticText}
          loading={loading}
          popup={popup}
        />
      }
      loading={loading}
      loadingText={<Loader loading small />}
      renderInput={(params) => (
        <InputRenderer
          props={{
            ...props,
            InputLabelProps,
            inputProps,
            className,
            staticText,
            error,
            InputProps,
          }}
          params={params}
        />
      )}
      getOptionDisabled={getOptionDisabled}
      renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: T) => {
        const value = getLabel(option);
        const key = getValue(option);
        if (disabledTooltip && getOptionDisabled && getOptionDisabled(option)) {
          return (
            <Tooltip
              id="disabled-tooltip"
              element={
                <li
                  {...props}
                  key={key || UNDEFINED_DEFAULT_KEY}
                  className={`${props.className} ${
                    customRender ? "m-0 py-0" : ""
                  }`}
                >
                  <If isTrue={customRender}>
                    {customRender && customRender(option)}
                  </If>
                  <If isTrue={!customRender}>{value}</If>
                </li>
              }
              key={key}
            >
              {disabledTooltip}
            </Tooltip>
          );
        }

        return (
          <li
            {...props}
            key={key || UNDEFINED_DEFAULT_KEY}
            className={`${props.className} ${customRender ? "m-0 py-0" : ""}`}
          >
            <If isTrue={customRender}>
              {customRender && customRender(option)}
            </If>
            <If isTrue={!customRender}>{value}</If>
          </li>
        );
      }}
    />
  );
};

export const Select = React.forwardRef(SelectWithoutRef) as <T>(
  props: SelectProps<T> & { ref?: React.ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof SelectWithoutRef>;
