import { Tabs } from "@/common/components/tabs/Tabs";
import { routes } from "@/config/routes";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const AdminItemsTabNavigation = () => {
  const intl = useIntl();
  const tabs = useMemo(
    () => [
      {
        label: intl.$t({ id: "ITEMS" }),
        path: routes.itemDatabase,
      },
      {
        label: intl.$t({ id: "PRICES" }),
        path: routes.itemPrices,
      },
    ],
    [intl],
  );

  return <Tabs tabs={tabs} type="secondary" className="pt-4" />;
};
