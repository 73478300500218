import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress } from "@mui/material";
import React, { FC, useEffect } from "react";
import tw from "tailwind-styled-components";
import { LOCAL_STORAGE_KEYS } from "../const";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { goToExternalUrl } from "../utils/browserUtils";

const CircularProgressStyled = tw(CircularProgress)`
  m-auto
`;
const Container = tw.div`
  w-screen h-screen flex justify-center items-center
`;

export const ExternalAuthAuthenticated: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const { readValue } = useLocalStorage();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      const orgId = readValue(LOCAL_STORAGE_KEYS.LOGIN_ORG_ID);

      loginWithRedirect({
        openUrl(url: string) {
          goToExternalUrl(url);
        },
        appState: {
          returnTo: window.location.pathname + window.location.search,
        },
        ...(orgId ? { authorizationParams: { org_id: orgId } } : {}),
      });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, readValue]);

  if (isLoading || !isAuthenticated) {
    return (
      <Container>
        <CircularProgressStyled />
      </Container>
    );
  }

  return <>{children}</>;
};
