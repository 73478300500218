import { useLocalStorage } from "@/common/hooks/useLocalStorage";
import { FC, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { LinkLike } from "../link-like/LinkLike";

export enum PDF_VIEWER_MODE {
  PDF_JS = "PDF_JS",
  NATIVE = "NATIVE",
}

const PDF_VIEWER_MODE_SELECTION = "pdfViewMode";

type Props = {
  pdfView: PDF_VIEWER_MODE;
  setPdfView: (mode: PDF_VIEWER_MODE) => void;
};

const Container = tw.div`
  justify-center flex-1
  flex items-center space-x-2 border border-dashed py-1 px-2 rounded-xl border-gray-500 my-3
`;

const QuestionText = tw.div`text-sm`;

export const PdfRenderingSwitcher: FC<Props> = ({ pdfView, setPdfView }) => {
  const { readValue, setValue } = useLocalStorage();

  useEffect(() => {
    const mode = readValue(PDF_VIEWER_MODE_SELECTION) as PDF_VIEWER_MODE;
    if (mode) {
      setPdfView(mode);
    }
  }, [readValue, setPdfView]);

  const updatePdfView = useCallback(
    (mode: PDF_VIEWER_MODE) => {
      setValue(PDF_VIEWER_MODE_SELECTION, mode);
      setPdfView(mode);
    },
    [setPdfView, setValue],
  );

  return (
    <Container>
      <FormattedMessage id="PROBLEMS_DISPLAYING_PDF" tagName={QuestionText} />
      <If isTrue={pdfView === PDF_VIEWER_MODE.PDF_JS}>
        <LinkLike onClick={() => updatePdfView(PDF_VIEWER_MODE.NATIVE)}>
          <FormattedMessage id="SWITCH_TO_IN_BROWSER_PDF_VIEWER" />
        </LinkLike>
      </If>
      <If isTrue={pdfView === PDF_VIEWER_MODE.NATIVE}>
        <LinkLike onClick={() => updatePdfView(PDF_VIEWER_MODE.PDF_JS)}>
          <FormattedMessage id="SWITCH_TO_FM_PDF_VIEWER" />
        </LinkLike>
      </If>
    </Container>
  );
};
