import {
  InvoiceEmailFieldsFragment,
  InvoiceEmailStatus,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FaCheck, FaExclamation } from "react-icons/fa6";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-row`;
const Item = tw.div`flex flex-row gap-2 items-center text-xs`;
const Circle = tw.div`w-6 h-6 rounded-full flex items-center justify-center text-white text`;
const CircleGreen = tw(Circle)`bg-green-600`;
const CircleOrange = tw(Circle)`bg-orange-500`;
const Text = tw.div`w-15`;

type Props = {
  invoiceEmail: InvoiceEmailFieldsFragment;
};

export const InvoiceEmailStatusDisplay: FC<Props> = ({ invoiceEmail }) => {
  const status = useMemo(() => {
    switch (invoiceEmail.status) {
      case InvoiceEmailStatus.Processed:
        return (
          <Item>
            <CircleGreen>
              <FaCheck className="text-sm" />
            </CircleGreen>
            <FormattedMessage
              id="INVOICE_EMAIL_STATUS_PROCESSED"
              tagName={Text}
            />
          </Item>
        );
      case InvoiceEmailStatus.AwaitingApproval:
        return (
          <Item>
            <CircleOrange>
              <FaExclamation className="text-sm" />
            </CircleOrange>
            <FormattedMessage
              id="INVOICE_EMAIL_STATUS_AWAITING_APPROVAL"
              tagName={Text}
            />
          </Item>
        );
    }
  }, [invoiceEmail.status]);

  return <Container>{status}</Container>;
};
