import { If } from "@/common/components/if/If";
import { PhoneView } from "@/common/components/phone-input/PhoneView";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import { Avatar } from "@mui/material";
import { FC, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { VendorListItem } from "./VendorListItem";

const AvatarStyled = tw(Avatar)`
  ml-2 bg-blue-500 text-white text-2xs font-normal w-5 h-5
`;

const ContactName = tw.div``;
const ExtraContactsContainer = tw.div`flex flex-col gap-2`;
const Container = tw.div`flex items-center`;
const PopoverDescription = tw.div`flex flex-col`;
const Separator = tw.span``;

type Props = {
  contacts:
    | Pick<
        OrgPreferredVendorsFieldsFragment["contacts"][0],
        "name" | "cellPhone" | "email" | "id"
      >[]
    | undefined;
  limit?: number;
  className?: string;
  classes?: {
    root?: string;
    popover?: string;
  };
  separator?: string;
};

export const VendorList: FC<Props> = ({
  contacts,
  limit = 1,
  className,
  classes,
  separator,
}) => {
  if (!contacts || !contacts.length) {
    return null;
  }

  return (
    <Container className={className}>
      {contacts.slice(0, limit).map((contact, index) => {
        return (
          <Fragment key={contact.id}>
            <VendorListItem contact={contact} classes={classes} />
            <If isTrue={separator && index !== contacts.length - 1}>
              <Separator className={classes?.popover}>
                {separator && index !== contacts.length - 1
                  ? separator + " "
                  : null}
              </Separator>
            </If>
          </Fragment>
        );
      })}
      <If isTrue={contacts.length > limit}>
        <Tooltip
          id="extra-items"
          position="top"
          element={<AvatarStyled>+{contacts.length - limit}</AvatarStyled>}
        >
          <ExtraContactsContainer>
            {contacts.slice(limit, contacts.length).map((contact, index) => {
              return (
                <PopoverDescription key={index}>
                  <ContactName>{contact.name}</ContactName>
                  <FormattedMessage
                    tagName="div"
                    id="PHONE_WITH_VALUE"
                    values={{ value: <PhoneView value={contact.cellPhone} /> }}
                  />
                  <FormattedMessage
                    tagName="div"
                    id="EMAIL_WITH_VALUE"
                    values={{ value: contact?.email }}
                  />
                </PopoverDescription>
              );
            })}
          </ExtraContactsContainer>
        </Tooltip>
      </If>
    </Container>
  );
};
