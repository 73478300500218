import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { If } from "@/common/components/if/If";
import { LinkLikeText } from "@/common/components/link-like-text/LinkLikeText";
import {
  AddOutlined,
  DeleteOutline,
  NoteAddOutlined,
} from "@mui/icons-material";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { usePageRangeManipulation } from "../../../utils/usePageRangeManipulation";
import {
  SplitType,
  useSplittingInvoicesWizard,
} from "../SplittingInvoicesWizardProvider";

const Container = tw.div`flex flex-row gap-2 items-center justify-between w-full`;
const Group = tw.div`flex`;
const FileCount = tw.span`text-sm font-normal flex items-center text-gray-600`;
const Item = tw(LinkLikeText)`text-sm font-normal flex items-center`;

export const SplittingInvoiceFooterActions = () => {
  const {
    pageRanges,
    selectedPages,
    setPageRanges,
    setSelectedPages,
    urls,
    currentUrl,
    type,
  } = useSplittingInvoicesWizard();
  const { excludePages, mergePages } = usePageRangeManipulation();
  const disabledExclude = useMemo(
    () =>
      !selectedPages.length ||
      !selectedPages.every(
        (p) => !pageRanges.find((pr) => pr.start <= p && pr.end >= p)?.excluded,
      ),
    [pageRanges, selectedPages],
  );

  const disableMerge = useMemo(() => {
    let isConsecutive = selectedPages.length > 1;
    const sortedPages = selectedPages.sort((a, b) => a - b);
    sortedPages.forEach((page, index) => {
      if (index === 0) {
        return;
      }
      if (page - 1 !== selectedPages[index - 1]) {
        isConsecutive = false;
      }
    });
    if (type === SplitType.Restructure) {
      if (!isConsecutive) {
        return true;
      }
      const pageRange = pageRanges.filter((p) => !p.excluded)[0];
      return !(
        sortedPages[0] <= pageRange.start &&
        sortedPages[sortedPages.length - 1] >= pageRange.end
      );
    }
    return !isConsecutive;
  }, [pageRanges, selectedPages, type]);

  const exclude = useCallback(() => {
    if (disabledExclude) {
      return;
    }
    const newPageRanges = excludePages(pageRanges, selectedPages);
    setPageRanges(newPageRanges);
    setSelectedPages([]);
  }, [
    disabledExclude,
    excludePages,
    pageRanges,
    selectedPages,
    setPageRanges,
    setSelectedPages,
  ]);

  const merge = useCallback(() => {
    if (disableMerge) {
      return;
    }
    const newPageRanges = mergePages(pageRanges, selectedPages);
    setPageRanges(newPageRanges);
    setSelectedPages([]);
  }, [
    disableMerge,
    mergePages,
    pageRanges,
    selectedPages,
    setPageRanges,
    setSelectedPages,
  ]);

  const includeEnablePage = useMemo(
    () =>
      selectedPages.length === 1 &&
      !!pageRanges.find(
        (i) =>
          i.start === selectedPages[0] &&
          i.end === selectedPages[0] &&
          i.excluded,
      ) &&
      (type !== SplitType.Restructure || !pageRanges.some((p) => !p.excluded)),
    [pageRanges, selectedPages, type],
  );

  const includePage = useCallback(() => {
    const selectedPageIndex = pageRanges.findIndex(
      (i) =>
        i.start === selectedPages[0] &&
        i.end === selectedPages[0] &&
        i.excluded,
    );
    if (selectedPageIndex === -1) {
      return;
    }
    const newPageRanges = [...pageRanges];
    newPageRanges[selectedPageIndex] = {
      ...newPageRanges[selectedPageIndex],
      excluded: false,
    };
    setPageRanges(newPageRanges);
    setSelectedPages([]);
  }, [pageRanges, selectedPages, setPageRanges, setSelectedPages]);

  return (
    <Container>
      <Group>
        <If isTrue={!includeEnablePage}>
          <Item $disabled={disabledExclude} onClick={exclude}>
            <IconButtonBorderless disabled={disabledExclude}>
              <DeleteOutline />
            </IconButtonBorderless>
            <FormattedMessage
              id="EXCLUDE_PAGE"
              values={{ multiple: selectedPages.length > 1 }}
            />
          </Item>
        </If>
        <If isTrue={includeEnablePage}>
          <Item onClick={includePage}>
            <IconButtonBorderless>
              <AddOutlined />
            </IconButtonBorderless>
            <FormattedMessage id="INCLUDE_PAGE" />
          </Item>
        </If>
        <Item $disabled={disableMerge} onClick={merge}>
          <IconButtonBorderless disabled={disableMerge}>
            <NoteAddOutlined />
          </IconButtonBorderless>
          <FormattedMessage id="COMBINE_PAGES_INVOICE" />
        </Item>
      </Group>
      <Group>
        <If isTrue={type === SplitType.Split}>
          <Item>
            <FormattedMessage
              id="FILE_OUT_OF"
              values={{
                current: urls.indexOf(currentUrl) + 1,
                total: urls.length,
              }}
              tagName={FileCount}
            />
          </Item>
        </If>
      </Group>
    </Container>
  );
};
