import { useMemo } from "react";

export const useArrowPosition = (
  color: "primary" | "secondary" | "warning",
  position: "top" | "bottom" | "center",
  horizontalClassName?: string,
) =>
  useMemo(() => {
    return `overflow-x-visible overflow-y-visible bg-transparent 
            rounded-lg shadow-none
            before:rotate-[-45deg] 
            before:${
              color === "primary"
                ? "bg-blue-500"
                : color === "secondary"
                  ? "bg-white"
                  : color === "warning"
                    ? "bg-yellow-600"
                    : ""
            } before:absolute
            ${
              horizontalClassName ||
              "before:right-0 before:left-[calc(50%-6px)]"
            }        
            before:w-3 before:h-3 before:clip
            ${position === "top" ? "before:bottom-[-6px]" : "before:top-[-6px]"}

          `;
  }, [color, position, horizontalClassName]);
