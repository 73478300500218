import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  AssetFieldsFragment,
  NoteDocumentFieldsFragment,
} from "@/generated/graphql";
import { NoFunctionUndefinedPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useCreateNoteDocument } from "../../hooks/useCreateNoteDocument";
import { useGetNoteDocument } from "../../hooks/useGetNoteDocument";

type ProviderContextType = {
  createNoteDocumentWithAsset: (
    orgLocationId: string,
  ) => Promise<NoteDocumentFieldsFragment | null | undefined>;
  asset: AssetFieldsFragment | null;
  setAsset: (asset: AssetFieldsFragment) => void;
  noteDocument?: NoteDocumentFieldsFragment | null;
  loading: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  createNoteDocumentWithAsset: NoFunctionUndefinedPromise,
  asset: null,
  setAsset: () => {},
  noteDocument: null,
  loading: false,
});

export const NoteDocumentProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { setError } = useGlobalError();
  const [asset, setAsset] = useState<AssetFieldsFragment | null>(null);
  const { noteDocumentId } = useParams();
  const { createNoteDocument } = useCreateNoteDocument();

  const { noteDocument, loading } = useGetNoteDocument(noteDocumentId);

  const createNoteDocumentWithAsset = async (orgLocationId: string) => {
    try {
      if (!asset || !orgLocationId) {
        return null;
      }
      const noteDocument = await createNoteDocument({
        assetUrls: [asset.url],
        orgLocationId,
      });
      if (noteDocument) {
        return noteDocument;
      }
    } catch (error) {
      setError(error);
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        createNoteDocumentWithAsset,
        asset,
        setAsset,
        noteDocument,
        loading,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useNoteDocument = (): ProviderContextType =>
  useContext(ProviderContext);
