import {
  BuyoutEvent,
  InvoiceEvent,
  MessageContextKind,
  QuoteEvent,
  ReleaseEvent,
} from "@/generated/graphql";

export const mapExcludedEventsToApi = <
  T extends
    | QuoteEvent
    | ReleaseEvent
    | BuyoutEvent
    | InvoiceEvent
    | MessageContextKind,
>(
  events: Record<T, boolean>,
): Array<T> => {
  return Object.entries(events)
    .filter(([key, value]) => key && !value)
    .map(([key]) => key as T);
};
