import { NoFunction } from "@/types/NoFunction";
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

export enum Locale {
  en = "en",
  es = "es",
}

type ProviderContextType = {
  selectedLocale: Locale;
  setSelectedLocale: Dispatch<SetStateAction<Locale>>;
};

const ProviderContext = createContext<ProviderContextType>({
  selectedLocale: Locale.en,
  setSelectedLocale: NoFunction,
});
export const LocaleProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [selectedLocale, setSelectedLocale] = useState(Locale.en);

  return (
    <ProviderContext.Provider
      value={{
        selectedLocale,
        setSelectedLocale,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useLocale = (): ProviderContextType => useContext(ProviderContext);
