import { Breadcrumbs } from "@/common/components/breadcrumbs/Breadcrumbs";
import { If } from "@/common/components/if/If";
import {
  QuestionBox,
  QuestionText,
} from "@/common/components/messages/Messages.styles";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { BuyoutStatusChip } from "@/common/components/statuses/BuyoutStatusChip";
import { routes } from "@/config/routes";
import { VendorBuyoutMessagesButton } from "@/distributor/common/buyout-messages/VendorBuyoutMessagesButton";
import {
  BuyoutStatus,
  DistributorBuyoutFieldsFragment,
  MessageContextKind,
} from "@/generated/graphql";
import { ListAlt } from "@mui/icons-material";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { BuyoutInfoHeader } from "../components/BuyoutInfoHeader";
import { BuyoutPrintButton } from "../components/BuyoutPrintButton";

const BuyoutNoWrapper = tw.div`pr-2`;

const Headline = tw.div`flex items-center`;

type Props = {
  buyout: DistributorBuyoutFieldsFragment;
};

export const BuyoutHeader: FC<Props> = ({ buyout }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>
          {intl.$t({ id: "BUYOUT" }, { number: buyout.clientIdentifier })}
        </title>
      </Helmet>
      <Breadcrumbs
        items={[
          {
            id: "buyoutNo",
            text: (
              <Headline>
                <BuyoutNoWrapper>
                  <FormattedMessage
                    id="BUYOUT"
                    values={{ number: buyout.clientIdentifier }}
                  />
                </BuyoutNoWrapper>
              </Headline>
            ),
          },
        ]}
        appendItems={[
          <BuyoutStatusChip
            key="status"
            status={buyout.status}
            type="breadcrumb"
          />,
        ]}
        actions={[
          <If
            key="orders-history"
            isTrue={buyout.status !== BuyoutStatus.Draft}
          >
            <QuestionBox
              onClick={() =>
                navigate(
                  generatePath(routes.distributorBuyoutDeliveries, {
                    id: buyout.id,
                  }) + location.search,
                )
              }
            >
              <QuestionText>
                <FormattedMessage id="DISTRIBUTOR_BUYOUT_ORDERS_HISTORY" />
              </QuestionText>
              <ListAlt />
            </QuestionBox>
          </If>,
          <BuyoutPrintButton buyout={buyout} key="print" />,
          <VendorBuyoutMessagesButton
            key="messages"
            buyout={buyout}
            org={buyout.project.location.org}
            text={
              <QuestionBox $fixedWidth>
                <QuestionText>
                  <FormattedMessage id="BUYOUT_MESSAGE" />
                </QuestionText>
                <MessageButton
                  id={buyout.id}
                  kind={MessageContextKind.Buyout}
                />
              </QuestionBox>
            }
          />,
        ]}
        classes={{
          text: "flex items-center",
        }}
      />
      <BuyoutInfoHeader buyout={buyout} />
    </>
  );
};
