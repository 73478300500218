export enum ExternalProjectStatus {
  // active statuses
  Active = "Active", //Foundation, Procore
  Bid = "Bid", // Foundation
  Contract = "Contract", // Sage100 Contractor
  Current = "Current", // Sage100 Contractor
  InProgress = "In progress", // Sage100 CRE
  Pending = "Pending", // Vista
  Open = "Open", // Vista
  // inactive statuses
  Closed = "Closed", // Foundation, Sage100 Contractor, Sage100 CRE, Vista
  Inactive = "Inactive", // Foundation
  Overhead = "Overhead", // Foundation
  Complete = "Complete", // Sage100 Contractor
  // eslint-disable-next-line @cspell/spellchecker
  Unstarted = "Unstarted", // Sage100 CRE
  SoftClosed = "Soft Closed", // Vista
}
