import { useUser } from "@/common/providers/UserProvider";
import {
  OrgPreferredVendor,
  useEnterpriseVendorsQuery,
} from "@/generated/graphql";
import { useEffect, useState } from "react";

const useEnterprisePreferredVendors = () => {
  const { viewer } = useUser();

  const [vendors, setVendors] = useState<OrgPreferredVendor[] | null>(null);

  const { data, error } = useEnterpriseVendorsQuery({
    variables: {
      excludedOrgIDs: [viewer?.org?.id || ""],
    },
    skip: !viewer?.org?.enterprise?.name || !viewer?.org?.id,
  });

  useEffect(() => {
    if (error) {
      return;
    }

    const orgPreferredVendors =
      data?.viewer?.org?.enterprise?.orgPreferredVendors;

    if (orgPreferredVendors) {
      setVendors(orgPreferredVendors as OrgPreferredVendor[]);
    }
  }, [data, error]);

  return {
    vendors,
  };
};

export default useEnterprisePreferredVendors;
