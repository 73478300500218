import { useFiltersQueryParams } from "@/common/hooks/useFiltersQueryParams";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { isUUID } from "@/common/utils/uuidUtils";
import {
  AddVendorPriceInput,
  OrgMaterialFieldsFragment,
  OrgMaterialsDocument,
  OrgMaterialsQuery,
  QueryOrgMaterialsFilter,
  Sku,
  UomsDocument,
  UpdateVendorPriceInput,
  VendorPricesDocument,
  useAddVendorPriceMutation,
  useRemoveVendorPriceMutation,
  useUpdateVendorPriceMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBoolean,
  NoFunctionBooleanPromise,
} from "@/types/NoFunction";
import { useApolloClient } from "@apollo/client";
import { FC, createContext, useContext, useState } from "react";
import { useMaterialsWithPagination } from "../../materials/hooks/useMaterialsWithPagination";

type ExtendedPrice = UpdateVendorPriceInput & { submitted: boolean };

type ProviderContextType = {
  materials: OrgMaterialFieldsFragment[];
  filter?: QueryOrgMaterialsFilter | undefined;
  setFilter: (filter: QueryOrgMaterialsFilter | undefined) => void;
  loading: boolean;
  error: boolean;
  totalCount: number;
  isFiltered: boolean;
  removePrice: (id: string) => Promise<boolean>;
  addNewPriceToMaterial: (id: string) => void;
  updateMaterial: (id: string, price: UpdateVendorPriceInput) => void;
  commitUpdate: (id: string, index: number) => Promise<boolean>;
  expandedItems: string[];
  setExpandedItems: (items: string[]) => void;
  adding: boolean;
  updating: boolean;
  isPriceSubmitted: (id: string) => boolean;
  clearSubmittedPrice: (id: string) => void;
  refetch: () => void;
};

const ProviderContext = createContext<ProviderContextType>({
  materials: [],
  filter: undefined,
  setFilter: () => null,
  loading: false,
  error: false,
  totalCount: 0,
  isFiltered: false,
  removePrice: NoFunctionBooleanPromise,
  addNewPriceToMaterial: NoFunction,
  updateMaterial: NoFunction,
  commitUpdate: NoFunctionBooleanPromise,
  expandedItems: [],
  setExpandedItems: NoFunction,
  adding: false,
  updating: false,
  isPriceSubmitted: NoFunctionBoolean,
  clearSubmittedPrice: NoFunction,
  refetch: NoFunction,
});

export const MaterialPricesProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { getFiltersQueryParam, setFiltersQueryParams } =
    useFiltersQueryParams();
  const [vendorPriceMap, setVendorPriceMap] = useState<
    Record<string, ExtendedPrice>
  >({});
  const client = useApolloClient();
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [submittedPrices, setSubmittedPrices] = useState<
    Record<string, boolean>
  >({});

  const { setError } = useGlobalError();
  const [filter, setFilter] = useState<QueryOrgMaterialsFilter | undefined>(
    getFiltersQueryParam(),
  );
  const { materials, loading, error, totalCount, pagination, refetch } =
    useMaterialsWithPagination(filter);

  const [addVendorPrice, { loading: adding }] = useAddVendorPriceMutation();
  const addPrice = async (input: AddVendorPriceInput) => {
    try {
      const { errors } = await addVendorPrice({
        variables: {
          input,
        },
        refetchQueries: [
          { query: OrgMaterialsDocument, variables: { ...pagination, filter } },
          { query: VendorPricesDocument },
          { query: UomsDocument },
        ],
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const [updateVendorPrice, { loading: updating }] =
    useUpdateVendorPriceMutation();
  const updatePrice = async (input: UpdateVendorPriceInput) => {
    try {
      const { errors } = await updateVendorPrice({
        variables: {
          input,
        },
        refetchQueries: [
          { query: OrgMaterialsDocument, variables: { ...pagination, filter } },
          { query: VendorPricesDocument },
        ],
      });

      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const [removeVendorPrice] = useRemoveVendorPriceMutation();
  const removePrice = async (id: string) => {
    clearSubmittedPrice(
      materials.find((m) => m.vendorPrices.some((p) => p.id === id))?.id || "",
    );
    if (!isUUID(id)) {
      client.cache.updateQuery(
        { query: OrgMaterialsDocument, variables: { ...pagination, filter } },
        (data: OrgMaterialsQuery | null) => {
          if (data?.orgMaterials) {
            const materials = {
              ...data.orgMaterials,
              edges: data.orgMaterials.edges.map((edge) => {
                return {
                  ...edge,
                  node: {
                    ...edge.node,
                    vendorPrices: edge.node.vendorPrices.filter(
                      (price) => price.id !== id,
                    ),
                  },
                };
              }),
            };
            return {
              orgMaterials: materials,
            };
          }
          return data;
        },
      );
    } else {
      try {
        const { errors } = await removeVendorPrice({
          variables: {
            id,
          },
          refetchQueries: [
            {
              query: OrgMaterialsDocument,
              variables: { ...pagination, filter },
            },
            { query: VendorPricesDocument },
          ],
        });
        setError(errors);
        return !errors;
      } catch (error) {
        setError(error);
        return false;
      }
    }
    return true;
  };

  const setFilterAndUpdateQueryString = (
    filter: QueryOrgMaterialsFilter | undefined,
  ) => {
    setFiltersQueryParams(filter);
    setFilter(filter);
  };

  const addNewPriceToMaterial = (id: string) => {
    if (
      materials
        .find((m) => m.id === id)
        ?.vendorPrices.some((p) => !isUUID(p.id))
    ) {
      return;
    }
    clearSubmittedPrice(id);
    client.cache.updateQuery(
      { query: OrgMaterialsDocument, variables: { ...pagination, filter } },
      (data: OrgMaterialsQuery | null) => {
        if (data?.orgMaterials) {
          const newMaterials = {
            ...data.orgMaterials,
            edges: data.orgMaterials.edges.map((edge) => {
              if (edge?.node?.id === id) {
                return {
                  ...edge,
                  node: {
                    ...edge.node,
                    vendorPrices: [
                      ...edge.node.vendorPrices,
                      {
                        __typename: "VendorPrice" as const,
                        id: "new" + Math.random(),
                        manufacturer: {
                          id:
                            (edge.node.material as Sku)?.manufacturer?.id ?? "",
                        },
                        sellerOrgLocation: {
                          id: "",
                        },
                        orgMaterialId: "",
                        price: "",
                        expirationDate: null,
                        uom: {
                          id: edge.node.defaultEstimateUom.id ?? "",
                        },
                        leadTime: 0,
                        minimumOrder: 0,
                        orderIncrement: 0,
                      },
                    ],
                  },
                };
              } else {
                return edge;
              }
            }),
          };
          return {
            orgMaterials: newMaterials,
          };
        }
        return data;
      },
    );
    setExpandedItems((prev) => [...prev, id]);
  };

  const updateMaterial = (id: string, update: UpdateVendorPriceInput) => {
    const manufacturer = (materials.find((m) => m.id === id)?.material as Sku)
      ?.manufacturer;

    const defaultEstimateUom = (
      materials.find((m) => m.id === id) as OrgMaterialFieldsFragment
    ).defaultEstimateUom;

    setVendorPriceMap((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        manufacturerId:
          update.manufacturerId ?? manufacturer?.id ?? prev[id]?.manufacturerId,
        uom:
          update.uom ?? prev[id]?.uom ?? defaultEstimateUom.pluralDescription,
        clearExpirationDate: !update.expirationDate,
        ...update,
      },
    }));
  };

  const isPriceSubmitted = (id: string) => {
    return submittedPrices[id] ?? false;
  };

  const clearSubmittedPrice = (id: string) => {
    setSubmittedPrices((prev) => ({
      ...prev,
      [id]: false,
    }));
    setVendorPriceMap((prev) => {
      const newMap = { ...prev };
      delete newMap[id];
      return newMap;
    });
  };

  const commitUpdate = async (id: string, index: number) => {
    setSubmittedPrices((prev) => ({
      ...prev,
      [id]: true,
    }));
    if (!vendorPriceMap) {
      return true;
    }
    const priceInput = vendorPriceMap[id];
    if (!priceInput) {
      return isUUID(
        materials.find((m) => m.id === id)?.vendorPrices[index]?.id,
      );
    }

    if (priceInput.vendorPriceId && isUUID(priceInput.vendorPriceId)) {
      return await updatePrice({
        ...priceInput,
        expirationDate: priceInput.expirationDate ?? null,
      });
    } else {
      if (
        !priceInput.sellerOrgLocationId ||
        !priceInput.uom ||
        !priceInput.price ||
        !priceInput.manufacturerId
      ) {
        return false;
      } else {
        clearSubmittedPrice(id);
      }

      return await addPrice({
        orgMaterialId: id,
        manufacturerId: priceInput.manufacturerId,
        price: priceInput.price,
        sellerOrgLocationId: priceInput.sellerOrgLocationId,
        uom: priceInput.uom,
        expirationDate: priceInput.expirationDate,
        leadTime: priceInput.leadTime,
        minimumOrder: priceInput.minimumOrder,
        orderIncrement: priceInput.orderIncrement,
      });
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        materials,
        isFiltered:
          !!filter?.sellerOrgLocationId ||
          !!filter?.search ||
          !!filter?.costCodeIds ||
          !!filter?.priceCondition,
        loading,
        error: !!error,
        totalCount,
        filter,
        setFilter: setFilterAndUpdateQueryString,
        removePrice,
        addNewPriceToMaterial,
        updateMaterial,
        commitUpdate,
        expandedItems,
        setExpandedItems,
        adding,
        updating,
        isPriceSubmitted,
        clearSubmittedPrice,
        refetch,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useMaterialPrices = (): ProviderContextType =>
  useContext(ProviderContext);
