import { FC, useEffect, useMemo } from "react";

import {
  NotificationFieldsFragment,
  NotificationQuoteChangeContextFieldsFragment,
  NotificationType,
  QuoteStatus,
} from "@/generated/graphql";
import { useInView } from "react-intersection-observer";
import tw from "tailwind-styled-components";
import { useNotifications } from "../../providers/NotificationsProvider";
import { BaseNotification } from "../notification-types/BaseNotification";
import { BuyoutChangeNotification } from "../notification-types/BuyoutChangeNotification";
import { InvoiceChangeNotification } from "../notification-types/InvoiceNotification";
import { MessageNotification } from "../notification-types/MessageNotification";
import { QuoteChangeNotification } from "../notification-types/QuoteChangeNotification";
import { RFQChangeNotification } from "../notification-types/RFQChangeNotification";
import { ReleaseChangeNotification } from "../notification-types/ReleaseChangeNotification";

type NotificationListItemProps = {
  notification: NotificationFieldsFragment;
  index: number;
  closeDrawer: () => void;
};

const NotificationContainer = tw.div`
  flex gap-2 items-center relative border-b justify-between 
  hover:opacity-100 hover:bg-gray-100 cursor-pointer transition-all ease-out duration-200
  ${({ $read }: { $read?: boolean }) => $read && "opacity-70"}
`;

export const NotificationListItem: FC<NotificationListItemProps> = ({
  notification,
  closeDrawer,
}) => {
  const { markNotificationRead } = useNotifications();
  const { ref, inView } = useInView({
    threshold: 0.7,
    trackVisibility: true,
    delay: 1500,
  });

  useEffect(() => {
    if (inView === undefined) {
      return;
    }
    if (inView && !notification.readAt) {
      markNotificationRead(notification.id);
    }
  }, [
    inView,
    notification.readAt,
    closeDrawer,
    markNotificationRead,
    notification.id,
  ]);

  const notificationBody = useMemo(() => {
    if (notification) {
      switch (notification.context.type) {
        case NotificationType.Message:
          return <MessageNotification notification={notification} />;
        case NotificationType.QuoteStatusChange: {
          const context =
            notification.context as NotificationQuoteChangeContextFieldsFragment;
          if (context.quoteStatus === QuoteStatus.ChangeRequested) {
            return <RFQChangeNotification notification={notification} />;
          }
          return <QuoteChangeNotification notification={notification} />;
        }
        case NotificationType.ReleaseStatusChange: {
          return <ReleaseChangeNotification notification={notification} />;
        }
        case NotificationType.BuyoutStatusChange: {
          return <BuyoutChangeNotification notification={notification} />;
        }
        case NotificationType.InvoiceStatusChange:
          return <InvoiceChangeNotification notification={notification} />;
        default:
          return <BaseNotification notification={notification} />;
      }
    } else {
      return null;
    }
  }, [notification]);

  if (!notification) {
    return null;
  }

  return (
    <NotificationContainer
      ref={ref}
      $read={!!notification.readAt}
      onClick={closeDrawer}
    >
      {notificationBody}
    </NotificationContainer>
  );
};
