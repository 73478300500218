import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { DateView } from "@/common/utils/dates/DateView";
import { EditOutlined } from "@mui/icons-material";
import { FC } from "react";
import { InvoiceDatePicker } from "./InvoiceDatePicker";
import { NonEditableView, Title } from "./InvoiceHeaderDetails.styles";

type Props = {
  title: string;
  date: Date | null;
  isEditing: boolean;
  toggleEditing: () => void;
  onChange: (d: Date | null) => void;
  readonly?: boolean;
};

export const InvoiceHeaderDateEdit: FC<Props> = ({
  title,
  date,
  isEditing,
  toggleEditing,
  onChange,
  readonly = false,
}) => {
  return (
    <>
      <Title>{title}:</Title>
      <If isTrue={!isEditing}>
        <NonEditableView>
          <NotNullableRenderer value={date}>
            <DateView date={date} utc />
          </NotNullableRenderer>
          <If isTrue={!readonly}>
            <LinkLike>
              <EditOutlined onClick={toggleEditing} fontSize="small" />
            </LinkLike>
          </If>
        </NonEditableView>
      </If>
      <If isTrue={isEditing}>
        <InvoiceDatePicker
          date={date}
          onSave={onChange}
          onClose={toggleEditing}
        />
      </If>
    </>
  );
};
