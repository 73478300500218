import { Tabs } from "@/common/components/tabs/Tabs";
import { routes } from "@/config/routes";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useDeliverySlipStats } from "../../pages/delivery-slips/providers/DeliverySlipStatsProvider";

export const ReleaseTabNavigation = () => {
  const intl = useIntl();
  const { deliverySlipsStats } = useDeliverySlipStats();

  const tabs = useMemo(
    () => [
      {
        label: intl.$t({ id: "ORDER_LIST" }),
        path: routes.deliveries,
      },
      {
        label: intl.$t({ id: "PACKING_SLIPS" }),
        path: routes.deliverySlips,
        count: deliverySlipsStats?.pendingCount || undefined,
      },
    ],
    [deliverySlipsStats?.pendingCount, intl],
  );

  return <Tabs tabs={tabs} type="secondary" className="top-1 -mt-8 pt-8" />;
};
