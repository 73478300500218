import { If } from "@/common/components/if/If";
import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useUser } from "@/common/providers/UserProvider";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const InfoContainer = tw.span`text-blue-500`;
const LabelContainer = tw.div`flex items-center justify-center`;
const LabelText = tw.span``;
const Text = tw.div`w-17`;
const LabelSpacer = tw.span`mx-1 flex h-[1px] w-32 flex-row bg-gray-400`;

type Props = {
  price: string;
  uomDescription?: string | null;
  readonly: boolean;
  index?: number;
  updateValue: (value: string | null) => void;
  infoLabel?: string;
  error?: boolean;
};

export const BuyoutPriceInput: FC<Props> = ({
  price,
  uomDescription,
  readonly,
  index,
  updateValue,
  infoLabel,
  error,
}) => {
  const intl = useIntl();
  const { currencySymbol } = useUser();
  return (
    <PricePicker
      value={price}
      onBlur={(unitPrice) => {
        const mapValue = isNaN(Number(unitPrice)) ? "0" : unitPrice;
        if (mapValue === price) {
          return;
        }
        updateValue(unitPrice);
      }}
      readonly={readonly}
      error={error}
      label={
        <If isTrue={uomDescription}>
          <LabelContainer>
            <LabelText>
              <Text>
                {intl.$t(
                  { id: "PRICE_PER_UOM" },
                  { uom: uomDescription, currency: currencySymbol },
                )}
              </Text>
            </LabelText>
            <If isTrue={!!infoLabel}>
              <LabelSpacer />
              <Tooltip
                element={
                  <InfoContainer>
                    <InfoOutlined />
                  </InfoContainer>
                }
                id={`uom-description-${index}`}
              >
                {infoLabel}
              </Tooltip>
            </If>
          </LabelContainer>
        </If>
      }
    />
  );
};
