import { CircleCounter } from "@/common/components/circle-counter/CircleCounter";
import { CountType } from "@/common/components/circle-counter/CountType";
import { If } from "@/common/components/if/If";
import { ExternalPoBaseFieldsFragment } from "@/generated/graphql";
import { Identity } from "@/types/Identity";
import { FC } from "react";
import { CheckBlue, IconView, ReadonlyField, Row } from "./ExternalPO.styles";

type Props = {
  item: ExternalPoBaseFieldsFragment & Identity;
  selectedBaseExternalPO: ExternalPoBaseFieldsFragment | undefined;
  count: CountType;
};

export const ExternalPONumber: FC<Props> = ({
  item,
  selectedBaseExternalPO,
  count,
}) => (
  <Row>
    <IconView>
      <If isTrue={item.externalId === selectedBaseExternalPO?.externalId}>
        <CheckBlue />
      </If>
      <If isTrue={item.externalId !== selectedBaseExternalPO?.externalId}>
        <CircleCounter count={count} />
      </If>
    </IconView>
    <ReadonlyField className="ml-5">{item.number}</ReadonlyField>
  </Row>
);
