import { If } from "@/common/components/if/If";
import { useMemo } from "react";
import tw from "tailwind-styled-components";
import { useReceipts } from "../../providers/ReceiptsProvider";
import { useReceiptSequence } from "../../providers/ReceiptsSequenceProvider";
import { ToggleAddReceiptForm } from "../add-receipt/ToggleAddReceiptForm";
import { ExportReceiptsButton } from "../export/ExportReceiptsButton";

const Container = tw.div` 
  grid grid-flow-col items-center justify-center gap-2 
  text-base font-medium rounded-[32px] py-1 pl-6 pr-1
  ${({ $exportEnabled }: { $exportEnabled: boolean }) =>
    $exportEnabled ? "bg-blue-450" : ""}
`;

export const ReceiptListActions = () => {
  const { exportEnabled } = useReceipts();
  const { sequenceActive } = useReceiptSequence();

  const inSequenceFlow = useMemo(
    () => exportEnabled || sequenceActive,
    [exportEnabled, sequenceActive],
  );

  return (
    <Container $exportEnabled={inSequenceFlow}>
      <ExportReceiptsButton />
      <If isTrue={!inSequenceFlow}>
        <ToggleAddReceiptForm />
      </If>
    </Container>
  );
};
