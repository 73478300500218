import { WizardModal } from "@/common/components/wizard-modal/WizardModal";
import { useAccountingOnPremiseWizard } from "./AccountingOnPremiseWizardProvider";

export const AccountingOnPremiseWizardModal = () => {
  const { pages, wizardOpened, wizardMode, closeWizard } =
    useAccountingOnPremiseWizard();

  return (
    <WizardModal
      opened={wizardOpened}
      pages={pages}
      mode={wizardMode}
      onClose={closeWizard}
    />
  );
};
