import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { VendorLogo } from "@/common/components/vendor-logo/VendorLogo";
import { ExternalPoBaseFieldsFragment } from "@/generated/graphql";
import { Identity } from "@/types/Identity";
import { FC, useMemo } from "react";
import {
  BlueCircle,
  BlueCircleText,
  ReadonlyField,
  Row,
} from "./ExternalPO.styles";

type Props = {
  item: ExternalPoBaseFieldsFragment & Identity;
};

export const ExternalPOVendors: FC<Props> = ({ item }) => {
  const firstVendor = useMemo(
    () => item.vendor?.orgPreferredVendors?.[0],
    [item.vendor],
  );

  const hasMorePoLinks = useMemo(
    () => (item.vendor?.orgPreferredVendors ?? []).length > 1,
    [item.vendor],
  );

  return (
    <Row
      className={
        !(item.vendor && item.vendor.orgPreferredVendors.length > 0)
          ? "opacity-50"
          : ""
      }
    >
      <VendorLogo
        logoImageUrl={firstVendor?.sellerOrgLocation.org.photoUrl}
        orgName={firstVendor?.sellerOrgLocation.name}
      />
      <ReadonlyField className="ml-2">
        {firstVendor?.sellerOrgLocation.name ?? item.vendor?.name ?? "--"}
      </ReadonlyField>
      <If isTrue={hasMorePoLinks}>
        <Tooltip
          id="vendors"
          element={
            <BlueCircle>
              <BlueCircleText>
                +{(item.vendor?.orgPreferredVendors ?? []).length - 1}
              </BlueCircleText>
            </BlueCircle>
          }
        >
          {(item.vendor?.orgPreferredVendors ?? [])
            .slice(1)
            .map((v) => v.sellerOrgLocation.name)
            .join(", ")}
        </Tooltip>
      </If>
    </Row>
  );
};
