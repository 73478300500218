import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import { CalendarTodayOutlined, Clear } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useDistributorRelease } from "../../../providers/DistributorReleaseProvider";
import { useDistributorReleaseDeliveryRecord } from "../providers/DistributorReleaseDeliveryRecordProvider";

type Props = {
  item: DistributorReleaseItemFieldsFragment;
};

const Container = tw.div`flex flex-col items-center`;
const ButtonContent = tw.div`flex flex-row gap-2 text-sm items-center justify-center`;
const CalendarTodayOutlinedStyled = tw(CalendarTodayOutlined)`text-lg`;
const ClearIcon = tw(Clear)`text-sm hover:text-red-300`;
const MovedToContainer = tw.div`text-2xs mt-1`;
const DeleteContainer = tw.span`grid w-full h-full place-items-center`;

export const DistributorReleaseItemIssueSolutionDateSelector: FC<Props> = ({
  item,
}) => {
  const { release } = useDistributorRelease();
  const { issueResolutions, toggleCalendarOpened, setIssueRedelivery } =
    useDistributorReleaseDeliveryRecord();

  const issue = useMemo(() => item?.issues?.[0], [item]);

  const selectedIssueSolutions = useMemo(
    () => issueResolutions.find((r) => r.issueId === issue?.id),
    [issueResolutions, issue?.id],
  );

  const selectedRedelivery = useMemo(
    () =>
      selectedIssueSolutions?.edit?.redelivery ||
      selectedIssueSolutions?.redelivery,
    [selectedIssueSolutions],
  );

  const selectedAlternativeDelivery = useMemo(
    () =>
      release?.project?.releases.find(
        (r) => r.id === selectedRedelivery?.releaseId,
      ),
    [release, selectedRedelivery],
  );

  const Button = useMemo(
    () =>
      selectedRedelivery?.time || selectedAlternativeDelivery?.time
        ? PrimaryButton
        : OutlinedButton,
    [selectedRedelivery?.time, selectedAlternativeDelivery?.time],
  );

  const onClear = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setIssueRedelivery(issue?.id, { time: null });
      e.stopPropagation();
    },
    [issue?.id, setIssueRedelivery],
  );

  return (
    <Container>
      <Button
        className="h-7 min-h-1 w-26 p-0"
        onClick={toggleCalendarOpened}
        testId="distributor-release-items-solution-date-selector"
      >
        <ButtonContent>
          <If
            isTrue={
              selectedRedelivery?.time || selectedAlternativeDelivery?.time
            }
          >
            <>
              <DateView
                date={
                  selectedRedelivery?.time || selectedAlternativeDelivery?.time
                }
                options={SHORT_DATE_OPTION}
              />
              <DeleteContainer onClick={onClear}>
                <ClearIcon />
              </DeleteContainer>
            </>
          </If>
          <If
            isTrue={
              !selectedRedelivery?.time && !selectedAlternativeDelivery?.time
            }
          >
            <>
              <CalendarTodayOutlinedStyled />
              <FormattedMessage id="PICK_DATE" />
            </>
          </If>
        </ButtonContent>
      </Button>
      <If isTrue={selectedRedelivery?.time || selectedAlternativeDelivery}>
        <FormattedMessage
          id={
            !selectedAlternativeDelivery
              ? "MOVED_TO_NEW_DELIVERY"
              : "MOVED_TO_DELIVERY"
          }
          values={
            selectedRedelivery?.time
              ? {
                  number: selectedAlternativeDelivery?.sequenceNumber,
                }
              : undefined
          }
          tagName={MovedToContainer}
        />
      </If>
    </Container>
  );
};
