import { MenuItem } from "@szhsin/react-menu";
import { FC } from "react";
import { Category } from "./CategoriesProvider";

type Props = {
  category: Category;
  onCategorySelect: (id: string) => void;
  selectedId?: string | null;
};

export const CategoryMenuItem: FC<Props> = ({ category, onCategorySelect }) => (
  <MenuItem
    key={category.label}
    onClick={() => onCategorySelect(category.id || "")}
  >
    {category.label}
  </MenuItem>
);
