import { gql } from "@apollo/client";
import { COST_CODE_FIELDS } from "../../../../cost-structure/pages/cost-codes/fragments/costCodeFragment";
import { PROJECT_MATERIAL_ORG_CATALOG_SKU_FIELDS } from "./projectMaterialOrgCatalogSkuFragment";
import { PROJECT_MATERIAL_PRODUCT_FIELDS } from "./projectMaterialProductFragment";
import { PROJECT_MATERIAL_SKU_FIELDS } from "./projectMaterialSkuFragment";

export const PROJECT_MATERIAL_FIELDS = gql`
  ${PROJECT_MATERIAL_SKU_FIELDS}
  ${PROJECT_MATERIAL_ORG_CATALOG_SKU_FIELDS}
  ${PROJECT_MATERIAL_PRODUCT_FIELDS}
  ${COST_CODE_FIELDS}
  fragment ProjectMaterialFields on OrgMaterial {
    id
    material {
      ... on Product {
        ...ProjectMaterialProductFields
      }
      ... on Sku {
        ...ProjectMaterialSkuFields
      }
      ... on OrgCatalogSku {
        ...ProjectMaterialOrgCatalogSkuFields
      }
    }
    costCode {
      ...CostCodeFields
    }
  }
`;
