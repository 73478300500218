import { DeleteButton } from "@/common/components/button/DeleteButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { WarehouseFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { useWarehouses } from "../../providers/WarehousesProvider";

type Props = {
  warehouse: WarehouseFieldsFragment;
};

export const DeleteWarehouse: FC<Props> = ({ warehouse }) => {
  const { openDialog } = useDialog();
  const { archiveWarehouse } = useWarehouses();
  const intl = useIntl();

  const handleDelete = useCallback(() => {
    return archiveWarehouse(warehouse.id);
  }, [archiveWarehouse, warehouse.id]);

  return (
    <DeleteButton
      onClick={() =>
        openDialog({
          cancelButtonText: intl.$t({ id: "CANCEL" }),
          confirmButtonText: intl.$t({ id: "DELETE" }),
          icon: <WarningIcon />,
          title: intl.$t({ id: "DELETE_WAREHOUSE_QUESTION" }),
          text: intl.$t(
            { id: "DELETE_WAREHOUSE_INFO" },
            { name: strongify(warehouse.name) },
          ),
          handleConfirm: handleDelete,
        })
      }
    />
  );
};
