import { MaterialView } from "@/common/components/material/MaterialView";
import { RfqItemFieldsFragment } from "@/generated/graphql";
import { FC } from "react";

type Props = {
  item: RfqItemFieldsFragment;
  className?: string;
  readonly?: boolean;
  updateDescription?: (description: string) => void;
  count?: string | number;
};

export const RfqItemMaterialView: FC<Props> = ({
  item,
  className,
  readonly,
  updateDescription,
  count = "",
}) => {
  return (
    <MaterialView
      material={item.projectItem.material.material}
      description={item.description || ""}
      className={className}
      manufacturer={item.manufacturer}
      readonly={readonly}
      updateDescription={updateDescription}
      count={count}
    />
  );
};
