import { useUnitPriceCopyRenderer } from "@/common/components/spreadsheet-table/renderers/useUnitPriceCopyRenderer";
import {
  COLUMN_TYPE,
  SpreadSheetConfig,
} from "@/common/providers/ColumnMapperProvider";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useInvoiceImportExternalPO } from "../../../../providers/InvoiceImportExternalPoProvider";

export const useInvoiceUpdateReleaseSpreadsheetConfig =
  (): SpreadSheetConfig[] => {
    const intl = useIntl();
    const { importedItems } = useInvoiceImportExternalPO();
    const priceCopyRenderer = useUnitPriceCopyRenderer();
    const { release } = useRelease();

    const { costCodes } = useProjectCostCodes(release?.project?.id);

    const itemIsPartOfPoOrAssignedToInvoice = useCallback(
      (id: string, columnType: COLUMN_TYPE) => {
        const importedItem = importedItems?.find((item) => item.id === id);
        if (importedItem) {
          switch (columnType) {
            case COLUMN_TYPE.Material:
              return importedItem.description
                ? intl.$t({ id: "DISABLED_ITEM_PART_OF_PO" })
                : "";
            case COLUMN_TYPE.UOM:
              return importedItem.UOM
                ? intl.$t({ id: "DISABLED_ITEM_PART_OF_PO" })
                : "";
            default:
              return "";
          }
        }

        const assignedToInvoice = release?.items.some(
          (item) =>
            item.id === id &&
            !!item.poItemLink &&
            !!release?.poLink?.retroactive,
        );
        return assignedToInvoice
          ? intl.$t({ id: "DISABLED_ITEM_ASSIGNED_TO_INVOICE" })
          : "";
      },
      [importedItems, intl, release?.items, release?.poLink?.retroactive],
    );

    return [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: (id) =>
          isLumpSumItem(release?.items.find((itm) => itm.id === id))
            ? "name"
            : "material",
        width: 100,
        columnType: COLUMN_TYPE.Material,
        readOnlyFn: (id) =>
          itemIsPartOfPoOrAssignedToInvoice(id, COLUMN_TYPE.Material),
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "UOM",
        width: 60,
        columnType: COLUMN_TYPE.UOM,
        readOnlyFn: (id) =>
          itemIsPartOfPoOrAssignedToInvoice(id, COLUMN_TYPE.UOM),
      },
      {
        header: intl.$t({ id: "QUANTITY" }),
        columnId: "quantityDecimal",
        width: 60,
        columnType: COLUMN_TYPE.Quantity,
      },
      {
        header: intl.$t({ id: "UNIT_PRICE" }),
        columnId: "unitPrice",
        width: 60,
        columnType: COLUMN_TYPE.UnitPrice,
        renderer: priceCopyRenderer,
        disabledForLumpSum: true,
      },
      {
        header: intl.$t({ id: "EXT_PRICE" }),
        columnId: "extPrice",
        width: 60,
        columnType: COLUMN_TYPE.ExtPrice,
      },
      {
        header: intl.$t({ id: "COST_CODE" }),
        columnId: "costCode",
        width: 80,
        columnType: COLUMN_TYPE.CostCode,
        options: costCodes.map((c) => c.formatted),
        metadata: {
          projectId: release?.project?.id,
        },
      },
      {
        header: intl.$t({ id: "NOTES" }),
        columnId: "notes",
        width: 100,
        columnType: COLUMN_TYPE.Notes,
      },
    ];
  };
