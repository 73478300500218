import { DateListView } from "@/common/components/date/DateListView";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { MdContainer } from "@/common/layout/ResponsiveClasses";
import {
  DistributorBuyoutReleaseFieldsFragment,
  ServiceType,
} from "@/generated/graphql";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { DeliveryActions } from "./DeliveryActions";
import { DeliveryIssues } from "./DeliveryIssues";

const ReleaseWrapper = tw(MdContainer)`
  col-span-3
`;

const ThinContainer = tw.div`font-thin`;

export const ListConfiguration: Array<
  GridCol<DistributorBuyoutReleaseFieldsFragment>
> = [
  {
    wrapper: ReleaseWrapper,
    header: <FormattedMessage id="RELEASE" />,
    position: "start",
    item: ({ item }) => (
      <FormattedMessage
        id="RELEASE_NAME"
        values={{ number: item.sequenceNumber }}
      />
    ),
  },
  {
    wrapper: MdContainer,
    header: <FormattedMessage id="DATE" />,
    position: "center",
    item: ({ item }) => <DateListView date={item.time} />,
  },
  {
    wrapper: MdContainer,
    header: <FormattedMessage id="DELIVERY_TYPE" />,
    position: "center",
    item: ({ item }) => (
      <FormattedMessage
        tagName={ThinContainer}
        id={
          item.includeServices.includes(ServiceType.Delivery)
            ? "BY_VENDOR"
            : "CLIENT_DELIVERY"
        }
      />
    ),
  },
  {
    wrapper: MdContainer,
    header: <FormattedMessage id="STOCKING" />,
    position: "center",
    item: ({ item }) => (
      <FormattedMessage
        tagName={ThinContainer}
        id={
          item.includeServices.includes(ServiceType.Stocking)
            ? "BY_VENDOR"
            : "CLIENT_DELIVERY"
        }
      />
    ),
  },
  {
    wrapper: MdContainer,
    header: <FormattedMessage id="ISSUES" />,
    position: "center",
    item: ({ item }) => <DeliveryIssues issues={item.issues} />,
  },
  {
    wrapper: MdContainer,
    header: "",
    position: "center",
    item: ({ item }) => <DeliveryActions release={item} />,
  },
  {
    wrapper: MdContainer,
    header: <FormattedMessage id="STATUS" />,
    position: "center",
    item: ({ item }) => (
      <ReleaseStatusChip status={item.status} releaseType={undefined} />
    ),
  },
];
