import { DistributorQuoteItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";
import { LeadTime } from "../common/LeadTime";

type Props = {
  item: DistributorQuoteItemFieldsFragment;
  readonly: boolean;
};

export const DistributorQuoteLeadTime: FC<Props> = ({ item, readonly }) => {
  const { updateQuote } = useDistributorQuote();
  const handleOnSave = useCallback(
    async (leadTimeDays: number | null) => {
      const updates = [
        {
          quoteItemId: item.id,
          leadTimeDays,
        },
      ];
      await updateQuote({
        updates,
      });
    },
    [item.id, updateQuote],
  );

  return (
    <LeadTime
      leadTimeDays={item.leadTimeDays}
      readonly={readonly}
      updateLeadTime={handleOnSave}
    />
  );
};
