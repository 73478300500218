import {
  DistributorReleaseItemFieldsFragment,
  ReleaseItemFieldsFragment,
  ZoneFieldsFragment,
} from "@/generated/graphql";

export const getZonesByReleaseItems = (
  releaseItems:
    | DistributorReleaseItemFieldsFragment[]
    | ReleaseItemFieldsFragment[],
  unassignedZone: ZoneFieldsFragment,
  isAddMode?: boolean,
) => {
  const zones: ZoneFieldsFragment[] = [];
  releaseItems.forEach((item) => {
    if (item.zone && !zones.find((z) => z.id === item?.zone?.id)) {
      zones.push(item.zone);
    }
  });

  if (releaseItems.some((i) => !i.zone) || isAddMode) {
    zones.push(unassignedZone);
  }
  return zones.sort((a, b) => a.name.localeCompare(b.name));
};
