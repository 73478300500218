import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { QUERYSTRING } from "@/common/const";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useReleaseUpdate } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import {
  CreateDeliveredReleaseItemInput,
  DeliverySlipStatus,
  UpdateDeliveredReleaseItemInput,
} from "@/generated/graphql";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useArchiveDeliverySlip } from "../../../../hooks/useArchiveDeliverySlip";
import {
  UpdateReleaseFormValues,
  useDeliverySlipRelease,
} from "../../../../providers/DeliverySlipReleaseProvider";
import { useDeliverySlipSequence } from "../../../../providers/DeliverySlipSequenceProvider";
import { useDeliverySlipStats } from "../../../../providers/DeliverySlipStatsProvider";
import {
  PackingSlipReceiveViewState,
  useDeliverySlipVerification,
} from "../../../../providers/DeliverySlipVerificationProvider";

const ButtonContainer = tw.div`grid grid-flow-col gap-2 items-center justify-end`;
const FloatingFooterStyled = tw(FloatingFooter)`md:px-6`;

type Props = {
  disabled?: boolean;
};

export const DeliverySlipReceiveFooter: FC<Props> = ({ disabled = false }) => {
  const intl = useIntl();
  const { release } = useRelease();
  const { openDialog } = useDialog();
  const { refetch } = useDeliverySlipStats();
  const { archiveDeliverySlip } = useArchiveDeliverySlip();
  const [confirming, setConfirming] = useState(false);
  const {
    deliverySlip,
    updateDeliverySlip,
    scanningSlip,
    packingSlipReceiveViewState,
    setPackingSlipReceiveViewState,
  } = useDeliverySlipVerification();
  const { queryParams, setQueryParams } = useQueryParams();
  const { selectedReleaseItemIds, submitUpdate } = useReleaseUpdate();
  const {
    receivedQuantities,
    updateReleaseForm: form,
    updating,
    syncUpdateReleaseFromDeliverySlip,
  } = useDeliverySlipRelease();
  const { navigateToNextSequenceOrRedirect } = useDeliverySlipSequence();

  const saveRelease = useCallback(async () => {
    await form?.handleSubmit(async (values: UpdateReleaseFormValues) => {
      const sync = await syncUpdateReleaseFromDeliverySlip(values);
      if (!sync) {
        return null;
      }
      setPackingSlipReceiveViewState(
        deliverySlip?.status === DeliverySlipStatus.Processed
          ? PackingSlipReceiveViewState.DEFAULT
          : PackingSlipReceiveViewState.EDIT_COVERAGE,
      );

      if (queryParams.get(QUERYSTRING.SPREADSHEET_VIEW)) {
        setQueryParams({ [QUERYSTRING.SPREADSHEET_VIEW]: undefined });
      }
    })();
  }, [
    deliverySlip?.status,
    form,
    queryParams,
    setPackingSlipReceiveViewState,
    setQueryParams,
    syncUpdateReleaseFromDeliverySlip,
  ]);

  const confirm = useCallback(async () => {
    setConfirming(true);
    const itemsToAdd: CreateDeliveredReleaseItemInput[] =
      selectedReleaseItemIds
        ?.filter(
          (id) =>
            deliverySlip?.deliveredReleaseItems.find(
              (item) => item.releaseItem.id === id,
            ) === undefined,
        )
        .map((id) => ({
          releaseItemId: id,
          quantity:
            receivedQuantities.find(
              (receivedItem) => receivedItem.releaseItemId === id,
            )?.receivedQuantityDecimal ?? "0",
        })) || [];
    const itemsToUpdate: UpdateDeliveredReleaseItemInput[] =
      deliverySlip?.deliveredReleaseItems
        ?.filter((item) => selectedReleaseItemIds.includes(item.releaseItem.id))
        .map((item) => ({
          id: item.id,
          quantity:
            receivedQuantities.find(
              (receivedItem) =>
                receivedItem.releaseItemId === item.releaseItem.id,
            )?.receivedQuantityDecimal ?? "0",
        })) || [];
    const itemsToRemove = deliverySlip?.deliveredReleaseItems
      .filter((item) => !selectedReleaseItemIds.includes(item.releaseItem.id))
      .map((item) => item.id);

    const result = await updateDeliverySlip({
      id: deliverySlip?.id ?? "",
      releaseId: release?.id ?? "",
      addedDeliveredReleaseItems: itemsToAdd,
      removedDeliveredReleaseItems: itemsToRemove,
      updatedDeliveredReleaseItems: itemsToUpdate,
      receive: true,
    });

    if (!result) {
      setConfirming(false);
      return;
    }
    await refetch();

    const submitResult = submitUpdate(release?.version, {
      callback: (submitResult) => {
        if (!submitResult) {
          setConfirming(false);
          return;
        }

        setConfirming(false);

        navigateToNextSequenceOrRedirect(deliverySlip?.id, {
          navigateToOrder: true,
          orderId: release?.id,
        });
      },
    });
    if (!submitResult) {
      setConfirming(false);

      navigateToNextSequenceOrRedirect(deliverySlip?.id, {
        navigateToOrder: true,
        orderId: release?.id,
      });
    }
  }, [
    deliverySlip?.deliveredReleaseItems,
    deliverySlip?.id,
    navigateToNextSequenceOrRedirect,
    receivedQuantities,
    refetch,
    release?.id,
    release?.version,
    selectedReleaseItemIds,
    submitUpdate,
    updateDeliverySlip,
  ]);

  const archiveSlip = useCallback(() => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PROCEED" }),
      icon: <WarningIcon />,
      title: intl.$t({ id: "ARCHIVE_DELIVERY_SLIP_DETAILS" }),
      text: intl.$t({ id: "ARCHIVE_DELIVERY_SLIP" }),
      handleConfirm: async () => {
        if (await archiveDeliverySlip(deliverySlip?.id ?? "")) {
          await refetch();
          navigateToNextSequenceOrRedirect(deliverySlip?.id);
        }
      },
    });
  }, [
    archiveDeliverySlip,
    deliverySlip?.id,
    intl,
    navigateToNextSequenceOrRedirect,
    openDialog,
    refetch,
  ]);

  const close = useCallback(() => {
    navigateToNextSequenceOrRedirect(deliverySlip?.id);
  }, [deliverySlip?.id, navigateToNextSequenceOrRedirect]);

  return (
    <FloatingFooterStyled>
      <ButtonContainer>
        <If isTrue={!scanningSlip}>
          <If
            isTrue={
              deliverySlip?.status === DeliverySlipStatus.Processed &&
              packingSlipReceiveViewState ===
                PackingSlipReceiveViewState.EDIT_COVERAGE
            }
          >
            <OutlinedButton
              disabled={disabled}
              className="py-0"
              onClick={() =>
                setPackingSlipReceiveViewState(
                  PackingSlipReceiveViewState.DEFAULT,
                )
              }
            >
              <FormattedMessage id="BACK" />
            </OutlinedButton>
          </If>
          <If
            isTrue={
              deliverySlip?.status !== DeliverySlipStatus.Processed ||
              (packingSlipReceiveViewState !==
                PackingSlipReceiveViewState.EDIT_COVERAGE &&
                packingSlipReceiveViewState !==
                  PackingSlipReceiveViewState.EDIT_ORDER)
            }
          >
            <OutlinedButton onClick={archiveSlip} disabled={disabled}>
              <FormattedMessage id="DELETE" />
            </OutlinedButton>
          </If>
          <If
            isTrue={
              packingSlipReceiveViewState ===
              PackingSlipReceiveViewState.EDIT_COVERAGE
            }
          >
            <PrimaryButton
              disabled={disabled}
              className="py-0"
              loading={confirming}
              onClick={confirm}
            >
              <FormattedMessage
                id={
                  deliverySlip?.status === DeliverySlipStatus.Processed
                    ? "SAVE_CHANGES"
                    : "RECEIVE_ORDER"
                }
              />
            </PrimaryButton>
          </If>
          <If
            isTrue={
              packingSlipReceiveViewState !==
                PackingSlipReceiveViewState.EDIT_COVERAGE &&
              packingSlipReceiveViewState !==
                PackingSlipReceiveViewState.EDIT_ORDER
            }
          >
            <OutlinedButton
              disabled={disabled}
              className="py-0"
              onClick={close}
            >
              <FormattedMessage id="CLOSE" />
            </OutlinedButton>
          </If>
          <If
            isTrue={
              packingSlipReceiveViewState ===
              PackingSlipReceiveViewState.EDIT_ORDER
            }
          >
            <OutlinedButton
              onClick={() => {
                setPackingSlipReceiveViewState(
                  deliverySlip?.status === DeliverySlipStatus.Processed
                    ? PackingSlipReceiveViewState.DEFAULT
                    : PackingSlipReceiveViewState.EDIT_COVERAGE,
                );
              }}
            >
              <FormattedMessage id="CANCEL_CHANGES" />
            </OutlinedButton>
            <PrimaryButton onClick={saveRelease} loading={updating}>
              <FormattedMessage id="SAVE_CHANGES" />
            </PrimaryButton>
          </If>
        </If>
      </ButtonContainer>
    </FloatingFooterStyled>
  );
};
