import { If } from "@/common/components/if/If";
import { NumericalInput } from "@/common/components/numerical-input/NumericalInput";
import { Base, LgFixedContainer } from "@/common/layout/ResponsiveClasses";

import { useUomOptions } from "@/common/hooks/useUomOptions";
import {
  OrgMaterialFieldsFragment,
  VendorPriceFieldsFragment,
} from "@/generated/graphql";
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useMaterialPrices } from "../../providers/MaterialPricesProvider";
import { useMaterialPricesConfiguration } from "../MaterialPriceList.configuration";

const Container = tw.div`
  flex w-full gap-1 py-3 justify-start
`;

const Label = tw.div`
  text-sm font-medium mr-4 w-full text-right
`;

const LabelContainer = tw(Base)`
  basis-full lg:basis-1/12 lg:w-1/12
  3xl:basis-2/12 3xl:w-2/12 flex-1 col-span-full
`;

const CustomFixedContainer = tw(Base)`
  2xl:w-[263px] lg:w-[206px]
  2xl:basis-[263px] lg:basis-[206px]
`;

const NumericalInputStyled = tw(NumericalInput)`
  bg-white
`;

const ReadOnlyContainer = tw.div`
  grid grid-flow-col gap-10 w-full justify-end pr-1
`;

const ValueContainer = tw.div`
  grid grid-flow-col w-full gap-1 justify-start
`;

const ValueLabel = tw.div`
  text-xs text-gray-600 w-full text-right
`;

const Value = tw.div`
  text-xs font-medium w-full text-left
`;

const InputStyle = {
  ".MuiInputBase-input": {
    paddingTop: "10px",
    paddingBottom: "10px",
    lineHeight: "10px",
    textAlign: "center",
    height: "14px",
  },
};

type Props = {
  item: OrgMaterialFieldsFragment;
  price?: VendorPriceFieldsFragment;
  error?: boolean;
  readonly: boolean;
};

export const MaterialPriceExtraDetails: FC<Props> = ({
  item,
  price,
  error,
  readonly,
}) => {
  const { updateMaterial } = useMaterialPrices();
  const intl = useIntl();
  const [orderIncrement, setOrderIncrement] = useState(
    price?.orderIncrement || "",
  );
  const [minimumOrder, setMinimumOrder] = useState(price?.minimumOrder || "");
  const [leadTime, setLeadTime] = useState(price?.leadTime || "");
  const { configuration } = useMaterialPricesConfiguration();
  const { uoms } = useUomOptions();

  useEffect(() => {
    setOrderIncrement(price?.orderIncrement || "");
    setMinimumOrder(price?.minimumOrder || "");
    setLeadTime(price?.leadTime || "");
  }, [price]);

  const onOrderIncrementChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = Number(event.target.value);
      setOrderIncrement(newValue);
      updateMaterial(item.id, {
        vendorPriceId: price?.id || "",
        orderIncrement: newValue,
      });
    },
    [price, updateMaterial, item],
  );

  const onMinChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = Number(event.target.value);
      setMinimumOrder(newValue);
      updateMaterial(item.id, {
        vendorPriceId: price?.id || "",
        minimumOrder: newValue,
      });
    },
    [price, updateMaterial, item],
  );

  const onChangeLeadTime = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = Number(event.target.value);
      setLeadTime(newValue);
      updateMaterial(item.id, {
        vendorPriceId: price?.id || "",
        leadTime: newValue,
      });
    },
    [price, updateMaterial, item],
  );

  const lastColumnsInGrid = useMemo(() => {
    return configuration.slice(6, configuration.length - 1);
  }, [configuration]);

  const uom = useMemo(() => {
    return uoms.find((uom) => uom.id === price?.uom.id);
  }, [uoms, price]);

  if (!price) {
    return null;
  }

  return (
    <>
      {readonly ? (
        <ReadOnlyContainer>
          <If isTrue={price?.orderIncrement}>
            <ValueContainer>
              <ValueLabel>
                <FormattedMessage id="ORDER_INCREMENT" />
              </ValueLabel>
              <Value>
                {price?.orderIncrement} {uom?.mnemonic}
              </Value>
            </ValueContainer>
          </If>
          <If isTrue={price?.minimumOrder}>
            <ValueContainer>
              <ValueLabel>
                <FormattedMessage id="MINIMUM_ORDER" />
              </ValueLabel>
              <Value>
                {" "}
                {price?.minimumOrder} {uom?.mnemonic}{" "}
              </Value>
            </ValueContainer>
          </If>
          <If isTrue={price?.leadTime}>
            <ValueContainer>
              <ValueLabel>
                <FormattedMessage id="LEAD_TIME" />
              </ValueLabel>
              <Value>
                {price?.leadTime}{" "}
                {price?.leadTime === 1 ? (
                  <FormattedMessage id="DAY" />
                ) : (
                  <FormattedMessage id="DAYS" />
                )}
              </Value>
            </ValueContainer>
          </If>
          {lastColumnsInGrid.map((column, index) => {
            const Wrapper = column.wrapper;
            return Wrapper ? <Wrapper key={index} /> : null;
          })}
        </ReadOnlyContainer>
      ) : (
        <Container>
          <LabelContainer />
          <LgFixedContainer>
            <FormattedMessage id="ORDER_REQUIREMENTS" tagName={Label} />
          </LgFixedContainer>
          <CustomFixedContainer>
            <NumericalInputStyled
              value={orderIncrement}
              onChange={onOrderIncrementChange}
              staticText={readonly}
              error={error}
              label={intl.$t(
                { id: "ORDER_INCREMENT_PER_UOM" },
                { uom: uom?.mnemonic || intl.$t({ id: "UOM" }) },
              )}
              InputLabelProps={{ className: "text-sm" }}
              classes={{ container: "w-full" }}
              sx={InputStyle}
              className="w-full"
            />
          </CustomFixedContainer>
          <CustomFixedContainer>
            <NumericalInputStyled
              value={minimumOrder}
              onChange={onMinChange}
              staticText={readonly}
              error={error}
              label={intl.$t(
                { id: "MINIMUM_ORDER_PER_UOM" },
                { uom: uom?.mnemonic || intl.$t({ id: "UOM" }) },
              )}
              InputLabelProps={{ className: "text-sm" }}
              classes={{ container: "w-full" }}
              sx={InputStyle}
              className="w-full"
            />
          </CustomFixedContainer>
          <CustomFixedContainer>
            <NumericalInputStyled
              value={leadTime}
              onChange={onChangeLeadTime}
              staticText={readonly}
              error={error}
              label={intl.$t({
                id: "LEAD_TIME_DAYS_LABEL",
              })}
              InputLabelProps={{ className: "text-sm" }}
              classes={{ container: "w-full" }}
              sx={InputStyle}
              className="w-full"
            />
          </CustomFixedContainer>
          {lastColumnsInGrid.map((column, index) => {
            const Wrapper = column.wrapper;
            return Wrapper ? <Wrapper key={index} /> : null;
          })}
        </Container>
      )}
    </>
  );
};
