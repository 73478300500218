import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  grid gap-y-4 -mx-3
`;

const Wrapper = tw.div`
  grid justify-between border-blue-100 border
  w-full bg-blue-100 py-3 px-4 rounded-md
  grid-flow-col items-start
`;

type Props = {
  children: React.ReactNode;
  onClose: () => void;
};

export const InfoPanel: FC<Props> = ({ children, onClose }) => {
  const intl = useIntl();

  return (
    <OverlayPanel title={intl.$t({ id: "INFO" })} onCancel={onClose}>
      <Container>
        {React.Children.map(children, (child) => {
          return <Wrapper>{child}</Wrapper>;
        })}
      </Container>
    </OverlayPanel>
  );
};
