import { getAssetType } from "@/common/components/upload-asset/getAssetType";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { useUser } from "@/common/providers/UserProvider";

import { mergeChanges } from "@/common/utils/mergeChanges";
import { hasProperty } from "@/common/utils/objectUtils";
import { generateUUID } from "@/common/utils/uuidUtils";
import {
  AssetContext,
  DistributorReleaseDocument,
  DistributorReleaseFieldsFragment,
  DistributorReleaseItemFieldsFragment,
  ReleaseStatus,
  UpdateVendorReleaseFieldsFragment,
  UpdateVendorReleaseInput,
  UpdateVendorReleaseItemInput,
  useDistributorReleaseQuery,
  useUpdateVendorReleaseMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import Decimal from "decimal.js";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";

export enum DistributorReleaseErrorType {
  "MANUFACTURER",
  "UOM",
  "PRICE",
  "ADDITIONAL_PRICE",
}

type ProviderContextType = {
  release: DistributorReleaseFieldsFragment | undefined | null;
  updating: boolean;
  updatedItem: (
    item: DistributorReleaseItemFieldsFragment,
  ) => UpdateVendorReleaseItemInput | null | undefined;
  changes: UpdateVendorReleaseInput | undefined;
  confirmAndUpdateRelease: () => Promise<boolean>;
  updateVendorRelease: (
    input: Omit<UpdateVendorReleaseInput, "releaseId" | "version">,
  ) => Promise<UpdateVendorReleaseFieldsFragment | null | undefined | boolean>;
  updateVendorReleaseItem: (
    input: UpdateVendorReleaseItemInput,
  ) => Promise<void>;
  inputErrors: DistributorReleaseErrorType[];
  setInputError: (fieldName: DistributorReleaseErrorType) => void;
  removeInputError: (fieldName: DistributorReleaseErrorType) => void;
  expandedItems: string[];
  setExpandedItems: (items: string[]) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  release: undefined,
  updating: false,
  changes: undefined,
  updatedItem: () => null,
  confirmAndUpdateRelease: NoFunctionBooleanPromise,
  updateVendorRelease: NoFunctionUndefinedPromise,
  updateVendorReleaseItem: NoFunctionPromise,
  inputErrors: [],
  setInputError: NoFunction,
  removeInputError: NoFunction,
  expandedItems: [],
  setExpandedItems: NoFunction,
});

export const DistributorReleaseProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { deliveryId: id } = useParams();
  const { setContractorCurrency } = useUser();
  const releaseId = id || "";
  const { setError } = useGlobalError();
  const [inputErrors, setInputErrors] = useState<DistributorReleaseErrorType[]>(
    [],
  );
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const { data, error, refetch } = useDistributorReleaseQuery({
    variables: { id: releaseId },
  });
  const [updateRelease, { loading: updating }] =
    useUpdateVendorReleaseMutation();
  const { manufacturers } = useManufacturers();
  const [changes, setChanges] = useState<
    UpdateVendorReleaseInput | undefined
  >();
  const { getUomByName } = useUomOptions();
  const [release, setRelease] =
    useState<DistributorReleaseFieldsFragment | null>(null);

  useEffect(() => {
    if (data?.release) {
      const releaseWithRequested = {
        ...data.release,
        requestedTime: data.release.time,
        items: data.release.items.map((item) => ({
          ...item,
          requestedQuantity:
            data.release?.status === ReleaseStatus.Requested
              ? Number(item.quantityDecimal)
              : 0,
        })),
      };

      setRelease(releaseWithRequested);
      setContractorCurrency(
        data.release.orgLocation?.org.settings?.display?.currency,
      );
    }
  }, [data?.release, setContractorCurrency]);

  const updateVendorReleaseItem = async (
    input: UpdateVendorReleaseItemInput,
  ): Promise<void> => {
    await updateVendorRelease({
      updates: [input],
    });
  };

  const updateVendorRelease = async (
    input: Omit<UpdateVendorReleaseInput, "releaseId" | "version">,
  ) => {
    if (release) {
      const updatedRelease = {
        ...release,
        version: release.version,
        notes: input.notes || release.notes,
        paymentTerm: input.paymentTerm || release.paymentTerm,
        time: input.time || release.time,
        customTaxAmount: input.clearCustomTaxAmount
          ? null
          : hasProperty(input, "customTaxAmount")
            ? input.customTaxAmount
            : release?.customTaxAmount,
        taxRate: input.taxRate || release.taxRate,
        additionalCharges: input.additionalCharges
          ? input.additionalCharges?.map((c) => {
              return {
                ...c,
                id: c.id || "",
                __typename: "Charge" as const,
              };
            }) || []
          : release.additionalCharges || [],
        items: release.items.map(
          (item: DistributorReleaseItemFieldsFragment) => {
            const updatedItem = input.updates?.find(
              (i) => i.releaseItemId === item.id,
            );
            const targetRelease = release.project?.releases.find(
              (r) => r.id === updatedItem?.time?.releaseId,
            );
            let uom = item.uom;
            if (updatedItem?.uom) {
              const u = getUomByName(updatedItem.uom);
              if (u) {
                uom = u;
              } else {
                uom = {
                  id: generateUUID(),
                  pluralDescription: updatedItem.uom,
                  alternativeMnemonics: [],
                };
              }
            }
            const unitPrice = updatedItem?.unitPrice ?? item.unitPrice;
            const quantityDecimal =
              updatedItem?.quantityDecimal ?? item.quantityDecimal;
            const newItem: DistributorReleaseItemFieldsFragment = {
              ...item,
              ...(updatedItem && {
                isIncluded: updatedItem?.isIncluded ?? item.isIncluded,
                manufacturer: updatedItem?.manufacturerId
                  ? manufacturers.find(
                      (m) => m.id === updatedItem?.manufacturerId,
                    )
                  : item.manufacturer,
                quantityDecimal,
                unitPrice,
                alternativeFulfillmentTime: updatedItem.time
                  ? updatedItem?.time?.time
                  : item.alternativeFulfillmentTime || null,
                alternativeFulfillmentRelease: updatedItem.time?.releaseId
                  ? {
                      id: updatedItem?.time?.releaseId,
                      time: targetRelease?.time,
                      sequenceNumber: targetRelease?.sequenceNumber || 0,
                    }
                  : item.alternativeFulfillmentRelease || null,
                alternativeFulfillmentQuantity: updatedItem.time
                  ? updatedItem?.time?.quantity
                  : item.alternativeFulfillmentQuantityDecimal || null,
                uom,
                notes: updatedItem?.notes ?? item.notes,
                assets:
                  updatedItem?.assetUrls?.map((assetUrl) => ({
                    url: assetUrl,
                    __typename: "Asset" as const,
                    type: getAssetType(assetUrl),
                    context: AssetContext.Instruction,
                    pages: null,
                    thumbnailUrl: assetUrl,
                  })) ?? item.assets,
              }),
            };
            return newItem;
          },
        ),
      };
      const subtotal = updatedRelease.items.reduce((acc: number, item) => {
        return Number(
          new Decimal(acc).add(
            new Decimal(item.unitPrice || 0).mul(item.quantityDecimal),
          ),
        );
      }, 0);
      const charges = updatedRelease.additionalCharges.reduce((acc, charge) => {
        return Number(new Decimal(acc).add(new Decimal(charge.amount || 0)));
      }, 0);
      const taxAmount = updatedRelease.taxRate
        ? new Decimal(subtotal)
            .add(charges)
            .mul(updatedRelease.taxRate || 0)
            .toString()
        : "";
      const customTaxAmount =
        input.customTaxAmount ?? updatedRelease.customTaxAmount;
      setRelease({
        ...release,
        ...updatedRelease,
        taxAmount: taxAmount.toString(),
        subtotal: subtotal.toString(),
        total: new Decimal(subtotal)
          .add(charges)
          .add(customTaxAmount || taxAmount || 0)
          .toString(),
      });
    }
    return await update({
      releaseId,
      version: data?.release?.version || 0,
      ...input,
    });
  };

  const accumulateChanges = useCallback(
    (
      input:
        | Omit<UpdateVendorReleaseInput, "releaseId" | "version">
        | undefined
        | null,
    ) => {
      const newChanges = {
        ...changes,
        ...input,
        releaseId,
        version: data?.release?.version || 0,
        updates: mergeChanges(
          changes?.updates,
          input?.updates,
          "releaseItemId",
        ),
      };

      setChanges(newChanges);
      return newChanges;
    },
    [changes, releaseId, data?.release?.version],
  );

  const submitUpdate = useCallback(
    async (mergedChanges: UpdateVendorReleaseInput | undefined | null) => {
      try {
        const { data: updateResponse, errors } = await updateRelease({
          variables: {
            input: {
              ...(mergedChanges ?? {}),
              releaseId: release?.id ?? "",
              version: release?.version ?? 0,
              confirm: true,
            },
          },
          refetchQueries: [
            {
              query: DistributorReleaseDocument,
              variables: { id: releaseId },
            },
          ],
        });
        setChanges(undefined);
        setError(errors);
        return updateResponse;
      } catch (errors) {
        setError(errors);
        return null;
      }
    },
    [updateRelease, release?.id, release?.version, releaseId, setError],
  );

  const update = useCallback(
    async (input?: UpdateVendorReleaseInput) => {
      await accumulateChanges(input);
      return true;
    },
    [accumulateChanges],
  );

  const confirmAndUpdateRelease = async () => {
    if (data?.release?.version) {
      const result = await submitUpdate(changes);
      if (result) {
        refetch();
      }
      return !!result;
    }
    return false;
  };

  const setInputError = (fieldName: DistributorReleaseErrorType) => {
    setInputErrors((errors) =>
      errors.includes(fieldName) ? errors : [fieldName, ...errors],
    );
  };

  const removeInputError = (fieldName: DistributorReleaseErrorType) => {
    setInputErrors((errors) =>
      errors.includes(fieldName)
        ? errors.filter((e) => e !== fieldName)
        : errors,
    );
  };

  const updatedItem = useCallback(
    (item: DistributorReleaseItemFieldsFragment) => {
      return changes?.updates?.find((i) => i.releaseItemId === item.id);
    },
    [changes?.updates],
  );

  useErrorEffect(error);
  return (
    <ProviderContext.Provider
      value={{
        release,
        updateVendorRelease,
        updatedItem,
        updateVendorReleaseItem,
        updating,
        changes,
        confirmAndUpdateRelease,
        inputErrors,
        setInputError,
        removeInputError,
        expandedItems,
        setExpandedItems,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDistributorRelease = (): ProviderContextType =>
  useContext(ProviderContext);
