import { EditButton } from "@/common/components/button/EditButton";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { TextfieldWithActions } from "@/common/components/textfield-with-actions/TextfieldWithActions";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import Decimal from "decimal.js";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutGrouped } from "../../non-quoted/providers/BuyoutGroupedProvider";

const EditIconStyled = tw(EditButton)`h-4`;
const LoaderStyled = tw(Loader)`h-4 justify-end`;

export const BuyoutInlineSalesTax: FC = () => {
  const { buyout, updateBuyout } = useContractorBuyout();
  const { subtotal, isSaleTaxAmount, setEditSalesTax, editSalesTax } =
    useBuyoutGrouped();
  const [taxAmount, setTaxAmount] = useState(buyout?.taxAmount || 0);
  const [taxRate, setTaxRate] = useState(
    new Decimal(buyout?.taxRate || 0).times(100).toNumber() || 0,
  );
  const { formatCurrency } = useFormatNumberToCurrency();
  const [updating, setUpdating] = useState(false);
  const intl = useIntl();
  const formattedTaxAmount = useMemo(() => {
    if (!buyout) {
      return;
    }

    const updatedTaxAmount = new Decimal(subtotal || 0)
      .mul(buyout.taxRate || 0)
      .div(100)
      .toNumber();
    return formatCurrency(
      buyout.taxAmount || buyout.customTaxAmount || updatedTaxAmount,
      { maximumFractionDigits: 2, minimumFractionDigits: 2 },
    );
  }, [buyout, formatCurrency, subtotal]);

  const formattedSalesTax = useMemo(
    () =>
      buyout?.taxRate
        ? `${intl.formatNumber(Number(buyout?.taxRate || 0) * 100)}% (${formattedTaxAmount})`
        : formattedTaxAmount,
    [buyout?.taxRate, formattedTaxAmount, intl],
  );
  useEffect(() => {
    if (!updating) {
      setTaxRate(new Decimal(buyout?.taxRate || 0).times(100).toNumber());
      setTaxAmount(buyout?.taxAmount || 0);
    }
  }, [buyout, updating]);

  const updateBuyoutTaxRate = useCallback(async () => {
    if (!buyout) {
      return;
    }
    setUpdating(true);
    await updateBuyout(
      {
        buyoutId: buyout?.id,
        taxRate: isSaleTaxAmount
          ? undefined
          : new Decimal(taxRate || 0).div(100).toString(),
        customTaxAmount: isSaleTaxAmount ? taxAmount.toString() : undefined,
        clearCustomTaxAmount: !isSaleTaxAmount,
      },
      true,
    );
    setUpdating(false);
    setEditSalesTax(false);
  }, [
    buyout,
    isSaleTaxAmount,
    setEditSalesTax,
    taxAmount,
    taxRate,
    updateBuyout,
  ]);

  return (
    <>
      <If isTrue={!editSalesTax}>
        {formattedSalesTax}
        <EditIconStyled onClick={() => setEditSalesTax(true)} />
      </If>
      <If isTrue={editSalesTax}>
        <TextfieldWithActions
          value={isSaleTaxAmount ? taxAmount : taxRate}
          onChange={(e) =>
            isSaleTaxAmount
              ? setTaxAmount(e.target.value)
              : setTaxRate(Number(e.target.value))
          }
          onClickSave={updateBuyoutTaxRate}
          onClickClose={() => {
            setEditSalesTax(false);
            setTaxRate(
              new Decimal(buyout?.taxRate || 0).times(100).toNumber() || 0,
            );
            setTaxAmount(buyout?.taxAmount || 0);
          }}
          showActions={!updating}
          additionalActions={
            updating ? <LoaderStyled loading small /> : undefined
          }
          className="max-w-32"
          data-testid="salesTax"
        />
      </If>
    </>
  );
};
