import { If } from "@/common/components/if/If";
import { ReleaseItemIssueDescription } from "@/common/components/issue-details/ReleaseItemIssueDescription";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { IssueResolution } from "@/contractor/pages/home/release/pages/specify-details/components/issue-resolution/IssueResolution";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { Report } from "@mui/icons-material";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const OverlayPanelContainer = tw.div`
  grid
`;

const Content: FC<{
  item: ExpandedReleaseItem;
  togglePanel: (value: boolean) => void;
}> = ({ togglePanel, item }) => {
  const intl = useIntl();

  return item.issues[0]?.resolution ? (
    <IssueResolution onClose={() => togglePanel(false)} item={item} />
  ) : (
    <OverlayPanel
      title={intl.$t({ id: "ISSUE" })}
      onCancel={() => togglePanel(false)}
    >
      <OverlayPanelContainer>
        <ReleaseItemIssueDescription item={item} />
      </OverlayPanelContainer>
    </OverlayPanel>
  );
};

export const ReleaseIssues: FC<{ item: ExpandedReleaseItem }> = ({ item }) => {
  return (
    <If isTrue={item.issues.length}>
      <DrawerPanel
        anchor={(togglePanel) => (
          <LinkLike onClick={() => togglePanel(true)}>
            <Report color="primary" />
          </LinkLike>
        )}
        content={(togglePanel) => (
          <Content item={item} togglePanel={() => togglePanel(false)} />
        )}
      />
    </If>
  );
};
