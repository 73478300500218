import { ExternalProjectsTable } from "@/common/components/external-projects/ExternalProjectsTable";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { WizardModalFooterButton } from "@/common/components/wizard-modal/components/WizardModalFooterButton";
import { SourceSystem } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useAgaveExternalProjectProvider } from "../../providers/AgaveExternalProjectProvider";
import { useProject } from "../../providers/ProjectProvider";

type ProviderContextType = {
  pages: WizardModalPage[];
  sourceSystem: SourceSystem | null;
  modalOpened: boolean;
  openModal: ({
    sourceSystem,
    selectedExternalProject,
    isConnected,
  }: {
    sourceSystem: SourceSystem;
    selectedExternalProject?: string;
    isConnected: boolean;
  }) => void;
  closeModal: () => void;

  selectedExternalProject: string | null;
  setSelectedExternalProject: (id: string | null) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  pages: [],
  sourceSystem: null,
  modalOpened: false,
  openModal: NoFunction,
  closeModal: NoFunction,

  selectedExternalProject: null,
  setSelectedExternalProject: NoFunction,
});

export const ExternalProjectMappingProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const { project } = useProject();
  const { connectProject, disconnectProject, connecting } =
    useAgaveExternalProjectProvider();

  const [sourceSystem, setSourceSystem] = useState<SourceSystem | null>(null);
  const [modalOpened, setModalOpened] = useState(false);

  const [selectedExternalProject, setSelectedExternalProject] = useState<
    string | null
  >(null);
  const [isConnected, setIsConnected] = useState(false);

  const openModal = useCallback(
    ({
      sourceSystem,
      selectedExternalProject,
      isConnected,
    }: {
      sourceSystem: SourceSystem;
      selectedExternalProject?: string;
      isConnected: boolean;
    }) => {
      setSourceSystem(sourceSystem);
      setIsConnected(isConnected);
      if (selectedExternalProject) {
        setSelectedExternalProject(selectedExternalProject);
      }
      setModalOpened(true);
    },
    [],
  );

  const resetModal = useCallback(() => {
    setSourceSystem(null);
    setSelectedExternalProject(null);
    setIsConnected(false);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpened(false);
    resetModal();
  }, [resetModal]);

  const handleConnectProject = useCallback(async () => {
    if (!!project?.id && !!selectedExternalProject && !!sourceSystem) {
      await connectProject({
        externalId: selectedExternalProject,
        nodeId: project?.id,
        sourceSystem,
      });
      closeModal();
    }
  }, [
    project,
    selectedExternalProject,
    sourceSystem,
    connectProject,
    closeModal,
  ]);

  const handleDisconnectProject = useCallback(async () => {
    if (!!project?.id && !!sourceSystem) {
      const success = await disconnectProject({
        nodeId: project?.id,
        sourceSystem,
      });
      if (success) {
        setSelectedExternalProject(null);
        setIsConnected(false);
        closeModal();
      }
    }
  }, [project, sourceSystem, disconnectProject, closeModal]);

  const pages: WizardModalPage[] = useMemo(() => {
    const footerButtonsConfig: WizardModalFooterButton[] = [];
    if (isConnected) {
      footerButtonsConfig.push({
        type: "link",
        onClick: handleDisconnectProject,
        text: intl.$t({ id: "DISCONNECT_PROJECT" }),
        className: "mr-4 justify-center",
      });
    }
    footerButtonsConfig.push(
      ...([
        {
          type: "outlined",
          onClick: closeModal,
          text: intl.$t({ id: "CLOSE" }),
          className: "flex-1 mr-2",
        },
        {
          type: "primary",
          onClick: handleConnectProject,
          text: intl.$t({ id: "CONNECT" }),
          className: "flex-1",
          loading: connecting,
          disabled: !selectedExternalProject,
        },
      ] as WizardModalFooterButton[]),
    );
    return [
      {
        title: null,
        pages: [
          {
            component: (
              <ExternalProjectsTable
                singleSelection={{
                  selectedItemId: selectedExternalProject,
                  setSelectedItemId: setSelectedExternalProject,
                  type: "single",
                }}
                sourceSystem={sourceSystem}
                project={project}
                title={intl.$t(
                  { id: "CONNECT_PROJECT_WITH_SOURCE" },
                  {
                    project: project?.name,
                    integration: sourceSystem
                      ? intl.$t({
                          id: `INTEGRATION_${sourceSystem}`,
                        })
                      : "",
                  },
                )}
              />
            ),
            footerButtonsConfig,
          },
        ],
      },
    ];
  }, [
    isConnected,
    closeModal,
    intl,
    handleConnectProject,
    connecting,
    selectedExternalProject,
    sourceSystem,
    project,
    handleDisconnectProject,
  ]);

  return (
    <ProviderContext.Provider
      value={{
        modalOpened,
        sourceSystem,
        pages,
        openModal,
        closeModal,

        selectedExternalProject,
        setSelectedExternalProject,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useExternalProjectMapping = () => useContext(ProviderContext);
