import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ProjectDocument,
  UomsDocument,
  UpdateEstimatedItemsInput,
  useUpdateEstimatedItemsMutation,
} from "@/generated/graphql";

export const useProjectEstimatedItems = () => {
  const [updateEstimatedItemsMutation, { loading: updating }] =
    useUpdateEstimatedItemsMutation();
  const { setError } = useGlobalError();

  const updateEstimatedItems = async (input: UpdateEstimatedItemsInput) => {
    try {
      const { errors } = await updateEstimatedItemsMutation({
        variables: {
          input,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: ProjectDocument,
            variables: { id: input.projectId, excludePhantoms: true },
          },
          {
            query: UomsDocument,
          },
        ],
      });
      setError(errors);
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  return { updateEstimatedItems, updating };
};
