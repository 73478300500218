import { forwardRef, useCallback } from "react";
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { ControlledProps } from "../../textfield-controlled/TextFieldControlled";
import { DateTimePicker, DateTimePickerProps } from "./DateTimePicker";

type DateTimePickerControlledProps = Omit<
  DateTimePickerProps,
  "onChange" | "date"
> &
  ControlledProps & {
    defaultTime?: {
      hours: number;
      minutes?: number;
    };
  };

export const DateTimePickerControlled = forwardRef<
  HTMLDivElement,
  DateTimePickerControlledProps
>(function DateTimePickerControlled(
  {
    name,
    rules,
    defaultTime = {
      hours: 0,
      minutes: 0,
    },
    ...props
  }: DateTimePickerControlledProps & { name: string },
  ref,
) {
  const { control } = useFormContext();

  const onDateChange = useCallback(
    (date: Date | null, field: ControllerRenderProps<FieldValues, string>) => {
      if (!field.value && defaultTime) {
        date?.setMinutes((defaultTime.minutes ?? 0) + defaultTime.hours * 60);
      }
      field.onChange(date);
    },
    [defaultTime],
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <DateTimePicker
          {...field}
          ref={ref}
          date={field.value}
          onChange={(date) => onDateChange(date, field)}
          error={fieldState.invalid}
          required={!!rules?.required}
          data-testid={name}
          {...props}
        />
      )}
    />
  );
});
