import { If } from "@/common/components/if/If";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { Price } from "@/common/components/price/Price";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import {
  Organization,
  OrgLocation,
  Release,
  ReleaseType,
} from "@/generated/graphql";
import tw from "tailwind-styled-components";

const ReleaseItem = tw.div`
  w-full py-[6px] grid grid-cols-[auto_auto_1fr] gap-x-1 justify-start items-center
`;

const ReleaseNumber = tw.div`font-medium pl-2`;
const ReleaseValue = tw.div`text-sm text-gray-600`;
const ReleaseValueRight = tw.div`flex flex-col text-xs text-gray-600 justify-self-end text-center`;
const Time = tw.div``;
const Value = tw.div``;
const NumberGroup = tw.div`grid grid-cols-[auto_auto] w-fit justify-start gap-x-1`;

type OrderType = Pick<
  Release,
  "sequenceNumber" | "poNumber" | "time" | "total" | "status"
> & {
  type: Pick<ReleaseType, "transactionKind"> | undefined;
  sellerOrgLocation?:
    | (Pick<OrgLocation, "id"> & {
        org: Pick<Organization, "photoUrl" | "name">;
      })
    | null;
};

export const OrderOptionCustomRender = (release: OrderType) => {
  return (
    <ReleaseItem>
      <OrgLogo
        logoImageUrl={release.sellerOrgLocation?.org?.photoUrl}
        name={release.sellerOrgLocation?.org?.name ?? ""}
        width={24}
      />
      <NumberGroup>
        <ReleaseNumber>#{release.sequenceNumber}</ReleaseNumber>
        <ReleaseValue>
          {" "}
          {release.poNumber ? `(${release.poNumber}) ` : " "}{" "}
        </ReleaseValue>
        <div className="col-span-2">
          <ReleaseStatusChip
            status={release.status}
            releaseType={release.type}
            type="small"
          />
        </div>
      </NumberGroup>
      <ReleaseValueRight>
        <Time>
          <DateView date={release.time} options={SHORT_DATE_OPTION} />
        </Time>
        <If isTrue={release.total}>
          <Value>
            (
            <Price price={release.total} />)
          </Value>
        </If>
      </ReleaseValueRight>
    </ReleaseItem>
  );
};
