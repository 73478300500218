import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../../providers/InvoiceVerificationProvider";

const Container = tw.div`flex flex-col flex-1`;
const Title = tw.div`font-medium text-xs`;
const Text = tw.div`font-normal text-2xs`;
const Link = tw.a`cursor-pointer text-blue-500`;

export const InvoiceItemCoverageWarning = () => {
  const { release } = useRelease();
  const { invoice, setFooterState } = useInvoiceVerification();
  const { setMatchedOrderViewState, setAddMissingInvoiceItems } =
    useInvoiceMatchedOrder();

  const invoiceItemsOrderItemsDiff = useMemo(
    () =>
      !!invoice?.release
        ? invoice.items.length - (release?.items ?? []).length
        : 0,
    [release, invoice?.release, invoice?.items.length],
  );

  const addOrderMissingItems = useCallback(() => {
    setMatchedOrderViewState(MatchedOrderViewState.EDIT_ORDER);
    setFooterState(InvoiceFooterState.EDIT_ORDER);
    setTimeout(() => {
      setAddMissingInvoiceItems(true);
    }, 1000);
  }, [setMatchedOrderViewState, setFooterState, setAddMissingInvoiceItems]);

  return (
    <Container>
      <FormattedMessage
        id="INVOICE_COVERS_MORE_ITEMS_THAN_ORDER"
        values={{ itemDiff: invoiceItemsOrderItemsDiff }}
        tagName={Title}
      />
      <FormattedMessage
        id="INVOICE_COVERS_MORE_ITEMS_THAN_ORDER_DESCRIPTION"
        values={{
          sub: (...chunks) => (
            <Link onClick={addOrderMissingItems}>{chunks}</Link>
          ),
        }}
        tagName={Text}
      />
    </Container>
  );
};
