import { DeleteButton } from "@/common/components/button/DeleteButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useUser } from "@/common/providers/UserProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { UsersUserFieldsFragment } from "@/generated/graphql";
import { Lock } from "@mui/icons-material";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { getUserName } from "../../../../../common/utils/users/getUserName";
import { useAdminUsers } from "../hooks/useAdminUsers";

type Props = {
  user: UsersUserFieldsFragment;
};
export const UserActions: FC<Props> = ({ user }) => {
  const { viewer } = useUser();
  const { deleteUser } = useAdminUsers();
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { setSuccessAlert } = useSnackbar();

  const handleDelete = useCallback(async () => {
    if (await deleteUser(user.id)) {
      setSuccessAlert(
        intl.$t(
          { id: "USER_REMOVE_SUCCESS" },
          {
            name: strongify(getUserName(user)),
          },
        ),
      );
    }
  }, [deleteUser, intl, setSuccessAlert, user]);

  if (!viewer) {
    return null;
  }

  return (
    <ButtonsContainer>
      {user.id === viewer.id || user.isOrgAdmin ? (
        <Lock />
      ) : (
        <DeleteButton
          onClick={() => {
            openDialog({
              cancelButtonText: intl.$t({ id: "CANCEL" }),
              confirmButtonText: intl.$t({ id: "DELETE" }),
              title: intl.$t({ id: "USERS_DELETE_USER_TITLE" }),
              text: intl.$t({ id: "USERS_DELETE_USER_CONFIRMATION_TEXT" }),
              handleConfirm: handleDelete,
            });
          }}
        />
      )}
    </ButtonsContainer>
  );
};
