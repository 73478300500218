import { CreateReceiptInput } from "@/generated/graphql";
import { NoFunction, NoFunctionStringPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";
import { useCreateReceipt } from "../hooks/useCreateReceipt";

type ProviderContextType = {
  createReceipt: (input: CreateReceiptInput) => Promise<string | undefined>;
  creating: boolean;
  locationId: string | null;
  setLocationId: (id: string | null) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  createReceipt: NoFunctionStringPromise,
  creating: false,
  setLocationId: NoFunction,
  locationId: null,
});

export const ReceiptCreationProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [locationId, setLocationId] = useState<string | null>(null);
  const { createReceipt, loading: creating } = useCreateReceipt();

  return (
    <ProviderContext.Provider
      value={{
        createReceipt,
        creating,
        locationId,
        setLocationId,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReceiptCreation = (): ProviderContextType =>
  useContext(ProviderContext);
