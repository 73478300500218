import { FC, Fragment } from "react";
import tw from "tailwind-styled-components";

type Props = {
  locations: Array<string>;
  className?: string;
};

const Container = tw.div`bg-gray-100 text-xs grid grid-cols-[auto_auto] gap-x-4 p-2`;
const Location = tw.div`flex items-center font-light`;

export const Locations: FC<Props> = ({ locations, className }) => {
  return (
    <Container className={className}>
      {locations.map((location: string) => (
        <Fragment key={location}>
          <Location>{location}</Location>
        </Fragment>
      ))}
    </Container>
  );
};
