import { TagPicker } from "@/common/components/tag-picker/TagPicker";
import { CategoryLike } from "@/common/hooks/useToggleCategory";
import { useProjectItemsZones } from "@/contractor/pages/home/project/providers/ProjectItemsZonesProvider";
import { getEstimatedItem } from "@/contractor/pages/home/project/utils/getEstimatedItem";
import { ProjectItemFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";

type Props = {
  item: ProjectItemFieldsFragment;
  estimatedItemIndex?: number;
  category?: CategoryLike;
};

export const RfqProjectItemTagPicker: FC<Props> = ({
  item,
  estimatedItemIndex,
  category,
}) => {
  const { groupedByZones } = useProjectItemsZones();
  const tags = useMemo(() => {
    const estimatedItem = getEstimatedItem({
      item,
      index: estimatedItemIndex,
      category,
      groupedByZones,
      sumIfMultiple: true,
    });
    return estimatedItem?.tags || [];
  }, [category, estimatedItemIndex, groupedByZones, item]);
  return <TagPicker values={tags} readonly />;
};
