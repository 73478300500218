import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ManufacturersDocument,
  useCreateManufacturerMutation,
  useCreateManufacturersMutation,
  useManufacturersQuery,
} from "@/generated/graphql";
import { useCallback } from "react";
import { MANUFACTURERS } from "../queries/manufacturers";

export const useManufacturers = () => {
  const { setError } = useGlobalError();
  const [createManufacturerMutation, { loading: createManufacturerLoader }] =
    useCreateManufacturerMutation();

  const { data, error } = useManufacturersQuery({
    variables: {
      searchPrefix: "",
    },
  });
  useErrorEffect(error);

  const createManufacturer = useCallback(
    async (manufacturerName: string) => {
      try {
        const { data, errors } = await createManufacturerMutation({
          variables: {
            input: {
              name: manufacturerName,
            },
          },
          refetchQueries: [
            {
              query: ManufacturersDocument,
              variables: { searchPrefix: "" },
            },
          ],
        });
        setError(errors);
        return data?.createManufacturer;
      } catch (error) {
        setError(error);
      }
    },
    [createManufacturerMutation, setError],
  );

  const [createManufacturersMutation, { loading: createManufacturersLoader }] =
    useCreateManufacturersMutation();

  const createManufacturers = useCallback(
    async (manufacturerNames: string[]) => {
      try {
        const { data, errors } = await createManufacturersMutation({
          variables: {
            input: manufacturerNames.map((name) => ({
              name,
            })),
          },
          refetchQueries: [
            {
              query: MANUFACTURERS,
              variables: { searchPrefix: "" },
            },
          ],
        });
        setError(errors);
        return data?.createManufacturers;
      } catch (error) {
        setError(error);
      }
    },
    [createManufacturersMutation, setError],
  );

  return {
    manufacturers: data?.manufacturers || [],
    createManufacturer,
    createManufacturerLoader,
    createManufacturers,
    createManufacturersLoader,
  };
};
