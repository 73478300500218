import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { SelectableItem } from "@/common/components/searchable-list/components/SelectableItem";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { XlContainer } from "@/common/layout/ResponsiveClasses";
import { ExternalCostCodeType } from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/steps/shipping-and-stocking-fees/ExternalMaterialCostCodesProvider";
import { CostCodeFieldsFragment } from "@/generated/graphql";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Row = tw.div`flex flex-row`;

export const useImportExternalCostCodesTableConfiguration = (
  selectedItems?: string[],
): Array<GridCol<ExternalCostCodeType | CostCodeFieldsFragment>> => {
  const intl = useIntl();
  return [
    {
      header: <FormattedMessage id="DESCRIPTION" />,
      wrapper: XlContainer,
      item: ({ item, index, count }) => (
        <SelectableItem
          item={item}
          index={"index" in item ? item.index : index}
          selectedItems={selectedItems ?? []}
          itemNameSelectorFn={(item) =>
            "name" in item ? item.name : item.description
          }
          count={count}
        />
      ),
      sortItemFn: (
        item1: ExternalCostCodeType | CostCodeFieldsFragment,
        item2: ExternalCostCodeType | CostCodeFieldsFragment,
      ) =>
        "name" in item1 && "name" in item2
          ? item1.name.localeCompare(item2.name)
          : "description" in item1 && "description" in item2
            ? item1.description.localeCompare(item2.description)
            : 0,
    },
    {
      header: <FormattedMessage id="CODE" />,
      wrapper: XlContainer,
      item: ({ item }) => (
        <Row>
          {item.code}
          {"collidesWith" in item && !!item.collidesWith ? (
            <Tooltip
              id="cost-code-collides-with"
              element={
                <WarningIcon className="ml-3 h-5 w-5 border-2 text-sm" />
              }
            >
              {intl.$t(
                {
                  id: "COST_CODE_WILL_BE_MERGED",
                },
                {
                  description: item.collidesWith.description,
                },
              )}
            </Tooltip>
          ) : null}
        </Row>
      ),
      sortItemFn: (
        item1: ExternalCostCodeType | CostCodeFieldsFragment,
        item2: ExternalCostCodeType | CostCodeFieldsFragment,
      ) => item1.code.localeCompare(item2.code),
    },
  ];
};
