import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { AdminHeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { FormattedMessage, useIntl } from "react-intl";
import { UserForm } from "./user-form/UserForm";

export const NewUserButton = () => {
  const intl = useIntl();

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <AdminHeaderContainer>
          <PrimaryButton wide onClick={() => togglePanel(true)}>
            <FormattedMessage id="USERS_NEW_USER_BUTTON" />
          </PrimaryButton>
        </AdminHeaderContainer>
      )}
      content={(togglePanel) => (
        <UserForm
          title={intl.$t({ id: "USERS_NEW_USER_TITLE" })}
          onClose={() => togglePanel(false)}
        />
      )}
    />
  );
};
