import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  useSubmitQuoteMutation,
  useWithdrawQuoteMutation,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext } from "react";
import { useParams } from "react-router-dom";

type ProviderContextType = {
  submitBid: () => Promise<boolean>;
  isSubmitting: boolean;
  rejectRfq: () => Promise<boolean>;
  isRejecting: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  submitBid: NoFunctionBooleanPromise,
  isSubmitting: false,
  rejectRfq: NoFunctionBooleanPromise,
  isRejecting: false,
});

export const QuoteActionsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { quoteId: id } = useParams();
  const quoteId = id || "";

  const [submitQuoteMutation, { loading: isSubmitting }] =
    useSubmitQuoteMutation();
  const [withdrawQuoteMutation, { loading: isRejecting }] =
    useWithdrawQuoteMutation();
  const { setError } = useGlobalError();

  const submitBid = async (): Promise<boolean> => {
    try {
      const { errors } = await submitQuoteMutation({
        variables: { input: { quoteId } },
      });
      setError(errors);

      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const rejectRfq = async (): Promise<boolean> => {
    try {
      const { errors } = await withdrawQuoteMutation({
        variables: { input: { quoteId } },
      });
      setError(errors);
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        submitBid,
        isSubmitting,
        rejectRfq,
        isRejecting,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useQuoteActions = (): ProviderContextType =>
  useContext(ProviderContext);
