import {
  DEFAULT_AUXILIARY_ITEM_UOM_MNEMONIC,
  LUMP_SUM_UOM,
} from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import {
  ApproveInvoiceInput,
  ReceiptFieldsFragment,
  UpdateInvoiceInput,
  useReceiptQuery,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApproveReceipt } from "../../receipts/hooks/useApproveReceipt";
import { useUpdateReceipt } from "../../receipts/hooks/useUpdateReceipt";

type ProviderContextType = {
  receipt: ReceiptFieldsFragment | null;
  updateReceipt: (
    input: UpdateInvoiceInput,
    args?: { includeDocuments?: boolean },
  ) => Promise<boolean>;
  approveReceipt: (input: ApproveInvoiceInput) => Promise<boolean>;
  loading: boolean;
  itemized: boolean;
  setItemized: (itemized: boolean) => void;
  hasReleaseRecorded: boolean;
  isItemized: (
    receipt: ReceiptFieldsFragment | null | undefined,
  ) => boolean | null;
};

const ProviderContext = createContext<ProviderContextType>({
  receipt: null,
  updateReceipt: NoFunctionBooleanPromise,
  approveReceipt: NoFunctionBooleanPromise,
  loading: false,
  itemized: false,
  setItemized: NoFunction,
  hasReleaseRecorded: false,
  isItemized: NoFunction,
});

export const ReceiptProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { receiptId } = useParams();

  const { data, loading, error } = useReceiptQuery({
    variables: { id: receiptId || "" },
    skip: !receiptId,
  });

  const isItemized = (receipt: ReceiptFieldsFragment | null | undefined) => {
    if (!receipt) {
      return false;
    }

    const items = receipt?.releaseItemHints?.map((hint) => hint.releaseItem);

    if (!items) {
      return false;
    }

    const additionalCharges = receipt?.release?.additionalCharges;
    const firstItem = items[0];

    const isNonItemized =
      items?.length === 1 &&
      (additionalCharges || []).length === 0 &&
      firstItem.quantityDecimal === "1" &&
      [DEFAULT_AUXILIARY_ITEM_UOM_MNEMONIC, LUMP_SUM_UOM].includes(
        firstItem?.uom?.mnemonic || "",
      );

    return !isNonItemized;
  };

  const [itemized, setItemized] = useState<boolean>(
    data?.receipt?.release ? isItemized(data?.receipt) : false,
  );

  useEffect(() => {
    if (data?.receipt?.release) {
      setItemized(isItemized(data?.receipt));
    }
  }, [data]);

  const { updateReceipt: updateReceiptMutation } = useUpdateReceipt();

  const updateReceipt = async (
    input: UpdateInvoiceInput,
    { includeDocuments }: { includeDocuments?: boolean } = {},
  ): Promise<boolean> => {
    if (receiptId) {
      return await updateReceiptMutation(input, includeDocuments);
    }
    return false;
  };

  const { approveReceipt: approveInvoiceMutation } = useApproveReceipt();
  const approveReceipt = async (
    input: ApproveInvoiceInput,
  ): Promise<boolean> => {
    if (receiptId) {
      return await approveInvoiceMutation(input);
    }
    return false;
  };

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        receipt: data?.receipt || null,
        updateReceipt,
        approveReceipt,
        loading,
        itemized,
        setItemized,
        hasReleaseRecorded: !!data?.receipt.release,
        isItemized,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReceipt = (): ProviderContextType =>
  useContext(ProviderContext);
