import { SequenceStepper } from "@/common/components/sequence-stepper/SequenceStepper";
import { routes } from "@/config/routes";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useReceiptSequence } from "../../../receipts/providers/ReceiptsSequenceProvider";

export const ReceiptSequenceStepper = () => {
  const navigate = useNavigate();
  const { step, setStep, sequenceIds, redirectToReceipts } =
    useReceiptSequence();

  const prevStepCallback = useCallback(() => {
    navigate(
      generatePath(routes.processReceipt, {
        receiptId: sequenceIds[step - 1],
      }),
    );
  }, [navigate, sequenceIds, step]);

  const nextStepCallback = useCallback(() => {
    navigate(
      generatePath(routes.processReceipt, {
        receiptId: sequenceIds[step + 1],
      }),
    );
  }, [navigate, sequenceIds, step]);

  return (
    <SequenceStepper
      titleId="RECEIPTS"
      step={step}
      setStep={setStep}
      sequenceIds={sequenceIds}
      exitSequence={redirectToReceipts}
      prevStepCallback={prevStepCallback}
      nextStepCallback={nextStepCallback}
    />
  );
};
