import { If } from "@/common/components/if/If";
import {
  DistributorInvoiceFieldsFragment,
  InvoiceDocumentFieldsFragment,
  InvoiceFieldsFragment,
  InvoiceType,
  ReceiptFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Tabs = tw.div`mx-6 mt-2`;
const Tab = tw.div`rounded-t-xl flex items-center justify-center border border-white h-10 px-3 mr-1 border-b-0`;
const ActiveTab = tw(Tab)`bg-white text-black`;
const NonActiveTab = tw(Tab)`bg-blue-100 text-gray-600`;

type Props = {
  documents: InvoiceDocumentFieldsFragment[];
  activeDocumentIndex: number;
  setActiveDocumentIndex: (index: number) => void;
  type?: InvoiceType;
  invoice?:
    | InvoiceFieldsFragment
    | null
    | ReceiptFieldsFragment
    | DistributorInvoiceFieldsFragment;
  rescanInvoice?: () => void;
};

export const InvoiceDocumentTabs: FC<Props> = ({
  documents,
  activeDocumentIndex,
  setActiveDocumentIndex,
  type = InvoiceType.Invoice,
}) => {
  const intl = useIntl();

  if (type === InvoiceType.Receipt) {
    return;
  }

  return (
    <Tabs>
      <div className="flex flex-row px-4">
        <If isTrue={documents.length > 1}>
          {documents.map((document, index) => {
            const text =
              index === 0
                ? intl.$t({ id: "NEW_INVOICE" })
                : index === documents.length - 1
                  ? intl.$t({ id: "ORIGINAL_INVOICE" })
                  : intl.$t(
                      { id: "VERSION_HASH" },
                      { version: document.version },
                    );

            return index === activeDocumentIndex ? (
              <ActiveTab
                key={index}
                onClick={() => setActiveDocumentIndex(index)}
              >
                {text}
              </ActiveTab>
            ) : (
              <NonActiveTab
                key={index}
                onClick={() => setActiveDocumentIndex(index)}
              >
                {text}
              </NonActiveTab>
            );
          })}
        </If>
      </div>
    </Tabs>
  );
};
