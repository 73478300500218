import { MAX_QUANTITY_DECIMALS, MIN_QUANTITY_DECIMALS } from "@/common/const";
import { UomFieldsFragment } from "@/generated/graphql";
import React, { FC } from "react";
import { FormattedNumber } from "react-intl";
import tw from "tailwind-styled-components";
import { Price } from "../price/Price";
import { Tooltip } from "../tooltip/Tooltip";

type ValueUnitProps = {
  value: string | number | null | undefined;
  uom?: UomFieldsFragment | null;
  label?: string;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  classNames?: { container?: string; footer?: string };
  error?: boolean;
  includeMnemonic?: boolean;
  mnemonicFirst?: boolean;
  renderAsPrice?: boolean;
};

const Container = tw.div`
  flex flex-col justify-center items-center relative w-full text-sm
  ${(props: { $error?: boolean }) => props.$error && "text-red-500"}
`;

const HeaderContainer = tw.div`
  text-2xs left-3 top-0 w-full
`;

const Header = tw.div`
  text-gray-600 left-1/2 transform -translate-x-1/2
`;

const FooterContainer = tw.div`
  text-[9px] w-full line-clamp-2 text-center relative text-gray-600
`;

export const ValueUnit: FC<ValueUnitProps> = ({
  value,
  uom,
  includeMnemonic,
  mnemonicFirst = true,
  footer = !includeMnemonic
    ? mnemonicFirst
      ? uom?.mnemonic || uom?.pluralDescription
      : uom?.pluralDescription || uom?.mnemonic
    : "",
  header,
  classNames,
  error,
  renderAsPrice,
}) => {
  const val = Number(value);

  if (uom?.mnemonic || footer) {
    return (
      <Tooltip
        element={
          <Container $error={error}>
            <HeaderContainer>
              <Header>{header || null}</Header>
            </HeaderContainer>
            <Container className={classNames?.container}>
              {renderAsPrice ? (
                <Price price={val} />
              ) : (
                <FormattedNumber
                  value={val}
                  maximumFractionDigits={MAX_QUANTITY_DECIMALS}
                  minimumFractionDigits={MIN_QUANTITY_DECIMALS}
                />
              )}{" "}
              {includeMnemonic && uom?.mnemonic}
            </Container>
            <FooterContainer className={classNames?.footer}>
              {footer || null}
            </FooterContainer>
          </Container>
        }
        className="w-full"
        id={`value-unit-${uom?.mnemonic}`}
      >
        {uom?.pluralDescription}
      </Tooltip>
    );
  }

  return (
    <Container $error={error}>
      <HeaderContainer>
        <Header>{header || null}</Header>
      </HeaderContainer>
      <Container>
        <FormattedNumber
          value={val}
          maximumFractionDigits={MAX_QUANTITY_DECIMALS}
        />{" "}
        {mnemonicFirst ? uom?.mnemonic : uom?.pluralDescription}
      </Container>
      <FooterContainer className={classNames?.footer}>
        {footer || null}
      </FooterContainer>
    </Container>
  );
};
