import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { SourceSystemFeatureRequirement } from "@/common/components/integration-feature-requirement/SourceSystemFeatureRequirement";
import { Loader } from "@/common/components/loader/Loader";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { Switch } from "@/common/components/switch/Switch";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { TagItem } from "@/common/components/tag-picker/TagItem";
import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useIsBudgetZoneActive } from "@/contractor/pages/home/project/hooks/useIsBudgetZoneActive";
import { useProjectBudget } from "@/contractor/pages/home/project/pages/project-budget/hooks/useProjectBudget";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Decimal from "decimal.js";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { AmountWithCurrencyTextField } from "../input-components/AmountWithCurrencyTextField";
import { ProjectBudgetManageCostCodes } from "./ProjectBudgetManageCostCodes";
import {
  AddCostCodeButton,
  ButtonContainer,
  Cell,
  CellLabel,
  CellLabelCostCode,
  CellTotal,
  CellWrapper,
  Container,
  CostCodeLabel,
  CostCodesWrapper,
  EmptyInfoWrapper,
  InfoIcon,
  InputsCellWrapper,
  LabelCellWrapper,
  RemoveCostCodeButton,
  Row,
  Spacer,
  SubTitle,
  SubtitleContainer,
  SwitchWrapper,
  TagInputsCellWrapper,
  TagInputsWrapper,
  Title,
  TitleContainer,
  ValueCurrencyWrapper,
  ZoneChip,
  ZonesWrapper,
} from "./ProjectFormBudgetComponents.styled";
import { NewCostCodeAllowance, NewTagAllowance } from "./project-budget/types";
import { useBudgetFormHelpers } from "./project-budget/useBudgetFormHelpers";

export const ProjectFormBudget: FC = () => {
  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();
  const { costCodes: costCodesFullList, formatCostCode } = useCostCodes();
  const { project } = useProject();
  const { budget, updateProjectBudget, importAllowance, importing } =
    useProjectBudget();
  const { isZoneSpecificFn } = useIsBudgetZoneActive();
  const { openDialog } = useDialog();
  const { connectedSourceSystem } = useOrgSettings();

  const {
    prefillTagAllowances,
    prefillCostCodeAllowances,
    getAllowancesByZone,
    getCostCodesGeneralAllowances,
    getUniqueCostCodes,
    convertZoneAllowancesToCostCodeAllowances,
    convertCostCodeAllowancesToZoneAllowances,
  } = useBudgetFormHelpers();

  const methods = useForm<{
    amount: string;
    costCodes: NewCostCodeAllowance[];
    tags: NewTagAllowance[];
    restrictCostCodes?: boolean;
  }>({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    fields: costCodesInputs,
    append: addCostCode,
    remove: removeCostCodes,
    replace: replaceCostCodes,
  } = useFieldArray({
    control: methods.control,
    name: "costCodes",
  });

  const { fields: tagsInputs } = useFieldArray({
    control: methods.control,
    name: "tags",
  });
  const [saving, setSaving] = useState(false);
  const [isCostCodeManagerOpen, setIsCostCodeManagerOpen] = useState(false);
  const watchAll = methods.watch();

  const isByZoneActive = useMemo(
    () =>
      isZoneSpecificFn((watchAll?.costCodes ?? []) as NewCostCodeAllowance[]),
    [isZoneSpecificFn, watchAll],
  );

  const onSave = useCallback(async () => {
    const input = methods.getValues();

    setSaving(true);
    const updatedProjectBudget = {
      amount: input?.amount.toString() || undefined,
      costCodes:
        input?.costCodes.map(({ amount, costCode, zone }) => ({
          amount: amount?.toString() ?? "0",
          costCodeId: costCode.id,
          zoneId: zone?.id,
        })) || [],
      tags:
        input?.tags.map(({ tag: { id }, amount }) => ({
          amount: amount?.toString() ?? "0",
          tagId: id,
        })) || [],
    };

    const result = await updateProjectBudget({
      budgetAllowance: updatedProjectBudget,
      restrictCostCodes: input.restrictCostCodes,
    });
    setSaving(false);

    if (!result) {
      return;
    }

    setSuccessAlert(
      intl.$t(
        {
          id: "PROJECT_BUDGET_UPDATE_SUCCESS",
        },
        { name: strongify(project?.name || "") },
      ),
    );
  }, [methods, updateProjectBudget, setSuccessAlert, intl, project?.name]);

  const init = useCallback(() => {
    if (budget) {
      const tagAllowances = prefillTagAllowances(budget.tags);
      const { costCodeAllowances } = prefillCostCodeAllowances(
        budget?.costCodes as NewCostCodeAllowance[],
      );

      methods.reset({
        amount: budget.amount || "",
        costCodes: costCodeAllowances || [],
        tags: tagAllowances || [],
        restrictCostCodes: project?.restrictCostCodes,
      });
    }
  }, [
    budget,
    methods,
    prefillCostCodeAllowances,
    prefillTagAllowances,
    project?.restrictCostCodes,
  ]);

  const updateCostCodes = useCallback(
    (add: string[], remove: string[]) => {
      let indexesToRemove: number[] = [];
      remove.forEach((costCodeIdToRemove) => {
        costCodesInputs.reduce((acc, costCode, index) => {
          if (costCode.costCode.id === costCodeIdToRemove) {
            indexesToRemove.push(index);
          }
          return acc;
        }, [] as number[]);
      });

      removeCostCodes(indexesToRemove);
      add.forEach((costCodeIdToAdd) => {
        const costCodeToAdd = costCodesFullList.find(
          (costCode) => costCode.id === costCodeIdToAdd,
        );

        if (costCodeToAdd) {
          if (!isByZoneActive) {
            addCostCode({
              costCode: costCodeToAdd,
              amount: "0",
            });
          } else {
            project?.zones.forEach((zone) => {
              addCostCode({
                costCode: costCodeToAdd,
                zone,
                amount: "0",
              });
            });
          }
        }
      });
    },
    [
      addCostCode,
      costCodesFullList,
      costCodesInputs,
      isByZoneActive,
      project?.zones,
      removeCostCodes,
    ],
  );

  useEffect(() => {
    if (budget) {
      init();
    }
  }, [budget, methods, init]);

  const allowancesByZone = useMemo(
    () =>
      getAllowancesByZone((watchAll.costCodes ?? []) as NewCostCodeAllowance[]),
    [getAllowancesByZone, watchAll],
  );

  const costCodesGeneralAllowances = useMemo(
    () =>
      getCostCodesGeneralAllowances(
        (watchAll?.costCodes ?? []) as NewCostCodeAllowance[],
      ),
    [getCostCodesGeneralAllowances, watchAll],
  );

  const uniqueCostCodes = useMemo(
    () =>
      getUniqueCostCodes((watchAll?.costCodes ?? []) as NewCostCodeAllowance[]),
    [getUniqueCostCodes, watchAll],
  );

  const updateIsByZoneActive = useCallback(
    (isActive: boolean) => {
      const newCostCodes = isActive
        ? convertCostCodeAllowancesToZoneAllowances(costCodesInputs)
        : convertZoneAllowancesToCostCodeAllowances(costCodesGeneralAllowances);

      replaceCostCodes(newCostCodes);
    },
    [
      convertCostCodeAllowancesToZoneAllowances,
      convertZoneAllowancesToCostCodeAllowances,
      costCodesGeneralAllowances,
      costCodesInputs,
      replaceCostCodes,
    ],
  );

  const importFromErp = useCallback(async () => {
    if (!project?.id || !connectedSourceSystem) {
      return;
    }
    if (
      await importAllowance({
        projectId: project?.id,
        sourceSystem: connectedSourceSystem,
      })
    ) {
      openDialog({
        content: <SuccessModal message={intl.$t({ id: "IMPORT_COMPLETED" })} />,
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      });
    }
  }, [connectedSourceSystem, importAllowance, intl, openDialog, project?.id]);

  return (
    <>
      <Loader loading={!budget} />
      <If isTrue={budget}>
        <FormProvider {...methods}>
          <Container>
            <Row data-testid="header-row">
              <TitleContainer $span={(project?.zones || []).length > 0}>
                <Title>{intl.$t({ id: "OVERALL_BUDGET_FOR_MATERIALS" })}</Title>
                <If isTrue={(project?.zones || []).length > 0}>
                  <SwitchWrapper>
                    <Switch
                      onLabel={intl.$t({ id: "YES" })}
                      offLabel={intl.$t({ id: "NO" })}
                      className="w-15"
                      onChange={updateIsByZoneActive}
                      value={isByZoneActive}
                    />
                    <FormattedMessage id="ZONE_SPECIFIC" />
                  </SwitchWrapper>
                </If>
              </TitleContainer>

              <div className="rounded-t-lg bg-white">
                <CellWrapper>
                  <Cell>
                    <AmountWithCurrencyTextField name="amount" />
                  </Cell>
                </CellWrapper>
              </div>
            </Row>

            <Row data-testid="cost-codes-row">
              <SubtitleContainer>
                <SubTitle>{intl.$t({ id: "COST_CODES" })}</SubTitle>
                <SourceSystemFeatureRequirement
                  feature={IntegrationFeature.ImportProjectCostCodes}
                >
                  <If
                    isTrue={
                      connectedSourceSystem &&
                      project?.externalProjects.find(
                        (extProject) =>
                          extProject.sourceSystem === connectedSourceSystem,
                      )
                    }
                  >
                    <OutlinedButton
                      $small
                      onClick={importFromErp}
                      className="w-28"
                    >
                      <FormattedMessage
                        id={importing ? "IMPORTING" : "IMPORT_FROM_ERP"}
                      />
                      <Loader className="pl-2" small loading={importing} />
                    </OutlinedButton>
                  </If>
                </SourceSystemFeatureRequirement>
                <SwitchWrapper>
                  <SwitchControlled
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    className="w-15"
                    name="restrictCostCodes"
                  />
                  <FormattedMessage id="LIMIT_PROJECT_TO_COST_CODES" />
                </SwitchWrapper>
              </SubtitleContainer>

              <LabelCellWrapper>
                <Spacer />
                {costCodesGeneralAllowances.map((cc) => (
                  <CellLabelCostCode key={cc.costCode.id}>
                    <CostCodeLabel title={formatCostCode(cc.costCode.id)}>
                      {formatCostCode(cc.costCode.id)}
                    </CostCodeLabel>
                    <RemoveCostCodeButton
                      onClick={() => {
                        const indexesToRemove = costCodesInputs
                          .map(({ costCode }, index) =>
                            costCode.id === cc.costCode.id ? index : null,
                          )
                          .filter((el) => el !== null)
                          .reverse() as number[];

                        indexesToRemove.forEach(removeCostCodes);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </RemoveCostCodeButton>
                  </CellLabelCostCode>
                ))}
                <CellLabelCostCode>
                  <AddCostCodeButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsCostCodeManagerOpen(true);
                    }}
                  >
                    {intl.$t({ id: "ADD_COST_CODE" })} <AddCircleOutlineIcon />
                  </AddCostCodeButton>
                </CellLabelCostCode>
                <CellLabel>{intl.$t({ id: "TOTAL" })}</CellLabel>
              </LabelCellWrapper>

              <CostCodesWrapper>
                <InputsCellWrapper>
                  <Spacer />
                  <If isTrue={costCodesGeneralAllowances.length === 0}>
                    <EmptyInfoWrapper>
                      <InfoIcon />
                      <FormattedMessage id="BUDGET_COST_CODES_EMPTY" />
                    </EmptyInfoWrapper>
                  </If>
                  <If isTrue={costCodesGeneralAllowances.length > 0}>
                    {costCodesGeneralAllowances.map((cc, i) => {
                      const costCodeKey = watchAll.costCodes.findIndex(
                        (costCode) => costCode.costCode.id === cc.costCode.id,
                      );
                      return (
                        <Cell key={i}>
                          {isByZoneActive ? (
                            <ValueCurrencyWrapper>
                              <ValueCurrency value={cc.amount} />
                            </ValueCurrencyWrapper>
                          ) : (
                            <AmountWithCurrencyTextField
                              disabled={isByZoneActive}
                              name={`costCodes.${costCodeKey}.amount`}
                            />
                          )}
                        </Cell>
                      );
                    })}
                    <Cell></Cell>
                    <CellTotal>
                      <ValueCurrencyWrapper>
                        <ValueCurrency
                          value={costCodesGeneralAllowances
                            .reduce(
                              (acc, cc) => acc.add(cc.amount ?? "0"),
                              new Decimal(0),
                            )
                            .toString()}
                        />
                      </ValueCurrencyWrapper>
                    </CellTotal>
                  </If>
                </InputsCellWrapper>

                <If
                  isTrue={
                    isByZoneActive && costCodesGeneralAllowances.length > 0
                  }
                >
                  <ZonesWrapper>
                    {Object.entries(allowancesByZone).map(
                      ([zoneId, allowances]) => (
                        <InputsCellWrapper key={zoneId}>
                          <ZoneChip
                            label={
                              project?.zones.find((z) => z.id === zoneId)?.name
                            }
                          />

                          {allowances.map((cc) => (
                            <Cell key={cc.costCode.id}>
                              <AmountWithCurrencyTextField
                                name={`costCodes.${cc.index}.amount`}
                              />
                            </Cell>
                          ))}
                          <Cell></Cell>
                          <CellTotal>
                            <ValueCurrencyWrapper>
                              <ValueCurrency
                                value={(watchAll?.costCodes ?? [])
                                  .filter((el) => el.zone?.id === zoneId)
                                  .reduce(
                                    (acc, cc) => acc.add(cc.amount ?? "0"),
                                    new Decimal(0),
                                  )
                                  .toString()}
                              />
                            </ValueCurrencyWrapper>
                          </CellTotal>
                        </InputsCellWrapper>
                      ),
                    )}
                  </ZonesWrapper>
                </If>
              </CostCodesWrapper>
            </Row>

            <Row data-testid="tags-row">
              <SubTitle>{intl.$t({ id: "TAGS" })}</SubTitle>

              <LabelCellWrapper>
                {tagsInputs.map(({ tag }) => (
                  <CellLabel key={tag.id}>
                    <TagItem tag={tag} />
                  </CellLabel>
                ))}
              </LabelCellWrapper>

              <TagInputsWrapper>
                <TagInputsCellWrapper>
                  <If isTrue={tagsInputs.length === 0}>
                    <EmptyInfoWrapper>
                      <InfoIcon />
                      <FormattedMessage id="BUDGET_TAGS_EMPTY" />
                    </EmptyInfoWrapper>
                  </If>
                  {tagsInputs.map(({ tag }, i) => (
                    <Cell key={tag.id}>
                      <AmountWithCurrencyTextField name={`tags.${i}.amount`} />
                    </Cell>
                  ))}
                </TagInputsCellWrapper>
              </TagInputsWrapper>
            </Row>

            <FloatingFooter>
              <ButtonContainer>
                <OutlinedButton wide onClick={init}>
                  <FormattedMessage id="CANCEL" />
                </OutlinedButton>
                <PrimaryButton
                  onClick={methods.handleSubmit(onSave)}
                  type="submit"
                  loading={saving}
                  wide
                  testId="save-project"
                >
                  <FormattedMessage id="SAVE" />
                </PrimaryButton>
              </ButtonContainer>
            </FloatingFooter>
          </Container>

          <ProjectBudgetManageCostCodes
            selectedCostCodes={uniqueCostCodes.map((el) => el.id)}
            onSave={updateCostCodes}
            setVisible={setIsCostCodeManagerOpen}
            visible={isCostCodeManagerOpen}
          />
        </FormProvider>
      </If>
    </>
  );
};
