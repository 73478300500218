const DAYS_MILLISECONDS = 1000 * 3600 * 24;

export const dateDiffInDays = (
  timestamp?: number | null,
  timestampToCompare?: number | null,
) => {
  if (!timestamp) {
    return;
  }
  const today = timestampToCompare ? new Date(timestampToCompare) : new Date();
  const diffInTime = new Date(timestamp).getTime() - today.getTime();
  const diffInDays = Math.ceil(diffInTime / DAYS_MILLISECONDS);
  return Math.abs(diffInDays);
};
