import { TOTAL_PRICE_DECIMAL_POINTS } from "@/common/const";
import { IntlShape } from "react-intl";

const EMPTY_STRING = "-";

export const formatCSVCurrency = (
  value: string | null | undefined,
  intl: IntlShape,
) => {
  return (
    intl
      .formatNumber(Number(value), {
        minimumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
        maximumFractionDigits: TOTAL_PRICE_DECIMAL_POINTS,
      })
      .replaceAll(",", "") || EMPTY_STRING
  );
};
