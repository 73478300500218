import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { GridViewToggle } from "@/common/components/grid-view-toggle/GridViewToggle";
import { If } from "@/common/components/if/If";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { VIEW_STATE, useTableView } from "@/common/providers/TableViewProvider";
import { checkBuyoutStatus } from "@/common/utils/status-checks/checkBuyoutStatus";
import { routes } from "@/config/routes";
import { useCreateRelease } from "@/contractor/pages/home/release/hooks/useCreateRelease";
import { BuyoutStatus } from "@/generated/graphql";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutGrouped } from "../providers/BuyoutGroupedProvider";
import { SubmitBuyoutDialog } from "../providers/submit-buyout-dialog/SubmitBuyoutDialog";
import { useSyncBuyoutItems } from "../providers/SyncBuyoutItemsProvider";
import { useBuyoutMutations } from "../providers/useBuyoutMutations";

const DeliveryWrapper = tw.div`flex flex-col items-end`;
const Item = tw.div`flex`;
const FooterContent = tw.div`grid grid-cols-[80px_auto_1fr_auto] gap-4`;

type BuyoutActionsProps = {
  spreadsheetData: Record<string, string>[];
};

export const BuyoutActions: FC<BuyoutActionsProps> = ({ spreadsheetData }) => {
  const { buyout, updateBuyout } = useContractorBuyout();
  const { isEditable, setIsEditable } = useBuyoutGrouped();
  const { syncBuyoutItems, saving } = useSyncBuyoutItems();
  const { cancelBuyout, submitBuyout, submitting } = useBuyoutMutations();
  const { createReservedRelease, creating } = useCreateRelease();
  const navigate = useNavigate();
  const { rowIsEmpty } = useTableHelpers();
  const { tableView, setTableView } = useTableView();
  const [updating, setUpdating] = useState(false);
  const { openDialog } = useDialog();
  const intl = useIntl();
  const [submitDialogVisible, setSubmitDialogVisible] = useState(false);

  const isSendToVendorEnabled = useMemo(() => {
    return (
      (buyout && buyout.items.length > 0) ||
      (spreadsheetData.length > 0 &&
        spreadsheetData.some((row) => !rowIsEmpty(row)))
    );
  }, [buyout, spreadsheetData, rowIsEmpty]);

  const handleMakeChanges = useCallback(() => {
    setIsEditable(!isEditable);
  }, [isEditable, setIsEditable]);

  const handleRequestDelivery = useCallback(async () => {
    if (buyout?.id) {
      const result = await createReservedRelease({
        buyoutId: buyout.id,
        typeId: buyout.releaseType.id,
      });
      if (result) {
        navigate(
          generatePath(routes.specifyDeliveryDetails, {
            deliveryId: result.id,
          }),
        );
      }
    }
  }, [buyout?.id, buyout?.releaseType.id, createReservedRelease, navigate]);

  if (!buyout) {
    return null;
  }

  const handleCancelBuyout = async () => {
    await cancelBuyout({ buyoutId: buyout.id, version: buyout.version });
    navigate(routes.buyouts);
  };

  const handleSubmitBuyout = async (skip?: boolean) => {
    setUpdating(true);
    if (buyout.status === BuyoutStatus.Requested) {
      await updateBuyout(undefined, true);
      setIsEditable(!isEditable);
      setUpdating(false);
    } else {
      const synced = await syncBuyoutItems();

      if (!synced) {
        setUpdating(false);
        return;
      }
      const result = await submitBuyout({
        buyoutId: buyout.id,
        skipConfirmation: skip,
      });
      setUpdating(false);
      if (!result) {
        return;
      }
      setTableView(VIEW_STATE.normal);
      setIsEditable(false);
    }
    setSubmitDialogVisible(false);
  };

  return (
    <DeliveryWrapper>
      <If isTrue={buyout.status !== BuyoutStatus.Depleted}>
        <FloatingFooter>
          <FooterContent>
            <span />
            <If isTrue={buyout.status !== BuyoutStatus.Draft}>
              <span />
            </If>
            <If isTrue={buyout.status === BuyoutStatus.Draft}>
              <GridViewToggle saveState={syncBuyoutItems} saving={saving} />
            </If>
            <Item>
              <ButtonsContainer>
                <If isTrue={buyout.status !== BuyoutStatus.Cancelled}>
                  <OutlinedButton
                    onClick={() =>
                      openDialog({
                        cancelButtonText: intl.$t({ id: "CANCEL" }),
                        confirmButtonText: intl.$t({ id: "PROCEED" }),
                        icon: <WarningIcon />,
                        title: intl.$t(
                          {
                            id: "CONFIRM_CANCEL_BUYOUT_WO_QUOTE_QUESTION",
                          },
                          { buyoutIdentifier: buyout.clientIdentifier },
                        ),
                        text: intl.$t({
                          id: checkBuyoutStatus(buyout, [
                            BuyoutStatus.Active,
                            BuyoutStatus.Requested,
                          ])
                            ? "CONFIRM_CANCEL_ACTIVE_BUYOUT_WO_QUOTE_QUESTION_TEXT"
                            : "CONFIRM_CANCEL_BUYOUT_WO_QUOTE_QUESTION_TEXT",
                        }),
                        handleConfirm: handleCancelBuyout,
                      })
                    }
                  >
                    <FormattedMessage id="CANCEL_RFQ_BUYOUT" />
                  </OutlinedButton>
                </If>
                <If isTrue={buyout.status === BuyoutStatus.Draft}>
                  <If isTrue={tableView === VIEW_STATE.spreadsheet}>
                    <OutlinedButton onClick={syncBuyoutItems}>
                      <FormattedMessage id="SAVE" />
                    </OutlinedButton>
                  </If>
                  <PrimaryButton
                    disabled={!isSendToVendorEnabled}
                    onClick={() => setSubmitDialogVisible(true)}
                    testId="submit-changes-or-specify-release-details"
                    loading={updating}
                  >
                    <FormattedMessage id="BUYOUT_SEND_TO_VENDOR" />
                  </PrimaryButton>
                </If>
                <If isTrue={buyout.status !== BuyoutStatus.Draft}>
                  <If
                    isTrue={
                      buyout.status !== BuyoutStatus.Cancelled &&
                      buyout.status !== BuyoutStatus.Active
                    }
                  >
                    <OutlinedButton onClick={handleMakeChanges}>
                      <FormattedMessage
                        id={isEditable ? "CANCEL_CHANGES" : "MAKE_CHANGES"}
                      />
                    </OutlinedButton>
                  </If>
                  <If isTrue={isEditable}>
                    <PrimaryButton
                      disabled={!isSendToVendorEnabled}
                      onClick={() => handleSubmitBuyout()}
                      loading={updating}
                    >
                      <FormattedMessage id="BUYOUT_SUBMIT_CHANGES" />
                    </PrimaryButton>
                  </If>
                </If>
                <If isTrue={buyout.status === BuyoutStatus.Active}>
                  <OutlinedButton
                    onClick={handleRequestDelivery}
                    loading={creating}
                  >
                    <FormattedMessage id="CREATE_DELIVERY" />
                  </OutlinedButton>
                </If>
              </ButtonsContainer>
            </Item>
          </FooterContent>
        </FloatingFooter>
      </If>
      <SubmitBuyoutDialog
        visible={submitDialogVisible}
        setVisible={setSubmitDialogVisible}
        callback={handleSubmitBuyout}
        saving={submitting || updating || saving}
      />
    </DeliveryWrapper>
  );
};
