import { useGlobalError } from "@/common/hooks/useGlobalError";
import { cleanQuery } from "@/common/utils/cacheUtils";
import {
  CreateInvoicesInput,
  namedOperations,
  useCreateInvoicesMutation,
} from "@/generated/graphql";

export const useCreateInvoices = () => {
  const [createInvoicesMutation, { loading }] = useCreateInvoicesMutation();
  const { setError } = useGlobalError();

  const createInvoices = async (input: CreateInvoicesInput) => {
    try {
      const { data } = await createInvoicesMutation({
        variables: {
          input,
        },
        update: (cache) => cleanQuery(cache, namedOperations.Query.Invoices),
      });

      return data?.createInvoices;
    } catch (error) {
      setError(error);
    }
  };

  return { createInvoices, loading };
};
