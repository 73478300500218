import { If } from "@/common/components/if/If";
import { useSourceSystemLogo } from "@/common/hooks/integrations/source-system-config/useSourceSystemLogo";
import { ExternalPoBaseFieldsFragment } from "@/generated/graphql";
import { Identity } from "@/types/Identity";
import { FC } from "react";
import { Image, ReadonlyField, StretchRow } from "./ExternalPO.styles";

type Props = {
  item: ExternalPoBaseFieldsFragment & Identity;
};

export const ExternalPOProjects: FC<Props> = ({ item }) => {
  const { sourceSystemLogo } = useSourceSystemLogo(item.sourceSystem, true);

  return (
    <StretchRow>
      <ReadonlyField>{item.project?.name}</ReadonlyField>
      <If isTrue={item.project && item.project.project}>
        <Image loading="lazy" src={sourceSystemLogo} alt="logo" />
      </If>
    </StretchRow>
  );
};
