import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { SelectableItem } from "@/common/components/searchable-list/components/SelectableItem";
import { XlContainer } from "@/common/layout/ResponsiveClasses";
import {
  ExternalItemFieldsFragment,
  OrgMaterialFieldsFragment,
} from "@/generated/graphql";
import { FormattedMessage } from "react-intl";

export const useExternalItemsTableConfiguration = (
  selectedItems?: string[],
): Array<GridCol<ExternalItemFieldsFragment | OrgMaterialFieldsFragment>> => {
  const { page, pageSize } = usePagination();
  return [
    {
      header: <FormattedMessage id="ITEM_DESCRIPTION" />,
      wrapper: XlContainer,
      item: ({ item, index, count }) => (
        <SelectableItem
          item={item}
          index={page * pageSize + (index ?? 0)}
          selectedItems={selectedItems ?? []}
          itemNameSelectorFn={(item) =>
            "name" in item ? item.name : item.material.name
          }
          count={count}
        />
      ),
    },
    {
      header: <FormattedMessage id="PURCHASING_UOM" />,
      wrapper: XlContainer,
      item: ({ item }) => (
        <div className="flex flex-row">
          {"purchasingUom" in item
            ? (item as ExternalItemFieldsFragment).purchasingUom
            : (item as OrgMaterialFieldsFragment).defaultEstimateUom
                .pluralDescription}
        </div>
      ),
    },
    {
      header: <FormattedMessage id="DEFAULT_COST_CODE" />,
      wrapper: XlContainer,
      item: ({ item }) => (
        <div className="flex flex-row">
          {"defaultCostCode" in item
            ? (item as ExternalItemFieldsFragment).defaultCostCode
            : (item as OrgMaterialFieldsFragment).costCode?.description}
        </div>
      ),
    },
  ];
};
