import { FC } from "react";
import { useIntl } from "react-intl";
import { SelectControlled } from "../select/components/single/SelectControlled";
import { useCurrencies } from "./hooks/useCurrencies";
import { formatCurrencyName } from "./utils/formatCurrencyName";

type Props = {
  name: string;
};

export const CurrencyPickerControlled: FC<Props> = ({ name }) => {
  const { currencies, loading } = useCurrencies();
  const intl = useIntl();

  return (
    <SelectControlled
      name={name}
      label={intl.$t({ id: "CURRENCY" })}
      options={currencies}
      loading={loading}
      getLabel={(option) => formatCurrencyName(option)}
      getValue={(option) => option.id}
    />
  );
};
