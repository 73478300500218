export const NoFunction = () => null;
export const NoFunctionBoolean = () => false;
export const NoFunctionUndefined = () => undefined;
export const NoFunctionPromise = () => Promise.resolve();
export const NoFunctionBooleanPromise = () => Promise.resolve(false);
export const NoFunctionUndefinedPromise = () => Promise.resolve(undefined);
export const NoFunctionStringPromise = () => Promise.resolve("");
export const NoFunctionArrayPromise = () => Promise.resolve([]);
export const EmptyValidationFunction = (
  _: unknown,
  callback: (valid: boolean) => void,
) => callback(true);
