import { useCallback } from "react";

export const useLocalStorage = () => {
  const readValue = useCallback(
    <T>(key: string, defaultValue: T | null = null) => {
      const value = window.localStorage.getItem(key);
      if (value) {
        try {
          return JSON.parse(value) as T;
        } catch {
          return value;
        }
      }
      return defaultValue;
    },
    [],
  );

  const setValue = useCallback(<T>(key: string, value: T) => {
    if (value !== null && value !== undefined) {
      window.localStorage.setItem(key, JSON.stringify(value));
    } else {
      window.localStorage.removeItem(key);
    }
  }, []);

  const removeValue = useCallback((key: string) => {
    window.localStorage.removeItem(key);
  }, []);

  return {
    readValue,
    setValue,
    removeValue,
  };
};
