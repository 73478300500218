import { Dialog } from "@/common/components/dialog/Dialog";
import { TextField } from "@/common/components/textfield/TextField";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Fuse from "fuse.js";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  grid grid-cols-[1fr_1fr] items-start justify-center min-w-screen-xl
`;

const Column = tw.div`
  flex flex-col items-start gap-4 p-8 max-h-[500px] rounded-md h-full pb-4
`;

const SelectedColumn = tw.div`
  flex flex-col items-start gap-4 p-8 max-h-[500px] rounded-md h-full pb-4 bg-gray-100
`;

const CodesList = tw.ul`
  flex flex-col overflow-y-auto gap-2 w-full
`;

const Header = tw.div`text-black font-roboto text-sm font-bold`;

const AvailableCodesListItem = tw.li`flex items-center justify-between h-9 p-2 rounded-md bg-gray-100 text-black font-roboto text-sm font-normal w-full cursor-pointer hover:bg-blue-100`;

const SelectedCodesListItem = tw.li`flex items-center justify-between h-9 p-2 rounded-md bg-white text-black font-roboto text-sm font-normal w-full cursor-pointer hover:bg-blue-100`;

const AddSign = tw(AddIcon)`text-blue-500`;

const CloseSign = tw(CloseIcon)`text-blue-500`;

type ProjectBudgetManageCostCodesProps = {
  selectedCostCodes?: Array<string>;
  onSave: (add: string[], remove: string[]) => void;
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
};

export const ProjectBudgetManageCostCodes: FC<
  ProjectBudgetManageCostCodesProps
> = ({ selectedCostCodes = [], onSave, visible = true, setVisible }) => {
  const intl = useIntl();
  const { costCodes } = useCostCodes();
  const [updatedSelectedCostCodes, setUpdatedSelectedCostCodes] =
    useState(selectedCostCodes);
  const [filteredCostCodes, setFilteredCostCodes] = useState(costCodes);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fuse = new Fuse(costCodes, {
      keys: ["description"],
      threshold: 0.3,
    });
    const filteredCostCodes = search
      ? fuse.search(search).map((result) => result.item)
      : costCodes;
    setFilteredCostCodes(filteredCostCodes);
  }, [costCodes, search]);

  useEffect(() => {
    if (visible) {
      setUpdatedSelectedCostCodes(selectedCostCodes);
    }
  }, [selectedCostCodes, visible]);

  const handleCancel = useCallback(() => {
    setVisible?.(false);
    setUpdatedSelectedCostCodes(selectedCostCodes);
  }, [selectedCostCodes, setVisible]);

  const handleConfirm = useCallback(() => {
    const prevSelectedCostCodes = selectedCostCodes;
    const changes = {
      add: updatedSelectedCostCodes.filter(
        (el) => prevSelectedCostCodes.indexOf(el) < 0,
      ),
      remove: prevSelectedCostCodes.filter(
        (el) => updatedSelectedCostCodes.indexOf(el) < 0,
      ),
    };
    onSave(changes.add, changes.remove);
    setVisible?.(false);
  }, [onSave, selectedCostCodes, setVisible, updatedSelectedCostCodes]);

  return (
    <Dialog
      title=""
      hideTitle
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({ id: "ADD" })}
      buttonsConfiguration={{
        cancel: {
          wide: true,
        },
      }}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      maxWidth="xl"
      show={visible}
      sx={{
        ".MuiDialogContent-root": {
          padding: "0",
        },
        ".MuiDialogActions-root": {
          padding: "0",
          marginTop: "0",
        },
      }}
      content={
        <Container>
          <Column>
            <Header>
              <FormattedMessage id={"ADD_COST_CODES"} />
            </Header>
            <TextField
              label={intl.$t({ id: "SEARCH" })}
              onChange={(e) => setSearch(e.target.value)}
            />
            <CodesList>
              {filteredCostCodes
                .filter((el) => updatedSelectedCostCodes.indexOf(el.id) < 0)
                .map((costCode) => (
                  <AvailableCodesListItem
                    key={costCode.id}
                    onClick={() => {
                      setUpdatedSelectedCostCodes([
                        ...updatedSelectedCostCodes,
                        costCode.id,
                      ]);
                    }}
                  >
                    {costCode.formatted}
                    <AddSign />
                  </AvailableCodesListItem>
                ))}
            </CodesList>
          </Column>

          <SelectedColumn>
            <Header>
              <FormattedMessage id={"SELECTED"} />
            </Header>
            <CodesList>
              {updatedSelectedCostCodes.map((costCode) => (
                <SelectedCodesListItem
                  key={costCode}
                  onClick={() => {
                    setUpdatedSelectedCostCodes(
                      updatedSelectedCostCodes.filter((el) => el !== costCode),
                    );
                  }}
                >
                  {costCodes.find((el) => el.id === costCode)?.formatted ??
                    intl.$t({ id: "NOT_FOUND" })}
                  <CloseSign />
                </SelectedCodesListItem>
              ))}
            </CodesList>
          </SelectedColumn>
        </Container>
      }
    />
  );
};
