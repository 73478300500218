import { routes } from "@/config/routes";
import {
  ReleasePartialFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { generatePath } from "react-router-dom";

type ReleaseType = Pick<
  ReleasePartialFieldsFragment,
  "id" | "status" | "itemCount"
> & {
  quoteDocument?: { id: string } | null;
  noteDocument?: { id: string } | null;
};

export const useReleaseNavigation = () => {
  const getReleaseEditPath = (release: ReleaseType) => {
    let path;
    if (release.status === ReleaseStatus.Draft && release.quoteDocument) {
      path = routes.editDeliveryFromQuote;
    } else if (
      (release.status === ReleaseStatus.Draft ||
        release.status === ReleaseStatus.AwaitingApproval) &&
      release.noteDocument &&
      !release.itemCount
    ) {
      path = routes.editDeliveryFromNote;
    } else {
      path = routes.specifyDeliveryDetails;
    }

    return generatePath(path, {
      deliveryId: release.id,
      noteDocumentId: release.noteDocument?.id,
      quoteDocumentId: release.quoteDocument?.id,
    });
  };

  return { getReleaseEditPath };
};
