import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  UpdateDeliverySlipInput,
  useReplaceDeliverySlipMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useReplaceDeliverySlip = () => {
  const { setError } = useGlobalError();

  const [replaceDeliverySlipMutation, { loading }] =
    useReplaceDeliverySlipMutation();
  const replaceDeliverySlip = useCallback(
    async (input: UpdateDeliverySlipInput) => {
      try {
        const { data, errors } = await replaceDeliverySlipMutation({
          variables: {
            input,
          },
        });

        setError(errors);
        return data?.updateDeliverySlip;
      } catch (error) {
        setError(error);
        return null;
      }
    },
    [replaceDeliverySlipMutation, setError],
  );

  return { replaceDeliverySlip, loading };
};
