import { DrawerView } from "@/common/components/panel/DrawerView";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { UploadAsset } from "@/common/components/upload-asset/UploadAsset";
import { useUploadAssets } from "@/common/components/upload-asset/UploadAssetProvider";
import {
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPE,
} from "@/common/components/upload/FileUploadArea";
import { ImageUploaderWithCropping } from "@/common/components/upload/ImageUploaderWithCropping";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { AssetFieldsFragment } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useUpdateVendor } from "../../hooks/useUpdateVendor";
const OverlayPanelStyled = tw(OverlayPanel)`px-0 pt-0`;
const TitleContainer = tw.div`flex gap-2 items-center`;
const Header = tw.div`flex justify-between pb-2`;
const Label = tw.div`flex gap-2 items-center`;
const Container = tw.div`flex flex-col w-full h-full p-6`;
const StyledTooltip = tw(Tooltip)`text-blue-500`;

type NewLogoDrawerProps = {
  isOpen: boolean;
  orgId: string;
  setIsOpen: (value: boolean) => void;
};

export const NewOrgLogoDrawer: FC<NewLogoDrawerProps> = ({
  isOpen,
  orgId,
  setIsOpen,
}) => {
  const intl = useIntl();
  const { setSuccessAlert } = useSnackbar();
  const { setError } = useGlobalError();
  const { uploadAssets, uploading } = useUploadAssets();
  const { updateVendorOrg } = useUpdateVendor();

  const [uploadedAsset, setUploadedAsset] =
    useState<AssetFieldsFragment | null>(null);

  const handleLogoUpload = useCallback(
    async (logo: File) => {
      try {
        const uploadResult = await uploadAssets([logo]);
        setUploadedAsset(uploadResult?.[0] ?? "");
      } catch (error) {
        setError(error);
      }
    },
    [uploadAssets, setError],
  );

  const handleLogoUpdate = useCallback(async () => {
    const result = await updateVendorOrg({
      id: orgId,
      photoUrl: uploadedAsset?.url,
    });
    if (result) {
      setSuccessAlert(intl.$t({ id: "VENDOR_ORG_LOGO_UPDATE_SUCCESS" }));
      setIsOpen(false);
    }
  }, [intl, orgId, uploadedAsset, setIsOpen, setSuccessAlert, updateVendorOrg]);

  return (
    <DrawerView
      isOpened={isOpen}
      setIsOpened={setIsOpen}
      content={() => (
        <OverlayPanelStyled
          title={
            <TitleContainer>
              <FormattedMessage id="LOGO_UPLOAD_TITLE" />
            </TitleContainer>
          }
          onCancel={() => setIsOpen(false)}
          onSave={handleLogoUpdate}
          isLoading={uploading}
          disableSave={!uploadedAsset}
        >
          <Container>
            <Header>
              <Label>
                <FormattedMessage id="LOGO" />
              </Label>
              <StyledTooltip
                id="add-preferred-vendor-information"
                position="bottom"
                element={<InfoOutlined />}
              >
                <FormattedMessage id="LOGO_UPLOAD_INFORMATION" />
              </StyledTooltip>
            </Header>
            <ImageUploaderWithCropping
              save={handleLogoUpload}
              forceSquare
              disabled={!!uploadedAsset}
              uploadArea={
                <UploadAsset
                  className="pointer-events-none cursor-grab"
                  onChange={(urls) => {
                    if (urls.length === 0) {
                      setUploadedAsset(null);
                    }
                  }}
                  multiple={false}
                  accept={{ [IMAGE_MIME_TYPE]: IMAGE_EXTENSIONS }}
                />
              }
            />
          </Container>
        </OverlayPanelStyled>
      )}
    />
  );
};
