import { useUser } from "@/common/providers/UserProvider";
import { ErrorOutline } from "@mui/icons-material";
import { FC, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { NumberFormatValues } from "react-number-format";
import tw from "tailwind-styled-components";
import { ControlledProps } from "../textfield-controlled/TextFieldControlled";
import { TextFieldProps } from "../textfield/TextField";
import { Tooltip } from "../tooltip/Tooltip";
import { NumericFormat } from "./NumericFormat";

type CustomTextFieldProps = ControlledProps &
  TextFieldProps & {
    includeCurrency?: boolean;
    isAllowed?: (values: NumberFormatValues) => boolean;
    defaultValue?: unknown;
    emptyValueFiller?: string;
    useWatchValue?: boolean;
  };

const ErrorContainer = tw.span<{ $hasEndAdornment: boolean }>`absolute top-2.5
  ${(props) => (props.$hasEndAdornment ? "right-8" : "right-2")}
`;
const ErrorOutlineStyled = tw(ErrorOutline)`
    print:hidden text-base z-50
  `;

export const NumericalInputControlled: FC<CustomTextFieldProps> = ({
  name,
  rules,
  includeCurrency,
  defaultValue,
  useWatchValue,
  ...props
}) => {
  const { currencySymbol } = useUser();
  const prefix = useMemo(
    () => (includeCurrency ? currencySymbol : undefined),
    [includeCurrency, currencySymbol],
  );
  const { control, watch } = useFormContext();
  const formState = control.getFieldState(name);

  return (
    <Controller
      name={name as string}
      rules={rules}
      control={control}
      render={({ field }) => {
        return (
          <NumericFormat
            {...field}
            value={useWatchValue ? watch(name) : field.value}
            {...props}
            testId={name}
            error={!!formState.invalid}
            classes={{
              root: "w-full",
            }}
            prefix={props.prefix ?? prefix}
            size={undefined}
            type="text"
            defaultValue={defaultValue || field.value?.toString()}
            onChange={(value: unknown) => {
              field.onChange(value);
            }}
            helperText={
              formState.invalid &&
              formState.error?.message && (
                <ErrorContainer
                  $hasEndAdornment={!!props.InputProps?.endAdornment}
                >
                  <Tooltip
                    element={<ErrorOutlineStyled color="error" />}
                    id="error-popover"
                  >
                    <>{formState.error?.message || ""}</>
                  </Tooltip>
                </ErrorContainer>
              )
            }
            FormHelperTextProps={{ className: "m-0" }}
          />
        );
      }}
    />
  );
};
