import { PaginationContainer } from "@/common/components/pagination/Pagination.styles";
import { FC, PropsWithChildren, ReactNode } from "react";
import { If } from "../if/If";
import { NotNullableRenderer } from "../not-nullable-renderer/NotNullableRenderer";
import { LocalPagination } from "../pagination-local/LocalPagination";
import { useLocalPagination } from "../pagination-local/LocalPaginationProvider";

type Props = PropsWithChildren<{
  hasItemsCondition?: unknown;
  emptyList?: string | ReactNode;
  count?: number;
  totalCount?: number;
  paginated?: boolean;
}>;

export const LocalListRenderer: FC<Props> = ({
  hasItemsCondition = true,
  emptyList,
  children,
  totalCount = 0,
  count = 0,
  paginated = false,
}) => {
  const { page, nextPage, previousPage, pageSize } = useLocalPagination();

  return (
    <NotNullableRenderer
      value={hasItemsCondition}
      defaultValue={emptyList ?? ""}
    >
      {children}
      <If isTrue={paginated && totalCount > pageSize}>
        <PaginationContainer>
          <LocalPagination
            count={count}
            total={totalCount}
            page={page}
            itemsPerPage={pageSize}
            nextPage={nextPage}
            previousPage={previousPage}
          />
        </PaginationContainer>
      </If>
    </NotNullableRenderer>
  );
};
