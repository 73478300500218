import { BulkZoneSelector } from "@/common/components/zone-selector/BulkZoneSelector";
import { UpdateEstimatedItemInput } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";
import { useProject } from "../../providers/ProjectProvider";

type Props = {
  setLoading: (loading: boolean) => void;
};

export const ProjectBulkZoneSelector: FC<Props> = ({ setLoading }) => {
  const {
    selectedEstimatedItemIds,
    setSelectedEstimatedItemIds,
    updateEstimatedItems,
  } = useEstimatedItems();
  const { project } = useProject();

  const updateZone = useCallback(
    async (zone: string | null) => {
      if (!project) {
        return;
      }
      const itemsToUpdate: UpdateEstimatedItemInput[] =
        selectedEstimatedItemIds.map((item) => ({
          id: item,
          zoneId: zone,
        }));

      await updateEstimatedItems({
        projectId: project?.id,
        updates: itemsToUpdate,
      });

      setSelectedEstimatedItemIds([]);
    },
    [
      project,
      selectedEstimatedItemIds,
      setSelectedEstimatedItemIds,
      updateEstimatedItems,
    ],
  );

  return (
    <BulkZoneSelector
      setLoading={setLoading}
      count={selectedEstimatedItemIds.length}
      update={updateZone}
    />
  );
};
