import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { useProjectMaps } from "@/contractor/pages/home/project/hooks/useProjectMaps";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import { useSpendingReportItems } from "@/contractor/pages/home/project/providers/ProjectSpendingReportItemsProvider";
import {
  BUDGET_VIEW_TYPE,
  useSpendingReport,
} from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { FC, useMemo } from "react";
import { PiFileCsvDuotone } from "react-icons/pi";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { budgetTotalCSV } from "./csv-report/budgetTotalCSV";
import { materialsCSVBudgetReport } from "./csv-report/materialsCSVBudgetReport";
import { vendorsCSVBudgetReport } from "./csv-report/vendorsCSVBudgetReport";
const IconButtonStyled = tw(IconButtonBorderless)`
  text-blue-500 w-fit mx-2 hover:cursor-pointer
`;
const PiFileCsvDuotoneStyled = tw(PiFileCsvDuotone)`text-xl`;

const ButtonText = tw.div`flex gap-1 items-center text-blue-500 hover:text-blue-300`;
const Label = tw.div`text-xs hidden lg:block`;

export const BudgetExportButton: FC = () => {
  const intl = useIntl();
  const { project } = useProject();
  const { zones } = useSpendingReportItems();
  const { viewType, vendorsReport, materialsReport, zonesReport } =
    useSpendingReport();
  const { materialsMap } = useProjectMaps(project);

  const selectedReport = useMemo(() => {
    switch (viewType) {
      case BUDGET_VIEW_TYPE.VENDORS:
        return vendorsReport;
      case BUDGET_VIEW_TYPE.MATERIALS:
        return materialsReport;
      case BUDGET_VIEW_TYPE.ZONES:
        return zonesReport;
      default:
        return zonesReport;
    }
  }, [viewType, materialsReport, zonesReport, vendorsReport]);

  const saveCSV = () => {
    if (project) {
      const csv = [] as string[][];
      switch (viewType) {
        case BUDGET_VIEW_TYPE.VENDORS:
          vendorsCSVBudgetReport(csv, intl, zones);
          break;
        case BUDGET_VIEW_TYPE.MATERIALS:
        case BUDGET_VIEW_TYPE.ZONES:
          materialsCSVBudgetReport(csv, intl, zones, materialsMap);
          break;
      }
      if (selectedReport) {
        budgetTotalCSV(csv, intl, selectedReport);
      }
      downloadCSV(
        csv.join("\n"),
        intl.$t({ id: "SPENDING_REPORT_CSV_NAME" }, { name: project.name }),
      );
    }
  };

  const downloadCSV = (csv: string, filename: string) => {
    const csvFile = new Blob([csv], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  return (
    <IconButtonStyled onClick={saveCSV}>
      <ButtonText>
        <FormattedMessage tagName={Label} id="EXPORT" />
        <PiFileCsvDuotoneStyled />
      </ButtonText>
    </IconButtonStyled>
  );
};
