import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { routes } from "@/config/routes";
import { Footer } from "@/distributor/common/components/Footer";
import { ReleaseStatus } from "@/generated/graphql";
import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { checkReleaseStatus } from "../../../../../../common/utils/status-checks/checkReleaseStatus";
import { DistributorReleaseAdditionalChargesAndTaxes } from "../../../components/distributor-release-additional-charges-and-taxes/DistributorReleaseAdditionalChargesAndTaxes";
import { useDistributorRelease } from "../../../providers/DistributorReleaseProvider";
import { useDistributorReleaseDeliveryRecord } from "../providers/DistributorReleaseDeliveryRecordProvider";

const FooterContainer = tw(ButtonsContainer)`
  justify-end text-sm md:text-base gap-2
`;

const FooterStyled = tw(Footer)`
  grid justify-end mt-20 gap-4 justify-items-end`;

export const DistributorReleaseDeliveryRecordFooter = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const { release } = useDistributorRelease();
  const {
    cancelSolvingReleaseSolutions,
    solveReleaseSolutions,
    hasUnsolvedIssues,
  } = useDistributorReleaseDeliveryRecord();

  const sendSolutions = useCallback(() => {
    openDialog({
      icon: <WarningIcon />,
      title: intl.$t({ id: "SUBMIT_YOUR_SOLUTIONS" }),
      text: intl.$t({ id: "CONTRACTOR_WILL_BE_NOTIFIED" }),
      handleConfirm: () => solveReleaseSolutions(),
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "CONFIRM" }),
    });
  }, [openDialog, solveReleaseSolutions, intl]);

  const cancelSolutions = useCallback(() => {
    cancelSolvingReleaseSolutions();
    navigate(
      generatePath(routes.distributorDelivery, {
        deliveryId: release?.id,
      }),
    );
  }, [cancelSolvingReleaseSolutions, navigate, release?.id]);

  return (
    <FooterStyled>
      <DistributorReleaseAdditionalChargesAndTaxes release={release} readonly />
      <FooterContainer>
        <If
          isTrue={checkReleaseStatus(release, [
            ReleaseStatus.Received,
            ReleaseStatus.PartiallyReceived,
            ReleaseStatus.Scheduled,
          ])}
        >
          <If isTrue={hasUnsolvedIssues}>
            <OutlinedButton onClick={cancelSolutions}>
              <FormattedMessage id="CANCEL" />
            </OutlinedButton>
            <PrimaryButton
              onClick={sendSolutions}
              autoFocus
              testId="send-solutions-button"
            >
              <FormattedMessage id={"SEND_SOLUTIONS"} />
            </PrimaryButton>
          </If>
        </If>
      </FooterContainer>
    </FooterStyled>
  );
};
