import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { Dialog } from "@/common/components/dialog/Dialog";
import { Switch } from "@/common/components/switch/Switch";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  grid gap-1 mt-5 px-30 items-start justify-center
`;

const InfoText = tw.div`
  text-sm w-fit grid grid-flow-col gap-x-1 items-center font-normal place-self-center
  bg-gray-200 py-1.5 px-4 col-span-3 rounded-3xl 
`;

type SubmitDialogProps = {
  visible: boolean;
  callback?: (skip?: boolean) => Promise<void>;
  setVisible: (visible: boolean) => void;
  saving?: boolean;
};

export const SubmitBuyoutDialog: FC<SubmitDialogProps> = ({
  visible = true,
  callback,
  setVisible,
  saving,
}) => {
  const intl = useIntl();
  const [skipConfirmation, seSkipConfirmation] = useState(false);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleConfirm = useCallback(async () => {
    if (callback) {
      await callback(skipConfirmation);
    }
  }, [callback, skipConfirmation]);

  return (
    <Dialog
      icon={<WarningIcon />}
      title={
        <FormattedMessage
          id={"SUBMIT_BUYOUT_CONFIRMATION"}
          values={{ br: <br /> }}
        />
      }
      cancelButtonText={intl.$t({ id: "CLOSE" })}
      confirmButtonText={intl.$t({ id: "SUBMIT" })}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      saving={saving}
      show={visible}
      content={
        <>
          <Container>
            <InfoText>
              <FormattedMessage id="ASK_VENDOR_CONFIRM_BUYOUT" />
              <Switch
                onLabel={intl.$t({ id: "YES" })}
                offLabel={intl.$t({ id: "NO" })}
                value={!skipConfirmation}
                onChange={() => seSkipConfirmation(!skipConfirmation)}
              />
            </InfoText>
          </Container>
        </>
      }
    />
  );
};
