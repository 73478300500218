import { SearchOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { TextField } from "../textfield/TextField";

type SearchInputProps = {
  value?: string | null;
  onChange?: (value: string) => void;
  placeHolder?: string;
  updateOnChange?: boolean;
  info?: React.ReactNode;
};

const SearchInputContainer = tw.div`w-full flex items-center bg-transparent`;
const InfoContainer = tw.div`ml-3`;

const SearchIcon = tw(
  SearchOutlined,
)`text-gray-600 cursor-pointer focus:text-black hover:text-black`;

export const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
  placeHolder,
  updateOnChange,
  info = null,
}) => {
  const [searchText, setSearchText] = useState(value || "");

  const handleKeyDown = () => {
    if (onChange) {
      onChange(searchText.trim());
    }
  };

  return (
    <SearchInputContainer>
      <TextField
        className={"rounded-md bg-white"}
        value={searchText}
        onClickEnter={handleKeyDown}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchText(event.target.value);
          if (updateOnChange && onChange) {
            onChange(event.target.value);
          }
        }}
        onBlur={(event) => {
          if (onChange) {
            onChange(event.currentTarget.value.trim());
          }
        }}
        fullWidth
        label={placeHolder}
        InputProps={{
          endAdornment: (
            <SearchIcon onClick={() => onChange && onChange(searchText)} />
          ),
        }}
      />
      <If isTrue={info}>
        <InfoContainer>{info}</InfoContainer>
      </If>
    </SearchInputContainer>
  );
};
