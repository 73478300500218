import { CropOriginal } from "@mui/icons-material";
import { FC, useState } from "react";
import tw from "tailwind-styled-components";

type ProductImageProps = {
  imageUrl?: string | null;
  name: string;
  className?: string;
};

const CropOriginalStyled = tw(CropOriginal)`
  w-full h-full text-gray-500
`;

export const ProductImage: FC<ProductImageProps> = ({
  imageUrl,
  name,
  className,
}) => {
  const [url, setUrl] = useState<string | null | undefined>(imageUrl);

  return (
    <>
      {url ? (
        <img
          loading="lazy"
          className={className}
          src={url}
          alt={name}
          onError={(event) => {
            setUrl(null);
            event.currentTarget.onerror = null;
          }}
        />
      ) : (
        <CropOriginalStyled className={className} />
      )}
    </>
  );
};
