import { If } from "@/common/components/if/If";
import React, { FC, ReactNode } from "react";
import tw from "tailwind-styled-components";

type InfoHeaderItemProps = {
  title?: string | React.JSX.Element;
  children?: ReactNode;
  hideInMobile?: boolean;
  containerClassName?: string;
  testId?: string;
};

const Container = tw.div<{ $hideInMobile?: boolean }>`
  ${({ $hideInMobile }) => ($hideInMobile ? "hidden" : "flex")} 
  md:flex 
  flex-col w-full gap-1 border-dotted md:border-none
  border-b-2 border-gray-300 pb-3 pt-3
`;

const Label = tw.div`
  text-xs font-light pb-0.5 items-center grid-flow-col gap-1 min-h-[18px] justify-start hidden md:grid
`;
const Content = tw.div`
  grid grid-flow-col gap-2 text-sm font-normal w-full
`;

export const InfoHeaderItem: FC<InfoHeaderItemProps> = ({
  title,
  hideInMobile = false,
  children,
  containerClassName,
  testId,
}) => {
  return (
    <Container
      className={containerClassName}
      $hideInMobile={hideInMobile}
      data-testid={testId || "info-header"}
    >
      <If isTrue={title}>
        <Label>{title}</Label>
      </If>
      <Content>{children}</Content>
    </Container>
  );
};
