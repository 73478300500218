import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import {
  WizardModal,
  WizardModalMode,
  WizardModalPage,
} from "@/common/components/wizard-modal/WizardModal";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { FC, useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { SelectableCostCodeList } from "../list/SelectableCostCodeList";
import { SelectableCostTypeList } from "../list/SelectableCostTypeList";

type Props = {
  setOpened: (opened: boolean) => void;
  opened: boolean;
  costCodeId: string | null;
  costTypeId: string | null;
};

export const AdditionalChargesModal: FC<Props> = ({
  opened,
  setOpened,
  costCodeId: defaultCostCodeId,
  costTypeId: defaultCostTypeId,
}) => {
  const [costCodeId, setCostCodeId] = useState<string | null>(
    defaultCostCodeId,
  );
  const [costTypeId, setCostTypeId] = useState<string | null>(
    defaultCostTypeId,
  );
  const intl = useIntl();
  const { moveToNextStep, moveToPreviousStep, setStep } = useNestedStepper();
  const { setValue } = useFormContext();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const onClose = useCallback(() => {
    setStep(0);
    setOpened(false);
  }, [setOpened, setStep]);

  const saveCostCode = useCallback(() => {
    if (
      !hasFeatureInConnectedSourceSystem(IntegrationFeature.IncludeCostTypes)
    ) {
      setValue("additionalChargesCostCodeId", costCodeId);
      onClose();
      return;
    }
    moveToNextStep();
  }, [
    costCodeId,
    hasFeatureInConnectedSourceSystem,
    moveToNextStep,
    onClose,
    setValue,
  ]);

  const save = useCallback(() => {
    setValue("additionalChargesCostCodeId", costCodeId);
    setValue("additionalChargesCostTypeId", costTypeId);
    onClose();
  }, [costCodeId, costTypeId, onClose, setValue]);

  const pages: WizardModalPage[] = useMemo(() => {
    const pagesList: WizardModalPage[] = [
      {
        title: intl.$t({ id: "SELECT_COST_CODE_HEADER" }),
        pages: [
          {
            component: (
              <SelectableCostCodeList
                setSelectedCostCodeId={setCostCodeId}
                selectedCostCodeId={costCodeId}
              />
            ),
            hideHeader: true,
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: onClose,
                text: intl.$t({ id: "CLOSE" }),
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                onClick: saveCostCode,
                text: intl.$t({
                  id: hasFeatureInConnectedSourceSystem(
                    IntegrationFeature.IncludeCostTypes,
                  )
                    ? "NEXT"
                    : "SAVE",
                }),
                className: "flex-1",
              },
            ],
          },
        ],
      },
    ];

    if (
      hasFeatureInConnectedSourceSystem(IntegrationFeature.IncludeCostTypes)
    ) {
      pagesList.push({
        title: intl.$t({ id: "SELECT_COST_TYPE_HEADER" }),
        pages: [
          {
            component: (
              <SelectableCostTypeList
                setSelectedCostTypeId={setCostTypeId}
                selectedCostTypeId={costTypeId}
              />
            ),
            hideHeader: true,
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: () => moveToPreviousStep(),
                text: intl.$t({ id: "BACK" }),
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                onClick: save,
                text: intl.$t({ id: "NEXT" }),
                className: "flex-1",
              },
            ],
          },
        ],
      });
    }
    return pagesList;
  }, [
    costCodeId,
    costTypeId,
    hasFeatureInConnectedSourceSystem,
    intl,
    moveToPreviousStep,
    onClose,
    save,
    saveCostCode,
  ]);

  return (
    <WizardModal
      mode={WizardModalMode.MULTIPLE_PAGES}
      opened={opened}
      pages={pages}
      onClose={onClose}
    />
  );
};
