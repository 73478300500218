import { addressToString } from "@/common/components/adress-location/AddressLocation";
import { RfqQuotesPreferredVendorFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { getVendorContacts } from "../../../../../../../../common/utils/getVendorContacts";

type Props = {
  vendor: RfqQuotesPreferredVendorFieldsFragment;
};

const Container = tw.div``;
const Name = tw.div``;
const OrgName = tw.div``;
const Address = tw.div``;

export const VendorInfo: FC<Props> = ({ vendor }) => {
  return (
    <Container>
      <Name>
        {getVendorContacts(
          vendor.contacts.filter((c) => c.receivesQuoteNotifications),
        )}
      </Name>
      <OrgName>{vendor.sellerOrgLocation.org.name}</OrgName>
      <Address>{addressToString(vendor.sellerOrgLocation.address)}</Address>
    </Container>
  );
};
