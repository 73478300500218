import { Loader } from "@/common/components/loader/Loader";
import { Tabs } from "@/common/components/tabs/Tabs";
import { usePreviousValue } from "@/common/hooks/usePreviousValue";
import { routes } from "@/config/routes";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useInvoiceEmails } from "../../../pages/email-invoices/hooks/useInvoiceEmails";
import { useInvoiceStats } from "../../../pages/scanned-invoices/hooks/useInvoiceStats";

export const InvoiceTabNavigation = () => {
  const intl = useIntl();
  const { invoiceStats, loading, refetch } = useInvoiceStats();
  const { canSeeInbox } = useInvoiceEmails();
  const location = useLocation();
  const previousLocation = usePreviousValue(location.pathname);

  useEffect(() => {
    if (previousLocation !== location.pathname) {
      refetch();
    }
  }, [location.pathname, previousLocation, refetch]);

  const tabs = useMemo(
    () => [
      {
        label: intl.$t({ id: "EMAIL_INBOX" }),
        path: routes.emailInvoices,
        count: invoiceStats?.emails.requireAttention || undefined,
        excluded: !canSeeInbox,
      },
      {
        label: intl.$t({ id: "SCANNED_INVOICES" }),
        path: routes.invoices,
      },
    ],
    [canSeeInbox, intl, invoiceStats?.emails.requireAttention],
  );

  if (loading) {
    return <Loader loading />;
  }

  return (
    <Tabs tabs={tabs} type="secondary" className="top-1 -mt-8 pt-4 xl:pt-8" />
  );
};
