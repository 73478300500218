import { CheckboxControlled } from "@/common/components/checkbox-controlled/CheckboxControlled";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { StateSelectorControlled } from "@/common/components/state-selector/StateSelectorControlled";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import { countries } from "@/common/utils/countries";
import { isDefaultCountry } from "@/common/utils/isDefaultCountry";
import { isValidPostalCode } from "@/common/utils/validationUtils";
import { WarehouseFieldsFragment } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`h-full flex flex-col gap-8`;
const Headline = tw.div`text-sm font-medium flex justify-between items-center`;
const Section = tw.div`flex flex-col gap-3`;
const Row = tw.div`grid grid-cols-[2fr_1fr_1fr] gap-2`;

type Props = {
  warehouse?: WarehouseFieldsFragment;
};

export const WarehouseDetailsSection: FC<Props> = ({ warehouse }) => {
  const { watch, setValue } = useFormContext();
  const intl = useIntl();
  const { locations } = useUserLocations();

  const sameAddress = watch("sameAddress");
  const country = watch("address.country");

  useEffect(() => {
    if (sameAddress && locations?.[0].address) {
      setValue("address", locations?.[0].address);
    }
  }, [locations, sameAddress, setValue]);

  const arePostalCodeAndStateRequired = useMemo(
    () => isDefaultCountry(country),
    [country],
  );

  const isValidCode = useCallback(
    (value: string | null) => {
      return !isValidPostalCode(value) && arePostalCodeAndStateRequired
        ? intl.$t({ id: "POSTAL_CODE_VALIDATION" })
        : true;
    },
    [arePostalCodeAndStateRequired, intl],
  );
  return (
    <Container>
      <Section>
        <FormattedMessage
          id={warehouse ? "WAREHOUSE_NAME" : "ADD_NEW_WAREHOUSE"}
          tagName={Headline}
        />
        <TextFieldControlled
          name="name"
          label={intl.$t({ id: "WAREHOUSE_NAME" })}
          rules={{ required: true }}
        />
      </Section>
      <Section>
        <Headline>
          <FormattedMessage id="ADDRESS" />
          <If isTrue={locations?.length === 0}>
            <CheckboxControlled
              name="sameAddress"
              label={intl.$t({ id: "SAME_AS_OFFICE_ADDRESS" })}
            />
          </If>
        </Headline>
        <TextFieldControlled
          name="address.addressLine1"
          label={intl.$t({ id: "ADDRESS" })}
          rules={{ required: true }}
          disabled={sameAddress}
        />
        <Row>
          <TextFieldControlled
            name="address.city"
            label={intl.$t({ id: "ADDRESS_CITY" })}
            rules={{ required: true }}
            disabled={sameAddress}
          />
          <StateSelectorControlled
            name="address.state"
            countryInputName="address.country"
            useDefaultCountryAndState={!warehouse?.id}
          />
          <TextFieldControlled
            name="address.postalCode"
            label={intl.$t({ id: "ADDRESS_ZIP_CODE" })}
            rules={{
              required: arePostalCodeAndStateRequired,
              validate: isValidCode,
            }}
            disabled={sameAddress}
          />
        </Row>
        <SelectControlled
          name="address.country"
          placeholder={intl.$t({ id: "ADDRESS_COUNTRY" })}
          options={countries}
          rules={{ required: true }}
          getLabel={(option) => option.name}
          getValue={(option) => option.abbreviation}
          disabled={sameAddress}
        />
      </Section>
      <Section>
        <FormattedMessage id="SPECIFY_WAREHOUSE_ID" tagName={Headline} />
        <TextFieldControlled
          name="externalCode"
          label={intl.$t({ id: "WAREHOUSE_ID_IN_ACCOUNTING_SOFTWARE" })}
          InputProps={{
            endAdornment: (
              <Tooltip
                id="external-code"
                element={
                  <LinkLike>
                    <InfoOutlined />
                  </LinkLike>
                }
              >
                <FormattedMessage id="WAREHOUSE_ID_IN_ACCOUNTING_SOFTWARE_TOOLTIP" />
              </Tooltip>
            ),
          }}
        />
      </Section>
    </Container>
  );
};
