import { If } from "@/common/components/if/If";
import { Switch } from "@/common/components/switch/Switch";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { SourceSystemWrapper } from "@/contractor/pages/home/release/components/connections/components/common/SourceSystemWrapper";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { useIntl } from "react-intl";
import {
  DefaultSettingsDetails,
  DefaultSettingsToggleText,
  SimpleStepContainer,
  SimpleStepContent,
  SimpleStepItemRow,
  SimpleStepRow,
  SimpleStepTitle,
  SimpleStepTitleContainer,
} from "../../../Wizard.styles";
import { useAgaveHostedWizard } from "../../AgaveHostedWizardProvider";

// const LineItem = tw.li``;

type Props = {
  showReceiptPosted?: boolean;
};

export const AgaveWizardPOSettings: FC<Props> = ({
  showReceiptPosted = false,
}) => {
  const intl = useIntl();
  const {
    postInventoryReceipts,
    setPostInventoryReceipts,
    setAutoPostPos,
    autoPostPos,
  } = useAgaveHostedWizard();

  return (
    <SimpleStepContainer>
      <SimpleStepRow>
        <InfoOutlined className="mr-4 h-12 w-12 text-gray-400" />
        <SimpleStepTitleContainer>
          <SimpleStepTitle>
            {intl.$t({ id: "PO_EXPORT_SETTINGS" })}
          </SimpleStepTitle>
        </SimpleStepTitleContainer>
      </SimpleStepRow>
      <SimpleStepContent>
        {/* // TODO: Uncomment when the feature is implemented 
       <SimpleStepItemRow className="border-b-0">
          <DefaultSettingsToggleText>
            {intl.$t({ id: "AUTOMATED_SYNCHRONIZATION" })}
          </DefaultSettingsToggleText>
          <Switch
            width={100}
            offLabel={intl.$t({ id: "NO" })}
            onLabel={intl.$t({ id: "YES" })}
            onChange={setAutomatedSynchronization}
            value={automatedSynchronization}
          />
          <DefaultSettingsDetails>
            <FormattedMessage id="AUTOMATED_SYNCHRONIZATION_PO_DETAILS_HEADER" />
            <br />
            <FormattedMessage
              id="AUTOMATED_SYNCHRONIZATION_PO_DETAILS_LINE_1"
              tagName={LineItem}
            />
            <FormattedMessage
              id="AUTOMATED_SYNCHRONIZATION_PO_DETAILS_LINE_2"
              tagName={LineItem}
            />
            <FormattedMessage
              id="AUTOMATED_SYNCHRONIZATION_PO_DETAILS_LINE_3"
              tagName={LineItem}
            />
          </DefaultSettingsDetails>
        </SimpleStepItemRow> */}
        <If isTrue={showReceiptPosted}>
          <SimpleStepItemRow className="border-b-0">
            <DefaultSettingsToggleText>
              {intl.$t({ id: "POST_INVENTORY_RECEIPTS" })}
            </DefaultSettingsToggleText>
            <Switch
              width={100}
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
              onChange={setPostInventoryReceipts}
              value={postInventoryReceipts}
            />
            <DefaultSettingsDetails>
              {intl.$t({ id: "POST_INVENTORY_RECEIPTS_DETAILS" })}
            </DefaultSettingsDetails>
          </SimpleStepItemRow>
        </If>
        <SourceSystemWrapper allowedFeatures={[IntegrationFeature.AutoPostPOs]}>
          <SimpleStepItemRow className="border-b-0">
            <DefaultSettingsToggleText>
              {intl.$t({ id: "AUTO_POST_POS" })}
            </DefaultSettingsToggleText>
            <Switch
              width={100}
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
              onChange={setAutoPostPos}
              value={autoPostPos}
            />
            <DefaultSettingsDetails>
              {intl.$t({ id: "AUTO_POST_POS_DESCRIPTION" })}
            </DefaultSettingsDetails>
          </SimpleStepItemRow>
        </SourceSystemWrapper>
      </SimpleStepContent>
    </SimpleStepContainer>
  );
};
