import { useUser } from "@/common/providers/UserProvider";
import { PoNumberingMode } from "@/generated/graphql";
import { FC, PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  alternativeCondition?: boolean;
}>;

export const PoNumberingSettingsCheck: FC<Props> = ({
  alternativeCondition = false,
  children,
}) => {
  const { poNumbering } = useUser();
  if (!alternativeCondition && PoNumberingMode.Never === poNumbering) {
    return null;
  }
  return children;
};
