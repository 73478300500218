import { LinkChunks } from "@/common/components/link-chunks/LinkChunks";
import { TextField } from "@/common/components/textfield/TextField";
import tw from "tailwind-styled-components";

export const SimpleStepContainer = tw.div`mx-4 rounded-3xl items-center justify-center flex flex-1 flex-col`;
export const SimpleStepRow = tw.div<{
  $border?: boolean | null;
}>`flex flex-row items-center pb-4 max-w-md
${({ $border }) =>
  $border ? "border-b border-dashed border-gray-400 mb-4" : ""}
`;
export const SimpleStepCol = tw.div`flex flex-col items-center mb-4 gap-4`;
export const SimpleStepTitle = tw.div`text-lg font-medium`;
export const SimpleStepTitleContainer = tw.div``;
export const SimpleRowDetails = tw.div`text-2xs text-gray-600 w-full`;
export const SimpleStepContent = tw.div<{
  $narrow?: boolean | null;
  $gray?: boolean | null;
}>`flex flex-col px-5 py-4 rounded-3xl w-full
  ${({ $gray }) => ($gray ? "bg-gray-100" : "")}
  ${({ $narrow }) => ($narrow ? "max-w-md" : "max-w-xl")}
  `;
export const SimpleStepItemRow = tw.div`grid grid-cols-[110px_100px_1fr] justify-end items-center border-b border-dashed border-gray-400 gap-5 py-5`;
export const DefaultSettingsToggleText = tw.div`flex mr-3 font-medium text-sm justify-end text-end`;
export const DefaultSettingsDetails = tw.div`text-2xs text-gray-600 max-w-72`;
export const StyledTextField = tw(TextField)`w-full bg-white`;

// final step
export const FinalStepRow = tw.div`flex flex-row items-center justify-center py-16 mx-8 text-lg border-dashed border-b border-gray-400`;
export const FinalStepView = tw.div`flex flex-col items-center justify-center py-16 font-medium`;
export const FinalStepList = tw.ul`mt-12`;
export const FinalStepListItem = tw.li`flex flex-row items-center font-normal text-sm mb-3`;
export const FinalStepDot = tw.div`w-[10px] h-[10px] rounded-full bg-blue-800 mr-6`;
export const FinalStepDescription = tw.div`flex flex-row font-medium text-lg`;
export const FinalStepDescriptionDetails = tw.div`flex flex-row font-light text-sm text-gray-600 items-center`;
export const FinalStepItem = tw.div``;
export const DefaultLinkChunks = tw(
  LinkChunks,
)`text-blue-500 font-normal no-underline text-sm`;
export const FinalStepLinkChunks = tw(
  DefaultLinkChunks,
)`text-lg text-blue-500 font-normal mx-1 no-underline`;
