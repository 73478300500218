import { NumericalInput } from "@/common/components/numerical-input/NumericalInput";
import { Check, Close } from "@mui/icons-material";
import { FC, useEffect, useRef, useState } from "react";
import { Row } from "./InvoiceFooterDetails.styles";

type Props = {
  inputValue: string;
  isEditing: boolean;
  toggleEditing: () => void;
  onSave: (t: string) => void;
  decimals?: number;
  suffix?: string;
  includeCurrency?: boolean;
};

export const InvoiceFooterEditInput: FC<Props> = ({
  inputValue,
  isEditing,
  toggleEditing,
  onSave,
  decimals = 2,
  suffix,
  includeCurrency,
}) => {
  const [value, setValue] = useState(inputValue);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing) {
      ref.current?.focus();
    }
  }, [isEditing]);

  return (
    <NumericalInput
      inputRef={ref}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      decimals={decimals}
      fixedDecimalScale
      suffix={suffix}
      includeCurrency={includeCurrency}
      InputProps={{
        className: "h-8 pr-1 bg-white w-[185px]",
        endAdornment: (
          <Row>
            <Check
              className="h-6 w-6 cursor-pointer rounded-full p-0.5 text-blue-500 transition-colors hover:bg-gray-100"
              onClick={() => onSave(value)}
            />
            <Close
              className="h-6 w-6 cursor-pointer rounded-full p-0.5 text-blue-500 transition-colors hover:bg-gray-100"
              onClick={() => {
                setValue(inputValue);
                toggleEditing();
              }}
            />
          </Row>
        ),
      }}
    />
  );
};
