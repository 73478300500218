import { useEffect, useState } from "react";

function useDelayedQuery(delay: number) {
  const [startQuery, setStartQuery] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartQuery(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  return {
    startQuery,
  };
}

export default useDelayedQuery;
