import { Tooltip } from "@/common/components/tooltip/Tooltip";
import ReportGmailErroredIcon from "@mui/icons-material/ReportGmailerrorred";
import React, { FC } from "react";
import tw from "tailwind-styled-components";

type WarningIconWithDetailsProps = {
  warning?: string | React.JSX.Element;
};

const WarningIcon = tw(ReportGmailErroredIcon)`
  text-red-500 w-5 h-5 relative grid place-items-center
`;

export const WarningIconWithDetails: FC<WarningIconWithDetailsProps> = ({
  warning,
}) => {
  return (
    <Tooltip id="item-notes" element={<WarningIcon />}>
      {warning}
    </Tooltip>
  );
};
