import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ExternalEstimateFieldsFragment,
  ImportEstimateInput,
  SourceSystem,
  useImportEstimateMutation,
  useProjectExternalProjectsWithEstimatesQuery,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { FileSourceType } from "../../../admin/cost-structure/pages/cost-codes/components/import-codes/CodesFileSourceSelector";

export const useExternalEstimates = (
  projectId: string,
  sourceType: FileSourceType,
): {
  loadingEstimates: boolean;
  foundationJobMaterialListOptions: ExternalEstimateFieldsFragment[];
  importEstimate: (input: ImportEstimateInput) => Promise<{ success: boolean }>;
} => {
  const { setError } = useGlobalError();
  const {
    data,
    error,
    loading: loadingEstimates,
  } = useProjectExternalProjectsWithEstimatesQuery({
    variables: {
      id: projectId,
    },
    skip: sourceType !== SourceSystem.Foundation || !projectId,
  });

  useErrorEffect(error);

  const foundationJobMaterialListOptions = useMemo(
    () =>
      data?.project?.externalProjects.find(
        (ep) => ep.sourceSystem === SourceSystem.Foundation,
      )?.estimates ?? [],
    [data?.project],
  );

  const [importEstimateMutation] = useImportEstimateMutation();
  const importEstimate = useCallback(
    async (input: ImportEstimateInput) => {
      try {
        const { errors } = await importEstimateMutation({
          variables: { input, excludePhantoms: true },
        });
        setError(errors);
        return {
          success: !errors,
        };
      } catch (error) {
        setError(error);
        return {
          success: false,
        };
      }
    },
    [importEstimateMutation, setError],
  );

  return {
    loadingEstimates,
    foundationJobMaterialListOptions,
    importEstimate,
  };
};
