import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { SystemAlert } from "@/common/components/system-alert/SystemAlert";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { INVOICE_READONLY_STATUSES } from "@/distributor/pages/invoices/providers/DistributorInvoiceProvider";
import { ReleaseSelectorFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { MatchedOrderViewState } from "../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../providers/InvoiceVerificationProvider";
import { useLazyLoadExternalPo } from "./useLazyLoadExternalPo";

type Props = {
  setMatchedOrderViewState: (status: MatchedOrderViewState | null) => void;
  matchedOrderViewState: MatchedOrderViewState | null;
};

export const useInvoiceViewAutoSelection = ({
  matchedOrderViewState,
  setMatchedOrderViewState,
}: Props) => {
  const { invoice, setFooterState } = useInvoiceVerification();
  const { fetchExternalPo } = useLazyLoadExternalPo();
  const { setSystemAlert } = useSnackbar();
  const { connectedSourceSystem, settings } = useOrgSettings();
  const intl = useIntl();

  const autoSelectView = useCallback(
    (releases: ReleaseSelectorFieldsFragment[] = []) => {
      if (invoice?.release) {
        if (!INVOICE_READONLY_STATUSES.includes(invoice.status)) {
          setMatchedOrderViewState(
            MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
          );
        } else {
          setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
        }
      } else if (
        invoice?.poExists &&
        matchedOrderViewState !== MatchedOrderViewState.IMPORT_ORDER
      ) {
        setMatchedOrderViewState(MatchedOrderViewState.IMPORT_ORDER);
        setFooterState(InvoiceFooterState.IMPORT_ORDER);
        fetchExternalPo();
        setSystemAlert(
          <SystemAlert
            messages={[
              intl.$t(
                { id: "INVOICE_MATCHED_TO_EXTERNAL_PO" },
                {
                  poNumber: invoice.poNumber,
                  integration: intl.$t({
                    id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
                  }),
                },
              ),
            ]}
          />,
        );
      } else if (
        !invoice?.poExists &&
        releases.length > 0 &&
        invoice?.poNumber &&
        matchedOrderViewState !== MatchedOrderViewState.MATCH_ORDER
      ) {
        setSystemAlert(
          <SystemAlert
            messages={[
              intl.$t(
                { id: "INVOICE_MATCHED_MULTIPLE_ORDERS" },
                {
                  integration: intl.$t({
                    id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
                  }),
                  count: releases.length,
                },
              ),
            ]}
          />,
        );
        setMatchedOrderViewState(MatchedOrderViewState.MATCH_ORDER);
        setFooterState(InvoiceFooterState.MATCH_ORDER);
      } else {
        setSystemAlert(
          <SystemAlert
            messages={[
              intl.$t(
                {
                  id:
                    connectedSourceSystem &&
                    settings?.integrations.sourceSystems.find(
                      (sourceSystem) => sourceSystem.connected,
                    )?.autoExportApprovedInvoices
                      ? "INVOICE_MATCHED_NO_ORDER"
                      : "INVOICE_MATCHED_NO_ORDER_NO_CONNECTION",
                },
                {
                  integration: connectedSourceSystem
                    ? intl.$t({
                        id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
                      })
                    : null,
                },
              ),
            ]}
          />,
        );
        setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
        setFooterState(InvoiceFooterState.DEFAULT);
      }
    },
    [
      invoice?.release,
      invoice?.poExists,
      invoice?.poNumber,
      invoice?.status,
      matchedOrderViewState,
      setMatchedOrderViewState,
      setFooterState,
      fetchExternalPo,
      setSystemAlert,
      intl,
      connectedSourceSystem,
      settings?.integrations.sourceSystems,
    ],
  );

  return { autoSelectView };
};
