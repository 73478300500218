import { DeliveryCategoryId } from "@/config/deliveryConfiguration";
import { ServicePriceFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";

export const useItemsByCategory = () => {
  const itemsByCategory = useCallback(
    (prices: ServicePriceFieldsFragment[], categoryId: DeliveryCategoryId) => {
      return prices
        .filter(
          (item) =>
            item.type === categoryId.type &&
            item.productCategory === categoryId.productCategory,
        )
        .sort((left, right) => left.name.localeCompare(right.name));
    },
    [],
  );

  return {
    itemsByCategory,
  };
};
