import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { LUMP_SUM_UOM, LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  COLUMN_TYPE,
  SpreadSheetConfig,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { routes } from "@/config/routes";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import {
  ProjectListOptionWithTags,
  useProjectListOptions,
} from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import {
  AddToReleaseItemInput,
  OrgMaterialFieldsFragment,
  ReceiptDocument,
  UomsDocument,
  useCreateStandaloneReleaseMutation,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import { useReceiptSequence } from "../../receipts/providers/ReceiptsSequenceProvider";
import { useReceiptCreateReleaseSpreadsheetConfig } from "../components/order/itemized/create/ReceiptCreateRelease.config";
import { ReceiptCreateReleaseFormValues } from "../components/ReceiptVerificationForm";
import { useReceipt } from "./ReceiptProvider";

type ProviderContextType = {
  syncCreateReleaseFromReceipt: (
    values: ReceiptCreateReleaseFormValues,
  ) => Promise<boolean>;
  loading: boolean;
  spreadsheetViewColumns: SpreadSheetConfig[];
  createRelease: (values: ReceiptCreateReleaseFormValues) => Promise<boolean>;
};

const ProviderContext = createContext<ProviderContextType>({
  syncCreateReleaseFromReceipt: NoFunctionBooleanPromise,
  loading: false,
  spreadsheetViewColumns: [],
  createRelease: NoFunctionBooleanPromise,
});

export const ReceiptCreateReleaseProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { setError } = useGlobalError();
  const { materials, updateMaterials } = useMaterials();
  const { vendors } = useVendors();
  const { receipt, approveReceipt, updateReceipt } = useReceipt();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { costCodes } = useCostCodes();
  const { projects } = useProjectListOptions({
    includeTags: true,
  });
  const navigate = useNavigate();
  const { spreadsheetData, gotoInvalidRow } = useColumnMapper();
  const { sequenceActive, navigateToNextSequence } = useReceiptSequence();
  const {
    getCellValue,
    getRowUomCreatableValue,
    getCostCodeId,
    rowIsEmpty,
    addMissingMaterials,
    getCellWithAdditionalData,
    findMaterialByName,
    getPrefilledValue,
  } = useTableHelpers();
  const [saving, setSaving] = useState(false);
  const intl = useIntl();

  const [createStandaloneRelease, { loading: creatingRelease }] =
    useCreateStandaloneReleaseMutation();
  const syncCreateReleaseFromReceipt = async (
    values: ReceiptCreateReleaseFormValues,
  ) => {
    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.Quantity,
        COLUMN_TYPE.UnitPrice,
      ]) ||
      !validateRowValues(
        [
          COLUMN_TYPE.Quantity,
          COLUMN_TYPE.UnitPrice,
          COLUMN_TYPE.UOM,
          COLUMN_TYPE.CostCode,
        ],
        undefined,
        { minPrice: undefined },
      )
    ) {
      gotoInvalidRow();
      return false;
    }

    const newItems: AddToReleaseItemInput[] = [];

    setSaving(true);
    const newMaterials = (await addMissingMaterials()) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getRowUomCreatableValue(row);
      const isLumpSum = isLumpSumUomText(uom);
      if (isLumpSum) {
        material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
          ...materials,
          ...newMaterials,
        ]);
      }

      if (!material || rowIsEmpty(row)) {
        return false;
      }

      const quantityDecimal = getCellValue(row, COLUMN_TYPE.Quantity);
      const phaseCodeName = getCellValue(row, COLUMN_TYPE.PhaseCode);
      const phaseCode = (projects as ProjectListOptionWithTags[])
        .find((p) => p.id === values.projectId)
        ?.tags.find((t) => t.name === phaseCodeName)?.id;
      const unitPrice = Number(
        getCellValue(
          row,
          receipt?.isDeliverySlip
            ? COLUMN_TYPE.PrefilledPrice
            : COLUMN_TYPE.UnitPrice,
        ),
      );
      const vendor = vendors?.find((v) => v.id === values.vendorId);

      const prefilledPrice = getPrefilledValue(
        rowMaterialText,
        vendor ? vendorLabelFormatter(vendor.sellerOrgLocation) : "",
        "",
        uom,
      );

      newItems.push({
        projectItem: {
          estimateUom: uom,
          ...(isOrgCatalogSku(material.material) && {
            orgCatalogSkuId: material.material.id,
          }),
          ...(isProductSku(material.material) && {
            masterProductId: material.material.id,
          }),
          ...(isMasterSku(material.material) && {
            masterSkuId: material.material.id,
          }),
        },
        quantityDecimal: quantityDecimal,
        unitPrice: unitPrice ? String(unitPrice) : undefined,
        ...(isLumpSum && { name: rowMaterialText }),
        pricePrenegotiated: String(unitPrice) === prefilledPrice.value,
        tags: phaseCode ? [phaseCode] : undefined,
        position: index,
        costCodeId: getCostCodeId(row),
      });
    });

    if (newItems.length > 0) {
      try {
        const { data, errors } = await createStandaloneRelease({
          variables: {
            input: {
              invoiceId: receipt?.id ?? "",
              projectId: values.projectId ?? "",
              sellerOrgLocationId: values.vendorId,
              items: newItems,
              taxRate: values.taxRate || undefined,
              customTaxAmount: values.customTaxAmount || undefined,
              retroactive: true,
              time: values.issueDate?.getTime(),
              typeId: values.orderTypeId || undefined,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: ReceiptDocument,
              variables: { id: receipt?.id ?? "" },
            },
            {
              query: UomsDocument,
            },
          ],
        });
        setError(errors);
        let receiptUpdated = false;
        if (!errors && receipt?.id) {
          receiptUpdated = await updateReceipt({
            id: receipt?.id,
            issueDate: values.issueDate?.getTime(),
          });
          receiptUpdated = await approveReceipt({
            invoiceId: receipt?.id,
          });
        }
        if (!errors && receiptUpdated) {
          if (!sequenceActive) {
            navigate(
              generatePath(routes.delivery, {
                deliveryId: data?.createStandaloneRelease.id,
              }),
            );
          } else {
            navigateToNextSequence({ markSequenceIdAsApproved: receipt?.id });
          }
        }
        setSaving(false);
        return !errors && receiptUpdated;
      } catch (error) {
        setError(error);
        setSaving(false);
        return false;
      }
    }

    return true;
  };

  const createRelease = async (values: ReceiptCreateReleaseFormValues) => {
    try {
      setSaving(true);
      const costCode = costCodes.find(
        (c) => c.id === values.costCodeId,
      )?.description;
      const lumpSumMaterial = materials.find(
        (m) => m.material.name === LUMP_SUM_UOM_PLURAL_DESCRIPTION,
      );

      let newMaterials;
      if (!lumpSumMaterial) {
        newMaterials = await updateMaterials({
          addedMaterials: [
            {
              costCodeId: values.costCodeId,
              newOrgCatalogSKU: {
                defaultUom: LUMP_SUM_UOM,
                name: LUMP_SUM_UOM_PLURAL_DESCRIPTION,
              },
            },
          ],
        });
      }
      const items: AddToReleaseItemInput[] = [
        {
          projectItem: {
            estimateUom: LUMP_SUM_UOM,
            orgCatalogSkuId: (
              lumpSumMaterial ||
              (newMaterials as OrgMaterialFieldsFragment[])[0]
            )?.material.id,
          },
          quantityDecimal: values.subtotal.toString(),
          position: 0,
          unitPrice: "1",
          tags: values.phaseCodeId ? [values.phaseCodeId] : undefined,
          costCodeId: values.costCodeId,
          name: costCode
            ? intl.$t({ id: "RECEIPT_COST_CODE_EXPENSES" }, { costCode })
            : intl.$t({ id: "RECEIPT_EXPENSES" }),
        },
      ];
      const { data, errors } = await createStandaloneRelease({
        variables: {
          input: {
            invoiceId: receipt?.id ?? "",
            projectId: values.projectId ?? "",
            sellerOrgLocationId: values.vendorId,
            time: values.issueDate?.getTime(),
            items,
            taxRate: values.taxRate || undefined,
            customTaxAmount: values.customTaxAmount || undefined,
            retroactive: true,
            typeId: values.orderTypeId || undefined,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: UomsDocument,
          },
        ],
      });
      setError(errors);
      let receiptUpdated = false;
      if (!errors && receipt?.id) {
        receiptUpdated = await updateReceipt({
          id: receipt?.id,
          issueDate: values.issueDate?.getTime(),
        });
        receiptUpdated = await approveReceipt({
          invoiceId: receipt?.id,
        });
      }
      if (!errors && receiptUpdated) {
        if (!sequenceActive) {
          navigate(
            generatePath(routes.delivery, {
              deliveryId: data?.createStandaloneRelease.id,
            }),
          );
        } else {
          navigateToNextSequence({ markSequenceIdAsApproved: receipt?.id });
        }
      }

      setSaving(false);
      return !errors && receiptUpdated;
    } catch (error) {
      setError(error);
      setSaving(false);
      return false;
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        syncCreateReleaseFromReceipt,
        loading: saving || creatingRelease,
        spreadsheetViewColumns: useReceiptCreateReleaseSpreadsheetConfig(),
        createRelease,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReceiptCreateRelease = (): ProviderContextType =>
  useContext(ProviderContext);
