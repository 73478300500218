import {
  EstimatedItemFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import {
  AddToBuyoutItem,
  useBuyoutProjectItemsWithUpdates,
} from "../providers/BuyoutProjectItemsProvider";
import { AddEstimatedItemCheckbox } from "./AddEstimatedItemCheckbox";

export const BuyoutProjectItemCheckbox: FC<{
  item: ProjectItemFieldsFragment;
  estimatedItem?: EstimatedItemFieldsFragment | null;
}> = ({ item, estimatedItem }) => {
  const { updates, setUpdates } = useBuyoutProjectItemsWithUpdates();

  const onToggle = useCallback(
    (itemsToAdd: AddToBuyoutItem[]) => {
      setUpdates(itemsToAdd);
    },
    [setUpdates],
  );

  return (
    <AddEstimatedItemCheckbox
      item={item}
      estimatedItem={estimatedItem}
      updates={updates}
      setUpdates={onToggle}
    />
  );
};
