import { PAGE_OPTIONS } from "@/common/const";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useLocalPagination } from "./LocalPaginationProvider";

type PaginationProps = {
  total: number;
  count: number;
  page: number;
  itemsPerPage: number;
  nextPage: () => void;
  previousPage: () => void;
  itemLabel?: string;
};

const PaginationContainer = tw.div`
  inline-flex items-center gap-4
`;
const FormControlStyled = tw(FormControl)`w-24`;

type PaginationLinkProps = {
  $active: boolean;
};

const PaginationLink = tw.div<PaginationLinkProps>`
  flex items-center 
  ${(props: PaginationLinkProps) =>
    props.$active ? "cursor-pointer" : "text-gray-500"};
`;

export const LocalPagination: FC<PaginationProps> = ({
  total,
  count,
  page,
  itemsPerPage,
  nextPage,
  previousPage,
  itemLabel,
}) => {
  const { setPageSize, setPage } = useLocalPagination();
  const intl = useIntl();

  const startNumber = useMemo(
    () => page * itemsPerPage + 1,
    [itemsPerPage, page],
  );
  const onChangePageSize = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize);
      setPage(0);
    },
    [setPage, setPageSize],
  );

  const hasNext = useMemo(
    () => total > (page + 1) * itemsPerPage,
    [total, page, itemsPerPage],
  );

  const hasPrevious = useMemo(() => page > 0, [page]);

  return (
    <PaginationContainer>
      {intl.$t(
        { id: "PAGE_COUNTER" },
        { startNumber, endNumber: startNumber + (count - 1), total, itemLabel },
      )}
      <PaginationLink
        $active={hasPrevious}
        onClick={() => (hasPrevious ? previousPage() : null)}
      >
        <NavigateBefore />
      </PaginationLink>
      <PaginationLink
        $active={hasNext}
        onClick={() => (hasNext ? nextPage() : null)}
      >
        <NavigateNext />
      </PaginationLink>{" "}
      <FormControlStyled>
        <InputLabel id="pagination-select">
          {intl.$t({ id: "SHOW_ITEMS" })}
        </InputLabel>
        <Select
          value={itemsPerPage.toString()}
          labelId="pagination-select"
          label={intl.$t({ id: "SHOW_ITEMS" })}
          onChange={(event: SelectChangeEvent) =>
            onChangePageSize(Number(event.target.value))
          }
          size="small"
          inputProps={{ className: "text-center" }}
        >
          {PAGE_OPTIONS.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControlStyled>
    </PaginationContainer>
  );
};
