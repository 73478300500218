import { If } from "@/common/components/if/If";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Label = tw.div`
  grid grid-flow-col min-w-16 h-6 overflow-hidden text-2xs last:border-b-0 text-gray-600
  font-normal text-right items-center pr-2 truncate border-b border-gray-400 border-dashed
`;

const Total = tw.div`
  grid grid-flow-col min-w-16 h-6 overflow-hidden text-xs
  bg-gray-200 rounded-3xl place-content-center font-medium
`;

const LabelsContainer = tw.div`
  grid py-2.5 h-full
`;

type QuoteRowLabelsProps = {
  showItemDescription?: boolean;
  hasComponents?: boolean;
  hasExpiration?: boolean;
};

export const QuoteRowLabels: FC<QuoteRowLabelsProps> = ({
  showItemDescription = false,
  hasComponents = false,
  hasExpiration = false,
}) => {
  const { hasManufacturersSetting } = useManufacturersSettings();
  return (
    <LabelsContainer>
      <div>
        <If isTrue={showItemDescription}>
          <Label>
            <FormattedMessage id="ITEM_DESCRIPTION" />
          </Label>
        </If>
        <If isTrue={hasManufacturersSetting}>
          <Label>
            <FormattedMessage id="MANUFACTURER" />
          </Label>
        </If>
        <Label>
          <FormattedMessage id="QUANTITY" /> &{" "}
          <FormattedMessage id="UNIT_PRICE" />
        </Label>
        <If isTrue={hasExpiration}>
          <Label>
            <FormattedMessage id="PRICE_EXPIRATION" />
          </Label>
        </If>
        <If isTrue={hasComponents}>
          <Label>
            <FormattedMessage id="COMPONENTS" />
          </Label>
        </If>
      </div>
      <Total>
        <FormattedMessage id="TOTAL_CHARGES" />
      </Total>
    </LabelsContainer>
  );
};
