import { isOrgCatalogSku } from "@/common/components/material/utils";
import { UomPicker } from "@/common/components/uom-picker/UomPicker";
import { OrgMaterialFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useMaterialList } from "../../../providers/MaterialListProvider";

type Props = {
  item: OrgMaterialFieldsFragment;
  readonly: boolean;
};

export const MaterialUomPicker: FC<Props> = ({ item, readonly }: Props) => {
  const { updateMaterial } = useMaterialList();

  const onChange = useCallback(
    (uom: string | null) => {
      updateMaterial(item.id, {
        updates: [
          {
            orgCatalogSku: {
              id: item.material.id,
              defaultUom: uom,
            },
            orgMaterialId: item.id,
          },
        ],
      });
    },
    [updateMaterial, item],
  );
  return (
    <UomPicker
      uom={item.defaultEstimateUom}
      saveUom={onChange}
      readonly={readonly}
      staticText={readonly || !isOrgCatalogSku(item.material)}
    />
  );
};
