import { useUserLocations } from "@/common/hooks/useUserLocations";
import { FC, Fragment, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  userLocationRoles: Array<{ orgLocID: string; role: string }>;
  className?: string;
};

const Container = tw.div`
  bg-gray-100 text-xs grid grid-cols-[auto_auto] gap-x-4 p-2
`;
const Location = tw.div`
  flex items-center font-light
`;
const Role = tw.div`
  flex justify-end py-1 items-center 
`;

export const LocationsAndRoles: FC<Props> = ({
  userLocationRoles,
  className,
}) => {
  const { locations } = useUserLocations();

  const findLocation = useCallback(
    (locationId: string) =>
      locations?.find((location) => location.id === locationId),
    [locations],
  );

  return (
    <Container className={className}>
      {userLocationRoles.map(
        (locationRole: { orgLocID: string; role: string }) => (
          <Fragment key={locationRole.role}>
            <Location>
              {findLocation(locationRole.orgLocID)?.name || "-"}
            </Location>
            <Role>
              <FormattedMessage id={`USER_ROLE_${locationRole.role}`} />
            </Role>
          </Fragment>
        ),
      )}
    </Container>
  );
};
