import Decimal from "decimal.js";

export const sumIncludedFullPrices = (
  acc: number,
  item: {
    unitPrice?: string | null | undefined;
    quantity?: number | null;
    isIncluded: boolean;
  },
) => {
  const unitPrice = new Decimal(item.isIncluded ? item.unitPrice || 0 : 0);
  return Number(unitPrice.mul(item.quantity || 0).add(new Decimal(acc)));
};
