import { useMemo } from "react";
import { useVistaPages } from "./pages/useVistaPages";

export const useCustomPages = () => {
  const vistaPages = useVistaPages();
  const customPages = useMemo(() => {
    return [vistaPages];
  }, [vistaPages]);

  return { customPages };
};
