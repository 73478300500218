import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { ProjectOrderedQuantityProvider } from "@/contractor/pages/home/project/providers/ProjectOrderedQuantityProvider";
import {
  ProjectReportType,
  ReportItemType,
} from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { ProjectReportMaterialFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { CurrencyDisplay } from "./CurrencyDisplay";
import {
  ReportItemOrderedPanel,
  ReportType,
} from "./report-item-orders-panel/ReportItemOrderedPanel";

const LinkLikeStyled = tw(LinkLike)`font-medium text-xs`;

type Props = {
  item: ProjectReportType;
  type: ReportItemType;
};

export const SpendingReportOrderedQuantity: FC<Props> = ({ item, type }) => {
  return (
    <DrawerPanel
      anchor={(togglePanel) =>
        !item.ordered ? (
          "--"
        ) : (
          <LinkLikeStyled
            onClick={() => {
              togglePanel(true);
            }}
          >
            <CurrencyDisplay value={item.ordered} />
          </LinkLikeStyled>
        )
      }
      content={(togglePanel) => (
        <ProjectOrderedQuantityProvider item={item} type={type}>
          <ReportItemOrderedPanel
            orgMaterialId={
              (item as ProjectReportMaterialFieldsFragment).material.id
            }
            ordered={(item as ProjectReportMaterialFieldsFragment).ordered}
            onClose={() => togglePanel(false)}
            type={ReportType.Material}
          />
        </ProjectOrderedQuantityProvider>
      )}
    />
  );
};
