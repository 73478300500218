import { If } from "@/common/components/if/If";
import { Price } from "@/common/components/price/Price";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import Decimal from "decimal.js";
import { FC, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { usePriceCalculation } from "../../hooks/usePriceCalculation";

const Container = tw.div`
  grid w-full text-center text-2xs
`;

const ValueWithTax = tw.div`
  w-full text-center text-2xs text-gray-600
`;

type Props = {
  items: ExpandedReleaseItem[];
};

export const ReleaseItemExtPriceGroup: FC<Props> = ({ items }) => {
  const { formatCurrency } = useFormatNumberToCurrency();
  const [valueWithTax, setValueWithTax] = useState(0);
  const { release } = useRelease();
  const { calcExtPrice } = usePriceCalculation();

  const total = useMemo(() => {
    return items.reduce(
      (acc, item) =>
        acc.add(calcExtPrice(item.quantityDecimal, item.unitPrice)),
      new Decimal(0),
    );
  }, [calcExtPrice, items]);

  useEffect(() => {
    if (!total) {
      return;
    }
    if (release?.subtotal && release?.customTaxAmount) {
      const changes = release.additionalCharges.reduce(
        (acc, charge) => acc.add(charge.amount),
        new Decimal(0),
      );
      const calculatedTaxRate = new Decimal(release.customTaxAmount).div(
        new Decimal(release?.subtotal).add(changes),
      );
      setValueWithTax(total.add(total.mul(calculatedTaxRate)).toNumber());
    } else if (release?.taxRate) {
      setValueWithTax(total.add(total.mul(release.taxRate)).toNumber());
    }
  }, [total, release]);

  return (
    <Container>
      <Price price={total} minimumFractionDigits={2} />

      <If isTrue={valueWithTax}>
        <FormattedMessage
          id="VALUE_WITH_TAX_AND_FEES"
          tagName={ValueWithTax}
          values={{ value: formatCurrency(valueWithTax) }}
        />
      </If>
    </Container>
  );
};
