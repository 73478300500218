import {
  FC,
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { ProductCategory, useCategoriesQuery } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";

export type Category = {
  label: string;
  id?: string;
  children: Category[];
};

type ProviderContextType = {
  drywallCategories: ProductCategory[];
  categoryId: string | null;
  categoryLevels: Category[];
  changeCategory: (categoryId: string | null) => void;
  categoriesLoading: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  drywallCategories: [],
  changeCategory: NoFunction,
  categoryId: "",
  categoryLevels: [],
  categoriesLoading: false,
});

const addSubCategories = (
  item: Category,
  categoryId: string,
  items: string[],
) => {
  if (items.length === 1) {
    item.children.push({ label: items[0], id: categoryId, children: [] });
    return;
  }
  const itemToAdd = items.splice(0, 1);
  let childItem = item.children.find((child) => child.label === itemToAdd[0]);
  if (!childItem) {
    childItem = { label: itemToAdd[0], children: [] };
    item.children.push(childItem);
  }
  addSubCategories(childItem, categoryId, items);
};

const sortCategoryTree = (categories: Category[]) => {
  const sorted = categories.sort((left, right) =>
    left.label.localeCompare(right.label),
  );
  sorted.forEach((category) => {
    category.children = sortCategoryTree(category.children);
  });
  return sorted;
};

export const CategoriesProvider: FC<{
  children: React.ReactNode;
  initialCategory?: string | null;
}> = ({ children, initialCategory }) => {
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const { data, loading } = useCategoriesQuery();

  const sortedCategories = useMemo(() => {
    if (data?.categories) {
      return [...data.categories].sort((left, right) =>
        left.l1Name.localeCompare(right.l1Name),
      );
    }
    return [];
  }, [data]);

  const categoryLevels = useMemo(() => {
    if (data?.categories) {
      const result = [] as Category[];
      data.categories.forEach((category) => {
        let item = result.find((cat) => cat.label === category.l1Name);
        if (!item) {
          item = { label: category.l1Name, children: [], id: category.id };
          result.push(item);
        }
        const subCategories = [
          category.l2Name,
          category.l3Name,
          category.l4Name,
        ].filter((elem) => elem !== null) as string[];
        addSubCategories(item, category.id, subCategories);
      });
      return sortCategoryTree(result);
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (sortedCategories.length !== 0 && initialCategory === undefined) {
      setCategoryId(sortedCategories[0].id);
    }
  }, [setCategoryId, sortedCategories, initialCategory]);

  const changeCategory = (id: string | null) => {
    setCategoryId(id);
  };

  return (
    <ProviderContext.Provider
      value={{
        drywallCategories: sortedCategories,
        categoryLevels,
        categoryId,
        changeCategory,
        categoriesLoading: loading,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useCategories = (): ProviderContextType =>
  useContext(ProviderContext);
