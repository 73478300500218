import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useOpenFile } from "@/common/components/upload-asset/useOpenFile";
import { AssetFieldsFragment } from "@/generated/graphql";
import { AttachmentOutlined } from "@mui/icons-material";
import { FC, useMemo, useState } from "react";
import tw from "tailwind-styled-components";
import { hasImage } from "../upload-asset/UploadAsset";
import { getFileName } from "../upload-asset/getFileName";
import TextWithShowMore from "./TextWithShowMore";

const NotesContainer = tw.div`
  pl-8 w-full pr-3
`;

const NotesContent = tw.div`
  grid grid-flow-col gap-1 items-start justify-start 
`;

const Attachment = tw(AttachmentOutlined)`
  text-sm text-blue-500
`;

const ImagesContainer = tw.div`
  grid gap-1 justify-start grid-cols-auto-auto mb-1
`;

const ImageContainer = tw.div`
  grid items-center justify-items-center gap-1
`;

const StyledImage = tw.img`
  h-8 w-8 rounded border border-blue-800 cursor-pointer object-cover hover:border-blue-500
`;

const FileContainer = tw.div`
  w-full cursor-pointer col-span-full
`;

const File = tw.div`
  grid grid-flow-col justify-start gap-1 items-center text-blue-500 font-light text-2xs
`;

const FileName = tw.div`text-ellipsis overflow-hidden whitespace-nowrap w-full`;
const FileExtension = tw.div`-ml-1`;
const Label = tw.div`text-2xs min-w-15`;

type Props = {
  notes?: string | null;
  assets?: AssetFieldsFragment[] | null;
  className?: string;
  label?: string;
};

export const LineItemNameNotes: FC<Props> = ({
  notes,
  assets,
  className,
  label,
}) => {
  const { open } = useOpenFile();
  const [isExpanded, setIsExpanded] = useState(false);

  const images = useMemo(() => {
    return assets?.filter((asset) => hasImage(asset));
  }, [assets]);

  const assetUrlsWithoutImages = useMemo(() => {
    return assets?.filter((asset) => !hasImage(asset)).reverse();
  }, [assets]);

  if (!notes && (assets || []).length === 0) {
    return null;
  }

  return (
    <NotesContainer className={className}>
      <Tooltip
        id="notes"
        classes={{ tooltip: "max-w-screen-md" }}
        element={
          <>
            <NotesContent>
              <If isTrue={label}>
                <Label>{label}</Label>
              </If>
              <If isTrue={(assets || []).length > 0 && !isExpanded}>
                <Attachment />
              </If>
              <TextWithShowMore
                text={notes}
                setIsExpanded={setIsExpanded}
              ></TextWithShowMore>
            </NotesContent>
            {(assets || []).length > 0 ? (
              <>
                <ImagesContainer>
                  {(images || []).map((asset, index) => (
                    <ImageContainer key={getFileName(asset.url)}>
                      <Tooltip
                        key={asset.url}
                        id={`assetUrl-${index}`}
                        element={
                          <StyledImage
                            src={asset.thumbnailUrl || asset.url}
                            alt="image"
                            onClick={() => open(asset)}
                          />
                        }
                      >
                        {getFileName(asset.url)}
                      </Tooltip>
                    </ImageContainer>
                  ))}
                </ImagesContainer>
                {assetUrlsWithoutImages?.map((asset, index) => (
                  <FileContainer key={getFileName(asset.url)}>
                    <Tooltip
                      key={asset.url}
                      id={`assetUrl-${index}`}
                      element={
                        <File onClick={() => open(asset)}>
                          <Attachment />
                          <FileName>
                            {getFileName(asset.url).split(".")[0]}
                          </FileName>
                          <FileExtension>
                            .{getFileName(asset.url).split(".").pop()}
                          </FileExtension>
                        </File>
                      }
                    >
                      {getFileName(asset.url)}
                    </Tooltip>
                  </FileContainer>
                ))}
              </>
            ) : null}
          </>
        }
      ></Tooltip>
    </NotesContainer>
  );
};
