import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { BuyoutsBuyoutFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import tw from "tailwind-styled-components";
import { useBuyoutSequence } from "../hooks/useBuyoutSequence";
const Container = tw.div`
   flex
`;

export const BuyoutCheckbox: FC<{
  buyout: BuyoutsBuyoutFieldsFragment;
}> = ({ buyout }) => {
  const { sequenceIds, selectedEntities, setSelectedEntities } =
    useBuyoutSequence();
  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedEntities(
        newChecked
          ? [...selectedEntities, buyout]
          : selectedEntities.filter((d) => d.id !== buyout.id),
      );
    },
    [buyout, selectedEntities, setSelectedEntities],
  );

  return (
    <Container>
      <SelectionCheckbox
        itemId={buyout.id}
        items={sequenceIds}
        setSelection={handleChange}
      />
    </Container>
  );
};
