import { If } from "@/common/components/if/If";
import { useUser } from "@/common/providers/UserProvider";
import { InvoiceFieldsFragment } from "@/generated/graphql";
import { Checkbox, FormControlLabel } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { InvoiceIssueHeader } from "./common/InvoiceIssueHeader";
import { InvoiceIssuesTab } from "./common/InvoiceIssuesTab";
import { InvoiceKickbacks } from "./kickback/InvoiceKickbacks";
import { InvoiceVendorIssues } from "./vendor-issue/InvoiceVendorIssues";

const Container = tw.div`my-4`;
const Issues = tw.div`rounded-3xl mx-3 py-5 flex flex-col gap-2 px-4 bg-white`;
const VendorContainer = tw(Issues)<{ $hasTabs: boolean }>`
    ${({ $hasTabs }) => $hasTabs && "rounded-tl-none"}`;
const KickbackContainer = tw(Issues)<{ $hasTabs: boolean }>`
    ${({ $hasTabs }) => $hasTabs && "rounded-tr-none"}`;
const CheckboxContainer = tw.div<{ $hasIssues: boolean }>`flex 
${({ $hasIssues }) => $hasIssues && "justify-end"}`;

type Props = {
  invoice:
    | (Pick<InvoiceFieldsFragment, "id" | "issues"> & {
        kickbacks?: InvoiceFieldsFragment["kickbacks"];
      })
    | null;
  refetchInvoice?: () => Promise<void>;
};

export const InvoiceIssues: FC<Props> = ({ invoice, refetchInvoice }) => {
  const { isContractor } = useUser();
  const [showKickbacks, setShowKickbacks] = useState(
    invoice?.issues.length === 0 && !!invoice?.kickbacks?.length,
  );
  const [showIssues, setShowIssues] = useState(
    invoice?.kickbacks?.length === 0 && !!invoice?.issues.length,
  );
  const [kickbacksAssignedToMe, setKickbacksAssignedToMe] = useState(false);
  const intl = useIntl();
  const { viewer } = useUser();

  useEffect(() => {
    if (invoice?.kickbacks?.length && invoice.issues.length === 0) {
      setShowKickbacks(true);
    }
    if (invoice?.issues?.length && !invoice.kickbacks?.length) {
      setShowIssues(true);
    }
  }, [invoice?.kickbacks?.length, invoice?.issues?.length]);

  const handleCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setKickbacksAssignedToMe(event.target.checked);
    },
    [],
  );

  if (!invoice?.kickbacks?.length && !invoice?.issues?.length) {
    return null;
  }
  return (
    <Container>
      <InvoiceIssuesTab
        kickbacks={invoice?.kickbacks ?? []}
        issues={invoice?.issues ?? []}
        setShowKickbacks={setShowKickbacks}
        setShowIssues={setShowIssues}
        showKickbacks={showKickbacks}
        showIssues={showIssues}
      />
      <If isTrue={isContractor && showKickbacks && invoice?.kickbacks?.length}>
        <KickbackContainer $hasTabs={!!invoice.issues.length}>
          <InvoiceIssueHeader
            issues={invoice?.issues}
            kickbacks={invoice?.kickbacks}
          />
          <If
            isTrue={invoice?.kickbacks?.some((i) =>
              i.assignees.find((a) => a.id === viewer?.id),
            )}
          >
            <CheckboxContainer $hasIssues={invoice.issues.length > 0}>
              <FormControlLabel
                classes={{
                  label: "text-xs",
                }}
                control={
                  <Checkbox
                    checked={kickbacksAssignedToMe}
                    onChange={handleCheck}
                    size="small"
                  />
                }
                label={intl.$t({ id: "ASSIGNED_TO_ME_ONLY" })}
              />
            </CheckboxContainer>
          </If>
          <InvoiceKickbacks
            kickbacks={
              invoice?.kickbacks?.filter(
                (k) =>
                  !kickbacksAssignedToMe ||
                  k.assignees.some((a) => a.id === viewer?.id),
              ) ?? []
            }
            invoiceId={invoice?.id}
            refetchInvoice={refetchInvoice}
          />
        </KickbackContainer>
      </If>
      <If isTrue={showIssues && invoice?.issues.length}>
        <VendorContainer $hasTabs={!!invoice.kickbacks?.length}>
          <InvoiceIssueHeader
            issues={invoice?.issues}
            kickbacks={invoice?.kickbacks}
          />
          <InvoiceVendorIssues
            issues={invoice?.issues ?? []}
            canEdit={isContractor}
          />
        </VendorContainer>
      </If>
    </Container>
  );
};
