import { If } from "@/common/components/if/If";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { InvoiceSummaryFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import tw from "tailwind-styled-components";
import {
  InvoiceSequenceData,
  useInvoiceSequence,
} from "../../providers/InvoiceSequenceProvider";

const Container = tw.div`
  flex
`;

export const InvoiceCheckbox: FC<{
  invoice: InvoiceSummaryFieldsFragment;
}> = ({ invoice }) => {
  const { sequenceIds, setSelectedInvoices, selectedInvoices } =
    useInvoiceSequence();
  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedInvoices(
        newChecked
          ? ([...selectedInvoices, invoice] as InvoiceSequenceData[])
          : selectedInvoices.filter((i) => i.id !== invoice.id),
      );
    },
    [invoice, selectedInvoices, setSelectedInvoices],
  );

  return (
    <Container>
      <If isTrue={invoice.permissions.export}>
        <SelectionCheckbox
          itemId={invoice.id}
          items={sequenceIds}
          setSelection={handleChange}
        />
      </If>
    </Container>
  );
};
