import { ASSET_FIELDS } from "@/common/fragments/assetFragment";
import { gql } from "@apollo/client";

export const QUOTE_DOCUMENT_SUMMARY_FIELDS = gql`
  ${ASSET_FIELDS}
  fragment QuoteDocumentSummaryFields on QuoteDocument {
    id
    asset {
      ...AssetFields
    }
  }
`;
