import { getUserName } from "@/common/utils/users/getUserName";
import { AvatarGroup } from "@mui/material";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { ShowMoreCounter } from "../show-more-counter/ShowMoreCounter";
import { UserAvatar, UserAvatarProps } from "../user-avatar/UserAvatar";

type Props = {
  users: UserAvatarProps[];
  max?: number;
};

const AvatarContainer = tw.div`flex flex-wrap pb-2`;
const AvatarNameContainer = tw.div`flex items-center pl-2`;
const UserAvatarStyled = tw(UserAvatar)`
  bg-blue-100 text-black w-9 h-9 p-0
`;
const AvatarGroupStyled = tw(AvatarGroup)`
  grid
  ${({ $singleElement }: { $singleElement: boolean }) =>
    $singleElement
      ? "grid-cols-[repeat(2,_30px)]"
      : "grid-cols-[repeat(3,_30px)]"}
`;

export const UserAvatarGroup: FC<Props> = ({ users, max = 3 }: Props) => {
  const mapUsersToAvatar = (user: UserAvatarProps, key: number) => (
    <UserAvatarStyled
      key={key}
      firstName={user.firstName}
      lastName={user.lastName}
      email={user.email}
      photoUrl={user.photoUrl}
    />
  );

  return (
    <AvatarGroupStyled $singleElement={users.length === 1}>
      {users.length < max && users.map(mapUsersToAvatar)}
      {users.length >= max && users.slice(0, max - 1).map(mapUsersToAvatar)}
      {users.length >= max && (
        <ShowMoreCounter
          count={users.length - max + 1}
          classes={{ popover: "order-last", element: "ml-2" }}
        >
          {users.slice(max - 1, users.length).map((user, key) => (
            <AvatarContainer key={key}>
              <UserAvatarStyled
                firstName={user.firstName}
                lastName={user.lastName}
                email={user.email}
                photoUrl={user.photoUrl}
              />
              <AvatarNameContainer>{getUserName(user)}</AvatarNameContainer>
            </AvatarContainer>
          ))}
        </ShowMoreCounter>
      )}
    </AvatarGroupStyled>
  );
};
