import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { Price } from "@/common/components/price/Price";
import { getDiscountByAmount } from "@/common/components/price/utils/getDiscountByAmount";
import { getDiscountByPercentage } from "@/common/components/price/utils/getDiscountByPercentage";
import { sumQuoteGroupFullPrices } from "@/common/components/price/utils/sumQuoteGroupFullPrices";
import { TOTAL_PRICE_DECIMAL_POINTS } from "@/common/const";
import { RfqStatus } from "@/generated/graphql";
import Decimal from "decimal.js";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { CancelBuyoutButton } from "../../../../buyout/components/common/CancelBuyoutButton";
import { CancelRfqButton } from "../../../../rfq/components/buttons/CancelRfqButton";
import { RequestChangesButton } from "../../../../rfq/components/buttons/RequestChangesButton";
import { useRfqQuoteOptions } from "../../../providers/RfqQuoteOptionsProvider";
import { useRfqQuotes } from "../../../providers/RfqQuotesProvider";
import { AcceptQuotesButton } from "./AcceptQuotesButton";

const Container = tw.div`
  flex flex-row self-start justify-self-end right-12 
  justify-end col-span-full gap-2 mt-36 p-4 bg-white
`;

const TotalAmountContainer = tw.div`
  grid grid-cols-auto-auto justify-items-end gap-x-5 gap-y-1 pt-3 pl-3 pr-1
`;

const ButtonContainer = tw.div`
  flex self-start justify-self-end right-12 justify-end space-x-1
`;

const Label = tw.div`
  font-light text-left w-full
`;

const Value = tw.div`
  font-medium text-right
`;

export const TotalAmount = () => {
  const {
    rfq,
    selectedQuotes,
    selectedAuxiliaryQuoteItems,
    cancelQuote,
    createDraftQuote,
    allQuoteItems,
  } = useRfqQuotes();
  const { visibleQuotes } = useRfqQuoteOptions();
  const countBuyouts = useMemo(() => {
    return visibleQuotes.reduce((acc, quote) => {
      return (
        acc +
        (selectedQuotes.some((selected) =>
          quote.itemGroups.some((group) =>
            group.quoteItems.some(
              (quoteItem) => selected.quoteItemId === quoteItem.id,
            ),
          ),
        )
          ? 1
          : 0)
      );
    }, 0);
  }, [visibleQuotes, selectedQuotes]);

  const total: number = useMemo(() => {
    return (
      visibleQuotes.reduce((prevValue, quote) => {
        let discount = new Decimal(0);
        const sum =
          allQuoteItems
            .filter((qi) =>
              selectedQuotes.some(
                (selected) =>
                  selected.quoteItemId === qi.id && quote.id === qi.quoteId,
              ),
            )
            .reduce((prevValue, item) => {
              const price = new Decimal(item.unitPrice || 0).mul(
                item.quantityDecimal || 0,
              );
              return Number(price.add(new Decimal(prevValue)));
            }, 0) +
          selectedAuxiliaryQuoteItems.reduce((prevValue, item) => {
            const auxItem = rfq?.quotes
              .map((q) => q.auxiliaryItems)
              .flat()
              .find(
                (aux) => aux.id === item.itemId && quote.id === item.quoteId,
              );
            const price = new Decimal(auxItem?.unitPrice || 0).mul(
              auxItem?.quantityDecimal || 0,
            );
            return Number(price.add(new Decimal(prevValue)));
          }, 0);

        if (quote.discount) {
          if (quote.discount.percentage) {
            discount = getDiscountByPercentage(sum, quote.discount.percentage);
          }
          if (quote.discount.amount) {
            discount = getDiscountByAmount(
              quote.discount.amount ?? "0",
              quote.itemGroups.reduce(sumQuoteGroupFullPrices, 0),
            ).mul(sum);
          }
        }
        return Number(new Decimal(sum).sub(discount).add(prevValue));
      }, 0) ?? 0
    );
  }, [
    rfq,
    visibleQuotes,
    selectedQuotes,
    allQuoteItems,
    selectedAuxiliaryQuoteItems,
  ]);

  const isDisabled = useMemo(() => {
    return !selectedQuotes.length;
  }, [selectedQuotes]);

  return (
    <>
      <Container>
        <TotalAmountContainer>
          <Label>
            <FormattedMessage id="TOTAL_BUYOUTS" />:
          </Label>
          <Value>{countBuyouts}</Value>
          <Label>
            <FormattedMessage id="TOTAL_COST" />:
          </Label>
          <Value>
            <Price
              price={total}
              maximumFractionDigits={TOTAL_PRICE_DECIMAL_POINTS}
            />
          </Value>
        </TotalAmountContainer>
      </Container>
      <FloatingFooter>
        <ButtonContainer>
          <If
            isTrue={
              (rfq?.status === RfqStatus.Active ||
                rfq?.status === RfqStatus.Awarded) &&
              !rfq.quotes.some((quote) => quote.buyout)
            }
          >
            <CancelRfqButton cancelRfq={cancelQuote} rfq={rfq} />
          </If>
          <CancelBuyoutButton
            isVisible={rfq?.status === RfqStatus.Awarded}
            rfqId={rfq?.id}
            rfqNumber={rfq?.clientIdentifier}
          />
          <If isTrue={rfq?.status !== RfqStatus.Awarded}>
            <RequestChangesButton rfq={rfq} createRfqDraft={createDraftQuote} />
            <AcceptQuotesButton rfq={rfq} isDisabled={isDisabled} />
          </If>
        </ButtonContainer>
      </FloatingFooter>
    </>
  );
};
