import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  UpdateOrgSettingsInput,
  useUpdateReleaseApprovalMutation,
} from "@/generated/graphql";

export const useOrderApprovalActions = () => {
  const { setError } = useGlobalError();

  const [updateReleaseApprovalMutation, { loading: updating }] =
    useUpdateReleaseApprovalMutation();

  const updateReleaseApproval = async (input: UpdateOrgSettingsInput) => {
    try {
      const { errors } = await updateReleaseApprovalMutation({
        variables: { input },
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return {
    updateReleaseApproval,
    updating,
  };
};
