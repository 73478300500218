import { Controller, useFormContext } from "react-hook-form";
import { ControlledProps } from "../../../textfield-controlled/TextFieldControlled";
import { Select, SelectProps } from "./Select";

type SelectControlledProps<T> = ControlledProps & SelectProps<T>;

export const SelectControlled = <T,>({
  name,
  rules,
  ...props
}: SelectControlledProps<T>) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Select<T>
          {...field}
          {...props}
          error={!!fieldState.invalid}
          className={`rounded bg-white ${props.staticText && "ml-3"} ${
            props.className
          }`}
          placeholder={props.placeholder}
          testId={name}
          required={!!rules?.required}
        />
      )}
    />
  );
};
