import { ArrowForwardIos } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { Fragment } from "react/jsx-runtime";
import tw from "tailwind-styled-components";
import { Content, Item, Label } from "../../Integrations.styles";

const ArrowForwardIosStyled = tw(ArrowForwardIos)`h-3 w-3`;
const Variant = tw.div`flex py-2 gap-1`;
const Text = tw.div`font-extralight text-gray-600`;
const Value = tw.div`font-medium`;
const Options = tw.div`grid grid-cols-[50px_20px_150px] gap-y-2`;
const Source = tw.div`text-2xs text-gray-600`;
const Arrow = tw.div`text-2xs text-gray-600`;
const Target = tw.div`text-2xs text-gray-600`;

export const VariantListInfo = ({
  variant,
  options,
}: {
  variant: string;
  options: { source: string; target: string }[];
}) => {
  return (
    <Item>
      <div></div>
      <Content>
        <Label>
          <FormattedMessage id="MAPPING" />
        </Label>
        <Variant>
          <Text>
            <FormattedMessage id="VARIANT" />:
          </Text>
          <Value>{variant}</Value>
        </Variant>
        <Options>
          {options.map((option) => (
            <Fragment key={option.source}>
              <Source>{option.source}</Source>
              <Arrow>
                <ArrowForwardIosStyled />
              </Arrow>
              <Target>{option.target}</Target>
            </Fragment>
          ))}
        </Options>
      </Content>
    </Item>
  );
};
