import { Breadcrumbs } from "@/common/components/breadcrumbs/Breadcrumbs";
import {
  QuestionBox,
  QuestionText,
} from "@/common/components/messages/Messages.styles";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { VendorQuoteMessagesButton } from "@/distributor/common/quote-messages/VendorQuoteMessagesButton";
import { QuoteInfoHeader } from "@/distributor/common/quote/QuoteInfoHeader";
import {
  DistributorQuoteCommonFieldsFragment,
  DistributorQuoteFieldsFragment,
  MessageContextKind,
} from "@/generated/graphql";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { Headline } from "./DistributorQuote.styles";
import { DistributorQuoteHeaderDetails } from "./components/DistributorQuoteHeaderDetails";
import { DistributorQuotePrintButton } from "./components/DistributorQuotePrintButton";
import { QuoteActionsProvider } from "./providers/QuoteActionsProvider";

type DistributorQuoteBaseProps = {
  quote?: DistributorQuoteCommonFieldsFragment | null;
  children: React.ReactNode;
};

const Container = tw.div`flex gap-4`;
const DistributorQuoteContainer = tw.div``;

const DistributorQuoteBaseWithProvider: FC<DistributorQuoteBaseProps> = ({
  quote,
  children,
}) => {
  if (!quote) {
    return null;
  }

  return (
    <DistributorQuoteContainer data-testid="distributor-quote">
      <Breadcrumbs
        items={[
          {
            id: "quoteNumber",
            text: (
              <Headline>
                <FormattedMessage
                  id="QUOTE_HEADLINE"
                  values={{ number: quote.clientIdentifier }}
                />
                <DistributorQuoteHeaderDetails quote={quote} />
              </Headline>
            ),
          },
        ]}
        actions={[
          <Container key={1}>
            <DistributorQuotePrintButton
              quote={quote as DistributorQuoteFieldsFragment}
            />
            <VendorQuoteMessagesButton
              quote={quote}
              org={quote.rfq.project.location.org}
              text={
                <QuestionBox>
                  <QuestionText>
                    <FormattedMessage id="QUOTE_HEADLINE_MESSAGE" />
                  </QuestionText>
                  <MessageButton
                    id={quote.id}
                    kind={MessageContextKind.Quote}
                  />
                </QuestionBox>
              }
            />
          </Container>,
        ]}
      />
      <QuoteInfoHeader quote={quote} />
      {children}
    </DistributorQuoteContainer>
  );
};

export const DistributorQuoteBase: FC<DistributorQuoteBaseProps> = ({
  children,
  ...props
}) => (
  <QuoteActionsProvider>
    <DistributorQuoteBaseWithProvider {...props}>
      {children}
    </DistributorQuoteBaseWithProvider>
  </QuoteActionsProvider>
);
