import { useEnterpriseUsersLazyQuery } from "@/generated/graphql";
import { useCallback } from "react";

export const useEnterpriseUsers = () => {
  const [loadUsers, { data }] = useEnterpriseUsersLazyQuery({
    fetchPolicy: "no-cache",
  });

  const fetchUsers = useCallback(() => {
    if (!data) {
      loadUsers();
    }
  }, [data, loadUsers]);

  return {
    users: data?.viewer?.enterprise?.users,
    fetchUsers,
  };
};
