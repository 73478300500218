import { If } from "@/common/components/if/If";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import {
  ControlledProps,
  TextFieldControlled,
} from "@/common/components/textfield-controlled/TextFieldControlled";
import { DEFAULT_ADDRESS_STATE } from "@/common/const";
import { isDefaultCountry } from "@/common/utils/isDefaultCountry";
import { usaStates } from "@/common/utils/usaStates";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

type Props = ControlledProps & {
  countryInputName: string;
  useDefaultCountryAndState?: boolean;
};

export const StateSelectorControlled: FC<Props> = ({
  countryInputName,
  useDefaultCountryAndState = true,
  ...props
}) => {
  const intl = useIntl();
  const { watch, setValue } = useFormContext();

  const country = watch(countryInputName);

  useEffect(() => {
    if (useDefaultCountryAndState) {
      if (!isDefaultCountry(country)) {
        setValue(props.name, "");
      } else {
        setValue(props.name, DEFAULT_ADDRESS_STATE);
      }
    }
  }, [country, props.name, setValue, useDefaultCountryAndState]);

  return (
    <>
      <If isTrue={isDefaultCountry(country)}>
        <SelectControlled
          {...props}
          placeholder={intl.$t({ id: "ADDRESS_STATE" })}
          options={usaStates}
          rules={{ required: true }}
          getLabel={(option) => option.abbreviation}
          getValue={(option) => option.abbreviation}
        />
      </If>
      <If isTrue={!isDefaultCountry(country)}>
        <TextFieldControlled
          {...props}
          rules={{ required: false }}
          label={intl.$t({ id: "ADDRESS_STATE" })}
        />
      </If>
    </>
  );
};
