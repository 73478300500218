import { If } from "@/common/components/if/If";
import {
  InvoiceSummaryFieldsFragment,
  ReceiptSummaryFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-col items-center justify-center text-center`;
const Details = tw.div`text-2xs`;
const OrderStatus = tw.div`text-sm`;

type Props = {
  item: InvoiceSummaryFieldsFragment | ReceiptSummaryFieldsFragment;
};

export const InvoiceOrderStatus: FC<Props> = ({ item }) => {
  const intl = useIntl();
  const data = useMemo(() => {
    return {
      name: item.release?.status
        ? intl.$t({ id: `RELEASE_STATUS_${item.release?.status}` })
        : null,
      details: null,
    };
  }, [intl, item.release?.status]);
  return (
    <Container>
      <OrderStatus>{data.name}</OrderStatus>
      <If isTrue={data.details}>
        <Details>{data.details}</Details>
      </If>
    </Container>
  );
};
