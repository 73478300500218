import { hasProperty } from "@/common/utils/objectUtils";
import { PageRangeWithExcludedPages } from "../components/splitting-invoices/SplittingInvoicesWizardProvider";

export const usePageRangeManipulation = () => {
  const pushIfNotExists = (
    newPageRanges: Array<PageRangeWithExcludedPages>,
    pageRange: PageRangeWithExcludedPages,
  ) => {
    if (
      !newPageRanges.some(
        (p) =>
          (p.start <= pageRange.start && p.end >= pageRange.end) ||
          p.end === pageRange.start,
      )
    ) {
      if (hasProperty(pageRange, "excluded")) {
        newPageRanges.push(pageRange);
      } else {
        newPageRanges.push({ ...pageRange, excluded: false });
      }
      return true;
    }
    return false;
  };

  const excludePages = (
    pageRanges: PageRangeWithExcludedPages[],
    selectedPages: number[],
  ) => {
    const newPageRanges: Array<PageRangeWithExcludedPages> = [];
    pageRanges.forEach((pageRange) => {
      if (
        pageRange.start === pageRange.end &&
        selectedPages.includes(pageRange.start)
      ) {
        pushIfNotExists(newPageRanges, {
          start: pageRange.start,
          end: pageRange.end,
          excluded: true,
        });
      }
      if (
        !selectedPages.some(
          (page) => pageRange.start <= page && page <= pageRange.end,
        )
      ) {
        pushIfNotExists(newPageRanges, pageRange);
        return;
      }
      if (
        pageRange.start !== pageRange.end &&
        selectedPages.some(
          (page) => pageRange.start <= page && page <= pageRange.end,
        )
      ) {
        const newPageRange = {
          start: pageRange.start,
          end: Math.min(...selectedPages) - 1,
        };
        const disabledPageRange = {
          start: Math.min(
            ...selectedPages.filter(
              (p) => p >= pageRange.start && p <= pageRange.end,
            ),
          ),
          end: Math.max(...selectedPages),
          excluded: true,
        };
        const nextPageRange = {
          start: Math.max(...selectedPages) + 1,
          end: pageRange.end,
        };
        if (
          newPageRange.start <= newPageRange.end &&
          Math.min(...selectedPages) >= newPageRange.end
        ) {
          pushIfNotExists(newPageRanges, newPageRange);
        }
        pushIfNotExists(newPageRanges, disabledPageRange);
        if (Math.max(...selectedPages) < pageRange.end) {
          pushIfNotExists(newPageRanges, nextPageRange);
        }
      }
    });

    return newPageRanges;
  };

  const mergePages = (
    pageRanges: PageRangeWithExcludedPages[],
    selectedPages: number[],
  ) => {
    const newPageRanges: Array<PageRangeWithExcludedPages> = [];
    const min = Math.min(...selectedPages);
    const max = Math.max(...selectedPages);
    pageRanges.forEach((pageRange, index) => {
      if (pageRange.start === min && pageRange.end === max) {
        pushIfNotExists(newPageRanges, {
          start: pageRange.start,
          end: pageRange.end,
        });
      } else if (pageRange.start === min) {
        pushIfNotExists(newPageRanges, {
          start: min,
          end: max,
        });
        if (
          (pageRange.end !== max && pageRanges.length - 1 === index) ||
          !pageRanges[index + 1].excluded
        ) {
          const end = Math.max(
            pageRanges.length - 1 !== index
              ? pageRanges[index + 1].end
              : max + 1,
            max + 1,
          );
          if (pageRanges[pageRanges.length - 1].end >= end) {
            pushIfNotExists(newPageRanges, {
              start: max + 1,
              end,
            });
          }
        }
      } else if (
        pageRange.end === min &&
        !newPageRanges.some(
          (p) => p.start >= pageRange.start && p.end <= min - 1,
        )
      ) {
        pushIfNotExists(newPageRanges, {
          start: pageRange.start,
          end: min - 1,
        });
        if (
          pageRanges.length - 1 !== index ||
          max + 1 < pageRanges[index].end
        ) {
          pushIfNotExists(newPageRanges, {
            start: min,
            end:
              pageRanges.length - 1 !== index
                ? pageRanges[index + 1].end
                : max + 1,
          });
        }
      } else {
        pushIfNotExists(newPageRanges, pageRange);
      }
    });

    return newPageRanges;
  };

  return { excludePages, mergePages, pushIfNotExists };
};
