import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { useRoles } from "@/common/components/org-roles-wrapper/hasRoles";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useUpdateOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useUpdateOrgSettings";
import { OrgRole, UpdateOrgInput } from "@/generated/graphql";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  Container,
  FloatingFooterStyled,
  Item,
} from "../../common/Organization.styles";
import { OrderNotificationsSelector } from "./order-notifications-management/OrderNotificationsSelector";
import { PoNumberingSelector } from "./po-management/PoNumberingSelector";

const ItemContainer = tw.div`flex flex-1 items-center justify-between`;
const SwitchContainer = tw.div`flex items-center space-x-2`;
const Label = tw.div``;

export const OrgSettingsForm = () => {
  const intl = useIntl();
  const { updating, updateOrgSettings } = useUpdateOrgSettings();
  const { setSuccessAlert } = useSnackbar();
  const { hasRoles } = useRoles({ roles: [OrgRole.OrgRestrictedAdmin] });
  const { handleSubmit, formState, reset } = useFormContext<UpdateOrgInput>();

  const onSaveHandler = useCallback(
    async (values: UpdateOrgInput) => {
      let result = null;
      if (values.id) {
        result = await updateOrgSettings({
          id: values.id,
          display: {
            enableManufacturers:
              values.settings?.display?.enableManufacturers ?? false,
          },
          releases: {
            poNumbering: values.settings?.releases?.poNumbering,
            notifications: values.settings?.releases?.notifications,
          },
        });
      }

      if (!result) {
        return;
      }
      setSuccessAlert(intl.$t({ id: "ORG_SETTINGS_UPDATE_SUCCESS" }));
    },
    [setSuccessAlert, intl, updateOrgSettings],
  );

  return (
    <Container>
      <If isTrue={!hasRoles}>
        <Item>
          <ItemContainer>
            <Label>
              <FormattedMessage id="ENABLE_MANUFACTURERS_ASSIGNMENT" />
            </Label>
            <SwitchContainer>
              <InfoTooltip
                message={intl.$t({
                  id: "ENABLE_MANUFACTURERS_ASSIGNMENT_TOOLTIP",
                })}
              />
              <SwitchControlled
                name="settings.display.enableManufacturers"
                width={60}
                offLabel={intl.$t({ id: "NO" })}
                onLabel={intl.$t({ id: "YES" })}
              />
            </SwitchContainer>
          </ItemContainer>
        </Item>
      </If>
      <Item>
        <ItemContainer>
          <Label>
            <FormattedMessage id="PO_MANAGEMENT" />
          </Label>
          <PoNumberingSelector />
        </ItemContainer>
      </Item>
      <Item>
        <ItemContainer>
          <Label>
            <FormattedMessage id="ORDER_NOTIFICATION" />
          </Label>
          <OrderNotificationsSelector />
        </ItemContainer>
      </Item>
      <Item>
        <FloatingFooterStyled>
          <If isTrue={formState.isDirty}>
            <OutlinedButton wide onClick={() => reset()}>
              <FormattedMessage id="CANCEL" />
            </OutlinedButton>
          </If>
          <PrimaryButton
            onClick={handleSubmit(onSaveHandler)}
            loading={updating}
            wide
          >
            <FormattedMessage id="SAVE" />
          </PrimaryButton>
        </FloatingFooterStyled>
      </Item>
    </Container>
  );
};
