import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import { useReceiptCreateReleaseHeader } from "@/contractor/pages/home/receipts/pages/receipt-record-order/stores/useReceiptCreateReleaseHeader";
import {
  ReceiptFieldsFragment,
  usePredictedReceiptNumberLazyQuery,
} from "@/generated/graphql";
import { AutoFixNormalOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { LinkLike } from "../../link-like/LinkLike";

interface ReceiptMagicWandProps {
  readonly: boolean;
  receipt: ReceiptFieldsFragment;
  onSave: (receiptNumber: string) => void;
}

export const ReceiptMagicWand = ({
  readonly,
  receipt,
  onSave,
}: ReceiptMagicWandProps) => {
  const { projects } = useProjectListOptions();
  const { vendors } = useVendors();
  const projectId = useReceiptCreateReleaseHeader((state) => state.projectId);
  const vendorId = useReceiptCreateReleaseHeader((state) => state.vendorId);
  const [fetchPredictedReceiptNumber, { loading }] =
    usePredictedReceiptNumberLazyQuery({
      fetchPolicy: "network-only",
    });
  const { setError } = useGlobalError();

  const selectedProject = useMemo(
    () =>
      projects.find(
        (project) => project.id === (receipt.project?.id || projectId),
      ),
    [projectId, projects, receipt.project?.id],
  );

  const selectedVendor = useMemo(
    () =>
      vendors.find(
        (vendor) =>
          vendor.sellerOrgLocation.id ===
          (receipt.release?.sellerOrgLocation?.id || vendorId),
      ),
    [vendorId, vendors, receipt.release?.sellerOrgLocation?.id],
  );

  const generateReceiptNumber = async () => {
    if (!selectedProject || !selectedVendor) {
      return;
    }
    try {
      const { data, error } = await fetchPredictedReceiptNumber({
        fetchPolicy: "network-only",
        variables: {
          projectId: selectedProject.id,
          sellerOrgLocationId: selectedVendor.sellerOrgLocation.id,
        },
      });
      if (error) {
        setError(error);
        return;
      }
      if (data?.predictedReceiptNumber) {
        onSave(data?.predictedReceiptNumber);
      }
    } catch (error) {
      setError(error);
    }
  };

  if (readonly || !selectedProject || !selectedVendor) {
    return null;
  }

  return (
    <LinkLike
      className="ml-auto mr-2"
      onClick={generateReceiptNumber}
      disabled={loading}
    >
      <AutoFixNormalOutlined />
    </LinkLike>
  );
};
