import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { useMemo } from "react";
import { useAgaveHostedWizard } from "../../../AgaveHostedWizardProvider";
import { AgaveFinalStep } from "../../../steps/final/AgaveFinalStep";

type Props = {
  hideHeader: boolean;
};

export const useAgaveFinalStep = (
  { hideHeader = false }: Props = { hideHeader: false },
) => {
  const { closeWizard } = useAgaveHostedWizard();

  const agaveConnectionPage: WizardModalPage = useMemo(
    () => ({
      title: null,
      pages: [
        {
          component: <AgaveFinalStep />,
          onCloseClick: closeWizard,
          hideHeader,
        },
      ],
    }),
    [closeWizard, hideHeader],
  );

  return agaveConnectionPage;
};
