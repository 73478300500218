import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { RfqsRfqFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { QuotesOutlinedButton } from "../../Rfqs.styles";
import { RfqVendorsProvider } from "../../providers/RfqVendorsProvider";
import {
  RfqVendorsDetailsProps,
  RfqVendorsWithProvider,
} from "../../providers/RfqVendorsWithProvider";

type RfqVendorsProps = {
  rfq: RfqsRfqFieldsFragment;
};

export const QuotesRequested: FC<RfqVendorsProps> = ({ rfq }) => {
  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <NotNullableRenderer value={rfq.numBiddersRfqSentTo}>
          <QuotesOutlinedButton onClick={() => togglePanel(true)}>
            {rfq.numBiddersRfqSentTo || 0}
          </QuotesOutlinedButton>
        </NotNullableRenderer>
      )}
      content={(togglePanel) => (
        <RfqVendorsDetails rfq={rfq} onClose={() => togglePanel(false)} />
      )}
    />
  );
};

const RfqVendorsDetails: FC<RfqVendorsDetailsProps> = (props) => {
  return (
    <RfqVendorsProvider rfqId={props.rfq.id}>
      <RfqVendorsWithProvider {...props} />
    </RfqVendorsProvider>
  );
};
