import { GridTable } from "@/common/components/grid-table/GridTable";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { isUUID } from "@/common/utils/uuidUtils";
import { OrgMaterialFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import tw from "tailwind-styled-components";
import { useMaterialPrices } from "../providers/MaterialPricesProvider";
import { useMaterialPricesConfiguration } from "./MaterialPriceList.configuration";

const Container = tw.div`mb-10`;

type Props = {
  materials: OrgMaterialFieldsFragment[];
  loading: boolean;
  totalCount: number;
  isFiltered: boolean;
};

export const MaterialPriceList: FC<Props> = ({
  materials,
  loading,
  totalCount,
  isFiltered,
}) => {
  const { configuration } = useMaterialPricesConfiguration();
  const { isPriceSubmitted } = useMaterialPrices();

  const getExpandedItems = useCallback((item: OrgMaterialFieldsFragment) => {
    if (!item.vendorPrices || item.vendorPrices.length === 1) {
      return [];
    } else {
      return item.vendorPrices.map(() => item);
    }
  }, []);

  return (
    <Container>
      <ListRenderer totalCount={totalCount} count={materials.length} paginated>
        <GridTable
          configuration={{
            columns: configuration,
            classNames: {
              itemContent: "grid-cols-[50px_repeat(5,_auto)]",
              header: "top-[132px]",
              itemFn: (item, category, index, editMode) => {
                return {
                  className: `
                    -my-1 border-blue-100
                    ${editMode ? "bg-blue-100" : "bg-white"} 
                  `,
                };
              },
            },
            inEditModeFn: (item, index, editMode) => {
              const price = item.vendorPrices[index ?? 0];
              return editMode || (price && !isUUID(price.id));
            },
            hasRowError: (item) => isPriceSubmitted(item.id),
            hasCardItems: true,
          }}
          items={materials}
          loading={loading}
          expandedItems={getExpandedItems}
          hideFirstDetail
          emptyList={
            <NoResults
              isFiltered={isFiltered}
              filteredTranslationKey="MATERIALS_LIST_NO_MATERIALS_FILTERED"
              translationKey="MATERIALS_LIST_NO_MATERIALS"
            />
          }
        />
      </ListRenderer>
    </Container>
  );
};
