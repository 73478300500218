import {
  ReleaseFieldsFragment,
  ReleaseItemFieldsFragment,
} from "@/generated/graphql";
import {
  NoFunctionBooleanPromise,
  NoFunctionUndefined,
} from "@/types/NoFunction";
import { FC, createContext, useContext } from "react";
import { useRelease } from "../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";

type ProviderContextType = {
  addToRelease: (input: {
    masterSkuId?: string;
    masterProductId?: string;
    description?: string;
    orgCatalogSkuId?: string;
    estimateUom: string;
  }) => Promise<boolean>;
  removeFromRelease: (itemId: string) => Promise<boolean>;
  findItem: (id: string) => ReleaseItemFieldsFragment | undefined;
  count: number;
  release: ReleaseFieldsFragment | null;
};

const ProviderContext = createContext<ProviderContextType>({
  addToRelease: NoFunctionBooleanPromise,
  removeFromRelease: NoFunctionBooleanPromise,
  findItem: NoFunctionUndefined,
  count: 0,
  release: null,
});

export const ReleaseShoppingCartProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { release, removeItemFromRelease } = useRelease();
  const { addItems } = useReleaseUpdate();

  const addToRelease = async (input: {
    masterSkuId?: string;
    masterProductId?: string;
    description?: string;
    estimateUom: string;
  }) => {
    await addItems([
      {
        projectItem: {
          masterProductId: input.masterProductId,
          masterSkuId: input.masterSkuId,
          estimateUom: input.estimateUom,
        },
        quantityDecimal: "1",
        position: release?.items.length || 0,
      },
    ]);

    return true;
  };

  const removeFromRelease = (itemId: string) => {
    return removeItemFromRelease([itemId]);
  };

  const findItem = (
    materialId: string,
  ): ReleaseItemFieldsFragment | undefined => {
    if (release) {
      return release.items.find(
        (item) => item.projectItem?.material.material?.id === materialId,
      );
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        release: release || null,
        addToRelease,
        findItem,
        removeFromRelease,
        count: release?.items?.length || 0,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReleaseShoppingCart = (): ProviderContextType =>
  useContext(ProviderContext);
