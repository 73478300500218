import { If } from "@/common/components/if/If";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { ControlledProps } from "@/common/components/textfield-controlled/TextFieldControlled";
import { useUserLocations } from "@/common/hooks/useUserLocations";
import {
  AuthorizationStatus,
  ViewerOrgLocationFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

export const ProjectLocationSelector: FC<ControlledProps> = (props) => {
  const { locations } = useUserLocations();
  const intl = useIntl();
  const { setValue } = useFormContext();

  const disabledFn = useCallback(
    (location: ViewerOrgLocationFieldsFragment) =>
      location.permissions?.createProject !== AuthorizationStatus.Authorized,
    [],
  );

  useEffect(() => {
    if (locations.filter((l) => !disabledFn?.(l)).length === 1) {
      setValue(props.name, locations.filter((l) => !disabledFn(l))[0].id);
    }
  }, [locations, setValue, props.name, disabledFn]);

  return (
    <If isTrue={locations && locations.length !== 1}>
      <SelectControlled
        {...props}
        testId="form-locations-selector"
        placeholder={intl.$t({ id: "PROJECT_LOCATION" })}
        options={locations || []}
        rules={{ required: true }}
        getLabel={(option) => option.name}
        getValue={(option) => option.id}
        noOptionsText={intl.$t({ id: "NO_LOCATIONS_FOUND" })}
        getOptionDisabled={disabledFn}
        disabledTooltip={intl.$t({ id: "ORG_LOCATION_PROJECT_PERMISSION" })}
      />
    </If>
  );
};
