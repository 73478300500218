import { extPriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import Decimal from "decimal.js";

export const sumAuxiliaryQuoteFullPrices = (
  acc: number,
  item: {
    unitPrice?: string | null | undefined;
    quantityDecimal?: string | null;
  },
) => {
  const unitPrice = new Decimal(item.unitPrice || 0).toString();

  return new Decimal(extPriceCalculation(item.quantityDecimal || 0, unitPrice))
    .add(new Decimal(acc))
    .toNumber();
};
