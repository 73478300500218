import { DiscardButton } from "@/common/components/button/DiscardButton";
import { EditButton } from "@/common/components/button/EditButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { GroupToggle } from "@/common/components/group-toggle/GroupToggle";
import { If } from "@/common/components/if/If";
import { InvoiceKickbackForm } from "@/common/components/invoices/invoice-issue/kickback/InvoiceKickbackForm";
import { InvoiceVendorIssueForm } from "@/common/components/invoices/invoice-issue/vendor-issue/InvoiceVendorIssueForm";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useUser } from "@/common/providers/UserProvider";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { useInvoiceIssues } from "@/contractor/pages/home/invoices/pages/invoice-verification/hooks/useInvoiceIssues";
import { useInvoiceKickbacks } from "@/contractor/pages/home/invoices/pages/invoice-verification/hooks/useInvoiceKickbacks";
import {
  AssetFieldsFragment,
  InvoiceKickbackFieldsFragment,
  UsersUserFieldsFragment,
} from "@/generated/graphql";
import { Attachment, CheckCircle } from "@mui/icons-material";
import { FC, useCallback, useMemo, useState } from "react";
import { Form, FormProvider, useForm } from "react-hook-form";
import { BsExclamationCircle, BsExclamationCircleFill } from "react-icons/bs";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { InvoiceRespondForm, ResponseType } from "./InvoiceRespondForm";
import { IssueContent } from "./IssueContent";

const IssueContainer = tw.div`flex flex-row`;
const IssueView = tw.div`grid grid-flow-row content-start items-center bg-white rounded-l-3xl border px-4 py-2 font-normal text-sm w-full`;
const IssueResolution = tw(
  IssueView,
)`bg-green-300 rounded-r-3xl rounded-l-none`;
const IssueRejection = tw(
  IssueView,
)`bg-yellow-400 rounded-r-3xl rounded-l-none`;
const IssueViewHeader = tw.div`flex md:flex-row items-center justify-between font-medium flex-col`;
const RedExclamationCircle = tw(
  BsExclamationCircleFill,
)`text-red-500 mr-2 text-xl`;
const ExclamationCircle = tw(BsExclamationCircle)<{
  $hasDescription: boolean;
}>`mr-2 text-xl
  ${({ $hasDescription }) =>
    $hasDescription ? "text-gray-500" : "text-red-500"}
`;
const GreenCheckmarkCircle = tw(CheckCircle)`text-green-800 mr-1.5 text-2xl`;
const IssueDate = tw.div`text-2xs font-normal text-right`;
const IssueHeaderItem = tw.div`flex flex-row items-center text-sm`;
const IssueTitle = tw.div`flex flex-col`;
const Users = tw.div`text-xs font-normal`;
const ButtonContainer = tw.div`flex justify-end gap-4`;
const LinkLikeStyled = tw(LinkLike)`text-sm font-normal`;
const IssueDescriptionContainer = tw.div<{ $isAssignee: boolean }>`
  grid grid-flow-col items-baseline gap-2
  ${({ $isAssignee }) => $isAssignee && "grid-cols-[auto_100px]"}
`;
const SuccessContainer = tw.div`flex flex-col gap-2 items-center`;
const SuccessDetails = tw.div`text-sm font-normal`;
const SuccessTitle = tw.div`font-medium text-lg`;
const AttachmentStyled = tw(Attachment)`text-blue-500`;
const AttachmentsInfo = tw.div`grid justify-center grid-flow-col items-center gap-2 mx-2`;

export enum InvoiceIssueType {
  ISSUE = "ISSUE",
  KICKBACK = "KICKBAcK",
}

type Props = {
  type: InvoiceIssueType;
  resolutionDescription: string | null | undefined;
  issueDescription: string | null | undefined;
  rejectionReason?: string | null | undefined;
  issueDate: number | null | undefined;
  issueResponseDate: number | null | undefined;
  issueBy?: string;
  assignees?: Pick<UsersUserFieldsFragment, "firstName" | "lastName" | "id">[];
  responseBy?: string;
  id: string;
  invoiceId?: string;
  kickback?: InvoiceKickbackFieldsFragment;
  canEdit?: boolean;
  index?: number;
  refetchInvoice?: () => Promise<void>;
  assets?: AssetFieldsFragment[];
  resolutionAssets?: AssetFieldsFragment[] | null;
};

export const InvoiceIssue: FC<Props> = ({
  type,
  issueDescription,
  resolutionDescription,
  rejectionReason,
  issueDate,
  issueResponseDate,
  issueBy,
  responseBy,
  canEdit,
  assignees,
  id,
  invoiceId,
  index,
  refetchInvoice,
  assets,
  resolutionAssets,
}) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const [edit, setEdit] = useState<boolean>(false);
  const [responseType, setResponseType] = useState<ResponseType | null>(null);
  const intl = useIntl();
  const {
    updateInvoiceKickback,
    loading: updatingKickback,
    resolveInvoiceKickback,
    deleteInvoiceKickback,
  } = useInvoiceKickbacks();
  const {
    updateInvoiceIssue,
    loading: updatingIssue,
    deleteInvoiceIssue,
    resolveInvoiceIssue,
  } = useInvoiceIssues();
  const { viewer, isContractor } = useUser();
  const { openDialog } = useDialog();
  const methods = useForm({
    defaultValues: {
      description: issueDescription || "",
      assigneeIds: assignees?.map((a) => a.id) || [],
      resolution: resolutionDescription || "",
      assetUrls: [],
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const isAssignee = useMemo(
    () => !!assignees?.find((a) => a.id === viewer?.id),
    [assignees, viewer],
  );
  const update = useCallback(async () => {
    if (type === InvoiceIssueType.KICKBACK) {
      await updateInvoiceKickback({
        id,
        description: methods.getValues("description"),
        assigneeIds: [...new Set(methods.getValues("assigneeIds"))],
        assetUrls: methods.getValues("assetUrls"),
      });
      await refetchInvoice?.();
    } else {
      await updateInvoiceIssue({
        id,
        description: methods.getValues("description"),
        assetUrls: methods.getValues("assetUrls"),
      });
      await refetchInvoice?.();
      openDialog({
        content: (
          <SuccessModal
            message={
              <SuccessContainer>
                <FormattedMessage
                  id="ISSUE_NUMBER_UPDATED"
                  values={{
                    number: index,
                  }}
                  tagName={SuccessTitle}
                />
                <FormattedMessage
                  id="VENDOR_WAS_NOTIFIED"
                  tagName={SuccessDetails}
                />
              </SuccessContainer>
            }
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      });
    }
    setEdit(false);
  }, [
    id,
    index,
    methods,
    openDialog,
    refetchInvoice,
    type,
    updateInvoiceIssue,
    updateInvoiceKickback,
  ]);

  const deleteItem = useCallback(async () => {
    if (type === InvoiceIssueType.KICKBACK) {
      if (await deleteInvoiceKickback(id)) {
        await refetchInvoice?.();
        setEdit(false);
      }
    } else {
      if (await deleteInvoiceIssue(id)) {
        await refetchInvoice?.();
        setEdit(false);
      }
    }
  }, [type, deleteInvoiceKickback, id, refetchInvoice, deleteInvoiceIssue]);

  const resolve = useCallback(async () => {
    if (
      await resolveInvoiceKickback({
        id,
        resolutionDescription: methods.getValues("resolution"),
        assetUrls: methods.getValues("assetUrls"),
      })
    ) {
      openDialog({
        content: (
          <SuccessModal
            message={
              <SuccessContainer>
                <FormattedMessage
                  id="ISSUE_NUMBER_RESOLVED"
                  values={{
                    number: index,
                  }}
                  tagName={SuccessTitle}
                />
              </SuccessContainer>
            }
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      });
      await refetchInvoice?.();
      setResponseType(null);
    }
  }, [id, index, methods, openDialog, refetchInvoice, resolveInvoiceKickback]);

  const acceptIssue = useCallback(async () => {
    const description = methods.getValues("resolution");
    const assetUrls = methods.getValues("assetUrls");
    if (
      await resolveInvoiceIssue({
        id,
        resolutionDescription: description,
        issueAssetUrls: assetUrls,
      })
    ) {
      openDialog({
        content: (
          <SuccessModal
            message={
              <SuccessContainer>
                <FormattedMessage
                  id="ISSUE_ACKNOWLEDGED"
                  tagName={SuccessTitle}
                />
                <FormattedMessage
                  id="REMEMBER_TO_UPLOAD_INVOICE"
                  tagName={SuccessDetails}
                />
              </SuccessContainer>
            }
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      });
      await refetchInvoice?.();
      setResponseType(null);
    }
  }, [id, methods, openDialog, refetchInvoice, resolveInvoiceIssue]);

  const declineIssue = useCallback(async () => {
    const description = methods.getValues("resolution");
    const assetUrls = methods.getValues("assetUrls");
    if (
      await resolveInvoiceIssue({
        id,
        rejectionReason: description,
        issueAssetUrls: assetUrls,
      })
    ) {
      openDialog({
        content: (
          <SuccessModal
            message={
              <SuccessContainer>
                <FormattedMessage id="ISSUE_DECLINED" tagName={SuccessTitle} />
                <FormattedMessage
                  id="CONTRACTOR_WAS_NOTIFIED"
                  tagName={SuccessDetails}
                />
              </SuccessContainer>
            }
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
      });
      await refetchInvoice?.();
      setResponseType(null);
    }
  }, [id, methods, openDialog, refetchInvoice, resolveInvoiceIssue]);

  return (
    <FormProvider {...methods}>
      <Form>
        <IssueContainer
          className={`${
            !!resolutionDescription || !!rejectionReason
              ? ""
              : "w-full rounded-r-3xl"
          }`}
        >
          <IssueView
            className={
              !!resolutionDescription || rejectionReason
                ? "w-full"
                : "rounded-r-3xl"
            }
          >
            <IssueViewHeader>
              <IssueHeaderItem>
                <GroupToggle
                  isOpened={expanded}
                  onClick={() => setExpanded((v) => !v)}
                />
                {!resolutionDescription && (isAssignee || !isContractor) ? (
                  <RedExclamationCircle />
                ) : (
                  <ExclamationCircle
                    $hasDescription={
                      !!resolutionDescription || !!rejectionReason
                    }
                  />
                )}
                <IssueTitle>
                  <FormattedMessage
                    id={
                      type === InvoiceIssueType.KICKBACK
                        ? "ISSUE_REPORTED_BY_USER"
                        : "ISSUE_DESCRIPTION_WITH_NUMBER"
                    }
                    values={{ userName: issueBy, number: index }}
                    tagName="div"
                  />
                  <If
                    isTrue={
                      type === InvoiceIssueType.KICKBACK &&
                      !resolutionDescription
                    }
                  >
                    <FormattedMessage
                      id="ASSIGNED_TO_USERS"
                      values={{
                        users: assignees?.map((a) => getUserName(a)).join(", "),
                      }}
                      tagName={Users}
                    />
                  </If>
                </IssueTitle>
              </IssueHeaderItem>
              <If isTrue={!expanded && assets?.length}>
                <AttachmentsInfo>
                  <AttachmentStyled onClick={() => setExpanded((v) => !v)} />(
                  {assets?.length})
                </AttachmentsInfo>
              </If>
              <IssueHeaderItem>
                <If isTrue={issueDate}>
                  <IssueDate>
                    <DateView
                      date={issueDate}
                      includeTime
                      options={SHORT_DATE_OPTION}
                      hideTimeZone
                      twoLines
                    />
                  </IssueDate>
                  <If isTrue={canEdit && !resolutionDescription}>
                    {!edit ? (
                      <EditButton
                        onClick={() => {
                          setEdit(true);
                          setExpanded(true);
                        }}
                        className="h-3"
                      />
                    ) : (
                      <DiscardButton onClick={() => setEdit(false)} />
                    )}
                  </If>
                </If>
              </IssueHeaderItem>
            </IssueViewHeader>
            <If isTrue={edit}>
              <If isTrue={type === InvoiceIssueType.KICKBACK}>
                <InvoiceKickbackForm
                  invoiceId={invoiceId}
                  hideCloseButton
                  assets={assets}
                />
              </If>
              <If isTrue={type === InvoiceIssueType.ISSUE}>
                <InvoiceVendorIssueForm hideFooter />
              </If>
              <ButtonContainer>
                <LinkLikeStyled
                  onClick={() =>
                    openDialog({
                      cancelButtonText: intl.$t({ id: "CANCEL" }),
                      confirmButtonText: intl.$t({ id: "DELETE" }),
                      icon: <WarningIcon />,
                      titleClassName: "w-96",
                      title: intl.$t(
                        {
                          id:
                            type === InvoiceIssueType.KICKBACK
                              ? "DELETE_KICKBACK_NUMBER"
                              : "DELETE_ISSUE_NUMBER",
                        },
                        { number: index },
                      ),
                      text:
                        type === InvoiceIssueType.ISSUE
                          ? intl.$t({
                              id: "VENDOR_WILL_BE_IMMEDIATELY_NOTIFIED",
                            })
                          : undefined,
                      handleConfirm: deleteItem,
                    })
                  }
                >
                  <FormattedMessage id="DELETE" />
                </LinkLikeStyled>
                <OutlinedButton
                  className="h-8 px-4 text-sm"
                  $small
                  onClick={() => setEdit(false)}
                >
                  <FormattedMessage id="CANCEL" />
                </OutlinedButton>
                <PrimaryButton
                  className="h-8 px-4 text-sm"
                  $small
                  onClick={update}
                  loading={updatingIssue || updatingKickback}
                  disabled={!methods.watch("description")}
                >
                  <FormattedMessage id="UPDATE" />
                </PrimaryButton>
              </ButtonContainer>
            </If>
            <If isTrue={!edit}>
              <If isTrue={expanded}>
                <IssueDescriptionContainer $isAssignee={isAssignee}>
                  <IssueContent
                    description={issueDescription}
                    assets={assets}
                  />
                  <If
                    isTrue={
                      isAssignee && !resolutionDescription && !responseType
                    }
                  >
                    <OutlinedButton
                      onClick={() => {
                        setResponseType(ResponseType.Kickback);
                        setExpanded(true);
                      }}
                    >
                      <FormattedMessage id="RESOLVE" />
                    </OutlinedButton>
                  </If>
                </IssueDescriptionContainer>
              </If>
            </If>
            <If isTrue={responseType}>
              <InvoiceRespondForm type={responseType} />
              <If isTrue={isContractor}>
                <ButtonContainer>
                  <OutlinedButton
                    className="h-8 px-4 text-sm"
                    $small
                    onClick={() => setResponseType(null)}
                  >
                    <FormattedMessage id="CANCEL" />
                  </OutlinedButton>
                  <PrimaryButton
                    className="h-8 px-4 text-sm"
                    $small
                    onClick={resolve}
                    loading={updatingIssue || updatingKickback}
                    disabled={!methods.watch("resolution")}
                  >
                    <FormattedMessage id="MARK_AS_RESOLVED" />
                  </PrimaryButton>
                </ButtonContainer>
              </If>
            </If>
            <If isTrue={!isContractor}>
              <ButtonContainer>
                <If
                  isTrue={
                    !responseType && !resolutionDescription && !rejectionReason
                  }
                >
                  <OutlinedButton
                    className="h-8 text-sm"
                    $small
                    onClick={() => setResponseType(ResponseType.Decline)}
                  >
                    <FormattedMessage id="DECLINE" />
                  </OutlinedButton>
                  <OutlinedButton
                    className="h-8 px-4 text-sm"
                    $small
                    onClick={() => setResponseType(ResponseType.Accept)}
                  >
                    <FormattedMessage id="ACCEPT_AND_CORRECT" />
                  </OutlinedButton>
                </If>
                <If isTrue={responseType === ResponseType.Accept}>
                  <OutlinedButton
                    className="h-8 px-4 text-sm"
                    $small
                    onClick={() => setResponseType(null)}
                  >
                    <FormattedMessage id="CANCEL" />
                  </OutlinedButton>
                  <PrimaryButton
                    className="h-8 px-4 text-sm"
                    $small
                    onClick={acceptIssue}
                    disabled={!methods.watch("resolution")}
                  >
                    <FormattedMessage id="ACCEPT_AND_CORRECT_ISSUE" />
                  </PrimaryButton>
                </If>
                <If isTrue={responseType === ResponseType.Decline}>
                  <OutlinedButton
                    className="h-8 px-4 text-sm"
                    $small
                    onClick={() => setResponseType(null)}
                  >
                    <FormattedMessage id="CANCEL" />
                  </OutlinedButton>
                  <PrimaryButton
                    className="h-8 px-4 text-sm"
                    $small
                    onClick={declineIssue}
                    disabled={!methods.watch("resolution")}
                  >
                    <FormattedMessage id="DECLINE_ISSUE" />
                  </PrimaryButton>
                </If>
              </ButtonContainer>
            </If>
          </IssueView>
          <If isTrue={resolutionDescription}>
            <IssueResolution>
              <IssueViewHeader>
                <IssueHeaderItem>
                  <GreenCheckmarkCircle />
                  <FormattedMessage
                    id={
                      type === InvoiceIssueType.KICKBACK
                        ? "USER_RESPONSE"
                        : "ISSUE_RESOLVED"
                    }
                    values={{ userName: responseBy, number: index }}
                  />
                </IssueHeaderItem>
                <If isTrue={!expanded && resolutionAssets?.length}>
                  <AttachmentsInfo>
                    <AttachmentStyled onClick={() => setExpanded((v) => !v)} />(
                    {resolutionAssets?.length})
                  </AttachmentsInfo>
                </If>
                <If isTrue={issueDate}>
                  <IssueDate>
                    <DateView
                      date={issueResponseDate}
                      includeTime
                      options={SHORT_DATE_OPTION}
                      hideTimeZone
                      twoLines
                    />
                  </IssueDate>
                </If>
              </IssueViewHeader>
              <If isTrue={expanded}>
                <IssueContent
                  description={resolutionDescription}
                  assets={resolutionAssets}
                />
              </If>
            </IssueResolution>
          </If>
          <If isTrue={rejectionReason}>
            <IssueRejection>
              <IssueViewHeader>
                <IssueHeaderItem>
                  <RedExclamationCircle />
                  <FormattedMessage
                    id="ISSUE_REJECTED"
                    values={{ number: index }}
                  />
                </IssueHeaderItem>
                <If isTrue={!expanded && resolutionAssets?.length}>
                  <AttachmentsInfo>
                    <AttachmentStyled onClick={() => setExpanded((v) => !v)} />(
                    {resolutionAssets?.length})
                  </AttachmentsInfo>
                </If>
                <If isTrue={issueDate}>
                  <IssueDate>
                    <DateView
                      date={issueResponseDate}
                      includeTime
                      options={SHORT_DATE_OPTION}
                      hideTimeZone
                      twoLines
                    />
                  </IssueDate>
                </If>
              </IssueViewHeader>
              <If isTrue={expanded}>
                <IssueContent
                  description={rejectionReason}
                  assets={resolutionAssets}
                />
              </If>
            </IssueRejection>
          </If>
        </IssueContainer>
      </Form>
    </FormProvider>
  );
};
