import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { useEstimatedItems } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  ReleaseFieldsFragment,
  ReleaseStatus,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import Decimal from "decimal.js";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useReleaseUpdate } from "../../providers/ReleaseUpdateProvider";

const Container = tw.div`flex flex-row gap-1 items-center relative`;
const InfoContainer = tw.div`flex items-center
${(props: { $isReserved: boolean }) =>
  props.$isReserved && "bg-green-800 rounded-full"}
`;
const InfoIcon = tw(InfoOutlined)`text-lg text-white
${(props: { $isReserved: boolean }) =>
  props.$isReserved ? "text-white" : "text-blue-500 bg-white"}`;
const InfoLabel = tw.div`right-3 -top-2 absolute`;

enum PriceType {
  Reserved,
  Estimated,
  NotFromBuyout,
  Prenegotiated,
}

type Props = {
  item?: ExpandedReleaseItem;
  release?: Pick<ReleaseFieldsFragment, "id" | "status" | "version"> | null;
  index: number | undefined;
  readonly?: boolean;
};

export const ReleaseItemUnitPrice: FC<Props> = ({
  item,
  release,
  index,
  readonly = false,
}) => {
  const { updateRelease } = useReleaseUpdate();
  const { setNewProjectEstimatedItem } = useEstimatedItems();
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();

  const isReadOnly = useMemo(() => {
    return (
      readonly ||
      (release && [ReleaseStatus.Canceled].includes(release?.status))
    );
  }, [readonly, release]);

  const price = useMemo(() => {
    if (item?.unitPrice === null && isReadOnly) {
      return null;
    }

    return Number(new Decimal(item?.unitPrice || 0));
  }, [item?.unitPrice, isReadOnly]);

  const saveUnitPrice = useCallback(
    async (unitPrice: string | null) => {
      if (item?.id) {
        if (release) {
          const input: UpdateContractorReleaseInput = {
            releaseId: release?.id,
            version: release?.version,
            updates: [
              {
                releaseItemId: item.id,
                unitPrice,
              },
            ],
          };
          await updateRelease(input);
        }
      } else {
        setNewProjectEstimatedItem([
          {
            key: "unitPrice",
            value: unitPrice,
          },
        ]);
      }
    },
    [release, item?.id, updateRelease, setNewProjectEstimatedItem],
  );

  const priceType = useMemo(() => {
    if (item?.buyoutItem?.unitPrice) {
      return PriceType.Reserved;
    }
    if (item?.pricePrenegotiated) {
      return PriceType.Prenegotiated;
    }
    if (item?.priceEstimated) {
      return PriceType.Estimated;
    }

    return PriceType.NotFromBuyout;
  }, [
    item?.buyoutItem?.unitPrice,
    item?.priceEstimated,
    item?.pricePrenegotiated,
  ]);

  const infoLabel = useMemo(() => {
    if (
      readonly ||
      checkReleaseStatus(release, [
        ReleaseStatus.Received,
        ReleaseStatus.PartiallyReceived,
        ReleaseStatus.Canceled,
      ])
    ) {
      return;
    }

    const options = {
      maximumFractionDigits: 3,
    };
    switch (priceType) {
      case PriceType.Reserved:
        return intl.$t(
          { id: "ITEM_INCLUDE_IN_CURRENT_BUYOUT_WITH_PRICE" },
          { price: formatCurrency(price, options) },
        );
      case PriceType.Prenegotiated:
        return intl.$t(
          { id: "ITEM_PRENEGOTIATED_PRICE" },
          { price: formatCurrency(price, options) },
        );
      case PriceType.Estimated:
        return intl.$t(
          { id: "ITEM_UNIT_PRICE_ESTIMATED" },
          { price: formatCurrency(price, options) },
        );
      case PriceType.NotFromBuyout:
        return intl.$t({ id: "ITEM_SPECIFY_PRICE_CONTRACTOR" });
      default:
        return "";
    }
  }, [formatCurrency, intl, price, priceType, readonly, release]);

  return (
    <Container>
      <NotNullableRenderer value={!(isReadOnly && price === null)}>
        <PricePicker
          value={price?.toString()}
          index={index}
          onBlur={async (unitPrice) => {
            const mapValue = isNaN(Number(unitPrice)) ? null : unitPrice;
            if (mapValue === item?.unitPrice) {
              return;
            }
            await saveUnitPrice(unitPrice ?? "0");
          }}
          className={isReadOnly ? "text-xs" : "text-sm"}
          readonly={!!isReadOnly}
        />
      </NotNullableRenderer>

      <InfoLabel>
        <Tooltip
          element={
            <If isTrue={infoLabel}>
              <InfoContainer $isReserved={priceType === PriceType.Reserved}>
                <InfoIcon $isReserved={priceType === PriceType.Reserved} />
              </InfoContainer>
            </If>
          }
          id={`release-price-${index}`}
        >
          {infoLabel}
        </Tooltip>
      </InfoLabel>
    </Container>
  );
};
