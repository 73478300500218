import { BuyoutReleaseFieldsFragment, ServiceType } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`hidden lg:flex items-center flex-col`;
const StockingContainer = tw.div`font-bold`;
const StockingType = tw.div`font-light text-xs`;

type Props = {
  release: BuyoutReleaseFieldsFragment;
};

export const ReleaseStocking: FC<Props> = ({ release }) => {
  return (
    <Container>
      <StockingContainer>
        <FormattedMessage id="STOCKING" />
      </StockingContainer>
      <StockingType>
        {release.includeServices.includes(ServiceType.Stocking) ? (
          <FormattedMessage id="BY_VENDOR" />
        ) : (
          <FormattedMessage id="BY_CONTRACTOR" />
        )}
      </StockingType>
    </Container>
  );
};
