import { LinkLikeText } from "@/common/components/link-like-text/LinkLikeText";
import tw from "tailwind-styled-components";

export const QuestionBox = tw(LinkLikeText)<{
  $fixedWidth?: boolean;
  $disabled?: boolean;
}>`flex w-7 lg:w-auto items-center justify-end print:hidden font-normal
    ${({ $fixedWidth }) => $fixedWidth && "lg:w-48"}`;
export const QuestionText = tw.div`
    text-right mr-4 text-xs hidden lg:flex gap-2 items-center
`;
