import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useUnspecifiedPhaseCode } from "@/common/hooks/useUnspecifiedPhaseCode";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  BuyoutItemFieldsFragment,
  CostCodeFieldsFragment,
  DistributorBuyoutItemFieldsFragment,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { getCostCodesByBuyoutItems } from "../cost-codes-and-zones/getCostCodesByBuyoutItems";
import { getPhaseCodesByBuyoutItems } from "../cost-codes-and-zones/getPhaseCodesByBuyoutItems";

type BuyoutItem =
  | BuyoutItemFieldsFragment
  | DistributorBuyoutItemFieldsFragment;

type Props<T extends BuyoutItem[]> = {
  items?: T;
  forceUsePhaseCodes?: boolean;
};

export const useBuyoutItemsGrouping = <T extends BuyoutItem[]>(
  props: Props<T> = {},
) => {
  const { items, forceUsePhaseCodes } = props;
  const { hasPhaseCodes } = useOrgSettings();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { unassignedPhaseCode } = useUnspecifiedPhaseCode();

  const includePhaseCodes = useMemo(
    () => hasPhaseCodes || forceUsePhaseCodes,
    [hasPhaseCodes, forceUsePhaseCodes],
  );

  const getProjectCodes = useCallback(
    (isAddMode?: boolean | undefined) => {
      if (!items) {
        return [];
      }
      return includePhaseCodes
        ? getPhaseCodesByBuyoutItems(items, unassignedPhaseCode, isAddMode)
        : getCostCodesByBuyoutItems(items, unassignedCostCode, isAddMode);
    },
    [includePhaseCodes, items, unassignedPhaseCode, unassignedCostCode],
  );

  const filterBasedOnCostCodeAndPhaseCode = useCallback(
    (
      buyoutItem:
        | BuyoutItemFieldsFragment
        | DistributorBuyoutItemFieldsFragment,
      costCode: Omit<CostCodeFieldsFragment, "mappings">,
    ) =>
      hasPhaseCodes || forceUsePhaseCodes
        ? buyoutItem.tags.find((tag) => tag.id === costCode.id) ||
          (costCode.id === unassignedPhaseCode.id &&
            !buyoutItem.tags.some((tag) => tag.hasMapping))
        : (costCode.id === unassignedCostCode.id && !buyoutItem.costCode) ||
          buyoutItem.costCode?.id === costCode.id,
    [
      hasPhaseCodes,
      forceUsePhaseCodes,
      unassignedPhaseCode.id,
      unassignedCostCode.id,
    ],
  );

  return {
    getProjectCodes,
    filterBasedOnCostCodeAndPhaseCode,
  };
};
