import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import {
  BatchType,
  PoFormat,
  SourceSystem,
  useSyncPoMutation,
  useUpdatePoLinkMutation,
} from "@/generated/graphql";
import { LinkingProgress } from "@/types/LinkingProgress";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { format } from "date-fns";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useExportBatch } from "../../../../../../../common/providers/ExportBatchProvider";
import { useCreateExternalBatch } from "../hooks/useCreateExternalBatch";
import { useDefaultReleasePo } from "../hooks/useDefaultReleasePo";
import { useLinkPo } from "../hooks/useLinkPo";
import { useReleasesByIds } from "./ReleasesByIdsProvider";
export type UpdatedRelease = {
  releaseId: string;
  poNumber?: string;
  externalVendorCode?: string;
};

type ProviderContextType = {
  autoSync: boolean;
  setAutoSync: (value: boolean) => void;
  warehouseNumber?: string;
  setWarehouseNumber: (value: string) => void;
  sourceSystem?: SourceSystem;
  updatedReleases: UpdatedRelease[];
  updateRelease: (updatedRelease: UpdatedRelease) => void;
  linkingPos: LinkingProgress;
  linkPos: () => Promise<boolean>;
  syncPo: (poLinkId: string) => Promise<boolean>;
  updatePoLink: (poLinkId: string) => Promise<boolean>;
  clearErrors: () => void;
  hasBatchError: boolean;
  updatingPoLink: boolean;
};

type Props = {
  children: React.ReactNode;
  type: "onPremise" | "hosted";
};

const ProviderContext = createContext<ProviderContextType>({
  autoSync: false,
  setAutoSync: NoFunction,
  warehouseNumber: "",
  setWarehouseNumber: NoFunction,
  sourceSystem: undefined,
  updateRelease: NoFunction,
  updatedReleases: [],
  linkingPos: {
    linking: false,
    percentage: 0,
    completed: [],
    errors: [],
  },
  linkPos: NoFunctionBooleanPromise,
  syncPo: NoFunctionBooleanPromise,
  updatePoLink: NoFunctionBooleanPromise,
  clearErrors: NoFunction,
  hasBatchError: false,
  updatingPoLink: false,
});

export const ReleaseConnectionOptionsProvider: FC<Props> = ({
  children,
  type,
}) => {
  const { setError } = useGlobalError();
  const { linkPo } = useLinkPo();

  const { releasesByIds, validatedReleases } = useReleasesByIds();
  const { connectedSourceSystem } = useOrgSettingsExtended();
  const { integrations } = useOrgSettingsExtended();
  const { createExternalBatch } = useCreateExternalBatch();
  const { batchDate, newBatch, externalBatch } = useExportBatch();
  const { getDefaultReleasePo } = useDefaultReleasePo();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const integration = useMemo(
    () => integrations?.sourceSystems.find((s) => s.connected),
    [integrations?.sourceSystems],
  );

  const connectedSourceSystemDetails = useMemo(
    () =>
      integrations?.sourceSystems.find(
        (s) => s.system === connectedSourceSystem?.system,
      ),
    [integrations?.sourceSystems, connectedSourceSystem],
  );
  const [updatedReleases, setPoReleases] = useState<UpdatedRelease[]>([]);
  const [autoSync, setAutoSync] = useState(
    !!connectedSourceSystemDetails?.defaultAutoSync,
  );
  const [initialized, setInitialized] = useState(false);
  const [warehouseNumber, setWarehouseNumber] = useState<string>(
    (type === "hosted"
      ? integration?.toWarehouseLedgerAccount?.externalId
      : undefined) || "",
  );
  const [linkingPos, setLinkingPos] = useState<LinkingProgress>({
    linking: false,
    percentage: 0,
    completed: [],
    errors: [],
  });
  const [hasBatchError, setHasBatchError] = useState(false);

  useEffect(() => {
    if (
      releasesByIds &&
      releasesByIds.length === 1 &&
      releasesByIds[0].poLink &&
      !initialized
    ) {
      setAutoSync(releasesByIds[0].poLink?.autoSync || false);
      setInitialized(true);
    }
  }, [initialized, releasesByIds]);

  useEffect(() => {
    if (!initialized) {
      setAutoSync(!!connectedSourceSystemDetails?.defaultAutoSync);
    }
  }, [connectedSourceSystemDetails, initialized]);

  const updateRelease = useCallback(
    ({ releaseId, poNumber, externalVendorCode }: UpdatedRelease) => {
      const updatedPoReleases = updatedReleases.filter(
        (poRelease) => poRelease.releaseId !== releaseId,
      );
      updatedPoReleases.push({
        releaseId,
        poNumber,
        externalVendorCode,
      });
      setPoReleases(updatedPoReleases);
    },
    [updatedReleases],
  );

  const linkPos = useCallback(async () => {
    if (connectedSourceSystem) {
      let successful = true;
      let batchId = externalBatch?.externalId;
      const releasesToExport = releasesByIds.filter(
        (r) => validatedReleases.find((vr) => vr.id === r.id)?.validated,
      );

      if (
        hasFeatureInConnectedSourceSystem(IntegrationFeature.POBatching) &&
        !connectedSourceSystem.autoPostPOs &&
        newBatch
      ) {
        setHasBatchError(false);
        const newBatch = await createExternalBatch({
          sourceSystem: connectedSourceSystem.system,
          month: format(batchDate, "yyyy-MM"),
          type: BatchType.PurchaseOrder,
        });
        if (newBatch) {
          batchId = newBatch.externalId;
        } else {
          setHasBatchError(true);
          return false;
        }
      }
      setLinkingPos({
        linking: true,
        percentage: 0,
        completed: [],
        errors: [],
      });
      const errors = [];
      for await (const [key, release] of releasesToExport.entries()) {
        const updatedRelease = updatedReleases.find(
          (r) => r.releaseId === release?.id,
        );
        const input = {
          releaseId: release?.id,
          autoSync,
          poNumber:
            updatedRelease?.poNumber ||
            release.poNumber ||
            getDefaultReleasePo(release) ||
            "",
          format: release.type.poFormat || PoFormat.Basic,
          sourceSystem: connectedSourceSystem.system,
          batchId: !connectedSourceSystem.autoPostPOs ? batchId : undefined,
        };
        const result = await linkPo(input);
        const hasTextError = typeof result !== "boolean";
        let error = "";
        if (hasTextError || !result) {
          successful = false;
          if (hasTextError) {
            error = result;
          }
        }
        errors.push({ id: release.id, message: error });
        setLinkingPos({
          linking: true,
          percentage: (key + 1) / releasesToExport.length,
          completed: [...linkingPos.completed, release.id],
          errors: [],
        });
      }
      setLinkingPos({
        linking: false,
        percentage: 0,
        completed: [],
        errors,
      });
      return successful;
    }
    return false;
  }, [
    autoSync,
    batchDate,
    connectedSourceSystem,
    createExternalBatch,
    externalBatch?.externalId,
    getDefaultReleasePo,
    hasFeatureInConnectedSourceSystem,
    linkPo,
    linkingPos.completed,
    newBatch,
    releasesByIds,
    updatedReleases,
    validatedReleases,
  ]);

  const [syncPoMutation] = useSyncPoMutation();
  const syncPo = async (poLinkId: string) => {
    try {
      setLinkingPos({
        linking: true,
        percentage: 0,
        completed: [],
        errors: [],
      });
      const { data } = await syncPoMutation({
        variables: {
          poLinkId,
        },
      });
      setLinkingPos({
        linking: false,
        percentage: 0,
        completed: [],
        errors: [],
      });
      return !!data?.syncPO;
    } catch (error) {
      setError(error);
      setLinkingPos({
        linking: false,
        percentage: 0,
        completed: [],
        errors: [],
      });
      return false;
    }
  };

  const [updatePoLinkMutation, { loading: updatingPoLink }] =
    useUpdatePoLinkMutation();
  const updatePoLink = async (poLinkId: string) => {
    try {
      const { data } = await updatePoLinkMutation({
        variables: {
          input: {
            externalWarehouseId: warehouseNumber || undefined,
            clearWarehouse: !warehouseNumber,
            autoSync,
            poLinkID: poLinkId,
          },
        },
      });
      return !!data?.updatePOLink;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const clearErrors = useCallback(() => {
    setLinkingPos({
      linking: false,
      percentage: 0,
      completed: [],
      errors: [],
    });
  }, []);

  return (
    <ProviderContext.Provider
      value={{
        autoSync,
        setAutoSync,
        warehouseNumber,
        setWarehouseNumber,
        sourceSystem: connectedSourceSystem?.system,
        updatedReleases,
        updateRelease,
        linkPos,
        linkingPos,
        syncPo,
        updatePoLink,
        clearErrors,
        hasBatchError,
        updatingPoLink,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReleaseConnectionOptions = (): ProviderContextType =>
  useContext(ProviderContext);
