import { ProjectFieldsFragment, TagFieldsFragment } from "@/generated/graphql";
import {
  AddCircle,
  LocalOfferOutlined,
  SellOutlined,
} from "@mui/icons-material";
import { Popover } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { IconButtonBorderless } from "../button/IconButton";
import { TagsSelector } from "../tags-selector/TagsSelector";

type Props = {
  options: TagFieldsFragment[];
  handleChange: (newValue: string) => void;
  values: TagFieldsFragment[];
  project?: Pick<ProjectFieldsFragment, "id" | "tags"> | null;
};

const Button = tw(IconButtonBorderless)`
  relative w-7 h-7 min-h-7 place-content-center bg-blue-100 text-gray-600
`;
const Tag = tw(SellOutlined)`absolute text-lg left-[7px] bottom-1`;
const Plus = tw(AddCircle)`absolute text-xs left-[3px] bottom-[4px]`;

const Container = tw.div`
  p-2 w-48
`;

export const AddTagItem: FC<Props> = ({
  options,
  values,
  handleChange,
  project,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const intl = useIntl();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const onChange = useCallback(
    (newValue: string[] | null) => {
      handleClose();
      if (!newValue) {
        return;
      }
      handleChange(newValue[0]);
    },
    [handleChange],
  );

  const optionsFiltered = useMemo(
    () => options.filter((o) => !values.find((v) => v.id === o.id)),
    [options, values],
  );

  return (
    <>
      <Button onClick={handleClick} testId="add-tag-item-toggle">
        <Tag />
        <Plus />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Container>
          <TagsSelector
            creatable
            options={optionsFiltered}
            selectedTags={[]}
            setSelectedTags={onChange}
            placeholder={intl.$t({
              id: "SELECT_TAGS",
            })}
            popup={<LocalOfferOutlined fontSize="small" />}
            project={project}
          />
        </Container>
      </Popover>
    </>
  );
};
