import { If } from "@/common/components/if/If";
import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { AssetFieldsFragment } from "@/generated/graphql";
import { RiFileEditFill } from "react-icons/ri";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { WarningIconWithDetails } from "./WarningIconWithDetails";

const Container = tw.div`
  relative px-4 h-6 grid grid-cols-auto-1fr gap-2 justify-end font w-full items-center
`;

const LeftSide = tw.div`
  grid grid-flow-col gap-2 
`;

const NotesLink = tw.div`
  grid justify-start cursor-pointer grid-flow-col place-items-center gap-1 text-2xs text-blue-500 hover:text-blue-300 px-0.5 py-1 rounded-2xl h-fit
`;

const ManufacturerName = tw.div`
  text-xs text-right w-full text-ellipsis overflow-hidden whitespace-nowrap
`;

const EditIconFilled = tw(RiFileEditFill)`
w-4 h-4
`;

type Props = {
  quoteItemId?: string;
  itemId?: string;
  notes?: string | null;
  assets?: AssetFieldsFragment[] | null;
  manufacturerName?: string | null;
  manufacturerMismatch?: boolean;
};

export const ManufacturerRow = ({
  quoteItemId,
  itemId,
  notes,
  assets,
  manufacturerName,
  manufacturerMismatch,
}: Props) => {
  const { toggle } = useGlobalDrawer();

  const openNotes = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    toggle("notes-panel", true, quoteItemId ?? itemId);
  };

  return (
    <Container>
      <LeftSide>
        <If isTrue={manufacturerMismatch}>
          <WarningIconWithDetails
            warning={<FormattedMessage id="MANUFACTURER_MISMATCH" />}
          />
        </If>
        <If isTrue={notes || assets?.length}>
          <NotesLink data-testid="notes-link" onClick={openNotes}>
            <EditIconFilled />
            <FormattedMessage id="NOTES" />
          </NotesLink>
        </If>
      </LeftSide>
      <ManufacturerName title={manufacturerName ?? ""}>
        {manufacturerName}
      </ManufacturerName>
    </Container>
  );
};
