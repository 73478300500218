import { ProjectItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { AddEstimatedItemGroupCheckbox } from "../../../pages/add-item-to-rfq/rfq-items-list/AddEstimatedItemGroupCheckbox";
import {
  AddToRfqItem,
  useRfqProjectItemsWithUpdates,
} from "../RfqProjectItemsProvider";

export const RfqProjectItemGroupCheckbox: FC<{
  items: ProjectItemFieldsFragment[];
}> = ({ items }) => {
  const { updates, setUpdates } = useRfqProjectItemsWithUpdates();

  const onToggle = useCallback(
    (itemsToAdd: AddToRfqItem[]) => {
      setUpdates(itemsToAdd);
    },
    [setUpdates],
  );

  return (
    <AddEstimatedItemGroupCheckbox
      items={items}
      setUpdates={onToggle}
      updates={updates}
    />
  );
};
