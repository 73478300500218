import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { endOfDay, isSameDay, isWithinInterval, startOfDay } from "date-fns";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { DateRangePickerValue } from "./types";

type StyledPickersDayProps = PickersDayProps<Date> & {
  $selected?: boolean;
  $roundedRightCorners?: boolean;
  $roundedLeftCorners?: boolean;
};

const StyledPickersDay = tw(
  PickersDay,
)<StyledPickersDayProps>`p-3 tall:p-2 tall:my-1 tall:mx-3
  ${(p) => (p.$selected ? "Mui-selected bg-blue-800" : "")}
  ${(p) => (p.$roundedLeftCorners ? "rounded-l-[50%]" : "rounded-l-0")}
  ${(p) => (p.$roundedRightCorners ? `rounded-r-[50%]` : "rounded-r-0")}
`;

type StyledWrapperProps = {
  $isHighlighted?: boolean;
  $roundedRightCorners?: boolean;
  $roundedLeftCorners?: boolean;
};
const StyledWrapper = tw.div<StyledWrapperProps>`
  ${(p) => (p.$isHighlighted ? "bg-gray-200" : "bg-transparent")}
  ${(p) => (p.$roundedLeftCorners ? "rounded-l-[50%]" : "rounded-l-0")}
  ${(p) => (p.$roundedRightCorners ? `rounded-r-[50%]` : "rounded-r-0")}
`;

type CustomPickersDayProps = PickersDayProps<Date> & DateRangePickerValue;

const CustomPickersDay: FC<CustomPickersDayProps> = (props) => {
  const { startDate, endDate, ...other } = props;
  const isStart = startDate && isSameDay(props.day, startDate);
  const isEnd = endDate && isSameDay(props.day, endDate);
  const isOnlyStartDefined = isStart && !endDate;
  const isStartOrEnd = isStart || isEnd;
  const isBetween =
    startDate &&
    endDate &&
    endDate >= startDate &&
    isWithinInterval(props.day, {
      start: startOfDay(startDate),
      end: endOfDay(endDate),
    });

  return (
    <StyledWrapper
      $isHighlighted={isBetween || isStartOrEnd}
      $roundedLeftCorners={isStart}
      $roundedRightCorners={isEnd || isOnlyStartDefined}
    >
      {}
      <StyledPickersDay
        {...other}
        $selected={isStartOrEnd}
        $roundedLeftCorners={isStart}
        $roundedRightCorners={isEnd || isOnlyStartDefined}
        selected={isStartOrEnd}
        disableMargin
      />
    </StyledWrapper>
  );
};

export default CustomPickersDay;
