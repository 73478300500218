import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { FormattedMessage } from "react-intl";
import { WarehouseForm } from "../form/WarehouseForm";

export const AddWarehouseButton = () => {
  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <PrimaryButton wide onClick={() => togglePanel(true)}>
          <FormattedMessage id="WAREHOUSE_CREATE" />
        </PrimaryButton>
      )}
      content={(togglePanel) => (
        <WarehouseForm onClose={() => togglePanel(false)} />
      )}
    />
  );
};
