import { ProductCategory } from "@/generated/graphql";

export const getCategoryFullName = (
  selectedCategory: ProductCategory | undefined,
): string => {
  if (!selectedCategory) {
    return "";
  }
  return `${selectedCategory.l1Name} : ${selectedCategory.l2Name} ${
    selectedCategory.l3Name ? `: ${selectedCategory.l3Name}` : ""
  }`;
};

export const getCategoryNameById = (
  selectedCategoryId: string | null,
  categories: ProductCategory[],
): string => {
  const selectedCategory = categories.find((c) => c.id === selectedCategoryId);
  if (!selectedCategory) {
    return "";
  }
  return (
    selectedCategory.l3Name ||
    selectedCategory.l2Name ||
    selectedCategory.l1Name
  );
};
