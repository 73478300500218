import { GridTable } from "@/common/components/grid-table/GridTable";
import { getExpandedItems } from "@/common/components/grid-table/utils/getExpandedItems";
import { scrollIntoViewByDataAttr } from "@/common/utils/scrollUtils";
import { checkBuyoutStatus } from "@/common/utils/status-checks/checkBuyoutStatus";
import {
  BuyoutStatus,
  DistributorBuyoutFieldsFragment,
  DistributorBuyoutItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo } from "react";
import { genericError } from "../../../common/utils/status-checks/checkQuoteStatus";
import { BuyoutItemsContainer } from "./Buyout.styles";
import { useDistributorBuyoutConfiguration } from "./components/list/DistributorBuyout.configuration";
import { useDistributorReadonlyBuyoutConfiguration } from "./components/list/ReadonlyBuyout.configuration";
import { useDistributorBuyoutItemsProvider } from "./providers/DistributorBuyoutItemsProvider";
import { useDistributorBuyout } from "./providers/DistributorBuyoutProvider";

type Props = {
  items: DistributorBuyoutItemFieldsFragment[];
  buyout: DistributorBuyoutFieldsFragment;
};

export const BuyoutList: FC<Pick<Props, "buyout">> = ({ buyout }) => {
  const { costCodes, toggleCostCode, groupedByCostCode } =
    useDistributorBuyoutItemsProvider();
  const { expandedItems, validationError } = useDistributorBuyout();
  const readonly = useMemo(
    () => !checkBuyoutStatus(buyout, [BuyoutStatus.Requested]),
    [buyout],
  );
  const buyoutConfiguration = useDistributorBuyoutConfiguration(buyout);
  const readonlyConfiguration =
    useDistributorReadonlyBuyoutConfiguration(buyout);

  const configuration = useMemo(
    () => (readonly ? readonlyConfiguration : buyoutConfiguration),
    [buyoutConfiguration, readonly, readonlyConfiguration],
  );

  useEffect(() => {
    if (validationError) {
      setTimeout(() => scrollIntoViewByDataAttr(genericError), 100);
    }
  }, [validationError]);

  const itemFn = useCallback(
    (item: DistributorBuyoutItemFieldsFragment) => {
      if (buyout.items.some((i) => i.id === item.id && !i.isIncluded)) {
        return { className: "bg-gray-100" };
      }
      return { className: "" };
    },
    [buyout.items],
  );

  const topCategory = useMemo(() => {
    return buyout.status === BuyoutStatus.Requested ? 98 : 86;
  }, [buyout.status]);

  const items = useMemo(() => {
    return (
      buyout?.items.toSorted((a, b) => (a.position || 0) - (b.position || 0)) ??
      []
    );
  }, [buyout?.items]);

  return (
    <GridTable
      configuration={{
        container: BuyoutItemsContainer,
        columns: configuration,
        classNames: {
          header: "top-[32px]",
          category: `xl:top-[${topCategory}px] top-[30px]`,
          itemFn: !readonly ? itemFn : undefined,
          detailsContent: "flex justify-end lg:p-0",
        },
        toggle: {
          category: toggleCostCode,
        },
      }}
      expandedItems={(item) => getExpandedItems(item, expandedItems)}
      items={groupedByCostCode ? costCodes : items}
      readonly={readonly}
      error={validationError}
      hideGroup={!groupedByCostCode}
    />
  );
};
