import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import {
  PoFormat,
  ReleaseFieldsFragment,
  ReleaseItemFieldsFragment,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useOrderItemPoItemReferences = () => {
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  const hasOrderItemPoItemReferences = useCallback(
    (
      item: ReleaseItemFieldsFragment,
      release: ReleaseFieldsFragment | undefined | null,
    ) =>
      release?.type.poFormat === PoFormat.Detail &&
      !item.poItemLink &&
      !!release.poLink &&
      !!release.poLink?.retroactive &&
      hasFeatureInConnectedSourceSystem(
        IntegrationFeature.OrderItemPOItemReferences,
      ),
    [hasFeatureInConnectedSourceSystem],
  );

  return { hasOrderItemPoItemReferences };
};
