import { getUserName } from "@/common/utils/users/getUserName";
import { routes } from "@/config/routes";
import {
  InvoiceEvent,
  Notification,
  NotificationInvoiceChangeContextFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import { DetailsContainer, MessageBody } from "../common/Notification.styles";
import { BaseNotification, Sender } from "./BaseNotification";

type InvoiceChangeChangeNotificationProps = {
  notification: Notification;
};

export const InvoiceChangeNotification: FC<
  InvoiceChangeChangeNotificationProps
> = ({ notification }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const context =
    notification.context as NotificationInvoiceChangeContextFieldsFragment;

  const onCallback = () => {
    navigate(
      generatePath(routes.invoiceVerification, {
        invoiceId: context.invoice.id,
      }),
    );
  };

  const sender = useMemo(() => {
    return getUserName(context?.invoiceNotificationInitiatedBy) ?? "";
  }, [context]);

  const notificationMessage = useMemo(() => {
    switch (context.invoiceNotificationEvent) {
      case InvoiceEvent.AwaitingApproval:
        return intl.$t(
          {
            id: context.invoice.release
              ? "NOTIFICATION_INVOICE_AWAITING_APPROVAL"
              : "NOTIFICATION_INVOICE_AWAITING_APPROVAL_NO_ORDER",
          },
          {
            invoiceNumber: context.invoice.number,
            orderNumber: context.invoice.release?.sequenceNumber,
            projectName: context.invoice.project?.name,
          },
        );
      case InvoiceEvent.Approved:
        return intl.$t(
          { id: "NOTIFICATION_INVOICE_APPROVED" },
          {
            contractorName: getUserName(context.invoiceNotificationInitiatedBy),
            contractorOrgName: context.invoice.project?.location.org.name,
            invoiceNumber: context.invoice.number,
            orderNumber: context.invoice.release?.sequenceNumber,
          },
        );
      case InvoiceEvent.IssuesReported:
        return intl.$t(
          { id: "NOTIFICATION_INVOICE_ISSUES_REPORTED" },
          {
            contractorName: getUserName(context.invoiceNotificationInitiatedBy),
            contractorOrgName: context.invoice.project?.location.org.name,
            invoiceNumber: context.invoice.number,
            orderNumber: context.invoice.release?.sequenceNumber,
          },
        );
      case InvoiceEvent.IssuesCorrected:
        return intl.$t(
          { id: "NOTIFICATION_INVOICE_ISSUES_CORRECTED" },
          {
            vendorName: getUserName(context.invoiceNotificationInitiatedBy),
            vendorOrgName:
              context.invoice.release?.preferredVendor?.sellerOrgLocation.org
                .name,
            invoiceNumber: context.invoice.number,
            orderNumber: context.invoice.release?.sequenceNumber,
          },
        );
      case InvoiceEvent.IssuesRejected:
        return intl.$t(
          { id: "NOTIFICATION_INVOICE_ISSUES_REJECTED" },
          {
            vendorName: getUserName(context.invoiceNotificationInitiatedBy),
            vendorOrgName:
              context.invoice.release?.preferredVendor?.sellerOrgLocation.org
                .name,
            invoiceNumber: context.invoice.number,
            orderNumber: context.invoice.release?.sequenceNumber,
          },
        );
      case InvoiceEvent.KickbackRequested:
        return intl.$t(
          { id: "NOTIFICATION_INVOICE_KICKBACK_REQUESTED" },
          {
            contractorName: getUserName(context.invoiceNotificationInitiatedBy),
            invoiceNumber: context.invoice.number,
            orderNumber: context.invoice.release?.sequenceNumber,
          },
        );
      case InvoiceEvent.KickbackResolved:
        return intl.$t(
          { id: "NOTIFICATION_INVOICE_KICKBACK_RESOLVED" },
          {
            contractorName: getUserName(context.invoiceNotificationInitiatedBy),
            invoiceNumber: context.invoice.number,
            orderNumber: context.invoice.release?.sequenceNumber,
          },
        );
      case InvoiceEvent.Paid:
        return intl.$t(
          { id: "NOTIFICATION_INVOICE_PAID" },
          {
            contractorName: getUserName(context.invoiceNotificationInitiatedBy),
            contractorOrgName: context.invoice.project?.location.org.name,
            invoiceNumber: context.invoice.number,
            orderNumber: context.invoice.release?.sequenceNumber,
          },
        );
      case InvoiceEvent.PaymentDue:
        return intl.$t(
          { id: "NOTIFICATION_INVOICE_PAYMENT_DUE" },
          {
            invoiceNumber: context.invoice.number,
            orderNumber: context.invoice.release?.sequenceNumber,
          },
        );
      default:
        return "";
    }
  }, [context, intl]);

  return (
    <BaseNotification notification={notification} onCallback={onCallback}>
      <DetailsContainer>
        <Sender>{sender}</Sender>
        <MessageBody>{notificationMessage}</MessageBody>
      </DetailsContainer>
    </BaseNotification>
  );
};
