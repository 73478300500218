import {
  BuyoutsBuyoutFieldsFragment,
  OrgPreferredVendorsFieldsFragment,
} from "@/generated/graphql";
import tw from "tailwind-styled-components";
import { OrgLogo } from "../org-logo/OrgLogo";
import { vendorLabelFormatter } from "../vendor-picker/VendorPickerCustomRender";

const BuyoutItem = tw.div`
  w-full py-[6px] grid grid-flow-col gap-x-2 justify-start items-center
`;
const Separator = tw.span`mx-1`;
const BuyoutNumber = tw.span``;
const VendorOrg = tw.span``;
const VendorContacts = tw.span`text-xs relative bottom-[1px]`;

export const BuyoutPickerCustomRender = (
  item: BuyoutsBuyoutFieldsFragment,
  filter: (
    contact: OrgPreferredVendorsFieldsFragment["contacts"][0],
  ) => boolean,
) => (
  <BuyoutItem>
    <OrgLogo
      logoImageUrl={item.sellerOrgLocation?.org?.logoImageUrl}
      name={item.sellerOrgLocation?.org?.name}
      width={24}
    />
    <div>
      <BuyoutNumber>#{item.clientIdentifier}</BuyoutNumber>
      <Separator>-</Separator>
      <VendorOrg>{vendorLabelFormatter(item.sellerOrgLocation)}</VendorOrg>
      <Separator>-</Separator>
      <VendorContacts>
        {item.preferredVendor?.contacts
          .filter(filter)
          .map((c) => c.name)
          .join(", ")}
      </VendorContacts>
    </div>
  </BuyoutItem>
);
