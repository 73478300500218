import tw from "tailwind-styled-components";

export const ProjectSectionContainer = tw.form`grid gap-8  md:px-16 md:pt-16 pb-8`;

export const ProjectSection = tw.div`grid md:grid-cols-[_1fr_5fr] gap-4`;

export const ProjectSectionLabel = tw.div`text-lg font-medium flex gap-2.5 flex-row`;

export const ProjectGroup = tw.div`grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 md:border-b-0.5 md:border-gray-600 md:border-dashed md:pb-8 justify-start items-start`;

export const ProjectLabelDetails = tw.div`font-medium`;
