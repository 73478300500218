import { gql } from "@apollo/client";

export const SITE_CONTACT_FIELDS = gql`
  fragment SiteContactFields on SiteContact {
    id
    name
    phone
    role
    archivedAt
  }
`;
