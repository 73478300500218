import { FC, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDialog } from "../dialog/DialogProvider";
import { ImageCropper } from "./ImageCropper";
import { ImagePreview } from "./ImagePreview";
import { UploadArea } from "./UploadArea";
import { createImage, getCroppedImg } from "./utils";

const ImagePreviewContainer = tw.div`
  w-fit h-fit flex items-center justify-center
`;

const DialogContainer = tw.div`
  grid gap-3 text-left
`;

const ImageCropperContainer = tw.div`
  w-96 h-96 relative
`;

const TipText = tw.div`
  text-center text-sm text-gray-500
`;

type Props = {
  save: (file: File) => Promise<void>;
  uploadArea: React.ReactNode;
  showCircleCrop?: boolean;
  forceSquare?: boolean;
  disabled?: boolean;
};

const readFile = (file: File): Promise<string | null> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => resolve(reader.result as string),
      false,
    );
    reader.readAsDataURL(file);
  });

export const ImageUploaderWithCropping: FC<Props> = ({
  save,
  uploadArea,
  forceSquare,
  showCircleCrop,
  disabled,
}) => {
  const croppedImageRef = useRef<Blob | null>(null);
  const { openDialog } = useDialog();
  const intl = useIntl();

  const showPreview = () => {
    if (croppedImageRef.current) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "SAVE" }),
        closeOnConfirm: true,
        title: "",
        text: (
          <ImagePreviewContainer>
            <ImagePreview
              showCircleCrop={showCircleCrop}
              image={croppedImageRef.current}
            />
          </ImagePreviewContainer>
        ),
        handleConfirm: async () => {
          await save(new File([croppedImageRef.current as Blob], "image.jpeg"));
        },
      });
    }
  };

  const showCropping = (imageDataUrl: string) => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "UPLOAD_CROP_PREVIEW" }),
      title: "",
      closeOnConfirm: false,
      text: (
        <DialogContainer>
          <FormattedMessage id="EDIT_IMAGE" />
          <ImageCropperContainer>
            <ImageCropper
              imageSrc={imageDataUrl}
              forceSquare={forceSquare}
              showCircleCrop={showCircleCrop}
              croppedImageRef={croppedImageRef}
            />
          </ImageCropperContainer>
          <TipText>
            <FormattedMessage id="CROP_TIP_TEXT" />
          </TipText>
        </DialogContainer>
      ),
      handleConfirm: () => showPreview(),
    });
  };

  const onUploadFile = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    if (file) {
      const imageDataUrl = await readFile(file);
      if (imageDataUrl) {
        const dimensions = await createImage(imageDataUrl);
        if (dimensions.width === dimensions.height) {
          const croppedImage = await getCroppedImg(imageDataUrl, {
            x: 0,
            y: 0,
            width: dimensions.width,
            height: dimensions.height,
          });
          croppedImageRef.current = croppedImage;
          showPreview();
        } else {
          showCropping(imageDataUrl);
        }
      }
    }
  };

  return (
    <UploadArea
      onDrop={onUploadFile}
      components={{ uploadArea }}
      disabled={disabled}
    />
  );
};
