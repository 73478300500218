import { Search } from "@mui/icons-material";
import { FaSort, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import tw from "tailwind-styled-components";

export const PingEffect = tw.span`absolute left-5 inline-flex h-2 w-10 animate-ping bg-gray-400 opacity-75`;

export const SearchContainer = tw.span`flex flex-1 justify-end mr-[5%]`;
export const SearchStyled = tw(
  Search,
)`text-blue-500 hover:text-blue-300 cursor-pointer`;

export const SortContainer = tw.span`ml-2`;
export const SortAsc = tw(
  FaSortAmountDown,
)`text-blue-500 hover:text-blue-300 cursor-pointer text-base`;
export const SortDesc = tw(
  FaSortAmountUp,
)`text-blue-500 hover:text-blue-300 cursor-pointer`;
export const Sort = tw(
  FaSort,
)`text-blue-500 hover:text-blue-300 cursor-pointer`;
