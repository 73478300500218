import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { getEstimatedItem } from "@/contractor/pages/home/project/utils/getEstimatedItem";
import { ProjectItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import {
  SelectableItem,
  useAddItemsToRelease,
} from "../../AddItemsToReleaseProvider";

const Container = tw.div`
  flex mr-1 w-5
`;

export const AddEstimatedItemGroupCheckbox: FC<{
  group:
    | CategoryState<ProjectItemFieldsFragment>
    | CategoryState<CategoryState<ProjectItemFieldsFragment>>;
}> = ({ group }) => {
  const { selectedItems, setSelectedItems } = useAddItemsToRelease();
  const isSelected = useCallback(
    (item: ProjectItemFieldsFragment) => {
      return selectedItems.find(
        (i) =>
          i.itemId === item.id &&
          (i.estimatedItemId === getEstimatedItem({ item })?.id ||
            !i.estimatedItemId),
      );
    },
    [selectedItems],
  );

  const checked = useMemo(
    () =>
      group.items.every((item) => {
        if ((item as CategoryState<ProjectItemFieldsFragment>).items) {
          return (item as CategoryState<ProjectItemFieldsFragment>).items.every(
            (subitem) => isSelected(subitem),
          );
        } else {
          return isSelected(item as ProjectItemFieldsFragment);
        }
      }),
    [group.items, isSelected],
  );
  const handleChange = useCallback(
    (newChecked: boolean) => {
      if (newChecked) {
        const itemsToAdd = group.items
          .map((item) => {
            if ((item as CategoryState<ProjectItemFieldsFragment>).items) {
              return (
                item as CategoryState<ProjectItemFieldsFragment>
              ).items.map((subitem) => {
                const estimatedItem = getEstimatedItem({ item: subitem });
                return {
                  itemId: subitem.id,
                  quantityDecimal: estimatedItem?.quantityDecimal,
                  zoneId: estimatedItem?.zone?.id || UNSPECIFIED_ZONE_ID,
                  estimatedItemId: estimatedItem?.id,
                  costCodeId: estimatedItem?.costCode?.id,
                } as SelectableItem;
              });
            } else {
              const estimatedItem = getEstimatedItem({
                item: item as ProjectItemFieldsFragment,
              });
              return {
                itemId: item.id,
                quantityDecimal: estimatedItem?.quantityDecimal,
                zoneId: estimatedItem?.zone?.id || UNSPECIFIED_ZONE_ID,
                estimatedItemId: estimatedItem?.id,
                costCodeId: estimatedItem?.costCode?.id,
              } as SelectableItem;
            }
          })
          .flat();
        setSelectedItems([...selectedItems, ...itemsToAdd]);
      } else {
        const idsToRemove = group.items
          .map((item) => {
            if ((item as CategoryState<ProjectItemFieldsFragment>).items) {
              return (
                item as CategoryState<ProjectItemFieldsFragment>
              ).items.map((subitem) => subitem.id);
            } else {
              return item.id;
            }
          })
          .flat();
        setSelectedItems(
          selectedItems.filter((r) => !idsToRemove.includes(r.itemId)),
        );
      }
    },
    [group.items, selectedItems, setSelectedItems],
  );

  return (
    <Container>
      <SelectionCheckbox
        testId={`group-checkbox-${group.name.toLowerCase().replaceAll(/\s/g, "-")}`}
        checked={checked}
        setSelection={handleChange}
      />
    </Container>
  );
};
