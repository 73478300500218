import React, { FC } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { InfoTooltip } from "../info-tooltip/InfoTooltip";

const SwitchGroupContainer = tw.div`grid grid-flow-col gap-5 items-center justify-between`;
const SwitchLabel = tw.label`flex flex-row items-center gap-1 text-sm`;

type SwitchGroupProps = {
  label: React.ReactNode | string;
  className?: string;
  children: React.ReactNode;
  tooltip?: React.ReactNode;
};

export const SwitchGroup: FC<SwitchGroupProps> = ({
  label,
  className = "",
  children,
  tooltip,
}) => {
  return (
    <SwitchGroupContainer className={className}>
      <SwitchLabel>
        {label}
        <If isTrue={tooltip}>
          <InfoTooltip message={tooltip} />
        </If>
      </SwitchLabel>
      {children}
    </SwitchGroupContainer>
  );
};
