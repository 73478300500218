import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { Base, SmFixedContainer } from "@/common/layout/ResponsiveClasses";
import { EnterpriseOrgFieldsFragment } from "@/generated/graphql";
import { FormattedMessage } from "react-intl";
import { LoginLink } from "./LoginLink";

export const tableConfiguration: Array<GridCol<EnterpriseOrgFieldsFragment>> = [
  {
    wrapper: Base,
    header: <FormattedMessage id="ORG_NAME" />,
    item: ({ item }) => (
      <>{`${item.name}${item.enterprise?.name ? ` [${item.enterprise.name}]` : ""}`}</>
    ),
  },
  {
    wrapper: SmFixedContainer,
    header: <FormattedMessage id="LOGIN_TO_ORG" />,
    position: "center",
    item: ({ item }) => <LoginLink item={item} />,
  },
];
