import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import {
  COLUMN_TYPE,
  SpreadsheetSaveType,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { VIEW_STATE, useTableView } from "@/common/providers/TableViewProvider";

import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { AddCostTypeInput, UpdateCostTypeInput } from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useCostTypes } from "../hooks/useCostTypes";
import { useUpdateCostTypes } from "../hooks/useUpdateCostTypes";

type ProviderContextType = {
  syncCostTypes: (trigger?: SpreadsheetSaveType) => Promise<boolean>;
};

type Props = {
  children: React.ReactNode;
};

const ProviderContext = createContext<ProviderContextType>({
  syncCostTypes: NoFunctionBooleanPromise,
});

export const SyncCostTypesProvider: FC<Props> = ({ children }) => {
  const intl = useIntl();
  const { costTypes } = useCostTypes();
  const { updateCostTypes } = useUpdateCostTypes();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { tableView } = useTableView();
  const {
    spreadsheetData,
    resetPreviousData,
    rowHasChanges,
    gotoInvalidRow,
    getRemovedRowIds,
  } = useColumnMapper();
  const { getCellValue, rowIsEmpty } = useTableHelpers();
  const { setSuccessAlert } = useSnackbar();
  const { openDialog } = useDialog();

  const confirmRemove = (itemsToRemove: Array<unknown>) => {
    let resolveFn: (value: boolean | undefined) => void;
    const modalResult: Promise<boolean | undefined> = new Promise((resolve) => {
      resolveFn = resolve;
    });

    if (itemsToRemove.length) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        icon: <WarningIcon />,
        title: <FormattedMessage id="WARNING_REMOVING_COST_TYPES_TITLE" />,
        text: (
          <FormattedMessage
            id={
              itemsToRemove.length > 1
                ? "WARNING_REMOVING_COST_TYPES"
                : "DELETE_COST_TYPE_CONFIRMATION"
            }
            values={{
              number: itemsToRemove.length,
            }}
          />
        ),
        handleConfirm: () => {
          resolveFn(true);
        },
        handleCancel() {
          resolveFn(undefined);
        },
      });
      return modalResult;
    } else {
      return Promise.resolve(true);
    }
  };

  const syncCostTypes = async (trigger?: SpreadsheetSaveType) => {
    if (tableView !== VIEW_STATE.spreadsheet) {
      return true;
    }

    if (
      !validateRequiredValues([
        COLUMN_TYPE.CodeDescription,
        COLUMN_TYPE.Code,
      ]) ||
      !validateRowValues([COLUMN_TYPE.CodeDescription, COLUMN_TYPE.Code])
    ) {
      gotoInvalidRow();
      return false;
    }

    const itemsToUpdate: UpdateCostTypeInput[] = [];
    const newItems: AddCostTypeInput[] = [];
    const itemsToRemove = getRemovedRowIds(costTypes ?? []);

    spreadsheetData.forEach((row) => {
      if (rowIsEmpty(row)) {
        if (row.id) {
          itemsToRemove.push(row.id);
        }
        return false;
      }
      const codeText = getCellValue(row, COLUMN_TYPE.Code);
      const descriptionText = getCellValue(row, COLUMN_TYPE.CodeDescription);

      const existingItem = costTypes?.find((item) => item.id === row.id);

      if (existingItem) {
        if (rowHasChanges(row)) {
          itemsToUpdate.push({
            id: row.id,
            code: codeText,
            description: descriptionText,
          });
        }
      } else {
        newItems.push({
          code: codeText,
          description: descriptionText,
        });
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    const canRemoveItems = await confirmRemove(itemsToRemove);
    if (!canRemoveItems) {
      return false;
    }

    if (
      itemsToUpdate.length > 0 ||
      newItems.length > 0 ||
      itemsToRemove.length > 0
    ) {
      const result = await updateCostTypes({
        addedCostTypes: newItems,
        removedCostTypes: itemsToRemove,
        updates: itemsToUpdate,
      });

      if (result) {
        resetPreviousData();
        setSuccessAlert(intl.$t({ id: "COST_TYPE_SAVED_SUCCESS" }));
      }
      return !!result;
    } else if (trigger === SpreadsheetSaveType.SaveButton) {
      setSuccessAlert(intl.$t({ id: "NOTHING_TO_SAVE" }));
    }

    return true;
  };

  return (
    <ProviderContext.Provider
      value={{
        syncCostTypes,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useSyncCostTypes = (): ProviderContextType =>
  useContext(ProviderContext);
