import { InnerLabeledSwitch } from "@/common/components/switch/InnerLabeledSwitch";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useMasterSkusProductsToggle } from "./MasterSkusProductsToggleProvider";

const ProductSkuSwitchContainer = tw.div`
  flex items-center
`;

export const ProductSkuSwitch = () => {
  const intl = useIntl();
  const { showProductsToggle, toggleShowProducts } =
    useMasterSkusProductsToggle();

  return (
    <ProductSkuSwitchContainer>
      <InnerLabeledSwitch
        toggle={toggleShowProducts}
        width={100}
        initialValue={showProductsToggle}
        onLabel={intl.$t({ id: "PRODUCT" })}
        offLabel={intl.$t({ id: "SKU" })}
      />
    </ProductSkuSwitchContainer>
  );
};
