import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { PrintButton } from "@/common/components/print-button/PrintButton";
import { DistributorBuyoutFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { usePrintView } from "../hooks/usePrintView";

const IconButtonStyled = tw(IconButtonBorderless)`
  text-blue-500 w-50 hover:cursor-pointer
`;

type PrintButtonProps = {
  buyout: DistributorBuyoutFieldsFragment;
};

export const BuyoutPrintButton: FC<PrintButtonProps> = ({ buyout }) => {
  const { savePDF } = usePrintView(buyout);
  return (
    <IconButtonStyled onClick={savePDF}>
      <PrintButton />
    </IconButtonStyled>
  );
};
