import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { DatePicker } from "@/common/components/picker/components/DatePicker";
import {
  TextField,
  TextFieldProps,
} from "@/common/components/textfield/TextField";
import {
  OrgMaterialFieldsFragment,
  VendorPriceFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedDate } from "react-intl";
import { useMaterialPrices } from "../../providers/MaterialPricesProvider";
import { ReadonlyContainer } from "./MaterialPriceStyles";

const DatePickerTextField: FC<TextFieldProps> = ({
  required,
  staticText,
  onBlur,
  ...props
}) => {
  return (
    <TextField
      {...props}
      size="small"
      required={required}
      staticText={staticText}
      onBlur={onBlur}
      error={false}
      sx={{
        " .MuiInputBase-root": {
          backgroundColor: "#fff",
        },
        " .MuiInputBase-input": {
          paddingTop: "8.5px",
          paddingBottom: "8.5px",
          paddingLeft: "8px",
          lineHeight: "1em",
          height: "15px",
          fontSize: "14px",
          letterSpacing: "-0.5px",
        },
        " .MuiIconButton-root": {
          color: "#0070C0",
        },
        " .MuiIconButton-root:hover": {
          color: "#23B1ED",
          border: 0,
          backgroundColor: "transparent",
        },
      }}
    />
  );
};

type Props = {
  item: OrgMaterialFieldsFragment;
  price?: VendorPriceFieldsFragment;
  error?: boolean;
  readonly: boolean;
};

export const MaterialDatePicker: FC<Props> = ({ item, price, readonly }) => {
  const { updateMaterial } = useMaterialPrices();
  const [date, setDate] = useState<Date | null>(
    new Date(price?.expirationDate || ""),
  );

  const onChange = useCallback(
    (newValue: Date | null) => {
      setDate(newValue);
      updateMaterial(item.id, {
        vendorPriceId: price?.id || "",
        expirationDate: newValue?.getTime(),
      });
    },
    [price, updateMaterial, item],
  );

  useEffect(() => {
    setDate(new Date(price?.expirationDate || ""));
  }, [price]);

  return (
    <NotNullableRenderer value={price?.expirationDate || !readonly}>
      {readonly ? (
        <ReadonlyContainer>
          <FormattedDate value={price?.expirationDate ?? ""} />
        </ReadonlyContainer>
      ) : (
        <DatePicker
          date={date}
          onChange={onChange}
          textFieldComponent={DatePickerTextField}
          clearable
        />
      )}
    </NotNullableRenderer>
  );
};
