import { FC, useMemo } from "react";
import {
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaFilePowerpoint,
  FaFileWord,
} from "react-icons/fa";

import { MdOutlineImage } from "react-icons/md";

import tw from "tailwind-styled-components";

const Container = tw.div`
  grid grid-flow-col gap-2 items-center text-2xl
`;

type Props = {
  fileName: string;
  iconClassName?: string;
};

export const FileIcon: FC<Props> = ({ fileName, iconClassName = "" }) => {
  const extension = useMemo(() => {
    const fileExt = fileName.split(".").pop();
    switch (fileExt) {
      case "pdf":
        return <FaFilePdf />;
      case "csv":
        return <FaFileCsv />;
      case "xls":
      case "xlsx":
      case "xlsm":
        return <FaFileExcel />;
      case "doc":
      case "docx":
        return <FaFileWord />;
      case "ppt":
        return <FaFilePowerpoint />;
      default:
        return <MdOutlineImage />;
    }
  }, [fileName]);

  return <Container className={iconClassName}>{extension}</Container>;
};
