import { If } from "@/common/components/if/If";
import {
  InvoiceIssue,
  InvoiceIssueType,
} from "@/common/components/invoices/invoice-issue/common/InvoiceIssue";
import { InvoiceIssueFieldsFragment } from "@/generated/graphql";
import { FC } from "react";

type Props = {
  issues?: InvoiceIssueFieldsFragment[];
  invoiceId?: string;
  canEdit?: boolean;
};

export const InvoiceVendorIssues: FC<Props> = ({
  issues = [],
  invoiceId,
  canEdit,
}) => {
  return (
    <If isTrue={issues.length > 0}>
      {issues.map((issue, key) => (
        <InvoiceIssue
          key={key}
          id={issue.id}
          index={key + 1}
          invoiceId={invoiceId}
          type={InvoiceIssueType.ISSUE}
          issueDescription={issue.description}
          issueDate={issue.createdAt}
          assets={issue.contractorAssets}
          resolutionAssets={issue.vendorAssets}
          resolutionDescription={issue.resolutionDescription}
          issueResponseDate={issue.respondedAt}
          rejectionReason={issue.rejectionReason}
          canEdit={canEdit && !issue.respondedAt}
        />
      ))}
    </If>
  );
};
