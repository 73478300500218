import {
  DrawerProvider,
  useDrawer,
} from "@/common/components/panel/DrawerProvider";
import { DrawerView } from "@/common/components/panel/DrawerView";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import { MessagePanelProps, MessagesPanel } from "../components/MessagesPanel";

type Props = PropsWithChildren<{ projectId?: string }>;

type PanelProps = Omit<MessagePanelProps, "onClose">;

type ContextType = {
  setData: (data: PanelProps) => void;
  setIsOpened: (value: boolean) => void;
};

const Context = createContext<ContextType>({
  setData: NoFunction,
  setIsOpened: NoFunction,
});

const MessagesPanelProviderWithDrawer: FC<Props> = ({
  children,
  projectId,
}) => {
  const { isOpened, setIsOpened } = useDrawer();
  const [panelData, setPanelData] = useState<PanelProps | null>();

  return (
    <Context.Provider
      value={{
        setData: setPanelData,
        setIsOpened,
      }}
    >
      <DrawerView
        content={(togglePanel) => (
          <>
            {panelData ? (
              <MessagesPanel
                {...panelData}
                projectId={projectId}
                onClose={() => togglePanel(false)}
              />
            ) : null}
          </>
        )}
        isOpened={isOpened}
        setIsOpened={setIsOpened}
      />
      {children}
    </Context.Provider>
  );
};

export const MessagesPanelProvider: FC<Props> = (props) => {
  return (
    <DrawerProvider>
      <MessagesPanelProviderWithDrawer {...props} />
    </DrawerProvider>
  );
};

export const useMessagesPanel = (): ContextType => useContext(Context);
