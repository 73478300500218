import { Price } from "@/common/components/price/Price";
import { BuyoutItemFieldsFragment, ReleaseStatus } from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import { FormattedNumber } from "react-intl";
import tw from "tailwind-styled-components";
import { OrderedContainer, OrderedItem } from "./Buyout.styles";
import { If } from "@/common/components/if/If";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";

const Container = tw(OrderedContainer)`text-xs`;

type Props = {
  items: BuyoutItemFieldsFragment[];
  releaseStatuses: ReleaseStatus[];
};

export const BuyoutReleaseAmountsCount: FC<Props> = ({
  items,
  releaseStatuses,
}) => {
  const { hasPermissions: canViewPrices } = usePermissions([
    Permission.canViewPrices,
  ]);

  const costCodeReceivedTotal = useMemo(
    () =>
      items.reduce((acc, i) => {
        let itemTotal = 0;
        i.releaseItems.forEach((ri) => {
          if (releaseStatuses.includes(ri.release.status)) {
            itemTotal += new Decimal(ri.quantityDecimal)
              .mul(ri.unitPrice || 0)
              .toNumber();
          }
        });

        return acc.add(itemTotal);
      }, new Decimal(0)),
    [items, releaseStatuses],
  );

  const total = useMemo(
    () =>
      items.reduce((acc, i) => {
        const itemTotal = new Decimal(i.quantityDecimal).mul(i.unitPrice || 0);
        return acc.add(itemTotal);
      }, new Decimal(0)),
    [items],
  );

  const receivedPercentage = useMemo(
    () =>
      costCodeReceivedTotal.toNumber() === 0 || total.toNumber() === 0
        ? 0
        : costCodeReceivedTotal.div(total).mul(100).toNumber(),
    [costCodeReceivedTotal, total],
  );

  return (
    <Container>
      <If isTrue={canViewPrices}>
        <Price price={costCodeReceivedTotal.toNumber()} />
      </If>
      <OrderedItem>
        (
        <FormattedNumber value={receivedPercentage} maximumFractionDigits={2} />
        %)
      </OrderedItem>
    </Container>
  );
};
