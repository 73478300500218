import { gql } from "@apollo/client";

export const PO_LINK_FIELDS = gql`
  fragment POLinkFields on POLink {
    id
    format
    sourceSystem
    externalWarehouseId
    syncing
    syncedVersion
    syncedAt
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    autoSync
    autoSyncError
    autoSyncFailureCount
    retroactive
  }
`;
