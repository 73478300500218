import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { VIEW_STATE, useTableView } from "@/common/providers/TableViewProvider";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useProjectTags } from "@/contractor/pages/home/project/providers/ProjectTagsProvider";
import {
  AddToBuyoutItemInput,
  UpdateContractorBuyoutItemInput,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutGrouped } from "./BuyoutGroupedProvider";

type ProviderContextType = {
  syncBuyoutItems: () => Promise<boolean>;
  saving: boolean;
};

type AddToBuyoutExtendedItemInput = AddToBuyoutItemInput & {
  orgCatalogSkuName?: string;
  isAddMode?: boolean;
  notes?: string;
};

type Props = {
  children: React.ReactNode;
};

const ProviderContext = createContext<ProviderContextType>({
  syncBuyoutItems: NoFunctionBooleanPromise,
  saving: false,
});

export const SyncBuyoutItemsProvider: FC<Props> = ({ children }) => {
  const { manufacturers } = useManufacturers();
  const {
    spreadsheetData,
    getRemovedRowIds,
    rowHasChanges,
    resetPreviousData,
    gotoInvalidRow,
  } = useColumnMapper();
  const {
    getCellValue,

    getRowUomCreatableValue,
    rowIsEmpty,
    addMissingMaterials,
    addMissingManufacturers,
    getCellWithAdditionalData,
    findMaterialByName,
    getNameParts,
    getCostCodeId,
    addMissingTags,
  } = useTableHelpers();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { buyout } = useContractorBuyout();
  const { tableView } = useTableView();
  const { materials } = useMaterials();
  const { updateBuyoutItems, loading: updating } = useBuyoutGrouped();
  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();
  const { tags } = useProjectTags();

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setSaving(updating);
  }, [updating]);

  const syncBuyoutItems = async () => {
    if (tableView !== VIEW_STATE.spreadsheet) {
      return true;
    }

    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        COLUMN_TYPE.PositiveQuantity,
        COLUMN_TYPE.UOM,
      ]) ||
      !validateRowValues([
        COLUMN_TYPE.PositiveQuantity,
        COLUMN_TYPE.PrefilledPrice,
        COLUMN_TYPE.UOM,
        COLUMN_TYPE.CostCode,
      ])
    ) {
      gotoInvalidRow();
      return false;
    }

    const newItems: AddToBuyoutExtendedItemInput[] = [];
    const itemsToUpdate: UpdateContractorBuyoutItemInput[] = [];
    const itemsToRemove = getRemovedRowIds(buyout?.items ?? []);

    setSaving(true);
    const newMaterials = (await addMissingMaterials()) || [];
    const newManufacturers = (await addMissingManufacturers()) || [];
    const newTags = (await addMissingTags(buyout?.project?.id || "")) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      const material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      if (!material || rowIsEmpty(row)) {
        if (row.id) {
          itemsToRemove.push(row.id);
        }
        return false;
      }

      const uom = getRowUomCreatableValue(row);
      const quantityDecimal = getCellValue(row, COLUMN_TYPE.PositiveQuantity);
      const unitPrice = Number(getCellValue(row, COLUMN_TYPE.PrefilledPrice));
      const manufacturer = [...manufacturers, ...(newManufacturers ?? [])].find(
        (m) => m?.name === getCellValue(row, COLUMN_TYPE.Manufacturer),
      );
      const notes = getCellValue(row, COLUMN_TYPE.Notes) || "";

      const tag = [...(tags || []), ...newTags].find(
        (t) => t?.name === getCellValue(row, COLUMN_TYPE.Tag),
      );
      const existingItem = buyout?.items.find((item) => item.id === row.id);
      const matchingMaterials =
        existingItem && existingItem.projectItem.material.id === material.id;
      if (matchingMaterials) {
        if (rowHasChanges(row) || existingItem.position !== index) {
          itemsToUpdate.push({
            buyoutItemId: row.id,
            quantityDecimal,
            instructions: {
              text: notes,
            },
            uom,
            clearManufacturer: !manufacturer,
            requestedUnitPrice: unitPrice ? String(unitPrice) : undefined,
            manufacturerId: manufacturer?.id,
            position: index,
            costCodeId: getCostCodeId(row),
            clearCostCode: !getCostCodeId(row),
            tags: tag ? [tag.id] : [],
          });
        }
      } else {
        newItems.push({
          description: getNameParts(rowMaterialText).namePart,
          projectItem: {
            estimateUom: uom,
            ...(isOrgCatalogSku(material.material) && {
              orgCatalogSkuId: material.material.id,
            }),
            ...(isProductSku(material.material) && {
              masterProductId: material.material.id,
            }),
            ...(isMasterSku(material.material) && {
              masterSkuId: material.material.id,
            }),
          },
          instructions: {
            text: notes,
          },
          quantityDecimal,
          requestedUnitPrice: unitPrice ? String(unitPrice) : undefined,
          manufacturerId: manufacturer?.id,
          position: index,
          costCodeId: getCostCodeId(row),
          tags: tag ? [tag.id] : [],
        });
        if (row.id) {
          itemsToRemove.push(row.id);
        }
      }
    });

    if (
      itemsToUpdate.length > 0 ||
      newItems.length > 0 ||
      itemsToRemove.length > 0
    ) {
      const result = await updateBuyoutItems({
        buyoutId: buyout?.id || "",
        version: buyout?.version || 0,
        updates: itemsToUpdate,
        addedItems: newItems,
        removedItems: itemsToRemove,
      });

      if (result) {
        resetPreviousData();
        setSuccessAlert(intl.$t({ id: "BUYOUT_ITEMS_SAVED_SUCCESS" }));
      }
      return result;
    }
    return true;
  };

  return (
    <ProviderContext.Provider
      value={{
        syncBuyoutItems,
        saving,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useSyncBuyoutItems = (): ProviderContextType =>
  useContext(ProviderContext);
