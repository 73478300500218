import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { UserAvatar } from "@/common/components/user-avatar/UserAvatar";
import { AdminUsersUserFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { getUserName } from "../../../../../common/utils/users/getUserName";
import { UserForm } from "./user-form/UserForm";

type Props = {
  user: AdminUsersUserFieldsFragment;
};

const LinkLikeStyled = tw(LinkLike)`
  font-normal p-0
`;

const UserAvatarStyled = tw(UserAvatar)`
  mr-2 border border-blue-500
`;

export const UserName: FC<Props> = ({ user }) => {
  const intl = useIntl();
  const userFullName = getUserName(user);

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <>
          <UserAvatarStyled
            firstName={user.firstName}
            lastName={user.lastName}
            email={user.email}
            photoUrl={user.photoUrl}
          />
          <LinkLikeStyled onClick={() => togglePanel(true)}>
            {userFullName || user.email}
          </LinkLikeStyled>
        </>
      )}
      content={(togglePanel) => (
        <UserForm
          title={intl.$t({ id: "USERS_EDIT_USER" })}
          onClose={() => togglePanel(false)}
          user={user}
        />
      )}
    />
  );
};
