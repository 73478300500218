import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { useRoles } from "@/common/components/org-roles-wrapper/hasRoles";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { OrgRole } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC, Fragment, PropsWithChildren, ReactNode, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`relative`;
const ErrorList = tw.div`flex flex-col gap-2`;

type ErrorItemProps = {
  $isError?: boolean;
  $isFirstWarning: boolean;
};
const ErrorItem = tw.div<ErrorItemProps>`
  flex flex-row gap-2 items-center
  ${({ $isFirstWarning }) =>
    $isFirstWarning ? "border-t border-dashed border-gray-400 pt-2" : ""}
  ${({ $isError }) => ($isError ? "text-red-500" : "text-gray-600")}
`;
const ErrorText = tw.div`text-xs`;

export enum ValidationType {
  Error = "error",
  Warning = "warning",
}

export type ConnectionError = {
  id: string;
  condition?: boolean;
  includeCondition?: boolean;
  key: string;
  toolTipKey?: string;
  adminToolTipKey?: string;
  values?: Record<
    string,
    | string
    | number
    | ((chunks: ReactNode) => JSX.Element)
    | JSX.Element
    | undefined
    | null
  >;
  child?: ReactNode;
  button?: ReactNode;
  validationType?: ValidationType | undefined;
  hideInfoIcon?: boolean;
};

type Props = PropsWithChildren<{
  includedValidations: ConnectionError[];
  classes?: {
    item?: string;
  };
  includeDivider?: boolean;
}>;

export const ExportErrorList: FC<Props> = ({
  includedValidations,
  classes,
  includeDivider = true,
}) => {
  const { hasRoles: isAdmin } = useRoles({ roles: [OrgRole.OrgAdmin] });

  const sortedValidations = useMemo(
    () =>
      includedValidations.sort((i) => {
        if (!i.validationType) {
          return -1;
        }

        if (i.validationType === ValidationType.Error) {
          return -1;
        }

        return 1;
      }),
    [includedValidations],
  );

  return (
    <If isTrue={includedValidations.length}>
      <Container>
        <ErrorList>
          {sortedValidations.map((e, key) => (
            <Fragment key={key}>
              <ErrorItem
                $isError={e.validationType !== ValidationType.Warning}
                $isFirstWarning={
                  includeDivider &&
                  e.validationType === ValidationType.Warning &&
                  key !== 0 &&
                  sortedValidations.findIndex(
                    (v) => v.validationType === ValidationType.Warning,
                  ) === key
                }
                key={key}
                className={classes?.item}
              >
                <If isTrue={!e.hideInfoIcon}>
                  <Tooltip
                    id={e.toolTipKey || ""}
                    element={
                      <LinkLike
                        disabled={e.validationType === ValidationType.Warning}
                      >
                        <InfoOutlined
                          className={
                            e.validationType !== ValidationType.Warning
                              ? "text-red-500"
                              : ""
                          }
                        />
                      </LinkLike>
                    }
                  >
                    {(
                      isAdmin ? e.adminToolTipKey || e.toolTipKey : e.toolTipKey
                    ) ? (
                      <FormattedMessage
                        id={
                          isAdmin
                            ? e.adminToolTipKey || e.toolTipKey
                            : e.toolTipKey
                        }
                        values={e.values}
                      />
                    ) : null}
                  </Tooltip>
                </If>
                <FormattedMessage
                  id={e.key}
                  values={e.values}
                  tagName={ErrorText}
                />
                <If isTrue={e.button}>{e.button}</If>
              </ErrorItem>
              {e.child}
            </Fragment>
          ))}
        </ErrorList>
      </Container>
    </If>
  );
};
