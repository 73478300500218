import { gql } from "@apollo/client";
import { MANUFACTURER_FIELDS } from "../fragments/manufacturerFragment";

export const MANUFACTURERS = gql`
  ${MANUFACTURER_FIELDS}
  query Manufacturers($searchPrefix: String!) {
    manufacturers(searchPrefix: $searchPrefix) {
      ...ManufacturerFields
    }
  }
`;
