import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { NumericalInput } from "@/common/components/numerical-input/NumericalInput";
import { StaticDatePicker } from "@/common/components/picker/components/StaticDatePicker";
import { areDatesEqual } from "@/common/components/picker/utils/areDatesEqual";
import { Popover as CustomPopover } from "@/common/components/popover/Popover";
import { Popover } from "@mui/material";
import { CalendarIcon, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  discountDate: Date | null;
  setDiscountDate: (data: Date | null) => void;
  issueDate: Date | null;
};

const getTimezoneOffset = (value: Date) => value.getTimezoneOffset() * 60000;

const makeLocalAppearUTC = (value: Date) => {
  const dateTime = new Date(value);
  return new Date(dateTime.getTime() + getTimezoneOffset(dateTime));
};

const localToUTC = (dateTime: Date) => {
  return new Date(dateTime.getTime() - getTimezoneOffset(dateTime));
};

const Container = tw.div`pb-4 px-4`;
const PickersDayStyled = tw(
  PickersDay as React.ComponentType<PickersDayProps<Date>>,
)`
  ${({ $selected }: { $selected?: boolean }) => $selected && "bg-blue-800"}
`;
const CustomPickersDay = tw(
  PickersDay as React.ComponentType<PickersDayProps<Date>>,
)`
  ${({ $selected }: { $selected?: boolean }) =>
    $selected && "bg-orange-500 text-black border-white rounded-full hover"}`;
const DaysSinceIssuedContainer = tw.div`flex items-center gap-2 px-2`;
const Label = tw.div`font-medium text-base`;
const Days = tw.span`lowercase text-gray-400`;
const ButtonContainer = tw.div`flex items-center justify-center gap-4 mt-4`;

export const InvoiceDiscountDatePicker: FC<Props> = ({
  discountDate,
  setDiscountDate,
  issueDate,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | SVGElement>(null);
  const open = Boolean(anchorEl);
  const [date, setDate] = useState(discountDate);

  const renderDay = useCallback(
    ({ day, ...other }: PickersDayProps<Date>) => {
      if (
        issueDate &&
        !areDatesEqual(date, day) &&
        areDatesEqual(issueDate, day)
      ) {
        const key = `day-${day.getDay()}`;
        return (
          <CustomPopover
            id={key}
            key={key}
            element={<CustomPickersDay day={day} {...other} $selected={true} />}
          >
            <FormattedMessage id="ISSUED" />
          </CustomPopover>
        );
      }

      return <PickersDayStyled day={day} {...other} />;
    },
    [date, issueDate],
  );

  const daysSinceIssueDate = useMemo(() => {
    if (!issueDate || !date) {
      return 0;
    }
    return (
      Math.abs(issueDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
    );
  }, [issueDate, date]);

  const isDisabled = useMemo(
    () =>
      date?.getTime() === discountDate?.getTime() ||
      Boolean(issueDate && date && date.getTime() < issueDate.getTime()),
    [date, discountDate, issueDate],
  );

  return (
    <>
      <CalendarIcon
        aria-controls={open ? "discount-menu" : undefined}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Container>
          <StaticDatePicker
            minDate={issueDate ?? undefined}
            value={date ? makeLocalAppearUTC(date) : date}
            onChange={(date?: Date | null) =>
              setDate(date ? localToUTC(date) : null)
            }
            slots={{
              day: renderDay,
            }}
          />
          {issueDate ? (
            <DaysSinceIssuedContainer>
              <Label>
                <FormattedMessage id="DAYS_SINCE_ISSUED" />
              </Label>
              <NumericalInput
                className="w-40"
                label={<FormattedMessage id="DAYS_SINCE_ISSUED" />}
                value={daysSinceIssueDate}
                onChange={(event) => {
                  const value = parseInt(event.target.value, 10);
                  if (!isNaN(value) && issueDate) {
                    setDate(
                      new Date(
                        issueDate.getTime() + value * 24 * 60 * 60 * 1000,
                      ),
                    );
                  }
                }}
                InputProps={{
                  endAdornment: <FormattedMessage id="DAYS" tagName={Days} />,
                }}
                decimals={0}
                inputProps={{ className: "text-center" }}
              />
            </DaysSinceIssuedContainer>
          ) : null}
          <ButtonContainer>
            <OutlinedButton onClick={() => setAnchorEl(null)} wide>
              <FormattedMessage id="CANCEL" />
            </OutlinedButton>
            <PrimaryButton
              onClick={() => setDiscountDate(date)}
              wide
              disabled={isDisabled}
            >
              <FormattedMessage id="CONFIRM" />
            </PrimaryButton>
          </ButtonContainer>
        </Container>
      </Popover>
    </>
  );
};
