import { ValueCurrency } from "@/common/components/value-currency/ValueCurrency";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  DistributorReleaseItemFieldsFragment,
  UomFieldsFragment,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";

export const ReleaseItemPrice: FC<{
  item: ExpandedReleaseItem | DistributorReleaseItemFieldsFragment;
  perUom?: UomFieldsFragment | null;
}> = ({ item, perUom }) => {
  const price = useMemo(() => {
    if (item.id) {
      return Number(new Decimal(item.unitPrice || 0));
    } else {
      return 0;
    }
  }, [item]);

  if (!item?.id) {
    return null;
  }
  return <ValueCurrency perUom={perUom} value={price} dynamicDigits />;
};
