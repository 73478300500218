import { GridTable } from "@/common/components/grid-table/GridTable";
import { getExpandedItems } from "@/common/components/grid-table/utils/getExpandedItems";
import { useHideZones } from "@/common/hooks/useHideZones";
import {
  DistributorReleaseItemFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { checkReleaseStatus } from "../../../../../common/utils/status-checks/checkReleaseStatus";
import { useDistributorReleaseItemsZoneProvider } from "../../providers/DistributorReleaseItemsZoneProvider";
import { useDistributorRelease } from "../../providers/DistributorReleaseProvider";
import { useDistributorReleaseConfiguration } from "./DistributorRelease.configuration";

const ZoneItemsContainer = tw.div`flex flex-col`;

export const DistributorReleaseList: FC = () => {
  const { zones, toggleZone, toggleCostCode, groupedByCostCode } =
    useDistributorReleaseItemsZoneProvider();
  const { release, updatedItem, expandedItems } = useDistributorRelease();
  const distributorReleaseConfiguration =
    useDistributorReleaseConfiguration(release);
  const { hideZones } = useHideZones(zones);

  const readonly = useMemo(
    () =>
      !checkReleaseStatus(release, [
        ReleaseStatus.Requested,
        ReleaseStatus.Scheduled,
      ]),
    [release],
  );

  const itemFn = useCallback(
    (item: DistributorReleaseItemFieldsFragment) => {
      return {
        className: `${
          (updatedItem(item)?.isIncluded ?? item.isIncluded)
            ? ""
            : "bg-gray-100"
        }`,
      };
    },
    [updatedItem],
  );

  if (!release) {
    return null;
  }

  return (
    <GridTable
      configuration={{
        container: ZoneItemsContainer,
        columns: distributorReleaseConfiguration,
        classNames: {
          header: "top-[32px]",
          category: "top-[16px] md:top-[36px] lg:top-[78px]",
          subCategory: !groupedByCostCode
            ? "hidden"
            : hideZones
              ? "top-[16px] md:top-[36px] lg:top-[78px]"
              : "top-[75px] lg:top-[118px]",
          detailsContent: "lg:p-0",
          itemFn,
        },
        toggle: {
          category: toggleZone,
          subCategory: toggleCostCode,
        },
      }}
      expandedItems={(item) => getExpandedItems(item, expandedItems)}
      items={zones}
      hideGroup={hideZones}
      readonly={readonly}
    />
  );
};
