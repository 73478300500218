import pako from "pako";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { usePagination } from "../components/pagination/PaginationProvider";
import { QUERYSTRING } from "../const";

type FilterParams =
  | ({
      name?: string | null | undefined;
      search?: string | null | undefined;
    } & Record<string, unknown>)
  | undefined;

const compressQueryParam = (data: unknown): string => {
  const json = JSON.stringify(data);
  const compressed = pako.deflate(json);
  return btoa(String.fromCharCode(...new Uint8Array(compressed)));
};

const decompressQueryParam = (compressedData: string) => {
  const binaryString = atob(compressedData);
  const charArray = binaryString.split("").map((char) => char.charCodeAt(0));
  const byteArray = new Uint8Array(charArray);
  const decompressed = pako.inflate(byteArray, { to: "string" });
  return JSON.parse(decompressed);
};

export const useFiltersQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setPage } = usePagination();

  const getFiltersQueryParam = useCallback(() => {
    const compressedQueryParams = searchParams.get(QUERYSTRING.FILTERS);
    if (!compressedQueryParams) {
      return null;
    }
    return decompressQueryParam(compressedQueryParams);
  }, [searchParams]);

  const setFiltersQueryParams = useCallback(
    (filterParams: FilterParams) => {
      if (filterParams) {
        const compressedQueryParams = compressQueryParam({
          ...filterParams,
          search: undefined,
          name: undefined,
        });
        setPage({ page: 0 });
        setSearchParams({
          ...searchParams,
          [QUERYSTRING.FILTERS]: compressedQueryParams,
        });
      } else {
        setSearchParams({
          ...searchParams,
          [QUERYSTRING.FILTERS]: null,
        });
      }
    },
    [searchParams, setSearchParams, setPage],
  );

  return { getFiltersQueryParam, setFiltersQueryParams };
};
