import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import {
  MessageContextKind,
  MessageStats,
  useMessageStatsQuery,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { FC, PropsWithChildren, createContext, useContext } from "react";

type Props = PropsWithChildren<{
  kind: MessageContextKind;
  id: string;
}>;

type ProviderContextType = {
  loading: boolean;
  messageStats?: MessageStats;
  refetch: () => void;
};

const ProviderContext = createContext<ProviderContextType>({
  messageStats: undefined,
  loading: false,
  refetch: NoFunction,
});

export const MessageStatsProvider: FC<Props> = ({ children, id, kind }) => {
  const { data, error, loading, refetch } = useMessageStatsQuery({
    variables: {
      input: {
        context: { kind, id },
      },
    },
  });

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        loading,
        messageStats: data?.messageStats,
        refetch,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useMessageStats = (): ProviderContextType =>
  useContext(ProviderContext);
