export const filterByDescriptionAndInstructions = (
  item: {
    description: string;
    instructions?: {
      text?: string;
    } | null;
    notes?: string | null;
  },
  searchText: string,
) =>
  item.instructions?.text?.toLowerCase().includes(searchText.toLowerCase()) ||
  item.notes?.toLowerCase().includes(searchText.toLowerCase()) ||
  false;
