import { If } from "@/common/components/if/If";
import { ManufacturerModalPicker } from "@/common/components/manufacturer-modal-picker/ManufacturerModalPicker";
import { isMasterSku } from "@/common/components/material/utils";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { ReleaseStatus } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { ExpandedReleaseItem } from "../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";

type Props = {
  category: CategoryState<ExpandedReleaseItem>;
  readonly?: boolean;
};

export const ReleaseCostCodeManufacturerSelector: FC<Props> = ({
  category,
  readonly,
}) => {
  const { release, updateRelease } = useReleaseUpdate();

  const isBatch = useMemo(
    () =>
      release.status === ReleaseStatus.Draft ||
      release.status === ReleaseStatus.AwaitingApproval ||
      release.status === ReleaseStatus.Rejected,
    [release.status],
  );

  const saveManufacturer = useCallback(
    async (manufacturerId: string | null) => {
      const updates = category.items
        .filter(
          (item) => !isMasterSku(item.material.material) && !item.buyoutItem,
        )
        .map((item) => ({
          manufacturerId,
          releaseItemId: item.id,
        }));
      await updateRelease(
        { updates, releaseId: release?.id },
        { batch: isBatch },
      );
    },
    [category.items, isBatch, release?.id, updateRelease],
  );

  return (
    <If
      isTrue={category.items.some(
        (item) => !isMasterSku(item.material.material) && !item.buyoutItem,
      )}
    >
      <ManufacturerModalPicker
        onChange={saveManufacturer}
        readonly={readonly}
      />
    </If>
  );
};
