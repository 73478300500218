import { GraphQLError } from "graphql";
import {
  GlobalErrorType,
  useGlobalErrorStore,
} from "../stores/useGlobalErrorStore";

type ErrorFunction = (
  error: readonly GraphQLError[] | undefined | unknown,
  cleanup?: boolean,
) => void;

type UseGlobalErrorReturn = {
  setError: ErrorFunction;
  removeError: (error: GlobalErrorType) => void;
  errors: GlobalErrorType[];
};

export const useGlobalError = (): UseGlobalErrorReturn => {
  const { errors, removeError, setError } = useGlobalErrorStore();

  return {
    setError,
    removeError,
    errors,
  };
};
