import { QuantityPicker } from "@/common/components/quantity-picker/QuantityPicker";
import {
  EstimatedItemFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";

type Props = {
  estimatedItem?: EstimatedItemFieldsFragment | null;
  item: ProjectItemFieldsFragment;
  readonly?: boolean;
  staticText?: boolean;
};

export const ProjectItemUnitsInput: FC<Props> = ({
  estimatedItem,
  item,
  readonly = false,
  staticText = false,
}) => {
  const { setNewProjectEstimatedItem, updating } = useEstimatedItems();

  const saveQuantity = useCallback(
    (quantity: string | null) => {
      setNewProjectEstimatedItem([{ key: "quantity", value: quantity }]);
    },
    [setNewProjectEstimatedItem],
  );

  return (
    <QuantityPicker
      quantity={estimatedItem?.quantityDecimal}
      uom={item.estimateUom}
      id={(estimatedItem || item).id}
      saveQuantity={saveQuantity}
      readonly={readonly}
      staticText={staticText}
      updating={updating}
      testId="editable-project-item-quantity"
    />
  );
};
