import { SequenceStepper } from "@/common/components/sequence-stepper/SequenceStepper";
import { routes } from "@/config/routes";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useInvoiceSequence } from "../../../scanned-invoices/providers/InvoiceSequenceProvider";

export const InvoiceSequenceStepper = () => {
  const navigate = useNavigate();
  const { step, setStep, sequenceIds, redirectToInvoices } =
    useInvoiceSequence();

  const prevStepCallback = useCallback(() => {
    if (sequenceIds[step - 1]) {
      navigate(
        generatePath(routes.invoiceVerification, {
          invoiceId: sequenceIds[step - 1],
        }),
      );
    }
  }, [navigate, sequenceIds, step]);

  const nextStepCallback = useCallback(() => {
    if (sequenceIds[step + 1]) {
      navigate(
        generatePath(routes.invoiceVerification, {
          invoiceId: sequenceIds[step + 1],
        }),
      );
    }
  }, [navigate, sequenceIds, step]);

  return (
    <SequenceStepper
      titleId="INVOICES"
      step={step}
      setStep={setStep}
      sequenceIds={sequenceIds}
      exitSequence={redirectToInvoices}
      prevStepCallback={prevStepCallback}
      nextStepCallback={nextStepCallback}
    />
  );
};
