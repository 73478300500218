import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { DrawerView } from "@/common/components/panel/DrawerView";
import { useIntl } from "react-intl";
import { useOrderTypes } from "../../hooks/useOrderTypes";
import { OrderTypeForm } from "./OrderTypeForm";

export const EditOrderTypeId = "edit-order-type-panel";

export const ToggleEditOrderType = () => {
  const { isOpened, toggle, getObjectId } = useGlobalDrawer();
  const intl = useIntl();
  const { orderTypes } = useOrderTypes();

  return (
    <DrawerView
      isOpened={isOpened(EditOrderTypeId)}
      content={(togglePanel) => (
        <OrderTypeForm
          title={intl.$t({ id: "ORDER_TYPES_NEW_TITLE" })}
          onClose={() => {
            togglePanel(false);
          }}
          orderType={orderTypes.find(
            (v) => v.id === getObjectId(EditOrderTypeId),
          )}
        />
      )}
      setIsOpened={(value) => {
        toggle(EditOrderTypeId, value);
      }}
    />
  );
};
