import Truck from "@/assets/icons/truck.svg?react";
import { List } from "@mui/icons-material";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import tw from "tailwind-styled-components";
import { If } from "../if/If";

export enum ShoppingIconType {
  Truck,
  List,
}

type ShoppingCartIconProps = {
  count: number;
  path: string;
  type: ShoppingIconType;
};

type CounterProps = Pick<ShoppingCartIconProps, "count">;

const ShoppingCart = tw.div`
  relative
`;

const LinkStyled = tw(Link)<CounterProps>`
  ${(props: CounterProps) => (props.count === 0 ? "cursor-default" : "")}
`;

const TruckIcon = tw(Truck)<CounterProps>`
  w-16 h-16
  ${(props: CounterProps) =>
    props.count > 0 ? "text-green-600" : "text-gray-500 "}
`;

const ListIcon = tw(List)<CounterProps>`
  w-12 h-12
  ${(props: CounterProps) =>
    props.count > 0 ? "text-blue-500" : "text-gray-500 "}
`;

const Count = tw.div`
  absolute text-white flex items-center justify-center
`;

const ShoppingCartCount = tw(Count)`
  top-4 left-0 text-base font-bold w-9 h-5
`;

const ListCartCount = tw(Count)`
  top-4 -right-1 text-xs font-base m-1 max-w-6 max-h-6 w-full h-full min-w-5 min-h-5 bg-blue-800 rounded-full
`;

export const ShoppingCartIcon: FC<ShoppingCartIconProps> = ({
  count,
  path,
  type,
}) => {
  const [innerCount, setInnerCount] = useState(0);

  const clickLink = (event: React.MouseEvent) => {
    if (path.length === 0) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setInnerCount(count);
    }, 200);
  }, [count]);

  return (
    <ShoppingCart>
      <LinkStyled
        data-testid="cart-icon"
        onClick={clickLink}
        to={path}
        count={count}
      >
        <If isTrue={type === ShoppingIconType.Truck}>
          <TruckIcon count={count} />
          <ShoppingCartCount
            className={`${innerCount !== count ? "animate-[expand_0.2s]" : ""}`}
          >
            {count}
          </ShoppingCartCount>
        </If>
        <If isTrue={type === ShoppingIconType.List}>
          <ListIcon count={count} />
          <ListCartCount
            className={`${innerCount !== count ? "animate-[expand_0.2s]" : ""}`}
          >
            {count}
          </ListCartCount>
        </If>
      </LinkStyled>
    </ShoppingCart>
  );
};
