import { InlineNotesAndAttachments } from "@/common/components/inline-notes-and-attachments/InlineNotesAndAttachments";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import {
  AssetContext,
  AssetFieldsFragment,
  DistributorReleaseItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useDistributorRelease } from "../providers/DistributorReleaseProvider";

type Props = {
  item: DistributorReleaseItemFieldsFragment;
  readonly: boolean;
};

const DistributorReleaseNotesDetailsWithProvider: FC<Props> = ({
  item,
  readonly,
}) => {
  const { setExpandedItems, expandedItems, updateVendorReleaseItem } =
    useDistributorRelease();

  const closeItem = useCallback(
    () => setExpandedItems(expandedItems.filter((id) => id !== item.id)),
    [expandedItems, item.id, setExpandedItems],
  );

  const handleOnSave = useCallback(
    async (notes: string, assets?: AssetFieldsFragment[]) => {
      await updateVendorReleaseItem({
        releaseItemId: item.id,
        notes: notes || null,
        assetUrls: assets?.map((a) => a.url) ?? [],
      });

      closeItem();
    },
    [closeItem, item.id, updateVendorReleaseItem],
  );

  return (
    <InlineNotesAndAttachments
      closeItem={closeItem}
      item={item}
      readonly={readonly}
      saveItem={handleOnSave}
    />
  );
};

export const DistributorReleaseNotesDetails: FC<Props> = (props) => {
  const { item } = props;
  const { release } = useDistributorRelease();

  return (
    <UploadAssetProvider
      initialAssets={item.assets}
      context={AssetContext.Note}
      projectId={release?.project?.id ?? ""}
    >
      <DistributorReleaseNotesDetailsWithProvider {...props} />
    </UploadAssetProvider>
  );
};
