import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  OrgRolesWrapper,
  Permission,
} from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  Base,
  LgContainer,
  MdFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseItemUnitPrice } from "@/contractor/pages/home/release/release-items-list/components/ReleaseItemUnitPrice";
import { ReleaseTagsPicker } from "@/contractor/pages/home/release/release-items-list/components/ReleaseTagsPicker";
import { filterByMaterialNamePriceAndQty } from "@/contractor/pages/home/release/utils/filters/filterByMaterialNamePriceAndQty";
import {
  BuyoutReleaseDetailsFieldsFragment,
  EstimatedItemFieldsFragment,
} from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { ReleaseAssignCostCodes } from "./ReleaseAssignCostCodes";
import { ReleaseCostCodeDropdown } from "./ReleaseCostCodeDropdown";
import { ReleaseIssues } from "./ReleaseIssues";
import { ReleaseItemMaterial } from "./ReleaseItemMaterial";

const RequestedItemsContainer = tw(
  Base,
)`col-span-full flex-1 2xl:basis-4/12 2xl:w-4/12 lg:basis-5/12 lg:w-5/12 xl:basis-4/12 xl:w-4/12`;
const FixedContainer = tw(MdFixedContainer)`line-clamp-2 xs:basis-1/2 xs:w-52`;
const ReleaseIssueContainer = tw(Base)`lg:w-5 lg:basis-5 lg:pr-8`;
const TagsContainer = tw(FixedContainer)`xs:col-span-full justify-center`;

export const useReleaseInfoViewConfiguration = (
  release: BuyoutReleaseDetailsFieldsFragment | null,
  projectId: string | undefined,
): Array<GridCol<ExpandedReleaseItem, EstimatedItemFieldsFragment>> =>
  useMemo(
    () => [
      {
        wrapper: RequestedItemsContainer,
        item: ({ item, count }) => (
          <ReleaseItemMaterial
            item={item}
            count={count}
            projectId={projectId}
          />
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
            projectId={projectId}
          />
        ),
        header: <FormattedMessage id="ITEMS_IN_RELEASES" />,
        searchItemFn: filterByMaterialNamePriceAndQty,
      },
      {
        wrapper: LgContainer,
        subgroup: (category) => (
          <ReleaseAssignCostCodes
            category={category}
            status={release?.status}
          />
        ),
        item: ({ item }) => (
          <ReleaseCostCodeDropdown
            releaseItemId={item.id}
            readonly={!!item.costCode || !item.isIncluded}
          />
        ),
      },
      {
        wrapper: TagsContainer,
        header: <FormattedMessage id="TAGS" />,
        item: ({ item }) => {
          return <ReleaseTagsPicker item={item} visibleTags={4} />;
        },
      },
      {
        wrapper: FixedContainer,
        item: ({ item }) => (
          <ValueUnit
            value={(item as ExpandedReleaseItem).quantityDecimal}
            uom={(item as ExpandedReleaseItem).uom}
          />
        ),
        position: "center",
        header: <FormattedMessage id="RELEASE_QUANTITY" />,
      },
      {
        wrapper: FixedContainer,
        item: ({ item, index }) => (
          <OrgRolesWrapper permissions={[Permission.canViewPrices]}>
            <ReleaseItemUnitPrice
              item={item}
              release={release}
              index={index}
              readonly
            />
          </OrgRolesWrapper>
        ),
        position: "center",
      },
      {
        wrapper: ReleaseIssueContainer,
        item: ({ item }) => <ReleaseIssues item={item} />,
      },
    ],
    [projectId, release],
  );
