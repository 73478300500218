import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../providers/DistributorQuoteItemsProvider";
import { useDistributorQuote } from "../providers/DistributorQuoteProvider";
import { UnitInput } from "./common/UnitInput";

type Props = {
  item: DistributorQuoteItemGroupExtendedFieldsFragment;
  readonly: boolean;
  index?: number;
  error?: boolean;
  hideErrorIcon?: boolean;
};

export const DistributorQuoteUnitInput: FC<Props> = ({ item, ...props }) => {
  const { updateQuote } = useDistributorQuote();
  const intl = useIntl();

  const onChange = useCallback(
    async (quantityDecimal: string | null | undefined) => {
      await updateQuote({
        updates: [
          {
            quoteItemId: item.quoteItems[0]?.id,
            quantityDecimal: quantityDecimal ?? "0",
          },
        ],
      });
    },
    [item.quoteItems, updateQuote],
  );

  return (
    <UnitInput
      {...props}
      quantity={item.quoteItems[0]?.quantityDecimal}
      uom={item.rfqItem.projectItem.estimateUom}
      onChange={onChange}
      resetMessageIfEmpty={intl.$t({
        id: "DISTRIBUTOR_QUOTE_ITEM_VALIDATION_MISSING_QUANTITY",
      })}
    />
  );
};
