import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { PDF_FONT, PDF_LEFT_SPACING } from "@/common/const";
import { useManufacturersSettings } from "@/contractor/pages/admin/organization/pages/org-settings/hooks/manufacturers-settings/useManufacturersSettings";
import { DistributorBuyoutFieldsFragment } from "@/generated/graphql";
import jsPDF from "jspdf";
import { useIntl } from "react-intl";
import { useDistributorBuyoutItemsProvider } from "../providers/DistributorBuyoutItemsProvider";
import { header } from "./print/header";
import { materials } from "./print/materials";
import { termsAndConditions } from "./print/termsAndConditions";
import { total } from "./print/total";

export const usePrintView = (
  buyout: DistributorBuyoutFieldsFragment | null | undefined,
) => {
  const intl = useIntl();
  const { costCodes, groupedByCostCode } = useDistributorBuyoutItemsProvider();

  const { getVendorHasManufacturerSetting } = useManufacturersSettings();
  const { formatCurrency } = useFormatNumberToCurrency();
  const hasManufacturersSetting = getVendorHasManufacturerSetting(
    buyout?.project?.location?.org.settings?.display,
  );
  const savePDF = () => {
    if (buyout) {
      const doc = new jsPDF("landscape")
        .setFont(PDF_FONT)
        .setLineHeightFactor(1.5);
      doc
        .setFont(PDF_FONT, "", "bold")
        .setFontSize(16)
        .text(
          intl.$t({ id: "BUYOUT" }, { number: buyout.clientIdentifier }),
          PDF_LEFT_SPACING,
          20,
        );
      header(doc, buyout, intl);
      if (buyout.paymentTermDays || buyout.notes || buyout.additionalCharges) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const topSpacing = (doc as any).lastAutoTable.finalY + 5;
        doc
          .setFontSize(10)
          .setFont(PDF_FONT, "", "bold")
          .text(
            intl.$t({ id: "NOTES_AND_TERMS" }),
            PDF_LEFT_SPACING,
            topSpacing,
          );
      }
      termsAndConditions(doc, buyout, intl, formatCurrency);
      doc.addPage();
      materials(
        doc,
        intl,
        hasManufacturersSetting,
        costCodes,
        groupedByCostCode,
        formatCurrency,
      );
      total(doc, buyout, intl, formatCurrency);
      doc.save(`Buyout #${buyout.clientIdentifier}.pdf`);
    }
  };

  return {
    savePDF,
  };
};
