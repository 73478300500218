import { Avatar } from "@mui/material";
import tw from "tailwind-styled-components";
import { TextField } from "../../textfield/TextField";
import "./select.scss";

export const TextFieldStyled = tw(TextField)`
  bg-white rounded
`;

export const AvatarStyled = tw(Avatar)`
  bg-blue-500 text-white text-2xs font-normal w-5 h-5
`;
