import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { Tooltip } from "../../tooltip/Tooltip";

type Props = {
  invoice?: {
    poNumber?: string | null | undefined;
    release?: {
      poNumber?: string | null | undefined;
    } | null;
  } | null;
  className?: string;
};

const ErrorIcon = tw(InfoOutlined)`text-red-500`;
const Container = tw.div``;

export const isNotMatchingPoNumber = (invoice: Props["invoice"]): boolean => {
  if (!invoice?.release?.poNumber || !invoice?.poNumber) {
    return false;
  }
  return invoice?.poNumber !== invoice?.release?.poNumber;
};

export const NotMatchingPoNumberInfo: FC<Props> = ({ invoice, className }) => {
  if (!invoice?.release || !isNotMatchingPoNumber(invoice)) {
    return null;
  }
  return (
    <Tooltip
      id={`asset`}
      element={
        <Container className={className}>
          <ErrorIcon />
        </Container>
      }
    >
      <FormattedMessage id="INVOICE_INVALID_PO_NUMBER" tagName="div" />
    </Tooltip>
  );
};
