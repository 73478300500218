import { If } from "@/common/components/if/If";
import { useIntegrationFeatureRequirement } from "@/common/components/integration-feature-requirement/hooks/useIntegrationFeatureRequirement";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { routes } from "@/config/routes";
import { CheckCircleOutline, InfoOutlined } from "@mui/icons-material";
import { ReactNode } from "react";
import { useIntl } from "react-intl";
import {
  FinalStepDescription,
  FinalStepDescriptionDetails,
  FinalStepDot,
  FinalStepItem,
  FinalStepLinkChunks,
  FinalStepList,
  FinalStepListItem,
  FinalStepRow,
  FinalStepView,
} from "../../../Wizard.styles";

export const AgaveFinalStep = () => {
  const intl = useIntl();
  const { hasFeatureInConnectedSourceSystem } =
    useIntegrationFeatureRequirement();

  return (
    <>
      <FinalStepRow>
        <CheckCircleOutline className="mr-4 h-20 w-20 text-blue-500" />
        {intl.$t({ id: "BASIC_SETUP_DONE" })}
      </FinalStepRow>
      <FinalStepView>
        {intl.$t({ id: "GO_TO_INDIVIDUAL_TABS_TO_COMPLETE_FINAL_STEPS" })}
        <FinalStepList>
          <FinalStepListItem>
            <FinalStepDot />
            <FinalStepItem>
              {intl.$t({ id: "COST_CODES" })}
              <FinalStepDescription>
                {intl.$t(
                  {
                    id: "IMPORT_COST_CODES_LINK",
                  },
                  {
                    sub: (chunks: ReactNode) => (
                      <FinalStepLinkChunks
                        chunks={chunks}
                        route={routes.costStructure}
                      />
                    ),
                  },
                )}
              </FinalStepDescription>
            </FinalStepItem>
          </FinalStepListItem>
          <FinalStepListItem>
            <FinalStepDot />
            <FinalStepItem>
              {intl.$t({ id: "VENDORS" })}
              <FinalStepDescription>
                {intl.$t(
                  {
                    id: "CONNECT_VENDORS_LINK",
                  },
                  {
                    sub: (chunks: ReactNode) => (
                      <FinalStepLinkChunks
                        chunks={chunks}
                        route={routes.vendors}
                      />
                    ),
                  },
                )}
              </FinalStepDescription>
            </FinalStepItem>
          </FinalStepListItem>
          <FinalStepListItem>
            <FinalStepDot />
            <FinalStepItem>
              {intl.$t({ id: "PROJECTS" })}
              <FinalStepDescription>
                {intl.$t(
                  {
                    id: "CONNECT_PROJECTS_LINK",
                  },
                  {
                    sub: (chunks: ReactNode) => (
                      <FinalStepLinkChunks
                        chunks={chunks}
                        route={routes.projects}
                      />
                    ),
                  },
                )}
              </FinalStepDescription>
            </FinalStepItem>
          </FinalStepListItem>
          <If
            isTrue={hasFeatureInConnectedSourceSystem(
              IntegrationFeature.OrgMaterialsMapping,
            )}
          >
            <FinalStepListItem>
              <FinalStepDot />
              <FinalStepItem>
                {intl.$t({ id: "NOT_ORG_MATERIALS" })}
                <FinalStepDescription>
                  {intl.$t(
                    {
                      id: "CONNECT_MATERIALS_LINK",
                    },
                    {
                      sub: (chunks: ReactNode) => (
                        <FinalStepLinkChunks
                          chunks={chunks}
                          route={routes.itemDatabase}
                        />
                      ),
                    },
                  )}
                </FinalStepDescription>
                <FinalStepDescriptionDetails>
                  <InfoOutlined className="mr-2 h-4 w-4 text-gray-600" />
                  {intl.$t({ id: "INTEGRATIONS_REQUIRED_MATERIALS" })}
                </FinalStepDescriptionDetails>
              </FinalStepItem>
            </FinalStepListItem>
          </If>
        </FinalStepList>
      </FinalStepView>
    </>
  );
};
