import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { IntegrationLogo } from "@/contractor/pages/admin/integrations/components/form/components/IntegrationLogo";
import { SourceSystem, useExternalVendorQuery } from "@/generated/graphql";
import { CheckCircleOutline, InfoOutlined } from "@mui/icons-material";
import { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useExternalVendorMapping } from "../../../providers/ExternalVendorMappingProvider";

const Row = tw.div`flex flex-row items-center py-4`;
const Name = tw.div`ml-4 justify-center text-sm`;

export type ExternalVendorConnection = {
  logo: string;
  name: string;
  type: string | null | undefined;
  sourceSystem: SourceSystem;
  isMapped: boolean;
  isConnected: boolean;
  externalId: string | undefined;
};

type Props = {
  item: ExternalVendorConnection;
  vendorId: string | undefined;
};

export const VendorFormVendorConnection: FC<Props> = ({ item, vendorId }) => {
  const { openModal, selectedExternalVendorData } = useExternalVendorMapping();

  const { data, loading } = useExternalVendorQuery({
    variables: {
      input: {
        externalId: item.externalId ?? "",
        sourceSystem: item.sourceSystem,
      },
    },
    skip: !item.externalId,
  });

  const showConnectionModal = useCallback(() => {
    openModal({
      sourceSystem: item.sourceSystem,
      vendor: vendorId,
      selectedExternalVendor:
        data?.externalVendor.externalId ??
        selectedExternalVendorData?.externalId,
      isConnected: !!(
        data?.externalVendor.externalId ??
        selectedExternalVendorData?.externalId
      ),
    });
  }, [
    data,
    item.sourceSystem,
    openModal,
    selectedExternalVendorData,
    vendorId,
  ]);

  return (
    <Row>
      <If isTrue={item.isMapped || !!selectedExternalVendorData}>
        <CheckCircleOutline className="text-green-600" />
      </If>
      <If isTrue={!item.isMapped && !selectedExternalVendorData}>
        <Tooltip
          id="vendor-connection"
          element={<InfoOutlined className="h-6 w-6 text-blue-500" />}
        >
          <FormattedMessage id="CONNECT_VENDOR_TOOLTIP" />
        </Tooltip>
      </If>
      <IntegrationLogo url={item.logo} className="mx-4 h-14 w-30" />
      <If isTrue={item.isMapped || !!selectedExternalVendorData}>
        <OutlinedButton
          className="h-6 min-h-6 w-15 min-w-15 text-xs"
          onClick={showConnectionModal}
        >
          <FormattedMessage id="EDIT" />
        </OutlinedButton>
        <If isTrue={loading}>
          <Loader loading={loading} small />
        </If>
        <If isTrue={!loading}>
          <Name>
            {data?.externalVendor.name ?? selectedExternalVendorData?.name}
          </Name>
        </If>
      </If>
      <If isTrue={!item.isMapped && !selectedExternalVendorData}>
        <PrimaryButton
          className="h-6 min-h-6 w-15 min-w-15 text-xs"
          onClick={showConnectionModal}
          loading={loading}
        >
          <FormattedMessage id="CONNECT" />
        </PrimaryButton>
      </If>
    </Row>
  );
};
