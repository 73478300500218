import { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { InnerLabeledSwitchControlled } from "../switch/InnerLabeledSwitchControlled";
import { Tooltip } from "../tooltip/Tooltip";
import {
  BorderItem,
  FormSection,
  Header,
  Item,
  LabelContainer,
  Text,
  Value,
  ValueContainer,
} from "./NotificationsSetting.styles";
import { NotificationSetting } from "./types/NotificationSetting";

type Props = {
  category: NotificationSetting;
  excludedOrgNotifications?: string[];
};

export const NotificationsSetting: FC<Props> = ({
  category,
  excludedOrgNotifications,
}) => {
  const intl = useIntl();

  const isDisabledOnOrgLevel = useCallback(
    (setting: string) => {
      return excludedOrgNotifications?.some(
        (notification) => notification === setting,
      );
    },
    [excludedOrgNotifications],
  );

  return (
    <FormSection key={category.id} $extraBottomMargin={false}>
      <Header>{category.name}</Header>
      {category.children.map((child, key) => (
        <Item key={child.value}>
          <LabelContainer>
            <BorderItem $lastItem={key === category.children.length - 1}>
              <Text>{child.name}</Text>
            </BorderItem>
          </LabelContainer>
          <ValueContainer>
            <BorderItem $lastItem={key === category.children.length - 1}>
              <Value>
                <Tooltip
                  id={`disabled-${child.value}`}
                  hideTooltip={!isDisabledOnOrgLevel(child.value)}
                  element={
                    <InnerLabeledSwitchControlled
                      disabled={isDisabledOnOrgLevel(child.value)}
                      name={child.key}
                      onLabel={intl.$t({ id: "YES" })}
                      offLabel={intl.$t({ id: "NO" })}
                    />
                  }
                >
                  <FormattedMessage id="SETTING_DISABLED_IN_ORG_SETTINGS" />
                </Tooltip>
              </Value>
            </BorderItem>
          </ValueContainer>
        </Item>
      ))}
    </FormSection>
  );
};
