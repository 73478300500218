import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { Check } from "@mui/icons-material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  grid fixed left-0 top-0 h-screen rounded-r-lg py-1 content-center
  animate-[enter-left_0.3s_ease-in-out_forwards] z-70
`;

type ActionProps = {
  $active: boolean;
};

const ActionContainer = tw.div<ActionProps>`  
  grid grid-flow-col w-fit items-center gap-2 h-12
  first:rounded-tr-lg last:rounded-br-lg
  ${({ $active }) => ($active ? "bg-transparent" : "bg-blue-450")}
`;
const Action = tw.div<ActionProps>`
  grid grid-flow-col items-center gap-2 h-12 min-w-10
  ${({ $active }) =>
    $active ? "text-white bg-blue-800 rounded-r-lg" : "text-blue-800"}
`;

const ActionIconButton = tw(IconButtonBorderless)<ActionProps>`
  text-blue-800 hover:opacity-100 hover:text-blue-800 w-10 h-10
  ${({ $active }) =>
    $active
      ? "text-white hover:text-white opacity-100"
      : "text-blue-800 opacity-60 "}
`;

const ComponentContainer = tw.div`
  grid grid-flow-col items-center gap-2 animate-[fade-in_0.1s_ease-in-out_forwards] min-w-10
`;

type Action = {
  icon: React.ReactNode;
  tooltipKey: string;
  component?: React.ReactNode;
  callback?: () => void;
};

type Props = {
  actions: Action[];
  visible?: boolean;
  loading?: boolean;
};

export const SideActionBar: FC<Props> = ({ actions, visible, loading }) => {
  const [activeAction, setActiveAction] = useState(-1);
  const [hideLoader, setHideLoader] = useState(true);

  const handleClick = (index: number) => {
    if (activeAction === index) {
      setActiveAction(-1);
    } else {
      if (actions[index].component) {
        setActiveAction(index);
      } else {
        actions[index].callback?.();
        setActiveAction(-1);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest("#action")) {
        setActiveAction(-1);
      }
    };

    if (activeAction !== -1) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [activeAction]);

  useEffect(() => {
    if (loading) {
      setHideLoader(false);
    }

    if (!loading && hideLoader === false) {
      setTimeout(() => {
        setHideLoader(true);
      }, 500);
    }
  }, [hideLoader, loading]);

  if (!visible && hideLoader) {
    return null;
  }

  return (
    <Container>
      <If isTrue={loading || hideLoader === false}>
        <ActionContainer
          $active={false}
          className="h-fit animate-[warp-in_0.4s_ease-in-out_forwards] px-3 py-5"
        >
          <If isTrue={loading}>
            <Loader loading small circleClassName="text-blue-800" />
          </If>
          <If isTrue={!loading}>
            <Check className="text-base text-blue-500" />
          </If>
        </ActionContainer>
      </If>
      <If isTrue={!loading && hideLoader}>
        {actions.map((action, index) => (
          <ActionContainer
            id="action"
            key={index}
            $active={activeAction === index}
          >
            <Tooltip
              id={`action-${action.tooltipKey}`}
              position="left"
              element={
                <Action $active={activeAction === index}>
                  <If isTrue={activeAction !== index}>
                    <ActionIconButton
                      onClick={() => handleClick(index)}
                      $active={false}
                    >
                      {action.icon}
                    </ActionIconButton>
                  </If>
                  <If isTrue={activeAction === index}>
                    <ComponentContainer>
                      <ActionIconButton
                        onClick={() => handleClick(index)}
                        $active
                      >
                        {action.icon}
                      </ActionIconButton>
                      {action.component}
                    </ComponentContainer>
                  </If>
                </Action>
              }
            >
              <FormattedMessage id={action.tooltipKey} />
            </Tooltip>
          </ActionContainer>
        ))}
      </If>
    </Container>
  );
};
