import { getCostCodesByRfqItems } from "@/common/utils/cost-codes-and-zones/getCostCodesByRfqItems";
import { RfqItemFieldsFragment } from "@/generated/graphql";
import { FC, createContext, useContext, useEffect, useState } from "react";
import {
  CategoryState,
  useToggleCategory,
} from "../../../../../common/hooks/useToggleCategory";
import { useUnspecifiedCostCode } from "../../../../../common/hooks/useUnspecifiedCostCode";

type Category = CategoryState<RfqItemFieldsFragment>;

type ProviderContextType = {
  categories: Category[];
  toggleCategory: (name: string) => void;
};

type RfqItemsGroupedProviderProps = {
  items: RfqItemFieldsFragment[];
  children: React.ReactNode;
};

const ProviderContext = createContext<ProviderContextType>({
  categories: [],
  toggleCategory: () => null,
});

export const RfqItemsGroupedProvider: FC<RfqItemsGroupedProviderProps> = ({
  children,
  items,
}) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const { unassignedCostCode } = useUnspecifiedCostCode();

  useEffect(() => {
    const costCodeGroups = getCostCodesByRfqItems(items);
    const costCodeCategories: Category[] = costCodeGroups.map((costCode) => {
      return {
        id: costCode.id,
        name: costCode.description,
        isOpened: true,
        items: items
          .filter(
            (i) =>
              i &&
              ((!i.costCode && costCode.id === unassignedCostCode.id) ||
                i.costCode?.id === costCode.id),
          )
          .sort((a, b) =>
            (a?.projectItem?.material.material.name || "").localeCompare(
              b?.projectItem?.material.material.name || "",
            ),
          ),
      };
    });
    setCategories(costCodeCategories);
  }, [items, unassignedCostCode]);

  const { toggleCategory } = useToggleCategory(categories, setCategories);

  return (
    <ProviderContext.Provider
      value={{
        categories,
        toggleCategory,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useRfqItemsGrouped = (): ProviderContextType =>
  useContext(ProviderContext);
