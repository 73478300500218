import { Drawer } from "@mui/material";
import React, { FC } from "react";

type Props = {
  anchor?: (togglePanel: (opened: boolean) => void) => React.ReactNode;
  content: (togglePanel: (opened: boolean) => void) => React.ReactNode;
  isOpened: boolean;
  setIsOpened: (value: boolean) => void;
  onClick?: () => void;
};

export const DrawerView: FC<Props> = ({
  anchor,
  content,
  isOpened,
  setIsOpened,
  onClick,
}) => {
  return (
    <>
      {anchor?.(setIsOpened)}
      <Drawer
        anchor="right"
        open={isOpened}
        onClose={() => setIsOpened(false)}
        ModalProps={{
          BackdropProps: {
            open: true,
            onClick: (ev) => {
              onClick?.();
              ev.stopPropagation();
            },
          },
        }}
      >
        {content(setIsOpened)}
      </Drawer>
    </>
  );
};
