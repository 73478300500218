import { RETRY_COUNT } from "../const";

export const retriableFetch = async (url: string): Promise<Response | null> => {
  let retries = 0;
  while (retries < RETRY_COUNT) {
    try {
      return await fetch(url);
    } catch {}
    retries += 1;
  }
  return null;
};
