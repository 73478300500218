import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  IntegrationType,
  useImportOrgCatalogMutation,
} from "@/generated/graphql";
import { FC, createContext, useContext } from "react";

type ProviderContextType = {
  importMaterials: (
    orgId: string,
    orgCatalogFormat: IntegrationType,
    file: File,
  ) => Promise<string | null | undefined>;
  loading: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  importMaterials: () => Promise.resolve(null),
  loading: false,
});

export const ImportMaterialsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { setError } = useGlobalError();
  const [importMaterialsMutation, { loading }] = useImportOrgCatalogMutation();

  const importMaterials = async (
    orgId: string,
    orgCatalogFormat: IntegrationType,
    file: File,
  ) => {
    try {
      const { data, errors } = await importMaterialsMutation({
        variables: {
          input: {
            orgId,
            orgCatalogFormat,
            file,
          },
        },
      });
      setError(errors);
      return data?.importOrgCatalog?.id;
    } catch (errors) {
      setError(errors);
    }

    return null;
  };

  return (
    <ProviderContext.Provider value={{ loading, importMaterials }}>
      {children}
    </ProviderContext.Provider>
  );
};

export const useImportMaterials = (): ProviderContextType =>
  useContext(ProviderContext);
