import { If } from "@/common/components/if/If";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { SearchableList } from "@/common/components/searchable-list/SearchableList";
import { Switch } from "@/common/components/switch/Switch";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  AgaveExternalItemsProvider,
  useAgaveExternalItemsProvider,
} from "../../../providers/AgaveExternalItemsProvider";
import {
  ExternalItemsMode,
  useImportExternalItems,
} from "../../../providers/ImportExternalItemsProvider";
import { useImportExternalItemsTableConfiguration } from "./ImportExternalItemsTable.configuration";

const SearchBarTitleComponent = tw.div`flex flex-col pr-2`;
const ToggleText = tw.div`flex flex-row font-normal text-sm items-center mt-1`;

const ImportExternalItemsTableWithProviders = () => {
  const intl = useIntl();
  const {
    sourceSystem,
    mode,
    connectedItem,
    selectedExternalItems,
    setSelectedExternalItems,
    importAllExternalItems,
    setImportAllExternalItems,
    setTotalExternalItemsCount,
  } = useImportExternalItems();
  const {
    externalItems,
    loading,
    totalCount,
    error,
    searchText,
    setSearchText,
  } = useAgaveExternalItemsProvider();
  const configuration = useImportExternalItemsTableConfiguration(
    selectedExternalItems,
  );

  useEffect(() => {
    setTotalExternalItemsCount(totalCount);
  }, [setTotalExternalItemsCount, totalCount]);

  useEffect(() => {
    if (importAllExternalItems) {
      setSelectedExternalItems(externalItems.map((i) => i.id));
    }
  }, [importAllExternalItems, externalItems, setSelectedExternalItems]);

  return (
    <SearchableList
      tableConfiguration={configuration}
      items={externalItems}
      totalCount={totalCount}
      loadingItems={loading}
      error={!!error}
      search={{
        searchText,
        setSearchText,
      }}
      searchBarTitle={
        <SearchBarTitleComponent>
          <FormattedMessage
            id={
              mode === ExternalItemsMode.IMPORT
                ? "SELECT_MATERIALS_TO_IMPORT"
                : "SELECT_MATERIAL_TO_CONNECT"
            }
            values={{
              name: connectedItem?.material.name,
            }}
          />
          <If isTrue={mode === ExternalItemsMode.IMPORT}>
            <ToggleText>
              <FormattedMessage
                id="IMPORT_ALL_MATERIALS_FROM_SYSTEM"
                values={{
                  system: intl.$t({ id: `INTEGRATION_${sourceSystem}` }),
                }}
              />
              <Switch
                className="ml-2"
                offLabel={intl.$t({ id: "NO" })}
                onLabel={intl.$t({ id: "YES" })}
                value={importAllExternalItems}
                onChange={(value) => {
                  setImportAllExternalItems(value);
                  setSelectedExternalItems(
                    value ? externalItems.map((i) => i.id) : [],
                  );
                }}
              />
            </ToggleText>
          </If>
        </SearchBarTitleComponent>
      }
      itemSelection={
        mode === ExternalItemsMode.IMPORT
          ? {
              type: "multiple",
              selectedItemIds: selectedExternalItems,
              setSelectedItemIds: setSelectedExternalItems,
              disable: importAllExternalItems,
            }
          : {
              type: "single",
              selectedItemId: selectedExternalItems?.[0] ?? null,
              setSelectedItemId: (id: string | null) =>
                setSelectedExternalItems(
                  id && id !== selectedExternalItems?.[0] ? [id] : [],
                ),
            }
      }
    />
  );
};

export const ImportExternalItemsTable: FC = () => {
  const { sourceSystem } = useImportExternalItems();
  return (
    <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
      <AgaveExternalItemsProvider sourceSystem={sourceSystem}>
        <ImportExternalItemsTableWithProviders />
      </AgaveExternalItemsProvider>
    </PaginationProvider>
  );
};
