import { UserAvatar } from "@/common/components/user-avatar/UserAvatar";
import { getFirstAndLastFromFullName } from "@/common/utils/users/getFirstAndLastFromFullName";
import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { VendorDetailsList, VendorDetailsListItem } from "../Vendors.styles";

type Props = {
  contacts: OrgPreferredVendorsFieldsFragment["contacts"];
};

const UserAvatarStyled = tw(UserAvatar)`
  mr-2 border border-blue-500
`;

export const VendorContactNames: FC<Props> = ({ contacts }) => {
  return (
    <VendorDetailsList>
      {contacts.map((contact, key) => (
        <VendorDetailsListItem key={key}>
          <UserAvatarStyled
            firstName={getFirstAndLastFromFullName(contact.name).firstName}
            lastName={getFirstAndLastFromFullName(contact.name).lastName}
          />
          {contact.name}
        </VendorDetailsListItem>
      ))}
    </VendorDetailsList>
  );
};
