import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { ProjectProjectsFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useProjectsListItemConfiguration } from "./ProjectsListItem.configuration";
import { ProjectsListItems } from "./ProjectsListItems";

type Props = {
  projects: ProjectProjectsFieldsFragment[];
  loading: boolean;
  totalCount: number;
  isFiltered?: boolean;
};

export const ProjectsList: FC<Props> = ({
  projects,
  loading,
  totalCount,
  isFiltered,
}) => {
  const configuration = useProjectsListItemConfiguration();

  return (
    <ListRenderer totalCount={totalCount} count={projects.length} paginated>
      <ProjectsListItems
        columns={configuration}
        items={projects}
        loading={loading}
        emptyList={
          <NoResults
            isFiltered={isFiltered}
            filteredTranslationKey="PROJECT_LIST_NO_PROJECTS_FILTERED"
            translationKey="PROJECT_LIST_NO_PROJECTS"
          />
        }
      />
    </ListRenderer>
  );
};
