import { addressToString } from "@/common/components/adress-location/AddressLocation";
import { WarehouseFieldsFragment } from "@/generated/graphql";
import tw from "tailwind-styled-components";

const Item = tw.div`py-2 inline relative
  ${({ $archived }: { $archived: boolean }) => $archived && "text-gray-400"}
`;
const ItemName = tw.span``;
const AddressContainer = tw.span`text-xs bottom-[1px] relative`;

export const WarehouseSelectorCustomRenderer = (
  item: WarehouseFieldsFragment,
) => {
  return (
    <Item $archived={!!item.archivedAt}>
      <ItemName>{item.name} - </ItemName>
      <AddressContainer>{addressToString(item.address)} </AddressContainer>
    </Item>
  );
};
