import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { LUMP_SUM_UOM, LUMP_SUM_UOM_PLURAL_DESCRIPTION } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  COLUMN_TYPE,
  SpreadSheetConfig,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { INVOICE_READONLY_STATUSES } from "@/distributor/pages/invoices/providers/DistributorInvoiceProvider";
import {
  AddToReleaseItemInput,
  InvoiceItemFieldsFragment,
  OrgMaterialFieldsFragment,
  UomsDocument,
  useCreateStandaloneReleaseMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { useInvoiceCreateReleaseSpreadsheetConfig } from "../components/matched-order/components/invoice-create-release/InvoiceCreateRelease.config";
import { InvoiceCreateReleaseFormValues } from "../components/matched-order/components/InvoiceVerificationForm";
import { useInvoiceImportExternalPO } from "./InvoiceImportExternalPoProvider";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "./InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "./InvoiceVerificationProvider";
type ProviderContextType = {
  invoiceItems: InvoiceItemFieldsFragment[];
  setInvoiceItems: (items: InvoiceItemFieldsFragment[]) => void;
  syncCreateReleaseFromInvoice: (
    values: InvoiceCreateReleaseFormValues,
  ) => Promise<boolean | undefined>;
  loading: boolean;
  spreadsheetViewColumns: SpreadSheetConfig[];
  createRelease: (values: InvoiceCreateReleaseFormValues) => Promise<boolean>;
};

const ProviderContext = createContext<ProviderContextType>({
  invoiceItems: [],
  setInvoiceItems: NoFunction,
  syncCreateReleaseFromInvoice: NoFunctionBooleanPromise,
  loading: false,
  spreadsheetViewColumns: [],
  createRelease: NoFunctionBooleanPromise,
});

export const InvoiceCreateReleaseProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { setError } = useGlobalError();
  const { connectedSourceSystem } = useOrgSettings();
  const { materials } = useMaterials();
  const { vendors } = useVendors();
  const { invoice, refetch, setFooterState } = useInvoiceVerification();
  const { validateRequiredValues, validateRowValues } = useTableValidators();
  const { importedPoExternalId, importedPoItemized, importedItems } =
    useInvoiceImportExternalPO();
  const { spreadsheetData, resetPreviousData, gotoInvalidRow } =
    useColumnMapper();
  const { matchedOrderViewState, setMatchedOrderViewState } =
    useInvoiceMatchedOrder();
  const {
    getCellValue,
    getRowUomCreatableValue,
    getCostCodeId,
    rowIsEmpty,
    addMissingMaterials,
    getCellWithAdditionalData,
    findMaterialByName,
    getPrefilledValue,
  } = useTableHelpers();
  const { calcExtPrice } = usePriceCalculation();
  const { updateMaterials } = useMaterials();
  const { costCodes } = useCostCodes();
  const intl = useIntl();
  const { openDialog } = useDialog();

  const [saving, setSaving] = useState(false);
  const [invoiceItems, setInvoiceItems] = useState<InvoiceItemFieldsFragment[]>(
    (invoice?.items ?? []).map((item) => {
      const matchingOrgMaterial = findMaterialByName(item.description);
      const isLumpSum = isLumpSumUomText(item.UOM);

      return {
        ...item,
        material: matchingOrgMaterial,
        costCode: matchingOrgMaterial?.costCode?.description ?? undefined,
        manufacturer:
          item.manufacturer ?? matchingOrgMaterial?.manufacturer?.name,
        UOM:
          item.UOM ??
          matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
          matchingOrgMaterial?.defaultEstimateUom?.mnemonic,
        quantityDecimal:
          (isLumpSum ? item.unitPrice : item.quantityDecimal) ?? "",
        unitPrice: isLumpSum ? "1" : (item.unitPrice ?? undefined),
        extPrice: calcExtPrice(item?.quantityDecimal, item?.unitPrice),
      };
    }),
  );

  const [createStandaloneRelease, { loading: creatingRelease }] =
    useCreateStandaloneReleaseMutation();

  const getNewItems = async (values: InvoiceCreateReleaseFormValues) => {
    const newItems: AddToReleaseItemInput[] = [];

    setSaving(true);
    const newMaterials = (await addMissingMaterials([], LUMP_SUM_UOM)) || [];
    setSaving(false);

    spreadsheetData.forEach((row, index) => {
      const rowMaterialText = getCellWithAdditionalData(
        row,
        COLUMN_TYPE.Material,
      );
      let material = findMaterialByName(rowMaterialText, [
        ...materials,
        ...newMaterials,
      ]);

      const uom = getRowUomCreatableValue(row);
      const isLumpSum = isLumpSumUomText(uom);
      if (isLumpSum) {
        material = findMaterialByName(LUMP_SUM_UOM_PLURAL_DESCRIPTION, [
          ...materials,
          ...newMaterials,
        ]);
      }

      if (!material || rowIsEmpty(row)) {
        return false;
      }

      const quantityDecimal = getCellValue(row, COLUMN_TYPE.Quantity) || "1";
      const unitPrice = Number(
        getCellValue(
          row,
          invoice?.isDeliverySlip
            ? COLUMN_TYPE.PrefilledPrice
            : COLUMN_TYPE.UnitPrice,
        ),
      );
      const notes = getCellValue(row, COLUMN_TYPE.Notes);
      const vendor = vendors?.find((v) => v.id === values.vendorId);

      const prefilledPrice = getPrefilledValue(
        rowMaterialText,
        vendor ? vendorLabelFormatter(vendor.sellerOrgLocation) : "",
        "",
        uom,
      );

      const importedItem = importedItems?.find((_, i) => i === index);
      if (importedPoItemized) {
        newItems.push({
          invoiceId: invoice?.id ?? "",
          projectItem: {
            estimateUom: uom,
            ...(isOrgCatalogSku(material.material) && {
              orgCatalogSkuId: material.material.id,
            }),
            ...(isProductSku(material.material) && {
              masterProductId: material.material.id,
            }),
            ...(isMasterSku(material.material) && {
              masterSkuId: material.material.id,
            }),
          },
          ...(isLumpSum && { name: rowMaterialText }),
          instructions: { text: notes },
          quantityDecimal,
          unitPrice: String(unitPrice),
          pricePrenegotiated: String(unitPrice) === prefilledPrice.value,
          position: index,
          costCodeId: getCostCodeId(row),
          poItemExternalId: importedItem?.id,
        });
      } else {
        newItems.push({
          invoiceId: invoice?.id ?? "",
          projectItem: {
            estimateUom: LUMP_SUM_UOM,
            ...(isOrgCatalogSku(material.material) && {
              orgCatalogSkuId: material.material.id,
            }),
            ...(isProductSku(material.material) && {
              masterProductId: material.material.id,
            }),
            ...(isMasterSku(material.material) && {
              masterSkuId: material.material.id,
            }),
          },
          ...(isLumpSum && { name: rowMaterialText }),
          instructions: { text: notes },
          quantityDecimal,
          unitPrice: "1",
          position: index,
          poItemExternalId: importedItem?.id,
        });
      }
    });

    return newItems;
  };

  const createReleaseFromInvoice = async (
    values: InvoiceCreateReleaseFormValues,
    newItems: AddToReleaseItemInput[],
  ) => {
    if (newItems.length > 0) {
      try {
        const { errors } = await createStandaloneRelease({
          variables: {
            input: {
              invoiceId: invoice?.id ?? "",
              projectId: values.projectId ?? "",
              warehouseId:
                values.fulfillmentLocationId === values.projectId
                  ? undefined
                  : values.fulfillmentLocationId,
              sellerOrgLocationId: values.vendorId,
              poNumber: values.poNumber,
              time: values.orderDate?.getTime() ?? undefined,
              items: newItems,
              paymentTerm: values.paymentTerm
                ? Number(values.paymentTerm)
                : undefined,
              taxRate: values.taxRate,
              customTaxAmount: values.customTaxAmount || undefined,
              additionalCharges: values.additionalCharges.filter(
                (charge) => charge.description && Number(charge.amount) > 0,
              ),
              retroactive: true,
              mapping:
                importedPoExternalId &&
                connectedSourceSystem &&
                matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
                  ? {
                      externalId: importedPoExternalId,
                      sourceSystem: connectedSourceSystem,
                    }
                  : undefined,
              typeId: values.orderTypeId || undefined,
              assignPOItemZones:
                !!importedPoExternalId &&
                importedPoItemized === true &&
                matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
                  ? true
                  : undefined,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: UomsDocument,
            },
          ],
        });
        await refetch();
        setError(errors);
        if (!errors) {
          resetPreviousData();
        }
        return !errors;
      } catch (error) {
        setError(error);
        return false;
      }
    }

    return true;
  };

  const syncCreateReleaseFromInvoice = async (
    values: InvoiceCreateReleaseFormValues,
  ) => {
    if (
      !validateRequiredValues([
        COLUMN_TYPE.Material,
        ...(importedPoItemized
          ? [
              COLUMN_TYPE.UOM,
              COLUMN_TYPE.Quantity,
              invoice?.isDeliverySlip
                ? COLUMN_TYPE.PrefilledPrice
                : COLUMN_TYPE.UnitPrice,
            ]
          : []),
        COLUMN_TYPE.UnitPrice,
      ]) ||
      !validateRowValues(
        [
          COLUMN_TYPE.Quantity,
          ...(importedPoItemized
            ? [
                invoice?.isDeliverySlip
                  ? COLUMN_TYPE.PrefilledPrice
                  : COLUMN_TYPE.UnitPrice,
                COLUMN_TYPE.UOM,
                COLUMN_TYPE.CostCode,
              ]
            : []),
        ],
        undefined,
        { minPrice: undefined },
      )
    ) {
      gotoInvalidRow();
      return false;
    }

    const newItems = await getNewItems(values);
    if (
      matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER &&
      newItems.find((i) => !i.poItemExternalId)
    ) {
      openDialog({
        cancelButtonText: intl.$t({ id: "CANCEL" }),
        confirmButtonText: intl.$t({ id: "PROCEED" }),
        icon: <WarningIcon />,
        title: intl.$t(
          { id: "RECORD_ORDER_CHANGE_ORDER" },
          {
            integration: intl.$t({
              id: `INTEGRATION_${connectedSourceSystem}`,
            }),
          },
        ),
        handleConfirm: async () => {
          if (await createReleaseFromInvoice(values, newItems)) {
            if (
              invoice &&
              !INVOICE_READONLY_STATUSES.includes(invoice.status)
            ) {
              setMatchedOrderViewState(
                MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
              );
            } else {
              setMatchedOrderViewState(MatchedOrderViewState.DEFAULT);
            }
            setFooterState(InvoiceFooterState.DEFAULT);
          }
        },
      });

      return;
    }
    return await createReleaseFromInvoice(values, newItems);
  };

  const createRelease = async (values: InvoiceCreateReleaseFormValues) => {
    try {
      setSaving(true);
      const costCode = costCodes.find(
        (c) => c.id === values.costCodeId,
      )?.description;
      const lumpSumMaterial = materials.find(
        (m) => m.material.name === LUMP_SUM_UOM_PLURAL_DESCRIPTION,
      );

      let newMaterials;
      if (!lumpSumMaterial) {
        newMaterials = await updateMaterials({
          addedMaterials: [
            {
              costCodeId: values.costCodeId,
              newOrgCatalogSKU: {
                defaultUom: LUMP_SUM_UOM,
                name: LUMP_SUM_UOM_PLURAL_DESCRIPTION,
              },
            },
          ],
        });
      }
      const items: AddToReleaseItemInput[] = [
        {
          projectItem: {
            estimateUom: LUMP_SUM_UOM,
            orgCatalogSkuId: (
              lumpSumMaterial ||
              (newMaterials as OrgMaterialFieldsFragment[])[0]
            )?.material.id,
          },
          quantityDecimal: values.subtotal.toString(),
          position: 0,
          unitPrice: "1",
          tags: values.phaseCodeId ? [values.phaseCodeId] : undefined,
          costCodeId: values.costCodeId,
          name: costCode
            ? intl.$t({ id: "RECEIPT_COST_CODE_EXPENSES" }, { costCode })
            : intl.$t({ id: "RECEIPT_EXPENSES" }),
        },
      ];
      const { errors } = await createStandaloneRelease({
        variables: {
          input: {
            invoiceId: invoice?.id ?? "",
            projectId: values.projectId ?? "",
            sellerOrgLocationId: values.vendorId,
            time: values.orderDate?.getTime() ?? undefined,
            items,
            taxRate: values.taxRate || undefined,
            customTaxAmount: values.customTaxAmount || undefined,
            retroactive: true,
            typeId: values.orderTypeId || undefined,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: UomsDocument,
          },
        ],
      });
      await refetch();
      setError(errors);
      setSaving(false);
      return !errors;
    } catch (error) {
      setError(error);
      setSaving(false);
      return false;
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        invoiceItems,
        setInvoiceItems,
        syncCreateReleaseFromInvoice,
        createRelease,
        loading: saving || creatingRelease,
        spreadsheetViewColumns: useInvoiceCreateReleaseSpreadsheetConfig(),
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceCreateRelease = (): ProviderContextType =>
  useContext(ProviderContext);
