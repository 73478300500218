import {
  sxGroupHide,
  sxHiddenHide,
} from "@/common/components/grid-table/grid-item/utils";
import { Base, RfqItemContainer } from "@/common/layout/ResponsiveClasses";
import tw from "tailwind-styled-components";

export const TagContainer = tw(Base)`
  lg:basis-2/12 lg:w-2/12
  2xl:basis-2/12 2xl:w-2/12
  ${sxGroupHide} ${sxHiddenHide} justify-items-start
`;

export const WideRfqItemContainer = tw(RfqItemContainer)`
  lg:basis-auto lg:w-5/12 2xl:basis-auto 2xl:w-6/12
`;
