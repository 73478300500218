import { formattedDate } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { Avatar } from "@mui/material";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { Tooltip } from "../tooltip/Tooltip";

export type UserAvatarProps = {
  firstName?: string;
  lastName?: string;
  className?: string;
  email?: string;
  photoUrl?: string | null;
  size?: number;
  date?: number | null;
};

const AvatarStyled = tw(Avatar)`bg-white text-blue-800 text-xs font-normal`;
const PopoverContent = tw.div`text-xs`;
const Date = tw.div`text-center`;

export const UserAvatar: FC<UserAvatarProps> = ({
  firstName,
  lastName,
  className,
  email,
  photoUrl,
  date,
  size = 40,
}) => {
  const initials =
    firstName && lastName
      ? `${firstName.slice(0, 1).toUpperCase()}${lastName
          .slice(0, 1)
          .toUpperCase()}`
      : email
        ? email.slice(0, 2).toUpperCase()
        : "";

  return (
    <Tooltip
      id="user-avatar-popover"
      element={
        <AvatarStyled
          src={photoUrl || ""}
          className={className}
          sx={{ width: size, height: size }}
        >
          {photoUrl ? null : initials || null}
        </AvatarStyled>
      }
    >
      <PopoverContent>
        {getUserName({ firstName, lastName, email })}
        <If isTrue={date}>
          <Date>{formattedDate({ date })}</Date>
        </If>
      </PopoverContent>
    </Tooltip>
  );
};

export const UserAvatarStyled = tw(UserAvatar)`
  bg-blue-200 bg-opacity-30 border-2 border-white text-black w-9 h-9 p-0
`;
