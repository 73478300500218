import { UpdateChargeInput } from "@/generated/graphql";
import { FC } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { useInvoiceCreation } from "../../../../scanned-invoices/providers/InvoiceCreationProvider";

export type InvoiceCreateReleaseFormValues = {
  businessLocationId: string;
  projectId: string;
  fulfillmentLocationId: string;
  vendorId: string;
  poNumber: string;
  orderDate: Date;
  orderTypeId: string;
  costCodeId: string;
  phaseCodeId: string;

  subtotal: number;
  paymentTerm: string | undefined;
  additionalCharges: UpdateChargeInput[];
  taxRate: string | undefined;
  customTaxAmount: string | undefined;
  total: string;
};

type InvoiceCreateReleaseForm = UseFormReturn<
  InvoiceCreateReleaseFormValues,
  unknown
>;

export const invoiceCreateReleaseFormDefaultValues = {
  businessLocationId: "",
  projectId: "",
  fulfillmentLocationId: "",
  vendorId: "",
  poNumber: "",
  orderDate: undefined,
  orderTypeId: "",
  subtotal: 0,
  total: "0",
  taxRate: undefined,
  customTaxAmount: undefined,
  additionalCharges: [],
  costCodeId: "",
};

export const InvoiceVerificationForm: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { locationId } = useInvoiceCreation();
  const invoiceCreateReleaseForm: InvoiceCreateReleaseForm =
    useForm<InvoiceCreateReleaseFormValues>({
      defaultValues: {
        ...invoiceCreateReleaseFormDefaultValues,
        businessLocationId: locationId ?? "",
      },
      mode: "onChange",
      reValidateMode: "onChange",
    });
  return <FormProvider {...invoiceCreateReleaseForm}>{children}</FormProvider>;
};
