import { ManufacturerPicker } from "@/common/components/manufacturer-picker/ManufacturerPicker";
import { getManufacturerIdFromPicker } from "@/common/components/manufacturer-picker/getManufacturerIdFromPicker";
import { RfqItemFieldsFragment, Sku } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useRfq } from "../../hooks/useRfq";
import { useRfqGroupedItems } from "../../hooks/useRfqGroupedItems";

type Props = {
  item: RfqItemFieldsFragment;
  readonly?: boolean;
  index?: number;
};

export const RfqManufacturerSelector: FC<Props> = ({
  item,
  readonly = false,
  index,
}) => {
  const { newRfqItem, setNewRfqItem } = useRfqGroupedItems();
  const { updateRfq, rfq } = useRfq();

  const saveManufacturer = useCallback(
    async (value: string | null) => {
      const manufacturerId = getManufacturerIdFromPicker(value);
      if (item.id) {
        await updateRfq({
          rfqId: rfq?.id ?? "",
          updates: [
            {
              rfqItemId: item.id,
              manufacturerId,
              clearManufacturer: !manufacturerId,
            },
          ],
        });
      } else {
        setNewRfqItem({
          ...(newRfqItem || {}),
          description: "",
          manufacturerId,
        });
      }
    },
    [item, updateRfq, newRfqItem, setNewRfqItem, rfq],
  );

  return (
    <ManufacturerPicker
      item={
        item.manufacturer ||
        (item.projectItem.material.material as Sku).manufacturer
      }
      saveManufacturer={saveManufacturer}
      readonly={readonly}
      index={index}
      canClearManufacturer
    />
  );
};
