import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useDeliveries } from "../providers/DeliveriesProvider";
import { useReleaseSequence } from "../providers/ReleaseSequenceProvider";

const Container = tw.div`
  flex -ml-2
`;

export const DeliveryAllItemsCheckbox: FC = () => {
  const { sequenceIds, selectedReleases, setSelectedReleases } =
    useReleaseSequence();
  const { deliveries } = useDeliveries();

  const checked = useMemo(
    () => deliveries.every((d) => selectedReleases.includes(d)),
    [deliveries, selectedReleases],
  );
  const handleChange = useCallback(() => {
    const filteredItems = deliveries;
    setSelectedReleases(
      checked
        ? selectedReleases.filter((i) => !filteredItems.includes(i))
        : [
            ...selectedReleases.filter((i) => filteredItems.includes(i)),
            ...filteredItems,
          ],
    );
  }, [checked, deliveries, selectedReleases, setSelectedReleases]);

  return (
    <Container>
      <SelectionCheckbox
        items={sequenceIds}
        setSelection={handleChange}
        checked={checked}
      />
    </Container>
  );
};
