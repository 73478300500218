import {
  PaginationArgs,
  usePagination,
} from "@/common/components/pagination/PaginationProvider";
import {
  QueryReleasesFilter,
  ReleasePartialFieldsFragment,
  useReleasesQuery,
} from "@/generated/graphql";
import { ApolloError } from "@apollo/client";
import { useEffect } from "react";

type ReturnType = {
  deliveries: ReleasePartialFieldsFragment[];
  loading: boolean;
  error?: ApolloError;
  totalCount: number;
  pagination: PaginationArgs;
  refetch: () => void;
};

export const useDeliveriesWithPagination = (
  filter: QueryReleasesFilter | undefined,
): ReturnType => {
  const { paginationArgs, setPageInfo } = usePagination();
  const { data, loading, error, refetch } = useReleasesQuery({
    variables: {
      filter,
      ...paginationArgs,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data?.releases.pageInfo) {
      setPageInfo({ ...data.releases.pageInfo });
    }
  }, [data, setPageInfo]);

  return {
    deliveries: data?.releases.edges.map((edge) => edge.node) || [],
    pagination: paginationArgs,
    loading,
    error,
    totalCount: data?.releases.totalCount || 0,
    refetch,
  };
};
