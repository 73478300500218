import {
  WizardModal,
  WizardModalMode,
} from "@/common/components/wizard-modal/WizardModal";
import { useSplittingInvoicesWizard } from "./SplittingInvoicesWizardProvider";

export const SplittingInvoicesWizardModal = () => {
  const { pages, wizardOpened, closeWizard, splitting } =
    useSplittingInvoicesWizard();

  return (
    <WizardModal
      opened={wizardOpened}
      pages={pages}
      mode={WizardModalMode.MULTIPLE_PAGES}
      onClose={closeWizard}
      loading={splitting}
    />
  );
};
