import { DateView } from "@/common/utils/dates/DateView";
import { FC } from "react";
import tw from "tailwind-styled-components";

type DateTimeViewProps = {
  date: Date | number;
};

const DateTimeViewContainer = tw.div`
  flex flex-col text-sm
`;

const Date = tw.div`
  font-normal
`;

const Time = tw.div`
  font-light text-xs
`;

export const DateTimeView: FC<DateTimeViewProps> = ({ date }) => (
  <DateTimeViewContainer>
    <Date>
      <DateView date={date} />
    </Date>
    <Time>
      <DateView date={date} onlyTime={true} />
    </Time>
  </DateTimeViewContainer>
);
