import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import tw from "tailwind-styled-components";

export const Container = tw.div`grid grid-flow-row grid-cols-1 gap-2 pt-2`;

export const Item = tw.div<{ $fullWidth?: boolean }>`grid ${({
  $fullWidth,
}: {
  $fullWidth?: boolean;
}) => !$fullWidth && "lg:grid-cols-3 md:grid-cols-2 "} gap-2 pb-3`;

export const FloatingFooterStyled = tw(FloatingFooter)`flex justify-end gap-2`;
