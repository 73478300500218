import { Instructions } from "@/common/components/instructions/Instructions";
import { InvoiceExpandableList } from "@/common/components/invoices/invoice-expandable-list/InvoiceExpandableList";
import { Loader } from "@/common/components/loader/Loader";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import { ReleaseDeliverySlipAndPhotos } from "@/common/components/release-images/ReleaseDeliverySlipAndPhotos";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { DistributorReleaseInlineNotesAndTerms } from "./components/distributor-release-notes-and-terms/DistributorReleaseInlineNotesAndTerms";
import { DistributorReleaseFooter } from "./components/footer/DistributorReleaseFooter";
import { ReleaseHeader } from "./components/header/ReleaseHeader";
import { DistributorReleaseList } from "./components/list/DistributorReleaseList";
import { DistributorReleaseItemsZoneProvider } from "./providers/DistributorReleaseItemsZoneProvider";
import {
  DistributorReleaseProvider,
  useDistributorRelease,
} from "./providers/DistributorReleaseProvider";

type Props = { openedMessages?: boolean };

const Container = tw.div`pb-15`;

const ReleaseWithProvider: FC<Props> = ({ openedMessages }) => {
  const { release } = useDistributorRelease();

  if (!release) {
    return <Loader loading />;
  }

  return (
    <DistributorReleaseItemsZoneProvider items={release?.items || []}>
      <Container>
        <ReleaseHeader release={release} openedMessages={openedMessages} />
        <DistributorReleaseList />
        <QuoteDocumentPanel quoteDocument={release.quoteDocument} readonly />
        <Instructions
          instruction={release.instructions}
          projectId={release.project?.id}
        />
        <ReleaseDeliverySlipAndPhotos release={release} />
        <InvoiceExpandableList invoice={release.invoice} />
        <DistributorReleaseInlineNotesAndTerms release={release} />
        <DistributorReleaseFooter />
      </Container>
    </DistributorReleaseItemsZoneProvider>
  );
};

export const DistributorRelease: FC<Props> = (props) => {
  return (
    <DistributorReleaseProvider>
      <ReleaseWithProvider {...props} />
    </DistributorReleaseProvider>
  );
};
