import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  MessageContextKind,
  MessageFieldsFragment,
  MessageStatsDocument,
  MessagesDocument,
  useMarkMessageThreadAsReadMutation,
  useMessagesQuery,
  useSendMessageMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useErrorEffect } from "../../../hooks/useErrorEffect";
import { DisabledMessaging } from "../components/MessagesPanel";

type Props = PropsWithChildren<{
  kind: MessageContextKind;
  id: string;
  disabled?: DisabledMessaging;
}>;

export type MessageType = MessageFieldsFragment & {
  grouped: boolean;
  day?: string;
};

type ProviderContextType = {
  messages: MessageType[];
  sendMessage: (obj: {
    customId?: string;
    customKind?: MessageContextKind;
  }) => Promise<boolean>;
  loading: boolean;
  loadMore: () => void;
  error: boolean;
  message: string;
  setMessage: (message: string) => void;
  cursor?: string;
  isPrivate: boolean;
  setIsPrivate: (value: boolean) => void;
  userIds: string[];
  setUserIds: (value: string[]) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  messages: [],
  sendMessage: NoFunctionBooleanPromise,
  loading: false,
  loadMore: NoFunction,
  error: false,
  message: "",
  setMessage: NoFunction,
  cursor: undefined,
  isPrivate: false,
  setIsPrivate: NoFunction,
  userIds: [],
  setUserIds: NoFunction,
});

const POLL_INTERVAL = 10000;
const LIMIT = 20;

export const MessagesProvider: FC<Props> = ({
  children,
  kind,
  id,
  disabled,
}) => {
  const [isPrivate, setIsPrivate] = useState<boolean>(!!disabled?.vendor);
  const [userIds, setUserIds] = useState<string[]>([]);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [message, setMessage] = useState("");
  const [cursor, setCursor] = useState("");
  const { setError } = useGlobalError();
  const [initialLoad, setInitialLoad] = useState(false);

  const [markMessageThreadAsReadMutation] =
    useMarkMessageThreadAsReadMutation();

  const markMessageThreadAsRead = useCallback(async () => {
    try {
      await markMessageThreadAsReadMutation({
        variables: {
          input: {
            context: { id, kind },
            isPrivate,
          },
        },
        refetchQueries: [
          {
            query: MessageStatsDocument,
            variables: { input: { context: { id, kind } } },
          },
        ],
      });
    } catch (errors) {
      setError(errors);
    }
  }, [markMessageThreadAsReadMutation, id, kind, isPrivate, setError]);

  const { data, loading, fetchMore, error } = useMessagesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: { context: { id, kind }, isPrivate },
      first: LIMIT,
    },
    pollInterval: POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!initialLoad && data?.messages.edges?.length) {
      setInitialLoad(true);
      setTimeout(async () => {
        await markMessageThreadAsRead();
      }, 1000);
    }
  }, [data?.messages.edges?.length, initialLoad, markMessageThreadAsRead]);

  const [sendMessageMutation] = useSendMessageMutation({
    refetchQueries: [
      {
        query: MessagesDocument,
        variables: {
          input: { context: { id, kind }, isPrivate },
          first: LIMIT,
        },
      },
    ],
  });

  const sendMessage = async ({
    customId,
    customKind,
  }: {
    customId?: string;
    customKind?: MessageContextKind;
  }) => {
    if (message.length) {
      try {
        const { data: sendMessageData, errors } = await sendMessageMutation({
          variables: {
            input: {
              context: { id: customId || id, kind: customKind || kind },
              message,
              isPrivate,
              teamMembers: isPrivate ? userIds : undefined,
            },
          },
          onCompleted: () => setMessage(""),
        });
        setError(errors);
        return Boolean(sendMessageData?.sendMessage.length !== 0 && !errors);
      } catch (errors) {
        setError(errors);
      }
    }
    return false;
  };

  const loadMoreMessages = async () => {
    if (data?.messages?.pageInfo?.hasNextPage && data?.messages?.edges) {
      const lastItem = data.messages.edges[data.messages.edges.length - 1];
      const result = await fetchMore({ variables: { after: lastItem.cursor } });
      if (result) {
        setCursor(lastItem.node.id);
      }
    }
  };

  useEffect(() => {
    if (data?.messages?.pageInfo) {
      const newMessages = data?.messages.edges.map((edge) => edge.node) || [];
      const sortedMessages = newMessages.sort(
        (a, b) => b.timeSent - a.timeSent,
      );
      const mappedMessages = sortedMessages.map((message, index) => ({
        ...message,
        grouped:
          index < sortedMessages.length - 1 &&
          message.author.id === sortedMessages[index + 1].author.id &&
          message.timeSent - sortedMessages[index + 1].timeSent < 300000,
      }));
      setMessages(mappedMessages);
    }
  }, [data]);

  const setPrivateAndResetInitialLoad = useCallback(
    (value: boolean) => {
      setIsPrivate(value);
      setInitialLoad(false);
    },
    [setIsPrivate],
  );

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        messages,
        loading,
        sendMessage,
        error: !!error,
        loadMore: loadMoreMessages,
        message,
        setMessage,
        cursor,
        isPrivate,
        setIsPrivate: setPrivateAndResetInitialLoad,
        userIds,
        setUserIds,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useMessages = () => useContext(ProviderContext);
