import { FC } from "react";
import { MainTabNavigation } from "./MainTabNavigation";

export const WithMainTabNavigation: FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <>
    <MainTabNavigation />
    {children}
  </>
);
