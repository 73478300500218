import { gql } from "@apollo/client";
import { ASSET_FIELDS } from "./assetFragment";

export const INSTRUCTION_FIELDS = gql`
  ${ASSET_FIELDS}
  fragment InstructionFields on Instruction {
    text
    assets {
      ...AssetFields
    }
  }
`;
