import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { CompletedStep } from "@/common/components/wizard-modal/components/CompletedStep";
import { PoValidationFieldsFragment, SourceSystem } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { FullProgress } from "../Connections.styles";
import { MissingItemFromInventory } from "../components/connection-types/hosted/steps/missing-items-from-inventory/MissingItemsFromInventory";
import { useConnectOrgMaterial } from "../hooks/useConnectOrgMaterial";
import { usePoValidation } from "./PoValidationProvider";

export type MaterialType =
  PoValidationFieldsFragment["materialsNotConnected"][0];

type ProviderContextType = {
  pages: WizardModalPage[];
  sourceSystem: SourceSystem | null | undefined;
  modalOpened: boolean;
  openModal: ({
    sourceSystem,
    materials,
  }: {
    sourceSystem: SourceSystem;
    materials: MaterialType[];
  }) => void;
  closeModal: () => void;

  selectedExternalItem: string | null;
  setSelectedExternalItem: (id: string | null) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  pages: [],
  sourceSystem: null,
  modalOpened: false,
  openModal: NoFunction,
  closeModal: NoFunction,

  selectedExternalItem: null,
  setSelectedExternalItem: NoFunction,
});

export const MissingItemsFromInventoryProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const [sourceSystem, setSourceSystem] = useState<SourceSystem | null>(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [materials, setMaterials] = useState<MaterialType[]>([]);
  const { setNestedStep, setStep, moveToNextNestedStep } = useNestedStepper();
  const { refetchPoValidation } = usePoValidation();
  const { connectOrgMaterial } = useConnectOrgMaterial();
  const [index, setIndex] = useState(0);
  const [selectedExternalItem, setSelectedExternalItem] = useState<
    string | null
  >(null);

  const openModal = useCallback(
    ({
      sourceSystem,
      materials,
    }: {
      sourceSystem: SourceSystem;
      materials: MaterialType[];
    }) => {
      setSourceSystem(sourceSystem);
      setMaterials(materials);
      setModalOpened(true);
    },
    [],
  );

  const resetModal = useCallback(() => {
    setSourceSystem(null);
    setNestedStep(0);
    setStep(0);
  }, [setNestedStep, setStep]);

  const closeModal = useCallback(() => {
    setModalOpened(false);
    resetModal();
    setIndex(0);
    refetchPoValidation();
  }, [refetchPoValidation, resetModal]);

  const onNext = useCallback(async () => {
    if (
      await connectOrgMaterial({
        externalId: selectedExternalItem!,
        nodeId: materials[index].id,
        sourceSystem: sourceSystem!,
      })
    ) {
      setIndex((prev) => prev + 1);
      setSelectedExternalItem(null);
      if (index === materials.length - 1) {
        moveToNextNestedStep();
      }
    }
  }, [
    connectOrgMaterial,
    index,
    materials,
    moveToNextNestedStep,
    selectedExternalItem,
    sourceSystem,
  ]);

  const pages: WizardModalPage[] = useMemo(
    () => [
      {
        title: null,
        pages: [
          {
            component: (
              <MissingItemFromInventory
                material={materials[index]}
                sourceSystem={sourceSystem}
              />
            ),
            footerContent: {
              node: (
                <FullProgress
                  progress={materials.length ? index / materials.length : 0}
                  label={intl.$t(
                    { id: "CONNECT_MATERIALS_WITH_COUNTER" },
                    {
                      count: index + 1,
                      total: materials.length,
                    },
                  )}
                />
              ),
              className: "flex-[4]",
            },
            footerDotsConfig: {
              hidden: true,
            },
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                onClick: onNext,
                text: intl.$t({ id: "NEXT" }),
                className: "flex-1",
                disabled: !selectedExternalItem,
              },
            ],
          },
          {
            component: (
              <CompletedStep
                text={intl.$t({ id: "MATERIALS_CONNECTED" })}
                onTimeoutCompleted={closeModal}
              />
            ),
            onModalClose: closeModal,
            hideFooter: true,
            componentWrapperClassName: "mb-0",
          },
        ],
      },
    ],
    [
      materials,
      index,
      sourceSystem,
      closeModal,
      intl,
      onNext,
      selectedExternalItem,
    ],
  );

  return (
    <ProviderContext.Provider
      value={{
        modalOpened,
        sourceSystem,
        pages,
        openModal,
        closeModal,
        selectedExternalItem,
        setSelectedExternalItem,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useMissingItemsFromInventory = () => useContext(ProviderContext);
