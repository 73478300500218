import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { CSV_MIME_TYPE } from "@/common/components/upload/FileUploadArea";
import { tableToCsvString } from "@/common/utils/tableToCsv";
import { ButtonContainer } from "@/contractor/pages/home/buyout/Buyout.styles";
import {
  AddTwoTone,
  ContentCopyTwoTone,
  ContentPasteTwoTone,
  UploadFileOutlined,
  ZoomOutMapOutlined,
} from "@mui/icons-material";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { PreviewTable } from "./PreviewTable";

const FileTable = tw.div`
  grid relative bg-gray-100 px-7 -mx-7 pt-4 pb-5
`;

const TableContainer = tw.div`
  flex w-full border border-gray-500 rounded-md p-4 bg-white
  overflow-auto mb-3
`;

const PasteTableArea = tw.div`
  flex flex-col w-full h-96 text-xs
`;

const ButtonContainerStyled = tw(ButtonContainer)`
  w-full justify-center gap-4
`;

const CopyPasteIcons = tw.div`
  absolute top-[40%] text-8xl w-full text-center font-medium
  grid grid-flow-col px-8 place-content-center gap-1
  text-blue-500 text-opacity-10 pointer-events-none
`;

const InstructionsText = tw.div`
  text-black font-normal text-base mb-3 px-1
`;

const ButtonContent = tw.div`flex flex-row`;

type FileTableCopyPasteProps = {
  handleChange: (files: File[]) => void;
  instructions: string;
};

export const FileTableCopyPaste: FC<FileTableCopyPasteProps> = ({
  handleChange,
  instructions,
}) => {
  const [tableData, setTableData] = useState<HTMLTableElement>();
  const { openDialog } = useDialog();
  const intl = useIntl();

  const scaleTable = () => {
    const tableCont = document.getElementById("pasted-table");
    if (tableCont) {
      const table = tableCont.getElementsByTagName("table")[0];
      setTableData(table);
      if (table) {
        const tableWidth = table.offsetWidth;
        const tableHeight = table.offsetHeight;
        table?.setAttribute(
          "style",
          `
          transform: scale(${
            tableWidth > tableHeight ? 410 / tableWidth : 380 / tableHeight
          });
          transform-origin: ${
            tableWidth > tableHeight ? "left top" : "center top"
          };
        `,
        );
      }
    }
  };

  const convertToCsv = () => {
    const csvString = tableToCsvString("pasted-table");
    if (csvString) {
      const blob = new Blob([csvString], { type: CSV_MIME_TYPE });
      const file = new File([blob], intl.$t({ id: "PASTED_TABLE_DATA" }), {
        type: CSV_MIME_TYPE,
      });
      handleChange([file]);
    }
  };

  const selectAll = () => {
    const tableCont = document.getElementById("pasted-table");
    if (tableCont) {
      const table = tableCont.getElementsByTagName("table")[0];
      if (table) {
        const range = document.createRange();
        range.selectNode(table);
        window.getSelection()?.removeAllRanges();
        window.getSelection()?.addRange(range);
      }
    }
  };

  return (
    <FileTable>
      <InstructionsText>
        <FormattedMessage id={instructions} />
      </InstructionsText>
      <TableContainer>
        <PasteTableArea
          id="pasted-table"
          contentEditable="true"
          onInput={scaleTable}
          onClick={selectAll}
        />
      </TableContainer>
      <If isTrue={!tableData}>
        <CopyPasteIcons>
          <ContentCopyTwoTone className="text-8xl" />
          <AddTwoTone className="text-8xl" />
          <ContentPasteTwoTone className="text-8xl" />
        </CopyPasteIcons>
      </If>
      <ButtonContainerStyled>
        <If isTrue={tableData}>
          <OutlinedButton
            onClick={() =>
              openDialog({
                cancelButtonText: intl.$t({ id: "CLOSE" }),
                confirmButtonText: intl.$t({ id: "UPLOAD_TABLE" }),
                content: tableData ? (
                  <PreviewTable csvString={tableToCsvString("pasted-table")} />
                ) : (
                  ""
                ),
                maxWidth: "xl",
                closeOnConfirm: true,
                handleConfirm: convertToCsv,
                title: intl.$t({ id: "PREVIEW_TABLE" }),
                fullScreen: true,
              })
            }
          >
            <ButtonContent>
              <ZoomOutMapOutlined />
              <FormattedMessage id="PREVIEW_TABLE" />
            </ButtonContent>
          </OutlinedButton>
        </If>
        <PrimaryButton onClick={convertToCsv} disabled={!tableData}>
          <ButtonContent>
            <UploadFileOutlined />
            <FormattedMessage id="UPLOAD_TABLE" />
          </ButtonContent>
        </PrimaryButton>
      </ButtonContainerStyled>
    </FileTable>
  );
};
