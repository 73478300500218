import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { Price } from "@/common/components/price/Price";
import {
  OrgMaterialFieldsFragment,
  VendorPriceFieldsFragment,
} from "@/generated/graphql";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMaterialPrices } from "../../providers/MaterialPricesProvider";
import { ReadonlyContainer } from "./MaterialPriceStyles";

type Props = {
  item: OrgMaterialFieldsFragment;
  price?: VendorPriceFieldsFragment;
  error?: boolean;
  readonly?: boolean;
};

export const MaterialUnitPriceInput: FC<Props> = ({
  item,
  price,
  error,
  readonly,
}) => {
  const { updateMaterial } = useMaterialPrices();
  const [unitPrice, setUnitPrice] = useState<string | null | undefined>(
    price?.price ?? null,
  );
  const intl = useIntl();

  useEffect(() => {
    setUnitPrice(price?.price ?? null);
  }, [price]);

  const updateMatPrice = async (newPrice: string | null) => {
    setUnitPrice(newPrice);
    await updateMaterial(item.id, {
      vendorPriceId: price?.id || "",
      price: newPrice,
    });
  };

  return (
    <NotNullableRenderer value={price?.price || !readonly}>
      {readonly ? (
        <ReadonlyContainer>
          <Price price={price?.price} />
        </ReadonlyContainer>
      ) : (
        <PricePicker
          onBlur={updateMatPrice}
          value={unitPrice}
          label={!unitPrice ? intl.$t({ id: "PRICE" }) : ""}
          error={error && !unitPrice}
          className="w-full"
          shrink={false}
          labelProps={{ className: "text-sm" }}
        />
      )}
    </NotNullableRenderer>
  );
};
