import { groupBy } from "lodash";
import { useCallback, useMemo } from "react";
import { NewCostCodeAllowance } from "../../projects/components/project-form/forms/project-budget/types";
import { useProjectBudget } from "../pages/project-budget/hooks/useProjectBudget";

export const useIsBudgetZoneActive = () => {
  const { budget } = useProjectBudget();

  const isZoneSpecificFn = useCallback((costCodes: NewCostCodeAllowance[]) => {
    if (!costCodes) {
      return false;
    }
    const zonedCostCodes = costCodes.reduce(
      (acc, costCode) => {
        if (costCode.zone?.id) {
          acc.push({
            amount: costCode.amount,
            costCodeId: costCode.costCode.id,
            zoneId: costCode.zone?.id,
          });
        }
        return acc;
      },
      [] as { amount: string; costCodeId: string; zoneId: string }[],
    );

    const costCodesByZone = groupBy(zonedCostCodes, "costCodeId");

    return Object.values(costCodesByZone).some((zones) => zones.length > 0);
  }, []);

  const isZoneSpecific = useMemo(() => {
    if (!budget?.costCodes) {
      return false;
    }

    return isZoneSpecificFn(budget.costCodes as NewCostCodeAllowance[]);
  }, [budget?.costCodes, isZoneSpecificFn]);

  return {
    isZoneSpecific,
    isZoneSpecificFn,
  };
};
