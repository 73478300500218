import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import { ContractorBuyoutProvider } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { QuoteDocumentDetails } from "@/contractor/pages/home/common/quote-document/components/quote-document-details/QuoteDocumentDetails";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { BuyoutFromQuoteProvider } from "../../../providers/BuyoutFromQuoteProvider";
import { useCreateBuyoutFromQuoteConfig } from "./components/CreateBuyoutFromQuote.config";
import { CreateBuyoutFromQuoteBreadcrumbs } from "./components/CreateBuyoutFromQuoteBreadcrumbs";
import { CreateBuyoutFromQuoteDetails } from "./components/CreateBuyoutFromQuoteDetails";
import { CreateBuyoutFromQuoteFooter } from "./components/CreateBuyoutFromQuoteFooter";

const Container = tw.div`
  mt-5 xl:-mx-10
`;

const GroupContainer = tw.div`
  grid grid-cols-[2.5fr_2fr] gap-4
`;

const CreateBuyoutFromQuoteWithProvider: FC = () => {
  return (
    <Container>
      <CreateBuyoutFromQuoteBreadcrumbs />
      <GroupContainer>
        <CreateBuyoutFromQuoteDetails />
        <QuoteDocumentDetails />
      </GroupContainer>
      <CreateBuyoutFromQuoteFooter />
    </Container>
  );
};

export const CreateBuyoutFromQuote: FC = () => {
  const spreadsheetConfig = useCreateBuyoutFromQuoteConfig();
  return (
    <ColumnMapperProvider config={spreadsheetConfig}>
      <ContractorBuyoutProvider>
        <BuyoutFromQuoteProvider>
          <CreateBuyoutFromQuoteWithProvider />
        </BuyoutFromQuoteProvider>
      </ContractorBuyoutProvider>
    </ColumnMapperProvider>
  );
};
