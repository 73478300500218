import { TextField } from "@/common/components/textfield/TextField";
import { NoFunction } from "@/types/NoFunction";
import React, { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";

type Props = {
  notes?: string | null;
  updateItem?: (notes: string) => void;
  readonly?: boolean;
};

const NotesAndTermsContainer = tw.div`
  flex w-full
`;

const TextFieldStyled = tw(TextField)`
  w-full
`;

export const NotesAndTerms: FC<Props> = ({
  notes,
  updateItem = NoFunction,
  readonly,
}) => {
  const intl = useIntl();
  const label = useMemo(() => {
    if (readonly) {
      if (!notes) {
        return "";
      }
      return intl.$t({ id: "VENDOR_NOTES" });
    }
    return `${intl.$t({ id: "NOTES_AND_TERMS" })} ${intl.$t({
      id: "OPTIONAL_WITH_BRACKETS",
    })}`;
  }, [intl, notes, readonly]);
  return (
    <If isTrue={!readonly || notes}>
      <NotesAndTermsContainer>
        <TextFieldStyled
          multiline
          placeholder={intl.$t({
            id: "TERMS_AND_CONDITION_NOTES_PLACEHOLDER",
          })}
          label={label}
          minRows={5}
          maxRows={10}
          value={notes}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
            updateItem(ev.target.value)
          }
          staticText={readonly}
          testId="distributor-notes-terms"
        />
      </NotesAndTermsContainer>
    </If>
  );
};
