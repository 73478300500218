import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ConnectNodeInput,
  useConnectOrgMaterialMutation,
} from "@/generated/graphql";

export const useConnectOrgMaterial = () => {
  const [connectOrgMaterialMutation] = useConnectOrgMaterialMutation();
  const { setError } = useGlobalError();

  const connectOrgMaterial = async (input: ConnectNodeInput) => {
    try {
      const { data } = await connectOrgMaterialMutation({
        variables: {
          input,
        },
      });
      return !!data?.connectOrgMaterial.id;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  return { connectOrgMaterial };
};
