import { DeleteButton } from "@/common/components/button/DeleteButton";
import { If } from "@/common/components/if/If";
import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { ThreeDotMenu } from "@/common/components/three-dot-menu/ThreeDotMenu";
import { SettingsOutlined } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../../providers/DistributorQuoteItemsProvider";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";
import { DISTRIBUTOR_QUOTE_ORDER_REQUIREMENTS_PANEL } from "../terms-and-discounts/DistributorQuoteOrderRequirements";

const Container = tw.div`flex items-center`;
const ListItemIconStyled = tw(ListItemIcon)`text-blue-500`;

type Props = {
  item: DistributorQuoteItemGroupExtendedFieldsFragment;
  readonly?: boolean;
};

export const DistributorQuoteItemOptionsMenu: FC<Props> = ({
  item,
  readonly = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    updateQuote,
    isAlternative,
    updateInEditQuoteItemGroups,
    inEditQuoteItemGroups,
  } = useDistributorQuote();
  const { toggle } = useGlobalDrawer();

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((ev?: { stopPropagation?: () => void }) => {
    ev?.stopPropagation?.();
    setAnchorEl(null);
  }, []);

  const deleteAlternative = useCallback(async () => {
    const deletedItems =
      item.quoteItemCount === 1 ? [] : [item.quoteItems[0]?.id];
    const updates =
      item.quoteItemCount === 1 &&
      item.quoteItems[0]?.description !== item.rfqItem.description
        ? [
            {
              quoteItemId: item.id,
              description: item.rfqItem.description,
            },
          ]
        : [];
    if (deletedItems.length > 0 || updates.length > 0) {
      await updateQuote({
        deletedItems,
        updates,
      });
    }
    if (inEditQuoteItemGroups.includes(item.quoteItems[0].id)) {
      updateInEditQuoteItemGroups(item.quoteItems[0].id);
    }
    // expand sibling item, if only one left
    if (item.quoteItemCount === 2) {
      const siblingItemId = item.quoteItemIds?.filter(
        (id) => id !== item.quoteItems[0].id,
      )[0];
      if (siblingItemId) {
        updateInEditQuoteItemGroups(siblingItemId);
      }
    }

    handleClose();
  }, [
    handleClose,
    inEditQuoteItemGroups,
    item.id,
    item.quoteItemCount,
    item.quoteItemIds,
    item.quoteItems,
    item.rfqItem.description,
    updateInEditQuoteItemGroups,
    updateQuote,
  ]);

  const orderRequirements = useCallback(() => {
    toggle(
      DISTRIBUTOR_QUOTE_ORDER_REQUIREMENTS_PANEL,
      true,
      item.quoteItems[0]?.id,
    );
    handleClose();
  }, [handleClose, item.quoteItems, toggle]);

  return (
    <Container>
      <ThreeDotMenu
        handleClick={handleClick}
        active={!!item.quoteItems[0].expirationDate}
      />
      <Menu
        id={`quote-item-more-menu-${item.id}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={orderRequirements}>
          <ListItemIconStyled>
            <SettingsOutlined />
          </ListItemIconStyled>
          <ListItemText>
            <FormattedMessage id="OTHER_OPTIONS" />
          </ListItemText>
        </MenuItem>
        <If isTrue={isAlternative(item) && !readonly}>
          <MenuItem onClick={deleteAlternative}>
            <ListItemIconStyled>
              <DeleteButton />
            </ListItemIconStyled>
            <ListItemText>
              <FormattedMessage id="DELETE" />
            </ListItemText>
          </MenuItem>
        </If>
      </Menu>
    </Container>
  );
};
