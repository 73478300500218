import { gql } from "@apollo/client";

export const USERS_USER_FIELDS = gql`
  fragment UsersUserFields on User {
    id
    email
    cellPhone
    createdAt
    firstName
    lastName
    photoUrl
    isEmailValidated
    org {
      id
      locations {
        id
        name
      }
    }
    isOrgAdmin
    orgRoles
    enterpriseRoles
    locationRoles {
      orgLocID
      role
    }
  }
`;
