import { GridTable } from "@/common/components/grid-table/GridTable";
import { GRID_ERROR_ROW_KEY } from "@/common/components/grid-table/GridTableCategory";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { scrollIntoViewByDataAttr } from "@/common/utils/scrollUtils";
import {
  EstimatedItemFieldsFragment,
  ProjectItemExtendedFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useEffect } from "react";
import { CategoryState } from "../../../../../../common/hooks/useToggleCategory";
import { UNSPECIFIED_ZONE_ID } from "../../../../../../common/hooks/useUnspecifiedZone";
import { ZoneItemsContainer } from "../../Project.styles";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";
import { useProjectItemsZones } from "../../providers/ProjectItemsZonesProvider";
import { getEstimatedItem } from "../../utils/getEstimatedItem";

type Props = {
  columns: Array<
    GridCol<
      ProjectItemFieldsFragment | ProjectItemExtendedFieldsFragment,
      EstimatedItemFieldsFragment
    >
  >;
  loading?: boolean;
};

export const ProjectListItems: FC<Props> = ({ columns, loading }) => {
  const { zones, groupedByZones, toggleZone, toggleCostCode } =
    useProjectItemsZones();
  const { newProjectEstimatedItem, expandedItems } = useEstimatedItems();
  useEffect(() => {
    if (newProjectEstimatedItem.hasError) {
      setTimeout(() => scrollIntoViewByDataAttr(GRID_ERROR_ROW_KEY), 100);
    }
  }, [newProjectEstimatedItem.hasError]);

  const itemClassNameFn = useCallback(
    (
      item: ProjectItemFieldsFragment,
      category?: CategoryState<ProjectItemFieldsFragment>,
      index?: number,
    ) => {
      const estimatedItem = getEstimatedItem({ item, category, index });
      return ((estimatedItem?.id === newProjectEstimatedItem.id &&
        (!expandedItems.some(
          (i) =>
            i.id === item.id &&
            (!i.zoneId ||
              i.zoneId === UNSPECIFIED_ZONE_ID ||
              item.estimatedItems.some((e) => e.zone === i.zoneId)),
        ) ||
          !isNaN(Number(index)))) ||
        (estimatedItem && !estimatedItem.id)) &&
        newProjectEstimatedItem.hasError
        ? { className: "border-red-500 lg:border-t-1", error: true }
        : { className: "", error: false };
    },
    [
      expandedItems,
      newProjectEstimatedItem.hasError,
      newProjectEstimatedItem.id,
    ],
  );

  const getExpandedItems = useCallback(
    (item: ProjectItemFieldsFragment) => {
      const estimatedItems = item.estimatedItems;
      if (
        expandedItems.some(
          (i) =>
            i.id === item.id &&
            (!i.zoneId ||
              i.zoneId === UNSPECIFIED_ZONE_ID ||
              estimatedItems.find((e) => e.zone?.id === i.zoneId)),
        ) &&
        estimatedItems.length > 1
      ) {
        return estimatedItems.map(() => item);
      }

      return [];
    },
    [expandedItems],
  );

  return (
    <GridTable
      configuration={{
        container: ZoneItemsContainer,
        columns,
        classNames: {
          root: "pb-20",
          header: "top-[126px]",
          category: "top-[164px]",
          subCategory: !groupedByZones ? "top-[164px]" : "top-[198px]",
          itemFn: itemClassNameFn,
        },
        toggle: {
          category: toggleZone,
          subCategory: toggleCostCode,
        },
      }}
      items={zones}
      readonly={true}
      hideGroup={!groupedByZones}
      expandedItems={getExpandedItems}
      loading={loading}
    />
  );
};
