import { gql } from "@apollo/client";
import { AGGREGATED_ITEM_FIELDS } from "./aggregatedItemFragment";

export const AGGREGATED_COST_CODE_FIELDS = gql`
  ${AGGREGATED_ITEM_FIELDS}
  fragment AggregatedCostCodeFields on AggregatedCostCode {
    costCode {
      id
    }
    items {
      ...AggregatedItemFields
    }
  }
`;
