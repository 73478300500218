import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  UpdateCostCodesInput,
  ViewerCostCodesDocument,
  useUpdateCostCodesMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useUpdateCostCodes = () => {
  const { setError } = useGlobalError();
  const [updateCostCodesMutation] = useUpdateCostCodesMutation();

  const updateCostCodes = useCallback(
    async (input: UpdateCostCodesInput) => {
      try {
        const { errors } = await updateCostCodesMutation({
          variables: {
            input,
          },
          refetchQueries: [{ query: ViewerCostCodesDocument }],
        });
        setError(errors);
        return !errors;
      } catch (e) {
        setError(e);
        return false;
      }
    },
    [updateCostCodesMutation, setError],
  );

  return {
    updateCostCodes,
  };
};
