import { useOrderTypeOptions } from "@/common/components/order-type-picker/hooks/useOrderTypeOptions";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { Card } from "@/common/layout/ResponsiveClasses";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { DeliveryInformation } from "./DeliveryInformation";
import { ReleaseVendorContactSelector } from "./ReleaseVendorContactSelector";
import { ReleaseVendorSelector } from "./ReleaseVendorSelector";
import { AssignBuyoutButton } from "./assign-buyout/AssignBuyoutButton";

const Container = tw(Card)`
  sticky z-50 top-9 lg:top-7 mb-6 justify-between border
  gap-2 items-center w-full bg-blue-100 py-3 pl-4 pr-3 rounded-md border-blue-200
  grid grid-cols-[repeat(2,_auto)]
  ${({
    $includeOrderTypes,
    $includePoNumbering,
  }: {
    $includeOrderTypes: boolean;
    $includePoNumbering: boolean;
  }) =>
    $includeOrderTypes && $includePoNumbering
      ? "sm:grid-cols-[repeat(5,_auto)] lg:grid-cols-[auto_auto_0.5fr_1fr_1fr_1fr_1fr_minmax(auto,_210px)_1fr]"
      : !$includeOrderTypes && !$includePoNumbering
        ? "sm:grid-cols-[repeat(3,_auto)] lg:grid-cols-[auto_auto_1fr_1fr_1fr_minmax(auto,_210px)_1fr]"
        : "sm:grid-cols-[repeat(4,_auto)] lg:grid-cols-[auto_auto_1fr_1fr_1fr_1fr_minmax(auto,_210px)_1fr]"}
`;

const ReleaseNumber = tw.div`
  text-black text-sm font-medium truncate w-fit col-span-full lg:col-span-1
`;

export const SpecifyDetailsHeader: FC<{
  release: ReleaseFieldsFragment;
}> = ({ release }) => {
  const intl = useIntl();
  const { includeOrderTypes } = useOrderTypeOptions();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "RELEASE" })}</title>
      </Helmet>
      <Container
        $includeOrderTypes={includeOrderTypes}
        $includePoNumbering={includePoNumbering || !!release.poNumber}
      >
        <ReleaseNumber>
          <FormattedMessage
            id={
              !release?.sequenceNumber
                ? "RELEASE_STATUS_DRAFT"
                : "DELIVERY_WITH_NUMBER"
            }
            values={{ number: release?.sequenceNumber }}
          />
        </ReleaseNumber>
        <ReleaseVendorSelector />
        <ReleaseVendorContactSelector />
        <AssignBuyoutButton release={release} />
        <DeliveryInformation />
      </Container>
    </>
  );
};
