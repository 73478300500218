import { If } from "@/common/components/if/If";
import { ManufacturerModalPicker } from "@/common/components/manufacturer-modal-picker/ManufacturerModalPicker";
import { isMasterSku } from "@/common/components/material/utils";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { RfqItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useRfq } from "../../hooks/useRfq";

type Props = {
  category: CategoryState<RfqItemFieldsFragment>;
  readonly?: boolean;
};

export const RfqCostCodeManufacturerSelector: FC<Props> = ({
  category,
  readonly,
}) => {
  const { updateRfq, rfq } = useRfq();

  const saveManufacturer = useCallback(
    async (manufacturerId: string | null) => {
      const updates = category.items
        .filter((i) => !isMasterSku(i.projectItem.material.material))
        .map((item) => {
          return {
            rfqItemId: item.id,
            manufacturerId,
          };
        });
      await updateRfq({
        rfqId: rfq?.id || "",
        updates,
      });
    },
    [category.items, updateRfq, rfq],
  );

  return (
    <If
      isTrue={category.items.some(
        (item) => !isMasterSku(item.projectItem.material.material),
      )}
    >
      <ManufacturerModalPicker
        onChange={saveManufacturer}
        readonly={readonly}
      />
    </If>
  );
};
