import { PoNumberInput } from "@/common/components/po-number/PoNumberInput";
import { TextField } from "@/common/components/textfield/TextField";
import { useUser } from "@/common/providers/UserProvider";
import { useReleasePredictedPoNumber } from "@/contractor/pages/home/release/pages/specify-details/hooks/useReleasePredictedPoNumber";
import { PoNumberingMode, Release, SourceSystem } from "@/generated/graphql";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`relative lg:inline-grid`;

type Props = {
  release?:
    | (Pick<Release, "id" | "version" | "status" | "poNumber"> & {
        buyout?: { poLink?: { id: string } | null } | null;
      })
    | null;
  hideLabel?: boolean;
  readonly?: boolean;
  readonlyClassName?: string;
  update: (
    release?: Pick<Release, "id" | "version" | "status"> | null,
    poNumber?: string,
  ) => void;
  sourceSystem?: SourceSystem;
  autoSize?: boolean;
  placeholder?: string;
  loading?: boolean;
};

export const ReleasePONumber: FC<Props> = ({
  release,
  hideLabel,
  readonly,
  readonlyClassName,
  update,
  sourceSystem,
  autoSize,
  placeholder,
  loading,
}) => {
  const { predictedPoNumber } = useReleasePredictedPoNumber(release?.id);
  const { poNumbering } = useUser();
  const [poNumber, setPONumber] = useState(release?.poNumber || "");
  const intl = useIntl();

  useEffect(() => {
    setPONumber(release?.poNumber || "");
  }, [release?.poNumber]);

  if (readonly) {
    return (
      <TextField
        label={hideLabel ? null : intl.$t({ id: "PO_NUMBER" })}
        staticText
        value={poNumber}
        InputLabelProps={{ className: "bg-transparent text-sm pl-4" }}
        inputProps={{ className: "w-28 text-sm pl-1" }}
        placeholder={placeholder}
        className={readonlyClassName}
      />
    );
  }

  return (
    <Container>
      <PoNumberInput
        hideLabel={hideLabel}
        value={poNumber}
        onChange={setPONumber}
        onBlur={(value?: string) => {
          update(release, value || poNumber);
        }}
        disabled={!!release?.buyout?.poLink}
        sourceSystem={sourceSystem}
        autoSize={autoSize}
        loading={loading}
        placeholder={
          poNumbering === PoNumberingMode.Always ? placeholder : undefined
        }
        autoFillValue={
          poNumbering === PoNumberingMode.Optional && !release?.buyout?.poLink
            ? predictedPoNumber || ""
            : ""
        }
      />
    </Container>
  );
};
