import { NoFunction } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";

type ProviderContextType = {
  actions?: React.ReactElement[];
  setActions: (actions: React.ReactElement[]) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  actions: [],
  setActions: NoFunction,
});

export const BreadcrumbsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [actions, setActions] = useState<React.ReactElement[]>([]);

  return (
    <ProviderContext.Provider
      value={{
        actions,
        setActions,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useBreadcrumbs = (): ProviderContextType =>
  useContext(ProviderContext);
