import { useCallback } from "react";
import { useFormatNumberToCurrency } from "../../value-currency/hooks/useFormatNumberToCurrency";
import { GraphDataType } from "../enums/GraphDataType";

export const useChartUtils = () => {
  const { formatCurrency } = useFormatNumberToCurrency();

  const getFormatted = useCallback(
    (value: number, format: GraphDataType) => {
      switch (format) {
        case GraphDataType.Currency:
          return formatCurrency(value, {
            minimumFractionDigits: value > 1e4 ? 0 : 2,
            maximumFractionDigits: value > 1e4 ? 0 : 2,
          });
        case GraphDataType.Numeric:
          return value;
        default:
          return value;
      }
    },
    [formatCurrency],
  );

  return { getFormatted };
};
