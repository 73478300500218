import { InfoOutlined } from "@mui/icons-material";
import { useIntl } from "react-intl";
import { TaxAuthorities } from "../../../../form/components/TaxAuthorities";
import {
  SimpleRowDetails,
  SimpleStepContainer,
  SimpleStepContent,
  SimpleStepRow,
  SimpleStepTitle,
  SimpleStepTitleContainer,
} from "../../../Wizard.styles";

export const AccountingOnPremiseTaxAuthorities = () => {
  const intl = useIntl();

  return (
    <SimpleStepContainer>
      <SimpleStepRow>
        <InfoOutlined className="mr-4 h-12 w-12 text-gray-400" />
        <SimpleStepTitleContainer>
          <SimpleStepTitle>
            {intl.$t({ id: "SPECIFY_TAX_AUTHORITIES_TITLE" })}
          </SimpleStepTitle>
        </SimpleStepTitleContainer>
      </SimpleStepRow>
      <SimpleStepContent $narrow>
        <TaxAuthorities />
        <SimpleRowDetails>
          {intl.$t({ id: "TAX_AUTHORITIES_DESCRIPTION" })}
        </SimpleRowDetails>
      </SimpleStepContent>
    </SimpleStepContainer>
  );
};
