import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useValidateRequiredParams } from "@/common/hooks/useValidateRequiredParams";
import { useUser } from "@/common/providers/UserProvider";
import {
  CreateReleaseTypeInput,
  OrderTypeSummariesDocument,
  OrderTypesDocument,
  UpdateReleaseTypeInput,
  useArchiveOrderTypeMutation,
  useCreateOrderTypeMutation,
  useUnarchiveOrderTypeMutation,
  useUpdateOrderTypeMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useOrderTypesActions = () => {
  const { setError } = useGlobalError();
  const { viewer } = useUser();

  const { validateParam } = useValidateRequiredParams("useOrderTypesActions");

  const [createOrderTypeMutation, { loading: creating }] =
    useCreateOrderTypeMutation();
  const createOrderType = useCallback(
    async (input: CreateReleaseTypeInput) => {
      validateParam({
        value: viewer?.org.id,
        methodName: "createOrderType",
        paramName: "viewer->org->id",
      });

      try {
        const { errors } = await createOrderTypeMutation({
          variables: {
            input,
          },
          refetchQueries: [
            { query: OrderTypesDocument },
            {
              query: OrderTypeSummariesDocument,
              variables: { id: viewer?.org.id },
            },
          ],
        });
        setError(errors);
        return !errors;
      } catch (e) {
        setError(e);
        return false;
      }
    },
    [createOrderTypeMutation, setError, validateParam, viewer?.org.id],
  );

  const [updateOrderTypeMutation, { loading: updating }] =
    useUpdateOrderTypeMutation();
  const updateOrderType = useCallback(
    async (input: UpdateReleaseTypeInput) => {
      try {
        const { errors } = await updateOrderTypeMutation({
          variables: {
            input,
          },
          refetchQueries: [{ query: OrderTypesDocument }],
        });
        setError(errors);
        return !errors;
      } catch (e) {
        setError(e);
        return false;
      }
    },
    [updateOrderTypeMutation, setError],
  );

  const [archiveOrderTypeMutation, { loading: archiving }] =
    useArchiveOrderTypeMutation();
  const archiveOrderType = useCallback(
    async (id: string) => {
      try {
        const { errors } = await archiveOrderTypeMutation({
          variables: {
            id,
          },
          refetchQueries: [{ query: OrderTypesDocument }],
        });
        setError(errors);
        return !errors;
      } catch (e) {
        setError(e);
        return false;
      }
    },
    [archiveOrderTypeMutation, setError],
  );

  const [unarchiveOrderTypeMutation, { loading: unarchiving }] =
    useUnarchiveOrderTypeMutation();
  const unArchiveOrderType = useCallback(
    async (id: string) => {
      try {
        const { errors } = await unarchiveOrderTypeMutation({
          variables: {
            id,
          },
          refetchQueries: [{ query: OrderTypesDocument }],
        });
        setError(errors);
        return !errors;
      } catch (e) {
        setError(e);
        return false;
      }
    },
    [setError, unarchiveOrderTypeMutation],
  );

  return {
    createOrderType,
    creating,
    updateOrderType,
    updating,
    archiveOrderType,
    archiving,
    unArchiveOrderType,
    unarchiving,
  };
};
