import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { TextField } from "@/common/components/textfield/TextField";
import { OrgMaterialFieldsFragment } from "@/generated/graphql";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMaterialList } from "../../../providers/MaterialListProvider";

type Props = {
  item: OrgMaterialFieldsFragment;
  readonly: boolean;
};

export const MaterialAccountingSoftwareID: FC<Props> = ({
  item,
  readonly,
}: Props) => {
  const [accountingSoftwareId, setAccountingSoftwareId] = useState(
    item.externalCode,
  );
  const { updateMaterial } = useMaterialList();
  const intl = useIntl();

  useEffect(() => {
    if (readonly) {
      setAccountingSoftwareId(item.externalCode);
    }
  }, [item.externalCode, readonly]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      setAccountingSoftwareId(newValue);
      updateMaterial(item.id, {
        updates: [
          {
            externalCode: newValue,
            clearExternalCode: !newValue,
            orgMaterialId: item.id,
          },
        ],
      });
    },
    [updateMaterial, item],
  );
  return (
    <NotNullableRenderer value={accountingSoftwareId || !readonly}>
      <TextField
        label={!readonly ? intl.$t({ id: "SPECIFY" }) : ""}
        value={accountingSoftwareId}
        onChange={onChange}
        staticText={readonly}
        inputProps={{ className: readonly ? "text-center text-sm" : "" }}
        InputLabelProps={{ className: "text-sm" }}
        xs
      />
    </NotNullableRenderer>
  );
};
