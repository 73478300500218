import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { FC, PropsWithChildren } from "react";
import { Tooltip } from "../../tooltip/Tooltip";

export type WizardModalFooterButton = {
  text: string;
  onClick?: () => void | Promise<void>;
  disabled?: boolean;
  loading?: boolean;
  type: "outlined" | "primary" | "link" | "text";
  className?: string;
  hidden?: boolean;
  testId?: string;
  tooltip?: string;
} & PropsWithChildren;

export const WizardModalFooterButton: FC<WizardModalFooterButton> = ({
  text,
  onClick,
  disabled,
  loading,
  type,
  className,
  hidden,
  children,
  testId,
  tooltip,
}) => {
  if (hidden) {
    return null;
  }
  if (children) {
    return <div className={className}>{children}</div>;
  }
  switch (type) {
    case "outlined":
      return (
        <OutlinedButton
          onClick={onClick}
          disabled={disabled}
          className={`max-w-60 ${className}`}
          loading={loading}
          testId={testId}
        >
          {text}
        </OutlinedButton>
      );
    case "primary":
      return (
        <Tooltip
          id="outlined-button-tooltip"
          element={
            <PrimaryButton
              onClick={onClick}
              disabled={disabled}
              className={`max-w-60 ${className}`}
              loading={loading}
              testId={testId}
            >
              {text}
            </PrimaryButton>
          }
        >
          {tooltip ?? undefined}
        </Tooltip>
      );
    case "link":
      return (
        <LinkLike
          onClick={onClick}
          disabled={disabled}
          className={`grid max-w-60 items-center ${className}`}
          testId={testId}
        >
          {text}
        </LinkLike>
      );
    case "text":
      return <div className={`max-w-60 ${className}`}>{text}</div>;
    default:
      return null;
  }
};
