import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import {
  BuyoutReleaseDetailsFieldsFragment,
  Scalars,
  useBuyoutReleaseDetailsQuery,
} from "@/generated/graphql";
import { FC, PropsWithChildren, createContext, useContext } from "react";

type ProviderContextType = {
  release: BuyoutReleaseDetailsFieldsFragment | null;
  loading: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  release: null,
  loading: false,
});

export const BuyoutReleaseDetailsProvider: FC<
  PropsWithChildren<{ id: Scalars["ID"]["output"] }>
> = ({ children, id }) => {
  const { data, loading, error } = useBuyoutReleaseDetailsQuery({
    variables: { id },
  });

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{ release: data?.release || null, loading }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useBuyoutReleaseDetails = (): ProviderContextType =>
  useContext(ProviderContext);
