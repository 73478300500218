import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { DEFAULT_ADDRESS_COUNTRY } from "@/common/const";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { WarehouseFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useWarehouses } from "../../providers/WarehousesProvider";
import { WarehouseDetailsSection } from "./WarehouseDetailsSection";

type FormType = {
  name: string;
  sameAddress: boolean;
  address: {
    addressLine1: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  externalCode: string;
};

type Props = {
  onClose: () => void;
  warehouse?: WarehouseFieldsFragment;
};

const Form = tw.form`h-full`;

export const WarehouseForm: FC<Props> = ({ onClose, warehouse }) => {
  const intl = useIntl();
  const { createWarehouse, updateWarehouse, loading } = useWarehouses();
  const { setSuccessAlert } = useSnackbar();

  const methods = useForm<FormType>({
    defaultValues: {
      name: warehouse?.name || "",
      address: {
        addressLine1: warehouse?.address.addressLine1 || "",
        city: warehouse?.address.city || "",
        state: warehouse?.address.state || "",
        postalCode: warehouse?.address.postalCode || "",
        country: warehouse?.address.country || DEFAULT_ADDRESS_COUNTRY,
      },
      externalCode: warehouse?.externalCode || "",
      sameAddress: false,
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSave = useCallback(
    async (values: FormType) => {
      let result = false;
      if (warehouse) {
        result = await updateWarehouse({
          id: warehouse.id,
          name: values.name,
          address: {
            addressLine1: values.address.addressLine1,
            city: values.address.city,
            state: values.address.state,
            postalCode: values.address.postalCode,
            country: values.address.country,
          },
          externalCode: values.externalCode,
        });
      } else {
        result = await createWarehouse({
          name: values.name,
          address: {
            addressLine1: values.address.addressLine1,
            city: values.address.city,
            state: values.address.state,
            postalCode: values.address.postalCode,
            country: values.address.country,
          },
          externalCode: values.externalCode || undefined,
        });
      }
      if (!result) {
        return;
      }
      setSuccessAlert(
        intl.$t(
          {
            id: warehouse
              ? "WAREHOUSE_UPDATE_SUCCESS"
              : "WAREHOUSE_CREATE_SUCCESS",
          },
          {
            name: strongify(values.name),
          },
        ),
      );
      onClose();
    },
    [
      warehouse,
      setSuccessAlert,
      intl,
      onClose,
      updateWarehouse,
      createWarehouse,
    ],
  );

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSave)}>
        <OverlayPanel
          title={intl.$t({
            id: warehouse ? "WAREHOUSE_UPDATE" : "WAREHOUSE_CREATE",
          })}
          onCancel={onClose}
          saving={loading}
          onSave={methods.handleSubmit(onSave)}
        >
          <WarehouseDetailsSection warehouse={warehouse} />
        </OverlayPanel>
      </Form>
    </FormProvider>
  );
};
