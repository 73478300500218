import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { routes } from "@/config/routes";
import { useCreateRelease } from "@/contractor/pages/home/release/hooks/useCreateRelease";
import { BuyoutFieldsFragment, BuyoutStatus } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import { ButtonContainer, FooterContainer } from "../../../Buyout.styles";
import { BackToAllBuyoutsButton } from "../../common/BackToAllBuyoutsButton";
import { BuyoutCancelButton } from "../../common/BuyoutCancelButton";
import { BuyoutQuotedSubmitButton } from "./BuyoutQuotedSubmitButton";

export const BuyoutQuotedFooter: FC<{
  buyout: BuyoutFieldsFragment;
}> = ({ buyout }) => {
  const navigate = useNavigate();
  const { createReservedRelease } = useCreateRelease();

  const handleRequestDelivery = useCallback(async () => {
    if (buyout?.id) {
      const result = await createReservedRelease({
        buyoutId: buyout.id,
        typeId: buyout.releaseType.id,
      });
      if (result) {
        navigate(
          generatePath(routes.specifyDeliveryDetails, {
            deliveryId: result.id,
          }),
        );
      }
    }
  }, [buyout.id, buyout.releaseType.id, createReservedRelease, navigate]);

  const readonly = useMemo(
    () =>
      [BuyoutStatus.Depleted, BuyoutStatus.Cancelled].includes(buyout.status),
    [buyout.status],
  );

  return (
    <FooterContainer>
      <If isTrue={!readonly}>
        <FloatingFooter>
          <ButtonContainer>
            <If isTrue={buyout.status === BuyoutStatus.Draft}>
              <BackToAllBuyoutsButton />
              <BuyoutQuotedSubmitButton buyout={buyout} />
            </If>
            <If isTrue={buyout.status === BuyoutStatus.Active}>
              <BuyoutCancelButton buyout={buyout} />
              <OutlinedButton
                onClick={handleRequestDelivery}
                testId="create-release-button"
              >
                <FormattedMessage id="CREATE_DELIVERY" />
              </OutlinedButton>
            </If>
          </ButtonContainer>
        </FloatingFooter>
      </If>
    </FooterContainer>
  );
};
