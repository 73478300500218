import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { useSourceSystemConfig } from "@/common/hooks/integrations/source-system-config/useSourceSystemConfig";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { useMemo } from "react";
import { useApLedgerAccountPages } from "./pages/useApLedgerAccountPages";

export const useDefaultPageConfig = () => {
  const { connectedSourceSystem } = useOrgSettingsExtended();
  const apLedgerAccountPages = useApLedgerAccountPages();
  const { sourceSystemConfig } = useSourceSystemConfig(
    connectedSourceSystem?.system,
  );

  // add here any custom
  const allPages: WizardModalPage[] = useMemo(() => {
    return [apLedgerAccountPages];
  }, [apLedgerAccountPages]);

  const sourceSystemPages: WizardModalPage[] = useMemo(() => {
    return (
      (sourceSystemConfig?.features
        .filter((feature) => !!feature)
        .map((feature) => {
          return allPages.find((page) => page.feature === feature);
        })
        .filter((page) => !!page) as WizardModalPage[]) || []
    );
  }, [allPages, sourceSystemConfig?.features]);

  return {
    allPages,
    sourceSystemPages,
  };
};
