import {
  OrgPreferredVendorsFieldsFragment,
  RfqFieldsFragment,
  RfqQuotesFieldsFragment,
} from "@/generated/graphql";
import { getVendorContacts } from "../../../../../common/utils/getVendorContacts";

export const getVendorFullName = (
  vendor?: {
    contacts: { name: string }[];
    sellerOrgLocation: { org: { name: string } };
  } | null,
): string | undefined => {
  if (!vendor) {
    return;
  }

  return concatVendorOrgAndName(
    vendor.sellerOrgLocation.org.name,
    getVendorContacts(vendor.contacts),
  );
};

export const joinRfqVendors = (
  vendors: OrgPreferredVendorsFieldsFragment[],
  rfq: RfqQuotesFieldsFragment | RfqFieldsFragment | null,
) => {
  return vendors
    .filter((vendor) =>
      rfq?.targetSellersOrgLocations
        .map((t) => t.id)
        .includes(vendor.sellerOrgLocation.id),
    )
    .map(getVendorFullName)
    .join(", ");
};

const concatVendorOrgAndName = (orgName: string, contactName: string) =>
  `${orgName} - ${contactName}`;
