import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { DrawerView } from "@/common/components/panel/DrawerView";
import { ReleaseFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { RejectReason } from "./RejectReason";

type Props = {
  release: ReleaseFieldsFragment;
};

export const AddRejectReason: FC<Props> = ({ release }) => {
  const { isOpened, toggle } = useGlobalDrawer();
  return (
    <DrawerView
      isOpened={isOpened("reject-reason")}
      setIsOpened={(value) => toggle("reject-reason", value)}
      content={(togglePanel) => (
        <RejectReason release={release} onClose={() => togglePanel(false)} />
      )}
    />
  );
};
