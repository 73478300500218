import { ASSET_FIELDS } from "@/common/fragments/assetFragment";
import { gql } from "@apollo/client";

export const NOTE_DOCUMENT_SUMMARY_FIELDS = gql`
  ${ASSET_FIELDS}
  fragment NoteDocumentSummaryFields on NoteDocument {
    id
    asset {
      ...AssetFields
    }
  }
`;
