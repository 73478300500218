import { If } from "@/common/components/if/If";
import { LinkLikeText } from "@/common/components/link-like-text/LinkLikeText";
import { Loader } from "@/common/components/loader/Loader";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { ORDER_INVOICED_INVOICES_STATUS } from "@/common/const";
import { ReleaseProvider } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  ExternalNotificationsProvider,
  useExternalNotifications,
} from "../../providers/ExternalNotificationsProvider";
import { useGetReleaseFromPathParams } from "../../utils/getReleaseFromPathParams";
import { NoNotifications } from "../common/Notification.styles";
import { ExternalNotificationFilter } from "./ExternalNotificationFilter";
import { ExternalNotificationListItem } from "./ExternalNotificationListItem";

const Container = tw.div`flex flex-col gap-2`;
const NotificationsContainer = tw.div``;
const LoadMore = tw.div`text-center my-2`;

const ExternalNotificationsWithProvider: FC = () => {
  const { externalNotifications, loading, loadMore, hasNextPage } =
    useExternalNotifications();

  return (
    <Container>
      <ExternalNotificationFilter />
      <NotificationsContainer>
        <Loader loading={loading && externalNotifications.length === 0}>
          {externalNotifications.map((notification, index) => (
            <ExternalNotificationListItem
              key={index}
              notification={notification}
              index={index}
            />
          ))}
          {externalNotifications.length === 0 ? (
            <NoNotifications>
              <FormattedMessage id="NO_NOTIFICATIONS" />
            </NoNotifications>
          ) : (
            <If isTrue={hasNextPage}>
              <LinkLikeText onClick={loadMore}>
                <FormattedMessage id="LOAD_MORE" tagName={LoadMore} />
              </LinkLikeText>
            </If>
          )}
        </Loader>
        <Loader loading={loading && externalNotifications.length > 0} small />
      </NotificationsContainer>
    </Container>
  );
};

export const ExternalNotifications: FC = () => {
  const { releaseId } = useGetReleaseFromPathParams();
  return (
    <PaginationProvider itemsPerPage={5} useQueryString={false}>
      <ReleaseProvider
        id={releaseId}
        invoiceStatuses={ORDER_INVOICED_INVOICES_STATUS}
      >
        <ExternalNotificationsProvider>
          <ExternalNotificationsWithProvider />
        </ExternalNotificationsProvider>
      </ReleaseProvider>
    </PaginationProvider>
  );
};
