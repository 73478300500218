import { StyledSelect } from "@/common/components/select/components/single/StyledSelect";
import { EstimatedItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";
import { UNSPECIFIED_ZONE_ID } from "../../../../../../common/hooks/useUnspecifiedZone";
import { useProjectZoneUtils } from "../../hooks/useProjectZoneUtils";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";
import { useProject } from "../../providers/ProjectProvider";

type Props = {
  estimatedItem?: EstimatedItemFieldsFragment | null;
  readonly?: boolean;
};

export const ProjectItemZonePicker: FC<Props> = ({
  estimatedItem,
  readonly = false,
}) => {
  const { project } = useProject();
  const { zoneOptions } = useProjectZoneUtils(project);
  const { setNewProjectEstimatedItem, updating } = useEstimatedItems();
  const [selectedZone, setSelectedZone] = useState<string | null>(
    estimatedItem?.zone?.id || UNSPECIFIED_ZONE_ID,
  );

  useEffect(() => {
    if (readonly && !updating) {
      setSelectedZone(estimatedItem?.zone?.id || UNSPECIFIED_ZONE_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatedItem?.zone, estimatedItem?.id, readonly]);

  const handleChange = useCallback(
    (newValue: string | null) => {
      setSelectedZone(newValue);
      setNewProjectEstimatedItem([{ key: "zoneId", value: newValue }]);
    },
    [setNewProjectEstimatedItem],
  );

  return (
    <StyledSelect
      options={zoneOptions}
      value={selectedZone}
      getValue={(opt) => opt.id}
      getLabel={(opt) => opt.name}
      onChange={handleChange}
      disabled={readonly}
      xs
    />
  );
};
