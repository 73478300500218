import {
  CreateInvoicesInput,
  InvoiceFieldsFragment,
} from "@/generated/graphql";
import { NoFunction, NoFunctionArrayPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";
import { useCreateInvoices } from "../hooks/useCreateInvoices";

type ProviderContextType = {
  createInvoices: (
    input: CreateInvoicesInput,
  ) => Promise<InvoiceFieldsFragment[] | undefined>;
  creating: boolean;
  locationId: string | null;
  setLocationId: (id: string | null) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  createInvoices: NoFunctionArrayPromise,
  creating: false,
  setLocationId: NoFunction,
  locationId: null,
});

export const InvoiceCreationProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [locationId, setLocationId] = useState<string | null>(null);
  const { createInvoices, loading: creating } = useCreateInvoices();

  return (
    <ProviderContext.Provider
      value={{
        createInvoices,
        creating,
        locationId,
        setLocationId,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useInvoiceCreation = (): ProviderContextType =>
  useContext(ProviderContext);
