import { AssetItem } from "@/common/components/asset-item/AssetItem";
import { ReleaseStatus } from "@/generated/graphql";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { FooterHeaderText } from "../../../../../../../common/components/grid-table/styles/Footer.styles";
import { useRelease } from "../../../providers/ReleaseProvider";

const Container = tw.div<{
  $hideBorder: boolean;
}>`
  bg-gray-100 rounded-b-3xl px-4 pb-6 flex flex-col items-start pt-5 
  ${({ $hideBorder }) =>
    $hideBorder
      ? ""
      : "border-[1px] border-l-0 border-r-0 border-b-0 border-gray-600 border-dashed"}
`;
const Notes = tw.div`font-light text-sm`;
const Assets = tw.div`flex flex-wrap gap-4 mt-3`;

export const ReleaseNotesFooter = () => {
  const { release } = useRelease();
  if (!release?.notes && (release?.assets ?? []).length === 0) {
    return null;
  }
  return (
    <Container
      $hideBorder={
        !!(
          release?.status &&
          ![
            ReleaseStatus.Received,
            ReleaseStatus.PartiallyReceived,
            ReleaseStatus.Requested,
            ReleaseStatus.Scheduled,
          ].includes(release?.status)
        )
      }
    >
      <FormattedMessage id="VENDOR_NOTES" tagName={FooterHeaderText} />
      <Notes>{release?.notes}</Notes>
      <Assets>
        {(release?.assets ?? []).map((asset, index) => (
          <AssetItem key={index} asset={asset} readonly />
        ))}
      </Assets>
    </Container>
  );
};
