import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import { useReleaseItemsToSubmit } from "@/contractor/pages/home/release/pages/specify-details/hooks/useReleaseItemsToSubmit";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseUpdateProvider } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { OrderFromNoteProvider } from "../../../providers/OrderFromNoteProvider";
import { NoteDocumentDetails } from "../note-document/components/note-document-details/NoteDocumentDetails";
import { useCreateOrderFromNoteConfig } from "./components/CreateOrderFromNote.config";
import { CreateOrderFromNoteBreadcrumbs } from "./components/CreateOrderFromNoteBreadcrumbs";
import { CreateOrderFromNoteDetails } from "./components/CreateOrderFromNoteDetails";
import { CreateOrderFromNoteFooter } from "./components/CreateOrderFromNoteFooter";

const Container = tw.div`
  mt-5 xl:-mx-10 sticky 
`;

const GroupContainer = tw.div`
  grid grid-cols-[2.5fr_2fr] gap-4 top-0
`;

const CreateOrderFromNoteWithProvider: FC = () => {
  const { release } = useRelease();
  const items = useReleaseItemsToSubmit();

  if (!release) {
    return (
      <Container>
        <CreateOrderFromNoteBreadcrumbs />
        <GroupContainer>
          <CreateOrderFromNoteDetails />
          <NoteDocumentDetails />
        </GroupContainer>
        <CreateOrderFromNoteFooter />
      </Container>
    );
  }

  return (
    <ReleaseUpdateProvider release={release} items={items}>
      <Container>
        <CreateOrderFromNoteBreadcrumbs />
        <GroupContainer>
          <CreateOrderFromNoteDetails />
          <NoteDocumentDetails />
        </GroupContainer>
        <CreateOrderFromNoteFooter />
      </Container>
    </ReleaseUpdateProvider>
  );
};

export const CreateOrderFromNote: FC = () => {
  const spreadsheetConfig = useCreateOrderFromNoteConfig();
  return (
    <ColumnMapperProvider config={spreadsheetConfig}>
      <OrderFromNoteProvider>
        <CreateOrderFromNoteWithProvider />
      </OrderFromNoteProvider>
    </ColumnMapperProvider>
  );
};
