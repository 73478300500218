import { InfoHeader } from "@/common/components/info-header/InfoHeader";
import { InfoHeaderItem } from "@/common/components/info-header/components/InfoHeaderItem";
import { OrgDetailsHeaderItem } from "@/common/components/info-header/components/OrgDetailsHeaderItem";
import { VendorList } from "@/common/components/vendor-list/VendorList";
import { getUserName } from "@/common/utils/users/getUserName";
import { DistributorQuoteCommonFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { ProjectInfoHeader } from "../components/info-header/ProjectInfoHeader";
import { ProjectStageHeader } from "../components/info-header/ProjectStageHeader";

type Props = {
  quote: DistributorQuoteCommonFieldsFragment;
};

export const QuoteInfoHeader: FC<Props> = ({ quote }) => {
  return (
    <InfoHeader>
      <OrgDetailsHeaderItem
        title={
          <FormattedMessage
            id="RFQ_NR"
            values={{ number: quote.rfq.clientIdentifier }}
          />
        }
        name={getUserName(quote.rfq.createdBy)}
        orgName={quote.rfq.project.location.org.name}
        logoImageUrl={quote.rfq.project.location.org.logoImageUrl}
        address={quote.rfq.project.address}
      />
      <InfoHeaderItem>
        <ProjectInfoHeader project={quote.rfq.project} />
      </InfoHeaderItem>
      <InfoHeaderItem>
        <ProjectStageHeader project={quote.rfq.project} />
      </InfoHeaderItem>
      <OrgDetailsHeaderItem
        title={<FormattedMessage id="VENDOR" />}
        name={
          <VendorList
            contacts={quote.preferredVendor?.contacts.filter(
              (c) => c.receivesQuoteNotifications,
            )}
          />
        }
        orgName={quote.sellerOrgLocation.org.name}
        logoImageUrl={quote.sellerOrgLocation?.org.logoImageUrl}
        address={quote.sellerOrgLocation.address}
      />
    </InfoHeader>
  );
};
