export const TAG_COLORS = [
  "#880009",
  "#F40018",
  "#FF4382",
  "#FF81AB",
  "#FF32E5",
  "#F089F8",
  "#C260E7",
  "#9E5FB3",
  "#715EFA",
  "#0046E2",
  "#6EB5FB",
  "#009BD9",
  "#0084B5",
  "#00ACF0",
  "#00BDD2",
  "#00BC9D",
  "#00CB76",
  "#FFD63D",
  "#B77C38",
  "#FF7628",
  "#F44F1E",
  "#B5BDC2",
];

export const getRandomTagColor = (currentColors: string[] = []): string => {
  const remainingColors = TAG_COLORS.filter(
    (color) => !currentColors.includes(color),
  );

  if (remainingColors.length === 0) {
    const minColor = 0x333333;
    return `#${Math.floor(
      Math.random() * (0xffffff - minColor) + minColor,
    ).toString(16)}`;
  } else {
    return remainingColors[Math.floor(Math.random() * remainingColors.length)];
  }
};
