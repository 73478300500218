import tw from "tailwind-styled-components";

const Container = tw.div`
  relative px-4 h-6 grid font w-full items-center
`;

const Name = tw.div`
  text-xs border-b border-gray-400  border-dashed text-right py-1
  text-ellipsis overflow-hidden whitespace-nowrap mr-2 w-full
`;

type Props = {
  name: string | null | undefined;
};

export const ItemDetailsRow = ({ name }: Props) => {
  if (!name) {
    return null;
  }
  return (
    <Container>
      <Name title={name}>{name}</Name>
    </Container>
  );
};
