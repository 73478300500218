import { If } from "@/common/components/if/If";
import { FC, ReactNode } from "react";
import {
  ColumnContainer,
  DetailsContainer,
  NameContainer,
} from "./RfqVendors.styles";

type ItemCellProps = {
  name?: string | ReactNode;
  value: string | ReactNode | ReactNode[];
  centered?: boolean;
};

export const ItemCell: FC<ItemCellProps> = ({
  name = "",
  value,
  centered = false,
}) => {
  return (
    <ColumnContainer>
      <If isTrue={!!name}>
        <NameContainer $centered={centered}>{name}</NameContainer>
      </If>
      <DetailsContainer $centered={centered}>{value}</DetailsContainer>
    </ColumnContainer>
  );
};
