import { FC } from "react";
import {
  TextFieldControlled,
  TextFieldControlledProps,
} from "../textfield-controlled/TextFieldControlled";
import { PhoneMask } from "./PhoneMask";

type PhoneInputControlledProps = TextFieldControlledProps & {
  countryCode?: string;
};

export const PhoneInputControlled: FC<PhoneInputControlledProps> = ({
  name,
  rules,
  countryCode,
  ...props
}) => {
  return (
    <TextFieldControlled
      name={name}
      rules={rules}
      {...props}
      InputProps={{
        inputComponent: PhoneMask,
        inputProps: { countryCode },
      }}
    />
  );
};
