import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  AddOrgLocationInput,
  AddOrgPreferredVendorInput,
  AddVendorOrgInput,
  OrgPreferredVendorsDocument,
  UpdateOrgLocationInput,
  UpdateOrgPreferredVendorInput,
  useAddVendorLocationMutation,
  useAddVendorOrgMutation,
  useCreateVendorMutation,
  useOrgPreferredVendorsQuery,
  useRemoveVendorMutation,
  useUpdateVendorLocationMutation,
  useUpdateVendorMutation,
} from "@/generated/graphql";
import { useCallback, useMemo } from "react";

export const useVendors = () => {
  const { setError } = useGlobalError();

  const {
    data: vendors,
    loading: queryLoader,
    error,
  } = useOrgPreferredVendorsQuery();

  const [createVendorMutation, { loading: createVendorLoader }] =
    useCreateVendorMutation();
  const [updateVendorMutation, { loading: updateVendorLoader }] =
    useUpdateVendorMutation();
  const [removeVendorMutation] = useRemoveVendorMutation();
  const [addVendorOrgMutation] = useAddVendorOrgMutation();
  const [addVendorLocationMutation] = useAddVendorLocationMutation();
  const [updateVendorLocationMutation] = useUpdateVendorLocationMutation();

  const loading = useMemo(
    () => queryLoader || createVendorLoader || updateVendorLoader,
    [queryLoader, createVendorLoader, updateVendorLoader],
  );

  const createVendorOrg = async (vendor: AddVendorOrgInput) => {
    try {
      const { data, errors } = await addVendorOrgMutation({
        variables: { input: vendor },
      });
      setError(errors);
      return data?.addVendorOrg;
    } catch (errors) {
      setError(errors);
    }
  };

  const createVendor = async (vendor: AddOrgPreferredVendorInput) => {
    try {
      const { data, errors } = await createVendorMutation({
        variables: { input: vendor },
        refetchQueries: [{ query: OrgPreferredVendorsDocument }],
        awaitRefetchQueries: true,
      });
      setError(errors);
      return data?.addOrgPreferredVendor.find(
        (o) => o.sellerOrgLocation.id === vendor.orgLocationID,
      );
    } catch (errors) {
      setError(errors);
    }
  };

  const updateVendor = async (vendor: UpdateOrgPreferredVendorInput) => {
    try {
      const { data, errors } = await updateVendorMutation({
        variables: { input: vendor },
        refetchQueries: [{ query: OrgPreferredVendorsDocument }],
        awaitRefetchQueries: true,
      });
      setError(errors);
      return data?.updateOrgPreferredVendor;
    } catch (errors) {
      setError(errors);
    }
  };

  const removeVendor = async (id: string) => {
    try {
      const { errors } = await removeVendorMutation({
        variables: { id },
        refetchQueries: [{ query: OrgPreferredVendorsDocument }],
        awaitRefetchQueries: true,
      });
      setError(errors);
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const addVendorLocation = async (vendorLocation: AddOrgLocationInput) => {
    try {
      const { data, errors } = await addVendorLocationMutation({
        variables: {
          input: { ...vendorLocation, name: vendorLocation.name || null },
        },
        refetchQueries: [{ query: OrgPreferredVendorsDocument }],
        awaitRefetchQueries: true,
      });
      setError(errors);
      return data?.addVendorLocation;
    } catch (errors) {
      setError(errors);
    }
  };

  const updateVendorLocation = async (
    updatedVendorLocation: UpdateOrgLocationInput,
  ) => {
    try {
      const { data, errors } = await updateVendorLocationMutation({
        variables: {
          input: updatedVendorLocation,
        },
        refetchQueries: [{ query: OrgPreferredVendorsDocument }],
      });
      setError(errors);
      return data?.updateVendorLocation;
    } catch (errors) {
      setError(errors);
    }
  };

  const findOrderTypeByLocationId = useCallback(
    (locationId: string) => {
      return (vendors?.orgPreferredVendors ?? []).find(
        (vendor) => vendor.sellerOrgLocation.id === locationId,
      )?.defaultReleaseType?.id;
    },
    [vendors?.orgPreferredVendors],
  );

  useErrorEffect(error);

  return {
    vendors: vendors?.orgPreferredVendors ?? [],
    loading,
    error: !!error,
    createVendorOrg,
    createVendor,
    updateVendor,
    removeVendor,
    addVendorLocation,
    updateVendorLocation,
    findOrderTypeByLocationId,
  };
};
