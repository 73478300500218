import { ManufacturerPicker } from "@/common/components/manufacturer-picker/ManufacturerPicker";
import { DistributorReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  DistributorReleaseErrorType,
  useDistributorRelease,
} from "../providers/DistributorReleaseProvider";

type Props = {
  item: DistributorReleaseItemFieldsFragment;
  readonly?: boolean;
  staticText?: boolean;
  index?: number;
};

export const DistributorReleaseManufacturerSelector: FC<Props> = ({
  item,
  readonly,
  staticText,
  index,
}) => {
  const { updateVendorReleaseItem, inputErrors, updatedItem } =
    useDistributorRelease();
  const [manufacturer, setManufacturer] = useState(item.manufacturer);

  const saveManufacturer = useCallback(
    async (manufacturerId: string | null) => {
      await updateVendorReleaseItem({ manufacturerId, releaseItemId: item.id });
    },
    [updateVendorReleaseItem, item.id],
  );

  useEffect(() => {
    if (updatedItem(item)?.manufacturerId !== item.manufacturer?.id) {
      return;
    }
    setManufacturer(item.manufacturer);
  }, [item, updatedItem]);

  const error = useMemo(
    () =>
      !!(
        inputErrors.includes(DistributorReleaseErrorType.MANUFACTURER) &&
        !(updatedItem(item)?.manufacturerId ?? item.manufacturer?.id)
      ),
    [inputErrors, item, updatedItem],
  );

  if (!(updatedItem(item)?.isIncluded ?? item.isIncluded)) {
    return null;
  }

  return (
    <ManufacturerPicker
      item={manufacturer}
      saveManufacturer={saveManufacturer}
      readonly={readonly}
      error={error && !item.manufacturer?.id}
      staticText={staticText}
      index={index}
    />
  );
};
