import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  PoFormat,
  PoValidationFieldsFragment,
  ReleaseSummaryFieldsFragment,
  SourceSystem,
  usePoValidationQuery,
} from "@/generated/graphql";
import { NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useDefaultReleasePo } from "../hooks/useDefaultReleasePo";
import { useLinkPo } from "../hooks/useLinkPo";
import { useReleaseConnectionOptions } from "./ReleaseConnectionOptionsProvider";
import { useReleasesByIds } from "./ReleasesByIdsProvider";
type ProviderContextType = {
  linkPo: () => Promise<boolean | undefined | string>;
  poValidation?: PoValidationFieldsFragment;
  refetchPoValidation: () => void;
  loading: boolean;
};

type Props = {
  children: React.ReactNode;
  release: ReleaseSummaryFieldsFragment;
};

const ProviderContext = createContext<ProviderContextType>({
  linkPo: NoFunctionBooleanPromise,
  poValidation: undefined,
  refetchPoValidation: NoFunctionBooleanPromise,
  loading: false,
});

export const PoValidationProvider: FC<Props> = ({ children, release }) => {
  const { linkPo: linkPoMutation } = useLinkPo();
  const { connectedSourceSystem } = useOrgSettings();
  const { setValidatedRelease } = useReleasesByIds();
  const [poValidation, setPoValidation] =
    useState<PoValidationFieldsFragment>();
  const { clearErrors } = useReleaseConnectionOptions();
  const { getDefaultReleasePo } = useDefaultReleasePo();

  const { autoSync, updatedReleases, sourceSystem } =
    useReleaseConnectionOptions();

  const { data, error, refetch, loading } = usePoValidationQuery({
    variables: {
      input: {
        sourceSystem: connectedSourceSystem || SourceSystem.Foundation,
        autoSync,
        poNumber:
          updatedReleases.find((r) => r.releaseId === release?.id)?.poNumber ||
          release.poNumber ||
          getDefaultReleasePo(release) ||
          "",
        format: release.type.poFormat || PoFormat.Basic,
        releaseId: release?.id || "",
      },
    },
    skip: !connectedSourceSystem,
    fetchPolicy: "cache-and-network",
  });
  useErrorEffect(error);

  useEffect(() => {
    if (data?.poValidation || !loading) {
      setValidatedRelease({
        id: release.id,
        loading: false,
        validated:
          data?.poValidation.errors.length === 0 &&
          data?.poValidation.costCodesNotConnected.length === 0 &&
          data?.poValidation.itemsMissingCostCodes.length === 0 &&
          data?.poValidation.itemsMissingPrices.length === 0 &&
          data?.poValidation.materialsNotConnected.length === 0 &&
          data?.poValidation.itemsMissingTags.length === 0 &&
          data?.poValidation.materialConversions.filter(
            (m) => !m.quantityMultiplier,
          ).length === 0,
      });
      setPoValidation(data?.poValidation);
      clearErrors();
    }
  }, [
    clearErrors,
    data?.poValidation,
    loading,
    release.id,
    setValidatedRelease,
  ]);

  useEffect(() => {
    if (loading) {
      setValidatedRelease({
        id: release.id,
        loading: true,
        validated:
          poValidation?.errors.length === 0 &&
          poValidation?.costCodesNotConnected.length === 0 &&
          poValidation?.itemsMissingCostCodes.length === 0 &&
          poValidation?.itemsMissingPrices.length === 0 &&
          poValidation?.materialsNotConnected.length === 0 &&
          poValidation.itemsMissingTags.length === 0 &&
          poValidation?.materialConversions.filter((m) => !m.quantityMultiplier)
            .length === 0,
      });
      clearErrors();
    }
  }, [clearErrors, loading, poValidation, release.id, setValidatedRelease]);

  const linkPo = async () => {
    if (release && sourceSystem) {
      const updatedRelease = updatedReleases.find(
        (r) => r.releaseId === release?.id,
      );
      return await linkPoMutation({
        releaseId: release?.id,
        autoSync,
        poNumber: updatedRelease?.poNumber || release.poNumber || "",
        format: release.type.poFormat || PoFormat.Basic,
        sourceSystem,
      });
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        linkPo,
        poValidation,
        refetchPoValidation: refetch,
        loading,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const usePoValidation = (): ProviderContextType =>
  useContext(ProviderContext);
