import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceImportExternalPO } from "../../../../providers/InvoiceImportExternalPoProvider";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";
const Container = tw.div`
  flex flex-col justify-center items-center py-16
  border-dashed border border-gray-600 rounded-3xl mt-5 mx-4
`;
const Row = tw.div`flex flex-row`;
const InfoIcon = tw(InfoOutlined)`text-gray-500 w-6 h-6 mr-2`;
const Title = tw.div`text-lg font-medium mb-3`;
const Description = tw.div`text-sm text-gray-600 font-normal text-center mb-10 px-8`;

export const NoReleaseForInvoiceInfo: FC = () => {
  const { connectedSourceSystem } = useOrgSettings();
  const { setFooterState, invoice } = useInvoiceVerification();
  const { setMatchedOrderViewState } = useInvoiceMatchedOrder();
  const { externalPOsQueryForm } = useInvoiceImportExternalPO();
  const intl = useIntl();

  return (
    <Container>
      <Row>
        <InfoIcon />
        <FormattedMessage id="INVOICE_NOT_MATCHED_TITLE" tagName={Title} />
      </Row>
      <FormattedMessage
        id={
          connectedSourceSystem
            ? "INVOICE_NOT_MATCHED_DESCRIPTION_CONNECTION_ACTIVE"
            : "INVOICE_NOT_MATCHED_DESCRIPTION"
        }
        values={{
          integration: connectedSourceSystem
            ? intl.$t({
                id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
              })
            : "",
        }}
        tagName={Description}
      />
      <Row>
        <PrimaryButton
          className="mr-2 w-[200px] rounded-[90px]"
          onClick={() => {
            setFooterState(InvoiceFooterState.MATCH_ORDER);
            setMatchedOrderViewState(MatchedOrderViewState.MATCH_ORDER);
          }}
        >
          <FormattedMessage id="SELECT_ORDER_MANUALLY_INVOICE" />
        </PrimaryButton>
        <PrimaryButton
          className="w-[180px] rounded-[90px]"
          onClick={() => {
            setFooterState(InvoiceFooterState.CREATE_ORDER);
            setMatchedOrderViewState(MatchedOrderViewState.CREATE_ORDER);
          }}
          testId="create-new-order-from-invoice"
        >
          <FormattedMessage id="CREATE_NEW_ORDER_FROM_INVOICE" />
        </PrimaryButton>
        <If isTrue={connectedSourceSystem}>
          <PrimaryButton
            className="ml-2 w-[180px] rounded-[90px]"
            onClick={() => {
              externalPOsQueryForm?.setValue("search", invoice?.poNumber || "");
              setFooterState(InvoiceFooterState.IMPORT_ORDER);
              setMatchedOrderViewState(MatchedOrderViewState.IMPORT_ORDER);
            }}
            testId="import-external-po"
          >
            <FormattedMessage
              id="IMPORT_PO_FROM_SYSTEM_AS_NEW_ORDER"
              values={{
                system: connectedSourceSystem
                  ? intl.$t({
                      id: `SOURCE_SYSTEM_${connectedSourceSystem}`,
                    })
                  : "",
              }}
            />
          </PrimaryButton>
        </If>
      </Row>
    </Container>
  );
};
