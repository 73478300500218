import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { useMasterSkusProductsToggle } from "@/common/components/skus-products-switch/MasterSkusProductsToggleProvider";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import {
  Facet,
  MasterProductsProductFieldsFragment,
  MasterSkusSkuFieldsFragment,
  SearchContext,
  SearchInput,
  useMasterProductsQuery,
  useMasterSkusQuery,
} from "@/generated/graphql";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useCategories } from "../category-filter/CategoriesProvider";
import { FacetsProvider, useFacets } from "./FacetsProvider";

export type SelectedFacet = {
  id: string;
  value: string;
};

export type ProductFacet = Pick<Facet, "id" | "name">;

type ProviderContextType = {
  masterSkus: MasterSkusSkuFieldsFragment[];
  masterProducts: MasterProductsProductFieldsFragment[];
  skusLoading: boolean;
  productsLoading: boolean;
  totalCount: number;
  setSearch: (search: SearchInput | null) => void;
  searchContext?: SearchContext | null;
  searchPerformed: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  masterSkus: [],
  masterProducts: [],
  skusLoading: false,
  productsLoading: false,
  totalCount: 0,
  setSearch: () => null,
  searchContext: null,
  searchPerformed: false,
});

const SkusAndProductsProviderWithFacets: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { categoryId } = useCategories();
  const { setPageInfo, paginationArgs } = usePagination();
  const [search, setSearch] = useState<SearchInput | null>();
  const [searchPerformed, setSearchPerformed] = useState<boolean>(false);
  const { updateFacets, selectedFacets } = useFacets();
  const { showProductsToggle } = useMasterSkusProductsToggle();

  const {
    data: skus,
    loading: skusLoading,
    error,
  } = useMasterSkusQuery({
    variables: {
      filter: {
        categoryId: categoryId || undefined,
        facets: selectedFacets.length === 0 ? undefined : [...selectedFacets],
        search,
      },
      ...paginationArgs,
    },
    skip: showProductsToggle || (!categoryId && !search),
    onCompleted: () => setSearchPerformed(true),
  });

  const {
    data: products,
    loading: productsLoading,
    error: masterProductsError,
  } = useMasterProductsQuery({
    variables: {
      filter: {
        categoryId: categoryId || undefined,
        facets: selectedFacets.length === 0 ? undefined : [...selectedFacets],
        search,
      },
      ...paginationArgs,
    },
    skip: !showProductsToggle || (!categoryId && !search),
    onCompleted: () => setSearchPerformed(true),
  });

  useEffect(() => {
    if (showProductsToggle && products?.masterProducts?.facets) {
      updateFacets(products?.masterProducts?.facets || []);
    } else if (skus?.masterSkus?.facets) {
      updateFacets(skus?.masterSkus?.facets || []);
    }
  }, [
    showProductsToggle,
    skus?.masterSkus?.facets,
    products?.masterProducts?.facets,
    updateFacets,
  ]);

  useEffect(() => {
    if (showProductsToggle) {
      if (products?.masterProducts?.pageInfo) {
        setPageInfo(products.masterProducts.pageInfo);
      }
    } else {
      if (skus?.masterSkus?.pageInfo) {
        setPageInfo(skus.masterSkus.pageInfo);
      }
    }
  }, [showProductsToggle, skus, products, setPageInfo]);
  useErrorEffect(error);
  useErrorEffect(masterProductsError);

  return (
    <ProviderContext.Provider
      value={{
        masterSkus: skus?.masterSkus?.edges?.map((edge) => edge.node) || [],
        masterProducts:
          products?.masterProducts?.edges?.map((edge) => edge.node) || [],
        setSearch,
        searchContext: showProductsToggle
          ? products?.masterProducts?.searchContext
          : skus?.masterSkus?.searchContext,
        skusLoading,
        productsLoading,
        totalCount:
          (showProductsToggle
            ? products?.masterProducts?.totalCount
            : skus?.masterSkus?.totalCount) || 0,
        searchPerformed,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useSkusAndProducts = (): ProviderContextType =>
  useContext(ProviderContext);

export const SkusAndProductsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <FacetsProvider>
    <SkusAndProductsProviderWithFacets>
      {children}
    </SkusAndProductsProviderWithFacets>
  </FacetsProvider>
);
