import Decimal from "decimal.js";
import { ExpandedReleaseItem } from "../../providers/ReleaseProvider";
import {
  ReleaseItemFilter,
  ReleaseItemsFilters,
} from "../../types/ReleaseItemsFilters";

const filterFullyReceived = (
  item: ExpandedReleaseItem,
  filter: ReleaseItemsFilters,
) => {
  switch (filter[ReleaseItemFilter.FULLY_RECEIVED]) {
    case false:
      return new Decimal(item.receivedQuantityDecimal || 0).lessThan(
        item.quantityDecimal,
      );
    case true:
      return new Decimal(
        item.receivedQuantityDecimal || 0,
      ).greaterThanOrEqualTo(item.quantityDecimal);
    default:
      return true;
  }
};

const filterFullyInvoiced = (
  item: ExpandedReleaseItem,
  filter: ReleaseItemsFilters,
) => {
  switch (filter[ReleaseItemFilter.FULLY_INVOICED]) {
    case false:
      return new Decimal(item.invoicedQuantity).lessThan(item.quantityDecimal);
    case true:
      return new Decimal(item.invoicedQuantity).greaterThanOrEqualTo(
        item.quantityDecimal,
      );
    default:
      return true;
  }
};

const filterTags = (item: ExpandedReleaseItem, filteredTags: string[]) =>
  filteredTags.length
    ? item.tags.some((tag) => filteredTags.includes(tag.id))
    : true;

export const filterReleaseItems = (
  items: ExpandedReleaseItem[],
  filter: ReleaseItemsFilters,
  filteredTags: string[],
) =>
  items
    .filter((item) => filterFullyInvoiced(item, filter))
    .filter((item) => filterFullyReceived(item, filter))
    .filter((item) => filterTags(item, filteredTags));
