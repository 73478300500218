import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { GraphDataType } from "../../../../../../common/components/charting/enums/GraphDataType";
import { PointDetails } from "./PointDetails";

type PointProps = {
  $inTheFuture: boolean;
};

const Point = tw.div<PointProps>`
  h-3 w-3 rounded-full bg-blue-500 cursor-pointer border-2 border-blue-300 group hover:bg-blue-300 transition-colors duration-200 ease-in-out
  ${(props) => (props.$inTheFuture ? "bg-gray-400 border-none hover:bg-gray-400" : "")}  
`;
const Details = tw.div`
  absolute text-sm text-gray-600 font-light whitespace-nowrap bg-white p-2 
  rounded-md transition-colors duration-200 ease-in-out hidden group-hover:block
  -translate-x-1/2 -translate-y-full
`;

type LineChartPointProps = {
  point: {
    value: number;
    position: number;
  };
  index: number;
  format: GraphDataType;
  mappedData: {
    value: number;
    position: number;
  }[];
  label: string;
};

export const LineChartPoint: FC<LineChartPointProps> = ({
  point,
  index,
  mappedData,
  label,
  format,
}) => {
  const diffValue = useMemo(
    () => (index > 0 ? point.value - mappedData[index - 1].value : 0),
    [index, point.value, mappedData],
  );
  return (
    <Point $inTheFuture={new Date(label) > new Date()}>
      <Details>
        <PointDetails
          value={point.value}
          label={label}
          diffValue={diffValue}
          format={format}
        />
      </Details>
    </Point>
  );
};
