import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useEstimatedPricesQuery } from "@/generated/graphql";
import { FC, createContext, useContext, useMemo } from "react";

type Price = {
  id: string;
  material: {
    id: string;
  };
  prices: {
    unitPrice?: string;
    manufacturerId?: string;
    uomId?: string;
  }[];
};

type ProviderContextType = {
  estimatedPrices: Price[];
};

const ProviderContext = createContext<ProviderContextType>({
  estimatedPrices: [],
});

type Props = {
  children: React.ReactNode;
  projectId: string | null | undefined;
};

export const EstimatedPricesProvider: FC<Props> = ({ children, projectId }) => {
  const { data, error } = useEstimatedPricesQuery({
    fetchPolicy: "cache-and-network",
    variables: { id: projectId ?? "" },
    skip: !projectId,
    notifyOnNetworkStatusChange: true,
  });

  useErrorEffect(error);

  const prices = useMemo(() => {
    if (!data?.project?.items) {
      return [];
    }

    return data.project.items.map((item) => {
      const estimatedItems = item.estimatedItems ?? [];
      return {
        id: item.id,
        material: item.material,
        prices: estimatedItems.map((ei) => ({
          unitPrice: ei.unitPrice ?? undefined,
          manufacturerId: ei.manufacturer?.id,
          uomId: ei.uom?.id,
        })),
      };
    });
  }, [data?.project?.items]);

  return (
    <ProviderContext.Provider
      value={{
        estimatedPrices: prices,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useEstimatedPrices = (): ProviderContextType =>
  useContext(ProviderContext);
