import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { useLoadingAction } from "@/common/hooks/useLoadingAction";
import { retriableFetch } from "@/common/utils/retriableFetch";
import { AssetType } from "@/generated/graphql";
import { DownloadOutlined } from "@mui/icons-material";
import { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { getFileName } from "../upload-asset/getFileName";

type Props = {
  type: AssetType | undefined | null;
  url: string | undefined | null;
};

const ButtonContent = tw.div`flex flex-row items-center py-0`;

export const AssetDownloadButton: FC<Props> = ({ type, url }) => {
  const { loading, asyncAction } = useLoadingAction();

  const download = useCallback(async () => {
    if (!type || !url) {
      return;
    }

    if (type === AssetType.Document) {
      window.open(url, "_blank");
    } else if (type === AssetType.Image) {
      const response = await retriableFetch(url);
      if (!response || !response.ok) {
        window.open(url, "_blank");
        return;
      }
      const blob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.download = getFileName(url);
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      setTimeout(() => {
        document.body.removeChild(downloadLink);
      }, 500);
    }
  }, [type, url]);

  return (
    <OutlinedButton
      $small
      className="h-6 pr-4"
      onClick={() => !loading && asyncAction(download)}
      loading={loading}
    >
      <ButtonContent>
        <DownloadOutlined className="text-sm" />
        <FormattedMessage id="DOWNLOAD" />
      </ButtonContent>
    </OutlinedButton>
  );
};
