import { gql } from "@apollo/client";
import { RELEASE_APPROVAL_FIELDS } from "./releaseApprovalFragment";

export const RELEASE_APPROVAL_SLOT_FIELDS = gql`
  ${RELEASE_APPROVAL_FIELDS}
  fragment ReleaseApprovalSlotFields on ReleaseApprovalSlot {
    role
    approvals {
      ...ReleaseApprovalFields
    }
    ineffectual
  }
`;
