import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { ProjectsFilterSelector } from "@/common/components/projects-filter-selector/ProjectsFilterSelector";
import { Select } from "@/common/components/select/components/single/Select";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { VendorPickerCustomRender } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import {
  ALL_VENDORS_LIST_ID,
  useVendorOptions,
} from "@/common/components/vendor-picker/useVendorOptions";
import { useReleasesSelectorData } from "@/common/hooks/userReleasesSelectorQuery";
import { useProjectListOptions } from "@/contractor/pages/home/projects/hooks/useProjectListOptions";
import {
  ReleaseSelectorFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ReleaseType } from "../../releases/pages/delivery-slips/providers/DeliverySlipVerificationProvider";
import { OrderOptionCustomRender } from "./OrderOptionCustomRender";

const Container = tw.div`w-full`;
const Selectors = tw.div`grid grid-cols-2 xl:grid-cols-[2fr_2fr_3fr_auto] gap-3 items-center w-full p-4 bg-blue-100 rounded-2xl drop-shadow-md`;
const PrimaryButtonStyled = tw(PrimaryButton)`w-32`;

type OrderSelectorFiltersProps = {
  initialReleaseId?: string | null;
  preselectedPoNumber?: string;
  onOrderChange?: (release: ReleaseType) => void;
  onOrderConfirmed?: () => Promise<boolean>;
  allowDraftOrders: boolean;
};

export const OrderSelectorFilters: FC<OrderSelectorFiltersProps> = ({
  initialReleaseId,
  preselectedPoNumber,
  onOrderChange,
  onOrderConfirmed,
  allowDraftOrders = false,
}) => {
  const intl = useIntl();
  const { projects } = useProjectListOptions();
  const vendorOptions = useVendorOptions();
  const { formatCurrency } = useFormatNumberToCurrency();
  const [searchStr, setSearch] = useState("");
  const { filter, setFilter, releases, loading } = useReleasesSelectorData({
    search: preselectedPoNumber,
    statuses: [
      ...(allowDraftOrders ? [ReleaseStatus.Draft] : []),
      ReleaseStatus.Scheduled,
      ReleaseStatus.Received,
      ReleaseStatus.PartiallyReceived,
      ReleaseStatus.Requested,
    ],
    closedProjects: false,
    deleted: false,
  });
  const [selectedRelease, setSelectedRelease] =
    useState<ReleaseSelectorFieldsFragment | null>(null);
  const [poNumber, setPoNumber] = useState(preselectedPoNumber);
  const [confirming, setConfirming] = useState(false);

  useEffect(() => {
    if (poNumber && releases.length > 0) {
      setPoNumber("");
      const release = releases.find((r) => r.poNumber === preselectedPoNumber);
      if (release) {
        setFilter({
          ...filter,
          projectIds: [release.project?.id ?? ""],
          sellerOrgLocationIds: release.sellerOrgLocation?.id
            ? [release.sellerOrgLocation.id]
            : [],
        });
      }
    }
  }, [filter, poNumber, preselectedPoNumber, releases, setFilter]);

  useEffect(() => {
    if (initialReleaseId && !selectedRelease) {
      const release = releases.find((r) => r.id === initialReleaseId);
      if (release) {
        setSelectedRelease(release);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialReleaseId]);

  useEffect(() => {
    if (selectedRelease) {
      setFilter({
        ...filter,
        projectIds: [selectedRelease.project?.id ?? ""],
        sellerOrgLocationIds: selectedRelease.sellerOrgLocation?.id
          ? [selectedRelease.sellerOrgLocation.id]
          : [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRelease]);

  const onVendorSelected = useCallback(
    (vendorId: string | null) => {
      setFilter({
        ...filter,
        sellerOrgLocationIds:
          vendorId && vendorId !== ALL_VENDORS_LIST_ID ? [vendorId] : undefined,
      });
    },
    [filter, setFilter],
  );

  const onProjectSelected = useCallback(
    (projectId: string | null) => {
      setFilter({
        ...filter,
        projectIds: projectId ? [projectId] : undefined,
      });
    },
    [filter, setFilter],
  );

  const formatOrderLabel = useCallback(
    (order: ReleaseSelectorFieldsFragment) =>
      `${order.sequenceNumber ? `#${order.sequenceNumber}` : ""} ${
        order.poNumber ? `(${order.poNumber}) ` : ""
      }${order.time ? intl.formatDate(order.time as number) : ""} - (${formatCurrency(order.total)})`,
    [formatCurrency, intl],
  );

  const handleInputChange = useCallback(
    (search: string) => {
      setSearch(search);
      if (!search.includes("#")) {
        setFilter({ ...filter, search });
      }
      if (!search) {
        setSelectedRelease(null);
      }
    },
    [filter, setFilter],
  );

  const handleOrderChange = useCallback(
    (value: string | null) => {
      const rel = releases?.find((d) => d.id === value) ?? null;
      setSelectedRelease(rel);
      onOrderChange?.(rel);
    },
    [onOrderChange, releases],
  );

  const sortedReleases = useMemo(
    () =>
      releases
        .filter((r) => r.status !== ReleaseStatus.Draft || r.poNumber)
        .toSorted((o1, o2) =>
          o1.sequenceNumber && o2.sequenceNumber
            ? o2.sequenceNumber - o1.sequenceNumber
            : 0,
        ),
    [releases],
  );

  const confirm = useCallback(async () => {
    setConfirming(true);
    if (onOrderConfirmed) {
      await onOrderConfirmed();
    }
    setConfirming(false);
    return true;
  }, [onOrderConfirmed]);

  return (
    <Container>
      <Selectors>
        <Select
          options={vendorOptions}
          label={intl.$t({ id: "VENDOR" })}
          value={filter?.sellerOrgLocationIds?.[0] || ALL_VENDORS_LIST_ID}
          onChange={onVendorSelected}
          customRender={VendorPickerCustomRender}
          getLabel={(option) => option.name}
          getValue={(option) => option.value as string}
        />
        <ProjectsFilterSelector
          projects={projects}
          label={intl.$t({ id: "PROJECT" })}
          onChange={onProjectSelected}
          value={filter?.projectIds?.[0]}
        />
        <Select
          options={sortedReleases}
          label={intl.$t({ id: "ORDER" })}
          value={selectedRelease?.id}
          blurOnSelect
          inputValue={
            searchStr ||
            (selectedRelease
              ? formatOrderLabel(
                  selectedRelease as ReleaseSelectorFieldsFragment,
                )
              : "")
          }
          handleInputChange={handleInputChange}
          onChange={handleOrderChange}
          customRender={(item) => OrderOptionCustomRender(item)}
          getLabel={formatOrderLabel}
          classes={{ option: "text-sm" }}
          getValue={(option) => option.id}
          loading={loading}
          className="flex min-w-[260px] flex-1"
        />
        <PrimaryButtonStyled loading={confirming} onClick={() => confirm()}>
          <FormattedMessage id="CONFIRM" />
        </PrimaryButtonStyled>
      </Selectors>
    </Container>
  );
};
