import { If } from "@/common/components/if/If";
import { useStepper } from "@/common/components/stepper/Stepper";
import { useIntegrationTypeConfig } from "@/common/hooks/integrations/integration-type-config/useIntegrationConfig";
import { useSourceSystems } from "@/common/hooks/integrations/source-system-config/useSourceSystems";
import { IntegrationConfig } from "@/common/hooks/integrations/types/IntegrationConfigType";
import { IntegrationType, SourceSystem } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useOrgSettingsExtended } from "../../../org-settings/hooks/useOrgSettingsExtended";
import { useAgaveConnectionProvider } from "../../providers/AgaveConnectionProvider";
import { Header, IntegrationTypeContainer } from "../Integrations.styles";
import { useAccountingOnPremiseWizard } from "../wizard/accounting-onpremise-wizard/AccountingOnPremiseWizardProvider";
import { IntegrationLogo } from "./components/IntegrationLogo";

type Props = {
  onClose: () => void;
  setSelectedConfig: (config: IntegrationConfig) => void;
};

const Container = tw.div`grid grid-flow-row gap-4`;
const Integrations = tw.div`grid grid-cols-2 gap-4`;
const Integration = tw.div`border-0.5 hover:shadow-md border-dashed border-gray-600 rounded-xl flex flex-col justify-center items-center text-gray-600 text-xs p-6 cursor-pointer bg-white`;
const Name = tw.div`flex items-center justify-center gap-2`;

export const SelectIntegrationForm: FC<Props> = ({
  onClose,
  setSelectedConfig,
}) => {
  const intl = useIntl();
  const { connect } = useAgaveConnectionProvider();
  const { integrations, connectedAccountingSystem } = useOrgSettingsExtended();
  const { openWizard } = useAccountingOnPremiseWizard();
  const { moveToNextStep } = useStepper();

  const hasAnyConnection = useMemo(
    () =>
      integrations?.sourceSystems.some((s) => s.connected) ||
      !!connectedAccountingSystem,
    [integrations?.sourceSystems, connectedAccountingSystem],
  );

  const hasFoundationOnPremise = useMemo(
    () => connectedAccountingSystem?.integration === IntegrationType.Foundation,
    [connectedAccountingSystem?.integration],
  );

  const hasFoundationHosted = useMemo(
    () =>
      integrations?.sourceSystems.some(
        (s) => s.system === SourceSystem.Foundation && s.connected,
      ),
    [integrations?.sourceSystems],
  );

  const { getIntegrationConfig: getConfig } = useIntegrationTypeConfig();
  const sageConfig = useMemo(
    () => getConfig(IntegrationType.Sage300),
    [getConfig],
  );
  const foundationConfig = useMemo(
    () => getConfig(IntegrationType.Foundation),
    [getConfig],
  );
  const { sourceSystems } = useSourceSystems();

  const items = useMemo(
    () => [
      ...(!hasAnyConnection
        ? [
            {
              logo: sageConfig.logo.normal,
              name: sageConfig.name,
              type: intl.$t({ id: "INTEGRATION_TYPE_ON_PREMISE" }),
              onClick: () => {
                openWizard({ integrationType: IntegrationType.Sage300 });
                onClose();
              },
            },
          ]
        : []),
      ...(!hasFoundationOnPremise
        ? [
            {
              logo: foundationConfig.logo.normal,
              name: foundationConfig.name,
              type: intl.$t({ id: "INTEGRATION_TYPE_ON_PREMISE" }),
              onClick: () => {
                openWizard({ integrationType: IntegrationType.Foundation });
                onClose();
              },
            },
          ]
        : []),
      ...sourceSystems
        .filter(
          (ss) =>
            !integrations?.sourceSystems.some(
              (s) => s.system === ss.sourceSystem.system && s.connected,
            ) &&
            (hasFoundationOnPremise
              ? ss.sourceSystem.system === SourceSystem.Foundation
              : true) &&
            (hasFoundationHosted
              ? ss.sourceSystem.system === SourceSystem.Foundation
              : true),
        )
        .filter((sourceSystem) => {
          return (
            !sourceSystem.config.variations ||
            sourceSystem.config.variations.find(
              (variation) =>
                variation.system === sourceSystem.sourceSystem.system,
            )?.topLevel
          );
        })
        .map((sourceSystem) => {
          return {
            logo: sourceSystem.config.logo.normal,
            name: sourceSystem.config.name,
            type: sourceSystem.config.description,
            onClick: async () => {
              if (sourceSystem.config.variations?.length) {
                setSelectedConfig(sourceSystem.config);
                moveToNextStep();
                return;
              }
              if (sourceSystem.sourceSystem.system) {
                await connect({ system: sourceSystem.sourceSystem.system });
              }
              onClose();
            },
          };
        }),
    ],
    [
      hasAnyConnection,
      sageConfig.logo.normal,
      sageConfig.name,
      intl,
      hasFoundationOnPremise,
      foundationConfig.logo.normal,
      foundationConfig.name,
      sourceSystems,
      openWizard,
      onClose,
      integrations?.sourceSystems,
      hasFoundationHosted,
      setSelectedConfig,
      moveToNextStep,
      connect,
    ],
  );

  return (
    <Container>
      <FormattedMessage id="SELECT_ACCOUNTING_SOFTWARE" tagName={Header} />
      <Integrations>
        {items.map((item, key) => (
          <Integration key={key} tabIndex={0} onClick={item.onClick}>
            <IntegrationLogo url={item.logo} className="min-h-18" />
            <Name>
              {item.name}
              <If isTrue={item.type}>
                <IntegrationTypeContainer $xs>
                  {item.type}
                </IntegrationTypeContainer>
              </If>
            </Name>
          </Integration>
        ))}
        <If isTrue={items.length === 0}>
          <FormattedMessage id="NO_INTEGRATIONS_AVAILABLE" />
        </If>
      </Integrations>
    </Container>
  );
};
