import { GridTable } from "@/common/components/grid-table/GridTable";
import { getExpandedItems } from "@/common/components/grid-table/utils/getExpandedItems";
import { If } from "@/common/components/if/If";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { RfqItemFieldsFragment } from "@/generated/graphql";
import { FC, PropsWithChildren, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useRfqGroupedItems } from "../hooks/useRfqGroupedItems";
import { useRfqItemsListConfiguration } from "./RfqItemsList.configuration";
import { RfqItemsSideActions } from "./bulk-actions/RfqItemsSideActions";
import { RfqProductMenu } from "./rfq-product-menu/RfqProductMenu";

type Props = PropsWithChildren<{
  readonly?: boolean;
  loading?: boolean;
}>;

const Container = tw.div``;

export const RfqItemsList: FC<Props> = ({ loading, readonly = false }) => {
  const { rfqGroupedItems, toggleCostCode } = useRfqGroupedItems();
  const { newRfqItem, isRfqWithErrors, expandedItems } = useRfqGroupedItems();
  const { configuration } = useRfqItemsListConfiguration(readonly);

  const isEmpty = useMemo(
    () => rfqGroupedItems?.length === 0 && !loading && !newRfqItem?.isAddMode,
    [rfqGroupedItems?.length, loading, newRfqItem?.isAddMode],
  );

  if (!rfqGroupedItems) {
    return null;
  }

  return (
    <Container>
      <ListRenderer>
        <GridTable<RfqItemFieldsFragment>
          configuration={{
            container: Container,
            columns: configuration,
            classNames: {
              header: "top-[48px]",
              category: "top-[36px] lg:top-[86px]",
              subCategory: "top-[36px] lg:top-[86px]",
            },
            toggle: {
              category: toggleCostCode,
            },
          }}
          expandedItems={(item) => getExpandedItems(item, expandedItems)}
          items={rfqGroupedItems}
          readonly={readonly}
          loading={loading}
          error={isRfqWithErrors}
        />
        <RfqItemsSideActions />
      </ListRenderer>
      <If isTrue={!readonly}>
        <RfqProductMenu isOpen={isEmpty} />
      </If>
    </Container>
  );
};
