import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CreateReservedReleaseInput,
  CreateStandaloneReleaseInput,
  useCreateReservedReleaseMutation,
  useCreateStandaloneReleaseMutation,
} from "@/generated/graphql";

export const useCreateRelease = () => {
  const [createStandalone, { loading: creatingStandalone }] =
    useCreateStandaloneReleaseMutation();
  const [createReserved, { loading: creatingReserved }] =
    useCreateReservedReleaseMutation();
  const { setError } = useGlobalError();

  const createStandaloneRelease = async (
    input: CreateStandaloneReleaseInput,
  ) => {
    try {
      const { data, errors } = await createStandalone({
        variables: {
          input,
        },
      });
      if (data?.createStandaloneRelease) {
        return data.createStandaloneRelease;
      }
      setError(errors);
      return undefined;
    } catch (errors) {
      setError(errors);
      return undefined;
    }
  };

  const createReservedRelease = async ({
    buyoutId,
    warehouseId,
    typeId,
  }: CreateReservedReleaseInput) => {
    try {
      const { data, errors } = await createReserved({
        variables: {
          input: {
            buyoutId,
            warehouseId,
            typeId,
          },
        },
      });
      if (data?.createReservedRelease) {
        return data.createReservedRelease;
      }
      setError(errors);
      return undefined;
    } catch (errors) {
      setError(errors);
      return undefined;
    }
  };

  return {
    createStandaloneRelease,
    createReservedRelease,
    creating: creatingStandalone || creatingReserved,
  };
};
