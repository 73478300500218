import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { FC, createContext, useContext, useState } from "react";

type ProviderContextType = {
  showProductsToggle: boolean;
  toggleShowProducts: () => void;
};

const ProviderContext = createContext<ProviderContextType>({
  showProductsToggle: true,
  toggleShowProducts: () => null,
});

export const MasterSkusProductsToggleProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [showProductsToggle, setShowProductsToggle] = useState<boolean>(true);
  const { setPage } = usePagination();

  const toggleShowProducts = () => {
    setShowProductsToggle((oldValue) => !oldValue);
    setPage({ page: 0 });
  };

  return (
    <ProviderContext.Provider
      value={{
        showProductsToggle,
        toggleShowProducts,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useMasterSkusProductsToggle = (): ProviderContextType =>
  useContext(ProviderContext);
