import { useSourceSystems } from "@/common/hooks/integrations/source-system-config/useSourceSystems";
import { AgaveExternalProjectProvider } from "@/contractor/pages/home/project/providers/AgaveExternalProjectProvider";
import { SourceSystemMappingFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import {
  ExternalProjectConnection,
  ProjectFormProjectConnection,
} from "./ProjectFormProjectConnection";
const Connections = tw.div`flex flex-row self-stretch h-32 border-b border-dashed border-black pb-8`;

type Props = {
  mappings: SourceSystemMappingFieldsFragment[];
};

const ProjectFormProjectConnectionsWithProviders: FC<Props> = ({
  mappings,
}) => {
  const { sourceSystems } = useSourceSystems({ connected: true });

  const integrations: ExternalProjectConnection[] = useMemo(
    () =>
      sourceSystems.map((ss) => {
        const mapping = mappings.find(
          (m) => m.sourceSystem === ss.sourceSystem.system,
        );
        return {
          logo: ss.config.logo.normal,
          name: ss.config.name,
          type: ss.config.description,
          sourceSystem: ss.sourceSystem.system,
          isConnected: !!ss?.sourceSystem.connected,
          isMapped: !!mapping,
          externalId: mapping?.externalId,
        };
      }),
    [sourceSystems, mappings],
  );

  return (
    <Connections>
      {integrations.map((item, key) => (
        <ProjectFormProjectConnection item={item} key={key} />
      ))}
    </Connections>
  );
};

export const ProjectFormProjectConnections: FC<Props> = (props) => (
  <AgaveExternalProjectProvider>
    <ProjectFormProjectConnectionsWithProviders {...props} />
  </AgaveExternalProjectProvider>
);
