import { ImageUploaderWithCropping } from "@/common/components/upload/ImageUploaderWithCropping";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useUser } from "@/common/providers/UserProvider";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { ProfileImage } from "./ProfileImage";

export const ProfileImageWithUploader = () => {
  const { updateUser } = useUser();
  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();

  const saveImage = useCallback(
    async (image: File | null) => {
      if (await updateUser({ photo: image })) {
        setSuccessAlert(intl.$t({ id: "UPLOAD_SUCCESS_PHOTO" }));
      }
    },
    [intl, setSuccessAlert, updateUser],
  );

  return (
    <ImageUploaderWithCropping
      save={saveImage}
      showCircleCrop
      forceSquare
      uploadArea={<ProfileImage removeImage={() => saveImage(null)} />}
    />
  );
};
