import { FormatCurrencyType } from "@/common/components/value-currency/ValueCurrency";
import { PDF_FONT } from "@/common/const";
import { DistributorBuyoutFieldsFragment } from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { IntlShape } from "react-intl";

export const termsAndConditions = (
  doc: jsPDF,
  buyout: Pick<
    DistributorBuyoutFieldsFragment,
    "notes" | "paymentTermDays" | "additionalCharges"
  >,
  intl: IntlShape,
  formatCurrency: FormatCurrencyType,
) => {
  if (
    buyout.paymentTermDays ||
    buyout.notes ||
    buyout.additionalCharges.length
  ) {
    const body: RowInput[] = [];
    if (buyout.paymentTermDays) {
      body.push([
        {
          content: intl.$t({ id: "PAYMENT_TERMS" }),
          styles: { fontStyle: "bold" },
        },
        {
          content: intl.$t(
            { id: "PAYMENT_TERM_NET_WITH_DAYS" },
            { days: buyout.paymentTermDays },
          ),
        },
      ]);
    }
    if (buyout.notes) {
      body.push([
        {
          content: intl.$t({
            id: "NOTES_AND_TERMS",
          }),
          styles: { fontStyle: "bold" },
        },
        { content: buyout.notes },
      ]);
    }
    buyout.additionalCharges.forEach((charge) => {
      body.push([
        {
          content: charge.description,
          styles: { fontStyle: "bold" },
        },
        {
          content: formatCurrency(charge.amount),
        },
      ]);
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const topSpacing = (doc as any).lastAutoTable.finalY + 10;
    autoTable(doc, {
      startY: topSpacing,
      theme: "plain",
      showHead: false,
      styles: {
        font: PDF_FONT,
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 50 },
      },
      body,
    });
  }
};
