import { ManufacturerModalPicker } from "@/common/components/manufacturer-modal-picker/ManufacturerModalPicker";
import { ManufacturerPicker } from "@/common/components/manufacturer-picker/ManufacturerPicker";
import { FC, useCallback } from "react";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../providers/DistributorQuoteItemsProvider";
import { useDistributorQuote } from "../providers/DistributorQuoteProvider";

type Props = {
  item: DistributorQuoteItemGroupExtendedFieldsFragment;
  error?: boolean;
  readonly?: boolean;
  index?: number;
};

export const DistributorQuoteManufacturerSelector: FC<Props> = ({
  item,
  error,
  readonly,
  index,
}) => {
  const { updateQuote } = useDistributorQuote();

  const saveManufacturer = useCallback(
    async (manufacturerId: string | null) => {
      const payload = item.quoteItems.map((qi) => ({
        quoteItemId: qi.id,
        manufacturerId,
      }));
      await updateQuote({
        updates: payload,
      });
    },
    [item, updateQuote],
  );

  if (item.quoteItems.length === 1) {
    return (
      <ManufacturerPicker
        item={item.quoteItems[0]?.manufacturer}
        saveManufacturer={saveManufacturer}
        readonly={readonly}
        error={error && !item.quoteItems[0]?.manufacturer}
        index={index}
      />
    );
  }

  if (readonly) {
    return null;
  }

  return (
    <ManufacturerModalPicker onChange={saveManufacturer} readonly={readonly} />
  );
};
