import { useCategories } from "@/common/components/category-filter/CategoriesProvider";
import { If } from "@/common/components/if/If";
import { ListEmptyState } from "@/common/components/list-empty-state/ListEmptyState";
import { Pagination } from "@/common/components/pagination/Pagination";
import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { CardLoader } from "@/common/components/skeleton-loader/CardLoader";
import { CatalogListItemCustomLabels } from "@/contractor/pages/home/rfq-master-catalog-import/components/CatalogListItem";
import {
  ProjectItemFieldsFragment,
  ReleaseItemFieldsFragment,
  RfqItemFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { ProductListItem } from "../../../contractor/pages/home/rfq-master-catalog-import/components/ProductListItem";
import { SkuListItem } from "../../../contractor/pages/home/rfq-master-catalog-import/components/SkuListItem";
import { NoResultsWithOptions } from "../no-results-with-options/NoResultsWithOptions";
import { useSkusAndProducts } from "../product-facets-filter/SkusAndProductsProvider";
import { useMasterSkusProductsToggle } from "../skus-products-switch/MasterSkusProductsToggleProvider";

type Props = {
  addToCart: (input: {
    masterSkuId?: string;
    masterProductId?: string;
    orgCatalogSkuId?: string;
    description?: string;
    estimateUom: string;
  }) => Promise<boolean>;
  findItem: (
    id: string,
  ) =>
    | { id: string }
    | RfqItemFieldsFragment
    | undefined
    | ProjectItemFieldsFragment
    | ReleaseItemFieldsFragment;
  removeFromCart: (itemId: string) => Promise<boolean>;
  customLabels?: CatalogListItemCustomLabels;
};

const ProductListContainer = tw.div`
  grid grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-4 w-full
`;

const ProductListOuterContainer = tw.div`
  flex flex-col flex-1 ml-5
`;

const PaginationContainer = tw.div`
  flex bottom-15 right-15 mt-5 justify-end w-full
`;

const NO_RESULTS_OPTIONS = [
  "NO_MASTER_SKU_RESULTS_SELECT_A_CATEGORY",
  "NO_MASTER_SKU_RESULTS_SEARCH_MATERIAL",
  "NO_MASTER_SKU_RESULTS_UPLOAD_MATERIAL_LIST",
];

export const ProductsList: FC<Props> = ({
  addToCart,
  findItem,
  removeFromCart,
  customLabels,
}) => {
  const {
    masterSkus,
    masterProducts,
    skusLoading,
    productsLoading,
    totalCount,
    searchPerformed,
  } = useSkusAndProducts();
  const { showProductsToggle } = useMasterSkusProductsToggle();
  const { categoryId } = useCategories();

  const { page, nextPage, previousPage, pageSize, pageInfo } = usePagination();
  const isEmpty = showProductsToggle
    ? !productsLoading && masterProducts.length === 0
    : !skusLoading && masterSkus.length === 0;

  return (
    <ProductListOuterContainer>
      <ProductListContainer>
        {skusLoading || productsLoading ? (
          <CardLoader pageSize={pageSize} />
        ) : showProductsToggle ? (
          <>
            {masterProducts.map((masterProduct) => (
              <ProductListItem
                data-testid="product"
                key={masterProduct.id}
                product={masterProduct}
                addToCart={(id: string, description: string) =>
                  addToCart({
                    masterProductId: id,
                    estimateUom: masterProduct.uom.pluralDescription,
                    description,
                  })
                }
                findItem={findItem}
                removeFromCart={removeFromCart}
                customLabels={customLabels}
              />
            ))}
          </>
        ) : (
          <>
            {masterSkus.map((masterSku) => (
              <SkuListItem
                data-testid="product"
                key={masterSku.id}
                sku={masterSku}
                addToCart={(id: string, description: string) =>
                  addToCart({
                    masterSkuId: id,
                    estimateUom: masterSku.product.uom.pluralDescription,
                    description,
                  })
                }
                findItem={findItem}
                removeFromCart={removeFromCart}
                customLabels={customLabels}
              />
            ))}
          </>
        )}
      </ProductListContainer>
      <PaginationContainer>
        <Pagination
          count={showProductsToggle ? masterProducts.length : masterSkus.length}
          total={totalCount}
          page={page}
          itemsPerPage={pageSize}
          nextPage={nextPage}
          previousPage={previousPage}
          hasNext={pageInfo?.hasNextPage || false}
          hasPrevious={pageInfo?.hasPreviousPage || false}
        />
      </PaginationContainer>
      <If isTrue={isEmpty}>
        {!searchPerformed && !categoryId ? (
          <NoResultsWithOptions options={NO_RESULTS_OPTIONS} />
        ) : (
          <>
            <ListEmptyState>
              <FormattedMessage id="CATALOG_NO_PRODUCTS" />
            </ListEmptyState>
          </>
        )}
      </If>
    </ProductListOuterContainer>
  );
};
