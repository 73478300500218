import { FC } from "react";
import tw from "tailwind-styled-components";
import { GraphDataType } from "../../../../../../common/components/charting/enums/GraphDataType";
import { useChartUtils } from "../../../../../../common/components/charting/hooks/useChartUtils";
import { If } from "../../../../../../common/components/if/If";

const Container = tw.div`grid`;
const Amount = tw.div`text-sm font-bold`;
const Diff = tw.span`text-xs text-green-800`;
const Label = tw.span`text-xs text-gray-500 mt-1`;

type Props = {
  value: number;
  format: GraphDataType;
  diffValue: number;
  label: string;
};

export const PointDetails: FC<Props> = ({
  value,
  format,
  diffValue,
  label,
}) => {
  const { getFormatted } = useChartUtils();
  return (
    <Container>
      <Amount>{getFormatted(value, format)}</Amount>
      <If isTrue={diffValue}>
        <Diff>{`(+${getFormatted(diffValue, format)})`}</Diff>
      </If>
      <Label>{label}</Label>
    </Container>
  );
};
