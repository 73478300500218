import { If } from "@/common/components/if/If";
import { ReleaseItemIssueDescription } from "@/common/components/issue-details/ReleaseItemIssueDescription";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import { DateView } from "@/common/utils/dates/DateView";
import { routes } from "@/config/routes";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AssetContext,
  AssetType,
  ReleaseFieldsFragment,
  ReleaseIssueResolutionType,
  ReleaseItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";

const OverlayPanelContainer = tw.div`
  grid gap-2
`;

const Container = tw.div`-mx-6`;
const Headline = tw.div`
  font-medium px-6
`;
const Details = tw.div`my-2 py-4 px-6 bg-gray-100 font-light`;
const Row = tw.div`flex flex-row justify-between w-full`;
const RowLabel = tw.div`grid grid-flow-col justify-start gap-2 font-medium mt-2`;
const RedeliveryContainer = tw.div`flex gap-2`;
const LinkLikeStyled = tw(LinkLike)`text-base`;

type IssueResolutionWithProviderProps = {
  onClose: () => void;
  item?: ExpandedReleaseItem;
};

const IssueResolutionWithProvider: FC<IssueResolutionWithProviderProps> = ({
  onClose,
  item,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const issue = useMemo(() => item?.issues?.[0], [item]);

  if (!issue) {
    return null;
  }

  return (
    <OverlayPanel
      title={intl.$t({ id: "DELIVERY_ISSUE_RESOLUTION" })}
      onCancel={onClose}
    >
      <OverlayPanelContainer>
        <Container>
          <Headline>
            <FormattedMessage id="ITEM_DELIVERED" />
          </Headline>
          <Details>
            <ReleaseItemIssueDescription
              item={item as ReleaseItemFieldsFragment}
            />
          </Details>
        </Container>
        <Container>
          <Headline>
            <FormattedMessage id="VENDOR_SOLUTION" />
          </Headline>
          <Details>
            <Row>
              <FormattedMessage id={issue.resolution?.resolutionType} />
              <If
                isTrue={
                  issue.resolution?.resolutionType ===
                  ReleaseIssueResolutionType.Redeliver
                }
              >
                <RedeliveryContainer>
                  <LinkLikeStyled
                    to={generatePath(routes.delivery, {
                      deliveryId:
                        issue.resolution?.redeliveryReleaseItem?.release.id ||
                        "",
                    })}
                  >
                    <FormattedMessage
                      id="RELEASE_NAME"
                      values={{
                        number:
                          issue.resolution?.redeliveryReleaseItem?.release
                            ?.sequenceNumber,
                      }}
                    />
                  </LinkLikeStyled>
                  {issue.resolution?.redeliveryReleaseItem?.release.time && (
                    <DateView
                      date={
                        issue.resolution?.redeliveryReleaseItem?.release.time
                      }
                      includeTime
                    />
                  )}
                </RedeliveryContainer>
              </If>
            </Row>
            <If
              isTrue={
                issue.resolution?.resolutionType ===
                  ReleaseIssueResolutionType.Redeliver &&
                issue.resolution?.redeliveryReleaseItem
                  ?.alternativeFulfillmentTime
              }
            >
              <FormattedMessage id="DELIVERY_DATE" tagName={RowLabel} />
              <Row>
                {
                  issue.resolution?.redeliveryReleaseItem
                    ?.alternativeFulfillmentTime
                }
              </Row>
              <RowLabel>
                <FormattedMessage id="DELIVERY_ISSUE_RESOLUTION_REDELIVERED" />
                <LinkLike
                  onClick={() =>
                    navigate(
                      generatePath(routes.delivery, {
                        deliveryId:
                          issue?.resolution?.redeliveryReleaseItem
                            ?.alternativeFulfillmentRelease?.id || "",
                      }),
                    )
                  }
                >
                  #
                  {
                    issue.resolution?.redeliveryReleaseItem
                      ?.alternativeFulfillmentRelease?.sequenceNumber
                  }
                </LinkLike>
              </RowLabel>
            </If>
            <If isTrue={issue.resolution?.comments}>
              <FormattedMessage id="VENDOR_COMMENT" tagName={RowLabel} />
              <Row>{issue.resolution?.comments}</Row>
            </If>
          </Details>
        </Container>
      </OverlayPanelContainer>
    </OverlayPanel>
  );
};

type Props = {
  item?: ExpandedReleaseItem;
  onClose: () => void;
  release?: ReleaseFieldsFragment | null;
};

export const IssueResolution: FC<Props> = ({ onClose, item }) => {
  const { release } = useRelease();

  const issueAssets = useMemo(() => {
    return item?.issues?.[0]?.photos ?? [];
  }, [item?.issues]);

  return (
    <UploadAssetProvider
      initialAssets={issueAssets}
      context={AssetContext.DeliveryIssue}
      projectId={release?.project?.id ?? ""}
      type={AssetType.Image}
    >
      <IssueResolutionWithProvider onClose={onClose} item={item} />
    </UploadAssetProvider>
  );
};
