import { If } from "@/common/components/if/If";
import {
  DuplicatedInvoiceInfo,
  isDuplicatedInvoice,
} from "@/common/components/invoices/common/DuplicatedInvoiceInfo";
import { InvoiceSummaryFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

type Props = {
  invoice: InvoiceSummaryFieldsFragment;
};

const Item = tw.div`flex-1 text-left text-sm 
${(props: { $error?: boolean }) => props.$error && "text-red-500"}`;
const Container = tw.div`flex items-center gap-1`;
const Description = tw.div`truncate text-[10px] font-normal max-w-20`;

export const InvoiceNumber: FC<Props> = ({ invoice }) => {
  return (
    <Container>
      <div>
        <Item $error={isDuplicatedInvoice(invoice)}>{invoice.number}</Item>

        <If isTrue={invoice?.description}>
          <Description>{invoice?.description}</Description>
        </If>
      </div>

      <DuplicatedInvoiceInfo invoice={invoice} />
    </Container>
  );
};
