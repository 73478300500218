import { If } from "@/common/components/if/If";
import { LinkChunks } from "@/common/components/link-chunks/LinkChunks";
import { TextField } from "@/common/components/textfield/TextField";
import { routes } from "@/config/routes";
import { AdminUsersInfo } from "@/contractor/pages/home/release/components/connections/components/common/AdminUsersInfo";
import { ConnectionError } from "@/contractor/pages/home/release/components/connections/components/common/ExportErrorList";
import { ReceiptToExportFieldsFragment } from "@/generated/graphql";
import { FC, ReactNode, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useExportReceipt } from "../../providers/ExportReceiptProvider";
import { ReceiptReleaseDetails } from "./ReceiptReleaseDetails";
const AdminUsersInfoStyled = tw(AdminUsersInfo)`text-white text-base`;

type Props = {
  receipt: ReceiptToExportFieldsFragment;
};

export const ReceiptExportInformation: FC<Props> = ({ receipt }) => {
  const intl = useIntl();
  const { updatedReceipts, updateReceipt } = useExportReceipt();

  const updatedReceipt = useMemo(
    () => updatedReceipts.find((r) => r.id === receipt.id),
    [receipt.id, updatedReceipts],
  );

  const hasVendorId = useMemo(
    () =>
      !!receipt.release?.preferredVendor?.externalCode ||
      updatedReceipt?.externalVendorCode,
    [
      receipt.release?.preferredVendor?.externalCode,
      updatedReceipt?.externalVendorCode,
    ],
  );
  const hasMissingItems = useMemo(
    () => receipt?.release?.hasMissingCostCodes,
    [receipt?.release?.hasMissingCostCodes],
  );

  const includedValidations: ConnectionError[] = useMemo(
    () =>
      [
        {
          id: "EXPORT_RELEASE_ERROR_MISSING_ITEMS",
          condition: hasMissingItems,
          key: "EXPORT_RELEASE_ERROR_MISSING_ITEMS",
          toolTipKey: "EXPORT_RELEASE_ERROR_MISSING_ITEMS_TOOLTIP",
          values: {
            releaseNumber: receipt.release?.sequenceNumber,
            sub: (chunks: ReactNode) => (
              <LinkChunks
                chunks={chunks}
                route={routes.delivery}
                routeParams={{ deliveryId: receipt.release?.id || "" }}
              />
            ),
          },
        },
        {
          id: "EXPORT_RELEASE_ERROR_MISSING_VENDOR_ID",
          key: "EXPORT_RELEASE_ERROR_MISSING_VENDOR_ID",
          condition: !hasVendorId,
          includeCondition:
            !receipt.release?.preferredVendor?.externalCode &&
            !!receipt.release?.preferredVendor?.deletedAt,
          toolTipKey: !receipt.release?.preferredVendor?.deletedAt
            ? "EXPORT_RELEASE_ERROR_MISSING_VENDOR_ID_TOOLTIP"
            : "EXPORT_RELEASE_ERROR_MISSING_DELETED_VENDOR_ID_TOOLTIP",
          adminToolTipKey: !receipt.release?.preferredVendor?.deletedAt
            ? "EXPORT_RELEASE_ERROR_MISSING_VENDOR_ID_TOOLTIP_ADMIN"
            : "EXPORT_RELEASE_ERROR_MISSING_DELETED_VENDOR_ID_TOOLTIP",
          values: {
            sub: (chunks: ReactNode) => (
              <LinkChunks
                chunks={chunks}
                route={routes.vendorsWithId}
                routeParams={{
                  vendorId: receipt.release?.preferredVendor?.id || "",
                }}
              />
            ),
            sub1: () => <AdminUsersInfoStyled />,
            org: receipt.release?.sellerOrgLocation?.org.name || "da",
          },
          child: (
            <If isTrue={receipt.release?.preferredVendor?.deletedAt}>
              <TextField
                placeholder={intl.$t({
                  id: "EXPORT_RELEASE_ERROR_MISSING_VENDOR_ID",
                })}
                className="mb-auto"
                value={updatedReceipt?.externalVendorCode}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  updateReceipt({
                    id: receipt.id,
                    externalVendorCode: event.target.value,
                  })
                }
                size="small"
                required
                shrink
              />
            </If>
          ),
        },
      ].filter((e) =>
        e.includeCondition !== undefined
          ? e.includeCondition || e.condition
          : e.condition,
      ),
    [
      hasMissingItems,
      hasVendorId,
      intl,
      receipt.id,
      receipt.release?.id,
      receipt.release?.preferredVendor?.deletedAt,
      receipt.release?.preferredVendor?.externalCode,
      receipt.release?.preferredVendor?.id,
      receipt.release?.sellerOrgLocation?.org.name,
      receipt.release?.sequenceNumber,
      updateReceipt,
      updatedReceipt?.externalVendorCode,
    ],
  );

  return (
    <ReceiptReleaseDetails
      receipt={receipt}
      includedValidations={includedValidations}
    />
  );
};
