import { useParams } from "react-router-dom";
import { ProjectContent } from "../../components/ProjectContent";
import { EstimatedItemsProvider } from "../../providers/EstimatedItemsProvider";
import { useProject } from "../../providers/ProjectProvider";

const ProjectEstimatedItemsWithProvider = () => {
  const { project, loading } = useProject();

  if (!project) {
    return null;
  }

  return <ProjectContent project={project} loading={loading} />;
};

export const ProjectEstimatedItems = () => {
  const { id } = useParams();

  return (
    <EstimatedItemsProvider projectId={id}>
      <ProjectEstimatedItemsWithProvider />
    </EstimatedItemsProvider>
  );
};
