import { ExternalAddress } from "@/generated/graphql";
import { Address } from "@/types/Address";
import { FC } from "react";

type AddressLocationOptions = {
  excludeAddressLines?: boolean | null;
  excludeCity?: boolean | null;
  excludeState?: boolean | null;
  excludePostalCode?: boolean | null;
  excludeCountry?: boolean | null;
};

type AddressLocationProps = {
  address: Address | null;
  options?: AddressLocationOptions;
};

export const addressToString = (
  value: Address | null | undefined | ExternalAddress,
  options?: AddressLocationOptions,
): string => {
  if (!value) {
    return "";
  }
  const values = [
    ...(options?.excludeAddressLines
      ? []
      : [value.addressLine1, value.addressLine2]),
    ...(options?.excludeCity ? [] : [value.city]),
    ...(options?.excludeState ? [] : [value.state]),
  ];
  return values
    .filter((item) => !!item)
    .join(", ")
    .concat(
      value.postalCode && !options?.excludePostalCode
        ? ` ${value.postalCode}`
        : "",
    )
    .concat(
      value.country && !options?.excludeCountry ? `, ${value.country}` : "",
    );
};

export const AddressLocation: FC<AddressLocationProps> = ({
  address,
  options,
}) => {
  return <>{addressToString(address, options)}</>;
};
