import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useIsBudgetZoneActive } from "@/contractor/pages/home/project/hooks/useIsBudgetZoneActive";
import { useProject } from "@/contractor/pages/home/project/providers/ProjectProvider";
import Decimal from "decimal.js";
import { groupBy } from "lodash";
import { useCallback } from "react";
import { NewCostCodeAllowance, NewTagAllowance } from "./types";

export const useBudgetFormHelpers = () => {
  const { project } = useProject();
  const { costCodes: costCodesFullList } = useCostCodes();
  const { isZoneSpecificFn } = useIsBudgetZoneActive();

  const getUniqueCostCodes = useCallback(
    (costCodes: NewCostCodeAllowance[] = []) => {
      return Array.from(new Set(costCodes.map(({ costCode: { id } }) => id)))
        .map((id) => {
          const originalCC = costCodes.find(
            ({ costCode }) => costCode.id === id,
          );

          return {
            id,
            description: originalCC?.costCode?.description?.toLowerCase() || "",
          };
        })
        .toSorted((a, b) => a.description.localeCompare(b.description));
    },
    [],
  );

  const convertCostCodeAllowancesToZoneAllowances = useCallback(
    (costCodes: NewCostCodeAllowance[] = []) => {
      const newCostCodes: NewCostCodeAllowance[] = [];

      costCodes.forEach(({ costCode }) => {
        project?.zones.forEach((zone) => {
          newCostCodes.push({
            amount: "0",
            costCode: {
              id: costCode.id,
              description: costCode.description,
            },
            zone: {
              id: zone.id,
              name: zone.name,
            },
          });
        });
      });

      return newCostCodes;
    },
    [project?.zones],
  );

  const convertZoneAllowancesToCostCodeAllowances = useCallback(
    (costCodes: NewCostCodeAllowance[] = []) => {
      return costCodes.map(({ costCode }) => {
        const { id: costCodeId, description } =
          costCodesFullList.find(({ id }) => id === costCode.id) || {};

        return {
          amount: "0",
          costCode: {
            id: costCodeId ?? "",
            description: description ?? "",
          },
        };
      });
    },
    [costCodesFullList],
  );

  const prefillTagAllowances = useCallback(
    (tagAllowances: NewTagAllowance[] = []) => {
      if (!project?.tags) {
        return tagAllowances;
      }

      const newTagAllowances: NewTagAllowance[] = [...tagAllowances];
      project.tags.forEach((t) => {
        const existentTagAllowance = tagAllowances.find(
          ({ tag }) => tag.id === t.id,
        );

        if (!existentTagAllowance) {
          newTagAllowances.push({
            amount: "0",
            tag: t,
          });
        }
      });

      return newTagAllowances;
    },
    [project?.tags],
  );

  const prefillCostCodeAllowances = useCallback(
    (costCodeAllowances: NewCostCodeAllowance[] = []) => {
      const isZoneSpecific = isZoneSpecificFn(costCodeAllowances);
      const newCostCodeAllowances = [...costCodeAllowances];

      project?.estimatedItemGroups.forEach((estimatedItemGroup) => {
        if (!estimatedItemGroup.costCode) {
          return;
        }

        const existingCCAllowance = newCostCodeAllowances.find(
          ({ costCode: { id } }) => {
            return id === estimatedItemGroup.costCode?.id;
          },
        );

        if (!existingCCAllowance) {
          if (isZoneSpecific && project?.zones) {
            project?.zones.forEach((zone) => {
              newCostCodeAllowances.push({
                amount: "0",
                costCode: {
                  id: estimatedItemGroup.costCode?.id ?? "",
                  description: estimatedItemGroup.costCode?.description ?? "",
                },
                zone,
              });
            });
          } else {
            newCostCodeAllowances.push({
              amount: "0",
              costCode: {
                id: estimatedItemGroup.costCode?.id ?? "",
                description: estimatedItemGroup.costCode?.description ?? "",
              },
            });
          }
        }
      });

      if (isZoneSpecific) {
        project?.zones
          ?.filter(
            (zone) =>
              !newCostCodeAllowances.some((el) => el.zone?.id === zone.id),
          )
          .forEach((zone) => {
            getUniqueCostCodes(newCostCodeAllowances).forEach(
              ({ id, description }) => {
                newCostCodeAllowances.push({
                  amount: "0",
                  costCode: {
                    id,
                    description: description ?? "",
                  },
                  zone,
                });
              },
            );
          });
      }

      return {
        costCodeAllowances,
      };
    },
    [
      getUniqueCostCodes,
      isZoneSpecificFn,
      project?.estimatedItemGroups,
      project?.zones,
    ],
  );

  const getAllowancesByZone = useCallback(
    (costCodes: NewCostCodeAllowance[] = []) => {
      const isZoneSpecific = isZoneSpecificFn(costCodes);
      return isZoneSpecific
        ? groupBy(
            costCodes.map((costCode, index) => ({ ...costCode, index })) ?? [],
            "zone.id",
          )
        : {};
    },
    [isZoneSpecificFn],
  );

  const getCostCodesGeneralAllowances = useCallback(
    (costCodes: NewCostCodeAllowance[] = []) => {
      const isZoneSpecific = isZoneSpecificFn(costCodes);
      return isZoneSpecific
        ? Object.entries(groupBy(costCodes, "costCode.id")).map(
            ([costCodeId, costCodeList]) => ({
              costCode: {
                id: costCodeId,
              },
              amount: costCodeList
                .reduce(
                  (acc, { amount }) => acc.add(amount || 0),
                  new Decimal(0),
                )
                .toString(),
            }),
          )
        : costCodes.map(({ amount, costCode: { id, description } }) => ({
            costCode: {
              id,
              description,
            },
            amount,
          }));
    },
    [isZoneSpecificFn],
  );

  return {
    getUniqueCostCodes,
    prefillTagAllowances,
    prefillCostCodeAllowances,
    getAllowancesByZone,
    getCostCodesGeneralAllowances,
    convertZoneAllowancesToCostCodeAllowances,
    convertCostCodeAllowancesToZoneAllowances,
  };
};
