import tw from "tailwind-styled-components";
import { TAG_COLORS } from "./TagColors";

const Container = tw.div`
    flex flex-row flex-wrap justify-start
`;

const ColorContainer = tw.div`
    border-2 p-1 rounded-md
    ${(props: { $isSelected: boolean }) =>
      props.$isSelected ? "border-blue-100" : "border-white"}
`;

const Color = tw.div`
    w-6 h-6 rounded-md
`;

type Props = {
  selectedColor: string;
  setSelectedColor: (color: string) => void;
};

export const TagColorPicker = ({ selectedColor, setSelectedColor }: Props) => {
  return (
    <Container>
      {TAG_COLORS.map((color, key) => (
        <ColorContainer key={key} $isSelected={selectedColor === color}>
          <Color
            style={{ backgroundColor: color }}
            onClick={() => setSelectedColor(color)}
          />
        </ColorContainer>
      ))}
    </Container>
  );
};
