import { DeleteButton } from "@/common/components/button/DeleteButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { DeliverySlipPartialFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useDeliverySlips } from "../../providers/DeliverySlipsProvider";

const IconContainer = tw.div`w-fit h-fit`;
const DeleteButtonStyled = tw(DeleteButton)`h-auto p-0`;
const ButtonsContainerStyled = tw(ButtonsContainer)`gap-1.5`;

export const DeliverySlipActions: FC<{
  item: DeliverySlipPartialFieldsFragment;
}> = ({ item }) => {
  const { openDialog } = useDialog();
  const { archiveDeliverySlip } = useDeliverySlips();
  const { setSuccessAlert } = useSnackbar();
  const intl = useIntl();

  const handleArchiveRelease = useCallback(async () => {
    if (await archiveDeliverySlip(item?.id)) {
      setSuccessAlert(intl.$t({ id: "DELIVERY_SLIP_ARCHIVED" }));
    }
  }, [archiveDeliverySlip, item?.id, setSuccessAlert, intl]);

  return (
    <ButtonsContainerStyled>
      <IconContainer>
        <DeleteButtonStyled
          onClick={() =>
            openDialog({
              cancelButtonText: intl.$t({ id: "CANCEL" }),
              confirmButtonText: intl.$t({ id: "PROCEED" }),
              icon: <WarningIcon />,
              title: intl.$t({ id: "ARCHIVE_DELIVERY_SLIP_DETAILS" }),
              handleConfirm: handleArchiveRelease,
            })
          }
        />
      </IconContainer>
    </ButtonsContainerStyled>
  );
};
