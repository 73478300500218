import { GroupBySegment } from "@/common/components/group-by-segment/GroupBySegment";
import { useBuyoutGrouped } from "@/contractor/pages/home/buyout/components/non-quoted/providers/BuyoutGroupedProvider";
import { FC } from "react";

export const GroupStandaloneBuyoutItemsByCostCodeToggle: FC = () => {
  const {
    groupedStandaloneItemsByCostCode,
    setGroupedStandaloneItemsByCostCode,
  } = useBuyoutGrouped();
  return (
    <GroupBySegment
      groupedByCostCode={groupedStandaloneItemsByCostCode}
      setGroupedByCostCode={setGroupedStandaloneItemsByCostCode}
    />
  );
};
