import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { FC, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div``;
const Label = tw.span`font-extralight text-gray-600`;
const Value = tw.span`font-medium`;
const Details = tw.div`flex flex-row font-normal text-xs text-gray-600 mt-1`;
const Error = tw.span`text-red-500 font-medium`;

type Props = {
  label?: string;
  value: string | null | ReactNode;
  details?: string;
  emptyCondition?: () => boolean;
  hidden?: boolean;
};

export const ItemValue: FC<Props> = ({
  label,
  value,
  emptyCondition,
  hidden,
  details,
}) => {
  return (
    <If isTrue={!hidden}>
      <Container>
        <If isTrue={label}>
          <Label>{label}: </Label>
        </If>
        <NotNullableRenderer
          value={emptyCondition ? !emptyCondition() : value}
          defaultValue={<FormattedMessage id="SPECIFY" tagName={Error} />}
        >
          <Value>{value}</Value>
          <If isTrue={details}>
            <Details>{details}</Details>
          </If>
        </NotNullableRenderer>
      </Container>
    </If>
  );
};
