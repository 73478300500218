import { CategoryState } from "@/common/hooks/useToggleCategory";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { Identity } from "@/types/Identity";

export const addItemToUnspecifiedCostCode = <T extends Identity>(
  items: CategoryState<T>[],
  item: T,
) => {
  const newItems = !items.some((c) => c.id === UNSPECIFIED_COST_CODE_ID)
    ? [item]
    : [
        ...(items.find((c) => c.id === UNSPECIFIED_COST_CODE_ID)?.items || []),
        item,
      ];

  return [
    ...items.filter((c) => c.id !== UNSPECIFIED_COST_CODE_ID),
    {
      id: UNSPECIFIED_COST_CODE_ID,
      name: "",
      isOpened: true,
      items: newItems,
    },
  ];
};
