import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { WarehousesProvider } from "@/contractor/pages/admin/warehouse/providers/WarehousesProvider";
import { Outlet } from "react-router-dom";
import { DeliverySlipImportExternalPOProvider } from "../../providers/DeliverySlipImportExternalPOProvider";
import { DeliverySlipVerificationProvider } from "../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipVerificationForm } from "./components/delivery-slip-form/DeliverySlipVerificationForm";

export const DeliverySlipVerification = () => {
  return (
    <NestedStepperProvider>
      <WarehousesProvider>
        <DeliverySlipVerificationForm>
          <DeliverySlipVerificationProvider>
            <DeliverySlipImportExternalPOProvider>
              <Outlet />
            </DeliverySlipImportExternalPOProvider>
          </DeliverySlipVerificationProvider>
        </DeliverySlipVerificationForm>
      </WarehousesProvider>
    </NestedStepperProvider>
  );
};
