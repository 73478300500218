import {
  RendererFunctionType,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";

import { textRenderer } from "handsontable/renderers";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { useFormatNumberToCurrency } from "../../value-currency/hooks/useFormatNumberToCurrency";
import { useRenderHelpers } from "./useRenderHelpers";

export const useExtPriceRenderer = () => {
  const intl = useIntl();
  const { formatCurrency } = useFormatNumberToCurrency();
  const { getMetadata } = useColumnMapper();
  const { applyTooltip, applyClasses, checkReadOnly } = useRenderHelpers();

  const isNotEmpty = useCallback((value: string) => {
    return value !== "" && value !== null && value !== undefined;
  }, []);

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      const formattedValue = isNotEmpty(value) ? formatCurrency(value) : "";
      textRenderer(
        instance,
        td,
        row,
        col,
        prop,
        formattedValue,
        cellProperties,
      );
      const meta = getMetadata(row);
      if (
        meta?.extPrice &&
        value &&
        Number(value).toFixed(2) === Number(meta.extPrice).toFixed(2)
      ) {
        applyClasses(td, "bg-green-100 text-green-800");
        applyTooltip(
          td,
          intl.formatMessage(
            { id: "COPIED_PRICE_TOOLTIP" },
            { price: formattedValue },
          ),
        );
      }
      checkReadOnly(td, row, instance, cellProperties);
    },
    [
      applyClasses,
      applyTooltip,
      checkReadOnly,
      formatCurrency,
      getMetadata,
      intl,
      isNotEmpty,
    ],
  );

  return renderer;
};
