import { LoadingButton } from "@/common/components/button/LoadingButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { If } from "@/common/components/if/If";
import { StaticDatePicker } from "@/common/components/picker/components/StaticDatePicker";
import { TimePicker } from "@/common/components/picker/components/TimePicker";
import { areDatesEqual } from "@/common/components/picker/utils/areDatesEqual";
import { isValidDate } from "@/common/components/picker/utils/isValidDate";
import { Popover } from "@/common/components/popover/Popover";
import { QuantityPicker } from "@/common/components/quantity-picker/QuantityPicker";
import { ReleaseStatusChip } from "@/common/components/statuses/ReleaseStatusChip";
import { InnerLabeledSwitch } from "@/common/components/switch/InnerLabeledSwitch";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { MAX_QUANTITY_DECIMALS } from "@/common/const";
import { DateView } from "@/common/utils/dates/DateView";
import { defaultReleaseDate } from "@/common/utils/dates/defaultReleaseDate";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import {
  AlternativeFulfillmentInput,
  DistributorReleaseFieldsFragment,
  DistributorReleaseItemFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import Decimal from "decimal.js";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { getReleaseTime } from "../utils/getReleaseTime";

const SET_NEW_FULFILLMENT_TIME_ID = "set-new-fulfillment-time-id";

const Container = tw.div`flex flex-col justify-between gap-4 w-96 ${({
  $className,
}: {
  $className?: string;
}) => $className}`;
const Content = tw.div`flex gap-4 flex-col`;
const CustomPickersDay = tw(
  PickersDay as React.ComponentType<PickersDayProps<Date>>,
)` border-solid border-4 
  ${({ $selected }: { $selected?: boolean }) =>
    $selected && "bg-blue-500 text-white rounded-full hover"}`;
const PickersDayStyled = tw(
  PickersDay as React.ComponentType<PickersDayProps<Date>>,
)`
  ${({ $selected }: { $selected?: boolean }) => $selected && "bg-blue-800"}
`;
const TimePickerContainer = tw.div`flex flex-col items-left justify-between w-full text-sm gap-2`;
const TimePickerHeader = tw.div`font-bold`;
const NewTimeContainer = tw.div`flex flex-row justify-evenly items-center gap-4`;
const ExistingRelease = tw.div`flex items-start justify-evenly text-sm`;
const Label = tw.div`text-sm font-normal`;
const Item = tw.div`last:absolute right-0`;
const ButtonContainer = tw.div`flex flex-row justify-around w-full gap-2`;
const OutlinedButtonStyled = tw(OutlinedButton)`w-full`;
const PrimaryButtonStyled = tw(PrimaryButton)`w-full`;
const StaticDatePickerStyled = tw(StaticDatePicker)`border-dashed border-b`;
const FormControlLabelStyled = tw(FormControlLabel)`pb-2 mr-0 relative`;
const BackorderQuantityContainer = tw.div`flex flex-col bg-gray-100 rounded text-sm gap-4 p-5 text-blue-800`;
const BackorderQuantityItem = tw.div`flex flex-row w-full justify-between items-center `;
const SwitchContainer = tw.div`flex flex-row justify-evenly gap-3`;
const QuantityPickerStyled = tw(QuantityPicker)`bg-white`;

type Props = {
  item?: DistributorReleaseItemFieldsFragment;
  release: DistributorReleaseFieldsFragment | undefined | null;
  onSave: (time: AlternativeFulfillmentInput) => Promise<void>;
  onChange?: (time: AlternativeFulfillmentInput) => void;
  className?: string;
  withoutBottomButtons?: boolean;
  selectedFulfillmentAlternative?: AlternativeFulfillmentInput | null;
  includeReleaseOptions?: boolean;
  useReleaseTimeAsDefault?: boolean;
  useCurrentDayAsDefault?: boolean;
  includeBackorderQuantity?: boolean;
  labels?: {
    newInput?: string;
    timeHeader?: string;
  };
};

export const DistributorReleaseDatePicker: FC<Props> = ({
  item,
  release,
  onSave,
  onChange,
  className,
  withoutBottomButtons = false,
  selectedFulfillmentAlternative = null,
  includeReleaseOptions = true,
  useReleaseTimeAsDefault = true,
  useCurrentDayAsDefault = true,
  includeBackorderQuantity = true,
  labels = {
    newInput: "SET_NEW_FULFILLMENT_TIME",
    timeHeader: "SET_FULFILLMENT_TIME_HEADER",
  },
}) => {
  const initialDate = useMemo(() => {
    const alternativeRelease = release?.project?.releases.find(
      (r) => r.id === selectedFulfillmentAlternative?.releaseId,
    );
    if (selectedFulfillmentAlternative?.time) {
      return new Date(selectedFulfillmentAlternative.time);
    } else if (alternativeRelease) {
      return new Date(alternativeRelease?.time || 0);
    } else if (useReleaseTimeAsDefault && item?.alternativeFulfillmentTime) {
      return new Date(item.alternativeFulfillmentTime);
    } else if (useReleaseTimeAsDefault && release?.time) {
      return new Date(release.time);
    } else if (useCurrentDayAsDefault) {
      return defaultReleaseDate();
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [date, setDate] = useState<Date | null>(initialDate);
  const [alternativeFulfillmentQuantity, setAlternativeFulfillmentQuantity] =
    useState<string | null>(
      item?.alternativeFulfillmentQuantityDecimal || null,
    );
  const [backorderEntireQuantity, setBackorderEntireQuantity] =
    useState<boolean>(!item?.alternativeFulfillmentQuantityDecimal);
  const [releaseIdOption, setReleaseIdOption] = useState<string | undefined>(
    item?.alternativeFulfillmentRelease?.id ||
      selectedFulfillmentAlternative?.releaseId?.toString() ||
      SET_NEW_FULFILLMENT_TIME_ID,
  );

  const intl = useIntl();
  const { cancelDialog } = useDialog();
  const handleChange = useCallback(
    ({
      date = null,
      releaseId,
    }: {
      date?: Date | null;
      releaseId?: string;
    }) => {
      setDate(date);
      setReleaseIdOption(releaseId || SET_NEW_FULFILLMENT_TIME_ID);
      onChange?.({ time: date?.getTime(), releaseId });
    },
    [setDate, onChange, setReleaseIdOption],
  );

  const getReleases = useCallback(() => {
    return release?.buyout
      ? release.buyout.releases.map((rel) => ({
          ...rel,
          buyout: {
            id: release.buyout?.id,
            clientIdentifier: release.buyout?.clientIdentifier,
          },
        }))
      : release?.project?.releases.map((rel) => ({
          ...rel,
          buyout: {
            id: rel.buyout?.id,
            clientIdentifier: rel.buyout?.clientIdentifier,
          },
        }));
  }, [release?.buyout, release?.project?.releases]);

  const onConfirm = useCallback(async () => {
    const getDate = (inputDate: Date) => {
      const newDate = new Date(inputDate);
      newDate.setSeconds(0);
      newDate.setMilliseconds(0);
      return newDate.getTime();
    };
    const time = {
      releaseId:
        releaseIdOption === SET_NEW_FULFILLMENT_TIME_ID
          ? undefined
          : releaseIdOption,
      time:
        releaseIdOption === SET_NEW_FULFILLMENT_TIME_ID && date
          ? getDate(date)
          : undefined,
      quantityDecimal: alternativeFulfillmentQuantity,
    };
    await onSave(time);
    cancelDialog?.();
  }, [
    releaseIdOption,
    date,
    alternativeFulfillmentQuantity,
    onSave,
    cancelDialog,
  ]);

  const renderDay = useCallback(
    ({ day, ...other }: PickersDayProps<Date>) => {
      if (
        !includeReleaseOptions ||
        !getReleases()?.find(
          (r) =>
            r.id !== release?.id &&
            checkReleaseStatus(r, [
              ReleaseStatus.Scheduled,
              ReleaseStatus.Requested,
            ]) &&
            areDatesEqual(getReleaseTime(r), day) &&
            (!date || !areDatesEqual(getReleaseTime(r), date)),
        )
      ) {
        return <PickersDayStyled day={day} {...other} />;
      }
      const key = `day-${day.getDay()}`;
      return (
        <Popover
          id={key}
          key={key}
          element={<CustomPickersDay day={day} {...other} $selected={true} />}
        >
          <FormattedMessage id="RELEASE_ADD_TO_EXISTING_DATE" />
        </Popover>
      );
    },
    [date, getReleases, includeReleaseOptions, release?.id],
  );

  const releaseOptions = useMemo(() => {
    return includeReleaseOptions
      ? getReleases()?.filter(
          (r) =>
            release?.id !== r.id &&
            checkReleaseStatus(r, [
              ReleaseStatus.Scheduled,
              ReleaseStatus.Requested,
            ]) &&
            ((!date && releaseIdOption === r.id) ||
              (date && areDatesEqual(getReleaseTime(r), date))),
        )
      : [];
  }, [includeReleaseOptions, getReleases, release?.id, date, releaseIdOption]);

  const setIsBackorderedEntireQuantity = useCallback(
    (value: boolean) => {
      setBackorderEntireQuantity(value);
      if (value) {
        setAlternativeFulfillmentQuantity(null);
      }
    },
    [setBackorderEntireQuantity, setAlternativeFulfillmentQuantity],
  );

  return (
    <Container $className={className}>
      <Content>
        <StaticDatePickerStyled
          value={date}
          onChange={(date?: Date | null) => handleChange({ date })}
          slots={{
            day: renderDay,
          }}
        />
        <If isTrue={!releaseOptions?.length && date}>
          <TimePickerContainer>
            <TimePicker
              label={intl.$t({ id: labels.newInput })}
              value={date}
              onChange={(date) => handleChange({ date })}
            />
          </TimePickerContainer>
        </If>
        <If isTrue={releaseOptions && releaseOptions.length > 0}>
          <TimePickerContainer>
            <TimePickerHeader>
              <FormattedMessage id={labels.timeHeader} />
            </TimePickerHeader>
            <RadioGroup
              aria-labelledby="distributor-release-date-picker-radio-buttons-group"
              name="distributor-release-date-picker-radio-buttons-group"
              value={releaseIdOption}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange({
                  releaseId: (event.target as HTMLInputElement).value,
                  date,
                })
              }
            >
              <FormControlLabelStyled
                key={0}
                value={SET_NEW_FULFILLMENT_TIME_ID}
                control={<Radio />}
                label={
                  <NewTimeContainer>
                    <Label>
                      <FormattedMessage id={labels.newInput} />
                    </Label>
                    <If
                      isTrue={releaseIdOption === SET_NEW_FULFILLMENT_TIME_ID}
                    >
                      <TimePicker
                        label={intl.$t({ id: "SET" })}
                        value={date}
                        onChange={(newDate) =>
                          handleChange({
                            date: isValidDate(newDate) ? newDate : date,
                            releaseId: SET_NEW_FULFILLMENT_TIME_ID,
                          })
                        }
                      />
                    </If>
                  </NewTimeContainer>
                }
              />
              {releaseOptions?.map((r) => (
                <FormControlLabelStyled
                  key={r.id}
                  value={r.id}
                  control={<Radio />}
                  labelPlacement="end"
                  label={
                    <ExistingRelease>
                      <Item>
                        <Label>
                          <DateView date={getReleaseTime(r)} includeTime />
                        </Label>
                        <Label>
                          <If isTrue={r.buyout?.id}>
                            <FormattedMessage
                              id="BUYOUT"
                              values={{
                                number: r.buyout?.clientIdentifier,
                              }}
                            />{" "}
                            -{" "}
                          </If>
                          <FormattedMessage
                            id="DELIVERY_WITH_NUMBER"
                            values={{ number: r.sequenceNumber }}
                          />
                        </Label>
                      </Item>
                      <Item>
                        <Label>
                          <ReleaseStatusChip
                            key="status"
                            status={r.status}
                            type="breadcrumb"
                            releaseType={undefined}
                          />
                        </Label>
                      </Item>
                    </ExistingRelease>
                  }
                />
              ))}
            </RadioGroup>
          </TimePickerContainer>
        </If>
        <If isTrue={includeBackorderQuantity}>
          <BackorderQuantityContainer>
            <BackorderQuantityItem>
              <FormattedMessage id="BACKORDER_ENTIRE_QUANTITY" />
              <SwitchContainer>
                <InnerLabeledSwitch
                  toggle={(value) => setIsBackorderedEntireQuantity(value)}
                  width={60}
                  initialValue={backorderEntireQuantity}
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                />
                <Tooltip
                  id="backorder-quantity-tooltip"
                  element={<InfoOutlined />}
                >
                  <FormattedMessage id="BACKORDER_ENTIRE_QUANTITY_TOOLTIP" />
                </Tooltip>
              </SwitchContainer>
            </BackorderQuantityItem>
            <If isTrue={!backorderEntireQuantity}>
              <BackorderQuantityItem>
                <FormattedMessage id="BACKORDERED_QUANTITY" />
                <QuantityPickerStyled
                  id="backorder-quantity-picker"
                  quantity={alternativeFulfillmentQuantity ?? "0"}
                  saveQuantity={setAlternativeFulfillmentQuantity}
                  label={
                    item?.quantityDecimal
                      ? intl.$t(
                          {
                            id: "ORDERED_QUANTITY_VALUE",
                          },
                          {
                            quantity: intl.formatNumber(
                              Number(item?.quantityDecimal) || 0,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: MAX_QUANTITY_DECIMALS,
                              },
                            ),
                          },
                        )
                      : undefined
                  }
                  hoverLabel={item?.uom?.pluralDescription}
                />
              </BackorderQuantityItem>
            </If>
          </BackorderQuantityContainer>
        </If>
      </Content>
      <If isTrue={!withoutBottomButtons}>
        <ButtonContainer>
          <OutlinedButtonStyled wide onClick={cancelDialog}>
            <FormattedMessage id="CANCEL" />
          </OutlinedButtonStyled>
          <LoadingButton
            button={PrimaryButtonStyled}
            wide
            onClick={onConfirm}
            disabled={new Decimal(item?.quantityDecimal || 0).lessThan(
              alternativeFulfillmentQuantity || 0,
            )}
          >
            <FormattedMessage id="CONFIRM" />
          </LoadingButton>
        </ButtonContainer>
      </If>
    </Container>
  );
};
