import { AccountingSettingsFieldsFragment } from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, Fragment } from "react";
import { FormattedNumber } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`grid grid-cols-[1fr_2fr]`;
const TaxCode = tw.div`font-medium`;
const TaxRate = tw.div`font-light`;

type Props = {
  taxAuthorities: AccountingSettingsFieldsFragment["taxAuthorities"];
};

export const TaxAuthoritiesList: FC<Props> = ({ taxAuthorities }) => {
  return (
    <Container>
      {taxAuthorities.map((taxAuthority, key) => (
        <Fragment key={key}>
          <TaxCode>{taxAuthority.code}</TaxCode>
          <TaxRate>
            <FormattedNumber
              value={new Decimal(taxAuthority.rate).mul(100).toNumber()}
              maximumFractionDigits={2}
              minimumFractionDigits={2}
            />
            %
          </TaxRate>
        </Fragment>
      ))}
    </Container>
  );
};
