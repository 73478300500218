import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { CompletedStep } from "@/common/components/wizard-modal/components/CompletedStep";
import { MissingMaterialConversionsList } from "@/contractor/pages/home/release/components/connections/components/connection-types/hosted/steps/missing-material-conversion/MissingMaterialConversionsList";
import {
  CreateMaterialConversionInput,
  PoMaterialConversionFieldsFragment,
  SourceSystem,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { getCreateMaterialId } from "../components/connection-types/hosted/steps/missing-material-conversion/utils/getCreateMaterialId";
import { useCreateMaterialConversions } from "../hooks/useCreateMaterialConversions";
import { usePoValidation } from "./PoValidationProvider";
import { useReleasesByIds } from "./ReleasesByIdsProvider";

type ProviderContextType = {
  pages: WizardModalPage[];
  sourceSystem: SourceSystem | null | undefined;
  modalOpened: boolean;
  openModal: ({
    sourceSystem,
    conversions,
  }: {
    sourceSystem?: SourceSystem;
    conversions: PoMaterialConversionFieldsFragment[];
  }) => void;
  closeModal: () => void;

  conversions: CreateMaterialConversionInput[];
  setConversion: (conversion: CreateMaterialConversionInput) => void;
  poConversions: PoMaterialConversionFieldsFragment[];
  setPoConversions: (conversions: PoMaterialConversionFieldsFragment[]) => void;
  updatedConversions: string[];
  setUpdatedConversion: (id: string) => void;
  clearConversion: (id: string) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  pages: [],
  sourceSystem: null,
  modalOpened: false,
  openModal: NoFunction,
  closeModal: NoFunction,

  conversions: [],
  setConversion: NoFunction,
  poConversions: [],
  setPoConversions: NoFunction,
  updatedConversions: [],
  setUpdatedConversion: NoFunction,
  clearConversion: NoFunction,
});

export const MissingMaterialConversionsProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const [sourceSystem, setSourceSystem] = useState<
    SourceSystem | null | undefined
  >(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [conversions, setConversions] = useState<
    CreateMaterialConversionInput[]
  >([]);
  const [poConversions, setPoConversions] = useState<
    PoMaterialConversionFieldsFragment[]
  >([]);
  const { moveToNextNestedStep, setNestedStep, setStep } = useNestedStepper();
  const { createMaterialConversions } = useCreateMaterialConversions();
  const { refetchPoValidation } = usePoValidation();
  const { refetchReleasesByIds } = useReleasesByIds();
  const [updatedConversions, setUpdatedConversions] = useState<string[]>([]);

  const setConversion = useCallback(
    (conversion: CreateMaterialConversionInput) => {
      if (
        !conversions.find(
          (c) => c.from === conversion.from && c.to === conversion.to,
        )
      ) {
        setConversions([...conversions, conversion]);
      } else {
        setConversions(
          conversions.map((c) => {
            if (c.from === conversion.from && c.to === conversion.to) {
              return conversion;
            }
            return c;
          }),
        );
      }
    },
    [conversions],
  );

  const openModal = useCallback(
    ({
      sourceSystem,
      conversions,
    }: {
      sourceSystem?: SourceSystem;
      conversions: PoMaterialConversionFieldsFragment[];
    }) => {
      setSourceSystem(sourceSystem);
      setPoConversions(conversions);
      setModalOpened(true);
    },
    [],
  );

  const resetModal = useCallback(() => {
    setSourceSystem(null);
    setConversions([]);
    setUpdatedConversions([]);
    setPoConversions([]);
    setNestedStep(0);
    setStep(0);
  }, [setNestedStep, setStep]);

  const closeModal = useCallback(() => {
    setModalOpened(false);
    resetModal();
    if (sourceSystem) {
      refetchPoValidation();
    } else {
      refetchReleasesByIds();
    }
  }, [refetchPoValidation, refetchReleasesByIds, resetModal, sourceSystem]);

  const conversionsToCreate = useMemo(
    () =>
      conversions.filter(
        (c) =>
          !poConversions.find(
            (pc) =>
              pc.fulfillmentUom.pluralDescription === c.from &&
              pc.purchasingUom === c.to,
          )?.quantityMultiplier ||
          updatedConversions.includes(getCreateMaterialId(c)),
      ),
    [conversions, poConversions, updatedConversions],
  );
  const save = useCallback(async () => {
    if (await createMaterialConversions(conversionsToCreate)) {
      moveToNextNestedStep();
    }
  }, [conversionsToCreate, createMaterialConversions, moveToNextNestedStep]);

  const pages: WizardModalPage[] = useMemo(
    () => [
      {
        title: null,
        pages: [
          {
            component: (
              <MissingMaterialConversionsList sourceSystem={sourceSystem} />
            ),
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                onClick: save,
                text: intl.$t({ id: "SAVE" }),
                className: "flex-1",
                disabled: conversionsToCreate.length === 0,
              },
            ],
          },
          {
            component: (
              <CompletedStep
                text={intl.$t({ id: "CONVERSION_SET" })}
                onTimeoutCompleted={closeModal}
              />
            ),
            onModalClose: closeModal,
            hideFooter: true,
            componentWrapperClassName: "mb-0",
          },
        ],
      },
    ],
    [sourceSystem, closeModal, intl, save, conversionsToCreate.length],
  );

  const clearConversion = useCallback(
    (id: string) => {
      setConversions(conversions.filter((c) => getCreateMaterialId(c) !== id));
    },
    [conversions],
  );

  return (
    <ProviderContext.Provider
      value={{
        modalOpened,
        sourceSystem,
        pages,
        openModal,
        closeModal,

        conversions,
        setConversion,
        poConversions,
        setPoConversions,
        updatedConversions,
        setUpdatedConversion: (id: string) =>
          setUpdatedConversions([...updatedConversions, id]),
        clearConversion,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useMissingMaterialConversions = () => useContext(ProviderContext);
