import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { useInvoiceDownload } from "@/contractor/pages/home/invoices/pages/invoice-verification/hooks/useInvoiceDownload";
import {
  DistributorInvoiceFieldsFragment,
  InvoiceFieldsFragment,
  ReceiptFieldsFragment,
} from "@/generated/graphql";
import { DownloadOutlined } from "@mui/icons-material";
import { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  invoice:
    | DistributorInvoiceFieldsFragment
    | InvoiceFieldsFragment
    | ReceiptFieldsFragment
    | null;
  version?: number;
};

const ButtonContent = tw.div`flex flex-row items-center py-0`;

export const InvoiceDownloadButton: FC<Props> = ({ invoice, version }) => {
  const { downloadInvoice } = useInvoiceDownload();

  const downloadPdf = useCallback(async () => {
    const downloadInvoiceUrl = await downloadInvoice(invoice?.id, version);
    if (downloadInvoiceUrl) {
      window.open(downloadInvoiceUrl, "_blank");
    }
  }, [downloadInvoice, invoice?.id, version]);

  return (
    <OutlinedButton $small className="h-6 pr-4" onClick={downloadPdf}>
      <ButtonContent>
        <DownloadOutlined className="text-sm" />
        <FormattedMessage id="DOWNLOAD" />
      </ButtonContent>
    </OutlinedButton>
  );
};
