import { StyledNumericInput } from "@/common/components/styled-numeric-input/StyledNumericInput";
import { MAX_QUANTITY_DECIMALS } from "@/common/const";
import { UomFieldsFragment } from "@/generated/graphql";
import { InputProps } from "@mui/material";
import Decimal from "decimal.js";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

type Props = {
  quantity?: string | null;
  readonly?: boolean;
  staticText?: boolean;
  saveQuantity?: (quantity: string | null) => void;
  changeQuantity?: (quantity: string) => void;
  id: string;
  label?: string | React.JSX.Element;
  uom?: UomFieldsFragment | null;
  hoverLabel?: string;
  customError?: React.JSX.Element;
  error?: boolean;
  hideErrorIcon?: boolean;
  resetIfZero?: boolean;
  includeUomMnemonic?: boolean;
  updating?: boolean;
  testId?: string;
  className?: string;
  helperText?: string | React.JSX.Element;
  allowNull?: boolean;
  shrink?: boolean;
  InputProps?: InputProps;
};

export const QuantityPicker: FC<Props> = ({
  quantity: initialQuantity,
  saveQuantity,
  changeQuantity,
  readonly = false,
  staticText = false,
  id,
  label,
  uom,
  hoverLabel = undefined,
  customError,
  error,
  resetIfZero,
  hideErrorIcon,
  includeUomMnemonic,
  updating,
  testId,
  className,
  helperText,
  allowNull = false,
  shrink,
  InputProps,
}) => {
  const defaultEmptyValue = useMemo(
    () => (allowNull ? null : "0"),
    [allowNull],
  );
  const [quantity, setQuantity] = useState<string | null>(
    initialQuantity || defaultEmptyValue,
  );

  useEffect(() => {
    setQuantity(initialQuantity || defaultEmptyValue);
  }, [defaultEmptyValue, initialQuantity]);

  useEffect(() => {
    if (readonly && !updating) {
      setQuantity(initialQuantity || defaultEmptyValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialQuantity, readonly]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const quantityValue = event.target.value;

      setQuantity(quantityValue);
      changeQuantity?.(quantityValue);
    },
    [changeQuantity],
  );

  const onBlur = useCallback(() => {
    try {
      const decimalValue = new Decimal(quantity || "0").toString();
      saveQuantity?.(decimalValue);
      if (resetIfZero && !decimalValue) {
        setQuantity(initialQuantity || "0");
      }
    } catch {
      setQuantity(initialQuantity || "0");
    }
  }, [initialQuantity, quantity, resetIfZero, saveQuantity]);

  return (
    <StyledNumericInput
      helperText={helperText}
      inputClassName={className}
      value={quantity}
      onChange={onChange}
      onBlur={onBlur}
      readonly={readonly}
      label={label}
      staticText={staticText}
      id={`quantity-input-${id}`}
      hoverLabel={hoverLabel}
      uom={uom}
      error={error}
      customError={customError}
      hideErrorIcon={hideErrorIcon}
      includeUomMnemonic={includeUomMnemonic}
      testId={testId}
      InputLabelProps={{
        className: "bg-transparent",
      }}
      InputProps={InputProps}
      decimals={MAX_QUANTITY_DECIMALS}
      shrink={shrink}
    />
  );
};
