import { If } from "@/common/components/if/If";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import {
  BuyoutItemFieldsFragment,
  BuyoutItemReleaseItemFieldsFragment,
  BuyoutStandaloneReleaseItemFieldsFragment,
  ProjectMaterialSkuFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";

import TextWithShowMore from "@/common/components/line-item-name-notes/TextWithShowMore";
import { Price } from "@/common/components/price/Price";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { BuyoutReleaseItem } from "./BuyoutReleaseItem";

const Container = tw.div`flex flex-col gap-2`;
const ProductContainer = tw.div`py-2`;
const ProductName = tw.div`text-base`;
const ProductDescription = tw.div`text-sm font-extralight mt-1.5 line-clamp-5 hover:line-clamp-none`;
const DeliveriesContainer = tw.div`bg-white pb-2 pt-3 mt-2 first:mt-0 last:mb-2`;
const ProductDetails = tw.div`grid grid-cols-[2fr_1fr] gap-1`;
const CostCode = tw.div`flex flex-row justify-between -mx-7 px-7 py-2 my-2 bg-yellow-200`;
const CostCodeName = tw.span`flex gap-1 items-center`;
const CostCodeDetails = tw.span`font-light whitespace-nowrap text-xs`;

type GroupedReleaseItem = {
  releaseId: string;
  items: BuyoutItemReleaseItemFieldsFragment[];
};

type Props = {
  title: string;
  amount: string | null | undefined;
  releaseStatuses: ReleaseStatus[];
  item: BuyoutItemFieldsFragment | BuyoutStandaloneReleaseItemFieldsFragment;
  material: BuyoutItemFieldsFragment["projectItem"]["material"];
  onClose: () => void;
};

export const BuyoutReleaseAmountsPanel: FC<Props> = ({
  title,
  amount,
  material,
  item,
  releaseStatuses,
  onClose,
}) => {
  const intl = useIntl();

  const groupedByReleaseReleaseItems: Array<GroupedReleaseItem> =
    useMemo(() => {
      const groupedByReleaseReleaseItems: Array<GroupedReleaseItem> = [];
      (item.releaseItems ?? [])
        .filter((releaseItem) =>
          releaseStatuses.includes(releaseItem.release.status),
        )
        .forEach((releaseItem) => {
          const releaseId = releaseItem.release.id;
          const release = groupedByReleaseReleaseItems.find(
            (r) => r.releaseId === releaseId,
          );
          if (release) {
            release.items.push(releaseItem);
          } else {
            groupedByReleaseReleaseItems.push({
              releaseId,
              items: [releaseItem],
            });
          }
        });
      return groupedByReleaseReleaseItems;
    }, [item.releaseItems, releaseStatuses]);

  return (
    <OverlayPanel title={title} onCancel={onClose}>
      <Container>
        <ProductContainer>
          <ProductDetails>
            <ProductName>{material.material.name}</ProductName>
          </ProductDetails>
          <CostCode>
            {item.costCode?.description ? (
              <CostCodeName>
                {item.costCode?.description}
                <CostCodeDetails>
                  ({intl.$t({ id: "COST_CODE" })})
                </CostCodeDetails>
              </CostCodeName>
            ) : (
              intl.$t({ id: "UNSPECIFIED_COST_CODE" })
            )}
            <Price price={amount} />
          </CostCode>
          <If
            isTrue={
              (material.material as ProjectMaterialSkuFieldsFragment)
                ?.description
            }
          >
            <ProductDescription>
              <TextWithShowMore
                classes={{ content: "text-sm font-light" }}
                text={
                  (material.material as ProjectMaterialSkuFieldsFragment)
                    ?.description
                }
              ></TextWithShowMore>
            </ProductDescription>
          </If>
        </ProductContainer>
        <DeliveriesContainer>
          {groupedByReleaseReleaseItems.map((rItem) => (
            <BuyoutReleaseItem
              key={rItem.releaseId}
              releaseItems={rItem.items}
              releaseStatuses={releaseStatuses}
            />
          ))}
        </DeliveriesContainer>
      </Container>
    </OverlayPanel>
  );
};
