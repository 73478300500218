import ExpirationDateFilled from "@/assets/icons/expiration-date-filled.svg?react";
import ExpirationDate from "@/assets/icons/expiration-date.svg?react";
import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { ButtonDatePicker } from "@/common/components/picker/components/ButtonDatePicker";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`flex items-center relative`;
const DateContainer = tw.div<{
  $readonly: boolean;
}>`
  pointer-events-none
  ${(props) =>
    props.$readonly
      ? "text-xs relative"
      : "absolute -bottom-1 -left-1 text-gray-600 text-[9px]"}
`;

type Props = {
  date?: number | null;
  onChange: (date: Date | null) => void;
  readonly: boolean;
};

export const ExpirationPicker: FC<Props> = ({ date, onChange, readonly }) => {
  const expirationDate = useMemo(
    () => (date ? new Date(date) : new Date()),
    [date],
  );

  return (
    <Container>
      <If isTrue={!readonly}>
        <ButtonDatePicker
          label={
            date ? (
              <ExpirationDateFilled width={20} height={20} />
            ) : (
              <ExpirationDate
                width={20}
                height={20}
                path="blue"
                className="fill-blue-500"
              />
            )
          }
          date={expirationDate}
          onChange={onChange}
        />
      </If>
      <If isTrue={date || readonly}>
        <NotNullableRenderer value={date} defaultValue="">
          <DateContainer $readonly={readonly}>
            <DateView date={expirationDate} options={SHORT_DATE_OPTION} />
          </DateContainer>
        </NotNullableRenderer>
      </If>
    </Container>
  );
};
