import { Loader } from "@/common/components/loader/Loader";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { useReceipt } from "../../providers/ReceiptProvider";
import { ReceiptCreateRelease } from "./ReceiptCreateRelease";
import { ReceiptOrderHeader } from "./ReceiptOrderHeader";
import { ReceiptNonItemizedView } from "./non-itemized/ReceiptNonItemizedView";

const Container = tw.div`bg-gray-100 rounded-3xl pt-6 h-full overflow-y-scroll flex flex-col gap-4 px-4`;
const InnerContainer = tw.div``;
const ViewContainer = tw.div<{ $show: boolean }>`
  ${({ $show }) => !$show && "opacity-0 h-0 fixed -bottom-full"}
`;

export const RecordOrderForm: FC = () => {
  const { loading, itemized } = useReceipt();

  return (
    <Loader loading={loading}>
      <Container>
        <ReceiptOrderHeader />
        <InnerContainer>
          <ViewContainer $show={!itemized}>
            <ReceiptNonItemizedView />
          </ViewContainer>
          <ViewContainer $show={itemized}>
            <ReceiptCreateRelease itemized={itemized} />
          </ViewContainer>
        </InnerContainer>
      </Container>
    </Loader>
  );
};
