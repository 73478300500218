import { If } from "@/common/components/if/If";

import {
  HeaderContainerStyled,
  ProjectHeaderContent,
} from "../../Project.styles";
import { useProjectZoneUtils } from "../../hooks/useProjectZoneUtils";
import { useProjectItemsZones } from "../../providers/ProjectItemsZonesProvider";
import { useProject } from "../../providers/ProjectProvider";
import { GroupByZonesSwitch } from "../project-items-filters/GroupByZonesSwitch";
import { ProjectCostCodesSelector } from "../project-items-filters/ProjectCostCodesSelector";
import {
  ActionItemWrapper,
  ActionsContainer,
  ActionsWrapper,
} from "../project-items-filters/ProjectItemsFilters.styles";
import { ProjectTagsSelector } from "../project-items-filters/ProjectTagsSelector";
import { ZonesSelector } from "../project-items-filters/ZonesSelector";

export const ProjectHeader = () => {
  const { groupedByZones } = useProjectItemsZones();
  const { project } = useProject();
  const { zoneOptions, costCodeOptions, tagOptions, includeGrouping } =
    useProjectZoneUtils(project);

  return (
    <HeaderContainerStyled>
      <ProjectHeaderContent>
        <ActionsContainer className="ml-0">
          <ActionsWrapper>
            <If isTrue={includeGrouping}>
              <ActionItemWrapper className="w-1/6">
                <GroupByZonesSwitch />
              </ActionItemWrapper>
            </If>
            <If isTrue={includeGrouping}>
              <ActionItemWrapper>
                <ZonesSelector
                  options={zoneOptions}
                  disabled={!groupedByZones}
                />
              </ActionItemWrapper>
            </If>
            <ActionItemWrapper>
              <ProjectCostCodesSelector options={costCodeOptions} />
            </ActionItemWrapper>
            <ActionItemWrapper>
              <ProjectTagsSelector options={tagOptions} />
            </ActionItemWrapper>
          </ActionsWrapper>
        </ActionsContainer>
      </ProjectHeaderContent>
    </HeaderContainerStyled>
  );
};
