import { FormatCurrencyType } from "@/common/components/value-currency/ValueCurrency";
import { NUMERIC_DECIMAL_POINTS } from "@/common/const";
import { ExpandedRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import Decimal from "decimal.js";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { IntlShape } from "react-intl";

export const total = (
  doc: jsPDF,
  release: ExpandedRelease,
  intl: IntlShape,
  formatCurrency: FormatCurrencyType,
) => {
  const additionalCharges = release.additionalCharges.map(
    (charge) =>
      [
        {
          content: `${charge.description}:`,
        },
        formatCurrency(charge.amount, {
          maximumFractionDigits: NUMERIC_DECIMAL_POINTS,
        }),
      ] as RowInput,
  );
  autoTable(doc, {
    theme: "plain",
    margin: { left: 190 },
    tableWidth: 93,
    showHead: false,
    styles: {
      fontSize: 10,
      halign: "right",
    },
    columns: [{ dataKey: "label" }, { dataKey: "value" }],
    body: [
      [
        {
          content: `${intl.$t({ id: "SUBTOTAL" })}:`,
          styles: { fontStyle: "bold" },
        },
        Number(release.subtotal) > 0
          ? formatCurrency(release.subtotal, {
              maximumFractionDigits: NUMERIC_DECIMAL_POINTS,
            })
          : intl.$t({ id: "TBD" }),
      ],
      ...additionalCharges,
      [
        {
          content: `${intl.$t({ id: "SALES_TAX" })}:`,
          styles: { fontStyle: "bold" },
        },
        release.customTaxAmount !== null &&
        release.customTaxAmount !== undefined
          ? formatCurrency(release.customTaxAmount, {
              maximumFractionDigits: NUMERIC_DECIMAL_POINTS,
            })
          : Number(release.taxRate) > 0
            ? `${new Decimal(release.taxRate || 0).mul(
                100,
              )}% (${formatCurrency(Number(release.taxAmount))})`
            : intl.$t({ id: "TBD" }),
      ],
      [
        {
          content: `${intl.$t({ id: "TOTAL" })}:`,
          styles: { fontStyle: "bold" },
        },
        Number(release.total) > 0
          ? formatCurrency(release.total, {
              maximumFractionDigits: NUMERIC_DECIMAL_POINTS,
            })
          : intl.$t({ id: "TBD" }),
      ],
    ],
  });
};
