import { gql } from "@apollo/client";
import { INVOICED_RELEASE_ITEM_FIELDS } from "../fragments/InvoicedReleaseItemFragment";
import { RELEASE_FIELDS } from "../fragments/releaseFragment";

export const RELEASE = gql`
  ${RELEASE_FIELDS}
  ${INVOICED_RELEASE_ITEM_FIELDS}
  query Release($id: ID!, $invoiceId: ID, $invoiceStatuses: [InvoiceStatus!]) {
    release(id: $id) {
      ...ReleaseFields
    }
  }
`;
