import { HeaderContainer } from "@/common/components/header-container/HeaderContainer";
import tw from "tailwind-styled-components";

export const HeaderContainerStyled = tw(
  HeaderContainer,
)`py-6 top-[37px] xl:top-[34px]`;

export const ProjectHeaderContent = tw.div`relative bg-green-300 flex justify-between w-full p-3 rounded-md z-70`;

export const Actions = tw.div`grid grid-flow-col w-1/3 items-center`;

export const ZoneItemsContainer = tw.div`
  flex flex-col
`;
