import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CreateDeliverySlipInput,
  DeliverySlipStatsDocument,
  useCreateDeliverySlipMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useCreateDeliverySlip = () => {
  const { setError } = useGlobalError();

  const [createDeliverySlipMutation, { loading }] =
    useCreateDeliverySlipMutation();
  const createDeliverySlip = useCallback(
    async (input: CreateDeliverySlipInput) => {
      try {
        const { data, errors } = await createDeliverySlipMutation({
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: DeliverySlipStatsDocument,
            },
          ],
        });

        setError(errors);
        return data?.createDeliverySlip;
      } catch (error) {
        setError(error);
        return null;
      }
    },
    [createDeliverySlipMutation, setError],
  );

  return { createDeliverySlip, loading };
};
