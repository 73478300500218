import {
  BreadcrumbItem,
  Breadcrumbs,
} from "@/common/components/breadcrumbs/Breadcrumbs";
import { BuyoutStatusChip } from "@/common/components/statuses/BuyoutStatusChip";
import { routes } from "@/config/routes";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
export const CreateBuyoutFromQuoteBreadcrumbs: FC = () => {
  const intl = useIntl();
  const { buyout } = useContractorBuyout();

  const breadcrumbs = useMemo(() => {
    return [
      {
        text: intl.$t({ id: "BUYOUTS" }),
        link: routes.buyouts,
      },
      {
        text: intl.$t({ id: "CREATE_BUYOUT_FROM_QUOTE" }),
      },
    ] as BreadcrumbItem[];
  }, [intl]);

  return (
    <Breadcrumbs
      classes={{ text: "flex items-center" }}
      items={breadcrumbs}
      appendItems={
        buyout
          ? [
              <BuyoutStatusChip
                key="status"
                status={buyout.status}
                type="breadcrumb"
              />,
            ]
          : []
      }
    />
  );
};
