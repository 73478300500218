import { ManufacturerPicker } from "@/common/components/manufacturer-picker/ManufacturerPicker";
import { isMasterSku } from "@/common/components/material/utils";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { useEstimatedItems } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import {
  EstimatedItemFieldsFragment,
  MasterSkusByKeywordSkuFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";

type Props = {
  item: ProjectItemFieldsFragment;
  estimatedItem?: EstimatedItemFieldsFragment | null;
  error?: boolean;
  index?: number;
  readonly?: boolean;
};

export const ProjectItemManufacturerSelector: FC<Props> = ({
  item,
  estimatedItem,
  index,
  error,
  readonly,
}) => {
  const { setNewProjectEstimatedItem } = useEstimatedItems();
  const { manufacturers } = useManufacturers();

  const handleChange = useCallback(
    (newValue: string | null) => {
      setNewProjectEstimatedItem([{ key: "manufacturerId", value: newValue }]);
    },
    [setNewProjectEstimatedItem],
  );

  const manufacturer = useMemo(() => {
    const isMaster = isMasterSku(item.material.material);
    return (
      manufacturers.find((m) => m.id === estimatedItem?.manufacturer?.id) ||
      (isMaster
        ? (item.material.material as MasterSkusByKeywordSkuFieldsFragment)
            .manufacturer
        : null)
    );
  }, [estimatedItem, item, manufacturers]);

  return (
    <ManufacturerPicker
      item={manufacturer}
      saveManufacturer={handleChange}
      error={error}
      disabled={readonly || isMasterSku(item.material.material)}
      index={index}
      canClearManufacturer
    />
  );
};
