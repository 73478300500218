import {
  NOT_NULLABLE_DEFAULT_VALUE,
  NotNullableRenderer,
} from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { StyledSelect } from "@/common/components/select/components/single/StyledSelect";
import {
  VendorPickerCustomRender,
  vendorLabelFormatter,
} from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import {
  OrgMaterialFieldsFragment,
  VendorPriceFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useMaterialPrices } from "../../providers/MaterialPricesProvider";
import { CenteredNoResults, ReadonlyContainer } from "./MaterialPriceStyles";

type Props = {
  item: OrgMaterialFieldsFragment;
  price?: VendorPriceFieldsFragment;
  error?: boolean;
  readonly?: boolean;
};

export const VendorSelector: FC<Props> = ({ item, price, error, readonly }) => {
  const intl = useIntl();
  const { vendors } = useVendors();
  const [selectedVendorId, setSelectedVendorId] = useState<
    string | null | undefined
  >(price?.sellerOrgLocation.id || null);
  const { updateMaterial } = useMaterialPrices();

  useEffect(() => {
    setSelectedVendorId(price?.sellerOrgLocation.id || null);
  }, [price]);

  const onChange = useCallback(
    async (id: string | null) => {
      setSelectedVendorId(id);
      if (id) {
        await updateMaterial(item.id, {
          vendorPriceId: price?.id || "",
          sellerOrgLocationId: id,
        });
      }
    },
    [price, updateMaterial, item],
  );

  const vendor = useMemo(() => {
    return vendors.find(
      (v) => v.sellerOrgLocation.id === price?.sellerOrgLocation.id,
    );
  }, [vendors, price]);

  return (
    <NotNullableRenderer
      value={vendor || !readonly}
      defaultValue={
        <CenteredNoResults>{NOT_NULLABLE_DEFAULT_VALUE}</CenteredNoResults>
      }
    >
      {readonly ? (
        <ReadonlyContainer>
          <OrgLogo
            width={24}
            height={24}
            name={vendor?.sellerOrgLocation.org.name ?? ""}
            logoImageUrl={vendor?.sellerOrgLocation.org.logoImageUrl ?? ""}
          />
          {vendor ? vendorLabelFormatter(vendor?.sellerOrgLocation) : ""}
        </ReadonlyContainer>
      ) : (
        <StyledSelect
          testId="vendors-selector"
          placeholder={selectedVendorId ? undefined : intl.$t({ id: "VENDOR" })}
          options={vendors || []}
          value={selectedVendorId}
          className="w-full"
          centered
          onChange={onChange}
          customRender={VendorPickerCustomRender}
          getLabel={(v) => vendorLabelFormatter(v?.sellerOrgLocation)}
          staticText={readonly}
          getValue={(option) => option.sellerOrgLocation.id}
          noOptionsText={intl.$t({ id: "NO_VENDORS_FOUND" })}
          error={error && !selectedVendorId}
          xs
        />
      )}
    </NotNullableRenderer>
  );
};
