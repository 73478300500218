import { Messages, MessagesProps } from "@/common/components/messages/Messages";
import {
  MessagesProvider,
  useMessages,
} from "@/common/components/messages/providers/MessagesProvider";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { useUser } from "@/common/providers/UserProvider";
import { MessageContextKind } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { If } from "../../if/If";
import { MessageStatsProvider } from "../providers/MessageStatsProvider";
import { MessageTabs } from "./MessageTabs";

export type DisabledMessaging = {
  vendor?: boolean;
  internal?: boolean;
};

export type MessagePanelProps = MessagesProps & {
  onClose: () => void;
  title: string;
  customParams?: { customId: string; customKind: MessageContextKind };
  projectId?: string;
  disabled?: DisabledMessaging;
};

const MessagesPanelWithProvider: FC<MessagePanelProps> = ({
  onClose,
  title,
  customParams,
  ...props
}) => {
  const { id, kind } = props;
  const intl = useIntl();
  const { sendMessage, message } = useMessages();
  const { isContractor } = useUser();
  const params = useMemo(() => {
    if (customParams) {
      return customParams;
    } else {
      return {
        customId: id,
        customKind: kind,
      };
    }
  }, [id, kind, customParams]);
  const send = useCallback(async () => {
    await sendMessage(params);
  }, [sendMessage, params]);

  return (
    <OverlayPanel
      title={intl.$t({ id: title })}
      onSave={send}
      saveLabel={intl.$t({ id: "SEND" })}
      disableSave={message.length === 0}
      onCancel={onClose}
      noPadding
      className="relative flex-col-reverse"
      bottomContainerClassName="bg-gray-100 border-none pt-0"
    >
      <Messages {...props} />
      <If isTrue={isContractor}>
        <MessageTabs disabled={props.disabled} />
      </If>
    </OverlayPanel>
  );
};

export const MessagesPanel = (props: MessagePanelProps) => (
  <MessageStatsProvider kind={props.kind} id={props.id}>
    <MessagesProvider kind={props.kind} id={props.id} disabled={props.disabled}>
      <MessagesPanelWithProvider {...props} />
    </MessagesProvider>
  </MessageStatsProvider>
);
