import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import {
  ItemSelectionType,
  SearchableList,
} from "@/common/components/searchable-list/SearchableList";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { SourceSystem } from "@/generated/graphql";
import { FC, ReactNode } from "react";
import {
  AgaveExternalItemsProvider,
  useAgaveExternalItemsProvider,
} from "../../providers/AgaveExternalItemsProvider";
import { useExternalItemsTableConfiguration } from "./ExternalItemsList.configuration";

type Props = {
  sourceSystem: SourceSystem | null;
  itemSelection: ItemSelectionType;
  searchTitleBar?: ReactNode;
};

const ExternalItemsListWithProvider: FC<Props> = ({
  itemSelection,
  searchTitleBar,
}) => {
  const {
    externalItems,
    loading,
    totalCount,
    error,
    searchText,
    setSearchText,
  } = useAgaveExternalItemsProvider();
  const configuration = useExternalItemsTableConfiguration(
    itemSelection.type === "single"
      ? itemSelection.selectedItemId
        ? [itemSelection.selectedItemId]
        : []
      : itemSelection.selectedItemIds,
  );

  return (
    <SearchableList
      tableConfiguration={configuration}
      items={externalItems}
      totalCount={totalCount}
      loadingItems={loading}
      error={!!error}
      search={{
        searchText,
        setSearchText,
      }}
      searchBarTitle={searchTitleBar}
      itemSelection={itemSelection}
    />
  );
};

export const ExternalItemsList: FC<Props> = (props) => {
  return (
    <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
      <AgaveExternalItemsProvider sourceSystem={props.sourceSystem}>
        <ExternalItemsListWithProvider {...props} />
      </AgaveExternalItemsProvider>
    </PaginationProvider>
  );
};
