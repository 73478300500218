import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import {
  COLUMN_TYPE,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { useQuoteDocument } from "@/contractor/pages/home/common/quote-document/providers/QuoteDocumentProvider";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useCallback } from "react";

export const useCopyQuotePrices = () => {
  const { handsonInstance, spreadsheetData, setMetadata } = useColumnMapper();
  const { quoteDocument } = useQuoteDocument();
  const { getPhysicalColumnIndex, getPhysicalRowIndex, setCells } =
    useTableHelpers();
  const { calcExtPrice } = usePriceCalculation();

  const copyPriceFromQuote = useCallback(() => {
    if (!handsonInstance || !quoteDocument) {
      return;
    }

    const updatedCells = [] as [number, number, string | number][];
    spreadsheetData?.forEach((row, index) => {
      if (row.id) {
        const match = quoteDocument.releaseItemHints.find(
          (hint) => hint?.releaseItem.id === row.id,
        );

        const matchingQuoteItem = match?.quoteDocumentItem;
        const matchingReleaseItem = match?.releaseItem;

        if (matchingQuoteItem) {
          const copiedPrice = matchingQuoteItem.unitPrice;
          const extPrice = calcExtPrice(
            matchingQuoteItem.quantity,
            matchingQuoteItem.unitPrice,
          );
          const previousPrice = matchingReleaseItem?.unitPrice;
          setMetadata(index, { extPrice, copiedPrice, previousPrice });

          updatedCells.push([
            getPhysicalRowIndex(handsonInstance, index),
            getPhysicalColumnIndex(handsonInstance, COLUMN_TYPE.UnitPrice),
            matchingQuoteItem.unitPrice || "",
          ]);

          updatedCells.push([
            getPhysicalRowIndex(handsonInstance, index),
            getPhysicalColumnIndex(handsonInstance, COLUMN_TYPE.ExtPrice),
            extPrice,
          ]);
        }
      }
    });
    setCells(updatedCells, handsonInstance);
  }, [
    calcExtPrice,
    getPhysicalColumnIndex,
    getPhysicalRowIndex,
    handsonInstance,
    quoteDocument,
    setCells,
    setMetadata,
    spreadsheetData,
  ]);

  return { copyPriceFromQuote };
};
