import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { useOrderItemPoItemReferences } from "@/contractor/pages/home/release/pages/specify-details/hooks/useOrderItemPoItemReferences";
import {
  ExpandedReleaseItem,
  useRelease,
} from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { FC, useCallback, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";

const Container = tw.div`flex`;

type Props = {
  zone:
    | CategoryState<ExpandedReleaseItem>
    | CategoryState<CategoryState<ExpandedReleaseItem>>;
};

export const InvoiceReleaseItemZoneCheckbox: FC<Props> = ({ zone }) => {
  const { updateInvoice } = useInvoiceVerification();
  const { invoice } = useInvoiceVerification();
  const { hasOrderItemPoItemReferences } = useOrderItemPoItemReferences();
  const { release } = useRelease();

  const items = useMemo(
    () => (zone as CategoryState<CategoryState<ExpandedReleaseItem>>).items,
    [zone],
  );

  const checked = useMemo(
    () =>
      items.every((item) =>
        (item.items || [])?.every(
          (item) => (item.invoiceItems || [])?.length > 0,
        ),
      ),
    [items],
  );

  const handleChange = useCallback(
    async (newChecked: boolean) => {
      const addedInvoicedReleaseItems = newChecked
        ? items
            .map((item) =>
              item.items
                .filter((item) => (item.invoiceItems || []).length === 0)
                .filter((item) => hasOrderItemPoItemReferences(item, release))
                .map((item) => ({
                  id: item.id,
                  releaseItemId: item.id,
                  quantity: item.quantityDecimal || "0",
                  unitPrice: item.unitPrice ? item.unitPrice.toString() : "0",
                })),
            )
            .flat()
        : undefined;
      const removedInvoicedReleaseItems = !newChecked
        ? items
            .map(
              (item) =>
                item.items
                  .map((item) => item.invoiceItems?.[0].id)
                  .filter((id) => id) as string[],
            )
            .flat()
        : undefined;
      await updateInvoice(
        {
          id: invoice?.id || "",
          addedInvoicedReleaseItems,
          removedInvoicedReleaseItems,
          releaseId: invoice?.release?.id || "",
        },
        { bulkUpdate: true },
      );
    },
    [
      items,
      updateInvoice,
      invoice?.id,
      invoice?.release?.id,
      hasOrderItemPoItemReferences,
      release,
    ],
  );

  const hasSelectableItems = useMemo(
    () =>
      !items.some((item) =>
        (item.items || []).some(
          (item) => !hasOrderItemPoItemReferences(item, release),
        ),
      ),
    [hasOrderItemPoItemReferences, items, release],
  );

  if (!hasSelectableItems) {
    return null;
  }

  return (
    <Container>
      <SelectionCheckbox
        testId={`invoice-zone-${zone.name.toLowerCase().replaceAll(/\s+/g, "-")}`}
        checked={checked}
        setSelection={handleChange}
      />
    </Container>
  );
};
