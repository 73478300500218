export const getPropertyDrillDown = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any,
  accessor: string | string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (!obj) {
    return "";
  }
  if (typeof accessor === "string") {
    return getPropertyDrillDown(obj, accessor.split("."));
  } else if (Array.isArray(accessor)) {
    if (accessor.length === 1) {
      return obj[accessor[0]];
    } else {
      return getPropertyDrillDown(obj[accessor[0]], accessor.slice(1));
    }
  }
  return "";
};
