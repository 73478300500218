import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { Menu, MenuItem } from "@mui/material";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useCostCodesList } from "../providers/CostCodesListProvider";
import { CodesFileUploadButton } from "./import-codes/CodesFileUploadButton";

const Container = tw.div`
  ml-3
`;

const LinkLikeStyled = tw(LinkLike)`
  py-2
`;

export const AddCodeMenu: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const open = Boolean(anchorEl);
  const { addEmptyCostCode } = useCostCodesList();
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Container>
      <PrimaryButton onClick={addEmptyCostCode}>
        <FormattedMessage id="ADD_COST_CODES" />
      </PrimaryButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        variant="menu"
        onClose={handleClose}
        keepMounted
      >
        <MenuItem onClick={handleClose}>
          <LinkLikeStyled onClick={addEmptyCostCode} forwardEvent>
            + <FormattedMessage id="ADD_NEW_COST_CODE" />
          </LinkLikeStyled>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <CodesFileUploadButton />
        </MenuItem>
      </Menu>
    </Container>
  );
};
