import { Loader } from "@/common/components/loader/Loader";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CurrencyFieldsFragment,
  PoNumberingMode,
  SystemRole,
  UpdateProfileInput,
  UserViewerFieldsFragment,
  useUpdateViewerProfileMutation,
  useViewerQuery,
} from "@/generated/graphql";
import { NoFunction, NoFunctionUndefinedPromise } from "@/types/NoFunction";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import tw from "tailwind-styled-components";
import {
  DEFAULT_CURRENCY,
  DEFAULT_CURRENCY_SYMBOL,
  ORG_ID_QUERY_PARAM,
} from "../const";
import { useQueryParams } from "../hooks/useQueryParams";
import { useLocale } from "./LocaleProvider";

const LoadingContainer = tw.div`grid w-screen h-screen place-items-center`;

type ContextProps = {
  viewer: UserViewerFieldsFragment | null;
  updateUser: (input: UpdateProfileInput) => Promise<string | undefined>;
  updating: boolean;
  loading: boolean;
  isContractor: boolean;
  isSystemAdmin: boolean;
  contractorCurrency: CurrencyFieldsFragment | null | undefined;
  setContractorCurrency: (
    currency: CurrencyFieldsFragment | null | undefined,
  ) => void;
  poNumbering: PoNumberingMode | undefined;
  currency: CurrencyFieldsFragment | undefined;
  currencySymbol: string;
};

const UserProviderContext = createContext<ContextProps>({
  viewer: {} as UserViewerFieldsFragment,
  updateUser: NoFunctionUndefinedPromise,
  updating: false,
  loading: false,
  isContractor: false,
  isSystemAdmin: false,
  contractorCurrency: undefined,
  setContractorCurrency: NoFunction,
  poNumbering: undefined,
  currency: undefined,
  currencySymbol: "",
});

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const { data, error, loading: queryLoading } = useViewerQuery();
  const { selectedLocale } = useLocale();
  const [contractorCurrency, setContractorCurrency] = useState<
    CurrencyFieldsFragment | null | undefined
  >();
  const { queryParams } = useQueryParams();
  const [updateViewerMutation, { loading: updateUserLoading }] =
    useUpdateViewerProfileMutation();
  useErrorEffect(error);
  const { setError } = useGlobalError();
  const { loginWithRedirect } = useAuth0();

  const shouldBlockRender = useMemo(() => {
    return (
      queryParams.get(ORG_ID_QUERY_PARAM) &&
      (!data?.viewer ||
        queryParams.get(ORG_ID_QUERY_PARAM) !== data.viewer.org.id)
    );
  }, [data?.viewer, queryParams]);

  const loading = useMemo(
    () => updateUserLoading || queryLoading,
    [queryLoading, updateUserLoading],
  );

  const updateUser = async (input: UpdateProfileInput) => {
    try {
      const { data, errors } = await updateViewerMutation({
        variables: { input },
      });
      setError(errors);
      return data?.updateViewerProfile?.id;
    } catch (errors) {
      setError(errors);
    }
  };

  useEffect(() => {
    if (data?.viewer?.id) {
      Sentry.setUser({
        id: data.viewer.id,
        orgRoles: JSON.stringify(data.viewer.orgRoles),
        systemRoles: JSON.stringify(data.viewer.systemRoles),
        locationRoles: JSON.stringify(data.viewer.locationRoles),
        orgId: data.viewer.org.id,
        sessionId: data.viewer.sessionId,
      });

      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).clarity?.("identify", data.viewer.id);
      } catch {
        //ignore
      }

      if (
        queryParams.get(ORG_ID_QUERY_PARAM) &&
        queryParams.get(ORG_ID_QUERY_PARAM) !== data.viewer.org.id
      ) {
        loginWithRedirect({
          authorizationParams: {
            org_id: queryParams.get(ORG_ID_QUERY_PARAM),
          },
          appState: {
            returnTo: window.location.pathname,
          },
        });
      }
    }
  }, [data, loginWithRedirect, queryParams]);
  const isContractor = useMemo(
    () => !!data?.viewer?.authId,
    [data?.viewer?.authId],
  );

  const currency = useMemo(() => {
    const defaultCurrency: CurrencyFieldsFragment = {
      id: "",
      code: DEFAULT_CURRENCY,
      name: DEFAULT_CURRENCY,
      symbol: DEFAULT_CURRENCY_SYMBOL,
    };

    const currency = isContractor
      ? data?.viewer?.org?.settings?.display?.currency
      : contractorCurrency;
    return currency || defaultCurrency;
  }, [
    contractorCurrency,
    isContractor,
    data?.viewer?.org?.settings?.display?.currency,
  ]);

  const currencySymbol = useMemo(() => {
    const code = isContractor ? currency?.code : contractorCurrency?.code;
    return (0)
      .toLocaleString(selectedLocale || DEFAULT_CURRENCY, {
        style: "currency",
        currencyDisplay: "narrowSymbol",
        currency: code || DEFAULT_CURRENCY,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  }, [contractorCurrency?.code, currency?.code, isContractor, selectedLocale]);

  if (queryLoading || shouldBlockRender) {
    return (
      <LoadingContainer>
        <Loader loading />
      </LoadingContainer>
    );
  }

  return (
    <UserProviderContext.Provider
      value={{
        viewer: data?.viewer || null,
        updateUser,
        updating: updateUserLoading,
        loading,
        isContractor,
        isSystemAdmin: !!data?.viewer?.systemRoles?.includes(
          SystemRole.FmAdmin,
        ),
        contractorCurrency,
        setContractorCurrency,
        poNumbering: data?.viewer?.org?.settings?.releases?.poNumbering,
        currency,
        currencySymbol,
      }}
    >
      {children}
    </UserProviderContext.Provider>
  );
};

export const useUser = (): ContextProps => useContext(UserProviderContext);
