import { useToggleCategory } from "@/common/hooks/useToggleCategory";
import { generateUUID } from "@/common/utils/uuidUtils";
import { serviceCategories } from "@/config/deliveryConfiguration";
import { ServicePriceFieldsFragment } from "@/generated/graphql";
import { useState } from "react";

export const useServiceCategories = (prices: ServicePriceFieldsFragment[]) => {
  const [categories, setCategories] = useState(
    serviceCategories
      .filter((category) =>
        prices.some(
          (item) =>
            item.productCategory === category.productCategory &&
            item.type === category.type,
        ),
      )
      .map((category, index) => {
        return {
          ...category,
          description: category.productCategory,
          id: generateUUID() + index,
          isOpened: true,
        };
      }),
  );
  const { toggleCategory } = useToggleCategory(categories, setCategories);

  return {
    categories,
    toggleCategory,
  };
};
