import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { Dialog } from "@/common/components/dialog/Dialog";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import {
  InvoiceStatus,
  InvoiceType,
  ProjectExtendedFieldsFragment,
  ProjectStatus,
  ReleaseStatus,
  RfqStatus,
  useInvoicesLazyQuery,
  useReleasesLazyQuery,
  useRfqsLazyQuery,
} from "@/generated/graphql";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const CompleteProjectContent = tw.div`flex flex-1 flex-col items-center justify-center`;
const CompleteProjectContentTitle = tw.div`my-4`;
const CompleteProjectUnprocessedEntries = tw.ul`list-disc`;
const CompleteProjectUnprocessedEntry = tw.li``;

type Props = {
  project?: ProjectExtendedFieldsFragment | null;
  closeProjectStatus: ProjectStatus | null;
  handleConfirm: () => void;
  handleCancel: () => void;
};

export const ProjectFormCloseDialog = ({
  project,
  closeProjectStatus,
  handleConfirm,
  handleCancel,
}: Props) => {
  const intl = useIntl();

  const [
    loadUnprocessedReleases,
    { data: unprocessedReleases, loading: loadingUnprocessedReleases },
  ] = useReleasesLazyQuery({
    variables: {
      filter: {
        projectIds: project ? [project.id] : [],
        statuses: [ReleaseStatus.Scheduled, ReleaseStatus.Requested],
      },
    },
  });
  const [
    loadUnprocessedRFQs,
    { data: unprocessedRFQs, loading: loadingUnprocessedRFQs },
  ] = useRfqsLazyQuery({
    variables: {
      filter: {
        projectIds: project ? [project.id] : [],
        statuses: [RfqStatus.Active],
        deleted: false,
      },
    },
  });
  const [
    loadUnprocessedInvoices,
    { data: unprocessedInvoices, loading: loadingUnprocessedInvoices },
  ] = useInvoicesLazyQuery({
    variables: {
      filter: {
        projectIds: project ? [project.id] : [],
        statuses: [
          InvoiceStatus.AwaitingApproval,
          InvoiceStatus.InternalKickback,
          InvoiceStatus.IssuesReported,
        ],
        type: InvoiceType.Invoice,
      },
    },
  });
  const [
    loadUnprocessedReceipts,
    { data: unprocessedReceipts, loading: loadingUnprocessedReceipts },
  ] = useInvoicesLazyQuery({
    variables: {
      filter: {
        projectIds: project ? [project.id] : [],
        statuses: [
          InvoiceStatus.AwaitingApproval,
          InvoiceStatus.InternalKickback,
          InvoiceStatus.IssuesReported,
        ],
        type: InvoiceType.Receipt,
      },
    },
  });

  useEffect(() => {
    if (!!closeProjectStatus) {
      loadUnprocessedReleases();
      loadUnprocessedRFQs();
      loadUnprocessedInvoices();
      loadUnprocessedReceipts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeProjectStatus]);

  return (
    <Dialog
      show={!!closeProjectStatus}
      icon={<WarningIcon className="mb-3 mt-6" />}
      title={intl.$t({
        id:
          closeProjectStatus === ProjectStatus.Completed
            ? "COMPLETE_PROJECT"
            : "PROJECT_STATUS_LOST",
      })}
      text={intl.$t({ id: "COMPLETE_PROJECT_DESCRIPTION" })}
      content={
        loadingUnprocessedReleases ||
        loadingUnprocessedInvoices ||
        loadingUnprocessedReceipts ||
        loadingUnprocessedRFQs ? (
          <Loader loading className="mt-4" />
        ) : (unprocessedReleases?.releases.edges ?? []).length > 0 ||
          (unprocessedRFQs?.rfqs.edges ?? []).length > 0 ||
          (unprocessedInvoices?.invoices.edges ?? []).length > 0 ||
          (unprocessedReceipts?.invoices.edges ?? []).length > 0 ? (
          <CompleteProjectContent>
            <CompleteProjectContentTitle>
              {intl.$t({ id: "COMPLETE_PROJECT_UNPROCESSED_ENTRIES" })}:
            </CompleteProjectContentTitle>
            <CompleteProjectUnprocessedEntries>
              <If
                isTrue={(unprocessedReleases?.releases.edges ?? []).length > 0}
              >
                <CompleteProjectUnprocessedEntry>
                  {(unprocessedReleases?.releases.edges ?? []).length} Orders
                </CompleteProjectUnprocessedEntry>
              </If>
              <If isTrue={(unprocessedRFQs?.rfqs.edges ?? []).length > 0}>
                <CompleteProjectUnprocessedEntry>
                  {(unprocessedRFQs?.rfqs.edges ?? []).length} Quotes
                </CompleteProjectUnprocessedEntry>
              </If>
              <If
                isTrue={(unprocessedInvoices?.invoices.edges ?? []).length > 0}
              >
                <CompleteProjectUnprocessedEntry>
                  {(unprocessedInvoices?.invoices.edges ?? []).length} Invoices
                </CompleteProjectUnprocessedEntry>
              </If>
              <If
                isTrue={(unprocessedReceipts?.invoices.edges ?? []).length > 0}
              >
                <CompleteProjectUnprocessedEntry>
                  {(unprocessedReceipts?.invoices.edges ?? []).length} Receipts
                </CompleteProjectUnprocessedEntry>
              </If>
            </CompleteProjectUnprocessedEntries>
          </CompleteProjectContent>
        ) : null
      }
      cancelButtonText={intl.$t({ id: "CANCEL" })}
      confirmButtonText={intl.$t({ id: "PROCEED" })}
      handleConfirm={handleConfirm}
      handleCancel={handleCancel}
    />
  );
};
