import { useGlobalError } from "@/common/hooks/useGlobalError";
import { PROJECT } from "@/contractor/pages/home/project/queries/project";
import {
  AddZoneInput,
  AddZonesInput,
  ProjectDocument,
  ProjectQuery,
  UpdateZoneInput,
  ZoneFieldsFragment,
  useAddZoneMutation,
  useAddZonesMutation,
  useRemoveZoneMutation,
  useUpdateZoneMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionUndefinedPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useRelease } from "../../release/providers/ReleaseProvider";
import { useProject } from "./ProjectProvider";

type ProviderContextType = {
  addZone: (zone: AddZoneInput) => Promise<ZoneFieldsFragment[] | undefined>;
  addZones: (zones: AddZonesInput) => Promise<ZoneFieldsFragment[] | undefined>;
  updateZone: (zone: UpdateZoneInput) => void;
  deleteZone: (id: string) => void;
  zones: ZoneFieldsFragment[] | undefined;
};

const ProviderContext = createContext<ProviderContextType>({
  addZone: NoFunctionUndefinedPromise,
  addZones: NoFunctionUndefinedPromise,
  updateZone: NoFunction,
  deleteZone: NoFunction,
  zones: [],
});

export const ProjectZonesProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { project } = useProject();
  const { release } = useRelease();
  const [addZoneMutation] = useAddZoneMutation();
  const [addZonesMutation] = useAddZonesMutation();
  const [updateZoneMutation] = useUpdateZoneMutation();
  const [removeZoneMutation] = useRemoveZoneMutation();
  const { setError } = useGlobalError();
  const [zones, setZones] = useState<ZoneFieldsFragment[] | undefined>();

  useEffect(() => {
    const zoneList = project?.zones || release?.project?.zones || [];
    setZones([...zoneList].sort((a, b) => a.name.localeCompare(b.name)));
  }, [project, release]);

  const updateZone = async (zone: UpdateZoneInput) => {
    try {
      const { data, errors } = await updateZoneMutation({
        variables: {
          input: zone,
        },
        update: (cache, { data }) => {
          const queryProject = cache.readQuery<ProjectQuery>({
            query: ProjectDocument,
            variables: { id: project?.id, excludePhantoms: true },
          });
          if (queryProject && data?.updateZone) {
            cache.writeQuery({
              query: ProjectDocument,
              variables: { id: project?.id, excludePhantoms: true },
              data: {
                project: {
                  ...queryProject?.project,
                  zones: queryProject?.project?.zones.map((zone) => {
                    if (zone.id === data.updateZone.id) {
                      return { ...zone, ...data.updateZone };
                    }
                    return zone;
                  }),
                },
              },
            });
          }
        },
      });
      setError(errors);
      if (data?.updateZone) {
        return !!data?.updateZone;
      }
    } catch (errors) {
      setError(errors);
    }
  };

  const addZone = async (zone: AddZoneInput) => {
    try {
      const { data, errors } = await addZoneMutation({
        variables: {
          input: zone,
        },
        update: (cache, { data }) => {
          const queryProject = cache.readQuery<ProjectQuery>({
            query: ProjectDocument,
            variables: { id: project?.id, excludePhantoms: true },
          });
          if (queryProject && data?.addZone) {
            cache.writeQuery({
              query: ProjectDocument,
              variables: { id: project?.id, excludePhantoms: true },
              data: {
                project: {
                  ...queryProject?.project,
                  zones: [...data.addZone],
                },
              },
            });
          }
        },
      });
      setError(errors);
      if (data?.addZone) {
        return data?.addZone;
      }
    } catch (errors) {
      setError(errors);
    }
  };

  const addZones = async (input: AddZonesInput) => {
    try {
      const { data, errors } = await addZonesMutation({
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: PROJECT,
            variables: { id: project?.id, excludePhantoms: true },
          },
        ],
      });
      setError(errors);
      if (data?.addZones) {
        return data?.addZones;
      }
    } catch (errors) {
      setError(errors);
    }
  };

  const deleteZone = async (zoneId: string) => {
    try {
      const { data, errors } = await removeZoneMutation({
        variables: {
          zoneId,
        },
        update: (cache, { data }) => {
          const queryProject = cache.readQuery<ProjectQuery>({
            query: ProjectDocument,
            variables: { id: project?.id, excludePhantoms: true },
          });
          if (queryProject && data?.removeZone) {
            cache.writeQuery({
              query: ProjectDocument,
              variables: { id: project?.id, excludePhantoms: true },
              data: {
                project: {
                  ...queryProject?.project,
                  zones: [...data.removeZone],
                },
              },
            });
          }
        },
      });
      setError(errors);
      if (data?.removeZone) {
        return !!data?.removeZone;
      }
    } catch (errors) {
      setError(errors);
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        addZone,
        addZones,
        updateZone,
        deleteZone,
        zones,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useProjectZones = (): ProviderContextType =>
  useContext(ProviderContext);
