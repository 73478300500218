import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useSystemReportLazyQuery } from "@/generated/graphql";

export const useGMVReport = () => {
  const [loadGMVReport, { loading, error, data }] = useSystemReportLazyQuery();

  useErrorEffect(error);

  return {
    data,
    loading,
    loadGMVReport,
    error,
  };
};
