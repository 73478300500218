import { sxHiddenHide } from "@/common/components/grid-table/grid-item/utils";
import { WrapperProps } from "@/common/components/grid-table/types/WrapperProps";
import {
  MinWidthContainer,
  TotalContainer,
} from "@/common/layout/ResponsiveClasses";
import tw from "tailwind-styled-components";

export const SymbolContainer = tw(MinWidthContainer)`${sxHiddenHide}`;

export const TotalContainerStyled = tw(TotalContainer)<WrapperProps>`
  ${({ $details }: WrapperProps) =>
    $details ? "lg:basis-full w-full flex-full" : ""}
`;
