import {
  DistributorReleaseItemFieldsFragment,
  ReleaseItemFieldsFragment,
  ZoneFieldsFragment,
} from "@/generated/graphql";

export const getPhaseCodesByReleaseItem = (
  releaseItem: ReleaseItemFieldsFragment | DistributorReleaseItemFieldsFragment,
  unassignedZone: ZoneFieldsFragment,
  zone?: ZoneFieldsFragment,
) => {
  const phaseCode = releaseItem.tags.find((t) => t.hasMapping);
  if (
    phaseCode?.id &&
    ((!releaseItem.zone && zone?.id === unassignedZone.id) ||
      releaseItem.zone?.id === zone?.id)
  ) {
    return {
      id: phaseCode.id,
      code: phaseCode.id,
      description: phaseCode.name,
      inUse: false,
    };
  }
};
