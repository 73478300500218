import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useClearPoNumberMutation } from "@/generated/graphql";
import { useInvoiceVerification } from "../../invoice-verification/providers/InvoiceVerificationProvider";

export const useClearPoNumber = () => {
  const { refetch } = useInvoiceVerification();
  const [clearPoNumberMutation] = useClearPoNumberMutation();
  const { setError } = useGlobalError();

  const clearPoNumber = async (invoiceId: string) => {
    try {
      const { data } = await clearPoNumberMutation({
        variables: {
          invoiceId,
        },
      });
      refetch();
      return !!data?.clearPoNumber;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return { clearPoNumber };
};
