import { CountType } from "@/common/components/circle-counter/CountType";
import { If } from "@/common/components/if/If";
import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { ProjectItemMaterialView } from "@/common/components/project-item-material-view/ProjectItemMaterialView";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useEstimatedItems } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { AddOrgMaterialWithCounter } from "@/contractor/pages/home/rfq/components/add-org-sku/AddOrgMaterialWithCounter";
import { UpdateContractorReleaseInput } from "@/generated/graphql";
import { LockOutlined } from "@mui/icons-material";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useMaterialCostCodeAndZone } from "../../../../common/hooks/useMaterialCostCodeAndZone";
import { useVendorPrice } from "../../../hooks/useVendorPrice";
import { useReleaseItemsZoneProvider } from "../../../providers/ReleaseItemsZonesProvider";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";
import { useReleaseStore } from "../../../store/useReleaseStore";

const Row = tw.div`flex flex-row flex-1 justify-between`;
const StyledLock = tw(LockOutlined)`text-gray-600 text-base mr-3.5 ml-1`;

type Props = {
  item: ExpandedReleaseItem;
  index?: number;
  count: CountType;
  readonly: boolean;
  error?: boolean;
  expandable?: boolean;
  extraDetails?: React.ReactNode;
};

export const ReleaseItemMaterialEditableView = ({
  extraDetails,
  ...props
}: Props) => {
  const { setNewProjectEstimatedItem, newProjectEstimatedItem } =
    useEstimatedItems();
  const { groupedByCostCode } = useReleaseItemsZoneProvider();
  const { getPrice } = useVendorPrice();
  const { materials } = useMaterials();
  const { release: storeRelease } = useReleaseStore();

  const { itemDetails } = useMaterialCostCodeAndZone(
    props.item,
    groupedByCostCode,
    storeRelease?.project?.id,
  );

  const { updateRelease, release } = useReleaseUpdate();

  const updateDescription = useCallback(
    async (description: string) => {
      if (release) {
        const input: UpdateContractorReleaseInput = {
          releaseId: release?.id,
          version: release?.version,
          updates: [
            {
              releaseItemId: props.item.id,
              name: description,
            },
          ],
        };
        await updateRelease(input);
      }
    },
    [release, updateRelease, props.item.id],
  );

  return props.item && !props.item?.id ? (
    <AddOrgMaterialWithCounter
      count={props.count}
      setSelectedOrgMaterial={(material) => {
        if (material) {
          const orgMaterial = materials.find(
            (m) => m.material.id === material.material.id,
          );
          setNewProjectEstimatedItem([
            {
              key: "orgCatalogSkuId",
              value: isOrgCatalogSku(material?.material)
                ? material?.material.id
                : undefined,
            },
            {
              key: "masterSkuId",
              value: isMasterSku(material?.material)
                ? material?.material.id
                : undefined,
            },
            {
              key: "masterProductId",
              value: isProductSku(material?.material)
                ? material?.material.id
                : undefined,
            },
            {
              key: "orgCatalogSkuName",
              value: !material.id ? material.material.name : undefined,
            },
            {
              key: "uom",
              value: material.defaultEstimateUom?.pluralDescription,
            },
            { key: "uomId", value: material.defaultEstimateUom?.id },
            { key: "costCodeId", value: material.costCode?.id },
            {
              key: "unitPrice",
              value:
                getPrice(
                  orgMaterial?.id || "",
                  orgMaterial?.defaultEstimateUom.id || "",
                  release.sellerOrgLocation?.id,
                  newProjectEstimatedItem.manufacturerId,
                ) || undefined,
            },
          ]);
        }
      }}
    />
  ) : (
    <Row>
      <ProjectItemMaterialView
        {...props}
        updateDescription={updateDescription}
        vendorNotes={{
          notes: props.item.notes,
          assets: props.item.assets,
        }}
        manufacturer={props.item?.manufacturer}
        showOutsideOfEstimatesInfo={props.item.estimatedItems.length === 0}
        extraDetails={
          extraDetails ? (
            <>
              {itemDetails}
              {extraDetails}
            </>
          ) : (
            itemDetails
          )
        }
        isLocked={props.item.invoiceItems.length > 0}
      />
      <If isTrue={!!props.item.poItemLink && !!release?.poLink?.retroactive}>
        <Tooltip id="imported-item-tooltip" element={<StyledLock />}>
          <FormattedMessage id="ITEM_IMPORTED_FROM_PO" />
        </Tooltip>
      </If>
    </Row>
  );
};
