import { addressToString } from "@/common/components/adress-location/AddressLocation";
import { formatPhone } from "@/common/components/phone-input/formatPhone";
import { PDF_FONT } from "@/common/const";
import { formattedDate } from "@/common/utils/dates/DateView";
import { getVendorContacts } from "@/common/utils/getVendorContacts";
import { drawLogo, LOGO_SIZE } from "@/common/utils/pdf-print/drawLogo";
import { ExpandedRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ServiceType } from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable, { FontStyle } from "jspdf-autotable";
import { IntlShape } from "react-intl";

const addProjectAndDeliveryInfo = (
  doc: jsPDF,
  release: ExpandedRelease,
  intl: IntlShape,
  x: number,
  y: number,
  width: number,
) => {
  doc.setFontSize(8);
  autoTable(doc, {
    startY: y - 4,
    theme: "plain",
    margin: { left: x },
    tableWidth: width,
    showHead: false,
    styles: {
      fontSize: 8,
      cellPadding: 1,
    },
    columns: [{ dataKey: "label" }, { dataKey: "value" }],
    body: [
      [
        {
          content: `${intl.$t({ id: "PROJECT" })}:`,
          styles: { fontStyle: "bold" },
        },
        `${release?.project?.name}${release?.project?.jobNumber ? ` (${release.project.jobNumber})` : ""}`,
      ],
      ...(release.buyout
        ? [
            [
              {
                content: `${intl.$t({ id: "BUYOUT_NUMBER_SYMBOL" })}:`,
                styles: { fontStyle: "bold" as FontStyle },
              },
              release.buyout.clientIdentifier,
            ],
          ]
        : []),
      [
        {
          content: `${intl.$t({ id: "LOCATION" })}:`,
          styles: { fontStyle: "bold" },
        },
        `${addressToString(release.project?.address, {
          excludeCity: true,
          excludeCountry: true,
          excludePostalCode: true,
          excludeState: true,
        })}\n${addressToString(release.project?.address, {
          excludeAddressLines: true,
          excludeCountry: true,
        })}`,
      ],
      [
        {
          content: `${intl.$t({ id: "DELIVERY_DATE" })}:`,
          styles: { fontStyle: "bold" },
        },
        formattedDate({
          date: new Date(release?.time || 0),
          includeTime: true,
        }),
      ],
      ...(release.siteContact
        ? [
            [
              {
                content: `${intl.$t({ id: "SITE_CONTACT" })}:`,
                styles: { fontStyle: "bold" as FontStyle },
              },
              `${release?.siteContact?.name} ${formatPhone(release?.siteContact?.phone || "")}`,
            ],
          ]
        : []),
    ],
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (doc as any).lastAutoTable.finalY;
};

const addReceiverInfo = (
  doc: jsPDF,
  release: ExpandedRelease,
  intl: IntlShape,
  x: number,
  y: number,
  width: number,
  logo: string,
  logoProportion: number,
) => {
  let yShift = y;
  let deltaX = 0;
  if (logo) {
    deltaX = drawLogo(doc, logo, logoProportion, x, yShift);
    x += deltaX;
  }
  doc.setFontSize(10).setFont(PDF_FONT, "", "bold");
  doc.text(`${intl.$t({ id: "ORDER_FOR" })}:`, x, yShift);
  yShift += 5;
  doc.setFontSize(8).setFont(PDF_FONT, "", "normal");
  const vendorContacts =
    getVendorContacts(
      release.preferredVendor?.contacts.filter(
        (c) => c.receivesOrderNotifications,
      ),
    ) || "";
  const splitVendorContacts = doc.splitTextToSize(
    vendorContacts,
    width - deltaX,
  );
  doc.text(splitVendorContacts, x, yShift);
  yShift += 5 * splitVendorContacts.length;
  if (release?.sellerOrgLocation) {
    doc.text(release?.sellerOrgLocation.org.name, x, yShift);
    yShift += 5;
  }
  const addressLines = doc.splitTextToSize(
    addressToString(release.sellerOrgLocation?.address, {
      excludeCity: true,
      excludeCountry: true,
      excludePostalCode: true,
      excludeState: true,
    }),
    width - deltaX,
  );
  const addressCityAndState = doc.splitTextToSize(
    addressToString(release.sellerOrgLocation?.address, {
      excludeAddressLines: true,
      excludeCountry: true,
    }),
    width - deltaX,
  );
  const address = [...addressLines, ...addressCityAndState];
  doc.text(address, x, yShift);
  yShift += 5 * address.length;
  return yShift;
};

const addFulfillmentInformation = (
  doc: jsPDF,
  release: ExpandedRelease,
  intl: IntlShape,
  x: number,
  y: number,
) => {
  let yShift = y;
  doc.setFontSize(10).setFont(PDF_FONT, "", "bold");
  doc.text(intl.$t({ id: "FULFILLMENT" }), x + 10, yShift);
  yShift += 5;
  doc.setFontSize(8).setFont(PDF_FONT, "", "normal");
  doc.text(
    `${intl.$t({ id: "DELIVERY" })}: ${serviceTypeName(
      !!release?.includeServices?.includes(ServiceType.Delivery),
      intl,
    )}`,
    x + 10,
    yShift,
  );
  yShift += 5;
  doc.text(
    `${intl.$t({ id: "STOCKING" })}: ${serviceTypeName(
      !!release?.includeServices?.includes(ServiceType.Stocking),
      intl,
    )}`,
    x + 10,
    yShift,
  );
  yShift += 5;
  return yShift;
};

const serviceTypeName = (includeService: boolean, intl: IntlShape) => {
  if (includeService) {
    return intl.$t({ id: "VENDOR" });
  }
  return intl.$t({ id: "CLIENT_DELIVERY" });
};

export const releaseHeader = (
  doc: jsPDF,
  release: ExpandedRelease,
  intl: IntlShape,
  topSpacing: number,
  contractorLogo: string,
  contractorLogoProportion: number,
  vendorLogo: string,
  vendorLogoProportion: number,
) => {
  const columnOffset = contractorLogo ? 1 : 0;
  autoTable(doc, {
    startY: topSpacing,
    theme: "plain",
    showHead: false,
    styles: {
      font: PDF_FONT,
    },
    columnStyles: {
      ...(contractorLogo ? { 0: { cellWidth: LOGO_SIZE + 2 } } : {}),
    },
    body: [[...(contractorLogo ? [""] : []), "", "", ""]],
    didDrawCell: function (data) {
      if (data.row.index === 0 && data.row.section === "body") {
        if (data.column.index === 0 && contractorLogo) {
          drawLogo(
            doc,
            contractorLogo,
            contractorLogoProportion,
            data.cell.x,
            data.cell.y,
          );
          topSpacing = Math.max(topSpacing, data.cell.y + LOGO_SIZE);
        } else if (data.column.index === 0 + columnOffset) {
          const yShift = addProjectAndDeliveryInfo(
            doc,
            release,
            intl,
            data.cell.x,
            data.cell.y,
            data.cell.width,
          );
          topSpacing = Math.max(topSpacing, yShift);
        } else if (data.column.index === 1 + columnOffset) {
          const yShift = addReceiverInfo(
            doc,
            release,
            intl,
            data.cell.x + 10,
            data.cell.y,
            data.cell.width - 10,
            vendorLogo,
            vendorLogoProportion,
          );
          topSpacing = Math.max(topSpacing, yShift);
        } else if (data.column.index === 2 + columnOffset) {
          const yShift = addFulfillmentInformation(
            doc,
            release,
            intl,
            data.cell.x,
            data.cell.y,
          );
          topSpacing = Math.max(topSpacing, yShift);
        }
      }
    },
  });
  return topSpacing;
};
