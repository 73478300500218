import { UsersUserFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { Locations } from "./Locations";
import { SingleOrMultipleContainer } from "./SingleOrMultipleContainer";

type Props = {
  user: UsersUserFieldsFragment;
};

export const UserLocations: FC<Props> = ({ user }) => {
  const locationNames = useMemo(() => {
    const locationIds = user.locationRoles.map((location) => location.orgLocID);
    const uniqueNames = new Set(
      user.org.locations
        .filter((loc) => locationIds.includes(loc.id))
        .map((loc) => loc.name),
    );
    return [...uniqueNames];
  }, [user]);

  return (
    <SingleOrMultipleContainer items={locationNames} user={user}>
      <Locations locations={locationNames} className="rounded bg-blue-500" />
    </SingleOrMultipleContainer>
  );
};
