import { useUploadAssets } from "@/common/components/upload-asset/UploadAssetProvider";
import {
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPE,
  PDF_EXTENSIONS,
  PDF_MIME_TYPE,
} from "@/common/components/upload/FileUploadArea";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { AssetUploadStep } from "@/common/components/wizard-modal/components/AssetUploadStep";
import { NoFunction } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useReceipt } from "./ReceiptProvider";

type ProviderContextType = {
  uploadReceiptModalPages: WizardModalPage[];
  uploadReceiptModalOpened: boolean;
  openUploadReceiptModal: () => void;
  closeUploadReceiptModal: () => void;
};

const ProviderContext = createContext<ProviderContextType>({
  uploadReceiptModalPages: [],
  uploadReceiptModalOpened: false,
  openUploadReceiptModal: NoFunction,
  closeUploadReceiptModal: NoFunction,
});

export const UploadReceiptProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const { assets, removeAsset } = useUploadAssets();
  const { receipt, updateReceipt } = useReceipt();

  const [uploadReceiptModalOpened, setUploadReceiptModalOpened] =
    useState(false);

  const uploadReceiptModalPages: WizardModalPage[] = useMemo(() => {
    return [
      {
        title: null,
        pages: [
          {
            component: (
              <AssetUploadStep
                accept={{
                  [PDF_MIME_TYPE]: PDF_EXTENSIONS,
                  [IMAGE_MIME_TYPE]: IMAGE_EXTENSIONS,
                }}
                description={intl.$t({ id: "UPLOAD_RECEIPT_DETAILS" })}
                supportedFormats={intl.$t({ id: "RECEIPTS_SUPPORTED_FORMATS" })}
              />
            ),
            footerButtonsConfig: [
              {
                text: intl.$t({ id: "CLOSE" }),
                onClick: () => {
                  setUploadReceiptModalOpened(false);
                },
                className: "flex mr-4 items-center",
                type: "outlined",
              },
              {
                text: intl.$t({ id: "SAVE" }),
                onClick: async () => {
                  if (receipt && assets.length > 0) {
                    await updateReceipt(
                      {
                        id: receipt?.id,
                        assetUrl: assets[0].url,
                      },
                      { includeDocuments: true },
                    );
                    setUploadReceiptModalOpened(false);
                  }
                },
                type: "primary",
              },
            ],
          },
        ],
      },
    ];
  }, [intl, receipt, assets, updateReceipt]);

  const closeUploadReceiptModal = useCallback(() => {
    setUploadReceiptModalOpened(false);
    assets.forEach(removeAsset);
  }, [assets, removeAsset]);

  return (
    <ProviderContext.Provider
      value={{
        uploadReceiptModalOpened,
        uploadReceiptModalPages,
        openUploadReceiptModal: () => setUploadReceiptModalOpened(true),
        closeUploadReceiptModal,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useReceiptUploadModal = (): ProviderContextType =>
  useContext(ProviderContext);
