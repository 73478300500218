import { FC } from "react";
import tw from "tailwind-styled-components";
import { If } from "../../if/If";

const Container = tw.div`relative`;

const Count = tw.div`
  absolute grid place-items-center font-semibold py-0.5 h-5 select-none
  px-1 text-xs text-white bg-red-500 rounded-xl min-w-5 w-fit animate-[grow-center_0.4s]
`;

type NotificationCountProps = {
  unreadCount: number;
  className?: string;
};

export const NotificationCount: FC<NotificationCountProps> = ({
  unreadCount,
  className,
}) => {
  return (
    <Container className={className}>
      <If isTrue={unreadCount > 0}>
        <Count className="animate-[ping_3s_ease-in-out_infinite]" />
        <Count className="">{unreadCount < 10 ? unreadCount : "9+"}</Count>
      </If>
    </Container>
  );
};
