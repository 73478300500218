import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useNotifications } from "../../providers/NotificationsProvider";
import { NoNotifications } from "../common/Notification.styles";
import { NotificationListItem } from "./NotificationListItem";

type Props = {
  onClose: () => void;
};

const Container = tw.div``;

export const InternalNotifications: FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const { notifications } = useNotifications();

  return (
    <Container>
      {notifications.map((notification, index) => (
        <NotificationListItem
          key={notification.id}
          notification={notification}
          index={index}
          closeDrawer={onClose}
        />
      ))}
      {notifications.length === 0 && (
        <NoNotifications>{intl.$t({ id: "NO_NOTIFICATIONS" })}</NoNotifications>
      )}
    </Container>
  );
};
