import { GridTable } from "@/common/components/grid-table/GridTable";
import { GRID_ERROR_ROW_KEY } from "@/common/components/grid-table/GridTableCategory";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { scrollIntoViewByDataAttr } from "@/common/utils/scrollUtils";
import { ZoneItemsContainer } from "@/contractor/pages/home/project/Project.styles";
import { useEstimatedItems } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import { useProjectItemsZones } from "@/contractor/pages/home/project/providers/ProjectItemsZonesProvider";
import { getEstimatedItem } from "@/contractor/pages/home/project/utils/getEstimatedItem";
import {
  EstimatedItemFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useAddItemsToRelease } from "../AddItemsToReleaseProvider";

const Content = tw.div`grid`;
const ListContainer = tw.div`max-h-[calc(100vh-300px)] overflow-y-auto`;
const EmptyList = tw.div`flex justify-center items-center h-full p-5 border border-gray-500 border-dashed rounded-xl`;

type Props = {
  columns: Array<
    GridCol<ProjectItemFieldsFragment, EstimatedItemFieldsFragment>
  >;
  loading?: boolean;
};

export const AddFromEstimatesToReleaseList: FC<Props> = ({
  columns,
  loading,
}) => {
  const { zones, groupedByZones, toggleZone, toggleCostCode } =
    useProjectItemsZones();
  const { selectedItems } = useAddItemsToRelease();
  const { newProjectEstimatedItem, expandedItems } = useEstimatedItems();
  useEffect(() => {
    if (newProjectEstimatedItem.hasError) {
      setTimeout(() => scrollIntoViewByDataAttr(GRID_ERROR_ROW_KEY), 100);
    }
  }, [newProjectEstimatedItem.hasError]);

  const itemFn = useCallback(
    (
      item: ProjectItemFieldsFragment,
      category?: CategoryState<ProjectItemFieldsFragment>,
    ) => {
      const estimatedItem = getEstimatedItem({ item, category });
      const isIncluded = selectedItems?.some(
        (i) => i.itemId === item?.id && i.estimatedItemId === estimatedItem?.id,
      );
      return {
        className: `${
          isIncluded
            ? "border-2 border-blue-500 bg-blue-100 transition-all"
            : "opacity-70 transition-all bg-gray-100"
        }`,
      };
    },
    [selectedItems],
  );

  const getExpandedItems = useCallback(
    (item: ProjectItemFieldsFragment) => {
      const estimatedItems = item.estimatedItems;
      if (
        expandedItems.some(
          (i) =>
            i.id === item.id &&
            (!i.zoneId ||
              i.zoneId === UNSPECIFIED_ZONE_ID ||
              estimatedItems.find((e) => e.zone?.id === i.zoneId)),
        ) &&
        estimatedItems.length > 1
      ) {
        return estimatedItems.map(() => item);
      }

      return [];
    },
    [expandedItems],
  );

  return (
    <Content>
      <ListContainer>
        <ListRenderer
          hasItemsCondition={zones.length > 0 && zones[0].items.length > 0}
          emptyList={
            <EmptyList>
              <FormattedMessage id="NO_PROJECT_ESTIMATES" />
            </EmptyList>
          }
        >
          <GridTable
            configuration={{
              container: ZoneItemsContainer,
              columns,
              classNames: {
                header: "top-0",
                category: "top-10 lg:top-[35px]",
                subCategory: !groupedByZones ? "top-0" : "top-[68px]",
                itemFn,
              },
              toggle: {
                category: toggleZone,
                subCategory: toggleCostCode,
              },
            }}
            items={zones}
            readonly={true}
            hideGroup={!groupedByZones}
            expandedItems={getExpandedItems}
            loading={loading}
          />
        </ListRenderer>
      </ListContainer>
    </Content>
  );
};
