import { FormatCurrencyType } from "@/common/components/value-currency/ValueCurrency";
import {
  MAX_PRICE_DECIMALS,
  MAX_QUANTITY_DECIMALS,
  PDF_FONT,
  PDF_LEFT_SPACING,
} from "@/common/const";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { formattedDate } from "@/common/utils/dates/DateView";
import { formatHeader } from "@/common/utils/pdf-print/formatHeader";
import { CostCodeType } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { ZoneCategory } from "@/contractor/pages/home/release/providers/ReleaseItemsZonesProvider";
import { ExpandedRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { IntlShape } from "react-intl";

const itemsForPdf = (
  release: ExpandedRelease,
  intl: IntlShape,
  zones: ZoneCategory[],
  hasPhaseCodes: boolean,
  hasManufacturersSetting: boolean,
  groupedByCostCode: boolean,
  formatCostCode: (costCode: CostCodeType) => string,
  formatCurrency: FormatCurrencyType,
  header: { name: string; buyoutSpecific: boolean }[],
  canViewPrices?: boolean,
): RowInput[] => {
  const result: RowInput[] = [];
  const isBuyoutOrder = !!release?.buyout;
  let count = 0;
  zones.forEach((zone) => {
    if (
      !(zones.length === 1 && zones[0].id === UNSPECIFIED_ZONE_ID) &&
      zone.items.some((costCode) =>
        costCode.items.some(
          (i) =>
            i.alternativeFulfillmentRelease || i.alternativeFulfillmentTime,
        ),
      )
    ) {
      result.push([
        {
          content: `${zone.name} (${intl.$t({ id: "ZONE" })})`,
          colSpan: header.filter((i) => isBuyoutOrder || !i.buyoutSpecific)
            .length,
          styles: {
            fontStyle: "bold",
            cellPadding: { left: 7, top: 3, bottom: 3 },
          },
        },
      ]);
    }
    zone.items.forEach((costCode) => {
      let formattedCostCode = groupedByCostCode
        ? `${formatCostCode(costCode.id)} (${intl.$t({ id: hasPhaseCodes ? "PHASE_CODE" : "COST_CODE" })})`
        : "";
      const items = costCode.items.filter(
        (i) => i.alternativeFulfillmentRelease || i.alternativeFulfillmentTime,
      );
      if (
        items.length > 0 &&
        groupedByCostCode &&
        (!hasPhaseCodes || costCode.id !== UNSPECIFIED_COST_CODE_ID)
      ) {
        result.push([
          {
            content: formattedCostCode,
            colSpan: header.filter((i) => isBuyoutOrder || !i.buyoutSpecific)
              .length,
            styles: {
              fontStyle: "bold",
              cellPadding: { left: 7, top: 3, bottom: 3 },
            },
          },
        ]);
      }
      items.forEach((releaseItem) => {
        const quantityDecimal = `${intl.formatNumber(
          Number(releaseItem.quantityDecimal),
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: MAX_QUANTITY_DECIMALS,
          },
        )}\n${releaseItem.uom?.mnemonic || releaseItem.uom?.pluralDescription}`;

        count++;
        if (!groupedByCostCode) {
          if (hasPhaseCodes) {
            formattedCostCode =
              releaseItem.tags.find((t) => t.mapping)?.name ??
              intl.$t({ id: "UNSPECIFIED_PHASE_CODE" });
          } else {
            formattedCostCode =
              formatCostCode(releaseItem.costCode) ??
              intl.$t({ id: "UNSPECIFIED_COST_CODE" });
          }
        }
        result.push([
          count,
          `${releaseItem.projectItem?.material.material.name || ""}${groupedByCostCode ? "" : ` (${formattedCostCode})`}`,
          ...(hasManufacturersSetting
            ? [
                releaseItem.manufacturer?.name ||
                  intl.$t({ id: "ANY_MANUFACTURER" }),
              ]
            : []),
          quantityDecimal,
          ...(canViewPrices
            ? [
                formatCurrency(releaseItem.unitPrice, {
                  maximumFractionDigits: MAX_PRICE_DECIMALS,
                }),
              ]
            : []),
          releaseItem.alternativeFulfillmentTime
            ? formattedDate({
                date: new Date(releaseItem.alternativeFulfillmentTime),
                includeTime: true,
              })
            : "",
        ]);
      });
    });
  });

  return result;
};

export const backorderMaterials = (
  doc: jsPDF,
  release: ExpandedRelease,
  intl: IntlShape,
  zones: ZoneCategory[],
  hasPhaseCodes: boolean,
  hasManufacturersSetting: boolean,
  groupedByCostCode: boolean,
  formatCostCode: (costCode: CostCodeType) => string,
  formatCurrency: FormatCurrencyType,
  canViewPrices?: boolean,
) => {
  doc
    .setFont(PDF_FONT, "", "bold")
    .setFontSize(10)
    .text(intl.$t({ id: "BACKORDERED_ITEMS" }), PDF_LEFT_SPACING, 30);
  const header = [
    { name: "", buyoutSpecific: false },
    { name: "ITEMS_IN_DELIVERY", buyoutSpecific: false },
    { name: "ITEMS_IN_DELIVERY", buyoutSpecific: false },
    { name: "MANUFACTURER", buyoutSpecific: false },
    { name: "QUANTITY_TO_FULFILL", buyoutSpecific: false },
    ...(canViewPrices ? [{ name: "UNIT_COST", buyoutSpecific: false }] : []),
    { name: "BACKORDER", buyoutSpecific: false },
  ];
  autoTable(doc, {
    theme: "grid",
    styles: {
      font: PDF_FONT,
      fontSize: 8,
    },
    margin: { top: 35 },
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: "black",
      halign: "center",
    },
    columnStyles: {
      0: { valign: "middle" },
      1: { valign: "middle" },
      2: { halign: "center", valign: "middle" },
      3: { halign: "center", valign: "middle" },
      4: { halign: "center", valign: "middle" },
      5: { halign: "center", valign: "middle" },
      6: { halign: "center", valign: "middle" },
      7: { halign: "center", valign: "middle" },
    },
    head: [
      header
        .filter((h) => hasManufacturersSetting || h.name !== "MANUFACTURER")
        .filter((i) => release.buyout || !i.buyoutSpecific)
        .map((header) => header.name)
        .map((name) => formatHeader(name, intl)),
    ],
    body: [
      ...itemsForPdf(
        release,
        intl,
        zones,
        hasPhaseCodes,
        hasManufacturersSetting,
        groupedByCostCode,
        formatCostCode,
        formatCurrency,
        header,
        canViewPrices,
      ),
    ],
  });
};
