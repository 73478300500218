import { DeleteButton } from "@/common/components/button/DeleteButton";
import { If } from "@/common/components/if/If";
import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { ThreeDotMenu } from "@/common/components/three-dot-menu/ThreeDotMenu";
import { DistributorQuotesAuxiliaryQuoteItemFieldsFragment } from "@/generated/graphql";
import { SettingsOutlined } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";
import { DISTRIBUTOR_QUOTE_ORDER_REQUIREMENTS_PANEL } from "../terms-and-discounts/DistributorQuoteOrderRequirements";

const Container = tw.div`flex items-center`;
const ListItemIconStyled = tw(ListItemIcon)`text-blue-500`;

type Props = {
  item: DistributorQuotesAuxiliaryQuoteItemFieldsFragment;
  readonly?: boolean;
};

export const DistributorQuoteAuxiliaryOptionsMenu: FC<Props> = ({
  item,
  readonly,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { updateQuote } = useDistributorQuote();
  const { toggle } = useGlobalDrawer();

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((ev?: { stopPropagation?: () => void }) => {
    ev?.stopPropagation?.();
    setAnchorEl(null);
  }, []);

  const deleteAuxiliaryItem = useCallback(async () => {
    await updateQuote({ deletedAuxiliaryItems: [item.id] });
    handleClose();
  }, [handleClose, item.id, updateQuote]);

  const orderRequirements = useCallback(() => {
    toggle(DISTRIBUTOR_QUOTE_ORDER_REQUIREMENTS_PANEL, true, item.id);
    handleClose();
  }, [handleClose, item.id, toggle]);

  return (
    <If isTrue={!readonly}>
      <Container>
        <ThreeDotMenu
          handleClick={handleClick}
          active={!!item.expirationDate}
        />
        <Menu
          id={`quote-auxiliary-item-more-menu-${item.id}`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={orderRequirements}>
            <ListItemIconStyled>
              <SettingsOutlined />
            </ListItemIconStyled>
            <ListItemText>
              <FormattedMessage id="OTHER_OPTIONS" />
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={deleteAuxiliaryItem}>
            <ListItemIconStyled>
              <DeleteButton />
            </ListItemIconStyled>
            <ListItemText>
              <FormattedMessage id="DELETE" />
            </ListItemText>
          </MenuItem>
        </Menu>
      </Container>
    </If>
  );
};
