import { BreadcrumbsProvider } from "@/common/components/breadcrumbs/BreadcrumbsProvider";
import { DialogProvider } from "@/common/components/dialog/DialogProvider";
import { DrawerGlobalProvider } from "@/common/components/panel/DrawerGlobalProvider";
import { UserProvider } from "@/common/providers/UserProvider";
import { theme } from "@/config/mui.config";
import { DeliverySlipStatsProvider } from "@/contractor/pages/home/releases/pages/delivery-slips/providers/DeliverySlipStatsProvider";
import messages_en from "@/translations/messages.json";
import messages_es from "@/translations/messages_es.json";
import { Auth0Provider } from "@auth0/auth0-react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { PropsWithChildren, forwardRef, useCallback, useMemo } from "react";
import { HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import { useNavigate } from "react-router-dom";
import { StyleSheetManager } from "styled-components";
import { Authenticated } from "../auth/Authenticated";
import { NotificationsProvider } from "../components/notifications/providers/NotificationsProvider";
import { AuthenticationProvider } from "./AuthenticationProvider";
import { GraphqlProvider } from "./GraphqlProvider";
import { Locale, useLocale } from "./LocaleProvider";
import { ScrollingProvider } from "./ScrollingProvider";
import { SnackbarProvider } from "./SnackbarProvider";
import { TooltipProvider } from "./TooltipProvider";

export const AppProvider = forwardRef<HTMLElement, PropsWithChildren>(
  ({ children }, ref) => {
    const domain = import.meta.env.VITE_APP_AUTH_DOMAIN;
    const clientId = import.meta.env.VITE_APP_AUTH_CLIENT_ID;
    const audience = import.meta.env.VITE_APP_AUTH_AUDIENCE;
    const navigate = useNavigate();
    const { selectedLocale } = useLocale();

    const onRedirectCallback = useCallback(
      (appState?: { returnTo?: string }) => {
        navigate((appState && appState.returnTo) || window.location.pathname, {
          replace: true,
        });
      },
      [navigate],
    );

    const messages = useMemo(() => {
      return selectedLocale === Locale.en
        ? messages_en
        : {
            ...messages_en,
            ...messages_es,
          };
    }, [selectedLocale]);

    return (
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/home`,
          audience,
        }}
        useRefreshTokens
        useRefreshTokensFallback
        onRedirectCallback={onRedirectCallback}
        cacheLocation="localstorage"
      >
        <Authenticated>
          <AuthenticationProvider>
            <ThemeProvider theme={theme}>
              <StyledEngineProvider injectFirst>
                <StyleSheetManager
                  shouldForwardProp={(props) => props !== "ownerState"}
                >
                  <IntlProvider
                    messages={messages}
                    locale={selectedLocale}
                    defaultLocale="en"
                  >
                    <GraphqlProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <HelmetProvider>
                          <SnackbarProvider>
                            <UserProvider>
                              <NotificationsProvider>
                                <DialogProvider>
                                  <DrawerGlobalProvider>
                                    <BreadcrumbsProvider>
                                      <ScrollingProvider ref={ref}>
                                        <TooltipProvider>
                                          <DeliverySlipStatsProvider>
                                            {children}
                                          </DeliverySlipStatsProvider>
                                        </TooltipProvider>
                                      </ScrollingProvider>
                                    </BreadcrumbsProvider>
                                  </DrawerGlobalProvider>
                                </DialogProvider>
                              </NotificationsProvider>
                            </UserProvider>
                          </SnackbarProvider>
                        </HelmetProvider>
                      </LocalizationProvider>
                    </GraphqlProvider>
                  </IntlProvider>
                </StyleSheetManager>
              </StyledEngineProvider>
            </ThemeProvider>
          </AuthenticationProvider>
        </Authenticated>
      </Auth0Provider>
    );
  },
);
