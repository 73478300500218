import { Category } from "@/common/components/category-filter/CategoriesProvider";
import { CategoryItem } from "@/common/components/category-filter/CategoryItem";
import { SubMenu } from "@szhsin/react-menu";
import { FC } from "react";

type Props = {
  category: Category;
  onCategorySelect: (id: string) => void;
  selectedId?: string | null;
};

export const NestedCategoryMenuItem: FC<Props> = ({
  category,
  onCategorySelect,
  selectedId,
}) => (
  <SubMenu label={category.label}>
    {category.children.map((subCategory) => (
      <CategoryItem
        key={subCategory.label}
        category={subCategory}
        onCategorySelect={onCategorySelect}
        selectedId={selectedId}
      />
    ))}
  </SubMenu>
);
