import { Notes } from "@/common/components/notes/Notes";
import { ReleaseItemFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";

type Props = {
  item: Pick<ReleaseItemFieldsFragment, "id" | "notes" | "assets">;
  readonly: boolean;
};

export const QuoteNotes: FC<Props> = ({ item, readonly }) => {
  const { expandedItems, setExpandedItems } = useDistributorQuote();

  return (
    <Notes
      expandedItems={expandedItems}
      setExpandedItems={setExpandedItems}
      item={item}
      readonly={readonly}
      defaultValue=""
      hasNotesOrAttachments={!!item.notes || !!item.assets?.length}
    />
  );
};
