import { gql } from "@apollo/client";

export const ASSET_FIELDS = gql`
  fragment AssetFields on Asset {
    url
    type
    context
    pages {
      url
      thumbnailUrl
    }
    thumbnailUrl
  }
`;
