import { If } from "@/common/components/if/If";
import { PricePicker } from "@/common/components/price-picker/PricePicker";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useEstimatedItems } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useReleaseUpdate } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import { UpdateContractorReleaseInput } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";

const Container = tw.div`
  w-full
`;
const PricePickerStyled = tw(PricePicker)`
${({ readonly }: { readonly: boolean }) =>
  readonly ? "bg-transparent" : "bg-white"}
`;

type Props = {
  item: ExpandedReleaseItem;
  readonly?: boolean;
};

export const InvoiceReleaseItemUnitPriceInput: FC<Props> = ({
  item,
  readonly = false,
}) => {
  const { updateRelease, release } = useReleaseUpdate();
  const { setNewProjectEstimatedItem } = useEstimatedItems();
  const { invoice } = useInvoiceVerification();
  const { calcExtPrice } = usePriceCalculation();

  const updatePrice = useCallback(
    async (price: string | null) => {
      if (item.id) {
        if (release) {
          const input: UpdateContractorReleaseInput = {
            releaseId: release?.id,
            version: release?.version,
            updates: [
              {
                releaseItemId: item.id,
                unitPrice: price,
              },
            ],
          };
          await updateRelease(input);
        }
      } else {
        setNewProjectEstimatedItem([
          {
            key: "quantity",
            value: price,
          },
        ]);
      }
    },
    [release, item.id, updateRelease, setNewProjectEstimatedItem],
  );

  const differentPrices = useMemo(() => {
    const hint = invoice?.releaseItemHints?.find(
      (hint) => hint.releaseItem.id === item.id,
    );
    const extPrice = calcExtPrice(item.quantityDecimal, item.unitPrice);
    const invoiceExtPrice = calcExtPrice(
      hint?.invoiceItem?.quantityDecimal,
      hint?.invoiceItem?.unitPrice,
    );

    return (
      hint &&
      hint?.invoiceItem?.unitPrice &&
      hint?.invoiceItem?.unitPrice != "0" &&
      item?.unitPrice &&
      hint?.invoiceItem?.unitPrice !== item?.unitPrice &&
      extPrice !== invoiceExtPrice
    );
  }, [
    calcExtPrice,
    invoice?.releaseItemHints,
    item.id,
    item.quantityDecimal,
    item.unitPrice,
  ]);

  return (
    <Container>
      <If isTrue={!differentPrices}>
        <PricePickerStyled
          value={item.unitPrice}
          readonly={readonly}
          onBlur={updatePrice}
          className="text-center text-sm"
          hideErrorIcon
        />
      </If>
      <If isTrue={differentPrices}>
        <Tooltip
          id="unit-price"
          element={
            <PricePickerStyled
              value={item.unitPrice}
              readonly={readonly}
              onBlur={updatePrice}
              className="text-center text-sm text-red-500"
              hideErrorIcon
            />
          }
        >
          <FormattedMessage id="UNIT_PRICE_DOES_NOT_MATCH" />
        </Tooltip>
      </If>
    </Container>
  );
};
