/*
 * Returns Date object with date set to timezone-independent value.
 * Should be used to create Date objects that are used to display dates without time.
 */
export const getUTCDate = (timestamp: number | null | undefined) => {
  if (!timestamp) {
    return undefined;
  }
  const date = new Date(timestamp);
  date.setDate(date.getUTCDate());
  return date;
};
