import { Address } from "@/common/components/address/Address";
import { If } from "@/common/components/if/If";
import { OrgLogo } from "@/common/components/org-logo/OrgLogo";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { AddressFieldsFragment } from "@/generated/graphql";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Text = tw.div``;

type Props = {
  logoImageUrl?: string | null;
  orgName?: string;
  contactName?: string;
  address?: AddressFieldsFragment;
  index?: number;
  vendorNotSelectedKey?: string;
  imageWidth?: number;
  tooltip?: React.ReactNode;
};

export const VendorLogo: FC<Props> = ({
  logoImageUrl,
  orgName,
  contactName,
  address,
  index,
  vendorNotSelectedKey = "VENDOR_NOT_SELECTED",
  imageWidth = 35,
  tooltip,
}) => {
  return (
    <Tooltip
      element={
        <OrgLogo
          logoImageUrl={logoImageUrl}
          name={orgName as string}
          width={imageWidth}
        />
      }
      id={`popover-${index}`}
      position="top"
    >
      <If isTrue={tooltip}>{tooltip}</If>
      <If isTrue={!tooltip}>
        <>
          <If isTrue={Boolean(orgName)}>
            <Text>{orgName}</Text>
            <Text>{contactName}</Text>
            <Address address={address} />
          </If>
          <If isTrue={!orgName}>
            <FormattedMessage id={vendorNotSelectedKey} tagName={Text} />
          </If>
        </>
      </If>
    </Tooltip>
  );
};
