import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { If } from "@/common/components/if/If";
import { Select } from "@/common/components/select/components/single/Select";
import { IntegrationType } from "@/generated/graphql";
import { DeleteOutline } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

export type FileSourceType = IntegrationType | string;

export const PASTE_TABLE = "PASTE_TABLE";

const Option = tw.div`
  grid w-full grid-flow-col items-center justify-between h-10
`;

type FileSourceOption = {
  label: string;
  value: FileSourceType;
  isCustom?: boolean;
};

type CodesFileSourceSelectorProps = {
  onChange: (value: FileSourceType) => void;
  selectedFiles?: string[];
  value: string;
  disabled?: boolean;
  onRemoveCallback?: (option: FileSourceOption) => void;
};

export const CodesFileSourceSelector: FC<CodesFileSourceSelectorProps> = ({
  onChange,
  value,
  selectedFiles,
  disabled,
  onRemoveCallback,
}) => {
  const intl = useIntl();
  const fileSources = useMemo<FileSourceOption[]>(() => {
    const customFileFormat = {
      value: IntegrationType.Custom,
      label: intl.$t({ id: "CUSTOM_FILE" }),
    };

    let files: FileSourceOption[] = [];
    if (selectedFiles?.length) {
      files = selectedFiles.map((selectedFile) => ({
        value: selectedFile,
        label: selectedFile,
      }));
    }

    const pasteTable = {
      value: PASTE_TABLE,
      label: intl.$t({ id: "COPY_PASTE_TABLE" }),
    };

    return [customFileFormat, pasteTable, ...files];
  }, [intl, selectedFiles]);

  const isCustomOption = useCallback(
    (option: FileSourceOption) => {
      return fileSources.find((f) => f.value === option.value)?.isCustom;
    },
    [fileSources],
  );

  return (
    <Select
      value={value}
      testId="source-selector"
      placeholder={intl.$t({ id: "SELECT_COST_CODES_LIST_SOURCE" })}
      onChange={(value) => onChange(value as FileSourceType)}
      options={fileSources}
      getLabel={(option) => option.label}
      getValue={(option) => option.value}
      customRender={(option) => (
        <Option>
          <If isTrue={isCustomOption(option)}>
            {option.label}
            <If isTrue={onRemoveCallback}>
              <IconButtonBorderless
                onClick={() => onRemoveCallback && onRemoveCallback(option)}
              >
                <DeleteOutline />
              </IconButtonBorderless>
            </If>
          </If>
          <If isTrue={!isCustomOption(option)}>{option.label}</If>
        </Option>
      )}
      disabled={disabled}
    />
  );
};
