import { If } from "@/common/components/if/If";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useOrderTypesConfig } from "../../../../../../common/hooks/order-types-config/useOrderTypesConfig";
import { useRelease } from "../../providers/ReleaseProvider";

const Header = tw.div`flex gap-1`;
const PoNumber = tw.div`text-gray-600`;

export const ReleaseDetailsHeaderTitle: FC = () => {
  const { release } = useRelease();
  const { orderTypeConfig } = useOrderTypesConfig({ release });
  return (
    <Header>
      {orderTypeConfig.labels.buttons.complete} -{" "}
      <FormattedMessage
        id="ORDER_WITH_NUMBER"
        values={{ orderNumber: release?.sequenceNumber }}
      />
      <If isTrue={release?.poNumber}>
        <PoNumber>
          (
          <FormattedMessage id="PO_HASH" />
          {release?.poNumber})
        </PoNumber>
      </If>
    </Header>
  );
};
