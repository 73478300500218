import { FormatCurrencyType } from "@/common/components/value-currency/ValueCurrency";
import { PDF_FONT } from "@/common/const";
import { formattedDate } from "@/common/utils/dates/DateView";
import { DistributorQuoteFieldsFragment } from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { IntlShape } from "react-intl";

export const termsAndConditions = (
  doc: jsPDF,
  quote: DistributorQuoteFieldsFragment,
  intl: IntlShape,
  formatCurrency: FormatCurrencyType,
) => {
  if (
    quote.paymentTermDays ||
    quote.discount ||
    quote.expirationDate ||
    quote.notes ||
    quote.additionalCharges
  ) {
    const body: RowInput[] = [];
    if (quote.paymentTermDays) {
      body.push([
        {
          content: intl.$t({ id: "PAYMENT_TERMS" }),
          styles: { fontStyle: "bold" },
        },
        {
          content: intl.$t(
            { id: "PAYMENT_TERM_NET_WITH_DAYS" },
            { days: quote.paymentTermDays },
          ),
        },
      ]);
    }
    if (quote.expirationDate) {
      body.push([
        {
          content: intl.$t({
            id: "DISTRIBUTOR_QUOTE_TERMS_AND_CONDITION_QUOTE_EXPIRES",
          }),
          styles: { fontStyle: "bold" },
        },
        {
          content: formattedDate({ date: quote.expirationDate }) || "",
        },
      ]);
    }
    if (quote.notes) {
      body.push([
        {
          content: intl.$t({
            id: "NOTES_AND_TERMS",
          }),
          styles: { fontStyle: "bold" },
        },
        { content: quote.notes },
      ]);
    }
    quote.additionalCharges.forEach((charge) => {
      body.push([
        {
          content: charge.description,
          styles: { fontStyle: "bold" },
        },
        {
          content: formatCurrency(charge.amount),
        },
      ]);
    });
    if (quote.discount) {
      body.push([
        {
          content: intl.$t({
            id: "DISTRIBUTOR_QUOTE_QUOTE_DISCOUNT",
          }),
          styles: { fontStyle: "bold" },
        },
        {
          content: quote.discount.amount
            ? formatCurrency(quote.discount.amount)
            : intl.$t(
                { id: "QUOTE_PRINT_PERCENTAGE" },
                { value: quote.discount.percentage },
              ),
        },
      ]);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const topSpacing = (doc as any).lastAutoTable.finalY;

    autoTable(doc, {
      startY: topSpacing + 10,
      theme: "plain",
      showHead: false,
      styles: {
        font: PDF_FONT,
      },
      columnStyles: {
        0: { cellWidth: 50 },
      },
      body,
    });
  }
};
