import { addressToString } from "@/common/components/adress-location/AddressLocation";
import { PDF_FONT } from "@/common/const";
import { formattedDate } from "@/common/utils/dates/DateView";
import { getVendorContacts } from "@/common/utils/getVendorContacts";
import { getUserName } from "@/common/utils/users/getUserName";
import { BuyoutFieldsFragment } from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { IntlShape } from "react-intl";

const addProjectAndBuyoutInfo = (
  doc: jsPDF,
  buyout: BuyoutFieldsFragment,
  intl: IntlShape,
  x: number,
  y: number,
  width: number,
) => {
  doc.setFontSize(8);
  autoTable(doc, {
    startY: y - 4,
    theme: "plain",
    margin: { left: x },
    tableWidth: width,
    showHead: false,
    styles: {
      fontSize: 8,
    },
    columns: [{ dataKey: "label" }, { dataKey: "value" }],
    body: [
      [
        {
          content: `${intl.$t({ id: "BY" })}:`,
          styles: { fontStyle: "bold" },
        },
        getUserName(buyout.createdBy),
      ],
      [
        {
          content: `${intl.$t({ id: "PROJECT" })}:`,
          styles: { fontStyle: "bold" },
        },
        buyout.project.name,
      ],
      [
        {
          content: `${intl.$t({ id: "LOCATION" })}:`,
          styles: { fontStyle: "bold" },
        },
        addressToString(buyout.project.address),
      ],
      [
        {
          content: `${intl.$t({ id: "START_DATE_TITLE" })}`,
          styles: { fontStyle: "bold" },
        },
        formattedDate({
          date: new Date(buyout.project.startDate || 0),
        }),
      ],
      [
        {
          content: `${intl.$t({ id: "ORDER_TYPE" })}:`,
          styles: { fontStyle: "bold" },
        },
        buyout.releaseType.name,
      ],
      [
        {
          content: `${intl.$t({ id: "PO_HASH" })}:`,
          styles: { fontStyle: "bold" },
        },
        buyout.poNumber || intl.$t({ id: "NOT_SPECIFIED" }),
      ],
    ],
  });
};

const addReceiverInfo = (
  doc: jsPDF,
  buyout: BuyoutFieldsFragment,
  intl: IntlShape,
  x: number,
  y: number,
) => {
  doc.setFontSize(10).setFont(PDF_FONT, "", "bold");
  doc.text(intl.$t({ id: "VENDOR" }), x, y);
  doc.setFontSize(8).setFont(PDF_FONT, "", "normal");
  doc.text(
    getVendorContacts(
      buyout.preferredVendor?.contacts.filter(
        (c) => c.receivesBuyoutNotifications,
      ),
    ) || "",
    x,
    y + 7,
  );
  doc.text(buyout.sellerOrgLocation.org.name, x, y + 12);
  doc.text(addressToString(buyout.sellerOrgLocation.address), x, y + 17);
};

export const header = (
  doc: jsPDF,
  buyout: BuyoutFieldsFragment,
  intl: IntlShape,
) => {
  autoTable(doc, {
    startY: 30,
    theme: "plain",
    showHead: false,
    head: [["", "", ""]],
    styles: {
      font: PDF_FONT,
    },
    body: [[{ content: "", styles: { minCellHeight: 40 } }, "", ""]],
    didDrawCell: (data) => {
      if (data.row.index === 0 && data.row.section === "body") {
        if (data.column.index === 0) {
          addProjectAndBuyoutInfo(
            doc,
            buyout,
            intl,
            data.cell.x,
            data.cell.y,
            data.cell.width,
          );
        } else if (data.column.index === 1) {
          addReceiverInfo(doc, buyout, intl, data.cell.x + 40, data.cell.y);
        }
      }
    },
  });
  doc.setFontSize(10);
};
