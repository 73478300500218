import { CheckCircleOutline } from "@mui/icons-material";
import { FC } from "react";

type Props = {
  title: string;
};

export const ImportExternalResultTitle: FC<Props> = ({ title }) => {
  return (
    <div>
      <CheckCircleOutline className="mr-2 text-green-600" />
      {title}
    </div>
  );
};
