import { useGlobalError } from "@/common/hooks/useGlobalError";
import { goToExternalUrl } from "@/common/utils/browserUtils";
import {
  IntegrationType,
  useExportQuoteSelectionMutation,
} from "@/generated/graphql";
import { NoFunctionUndefinedPromise } from "@/types/NoFunction";
import { FC, PropsWithChildren, createContext, useContext } from "react";
import { useParams } from "react-router-dom";

type ProviderContextType = {
  loading: boolean;
  exportQuotes: (
    integration: IntegrationType,
    quoteID: string | null,
    quoteItemIDs: string[] | null,
    targetURL: string,
  ) => Promise<void>;
};

const ProviderContext = createContext<ProviderContextType>({
  exportQuotes: NoFunctionUndefinedPromise,
  loading: false,
});

export const ExportQuotesProvider: FC<PropsWithChildren> = ({ children }) => {
  const { id } = useParams();
  const { setError } = useGlobalError();
  const rfqId = id || "";
  const [exportQuoteSelectionMutation, { loading }] =
    useExportQuoteSelectionMutation();

  const exportQuotes = async (
    integration: IntegrationType,
    quoteID: string | null,
    quoteItemIDs: string[] | null,
    targetURL: string,
  ) => {
    try {
      const { data, errors } = await exportQuoteSelectionMutation({
        variables: {
          input: {
            rfqID: rfqId,
            integration,
            quoteID,
            quoteItemIDs,
            targetURL,
          },
        },
      });
      setError(errors);

      const url = data?.exportQuoteSelection.downloadURL;
      if (url) {
        goToExternalUrl(url);
      }
    } catch (errors) {
      setError(errors);
    }
  };

  return (
    <ProviderContext.Provider value={{ loading, exportQuotes }}>
      {children}
    </ProviderContext.Provider>
  );
};

export const useExportQuotes = (): ProviderContextType =>
  useContext(ProviderContext);
