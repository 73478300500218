import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { Popover } from "@/common/components/popover/Popover";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { useVendors } from "@/contractor/pages/admin/vendors/hooks/useVendors";
import { joinRfqVendors } from "@/contractor/pages/admin/vendors/utils/utils";
import {
  RfqFieldsFragment,
  RfqQuotesFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

type Props = {
  rfq: RfqQuotesFieldsFragment | RfqFieldsFragment | null | undefined;
  cancelRfq: (rfqId: string) => Promise<undefined | boolean>;
};

export const CancelRfqButton: FC<Props> = ({ rfq, cancelRfq }: Props) => {
  const intl = useIntl();
  const { openDialog } = useDialog();
  const { setSuccessAlert } = useSnackbar();
  const { vendors } = useVendors();

  const cancel = useCallback(async () => {
    if (rfq) {
      const result = await cancelRfq(rfq?.id);
      if (result) {
        setSuccessAlert(
          intl.$t(
            {
              id: "CANCEL_RFQ_SUCCESS",
            },
            {
              rfqNumber: strongify(rfq.clientIdentifier),
              vendors: strongify(joinRfqVendors(vendors, rfq)),
            },
          ),
        );
      }
    }
  }, [cancelRfq, intl, rfq, setSuccessAlert, vendors]);

  return (
    <Popover
      element={
        <OutlinedButton
          onClick={() =>
            openDialog({
              cancelButtonText: intl.$t({ id: "CANCEL" }),
              confirmButtonText: intl.$t({ id: "PROCEED" }),
              icon: <WarningIcon />,
              title: intl.$t(
                { id: "CONFIRM_CANCEL_RFQ_QUESTION" },
                { number: rfq?.clientIdentifier },
              ),
              text: intl.$t({ id: "CONFIRM_CANCEL_RFQ_DETAILS" }),
              handleConfirm: () => cancel(),
            })
          }
          disabled={!!rfq?.nextRevision}
        >
          <FormattedMessage id="CANCEL_RFQ" />
        </OutlinedButton>
      }
      $arrow
      $ellipsis={!rfq?.nextRevision}
      id="cancel-rfq-popover"
      position="top"
    >
      <FormattedMessage id="RFQ_REQUEST_CHANGES_DISABLED" />
    </Popover>
  );
};
