import { If } from "@/common/components/if/If";
import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { ControlledProps } from "@/common/components/textfield-controlled/TextFieldControlled";
import { useProjectStatusOptions } from "@/common/hooks/useProjectStatusOptions";
import { ProjectStatus } from "@/generated/graphql";
import { InputLabel } from "@mui/material";
import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-col`;

type Props = ControlledProps & {
  isNewProject?: boolean;
  includeLabel?: boolean;
  disabled?: boolean;
};

export const ProjectStatusSelector: FC<Props> = ({
  name,
  includeLabel = true,
  isNewProject = false,
  disabled,
}) => {
  const intl = useIntl();
  const { getValues } = useFormContext();
  const statusName = getValues(name);
  const projectStatusOptions = useProjectStatusOptions();

  const statuses = useMemo(() => {
    if (disabled) {
      return projectStatusOptions.filter(
        (option) => option.name === statusName,
      );
    }
    if (isNewProject) {
      return projectStatusOptions.filter(
        (option) =>
          option.id === ProjectStatus.Active ||
          option.id === ProjectStatus.Awarded,
      );
    }
    return projectStatusOptions;
  }, [disabled, isNewProject, statusName, projectStatusOptions]);

  return (
    <Container>
      <If isTrue={includeLabel}>
        <InputLabel htmlFor="component-simple" className="mb-2">
          <FormattedMessage id="PROJECT_STAGE_QUESTION" />
        </InputLabel>
      </If>
      <SelectControlled
        name={name}
        options={statuses}
        getLabel={(option) => option.name}
        getValue={(option) => option.id}
        label={intl.$t({ id: "STAGE" })}
        disabled={disabled}
      />
    </Container>
  );
};
