import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import { WizardModalPage } from "@/common/components/wizard-modal/WizardModal";
import { CompletedStep } from "@/common/components/wizard-modal/components/CompletedStep";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ImportCostCodesMutation,
  SourceSystem,
  ViewerCostCodesDocument,
  useImportCostCodesMutation,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { AnimatedLoading } from "../../../../../home/invoices/pages/scanned-invoices/components/splitting-invoices/steps/AnimatedLoading";
import { ImportExternalCostCodesImportResults } from "../components/import-external-codes/steps/ImportExternalCostCodesImportResults";
import { ImportExternalCostCodesTable } from "../components/import-external-codes/steps/ImportExternalCostCodesTable";

type ProviderContextType = {
  pages: WizardModalPage[];
  sourceSystem: SourceSystem | null;
  modalOpened: boolean;
  openModal: ({ sourceSystem }: { sourceSystem: SourceSystem }) => void;
  closeModal: () => void;

  selectedExternalCostCodes: string[];
  setSelectedExternalCostCodes: (id: string[]) => void;

  importAllExternalItems: boolean;
  setImportAllExternalItems: (value: boolean) => void;

  totalExternalCostCodesCount: number;
  setTotalExternalCostCodesCount: (count: number) => void;

  importedCostCodes: ImportCostCodesMutation["importCostCodes"] | null;
};

const ProviderContext = createContext<ProviderContextType>({
  pages: [],
  sourceSystem: null,
  modalOpened: false,
  openModal: NoFunction,
  closeModal: NoFunction,

  selectedExternalCostCodes: [],
  setSelectedExternalCostCodes: NoFunction,

  importAllExternalItems: false,
  setImportAllExternalItems: NoFunction,

  totalExternalCostCodesCount: 0,
  setTotalExternalCostCodesCount: NoFunction,

  importedCostCodes: null,
});

export const ImportExternalCostCodesProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const intl = useIntl();
  const { setError } = useGlobalError();
  const { moveToNextNestedStep, moveToPreviousNestedStep, setNestedStep } =
    useNestedStepper();

  const [sourceSystem, setSourceSystem] = useState<SourceSystem | null>(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [importAllExternalItems, setImportAllExternalItems] = useState(false);

  const [totalExternalCostCodesCount, setTotalExternalCostCodesCount] =
    useState(0);
  const [selectedExternalCostCodes, setSelectedExternalCostCodes] = useState<
    string[]
  >([]);
  const [importedCostCodes, setImportedCostCodes] = useState<
    ImportCostCodesMutation["importCostCodes"] | null
  >(null);

  const openModal = useCallback(
    ({ sourceSystem }: { sourceSystem: SourceSystem }) => {
      setSourceSystem(sourceSystem);
      setModalOpened(true);
    },
    [],
  );

  const resetModal = useCallback(() => {
    setSourceSystem(null);
    setNestedStep(0);
  }, [setNestedStep]);

  const closeModal = useCallback(() => {
    setModalOpened(false);
    resetModal();
  }, [resetModal]);

  const [importCostCodesMutation] = useImportCostCodesMutation();
  const importSelectedCostCodes = useCallback(async () => {
    try {
      if (sourceSystem) {
        moveToNextNestedStep();
        const { data, errors } = await importCostCodesMutation({
          variables: {
            input: {
              externalCostCodeIds: selectedExternalCostCodes,
              sourceSystem,
            },
          },
          refetchQueries: [
            {
              query: ViewerCostCodesDocument,
            },
          ],
        });
        if (errors) {
          moveToPreviousNestedStep();
          setError(errors);
        }
        if (data?.importCostCodes) {
          setImportedCostCodes(data.importCostCodes);
          moveToNextNestedStep();
        }
      }
    } catch (error) {
      moveToPreviousNestedStep();
      setError(error);
    }
  }, [
    sourceSystem,
    selectedExternalCostCodes,
    importCostCodesMutation,
    moveToPreviousNestedStep,
    moveToNextNestedStep,
    setError,
  ]);

  const pages: WizardModalPage[] = useMemo(
    () => [
      {
        title: null,
        pages: [
          {
            component: <ImportExternalCostCodesTable />,
            footerButtonsConfig: [
              {
                type: "text",
                text: intl.$t(
                  { id: "OUT_OF_SELECTED" },
                  {
                    count: selectedExternalCostCodes.length,
                    total: totalExternalCostCodesCount,
                  },
                ),
                className: "flex mr-4 items-center",
                hidden:
                  selectedExternalCostCodes.length === 0 &&
                  totalExternalCostCodesCount === 0,
              },
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "flex-1 mr-2",
              },
              {
                type: "primary",
                onClick: importSelectedCostCodes,
                text: intl.$t({ id: "IMPORT" }),
                className: "flex-1",
                disabled: selectedExternalCostCodes.length === 0,
              },
            ],
          },
          {
            component: (
              <AnimatedLoading loading text={intl.$t({ id: "IMPORTING" })} />
            ),
            hideFooter: true,
            classNames: {
              wrapper: "mb-0",
            },
          },
          {
            component: (
              <CompletedStep text={intl.$t({ id: "IMPORT_COMPLETED" })} />
            ),
            onModalClose: moveToNextNestedStep,
            hideFooter: true,
            classNames: {
              wrapper: "mb-0",
            },
          },
          {
            component: <ImportExternalCostCodesImportResults />,
            footerButtonsConfig: [
              {
                type: "outlined",
                onClick: closeModal,
                text: intl.$t({ id: "CLOSE" }),
                className: "w-60",
              },
            ],
          },
        ],
      },
    ],
    [
      intl,
      selectedExternalCostCodes.length,
      totalExternalCostCodesCount,
      closeModal,
      importSelectedCostCodes,
      moveToNextNestedStep,
    ],
  );

  return (
    <ProviderContext.Provider
      value={{
        modalOpened,
        sourceSystem,
        pages,
        openModal,
        closeModal,

        selectedExternalCostCodes,
        setSelectedExternalCostCodes,

        importAllExternalItems,
        setImportAllExternalItems,

        totalExternalCostCodesCount,
        setTotalExternalCostCodesCount,

        importedCostCodes,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useImportExternalCostCodes = () => useContext(ProviderContext);
