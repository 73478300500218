import {
  BuyoutItemFieldsFragment,
  CostCodeFieldsFragment,
  DistributorBuyoutItemFieldsFragment,
} from "@/generated/graphql";
import { getPhaseCodesByBuyoutItem } from "./getPhaseCodesByBuyoutItem";

export const getPhaseCodesByBuyoutItems = (
  buyoutItems: (
    | DistributorBuyoutItemFieldsFragment
    | BuyoutItemFieldsFragment
  )[],
  unassignedPhaseCode: Omit<CostCodeFieldsFragment, "mappings">,
  isAddMode?: boolean,
) => {
  const phaseCodes: Omit<CostCodeFieldsFragment, "mappings">[] = [];

  buyoutItems.forEach((buyoutItem) => {
    const phaseCode = getPhaseCodesByBuyoutItem(buyoutItem);
    if (phaseCode && !phaseCodes.map((c) => c.id).includes(phaseCode.id)) {
      phaseCodes.push(phaseCode);
    }
  });
  phaseCodes.sort((a, b) => a.code.localeCompare(b.code));
  if (
    buyoutItems.some((i) => i?.tags.filter((i) => i.hasMapping).length === 0) ||
    isAddMode
  ) {
    phaseCodes.push(unassignedPhaseCode);
  }
  return phaseCodes;
};
