import { NumericalInputControlled } from "@/common/components/numerical-input/NumericalInputControlled";
import { ControlledProps } from "@/common/components/textfield-controlled/TextFieldControlled";
import { TextFieldProps } from "@/common/components/textfield/TextField";
import { FC } from "react";
import { useIntl } from "react-intl";
import { NumberFormatValues } from "react-number-format";
import tw from "tailwind-styled-components";

type Props = TextFieldProps & ControlledProps;

const NumericalInputStyled = tw(NumericalInputControlled)`w-full bg-white`;

export const ProjectBudgetTextField: FC<Props> = (props) => {
  const intl = useIntl();

  return (
    <NumericalInputStyled
      label={intl.$t({ id: "PROJECT_BUDGET_MATERIALS" })}
      includeCurrency
      isAllowed={(values: NumberFormatValues) => {
        const { floatValue } = values;
        return !floatValue || floatValue > 0;
      }}
      {...props}
    />
  );
};
