import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { SuccessModal } from "@/common/components/success-modal/SuccessModal";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { routes } from "@/config/routes";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AssetFieldsFragment,
  useUpdateContractorReleaseMutation,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useOrderFromNote } from "../../../../../providers/OrderFromNoteProvider";
import {
  NoteDocumentProvider,
  useNoteDocument,
} from "../../providers/NoteDocumentProvider";
import { NoteDocumentImport } from "../NoteDocumentImport";

const AddNoteDocument = tw.div`
  grid grid-flow-col justify-start gap-2 items-center text-sm
`;
const Container = tw.div`
  grid p-4 gap-4
`;

const ReplaceNoteFileProviders: FC = () => {
  const intl = useIntl();
  const [updateContractorRelease, { loading: updating }] =
    useUpdateContractorReleaseMutation();
  const { release } = useRelease();
  const { setError } = useGlobalError();
  const { createNoteDocumentWithAsset, setAsset, noteDocument } =
    useNoteDocument();
  const { setWasDocumentReplaced } = useOrderFromNote();
  const navigate = useNavigate();
  const { openDialog } = useDialog();

  const handleNoteDocumentChange = (asset: AssetFieldsFragment) => {
    setAsset(asset);
  };

  const onSave = async () => {
    if (release && release.project) {
      const document = await createNoteDocumentWithAsset(
        release.project.location.id,
      );

      if (!document) {
        return;
      }

      try {
        const { errors, data } = await updateContractorRelease({
          variables: {
            input: {
              releaseId: release.id,
              version: release.version,
              noteDocumentId: document.id,
            },
          },
        });
        setError(errors);
        if (data) {
          setWasDocumentReplaced(true);
          navigate(
            generatePath(routes.editDeliveryFromNote, {
              noteDocumentId: document.id,
              deliveryId: release.id,
            }),
          );
        }
      } catch (error) {
        setError(error);
      }
    } else if (noteDocument) {
      const document = await createNoteDocumentWithAsset(
        noteDocument?.orgLocation?.id,
      );
      if (document) {
        openDialog({
          content: (
            <SuccessModal message={intl.$t({ id: "NOTE_DOCUMENT_REPLACED" })} />
          ),
          closingTimer: DIALOG_AUTO_CLOSE_TIMER,
        });
        setTimeout(() => {
          navigate({
            pathname: generatePath(routes.deliveryFromNote, {
              noteDocumentId: document?.id,
            }),
          });
        }, DIALOG_AUTO_CLOSE_TIMER);
      }
    }
  };

  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <AddNoteDocument onClick={() => togglePanel(true)}>
          <FormattedMessage id="REPLACE_THIS_NOTE" />
          <PrimaryButton $small>
            <FormattedMessage id="REPLACE" />
          </PrimaryButton>
        </AddNoteDocument>
      )}
      content={(togglePanel) => (
        <OverlayPanel
          onSave={onSave}
          saveLabel={intl.$t({ id: "REPLACE" })}
          title={intl.$t({ id: "REPLACE" })}
          onCancel={() => togglePanel(false)}
          className="px-0"
          isLoading={updating}
        >
          <Container>
            <NoteDocumentImport onChange={handleNoteDocumentChange} />
          </Container>
        </OverlayPanel>
      )}
    />
  );
};

export const ReplaceNoteFile: FC = () => {
  return (
    <NoteDocumentProvider>
      <ReplaceNoteFileProviders />
    </NoteDocumentProvider>
  );
};
