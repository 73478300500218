import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import {
  NEW_UOM_ID,
  UomPicker,
} from "@/common/components/uom-picker/UomPicker";
import { DEFAULT_AUXILIARY_ITEM_UOM_MNEMONIC } from "@/common/const";

import { useUomOptions } from "@/common/hooks/useUomOptions";
import {
  OrgMaterialFieldsFragment,
  VendorPriceFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useMaterialPrices } from "../../providers/MaterialPricesProvider";

type Props = {
  item: OrgMaterialFieldsFragment;
  price?: VendorPriceFieldsFragment;
  error?: boolean;
  readonly: boolean;
};

export const MaterialPriceUom: FC<Props> = ({
  item,
  price,
  error,
  readonly,
}) => {
  const { updateMaterial } = useMaterialPrices();
  const [uomId, setUomId] = useState<string | undefined>(
    price?.uom.id || item.defaultEstimateUom.id,
  );
  const { uoms } = useUomOptions();
  const intl = useIntl();

  useEffect(() => {
    if (price?.uom.id) {
      setUomId(price?.uom.id);
    } else {
      setUomId(
        item.defaultEstimateUom.id ||
          uoms.find((u) => u.mnemonic === DEFAULT_AUXILIARY_ITEM_UOM_MNEMONIC)
            ?.id,
      );
    }
  }, [price, item, uoms]);

  const saveUom = useCallback(
    (newValue: string | null) => {
      const uom = uoms.find((u) => u.id === newValue) || {
        id: NEW_UOM_ID,
        pluralDescription: newValue || "",
      };
      setUomId(uom.id);
      updateMaterial(item.id, {
        vendorPriceId: price?.id || "",
        uom: newValue || "",
      });
    },
    [price, updateMaterial, item, uoms],
  );

  return (
    <NotNullableRenderer value={price?.uom || !readonly}>
      <UomPicker
        uom={uoms.find((u) => u.id === uomId)}
        saveUom={saveUom}
        staticText={readonly}
        placeholder={uomId ? "" : intl.$t({ id: "SELECT_UOM" })}
        error={error && !uomId}
        centered
      />
    </NotNullableRenderer>
  );
};
