import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { If } from "@/common/components/if/If";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Price } from "@/common/components/price/Price";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ProductCategoryCount } from "@/common/components/product-category/ProductCategoryCount";
import { QuantityPicker } from "@/common/components/quantity-picker/QuantityPicker";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  Base,
  SmFixedContainer,
  SmdFixedContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { GroupByCostCodeToggle } from "@/contractor/pages/home/release/components/group-by-costcode/GroupByCostCodeToggle";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useOrderItemPoItemReferences } from "@/contractor/pages/home/release/pages/specify-details/hooks/useOrderItemPoItemReferences";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { filterByMaterialNamePriceAndQty } from "@/contractor/pages/home/release/utils/filters/filterByMaterialNamePriceAndQty";
import {
  EstimatedItemFieldsFragment,
  ReleaseFieldsFragment,
} from "@/generated/graphql";
import { ErrorOutlineOutlined, InfoOutlined } from "@mui/icons-material";
import Decimal from "decimal.js";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../providers/InvoiceMatchedOrderProvider";
import { InvoiceExtPriceInput } from "./InvoiceExtPriceInput";
import { InvoiceItemQuantityInput } from "./InvoiceItemQuantityInput";
import { InvoiceItemReceivedQuantity } from "./InvoiceItemReceivedQuantity";
import { InvoiceReleaseItemCheckbox } from "./InvoiceReleaseItemCheckbox";
import { InvoiceReleaseItemCostCodeCheckbox } from "./InvoiceReleaseItemCostCodeCheckbox";
import { InvoiceReleaseItemMaterial } from "./InvoiceReleaseItemMaterial";
import { InvoiceReleaseItemUnitPriceInput } from "./InvoiceReleaseItemUnitPriceInput";
import { InvoiceReleaseItemWholeSelectionCheckbox } from "./InvoiceReleaseItemWholeSelectionCheckbox";
import { InvoiceReleaseItemZoneCheckbox } from "./InvoiceReleaseItemZoneCheckbox";
import { InvoicedItemExtPriceGroup } from "./InvoicedItemExtPriceGroup";

const RequestedItemsContainer = tw(Base)`
  col-span-full flex-1
`;
const FixedContainer = tw(SmdFixedContainer)`line-clamp-2 xs:basis-1/2 xs:w-52`;
const ReadonlyField = tw.div`flex flex-col items-center text-xs`;
const CheckboxContainer = tw(XxsFixedContainer)`pl-0`;
const InvoiceSoFarLabel = tw.span`text-[9px] text-gray-600`;
const InvoiceQtyContainer = tw(SmdFixedContainer)`lg:w-28 lg:basis-28`;

export const useInvoiceReleaseConfiguration = (
  release?: ReleaseFieldsFragment | null,
  readonly?: boolean,
  items: ExpandedReleaseItem[] = [],
  includeToggle?: boolean,
  includeInvoiceQty: boolean = true,
): Array<GridCol<ExpandedReleaseItem, EstimatedItemFieldsFragment>> => {
  const { calcExtPrice } = usePriceCalculation();
  const { matchedOrderViewState } = useInvoiceMatchedOrder();
  const { hasOrderItemPoItemReferences } = useOrderItemPoItemReferences();
  const { connectedSourceSystem } = useOrgSettings();
  const intl = useIntl();
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);

  return useMemo(
    () => [
      {
        wrapper: CheckboxContainer,
        position: "center",
        item: ({ item }) => <InvoiceReleaseItemCheckbox item={item} />,
        subgroup: (category) => (
          <InvoiceReleaseItemCostCodeCheckbox costCode={category} />
        ),
        group: (category) => <InvoiceReleaseItemZoneCheckbox zone={category} />,
        header: <InvoiceReleaseItemWholeSelectionCheckbox items={items} />,
        hidden: readonly,
      },
      {
        wrapper: RequestedItemsContainer,
        item: ({ item, count }) => (
          <InvoiceReleaseItemMaterial
            item={item}
            count={count}
            projectId={release?.project?.id}
            extraDetails={
              <If isTrue={hasOrderItemPoItemReferences(item, release)}>
                <Tooltip
                  id="cannot-post-invoice-against-item"
                  element={<ErrorOutlineOutlined className="text-red-500" />}
                >
                  <FormattedMessage
                    id="YOU_CANNOT_POST_INVOICE_AGAINST_ITEM"
                    values={{
                      sourceSystem: connectedSourceSystem
                        ? intl.$t({
                            id: `INTEGRATION_${connectedSourceSystem}`,
                          })
                        : "",
                    }}
                  />
                </Tooltip>
              </If>
            }
          />
        ),
        header: (
          <>
            <FormattedMessage id="ITEM_DESCRIPTION" />
            <If isTrue={includeToggle}>
              <GroupByCostCodeToggle />
            </If>
          </>
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
            projectId={release?.project?.id}
          />
        ),
        searchItemFn: filterByMaterialNamePriceAndQty,
      },
      {
        header: <FormattedMessage id="INVOICE_ORDERED" />,
        wrapper: FixedContainer,
        position: "center",
        item: ({ item }) => {
          const itemIsLumpSum = isLumpSumItem(item);
          return (
            <ValueUnit
              value={new Decimal(item.quantityDecimal)
                .mul(itemIsLumpSum ? (item.unitPrice ?? 0) : 1)
                .toString()}
              uom={item.uom}
              renderAsPrice={itemIsLumpSum}
            />
          );
        },
        hideGroupIfEmpty: true,
        hideSubGroupIfEmpty: true,
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => <InvoiceItemReceivedQuantity item={item} />,
        position: "center",
        header: <FormattedMessage id="RECEIVED" />,
        group: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} $bold />
        ),
        subgroup: (category) => (
          <ProductCategoryCount itemsLength={category.items.length} />
        ),
      },
      {
        header: <FormattedMessage id="UNIT_PRICE" />,
        wrapper: SmFixedContainer,
        position: "center",
        item: ({ item }) => {
          if (isLumpSumItem(item)) {
            return (
              <Tooltip
                element={<InfoOutlined className="text-blue-500" />}
                id="lump-sum-unit-price-tooltip"
              >
                <FormattedMessage id="LUMP_SUM_ITEM_UNIT_PRICE_TOOLTIP" />
              </Tooltip>
            );
          }
          return (
            <ReadonlyField>
              <InvoiceReleaseItemUnitPriceInput item={item} readonly />
            </ReadonlyField>
          );
        },
        hidden: !hasPermissions,
      },
      ...(includeInvoiceQty
        ? [
            {
              header: <FormattedMessage id="INVOICE_QUANTITY" />,
              wrapper: InvoiceQtyContainer,
              position: "center",
              item: ({ item, readonly }) => (
                <NotNullableRenderer value={!isLumpSumItem(item)}>
                  <>
                    <If
                      isTrue={
                        matchedOrderViewState ===
                          MatchedOrderViewState.EDIT_INVOICE_COVERAGES &&
                        (item.invoiceItems || [])?.length > 0
                      }
                    >
                      <ReadonlyField>
                        <InvoiceItemQuantityInput item={item} key={item.id} />
                      </ReadonlyField>
                    </If>
                    <If
                      isTrue={
                        matchedOrderViewState !==
                          MatchedOrderViewState.EDIT_INVOICE_COVERAGES ||
                        (!readonly && item.invoicedQuantity)
                      }
                    >
                      <NotNullableRenderer
                        value={
                          !isNaN(Number(item.invoicedQuantity)) &&
                          item.invoiceItems.length > 0
                        }
                      >
                        <ReadonlyField>
                          <QuantityPicker
                            id="invoiced-so-far"
                            staticText
                            quantity={item.invoiceItems?.[0]?.quantity}
                          />
                          <FormattedMessage
                            id="QUANTITY_SO_FAR"
                            tagName={InvoiceSoFarLabel}
                            values={{
                              value: new Decimal(
                                item.invoiceItems?.[0]?.quantitySoFar || "0",
                              )
                                .plus(item.invoiceItems?.[0]?.quantity || "0")
                                .toString(),
                            }}
                          />
                        </ReadonlyField>
                      </NotNullableRenderer>
                    </If>
                  </>
                </NotNullableRenderer>
              ),
            } as GridCol<ExpandedReleaseItem, EstimatedItemFieldsFragment>,
          ]
        : []),
      ...(includeInvoiceQty
        ? [
            {
              header: <FormattedMessage id="INVOICE_EXT_PRICE" />,
              wrapper: SmFixedContainer,
              position: "center",
              item: ({ item }) => (
                <If isTrue={(item.invoiceItems || [])?.length > 0}>
                  <ReadonlyField>
                    {matchedOrderViewState ===
                      MatchedOrderViewState.EDIT_INVOICE_COVERAGES &&
                    isLumpSumItem(item) ? (
                      <InvoiceExtPriceInput item={item} key={item.id} />
                    ) : (
                      <Price
                        price={calcExtPrice(
                          item.invoiceItems?.[0]?.quantity,
                          item.unitPrice,
                        )}
                      />
                    )}
                  </ReadonlyField>
                </If>
              ),
              subgroup: (category) => (
                <InvoicedItemExtPriceGroup items={category.items} />
              ),
              hidden: !hasPermissions,
            } as GridCol<ExpandedReleaseItem, EstimatedItemFieldsFragment>,
          ]
        : []),
    ],
    [
      calcExtPrice,
      connectedSourceSystem,
      hasOrderItemPoItemReferences,
      hasPermissions,
      includeInvoiceQty,
      includeToggle,
      intl,
      items,
      matchedOrderViewState,
      readonly,
      release,
    ],
  );
};
