import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useOrgSettingsExtendedQuery } from "@/generated/graphql";
import { useCallback } from "react";

export const useOrgSettingsExtended = () => {
  const { data, loading, error, refetch } = useOrgSettingsExtendedQuery();
  const { setError } = useGlobalError();
  useErrorEffect(error);

  const refetchSettings = useCallback(async () => {
    const { errors } = await refetch();
    setError(errors);
  }, [refetch, setError]);

  return {
    integrations: data?.viewer?.org.settings?.integrations || null,
    orgId: data?.viewer?.org.id || "",
    loading,
    refetchSettings,
    connectedAccountingSystem:
      data?.viewer?.org.settings?.integrations?.accounting.find(
        (a) => a.enabled,
      ),
    connectedSourceSystem:
      data?.viewer?.org.settings?.integrations?.sourceSystems.find(
        (s) => s.connected,
      ),
  };
};
