import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { NoResults } from "@/common/components/no-results/NoResults";
import { BuyoutReleaseFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { ReleaseInfoView } from "./ReleaseInfoView";
import { ReleasesListFooter } from "./ReleasesListFooter";

const Container = tw.div`mt-6 grid`;

type Props = {
  releases?: BuyoutReleaseFieldsFragment[];
};

export const ReleasesList: FC<Props> = ({ releases }) => {
  return (
    <Container>
      <ListRenderer
        hasItemsCondition={releases && releases.length > 0}
        emptyList={<NoResults translationKey="NO_RELEASES" />}
      >
        {releases?.map((release) => (
          <ReleaseInfoView key={release.id} release={release} />
        ))}
      </ListRenderer>
      <ReleasesListFooter />
    </Container>
  );
};
