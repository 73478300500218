import { If } from "@/common/components/if/If";
import { usePoNumberingSettingsCheck } from "@/common/components/po-numbering-settings-check/usePoNumberingSettingsCheck";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { DateView, SHORT_DATE_OPTION } from "@/common/utils/dates/DateView";
import { getUserName } from "@/common/utils/users/getUserName";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { SourceSystemWrapper } from "@/contractor/pages/home/release/components/connections/components/common/SourceSystemWrapper";
import { InvoiceSummaryFieldsFragment } from "@/generated/graphql";
import { ArrowForward } from "@mui/icons-material";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
const Container = tw.div`flex flex-row gap-1`;
const Rounded = tw.div<{
  $disabled: boolean;
  $notRequired?: boolean;
  $failed?: boolean;
}>`
${({ $disabled }) =>
  $disabled ? "bg-white text-gray-500" : "bg-green-300 text-black"}
${({ $failed }) => $failed && "bg-red-500 text-white"}
${({ $notRequired }) => $notRequired && "bg-gray-200 text-black"}
rounded-full p-3 text-xs border border-gray-300 w-5 h-5 flex items-center justify-center`;
const IconContainer = tw.div`rounded-full border border-gray-300 border-dashed`;
const Arrow = tw(
  ArrowForward,
)`transition-all duration-500 animate-slide text-blue-500`;
const Text = tw.div`flex flex-full`;
const ErrorMessage = tw.div`whitespace-break-spaces`;

export const InvoiceWarnings: FC<{ invoice: InvoiceSummaryFieldsFragment }> = ({
  invoice,
}) => {
  const { settings } = useOrgSettings();
  const { includePoNumbering } = usePoNumberingSettingsCheck();
  const isRequiredReceipt = useMemo(() => {
    return (
      settings?.integrations.sourceSystems.find((system) => system.connected)
        ?.postInventoryReceipts ||
      settings?.integrations.accounting.find((accounting) => accounting.enabled)
        ?.postInventoryReceipts
    );
  }, [settings?.integrations.accounting, settings?.integrations.sourceSystems]);

  const tooltipTranslationKey = useMemo(() => {
    if (
      invoice.link &&
      invoice.link.exportedAt === null &&
      invoice.link.failedAt === null
    ) {
      return "INVOICE_EXPORT_IN_PROGRESS";
    }
    if (invoice.link?.failedAt) {
      return "INVOICE_EXPORT_FAILED";
    }
    if (invoice.exportedAt || invoice.link?.exportedAt) {
      if (invoice.link?.createdBy) {
        return "INVOICE_EXPORTED_AT_BY";
      }
      return "INVOICE_EXPORTED_AT";
    }
    return "INVOICE_NOT_EXPORTED";
  }, [invoice.link, invoice.exportedAt]);

  return (
    <SourceSystemWrapper anyIntegrationType anySourceSystem>
      <Container>
        <If
          isTrue={
            includePoNumbering ||
            invoice.release?.exportedAt ||
            invoice.release?.poLink?.syncedAt
          }
        >
          <Tooltip
            id={`po-export-${invoice.id}`}
            element={
              <Rounded
                $disabled={
                  !invoice.release?.exportedAt &&
                  !invoice.release?.poLink?.syncedAt
                }
              >
                P
              </Rounded>
            }
          >
            <FormattedMessage
              id={
                invoice.release?.exportedAt || invoice.release?.poLink?.syncedAt
                  ? invoice.release?.poLink?.createdBy
                    ? "PO_EXPORTED_AT_BY"
                    : "PO_EXPORTED_AT"
                  : "PO_NOT_EXPORTED"
              }
              values={{
                date: (
                  <DateView
                    date={
                      invoice.release?.exportedAt ||
                      invoice.release?.poLink?.syncedAt
                    }
                    options={SHORT_DATE_OPTION}
                  />
                ),
                username: getUserName(invoice.release?.poLink?.createdBy),
              }}
            />
          </Tooltip>
        </If>
        <If isTrue={isRequiredReceipt}>
          <Tooltip
            id={`receipt-posted-${invoice.id}`}
            element={
              <Rounded
                $notRequired={!invoice.release?.requiresInventoryReceipt}
                $disabled={!invoice.release?.receiptPostedAt}
              >
                R
              </Rounded>
            }
          >
            {invoice?.release?.requiresInventoryReceipt ? (
              <FormattedMessage
                id={
                  invoice.release?.receiptPostedAt
                    ? "RECEIPT_POSTED_AT"
                    : "RECEIPT_NOT_POSTED"
                }
                values={{
                  date: (
                    <DateView
                      date={invoice.release?.receiptPostedAt}
                      options={SHORT_DATE_OPTION}
                    />
                  ),
                }}
              />
            ) : (
              <FormattedMessage id="RECEIPT_NOT_REQUIRED" />
            )}
          </Tooltip>
        </If>
        <Tooltip
          id={`export-invoice-${invoice.id}`}
          element={
            invoice.link?.exportedAt === null &&
            invoice.link?.failedAt === null ? (
              <IconContainer>
                <Arrow />
              </IconContainer>
            ) : (
              <Rounded
                $disabled={!invoice.exportedAt && !invoice.link?.exportedAt}
                $failed={!!invoice.link?.failedAt}
              >
                I
              </Rounded>
            )
          }
        >
          <FormattedMessage
            id={tooltipTranslationKey}
            values={{
              date: (
                <DateView
                  date={
                    invoice.exportedAt ||
                    invoice.link?.exportedAt ||
                    invoice.link?.failedAt
                  }
                  options={SHORT_DATE_OPTION}
                />
              ),
              message: (
                <Text>
                  <FormattedMessage
                    id="AUTOMATED_EXPORT_FAILED_BECAUSE_OF"
                    values={{
                      error: invoice.link?.failedMessage,
                    }}
                    tagName={ErrorMessage}
                  />
                </Text>
              ),
              username: getUserName(invoice.link?.createdBy),
            }}
          />
        </Tooltip>
      </Container>
    </SourceSystemWrapper>
  );
};
