import { If } from "@/common/components/if/If";
import { RfqProductBrand } from "@/common/components/rfq-product-view/RfqProductBrand";
import { useIssueTypes } from "@/common/hooks/useIssueTypes";
import {
  DistributorReleaseItemFieldsFragment,
  ReleaseItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import tw from "tailwind-styled-components";
import { AssetItem } from "../asset-item/AssetItem";

type Props = {
  item: ReleaseItemFieldsFragment | DistributorReleaseItemFieldsFragment;
};

const Row = tw.div`flex flex-row justify-between w-full`;
const ValueRow = tw(Row)`my-1`;
const DashedLine = tw.div`flex w-full border-b border-dashed mt-4`;
const NameView = tw.div`mr-4`;
const Name = tw.div`text-sm font-light`;
const QuantityView = tw.div`text-right`;
const Units = tw.div`font-light text-sm`;
const Quantity = tw.div`font-medium text-base`;
const ProductBrandStyled = tw.div`font-bold pl-0`;
const RfqProductBrandStyled = tw(RfqProductBrand)`font-bold pl-0`;
const Title = tw.div`font-medium text-base`;
const Value = tw.div`font-extralight text-base`;
const Issue = tw(Value)` text-red-500`;
const Section = tw.div`mt-3`;
const ImagesContainer = tw.div`grid grid-cols-[repeat(5,_70px)] gap-2 items-top gap-x-4 mt-2`;

export const ReleaseItemIssueDescription: FC<Props> = ({ item }) => {
  const issueTypes = useIssueTypes();

  const issue = useMemo(() => item?.issues?.[0], [item]);

  return (
    <>
      <Row>
        <NameView>
          {item.manufacturer ? (
            <ProductBrandStyled>{item.manufacturer?.name}</ProductBrandStyled>
          ) : (
            <RfqProductBrandStyled item={item.buyoutItem} />
          )}
          <Name>{item.buyoutItem?.description}</Name>
        </NameView>
        <QuantityView>
          <Units>{item.uom?.mnemonic || item.uom?.pluralDescription}</Units>
          <Quantity>
            <FormattedNumber
              value={Number(item.quantityDecimal)}
              maximumFractionDigits={2}
            />
          </Quantity>
        </QuantityView>
      </Row>
      <DashedLine />
      <Section>
        <Row>
          <FormattedMessage id="CONTRACTOR_REPORTED_ISSUE" tagName={Title} />
          <FormattedMessage id="QUANTITY" tagName={Title} />
        </Row>
        <ValueRow>
          <Issue>
            {issueTypes.find((i) => i.value === issue?.issueType)?.label ||
              issue?.issueType}
          </Issue>
          <Issue>-{issue?.quantityDecimal}</Issue>
        </ValueRow>
      </Section>
      <If isTrue={issue?.description}>
        <Section>
          <FormattedMessage id="CONTRACTOR_COMMENTS" tagName={Title} />
          <ValueRow>
            <Value>{issue?.description}</Value>
          </ValueRow>
        </Section>
      </If>
      <If isTrue={(issue?.photos?.length || 0) > 0}>
        <Section>
          <FormattedMessage id="PHOTOS_OF_ISSUE" tagName={Title} />
          <ImagesContainer>
            {(issue?.photos || []).map((asset) => (
              <AssetItem asset={asset} key={asset.url} />
            ))}
          </ImagesContainer>
        </Section>
      </If>
    </>
  );
};
