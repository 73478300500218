import {
  AdditionalFiltersOverlay,
  Option,
} from "@/common/components/additional-filters-overlay/AdditionalFiltersOverlay";
import { OrderTypesFilter } from "@/common/components/order-types-filter/OrderTypesFilter";
import { QueryInvoicesFilter } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useReceipts } from "../../providers/ReceiptsProvider";

enum AdditionalFilter {
  ClosedProjects = "CLOSED_PROJECTS",
  Archived = "ARCHIVED",
}

export const ReceiptsAdditionalFilters = () => {
  const { filter, setFilter } = useReceipts();
  const intl = useIntl();

  const options = useMemo<Option<QueryInvoicesFilter>[]>(() => {
    return [
      {
        id: AdditionalFilter.Archived,
        title: intl.$t({ id: "RECEIPT_EXPORTED" }),
        filter: (value) => ({
          exported: value,
        }),
        value: (currentFilter) => currentFilter?.exported,
      },
      {
        id: AdditionalFilter.Archived,
        title: intl.$t({ id: "RECEIPT_PAID_VIA_INVOICE" }),
        filter: (value) => ({
          prePaid: value,
        }),
        value: (currentFilter) => currentFilter?.prePaid,
      },
      {
        id: AdditionalFilter.ClosedProjects,
        title: intl.$t({ id: "RECEIPTS_FROM_CLOSED_PROJECTS" }),
        filter: (value) => ({
          closedProjects: value,
        }),
        value: (currentFilter) => currentFilter?.closedProjects,
      },
      {
        id: AdditionalFilter.Archived,
        title: intl.$t({ id: "ARCHIVED" }),
        filter: (value) => ({
          archived: value,
        }),
        value: (currentFilter) => currentFilter?.archived,
      },
    ];
  }, [intl]);

  const setFilterHandler = useCallback(
    (values: string[] | undefined) => {
      setFilter({
        ...filter,
        releaseTypeIds: values,
      });
    },
    [filter, setFilter],
  );

  return (
    <AdditionalFiltersOverlay
      filter={filter}
      options={options}
      defaultFilter={{
        exported: null,
        prePaid: null,
        archived: false,
      }}
      setFilter={setFilter}
      customBottomFilters={
        <OrderTypesFilter
          selectedOrderTypes={filter?.releaseTypeIds}
          onChange={setFilterHandler}
        />
      }
    />
  );
};
