import { IconButtonBorderless } from "@/common/components/button/IconButton";
import { PrintButton } from "@/common/components/print-button/PrintButton";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { usePrintView } from "./print-view/usePrintView";

const IconButtonStyled = tw(IconButtonBorderless)`
  text-blue-500 w-50 hover:cursor-pointer
`;

export const BudgetPrintButton: FC = () => {
  const { savePDF } = usePrintView();
  return (
    <IconButtonStyled onClick={savePDF}>
      <PrintButton />
    </IconButtonStyled>
  );
};
