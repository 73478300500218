import { LinkLike } from "@/common/components/link-like/LinkLike";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { ProjectOrderedQuantityProvider } from "@/contractor/pages/home/project/providers/ProjectOrderedQuantityProvider";
import { ReportItemType } from "@/contractor/pages/home/project/providers/ProjectSpendingReportProvider";
import { ProjectReportVendorFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { CurrencyDisplay } from "./CurrencyDisplay";
import {
  ReportItemOrderedPanel,
  ReportType,
} from "./report-item-orders-panel/ReportItemOrderedPanel";

const LinkLikeStyled = tw(LinkLike)`font-medium text-xs`;

type Props = {
  item: ProjectReportVendorFieldsFragment;
  costCodeId?: string | undefined;
};

export const SpendingReportVendorOrderedQuantity: FC<Props> = ({
  item,
  costCodeId,
}) => {
  return (
    <DrawerPanel
      anchor={(togglePanel) =>
        !item.ordered ? (
          "--"
        ) : (
          <LinkLikeStyled
            onClick={() => {
              togglePanel(true);
            }}
          >
            <CurrencyDisplay value={item.ordered} />
          </LinkLikeStyled>
        )
      }
      content={(togglePanel) => (
        <ProjectOrderedQuantityProvider
          item={{
            costCodeId,
            ordered: item.ordered,
            vendor: {
              id: item.vendor?.id,
              name: item.vendor?.name,
            },
          }}
          type={ReportItemType.CostCode}
        >
          <ReportItemOrderedPanel
            ordered={item.ordered}
            onClose={() => togglePanel(false)}
            type={ReportType.Vendor}
          />
        </ProjectOrderedQuantityProvider>
      )}
    />
  );
};
