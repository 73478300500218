import { InvoiceEmailFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`mr-7 flex h-full flex-row items-center text-sm text-gray-600`;
const Counter = tw.div`mr-2 flex h-5 w-5 items-center justify-center rounded-full bg-orange-500 text-xs text-white`;

type Props = {
  invoiceEmail: InvoiceEmailFieldsFragment;
};

export const InvoiceEmailFooterInfo: FC<Props> = ({ invoiceEmail }) => {
  const invoicesWithoutReleaseCount = useMemo(
    () =>
      invoiceEmail.attachments.reduce(
        (acc, attachment) =>
          acc +
          attachment.invoiceDocuments.reduce(
            (docAcc, doc) =>
              docAcc + (!!doc.invoice && doc.invoice.release === null ? 1 : 0),
            0,
          ),
        0,
      ),
    [invoiceEmail.attachments],
  );
  if (invoicesWithoutReleaseCount > 0) {
    return (
      <Container>
        <Counter>{invoicesWithoutReleaseCount}</Counter>
        <FormattedMessage id="IMPORTED_INVOICES_NEEDS_TO_BE_MATCHED" />
      </Container>
    );
  }
  return null;
};
