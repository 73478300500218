import {
  MAX_IMAGE_DIMENSION,
  MAX_IMAGE_SIZE,
  MIN_IMAGE_DIMENSION,
} from "@/common/const";
import { FC, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { createImage } from "./utils";

type Props = {
  image?: Blob | null;
  showCircleCrop?: boolean;
  validation?: {
    minDimension: number;
    maxDimension: number;
    maxSize: number;
  };
};

const Image = tw.img`
  shadow-lg
  ${({ $showCircleCrop }: { $showCircleCrop?: boolean }) =>
    $showCircleCrop && "rounded-full"}
`;

const Error = tw.div`
  mt-2 text-red-500
`;

const Container = tw.div`
  flex justify-center
`;

const PreviewContainer = tw.div``;

export const ImagePreview: FC<Props> = ({
  image,
  showCircleCrop,
  validation = {
    minDimension: MIN_IMAGE_DIMENSION,
    maxDimension: MAX_IMAGE_DIMENSION,
    maxSize: MAX_IMAGE_SIZE,
  },
}) => {
  const [width, setWidth] = useState<number | null>(null);
  const [size, setSize] = useState<number | null>(null);
  const imageSrc = useMemo(() => {
    if (image) {
      return URL.createObjectURL(image);
    }
  }, [image]);

  useEffect(() => {
    const setDimension = async () => {
      if (imageSrc) {
        const dimensions = await createImage(imageSrc);
        setWidth(dimensions.width);
      }
    };
    if (imageSrc) {
      setDimension();
    }
  }, [imageSrc]);

  useEffect(() => {
    if (image) {
      const file = new File([image as Blob], "image.jpeg");
      setSize(file.size);
    }
  }, [image]);

  if (!imageSrc) {
    return null;
  }

  return (
    <PreviewContainer>
      <Container>
        <Image src={imageSrc} $showCircleCrop={showCircleCrop} alt="" />
      </Container>
      <If isTrue={!!width && width > validation.maxDimension}>
        <Error>
          <FormattedMessage id="IMAGE_SIZE_TOO_BIG" />
        </Error>
      </If>
      <If isTrue={!!width && width < validation.minDimension}>
        <Error>
          <FormattedMessage id="IMAGE_SIZE_TOO_SMALL" />
        </Error>
      </If>
      <If isTrue={!!size && size > validation.maxSize}>
        <Error>
          <FormattedMessage id="UPLOADED_IMAGE_TOO_BIG" />
        </Error>
      </If>
    </PreviewContainer>
  );
};
