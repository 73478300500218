import {
  BuyoutReleaseFieldsFragment,
  ReleaseFieldsFragment,
  ReleasePartialFieldsFragment,
} from "@/generated/graphql";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type ReleaseSlipType =
  | ReleasePartialFieldsFragment
  | ReleaseFieldsFragment
  | BuyoutReleaseFieldsFragment
  | null;

type State = {
  addSlipVisible: boolean;
  setAddSlipVisible: (visible: boolean) => void;
  slipRelease: ReleaseSlipType;
  setSlipRelease: (release: ReleaseSlipType) => void;
};

export const useAddDeliverySlipStore = create<State>()(
  devtools((set) => ({
    addSlipVisible: false,
    setAddSlipVisible: (visible) => set({ addSlipVisible: visible }),
    slipRelease: null,
    setSlipRelease: (release) => set({ slipRelease: release }),
  })),
);
