import { SuccessIcon } from "@/common/components/dialog-icons/SuccessIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { AssignedColumn } from "@/common/components/upload-file-mapper/FileMapper";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CostCodeFieldsFragment,
  CustomIntegrationInput,
  FieldPreview,
  FileType,
  useFieldsPreviewQuery,
  useUpdateCostCodesWithFileMutation,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { ApolloError } from "@apollo/client";
import { AutoFixHigh } from "@mui/icons-material";
import { FC, createContext, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { FM_COLUMNS } from "./CodesFileMapper";

type ProviderContextType = {
  uploadFile: (
    fileType: FileType,
    file: File,
    custom?: CustomIntegrationInput,
  ) => Promise<CostCodeFieldsFragment[] | null>;
  isUploading: boolean;
  setFilePreview: (file: File) => void;
  setFieldPreviewType: (type: FileType) => void;
  fieldPreviews: FieldPreview[];
  loadingPreview: boolean;
  previewError: ApolloError | undefined;
  assignedColumns: AssignedColumn[];
  setAssignedColumns: (assignedColumns: AssignedColumn[]) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  uploadFile: () => Promise.resolve(null),
  isUploading: false,
  setFilePreview: NoFunction,
  setFieldPreviewType: NoFunction,
  fieldPreviews: [],
  loadingPreview: false,
  previewError: undefined,
  assignedColumns: [],
  setAssignedColumns: NoFunction,
});

export const CodesFileUploadProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [uploadFileMutation, { loading: isUploading }] =
    useUpdateCostCodesWithFileMutation();

  const { setError } = useGlobalError();
  const [filePreview, setFilePreview] = useState<File | undefined>();
  const [fieldPreviewType, setFieldPreviewType] = useState<FileType>(
    FileType.Csv,
  );
  const { openDialog } = useDialog();
  const intl = useIntl();
  const [assignedColumns, setAssignedColumns] = useState<AssignedColumn[]>([]);

  const uploadFile = async (
    fileType: FileType,
    file: File,
    custom?: CustomIntegrationInput,
  ) => {
    try {
      const { data, errors } = await uploadFileMutation({
        variables: {
          input: {
            fileType,
            file,
            custom,
          },
        },
      });
      setError(errors);
      if (data?.updateCostCodesWithFile) {
        return data.updateCostCodesWithFile;
      }
    } catch (errors) {
      setError(errors);
    }
    return null;
  };

  const {
    data: fieldPreviews,
    loading: loadingPreview,
    error: previewError,
  } = useFieldsPreviewQuery({
    variables: {
      input: {
        fileType: fieldPreviewType,
        file: filePreview,
        records: 10,
      },
    },
    skip: !filePreview,
    onCompleted: (data) => {
      const newColumns = FM_COLUMNS.map((c) => ({
        value: c.value,
        assigned:
          data?.fieldsPreview?.find((f) => f.hint === c.value)?.name || "",
      }));
      setAssignedColumns(newColumns);
      const hasAssignedColumns = newColumns.some((c) => c.assigned);
      if (hasAssignedColumns) {
        openDialog({
          confirmButtonText: intl.$t({ id: "OK" }),
          icon: <SuccessIcon customIcon={AutoFixHigh} />,
          title: intl.$t({ id: "AUTO_ASSIGNED" }),
          text: intl.$t({ id: "AUTO_ASSIGNED_INFO" }),
        });
      }
    },
    onError(error) {
      setError(error);
    },
    fetchPolicy: "no-cache",
  });

  return (
    <ProviderContext.Provider
      value={{
        uploadFile,
        isUploading,
        setFilePreview,
        setFieldPreviewType,
        fieldPreviews: fieldPreviews?.fieldsPreview || [],
        loadingPreview,
        previewError,
        assignedColumns,
        setAssignedColumns,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useCodesFileUpload = (): ProviderContextType =>
  useContext(ProviderContext);
