import {
  BreadcrumbItem,
  Breadcrumbs,
} from "@/common/components/breadcrumbs/Breadcrumbs";
import {
  QuestionBox,
  QuestionText,
} from "@/common/components/messages/Messages.styles";
import { MessageButton } from "@/common/components/messages/components/MessageButton";
import { InvoiceStatusChip } from "@/common/components/statuses/InvoiceStatusChip";
import { routes } from "@/config/routes";
import { VendorReleaseMessagesButton } from "@/distributor/common/release-messages/VendorReleaseMessagesButton";
import { MessageContextKind } from "@/generated/graphql";
import { FC, ReactElement, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useLocation } from "react-router-dom";
import { useDistributorInvoice } from "../../providers/DistributorInvoiceProvider";

export const InvoiceBreadcrumbs: FC = () => {
  const intl = useIntl();
  const { invoice } = useDistributorInvoice();
  const { search } = useLocation();

  const breadcrumbs = useMemo(() => {
    return [
      ...(invoice?.release
        ? [
            {
              text: intl.$t(
                { id: "ORDER_WITH_NUMBER" },
                {
                  orderNumber: invoice.release.sequenceNumber,
                },
              ),
              link: `${generatePath(routes.distributorDelivery, { deliveryId: invoice.release.id })}${search}`,
              id: "order",
            },
          ]
        : []),
      {
        text: intl.$t(
          { id: "INVOICE_NUMBER" },
          {
            vendor: "",
            number: invoice?.number,
          },
        ),
        id: "invoices",
      },
    ] as BreadcrumbItem[];
  }, [intl, invoice, search]);

  const actions = useMemo(() => {
    const bActions = [];

    if (invoice?.release && invoice?.release?.project?.location.org) {
      bActions.push(
        <VendorReleaseMessagesButton
          key="messages"
          release={invoice?.release}
          org={invoice?.release?.project?.location.org}
          text={
            <QuestionBox>
              <QuestionText>
                <FormattedMessage id="MESSAGE_CONTRACTOR" tagName="div" />
                <MessageButton
                  id={invoice.release.id}
                  kind={MessageContextKind.Release}
                />
              </QuestionText>
            </QuestionBox>
          }
        />,
      );
    }

    return bActions;
  }, [invoice]);

  const appendedItems = useMemo(() => {
    const items = [] as ReactElement[];
    if (invoice) {
      items.push(
        <InvoiceStatusChip
          key="status"
          status={invoice.status}
          type="breadcrumb"
        />,
      );
    }
    return items;
  }, [invoice]);

  return (
    <Breadcrumbs
      classes={{ text: "flex items-center" }}
      items={breadcrumbs}
      appendItems={appendedItems}
      actions={actions}
    />
  );
};
