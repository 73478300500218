import { Breadcrumbs } from "@/common/components/breadcrumbs/Breadcrumbs";
import { CategoriesProvider } from "@/common/components/category-filter/CategoriesProvider";
import { CategoryListFilter } from "@/common/components/category-filter/CategoryListFilter";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { useFacets } from "@/common/components/product-facets-filter/FacetsProvider";
import { ProductFacetsFilter } from "@/common/components/product-facets-filter/ProductFacetsFilter";
import {
  SkusAndProductsProvider,
  useSkusAndProducts,
} from "@/common/components/product-facets-filter/SkusAndProductsProvider";
import {
  ShoppingCartIcon,
  ShoppingIconType,
} from "@/common/components/shopping-cart-icon/ShoppingCartIcon";
import { MasterSkusProductsToggleProvider } from "@/common/components/skus-products-switch/MasterSkusProductsToggleProvider";
import { routes } from "@/config/routes";
import { RfqStatus } from "@/generated/graphql";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { ProductsList } from "../../../../common/components/product-list/ProductsList";
import { useRfq } from "../rfq/hooks/useRfq";
import { ProductsContainer } from "./Rfq.styles";
import { RFQInfo } from "./components/RFQInfo";
import { useEnsureRfqStatus } from "./hooks/useEnsureRfqStatus";
import { useRfqShoppingCart } from "./hooks/useRfqShoppingCart";

const SelectRfqProductsWithProviders = () => {
  const { id } = useParams();
  const { addToRfq, findItem, removeFromRfq } = useRfqShoppingCart();
  const { rfq, count } = useRfq();
  const {
    productFacets,
    productFacetsValues,
    selectedFacets,
    toggleFacetValue,
  } = useFacets();
  const { searchPerformed } = useSkusAndProducts();
  const navigate = useNavigate();

  useEnsureRfqStatus(rfq, RfqStatus.Draft);

  const intl = useIntl();

  if (!rfq) {
    return <Loader loading />;
  }

  if (rfq.deletedAt) {
    navigate(generatePath(routes.rfqById, { id: rfq.id }));
  }

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "NEW_RFQ" })}</title>
      </Helmet>
      <Breadcrumbs
        items={[
          {
            text: intl.$t({ id: "QUOTES" }),
            link: routes.quotes,
            id: "activeOrders",
          },
          {
            text: intl.$t(
              {
                id: "RFQ_NUMBER_WITH_SYMBOL",
              },
              { number: rfq.clientIdentifier },
            ),
            id: "newOrder",
            link: generatePath(routes.rfqById, { id: rfq.id }),
          },
          {
            text: intl.$t({ id: "QUOTE_SELECT_PRODUCTS" }),
            id: "selectProducts",
          },
        ]}
        actions={[
          <ShoppingCartIcon
            key="shopping-cart-icon"
            count={count}
            path={generatePath(routes.rfqCheckout, { id })}
            type={ShoppingIconType.Truck}
          />,
        ]}
      />
      <RFQInfo rfq={rfq} />
      <CategoryListFilter />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <ProductsContainer>
            <If isTrue={searchPerformed}>
              <Grid item xs={2}>
                <ProductFacetsFilter
                  productFacets={productFacets}
                  productFacetsValues={productFacetsValues}
                  selectedFacets={selectedFacets}
                  toggleFacetValue={toggleFacetValue}
                />
              </Grid>
            </If>
            <ProductsList
              addToCart={addToRfq}
              findItem={findItem}
              removeFromCart={removeFromRfq}
            />
          </ProductsContainer>
        </Grid>
      </Grid>
    </>
  );
};

export const SelectRfqProducts = () => (
  <CategoriesProvider initialCategory={null}>
    <PaginationProvider>
      <MasterSkusProductsToggleProvider>
        <SkusAndProductsProvider>
          <SelectRfqProductsWithProviders />
        </SkusAndProductsProvider>
      </MasterSkusProductsToggleProvider>
    </PaginationProvider>
  </CategoriesProvider>
);
