import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const NoItemsText = tw.div`
  text-center text-black text-xl font-light pt-14
`;

type ListEmptyStateProps = {
  messageKey?: string;
  values?: Record<string, string>;
  children?: React.ReactNode;
  className?: string;
};

export const ListEmptyState: FC<ListEmptyStateProps> = ({
  messageKey,
  values,
  children,
  className,
}) => {
  return (
    <NoItemsText className={className}>
      {messageKey && <FormattedMessage id={messageKey} values={values} />}
      {children}
    </NoItemsText>
  );
};
