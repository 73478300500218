import { GridTable } from "@/common/components/grid-table/GridTable";
import { getExpandedItems } from "@/common/components/grid-table/utils/getExpandedItems";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import { BuyoutFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutListNonDraftedConfiguration } from "../../common/table-configurations/BuyoutListNonDraftedConfiguration";
import { useBuyoutQuotedGrouped } from "../providers/BuyoutQuotedGroupedProvider";

const Container = tw.div``;

type Props = {
  buyout: BuyoutFieldsFragment;
  loading: boolean;
};

export const BuyoutQuotedList: FC<Props> = ({ buyout, loading }) => {
  const { costCodes, toggleCategory, groupedByCostCode } =
    useBuyoutQuotedGrouped();
  const buyoutItemsListConfiguration = useBuyoutListNonDraftedConfiguration({
    buyout,
  });
  const { expandedItems } = useContractorBuyout();
  const items = useMemo(() => {
    return (
      buyout?.items.toSorted((a, b) => (a.position || 0) - (b.position || 0)) ??
      []
    );
  }, [buyout?.items]);

  return (
    <ListRenderer count={buyout.items.length} totalCount={buyout.items.length}>
      <GridTable
        configuration={{
          container: Container,
          columns: buyoutItemsListConfiguration,
          classNames: {
            header: "top-8",
            category: "top-[52px] lg:top-[78px] bg-yellow-200",
          },
          toggle: {
            category: toggleCategory,
          },
        }}
        expandedItems={(item) => getExpandedItems(item, expandedItems)}
        items={groupedByCostCode ? costCodes : items}
        loading={loading}
      />
    </ListRenderer>
  );
};
