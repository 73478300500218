import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { useFormatNumberToCurrency } from "@/common/components/value-currency/hooks/useFormatNumberToCurrency";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type CurrencyTextProps = {
  $rightAlign?: boolean;
};
const CurrencyText = tw.div<CurrencyTextProps>`
  font-medium text-center grid grid-flow-col gap-1 text-xs
  ${(props) => props.$rightAlign && "text-right"}
`;

type Props = {
  value: string | number | null | undefined;
  showColor?: boolean;
  rightAlign?: boolean;
  info?: string;
  className?: string;
};

export const CurrencyDisplay: FC<Props> = ({
  value,
  showColor,
  rightAlign,
  info,
  className,
}) => {
  const { formatCurrency } = useFormatNumberToCurrency();

  return (
    <CurrencyText
      className={`${
        showColor
          ? Number(value ?? 0) >= 0
            ? "text-green-800"
            : "text-red-500"
          : ""
      } ${className}`}
      $rightAlign={rightAlign}
    >
      {value && info && (
        <InfoTooltip
          message={<FormattedMessage id={info} />}
          iconClassName="w-4 h-4"
        />
      )}
      {value
        ? formatCurrency(value, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : value === undefined
          ? ""
          : "--"}
    </CurrencyText>
  );
};
