export const isEmptyString = (str: string | null): boolean => {
  return !str || str.length === 0 || /^\s*$/.test(str);
};

export const isValidPostalCode = (str: string | null): boolean => {
  return !str || str.length === 0 || /\b\d{5}\b/g.test(str);
};

export const isValidCellPhone = (str: string | null): boolean => {
  return !str || str.length === 0 || /^\d{11}$/.test(str);
};

export const isValidEmail = (str: string | null): boolean => {
  return !str || str.length === 0 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
};
