import { CountType } from "@/common/components/circle-counter/CountType";
import { If } from "@/common/components/if/If";
import { LineItemNameNotes } from "@/common/components/line-item-name-notes/LineItemNameNotes";
import {
  isMasterSku,
  isOrgCatalogSku,
  isProductSku,
} from "@/common/components/material/utils";
import { isEmptyString } from "@/common/utils/validationUtils";
import { AddOrgMaterialWithCounter } from "@/contractor/pages/home/rfq/components/add-org-sku/AddOrgMaterialWithCounter";
import { RfqItemFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import tw from "tailwind-styled-components";
import { useRfq } from "../../hooks/useRfq";
import { useRfqGroupedItems } from "../../hooks/useRfqGroupedItems";
import { RfqItemMaterialView } from "./RfqItemMaterialView";

const NameContainer = tw.div`flex w-full flex-col truncate`;

type Props = {
  item: RfqItemFieldsFragment;
  index?: number;
  readonly: boolean;
  count: CountType;
  error?: boolean;
};

export const RfqItemMaterialEditableView = (props: Props) => {
  const { newRfqItem, setNewRfqItem } = useRfqGroupedItems();
  const { updateRfq, rfq } = useRfq();
  const updateDescription = useCallback(
    async (description: string) => {
      await updateRfq({
        rfqId: rfq?.id ?? "",
        updates: [
          {
            rfqItemId: props.item.id,
            description,
          },
        ],
      });
    },
    [props.item.id, updateRfq, rfq],
  );
  return !props.item.id ? (
    <AddOrgMaterialWithCounter
      count={props.count}
      setSelectedOrgMaterial={(item) => {
        if (item) {
          const projectItem = {
            ...newRfqItem?.projectItem,
            orgCatalogSkuId: isOrgCatalogSku(item.material)
              ? item?.material.id
              : undefined,
            masterProductId: isProductSku(item.material)
              ? item?.material.id
              : undefined,
            masterSkuId: isMasterSku(item.material)
              ? item?.material.id
              : undefined,
            estimateUom:
              item.defaultEstimateUom?.pluralDescription ||
              newRfqItem?.projectItem?.estimateUom ||
              "",
          };

          setNewRfqItem({
            ...newRfqItem,
            projectItem,
            description: item.material.name,
            orgCatalogSkuName: !item.id ? item.material.name : undefined,
            uomId: item.defaultEstimateUom?.id || newRfqItem?.uomId,
            costCodeId: item.costCode?.id,
          });
        }
      }}
      error={props.error && isEmptyString(newRfqItem?.description || "")}
    />
  ) : (
    <NameContainer>
      <RfqItemMaterialView {...props} updateDescription={updateDescription} />
      <If isTrue={props.readonly}>
        <LineItemNameNotes
          notes={props.item.instructions?.text}
          assets={props.item.instructions?.assets}
        />
      </If>
    </NameContainer>
  );
};
