import { FC } from "react";
import { SkeletonCard, SkeletonCardContainer } from "./Skeleton.styles";

type CardLoaderProps = {
  pageSize: number;
};

export const CardLoader: FC<CardLoaderProps> = ({ pageSize }) => {
  return (
    <>
      {Array.from({ length: pageSize }, (_, index) => (
        <SkeletonCardContainer key={index}>
          <SkeletonCard variant="rectangular" />
          <SkeletonCard variant="text" />
        </SkeletonCardContainer>
      ))}
    </>
  );
};
