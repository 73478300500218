import { ManufacturerPicker } from "@/common/components/manufacturer-picker/ManufacturerPicker";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import {
  OrgMaterialFieldsFragment,
  Sku,
  VendorPriceFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMaterialPrices } from "../../providers/MaterialPricesProvider";

type Props = {
  item: OrgMaterialFieldsFragment;
  price?: VendorPriceFieldsFragment;
  error?: boolean;
  readonly?: boolean;
  index?: number;
};

export const VendorPriceManufacturerSelector: FC<Props> = ({
  item,
  price,
  error,
  readonly,
  index,
}) => {
  const { updateMaterial } = useMaterialPrices();
  const { manufacturers } = useManufacturers();
  const [manufacturerId, setManufacturerId] = useState<
    string | null | undefined
  >(price?.manufacturer?.id);

  useEffect(() => {
    setManufacturerId(price?.manufacturer?.id);
  }, [price]);

  const saveManufacturer = useCallback(
    async (manufacturerId: string | null) => {
      setManufacturerId(manufacturerId);

      await updateMaterial(item.id, {
        vendorPriceId: price?.id || "",
        manufacturerId,
      });
    },
    [price, updateMaterial, item],
  );

  const manufacturer = useMemo(() => {
    return manufacturers.find(
      (m) =>
        m.id === manufacturerId ||
        m.id === (item.material as Sku)?.manufacturer?.id,
    );
  }, [manufacturers, manufacturerId, item]);

  return (
    <NotNullableRenderer value={(manufacturer && price?.id) || !readonly}>
      <ManufacturerPicker
        item={manufacturer}
        saveManufacturer={saveManufacturer}
        readonly={readonly}
        hasAnyManufacturer={false}
        error={error}
        index={index}
        disabled={!!(item.material as Sku)?.manufacturer}
      />
    </NotNullableRenderer>
  );
};
