import { If } from "@/common/components/if/If";
import { UserAvatar } from "@/common/components/user-avatar/UserAvatar";
import { useUser } from "@/common/providers/UserProvider";
import { DateView } from "@/common/utils/dates/DateView";
import { MessageFieldsFragment } from "@/generated/graphql";
import { FC, memo, useMemo } from "react";
import tw from "tailwind-styled-components";
import { SIGNATURE_USER_ID } from "../../../const";
import { useQueryParams } from "../../../hooks/useQueryParams";

type Props = {
  message: MessageFieldsFragment & {
    grouped: boolean;
  };
  firstMessage?: boolean;
  lastMessage?: boolean;
  rightSide?: boolean;
};

type ContainerProps = {
  $myMessage?: boolean;
  $grouped?: boolean;
};

const Container = tw.div`
  flex gap-2 items-start 
  ${({ $myMessage }: ContainerProps) => ($myMessage ? "flex-row-reverse" : "")} 
  ${({ $grouped }: ContainerProps) => ($grouped ? "" : "mt-2")}
`;

type MessageContainerProps = {
  $myMessage: boolean;
  $firstInGroup: boolean;
};

const MessageContainer = tw.div`
  grid 
  ${({ $myMessage }: MessageContainerProps) =>
    $myMessage
      ? "justify-end justify-items-end"
      : "justify-start justify-items-start"}
  ${({ $firstInGroup }: MessageContainerProps) => ($firstInGroup ? "mt-3" : "")}
`;

type MessageContentProps = {
  $myMessage: boolean;
  $first: boolean;
  $last: boolean;
};

const MessageContent = tw.div<MessageContentProps>`
  grid px-4 py-3 w-fit rounded-4xl items-center
  whitespace-pre-line text-sm 
  ${({ $myMessage }: MessageContentProps) =>
    $myMessage
      ? "justify-end rounded-tr-md rounded-br-md bg-blue-500 text-white"
      : "justify-start rounded-tl-md rounded-bl-md bg-blue-100"}
  ${({ $first, $last, $myMessage }: MessageContentProps) =>
    $first && $last
      ? $myMessage
        ? "rounded-br-4xl"
        : "rounded-bl-4xl"
      : $last
        ? $myMessage
          ? "rounded-br-4xl"
          : "rounded-bl-4xl"
        : $first
          ? $myMessage
            ? "rounded-tr-4xl"
            : "rounded-tl-4xl"
          : ""}
`;

const UserAvatarStyled = tw(UserAvatar)`
  bg-blue-100 border border-blue-500 w-10 h-10 mt-3
`;

const EmptyAvatar = tw.div`
  min-w-10 
`;

const MessageSentTime = tw.div``;

const MessageAuthor = tw.div` pr-1`;

const MessageContextContainer = tw.div`flex text-2xs font-light h-5 items-top`;

export const Message: FC<Props> = memo(
  ({ message, firstMessage, lastMessage }) => {
    const { viewer } = useUser();
    const { queryParams } = useQueryParams();
    const userId = queryParams.get(SIGNATURE_USER_ID);

    const myMessage = useMemo(() => {
      return message.author.id === (viewer?.id || userId);
    }, [viewer, message.author, userId]);

    return (
      <Container $myMessage={myMessage} $grouped={message.grouped}>
        {!message.grouped ? (
          <UserAvatarStyled
            firstName={message.author.firstName}
            lastName={message.author.lastName}
            email={message.author.email}
            photoUrl={message.author.photoUrl}
            size={45}
          />
        ) : (
          <EmptyAvatar />
        )}
        <MessageContainer
          $myMessage={myMessage}
          $firstInGroup={!message.grouped}
        >
          <If isTrue={!message.grouped}>
            <MessageContextContainer>
              <MessageAuthor>
                {`${message.author.firstName} ${message.author.lastName} -`}
              </MessageAuthor>
              <MessageSentTime>
                <DateView date={message.timeSent} onlyTime hideTimeZone />
              </MessageSentTime>
            </MessageContextContainer>
          </If>
          <MessageContent
            $myMessage={!!myMessage}
            $first={!!firstMessage}
            $last={!!lastMessage}
          >
            {message.message}
          </MessageContent>
        </MessageContainer>
      </Container>
    );
  },
);
