import { If } from "@/common/components/if/If";
import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import {
  AdditionalChargesFieldsFragment,
  UpdateContractorReleaseInput,
} from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useCallback, useEffect, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useDeliverySlipRelease } from "../../../../providers/DeliverySlipReleaseProvider";
import {
  PackingSlipReceiveViewState,
  useDeliverySlipVerification,
} from "../../../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipReleaseItems } from "../../components/delivery-slip-release-items/DeliverySlipReleaseItems";
import { useDeliverySlipEditReleaseSpreadsheetConfig } from "./DeliverySlipEditRelease.config";
import { DeliverySlipEditReleaseHeader } from "./DeliverySlipEditReleaseHeader";
import { DeliverySlipReceiveFooter } from "./DeliverySlipReceiveFooter";
import { DeliverySlipReceiveHeader } from "./DeliverySlipReceiveHeader";

const Container = tw.div`bg-gray-100 rounded-3xl pt-6 h-full overflow-y-scroll`;
const InnerContainer = tw.div`flex flex-col px-2.5 pb-4  mb-20`;
const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-fit`;
const Footer = tw.div`mr-7 mt-10 w-full pb-5`;

export const DeliverySlipReceiveOrderDetails: FC = () => {
  const { release, loading } = useRelease();
  const { spreadsheetData } = useColumnMapper();
  const { calcTableTotal } = useTableHelpers();
  const { setGlobalVendorId } = useVendorPrices();
  const { updating, updateReleaseForm: form } = useDeliverySlipRelease();
  const { packingSlipReceiveViewState } = useDeliverySlipVerification();
  const spreadsheetViewColumns = useDeliverySlipEditReleaseSpreadsheetConfig();
  const taxRate = form.watch("taxRate");
  const customTaxAmount = form.watch("customTaxAmount");
  const subtotal = form.watch("subtotal");
  const additionalCharges = form.watch("additionalCharges");

  const vendorId = form.watch("vendorId");
  useEffect(() => {
    if (vendorId) {
      setGlobalVendorId(vendorId);
    }
  }, [setGlobalVendorId, vendorId]);

  const releaseItems = useMemo(
    () =>
      release?.items
        .toSorted((a, b) => (a.position || 0) - (b.position || 0))
        .map((item) => ({
          ...item,
          phaseCode: item.tags[0]?.name,
        })) ?? [],
    [release?.items],
  );

  const handleUpdateRelease = (values: UpdateContractorReleaseInput) => {
    if (values.additionalCharges) {
      form.setValue("additionalCharges", values.additionalCharges);
    }
    if (
      values.customTaxAmount ||
      (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
    ) {
      form.setValue(
        "customTaxAmount",
        values.customTaxAmount || release?.taxAmount || "0",
      );
      form.setValue("taxRate", undefined);
    }
    if (values.taxRate || values.clearCustomTaxAmount) {
      form.setValue("customTaxAmount", undefined);
      form.setValue("taxRate", values.taxRate || "0");
    }

    return true;
  };

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      const newTotal = calcTableTotal(data);
      if (newTotal !== form.watch("subtotal")) {
        form.setValue("subtotal", newTotal);
      }
    },
    [calcTableTotal, form],
  );

  useEffect(() => {
    updateSubtotal(spreadsheetData);
  }, [spreadsheetData, updateSubtotal]);

  const total = useMemo(() => {
    const subTotal = new Decimal(subtotal || 0);
    const taxAmount = taxRate
      ? subTotal.mul(taxRate)
      : new Decimal(customTaxAmount || 0);
    const charges = (additionalCharges ?? []).reduce((acc, charge) => {
      return acc.plus(charge.amount);
    }, new Decimal(0));
    return subTotal.plus(taxAmount).plus(charges).toString();
  }, [customTaxAmount, subtotal, taxRate, additionalCharges]);

  return (
    <Container>
      <InnerContainer>
        <If
          isTrue={
            packingSlipReceiveViewState ===
            PackingSlipReceiveViewState.EDIT_ORDER
          }
        >
          <DeliverySlipEditReleaseHeader />
          <SpreadSheetView>
            <SpreadSheetTable
              items={releaseItems}
              columns={spreadsheetViewColumns}
              saving={loading || updating}
              height="450px"
              rowNumber={10}
              onChanges={updateSubtotal}
            />
          </SpreadSheetView>
          <Footer>
            <ReleaseAdditionalChargesAndTaxes
              total={`${total}`}
              subtotal={`${form?.watch("subtotal")}`}
              customTaxAmount={form?.watch("customTaxAmount")}
              customTaxRate={form?.watch("taxRate")}
              customPaymentTerm={form?.watch("paymentTerm")}
              editableByContractor
              editablePaymentTerms
              updateRelease={(values) =>
                handleUpdateRelease({ ...values, version: -1 })
              }
              includePaymentTerms
              taxExempt={{
                isProjectTaxExempt: release?.project?.taxExempt,
                isVendorTaxExempt: release?.preferredVendor?.taxExempt,
                vendorName: vendorLabelFormatter(release?.sellerOrgLocation),
              }}
              additionalCharges={
                form?.watch(
                  "additionalCharges",
                ) as AdditionalChargesFieldsFragment[]
              }
            />
          </Footer>
        </If>
        <If
          isTrue={
            packingSlipReceiveViewState !==
            PackingSlipReceiveViewState.EDIT_ORDER
          }
        >
          <DeliverySlipReceiveHeader />
          <DeliverySlipReleaseItems />
        </If>
      </InnerContainer>
      <DeliverySlipReceiveFooter />
    </Container>
  );
};
