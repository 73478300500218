import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { WarehouseHeader } from "./components/header/WarehouseHeader";
import { WarehousesList } from "./components/list/WarehousesList";
import {
  WarehousesProvider,
  useWarehouses,
} from "./providers/WarehousesProvider";

const WarehousesWithProvider = () => {
  const intl = useIntl();
  const { warehouses, loading } = useWarehouses();

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "WAREHOUSES" })}</title>
      </Helmet>
      <WarehouseHeader />
      <WarehousesList warehouses={warehouses} loading={loading} />
    </>
  );
};
export const Warehouses = () => (
  <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
    <WarehousesProvider>
      <WarehousesWithProvider />
    </WarehousesProvider>
  </PaginationProvider>
);
