import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { SelectableItem } from "@/common/components/searchable-list/components/SelectableItem";
import { LgContainer, XlContainer } from "@/common/layout/ResponsiveClasses";
import { ExternalVendorFieldsFragment } from "@/generated/graphql";
import { Identity } from "@/types/Identity";
import { FormattedMessage } from "react-intl";

export const useExternalVendorsTableConfiguration = (
  selectedItems?: string[],
): Array<GridCol<ExternalVendorFieldsFragment & Identity>> => {
  return [
    {
      header: <FormattedMessage id="NAME" />,
      wrapper: LgContainer,
      item: ({ item, index, count }) => (
        <SelectableItem
          item={item}
          index={index}
          selectedItems={selectedItems ?? []}
          itemNameSelectorFn={(item) => item.name}
          count={count}
        />
      ),
    },
    {
      header: <FormattedMessage id="ADDRESS" />,
      wrapper: XlContainer,
      item: ({ item }) =>
        `${
          item.address?.addressLine1
            ? `${item.address?.addressLine1.trim()} `
            : ""
        }${
          item.address?.addressLine2
            ? `${item.address?.addressLine2.trim()}, `
            : ""
        }${item.address?.city ? `${item.address.city.trim()}, ` : ""}${
          item.address?.state ? `${item.address.state} ` : ""
        }${
          item.address?.postalCode ? `${item.address.postalCode.trim()}` : ""
        }`,
    },
    {
      header: <FormattedMessage id="PHONE" />,
      wrapper: XlContainer,
      item: ({ item }) => (
        <NotNullableRenderer value={!!item.phone}>
          {item.phone}
        </NotNullableRenderer>
      ),
    },
    {
      header: <FormattedMessage id="EMAIL" />,
      wrapper: XlContainer,
      item: ({ item }) => (
        <NotNullableRenderer value={!!item.email}>
          {item.email}
        </NotNullableRenderer>
      ),
    },
  ];
};
