import { ManufacturerPicker } from "@/common/components/manufacturer-picker/ManufacturerPicker";
import { DistributorQuotesAuxiliaryQuoteItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";

type Props = {
  item: DistributorQuotesAuxiliaryQuoteItemFieldsFragment;
  error?: boolean;
  readonly?: boolean;
  index?: number;
};

export const DistributorQuoteAuxiliaryManufacturerSelector: FC<Props> = ({
  item,
  error,
  readonly,
  index,
}) => {
  const { updateQuote } = useDistributorQuote();

  const saveManufacturer = useCallback(
    async (manufacturerId: string | null) => {
      const payload = [
        {
          auxiliaryItemId: item.id,
          manufacturerId,
        },
      ];
      await updateQuote({
        updatedAuxiliaryItems: payload,
      });
    },
    [item, updateQuote],
  );

  return (
    <ManufacturerPicker
      item={item.manufacturer}
      saveManufacturer={saveManufacturer}
      readonly={readonly}
      error={error && !item.manufacturer}
      index={index}
    />
  );
};
