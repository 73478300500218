import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  QuoteDocumentFieldsFragment,
  useQuoteDocumentQuery,
} from "@/generated/graphql";
import { ApolloError } from "@apollo/client";

type ReturnType = {
  quoteDocument?: QuoteDocumentFieldsFragment;
  loading: boolean;
  error?: ApolloError;
};

export const useGetQuoteDocument = (id?: string): ReturnType => {
  const { setError } = useGlobalError();

  const { data, loading, error } = useQuoteDocumentQuery({
    variables: {
      id: id || "",
    },
    skip: !id,
    fetchPolicy: "cache-and-network",
    onError(error) {
      setError(error);
    },
  });

  return {
    quoteDocument: data?.quoteDocument,
    loading,
    error,
  };
};
