import { ExternalPOItems } from "@/contractor/pages/home/common/external-po/ExternalPOItems";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDeliverySlipImportExternalPO } from "../../../../providers/DeliverySlipImportExternalPOProvider";
import { useDeliverySlipVerification } from "../../../../providers/DeliverySlipVerificationProvider";

export const DeliverySlipExternalPOItems: FC = () => {
  const intl = useIntl();

  const { deliverySlip } = useDeliverySlipVerification();
  const {
    closeModal,
    externalPo,
    loadingExternalPo,
    importExternalCostCodes,
    importingExternalCostCodes,
    externalPOsQueryForm,
    includedItemsCategory,

    missingTagsCategory,
    unsupportedCostTypesCategory,
    missingCostCodesCategory,
    selectedSellerOrgLocationId,
    selectedBaseExternalPO,
    setSelectedSellerOrgLocationId,
    setOrderTypeId,
    orderTypeId,
  } = useDeliverySlipImportExternalPO();

  const projectId =
    externalPOsQueryForm?.watch("projectId") ||
    deliverySlip?.predictedRelease?.project?.id;

  const poAlreadyImported = useMemo(
    () =>
      selectedBaseExternalPO?.poLinks.some(
        (poLink) => poLink.release?.id === deliverySlip?.release?.id,
      ) ||
      externalPo?.poLinks.some(
        (poLink) => poLink.release?.id === deliverySlip?.release?.id,
      ),
    [
      selectedBaseExternalPO?.poLinks,
      externalPo?.poLinks,
      deliverySlip?.release?.id,
    ],
  );

  const validationError = useMemo(() => {
    if (poAlreadyImported) {
      return null;
    }
    if (
      !selectedBaseExternalPO?.project?.project &&
      !externalPo?.project?.project
    ) {
      return intl.$t(
        { id: "PO_IMPORT_FM_PROJECT_NOT_IMPORTED" },
        {
          projectName:
            selectedBaseExternalPO?.project?.name ??
            externalPo?.project?.name ??
            "",
        },
      );
    }
    if (
      (selectedBaseExternalPO?.vendor?.orgPreferredVendors ?? []).length ===
        0 &&
      (externalPo?.vendor?.orgPreferredVendors ?? []).length === 0
    ) {
      return intl.$t(
        { id: "PO_IMPORT_FM_VENDOR_NOT_IMPORTED" },
        {
          vendorName:
            selectedBaseExternalPO?.vendor?.name ??
            externalPo?.vendor?.name ??
            "",
        },
      );
    }
    if (
      (selectedBaseExternalPO?.poLinks ?? []).length > 0 ||
      (externalPo?.poLinks ?? []).length > 0
    ) {
      return intl.$t({ id: "EXTERNAL_PO_ORDER_ALREADY_ASSOCIATED" });
    }
    return null;
  }, [poAlreadyImported, selectedBaseExternalPO, externalPo, intl]);

  return (
    <ExternalPOItems
      close={closeModal}
      projectId={projectId}
      externalPO={externalPo}
      loading={loadingExternalPo}
      missingCostCodesCategory={missingCostCodesCategory}
      missingTagsCategory={missingTagsCategory}
      unsupportedCostTypesCategory={unsupportedCostTypesCategory}
      includedItemsCategory={includedItemsCategory}
      readonly={poAlreadyImported}
      validationError={validationError}
      importExternalCostCodes={importExternalCostCodes}
      importingExternalCostCodes={importingExternalCostCodes}
      selectedSellerOrgLocationId={selectedSellerOrgLocationId}
      setSelectedSellerOrgLocationId={setSelectedSellerOrgLocationId}
      setOrderTypeId={setOrderTypeId}
      orderTypeId={orderTypeId}
    />
  );
};
