import { Label } from "@/common/components/release-terms-and-notes/ReleaseTermsAndNotes.styles";
import { NoFunction } from "@/types/NoFunction";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { NumericalInput } from "../numerical-input/NumericalInput";
import { TextField } from "../textfield/TextField";

type Props = {
  paymentTerm?: number | null;
  updateItem?: (paymentTerm: number) => void;
  readonly?: boolean;
};

const Container = tw.div`flex flex-row items-center gap-2`;

export const CustomPaymentTerms: FC<Props> = ({
  paymentTerm,
  updateItem = NoFunction,
  readonly,
}) => {
  const intl = useIntl();

  return (
    <Container>
      <If isTrue={!readonly}>
        <FormattedMessage id="NET" tagName={Label} />
      </If>
      {!readonly ? (
        <NumericalInput
          label={intl.$t({
            id: "NUMBER_OF_DAYS",
          })}
          placeholder="30"
          defaultValue={paymentTerm}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
            updateItem(Number(event.target.value || 0))
          }
          classes={{ container: "w-full", root: "w-full" }}
          staticText={readonly}
        />
      ) : (
        <TextField
          label={intl.$t({
            id: "PAYMENT_TERMS",
          })}
          defaultValue={
            paymentTerm
              ? `${intl.formatMessage({ id: "NET" })} ${paymentTerm}`
              : intl.$t({ id: "UNSPECIFIED" })
          }
          staticText
        />
      )}
    </Container>
  );
};
