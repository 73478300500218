import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { formattedDate } from "@/common/utils/dates/DateView";
import { useUserRoles } from "@/common/utils/hooks/useUserRoles";
import { getUserName } from "@/common/utils/users/getUserName";
import { ReleaseStatus } from "@/generated/graphql";
import { Check } from "@mui/icons-material";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useRelease } from "../../providers/ReleaseProvider";

const Container = tw.div`flex flex-row items-center gap-4 pl-2 pr-4 mr-2`;
const Item = tw.div`flex items-center gap-2`;
const NumberCircle = tw.div`
w-6 h-6 rounded-full flex items-center justify-center text-blue-800 text-sm border-blue-800 border
${({ $approved }: { $approved?: boolean }) => ($approved ? "bg-green-800" : "opacity-60")}`;
const CheckRoundedIcon = tw(Check)`bg-green-800 text-white text-base`;
const OutlineCheckRoundedIcon = tw(
  Check,
)`bg-transparent rounded-full text-blue-800`;
const ApprovedByContainer = tw.div`flex flex-col text-sm`;
const Approver = tw.div`flex flex-row`;
const Label = tw.div`w-20 text-sm text-center`;
const Role = tw.div<{ $disabled: boolean }>`text-2xs max-w-12
${({ $disabled }) => $disabled && "opacity-50"}`;

export const OrderApprovalsStepper: FC = () => {
  const { release } = useRelease();
  const { userRoles } = useUserRoles();
  const ineffectualApprovals = useMemo(
    () => release?.approvalProgress.slots.filter((s) => s.ineffectual) || [],
    [release],
  );
  const effectualApprovals = useMemo(
    () => release?.approvalProgress.slots.filter((s) => !s.ineffectual) || [],
    [release],
  );

  return (
    <If
      isTrue={
        (release?.approvalProgress.slots || []).length > 1 &&
        release?.status === ReleaseStatus.AwaitingApproval
      }
    >
      <Container>
        <FormattedMessage id="REQUIRES_APPROVAL" tagName={Label} />
        {effectualApprovals.map((approval, key) => {
          return (
            <Tooltip
              key={key}
              id={`REQUIRES_APPROVAL_${key}`}
              element={
                <Item>
                  {approval.approvals.length === 0 ? (
                    <NumberCircle>{key + 1}</NumberCircle>
                  ) : (
                    <NumberCircle $approved>
                      <CheckRoundedIcon />
                    </NumberCircle>
                  )}
                  <If isTrue={approval.role}>
                    <Role $disabled={approval.approvals.length === 0}>
                      {userRoles.find((r) => r.value === approval.role)?.label}
                    </Role>
                  </If>
                </Item>
              }
            >
              {approval.approvals.length > 0 ? (
                <ApprovedByContainer>
                  <FormattedMessage id="APPROVED_BY" />:
                  {approval.approvals.map((approval, key) => (
                    <Approver key={key}>
                      {getUserName(approval.approver)},{" "}
                      {formattedDate({ date: new Date(approval.createdAt) })}
                    </Approver>
                  ))}
                </ApprovedByContainer>
              ) : undefined}
            </Tooltip>
          );
        })}
      </Container>
      <If isTrue={(ineffectualApprovals || []).length > 0}>
        <Tooltip
          key="ineffectual-approvals"
          id="INEFFECTUAL_APPROVALS"
          className="flex items-center border-l border-dashed border-blue-800 pl-4 pr-2"
          element={
            <Item>
              <NumberCircle>
                <OutlineCheckRoundedIcon />
              </NumberCircle>
            </Item>
          }
        >
          <ApprovedByContainer>
            <FormattedMessage id="APPROVED_BY" />:
            {ineffectualApprovals?.[0]?.approvals.map((approval, key) => (
              <Approver key={key}>
                {getUserName(approval.approver)},{" "}
                {formattedDate({ date: new Date(approval.createdAt) })}
              </Approver>
            ))}
          </ApprovedByContainer>
        </Tooltip>
      </If>
    </If>
  );
};
