import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";

type RowToggleProps = {
  isOpened: boolean;
  count?: number;
};

const Container = tw.div`
  grid grid-flow-col items-center text-blue-500 rounded-3xl border-2 border-blue-500 pl-1.5 pr-0.5
  text-xs font-bold h-5 content-center cursor-pointer hover:bg-blue-100 mr-2
`;

export const RowToggle: FC<RowToggleProps> = ({ isOpened, count }) => (
  <Container>
    {count}
    {isOpened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
  </Container>
);
