import { PickersActionBar, PickersActionBarProps } from "@mui/x-date-pickers";
import { FC } from "react";
import tw from "tailwind-styled-components";

const StyledPickersActionBar = tw(PickersActionBar)<PickersActionBarProps>`
m-[10px] pt-[10px] col-span-2 grid grid-cols-2 grid-rows-2
`;
const CustomPickersActionBar: FC<PickersActionBarProps> = (props) => {
  return (
    <StyledPickersActionBar
      {...props}
      sx={{
        ".MuiButton-root": {
          // Clear button
          "&:nth-child(1)": {
            gridColumn: 2,
            gridRow: 1,
            textTransform: "none",
            fontSize: "0.75rem",
            lineHeight: "1rem",
            textAlign: "center",
            display: "inline-block",
            "&:hover": {
              background: "none",
            },
            width: "10px",
            right: "30px",
            marginBottom: "10px",
          },
          // Cancel button
          "&:nth-child(2)": {
            gridColumn: 1,
            gridRow: 2,
            border: "1px solid rgba(41, 59, 89, 1)",
            color: "rgba(41, 59, 89, 1)",
            borderRadius: "1.25rem",
            fontSize: "0.75rem",
            lineHeight: "1rem",
            textTransform: "none",
          },
          // Accept button
          "&:nth-child(3)": {
            gridColumn: 2,
            gridRow: 2,
            border: "1px solid rgba(41, 59, 89, 1)",
            background: "rgba(41, 59, 89, 1)",
            color: "#fff",
            borderRadius: "1.25rem",
            fontSize: "0.75rem",
            lineHeight: "1rem",
            textTransform: "none",
          },
        },
      }}
      actions={["clear", "cancel", "accept"]}
    />
  );
};

export default CustomPickersActionBar;
