import {
  QueryOrgMaterialsFilter,
  useOrgMaterialsFilteredQuery,
} from "@/generated/graphql";
import { useState } from "react";
import { useDebounce } from "use-debounce";

const KEYWORD_TYPE_DELAY = 500;

export const useOrgMaterials = (filter?: QueryOrgMaterialsFilter) => {
  const [searchQuery, setSearchQuery] = useState<string>();
  const { connected } = filter || {};

  const [debouncedSearch] = useDebounce(searchQuery, KEYWORD_TYPE_DELAY);

  const { data, loading } = useOrgMaterialsFilteredQuery({
    variables: {
      filter: {
        search: debouncedSearch || undefined,
        connected,
      },
      first: 20,
    },
    skip: !debouncedSearch,
  });

  return {
    orgMaterials: data?.orgMaterials.edges.map(({ node }) => node) || [],
    setSearchQuery,
    loading,
  };
};
