import { anyManufacturerId } from "./ManufacturerPicker";

export const getManufacturerIdFromPicker = (
  id: string | null,
): string | undefined => {
  if (!id || id === anyManufacturerId) {
    return undefined;
  }
  return id;
};
