import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { WarningIcon } from "@/common/components/dialog-icons/WarningIcon";
import { useDialog } from "@/common/components/dialog/DialogProvider";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import {
  BuyoutFieldsFragment,
  useCancelBuyoutMutation,
} from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

type Props = {
  buyout: Pick<BuyoutFieldsFragment, "id" | "clientIdentifier" | "version">;
};

export const BuyoutCancelButton: FC<Props> = ({ buyout }) => {
  const intl = useIntl();
  const [cancelBuyout] = useCancelBuyoutMutation();
  const { setError } = useGlobalError();

  const { openDialog } = useDialog();
  const { setSuccessAlert } = useSnackbar();

  const openCancelBuyoutsDialog = () => {
    openDialog({
      cancelButtonText: intl.$t({ id: "CANCEL" }),
      confirmButtonText: intl.$t({ id: "PROCEED" }),
      icon: <WarningIcon />,
      title: intl.$t(
        {
          id: "CANCEL_BUYOUT_NUMBER",
        },
        { buyoutIdentifier: buyout.clientIdentifier },
      ),
      text: intl.$t({ id: "CONFIRM_BUYOUT_CANCEL" }),
      handleConfirm: async () => {
        try {
          const { errors } = await cancelBuyout({
            variables: {
              input: { buyoutId: buyout.id, version: buyout.version },
            },
          });

          if (errors) {
            setError(errors);
          } else {
            setSuccessAlert(
              intl.$t(
                { id: "BUYOUT_CANCEL_SUCCESS_NUMBER" },
                { buyoutIdentifier: buyout.clientIdentifier },
              ),
            );
          }
          return !errors;
        } catch (errors) {
          setError(errors);
          return false;
        }
      },
    });
  };

  return (
    <OutlinedButton
      onClick={openCancelBuyoutsDialog}
      testId="cancel-buyout-button"
    >
      <FormattedMessage id={"CANCEL_BUYOUT"} />
    </OutlinedButton>
  );
};
