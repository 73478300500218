import { HeaderContainerWithSecondaryNavigation } from "@/common/components/header-container/HeaderContainer";
import { If } from "@/common/components/if/If";
import { SearchInput } from "@/common/components/search-input/SearchInput";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import {
  TableViewProvider,
  VIEW_STATE,
  useTableView,
} from "@/common/providers/TableViewProvider";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useOrgSettingsExtended } from "../../../org-settings/hooks/useOrgSettingsExtended";
import { AddTypeMenu } from "./components/AddTypeMenu";
import { CostTypeList } from "./components/CostTypeList";
import { useCostTypesConfiguration } from "./components/CostTypes.config";
import { CostTypesFooter } from "./components/CostTypesFooter";
import { ImportExternalCostTypesButton } from "./components/ImportExternalCostTypesButton";
import { ImportExternalCostTypesModal } from "./components/import-external-codes/ImportExternalCostCodesModal";
import {
  CostTypesListProvider,
  useCostTypesList,
} from "./providers/CostTypesListProvider";
import { ImportExternalCostTypesProvider } from "./providers/ImportExternalCostTypesProvider";
import { SyncCostTypesProvider } from "./providers/SyncCostTypesProvider";

const HeaderRightPanel = tw.div`flex flex-row`;
const Filters = tw.div`
  grid grid-flow-col gap-5 items-start
`;
const SpreadSheetContainer = tw.div`pt-12`;

const CostTypesWithProvider = () => {
  const intl = useIntl();
  const { costTypes } = useCostTypesList();
  const { spreadsheetConfig } = useCostTypesConfiguration();
  const { tableView } = useTableView();

  const [searchQuery, setSearchQuery] = useState("");

  const filteredCostTypes = useMemo(() => {
    if (!searchQuery) {
      return costTypes;
    }
    return costTypes.filter((costType) => {
      return (
        costType.description
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        costType.code.toLowerCase().includes(searchQuery.toLowerCase()) ||
        !costType.description ||
        !costType.code
      );
    });
  }, [costTypes, searchQuery]);

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "COST_TYPES" })}</title>
      </Helmet>
      <If isTrue={tableView === VIEW_STATE.normal}>
        <HeaderContainerWithSecondaryNavigation>
          <Filters>
            <SearchInput
              placeHolder={intl.$t({ id: "SEARCH_COST_TYPE" })}
              onChange={setSearchQuery}
            />
          </Filters>
          <HeaderRightPanel>
            <ImportExternalCostTypesButton />
            <AddTypeMenu />
          </HeaderRightPanel>
        </HeaderContainerWithSecondaryNavigation>
        <CostTypeList items={filteredCostTypes} />
      </If>
      <If isTrue={tableView === VIEW_STATE.spreadsheet}>
        <SpreadSheetContainer>
          <SpreadSheetTable
            items={costTypes}
            columns={spreadsheetConfig}
            height="calc(100vh - 320px)"
          />
        </SpreadSheetContainer>
      </If>
      <CostTypesFooter />
      <ImportExternalCostTypesModal />
    </>
  );
};

export const CostTypes = () => {
  const { spreadsheetConfig } = useCostTypesConfiguration();
  const { connectedSourceSystem } = useOrgSettingsExtended();

  return (
    <NestedStepperProvider>
      <CostTypesListProvider>
        <ImportExternalCostTypesProvider
          defaultSourceSystem={connectedSourceSystem?.system}
        >
          <ColumnMapperProvider config={spreadsheetConfig}>
            <TableViewProvider defaultView={VIEW_STATE.normal}>
              <SyncCostTypesProvider>
                <CostTypesWithProvider />
              </SyncCostTypesProvider>
            </TableViewProvider>
          </ColumnMapperProvider>
        </ImportExternalCostTypesProvider>
      </CostTypesListProvider>
    </NestedStepperProvider>
  );
};
