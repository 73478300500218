import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { routes } from "@/config/routes";
import { ReleaseStatus } from "@/generated/graphql";
import { Check, ChevronRight } from "@mui/icons-material";
import { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useReleaseActions } from "../../../providers/ReleaseActionsProvider";
import { useRelease } from "../../../providers/ReleaseProvider";

const Container = tw.div`
  grid grid-flow-col place-items-center justify-end gap-2
  ${({ $hasAssignments }: { $hasAssignments: boolean }) => $hasAssignments && "border-r border-dashed border-blue-800 px-4"}
`;

const CheckStyled = tw(Check)`
  text-blue-800 text-xs
`;

type ReassignmentItemProps = {
  $active: boolean;
};
const ReassignmentItem = tw.div<ReassignmentItemProps>`
  w-5 h-5 rounded-3xl border grid place-items-center border-blue-800 text-blue-800 text-xs opacity-40
  hover:opacity-100 cursor-pointer select-none hover:border-blue-500
  ${({ $active }) => ($active ? "opacity-100" : "")}
`;

const StyledChevronRight = tw(ChevronRight)`
  text-blue-800 text-xs
`;

export const VendorReassignmentWizard = () => {
  const { vendorReassignmentReleases } = useReleaseActions();
  const { release } = useRelease();
  const navigate = useNavigate();

  return (
    <Container $hasAssignments={vendorReassignmentReleases.length > 1}>
      <If isTrue={vendorReassignmentReleases.length > 1}>
        {vendorReassignmentReleases.map((rel, index) => (
          <Fragment key={index}>
            <Tooltip
              id="vendor-reassignment-tooltip"
              element={
                <ReassignmentItem
                  key={rel.id}
                  $active={release?.id === vendorReassignmentReleases[index].id}
                  onClick={() => {
                    navigate(
                      generatePath(routes.specifyDeliveryDetails, {
                        deliveryId: rel.id,
                      }),
                    );
                  }}
                >
                  {rel.status === ReleaseStatus.Requested ? (
                    <CheckStyled />
                  ) : (
                    index + 1
                  )}
                </ReassignmentItem>
              }
            >
              <FormattedMessage
                id={`RELEASE_WITH_NUM`}
                values={{ num: index + 1 }}
              />{" "}
              <FormattedMessage id={`RELEASE_STATUS_${rel.status}`} />
            </Tooltip>
            <If isTrue={index < vendorReassignmentReleases.length - 1}>
              <StyledChevronRight />
            </If>
          </Fragment>
        ))}
      </If>
    </Container>
  );
};
