import { useNestedStepper } from "@/common/components/stepper/NestedStepper";
import {
  NestedWizardModalPage,
  WizardModalPage,
} from "@/common/components/wizard-modal/WizardModal";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { UpdateSourceSystemsSettingsInput } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useAgaveHostedWizard } from "../../../AgaveHostedWizardProvider";
import { AgaveWizardInvoiceSettings } from "../../../steps/invoice-settings/AgaveWizardInvoiceSettings";

export const useInvoiceExportSettingsPage = (): WizardModalPage => {
  const intl = useIntl();
  const { moveToPreviousStep, moveToNextStep } = useNestedStepper();
  const { handleSaveWizardData, autoExportApprovedInvoices, autoPostInvoices } =
    useAgaveHostedWizard();

  const { connectedSourceSystem } = useOrgSettingsExtended();

  const onNextClick = useCallback(async () => {
    const settings: Omit<UpdateSourceSystemsSettingsInput, "system"> = {
      autoExportApprovedInvoices,
      autoPostInvoices,
    };
    await handleSaveWizardData(settings);
    moveToNextStep();
  }, [
    autoExportApprovedInvoices,
    autoPostInvoices,
    handleSaveWizardData,
    moveToNextStep,
  ]);

  return useMemo(() => {
    const pages: NestedWizardModalPage[] = [
      {
        component: <AgaveWizardInvoiceSettings />,
        onPreviousClick: moveToPreviousStep,
        onNextClick,
      },
    ];

    return {
      feature: IntegrationFeature.InvoiceWrites,
      title: intl.$t({ id: "INVOICE_EXPORT_SETTINGS" }),
      completed: connectedSourceSystem?.autoExportApprovedInvoices !== null,
      pages,
    };
  }, [
    moveToPreviousStep,
    onNextClick,
    intl,
    connectedSourceSystem?.autoExportApprovedInvoices,
  ]);
};
