import { QUERYSTRING } from "@/common/const";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import {
  ExternalVendorFieldsFragment,
  QueryExternalVendorsFilter,
  SourceSystem,
} from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import React, { FC, createContext, useContext, useState } from "react";
import { useAgaveExternalVendorsWithPagination } from "../../../home/project/hooks/useAgaveExternalVendorsWithPagination";

type ProviderContextType = {
  externalVendors: (ExternalVendorFieldsFragment & {
    id: string;
  })[];
  loading: boolean;
  totalCount: number;
  filter?: QueryExternalVendorsFilter;
  setFilter: (filter: QueryExternalVendorsFilter) => void;
  isFiltered: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  externalVendors: [],
  loading: false,
  totalCount: 0,
  filter: undefined,
  setFilter: NoFunction,
  isFiltered: false,
});

export const AgaveExternalVendorsProvider: FC<{
  children: React.ReactNode;
  sourceSystem: SourceSystem | null;
}> = ({ children, sourceSystem }) => {
  const { queryParams } = useQueryParams();
  const [filter, setFilter] = useState<QueryExternalVendorsFilter>({
    search: queryParams.get(QUERYSTRING.SEARCH),
    mapped:
      queryParams.get(QUERYSTRING.MAPPED) !== undefined
        ? Boolean(queryParams.get(QUERYSTRING.MAPPED))
        : undefined,
  });
  const { externalVendors, loading, error, totalCount, setPage } =
    useAgaveExternalVendorsWithPagination(sourceSystem, filter);

  useErrorEffect(error);

  const setFilterUpdate = (filter: QueryExternalVendorsFilter) => {
    setPage({
      page: 0,
      queryParams: {
        ...filter,
      },
    });
    setFilter(filter);
  };

  return (
    <ProviderContext.Provider
      value={{
        externalVendors:
          externalVendors.map((i) => ({
            ...i,
            id: i.externalId,
          })) || [],
        loading,
        totalCount,
        filter,
        isFiltered: !!(filter?.search || filter?.mapped !== undefined),
        setFilter: setFilterUpdate,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useAgaveExternalVendorsProvider = () =>
  useContext(ProviderContext);
