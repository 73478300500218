import { positionSuffix } from "@/common/utils/positionSuffix";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type PricePositionProps = {
  position?: {
    index: number;
    isLast: boolean;
  } | null;
};

type PricePositionContainerProps = {
  $isBest?: boolean;
  $isWorst?: boolean;
};

const PricePositionContainer = tw.div<PricePositionContainerProps>`
  flex items-center justify-center h-full text-sm font-medium 
  px-4 py-0.5 rounded-4xl border border-gray-300
  ${(props: PricePositionContainerProps) =>
    props.$isBest
      ? "bg-green-600 text-white"
      : props.$isWorst
        ? "bg-yellow-300"
        : "bg-gray-100"};
`;

const PositionContainer = tw.span``;

const Sup = tw.span`
  align-super text-2xs
`;

const Position: FC<{ index: number }> = ({ index }) => (
  <PositionContainer>
    {index}
    <Sup>{positionSuffix(index)}</Sup>
  </PositionContainer>
);

export const PricePositionRank: FC<PricePositionProps> = ({ position }) => {
  const isBest = position?.index === 0;
  const isWorst = position?.isLast && position.index > 0;
  const isNotAvailable = position?.index === -1;

  return (
    <PricePositionContainer $isBest={isBest} $isWorst={isWorst}>
      {isBest ? (
        <FormattedMessage id="BEST" />
      ) : isWorst ? (
        <FormattedMessage id="LAST" />
      ) : isNotAvailable ? (
        <FormattedMessage id="N_A" />
      ) : (
        <Position index={(position?.index || 0) + 1} />
      )}
    </PricePositionContainer>
  );
};
