import { FC } from "react";
import tw from "tailwind-styled-components";

const Image = tw.img`h-fit w-40 object-contain`;

type Props = {
  url: string;
  className?: string;
};

export const IntegrationLogo: FC<Props> = ({ url, className }) => {
  return <Image loading="lazy" src={url} alt="logo" className={className} />;
};
