import {
  CostCodeFieldsFragment,
  DistributorReleaseItemFieldsFragment,
  ReleaseItemFieldsFragment,
  ZoneFieldsFragment,
} from "@/generated/graphql";

export const getCostCodesByReleaseItems = (
  releaseItems: (
    | DistributorReleaseItemFieldsFragment
    | ReleaseItemFieldsFragment
  )[],
  unassignedCostCode: Omit<CostCodeFieldsFragment, "mappings">,
  unassignedZone: ZoneFieldsFragment,
  zone?: ZoneFieldsFragment,
  isAddMode?: boolean,
) => {
  const costCodes: Omit<CostCodeFieldsFragment, "mappings" | "inUse">[] = [];

  releaseItems.forEach((relItem) => {
    if (
      relItem &&
      relItem.costCode &&
      !costCodes.map((c) => c.id).includes(relItem.costCode.id) &&
      ((!relItem.zone && zone?.id === unassignedZone.id) ||
        relItem.zone?.id === zone?.id)
    ) {
      costCodes.push(relItem.costCode);
    }
  });
  costCodes.sort((a, b) => a.code.localeCompare(b.code));
  if (
    releaseItems
      .filter(
        (r) =>
          (!r.zone && zone?.id === unassignedZone.id) ||
          zone?.id === r.zone?.id,
      )
      .some((i) => i?.costCode === null) ||
    (isAddMode && zone?.id === unassignedZone.id)
  ) {
    costCodes.push(unassignedCostCode);
  }
  return costCodes;
};
