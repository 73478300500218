import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import {
  ProjectCostCodeFieldsFragment,
  useProjectCostCodesQuery,
} from "@/generated/graphql";
import { useEffect, useMemo, useState } from "react";

type Options = {
  includeUnspecifiedCostCode?: boolean;
  selectedCostCodeId?: string | null;
};

type ProjectCostCode = ProjectCostCodeFieldsFragment & {
  isExcluded?: boolean;
  formatted: string;
};

export const useProjectCostCodes = (
  projectId?: string | null,
  options?: Options,
) => {
  const [id, setId] = useState<string | null | undefined>(projectId);

  useEffect(() => {
    setId(projectId);
  }, [projectId]);

  const { data, loading, error } = useProjectCostCodesQuery({
    variables: { id: id || "" },
    skip: !id,
  });

  useErrorEffect(error);
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { costCodes, formatCostCode, showCodeWithNumber } = useCostCodes();

  const projectCostCodes = useMemo(() => {
    if (projectId && data?.project?.restrictCostCodes) {
      return (
        data?.project?.costCodes?.map((cc) => ({
          ...cc,
          formatted: showCodeWithNumber ? formatCostCode(cc) : cc.description,
        })) || []
      );
    }

    return costCodes || [];
  }, [
    costCodes,
    data?.project?.costCodes,
    data?.project?.restrictCostCodes,
    formatCostCode,
    projectId,
    showCodeWithNumber,
  ]);

  const allCostCodes = useMemo((): ProjectCostCode[] => {
    if (!options?.selectedCostCodeId) {
      return projectCostCodes;
    }
    if (
      !projectCostCodes?.find(
        (costCode) => costCode.id === options?.selectedCostCodeId,
      )
    ) {
      const existingCostCode = costCodes.find(
        (costCode) => costCode.id === options.selectedCostCodeId,
      );
      if (existingCostCode) {
        return [
          ...projectCostCodes,
          ...(existingCostCode
            ? [
                {
                  ...existingCostCode,
                  isExcluded: true,
                  formatted: formatCostCode(existingCostCode),
                },
              ]
            : []),
          ...(options?.includeUnspecifiedCostCode
            ? [
                {
                  ...unassignedCostCode,
                  formatted: unassignedCostCode.description,
                },
              ]
            : []),
        ];
      }
    }

    return [
      ...projectCostCodes,
      ...(options?.includeUnspecifiedCostCode
        ? [
            {
              ...unassignedCostCode,
              formatted: unassignedCostCode.description,
            },
          ]
        : []),
    ];
  }, [
    costCodes,
    formatCostCode,
    options?.includeUnspecifiedCostCode,
    options?.selectedCostCodeId,
    projectCostCodes,
    unassignedCostCode,
  ]);

  const isNotSupportedCostCode = useMemo(
    () =>
      data?.project?.restrictCostCodes &&
      !data?.project?.costCodes?.find(
        (costCode) => costCode.id === options?.selectedCostCodeId,
      ),
    [data?.project, options?.selectedCostCodeId],
  );

  const isCostCodeSupportedByFormattedName = (formattedName: string) => {
    if (!data?.project?.restrictCostCodes) {
      return true;
    }
    if (
      !allCostCodes.find((costCode) => costCode.formatted === formattedName)
    ) {
      return true;
    }

    return !!projectCostCodes.find(
      (costCode) =>
        formattedName &&
        costCode.formatted.toLocaleLowerCase() ===
          formattedName.toLowerCase() &&
        data?.project?.costCodes?.find(
          (projectCostCode) => projectCostCode.id === costCode.id,
        ),
    );
  };

  return {
    projectCostCodes,
    isNotSupportedCostCode,
    costCodes: allCostCodes,
    formatCostCode,
    isCostCodeSupportedByFormattedName,
    loading,
    setProjectId: setId,
  };
};
