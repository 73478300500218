import { LOCAL_STORAGE_KEYS } from "@/common/const";
import { useSequence } from "@/common/hooks/useSequence";
import { routes } from "@/config/routes";
import { BuyoutsBuyoutFieldsFragment } from "@/generated/graphql";
import { generatePath } from "react-router-dom";
import { useBuyoutSequenceStore } from "./useBuyoutSequenceStore";

export const useBuyoutSequence = () => {
  return useSequence<BuyoutsBuyoutFieldsFragment>({
    listPath: routes.buyouts,
    localStorageConfig: LOCAL_STORAGE_KEYS.BUYOUT_SEQUENCES,
    singEntityPath: (id: string) => generatePath(routes.buyout, { id }),
    useStore: useBuyoutSequenceStore,
  });
};
