import { AssetItem } from "@/common/components/asset-item/AssetItem";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import { AssetContext, AssetFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { InvoiceIssueAssetUpload } from "./InvoiceIssueAssetUpload";

const Container = tw.div`mt-4`;
const DescriptionContainer = tw.div`grid grid-cols-[3fr_1fr] gap-2 mb-2`;
const Assets = tw.div`grid col-span-full grid-flow-col justify-items-start gap-2 mb-2 justify-start`;

export enum ResponseType {
  Accept = "accept",
  Decline = "decline",
  Kickback = "kickback",
}

type Props = {
  type: ResponseType | null;
};

const InvoiceRespondFormWithProvider: FC<Props> = ({ type }) => {
  const intl = useIntl();
  const { setValue } = useFormContext();
  const { assets, removeAsset } = useUploadAssets();

  const updateUrls = useCallback(
    (assets: AssetFieldsFragment[]) => {
      setValue(
        "assetUrls",
        assets.map((asset) => asset.url),
      );
    },
    [setValue],
  );

  const remove = useCallback(
    async (asset: AssetFieldsFragment) => {
      const files = await removeAsset(asset);
      updateUrls(files);
    },
    [removeAsset, updateUrls],
  );

  const label = useMemo(() => {
    switch (type) {
      case ResponseType.Accept:
        return intl.$t({ id: "ACCEPT_ISSUE_LABEL" });
      case ResponseType.Decline:
        return intl.$t({ id: "DECLINE_ISSUE_LABEL" });
      case ResponseType.Kickback:
        return intl.$t({ id: "KICKBACK_RESPONSE_LABEL" });
      default:
        return "";
    }
  }, [type, intl]);

  return (
    <Container>
      <DescriptionContainer>
        <TextFieldControlled
          name="resolution"
          multiline
          label={label}
          required
          minRows={3}
          maxRows={10}
        />
        <InvoiceIssueAssetUpload updateUrls={updateUrls} />
      </DescriptionContainer>
      <Assets>
        {assets?.map((asset, index) => (
          <AssetItem key={index} asset={asset} remove={remove} />
        ))}
      </Assets>
    </Container>
  );
};

export const InvoiceRespondForm: FC<Props> = (props) => (
  <UploadAssetProvider context={AssetContext.Note}>
    <InvoiceRespondFormWithProvider {...props} />
  </UploadAssetProvider>
);
