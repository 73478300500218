import {
  WizardModal,
  WizardModalMode,
} from "@/common/components/wizard-modal/WizardModal";
import { useUploadInvoiceModal } from "../../providers/UploadInvoiceProvider";

export const InvoiceUploadModal = () => {
  const {
    uploadInvoiceModalOpened,
    closeUploadInvoiceModal,
    uploadInvoiceModalPages,
  } = useUploadInvoiceModal();

  return (
    <WizardModal
      opened={uploadInvoiceModalOpened}
      onClose={closeUploadInvoiceModal}
      mode={WizardModalMode.SINGLE_PAGE}
      pages={uploadInvoiceModalPages}
    />
  );
};
