import { If } from "@/common/components/if/If";
import { Instructions } from "@/common/components/instructions/Instructions";
import { Loader } from "@/common/components/loader/Loader";
import { QuoteDocumentPanel } from "@/common/components/quote-document-panel/QuoteDocumentPanel";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { SAVED_LIST_VIEW_ID } from "@/common/const";
import {
  ColumnMapperProvider,
  useColumnMapper,
} from "@/common/providers/ColumnMapperProvider";
import { EstimatedPricesProvider } from "@/common/providers/EstimatedPricesProvider";
import {
  TableViewProvider,
  VIEW_STATE,
  useTableView,
} from "@/common/providers/TableViewProvider";
import { useVendorPrices } from "@/contractor/pages/admin/org-items/pages/materials-prices/hooks/useVendorPrices";
import {
  BuyoutFieldsFragment,
  BuyoutStatus,
  InstructionInput,
} from "@/generated/graphql";
import { FC, useCallback, useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { ProjectTagsProvider } from "../../../project/providers/ProjectTagsProvider";
import { useContractorBuyout } from "../../providers/ContractorBuyoutProvider";
import { BuyoutStandaloneReleaseItemsList } from "../common/buyout-standalone-release-items-list/BuyoutStandaloneReleaseItemsList";
import { VendorBuyoutInlineNotesAndTermsPanel } from "../common/notes-and-attachments/VendorBuyoutInlineNotesAndTermsPanel";
import { useBuyoutItemsListConfiguration } from "../common/table-configurations/BuyoutListItemsConfiguration";
import { BuyoutActions } from "./components/BuyoutActions";
import { BuyoutAddItems } from "./components/BuyoutAddItems";
import { BuyoutList } from "./components/BuyoutList";
import { BuyoutNonQuotedHeader } from "./header/BuyoutNonQuotedHeader";
import {
  BuyoutGroupedProvider,
  useBuyoutGrouped,
} from "./providers/BuyoutGroupedProvider";
import {
  SyncBuyoutItemsProvider,
  useSyncBuyoutItems,
} from "./providers/SyncBuyoutItemsProvider";
const Container = tw.div`pb-15`;

const BuyoutNonQuotedWithProviders: FC<{
  buyout: BuyoutFieldsFragment;
  loading: boolean;
}> = ({ buyout, loading }) => {
  const { isEditable } = useBuyoutGrouped();
  const buyoutItemsListConfiguration = useBuyoutItemsListConfiguration();
  const { tableView } = useTableView();
  const { saving } = useSyncBuyoutItems();
  const { setGlobalVendorId } = useVendorPrices();
  const { spreadsheetData } = useColumnMapper();
  const [newSpreadsheetData, setNewSpreadsheetData] =
    useState<Record<string, string>[]>(spreadsheetData);
  const { updateBuyout } = useContractorBuyout();

  useEffect(() => {
    if (setGlobalVendorId) {
      setGlobalVendorId(buyout?.sellerOrgLocation?.id);
    }
  }, [buyout?.sellerOrgLocation?.id, setGlobalVendorId]);

  const saveInstructions = useCallback(
    async (instructions: InstructionInput) => {
      if (!buyout) {
        return;
      }

      await updateBuyout({
        instructions,
      });
    },
    [buyout, updateBuyout],
  );

  return (
    <Container>
      <BuyoutNonQuotedHeader buyout={buyout} />
      <If isTrue={tableView === VIEW_STATE.normal}>
        <BuyoutList
          buyoutItems={buyout.items}
          loading={loading}
          totalCount={buyout.items.length}
          readonly={!isEditable}
        />
        <BuyoutStandaloneReleaseItemsList />
      </If>
      <If isTrue={tableView === VIEW_STATE.spreadsheet}>
        <SpreadSheetTable
          items={buyout.items}
          columns={buyoutItemsListConfiguration.spreadsheetConfig}
          saving={saving}
          height="calc(100vh - 450px)"
          onChanges={setNewSpreadsheetData}
        />
      </If>
      <If isTrue={buyout.instructions || isEditable}>
        <Instructions
          instruction={buyout.instructions}
          projectId={buyout.project?.id}
          saveInstruction={saveInstructions}
          readonly={!isEditable}
        />
      </If>
      <VendorBuyoutInlineNotesAndTermsPanel />
      <QuoteDocumentPanel
        quoteDocument={buyout.quoteDocument}
        readonly={!isEditable}
      />
      <BuyoutActions spreadsheetData={newSpreadsheetData} />
      <If
        isTrue={
          buyout.status !== BuyoutStatus.Cancelled &&
          buyout.status !== BuyoutStatus.Active &&
          isEditable
        }
      >
        <BuyoutAddItems buyout={buyout} />
      </If>
    </Container>
  );
};

export const BuyoutNonQuoted = () => {
  const { buyout, loading } = useContractorBuyout();
  const buyoutItemsListConfiguration = useBuyoutItemsListConfiguration();

  if (!buyout) {
    return <Loader loading />;
  }

  return (
    <ProjectTagsProvider id={buyout.project.id}>
      <EstimatedPricesProvider projectId={buyout?.project?.id}>
        <ColumnMapperProvider
          config={buyoutItemsListConfiguration.spreadsheetConfig}
        >
          <TableViewProvider
            saveSelection={
              buyout?.status === BuyoutStatus.Draft
                ? { id: SAVED_LIST_VIEW_ID.BUYOUT }
                : undefined
            }
            defaultView={
              buyout?.status === BuyoutStatus.Draft
                ? VIEW_STATE.spreadsheet
                : VIEW_STATE.normal
            }
          >
            <BuyoutGroupedProvider buyout={buyout}>
              <SyncBuyoutItemsProvider>
                <BuyoutNonQuotedWithProviders
                  buyout={buyout}
                  loading={loading}
                />
              </SyncBuyoutItemsProvider>
            </BuyoutGroupedProvider>
          </TableViewProvider>
        </ColumnMapperProvider>
      </EstimatedPricesProvider>
    </ProjectTagsProvider>
  );
};
