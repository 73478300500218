import { Popover } from "@/common/components/popover/Popover";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Autocomplete,
  Checkbox,
  Chip,
  createFilterOptions,
} from "@mui/material";
import React, { ReactNode, Ref, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../../../if/If";
import { Loader } from "../../../loader/Loader";
import { Tooltip } from "../../../tooltip/Tooltip";
import { AvatarStyled } from "../../styles/Selector.styles";
import { getMultipleValueMaxWidth } from "../../utils/getMultipleValueMaxWidth";
import { InputRenderer } from "../common/InputRenderer";
import { PopupIcon } from "../common/PopupIcon";
import { SelectCommonProps } from "../common/SelectCommonProps";
import { CustomPopper } from "../single/components/CustomPoper";

const CheckboxPlaceholder = tw.div`w-[48px]`;

export type MultiselectProps<T> = SelectCommonProps<T> & {
  chipSize?: "small" | "medium";
  disableCloseOnSelect?: boolean;
  filterSelectedOptions?: boolean;
  includeCheckbox?: boolean;
  includeCheckboxFn?: (option: T) => boolean;
  optionDisabledFn?: (option: T) => boolean;
  optionTooltipRenderFn?: (option: T) => ReactNode;
  limitTags?: number;
  moreItems?: ReactNode;
  onMultipleChange?: (value: string[] | null) => void;
  renderTag?: (option: T) => ReactNode;
  values?: string[] | null;
  noWrap?: boolean;
  xs?: boolean;
  disabledFn?: (option: T) => boolean;
};

const CREATABLE_KEYS = ["[", "]"];

const MultiselectWithoutRef = <T,>(
  {
    chipSize,
    className,
    creatable = false,
    creatableFn,
    creatableTextKey,
    customRender,
    disableClearable = true,
    disabled,
    optionDisabledFn,
    optionTooltipRenderFn,
    getLabel,
    getOptionLabel,
    getValue,
    includeCheckbox = false,
    includeCheckboxFn,
    inputValue,
    limitTags,
    loading = false,
    moreItems,
    onMultipleChange,
    options,
    popup,
    renderTag,
    staticText = false,
    testId,
    values,
    noWrap,
    sx,
    xs,
    error,
    creatableUseLabel,
    creatableFirstOption,
    ...props
  }: MultiselectProps<T>,
  ref: Ref<HTMLDivElement>,
) => {
  const intl = useIntl();

  const filter = useMemo(
    () => creatableFn && createFilterOptions<T>(),
    [creatableFn],
  );

  const selectedOption = useMemo(() => {
    const option = creatable
      ? values?.map((v) => options.find((o) => getValue(o) === v) ?? v)
      : options.filter((o) => values?.includes(getValue(o)));
    return option || undefined;
  }, [creatable, values, options, getValue]);

  const getValueWithDefaultValue = (option: T | null) =>
    option ? getValue(option) || "" : "";

  return (
    <Autocomplete
      ref={ref}
      {...props}
      options={options}
      multiple
      className={className}
      data-testid={testId}
      inputValue={inputValue}
      disableClearable={disableClearable}
      PopperComponent={CustomPopper}
      onChange={(event, value) => {
        const values = value.map((v, index) => {
          let val = getValueWithDefaultValue(v as T);
          if (index === value.length - 1 && !val) {
            const label = getLabel(v as T);

            if (label) {
              val = label.slice(
                label.indexOf(CREATABLE_KEYS[0]) + 1,
                label.lastIndexOf(CREATABLE_KEYS[1]),
              );
            } else {
              val = value[value.length - 1] as unknown as string;
            }
          }

          return val;
        });

        onMultipleChange?.(values);
      }}
      isOptionEqualToValue={(option, value) =>
        getValue(option) === getValue(value)
      }
      value={selectedOption}
      getOptionLabel={(option) =>
        getOptionLabel
          ? getOptionLabel(option as T) || ""
          : getLabel(option as T) || ""
      }
      freeSolo={creatable}
      filterOptions={
        creatable && creatableFn
          ? (options, params) => {
              if (creatable) {
                const filtered = filter
                  ? filter(options, {
                      ...params,
                      getOptionLabel: creatableUseLabel
                        ? getLabel
                        : getOptionLabel || getLabel,
                    })
                  : options;
                const { inputValue } = params;
                if (
                  inputValue !== "" &&
                  filtered &&
                  !filtered.find((o) => getLabel(o) === inputValue) &&
                  typeof values === "object" &&
                  !values?.find((o) => o === inputValue)
                ) {
                  const creatableResults = creatableFn(
                    intl.$t(
                      { id: creatableTextKey || "CREATABLE_ADD_NEW" },
                      { inputValue },
                    ),
                    inputValue,
                  );
                  if (creatableResults) {
                    const mappedCreatableResults = (
                      (creatableResults as Array<T>)?.length > 0
                        ? creatableResults
                        : [creatableResults]
                    ) as Array<T>;
                    return creatableFirstOption
                      ? mappedCreatableResults.concat(filtered)
                      : filtered.concat(mappedCreatableResults);
                  }
                  return filtered;
                }
                return filtered;
              }

              return options;
            }
          : undefined
      }
      disabled={disabled}
      readOnly={staticText || disabled}
      popupIcon={
        <PopupIcon
          disabled={disabled || staticText}
          loading={loading}
          popup={popup}
        />
      }
      renderTags={(value, getTagProps) => {
        const tags = value
          .slice(limitTags ? 0 : undefined, limitTags)
          .map((option, index) =>
            renderTag ? (
              renderTag(option)
            ) : (
              <Chip
                label={
                  getOptionLabel ? getOptionLabel(option) : getLabel(option)
                }
                title={getLabel(option)}
                size={chipSize}
                {...getTagProps({ index })}
                className={`float-left -my-0.5 mx-0.5 ${getMultipleValueMaxWidth(
                  limitTags,
                  values?.length,
                )}`}
                key={index}
              />
            ),
          );
        const additionalItems =
          limitTags && limitTags < value.length
            ? value.length - (limitTags || 0)
            : undefined;
        return (
          <>
            {tags}
            <If isTrue={additionalItems}>
              <Tooltip
                id="limit"
                color="secondary"
                element={<AvatarStyled>+{additionalItems}</AvatarStyled>}
              >
                {moreItems}
              </Tooltip>
            </If>
          </>
        );
      }}
      ChipProps={{ size: chipSize }}
      loading={loading}
      loadingText={<Loader loading small />}
      limitTags={limitTags}
      renderInput={(params) => (
        <InputRenderer
          props={{ ...props, className, staticText, error, xs }}
          params={params}
        />
      )}
      sx={{
        ...sx,
        ...(noWrap && {
          ".MuiInputBase-formControl.MuiAutocomplete-inputRoot": {
            flexWrap: "nowrap",
          },
        }),
      }}
      renderOption={(
        props: React.HTMLAttributes<HTMLLIElement>,
        option: T,
        { selected },
      ) => {
        const value = getLabel(option);
        const key = getValue(option);

        const optionDisabled =
          !!optionDisabledFn && optionDisabledFn(option) === true;
        const optionTooltip = optionTooltipRenderFn?.(option);

        const element = (
          <li
            {...props}
            key={key}
            className={`${props.className} ${customRender ? "m-0 py-0" : ""} ${optionDisabled ? "cursor-default opacity-30" : ""}`}
            data-testid={`${testId}-option-${key}`}
            onClick={(e) => {
              if (optionDisabled) {
                e.preventDefault();
                e.stopPropagation();
              } else {
                props.onClick?.(e);
              }
            }}
          >
            <If
              isTrue={
                !!includeCheckboxFn
                  ? includeCheckboxFn(option)
                  : includeCheckbox
              }
            >
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                checked={selected}
                className="pr-4"
              />
            </If>
            <If
              isTrue={
                !!includeCheckboxFn && includeCheckboxFn(option) === false
              }
            >
              <CheckboxPlaceholder />
            </If>
            <If isTrue={customRender}>
              {customRender && customRender(option)}
            </If>
            <If isTrue={!customRender}>{value}</If>
          </li>
        );

        if (!!optionTooltip) {
          return (
            <Popover $arrow id={JSON.stringify(option)} element={element}>
              {optionTooltip}
            </Popover>
          );
        }

        return element;
      }}
    />
  );
};

export const Multiselect = React.forwardRef(MultiselectWithoutRef) as <T>(
  props: MultiselectProps<T> & { ref?: React.ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof MultiselectWithoutRef>;
