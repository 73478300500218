import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { ReleasePartialFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import tw from "tailwind-styled-components";
import { useReleaseSequence } from "../providers/ReleaseSequenceProvider";
const Container = tw.div`
   flex
`;

export const DeliveryCheckbox: FC<{
  delivery: ReleasePartialFieldsFragment;
}> = ({ delivery }) => {
  const { sequenceIds, selectedReleases, setSelectedReleases } =
    useReleaseSequence();
  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedReleases(
        newChecked
          ? [...selectedReleases, delivery]
          : selectedReleases.filter((d) => d.id !== delivery.id),
      );
    },
    [delivery, selectedReleases, setSelectedReleases],
  );

  return (
    <Container>
      <SelectionCheckbox
        itemId={delivery.id}
        items={sequenceIds}
        setSelection={handleChange}
      />
    </Container>
  );
};
