import { AdditionalFiles } from "@/common/components/additional-files/AdditionalFiles";
import { If } from "@/common/components/if/If";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { CustomPaymentTerms } from "@/common/components/terms-and-discounts/CustomPaymentTerms";
import { NotesAndTerms } from "@/common/components/terms-and-discounts/NotesAndTerms";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { useDistributorRelease } from "@/distributor/pages/release/providers/DistributorReleaseProvider";
import {
  AssetContext,
  ReleaseFieldsFragment,
  ReleaseStatus,
} from "@/generated/graphql";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "../upload-asset/UploadAssetProvider";
import { Label } from "./ReleaseTermsAndNotes.styles";

const Container = tw.div`flex flex-col gap-4`;

type Props = {
  onClose: () => void;
  release?:
    | (Pick<
        ReleaseFieldsFragment,
        "notes" | "paymentTerm" | "status" | "assets"
      > & { project?: { id: string } | null })
    | null;
  readonly?: boolean;
};

const ReleaseTermsAndNotesPanelWithProvider: FC<Props> = ({
  onClose,
  release,
  readonly,
}) => {
  const intl = useIntl();
  const [notes, setNotes] = useState(release?.notes || "");
  const [paymentTerm, setPaymentTerm] = useState(release?.paymentTerm);
  const { assets } = useUploadAssets();

  const { updateVendorRelease } = useDistributorRelease();

  const save = async () => {
    if (!readonly) {
      const result = await updateVendorRelease?.({
        notes,
        paymentTerm,
        assetUrls: assets.map((asset) => asset.url),
      });
      if (!result && !checkReleaseStatus(release, [ReleaseStatus.Scheduled])) {
        return;
      }
    }
    onClose();
  };

  return (
    <OverlayPanel
      title={intl.$t({ id: "NOTES_TERMS_DOCS" })}
      onSave={!readonly ? save : undefined}
      onCancel={onClose}
    >
      <Container>
        <If isTrue={!readonly}>
          <FormattedMessage id="PAYMENT_TERMS" tagName={Label} />
        </If>
        <CustomPaymentTerms
          paymentTerm={release?.paymentTerm}
          updateItem={setPaymentTerm}
          readonly={readonly}
        />
        <If isTrue={!readonly || release?.notes}>
          <If isTrue={!readonly}>
            <FormattedMessage id="NOTES_AND_TERMS" tagName={Label} />
          </If>
          <NotesAndTerms
            notes={notes}
            updateItem={setNotes}
            readonly={readonly}
          />
        </If>
        <AdditionalFiles readonly={readonly} />
      </Container>
    </OverlayPanel>
  );
};

export const ReleaseTermsAndNotesPanel: FC<Props> = (props) => {
  const { release } = props;
  return (
    <UploadAssetProvider
      initialAssets={release?.assets}
      context={AssetContext.Note}
      projectId={release?.project?.id ?? ""}
    >
      <ReleaseTermsAndNotesPanelWithProvider {...props} />
    </UploadAssetProvider>
  );
};
