import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  InvoiceQuery,
  ReleaseDocument,
  useUnlinkInvoiceMutation,
} from "@/generated/graphql";

export const useUnlinkInvoice = () => {
  const [unlinkInvoiceMutation] = useUnlinkInvoiceMutation();
  const { setError } = useGlobalError();

  const unlinkInvoice = async (linkId?: string, releaseId?: string) => {
    if (linkId) {
      try {
        const { data } = await unlinkInvoiceMutation({
          variables: {
            invoiceLinkId: linkId,
          },
          update: (cache, { data }) => {
            const invoice = cache.readQuery<InvoiceQuery>({
              query: ReleaseDocument,
              variables: {
                id: releaseId,
              },
            });
            if (invoice?.invoice && data?.unlinkInvoice) {
              cache.writeQuery({
                query: ReleaseDocument,
                variables: {
                  id: releaseId,
                },
                data: {
                  invoice: {
                    ...invoice.invoice,
                    link: null,
                  },
                },
              });
            }
          },
        });
        return !!data?.unlinkInvoice;
      } catch (error) {
        setError(error);
        return false;
      }
    }
  };

  return { unlinkInvoice };
};
