import { ProjectStatus } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { BaseStatusChip, BaseStatusChipProps } from "./BaseStatusChip";

type Props = BaseStatusChipProps & {
  status: ProjectStatus;
};

const PROJECT_STATUS = {
  [ProjectStatus.Active]: "bg-yellow-600",
  [ProjectStatus.Awarded]: "bg-green-300",
  [ProjectStatus.Completed]: "bg-green-600 text-white",
  [ProjectStatus.Lost]: "bg-red-200",
};

export const ProjectStatusChip: FC<Props> = ({ status, type = "normal" }) => {
  return (
    <BaseStatusChip
      type={type}
      classes={{ text: PROJECT_STATUS[status] }}
      data-testid={`project-status-${type}`}
    >
      <FormattedMessage id={`PROJECT_STATUS_${status}`} />
    </BaseStatusChip>
  );
};
