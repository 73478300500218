import { QuantityPicker } from "@/common/components/quantity-picker/QuantityPicker";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { InvoiceStatus } from "@/generated/graphql";
import { CheckCircleOutline } from "@mui/icons-material";
import Decimal from "decimal.js";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";

const Container = tw.div`relative`;
const CheckIcon = tw(
  CheckCircleOutline,
)`text-green-600 absolute left-1 top-1/2 transform -translate-y-1/2 text-lg`;

type Props = {
  item: ExpandedReleaseItem;
  readonly?: boolean;
};

export const InvoiceItemQuantityInput: FC<Props> = ({
  item,
  readonly = false,
}) => {
  const { updateInvoice } = useInvoiceVerification();
  const { invoice } = useInvoiceVerification();
  const invoicedItem = useMemo(
    () => item.invoiceItems?.[0],
    [item.invoiceItems],
  );
  const [invoiced, setInvoiced] = useState<string>(
    item.invoiceItems?.[0]?.quantity,
  );

  const onSave = useCallback(
    async (quantity: string | null) => {
      if (quantity) {
        setInvoiced(quantity);
      }

      if (!invoicedItem) {
        return;
      }
      await updateInvoice(
        {
          id: invoice?.id || "",
          updatedInvoicedReleaseItems: [
            {
              id: invoicedItem.id,
              quantity: quantity ? quantity.toString() : undefined,
              quantitySoFar: invoicedItem.quantitySoFar || "0",
              releaseItemId: item.id,
            },
          ],
          releaseId: invoice?.release?.id || "",
        },
        { bulkUpdate: true },
      );
    },
    [invoicedItem, updateInvoice, invoice?.id, invoice?.release?.id, item.id],
  );

  const invoiceIsProcessed = useMemo(
    () =>
      invoice?.status === InvoiceStatus.Approved ||
      invoice?.status === InvoiceStatus.Paid,
    [invoice?.status],
  );

  const invoicedSoFar = useMemo(() => {
    return new Decimal(invoicedItem?.quantitySoFar || "0").plus(
      invoiceIsProcessed ? invoicedItem?.quantity || "0" : 0,
    );
  }, [invoiceIsProcessed, invoicedItem?.quantity, invoicedItem?.quantitySoFar]);

  const inputClassName = useMemo(() => {
    const quantity = new Decimal(item.quantityDecimal);
    if (quantity.lessThan(invoicedSoFar)) {
      return "text-red-500 bg-white relative";
    }
    return "text-blue-800 bg-white relative";
  }, [invoicedSoFar, item.quantityDecimal]);

  const isFullyInvoiced = useMemo(() => {
    return (
      Number(invoiced) > 0 &&
      new Decimal(item.quantityDecimal)
        .sub(invoicedItem?.quantitySoFar || 0)
        .equals(Number(invoiced))
    );
  }, [invoiced, invoicedItem?.quantitySoFar, item.quantityDecimal]);

  return (
    <Container>
      <QuantityPicker
        key={item.id}
        id="invoice-item-quantity-picker"
        quantity={invoicedItem?.quantity || "0"}
        helperText={
          <FormattedMessage
            id="QUANTITY_SO_FAR"
            values={{ value: invoicedItem?.quantitySoFar }}
          />
        }
        staticText={readonly}
        saveQuantity={onSave}
        className={inputClassName}
        hideErrorIcon
      />
      {isFullyInvoiced && <CheckIcon />}
    </Container>
  );
};
