import { forwardRef, useEffect, useState } from "react";
import { Switch } from "./Switch";

export type InnerLabeledSwitchProps = {
  toggle: (checked: boolean) => void;
  initialValue: boolean;
  width?: number;
  onLabel: string;
  offLabel: string;
  className?: string;
  neutral?: boolean;
  value?: boolean;
  testId?: string;
  disabled?: boolean;
};

export const InnerLabeledSwitch = forwardRef<
  HTMLInputElement,
  InnerLabeledSwitchProps
>(
  (
    {
      initialValue,
      toggle,
      onLabel,
      offLabel,
      width,
      className,
      neutral,
      value,
      testId,
      disabled,
    },
    ref,
  ) => {
    const [checked, setChecked] = useState<boolean>(initialValue);

    useEffect(() => setChecked(initialValue), [initialValue]);

    const toggleValue = (value: boolean) => {
      setChecked(value);
      toggle(value);
    };

    return (
      <Switch
        onChange={toggleValue}
        value={value !== undefined ? value : checked}
        onLabel={onLabel}
        offLabel={offLabel}
        width={width}
        className={className}
        neutral={neutral}
        testId={testId}
        disabled={disabled}
        ref={ref}
      />
    );
  },
);
