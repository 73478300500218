import { useDialog } from "@/common/components/dialog/DialogProvider";
import {
  ExportCompleted,
  ExportType,
} from "@/common/components/export-completed/ExportCompleted";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { DIALOG_AUTO_CLOSE_TIMER } from "@/common/const";
import { useTabActive } from "@/common/hooks/useTabActive";

import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { InvoicesExport } from "@/contractor/pages/home/invoices/pages/scanned-invoices/components/export/InvoicesExport";
import { IntegrationType, SourceSystem } from "@/generated/graphql";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useIsValidInvoiceFoundationExport } from "../../hooks/useIsValidInvoiceFoundationExport";
import {
  ExportInvoiceProvider,
  useExportInvoice,
} from "../../providers/ExportInvoiceProvider";
import {
  InvoicesByIdsProvider,
  useInvoicesByIds,
} from "../../providers/InvoicesByIdsProvider";

const CardList = tw.div`flex flex-col gap-3`;

type Props = {
  onClose: () => void;
  invoiceIds: string[];
};

const ExportInvoicesPanelWithProvider: FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const {
    invoiceIds: invoicesByIds,
    loading,
    refetchInvoicesByIds,
  } = useInvoicesByIds();
  const { exportInvoices, updatedInvoices } = useExportInvoice();
  const { openDialog } = useDialog();
  const {
    connectedIntegrationType,
    settings,
    refetchSettings,
    connectedSourceSystem,
  } = useOrgSettings();
  const { isValidInvoiceFoundationExport } =
    useIsValidInvoiceFoundationExport();
  const { isTabActive } = useTabActive();
  useEffect(() => {
    if (isTabActive && !loading) {
      refetchInvoicesByIds();

      refetchSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabActive]);

  const invoicesToExportCount = useMemo(
    () =>
      invoicesByIds.filter((invoice) => {
        if (
          connectedIntegrationType === IntegrationType.Foundation ||
          connectedSourceSystem === SourceSystem.Foundation
        ) {
          return isValidInvoiceFoundationExport({
            invoice,
            updatedInvoices,
          });
        } else {
          return true;
        }
      }).length,
    [
      connectedIntegrationType,
      connectedSourceSystem,
      invoicesByIds,
      isValidInvoiceFoundationExport,
      updatedInvoices,
    ],
  );

  const onSave = useCallback(async () => {
    if (
      await exportInvoices({
        integration:
          !connectedIntegrationType ||
          connectedSourceSystem === SourceSystem.Foundation
            ? IntegrationType.Foundation
            : connectedIntegrationType,
        invoices: invoicesByIds
          .filter((invoice) =>
            isValidInvoiceFoundationExport({
              invoice,
              updatedInvoices,
            }),
          )
          .map((invoice) => ({
            invoiceId: invoice.id,
            includeCostCodes: false,
            GLExpense:
              connectedIntegrationType === IntegrationType.Foundation
                ? settings?.integrations.accounting.find((a) => a.enabled)
                    ?.toJobLedgerAccount
                : undefined,
          })),
      })
    ) {
      openDialog({
        content: (
          <ExportCompleted
            count={invoicesToExportCount}
            type={ExportType.INVOICE}
          />
        ),
        closingTimer: DIALOG_AUTO_CLOSE_TIMER,
        hideTitle: true,
      });
      onClose();
    }
  }, [
    exportInvoices,
    connectedIntegrationType,
    connectedSourceSystem,
    invoicesByIds,
    isValidInvoiceFoundationExport,
    updatedInvoices,
    settings?.integrations.accounting,
    openDialog,
    invoicesToExportCount,
    onClose,
  ]);

  return (
    <OverlayPanel
      title={intl.$t({ id: "EXPORT_INVOICES" })}
      isLoading={loading && invoicesByIds.length === 0}
      onCancel={onClose}
      onSave={onSave}
      saveLabel={intl.$t(
        {
          id:
            !invoicesToExportCount || invoicesByIds.length > 1
              ? "EXPORT_WITH_NUMBER"
              : "EXPORT",
        },
        { number: invoicesToExportCount },
      )}
      disableSave={invoicesToExportCount === 0}
    >
      <CardList>
        <InvoicesExport invoices={invoicesByIds} />
      </CardList>
    </OverlayPanel>
  );
};

export const ExportInvoicesPanel: FC<Props> = (props) => (
  <InvoicesByIdsProvider invoiceIds={props.invoiceIds}>
    <ExportInvoiceProvider>
      <ExportInvoicesPanelWithProvider {...props} />
    </ExportInvoiceProvider>
  </InvoicesByIdsProvider>
);
