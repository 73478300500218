import { isMasterSku, isProductSku } from "@/common/components/material/utils";
import { FormatCurrencyType } from "@/common/components/value-currency/ValueCurrency";
import { MAX_QUANTITY_DECIMALS, PDF_FONT } from "@/common/const";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { extPriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import {
  BuyoutFieldsFragment,
  BuyoutItemFieldsFragment,
  BuyoutStatus,
  ProjectMaterialSkuFieldsFragment,
} from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { IntlShape } from "react-intl";

const itemsForPdf = (
  intl: IntlShape,
  buyout: BuyoutFieldsFragment,
  costCodes: CategoryState<BuyoutItemFieldsFragment>[],
  groupedByCostCode: boolean,
  formatCurrency: FormatCurrencyType,
  canViewPrices?: boolean,
  header: string[] = [],
): RowInput[] => {
  const result: RowInput[] = [];

  costCodes.forEach((costCode) => {
    if (groupedByCostCode) {
      result.push([
        {
          content: `${costCode.name} (${intl.$t({ id: "COST_CODE" })})`,
          colSpan: header.length,
          styles: {
            fontStyle: "bold",
            cellPadding: { left: 7, top: 3, bottom: 3 },
          },
        },
      ]);
    }

    costCode.items
      .filter((item) => item.isIncluded)
      .forEach((item) => {
        const itemDescription = `${
          isMasterSku(item.projectItem.material.material) ||
          isProductSku(item.projectItem.material.material)
            ? `${
                (
                  item.projectItem.material
                    .material as ProjectMaterialSkuFieldsFragment
                ).manufacturer?.name || intl.$t({ id: "ANY_MANUFACTURER" })
              }\n`
            : ""
        }${item.description}`;
        const quantityDecimal = `${intl.formatNumber(
          Number(item.quantityDecimal),
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: MAX_QUANTITY_DECIMALS,
          },
        )}\n${item.projectItem.estimateUom.mnemonic ?? item.projectItem.estimateUom.pluralDescription}`;
        const unitPrice = item.unitPrice ? formatCurrency(item.unitPrice) : "";
        const totalPrice = extPriceCalculation(
          item.quantityDecimal || 0,
          item.unitPrice || 0,
        );
        const orderedSoFar = item.releasedAmount
          ? formatCurrency(item.releasedAmount)
          : "";
        const receivedSoFar = item.completedAmount
          ? formatCurrency(item.completedAmount)
          : "";

        const totalPriceForItem = formatCurrency(totalPrice);

        const materialColumns = [
          itemDescription,
          item.tags.map((tag) => tag.name).join(", "),
          quantityDecimal,
          ...(canViewPrices
            ? [
                unitPrice,
                { content: totalPriceForItem, styles: { font: "bold" } },
              ]
            : []),
        ];
        if (
          canViewPrices &&
          ![BuyoutStatus.Draft, BuyoutStatus.Requested].includes(buyout.status)
        ) {
          materialColumns.push(orderedSoFar, receivedSoFar);
        }
        result.push(materialColumns);
      });
  });

  return result;
};

export const materials = (
  doc: jsPDF,
  intl: IntlShape,
  buyout: BuyoutFieldsFragment,
  costCodes: CategoryState<BuyoutItemFieldsFragment>[],
  groupedByCostCode: boolean,
  formatCurrency: FormatCurrencyType,
  canViewPrices?: boolean,
) => {
  const header = [
    "ITEM_DESCRIPTION",
    "TAGS",
    "BUYOUT_QUANTITY",
    ...(canViewPrices
      ? ["PRICE", "TOTAL", "ORDERED_SO_FAR", "RECEIVED_SO_FAR"]
      : []),
  ];
  autoTable(doc, {
    theme: "grid",
    styles: {
      font: PDF_FONT,
      fontSize: 8,
    },
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: "black",
      halign: "center",
    },
    columnStyles: {
      0: { valign: "middle" },
      1: { halign: "center", valign: "middle" },
      2: { halign: "center", valign: "middle" },
      3: { halign: "center", valign: "middle" },
      4: { halign: "center", valign: "middle" },
      5: { halign: "center", valign: "middle" },
      6: { halign: "center", valign: "middle" },
      7: { halign: "center", valign: "middle" },
      8: { halign: "right", valign: "middle" },
    },
    head: [
      header
        .filter((h) =>
          [BuyoutStatus.Draft, BuyoutStatus.Requested].includes(buyout.status)
            ? h !== "ORDERED_SO_FAR" && h !== "RECEIVED_SO_FAR"
            : true,
        )
        .map((key) => intl.$t({ id: key })),
    ],
    body: [
      ...itemsForPdf(
        intl,
        buyout,
        costCodes,
        groupedByCostCode,
        formatCurrency,
        canViewPrices,
        header,
      ),
    ],
  });
};
