import { ReleaseIssueType } from "@/generated/graphql";
import { useIntl } from "react-intl";

export const useIssueTypes = () => {
  const intl = useIntl();
  return [
    {
      value: ReleaseIssueType.Damaged,
      label: intl.$t({ id: "RELEASE_ISSUE_" + ReleaseIssueType.Damaged }),
    },
    {
      value: ReleaseIssueType.MoreThanOrdered,
      label: intl.$t({
        id: "RELEASE_ISSUE_" + ReleaseIssueType.MoreThanOrdered,
      }),
    },
    {
      value: ReleaseIssueType.Other,
      label: intl.$t({ id: "RELEASE_ISSUE_" + ReleaseIssueType.Other }),
    },
    {
      value: ReleaseIssueType.WrongItem,
      label: intl.$t({ id: "RELEASE_ISSUE_" + ReleaseIssueType.WrongItem }),
    },
  ];
};
