import { ReleaseAdditionalChargesAndTaxes } from "@/common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { useColumnMapper } from "@/common/providers/ColumnMapperProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { UpdateVendorReleaseInput } from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import tw from "tailwind-styled-components";
import { useReceiptCreateRelease } from "../../providers/ReceiptCreateReleaseProvider";
import { useReceipt } from "../../providers/ReceiptProvider";
import { ReceiptSpreadSheetItem } from "../../types/ReceiptSpreadSheetItem";
import { ReceiptCreateReleaseFormValues } from "../ReceiptVerificationForm";
import { DashedDivider } from "./itemized/DashedDivider";
import { useReceiptCreateReleaseSpreadsheetConfig } from "./itemized/create/ReceiptCreateRelease.config";

const Container = tw.div`w-full flex flex-col flex-1 items-start px-2.5`;
const SpreadSheetView = tw.div`w-full mt-5 drop-shadow-md rounded-2xl h-full`;
const Footer = tw.div`mr-10 mt-10 w-full pb-5 flex justify-end`;

type Props = {
  itemized: boolean;
};

export const ReceiptCreateRelease: FC<Props> = ({ itemized }) => {
  const { setValue, watch } = useFormContext<ReceiptCreateReleaseFormValues>();
  const { release } = useRelease();
  const { receipt, hasReleaseRecorded, isItemized } = useReceipt();
  const { loading } = useReceiptCreateRelease();
  const { calcTableTotal, findMaterialByName } = useTableHelpers();
  const { spreadsheetData } = useColumnMapper();
  const customTaxAmount = watch("customTaxAmount");
  const subtotal = watch("subtotal");
  const spreadsheetViewColumns = useReceiptCreateReleaseSpreadsheetConfig();
  const { calcExtPrice } = usePriceCalculation();

  const receiptItems: ReceiptSpreadSheetItem[] = useMemo(() => {
    const shouldRenderReceiptItems = hasReleaseRecorded && !isItemized(receipt);

    if (release && !shouldRenderReceiptItems) {
      return release.items.map((item) => {
        const matchingOrgMaterial = findMaterialByName(
          item.projectItem?.material.material.name || "",
        );

        const extPrice = new Decimal(item?.unitPrice || 0)
          .mul(item?.quantityDecimal)
          .toNumber();

        return {
          id: item.id,
          description: item.name,
          material: matchingOrgMaterial,
          costCode: matchingOrgMaterial?.costCode?.description ?? undefined,
          manufacturer: item.manufacturer?.name,
          UOM: item.uom?.pluralDescription ?? item.uom?.mnemonic ?? undefined,
          quantityDecimal: item.quantityDecimal,
          unitPrice: Number(item.unitPrice),
          extPrice,
        };
      });
    }
    if (!receipt?.items || (receipt.release?.id && !shouldRenderReceiptItems)) {
      return [];
    }

    return receipt.items.map((item) => {
      const matchingOrgMaterial = findMaterialByName(item.description);
      const extPrice = calcExtPrice(item?.quantityDecimal, item?.unitPrice);
      const isLumpSum = isLumpSumUomText(item.UOM);

      return {
        ...item,
        material: matchingOrgMaterial,
        costCode: matchingOrgMaterial?.costCode?.description ?? undefined,
        manufacturer:
          item.manufacturer ?? matchingOrgMaterial?.manufacturer?.name,
        UOM:
          item.UOM ??
          matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
          matchingOrgMaterial?.defaultEstimateUom?.mnemonic,
        quantityDecimal:
          (isLumpSum ? item.unitPrice : item.quantityDecimal) ?? "",
        unitPrice: isLumpSum ? 1 : (Number(item.unitPrice) ?? undefined),
        extPrice,
      };
    });
  }, [
    release,
    receipt,
    findMaterialByName,
    isItemized,
    hasReleaseRecorded,
    calcExtPrice,
  ]);

  const updateSubtotal = useCallback(
    (data: Record<string, string>[]) => {
      const newTotal = calcTableTotal(data);
      if (newTotal !== watch("subtotal")) {
        setValue("subtotal", newTotal);
      }
    },
    [calcTableTotal, setValue, watch],
  );

  useEffect(() => {
    updateSubtotal(spreadsheetData);
  }, [spreadsheetData, updateSubtotal, itemized]);

  const handleUpdateRelease = (values: UpdateVendorReleaseInput) => {
    if (
      values.customTaxAmount ||
      (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
    ) {
      setValue(
        "customTaxAmount",
        values.customTaxAmount || receipt?.taxAmount || "0",
      );
      setValue("taxRate", undefined);
    }
    if (values.taxRate || values.clearCustomTaxAmount) {
      setValue("customTaxAmount", undefined);
      setValue("taxRate", values.taxRate || "0");
    }

    return true;
  };

  const taxRate = watch("taxRate");

  const total = useMemo(() => {
    const subTotal = new Decimal(subtotal || 0);
    const taxAmount = taxRate
      ? subTotal.mul(taxRate)
      : new Decimal(customTaxAmount || receipt?.taxAmount || 0);
    return subTotal.plus(taxAmount).toNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customTaxAmount, receipt?.taxAmount, subtotal, taxRate, itemized]);

  return (
    <Container>
      <SpreadSheetView>
        <SpreadSheetTable
          items={receiptItems}
          columns={spreadsheetViewColumns}
          saving={loading}
          height="380px"
          rowNumber={10}
          onChanges={updateSubtotal}
        />
      </SpreadSheetView>
      <Footer>
        <ReleaseAdditionalChargesAndTaxes
          total={`${total}`}
          subtotal={`${subtotal}`}
          customTaxAmount={watch("customTaxAmount")}
          customTaxRate={watch("taxRate")}
          editableByContractor
          editablePaymentTerms
          updateRelease={async (values) =>
            await handleUpdateRelease({ ...values, version: -1 })
          }
          includeAdditionalCharges={hasReleaseRecorded}
          includeNotesPanel={false}
          editableAdditionalCharges={false}
          hideTotalBorder
          totalDivider={<DashedDivider className="mr-10" />}
          release={release}
        />
      </Footer>
    </Container>
  );
};
