import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useInvoicePermissionsLazyQuery } from "@/generated/graphql";
import { useCallback } from "react";

export const useInvoicePermissions = () => {
  const [loadInvoicePermissions, { loading, error, data }] =
    useInvoicePermissionsLazyQuery();

  const fetchInvoicePermissions = useCallback(
    (invoiceId: string, projectId: string) =>
      loadInvoicePermissions({
        variables: {
          id: invoiceId,
          projectId,
        },
      }),
    [loadInvoicePermissions],
  );

  useErrorEffect(error);

  return {
    permissions: data?.invoice.permissions,
    loading,
    fetchInvoicePermissions,
    error,
  };
};
