type NodeWithTypename = {
  id: string;
  name: string;
  __typename?: string;
};

export const isOrgCatalogSku = (obj: NodeWithTypename | undefined) => {
  return obj?.__typename === "OrgCatalogSku";
};

export const isProductSku = (obj: NodeWithTypename | undefined) => {
  return obj?.__typename === "Product";
};

export const isMasterSku = (obj: NodeWithTypename | undefined) => {
  return obj?.__typename === "Sku";
};
