import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  CorrectInvoiceDocumentInput,
  DistributorInvoiceDocument,
  DistributorInvoiceFieldsFragment,
  InvoiceStatus,
  ResolveInvoiceIssueInput,
  useCorrectInvoiceDocumentMutation,
  useDistributorInvoiceQuery,
  useResolveInvoiceIssueMutation,
} from "@/generated/graphql";
import { NoFunctionPromise } from "@/types/NoFunction";
import { FC, createContext, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";

export const INVOICE_READONLY_STATUSES = [
  InvoiceStatus.Approved,
  InvoiceStatus.Paid,
];

type ProviderContextType = {
  invoice: DistributorInvoiceFieldsFragment | null;
  resolveInvoiceIssue: (input: ResolveInvoiceIssueInput) => Promise<void>;
  resolvingInvoiceIssue: boolean;
  correctInvoiceDocument: (input: CorrectInvoiceDocumentInput) => Promise<void>;
  correctingInvoice: boolean;
  loading: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  invoice: null,
  resolveInvoiceIssue: NoFunctionPromise,
  resolvingInvoiceIssue: false,
  correctInvoiceDocument: NoFunctionPromise,
  correctingInvoice: false,
  loading: false,
});

export const DistributorInvoiceProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { invoiceId } = useParams();
  const { setError } = useGlobalError();

  const { data, error, loading } = useDistributorInvoiceQuery({
    variables: { id: invoiceId || "" },
    skip: !invoiceId,
  });

  const [resolveInvoiceIssueMutation, { loading: resolvingInvoiceIssue }] =
    useResolveInvoiceIssueMutation();
  const resolveInvoiceIssue = useCallback(
    async (input: ResolveInvoiceIssueInput) => {
      await resolveInvoiceIssueMutation({
        variables: { input },
        refetchQueries: [
          { query: DistributorInvoiceDocument, variables: { id: invoiceId } },
        ],
      });
    },
    [invoiceId, resolveInvoiceIssueMutation],
  );
  useErrorEffect(error);

  const [correctInvoiceDocumentMutation, { loading: correctingInvoice }] =
    useCorrectInvoiceDocumentMutation();
  const correctInvoiceDocument = useCallback(
    async (input: CorrectInvoiceDocumentInput) => {
      try {
        const { errors } = await correctInvoiceDocumentMutation({
          variables: { input },
          refetchQueries: [
            { query: DistributorInvoiceDocument, variables: { id: invoiceId } },
          ],
        });
        setError(errors);
      } catch (errors) {
        setError(errors);
      }
    },
    [invoiceId, correctInvoiceDocumentMutation, setError],
  );
  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        invoice: data?.invoice || null,
        loading,
        resolveInvoiceIssue,
        resolvingInvoiceIssue,
        correctInvoiceDocument,
        correctingInvoice,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useDistributorInvoice = (): ProviderContextType =>
  useContext(ProviderContext);
