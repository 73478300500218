import { CalendarToday } from "@mui/icons-material";
import { DatePicker as DatePickerMaterial } from "@mui/x-date-pickers";
import { FC, Ref, forwardRef, useState } from "react";
import { DatePickerProps } from "./DatePicker";
import { DateButtonField } from "./common/DateButtonField";

export const ButtonDatePicker: FC<DatePickerProps> = forwardRef(
  (
    {
      date,
      onChange,
      label = <CalendarToday />,
      minDate,
      className,
      staticText,
      defaultValue,
    },
    ref: Ref<HTMLDivElement>,
  ) => {
    const [open, setOpen] = useState(false);

    return (
      <DatePickerMaterial
        ref={ref}
        className={className}
        open={open}
        onOpen={() => setOpen?.(true)}
        onClose={() => setOpen?.(false)}
        value={date}
        label={label}
        minDate={minDate}
        onChange={(newDate) => onChange?.(newDate as Date)}
        disableOpenPicker={staticText}
        defaultValue={defaultValue}
        slots={{ field: DateButtonField }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        slotProps={{ field: { setOpen } as any }}
      />
    );
  },
);
