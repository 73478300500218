import { isMasterSku, isProductSku } from "@/common/components/material/utils";
import { FormatCurrencyType } from "@/common/components/value-currency/ValueCurrency";
import { MAX_QUANTITY_DECIMALS, PDF_FONT } from "@/common/const";
import { CategoryState } from "@/common/hooks/useToggleCategory";
import { extPriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import {
  DistributorBuyoutItemFieldsFragment,
  ProjectMaterialSkuFieldsFragment,
} from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { IntlShape } from "react-intl";

const HEADER = [
  "ORDERED_ITEMS",
  "TAGS",
  "INSTRUCTIONS",
  "MANUFACTURER",
  "NOTES",
  "UNITS_IN_BUYOUT",
  "UNIT_PRICE",
  "EXT_PRICE",
];

const itemsForPdf = (
  intl: IntlShape,
  hasManufacturersSetting: boolean,
  costCodes: CategoryState<DistributorBuyoutItemFieldsFragment>[],
  groupedByCostCode: boolean,
  formatCurrency: FormatCurrencyType,
): RowInput[] => {
  const result: RowInput[] = [];
  const usePhaseCodes = costCodes.some((costCode) =>
    costCode.items.some((item) => item.tags.some((tag) => tag.hasMapping)),
  );

  costCodes.forEach((costCode) => {
    if (groupedByCostCode) {
      result.push([
        {
          content: `${costCode.name} (${intl.$t({ id: !usePhaseCodes ? "COST_CODE" : "PHASE_CODE" })})`,
          colSpan: HEADER.length,
          styles: {
            fontStyle: "bold",
            cellPadding: { left: 7, top: 3, bottom: 3 },
          },
        },
      ]);
    }

    costCode.items.forEach((item) => {
      const totalPrice = extPriceCalculation(
        item.quantityDecimal || 0,
        item.unitPrice || 0,
      );

      const requestedItemName = `${
        isMasterSku(item.projectItem.material.material) ||
        isProductSku(item.projectItem.material.material)
          ? `${
              (
                item.projectItem.material
                  .material as ProjectMaterialSkuFieldsFragment
              ).manufacturer?.name || intl.$t({ id: "ANY_MANUFACTURER" })
            }\n`
          : ""
      }${item.description}`;

      const instructions =
        item.rfqItem?.instructions?.text || intl.$t({ id: "NONE" });

      const quantityDecimal = `${intl.formatNumber(
        Number(item.quantityDecimal),
        {
          minimumFractionDigits: 0,
          maximumFractionDigits: MAX_QUANTITY_DECIMALS,
        },
      )}\n${item.projectItem.estimateUom.mnemonic}`;

      const manufacturer = item.manufacturer?.name || "--";

      const notes = item.notes || intl.$t({ id: "NONE" });

      const unitPrice = item.unitPrice ? formatCurrency(item.unitPrice) : "";

      const totalPriceForItem = formatCurrency(totalPrice);
      result.push([
        requestedItemName,
        item.tags.map((tag) => tag.name).join(", "),
        instructions,
        ...(hasManufacturersSetting ? [manufacturer] : []),
        notes,
        quantityDecimal,
        unitPrice,
        totalPriceForItem,
      ]);
    });
  });

  return result;
};

export const materials = (
  doc: jsPDF,
  intl: IntlShape,
  hasManufacturersSetting: boolean,
  costCodes: CategoryState<DistributorBuyoutItemFieldsFragment>[],
  groupedByCostCode: boolean,
  formatCurrency: FormatCurrencyType,
) => {
  autoTable(doc, {
    theme: "grid",
    styles: {
      font: PDF_FONT,
      fontSize: 8,
    },
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: "black",
      halign: "center",
    },
    columnStyles: {
      0: { valign: "middle" },
      1: { halign: "center", valign: "middle" },
      2: { halign: "center", valign: "middle" },
      3: { halign: "center", valign: "middle" },
      4: { halign: "center", valign: "middle" },
      5: { halign: "center", valign: "middle" },
      6: { halign: "center", valign: "middle" },
      7: { halign: "center", valign: "middle" },
      8: { halign: "right", valign: "middle" },
    },
    head: [
      HEADER.filter(
        (h) => hasManufacturersSetting || h !== "PROVIDED_MANUFACTURER",
      ).map((key) => intl.$t({ id: key })),
    ],
    body: [
      ...itemsForPdf(
        intl,
        hasManufacturersSetting,
        costCodes,
        groupedByCostCode,
        formatCurrency,
      ),
    ],
  });
};
