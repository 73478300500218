import { LoadingButton } from "@/common/components/button/LoadingButton";
import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { If } from "@/common/components/if/If";
import { SMSVerificationResult } from "@/common/providers/SMSValidationProvider";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useUser } from "@/common/providers/UserProvider";
import { SmsVerificationFieldsFragment } from "@/generated/graphql";
import { TextField } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  cancel: () => void;
  save: () => Promise<void>;
  resend: (
    verificationId: string,
  ) => Promise<SmsVerificationFieldsFragment | null | undefined>;
  submit: (
    verificationId: string,
    code: string,
  ) => Promise<SMSVerificationResult | null | undefined>;
  verification: SmsVerificationFieldsFragment;
};

const Container = tw.div``;
const Headline = tw.div`text-xl font-bold pb-4`;
const Text = tw.div`text-sm pb-3 w-96`;
const InputContainer = tw.div`mt-2`;
const Buttons = tw.div`flex justify-center gap-2 pt-10 text-base`;
const SubText = tw.div`text-xs pt-2 pb-2`;

export const SMSValidation: FC<Props> = ({
  cancel,
  save,
  resend,
  submit,
  verification,
}) => {
  const [code, setCode] = useState("");
  const { viewer } = useUser();
  const intl = useIntl();
  const { setSuccessAlert } = useSnackbar();
  const [currentVerification, setCurrentVerification] = useState<
    SmsVerificationFieldsFragment | null | undefined
  >(verification);
  const [warning, setWarning] = useState("");

  const submitOrResendCode = useCallback(async () => {
    if (currentVerification) {
      if (code) {
        const result = await submit(currentVerification.id, code);
        if (result && result.verification?.completedAt) {
          await save();
          setSuccessAlert(
            intl.$t({
              id: "PHONE_VALIDATED_SUCCESSFULLY",
            }),
          );

          cancel();
        }
        if (result) {
          setCurrentVerification(result.verification);
          setWarning(result.warnings ? result.warnings[0] : "");
        }
      } else {
        const result = await resend(currentVerification.id);
        setCurrentVerification(result);
      }
    }
  }, [
    currentVerification,
    code,
    submit,
    save,
    setSuccessAlert,
    intl,
    cancel,
    resend,
  ]);

  return (
    <Container>
      <Headline>
        <FormattedMessage id="ENTER_YOUR_CODE" />
      </Headline>
      <Text>
        <FormattedMessage
          id="SENT_SMS_MESSAGE"
          values={{
            phoneNumberMask: `xxxxxxxx${viewer?.cellPhone?.substring(
              viewer.cellPhone.length - 3,
            )}`,
          }}
        />
      </Text>
      <If isTrue={currentVerification}>
        <SubText>
          <FormattedMessage
            id="ATTEMPTS_LEFT"
            values={{ attemptsLeft: currentVerification?.remainingAttempts }}
          />
        </SubText>
      </If>
      <InputContainer>
        <TextField
          size="small"
          label={intl.$t({ id: "CODE_FROM_SMS" })}
          value={code}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setCode(event.target.value)
          }
          inputProps={{ maxLength: 6 }}
          error={!!warning}
        />
      </InputContainer>
      <Buttons>
        <OutlinedButton wide onClick={cancel} fullWidth>
          <FormattedMessage id="CANCEL" />
        </OutlinedButton>
        <LoadingButton
          button={PrimaryButton}
          onClick={submitOrResendCode}
          disabled={
            !code ||
            code.length !== 6 ||
            currentVerification?.remainingAttempts === 0
          }
          fullWidth
        >
          <FormattedMessage id={code ? "SUBMIT_CODE" : "RESEND_CODE"} />
        </LoadingButton>
      </Buttons>
    </Container>
  );
};
