import FileAdd from "@/assets/icons/file-add.svg?react";
import File from "@/assets/icons/file.svg?react";
import { AddItemsButton } from "@/common/components/add-items-button/AddItemsButton";
import {
  AddItemsFromDialog,
  AddItemsOptionType,
} from "@/common/components/add-items-from-dialog/AddItemsFromDialog";
import { If } from "@/common/components/if/If";
import { VIEW_STATE, useTableView } from "@/common/providers/TableViewProvider";
import { FC, useMemo, useState } from "react";
import { useRfqGroupedItems } from "../../hooks/useRfqGroupedItems";
import { AddEstimatedItemsDialog } from "../../pages/add-item-to-rfq/AddEstimatedItemsDialog";
import { useSyncRfqItems } from "../../providers/SyncRfqItemsProvider";
import { RfqProjectItemsProvider } from "../import-project-items/RfqProjectItemsProvider";

type RfqProductMenuProps = {
  isOpen?: boolean;
};

export const RfqProductMenu: FC<RfqProductMenuProps> = ({ isOpen = false }) => {
  const [showAddItemsDialog, setShowAddItemsDialog] = useState(isOpen);
  const { setNewRfqItem } = useRfqGroupedItems();
  const { tableView } = useTableView();
  const { syncRfqItems } = useSyncRfqItems();
  const [showEstimatedItemsDialog, setShowEstimatedItemsDialog] =
    useState(false);

  const dialogOptions = useMemo<AddItemsOptionType[]>(
    () => [
      {
        label: "ADD_FROM_PROJECT_ESTIMATED_ITEMS",
        details: "ADD_FROM_PROJECT_ESTIMATED_ITEMS_INFO",
        callback: () => {
          setShowAddItemsDialog(false);
          setShowEstimatedItemsDialog(true);
        },
        icon: <File className="h-28 w-28" />,
        preferred: true,
      },
      ...(tableView === VIEW_STATE.normal
        ? [
            {
              label: "ADD_CUSTOM_MATERIAL",
              details: "ADD_CUSTOM_MATERIAL_INFO",
              callback: () => {
                setNewRfqItem({
                  description: "",
                  projectItem: {
                    estimateUom: "",
                  },
                  quantity: 0,
                  isAddMode: true,
                });
                setShowAddItemsDialog(false);
              },
              icon: <FileAdd className="h-28 w-28" />,
            },
          ]
        : []),
    ],
    [setNewRfqItem, tableView],
  );

  const gotoItemSelection = async () => {
    const result = await syncRfqItems();
    if (result) {
      setShowAddItemsDialog(true);
    }
  };

  return (
    <>
      <AddItemsButton onClick={gotoItemSelection} />
      <AddItemsFromDialog
        title="ADD_ITEMS_TO_RFQ"
        options={dialogOptions}
        visible={showAddItemsDialog}
        setVisible={setShowAddItemsDialog}
      />
      <If isTrue={showEstimatedItemsDialog}>
        <RfqProjectItemsProvider>
          <AddEstimatedItemsDialog
            setShowEstimatedItemsDialog={setShowEstimatedItemsDialog}
          />
        </RfqProjectItemsProvider>
      </If>
    </>
  );
};
