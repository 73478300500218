import { BuyoutStatus } from "@/generated/graphql";

export const checkBuyoutStatus = (
  buyout: { status: BuyoutStatus } | null | undefined,
  statuses: BuyoutStatus[],
) => {
  if (!buyout) {
    return false;
  }

  return statuses.includes(buyout.status);
};
