import { ManufacturerPicker } from "@/common/components/manufacturer-picker/ManufacturerPicker";
import { getManufacturerIdFromPicker } from "@/common/components/manufacturer-picker/getManufacturerIdFromPicker";
import { isMasterSku } from "@/common/components/material/utils";
import { checkBuyoutStatus } from "@/common/utils/status-checks/checkBuyoutStatus";
import { BuyoutItemFieldsFragment, BuyoutStatus } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutGrouped } from "../providers/BuyoutGroupedProvider";

type Props = {
  item: BuyoutItemFieldsFragment;
  className?: string;
  includeInfo?: boolean;
  readonly: boolean;
  index?: number;
};

export const BuyoutManufacturerSelector: FC<Props> = ({
  item,
  readonly,
  index,
}) => {
  const { newBuyoutItem, setNewBuyoutItem } = useBuyoutGrouped();
  const { updateBuyout, buyout } = useContractorBuyout();

  const saveManufacturer = useCallback(
    async (value: string | null) => {
      const manufacturerId = getManufacturerIdFromPicker(value);
      const clearManufacturer = !manufacturerId;
      if (newBuyoutItem.isAddMode) {
        setNewBuyoutItem({
          ...newBuyoutItem,
          manufacturerId,
        });
      } else {
        await updateBuyout({
          updates: [
            {
              buyoutItemId: item.id,
              manufacturerId,
              clearManufacturer,
            },
          ],
        });
      }
    },
    [item.id, newBuyoutItem, setNewBuyoutItem, updateBuyout],
  );

  const canClearManufacturer = useMemo(
    () =>
      checkBuyoutStatus(buyout, [
        BuyoutStatus.Declined,
        BuyoutStatus.Draft,
        BuyoutStatus.Requested,
      ]),
    [buyout],
  );

  if (!item) {
    return null;
  }

  return (
    <ManufacturerPicker
      item={item.manufacturer}
      saveManufacturer={saveManufacturer}
      readonly={readonly || isMasterSku(item.projectItem.material.material)}
      canClearManufacturer={canClearManufacturer}
      index={index}
    />
  );
};
