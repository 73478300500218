import { useGlobalDrawer } from "@/common/components/panel/DrawerGlobalProvider";
import { DrawerView } from "@/common/components/panel/DrawerView";
import { DistributorQuoteOrderRequirementsPanel } from "./DistributorQuoteOrderRequirementsPanel";

export const DISTRIBUTOR_QUOTE_ORDER_REQUIREMENTS_PANEL =
  "distributor-quote-order-requirements";

export const DistributorQuoteOrderRequirements = () => {
  const { isOpened, toggle } = useGlobalDrawer();

  return (
    <DrawerView
      isOpened={isOpened(DISTRIBUTOR_QUOTE_ORDER_REQUIREMENTS_PANEL)}
      setIsOpened={(value) =>
        toggle(DISTRIBUTOR_QUOTE_ORDER_REQUIREMENTS_PANEL, value)
      }
      content={(togglePanel) => (
        <DistributorQuoteOrderRequirementsPanel
          onClose={() => togglePanel(false)}
        />
      )}
    />
  );
};
