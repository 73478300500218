import { Switch } from "@/common/components/switch/Switch";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useBuyoutReleases } from "../../providers/BuyoutReleasesProvider";

const Container = tw.div`flex items-center gap-2 justify-end pt-6`;

export const ReleasesListFooter = () => {
  const intl = useIntl();
  const { toggleArchived, setToggleArchived } = useBuyoutReleases();
  return (
    <Container>
      <FormattedMessage id="RELEASE_SHOW_ARCHIVED" />
      <Switch
        onLabel={intl.$t({ id: "YES" })}
        offLabel={intl.$t({ id: "NO" })}
        value={toggleArchived}
        onChange={setToggleArchived}
      />
    </Container>
  );
};
