import { CategoryState } from "@/common/hooks/useToggleCategory";
import { UNSPECIFIED_COST_CODE_ID } from "@/common/hooks/useUnspecifiedCostCode";
import { AssignCostCodesButton } from "@/contractor/pages/home/buyout-releases/components/releases-list/release-view/AssignCostCodesButton";
import { useReleaseActions } from "@/contractor/pages/home/release/providers/ReleaseActionsProvider";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseItemFieldsFragment, ReleaseStatus } from "@/generated/graphql";
import { FC, useCallback } from "react";

export const ReleaseAssignCostCodes: FC<{
  category: CategoryState<ReleaseItemFieldsFragment>;
  status?: ReleaseStatus;
}> = ({ category, status }) => {
  const { updateCostCode, setNewCostCodes, setAssignCostCodeOptions } =
    useReleaseActions();

  const handleOnSave = useCallback(async () => {
    await updateCostCode();
  }, [updateCostCode]);

  const handleOnCancel = useCallback(() => {
    setNewCostCodes([]);
  }, [setNewCostCodes]);

  const handleOnEdit = useCallback(
    (isEditable: boolean) => {
      setAssignCostCodeOptions({
        isEditable,
        categoryItems: category.items as ExpandedReleaseItem[],
      });
    },
    [setAssignCostCodeOptions, category],
  );

  return (
    <AssignCostCodesButton
      onSave={handleOnSave}
      onCancel={handleOnCancel}
      onEdit={handleOnEdit}
      readonly={
        category.id !== UNSPECIFIED_COST_CODE_ID ||
        category.items.every((i) => !i.isIncluded) ||
        status === ReleaseStatus.Canceled
      }
    />
  );
};
