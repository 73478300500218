import { LinkLike } from "@/common/components/link-like/LinkLike";
import {
  SHORT_DATE_OPTION,
  formattedDate,
} from "@/common/utils/dates/DateView";
import { IntegrationType, SourceSystem } from "@/generated/graphql";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { ExternalPOModal } from "../../../../../common/external-po/ExternalPOModal";

type DotColor = "green" | "red" | "orange";

const COLOR_MAP = {
  green: "bg-green-800",
  red: "bg-red-500",
  orange: "bg-orange-500",
};
export enum ReleaseExportStatusEnum {
  OK,
  Outdated,
  ExportIssue,
  Syncing,
  SyncIssue,
}

const RELEASE_EXPORT_STATUSES = {
  [ReleaseExportStatusEnum.OK]: {
    color: "green",
    label: "LAST_EXPORT_WITH_DATE",
  },
  [ReleaseExportStatusEnum.Outdated]: {
    color: "orange",
    label: "MODIFIED_AFTER_SYNC_WITH_DATE",
  },
  [ReleaseExportStatusEnum.ExportIssue]: {
    color: "red",
    label: "EXPORT_ISSUE_WITH_DATE",
  },
  [ReleaseExportStatusEnum.Syncing]: {
    color: "orange",
    label: "SYNCING_WITH_DATE",
  },
  [ReleaseExportStatusEnum.SyncIssue]: {
    color: "red",
    label: "SYNC_ISSUE_WITH_DATE",
  },
};

const ExportedAt = tw.div`flex flex-row items-center text-2xs text-gray-600`;
const PoContainer = tw.div`flex flex-col`;
const Item = tw.div``;

const Status = tw.div<{
  status: ReleaseExportStatusEnum;
}>`w-2 h-2 rounded-full inline-block mr-2
${({ status }: { status: ReleaseExportStatusEnum }) =>
  COLOR_MAP[RELEASE_EXPORT_STATUSES[status].color as DotColor]}
`;

type Props = {
  status: ReleaseExportStatusEnum;
  poNumber?: string | null;
  projectId?: string | null;
  sourceSystem?: SourceSystem;
  date?: number | null;
  integrationType?: IntegrationType;
};

export const ReleaseExportStatus: FC<Props> = ({
  status,
  poNumber,
  projectId,
  sourceSystem,
  integrationType,
  date,
}: Props) => {
  const intl = useIntl();

  const [externalPOModalOpened, setExternalPOModalOpened] = useState(false);

  return (
    <>
      <ExportedAt>
        <Status status={status} />
        <PoContainer>
          <LinkLike onClick={() => setExternalPOModalOpened(!!poNumber)}>
            <FormattedMessage
              id="PO_NUMBER_INTEGRATION"
              values={{
                number: poNumber,
                integration: intl.$t({
                  id: sourceSystem
                    ? `INTEGRATION_${sourceSystem}`
                    : `INTEGRATION_TYPE_${integrationType}`,
                }),
              }}
            />
          </LinkLike>
          <FormattedMessage
            id={RELEASE_EXPORT_STATUSES[status].label}
            values={{
              date: formattedDate({
                date,
                options: SHORT_DATE_OPTION,
              }),
            }}
            tagName={Item}
          />
        </PoContainer>
      </ExportedAt>
      <ExternalPOModal
        opened={externalPOModalOpened}
        goBack={() => setExternalPOModalOpened(false)}
        poNumber={poNumber}
        projectId={projectId}
      />
    </>
  );
};
