import { useReleaseItemDeliveredItemsLazyQuery } from "@/generated/graphql";
import { useCallback } from "react";

type Props = {
  releaseId: string | undefined;
  itemId: string;
};

export const useReleaseItemDeliveredItems = ({ releaseId, itemId }: Props) => {
  const [call, { data, loading }] = useReleaseItemDeliveredItemsLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const fetchDeliveredItems = useCallback(() => {
    if (releaseId) {
      call({
        variables: {
          id: releaseId,
          itemId,
        },
      });
    }
  }, [releaseId, itemId, call]);

  return {
    fetchDeliveredItems,
    deliveredItems: data?.release?.item.deliveredItems ?? [],
    loadingDeliveredItems: loading,
  };
};
