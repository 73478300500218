import { QuantityPicker } from "@/common/components/quantity-picker/QuantityPicker";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutGrouped } from "../providers/BuyoutGroupedProvider";

type SheetsInputProps = {
  item: BuyoutItemFieldsFragment;
  readonly: boolean;
  error?: boolean;
};

export const BuyoutQuantityInput: FC<SheetsInputProps> = ({
  item,
  readonly,
  error = false,
}) => {
  const intl = useIntl();
  const { updateBuyout } = useContractorBuyout();
  const { newBuyoutItem, setNewBuyoutItem } = useBuyoutGrouped();
  const { setWarningAlert } = useSnackbar();

  const updateValue = useCallback(
    async (value: string | null) => {
      if (value === item.quantityDecimal) {
        return;
      }
      if (newBuyoutItem.isAddMode) {
        setNewBuyoutItem({
          ...newBuyoutItem,
          quantityDecimal: value,
        });
      } else if (value === "0") {
        setWarningAlert(
          intl.$t({
            id: "CONTRACTOR_BUYOUT_VALIDATION_ERROR_SHEETS_ZERO",
          }),
        );
      } else {
        await updateBuyout({
          updates: [{ buyoutItemId: item.id, quantityDecimal: value }],
        });
      }
    },
    [
      item.quantityDecimal,
      item.id,
      newBuyoutItem,
      setNewBuyoutItem,
      setWarningAlert,
      intl,
      updateBuyout,
    ],
  );

  return (
    <QuantityPicker
      quantity={item.quantityDecimal}
      id={item.id}
      saveQuantity={updateValue}
      readonly={readonly}
      resetIfZero
      error={error && item.quantityDecimal === "0"}
      testId="buyout-item-quantity-input"
    />
  );
};
