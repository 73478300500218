import tw from "tailwind-styled-components";

const ProjectItem = tw.div`
  w-full py-[6px] grid grid-flow-col gap-x-2 justify-start items-center
`;
const JobNumber = tw.span`text-sm text-gray-600`;

export const ProjectFilterCustomRender = (item: {
  id: string | undefined;
  name: string;
  jobNumber?: string | null;
}) => {
  const split = item.name.split(`(${item.jobNumber})`);

  return (
    <ProjectItem>
      {split[0]}
      {item.jobNumber ? <JobNumber>({item.jobNumber})</JobNumber> : null}
    </ProjectItem>
  );
};
