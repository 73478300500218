import { Breadcrumbs } from "@/common/components/breadcrumbs/Breadcrumbs";
import { CategoriesProvider } from "@/common/components/category-filter/CategoriesProvider";
import { CategoryListFilter } from "@/common/components/category-filter/CategoryListFilter";
import { If } from "@/common/components/if/If";
import { Loader } from "@/common/components/loader/Loader";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { useFacets } from "@/common/components/product-facets-filter/FacetsProvider";
import { ProductFacetsFilter } from "@/common/components/product-facets-filter/ProductFacetsFilter";
import {
  SkusAndProductsProvider,
  useSkusAndProducts,
} from "@/common/components/product-facets-filter/SkusAndProductsProvider";
import { ProductsList } from "@/common/components/product-list/ProductsList";
import {
  ShoppingCartIcon,
  ShoppingIconType,
} from "@/common/components/shopping-cart-icon/ShoppingCartIcon";
import { MasterSkusProductsToggleProvider } from "@/common/components/skus-products-switch/MasterSkusProductsToggleProvider";
import { routes } from "@/config/routes";
import { ProductsContainer } from "@/contractor/pages/home/rfq-master-catalog-import/Rfq.styles";
import { BuyoutStatus } from "@/generated/graphql";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { generatePath, useParams } from "react-router-dom";

import {
  BuyoutShoppingCartProvider,
  useBuyoutShoppingCart,
} from "../providers/BuyoutShoppingCartProvider";
import { useEnsureBuyoutStatus } from "../providers/useEnsureBuyoutStatus";
import { BuyoutInfo } from "./BuyoutInfo";

const SelectBuyoutProductsWithProviders = () => {
  const { id } = useParams();
  const { count, buyout, addToBuyout, findItem, removeFromBuyout } =
    useBuyoutShoppingCart();
  const {
    productFacets,
    productFacetsValues,
    selectedFacets,
    toggleFacetValue,
  } = useFacets();
  const { searchPerformed } = useSkusAndProducts();

  useEnsureBuyoutStatus(buyout, [BuyoutStatus.Draft, BuyoutStatus.Requested]);

  const intl = useIntl();

  if (!buyout) {
    return <Loader loading />;
  }

  return (
    <>
      <Helmet>
        <title>
          {intl.$t({ id: "BUYOUT" }, { number: buyout.clientIdentifier })}
        </title>
      </Helmet>
      <Breadcrumbs
        items={[
          {
            text: intl.$t({ id: "BUYOUTS" }),
            link: routes.buyouts,
            id: "buyouts",
          },
          {
            text: intl.$t(
              {
                id: "BUYOUT",
              },
              { number: buyout.clientIdentifier },
            ),
            id: "newOrder",
            link: generatePath(routes.buyout, { id: buyout.id }),
          },
          {
            text: intl.$t({ id: "QUOTE_SELECT_PRODUCTS" }),
            id: "selectProducts",
          },
        ]}
        actions={[
          <ShoppingCartIcon
            key="shopping-cart-icon"
            count={count}
            path={generatePath(routes.buyout, { id })}
            type={ShoppingIconType.Truck}
          />,
        ]}
      />
      <BuyoutInfo buyout={buyout} />
      <CategoryListFilter />
      <ProductsContainer>
        <If isTrue={searchPerformed}>
          <ProductFacetsFilter
            productFacets={productFacets}
            productFacetsValues={productFacetsValues}
            selectedFacets={selectedFacets}
            toggleFacetValue={toggleFacetValue}
          />
        </If>
        <ProductsList
          addToCart={addToBuyout}
          findItem={findItem}
          removeFromCart={removeFromBuyout}
        />
      </ProductsContainer>
    </>
  );
};

export const SelectBuyoutProducts = () => (
  <CategoriesProvider initialCategory={null}>
    <PaginationProvider>
      <MasterSkusProductsToggleProvider>
        <SkusAndProductsProvider>
          <BuyoutShoppingCartProvider>
            <SelectBuyoutProductsWithProviders />
          </BuyoutShoppingCartProvider>
        </SkusAndProductsProvider>
      </MasterSkusProductsToggleProvider>
    </PaginationProvider>
  </CategoriesProvider>
);
