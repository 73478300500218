import {
  WizardModal,
  WizardModalMode,
} from "@/common/components/wizard-modal/WizardModal";
import { useMissingMaterialConversions } from "@/contractor/pages/home/release/components/connections/providers/MissingMaterialConversionsProvider";

export const MissingMaterialConversionsModal = () => {
  const { modalOpened, pages, closeModal } = useMissingMaterialConversions();

  return (
    <WizardModal
      mode={WizardModalMode.SINGLE_PAGE}
      opened={modalOpened}
      pages={pages}
      onClose={closeModal}
    />
  );
};
