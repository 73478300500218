import { AdminHeaderContainer } from "@/common/components/header-container/HeaderContainer";
import { If } from "@/common/components/if/If";
import { IntegrationType, SourceSystem } from "@/generated/graphql";
import { useMemo } from "react";
import { useOrgSettingsExtended } from "../../org-settings/hooks/useOrgSettingsExtended";
import { ToggleIntegrationFormButton } from "./form/components/ToggleIntegrationFormButton";

export const IntegrationsHeader = () => {
  const { integrations, connectedAccountingSystem } = useOrgSettingsExtended();

  const hasFoundationHosted = useMemo(
    () =>
      integrations?.sourceSystems.some(
        (s) => s.system === SourceSystem.Foundation && s.connected,
      ),
    [integrations?.sourceSystems],
  );

  const hasFoundationOnPremise = useMemo(
    () => connectedAccountingSystem?.integration === IntegrationType.Foundation,
    [connectedAccountingSystem?.integration],
  );

  return (
    <AdminHeaderContainer>
      <If
        isTrue={
          (!integrations?.accounting.some((a) => a.enabled) &&
            !integrations?.sourceSystems.some((s) => s.connected)) ||
          (hasFoundationHosted && !hasFoundationOnPremise) ||
          (!hasFoundationHosted && hasFoundationOnPremise)
        }
      >
        <ToggleIntegrationFormButton />
      </If>
    </AdminHeaderContainer>
  );
};
