import { If } from "@/common/components/if/If";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  options: string[];
  includeHeader?: boolean;
  className?: string;
};

const NoResultsContainer = tw.div`
  grid inset-0 items-center justify-center border border-gray-200
  bg-yellow-200 m-5 mx-auto p-5 col-span-full rounded-sm drop-shadow-sm
`;

const NoResultHeader = tw.div`
  grid  text-xl justify-items-center pb-5 px-0
`;

const NoResultOption = tw.div`
  font-medium mb-1
`;

const OptionText = tw.span`
  font-light pl-1
`;

const SentimentVeryDissatisfiedIconStyled = tw(SentimentVeryDissatisfiedIcon)`
   text-7xl m-2
`;

export const NoResultsWithOptions: FC<Props> = ({
  options,
  includeHeader,
  className,
}) => {
  return (
    <NoResultsContainer className={className}>
      <If isTrue={includeHeader}>
        <NoResultHeader>
          <SentimentVeryDissatisfiedIconStyled />
          <FormattedMessage id="NO_MASTER_SKU_RESULTS" />
        </NoResultHeader>
      </If>
      {options.map((option, index) => (
        <NoResultOption key={index}>
          <FormattedMessage id="OPTION" /> {index + 1}:
          <OptionText>
            <FormattedMessage
              id={option}
              values={{
                sub: (...chunks) => {
                  return <strong>{chunks}</strong>;
                },
              }}
            />
          </OptionText>
        </NoResultOption>
      ))}
    </NoResultsContainer>
  );
};
