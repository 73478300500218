import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useCostTypesQuery } from "@/generated/graphql";

export const useCostTypes = () => {
  const { data, error, loading } = useCostTypesQuery();
  useErrorEffect(error);

  return {
    costTypes: data?.viewer?.org.costTypes || [],
    loading,
  };
};
