import { useEffect, useState } from "react";

type Args = {
  pollInterval: number;
  refetch: () => void;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
  skip: boolean;
};

const hasFocus = () => {
  return document.hasFocus();
};

const useWindowFocus = () => {
  const [isWindowFocused, setIsWindowFocused] = useState(hasFocus());

  useEffect(() => {
    setIsWindowFocused(hasFocus());

    const onFocus = () => setIsWindowFocused(true);
    const onBlur = () => setIsWindowFocused(false);

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  return { isWindowFocused };
};

export const useQueryPollingWhileWindowFocused = ({
  pollInterval,
  refetch,
  startPolling,
  stopPolling,
  skip,
}: Args) => {
  const { isWindowFocused } = useWindowFocus();

  useEffect(() => {
    if (!isWindowFocused) {
      stopPolling();
    } else if (!skip) {
      refetch?.();
      startPolling(pollInterval);
    }
  }, [isWindowFocused, pollInterval, refetch, skip, startPolling, stopPolling]);
};
