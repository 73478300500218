import { routes } from "@/config/routes";
import { CheckCircleOutline } from "@mui/icons-material";
import { ReactNode } from "react";
import { useIntl } from "react-intl";
import {
  FinalStepDescription,
  FinalStepDot,
  FinalStepItem,
  FinalStepLinkChunks,
  FinalStepList,
  FinalStepListItem,
  FinalStepRow,
  FinalStepView,
} from "../../../Wizard.styles";

export const AccountingOnPremiseFinalStep = () => {
  const intl = useIntl();

  return (
    <>
      <FinalStepRow>
        <CheckCircleOutline className="mr-4 h-20 w-20 text-blue-500" />
        {intl.$t({ id: "BASIC_SETUP_DONE" })}
      </FinalStepRow>
      <FinalStepView>
        {intl.$t({ id: "GO_TO_INDIVIDUAL_TABS_TO_COMPLETE_FINAL_STEPS" })}
        <FinalStepList>
          <FinalStepListItem>
            <FinalStepDot />
            <FinalStepItem>
              {intl.$t({ id: "COST_CODES" })}
              <FinalStepDescription>
                {intl.$t(
                  {
                    id: "ENTER_COST_CODES_LINK",
                  },
                  {
                    sub: (chunks: ReactNode) => (
                      <FinalStepLinkChunks
                        chunks={chunks}
                        route={routes.costStructure}
                      />
                    ),
                  },
                )}
              </FinalStepDescription>
            </FinalStepItem>
          </FinalStepListItem>
          <FinalStepListItem>
            <FinalStepDot />
            <FinalStepItem>
              {intl.$t({ id: "VENDORS" })}
              <FinalStepDescription>
                {intl.$t(
                  {
                    id: "ENTER_VENDORS_LINK",
                  },
                  {
                    sub: (chunks: ReactNode) => (
                      <FinalStepLinkChunks
                        chunks={chunks}
                        route={routes.vendors}
                      />
                    ),
                  },
                )}
              </FinalStepDescription>
            </FinalStepItem>
          </FinalStepListItem>
          <FinalStepListItem>
            <FinalStepDot />
            <FinalStepItem>
              {intl.$t({ id: "PROJECTS" })}
              <FinalStepDescription>
                {intl.$t({
                  id: "ENTER_PROJECTS_LINK",
                })}
              </FinalStepDescription>
            </FinalStepItem>
          </FinalStepListItem>
          <FinalStepListItem>
            <FinalStepDot />
            <FinalStepItem>
              {intl.$t({ id: "MATERIALS" })}
              <FinalStepDescription>
                {intl.$t(
                  {
                    id: "ENTER_MATERIALS_LINK",
                  },
                  {
                    sub: (chunks: ReactNode) => (
                      <FinalStepLinkChunks
                        chunks={chunks}
                        route={routes.itemDatabase}
                      />
                    ),
                  },
                )}
              </FinalStepDescription>
            </FinalStepItem>
          </FinalStepListItem>
        </FinalStepList>
      </FinalStepView>
    </>
  );
};
