import { InlineNotesAndTerms } from "@/common/components/inline-notes-and-terms/InlineNotesAndTerms";
import {
  UploadAssetProvider,
  useUploadAssets,
} from "@/common/components/upload-asset/UploadAssetProvider";
import { checkQuoteStatus } from "@/common/utils/status-checks/checkQuoteStatus";
import { DISTRIBUTOR_QUOTE_READ_ONLY_STATUSES } from "@/distributor/common/constants";
import {
  AssetContext,
  AssetFieldsFragment,
  DistributorQuoteFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";

type Props = {
  quote: DistributorQuoteFieldsFragment;
};

const DistributorQuoteInlineNotesAndTermsWithProvider: FC<Props> = ({
  quote,
}) => {
  const { updateQuote } = useDistributorQuote();
  const { assets } = useUploadAssets();

  const save = async ({
    newAssets,
    notes,
  }: {
    newAssets?: AssetFieldsFragment[];
    notes?: string | undefined;
  } = {}) => {
    await updateQuote?.({
      notes: notes || undefined,
      assetUrls: (newAssets || assets).map((asset) => asset.url),
    });
  };

  const readonly = useMemo(() => {
    return checkQuoteStatus(quote, DISTRIBUTOR_QUOTE_READ_ONLY_STATUSES);
  }, [quote]);

  if (readonly && !quote.notes && !quote.assets.length) {
    return null;
  }

  return (
    <InlineNotesAndTerms save={save} notes={quote.notes} readonly={readonly} />
  );
};

export const DistributorQuoteInlineNotesAndTerms: FC<Props> = (props) => {
  const { quote } = props;
  return (
    <UploadAssetProvider
      initialAssets={quote?.assets}
      context={AssetContext.Note}
      projectId={quote?.rfq.project?.id ?? ""}
    >
      <DistributorQuoteInlineNotesAndTermsWithProvider {...props} />
    </UploadAssetProvider>
  );
};
