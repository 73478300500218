import { CircleCounter } from "@/common/components/circle-counter/CircleCounter";
import { getLetterFromIndex } from "@/common/components/circle-counter/getLetterFromIndex";
import { If } from "@/common/components/if/If";
import { TextfieldWithActions } from "@/common/components/textfield-with-actions/TextfieldWithActions";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { DistributorQuoteItemGroupExtendedFieldsFragment } from "../../providers/DistributorQuoteItemsProvider";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";
import { DistributorQuoteItemAddMenu } from "./DistributorQuoteItemAddMenu";

const Container = tw.div`w-full flex flex-row items-center gap-1`;
const TextfieldWithActionsStyled = tw(TextfieldWithActions)`
    w-full
    ${({ staticText }: { staticText?: boolean }) =>
      staticText ? "px-2 bg-transparent" : "`bg-transparent"}
`;

type Props = {
  item: DistributorQuoteItemGroupExtendedFieldsFragment;
  readonly?: boolean;
  index?: number;
  error?: boolean;
  showActions?: boolean;
  onCancel?: () => void;
  initialDescription?: string | null;
};

export const DistributorQuoteItemAlternativeName: FC<Props> = ({
  item,
  readonly,
  index = 0,
  error,
  showActions,
  onCancel,
  initialDescription: defaultDescription,
}) => {
  const { updateQuote } = useDistributorQuote();
  const intl = useIntl();
  const initialDescription = useMemo(
    () => defaultDescription || item.quoteItems[0]?.description,
    [defaultDescription, item.quoteItems],
  );
  const [description, setDescription] = useState<string>(
    initialDescription || "",
  );

  useEffect(() => {
    setDescription(initialDescription || "");
  }, [initialDescription]);

  const isLastItem = useMemo(
    () => item.quoteItemCount === index + 1,
    [index, item.quoteItemCount],
  );

  const letter = useMemo(() => getLetterFromIndex(index), [index]);

  const updateQuoteItem = useCallback(async () => {
    const result = await updateQuote({
      updates: [{ quoteItemId: item.quoteItems[0]?.id, description }],
    });
    if (result) {
      onCancel?.();
    }
  }, [description, item.quoteItems, onCancel, updateQuote]);

  return (
    <Container>
      <CircleCounter count={letter} innerClassName="bg-green-600" />
      <TextfieldWithActionsStyled
        label={
          !readonly
            ? intl.$t(
                {
                  id:
                    item.quoteItemCount === 1
                      ? "SPECIFIC_OPTION"
                      : "ALTERNATIVE_TAG",
                },
                { tag: letter },
              )
            : null
        }
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        staticText={readonly}
        onBlur={() => !showActions && updateQuoteItem()}
        shrink
        inputProps={{
          style: { marginTop: 0 },
          className: `${
            readonly && "font-light"
          } text-xs md:text-sm py-1 md:py-1.5`,
        }}
        error={error && !description}
        showActions={!readonly && showActions}
        onClickSave={updateQuoteItem}
        onClickClose={onCancel}
      />
      <If isTrue={!readonly && isLastItem}>
        <DistributorQuoteItemAddMenu item={item} />
      </If>
    </Container>
  );
};
