import { addressToString } from "@/common/components/adress-location/AddressLocation";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import {
  LgContainer,
  MdContainer,
  XsContainer,
} from "@/common/layout/ResponsiveClasses";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { Connections } from "../../../home/projects/components/Connections";
import { GroupedVendor } from "../Vendors";
import { Item } from "../Vendors.styles";
import { DeleteVendor } from "./DeleteVendor";
import { PreferredVendor } from "./PreferredVendor";
import { VendorContactEmails } from "./VendorContactEmails";
import { VendorContactNames } from "./VendorContactNames";
import { VendorContactPhones } from "./VendorContactPhones";
const VendorNameContainer = tw(LgContainer)`col-span-2`;
const AddressContainer = tw(LgContainer)`col-span-2`;
const PhoneWrapper = tw.div`w-25`;
const EmailContainer = tw(LgContainer)`flex-1`;
const ActionsContainer = tw(XsContainer)`sm:col-span-2 justify-self-end`;

export const useVendorsTableConfiguration = (): Array<
  GridCol<GroupedVendor>
> => {
  const { settings } = useOrgSettings();

  const hasAnyIntegration = useMemo(
    () => settings?.integrations.sourceSystems.some((s) => s.connected),
    [settings?.integrations.sourceSystems],
  );

  return [
    {
      wrapper: VendorNameContainer,
      position: "start",
      item: ({ item }) => (
        <PreferredVendor vendor={item.orgPreferredVendors[0]} />
      ),
      details: () => <div />,
      header: <FormattedMessage id="VENDOR_PREFERRED" />,
    },
    {
      wrapper: AddressContainer,
      position: "start",
      item: ({ item }) => (
        <Item>
          {addressToString(
            item.orgPreferredVendors[0].sellerOrgLocation.address,
          )}
        </Item>
      ),
      details: ({ item, index = 0 }) => (
        <Item>
          {addressToString(
            item.orgPreferredVendors[index].sellerOrgLocation.address,
          )}
        </Item>
      ),
      header: <FormattedMessage id="ADDRESS" />,
    },
    {
      wrapper: LgContainer,
      position: "start",
      item: ({ item }) => (
        <VendorContactNames contacts={item.orgPreferredVendors[0].contacts} />
      ),
      details: ({ item, index = 0 }) => (
        <VendorContactNames
          contacts={item.orgPreferredVendors[index].contacts}
        />
      ),
      header: <FormattedMessage id="VENDOR_CONTACT_NAME" />,
    },
    {
      wrapper: PhoneWrapper,
      position: "start",
      item: ({ item }) => (
        <VendorContactPhones contacts={item.orgPreferredVendors[0].contacts} />
      ),
      details: ({ item, index = 0 }) => (
        <VendorContactPhones
          contacts={item.orgPreferredVendors[index].contacts}
        />
      ),
      header: <FormattedMessage id="USER_CELL_PHONE" />,
    },
    {
      wrapper: MdContainer,
      position: "center",
      item: ({ item }) => (
        <Connections connections={item.orgPreferredVendors[0]?.mappings} />
      ),
      details: ({ item, index = 0 }) => (
        <Connections connections={item.orgPreferredVendors[index]?.mappings} />
      ),
      header: <FormattedMessage id="CONNECTIONS" />,
      hidden: !hasAnyIntegration,
    },
    {
      wrapper: EmailContainer,
      position: "end",
      item: ({ item }) => (
        <VendorContactEmails contacts={item.orgPreferredVendors[0].contacts} />
      ),
      details: ({ item, index = 0 }) => (
        <VendorContactEmails
          contacts={item.orgPreferredVendors[index].contacts}
        />
      ),
      header: <FormattedMessage id="USER_EMAIL" />,
    },
    {
      wrapper: ActionsContainer,
      item: ({ item }) => <DeleteVendor vendor={item.orgPreferredVendors[0]} />,
      details: ({ item, index = 0 }) => (
        <DeleteVendor vendor={item.orgPreferredVendors[index]} />
      ),
      position: "center",
    },
  ];
};
