import tw from "tailwind-styled-components";

interface ContainerProps {
  $centered: boolean;
}

export const Header = tw.div`
  font-bold border-b border-gray-400 pb-4 grid grid-cols-[2.8fr_1fr_repeat(1,minmax(0,_0.5fr))]
`;

export const HeaderItem = tw.div``;

export const ItemsContainer = tw.div`
  grid 
`;

export const ItemContainer = tw.div`
  border-b border-gray-400/[1] pb-2 pt-2 grid grid-cols-[0.8fr_2fr_1fr_repeat(1,minmax(0,_0.5fr))] grid-flow-row-dense
`;

export const ColumnContainer = tw.div`
  first:mr-2
`;

export const NameContainer = tw.div`
  text-xs font-bold flex ${(p: ContainerProps) =>
    p.$centered ? "justify-center" : "justify-start"}
`;

export const DetailsContainer = tw.div<ContainerProps>`
  text-xs flex h-full ${(p: ContainerProps) =>
    p.$centered ? "justify-center uppercase" : "justify-start"} 
`;

export const LocationContainer = tw.div`
  justify-between
`;

export const CenterContainer = tw.div`
  flex items-center justify-center h-full
`;

export const StatusContainer = tw(CenterContainer)`
    capitalize h-full flex flex-col items-center gap-2
`;

export const Item = tw.div``;
