import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { NoteDocumentFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";

export const useNoteDocumentItems = (
  noteDocument: NoteDocumentFieldsFragment | null | undefined,
) => {
  const { findMaterialByName } = useTableHelpers();
  const { calcExtPrice } = usePriceCalculation();

  const noteDocumentItems = useMemo(
    () =>
      (noteDocument?.items || []).map((item) => {
        const matchingOrgMaterial = findMaterialByName(item.description ?? "");
        const isLumpSum = isLumpSumUomText(item.uom);

        return {
          id: item.id,
          quantityDecimal: (isLumpSum ? item.unitPrice : item.quantity) ?? "",
          name: item.description ?? "",
          material: matchingOrgMaterial,
          costCode: matchingOrgMaterial?.costCode?.description ?? undefined,
          UOM:
            item.uom ??
            matchingOrgMaterial?.defaultEstimateUom?.pluralDescription ??
            matchingOrgMaterial?.defaultEstimateUom?.mnemonic,
          unitPrice: isLumpSum ? "1" : (item.unitPrice ?? undefined),
          extPrice: calcExtPrice(item.quantity, item.unitPrice ?? undefined),
        };
      }),
    [noteDocument?.items, findMaterialByName, calcExtPrice],
  );

  return {
    noteDocumentItems,
  };
};
