import { usePagination } from "@/common/components/pagination/PaginationProvider";
import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { routes } from "@/config/routes";
import {
  RfqVendorsDocument,
  RfqsDocument,
  ViewerDocument,
  useRfqQuery,
  useSubmitRfqMutation,
} from "@/generated/graphql";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useRfqMutations } from "./useRfqMutations";

export const useRfq = () => {
  const { id } = useParams();
  const { setError } = useGlobalError();
  const rfqId = id || "";
  const {
    data: rfq,
    error,
    loading,
  } = useRfqQuery({
    variables: { id: rfqId },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const navigate = useNavigate();
  const { paginationArgs } = usePagination();

  const [submitRfqMutation, { loading: isSubmitting }] = useSubmitRfqMutation({
    refetchQueries: [
      { query: RfqsDocument, variables: { ...paginationArgs, filter: {} } },
      { query: ViewerDocument },
    ],
    errorPolicy: "all",
  });
  const {
    cancelRfqMutation,
    createRfqDraftMutation,
    removeRfqMutation,
    updateRfq,
    isUpdating,
  } = useRfqMutations();

  const submit = async () => {
    try {
      const { data, errors } = await submitRfqMutation({
        variables: {
          input: {
            rfqId,
          },
        },
        refetchQueries: [
          { query: RfqVendorsDocument, variables: { id: rfqId } },
        ],
      });
      if (!errors && data?.submitRfq?.id) {
        navigate(generatePath(routes.rfqById, { id: data.submitRfq.id }));
      }
      setError(errors);
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const cancelRfq = async (rfqId: string) => {
    return await cancelRfqMutation(rfqId);
  };

  const removeRfq = async (rfqId: string) => {
    const result = await removeRfqMutation(rfqId);
    navigate(routes.quotes);
    return result;
  };

  const cancelChanges = () => navigate(routes.quotes);

  useErrorEffect(error);

  return {
    submit,
    isSubmitting,
    cancelRfq,
    cancelChanges,
    createRfqDraft: createRfqDraftMutation,
    updateRfq,
    removeRfq,
    rfq: rfq?.rfq || null,
    count: rfq?.rfq?.items?.length || 0,
    loading,
    isUpdating,
  };
};
