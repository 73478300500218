import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { useCallback } from "react";
import { useCustomPages } from "./useCustomPages";

export const useCustomConfig = () => {
  const { connectedSourceSystem } = useOrgSettingsExtended();
  const { customPages } = useCustomPages();

  const getCustomPages = useCallback(() => {
    const sourceSystem = connectedSourceSystem?.system;
    if (!sourceSystem) {
      return {
        pages: [],
      };
    }

    const pages = customPages.find(
      (page) => page.sourceSystem === sourceSystem,
    );
    if (!pages) {
      throw new Error(
        "Source system custom page configuration not found. The source system is configured as custom but no custom configuration is found.",
      );
    }
    return pages;
  }, [connectedSourceSystem?.system, customPages]);

  return { getCustomPages };
};
