import { CloseOutlined } from "@mui/icons-material";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { BaseButton } from "./BaseButton";
import { IconButtonBorderless } from "./IconButton";

const CloseIcon = tw(CloseOutlined)`w-5 h-5`;
type DiscardButtonProps = React.ComponentProps<typeof BaseButton>;

export const DiscardButton: FC<DiscardButtonProps> = ({ ...props }) => {
  return (
    <IconButtonBorderless autoScale {...props} testId="check-button">
      <CloseIcon />
    </IconButtonBorderless>
  );
};
