import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  OrgPreferredVendorsDocument,
  OrgPreferredVendorsQuery,
  UpdateVendorOrgInput,
  useUpdateVendorOrgMutation,
} from "@/generated/graphql";

export const useUpdateVendor = () => {
  const { setError } = useGlobalError();

  const [updateVendorOrgMutation, { loading: updating }] =
    useUpdateVendorOrgMutation();

  const updateVendorOrg = async (input: UpdateVendorOrgInput) => {
    try {
      const { errors } = await updateVendorOrgMutation({
        variables: { input },
        update: (cache, { data }) => {
          const queryVendorOrgs = cache.readQuery<OrgPreferredVendorsQuery>({
            query: OrgPreferredVendorsDocument,
          });
          if (queryVendorOrgs && data?.updateVendorOrg.id) {
            cache.writeQuery({
              query: OrgPreferredVendorsDocument,
              data: {
                orgPreferredVendors: queryVendorOrgs.orgPreferredVendors.map(
                  (el) => {
                    if (el.sellerOrgLocation.org.id === input.id) {
                      return {
                        ...el,
                        sellerOrgLocation: {
                          ...el.sellerOrgLocation,
                          org: {
                            ...el.sellerOrgLocation.org,
                            logoImageUrl: input.photoUrl,
                          },
                        },
                      };
                    }
                    return el;
                  },
                ),
              },
            });
          }
        },
      });
      setError(errors);
      return !errors;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  return {
    updateVendorOrg,
    updating,
  };
};
