import { InlineNotesAndAttachments } from "@/common/components/inline-notes-and-attachments/InlineNotesAndAttachments";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import {
  AssetContext,
  AssetFieldsFragment,
  RfqItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useRfq } from "../../hooks/useRfq";
import { useRfqGroupedItems } from "../../hooks/useRfqGroupedItems";

type Props = {
  item: RfqItemFieldsFragment;
  readonly: boolean;
};

const NotesDetailsWithProvider: FC<Props> = ({ item, readonly }) => {
  const { setExpandedItems, expandedItems, newRfqItem, setNewRfqItem } =
    useRfqGroupedItems();
  const { updateRfq, rfq } = useRfq();

  const closeItem = useCallback(
    () => setExpandedItems(expandedItems.filter((id) => id !== item.id)),
    [expandedItems, item.id, setExpandedItems],
  );

  const handleOnSave = useCallback(
    async (notes: string, assets?: AssetFieldsFragment[]) => {
      if (newRfqItem?.isAddMode) {
        setNewRfqItem({
          ...newRfqItem,
          instructions: {
            assetUrls: assets?.map((a) => a.url) ?? [],
            text: notes,
          },
        });
      } else {
        await updateRfq({
          rfqId: rfq?.id ?? "",
          updates: [
            {
              rfqItemId: item.id,
              instructions: {
                assetUrls: assets?.map((a) => a.url) ?? [],
                text: notes,
              },
            },
          ],
        });
      }
      closeItem();
    },
    [newRfqItem, closeItem, setNewRfqItem, updateRfq, rfq?.id, item.id],
  );

  return (
    <InlineNotesAndAttachments
      closeItem={closeItem}
      saveItem={handleOnSave}
      item={{
        ...item,
        notes: item.instructions?.text ?? "",
        assets: item.instructions?.assets ?? [],
      }}
      readonly={readonly}
      labelKey="CONTRACTOR_NOTES"
    />
  );
};

export const RfqItemInstructionsDetails: FC<Props> = (props) => {
  const { rfq } = useRfq();

  return (
    <UploadAssetProvider
      initialAssets={props.item.instructions?.assets}
      context={AssetContext.Instruction}
      projectId={rfq?.project?.id ?? ""}
    >
      <NotesDetailsWithProvider {...props} />
    </UploadAssetProvider>
  );
};
