import React, { Fragment, useMemo } from "react";
import tw from "tailwind-styled-components";
import { GraphDataType } from "../../../../../../common/components/charting/enums/GraphDataType";
import { useChartUtils } from "../../../../../../common/components/charting/hooks/useChartUtils";
import { LineChartPoint } from "./LineChartPoint";

const Container = tw.div`relative p-5 mx-auto`;
const Chart = tw.div`relative w-full h-full`;
const PointContainer = tw.div`absolute z-10 hover:z-20`;

type LineProps = {
  $inTheFuture: boolean;
};

const Line = tw.div<LineProps>`
  absolute bg-blue-300
  ${(props) => (props.$inTheFuture ? "bg-gray-400" : "")}
`;

const Background = tw.div`absolute grid grid-flow-col gap-0 w-fit h-full bg-white`;

type BackgroundLineProps = {
  $isWeekend: boolean;
  $inTheFuture: boolean;
};
const BackgroundLine = tw.div<BackgroundLineProps>`
  grid w-full h-full even:bg-blue-100 text-black items-end
  ${(props) => (props.$inTheFuture ? "even:bg-gray-200 odd:bg-gray-100" : "")}
`;

const HorizontalLine = tw.div`absolute w-full border-b border-gray-300 border-dashed`;
const HorizontalLineLabel = tw.div`absolute text-2xs text-gray-600 font-light pt-0.5 -bottom-full`;

interface LineChartProps {
  data: number[];
  width?: number;
  height?: number;
  padding?: number;
  labels?: string[];
  format: GraphDataType;
}

const LineChart: React.FC<LineChartProps> = ({
  data,
  width = 700,
  height = 500,
  padding = 0,
  labels,
  format,
}) => {
  const { getFormatted } = useChartUtils();

  const maxValue = Math.max(...data, 0) * 1.1;
  const minValue = 0;
  const paddedWidth = width - padding * 2;
  const paddedHeight = height - padding * 2;
  const stepWidth = paddedWidth / data.length;

  const mappedData = useMemo(
    () =>
      data.map((point, index) => {
        const percentage = (point - minValue) / (maxValue - minValue);
        const position = percentage * paddedHeight;
        const nextPosition =
          index < data.length - 1
            ? ((data[index + 1] - minValue) / (maxValue - minValue)) *
              paddedHeight
            : position;
        return {
          value: point,
          position,
          nextPosition,
        };
      }),
    [data, maxValue, minValue, paddedHeight],
  );

  if (data.every((value) => value === 0)) {
    return getFormatted(0, format);
  }

  return (
    <Container>
      <Background
        style={{
          width: `${width}px`,
          height: `${height}px`,
          transform: `translate(${Math.round(-stepWidth / 2)}px, 25px)`,
        }}
      >
        {mappedData.map((_, index) => {
          if (!labels) {
            return null;
          }
          const date = new Date(labels[index]);
          const isWeekend = date.getDay() === 0 || date.getDay() === 6;
          return (
            <BackgroundLine
              key={index}
              style={{
                width: `${stepWidth}px`,
              }}
              $isWeekend={isWeekend}
              $inTheFuture={date > new Date()}
            />
          );
        })}
        {mappedData.map((point, index) => {
          const closeThreshold = 20;
          const toClose = mappedData.some(
            (p, i) =>
              i > index &&
              Math.abs(p.position - point.position) < closeThreshold,
          );

          if (toClose) {
            return null;
          }

          return (
            <Fragment key={index}>
              <HorizontalLine
                style={{
                  left: `${padding}px`,
                  bottom: `${padding + point.position + 25}px`,
                }}
              >
                <HorizontalLineLabel>
                  {getFormatted(point.value, format)}
                </HorizontalLineLabel>
              </HorizontalLine>
            </Fragment>
          );
        })}
        <HorizontalLine
          style={{
            left: `${padding}px`,
            bottom: `${padding + 25}px`,
          }}
        >
          <HorizontalLineLabel>{getFormatted(0, format)}</HorizontalLineLabel>
        </HorizontalLine>
      </Background>
      <Chart
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        {mappedData.map((point, index) => {
          const date = new Date(labels?.[index] ?? "");
          return (
            <Fragment key={index}>
              <PointContainer
                style={{
                  left: `${padding + index * stepWidth}px`,
                  bottom: `${padding + point.position - 10}px`,
                  transform: "translate(-50%, -50%)",
                }}
              >
                <LineChartPoint
                  point={point}
                  index={index}
                  mappedData={mappedData}
                  format={format}
                  label={labels?.[index] ?? ""}
                />
              </PointContainer>
              {index < data.length - 1 && (
                <Line
                  $inTheFuture={date > new Date()}
                  style={{
                    width: `${Math.sqrt(Math.pow(stepWidth, 2) + Math.pow(point.nextPosition - point.position, 2))}px`,
                    height: "2px",
                    left: `${padding + index * stepWidth}px`,
                    bottom: `${padding + point.position}px`,
                    transform: `translateY(-50%) rotate(${Math.atan2(point.position - point.nextPosition, stepWidth)}rad)`,
                    transformOrigin: "0% 50%",
                  }}
                />
              )}
            </Fragment>
          );
        })}
      </Chart>
    </Container>
  );
};

export default LineChart;
