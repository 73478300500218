import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useAssetQuery } from "@/generated/graphql";
import { FC, PropsWithChildren, createContext, useContext } from "react";

type ProviderContextType = {
  loading: boolean;
  thumbnails: string[];
};

const ProviderContext = createContext<ProviderContextType>({
  thumbnails: [],
  loading: false,
});

export const AssetProvider: FC<PropsWithChildren<{ url: string }>> = ({
  children,
  url,
}) => {
  const { data, loading, error } = useAssetQuery({
    variables: {
      url,
    },
  });

  useErrorEffect(error);

  return (
    <ProviderContext.Provider
      value={{
        thumbnails: data?.asset?.pages?.map((p) => p.thumbnailUrl) ?? [],
        loading,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useAsset = (): ProviderContextType => useContext(ProviderContext);
