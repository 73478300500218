import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useManufacturers } from "@/common/hooks/useManufacturers";
import { PaymentTerm, usePaymentTerms } from "@/common/hooks/usePaymentTerms";
import { mergeChanges } from "@/common/utils/mergeChanges";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import {
  BuyoutDocument,
  BuyoutFieldsFragment,
  BuyoutItemFieldsFragment,
  BuyoutQuery,
  BuyoutStatus,
  UpdateContractorBuyoutInput,
  UpdateContractorBuyoutItemInput,
  useBuyoutQuery,
  useCreateReservedReleaseMutation,
} from "@/generated/graphql";
import {
  NoFunction,
  NoFunctionBooleanPromise,
  NoFunctionUndefinedPromise,
} from "@/types/NoFunction";
import { useApolloClient } from "@apollo/client";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useBuyoutMutations } from "../components/non-quoted/providers/useBuyoutMutations";

type PartialUpdateContractorBuyoutInput = Partial<UpdateContractorBuyoutInput>;

type ProviderContextType = {
  buyout: BuyoutFieldsFragment | null;
  updateBuyout: (
    updates?: PartialUpdateContractorBuyoutInput,
    trigger?: boolean,
  ) => Promise<boolean>;
  loading: boolean;
  createReservedRelease: (buyoutId: string) => Promise<string | undefined>;
  paymentTermOptions: PaymentTerm[];
  expandedItems: string[];
  setExpandedItems: (items: string[]) => void;
  selectedBuyoutItems: string[];
  setSelectedBuyoutItems: (items: string[]) => void;
  updating?: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  buyout: null,
  loading: false,
  updateBuyout: NoFunctionBooleanPromise,
  createReservedRelease: NoFunctionUndefinedPromise,
  paymentTermOptions: [],
  expandedItems: [],
  setExpandedItems: NoFunction,
  selectedBuyoutItems: [],
  setSelectedBuyoutItems: NoFunction,
  updating: false,
});

type Props = {
  children: React.ReactNode;
  id?: string;
};

export const ContractorBuyoutProvider: FC<Props> = ({ children, id }) => {
  const { id: bId, buyoutId } = useParams();
  const { data, loading, error } = useBuyoutQuery({
    variables: { id: buyoutId || bId || id || "" },
    skip: !buyoutId && !bId && !id,
    fetchPolicy: "cache-and-network",
  });
  const changes = useRef<UpdateContractorBuyoutInput>();

  const { updateContractorBuyout, loading: updating } = useBuyoutMutations();
  const client = useApolloClient();
  const { manufacturers } = useManufacturers();
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [selectedBuyoutItems, setSelectedBuyoutItems] = useState<string[]>([]);
  const { costCodes } = useCostCodes();
  const { setError } = useGlobalError();

  useErrorEffect(error);

  const { paymentTermsOptions } = usePaymentTerms();

  const isBulkUpdate = useMemo(() => {
    return data?.buyout?.status !== BuyoutStatus.Draft;
  }, [data?.buyout]);

  const updateQuery = useCallback(
    (newBulkUpdates: UpdateContractorBuyoutItemInput[]) => {
      client.cache.updateQuery(
        { query: BuyoutDocument, variables: { id: data?.buyout?.id } },
        (data: BuyoutQuery | null) => {
          if (data?.buyout) {
            return {
              ...data,
              buyout: {
                ...data?.buyout,
                items: data?.buyout?.items.map(
                  (item: BuyoutItemFieldsFragment) => {
                    if (newBulkUpdates?.length === 0) {
                      return item;
                    }
                    const updatedItem = newBulkUpdates.find(
                      (i) => i.buyoutItemId === item.id,
                    );
                    const newItem: BuyoutItemFieldsFragment = {
                      ...item,
                      quantityDecimal:
                        updatedItem?.quantityDecimal || item.quantityDecimal,
                      unitPrice:
                        updatedItem?.requestedUnitPrice || item.unitPrice,
                      description: updatedItem?.description || item.description,
                      manufacturer: updatedItem?.manufacturerId
                        ? manufacturers.find(
                            (m) => m.id === updatedItem?.manufacturerId,
                          ) || item.manufacturer
                        : item.manufacturer,
                      costCode: updatedItem?.costCodeId
                        ? costCodes.find(
                            (cc) => cc.id === updatedItem?.costCodeId,
                          )
                        : item.costCode,
                      tags: updatedItem?.tags
                        ? (data.buyout?.project?.tags || []).filter((t) =>
                            updatedItem.tags?.includes(t.id),
                          )
                        : item.tags,
                    };
                    return newItem;
                  },
                ),
              },
            };
          }
          return data;
        },
      );
    },
    [client.cache, costCodes, data, manufacturers],
  );

  const updateItems = async (input?: PartialUpdateContractorBuyoutInput) => {
    return await updateContractorBuyout(
      {
        ...input,
        buyoutId: data?.buyout?.id || "",
        version: data?.buyout?.version || 0,
      },
      data?.buyout,
    );
  };

  const accumulateChanges = (input: UpdateContractorBuyoutInput) => {
    changes.current = {
      ...changes.current,
      ...input,
      updates: mergeChanges(
        changes.current?.updates,
        input.updates,
        "buyoutItemId",
      ),
    };
    changes.current.updates = changes.current?.updates?.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.buyoutItemId === item.buyoutItemId),
    );
  };

  const [createReservedReleaseMutation] = useCreateReservedReleaseMutation();
  const createReservedRelease = async (buyoutId: string) => {
    try {
      const { data, errors } = await createReservedReleaseMutation({
        variables: {
          input: {
            buyoutId,
          },
        },
      });
      if (data?.createReservedRelease) {
        return data?.createReservedRelease.id;
      }
      setError(errors);
    } catch (errors) {
      setError(errors);
      return undefined;
    }
  };

  const updateBuyout = async (
    input?: PartialUpdateContractorBuyoutInput,
    trigger?: boolean,
  ) => {
    if (isBulkUpdate && !trigger) {
      accumulateChanges({
        ...input,
        buyoutId: data?.buyout?.id || "",
        version: data?.buyout?.version || 0,
      });
      updateQuery(changes.current?.updates || []);

      return true;
    } else {
      return await updateItems(input || changes.current);
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        buyout: data?.buyout || null,
        paymentTermOptions: paymentTermsOptions,
        loading,
        createReservedRelease,
        updateBuyout,
        expandedItems,
        setExpandedItems,
        selectedBuyoutItems,
        setSelectedBuyoutItems,
        updating,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useContractorBuyout = () => useContext(ProviderContext);
