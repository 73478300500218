import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  ApproveInvoiceInput,
  ReleaseDocument,
  useApproveInvoiceMutation,
} from "@/generated/graphql";
import { useCallback } from "react";

export const useInvoiceApprove = () => {
  const [approveInvoiceMutation, { loading }] = useApproveInvoiceMutation();
  const { setError } = useGlobalError();

  const approveInvoice = useCallback(
    async (input: ApproveInvoiceInput, releaseId?: string) => {
      try {
        const { data, errors } = await approveInvoiceMutation({
          variables: {
            input,
          },
          refetchQueries: releaseId
            ? [
                {
                  query: ReleaseDocument,
                  variables: {
                    id: releaseId,
                    invoiceId: input.invoiceId,
                  },
                },
              ]
            : undefined,
        });
        setError(errors);
        return Boolean(!!data?.approveInvoice && !errors);
      } catch (errors) {
        setError(errors);
        return false;
      }
    },
    [approveInvoiceMutation, setError],
  );

  return {
    approveInvoice,
    loading,
  };
};
