import { If } from "@/common/components/if/If";
import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import tw from "tailwind-styled-components";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";

const Container = tw.div`
  flex mr-1 w-5
`;

export const BuyoutItemCheckbox: FC<{
  item: BuyoutItemFieldsFragment;
}> = ({ item }) => {
  const { setSelectedBuyoutItems, selectedBuyoutItems } = useContractorBuyout();

  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedBuyoutItems(
        newChecked
          ? [...selectedBuyoutItems, item.id]
          : selectedBuyoutItems.filter((r) => r !== item.id),
      );
    },
    [item.id, selectedBuyoutItems, setSelectedBuyoutItems],
  );

  return (
    <Container>
      <If isTrue={item.id}>
        <SelectionCheckbox
          itemId={item.id}
          items={selectedBuyoutItems}
          setSelection={handleChange}
        />
      </If>
    </Container>
  );
};
