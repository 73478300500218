import { FormatCurrencyType } from "@/common/components/value-currency/ValueCurrency";
import { PDF_FONT } from "@/common/const";
import { DeliveryCategoryId } from "@/config/deliveryConfiguration";
import {
  DistributorQuoteFieldsFragment,
  QuoteStockingPriceFieldsFragment,
  ServicePriceFieldsFragment,
} from "@/generated/graphql";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { IntlShape } from "react-intl";

const HEADER = ["STOCKING_FEES", "REGULAR_HOURS", "OFF_HOURS"];

export const stocking = (
  doc: jsPDF,
  quote: DistributorQuoteFieldsFragment & QuoteStockingPriceFieldsFragment,
  intl: IntlShape,
  categories: DeliveryCategoryId[],
  itemsByCategory: (
    prices: ServicePriceFieldsFragment[],
    categoryId: DeliveryCategoryId,
  ) => ServicePriceFieldsFragment[],
  formatCurrency: FormatCurrencyType,
) => {
  const body: RowInput[] = [];
  const prices = quote.servicePriceSheet?.prices || [];
  categories.forEach((category) => {
    body.push([
      {
        content: intl.$t({
          id: `${category.productCategory}_${category.type}`,
        }),
        colSpan: 3,
        styles: {
          fontStyle: "bold",
        },
      },
    ]);
    itemsByCategory(prices, category).forEach((price) => {
      body.push([
        intl.$t({ id: price.name }),
        price.regularHoursAmount
          ? intl.$t(
              { id: "QUOTE_PRINT_PRICE_PER_UOM" },
              {
                value: formatCurrency(price.regularHoursAmount),
                uom: price.regularHoursUOM.mnemonic,
              },
            )
          : intl.$t({ id: "NOT_SPECIFIED" }),
        price.offHoursAmount
          ? intl.$t(
              { id: "QUOTE_PRINT_PRICE_PER_UOM" },
              {
                value: formatCurrency(price.offHoursAmount),
                uom: price.offHoursUOM.mnemonic,
              },
            )
          : intl.$t({ id: "NOT_SPECIFIED" }),
      ]);
    });
  });
  autoTable(doc, {
    theme: "grid",
    styles: {
      font: PDF_FONT,
    },
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: "black",
    },
    head: [HEADER.map((key) => intl.$t({ id: key }))],
    body: [...body],
  });
};
