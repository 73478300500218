import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Included = tw.div`w-3 h-3 bg-green-800 rounded-full`;
const Container = tw.div`flex items-center justify-center`;
const ExpirationContainer = tw.div`text-2xs text-red-500 ml-1 max-w-16`;
const TooltipText = tw.div`text-center`;

type Props = {
  id: string;
  expirationDate?: number | null;
};

export const IncludedInBuyout: FC<Props> = ({ id, expirationDate }) => {
  const daysUntilExpiration = useMemo(() => {
    if (!expirationDate) {
      return null;
    }
    const days = Math.abs(
      Math.ceil(
        (new Date(expirationDate).getTime() - new Date().getTime()) /
          (1000 * 60 * 60 * 24),
      ),
    );

    return days < 14 ? days : null;
  }, [expirationDate]);

  return (
    <Tooltip
      id={id}
      element={
        <Container>
          <Included />
          <If isTrue={daysUntilExpiration}>
            <FormattedMessage
              id="PRICE_EXPIRES_IN"
              values={{ days: daysUntilExpiration }}
              tagName={ExpirationContainer}
            />
          </If>
        </Container>
      }
    >
      <FormattedMessage
        id={
          daysUntilExpiration
            ? "ITEM_INCLUDE_IN_CURRENT_BUYOUT_WITH_EXPIRATION"
            : "ITEM_INCLUDE_IN_CURRENT_BUYOUT"
        }
        tagName={TooltipText}
      />
    </Tooltip>
  );
};
