import { Tabs } from "@/common/components/tabs/Tabs";
import { routes } from "@/config/routes";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const AdminOrderTabNavigation = () => {
  const intl = useIntl();
  const tabs = useMemo(
    () => [
      {
        label: intl.$t({ id: "ORDER_TYPES" }),
        path: routes.adminOrders,
      },
      {
        label: intl.$t({ id: "ORDER_APPROVAL" }),
        path: routes.orderApproval,
      },
      {
        label: intl.$t({ id: "INVOICE_APPROVAL" }),
        path: routes.invoiceApproval,
      },
    ],
    [intl],
  );

  return <Tabs tabs={tabs} type="secondary" className="pt-4" />;
};
