import { Loader } from "@/common/components/loader/Loader";
import { SAVED_LIST_VIEW_ID } from "@/common/const";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import {
  TableViewProvider,
  VIEW_STATE,
} from "@/common/providers/TableViewProvider";
import { RfqStatus } from "@/generated/graphql";
import { useRfqItemsListConfiguration } from "../rfq/components/RfqItemsList.configuration";
import { useRfq } from "../rfq/hooks/useRfq";
import { SyncRfqItemsProvider } from "../rfq/providers/SyncRfqItemsProvider";
import { RfqItems } from "./components/RfqItems";

const RfqCheckoutWithProvider = () => {
  const { rfq } = useRfq();

  if (!rfq) {
    return <Loader loading />;
  }

  return (
    <TableViewProvider
      saveSelection={{ id: SAVED_LIST_VIEW_ID.RFQ }}
      defaultView={
        rfq.status === RfqStatus.Draft
          ? VIEW_STATE.spreadsheet
          : VIEW_STATE.normal
      }
    >
      <SyncRfqItemsProvider>
        <RfqItems />
      </SyncRfqItemsProvider>
    </TableViewProvider>
  );
};

export const RfqCheckout = () => {
  const { spreadsheetConfig } = useRfqItemsListConfiguration(true);
  return (
    <ColumnMapperProvider config={spreadsheetConfig}>
      <RfqCheckoutWithProvider />
    </ColumnMapperProvider>
  );
};
