import { SelectControlled } from "@/common/components/select/components/single/SelectControlled";
import { TaxMode } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const SalesTaxPicker = () => {
  const intl = useIntl();
  const options = useMemo(() => {
    return [TaxMode.Blended, TaxMode.Majority, TaxMode.Separate].map(
      (mode) => ({
        value: mode,
        label: intl.$t({ id: `SALES_TAX_${mode}` }),
      }),
    );
  }, [intl]);

  return (
    <SelectControlled
      name="taxMode"
      getLabel={(option) => option.label}
      getValue={(option) => option.value}
      options={options}
      placeholder={intl.$t({ id: "SALES_TAX" })}
      rules={{ required: true }}
    />
  );
};
