import { If } from "@/common/components/if/If";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  relative grid grid-flow-col gap-2 h-6 items-center px-4
`;

const Tag = tw.div`
  text-2xs text-white bg-blue-800 px-1.5 py-1 rounded-2xl h-fit absolute whitespace-nowrap
`;

const Name = tw.div`
  text-xs border-b border-gray-400  border-dashed text-right py-1
  text-ellipsis overflow-hidden whitespace-nowrap
`;

type Props = {
  name: string;
  isAlternative?: boolean;
  isSingleAlternative?: boolean;
  index: number;
};

export const AlternativeDetailsRow = ({
  name,
  index,
  isAlternative = false,
  isSingleAlternative = false,
}: Props) => {
  const altLetter = useMemo(() => {
    if (isSingleAlternative) {
      return "";
    }
    return String.fromCharCode(65 + index);
  }, [index, isSingleAlternative]);

  return (
    <Container>
      <If isTrue={isAlternative && altLetter}>
        <Tooltip
          id={`assetUrl-${index}`}
          className="absolute left-1 top-0.5 z-20 w-10 cursor-pointer"
          element={
            <Tag>
              <FormattedMessage
                id="ALTERNATIVE_TAG"
                values={{ tag: altLetter }}
              />
            </Tag>
          }
        >
          <FormattedMessage id="ALTERNATIVE_TAG" values={{ tag: altLetter }} />
        </Tooltip>
      </If>
      <Name className={isAlternative ? "ml-10" : ""} title={name}>
        {name}
      </Name>
    </Container>
  );
};
