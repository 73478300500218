import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  Base,
  SmFixedContainer,
  SmdFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { ReleaseAllItemsCheckbox } from "@/contractor/pages/home/release/pages/specify-details/components/ReleaseAllItemsCheckbox";
import { ReleaseGroupCheckbox } from "@/contractor/pages/home/release/pages/specify-details/components/ReleaseGroupCheckbox";
import { ReleaseItemCheckbox } from "@/contractor/pages/home/release/pages/specify-details/components/ReleaseItemCheckbox";
import { ViewResolution } from "@/contractor/pages/home/release/pages/specify-details/components/issue-resolution/ViewResolution";
import { AddItemIssue } from "@/contractor/pages/home/release/pages/specify-details/components/report-issue/AddItemIssue";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useReleaseUpdate } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import { filterByMaterialNamePriceAndQty } from "@/contractor/pages/home/release/utils/filters/filterByMaterialNamePriceAndQty";
import { EstimatedItemFieldsFragment } from "@/generated/graphql";
import Decimal from "decimal.js";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import {
  PackingSlipReceiveViewState,
  useDeliverySlipVerification,
} from "../../../../providers/DeliverySlipVerificationProvider";
import { DeliverySlipReceivedQuantityInput } from "./DeliverySlipReceivedQuantityInput";
import { DeliverySlipReleaseItemMaterial } from "./DeliverySlipReleaseItemMaterial";

const RequestedItemsContainer = tw(Base)`col-span-full flex-1 `;

export const useDeliverySlipReleaseConfiguration = ({
  items,
  projectId,
}: {
  items: ExpandedReleaseItem[];
  projectId?: string;
}): Array<GridCol<ExpandedReleaseItem, EstimatedItemFieldsFragment>> => {
  const { selectedReleaseItemIds } = useReleaseUpdate();
  const { deliverySlip, packingSlipReceiveViewState } =
    useDeliverySlipVerification();

  const getSlipItem = useCallback(
    (item: { id: string }) => {
      return deliverySlip?.deliveredReleaseItems.find(
        (drItem) =>
          drItem.releaseItem.id === item.id && Number(drItem.quantity) > 0,
      );
    },
    [deliverySlip?.deliveredReleaseItems],
  );

  const getMatch = useCallback(
    (item: { id: string }) => {
      return deliverySlip?.releaseItemHints.find(
        (hint) => hint.releaseItem.id === item.id,
      );
    },
    [deliverySlip?.releaseItemHints],
  );

  return useMemo(
    () => [
      {
        wrapper: Base,
        position: "center",
        item: ({ item }) => <ReleaseItemCheckbox item={item} />,
        group: (category) => <ReleaseGroupCheckbox group={category} />,
        subgroup: (category) => <ReleaseGroupCheckbox group={category} />,
        hidden:
          packingSlipReceiveViewState !==
          PackingSlipReceiveViewState.EDIT_COVERAGE,
      },
      {
        wrapper: RequestedItemsContainer,
        item: ({ item, count }) => (
          <DeliverySlipReleaseItemMaterial item={item} count={count} />
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
            projectId={projectId}
          />
        ),
        header: (
          <>
            <If
              isTrue={
                packingSlipReceiveViewState ===
                PackingSlipReceiveViewState.EDIT_COVERAGE
              }
            >
              <ReleaseAllItemsCheckbox items={items} />
            </If>
            <FormattedMessage id="ITEM_DESCRIPTION" />
          </>
        ),
        searchItemFn: filterByMaterialNamePriceAndQty,
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => {
          const itemIsLumpSum = isLumpSumItem(item);
          return (
            <ValueUnit
              value={
                itemIsLumpSum
                  ? new Decimal(item.quantityDecimal)
                      .mul(item.unitPrice ?? 0)
                      .toNumber()
                  : item.quantityDecimal
              }
              uom={item.uom}
              renderAsPrice={itemIsLumpSum}
            />
          );
        },
        position: "center",
        header: <FormattedMessage id="RELEASE_QUANTITY" />,
      },
      {
        wrapper: SmdFixedContainer,
        position: "center",
        header: <FormattedMessage id="ORDER_RECEIVED_SO_FAR" />,
        item: ({ item }) =>
          isLumpSumItem(item) ? (
            <ValueUnit
              value={new Decimal(item.receivedQuantityDecimal)
                .mul(item.unitPrice ?? 0)
                .toNumber()}
              uom={item.uom}
              renderAsPrice
            />
          ) : (
            <If isTrue={item.isIncluded}>
              <ValueUnit
                value={item?.receivedQuantityDecimal || 0}
                uom={item.uom}
              />
            </If>
          ),
        hidden:
          packingSlipReceiveViewState !==
          PackingSlipReceiveViewState.EDIT_COVERAGE,
      },
      {
        wrapper: SmdFixedContainer,
        position: "center",
        header: (
          <>
            <FormattedMessage id="ORDER_RECEIVED_QUANTITY" />
            <InfoTooltip
              message={
                <FormattedMessage id="ORDER_RECEIVED_QUANTITY_TOOLTIP" />
              }
            />
          </>
        ),
        item: ({ item }) => {
          return (
            <If
              isTrue={
                item.isIncluded &&
                selectedReleaseItemIds.find((id) => id === item.id) &&
                packingSlipReceiveViewState ===
                  PackingSlipReceiveViewState.EDIT_COVERAGE
              }
            >
              <DeliverySlipReceivedQuantityInput
                item={item}
                receivedQuantity={
                  getSlipItem(item)?.quantity ||
                  getMatch(item)?.deliverySlipItem?.quantity?.toString() ||
                  new Decimal(item.quantityDecimal)
                    .sub(item.receivedQuantityDecimal || 0)
                    .toString()
                }
                slipItemQuantity={getSlipItem(item)?.quantity || "0"}
                key={getSlipItem(item)?.id}
              />
            </If>
          );
        },
        hidden:
          packingSlipReceiveViewState !==
          PackingSlipReceiveViewState.EDIT_COVERAGE,
      },
      {
        wrapper: SmFixedContainer,
        position: "center",
        header: (
          <>
            <FormattedMessage id="ORDER_RECEIVED_QUANTITY" />
            <InfoTooltip
              message={
                <FormattedMessage id="ORDER_RECEIVED_QUANTITY_TOOLTIP" />
              }
            />
          </>
        ),
        item: ({ item }) => (
          <If isTrue={item.isIncluded}>
            <ValueUnit
              value={getSlipItem(item)?.quantity || 0}
              footer={
                <FormattedMessage
                  id="QUANTITY_SO_FAR"
                  values={{ value: item.receivedQuantityDecimal }}
                />
              }
            />
          </If>
        ),
        hidden:
          packingSlipReceiveViewState ===
          PackingSlipReceiveViewState.EDIT_COVERAGE,
      },
      {
        wrapper: SmdFixedContainer,
        item: ({ item }) =>
          item.isIncluded &&
          (item.issues.length ||
            (selectedReleaseItemIds.find((id) => id === item.id) &&
              packingSlipReceiveViewState ===
                PackingSlipReceiveViewState.EDIT_COVERAGE)) ? (
            item.issues?.[0]?.resolution ? (
              <ViewResolution item={item} />
            ) : (
              <AddItemIssue item={item} batch />
            )
          ) : null,
        position: "center",
        header: <FormattedMessage id="DELIVERY_ISSUES" />,
      },
    ],
    [
      getMatch,
      getSlipItem,
      items,
      packingSlipReceiveViewState,
      projectId,
      selectedReleaseItemIds,
    ],
  );
};
