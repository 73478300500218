import {
  DEFAULT_ADDRESS_COUNTRY,
  DEFAULT_ADDRESS_COUNTRY_ALTERNATIVE,
} from "../const";

export const isDefaultCountry = (countryCode: string | null): boolean => {
  return (
    countryCode === DEFAULT_ADDRESS_COUNTRY_ALTERNATIVE ||
    countryCode === DEFAULT_ADDRESS_COUNTRY
  );
};
