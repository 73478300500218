import { LinkLike } from "@/common/components/link-like/LinkLike";
import { OrgLocationFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import { ToggleNewLocationForm } from "./ToggleNewLocationForm";

type DisplayLocationNameProps = {
  location: OrgLocationFieldsFragment;
};

export const DisplayLocationName: FC<DisplayLocationNameProps> = ({
  location,
}) => {
  const intl = useIntl();

  return (
    <ToggleNewLocationForm
      label={intl.$t({ id: "LOCATION_EDIT_LOCATION" })}
      location={location}
    >
      <LinkLike>{location.name}</LinkLike>
    </ToggleNewLocationForm>
  );
};
