import { Tabs } from "@/common/components/tabs/Tabs";
import { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { useMessageStats } from "../providers/MessageStatsProvider";
import { useMessages } from "../providers/MessagesProvider";
import { DisabledMessaging } from "./MessagesPanel";

enum TabType {
  Internal = 1,
  External = 2,
}

type Props = {
  disabled?: DisabledMessaging;
};

export const MessageTabs: FC<Props> = ({ disabled }) => {
  const { isPrivate, setIsPrivate } = useMessages();
  const intl = useIntl();
  const { messageStats } = useMessageStats();

  const tabs = useMemo(
    () => [
      {
        id: TabType.External,
        label: intl.$t({ id: "MESSAGE_TAB_VENDOR" }),
        onClick: () => setIsPrivate(false),
        count: messageStats?.unreadPublic || undefined,
        disabled: disabled?.vendor,
        disabledTooltip: intl.$t({ id: "RELEASE_MESSAGE_NOT_AVAILABLE" }),
      },
      {
        id: TabType.Internal,
        label: intl.$t({ id: "MESSAGE_TAB_INTERNAL" }),
        onClick: () => setIsPrivate(true),
        count: messageStats?.unreadPrivate || undefined,
      },
    ],
    [
      disabled?.vendor,
      intl,
      messageStats?.unreadPrivate,
      messageStats?.unreadPublic,
      setIsPrivate,
    ],
  );
  return (
    <Tabs
      className="-top-14 overflow-visible pt-4 xl:mx-0 xl:px-4"
      tabs={tabs}
      findIndex={(tab) =>
        isPrivate ? tab.id === TabType.Internal : tab.id === TabType.External
      }
    />
  );
};
