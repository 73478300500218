import { TagPicker } from "@/common/components/tag-picker/TagPicker";
import { checkBuyoutStatus } from "@/common/utils/status-checks/checkBuyoutStatus";
import {
  BuyoutItemFieldsFragment,
  BuyoutStatus,
  TagFieldsFragment,
  UpdateContractorBuyoutInput,
} from "@/generated/graphql";
import { FC, useCallback, useState } from "react";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutGrouped } from "../providers/BuyoutGroupedProvider";

type Props = {
  item?: BuyoutItemFieldsFragment;
  readonly?: boolean;
  visibleTags?: number;
  batchUpdates?: boolean;
};

export const BuyoutTagsPicker: FC<Props> = ({
  item,
  visibleTags = 3,
  readonly,
}) => {
  const { buyout, updateBuyout } = useContractorBuyout();
  const { newBuyoutItem } = useBuyoutGrouped();

  const [selectedTags, setSelectedTags] = useState<TagFieldsFragment[]>([]);

  const handleChange = useCallback(
    async (tags: string[]) => {
      if (buyout) {
        if (item?.id) {
          const input: UpdateContractorBuyoutInput = {
            buyoutId: buyout?.id,
            version: buyout?.version,
            updates: [
              {
                buyoutItemId: item.id,
                tags: tags.slice(-1),
              },
            ],
          };
          await updateBuyout(
            input,
            checkBuyoutStatus(buyout, [BuyoutStatus.Active]),
          );
        } else {
          newBuyoutItem.tags = tags;
          setSelectedTags(
            tags
              .slice(-1)
              .map(
                (tag) =>
                  buyout.project?.tags?.find(
                    (t) => t.id === tag,
                  ) as TagFieldsFragment,
              ),
          );
        }
      }
    },
    [buyout, item?.id, updateBuyout, newBuyoutItem],
  );

  if (!buyout) {
    return null;
  }

  return (
    <TagPicker
      options={buyout.project?.tags || []}
      values={item?.id ? (item?.tags ?? selectedTags) : selectedTags}
      handleChange={handleChange}
      visibleTags={visibleTags}
      project={buyout.project}
      readonly={readonly}
    />
  );
};
