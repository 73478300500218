import { Controller, useFormContext } from "react-hook-form";
import { ControlledProps } from "../../textfield-controlled/TextFieldControlled";
import { DatePicker, DatePickerProps } from "./DatePicker";

type DatePickerControlledProps = Omit<DatePickerProps, "onChange" | "date"> &
  ControlledProps;

export const DatePickerControlled = ({
  name,
  rules,
  ...props
}: DatePickerControlledProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <DatePicker
          {...field}
          date={field.value}
          onChange={field.onChange}
          error={fieldState.invalid}
          required={!!rules?.required}
          data-testid={name}
          {...props}
        />
      )}
    />
  );
};
