import { ProjectFieldsFragment, TagFieldsFragment } from "@/generated/graphql";
import { useCallback } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { Loader } from "../loader/Loader";
import { ShowMoreCounter } from "../show-more-counter/ShowMoreCounter";
import { AddTagItem } from "./AddTagItem";
import { TagItem } from "./TagItem";

const Container = tw.div`flex justify-start items-center gap-1 max-w-full`;
const TagLoader = tw.div`flex`;

type Props = {
  options?: TagFieldsFragment[];
  values: TagFieldsFragment[];
  handleChange?: (tags: string[]) => void;
  readonly?: boolean;
  loading?: boolean;
  visibleTags?: number;
  project?: Pick<ProjectFieldsFragment, "id" | "tags"> | null;
};

const TagPopoverContainer = tw.div`grid gap-1`;

export const TagPicker = ({
  values,
  options = [],
  handleChange,
  readonly,
  loading,
  project,
  visibleTags = 2,
}: Props) => {
  const handleRemove = useCallback(
    (value: TagFieldsFragment) => {
      handleChange?.([
        ...(values?.filter((t) => t.id !== value.id).map((v) => v.id) || []),
      ]);
    },
    [handleChange, values],
  );

  const handleAdd = useCallback(
    (value: string) => {
      handleChange?.([...(values?.map((v) => v.id) || []), value]);
    },
    [handleChange, values],
  );
  return (
    <Container>
      {values.slice(0, visibleTags).map((value, key) => (
        <TagItem
          key={key}
          tag={value}
          onRemove={handleRemove}
          readonly={readonly}
        />
      ))}
      <If isTrue={values.length > visibleTags}>
        <ShowMoreCounter
          count={values.length - visibleTags}
          classes={{ innerElement: "space-x-1 text-sm" }}
          color="secondary"
        >
          <TagPopoverContainer>
            {values.slice(visibleTags, values.length).map((value, key) => (
              <TagItem
                key={key}
                tag={value}
                onRemove={handleRemove}
                readonly={readonly}
              />
            ))}
          </TagPopoverContainer>
        </ShowMoreCounter>
      </If>
      <If isTrue={!readonly && !loading}>
        <AddTagItem
          options={options}
          values={values}
          handleChange={handleAdd}
          project={project}
        />
      </If>
      <TagLoader>
        <Loader loading={!!loading} small />
      </TagLoader>
    </Container>
  );
};
