import { DeleteButton } from "@/common/components/button/DeleteButton";
import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { PhoneInputControlled } from "@/common/components/phone-input/PhoneInputControlled";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { TextFieldControlled } from "@/common/components/textfield-controlled/TextFieldControlled";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { isDefaultCountry } from "@/common/utils/isDefaultCountry";
import { isValidCellPhone, isValidEmail } from "@/common/utils/validationUtils";
import { OrgPreferredVendorsFieldsFragment } from "@/generated/graphql";
import { Add, InfoOutlined } from "@mui/icons-material";
import { FC, useCallback, useMemo } from "react";
import { FieldValues, useFieldArray, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  AddNewContainer,
  Contact,
  LineItem,
  LineItemLabel,
  Notification,
  PopoverText,
  VendorContactTitle,
} from "../../../Vendors.styles";
import { VendorNotificationError } from "../VendorNotificationError";

const ContactContainer = tw.div`flex flex-col gap-2`;
const Row = tw.div`flex items-center justify-between`;
const Label = tw.div`font-medium`;
const SwitchContainer = tw.div`flex items-center gap-2`;

export const VendorContactSection: FC = () => {
  const intl = useIntl();

  const { control, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "contacts",
  });

  const addVendorContacts = watch("addVendorContacts");
  const country = watch("newVendor.location.country");

  const contacts: OrgPreferredVendorsFieldsFragment["contacts"] =
    watch("contacts");
  const requiredStateAndCode = useMemo(
    () => isDefaultCountry(country),
    [country],
  );

  const addNewContact = useCallback(() => {
    append("contacts", {
      email: "",
      cellPhone: "",
      name: "",
      receivesInvoiceNotifications: false,
      receivesQuoteNotifications: false,
      receivesBuyoutNotifications: false,
      receivesOrderNotifications: false,
    } as FieldValues);
  }, [append]);

  const removeContact = useCallback(
    (position: number) => {
      remove(position);
    },
    [remove],
  );

  const isValidContactCellPhone = useCallback(
    (value: string | null) => {
      return !isValidCellPhone(value) && requiredStateAndCode
        ? intl.$t({ id: "PHONE_NUMBER_VALIDATION" })
        : true;
    },
    [intl, requiredStateAndCode],
  );

  const isValidContactEmail = useCallback(
    (value: string | null) => {
      return !isValidEmail(value) ? intl.$t({ id: "EMAIL_VALIDATION" }) : true;
    },
    [intl],
  );

  return (
    <>
      <Row>
        <Label>
          <FormattedMessage id="ADD_VENDOR_CONTACTS_QUESTION_VENDOR" />
        </Label>
        <SwitchContainer>
          <InfoTooltip
            message={intl.$t({ id: "ADD_VENDOR_CONTACTS_TOOLTIP_VENDOR" })}
          />
          <SwitchControlled
            name="addVendorContacts"
            onLabel={intl.$t({ id: "YES" })}
            offLabel={intl.$t({ id: "NO" })}
          />
        </SwitchContainer>
      </Row>
      <If isTrue={addVendorContacts}>
        <ContactContainer>
          {fields.map((field, index) => (
            <Contact key={field.id}>
              <VendorContactTitle>
                <FormattedMessage
                  id="PREFERRED_VENDOR_COUNT"
                  values={{ count: index + 1 }}
                  tagName={LineItemLabel}
                />
                <If isTrue={fields.length > 1}>
                  <DeleteButton onClick={() => removeContact(index)} />
                </If>
              </VendorContactTitle>
              <LineItem>
                <TextFieldControlled
                  label={intl.$t({ id: "VENDORS_FORM_NAME" })}
                  name={`contacts.${index}.name`}
                  rules={{ required: true }}
                  required
                />
              </LineItem>
              <LineItem>
                <TextFieldControlled
                  label={intl.$t({ id: "USER_EMAIL" })}
                  name={`contacts.${index}.email`}
                  rules={{ required: true, validate: isValidContactEmail }}
                />
              </LineItem>
              <LineItem>
                <PhoneInputControlled
                  name={`contacts.${index}.cellPhone`}
                  label={intl.$t({ id: "USER_CELL_PHONE" })}
                  rules={{ required: true, validate: isValidContactCellPhone }}
                  countryCode={country}
                />
              </LineItem>
              <VendorContactTitle>
                <FormattedMessage id="NOTIFICATIONS" tagName={LineItemLabel} />
                <Tooltip
                  id="notifications"
                  element={
                    <LinkLike>
                      <InfoOutlined />
                    </LinkLike>
                  }
                >
                  <FormattedMessage
                    id="NOTIFICATIONS_INFORMATION"
                    tagName={PopoverText}
                  />
                </Tooltip>
              </VendorContactTitle>
              <Notification
                $error={!contacts.find((c) => c.receivesQuoteNotifications)}
              >
                <FormattedMessage id="QUOTES" />
                <VendorNotificationError
                  hasError={!contacts.find((c) => c.receivesQuoteNotifications)}
                />
                <SwitchControlled
                  name={`contacts.${index}.receivesQuoteNotifications`}
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                  rules={{
                    validate: () =>
                      !!contacts.find((c) => c.receivesQuoteNotifications),
                  }}
                />
              </Notification>
              <Notification
                $error={!contacts.find((c) => c.receivesBuyoutNotifications)}
              >
                <FormattedMessage id="BUYOUTS" />
                <VendorNotificationError
                  hasError={
                    !contacts.find((c) => c.receivesBuyoutNotifications)
                  }
                />
                <SwitchControlled
                  name={`contacts.${index}.receivesBuyoutNotifications`}
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                  rules={{
                    validate: () =>
                      !!contacts.find((c) => c.receivesBuyoutNotifications),
                  }}
                />
              </Notification>
              <Notification
                $error={!contacts.find((c) => c.receivesOrderNotifications)}
              >
                <FormattedMessage id="ORDERS" />
                <VendorNotificationError
                  hasError={!contacts.find((c) => c.receivesOrderNotifications)}
                />
                <SwitchControlled
                  name={`contacts.${index}.receivesOrderNotifications`}
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                  rules={{
                    validate: () =>
                      contacts.find((c) => c.receivesOrderNotifications)
                        ? true
                        : "error",
                  }}
                />
              </Notification>
              <Notification
                $error={!contacts.find((c) => c.receivesInvoiceNotifications)}
              >
                <FormattedMessage id="INVOICES" />
                <VendorNotificationError
                  hasError={
                    !contacts.find((c) => c.receivesInvoiceNotifications)
                  }
                />
                <SwitchControlled
                  name={`contacts.${index}.receivesInvoiceNotifications`}
                  onLabel={intl.$t({ id: "YES" })}
                  offLabel={intl.$t({ id: "NO" })}
                  rules={{
                    validate: () =>
                      !!contacts.find((c) => c.receivesInvoiceNotifications),
                  }}
                />
              </Notification>
            </Contact>
          ))}
          <AddNewContainer>
            <LinkLike onClick={addNewContact}>
              <Add /> <FormattedMessage id="ADD_NEW_CONTACT" />
            </LinkLike>
          </AddNewContainer>
        </ContactContainer>
      </If>
    </>
  );
};
