import { GroupToggle } from "@/common/components/group-toggle/GroupToggle";
import { IntegrationBatchOptions } from "@/common/components/integration-batch-options/IntegrationBatchOptions";
import {
  Container,
  Content,
  Title,
} from "@/common/components/integration-batch-options/IntegrationBatchOptions.styles";
import { IntegrationFeature } from "@/common/hooks/integrations/types/IntegrationFeature";
import { ConnectionMode } from "@/contractor/pages/admin/integrations/components/common/ConnectionMode";
import { SourceSystemWrapper } from "@/contractor/pages/home/release/components/connections/components/common/SourceSystemWrapper";
import { BatchType, InvoiceToExportFieldsFragment } from "@/generated/graphql";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";

type Props = {
  defaultClosed?: boolean;
  invoice?: InvoiceToExportFieldsFragment;
  mode?: ConnectionMode;
};

export const InvoiceConnectionOptions: FC<Props> = ({
  defaultClosed = false,
  invoice,
  mode,
}) => {
  const [collapsed, setCollapsed] = useState(defaultClosed);

  return (
    <SourceSystemWrapper allowedFeatures={[IntegrationFeature.InvoiceWrites]}>
      <Container>
        <Title>
          <FormattedMessage id="OPTIONS" />
          <GroupToggle
            isOpened={!collapsed}
            onClick={() => setCollapsed((v) => !v)}
          />
        </Title>
        <Content $opened={!collapsed}>
          <IntegrationBatchOptions
            mode={mode}
            batch={invoice?.link?.batch}
            type={BatchType.Invoice}
          />
        </Content>
      </Container>
    </SourceSystemWrapper>
  );
};
