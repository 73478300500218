import { Checkbox } from "@/common/components/checkbox/Checkbox";
import { useMemo } from "react";

type Props<T> = {
  itemId?: string;
  items?: T[];
  setSelection: (checked: boolean) => void;
  getId?: (item: T) => string;
  checked?: boolean;
  testId?: string;
};

export const SelectionCheckbox = <T,>({
  items = [],
  setSelection,
  itemId = "",
  getId = (id: T) => id as string,
  checked: customChecked,
  testId,
}: Props<T>) => {
  const checked = useMemo(
    () => customChecked ?? items.map(getId).includes(itemId),
    [customChecked, items, getId, itemId],
  );

  return (
    <Checkbox
      data-testid={testId}
      checked={checked}
      onClick={(event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setSelection(!checked);
      }}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
};
