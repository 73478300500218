import { SequenceStepper } from "@/common/components/sequence-stepper/SequenceStepper";
import { routes } from "@/config/routes";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useDeliverySlipSequence } from "../../../providers/DeliverySlipSequenceProvider";

export const DeliverySlipSequenceStepper = () => {
  const navigate = useNavigate();
  const {
    step,
    setStep,
    sequenceIds,
    selectedDeliverySlips,
    redirectToDeliverySlips,
  } = useDeliverySlipSequence();

  const prevStepCallback = useCallback(() => {
    if (sequenceIds[step - 1]) {
      navigate(
        generatePath(
          selectedDeliverySlips.find((slip) => slip.id === sequenceIds[step])
            ?.release
            ? routes.deliverySlipReceiveOrder
            : routes.deliverySlipMatchOrder,
          {
            deliverySlipId: sequenceIds[step - 1],
          },
        ),
      );
    }
  }, [navigate, selectedDeliverySlips, sequenceIds, step]);

  const nextStepCallback = useCallback(() => {
    if (sequenceIds[step + 1]) {
      navigate(
        generatePath(
          selectedDeliverySlips.find((slip) => slip.id === sequenceIds[step])
            ?.release
            ? routes.deliverySlipReceiveOrder
            : routes.deliverySlipMatchOrder,
          {
            deliverySlipId: sequenceIds[step + 1],
          },
        ),
      );
    }
  }, [navigate, selectedDeliverySlips, sequenceIds, step]);

  return (
    <SequenceStepper
      titleId="PACKING_SLIPS"
      step={step}
      setStep={setStep}
      sequenceIds={sequenceIds}
      exitSequence={redirectToDeliverySlips}
      prevStepCallback={prevStepCallback}
      nextStepCallback={nextStepCallback}
    />
  );
};
