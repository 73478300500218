import { FC } from "react";
import { DrawerProvider, useDrawer } from "./DrawerProvider";
import { DrawerView } from "./DrawerView";

type Props = {
  anchor: (togglePanel: (opened: boolean) => void) => React.ReactNode;
  content: (togglePanel: (opened: boolean) => void) => React.ReactNode;
  defaultOpened?: boolean;
};

const DrawerPanelWithProvider: FC<Props> = ({ anchor, content }) => {
  const { isOpened, setIsOpened } = useDrawer();

  return (
    <DrawerView
      anchor={anchor}
      content={content}
      isOpened={isOpened}
      setIsOpened={setIsOpened}
    />
  );
};

export const DrawerPanel: FC<Props> = (props) => (
  <DrawerProvider defaultOpened={props.defaultOpened}>
    <DrawerPanelWithProvider {...props} />
  </DrawerProvider>
);
