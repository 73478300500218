import {
  TextFieldControlled,
  TextFieldControlledProps,
} from "@/common/components/textfield-controlled/TextFieldControlled";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export const ProjectJobNumberTextField: FC<
  TextFieldControlledProps & { tooltip?: string }
> = (props) => {
  const intl = useIntl();

  return (
    <Tooltip
      id="postCode-popover"
      position="top"
      element={
        <TextFieldControlled
          size="small"
          label={intl.$t({ id: "PROJECT_JOB_NUMBER" })}
          rules={{
            required:
              props.required === null || props.required === undefined
                ? true
                : false,
          }}
          {...props}
        />
      }
    >
      <FormattedMessage id={`${props.tooltip || "PROJECT_NUMBER_TOOLTIP"}`} />
    </Tooltip>
  );
};
