import { IntegrationConfig } from "@/common/hooks/integrations/types/IntegrationConfigType";
import { SourceSystem } from "@/generated/graphql";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useAgaveConnectionProvider } from "../../providers/AgaveConnectionProvider";

const Variation = tw.div`py-2 border border-dashed rounded-lg px-2 mb-2 cursor-pointer`;
const Header = tw.div`flex pb-6 items-center`;
const Logo = tw.div``;
const Image = tw.img`w-28`;
const HeaderText = tw.div`flex-1 font-bold`;

export const Variations = ({
  config,
  onClose,
}: {
  config: IntegrationConfig | undefined;
  onClose: () => void;
}) => {
  const { connect } = useAgaveConnectionProvider();

  const onClick = useCallback(
    async (sourceSystem: SourceSystem) => {
      await connect({ system: sourceSystem });
      onClose();
    },
    [connect, onClose],
  );

  if (!config || !config.variations) {
    return null;
  }

  return (
    <>
      <Header>
        <FormattedMessage id="SELECT_YOUR_SETUP" tagName={HeaderText} />
        <Logo>
          <Image src={config.logo.normal} />
        </Logo>
      </Header>
      {config.variations.map((variation, index) => (
        <Variation key={index} onClick={() => onClick(variation.system)}>
          {variation.description({ logo: config.logo.small })}
        </Variation>
      ))}
    </>
  );
};
