import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { If } from "@/common/components/if/If";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { NumericalInput } from "@/common/components/numerical-input/NumericalInput";
import { ProjectItemMaterialView } from "@/common/components/project-item-material-view/ProjectItemMaterialView";
import { Tooltip } from "@/common/components/tooltip/Tooltip";
import {
  MdFixedContainer,
  RfqItemContainer,
  SmFixedContainer,
  XsFixedContainer,
  XxsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { useMissingMaterialConversions } from "@/contractor/pages/home/release/components/connections/providers/MissingMaterialConversionsProvider";
import { SourceSystem } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import Decimal from "decimal.js";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { MaterialConversionActions } from "./MaterialConversionActions";
import { MaterialConversionMultiplier } from "./MaterialConversionMultiplier";
import { PoMaterialConversionFieldsFragmentWithId } from "./MissingMaterialConversionsList";
import { getConversionId } from "./utils/getConversionId";

type Props = {
  sourceSystem?: SourceSystem | null;
  includeActions?: boolean;
};

const ItemContainer = tw(RfqItemContainer)`flex-[0.5]`;
const ActionsContainer = tw(
  XsFixedContainer,
)`2xl:w-16 lg:w-16 2xl:basis-16 lg:basis-16`;
const Item = tw.div`text-sm text-center`;
const IconContainer = tw.div`border-r border-gray-400 border-dashed justify-between items-center gap-2 p-2 pr-5`;

export const useMissingMaterialConversionsConfiguration = ({
  sourceSystem,
  includeActions,
}: Props) => {
  const intl = useIntl();
  const {
    setConversion,
    setUpdatedConversion,
    conversions,
    poConversions,
    clearConversion,
  } = useMissingMaterialConversions();

  const configuration: Array<
    GridCol<PoMaterialConversionFieldsFragmentWithId>
  > = useMemo(
    () => [
      {
        wrapper: ItemContainer,
        item: ({ item, count }) => (
          <ProjectItemMaterialView item={item} count={count} />
        ),
        position: "none",
        header: <FormattedMessage id="ITEM_DESCRIPTION" />,
      },
      {
        wrapper: MdFixedContainer,
        item: ({ item }) => (
          <Item>{item.fulfillmentUom.pluralDescription}</Item>
        ),
        header: <FormattedMessage id="ORDERED_UOM" />,
        position: "center",
      },
      {
        wrapper: XxsFixedContainer,
        item: () => <>=</>,
        position: "center",
      },
      {
        wrapper: MdFixedContainer,
        item: ({ item }) => <Item>{item.purchasingUom}</Item>,
        header: (
          <FormattedMessage
            id="PURCHASING_UNITS_IN_INTEGRATION"
            values={{
              integration: sourceSystem
                ? intl.$t({ id: `INTEGRATION_${sourceSystem}` })
                : intl.$t({ id: "INTEGRATION_FOUNDATION" }),
            }}
          />
        ),
        position: "center",
      },
      {
        wrapper: XxsFixedContainer,
        item: () => <>x</>,
        position: "center",
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item, readonly, editMode }) => (
          <MaterialConversionMultiplier
            item={item}
            setConversion={setConversion}
            readonly={readonly && !editMode}
          />
        ),
        header: <FormattedMessage id="MULTIPLIER" />,
        position: "center",
      },
      {
        wrapper: XsFixedContainer,
        item: () => (
          <IconContainer>
            <Tooltip
              id="material-conversion-multiplier-tooltip"
              element={
                <LinkLike>
                  <InfoOutlined />
                </LinkLike>
              }
            >
              <FormattedMessage id="UOM_MULTIPLIER_TOOLTIP" />
            </Tooltip>
          </IconContainer>
        ),
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => (
          <NumericalInput
            staticText
            label={item.fulfillmentUom.pluralDescription}
            value={100}
            className="flex justify-center"
            inputProps={{ className: "text-center" }}
            InputLabelProps={{
              className: "w-full text-center transform-none text-xs ml-0",
            }}
          />
        ),
      },
      {
        wrapper: XxsFixedContainer,
        item: () => <>=</>,
        position: "center",
      },
      {
        wrapper: SmFixedContainer,
        item: ({ item }) => (
          <NumericalInput
            staticText
            label={item.purchasingUom}
            value={Number(
              new Decimal(
                conversions.find(
                  (c) =>
                    c.from === item.fulfillmentUom.pluralDescription &&
                    c.to === item.purchasingUom,
                )?.quantityMultiplier ||
                  poConversions.find(
                    (poc) => getConversionId(poc) === getConversionId(item),
                  )?.quantityMultiplier ||
                  0,
              ).mul(100),
            )}
            inputProps={{ className: "text-center" }}
            InputLabelProps={{
              className: "w-full text-center transform-none text-xs ml-0",
            }}
          />
        ),
      },
      {
        wrapper: ActionsContainer,
        item: ({ item, setEditMode, readonly, editMode }) => (
          <If isTrue={includeActions}>
            <MaterialConversionActions
              setEditMode={setEditMode}
              readonly={readonly && !editMode}
              setUpdatedConversion={setUpdatedConversion}
              item={item}
              clearConversion={clearConversion}
            />
          </If>
        ),
        position: "center",
      },
    ],
    [
      conversions,
      includeActions,
      intl,
      poConversions,
      setConversion,
      setUpdatedConversion,
      sourceSystem,
      clearConversion,
    ],
  );

  return configuration;
};
