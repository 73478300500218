import { DateListView } from "@/common/components/date/DateListView";
import { ReleasePartialFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`grid place-items-center`;
const DateItem = tw.div``;

type Props = {
  release: Pick<ReleasePartialFieldsFragment, "time">;
};

export const ReleaseTime: FC<Props> = ({ release }) => {
  return (
    <Container>
      <DateItem>
        <DateListView date={release.time} />
      </DateItem>
    </Container>
  );
};
