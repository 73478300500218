import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { strongify } from "@/common/utils/htmlUtils";
import { OrgType, UpdateOrgInput } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { useIntl } from "react-intl";
import { OrgProfileForm } from "../../organization/pages/org-profile/components/OrgProfileForm";
import { OrgProfileFormHookProvider } from "../../organization/pages/org-profile/components/OrgProfileFormHookProvider";
import { useOrgProfile } from "../../organization/pages/org-profile/hooks/useOrgProfile";

type Props = {
  label: string;
  onClose: () => void;
  useViewerEnterprise?: boolean;
};

const NewOrgPanelWithFormProvider: FC<Props> = ({
  label,
  onClose,
  useViewerEnterprise = true,
}) => {
  const { loading, createOrg, viewerOrg } = useOrgProfile();
  const { setSuccessAlert } = useSnackbar();
  const { handleSubmit } = useFormContext<UpdateOrgInput>();
  const { isValid } = useFormState();

  const intl = useIntl();

  const onSaveHandler = useCallback(
    async (values: UpdateOrgInput) => {
      const { settings, ...otherValues } = values;
      if (!otherValues.name) {
        return;
      }

      const result = await createOrg({
        ...otherValues,
        name: otherValues.name,
        localPart: settings?.invoices?.localPart,
        type: OrgType.Contractor,
        enterpriseId: useViewerEnterprise
          ? viewerOrg?.viewer?.org?.enterprise?.id
          : undefined,
      });

      if (!result?.id) {
        return;
      }
      setSuccessAlert(
        intl.$t({ id: "ORG_CREATE_SUCCESS" }, { name: strongify(result.name) }),
      );
      onClose();
    },
    [
      createOrg,
      useViewerEnterprise,
      viewerOrg?.viewer?.org?.enterprise?.id,
      setSuccessAlert,
      intl,
      onClose,
    ],
  );

  return (
    <OverlayPanel
      title={label}
      onSave={handleSubmit(onSaveHandler)}
      onCancel={onClose}
      saving={loading}
      disableSave={!isValid}
    >
      <OrgProfileForm fullWidth useViewerEnterprise={useViewerEnterprise} />
    </OverlayPanel>
  );
};

export const NewOrgPanel: FC<Props> = (props) => (
  <OrgProfileFormHookProvider>
    <NewOrgPanelWithFormProvider {...props} />
  </OrgProfileFormHookProvider>
);
