import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { InfoOutlined } from "@mui/icons-material";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const GrayBackground = tw.div`mx-4 rounded-3xl bg-gray-100 mt-10 items-center justify-center flex flex-1 flex-col`;
const Row = tw.div`flex flex-row items-center`;
const Title = tw.div`flex flex-row text-lg font-medium mb-2`;
const Description = tw.div`text-sm`;

type Props = {
  title: string;
  description: string | React.ReactNode;
  onNoClick: () => Promise<void> | void;
  onYesClick: () => Promise<void> | void;
  containerClassName?: string;
};

export const WizardDecisionStep: FC<Props> = ({
  title,
  description,
  onNoClick,
  onYesClick,
  containerClassName,
}) => {
  const [transitioningForward, setTransitioningForward] = useState(false);
  const [transitioningBackward, setTransitioningBackward] = useState(false);
  const onYesClickWrapper = async () => {
    setTransitioningForward(true);
    await onYesClick();
    setTransitioningForward(false);
  };

  const onNoClickWrapper = async () => {
    setTransitioningBackward(true);
    await onNoClick();
    setTransitioningBackward(false);
  };

  return (
    <GrayBackground className={containerClassName}>
      <Row>
        <InfoOutlined className="mr-4 h-12 w-12 text-gray-400" />
        <div>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </div>
      </Row>
      <Row className="mt-8">
        <OutlinedButton
          className="mr-2 w-40"
          onClick={onNoClickWrapper}
          loading={transitioningBackward}
        >
          <FormattedMessage id="NO" />
        </OutlinedButton>
        <PrimaryButton
          className="w-40"
          onClick={onYesClickWrapper}
          loading={transitioningForward}
        >
          <FormattedMessage id="YES" />
        </PrimaryButton>
      </Row>
    </GrayBackground>
  );
};
