import { Notes } from "@/common/components/notes/Notes";
import { BuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutGrouped } from "../providers/BuyoutGroupedProvider";

type Props = {
  item: BuyoutItemFieldsFragment;
  readonly?: boolean;
};

export const BuyoutItemInstructions: FC<Props> = ({
  item,
  readonly = false,
}) => {
  const { expandedItems, setExpandedItems } = useContractorBuyout();
  const { newBuyoutItem } = useBuyoutGrouped();

  return (
    <Notes
      expandedItems={expandedItems}
      setExpandedItems={setExpandedItems}
      item={{
        ...item,
        notes: item.instructions?.text,
        assets: item.instructions?.assets ?? [],
      }}
      readonly={readonly}
      hasNotesOrAttachments={
        !!item.instructions?.text ||
        !!item.instructions?.assets?.length ||
        (!item.id &&
          (!!newBuyoutItem.instructions?.text ||
            !!newBuyoutItem.instructions?.assetUrls?.length))
      }
    />
  );
};
