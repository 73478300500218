import { useGlobalError } from "@/common/hooks/useGlobalError";
import { AddCostCodeInput, useAddCostCodeMutation } from "@/generated/graphql";

export const useAddCostCode = () => {
  const [addCostCodeMutation] = useAddCostCodeMutation();
  const { setError } = useGlobalError();

  const addCostCode = async (input: AddCostCodeInput) => {
    try {
      const { data } = await addCostCodeMutation({
        variables: {
          input,
        },
      });
      if (data?.addCostCode) {
        return data.addCostCode.find((c) => c.code === input.code)?.id || null;
      }
      return null;
    } catch (error) {
      setError(error);
      return null;
    }
  };

  return { addCostCode };
};
