import { OrderTypeFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

const Container = tw.div`tx-sm font-medium p-4`;
const DefaultTag = tw.span`text-sm font-normal px-2 py-1 rounded-3xl bg-gray-200 ml-2`;

type OrderTypeNameProps = {
  orderType: OrderTypeFieldsFragment;
};

export const OrderTypeName: FC<OrderTypeNameProps> = ({ orderType }) => {
  return (
    <Container>
      {orderType.name}
      {orderType.default && <DefaultTag>Default</DefaultTag>}
    </Container>
  );
};
