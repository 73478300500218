import { useDownloadAssetLazyQuery } from "@/generated/graphql";
import { useCallback } from "react";
import { getLogoProportion } from "../utils/pdf-print/getLogoProportion";

type LogoData = {
  base64Data: string;
  proportion: number;
};

export const useDownloadAsset = () => {
  const [download] = useDownloadAssetLazyQuery();

  const downloadAsset = useCallback(
    async (url?: string | null): Promise<LogoData> => {
      let base64Data = "";
      let proportion = 1;
      if (url) {
        try {
          const { data } = await download({
            variables: {
              url,
            },
            fetchPolicy: "network-only",
          });
          if (data?.downloadAsset?.data) {
            base64Data = `data:image/jpeg;base64,${data.downloadAsset.data}`;
            proportion = await getLogoProportion(base64Data);
          }
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {}
      }
      return { base64Data, proportion };
    },
    [download],
  );

  return { downloadAsset };
};
