import { DEFAULT_ADDRESS_COUNTRY } from "@/common/const";
import { parsePhoneNumber } from "libphonenumber-js";

export const formatPhone = (value: string) => {
  if (!value) {
    return "";
  }
  const parsedPhoneNumber = parsePhoneNumber(value, DEFAULT_ADDRESS_COUNTRY);
  if (!parsedPhoneNumber.isPossible()) {
    return value;
  }
  return parsedPhoneNumber.formatNational();
};
