import { LocalPaginationProvider } from "@/common/components/pagination-local/LocalPaginationProvider";
import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { Switch } from "@/common/components/switch/Switch";
import {
  ExternalMaterialCostCodesProvider,
  useExternalMaterialCostCodes,
} from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/steps/shipping-and-stocking-fees/ExternalMaterialCostCodesProvider";
import { FC, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useImportExternalCostCodes } from "../../../providers/ImportExternalCostCodesProvider";
import { useImportExternalCostCodesTableConfiguration } from "./ImportExternalCostCodesTable.configuration";

const SearchBarTitleComponent = tw.div`flex flex-col pr-2`;
const ToggleText = tw.div`flex flex-row font-normal text-sm items-center mt-1`;

const ImportExternalCostCodesTableWithProviders = () => {
  const intl = useIntl();
  const {
    sourceSystem,
    selectedExternalCostCodes,
    setSelectedExternalCostCodes,
    setTotalExternalCostCodesCount,
    importAllExternalItems,
    setImportAllExternalItems,
  } = useImportExternalCostCodes();
  const { externalCostCodes, loadingExternalCostCodes } =
    useExternalMaterialCostCodes();
  const configuration = useImportExternalCostCodesTableConfiguration(
    selectedExternalCostCodes,
  );

  useEffect(() => {
    setTotalExternalCostCodesCount(externalCostCodes.length);
  }, [externalCostCodes, setTotalExternalCostCodesCount]);

  return (
    <LocalSearchableList
      tableConfiguration={configuration}
      items={externalCostCodes}
      loadingItems={loadingExternalCostCodes}
      searchFilterFn={(item, searchText) =>
        item.code.toLowerCase().includes(searchText) ||
        ("name" in item
          ? item.name.toLowerCase().includes(searchText)
          : item.description.toLowerCase().includes(searchText))
      }
      searchBarTitle={
        <SearchBarTitleComponent>
          <FormattedMessage id="SELECT_COST_CODES_TO_IMPORT" />
          <ToggleText>
            <FormattedMessage
              id="IMPORT_ALL_COST_CODES_FROM_SYSTEM"
              values={{
                system: intl.$t({ id: `INTEGRATION_${sourceSystem}` }),
              }}
            />
            <Switch
              className="ml-2"
              offLabel={intl.$t({ id: "NO" })}
              onLabel={intl.$t({ id: "YES" })}
              value={importAllExternalItems}
              onChange={(value) => {
                setImportAllExternalItems(value);
                setSelectedExternalCostCodes(
                  value ? externalCostCodes.map((i) => i.id) : [],
                );
              }}
            />
          </ToggleText>
        </SearchBarTitleComponent>
      }
      itemSelection={{
        type: "multiple",
        selectedItemIds: selectedExternalCostCodes,
        setSelectedItemIds: setSelectedExternalCostCodes,
      }}
    />
  );
};

export const ImportExternalCostCodesTable: FC = () => {
  const { sourceSystem } = useImportExternalCostCodes();
  return (
    <LocalPaginationProvider>
      <ExternalMaterialCostCodesProvider sourceSystem={sourceSystem}>
        <ImportExternalCostCodesTableWithProviders />
      </ExternalMaterialCostCodesProvider>
    </LocalPaginationProvider>
  );
};
