import { EventKeysConstants } from "@/config/constants/eventKeysContants";
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { FC, KeyboardEvent, forwardRef, useMemo } from "react";
import { genericError } from "../error/error";

export type TextFieldProps = MuiTextFieldProps & {
  staticText?: boolean;
  staticTextRows?: number;
  onClickEnter?: () => void;
  testId?: string;
  xs?: boolean;
  shrink?: boolean;
};

export const TextField: FC<TextFieldProps> = forwardRef(
  (
    {
      staticText,
      InputProps,
      InputLabelProps,
      variant,
      required,
      placeholder,
      rows,
      staticTextRows,
      onClickEnter,
      size = "small",
      testId,
      xs,
      shrink,
      ...props
    },
    ref,
  ) => {
    const InputPropsMemoized = useMemo(() => {
      if (staticText) {
        return {
          ...InputProps,
          readOnly: staticText,
          disableUnderline: staticText,
        };
      }

      return InputProps;
    }, [InputProps, staticText]);

    const variantMemoized = useMemo(
      () => (staticText ? "standard" : variant),
      [staticText, variant],
    );

    const handleClickEnter = (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === EventKeysConstants.Enter) {
        onClickEnter?.();
        event.preventDefault();
      }
    };

    return (
      <MuiTextField
        InputProps={InputPropsMemoized}
        InputLabelProps={{
          ...(staticText && { style: { color: "inherit" } }),
          ...(shrink && { shrink: true }),
          ...InputLabelProps,
          className: `${InputLabelProps?.className} bg-transparent`,
        }}
        {...props}
        inputProps={{
          ...props.inputProps,
          className:
            xs && !staticText
              ? `${
                  props.inputProps?.className || ""
                } text-xs md:text-sm px-4 py-1 md:px-2 md:py-1.5`
              : props.inputProps?.className,
        }}
        placeholder={!staticText ? placeholder : undefined}
        rows={!staticText ? rows : staticTextRows || undefined}
        required={!staticText && required}
        variant={variantMemoized}
        onKeyDown={handleClickEnter}
        data-testid={testId}
        data-attr={props.error ? genericError : undefined}
        size={size}
        ref={ref}
      />
    );
  },
);
