import useDelayedQuery from "@/common/hooks/useDelayedQuery";
import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useUser } from "@/common/providers/UserProvider";
import {
  NotificationFieldsFragment,
  NotificationsDocument,
  QueryNotificationsFilter,
  useNotificationsQuery,
  useReadNotificationMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";
import { useDeleteNotificationMutation } from "../../../../generated/graphql";
import { useErrorEffect } from "../../../hooks/useErrorEffect";
import { useQueryPollingWhileWindowFocused } from "../../../hooks/useQueryPollingWhileWindowFocused";

type ProviderContextType = {
  notifications: NotificationFieldsFragment[];
  markNotificationRead: (id: string) => void;
  removeNotification: (id: string) => Promise<boolean | undefined>;
  error: boolean;
  internalFilter: QueryNotificationsFilter;
  setInternalFilter: (filter: QueryNotificationsFilter) => void;
  isInternalTabActive: boolean;
  setIsInternalTabActive: (value: boolean) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  notifications: [],
  markNotificationRead: NoFunction,
  removeNotification: NoFunctionBooleanPromise,
  error: false,
  internalFilter: {},
  setInternalFilter: NoFunction,
  isInternalTabActive: true,
  setIsInternalTabActive: NoFunction,
});

const POLL_INTERVAL = 60000;
const QUERY_DELAY = 4000;

export const NotificationsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isInternalTabActive, setIsInternalTabActive] = useState<boolean>(true);
  const [internalFilter, setInternalFilter] =
    useState<QueryNotificationsFilter>({
      projectIds: [],
      sellerOrgLocationIds: [],
    });
  const { isContractor } = useUser();
  const { startQuery } = useDelayedQuery(QUERY_DELAY);
  const { data, error, refetch, startPolling, stopPolling } =
    useNotificationsQuery({
      fetchPolicy: "cache-and-network",
      variables: {
        first: 10,
        filter: internalFilter,
      },
      skip: !isContractor || !startQuery,
    });
  useQueryPollingWhileWindowFocused({
    pollInterval: POLL_INTERVAL,
    refetch,
    startPolling,
    stopPolling,
    skip: !isContractor || !startQuery,
  });
  const { setError } = useGlobalError();
  const [readNotification] = useReadNotificationMutation();

  const [deleteNotification] = useDeleteNotificationMutation();

  const removeNotification = async (id: string) => {
    try {
      const { errors, data: deleteData } = await deleteNotification({
        variables: {
          id,
        },
      });
      if (deleteData?.deleteNotification) {
        refetch({ first: 10, filter: internalFilter });
      }
      setError(errors);
      return !errors;
    } catch (errors) {
      setError(errors);
      return false;
    }
  };

  const markNotificationRead = async (id: string) => {
    try {
      const { errors } = await readNotification({
        variables: {
          input: {
            id,
          },
        },
        refetchQueries: [
          {
            query: NotificationsDocument,
          },
        ],
      });
      setError(errors);
    } catch (errors) {
      setError(errors);
    }
  };

  // We don't want to show error on silent update we are doing on background
  useErrorEffect(data ? undefined : error);

  return (
    <ProviderContext.Provider
      value={{
        notifications: data?.notifications.edges.length
          ? data?.notifications.edges.map(({ node }) => node)
          : [],
        error: !!error,
        markNotificationRead,
        removeNotification,
        isInternalTabActive,
        setIsInternalTabActive,
        internalFilter,
        setInternalFilter,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useNotifications = (): ProviderContextType =>
  useContext(ProviderContext);
