import { isDefaultCountry } from "@/common/utils/isDefaultCountry";
import { InputBaseComponentProps } from "@mui/material";
import * as React from "react";
import { IMaskInput } from "react-imask";

type PhoneMaskProps = Omit<InputBaseComponentProps, "onChange"> & {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  countryCode: string;
};

export const PhoneMask = React.forwardRef<HTMLInputElement, PhoneMaskProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, countryCode, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={
          isDefaultCountry(countryCode) ? "+{1} (000) 000-0000" : "000000000000"
        }
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={
          ref as React.RefCallback<HTMLTextAreaElement | HTMLInputElement>
        }
        onAccept={(value, mask) =>
          onChange({
            target: {
              name: props.name,
              value: !isDefaultCountry(countryCode)
                ? value
                : mask.unmaskedValue,
            },
          })
        }
        overwrite
      />
    );
  },
);
