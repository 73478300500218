import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { NewOrgPanel } from "@/contractor/pages/admin/orgs/components/NewOrgPanel";
import { FC } from "react";
import { LuPlusCircle } from "react-icons/lu";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { IconButtonBorderless } from "../../button/IconButton";

const AddIcon = tw(LuPlusCircle)`
  text-white opacity-50 text-xl
`;

export const AddNewOrgButton: FC = () => {
  const intl = useIntl();
  return (
    <DrawerPanel
      anchor={(togglePanel) => (
        <IconButtonBorderless onClick={() => togglePanel(true)}>
          <AddIcon />
        </IconButtonBorderless>
      )}
      content={(togglePanel) => (
        <NewOrgPanel
          label={intl.$t({ id: "NEW_ORG" })}
          onClose={() => togglePanel(false)}
        />
      )}
    />
  );
};
