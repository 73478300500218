import { HeaderContainerWithSecondaryNavigation } from "@/common/components/header-container/HeaderContainer";
import tw from "tailwind-styled-components";
import { NewOrderTypeButton } from "./edit-order-type/NewOrderTypeButton";

const HeaderContainerStyled = tw(HeaderContainerWithSecondaryNavigation)`
  justify-end
`;

export const OrderTypesHeader = () => {
  return (
    <HeaderContainerStyled>
      <NewOrderTypeButton />
    </HeaderContainerStyled>
  );
};
