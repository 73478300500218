import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { DisplayProjectActions } from "@/common/components/project-actions/DisplayProjectActions";
import { ProjectNameListDetails } from "@/common/components/projects-filter-selector/ProjectNameListDetails";
import { ProjectStatusChip } from "@/common/components/statuses/ProjectStatusChip";
import { UserAvatarGroup } from "@/common/components/user-avatar-group/UserAvatarGroup";
import {
  Base,
  MdContainer,
  SmWideContainer,
  StatusContainer,
  XsContainer,
} from "@/common/layout/ResponsiveClasses";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ProjectProjectsFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { Connections } from "../Connections";
import { DisplayProjectBudget } from "../DisplayProjectBudget";
import { DisplayProjectBuyouts } from "../DisplayProjectBuyouts";
import { DisplayProjectDeliveries } from "../DisplayProjectDeliveries";
import { DisplayProjectMaterialCount } from "../DisplayProjectMaterialCount";
import { DisplayProjectStartDate } from "../DisplayProjectStartDate";
const ProjectNameWrapper = tw(Base)`
  col-span-5
  lg:basis-2/12 lg:w-2/12
`;

const UsersWrapper = tw(MdContainer)`
  col-span-3
`;

const DateWrapper = tw(MdContainer)`
  col-span-2
`;

const ActionsWrapper = tw(XsContainer)`
  absolute right-2 top-0 lg:relative 
`;

export const useProjectsListItemConfiguration = () => {
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);
  const { settings } = useOrgSettings();

  const hasAnyIntegration = useMemo(
    () => settings?.integrations.sourceSystems.some((s) => s.connected),
    [settings?.integrations.sourceSystems],
  );

  const configuration: Array<GridCol<ProjectProjectsFieldsFragment>> = useMemo(
    () => [
      {
        wrapper: ProjectNameWrapper,
        item: ({ item }) => (
          <ProjectNameListDetails
            classes={{ projectName: "font-bold" }}
            project={item}
          />
        ),
        header: <FormattedMessage id="PROJECT" />,
      },
      {
        wrapper: DateWrapper,
        position: "center",
        item: ({ item }) => <DisplayProjectStartDate project={item} />,
        header: <FormattedMessage id="START_DATE" />,
      },
      {
        wrapper: SmWideContainer,
        position: "center",
        item: ({ item }) => <DisplayProjectBudget project={item} />,
        header: <FormattedMessage id="PROJECT_BUDGET" />,
        hidden: !hasPermissions,
      },
      {
        wrapper: SmWideContainer,
        position: "center",
        item: ({ item }) => <DisplayProjectBuyouts project={item} />,
        header: <FormattedMessage id="PROJECT_BUYOUTS" />,
      },
      {
        wrapper: SmWideContainer,
        position: "center",
        item: ({ item }) => <DisplayProjectDeliveries project={item} />,
        header: <FormattedMessage id="PROJECT_DELIVERED" />,
      },
      {
        wrapper: UsersWrapper,
        position: "center",
        item: ({ item }) => <UserAvatarGroup users={item.team} />,
        header: <FormattedMessage id="PROJECT_TEAM" />,
      },
      {
        wrapper: XsContainer,
        position: "center",
        item: ({ item }) => <DisplayProjectMaterialCount project={item} />,
        header: <FormattedMessage id="MATERIALS_SHORT" />,
      },
      {
        wrapper: SmWideContainer,
        position: "center",
        item: ({ item }) => <Connections connections={item.mappings} />,
        header: <FormattedMessage id="CONNECTIONS" />,
        hidden: !hasAnyIntegration,
      },
      {
        wrapper: StatusContainer,
        position: "center",
        item: ({ item }) => <ProjectStatusChip status={item.status} />,
        header: <FormattedMessage id="STATUS" />,
      },
      {
        wrapper: ActionsWrapper,
        item: ({ item }) => <DisplayProjectActions project={item} />,
        position: "center",
      },
    ],
    [hasPermissions, hasAnyIntegration],
  );

  return configuration;
};
