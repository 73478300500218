import { ReleaseSummaryFieldsFragment } from "@/generated/graphql";
import { LinkingProgress } from "@/types/LinkingProgress";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { HostedIntegrationReleaseInformation } from "./HostedIntegrationReleaseInformation";

const Container = tw.div`flex flex-col gap-3`;

type Props = {
  releases: ReleaseSummaryFieldsFragment[];
  editablePoNumber?: boolean;
  linkingPos: LinkingProgress;
  displayDisconnect: boolean;
  onClose?: () => void;
  updatingPoLink: boolean;
};

export const PoAgaveConnections: FC<Props> = ({
  releases,
  editablePoNumber,
  linkingPos,
  displayDisconnect,
  onClose,
  updatingPoLink,
}) => {
  const filteredReleases = useMemo(() => {
    if (linkingPos.linking) {
      return releases;
    } else {
      if (linkingPos.completed.length) {
        return releases.filter(
          (release) => !linkingPos.completed.includes(release.id),
        );
      }

      return releases;
    }
  }, [linkingPos.completed, linkingPos.linking, releases]);

  return (
    <Container>
      {filteredReleases.map((release, key) => {
        return (
          <HostedIntegrationReleaseInformation
            release={release}
            key={key}
            editablePoNumber={editablePoNumber}
            linking={
              (linkingPos.linking &&
                !linkingPos.completed.includes(release.id)) ||
              updatingPoLink
            }
            error={linkingPos.errors.find((e) => e.id === release.id)?.message}
            displayDisconnect={displayDisconnect}
            onClose={onClose}
          />
        );
      })}
    </Container>
  );
};
