import { UploadAsset } from "@/common/components/upload-asset/UploadAsset";
import { ATTACHMENTS_MIME_TYPES } from "@/common/components/upload/FileUploadArea";
import { AssetFieldsFragment } from "@/generated/graphql";
import { FC } from "react";

type Props = {
  updateUrls: (assets: AssetFieldsFragment[]) => void;
};

export const InvoiceIssueAssetUpload: FC<Props> = ({ updateUrls }) => {
  return (
    <UploadAsset
      onChange={updateUrls}
      accept={ATTACHMENTS_MIME_TYPES}
      mode="horizontal"
      includeAssets={false}
      classes={{
        container: "bg-white rounded-lg",
        dndContainer: "py-2",
        uploadContainer: {
          container: "px-2 h-17",
          image: "w-10 h-10",
          text: "w-24 text-xs",
        },
      }}
    />
  );
};
