import { useConnectMissingPoItemsMutation } from "@/generated/graphql";
import { useCallback } from "react";

export const useConnectMissingPOItems = () => {
  const [connectMissingPOItemsMutation, { loading }] =
    useConnectMissingPoItemsMutation();

  const connectMissingPOItems = useCallback(
    async (poLinkId?: string, invoiceId?: string) => {
      if (!poLinkId) {
        return;
      }
      const { data } = await connectMissingPOItemsMutation({
        variables: {
          poLinkId,
          invoiceId,
        },
      });

      return data?.connectMissingPOItems;
    },
    [connectMissingPOItemsMutation],
  );

  return {
    connectMissingPOItems,
    loading,
  };
};
