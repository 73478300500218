import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  AddTagInput,
  ProjectDocument,
  ProjectQuery,
  TagFieldsFragment,
  UpdateTagInput,
  UpdateTagsInput,
  useAddTagMutation,
  useProjectTagsQuery,
  useRemoveTagMutation,
  useUpdateTagMutation,
  useUpdateTagsMutation,
} from "@/generated/graphql";
import { NoFunction, NoFunctionUndefinedPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { updateTagsCache } from "../utils/updateTagsCache";

type ProviderContextType = {
  tags: TagFieldsFragment[];
  loading: boolean;
  addTag: (tag: AddTagInput) => Promise<TagFieldsFragment[] | undefined>;
  updateTag: (tag: UpdateTagInput) => void;
  updateTags: (
    tags: UpdateTagsInput,
  ) => Promise<TagFieldsFragment[] | undefined>;
  deleteTag: (id: string) => void;
  setProjectId: (id: string) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  tags: [],
  loading: false,
  addTag: NoFunctionUndefinedPromise,
  updateTag: NoFunction,
  updateTags: NoFunctionUndefinedPromise,
  deleteTag: NoFunction,
  setProjectId: NoFunction,
});

export const ProjectTagsProvider: FC<{
  children: React.ReactNode;
  id?: string;
}> = ({ children, id = "" }) => {
  const [addTagMutation] = useAddTagMutation();
  const [updateTagsMutation] = useUpdateTagsMutation();
  const [updateTagMutation] = useUpdateTagMutation();
  const [removeTagMutation] = useRemoveTagMutation();
  const { setError } = useGlobalError();
  const { deliveryId, id: buyoutId } = useParams();
  const [projectId, setProjectId] = useState(id);
  const { data, loading } = useProjectTagsQuery({
    variables: { id: projectId },
    skip: !projectId,
  });

  const updateTag = async (tag: UpdateTagInput) => {
    try {
      const { data, errors } = await updateTagMutation({
        variables: {
          input: tag,
        },
        update: (cache, { data }) => {
          const queryProject = cache.readQuery<ProjectQuery>({
            query: ProjectDocument,
            variables: { id: projectId, excludePhantoms: true },
          });
          if (queryProject && data?.updateTag) {
            cache.writeQuery({
              query: ProjectDocument,
              variables: { id: projectId, excludePhantoms: true },
              data: {
                project: {
                  ...queryProject?.project,
                  tags: queryProject?.project?.tags.map((tag) => {
                    if (tag.id === data.updateTag.id) {
                      return data.updateTag;
                    }
                    return tag;
                  }),
                },
              },
            });
          }
        },
      });
      setError(errors);
      if (data?.updateTag) {
        return !!data?.updateTag;
      }
    } catch (errors) {
      setError(errors);
    }
  };

  const addTag = async (tag: AddTagInput) => {
    try {
      const { data, errors } = await addTagMutation({
        variables: {
          input: tag,
        },
        update: (cache, { data }) => {
          updateTagsCache({
            cache,
            tags: data?.addTag,
            deliveryId,
            projectId,
            buyoutId,
          });
        },
      });
      setError(errors);
      return data?.addTag;
    } catch (errors) {
      setError(errors);
      return undefined;
    }
  };

  const updateTags = async (tags: UpdateTagsInput) => {
    try {
      const { data, errors } = await updateTagsMutation({
        variables: {
          input: tags,
        },
        update: (cache, { data }) => {
          updateTagsCache({
            cache,
            tags: data?.updateTags,
            deliveryId,
            projectId,
            buyoutId,
          });
        },
      });
      setError(errors);
      if (data?.updateTags) {
        return data?.updateTags;
      }
    } catch (errors) {
      setError(errors);
    }
  };

  const deleteTag = async (tagId: string) => {
    try {
      const { data, errors } = await removeTagMutation({
        variables: {
          tagId,
        },
        update: (cache, { data }) => {
          const queryProject = cache.readQuery<ProjectQuery>({
            query: ProjectDocument,
            variables: { id: projectId, excludePhantoms: true },
          });
          if (queryProject && data?.removeTag) {
            cache.writeQuery({
              query: ProjectDocument,
              variables: { id: projectId, excludePhantoms: true },
              data: {
                project: {
                  ...queryProject?.project,
                  tags: [...data.removeTag],
                },
              },
            });
          }
        },
      });
      setError(errors);
      if (data?.removeTag) {
        return !!data?.removeTag;
      }
    } catch (errors) {
      setError(errors);
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        tags: data?.project?.tags || [],
        loading,
        addTag,
        updateTag,
        updateTags,
        deleteTag,
        setProjectId,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useProjectTags = (): ProviderContextType =>
  useContext(ProviderContext);
