import { MailOutline, PhoneIphoneOutlined } from "@mui/icons-material";
import tw from "tailwind-styled-components";
import { ButtonsContainer } from "../list-buttons-container/ButtonsContainer";

export const FormSection = tw.div<{
  $extraBottomMargin?: boolean;
}>` bg-gray-350 bg-opacity-50 rounded-t-lg rounded-b-lg
${({ $extraBottomMargin }) => $extraBottomMargin && "first:mb-5"}`;
export const Item = tw.div`grid justify-start md:grid-cols-[1fr_1fr] grid-cols-[1fr_0.3fr]`;
export const LabelContainer = tw.div<{
  $roundedLeft?: boolean;
  $roundedBottom?: boolean;
}>`bg-gray-100 pl-8
${({ $roundedLeft }) => $roundedLeft && "rounded-l-lg"}
${({ $roundedBottom }) => $roundedBottom && "rounded-b-lg"}
`;
export const Label = tw.div`flex flex-row items-center gap-1`;
export const ValueContainer = tw.div`pr-8`;
export const BorderItem = tw.div<{ $lastItem?: boolean }>`py-2
${({ $lastItem }) => !$lastItem && "border-b border-gray-400 border-dashed"}`;
export const Header = tw.div`px-4 py-2 text-base font-medium bg-opacity-100 bg-gray-350 rounded-t-lg`;
export const Form = tw.div`flex flex-col my-6`;
export const Value = tw.div`flex justify-start pl-6`;
export const Mail = tw(MailOutline)`text-gray-500`;
export const Phone = tw(PhoneIphoneOutlined)`text-gray-500`;
export const Text = tw.span`text-sm`;
export const UserProfileHeader = tw.div`text-lg font-bold border-b border-gray-400`;
export const ButtonsContainerStyled = tw(
  ButtonsContainer,
)`grid-cols-auto justify-end`;
