import { useGlobalError } from "@/common/hooks/useGlobalError";
import {
  AssetFieldsFragment,
  QuoteDocumentFieldsFragment,
} from "@/generated/graphql";
import { NoFunctionUndefinedPromise } from "@/types/NoFunction";
import { FC, createContext, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useCreateQuoteDocument } from "../hooks/useCreateQuoteDocument";
import { useGetQuoteDocument } from "../hooks/useGetQuoteDocument";

type ProviderContextType = {
  createQuoteDocumentWithAsset: (
    orgLocationId: string,
  ) => Promise<QuoteDocumentFieldsFragment | null | undefined>;
  asset: AssetFieldsFragment | null;
  setAsset: (asset: AssetFieldsFragment) => void;
  quoteDocument?: QuoteDocumentFieldsFragment | null;
  loading: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  createQuoteDocumentWithAsset: NoFunctionUndefinedPromise,
  asset: null,
  setAsset: () => {},
  quoteDocument: null,
  loading: false,
});

export const QuoteDocumentProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { setError } = useGlobalError();
  const [asset, setAsset] = useState<AssetFieldsFragment | null>(null);
  const { quoteDocumentId } = useParams();
  const { createQuoteDocument } = useCreateQuoteDocument();

  const { quoteDocument, loading } = useGetQuoteDocument(quoteDocumentId);

  const createQuoteDocumentWithAsset = async (orgLocationId: string) => {
    try {
      if (!asset || !orgLocationId) {
        return null;
      }
      const quoteDocument = await createQuoteDocument({
        assetUrl: asset.url,
        orgLocationId,
      });
      if (quoteDocument) {
        return quoteDocument;
      }
    } catch (error) {
      setError(error);
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        createQuoteDocumentWithAsset,
        asset,
        setAsset,
        quoteDocument,
        loading,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useQuoteDocument = (): ProviderContextType =>
  useContext(ProviderContext);
