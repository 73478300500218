import { AssetsCard } from "@/common/components/assets-card/AssetsCard";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const IconContainer = tw.div`
  flex items-center justify-center w-5 h-5 text-sm text-red-500 border-2 border-red-500 rounded-full
`;

const ExclamationContainer = tw.div``;

type Props = {
  rejectionReason?: string | null;
};

export const ReleaseRejectReasonView: FC<Props> = ({ rejectionReason }) => {
  const intl = useIntl();

  if (!rejectionReason) {
    return null;
  }

  return (
    <AssetsCard
      title={intl.$t({ id: "REJECT_ORDER_REQUEST_REASON_HEADER" })}
      titleIcon={
        <IconContainer>
          <ExclamationContainer>!</ExclamationContainer>
        </IconContainer>
      }
      description={rejectionReason}
      showOnlyText
    />
  );
};
