import { NoFunction } from "@/types/NoFunction";
import { createContext, FC, useContext, useState } from "react";

type ProviderContextType = {
  setIsRfqWideMessage: (value: boolean) => void;
  isRfqWideMessage: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  isRfqWideMessage: false,
  setIsRfqWideMessage: NoFunction,
});

export const QuoteMessagesProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isRfqWideMessage, setIsRfqWideMessage] = useState(false);

  return (
    <ProviderContext.Provider
      value={{
        isRfqWideMessage,
        setIsRfqWideMessage,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useQuoteMessage = () => useContext(ProviderContext);
