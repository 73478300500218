import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { routes } from "@/config/routes";
import { OrderApprovalsStepper } from "@/contractor/pages/home/release/components/order-approval/OrderApprovalsStepper";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { ReleaseFieldsFragment, ReleaseStatus } from "@/generated/graphql";
import { FC, useCallback, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import { useOrderFromNote } from "../../../../providers/OrderFromNoteProvider";
import { ReceiveReleaseDialog } from "../../../receive-release-dialog/ReceiveReleaseDialog";
import { CreateOrderFromNoteFormValues } from "./CreateOrderFromNoteForm";
import { SubmitOrderFromNoteDialog } from "./SubmitOrderFromNoteDialog";

const ButtonContainer = tw.div`grid grid-flow-col gap-2 items-center justify-end`;
const FloatingFooterStyled = tw(FloatingFooter)`md:px-6`;

export const CreateOrderFromNoteFooter: FC = () => {
  const navigate = useNavigate();
  const { deliveryId } = useParams();
  const { release } = useRelease();
  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const {
    syncCreateReleaseFromNote,
    syncUpdateReleaseFromNote,
    validateSpreadsheet,
    loading,
  } = useOrderFromNote();

  const { reset, watch, handleSubmit } =
    useFormContext<CreateOrderFromNoteFormValues>();

  const createAndSubmitOrder = useCallback(
    async (skipConfirmation: boolean, retroactive: boolean) => {
      const result = await syncCreateReleaseFromNote(
        skipConfirmation,
        retroactive,
      );
      if (result) {
        reset();
      }

      return result;
    },
    [reset, syncCreateReleaseFromNote],
  );

  const createAndEditOrder = useCallback(async () => {
    const result = await syncCreateReleaseFromNote();
    if (result) {
      reset();
    }
    return result;
  }, [reset, syncCreateReleaseFromNote]);

  const orderDate = watch("orderDate");

  const retroactiveRelease = useMemo(() => {
    return !!orderDate && orderDate?.getTime() < new Date().getTime();
  }, [orderDate]);

  const handleSaveReleaseFromNote = useCallback(async () => {
    if (!validateSpreadsheet()) {
      return;
    }
    await handleSubmit(async () => {
      setIsSaving(true);
      let result: ReleaseFieldsFragment | null | undefined;
      if (deliveryId) {
        result = await syncUpdateReleaseFromNote();
      } else {
        result = await syncCreateReleaseFromNote(
          undefined,
          undefined,
          ReleaseStatus.Draft,
        );
      }
      if (result) {
        navigate(
          generatePath(routes.specifyDeliveryDetails, {
            deliveryId: result.id,
          }),
        );
      }
      setIsSaving(false);
    })();
  }, [
    validateSpreadsheet,
    handleSubmit,
    deliveryId,
    syncUpdateReleaseFromNote,
    syncCreateReleaseFromNote,
    navigate,
  ]);

  const onCancel = useCallback(() => {
    if (release) {
      navigate(
        generatePath(routes.specifyDeliveryDetails, {
          deliveryId: release.id,
        }),
      );
    } else {
      reset();
      navigate(routes.deliveries);
    }
  }, [navigate, release, reset]);

  return (
    <FloatingFooterStyled>
      <ButtonContainer>
        <OrderApprovalsStepper />
        <OutlinedButton className="py-0" onClick={onCancel}>
          <FormattedMessage id="CANCEL" />
        </OutlinedButton>
        <PrimaryButton
          className="py-0"
          onClick={handleSaveReleaseFromNote}
          loading={isSaving && loading}
          disabled={!isSaving && loading}
        >
          <FormattedMessage id="FINALIZE_ORDER" />
        </PrimaryButton>
      </ButtonContainer>
      <SubmitOrderFromNoteDialog
        visible={visibleConfirmDialog}
        setVisible={setVisibleConfirmDialog}
        onSubmit={createAndSubmitOrder}
        onEdit={createAndEditOrder}
        retroactiveRelease={retroactiveRelease}
      />
      <ReceiveReleaseDialog />
    </FloatingFooterStyled>
  );
};
