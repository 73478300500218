import { useSourceSystemConfig } from "@/common/hooks/integrations/source-system-config/useSourceSystemConfig";
import { SourceSystem } from "@/generated/graphql";
import { useMemo } from "react";

export const useSourceSystemLogo = (
  sourceSystem?: SourceSystem | null,
  smallLogo?: boolean,
) => {
  const { getSourceSystemConfig: getConfig } = useSourceSystemConfig();

  const sourceSystemLogo = useMemo(() => {
    if (!sourceSystem) {
      return "";
    }

    const logos = getConfig(sourceSystem).logo;
    if (!logos) {
      return "";
    }
    return smallLogo ? logos.small : logos.normal;
  }, [sourceSystem, getConfig, smallLogo]);

  return { sourceSystemLogo };
};
