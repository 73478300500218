import { CircleCounter } from "@/common/components/circle-counter/CircleCounter";
import { CountType } from "@/common/components/circle-counter/CountType";
import { ProductName } from "@/common/components/product/ProductName";
import { RfqProductBrand } from "@/common/components/rfq-product-view/RfqProductBrand";
import { DistributorBuyoutItemFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import tw from "tailwind-styled-components";

type Props = {
  item: DistributorBuyoutItemFieldsFragment;
  count: CountType;
};

const OrderProductViewContainer = tw.div`
  flex w-full items-center overflow-hidden
`;

const NameAndBrandContainer = tw.div`
  mr-8 self-center text-sm flex-1 w-full text-overflow overflow-hidden whitespace-normal lg:whitespace-nowrap text-ellipsis
`;

const BrandContainer = tw.div`
  grid grid-cols-auto-auto justify-start items-center text-left
`;

const ProductNameStyled = tw(ProductName)`
  line-clamp-5
`;

const CircleCounterStyled = tw(CircleCounter)`
  mr-2
`;

const ProductBrandContainer = tw.div`font-bold`;

export const DistributorProductView: FC<Props> = ({ item, count }) => (
  <OrderProductViewContainer>
    <CircleCounterStyled count={count} />
    <NameAndBrandContainer>
      <BrandContainer>
        {item.manufacturer ? (
          <ProductBrandContainer>
            {item.manufacturer.name}
          </ProductBrandContainer>
        ) : (
          <RfqProductBrand item={item} />
        )}
      </BrandContainer>
      <ProductNameStyled name={item.description} readonlyClassName="text-sm" />
    </NameAndBrandContainer>
  </OrderProductViewContainer>
);
