import { UTC_OFFSET } from "@/common/const";
import { isSameDay, isSameMonth, isSameYear } from "date-fns";
import { useIntl } from "react-intl";
import { DateRangePickerValue } from "./types";

const useRangeLabelFormat = () => {
  const intl = useIntl();

  const generateLabel = ({ startDate, endDate }: DateRangePickerValue) => {
    if (!startDate && !endDate) {
      return "";
    }
    const utcStartDate = startDate
      ? new Date(startDate?.getTime() + UTC_OFFSET)
      : undefined;
    const utcEndDate = endDate
      ? new Date(endDate?.getTime() + UTC_OFFSET)
      : undefined;

    const sameDay =
      utcStartDate && utcEndDate && isSameDay(utcStartDate, utcEndDate);
    const sameMonth =
      utcStartDate && utcEndDate && isSameMonth(utcStartDate, utcEndDate);
    const sameYear =
      utcStartDate && utcEndDate && isSameYear(utcStartDate, utcEndDate);

    if (sameDay) {
      return intl.formatDate(utcStartDate, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    }

    if (sameYear) {
      if (sameMonth) {
        return `${intl.formatDate(utcStartDate, {
          month: "short",
        })} ${intl.formatDate(utcStartDate, {
          day: "numeric",
        })} - ${intl.formatDate(utcEndDate, {
          day: "numeric",
        })}, ${intl.formatDate(utcStartDate, { year: "numeric" })}`;
      } else {
        return `${intl.formatDate(utcStartDate, {
          month: "short",
        })} ${intl.formatDate(utcStartDate, {
          day: "numeric",
        })} - ${intl.formatDate(utcEndDate, {
          month: "short",
        })} ${intl.formatDate(utcEndDate, {
          day: "numeric",
        })}, ${intl.formatDate(utcStartDate, { year: "numeric" })}`;
      }
    } else {
      return `${intl.formatDate(utcStartDate, {
        day: "numeric",
        month: "short",
        year: "numeric",
      })} - ${intl.formatDate(utcEndDate, {
        day: "numeric",
        month: "short",
        year: "numeric",
      })}`;
    }
  };

  return { generateLabel };
};

export default useRangeLabelFormat;
