import { Chip } from "@mui/material";
import { FC } from "react";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { Tooltip } from "../tooltip/Tooltip";

type Props = {
  label?: string;
  className?: string;
  containerClassName?: string;
  tooltip?: React.ReactNode;
};

const ChipContainer = tw.div`
  text-left ml-2 inline w-18
`;

export const RfqProductLabel: FC<Props> = ({
  label,
  className,
  tooltip,
  containerClassName,
}) => (
  <If isTrue={label}>
    <ChipContainer className={containerClassName}>
      <Tooltip
        element={<Chip label={label} className={className} size="small" />}
        id="changed-status-popover"
      >
        {tooltip}
      </Tooltip>
    </ChipContainer>
  </If>
);
