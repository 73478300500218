import tw from "tailwind-styled-components";

export const Container = tw.div`flex flex-col gap-2 bg-gray-100 px-4 py-4 rounded-3xl relative`;
export const Title = tw.div`flex justify-between items-center font-medium text-sm`;
export const Content = tw.div`flex flex-col gap-4
    transition-all origin-bottom delay-1000

    ${({ $opened }: { $opened: boolean }) =>
      $opened
        ? "animate-[expand_0.2s_ease-in-out_forwards] overflow-auto"
        : "animate-[collapse_0.2s_ease-in-out_forwards] overflow-hidden hidden"}
`;
export const Label = tw.div`flex flex-row gap-2`;
export const Row = tw.div`flex flex-row justify-between items-center`;
export const BatchNumberAdornment = tw.div`w-7`;
