import {
  ApolloClient,
  ApolloProvider,
  from,
  fromPromise,
  NormalizedCacheObject,
  Operation,
  ServerError,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import { GraphQLBreadcrumb, SentryLink } from "apollo-link-sentry";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { FC, ReactNode, useEffect, useRef } from "react";
import { useApolloClientStore } from "../stores/useApolloClientStore";
import { useAuthentication } from "./AuthenticationProvider";
import { createApolloCache } from "./createApolloCache";

export const GraphqlProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const clientRef = useRef<ApolloClient<NormalizedCacheObject> | null>(null);
  const { getAuthenticationHeader } = useAuthentication();
  const { setClient } = useApolloClientStore();

  useEffect(() => {
    setClient(clientRef.current as ApolloClient<NormalizedCacheObject>);
  }, [setClient]);

  const authLink = setContext(async (operation, { headers, ...context }) => {
    const token = await getAuthenticationHeader();
    return {
      headers: {
        ...headers,
        ...token,
      },
      ...context,
    };
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if ((networkError as ServerError)?.statusCode === 401) {
        return fromPromise(
          getAuthenticationHeader({ force: true }).catch((error) => {
            // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
            console.error(error);
            return null;
          }),
        ).flatMap((authorization: Record<string, string> | null) => {
          const oldHeaders = operation.getContext().headers;

          operation.setContext({
            headers: {
              ...oldHeaders,
              ...authorization,
            },
          });
          // retry the request, returning the new observable
          return forward(operation);
        });
      }

      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          Sentry.withScope((scope) => {
            scope.setExtra("variables", JSON.stringify(operation.variables));
            scope.setExtra("operationName", operation.operationName);
            if (error.path) {
              // We can also add the path as breadcrumb
              scope.addBreadcrumb({
                category: "query-path",
                message: error.path.join(" > "),
                level: "debug",
              });
            }
            if (error.extensions?.requestId) {
              scope.setExtra("requestId", error.extensions.requestId);
            }
            if (error.extensions) {
              scope.setExtra("extensions", JSON.stringify(error.extensions));
            }
            Sentry.captureMessage(error.message);
          });
        });
      }

      // To retry on network errors, we recommend the RetryLink
      // instead of the onError link. This just logs the error.
      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
    },
  );

  if (!clientRef.current) {
    clientRef.current = new ApolloClient({
      uri: import.meta.env.VITE_APP_GRAPHQL_SERVER,
      cache: createApolloCache(),
      link: from([
        new SentryLink({
          attachBreadcrumbs: {
            includeVariables: false,
            transform: (
              breadcrumb: GraphQLBreadcrumb,
              operation: Operation,
            ) => {
              if (operation.operationName === "UpdateContractorRelease") {
                return {
                  ...breadcrumb,
                  data: { ...breadcrumb.data, variables: operation.variables },
                };
              }
              return breadcrumb;
            },
          },
        }),
        authLink,
        errorLink,
        createUploadLink({ uri: import.meta.env.VITE_APP_GRAPHQL_SERVER }),
      ]),
      connectToDevTools: !!import.meta.env
        .VITE_APP_GRAPHQL_CONNECT_APOLLO_DEV_TOOLS,
    });
  }

  if (!clientRef.current) {
    return null;
  }

  return <ApolloProvider client={clientRef.current}>{children}</ApolloProvider>;
};
