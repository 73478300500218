import { BuyoutPriceInput } from "@/common/components/buyout-price-input/BuyoutPriceInput";
import { BuyoutItemFieldsFragment, BuyoutStatus } from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutGrouped } from "../providers/BuyoutGroupedProvider";

type SheetsInputProps = {
  item: BuyoutItemFieldsFragment;
  readonly: boolean;
  index?: number;
};

export const ContractorBuyoutPriceInput: FC<SheetsInputProps> = ({
  item,
  readonly,
  index,
}) => {
  const { updateBuyout, buyout } = useContractorBuyout();
  const { newBuyoutItem, setNewBuyoutItem } = useBuyoutGrouped();
  const intl = useIntl();

  const updateValue = useCallback(
    async (value: string | null) => {
      if (newBuyoutItem.isAddMode) {
        setNewBuyoutItem({
          ...newBuyoutItem,
          requestedUnitPrice: value,
        });
      } else {
        await updateBuyout({
          updates: [{ buyoutItemId: item.id, requestedUnitPrice: value }],
        });
      }
    },
    [newBuyoutItem, setNewBuyoutItem, updateBuyout, item.id],
  );

  return (
    <BuyoutPriceInput
      index={index}
      price={item.unitPrice || "0"}
      uomDescription={item.projectItem?.estimateUom?.mnemonic}
      updateValue={updateValue}
      readonly={readonly}
      infoLabel={
        buyout?.status === BuyoutStatus.Draft
          ? intl.$t({ id: "PRICE_PER_UOM_DESCRIPTION" })
          : undefined
      }
    />
  );
};
