import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { PhoneInput } from "@/common/components/phone-input/PhoneInput";
import { Select } from "@/common/components/select/components/single/Select";
import { DEFAULT_ADDRESS_COUNTRY } from "@/common/const";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { useUser } from "@/common/providers/UserProvider";
import { countries } from "@/common/utils/countries";
import { strongify } from "@/common/utils/htmlUtils";
import { isDefaultCountry } from "@/common/utils/isDefaultCountry";
import { usaStates } from "@/common/utils/usaStates";
import {
  isEmptyString,
  isValidPostalCode,
} from "@/common/utils/validationUtils";
import { OrgLocationFieldsFragment } from "@/generated/graphql";
import { FormControl, Grid, TextField } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useLocations } from "../providers/LocationsProvider";

type NewLocationFormProps = {
  onClose: () => void;
  location?: OrgLocationFieldsFragment;
  label: string;
};

export const NewLocationForm: FC<NewLocationFormProps> = ({
  onClose,
  location,
  label,
}) => {
  const { createLocation, updateLocation, loading } = useLocations();
  const { viewer } = useUser();
  const { setSuccessAlert } = useSnackbar();

  const intl = useIntl();
  const [name, setName] = useState<string>(location?.name || "");
  const [addressLine1, setAddressLine1] = useState<string>(
    location?.address.addressLine1 || "",
  );
  const [addressLine2, setAddressLine2] = useState<string>(
    location?.address.addressLine2 || "",
  );
  const [city, setCity] = useState<string>(location?.address.city || "");
  const [country, setCountry] = useState<string | null>(
    location?.address.country || DEFAULT_ADDRESS_COUNTRY,
  );
  const [postalCode, setPostalCode] = useState<string>(
    location?.address.postalCode || "",
  );
  const [state, setState] = useState<string | null>(
    location?.address.state || "",
  );
  const [phone, setPhone] = useState<string>(location?.phone || "");

  const saveLocation = async () => {
    let result = null;
    const newLocation = {
      name: name || "",
      phone,
      orgId: viewer?.org.id || "",
      address: {
        addressLine1,
        addressLine2,
        city,
        state: state || "",
        country: country || "",
        postalCode,
      },
    };
    if (location?.id) {
      result = await updateLocation({ id: location.id, ...newLocation });
    } else {
      result = await createLocation(newLocation);
    }

    if (!result) {
      return;
    }
    setSuccessAlert(
      intl.$t(
        {
          id: location?.id
            ? "LOCATION_EDIT_SUCCESS"
            : "LOCATION_CREATE_SUCCESS",
        },
        { name: strongify(name) },
      ),
    );
    onClose();
  };

  const arePostalCodeAndStateRequired = useMemo(
    () => isDefaultCountry(country),
    [country],
  );

  const isAllRequiredFieldsPresented = () => {
    return (
      !isEmptyString(phone) &&
      !isEmptyString(city) &&
      !isEmptyString(addressLine1) &&
      !isEmptyString(name) &&
      ((!isEmptyString(postalCode) &&
        isValidPostalCode(postalCode) &&
        !isEmptyString(state)) ||
        !arePostalCodeAndStateRequired)
    );
  };

  return (
    <OverlayPanel
      title={label}
      onSave={saveLocation}
      onCancel={onClose}
      disableSave={!isAllRequiredFieldsPresented()}
      saving={loading}
    >
      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <FormControl fullWidth>
            <TextField
              size="small"
              label={intl.$t({ id: "LOCATION_NAME" })}
              required
              value={name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setName(event.target.value)
              }
            />
          </FormControl>
        </Grid>
        <Grid item container xs={12}>
          <FormControl fullWidth>
            <TextField
              size="small"
              label={intl.$t({ id: "LOCATION_ADDRESS_LINE_1" })}
              required
              value={addressLine1}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAddressLine1(event.target.value)
              }
            />
          </FormControl>
        </Grid>
        <Grid item container xs={12}>
          <FormControl fullWidth>
            <TextField
              size="small"
              label={intl.$t({ id: "LOCATION_ADDRESS_LINE_2" })}
              value={addressLine2}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAddressLine2(event.target.value)
              }
            />
          </FormControl>
        </Grid>
        <Grid item container xs={12}>
          <FormControl fullWidth>
            <TextField
              size="small"
              label={intl.$t({ id: "ADDRESS_CITY" })}
              required
              value={city}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCity(event.target.value)
              }
            />
          </FormControl>
        </Grid>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={6}>
            {arePostalCodeAndStateRequired ? (
              <Select
                placeholder={intl.$t({ id: "ADDRESS_STATE" })}
                options={usaStates}
                value={state}
                onChange={setState}
                getLabel={(option) => option.name}
                getValue={(option) => option.abbreviation}
                required
              />
            ) : (
              <TextField
                size="small"
                label={intl.$t({ id: "ADDRESS_STATE" })}
                value={state}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setState(event.target.value)
                }
                fullWidth
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label={intl.$t({ id: "ADDRESS_ZIP_CODE" })}
              value={postalCode}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setPostalCode(event.target.value)
              }
              fullWidth
              required={arePostalCodeAndStateRequired}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Select
              placeholder={intl.$t({ id: "ADDRESS_COUNTRY" })}
              options={countries}
              value={country}
              onChange={setCountry}
              getLabel={(option) => option.name}
              getValue={(option) => option.abbreviation}
              required
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <FormControl fullWidth>
            <PhoneInput
              size="small"
              label={intl.$t({ id: "LOCATION_CELL_PHONE" })}
              value={phone}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setPhone(event.target.value)
              }
              countryCode={country}
              required
            />
          </FormControl>
        </Grid>
      </Grid>
    </OverlayPanel>
  );
};
