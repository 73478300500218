import { routes } from "@/config/routes";
import { WithAdminNavigation } from "../Admin";
import { WithAdminOrgTabNavigation } from "./pages/common/WithAdminCodeOrgNavigation";
import { OrgNotificationsSettings } from "./pages/org-notifications-settings/OrgNotificationsSettings";
import { OrgProfile } from "./pages/org-profile/OrgProfile";
import { OrgSettings } from "./pages/org-settings/OrgSettings";

export const adminOrgRoutes = [
  {
    path: routes.orgProfile,
    element: (
      <WithAdminNavigation>
        <WithAdminOrgTabNavigation />
      </WithAdminNavigation>
    ),
    children: [
      {
        path: "",
        element: <OrgProfile />,
      },
      {
        path: routes.orgSettings,
        element: <OrgSettings />,
      },
      {
        path: routes.orgNotifications,
        element: <OrgNotificationsSettings />,
      },
    ],
  },
];
