import { CountType } from "@/common/components/circle-counter/CountType";
import { If } from "@/common/components/if/If";
import { LineItemNameNotes } from "@/common/components/line-item-name-notes/LineItemNameNotes";
import { MaterialView } from "@/common/components/material/MaterialView";
import {
  BuyoutItemFieldsFragment,
  DistributorBuyoutItemFieldsFragment,
} from "@/generated/graphql";
import { FC } from "react";
import { useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`
  flex w-full flex-col truncate
`;

type Props = {
  item: BuyoutItemFieldsFragment | DistributorBuyoutItemFieldsFragment;
  className?: string;
  readonly?: boolean;
  updateDescription?: (description: string) => void;
  count: CountType;
};

export const DistributorBuyoutItemMaterialView: FC<Props> = ({
  item,
  className,
  readonly,
  updateDescription,
  count = "",
}) => {
  const intl = useIntl();
  return (
    <Container>
      <MaterialView
        material={item.projectItem.material.material}
        description={item.description}
        className={className}
        readonly={readonly}
        count={count}
        updateDescription={updateDescription}
        manufacturer={item.manufacturer}
      />
      <LineItemNameNotes
        notes={item.instructions?.text}
        assets={item.instructions?.assets}
        label={intl.$t({ id: "CONTRACTOR_NOTE_LABEL" })}
      />
      <If isTrue={readonly}>
        <LineItemNameNotes
          notes={item.notes}
          assets={item.assets}
          label={intl.$t({ id: "YOUR_NOTE_LABEL" })}
        />
      </If>
    </Container>
  );
};
