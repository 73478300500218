import {
  COLUMN_TYPE,
  RendererFunctionType,
} from "@/common/providers/ColumnMapperProvider";

import { useUomOptions } from "@/common/hooks/useUomOptions";
import { textRenderer } from "handsontable/renderers";
import { ColumnSettings } from "handsontable/settings";
import { useCallback } from "react";
import { useRenderHelpers } from "./useRenderHelpers";

export const useValueWithUnitRenderer = () => {
  const { getUomByName } = useUomOptions();
  const { checkReadOnly } = useRenderHelpers();

  const renderer: RendererFunctionType = useCallback(
    (instance, td, row, col, prop, value, cellProperties) => {
      textRenderer(instance, td, row, col, prop, value, cellProperties);
      const rows = instance?.getData();
      const columns = instance?.getSettings().columns;
      const uomValueIndex = (columns as ColumnSettings[]).findIndex((c) => {
        return c.columnType === COLUMN_TYPE.UOM;
      });
      if (!uomValueIndex) {
        return;
      }
      const uom = getUomByName(rows[row][uomValueIndex]);
      if (uom && value && !Number.isNaN(Number(value))) {
        const unit = document.createElement("span");
        unit.className = "text-gray-600 font-light text-xs";
        unit.innerText = ` ${uom.mnemonic ?? uom.pluralDescription}`;
        td.appendChild(unit);
      }
      checkReadOnly(td, row, instance, cellProperties);
    },
    [checkReadOnly, getUomByName],
  );

  return renderer;
};
