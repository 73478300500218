import { EXTERNAL_PROJECT_ESSENTIAL_FIELDS } from "@/contractor/pages/home/project/fragments/externalProjectEssentialFragment";
import { gql } from "@apollo/client";
import { SOURCE_SYSTEM_MAPPING_FIELDS } from "../../contractor/pages/admin/org-settings/fragments/sourceSystemMappingFragment";
import { AGGREGATED_ZONE_FIELDS } from "./aggregatedZoneFragment";
import { INSTRUCTION_FIELDS } from "./instructionFragment";
import { PROJECT_FIELDS } from "./projectFragment";
import { ZONE_FIELDS } from "./zoneFragment";

export const PROJECT_EXTENDED_FIELDS = gql`
  ${PROJECT_FIELDS}
  ${INSTRUCTION_FIELDS}
  ${AGGREGATED_ZONE_FIELDS}
  ${SOURCE_SYSTEM_MAPPING_FIELDS}
  ${EXTERNAL_PROJECT_ESSENTIAL_FIELDS}
  ${ZONE_FIELDS}
  fragment ProjectExtendedFields on Project {
    ...ProjectFields
    releaseInstructions {
      ...InstructionFields
    }
    aggregatedZones(excludePhantoms: $excludePhantoms) {
      ...AggregatedZoneFields
    }
    mappings {
      ...SourceSystemMappingFields
    }
    externalProjects {
      ...ExternalProjectEssentialFields
    }
    zones {
      ...ZoneFields
      id
      externalSubJob {
        id
        sourceSystem
        externalId
      }
    }
  }
`;
