import { AddItemsButton } from "@/common/components/add-items-button/AddItemsButton";
import { If } from "@/common/components/if/If";
import { DEFAULT_AUXILIARY_ITEM_UOM_MNEMONIC } from "@/common/const";

import { useUomOptions } from "@/common/hooks/useUomOptions";
import { FC, useCallback } from "react";
import { useDistributorQuote } from "../../providers/DistributorQuoteProvider";

type Props = { readonly: boolean };

export const DistributorQuoteAuxiliaryItemsAddItems: FC<Props> = ({
  readonly,
}) => {
  const { uoms } = useUomOptions();
  const { updateQuote } = useDistributorQuote();

  const addNewAuxiliaryItem = useCallback(async () => {
    await updateQuote({
      addedAuxiliaryItems: [
        {
          description: "",
          uom: uoms.find(
            (o) => o.mnemonic === DEFAULT_AUXILIARY_ITEM_UOM_MNEMONIC,
          )?.pluralDescription,
        },
      ],
    });
  }, [uoms, updateQuote]);

  return (
    <If isTrue={!readonly}>
      <AddItemsButton onClick={addNewAuxiliaryItem} />
    </If>
  );
};
