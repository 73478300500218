import {
  PaginationArgs,
  usePagination,
} from "@/common/components/pagination/PaginationProvider";
import {
  ExternalProjectFieldsFragment,
  QueryExternalProjectsFilter,
  SourceSystem,
  useExternalProjectsQuery,
} from "@/generated/graphql";
import { ApolloError } from "@apollo/client";
import { useEffect } from "react";

type ReturnType = {
  externalProjects: ExternalProjectFieldsFragment[];
  loading: boolean;
  error?: ApolloError;
  totalCount: number;
  pagination: PaginationArgs;
  setPage: ({
    page,
    pagination,
    queryParams,
  }: {
    page: number;
    pagination?: PaginationArgs;
    queryParams?: Record<
      string,
      string | null | boolean | Array<unknown> | undefined
    >;
  }) => void;
};

export const useAgaveExternalProjectsWithPagination = (
  sourceSystem: SourceSystem | null,
  filter?: QueryExternalProjectsFilter,
): ReturnType => {
  const { paginationArgs, setPageInfo, setPage } = usePagination();
  const { data, loading, error } = useExternalProjectsQuery({
    variables: {
      ...paginationArgs,
      filter,
      sourceSystem: sourceSystem || SourceSystem.Foundation,
    },
    fetchPolicy: "network-only", // Used for first execution
    nextFetchPolicy: "cache-first",
    skip: !sourceSystem,
  });

  useEffect(() => {
    if (data?.externalProjects?.pageInfo) {
      setPageInfo({ ...data.externalProjects.pageInfo });
    }
  }, [data, setPageInfo]);

  return {
    externalProjects:
      data?.externalProjects.edges.map((edge) => edge.node) || [],
    pagination: paginationArgs,
    loading,
    error,
    totalCount: data?.externalProjects.totalCount || 0,
    setPage,
  };
};
