import { ZoneExtendedFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { useIntl } from "react-intl";

export const UNSPECIFIED_ZONE_ID = "-1";

export const useUnspecifiedZone = (includeZoneLabel = false) => {
  const intl = useIntl();
  const unassignedZone: ZoneExtendedFieldsFragment = useMemo(() => {
    return {
      id: UNSPECIFIED_ZONE_ID,
      name: intl.$t({
        id: !includeZoneLabel ? "UNSPECIFIED" : "PROJECT_UNSPECIFIED_ZONE",
      }),
      inUse: false,
    };
  }, [includeZoneLabel, intl]);

  return {
    unassignedZone,
  };
};
