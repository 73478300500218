import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { routes } from "@/config/routes";
import { QuoteStatus, RfqsRfqFieldsFragment } from "@/generated/graphql";
import { FC, useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { QuotesPrimaryButton } from "../../Rfqs.styles";

type QuotesReceivedProps = {
  rfq: RfqsRfqFieldsFragment;
};

const RECEIVED_STATUSES = [
  QuoteStatus.Accepted,
  QuoteStatus.Submitted,
  QuoteStatus.Rejected,
  QuoteStatus.CancelledByContractor,
];

export const QuotesReceived: FC<QuotesReceivedProps> = ({ rfq }) => {
  const navigate = useNavigate();

  const receivedQuotesCount = useMemo(() => {
    return rfq.quotes.filter((quote) =>
      RECEIVED_STATUSES.includes(quote.status),
    ).length;
  }, [rfq]);

  const goToBids = () => {
    navigate(generatePath(routes.bidsWithId, { id: rfq.id }));
  };

  return (
    <NotNullableRenderer value={receivedQuotesCount}>
      <QuotesPrimaryButton onClick={goToBids}>
        {receivedQuotesCount}
      </QuotesPrimaryButton>
    </NotNullableRenderer>
  );
};
