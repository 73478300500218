import {
  DEFAULT_CURRENCY,
  MAX_PRICE_DECIMALS,
  NUMERIC_DECIMAL_POINTS,
} from "@/common/const";
import { useUser } from "@/common/providers/UserProvider";
import { UomFieldsFragment } from "@/generated/graphql";
import Decimal from "decimal.js";
import { FC, useMemo } from "react";
import {
  FormatNumberOptions,
  FormattedMessage,
  FormattedNumber,
} from "react-intl";
import tw from "tailwind-styled-components";
import { If } from "../if/If";
import { Tooltip } from "../tooltip/Tooltip";

const Container = tw.div`
  flex flex-col justify-center items-center relative w-full text-xs
  ${(props: { $error?: boolean }) => props.$error && "text-red-500"}
`;

const Value = tw.div`
  text-center text-xs
`;

const FooterContainer = tw.div`
  text-[9px] w-full line-clamp-2 text-center relative
`;

const Footer = tw.div`
  text-gray-600 w-full
`;

type ValueCurrencyProps = {
  value: string | number;
  footer?: React.ReactNode;
  perUom?: UomFieldsFragment | null;
  error?: boolean;
  classNames?: { container?: string; value?: string; footer?: string };
  fractionDigits?: number;
  dynamicDigits?: boolean;
  mnemonicFirst?: boolean;
};

export type FormatCurrencyType = (
  value: Decimal | string | number | undefined | null,
  options?: FormatNumberOptions,
) => string;

export const ValueCurrency: FC<ValueCurrencyProps> = ({
  value,
  footer,
  perUom,
  error,
  classNames,
  fractionDigits = 2,
  dynamicDigits,
  mnemonicFirst = true,
}) => {
  const { currency } = useUser();
  const digits = useMemo(
    () =>
      !dynamicDigits
        ? fractionDigits
        : Number(value) > 10
          ? NUMERIC_DECIMAL_POINTS
          : MAX_PRICE_DECIMALS,
    [dynamicDigits, fractionDigits, value],
  );
  return (
    <Container $error={error} className={classNames?.container}>
      <Value className={classNames?.value}>
        <FormattedNumber
          currency={currency?.code || DEFAULT_CURRENCY}
          style={"currency"}
          currencyDisplay="narrowSymbol"
          value={Number(value)}
          maximumFractionDigits={digits}
        />
      </Value>
      <FooterContainer>
        <Footer className={classNames?.footer}>
          <If isTrue={perUom}>
            <Tooltip
              element={
                <Container $error={error}>
                  <FormattedMessage
                    id="PER_UOM"
                    values={{
                      uom: mnemonicFirst
                        ? perUom?.mnemonic || perUom?.pluralDescription
                        : perUom?.pluralDescription || perUom?.mnemonic,
                    }}
                  />
                </Container>
              }
              id={`per-oum-${perUom?.mnemonic}`}
            >
              <FormattedMessage
                id="PER_UOM"
                values={{
                  uom: perUom?.singularDescription || perUom?.pluralDescription,
                }}
              />
            </Tooltip>
          </If>
          {footer || null}
        </Footer>
      </FooterContainer>
    </Container>
  );
};
