import { DeleteButton } from "@/common/components/button/DeleteButton";
import { WizardModalMode } from "@/common/components/wizard-modal/WizardModal";
import { useIntegrationLogo } from "@/common/hooks/integrations/integration-type-config/useIntegrationLogo";
import { routes } from "@/config/routes";
import { useOrgSettingsExtended } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettingsExtended";
import { useUpdateOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useUpdateOrgSettings";
import {
  IntegrationSettingsExtendedFieldsFragment,
  IntegrationType,
} from "@/generated/graphql";
import { FC, ReactNode, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import {
  Actions,
  BaseContent,
  Group,
  Integration,
  IntegrationInner,
  IntegrationTypeContainer,
  Logo,
} from "../../Integrations.styles";
import { ItemGroup } from "../../common/ItemGroup";
import { DefaultLinkChunks } from "../../wizard/Wizard.styles";
import { useAccountingOnPremiseWizard } from "../../wizard/accounting-onpremise-wizard/AccountingOnPremiseWizardProvider";
import { TaxAuthoritiesList } from "../components/TaxAuthoritiesList";

const Content = tw(BaseContent)``;
const Image = tw.img`bg-white`;
type Props = {
  accountingSettings?: IntegrationSettingsExtendedFieldsFragment["accounting"][0];
};

export const Sage300OnPremiseIntegration: FC<Props> = ({
  accountingSettings,
}) => {
  const { orgId, connectedAccountingSystem } = useOrgSettingsExtended();
  const { updateOrgSettings } = useUpdateOrgSettings();
  const { openWizard } = useAccountingOnPremiseWizard();
  const intl = useIntl();

  const onDelete = useCallback(async () => {
    if (!connectedAccountingSystem) {
      return;
    }
    await updateOrgSettings({
      id: orgId,
      integrations: {
        accounting: {
          integration: connectedAccountingSystem.integration,
          enabled: false,
        },
      },
    });
  }, [connectedAccountingSystem, orgId, updateOrgSettings]);

  const handleOpenIntegrationStep = useCallback(
    (initialPageIndex: number) => {
      openWizard({
        mode: WizardModalMode.SINGLE_PAGE,
        initialPage: initialPageIndex,
        integrationType: IntegrationType.Sage300,
      });
    },
    [openWizard],
  );

  const { integrationLogo } = useIntegrationLogo(IntegrationType.Sage300);

  if (!accountingSettings) {
    return null;
  }

  return (
    <Integration>
      <IntegrationInner>
        <Logo>
          <Image loading="lazy" src={integrationLogo} alt="logo" />
          <IntegrationTypeContainer>
            <FormattedMessage id="INTEGRATION_TYPE_ON_PREMISE_DETAILS" />
          </IntegrationTypeContainer>
        </Logo>
        <Content>
          <Group>
            <ItemGroup
              values={[
                {
                  label: intl.$t({
                    id: "ACCOUNTING_MATERIAL_COST_TYPE",
                  }),
                  value: accountingSettings.materialCostType,
                },
              ]}
              label={intl.$t({ id: "ACCOUNTING_MATERIAL_COST_TYPE" })}
              fulfilled={!!accountingSettings.materialCostType}
              onEdit={() => handleOpenIntegrationStep(1)}
            />
            <ItemGroup
              label={intl.$t({ id: "TAX_AUTHORITIES" })}
              values={[
                {
                  value: (
                    <TaxAuthoritiesList
                      taxAuthorities={accountingSettings.taxAuthorities}
                    />
                  ),
                  emptyCondition: () =>
                    !accountingSettings.taxAuthorities?.length,
                },
              ]}
              fulfilled={!!accountingSettings.taxAuthorities?.length}
              onEdit={() => handleOpenIntegrationStep(3)}
            />
          </Group>
          <Group>
            <ItemGroup
              label={intl.$t({ id: "COST_CODES" })}
              values={[
                {
                  value: intl.$t(
                    {
                      id: "ENTER_COST_CODES_LINK",
                    },
                    {
                      sub: (chunks: ReactNode) => (
                        <DefaultLinkChunks
                          chunks={chunks}
                          route={routes.costStructure}
                        />
                      ),
                    },
                  ),
                },
              ]}
              iconColorClassName="text-gray-600"
            />
            <ItemGroup
              label={intl.$t(
                { id: "VENDORS_COUNT" },
                {
                  count: accountingSettings.progress.vendors.mapped,
                  total: accountingSettings.progress.vendors.total,
                },
              )}
              values={[
                {
                  value: intl.$t(
                    {
                      id: "ENTER_VENDORS_LINK",
                    },
                    {
                      sub: (chunks: ReactNode) => (
                        <DefaultLinkChunks
                          chunks={chunks}
                          route={routes.vendors}
                        />
                      ),
                    },
                  ),
                },
              ]}
              fulfilled={
                accountingSettings.progress.vendors.mapped ===
                accountingSettings.progress.vendors.total
              }
            />
            <ItemGroup
              label={intl.$t(
                { id: "PROJECTS_COUNT" },
                {
                  count: accountingSettings.progress.projects.mapped,
                  total: accountingSettings.progress.projects.total,
                },
              )}
              values={[
                {
                  value: intl.$t(
                    {
                      id: "ENTER_PROJECTS_LINK",
                    },
                    {
                      sub: (chunks: ReactNode) => (
                        <DefaultLinkChunks
                          chunks={chunks}
                          route={routes.projects}
                        />
                      ),
                    },
                  ),
                },
              ]}
              fulfilled={
                accountingSettings.progress.projects.mapped ===
                accountingSettings.progress.projects.total
              }
            />
            <ItemGroup
              label={intl.$t(
                { id: "MATERIALS_COUNT" },
                {
                  count: accountingSettings.progress.orgMaterials.mapped,
                  total: accountingSettings.progress.orgMaterials.total,
                },
              )}
              values={[
                {
                  value: intl.$t(
                    {
                      id: "ENTER_MATERIALS_LINK",
                    },
                    {
                      sub: (chunks: ReactNode) => (
                        <DefaultLinkChunks
                          chunks={chunks}
                          route={routes.itemDatabase}
                        />
                      ),
                    },
                  ),
                  details: intl.$t({ id: "INTEGRATIONS_REQUIRED_MATERIALS" }),
                },
              ]}
              iconColorClassName="text-gray-600"
              fulfilled={
                accountingSettings.progress.orgMaterials.mapped ===
                accountingSettings.progress.orgMaterials.total
              }
            />
          </Group>
        </Content>
        <Actions>
          <DeleteButton onClick={onDelete} iconClassName="w-8 h-8" />
        </Actions>
      </IntegrationInner>
    </Integration>
  );
};
