import { NotNullableRenderer } from "@/common/components/not-nullable-renderer/NotNullableRenderer";
import { Price } from "@/common/components/price/Price";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import {
  FooterCell,
  FooterCol,
  FooterDashedLine,
  FooterHeader,
  FooterHeaderText,
  FooterProps,
  FooterTotal,
} from "../../../../../../../common/components/grid-table/styles/Footer.styles";
import { useReleaseItemsZoneProvider } from "../../../providers/ReleaseItemsZonesProvider";

export const ReleaseExtPriceFooter: FC<FooterProps> = ({ Wrapper }) => {
  const {
    extPriceSubtotal,
    extPriceSalesTax,
    extPriceAdditionalCharges,
    noPrices,
  } = useReleaseItemsZoneProvider();

  return (
    <FooterCol>
      <Wrapper className="flex-col">
        <FooterHeader>
          <FormattedMessage id="CLIENT_ORDERED" tagName={FooterHeaderText} />
        </FooterHeader>
        <FooterCell>
          <NotNullableRenderer value={!noPrices}>
            <Price price={extPriceSubtotal} />
          </NotNullableRenderer>
        </FooterCell>
        <FooterCell>
          <NotNullableRenderer value={!noPrices}>
            <Price price={extPriceAdditionalCharges} />
          </NotNullableRenderer>
        </FooterCell>
        <FooterCell>
          <NotNullableRenderer value={!noPrices}>
            <Price price={extPriceSalesTax} />
          </NotNullableRenderer>
        </FooterCell>
        <FooterDashedLine />
        <FooterTotal>
          <NotNullableRenderer value={!noPrices}>
            <Price
              price={extPriceSubtotal
                .plus(extPriceAdditionalCharges)
                .plus(extPriceSalesTax)}
            />
          </NotNullableRenderer>
        </FooterTotal>
      </Wrapper>
    </FooterCol>
  );
};
