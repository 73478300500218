import { SelectionCheckbox } from "@/common/components/selection-checkbox/SelectionCheckbox";
import { DeliverySlipPartialFieldsFragment } from "@/generated/graphql";
import { FC, useCallback } from "react";
import tw from "tailwind-styled-components";
import { useDeliverySlipSequence } from "../../providers/DeliverySlipSequenceProvider";

const Container = tw.div`
  flex
`;

export const DeliverySlipsCheckbox: FC<{
  item: DeliverySlipPartialFieldsFragment;
}> = ({ item }) => {
  const { sequenceIds, setSelectedDeliverySlips, selectedDeliverySlips } =
    useDeliverySlipSequence();
  const handleChange = useCallback(
    (newChecked: boolean) => {
      setSelectedDeliverySlips(
        newChecked
          ? [...selectedDeliverySlips, item]
          : selectedDeliverySlips.filter((i) => i.id !== item.id),
      );
    },
    [item, selectedDeliverySlips, setSelectedDeliverySlips],
  );

  return (
    <Container>
      <SelectionCheckbox
        itemId={item.id}
        items={sequenceIds}
        setSelection={handleChange}
      />
    </Container>
  );
};
