import { useGlobalError } from "@/common/hooks/useGlobalError";
import { useUser } from "@/common/providers/UserProvider";
import {
  CreateOrgInput,
  EnterprisesDocument,
  OrgType,
  OrgsDocument,
  UpdateOrgInput,
  ViewerDocument,
  useCreateOrgMutation,
  useUpdateOrgMutation,
  useViewerOrgQuery,
} from "@/generated/graphql";
import { useMemo } from "react";

export const useOrgProfile = () => {
  const { isContractor } = useUser();
  const { data: viewerOrg } = useViewerOrgQuery({ skip: !isContractor });
  const [createOrgMutation, { loading: createOrgLoader }] =
    useCreateOrgMutation();
  const [updateOrgMutation, { loading: updateOrgLoader }] =
    useUpdateOrgMutation();
  const { setError } = useGlobalError();

  const loading = useMemo(
    () => createOrgLoader || updateOrgLoader,
    [createOrgLoader, updateOrgLoader],
  );

  const uploadImage = async (image: File | null) => {
    if (viewerOrg?.viewer?.org?.id && image) {
      try {
        const { errors } = await updateOrgMutation({
          variables: {
            input: {
              id: viewerOrg.viewer.org.id,
              photo: image,
            },
          },
          refetchQueries: [{ query: ViewerDocument }],
        });
        setError(errors);
        return !errors;
      } catch (errors) {
        setError(errors);
        return false;
      }
    }
  };

  const createOrg = async (input: CreateOrgInput) => {
    try {
      const { data, errors } = await createOrgMutation({
        variables: { input },
        refetchQueries: [
          {
            query: OrgsDocument,
            variables: { filter: { type: OrgType.Contractor } },
          },
          {
            query: ViewerDocument,
          },
          {
            query: EnterprisesDocument,
            variables: {
              excludedOrgIds: [],
              includeOrgPreferredVendors: false,
            },
          },
        ],
      });
      setError(errors);
      return data?.createOrg;
    } catch (error) {
      setError(error);
    }
  };

  const updateOrg = async (input: UpdateOrgInput) => {
    try {
      const { data, errors } = await updateOrgMutation({
        variables: { input },
        refetchQueries: [
          { query: ViewerDocument },
          {
            query: EnterprisesDocument,
            variables: {
              excludedOrgIds: [],
              includeOrgPreferredVendors: false,
            },
          },
        ],
      });
      setError(errors);
      return { id: data?.updateOrg.id, name: data?.updateOrg.name };
    } catch (error) {
      setError(error);
    }
  };

  const fullEmail = useMemo(
    () =>
      viewerOrg?.viewer?.org?.settings?.invoices
        ? `${viewerOrg?.viewer?.org?.settings?.invoices.localPart}@${viewerOrg?.viewer?.org?.settings?.invoices.domain}`
        : "",
    [viewerOrg?.viewer?.org?.settings?.invoices],
  );

  return {
    createOrg,
    updateOrg,
    logoImageUrl: viewerOrg?.viewer?.org?.logoImageUrl || "",
    domain: viewerOrg?.viewer?.org?.settings?.invoices.domain || "",
    emailInvoices: viewerOrg?.viewer?.org?.settings?.invoices.localPart || "",
    poNumbering: viewerOrg?.viewer?.org?.settings?.releases?.poNumbering,
    viewerOrg,
    fullEmail,
    uploadImage,
    loading,
  };
};
