import { GroupBySegment } from "@/common/components/group-by-segment/GroupBySegment";
import { FC } from "react";
import { useReleaseItemsZoneProvider } from "../../providers/ReleaseItemsZonesProvider";

export const GroupByCostCodeToggle: FC = () => {
  const { groupedByCostCode, setGroupedByCostCode } =
    useReleaseItemsZoneProvider();
  return (
    <GroupBySegment
      groupedByCostCode={groupedByCostCode}
      setGroupedByCostCode={setGroupedByCostCode}
    />
  );
};
