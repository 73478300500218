import { CostCodeSelector } from "@/common/components/cost-code-selector/CostCodeSelector";
import { If } from "@/common/components/if/If";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useReleaseActions } from "@/contractor/pages/home/release/providers/ReleaseActionsProvider";
import { FC, useCallback } from "react";

export const ReleaseCostCodeDropdown: FC<{
  releaseItemId: string;
  readonly?: boolean;
}> = ({ releaseItemId, readonly = false }) => {
  const { costCodes } = useCostCodes();
  const { newCostCodes, setNewCostCodes, assignCostCodeOptions } =
    useReleaseActions();

  const handleOnChange = useCallback(
    (value: string | null) => {
      if (value) {
        setNewCostCodes([
          ...(newCostCodes ?? []).filter(
            (update) => update.releaseItemId !== releaseItemId,
          ),
          {
            releaseItemId,
            costCodeId: value,
          },
        ]);
      }
    },
    [newCostCodes, releaseItemId, setNewCostCodes],
  );

  return (
    <If
      isTrue={
        assignCostCodeOptions.isEditable &&
        assignCostCodeOptions.categoryItems.find(
          (i) => i.id === releaseItemId,
        ) &&
        !readonly
      }
    >
      <CostCodeSelector costCodes={costCodes} onChange={handleOnChange} />
    </If>
  );
};
