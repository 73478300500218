import { BuyoutReleaseFieldsFragment, ServiceType } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`hidden lg:flex items-center flex-col`;
const DeliveryContainer = tw.div`font-bold`;
const DeliveryType = tw.div`font-light text-xs`;

type Props = {
  release: BuyoutReleaseFieldsFragment;
};

export const ReleaseDelivery: FC<Props> = ({ release }) => {
  return (
    <Container>
      <DeliveryContainer>
        <FormattedMessage id="DELIVERY" />
      </DeliveryContainer>
      <DeliveryType>
        {release.includeServices.includes(ServiceType.Delivery) ? (
          <FormattedMessage id="BY_VENDOR" />
        ) : (
          <FormattedMessage id="BY_CONTRACTOR" />
        )}
      </DeliveryType>
    </Container>
  );
};
