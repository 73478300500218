import { If } from "@/common/components/if/If";
import { PaginationProvider } from "@/common/components/pagination/PaginationProvider";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { NestedStepperProvider } from "@/common/components/stepper/NestedStepper";
import { DEFAULT_ITEMS_PER_PAGE } from "@/common/const";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import {
  TableViewProvider,
  VIEW_STATE,
  useTableView,
} from "@/common/providers/TableViewProvider";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { ImportExternalItemsModal } from "./components/import-external-items/ImportExternalItemsModal";
import { MaterialHeader } from "./components/list/MaterialHeader";
import { MaterialList } from "./components/list/MaterialList";
import { useMaterialsConfiguration } from "./components/list/MaterialList.configuration";
import { MaterialsFooter } from "./components/list/MaterialsFooter";
import { useMaterials } from "./hooks/useMaterials";
import { ImportExternalItemsProvider } from "./providers/ImportExternalItemsProvider";
import {
  MaterialListProvider,
  useMaterialList,
} from "./providers/MaterialListProvider";
import {
  SyncMaterialsProvider,
  useSyncMaterials,
} from "./providers/SyncMaterialsProvider";

const MaterialsListWithProvider = () => {
  const { materials, loading, totalCount, isFiltered } = useMaterialList();
  const { materials: allMaterials } = useMaterials();
  const intl = useIntl();
  const { spreadsheetConfig } = useMaterialsConfiguration();
  const { saving } = useSyncMaterials();
  const { tableView } = useTableView();

  return (
    <>
      <Helmet>
        <title>{intl.$t({ id: "MATERIALS" })}</title>
      </Helmet>
      <MaterialHeader />
      <If isTrue={tableView === VIEW_STATE.normal}>
        <MaterialList
          materials={materials}
          loading={loading}
          totalCount={totalCount}
          isFiltered={isFiltered}
        />
      </If>
      <If isTrue={tableView === VIEW_STATE.spreadsheet}>
        <SpreadSheetTable
          items={allMaterials}
          columns={spreadsheetConfig}
          saving={saving}
          height="calc(100vh - 320px)"
        />
      </If>
      <MaterialsFooter />
      <ImportExternalItemsModal />
    </>
  );
};

export const MaterialsList = () => {
  const { spreadsheetConfig } = useMaterialsConfiguration();
  return (
    <PaginationProvider itemsPerPage={DEFAULT_ITEMS_PER_PAGE}>
      <MaterialListProvider>
        <NestedStepperProvider>
          <ImportExternalItemsProvider>
            <ColumnMapperProvider config={spreadsheetConfig}>
              <TableViewProvider>
                <SyncMaterialsProvider>
                  <MaterialsListWithProvider />
                </SyncMaterialsProvider>
              </TableViewProvider>
            </ColumnMapperProvider>
          </ImportExternalItemsProvider>
        </NestedStepperProvider>
      </MaterialListProvider>
    </PaginationProvider>
  );
};
