import { LocalSearchableList } from "@/common/components/searchable-list/LocalSearchableList";
import { useAgaveHostedWizard } from "@/contractor/pages/admin/integrations/components/wizard/agave-wizard/AgaveHostedWizardProvider";
import { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  ExternalPOTypesProvider,
  ExternalPoType,
  useExternalPOTypes,
} from "../../providers/ExternalPOTypesProvider";
import { useExternalPoTypeConfiguration } from "./ExternalPoType.configuration";

type Props = {
  onChange?: (value?: ExternalPoType | null) => void;
};

const ExternalPoTypeModalWithProviders: FC<Props> = ({ onChange }) => {
  const intl = useIntl();
  const { externalPoTypes, loadingExternalPoTypes } = useExternalPOTypes();
  const { selectedPoType, setSelectedPoType } = useAgaveHostedWizard();
  const configuration = useExternalPoTypeConfiguration(
    selectedPoType ? [selectedPoType] : [],
  );
  useEffect(() => {
    if (selectedPoType && onChange) {
      onChange(externalPoTypes.find((i) => i.externalId === selectedPoType));
    }
  }, [selectedPoType, externalPoTypes, onChange]);

  return (
    <LocalSearchableList<ExternalPoType>
      tableConfiguration={configuration}
      items={externalPoTypes}
      loadingItems={loadingExternalPoTypes}
      searchBarTitle={intl.$t({
        id: "SELECT_GENERAL_LEDGER_ACCOUNT_NUMBER_TO_JOB",
      })}
      searchFilterFn={(item, searchText) =>
        item.name.toLowerCase().includes(searchText)
      }
      itemSelection={{
        type: "single",
        selectedItemId: selectedPoType,
        setSelectedItemId: setSelectedPoType,
        setSelectedItem: onChange,
      }}
    />
  );
};

export const ExternalPoTypeModal: FC<Props> = (props) => (
  <ExternalPOTypesProvider>
    <ExternalPoTypeModalWithProviders {...props} />
  </ExternalPOTypesProvider>
);
