import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { ControlledProps } from "@/common/components/textfield-controlled/TextFieldControlled";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";

const Container = tw.div`flex items-center justify-between`;
const Label = tw.div`font-medium`;
const SwitchContainer = tw.div`flex items-center gap-2`;

type Props = ControlledProps;

export const ProjectTaxExempt: FC<Props> = (props) => {
  const intl = useIntl();

  return (
    <Container>
      <Label>
        <FormattedMessage id="TAX_EXEMPT_QUESTION" />
      </Label>
      <SwitchContainer>
        <InfoTooltip message={intl.$t({ id: "TAX_EXEMPT_TOOLTIP" })} />
        <SwitchControlled
          {...props}
          onLabel={intl.$t({ id: "YES" })}
          offLabel={intl.$t({ id: "NO" })}
        />
      </SwitchContainer>
    </Container>
  );
};
