import { InlineNotesAndAttachments } from "@/common/components/inline-notes-and-attachments/InlineNotesAndAttachments";
import { UploadAssetProvider } from "@/common/components/upload-asset/UploadAssetProvider";
import {
  AssetContext,
  AssetFieldsFragment,
  BuyoutItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback } from "react";
import { useContractorBuyout } from "../../../providers/ContractorBuyoutProvider";
import { useBuyoutGrouped } from "../providers/BuyoutGroupedProvider";

type Props = {
  item: BuyoutItemFieldsFragment;
  readonly: boolean;
};

const NotesDetailsWithProvider: FC<Props> = ({ item, readonly }) => {
  const { setExpandedItems, expandedItems } = useContractorBuyout();
  const { newBuyoutItem, setNewBuyoutItem } = useBuyoutGrouped();
  const { updateBuyout } = useContractorBuyout();

  const closeItem = useCallback(
    () =>
      setExpandedItems(expandedItems.filter((id: string) => id !== item.id)),
    [expandedItems, item.id, setExpandedItems],
  );

  const handleOnSave = useCallback(
    async (notes: string, assets?: AssetFieldsFragment[]) => {
      if (newBuyoutItem.isAddMode) {
        setNewBuyoutItem({
          ...newBuyoutItem,
          instructions: {
            text: notes,
            assetUrls: assets?.map((a) => a.url) ?? [],
          },
        });
      } else {
        await updateBuyout(
          {
            updates: [
              {
                buyoutItemId: item.id,
                instructions: {
                  text: notes,
                  assetUrls: assets?.map((a) => a.url) ?? [],
                },
              },
            ],
          },
          true,
        );
      }

      closeItem();
    },
    [closeItem, item.id, newBuyoutItem, setNewBuyoutItem, updateBuyout],
  );

  return (
    <InlineNotesAndAttachments
      closeItem={closeItem}
      saveItem={handleOnSave}
      item={{
        ...item,
        notes: item.instructions?.text,
      }}
      readonly={readonly}
      labelKey="CONTRACTOR_NOTES"
    />
  );
};

export const BuyoutItemInstructionsDetails: FC<Props> = (props) => {
  const { buyout } = useContractorBuyout();

  return (
    <UploadAssetProvider
      initialAssets={props.item.instructions?.assets}
      context={AssetContext.Instruction}
      projectId={buyout?.project?.id ?? ""}
    >
      <NotesDetailsWithProvider {...props} />
    </UploadAssetProvider>
  );
};
