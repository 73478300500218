import { routes } from "@/config/routes";
import { ProjectBudget } from "./pages/project-budget/ProjectBudget";
import { ProjectDetails } from "./pages/project-details/ProjectDetails";
import { ProjectEstimatedItems } from "./pages/project-estimated-items/ProjectEstimatedItems";
import { ProjectMaterials } from "./pages/project-materials/ProjectMaterials";
import { SpendingReportReport } from "./pages/project-spending-report/ProjectSpendingReport";
import { ProjectTags } from "./pages/project-tags/ProjectTags";
import { ProjectZones } from "./pages/project-zones/ProjectZones";

export const projectRoutesConfig = {
  children: [
    {
      path: routes.projectEstimatedItems,
      element: <ProjectEstimatedItems />,
    },
    {
      path: routes.projectMaterials,
      element: <ProjectMaterials />,
    },
    {
      path: routes.projectDetails,
      element: <ProjectDetails />,
    },
    {
      path: routes.projectZones,
      element: <ProjectZones />,
    },
    {
      path: routes.projectTags,
      element: <ProjectTags />,
    },
    {
      path: routes.projectBudget,
      element: <ProjectBudget />,
    },
    {
      path: routes.projectBudgetReport,
      element: <SpendingReportReport />,
    },
  ],
};
