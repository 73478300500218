import { Loader } from "@/common/components/loader/Loader";
import { OverlayPanel } from "@/common/components/panel/OverlayPanel";
import { SearchOutlined } from "@mui/icons-material";
import tw from "tailwind-styled-components";

export const OverlayPanelStyled = tw(OverlayPanel)`px-0 pt-2 mb-0`;
export const Form = tw.form`h-full`;
export const TitleContainer = tw.div`flex gap-2 items-center`;
export const Container = tw.div`grid gap-4 h-fit p-5`;
export const GrayContainer = tw.div`flex flex-col flex-1 bg-gray-100 p-5 gap-3`;
export const Subtitle = tw.div`flex justify-between font-medium items-center`;
export const SearchIcon = tw(
  SearchOutlined,
)`text-blue-500 cursor-pointer focus:text-black hover:text-black -mr-1`;
export const LedgerNumber = tw.div`mr-1`;
export const ItemsContainer = tw.div`flex flex-row justify-between last-of-type:mt-2 mb-2`;
export const Items = tw.div``;
export const Item = tw.div`flex flex-row gap-1 first:mt-1`;
export const Label = tw.div<{ $error: boolean }>`
${({ $error }) => ($error ? "text-red-500" : "text-gray-500")}
`;
export const Value = tw.div`font-medium`;
export const LoaderStyled = tw(Loader)`h-4 justify-end w-4 -mr-1`;
