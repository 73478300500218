import { Facet, MasterSkusFacetFieldsFragment } from "@/generated/graphql";
import { NoFunction } from "@/types/NoFunction";
import { FC, createContext, useContext, useEffect, useState } from "react";
import { useCategories } from "../category-filter/CategoriesProvider";
import { usePagination } from "../pagination/PaginationProvider";
import { useMasterSkusProductsToggle } from "../skus-products-switch/MasterSkusProductsToggleProvider";

type SelectedFacet = {
  id: string;
  value: string;
};

type ProductFacet = Pick<Facet, "id" | "name">;

type ProviderContextType = {
  productFacets: ProductFacet[];
  productFacetsValues: MasterSkusFacetFieldsFragment[];
  selectedFacets: SelectedFacet[];
  toggleFacetValue: (facetId: string, value: string) => void;
  updateFacets: (facets: MasterSkusFacetFieldsFragment[]) => void;
};

const ProviderContext = createContext<ProviderContextType>({
  productFacets: [],
  productFacetsValues: [],
  selectedFacets: [],
  toggleFacetValue: NoFunction,
  updateFacets: NoFunction,
});

export const FacetsProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [selectedFacets, setSelectedFacets] = useState<SelectedFacet[]>([]);
  const [productFacets, setProductFacets] = useState<ProductFacet[]>([]);
  const [productFacetsValues, setProductFacetsValues] = useState<
    MasterSkusFacetFieldsFragment[]
  >([]);
  const { setPage } = usePagination();
  const { categoryId } = useCategories();
  const { showProductsToggle } = useMasterSkusProductsToggle();

  const updateFacets = (facets: MasterSkusFacetFieldsFragment[]) => {
    const updatedFacets = facets.map((facet) => ({
      id: facet.id,
      name: facet.name,
    }));
    if (JSON.stringify(updatedFacets) !== JSON.stringify(productFacets)) {
      setProductFacets(updatedFacets);
    }
    if (JSON.stringify(facets) !== JSON.stringify(productFacetsValues)) {
      setProductFacetsValues(facets);
    }
  };

  const toggleFacetValue = (facetId: string, value: string) => {
    const existingFacetIndex = selectedFacets.findIndex(
      (facet) => facet.id === facetId && facet.value === value,
    );
    if (existingFacetIndex === -1) {
      setSelectedFacets([...selectedFacets, { id: facetId, value }]);
    } else {
      selectedFacets.splice(existingFacetIndex, 1);
      setSelectedFacets([...selectedFacets]);
    }
    setPage({ page: 0 });
  };

  useEffect(() => {
    if (categoryId) {
      setSelectedFacets([]);
    }
  }, [categoryId]);

  useEffect(() => {
    setSelectedFacets([]);
  }, [showProductsToggle]);

  return (
    <ProviderContext.Provider
      value={{
        toggleFacetValue,
        productFacets,
        productFacetsValues,
        selectedFacets,
        updateFacets,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useFacets = (): ProviderContextType => useContext(ProviderContext);
