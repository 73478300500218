import { If } from "@/common/components/if/If";
import { RfqStatus } from "@/generated/graphql";
import React, { FC } from "react";
import tw from "tailwind-styled-components";
import {
  LEFT_COLUMN_WIDTH,
  MAX_COLUMN_WIDTH,
  MIN_COLUMN_WIDTH,
} from "../../../constants";
import { useRfqQuoteOptions } from "../../../providers/RfqQuoteOptionsProvider";
import { useRfqQuotes } from "../../../providers/RfqQuotesProvider";
import { BuyoutName } from "./BuyoutName";
import { QuoteInfoFields } from "./QuoteInfoFields";
import { QuoteInfoValues } from "./QuoteInfoValues";
import { TotalQuotePrice } from "./TotalQuotePrice";

const Container = tw.div`  
  sticky self-start z-40 border-t border-b border-dashed py-4 mt-2
  grid bg-white w-full rounded-3xl grid-flow-col
  grid-rows-[repeat(6,minmax(15px,_auto))] items-start
  pr-4 gap-2
`;

const TotalQuoteContainer = tw.div`
  w-full text-right sticky justify-self-star bg-white
`;

const WhiteCorner = tw.div`
  sticky left-0 justify-self-start bg-white z-50 w-full h-full
`;

const QuoteContainer = tw.div`sticky z-40`;

export const TableSummary: FC = () => {
  const { visibleQuotes } = useRfqQuoteOptions();
  const { rfq } = useRfqQuotes();

  return (
    <Container
      style={{
        gridTemplateColumns: `${LEFT_COLUMN_WIDTH}px repeat(${visibleQuotes.length}, minmax(${MIN_COLUMN_WIDTH}px, 1fr))`,
      }}
    >
      <QuoteInfoFields />
      <WhiteCorner />
      {visibleQuotes.map((quote, index) => (
        <React.Fragment key={index}>
          <QuoteInfoValues index={index + 1} key={index} quote={quote} />
          <QuoteContainer
            key={quote.id}
            style={{
              maxWidth: `${MAX_COLUMN_WIDTH}px`,
            }}
          >
            <TotalQuoteContainer>
              <TotalQuotePrice quote={quote} />
            </TotalQuoteContainer>
            <If
              isTrue={
                Boolean(quote.buyout) && rfq?.status === RfqStatus.Awarded
              }
            >
              <BuyoutName
                buyoutId={quote.buyout?.id}
                buyoutIdentifier={quote.buyout?.clientIdentifier}
              />
            </If>
          </QuoteContainer>
        </React.Fragment>
      ))}
    </Container>
  );
};
