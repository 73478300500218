import {
  COLUMN_TYPE,
  SpreadSheetConfig,
} from "@/common/providers/ColumnMapperProvider";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { InvoiceCreateReleaseFormValues } from "@/contractor/pages/home/invoices/pages/invoice-verification/components/matched-order/components/InvoiceVerificationForm";
import { useProjectCostCodes } from "@/contractor/pages/home/project/hooks/useProjectCostCodes";
import { useRelease } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

export const useReceiptUpdateReleaseSpreadsheetConfig =
  (): SpreadSheetConfig[] => {
    const { watch } = useFormContext<InvoiceCreateReleaseFormValues>();
    const projectId = watch("projectId");
    const { costCodes } = useProjectCostCodes(projectId);
    const { release } = useRelease();

    const intl = useIntl();
    return [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: (id) =>
          isLumpSumItem(release?.items.find((itm) => itm.id === id))
            ? "name"
            : "material",
        width: 150,
        columnType: COLUMN_TYPE.Material,
      },
      {
        header: intl.$t({ id: "MANUFACTURER" }),
        columnId: "manufacturer",
        columnType: COLUMN_TYPE.Manufacturer,
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "UOM",
        columnType: COLUMN_TYPE.UOM,
      },
      {
        header: intl.$t({ id: "QUANTITY" }),
        columnId: "quantityDecimal",
        columnType: COLUMN_TYPE.Quantity,
      },
      {
        header: intl.$t({ id: "UNIT_PRICE" }),
        columnId: "unitPrice",
        columnType: COLUMN_TYPE.UnitPrice,
        disabledForLumpSum: true,
      },
      {
        header: intl.$t({ id: "EXT_PRICE" }),
        columnId: "extPrice",
        width: 55,
        columnType: COLUMN_TYPE.ExtPrice,
      },
      {
        header: intl.$t({ id: "COST_CODE" }),
        columnId: "costCode",
        columnType: COLUMN_TYPE.CostCode,
        options: costCodes.map((c) => c.formatted),
        metadata: {
          projectId,
        },
      },
    ];
  };
