import { UomFieldsFragment } from "@/generated/graphql";
import { add, differenceInDays, isAfter, isBefore } from "date-fns";

export const isDayUOM = (uom: UomFieldsFragment | null | undefined) => {
  return uom?.mnemonic === "DY";
};

export const isWeekUOM = (uom: UomFieldsFragment | null | undefined) => {
  return uom?.mnemonic === "WK";
};

const isYearUOM = (uom: UomFieldsFragment | null | undefined) => {
  return uom?.mnemonic === "YR";
};

const isMonthUOM = (uom: UomFieldsFragment | null | undefined) => {
  return uom?.mnemonic === "MO";
};

export const isTimeUOM = (uom: UomFieldsFragment | null | undefined) => {
  return (
    uom?.mnemonic &&
    (isDayUOM(uom) || isWeekUOM(uom) || isMonthUOM(uom) || isYearUOM(uom))
  );
};

export const itemDeadline = ({
  releaseTime,
  item,
}: {
  releaseTime: number | null | undefined;
  item: {
    uom: UomFieldsFragment | null | undefined;
    quantityDecimal: string | null | undefined;
  };
}) => {
  if (!isTimeUOM(item?.uom) || !releaseTime) {
    return null;
  }

  return add(releaseTime, {
    days: isDayUOM(item?.uom) ? Number(item?.quantityDecimal) : 0,
    weeks: isWeekUOM(item?.uom) ? Number(item?.quantityDecimal) : 0,
    months: isMonthUOM(item?.uom) ? Number(item?.quantityDecimal) : 0,
    years: isYearUOM(item?.uom) ? Number(item?.quantityDecimal) : 0,
  });
};

export enum StatusMessageType {
  EXCEEDED,
  DAYS_UNTIL_START,
  DAYS_LEFT,
}

export const statusMessage = ({
  now,
  deadline,
  releaseTime,
}: {
  now: Date;
  deadline: Date;
  releaseTime: number;
}): { type: StatusMessageType; days: number } | null => {
  if (isAfter(now, deadline)) {
    return {
      type: StatusMessageType.EXCEEDED,
      days: differenceInDays(now, deadline),
    };
  }

  if (isBefore(now, releaseTime)) {
    return {
      type: StatusMessageType.DAYS_UNTIL_START,
      days: differenceInDays(releaseTime, now),
    };
  }

  if (isAfter(now, releaseTime) && isBefore(now, deadline)) {
    return {
      type: StatusMessageType.DAYS_LEFT,
      days: differenceInDays(deadline, now),
    };
  }

  return null;
};
