import { If } from "@/common/components/if/If";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { GroupByZonesSwitch } from "@/contractor/pages/home/project/components/project-items-filters/GroupByZonesSwitch";
import { ProjectCostCodesSelector } from "@/contractor/pages/home/project/components/project-items-filters/ProjectCostCodesSelector";
import { ProjectTagsSelector } from "@/contractor/pages/home/project/components/project-items-filters/ProjectTagsSelector";
import { ZonesSelector } from "@/contractor/pages/home/project/components/project-items-filters/ZonesSelector";
import { useProjectItemsZones } from "@/contractor/pages/home/project/providers/ProjectItemsZonesProvider";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { UNSPECIFIED_ZONE_ID } from "../../../../../common/hooks/useUnspecifiedZone";
import { useProjectZoneUtils } from "../../project/hooks/useProjectZoneUtils";
import { useReleaseProject } from "../pages/add-items-to-release/release-items-list/ReleaseProjectProvider";

const Container = tw.div`
  grid grid-flow-col lg:grid-cols-5 justify-end gap-1 bg-green-300 p-2 rounded-lg mb-1 place-items-center
`;

const InputContainer = tw.div`
  w-full
`;

const SwitchContainer = tw.div`
  flex
`;
const LeftSpace = tw.div`lg:w-auto w-0`;

export const ReleaseFilters: FC = () => {
  const { groupedByZones, zones } = useProjectItemsZones();
  const { buyout } = useContractorBuyout();
  const { project: releaseProject } = useReleaseProject();

  const project = releaseProject;
  const items = useMemo(() => {
    if (buyout) {
      return buyout.items.map((item) => ({ ...item.projectItem })) ?? [];
    } else if (project) {
      return project.items.map((item) => ({ ...item })) ?? [];
    } else {
      return [];
    }
  }, [buyout, project]);

  const hasZones = useMemo(() => {
    if (!items) {
      return false;
    }
    return (
      zones.some((z) => z.id !== UNSPECIFIED_ZONE_ID) ||
      items.some((item) => item.estimatedItems?.some((e) => e.zone)) ||
      (project?.zones?.length && project?.zones?.length > 0)
    );
  }, [items, project?.zones?.length, zones]);

  const { zoneOptions, costCodeOptions, tagOptions } =
    useProjectZoneUtils(project);

  return (
    <Container>
      <LeftSpace />
      <If isTrue={hasZones}>
        <SwitchContainer>
          <GroupByZonesSwitch />
        </SwitchContainer>
        <If isTrue={groupedByZones}>
          <InputContainer>
            <ZonesSelector options={zoneOptions} />
          </InputContainer>
        </If>
      </If>
      <InputContainer>
        <ProjectCostCodesSelector options={costCodeOptions} />
      </InputContainer>
      <InputContainer>
        <ProjectTagsSelector options={tagOptions} />
      </InputContainer>
    </Container>
  );
};
