import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { If } from "@/common/components/if/If";
import { InfoTooltip } from "@/common/components/info-tooltip/InfoTooltip";
import { NumericalInputControlled } from "@/common/components/numerical-input/NumericalInputControlled";
import { SwitchControlled } from "@/common/components/switch/SwitchControlled";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { OrgLocationRole } from "@/generated/graphql";
import { Fragment, useCallback, useEffect } from "react";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useOrgSettings } from "../../../org-settings/hooks/useOrgSettings";
import { useInvoiceApprovalActions } from "./hooks/useInvoiceApprovalActions";

type StyleProps = {
  $disabled?: boolean;
};

const Container = tw.div`grid w-full gap-8 items-start pt-20`;
const OptionLabel = tw.div``;

const Group = tw.div`grid grid-cols-[1fr_2fr]`;
const List = tw.div`grid grid-cols bg-white py-10 pl-18 rounded-l-lg`;
const GrayList = tw.div`grid grid-cols-3 w-full bg-gray-200 py-10 pr-18 rounded-r-lg`;
const HeaderItem = tw.div<StyleProps>`w-full grid grid-flow-col gap-1 justify-center text-left pb-4 mb-4 font-medium border-b border-b-black
    ${(props) => (props.$disabled ? "opacity-50" : "")}
  `;
const RowItem = tw.div<StyleProps>`w-full grid justify-center text-left border-b border-b-gray-300 py-3 items-center`;

const ButtonContainer = tw.div`w-full grid grid-flow-col justify-end gap-4 col-span-full`;

type OrgLocationRoleType = {
  [key in OrgLocationRole]: {
    canApprove: boolean;
    maxAmount: string | null | undefined;
    notificationThreshold: string | null | undefined;
  };
};

type FormType = {
  approvers: OrgLocationRoleType;
};

const SETTINGS = [
  {
    role: OrgLocationRole.LocationAccountant,
  },
  {
    role: OrgLocationRole.LocationAdmin,
  },
  {
    role: OrgLocationRole.LocationEstimator,
  },
  {
    role: OrgLocationRole.LocationForeman,
    canApprove: false,
  },
  {
    role: OrgLocationRole.LocationProjectManager,
  },
  {
    role: OrgLocationRole.LocationPurchasingAgent,
  },
  {
    role: OrgLocationRole.LocationSuperintendent,
  },
  {
    role: OrgLocationRole.LocationProjectEngineer,
  },
];

export const InvoiceApproval = () => {
  const intl = useIntl();
  const { orgId, settings } = useOrgSettings();
  const { updating, updateInvoiceApproval } = useInvoiceApprovalActions();
  const { setSuccessAlert } = useSnackbar();

  const methods = useForm<FormType>({
    defaultValues: {
      approvers: {},
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const resetForm = useCallback(() => {
    methods.reset({
      approvers: Object.keys(OrgLocationRole).reduce(
        (acc: OrgLocationRoleType, key: string) => {
          const roleKey = key as keyof typeof OrgLocationRole;
          const configuration = settings?.invoices.approval?.roles.find(
            (approvalRole) => approvalRole.role === OrgLocationRole[roleKey],
          );
          acc[OrgLocationRole[roleKey]] = {
            canApprove: !!configuration,
            maxAmount: configuration?.maxAmount,
            notificationThreshold: configuration?.notificationThreshold,
          };
          return acc;
        },
        {} as OrgLocationRoleType,
      ),
    });
  }, [methods, settings?.invoices.approval?.roles]);

  useEffect(() => {
    if (settings) {
      resetForm();
    }
  }, [settings, resetForm]);

  const onSubmit = async (data: FormType) => {
    const roles = Object.keys(data.approvers)
      .filter((key) => {
        return data.approvers[key as OrgLocationRole].canApprove;
      })
      .map((key) => {
        const role = key as OrgLocationRole;
        return {
          role,
          maxAmount: data.approvers[role].maxAmount?.toString(),
          notificationThreshold:
            data.approvers[role].notificationThreshold?.toString(),
        };
      });

    const result = await updateInvoiceApproval({
      id: orgId,
      invoices: {
        approval: {
          roles,
        },
      },
    });

    if (result) {
      setSuccessAlert(intl.$t({ id: "SETTINGS_SUCCESSFULLY_UPDATED" }));
      methods.reset(data, { keepValues: true });
    }
  };

  const { isDirty } = useFormState({ control: methods.control });

  return (
    <FormProvider {...methods}>
      <Container>
        <Group>
          <List>
            <HeaderItem className="justify-start pb-0">
              <FormattedMessage id="ROLE" />
            </HeaderItem>
            {SETTINGS.map((setting, index) => (
              <Fragment key={index}>
                <RowItem className="justify-start">
                  <FormattedMessage
                    id={`USER_ROLE_${setting.role}`}
                    tagName={OptionLabel}
                  />
                </RowItem>
              </Fragment>
            ))}
          </List>
          <GrayList>
            <HeaderItem>
              <FormattedMessage id="CAN_APPROVE_INVOICES" />
            </HeaderItem>
            <HeaderItem>
              <FormattedMessage id="THRESHOLD_APPROVAL_NOTIFICATION" />
              <InfoTooltip
                message={intl.$t({
                  id: "THRESHOLD_APPROVAL_NOTIFICATION_TOOLTIP",
                })}
              />
            </HeaderItem>
            <HeaderItem>
              <FormattedMessage id="MAX_AMOUNT_FOR_APPROVAL" />
              <InfoTooltip
                message={intl.$t({ id: "MAX_AMOUNT_FOR_APPROVAL_TOOLTIP" })}
              />
            </HeaderItem>
            {SETTINGS.map((setting, index) => (
              <Fragment key={index}>
                <RowItem
                  className="py-5"
                  $disabled={
                    setting.canApprove !== undefined && !setting.canApprove
                  }
                >
                  <SwitchControlled
                    name={`approvers.${setting.role}.canApprove`}
                    onLabel={intl.$t({ id: "YES" })}
                    offLabel={intl.$t({ id: "NO" })}
                    disabled={
                      setting.canApprove !== undefined && !setting.canApprove
                    }
                  />
                </RowItem>
                <RowItem>
                  <If
                    isTrue={methods.watch(
                      `approvers.${setting.role}.canApprove`,
                    )}
                  >
                    <NumericalInputControlled
                      includeCurrency
                      className="h-[30px] rounded-md bg-white"
                      inputProps={{
                        className: "text-center py-1",
                      }}
                      name={`approvers.${setting.role}.notificationThreshold`}
                      size="small"
                      emptyValueFiller="--"
                    />
                  </If>
                </RowItem>
                <RowItem>
                  <If
                    isTrue={methods.watch(
                      `approvers.${setting.role}.canApprove`,
                    )}
                  >
                    <NumericalInputControlled
                      includeCurrency
                      className="h-[30px] rounded-md bg-white"
                      inputProps={{
                        className: "text-center py-1",
                      }}
                      InputLabelProps={{
                        className: "text-xs bg-white",
                      }}
                      name={`approvers.${setting.role}.maxAmount`}
                      size="small"
                      emptyValueFiller="--"
                    />
                  </If>
                </RowItem>
              </Fragment>
            ))}
          </GrayList>
          <List />
          <GrayList />
          <If isTrue={isDirty}>
            <FloatingFooter>
              <ButtonContainer>
                <OutlinedButton wide onClick={resetForm}>
                  <FormattedMessage id="CANCEL" />
                </OutlinedButton>
                <PrimaryButton
                  wide
                  loading={updating}
                  onClick={methods.handleSubmit(onSubmit)}
                  disabled={!methods.formState.isValid || updating}
                >
                  <FormattedMessage id="SAVE" />
                </PrimaryButton>
              </ButtonContainer>
            </FloatingFooter>
          </If>
        </Group>
      </Container>
    </FormProvider>
  );
};
