export type ProcessedData = {
  [key: string]: number | string;
};

import { useCallback } from "react";

export const useDataProcessor = () => {
  const process = useCallback((rawData: string) => {
    const tables = rawData
      .split("\n")
      .filter((table) => table)
      .map((table) =>
        table.replace(/"([^"]*)"/g, (match) =>
          match.replace(/,/g, "").slice(1, -1).trim(),
        ),
      );
    const headers = tables[1]
      .split(",")
      .slice(1)
      .map((header) => header.toLocaleLowerCase());
    const result = [] as ProcessedData[];
    tables.slice(2).forEach((table) => {
      const row = table.split(",");
      const name = row[0];
      const value = row.slice(1).reduce(
        (acc, item, index) => {
          if (acc[headers[index]]) {
            acc[headers[index] + "Incomplete"] = Number(item);
          } else {
            acc[headers[index]] = Number(item);
          }
          return acc;
        },
        {} as { [key: string]: number },
      );
      result.push({ name, ...value });
    });
    return result;
  }, []);

  return {
    process,
  };
};
