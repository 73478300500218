import { Switch } from "@/common/components/switch/Switch";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import tw from "tailwind-styled-components";
import { useRelease } from "../../../providers/ReleaseProvider";
import { useReleaseUpdate } from "../../../providers/ReleaseUpdateProvider";
type Props = {
  className?: string;
};

const Container = tw.div`flex gap-2 items-center text-base border-r border-dashed border-blue-800 px-4`;
const PostedContainer = tw.div`text-sm w-30 text-right`;

export const RequestInventoryReceiptToggle: FC<Props> = ({ className }) => {
  const intl = useIntl();
  const { release } = useRelease();
  const { updateRelease } = useReleaseUpdate();
  const { settings } = useOrgSettings();

  const isFoundationConnected =
    settings?.integrations.sourceSystems.some(
      (system) => system.connected && system.postInventoryReceipts,
    ) ||
    settings?.integrations.accounting.some(
      (accounting) => accounting.enabled && accounting.postInventoryReceipts,
    );

  const setRequestInventoryReceipt = useCallback(
    async (value: boolean) => {
      if (release?.id) {
        await updateRelease({
          releaseId: release?.id,
          requiresInventoryReceipt: value,
          version: release?.version,
          skipConfirmation: true,
        });
      }
    },
    [release?.id, release?.version, updateRelease],
  );

  if (!isFoundationConnected) {
    return null;
  }

  return (
    <Container className={className}>
      <PostedContainer>
        <FormattedMessage id="REQUEST_INVENTORY_RECEIPT" />
      </PostedContainer>
      <Switch
        onLabel={intl.$t({ id: "YES" })}
        offLabel={intl.$t({ id: "NO" })}
        value={!!release?.requiresInventoryReceipt}
        onChange={setRequestInventoryReceipt}
      />
    </Container>
  );
};
