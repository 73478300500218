import { useErrorEffect } from "@/common/hooks/useErrorEffect";
import { useInvoiceStatsQuery } from "@/generated/graphql";
import { useInvoiceEmails } from "../../email-invoices/hooks/useInvoiceEmails";

export const useInvoiceStats = () => {
  const { canSeeInbox } = useInvoiceEmails({ skipQuery: true });
  const { data, error, loading, refetch } = useInvoiceStatsQuery({
    skip: !canSeeInbox,
  });

  useErrorEffect(error);

  return {
    loading,
    invoiceStats: data?.invoiceStats,
    refetch,
  };
};
