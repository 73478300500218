import { FC } from "react";
import { Category } from "./CategoriesProvider";
import { CategoryMenuItem } from "./CategoryMenuItem";
import { NestedCategoryMenuItem } from "./NestedCategoryMenuItem";

type Props = {
  category: Category;
  onCategorySelect: (id: string) => void;
  selectedId?: string | null;
};

export const CategoryItem: FC<Props> = ({
  category,
  onCategorySelect,
  selectedId,
}) => {
  const Element =
    category.children.length === 0 ? CategoryMenuItem : NestedCategoryMenuItem;

  return (
    <Element
      category={category}
      onCategorySelect={onCategorySelect}
      selectedId={selectedId}
    />
  );
};
