import { Price } from "@/common/components/price/Price";
import { AdditionalChargesFieldsFragment } from "@/generated/graphql";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

type Props = {
  additionalCharges: AdditionalChargesFieldsFragment[];
};

const Container = tw.div`
  grid
`;

const Charge = tw.div`
  py-0.5
`;

export const AdditionalCharges: FC<Props> = ({ additionalCharges }) => {
  return (
    <Container>
      {additionalCharges.length > 0 ? (
        additionalCharges.map((charge) => {
          return (
            <Charge key={charge.id}>
              {charge.description}: <Price price={Number(charge.amount)} />
            </Charge>
          );
        })
      ) : (
        <FormattedMessage id="NONE" />
      )}
    </Container>
  );
};
