import tw from "tailwind-styled-components";

const BaseContainer = tw.div`flex flex-col w-full`;
export const PageContainer = tw.div``;
export const Item = tw.div`flex text-sm min-h-12 items-center space-y-1 `;
export const VendorDetailsList = tw.div`flex flex-col`;
export const VendorDetailsListItem = tw.div`flex flex-row items-center justify-start text-sm h-12`;
export const Container = tw(BaseContainer)`h-full`;
export const PreferredVendorDetailsContainer = tw(BaseContainer)`px-6`;
export const Contact = tw(
  BaseContainer,
)`pb-4 my-2 border-b border-black gap-4 border-dashed`;
export const LineItem = tw.div`w-full`;
export const LineItemSplit = tw.div`grid grid-cols-[_0.5fr_2fr] gap-3 w-full`;
export const Notification = tw.div`grid gap-2 pl-6 grid-cols-[_1fr_20px_auto] w-full pr-3
${({ $error }: { $error?: boolean }) => $error && "text-red-500"};
}`;
export const ErrorContainer = tw.div``;
export const LineItemLabel = tw.div`font-medium`;
export const VendorContactTitle = tw.div`flex justify-between items-center h-10`;
export const AddNewContainer = tw.div`py-4`;
export const PopoverText = tw.span`text-justify`;

export const ListItem = tw.div`
  flex flex-row justify-start w-full items-center p-2 
  ${({ $isAdd }: { $isAdd: boolean }) => ($isAdd ? "text-blue-500" : "")}
`;
