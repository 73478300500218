import { StyledNumericInput } from "@/common/components/styled-numeric-input/StyledNumericInput";
import { MAX_QUANTITY_DECIMALS } from "@/common/const";
import { useSnackbar } from "@/common/providers/SnackbarProvider";
import { UomFieldsFragment } from "@/generated/graphql";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

type Props = {
  quantity: string | null | undefined;
  onChange: (value: string | null | undefined) => void;
  uom?: UomFieldsFragment | null;
  readonly: boolean;
  index?: number;
  error?: boolean;
  errorMessage?: string;
  hideErrorIcon?: boolean;
  resetMessageIfEmpty?: string;
};

export const UnitInput: FC<Props> = ({
  quantity: initialQuantity,
  readonly,
  index,
  error,
  onChange,
  uom,
  hideErrorIcon,
  resetMessageIfEmpty,
}) => {
  const [quantity, setQuantity] = useState(initialQuantity);
  const { setWarningAlert } = useSnackbar();

  useEffect(() => {
    setQuantity(initialQuantity);
  }, [initialQuantity]);

  const updateQuantity = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const quantityValue = event.target.value;
      setQuantity(quantityValue);
    },
    [],
  );

  const onBlur = useCallback(() => {
    if (quantity === initialQuantity) {
      return;
    }
    if (resetMessageIfEmpty && !quantity) {
      setQuantity(initialQuantity);
      setWarningAlert(resetMessageIfEmpty);
    } else {
      onChange(quantity);
    }
  }, [
    initialQuantity,
    onChange,
    quantity,
    resetMessageIfEmpty,
    setWarningAlert,
  ]);

  const hasError = useMemo(() => {
    return !initialQuantity;
  }, [initialQuantity]);

  return (
    <StyledNumericInput
      className="bg-white"
      value={quantity}
      label={uom?.mnemonic}
      onBlur={onBlur}
      onChange={updateQuantity}
      error={hasError && error}
      hideErrorIcon={hideErrorIcon}
      readonly={false}
      id={`units-input-${index}`}
      hoverLabel={uom?.pluralDescription}
      data-testid={`units-input-${index}`}
      staticText={readonly}
      shrink
      decimals={MAX_QUANTITY_DECIMALS}
    />
  );
};
