import { GridTable } from "@/common/components/grid-table/GridTable";
import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { UNSPECIFIED_ZONE_ID } from "@/common/hooks/useUnspecifiedZone";
import { ZoneItemsContainer } from "@/contractor/pages/home/project/Project.styles";
import { useEstimatedItems } from "@/contractor/pages/home/project/providers/EstimatedItemsProvider";
import { useProjectItemsZones } from "@/contractor/pages/home/project/providers/ProjectItemsZonesProvider";
import {
  EstimatedItemFieldsFragment,
  ProjectItemExtendedFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useCallback } from "react";

type Props = {
  columns: Array<
    GridCol<
      ProjectItemFieldsFragment | ProjectItemExtendedFieldsFragment,
      EstimatedItemFieldsFragment
    >
  >;
  loading?: boolean;
};

export const AddEstimatedItemListItems: FC<Props> = ({ columns, loading }) => {
  const { zones, groupedByZones, toggleZone, toggleCostCode } =
    useProjectItemsZones();
  const { expandedItems } = useEstimatedItems();

  const getExpandedItems = useCallback(
    (item: ProjectItemFieldsFragment) => {
      const estimatedItems = item.estimatedItems;
      if (
        expandedItems.some(
          (i) =>
            i.id === item.id &&
            (!i.zoneId ||
              i.zoneId === UNSPECIFIED_ZONE_ID ||
              estimatedItems.find((e) => e.zone?.id === i.zoneId)),
        ) &&
        estimatedItems.length > 1
      ) {
        return estimatedItems.map(() => item);
      }

      return [];
    },
    [expandedItems],
  );

  return (
    <GridTable
      configuration={{
        container: ZoneItemsContainer,
        columns,
        classNames: {
          container: "flex-1 overflow-scroll",
          header: "top-0",
          category: "top-10 lg:top-[38px]",
          subCategory: !groupedByZones ? "top-[38px]" : "top-[72px]",
        },
        toggle: {
          category: toggleZone,
          subCategory: toggleCostCode,
        },
      }}
      items={zones}
      readonly={true}
      hideGroup={!groupedByZones}
      expandedItems={getExpandedItems}
      loading={loading}
    />
  );
};
