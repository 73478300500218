import { AddToRfqItemInput } from "@/generated/graphql";
import { NoFunction, NoFunctionBooleanPromise } from "@/types/NoFunction";
import Decimal from "decimal.js";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useRfqMutations } from "../../hooks/useRfqMutations";

export type AddToRfqItem = AddToRfqItemInput & {
  itemId: string;
  estimatedItemIds: string[];
  isIncluded: boolean;
};

type ContextType = {
  addToRfq: () => Promise<boolean>;
  updates: AddToRfqItem[];
  setUpdates: (updates: AddToRfqItem[]) => void;
  isUpdating: boolean;
};

const ProjectItemContext = createContext<ContextType>({
  updates: [],
  setUpdates: NoFunction,
  addToRfq: NoFunctionBooleanPromise,
  isUpdating: false,
});

export const RfqProjectItemsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { id } = useParams();
  const [bulkUpdates, setBulkUpdates] = useState<AddToRfqItem[]>([]);
  const { updateRfq, isUpdating } = useRfqMutations();
  const setUpdateForBulkUpdate = (updates: AddToRfqItem[]) => {
    const updatedBulkUpdates = bulkUpdates.map((bu) => {
      const newItem =
        updates.find(
          (u) =>
            u.itemId === bu.itemId &&
            u.estimatedItemIds.some((ei) => bu.estimatedItemIds.includes(ei)),
        ) || ({} as AddToRfqItem);
      return {
        ...bu,
        ...newItem,
        estimatedItemIds: newItem.estimatedItemIds
          ? [...newItem.estimatedItemIds, ...bu.estimatedItemIds]
          : [...bu.estimatedItemIds],
        quantityDecimal: new Decimal(newItem.quantityDecimal || 0)
          .plus(bu.quantityDecimal || 0)
          .toString(),
        manufacturerId: newItem.manufacturerId,
      };
    });
    const existingUpdates = bulkUpdates.filter((u) =>
      updates.some(
        (existing) =>
          existing.itemId === u.itemId &&
          existing.estimatedItemIds.some((ei) =>
            u.estimatedItemIds.includes(ei),
          ),
      ),
    );
    const newBulkUpdates = [
      ...updatedBulkUpdates,
      ...updates.filter(
        (u) =>
          !existingUpdates.some(
            (existing) =>
              existing.itemId === u.itemId &&
              existing.estimatedItemIds.includes(u.estimatedItemIds?.[0]),
          ),
      ),
    ].filter((u) => u.isIncluded);

    setBulkUpdates(newBulkUpdates);
  };
  const addToRfqHandler = async () => {
    return await updateRfq({
      rfqId: id || "",
      assignDefaultCostCodes: false,
      addedItems: bulkUpdates.map((item) => ({
        quantityDecimal: item.quantityDecimal,
        description: item.description,
        projectItem: item.projectItem,
        manufacturerId: item.manufacturerId,
        costCodeId: item.costCodeId,
      })),
    });
  };

  return (
    <ProjectItemContext.Provider
      value={{
        updates: bulkUpdates,
        setUpdates: setUpdateForBulkUpdate,
        addToRfq: addToRfqHandler,
        isUpdating,
      }}
    >
      {children}
    </ProjectItemContext.Provider>
  );
};

export const useRfqProjectItemsWithUpdates = () => {
  return useContext(ProjectItemContext);
};
