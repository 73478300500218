import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { ListRenderer } from "@/common/components/list-renderer/ListRenderer";
import {
  EstimatedItemFieldsFragment,
  ProjectFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { FC, useMemo } from "react";
import tw from "tailwind-styled-components";
import { useEstimatedItems } from "../../providers/EstimatedItemsProvider";
import { ProjectItemsSideActions } from "./ProjectItemsSideActions";
import { ProjectListItems } from "./ProjectListItems";

const Container = tw.div``;

type Props = {
  project: ProjectFieldsFragment;
  loading?: boolean;
  columns: Array<
    GridCol<ProjectItemFieldsFragment, EstimatedItemFieldsFragment>
  >;
  readonly?: boolean;
};

export const ProjectList: FC<Props> = ({ project, loading, columns }) => {
  const { newProjectEstimatedItem } = useEstimatedItems();
  const condition = useMemo(
    () => project.items.length > 0 || !!newProjectEstimatedItem.isAddMode,
    [newProjectEstimatedItem.isAddMode, project.items.length],
  );

  return (
    <Container>
      <ListRenderer hasItemsCondition={condition}>
        <ProjectListItems columns={columns} loading={loading} />
        <ProjectItemsSideActions />
      </ListRenderer>
    </Container>
  );
};
