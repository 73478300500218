import { GridCol } from "@/common/components/grid-table/types/GridCol";
import { If } from "@/common/components/if/If";
import { Permission } from "@/common/components/org-roles-wrapper/OrgRolesWrapper";
import { usePermissions } from "@/common/components/org-roles-wrapper/hasPermissions";
import { Price } from "@/common/components/price/Price";
import {
  ProductCategory,
  ProductCategoryType,
} from "@/common/components/product-category/ProductCategory";
import { TagPicker } from "@/common/components/tag-picker/TagPicker";
import { ValueUnit } from "@/common/components/value-unit/ValueUnit";
import {
  Base,
  MdFixedContainer,
  SmFixedContainer,
  SmWideContainer,
  XsFixedContainer,
} from "@/common/layout/ResponsiveClasses";
import { ReleaseItemMaterial } from "@/contractor/pages/home/buyout-releases/components/releases-list/release-view/ReleaseItemMaterial";
import { GroupByCostCodeToggle } from "@/contractor/pages/home/release/components/group-by-costcode/GroupByCostCodeToggle";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { filterByMaterialNamePriceAndQty } from "@/contractor/pages/home/release/utils/filters/filterByMaterialNamePriceAndQty";
import { EstimatedItemFieldsFragment } from "@/generated/graphql";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";

const RequestedItemsContainer = tw(Base)`col-span-full flex-1`;
const FloatingText = tw.span`absolute w-96 text-center text-xs text-gray-600`;

export const useDeliverySlipOrderConfiguration = ({
  projectId,
}: {
  projectId?: string;
}) => {
  const { hasPermissions } = usePermissions([Permission.canViewPrices]);
  const { calcExtPrice } = usePriceCalculation();

  const configuration: Array<
    GridCol<ExpandedReleaseItem, EstimatedItemFieldsFragment>
  > = useMemo(() => {
    return [
      {
        wrapper: RequestedItemsContainer,
        item: ({ item, count }) => (
          <ReleaseItemMaterial
            count={count}
            item={item}
            projectId={projectId}
          />
        ),
        group: (category) => (
          <ProductCategory
            type={ProductCategoryType.Zone}
            category={category}
            items={category.items.length}
          />
        ),
        subgroup: (category) => (
          <ProductCategory
            type={ProductCategoryType.CostCode}
            category={category}
            items={category.items.length}
            projectId={projectId}
          />
        ),
        header: (
          <>
            <FormattedMessage id="ITEMS_IN_RELEASES" />
            <GroupByCostCodeToggle />
          </>
        ),
        searchItemFn: filterByMaterialNamePriceAndQty,
      },
      {
        wrapper: SmFixedContainer,
        header: <FormattedMessage id="TAGS" />,
        item: ({ item }) => {
          return <TagPicker values={item.tags} visibleTags={1} readonly />;
        },
      },
      {
        wrapper: XsFixedContainer,
        item: ({ item }) => {
          const uom = item.uom || item.projectItem?.estimateUom;
          return item.isIncluded ? (
            <ValueUnit value={item.quantityDecimal} uom={uom} />
          ) : (
            <FormattedMessage
              id="NOT_INCLUDED_BY_VENDOR"
              tagName={FloatingText}
            />
          );
        },
        position: "center",
        header: <FormattedMessage id="RELEASE_QUANTITY" />,
      },
      {
        wrapper: MdFixedContainer,
        item: ({ item }) => (
          <If isTrue={item.isIncluded}>
            <Price price={item.unitPrice} />
          </If>
        ),
        header: <FormattedMessage id="UNIT_PRICE" />,
        position: "center",
        hidden: !hasPermissions,
      },
      {
        wrapper: SmWideContainer,
        item: ({ item }) => (
          <If isTrue={item.isIncluded}>
            <Price price={calcExtPrice(item.quantityDecimal, item.unitPrice)} />
          </If>
        ),
        header: <FormattedMessage id="EXT_PRICE" />,
        position: "center",
        hidden: !hasPermissions,
      },
    ];
  }, [calcExtPrice, hasPermissions, projectId]);

  return configuration;
};
