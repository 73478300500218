import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import { InvoiceFooterState } from "@/common/components/invoices/invoice-details/types/InvoiceFooterState";
import { LinkLike } from "@/common/components/link-like/LinkLike";
import { routes } from "@/config/routes";
import { AuthorizationStatus } from "@/generated/graphql";
import { InfoOutlined } from "@mui/icons-material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router-dom";
import tw from "tailwind-styled-components";
import {
  MatchedOrderViewState,
  useInvoiceMatchedOrder,
} from "../../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "../../../../providers/InvoiceVerificationProvider";

const Container = tw.div`
  grid grid-flow-col gap-1 justify-between text-xs font-normal items-center px-4 my-2
`;

const InfoIcon = tw(InfoOutlined)`
  text-gray-500 w-6 h-6 
`;

const LinkLikeStyled = tw(LinkLike)`
  text-xs
`;

export const InvoiceReleaseEditInfo: FC = () => {
  const { invoice, setFooterState } = useInvoiceVerification();
  const { setMatchedOrderViewState, matchedOrderViewState } =
    useInvoiceMatchedOrder();

  return (
    <Container>
      <If
        isTrue={
          invoice?.permissions.edit === AuthorizationStatus.Authorized &&
          !invoice.archivedAt
        }
      >
        <Container>
          <OutlinedButton
            $small
            onClick={() => {
              setMatchedOrderViewState(MatchedOrderViewState.EDIT_ORDER);
              setFooterState(InvoiceFooterState.EDIT_ORDER);
            }}
          >
            <FormattedMessage
              id="EDIT_ORDER_WITH_NUMBER"
              values={{
                orderNumber: invoice?.release?.sequenceNumber,
              }}
            />
          </OutlinedButton>
          <If
            isTrue={
              matchedOrderViewState !==
              MatchedOrderViewState.EDIT_INVOICE_COVERAGES
            }
          >
            <OutlinedButton
              $small
              onClick={() => {
                setMatchedOrderViewState(
                  MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
                );
                setFooterState(InvoiceFooterState.EDIT_INVOICE_COVERAGES);
              }}
              className="ml-2"
            >
              <FormattedMessage id="ADJUST_INVOICE_QUANTITIES" />
            </OutlinedButton>
          </If>
        </Container>
      </If>
      <If
        isTrue={
          invoice?.permissions.edit === AuthorizationStatus.SelfAssignable
        }
      >
        <Container>
          <InfoIcon />
          <FormattedMessage id="RELEASE_SELF_ASSIGNED_EDIT_INFO" />
          <LinkLikeStyled
            to={generatePath(routes.projectDetails, {
              id: invoice?.release?.project?.id ?? "",
            })}
          >
            {invoice?.release?.project?.name}
          </LinkLikeStyled>
        </Container>
      </If>
    </Container>
  );
};
