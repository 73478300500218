import { CountType } from "@/common/components/circle-counter/CountType";
import { MaterialView } from "@/common/components/material/MaterialView";
import { isOrgCatalogSku } from "@/common/components/material/utils";
import { OrgMaterialFieldsFragment } from "@/generated/graphql";
import { FC, useCallback, useMemo } from "react";
import { useMaterialList } from "../../../providers/MaterialListProvider";

type Props = {
  item: OrgMaterialFieldsFragment;
  editMode: boolean;
  count: CountType;
};

export const OrgMaterialVIew: FC<Props> = ({
  item,
  editMode,
  count,
}: Props) => {
  const { updateMaterial } = useMaterialList();

  const isOrgSku = useMemo(() => {
    return isOrgCatalogSku(item.material);
  }, [item.material]);

  const onChange = useCallback(
    (name: string | null) => {
      updateMaterial(item.id, {
        updates: [
          {
            orgCatalogSku: {
              id: item.material.id,
              name,
            },
            orgMaterialId: item.id,
          },
        ],
      });
    },
    [updateMaterial, item],
  );

  return (
    <MaterialView
      material={item.material}
      description={item.material.name}
      count={count}
      includeCounter
      readonly={!isOrgSku}
      editMode={editMode}
      updateDescription={onChange}
      immediateUpdate
      hideActions
    />
  );
};
