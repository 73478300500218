import { OutlinedButton } from "@/common/components/button/OutlinedButton";
import { If } from "@/common/components/if/If";
import { DrawerPanel } from "@/common/components/panel/DrawerPanel";
import { ConnectionMode } from "@/contractor/pages/admin/integrations/components/common/ConnectionMode";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { ConnectReleasePOsPanel } from "@/contractor/pages/home/release/components/connections/components/panels/ConnectReleasePOsPanel";
import {
  IntegrationType,
  PoFormat,
  ReleaseFieldsFragment,
  SourceSystem,
} from "@/generated/graphql";
import { FC, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useIsValidFoundationReleaseExport } from "../../hooks/isValidFoundationReleaseExport";
import { useIsValidSage300ReleaseExport } from "../../hooks/isValidSage300ReleaseExport";
import {
  ReleaseExportStatus,
  ReleaseExportStatusEnum,
} from "../common/ReleaseExportStatus";
import { ExportMultipleReleasePOsPanel } from "../panels/ExportMultipleReleasePOsPanel";
import { FoundationExportTypeDialog } from "./components/FoundationExportTypeDialog";
type Props = {
  release: ReleaseFieldsFragment;
};

export const ConnectFoundationReleasePOButton: FC<Props> = ({ release }) => {
  const intl = useIntl();
  const { connectedIntegrationType } = useOrgSettings();
  const { isValidFoundationReleaseExport } =
    useIsValidFoundationReleaseExport();
  const { isValidSage300ReleaseExport } = useIsValidSage300ReleaseExport();

  const [showDialog, setShowDialog] = useState(false);
  const [toJob, setToJob] = useState(!(release.exportedAt && !release.poLink));

  const status = useMemo(() => {
    if (
      (connectedIntegrationType === IntegrationType.Foundation &&
        !isValidFoundationReleaseExport({
          release,
          poFormatBasic: release.type.poFormat === PoFormat.Basic,
        })) ||
      (connectedIntegrationType === IntegrationType.Sage300 &&
        !isValidSage300ReleaseExport({
          release,
        }))
    ) {
      return ReleaseExportStatusEnum.ExportIssue;
    }
    if (release.exportedVersion !== release.syncVersion) {
      return ReleaseExportStatusEnum.Outdated;
    }
    return ReleaseExportStatusEnum.OK;
  }, [
    connectedIntegrationType,
    isValidFoundationReleaseExport,
    isValidSage300ReleaseExport,
    release,
  ]);

  return (
    <>
      <DrawerPanel
        anchor={(togglePanel) => (
          <>
            <If isTrue={release.exportedAt}>
              <ReleaseExportStatus
                status={status}
                sourceSystem={SourceSystem.Foundation}
                date={release.exportedAt}
                poNumber={release.poNumber}
                projectId={release.project?.id}
              />
            </If>
            <OutlinedButton $small onClick={() => setShowDialog(true)}>
              {intl.$t({ id: "CONNECT_PO" })}
            </OutlinedButton>
            <FoundationExportTypeDialog
              toJob={toJob}
              showDialog={showDialog}
              setToJob={setToJob}
              setShowDialog={setShowDialog}
              togglePanel={togglePanel}
            />
          </>
        )}
        content={(togglePanel) =>
          toJob ? (
            <ConnectReleasePOsPanel
              releaseIds={[release.id]}
              sourceSystem={SourceSystem.Foundation}
              onClose={() => togglePanel(false)}
              mode={ConnectionMode.Connect}
            />
          ) : (
            <ExportMultipleReleasePOsPanel
              releaseIds={[release.id]}
              onClose={() => togglePanel(false)}
              titleId="EXPORT_WAREHOUSE_PO"
            />
          )
        }
      />
    </>
  );
};
